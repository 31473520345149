export const statesData = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {
        gid: 1,
        id: 0,
        area: 4021522.6931469999,
        perimeter: 12382.312916000001,
        kode_pos: 50144,
        kode_kec: 3374140,
        kode_kel: 3374080036,
        kec: "Semarang Barat",
        kel: "Tawangsari",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.39374327171214, -6.945505822969306],
            [110.395886608822991, -6.949984062210688],
            [110.396687089278018, -6.951759541700389],
            [110.398025164335237, -6.954492426786762],
            [110.398215099426565, -6.95495417324103],
            [110.397494466415324, -6.955238336271638],
            [110.396927824250341, -6.955472797691301],
            [110.396673946453916, -6.955571974664537],
            [110.396345847844302, -6.955752466285952],
            [110.39578434823666, -6.956167842581864],
            [110.395508502355781, -6.956411718664007],
            [110.395110406938713, -6.956908712043518],
            [110.394879308041951, -6.95733355461801],
            [110.394717505065287, -6.957767531143224],
            [110.394658056725234, -6.958020728759032],
            [110.394569758299454, -6.958490981139945],
            [110.394503726852221, -6.959024580375679],
            [110.394433572425115, -6.959684811107654],
            [110.394363158024149, -6.96032695049006],
            [110.394297044721398, -6.960923867907764],
            [110.394225002060111, -6.961511732209567],
            [110.394177519931716, -6.961918718229301],
            [110.39414128815524, -6.962597083178437],
            [110.394034158868124, -6.963411039528517],
            [110.393981370929922, -6.963763745764508],
            [110.393935740733014, -6.964270234377486],
            [110.393873938996919, -6.964812884171366],
            [110.393813846044964, -6.965346490640558],
            [110.393757182489395, -6.965852965028],
            [110.393712667569332, -6.966151408870789],
            [110.393608454158795, -6.966675913184842],
            [110.393557029364942, -6.966847711376526],
            [110.393375042621329, -6.967354024846168],
            [110.393255854477601, -6.967643327271863],
            [110.393135003698873, -6.967905491074078],
            [110.392932527531613, -6.968285141242026],
            [110.392755910964496, -6.968574369716729],
            [110.392614478084468, -6.968782234006399],
            [110.392452959446885, -6.968990072419652],
            [110.392152069231656, -6.969342459175771],
            [110.391907308670824, -6.969604463149504],
            [110.391646174822924, -6.969839309457448],
            [110.391474293215396, -6.969965724820685],
            [110.391196161799812, -6.970218639903407],
            [110.388547438853237, -6.972585133347332],
            [110.388450217540054, -6.972729735026092],
            [110.388026179671343, -6.973362368756334],
            [110.387770229997798, -6.973959038053974],
            [110.387570388566104, -6.974700507422511],
            [110.387527248548196, -6.975243179611661],
            [110.387526331812893, -6.975948724988948],
            [110.387645202728933, -6.977432336031253],
            [110.387690964612872, -6.978137941919251],
            [110.387698604542351, -6.978572134296756],
            [110.387679333499307, -6.979033428153985],
            [110.387637241358576, -6.979422328643762],
            [110.387479678522823, -6.980064352318609],
            [110.387362982066634, -6.980389837620423],
            [110.387239566364713, -6.980661041350009],
            [110.386922621600903, -6.981167175694886],
            [110.386306010642997, -6.98194428394495],
            [110.385509871181156, -6.98284779381214],
            [110.384241122073448, -6.984302458271108],
            [110.384090239074851, -6.98437462490601],
            [110.383864433561428, -6.98441051176286],
            [110.383687551071816, -6.984464553370535],
            [110.380288500001811, -6.980118280580626],
            [110.37957652742341, -6.979402884122483],
            [110.380086283599354, -6.979213469456792],
            [110.381125279063511, -6.978862060369978],
            [110.381288560743968, -6.978826092637558],
            [110.381393800097726, -6.978826230597776],
            [110.381510296143261, -6.978871610575247],
            [110.382128268137805, -6.979216147490463],
            [110.382326511124461, -6.979270679765652],
            [110.382454948547021, -6.97927084786148],
            [110.382559952539978, -6.97923480342544],
            [110.382793359875166, -6.979008972269574],
            [110.383244402437427, -6.978503016172037],
            [110.383524146839804, -6.978105381375533],
            [110.383728261565608, -6.977780011319221],
            [110.383879343458446, -6.977554072008202],
            [110.383985384864857, -6.977373301168059],
            [110.384022869643488, -6.977264804537766],
            [110.384023070634058, -6.977111031939152],
            [110.383868434030205, -6.976161056577711],
            [110.383789628500082, -6.974767952472912],
            [110.383707740508726, -6.971837115477554],
            [110.383745795678294, -6.970208981554217],
            [110.383816113164158, -6.96813766175455],
            [110.38385798681982, -6.965966804513717],
            [110.383598141153982, -6.964347327346719],
            [110.383512368792253, -6.963089895672907],
            [110.383445644283583, -6.961977216258885],
            [110.383204600622278, -6.960692445710448],
            [110.383108360495058, -6.96008627393376],
            [110.383528231904521, -6.958087772627971],
            [110.383656417208954, -6.956975346855877],
            [110.381657990334418, -6.950105091946694],
            [110.38174120195788, -6.949939739215611],
            [110.381700174777748, -6.949691765801335],
            [110.38153014405593, -6.949304169106545],
            [110.381499592001589, -6.948984109868604],
            [110.381536084605642, -6.948766660784251],
            [110.381562025530783, -6.948612101035684],
            [110.381914264931851, -6.948034080755058],
            [110.382307625195978, -6.947631723662997],
            [110.382783605828948, -6.9472914537527],
            [110.385653554598051, -6.946349986585172],
            [110.386211617343818, -6.946484999484522],
            [110.387223403761396, -6.947498649016751],
            [110.387181464253828, -6.9479531150778],
            [110.387087942822347, -6.948324874369775],
            [110.387056031235076, -6.948370463770375],
            [110.387007735975331, -6.948439439758851],
            [110.386957740148759, -6.948433977032979],
            [110.386650677159537, -6.947945253235464],
            [110.385690968561477, -6.948269561329408],
            [110.386443051932744, -6.951382688851104],
            [110.386637106866928, -6.951503191588291],
            [110.390069238031472, -6.950831548637873],
            [110.390283118676422, -6.950608811726413],
            [110.39015032344949, -6.950413130920169],
            [110.39014007742955, -6.950411972914829],
            [110.388866474895167, -6.948587896623825],
            [110.390465066565767, -6.94726026910912],
            [110.392442214291421, -6.946102340785544],
            [110.39374327171214, -6.945505822969306],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 2,
        id: 1,
        area: 891661.65117299999,
        perimeter: 4123.883901,
        kode_pos: 50141,
        kode_kec: 3374140,
        kode_kel: 3374080020,
        kec: "Semarang Barat",
        kel: "Krobokan",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.401519956716186, -6.977513080042981],
            [110.401877707700876, -6.981113148262547],
            [110.401444034843621, -6.981024208647666],
            [110.40122497493131, -6.981136853677471],
            [110.401087683870188, -6.981264560823354],
            [110.400941320543566, -6.981337450083255],
            [110.400744798749628, -6.981314364527458],
            [110.40045695683061, -6.981213520567407],
            [110.399744377838317, -6.980828970303093],
            [110.399124065400983, -6.980884372882414],
            [110.396985075891592, -6.981288690510909],
            [110.396169267162648, -6.981441420204668],
            [110.395342719286873, -6.981585089197559],
            [110.395148754379051, -6.980399882762539],
            [110.394773294154888, -6.978454623260358],
            [110.394705505815551, -6.977929898508512],
            [110.394553563644394, -6.976627154553193],
            [110.394388100907022, -6.975279165806741],
            [110.394323572477205, -6.975080082473869],
            [110.394164354031773, -6.974817559294412],
            [110.393534430097844, -6.974084066861597],
            [110.393223683240592, -6.973739939496942],
            [110.393040985219017, -6.973477385848083],
            [110.392998674817292, -6.973160906221898],
            [110.394542061921968, -6.973027038525721],
            [110.39441414496892, -6.971948344187016],
            [110.395159504889833, -6.971829404481287],
            [110.395028073252391, -6.970901630197401],
            [110.396089204113551, -6.970776782848207],
            [110.396107992457488, -6.970899856663383],
            [110.396613276537991, -6.97085317539771],
            [110.396591407099066, -6.970670150312364],
            [110.396929315539737, -6.970639030511223],
            [110.397409422806675, -6.970526058625484],
            [110.397937482541991, -6.970317732717997],
            [110.398487763880027, -6.970282251624724],
            [110.399288657627878, -6.970274225036561],
            [110.399911100776606, -6.970220743027774],
            [110.400124428354658, -6.970202922929237],
            [110.400302948057217, -6.970194104048018],
            [110.400857949778256, -6.970171566572808],
            [110.400857950219304, -6.970171570455171],
            [110.400889951280192, -6.970451043138938],
            [110.401117575436587, -6.972769567363088],
            [110.401309383765323, -6.974922457787222],
            [110.401519956716186, -6.977513080042981],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 175,
        id: 3,
        area: 4272595.5392230004,
        perimeter: 21187.104807,
        kode_pos: 50174,
        kode_kec: 3374120,
        kode_kel: 3374060017,
        kec: "Semarang Utara",
        kel: "Tanjung Emas",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.444758627738764, -6.937347554360451],
            [110.444922025386902, -6.938065224671496],
            [110.444867674281312, -6.938482291386523],
            [110.444815598430679, -6.938810836302019],
            [110.444597477809737, -6.939770477233402],
            [110.444526862146915, -6.940081200265116],
            [110.444474530584074, -6.940680624381454],
            [110.444462905734341, -6.940813816691755],
            [110.444264782014656, -6.94162768606364],
            [110.444017372178408, -6.942495770928097],
            [110.443759505255898, -6.943354797727848],
            [110.443495354192621, -6.944023859964943],
            [110.443153959953477, -6.944946107182966],
            [110.443006034500371, -6.945407257397894],
            [110.442091925771479, -6.947658528580569],
            [110.441402367082475, -6.948996459941273],
            [110.441198042596383, -6.949303768219495],
            [110.441073721440446, -6.949656398569056],
            [110.441052419591784, -6.949807752491909],
            [110.440983647833392, -6.950039401712261],
            [110.440760235779365, -6.950130980443198],
            [110.440738495065375, -6.950139908491902],
            [110.440367293240456, -6.950292662696823],
            [110.440012932018718, -6.950438486407652],
            [110.439990905256224, -6.95044755066121],
            [110.439016686545713, -6.950848450895586],
            [110.437840759601286, -6.9513415624941],
            [110.437084617373159, -6.951655105538983],
            [110.436734759177583, -6.951834754117534],
            [110.435881508953955, -6.952384682124603],
            [110.435510088327604, -6.952601929426876],
            [110.434412212651196, -6.953044061834261],
            [110.434057521130512, -6.953179311955455],
            [110.433653405170404, -6.95333340805507],
            [110.432423743507172, -6.953770002898861],
            [110.430605552150453, -6.954410279924911],
            [110.430612257282689, -6.954640046776228],
            [110.430896825635756, -6.964259832382465],
            [110.430898000227046, -6.964313679789324],
            [110.430898000535606, -6.964313693857296],
            [110.430959168548881, -6.967102471796459],
            [110.430959514320634, -6.967138443894777],
            [110.430614141061014, -6.967355112243943],
            [110.43024981218835, -6.967553683863797],
            [110.429802517607399, -6.967815465133039],
            [110.429206374695752, -6.96811324835354],
            [110.428726100493961, -6.968275487768686],
            [110.428395000734312, -6.968365542815788],
            [110.428036296012252, -6.968400286991852],
            [110.427749351564671, -6.968428080259068],
            [110.427646065575402, -6.968436169049874],
            [110.427285512063619, -6.968464405438227],
            [110.427097117866239, -6.968479159230244],
            [110.42659924322291, -6.968525076996006],
            [110.426112056220859, -6.968617528751221],
            [110.425464872970053, -6.968723811241624],
            [110.425348249795292, -6.968735619803071],
            [110.425166259949876, -6.968798512497751],
            [110.425163418116469, -6.968800741322035],
            [110.425100734431226, -6.968849602231695],
            [110.425007738590736, -6.968922839308109],
            [110.424975306992351, -6.968948275090149],
            [110.424913989372754, -6.968996365878849],
            [110.424479670175316, -6.96940454272565],
            [110.424181850289031, -6.969684906959677],
            [110.42409912192042, -6.969763244578109],
            [110.424066018469787, -6.969804487618871],
            [110.424028793461275, -6.969837468955592],
            [110.423987467058311, -6.969845675257554],
            [110.423984557861772, -6.969841951568678],
            [110.423913213624189, -6.969750632948881],
            [110.423859562915212, -6.969701027475012],
            [110.423846259123607, -6.969696111560229],
            [110.423781097224207, -6.969672033429414],
            [110.423753667865839, -6.969662462887956],
            [110.423626586333327, -6.969662307898729],
            [110.4231961986833, -6.969743192495295],
            [110.422584548635569, -6.969853833279649],
            [110.422550481076698, -6.969859995702192],
            [110.422356690400306, -6.969923159667079],
            [110.422356672044728, -6.969923166798643],
            [110.422287274944935, -6.969950129244147],
            [110.421656339841533, -6.970238814246039],
            [110.421618975068569, -6.970256859560727],
            [110.421395917968724, -6.970365132767292],
            [110.420754174751963, -6.970678406516792],
            [110.420544783851227, -6.969998088855911],
            [110.420097668503445, -6.968545390968125],
            [110.418940146263608, -6.968697743126085],
            [110.418643614105548, -6.968742606056591],
            [110.418191142574159, -6.969067687985707],
            [110.417250157928024, -6.969808261119523],
            [110.416465624393567, -6.968938923231434],
            [110.415793767986983, -6.968232542841576],
            [110.415369127401462, -6.967788787456052],
            [110.41561823145274, -6.966974998959647],
            [110.415758281505035, -6.966504805154999],
            [110.415820627533961, -6.966188289075148],
            [110.418002124873581, -6.965431155815455],
            [110.419404133784113, -6.964989648811247],
            [110.419911010228304, -6.964818405945213],
            [110.420399453750193, -6.964683322014368],
            [110.420962581306156, -6.964548329011559],
            [110.421504424974017, -6.964467582439345],
            [110.421531571807805, -6.964476661175789],
            [110.421648302503229, -6.96479339742319],
            [110.421716796372152, -6.964766344609193],
            [110.421908992941368, -6.964676124334559],
            [110.422096696337078, -6.964558761899292],
            [110.422258079340949, -6.96445041275413],
            [110.42234564882996, -6.964323882329606],
            [110.422359327181496, -6.964242654999445],
            [110.422567482700117, -6.964287971138057],
            [110.422870038391622, -6.964867254371161],
            [110.422862152325052, -6.965301430155314],
            [110.422722021135769, -6.965843990826779],
            [110.422271237503637, -6.966874630393586],
            [110.422204846455344, -6.967263506936847],
            [110.422292439773756, -6.967580207462841],
            [110.422592904599966, -6.96778862173996],
            [110.423023352206982, -6.967888648195112],
            [110.42344879628979, -6.967916303935646],
            [110.424688161073675, -6.968388181721335],
            [110.425032512660096, -6.9687505392675],
            [110.425120806876791, -6.968542482208883],
            [110.425092693308429, -6.967701213565638],
            [110.425094092674868, -6.966552432697328],
            [110.4251969197568, -6.966208827499626],
            [110.425204880862907, -6.965711332882434],
            [110.425132679275436, -6.964833828417169],
            [110.425016588556943, -6.963757268841457],
            [110.424711255965093, -6.961043237550577],
            [110.424558698905372, -6.959713358557245],
            [110.424482012771946, -6.959034850243588],
            [110.424331315583615, -6.957804474310052],
            [110.424285832104033, -6.956592317360706],
            [110.423233615281191, -6.95694381237555],
            [110.422263928341181, -6.957123540557034],
            [110.421642307035327, -6.957213236784922],
            [110.421081695402279, -6.957149232792868],
            [110.420364212870808, -6.956976489748506],
            [110.420272230745184, -6.95668658510803],
            [110.420187078502636, -6.956418206259292],
            [110.420187100785171, -6.956418200196586],
            [110.420294777143638, -6.956388445367741],
            [110.42028991549229, -6.956372701211969],
            [110.420194184810995, -6.956062683153922],
            [110.420169856282811, -6.956062653359196],
            [110.420076308238237, -6.955754698937199],
            [110.419944454507302, -6.955320643928531],
            [110.419571951617542, -6.954117132201582],
            [110.419336129041326, -6.953338927689463],
            [110.419382262028236, -6.953320893189436],
            [110.419106957989015, -6.952443139387886],
            [110.419114579692206, -6.952226056054616],
            [110.419136341284698, -6.951321529882288],
            [110.419155655347126, -6.951258234860807],
            [110.419206020327977, -6.951249251077712],
            [110.419255797323714, -6.951258357626286],
            [110.41931087144637, -6.951330789365659],
            [110.419402914967975, -6.95147563064105],
            [110.419472654862602, -6.951584262454952],
            [110.4195011709607, -6.951629525043971],
            [110.419542777575828, -6.951611484966477],
            [110.419536587358408, -6.951584340793985],
            [110.419583579923156, -6.951557261782291],
            [110.419619101618792, -6.951656806124344],
            [110.419559989294314, -6.951647688172232],
            [110.419512724932446, -6.951665721318392],
            [110.419628342428553, -6.951964365442525],
            [110.419677869916754, -6.951946335059533],
            [110.419668567927701, -6.951919187076139],
            [110.419719509846601, -6.95190115842099],
            [110.419757294690783, -6.952000705527973],
            [110.419708332973897, -6.952018736612041],
            [110.41969140411986, -6.951982533756738],
            [110.419646402826658, -6.952000569685952],
            [110.419740859391354, -6.952253960219267],
            [110.4199471454302, -6.952896445463197],
            [110.420087208330457, -6.953330802386714],
            [110.420094530147566, -6.953357947940013],
            [110.420135809647803, -6.953376089539934],
            [110.420177466383493, -6.953548005600883],
            [110.420151135583296, -6.953566064423427],
            [110.420218207936117, -6.953774193716169],
            [110.420257235458777, -6.953783287022083],
            [110.420301920753403, -6.953792387250227],
            [110.420337010017121, -6.953783384669832],
            [110.420366463685042, -6.953756284129854],
            [110.420378955128442, -6.953720117298802],
            [110.420271324211555, -6.953367209903533],
            [110.420270824968185, -6.953312936113917],
            [110.420266354200947, -6.953267702992738],
            [110.420287520781088, -6.953077772773853],
            [110.420301188111409, -6.953005425262376],
            [110.420290832132594, -6.952914957288208],
            [110.420185923621375, -6.952357816153861],
            [110.420510968364141, -6.952312391445525],
            [110.420598379008567, -6.952348481795511],
            [110.420883729345917, -6.952482483467025],
            [110.421022499084316, -6.952588033141488],
            [110.421128187290705, -6.952695860062473],
            [110.421209973978691, -6.952829865274762],
            [110.420947474173374, -6.952940064963038],
            [110.420996091111633, -6.953148314220341],
            [110.421232771496491, -6.953117760555648],
            [110.421273722289797, -6.953284876509196],
            [110.421132194473429, -6.953330967961445],
            [110.421224277899768, -6.953752600643124],
            [110.421393927130495, -6.953847906997255],
            [110.421108336696562, -6.95390924386644],
            [110.421098550543107, -6.95395546151685],
            [110.421158564505518, -6.954012297705654],
            [110.421482006882641, -6.953943922569401],
            [110.421555013836453, -6.954095743174854],
            [110.421572383167231, -6.954185275016579],
            [110.421153104333413, -6.95490303084692],
            [110.421169392974662, -6.955068868109879],
            [110.421445010818715, -6.955232024508301],
            [110.42148291800234, -6.955232070824518],
            [110.421627196434557, -6.954997063269432],
            [110.421944141281998, -6.954445672852028],
            [110.42296237887949, -6.955043919907583],
            [110.424171459611529, -6.955732852455639],
            [110.424418635165736, -6.955326103913554],
            [110.423053532649305, -6.95452843556984],
            [110.423634928970188, -6.953552225808476],
            [110.426083575283343, -6.954984394451996],
            [110.426381072500504, -6.954491129258532],
            [110.424297238440019, -6.953280253729043],
            [110.422666554367794, -6.952344546530776],
            [110.422696966735231, -6.952292336695586],
            [110.422623419757144, -6.952238570613032],
            [110.422928452840708, -6.951718081972911],
            [110.422793269471811, -6.951632432571126],
            [110.422479410472263, -6.952094487304643],
            [110.422408638258631, -6.952166740185008],
            [110.422384776079923, -6.95215677100728],
            [110.422410761306779, -6.952057401873549],
            [110.422897173436766, -6.951264776088345],
            [110.422885279956262, -6.951228977301679],
            [110.422775938686385, -6.951161251532595],
            [110.422738125631582, -6.951171145543896],
            [110.422231788961739, -6.951987603050295],
            [110.42205282396155, -6.951911840123664],
            [110.421613469494872, -6.95164093370763],
            [110.421627422901437, -6.951619082565567],
            [110.421376939872687, -6.951457747392672],
            [110.421624136603214, -6.951052493947564],
            [110.421431308628613, -6.950925025477019],
            [110.42117621454102, -6.951280568766712],
            [110.420937683585962, -6.951107319892799],
            [110.421116843159822, -6.950853586320191],
            [110.421063443974006, -6.950799247881843],
            [110.421006844481184, -6.950817269782476],
            [110.420848254907597, -6.951025701929131],
            [110.420812482471348, -6.950993849944733],
            [110.421128061121181, -6.950557403456014],
            [110.420976887188033, -6.950448161430301],
            [110.420614249941053, -6.95084924728392],
            [110.420579553989555, -6.950824419104426],
            [110.421061513612514, -6.95021032161887],
            [110.420868223780673, -6.950058892330563],
            [110.420368916130201, -6.950660575518532],
            [110.420142446028464, -6.950347749585997],
            [110.420739570641075, -6.949643246568013],
            [110.420485156522901, -6.949464636729795],
            [110.420372239609037, -6.949373128440511],
            [110.419975208223988, -6.948985588403179],
            [110.419832519714575, -6.948849499719648],
            [110.419828071936337, -6.948836931753755],
            [110.41983576497168, -6.948777296746302],
            [110.419777421905309, -6.948715042836405],
            [110.419754615083718, -6.948671867883631],
            [110.419748486342982, -6.948111116761384],
            [110.419765033961994, -6.948051518177748],
            [110.419627835406459, -6.94793677700307],
            [110.419682110736574, -6.94785878259539],
            [110.419737612079004, -6.947808488696573],
            [110.419820828572483, -6.947762005853257],
            [110.419901498835387, -6.947736923663481],
            [110.420028748625796, -6.947744633656947],
            [110.42007913282302, -6.947757285765451],
            [110.420148384490531, -6.94779640092182],
            [110.420208799267584, -6.947849354768735],
            [110.420882165717487, -6.948445706909489],
            [110.420808978278004, -6.948533750723003],
            [110.421258621827008, -6.948896651848033],
            [110.421376961071488, -6.94875855476717],
            [110.421421011587867, -6.948798898034188],
            [110.421302097075937, -6.948932516893246],
            [110.42159894782445, -6.949180751495479],
            [110.421686516367615, -6.949088802796452],
            [110.421638445847677, -6.949036071165584],
            [110.421697999417106, -6.948971725480967],
            [110.421835189573514, -6.949094020385968],
            [110.421773368297195, -6.949160674427661],
            [110.421719236221122, -6.949121577933532],
            [110.421637224230992, -6.949213388247939],
            [110.421955609193631, -6.949536092631103],
            [110.422695884583291, -6.950030541363405],
            [110.422820830128018, -6.9498619813102],
            [110.422858596246627, -6.94988972633788],
            [110.422818205692565, -6.94994759329156],
            [110.423033471743921, -6.95010649534423],
            [110.42302821306339, -6.950214798072127],
            [110.423684330722409, -6.950566839525501],
            [110.423758845483221, -6.950423398807518],
            [110.425187972616172, -6.951224629671251],
            [110.425107158117001, -6.951368062989618],
            [110.425520513475306, -6.951582589403776],
            [110.425771258953603, -6.951727622024438],
            [110.426184224164274, -6.951979865002035],
            [110.426228729694344, -6.951959811755529],
            [110.426548258156046, -6.951398653429895],
            [110.426480489446931, -6.951334082984816],
            [110.426881716823914, -6.950702912765276],
            [110.42666502894906, -6.950463004563558],
            [110.426625360323541, -6.950501450561686],
            [110.426400231772533, -6.950251182157177],
            [110.426441830088066, -6.95021148051084],
            [110.423394502105424, -6.946817277741481],
            [110.4234225229754, -6.936838451008042],
            [110.424292938231204, -6.93685255908886],
            [110.424292239926388, -6.937427520059365],
            [110.424398450066818, -6.937889989288833],
            [110.424561130918846, -6.938254724187727],
            [110.426150801448046, -6.938292213472828],
            [110.426146310304446, -6.939551789649999],
            [110.426135686024566, -6.940977326727241],
            [110.426490245046509, -6.942092114646004],
            [110.42661466968687, -6.942210813906406],
            [110.427477340035267, -6.94193697066945],
            [110.428334376783567, -6.944486805147846],
            [110.430481851433626, -6.943752162960802],
            [110.430999819201901, -6.94464190164153],
            [110.431705060858917, -6.944348144424656],
            [110.432312899146524, -6.944095186470966],
            [110.433777443782859, -6.943996911616335],
            [110.435609520197119, -6.943480443473629],
            [110.436466331265464, -6.943144338448886],
            [110.437493853415432, -6.942505167512965],
            [110.437478721235792, -6.942369919617393],
            [110.437469437695029, -6.942266515312342],
            [110.437532994605078, -6.942212544181031],
            [110.438206032136421, -6.941804468188609],
            [110.438276674296759, -6.941724657007342],
            [110.438305038148314, -6.941602498493559],
            [110.438305238758517, -6.941433309671915],
            [110.438319637985686, -6.941188942520474],
            [110.438357480522043, -6.941005699167886],
            [110.438390536305164, -6.940892945586916],
            [110.438508343481857, -6.940700397466913],
            [110.43868494270238, -6.940505569014838],
            [110.438807358171417, -6.940392921095891],
            [110.438967365366892, -6.940308515769998],
            [110.439216810162904, -6.94015842033506],
            [110.439438013700681, -6.940027090183208],
            [110.439649827507708, -6.939881649737533],
            [110.439774602500137, -6.939761954775016],
            [110.440151092659633, -6.939560312092316],
            [110.440428761103178, -6.939405549551921],
            [110.440518131847455, -6.939396255527724],
            [110.440668582378748, -6.939438730162852],
            [110.440823730534987, -6.939485909986135],
            [110.440950698692603, -6.939500158674189],
            [110.441082419891288, -6.939472115638974],
            [110.441176576920697, -6.939392331646673],
            [110.44122842783473, -6.939293699026642],
            [110.441266113899673, -6.93924204671077],
            [110.441341369653685, -6.93923743560819],
            [110.441515374696664, -6.939247039811345],
            [110.44180697483074, -6.939242683184699],
            [110.441929289647831, -6.93921462884053],
            [110.44223212040572, -6.938659245371035],
            [110.442220791241581, -6.938295005326084],
            [110.442242855138147, -6.93828716034399],
            [110.442237241806168, -6.938279748271629],
            [110.444758627738764, -6.937347554360451],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 3,
        id: 4,
        area: 374978.06128299999,
        perimeter: 2672.502285,
        kode_pos: 50137,
        kode_kec: 3374130,
        kode_kel: 3374070019,
        kec: "Semarang Tengah",
        kel: "Purwodinatan",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.430959514320634, -6.967138443894777],
            [110.431041786242247, -6.969798040235338],
            [110.431169907973896, -6.974175914934743],
            [110.430887507866771, -6.97418080276774],
            [110.430669833137372, -6.974184570206487],
            [110.430663163660185, -6.974085061247947],
            [110.430532180413394, -6.974084903316861],
            [110.430530081084058, -6.973949217718064],
            [110.429835580812679, -6.973930288667122],
            [110.428309088784815, -6.973980691957768],
            [110.428324385391235, -6.974273380481349],
            [110.428311402052444, -6.974552587900571],
            [110.427938820988913, -6.974552136686031],
            [110.427599449226932, -6.974461270102521],
            [110.427396408331319, -6.974328109223477],
            [110.427202063719918, -6.974153240136995],
            [110.42699965239531, -6.973800218817557],
            [110.426842879284564, -6.973139704715917],
            [110.426654080817571, -6.972759563211557],
            [110.426324637347506, -6.972415432986605],
            [110.425742337060697, -6.972025767586125],
            [110.425585581114362, -6.971817529784397],
            [110.425494046426735, -6.97148273382741],
            [110.425548387643573, -6.969836513190559],
            [110.425510027095129, -6.969510827411708],
            [110.425333296415118, -6.96921210998038],
            [110.425150896972255, -6.968922431133377],
            [110.425100734431226, -6.968849602231695],
            [110.425163418116469, -6.968800741322035],
            [110.425166259949876, -6.968798512497751],
            [110.425348249795292, -6.968735619803071],
            [110.425464872970053, -6.968723811241624],
            [110.426112056220859, -6.968617528751221],
            [110.42659924322291, -6.968525076996006],
            [110.427097117866239, -6.968479159230244],
            [110.427285512063619, -6.968464405438227],
            [110.427646065575402, -6.968436169049874],
            [110.427749351564671, -6.968428080259068],
            [110.428036296012252, -6.968400286991852],
            [110.428395000734312, -6.968365542815788],
            [110.428726100493961, -6.968275487768686],
            [110.429206374695752, -6.96811324835354],
            [110.429802517607399, -6.967815465133039],
            [110.43024981218835, -6.967553683863797],
            [110.430614141061014, -6.967355112243943],
            [110.430959514320634, -6.967138443894777],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 4,
        id: 5,
        area: 313290.562683,
        perimeter: 2678.649414,
        kode_pos: 50246,
        kode_kec: 3374050,
        kode_kel: 3374030037,
        kec: "Semarang Selatan",
        kel: "Bulustalan",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.401877707700876, -6.981113148262547],
            [110.402272656538372, -6.981222542028311],
            [110.406657101770307, -6.983246047114641],
            [110.406576988750246, -6.983419604844292],
            [110.405023927187443, -6.986789512238582],
            [110.40463695096129, -6.987874482722554],
            [110.404412977394358, -6.98869733947888],
            [110.404239004594331, -6.989131303149403],
            [110.40409456857688, -6.989474849169516],
            [110.403946251831073, -6.989755071782846],
            [110.403747676480691, -6.990179958570873],
            [110.403553130304346, -6.990550577403129],
            [110.403460400859956, -6.990722324296861],
            [110.403373998629874, -6.990812669778716],
            [110.403225967916427, -6.990866755227413],
            [110.403022521274835, -6.990893633946487],
            [110.402768185295386, -6.990893311604562],
            [110.402450254129732, -6.990875404468225],
            [110.402429806933966, -6.990171713913682],
            [110.402430311035161, -6.989774892953508],
            [110.402474456310344, -6.989285716813488],
            [110.402485786025139, -6.988932396862181],
            [110.402464742123925, -6.988367035263749],
            [110.402404928095223, -6.987702820820143],
            [110.402325133456358, -6.986915469535568],
            [110.402207366412611, -6.985632247384983],
            [110.4021537672037, -6.984877615677767],
            [110.402153090996961, -6.984868095248974],
            [110.402089792972689, -6.983735161956712],
            [110.402035212386906, -6.983060231901635],
            [110.401877707700876, -6.981113148262547],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 5,
        id: 6,
        area: 726638.12641400006,
        perimeter: 3924.270738,
        kode_pos: 50242,
        kode_kec: 3374050,
        kode_kel: 3374030040,
        kec: "Semarang Selatan",
        kel: "Wonodri",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.431980248867518, -6.994273934778962],
            [110.431983752885074, -6.994274866447524],
            [110.431970991084413, -6.995443146536858],
            [110.432054362451794, -6.997623220231931],
            [110.432196173074516, -6.998735991662107],
            [110.43245307910226, -6.999423761928637],
            [110.432967275423422, -7.000482709022522],
            [110.433252965439195, -7.00099864855645],
            [110.433223801280263, -7.001487072130245],
            [110.433079651132928, -7.002310041771321],
            [110.43270615037153, -7.003286508730614],
            [110.432390222742214, -7.00414545284653],
            [110.432055977300635, -7.004687991488091],
            [110.432017277044849, -7.004660597676202],
            [110.431934392538352, -7.004651452024732],
            [110.431690111044219, -7.004711133469766],
            [110.43152309882268, -7.004763675756017],
            [110.430981149168517, -7.004973389147892],
            [110.43071698723864, -7.004984544064425],
            [110.430297842265418, -7.004945787525572],
            [110.429683786497066, -7.004865820345747],
            [110.429255523159455, -7.004801982043858],
            [110.428637465011434, -7.004701730687144],
            [110.427800744353902, -7.004538460221465],
            [110.427798451922996, -7.004538012894082],
            [110.427369859528326, -7.004439258249155],
            [110.427098165231641, -7.004349042480331],
            [110.426860946077284, -7.004241656592383],
            [110.426635237400433, -7.004113247751995],
            [110.426366389078638, -7.003839641245531],
            [110.426242897252166, -7.003721898838071],
            [110.425920834400586, -7.003341593985501],
            [110.425612538110116, -7.003008520150933],
            [110.425142260373264, -7.002524097560512],
            [110.424700765424078, -7.001982336487993],
            [110.424287067004315, -7.001470877074989],
            [110.424370627587379, -7.001349670190689],
            [110.424488521400065, -7.001177964717118],
            [110.424797167004741, -7.000961249735953],
            [110.42520117748154, -7.000726560007481],
            [110.425448024899467, -7.000609269762347],
            [110.426210773230011, -7.000375017043244],
            [110.42660896964648, -7.000266956218693],
            [110.427337840369972, -7.00020452523041],
            [110.427999343765663, -7.000169147777518],
            [110.427974869166221, -6.999590204481947],
            [110.427970036930617, -6.998911784283638],
            [110.427976477200531, -6.998269559866608],
            [110.428022220572672, -6.997645474243388],
            [110.428101935554125, -6.99700333881302],
            [110.428203354386355, -6.996659732045093],
            [110.428321696065282, -6.996126189742563],
            [110.428366737580859, -6.995845833123187],
            [110.428075196193063, -6.995727887219556],
            [110.427772654356374, -6.99558279120065],
            [110.427593103137667, -6.995501163217567],
            [110.427683323080103, -6.995293225810494],
            [110.427764989455284, -6.995139551136235],
            [110.428188597801707, -6.994371196023762],
            [110.428490898096683, -6.993783603745721],
            [110.428640316258011, -6.993468974309347],
            [110.428855846128712, -6.993015132706847],
            [110.42953370711858, -6.993336015583258],
            [110.430711872564189, -6.993931022252731],
            [110.431184368707065, -6.994139611529006],
            [110.431980248867518, -6.994273934778962],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 6,
        id: 7,
        area: 619430.82275399996,
        perimeter: 3703.245005,
        kode_pos: 50122,
        kode_kec: 3374110,
        kode_kel: 3374050028,
        kec: "Semarang Timur",
        kel: "Mlatibaru",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.430959514320634, -6.967138443894777],
            [110.430959168548881, -6.967102471796459],
            [110.432299174564918, -6.96633500131752],
            [110.433388227154381, -6.96571216520246],
            [110.434614633217379, -6.964962852924581],
            [110.435488331441135, -6.964394027210953],
            [110.437719143453393, -6.963057943187611],
            [110.439288662229004, -6.962091932006194],
            [110.439471279423103, -6.961965510649096],
            [110.439670075151568, -6.961793880594904],
            [110.439731885586014, -6.961676361569719],
            [110.439738846716239, -6.961531640909612],
            [110.439745829296399, -6.961368829160365],
            [110.439746022413445, -6.961206009369397],
            [110.439739383160372, -6.961079363713336],
            [110.439712049748451, -6.960988875777206],
            [110.439683051171301, -6.960871249197799],
            [110.439649531965415, -6.960807890603369],
            [110.439751095851364, -6.960744691981938],
            [110.440000170928997, -6.960636440165834],
            [110.440438576673714, -6.960465093250644],
            [110.440956485701875, -6.960284947174841],
            [110.440940263051942, -6.960845599757508],
            [110.44088709086661, -6.96203050506337],
            [110.44051713541694, -6.964119591351863],
            [110.440299504408898, -6.964906297224079],
            [110.440111705465, -6.965584491574293],
            [110.439584232976728, -6.967619116401367],
            [110.439129396588939, -6.968993500982421],
            [110.439017590406223, -6.969282825958212],
            [110.438889791123145, -6.969698769633759],
            [110.438813945065547, -6.969961000573166],
            [110.438732659254299, -6.970277498339604],
            [110.438709289482702, -6.970422053263158],
            [110.438219479954256, -6.970276888469711],
            [110.437672818989995, -6.970122463844156],
            [110.437076085301911, -6.969967978963018],
            [110.436423849846861, -6.969858654907361],
            [110.436104783107581, -6.969822092060884],
            [110.435646845559248, -6.969758226525228],
            [110.435063856081953, -6.969703256613818],
            [110.434356054242315, -6.969693364332705],
            [110.433946688695642, -6.969702122531434],
            [110.433550881066012, -6.969728582001101],
            [110.432273854646994, -6.969745140528486],
            [110.431871561873066, -6.969753702563174],
            [110.431455113279895, -6.969771292763088],
            [110.431041786242247, -6.969798040235338],
            [110.430959514320634, -6.967138443894777],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 7,
        id: 9,
        area: 383206.61648500001,
        perimeter: 2600.413877,
        kode_pos: 50131,
        kode_kec: 3374130,
        kode_kel: 3374070020,
        kec: "Semarang Tengah",
        kel: "Pindrikan Kidul",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.402272656538372, -6.981222542028311],
            [110.40285777437586, -6.980745034358081],
            [110.404143314544129, -6.979796233933242],
            [110.404971377921512, -6.979345004077542],
            [110.405901736560068, -6.978984356390393],
            [110.406753604576309, -6.978723108573362],
            [110.407014226331924, -6.978669163120007],
            [110.408322121017207, -6.978417530620631],
            [110.408629138527672, -6.978363642407952],
            [110.409229842941343, -6.978282985188341],
            [110.409529470835466, -6.978256223634249],
            [110.409946491974551, -6.978238654170266],
            [110.410465041212873, -6.978248347679108],
            [110.410846280922854, -6.978339278786698],
            [110.411653570708054, -6.978566423510747],
            [110.411140478763599, -6.97971456280946],
            [110.409872081012523, -6.982535175138184],
            [110.40945533614682, -6.983457294715029],
            [110.408550641066569, -6.984088965791624],
            [110.406840427455307, -6.983327543278868],
            [110.406657101770307, -6.983246047114641],
            [110.402272656538372, -6.981222542028311],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 8,
        id: 10,
        area: 1136536.6536020001,
        perimeter: 7033.935654,
        kode_pos: 50149,
        kode_kec: 3374140,
        kode_kel: 3374080008,
        kec: "Semarang Barat",
        kel: "Gisikdrono",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.388425752435793, -6.973126953800227],
            [110.388327558644434, -6.973322443977866],
            [110.388295666889235, -6.973565346780466],
            [110.388215079560894, -6.974833599882228],
            [110.388195629899258, -6.975221654197226],
            [110.388211164379101, -6.975417291640193],
            [110.389009246737359, -6.979478379898747],
            [110.389386131453463, -6.981179416107614],
            [110.389779309615051, -6.982970927905057],
            [110.38983575316054, -6.983197137672001],
            [110.390039683785403, -6.983984357337648],
            [110.390072607899029, -6.984120081968141],
            [110.390160798999688, -6.985051879406934],
            [110.390220324651054, -6.986273094763677],
            [110.390208223143134, -6.986879125537829],
            [110.389999530206083, -6.987575356601011],
            [110.38996187587901, -6.987701944424402],
            [110.389898022362473, -6.987855634734583],
            [110.389849775628676, -6.987973163363908],
            [110.389760249187276, -6.988289638829873],
            [110.3896427629507, -6.988786987400963],
            [110.389621675206257, -6.988904551163102],
            [110.389563491283496, -6.989049203273099],
            [110.389499920328561, -6.989202893859947],
            [110.389398605126487, -6.989446990194432],
            [110.389253003422667, -6.989808620158581],
            [110.38907141332669, -6.990215430721093],
            [110.388922133274448, -6.990577055759871],
            [110.388787894177653, -6.99090251837939],
            [110.388704177336237, -6.991101409991326],
            [110.388580122652868, -6.991426885734039],
            [110.38844221697174, -6.991743297979274],
            [110.388370370253568, -6.991951250373889],
            [110.388223835737307, -6.992376196882107],
            [110.388142959220616, -6.99256604656486],
            [110.38807349321506, -6.992683547345369],
            [110.388027261131072, -6.992773941910986],
            [110.38799825123526, -6.992891495237587],
            [110.387966942717, -6.993036181962418],
            [110.38791185792094, -6.993189883246167],
            [110.387874384198824, -6.993289334633137],
            [110.387901779416595, -6.993325552088992],
            [110.387950404706913, -6.993352751671003],
            [110.387893928859015, -6.993488360213919],
            [110.387851350933786, -6.99359685042391],
            [110.387820148308919, -6.993660128102544],
            [110.387769967093988, -6.993741472030954],
            [110.38765582459618, -6.994057914803622],
            [110.387565576092086, -6.994274888527902],
            [110.387511681559815, -6.994383363972915],
            [110.387734662854655, -6.99456456318686],
            [110.387973475586563, -6.994754828348212],
            [110.388093866329214, -6.994854484899547],
            [110.388234897894634, -6.994963213704634],
            [110.38836147749349, -6.995090014625314],
            [110.388527641267643, -6.99523495785167],
            [110.388820997853003, -6.995470520784909],
            [110.38953562442282, -6.996131766801007],
            [110.389690696617961, -6.996321922612703],
            [110.389834358479362, -6.996584427310713],
            [110.389909083719786, -6.996774478919053],
            [110.389924116080337, -6.996883043977578],
            [110.389992745905516, -6.997272087900697],
            [110.390014012453094, -6.997453024753343],
            [110.39003479647765, -6.997787733877233],
            [110.390018777186143, -6.997923395084127],
            [110.389965024685452, -6.998140416561794],
            [110.389782759771123, -6.998628635334035],
            [110.389606081360128, -6.998954042899262],
            [110.389536696865463, -6.999008250738165],
            [110.389467064772873, -6.999035271734219],
            [110.389242949791679, -6.999071162771908],
            [110.388618016162184, -6.999052260361994],
            [110.388415202127533, -6.999024860442798],
            [110.387966488739636, -6.99903332254029],
            [110.3877101922469, -6.999014898221553],
            [110.38758744296112, -6.998987602116395],
            [110.386905585380191, -6.998796759645321],
            [110.386558839379532, -6.99871489866152],
            [110.38641799560034, -6.998678533218873],
            [110.386228526147377, -6.998614967947934],
            [110.38602605454696, -6.998542340184953],
            [110.385868825534573, -6.998487862294621],
            [110.385614875628519, -6.998406121729784],
            [110.385379008614834, -6.998342495563787],
            [110.385131848814524, -6.998260763619092],
            [110.384898540020686, -6.998188095109981],
            [110.384716697831664, -6.998133584687363],
            [110.384502049604691, -6.998069985841868],
            [110.384300945716419, -6.998033540986595],
            [110.384063381642065, -6.997969911971521],
            [110.383684420421361, -6.99786087044037],
            [110.383506256406818, -6.997806364388594],
            [110.383169732607541, -6.997697237547824],
            [110.383259536575977, -6.997389950310323],
            [110.383300572794042, -6.99716386773651],
            [110.383290613490885, -6.996991991068805],
            [110.383234917352624, -6.996747690848307],
            [110.383184513488231, -6.99656671573383],
            [110.383114222693578, -6.996449032732185],
            [110.382976765112886, -6.996204625345777],
            [110.382808788821038, -6.995933041561527],
            [110.382678886986326, -6.995751962186517],
            [110.382556647627752, -6.995552801925667],
            [110.38259506644809, -6.99538098869788],
            [110.382610840441657, -6.995218191224339],
            [110.382664667090694, -6.994946898199456],
            [110.382779825986944, -6.994720912825657],
            [110.38294852618219, -6.994440724850449],
            [110.383125701621736, -6.994169593350872],
            [110.383299824159053, -6.993853230491577],
            [110.383629501452987, -6.993356162042064],
            [110.38393128691456, -6.992985693197739],
            [110.384224147691185, -6.992732803385246],
            [110.384535102548881, -6.992488982461253],
            [110.384912105208201, -6.992145747511773],
            [110.385258010925313, -6.991784380710121],
            [110.385345206160096, -6.991739267195399],
            [110.385341292717584, -6.991703080252776],
            [110.385341682850168, -6.991404580609689],
            [110.385325759617658, -6.990816604979418],
            [110.385316544973122, -6.989857774242375],
            [110.385310136363188, -6.98869994700675],
            [110.385310679971326, -6.988283856543667],
            [110.385304823714947, -6.987569257528737],
            [110.385273022689319, -6.986791306435382],
            [110.385259644041767, -6.986637516154378],
            [110.384143256233017, -6.985152603686949],
            [110.384067815166816, -6.98508014146848],
            [110.383849129303897, -6.985079855751374],
            [110.383657802255428, -6.985016287484085],
            [110.383535554473198, -6.984735718453333],
            [110.383563185787025, -6.984591027235322],
            [110.383687551071816, -6.984464553370535],
            [110.383864433561428, -6.98441051176286],
            [110.384090239074851, -6.98437462490601],
            [110.384241122073448, -6.984302458271108],
            [110.385509871181156, -6.98284779381214],
            [110.386306010642997, -6.98194428394495],
            [110.386922621600903, -6.981167175694886],
            [110.387239566364713, -6.980661041350009],
            [110.387362982066634, -6.980389837620423],
            [110.387479678522823, -6.980064352318609],
            [110.387637241358576, -6.979422328643762],
            [110.387679333499307, -6.979033428153985],
            [110.387698604542351, -6.978572134296756],
            [110.387690964612872, -6.978137941919251],
            [110.387645202728933, -6.977432336031253],
            [110.387526331812893, -6.975948724988948],
            [110.387527248548196, -6.975243179611661],
            [110.387570388566104, -6.974700507422511],
            [110.387770229997798, -6.973959038053974],
            [110.388026179671343, -6.973362368756334],
            [110.388450217540054, -6.972729735026092],
            [110.388425752435793, -6.973126953800227],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 9,
        id: 11,
        area: 1282858.8959659999,
        perimeter: 5835.647638,
        kode_pos: 50144,
        kode_kec: 3374140,
        kode_kel: 3374080038,
        kec: "Semarang Barat",
        kel: "Tawangmas",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.392998674817292, -6.973160906221898],
            [110.392815120362869, -6.973124321866106],
            [110.392142894848476, -6.973168684578902],
            [110.391709753668522, -6.973186217878596],
            [110.390714857629646, -6.97313970810411],
            [110.390459930941958, -6.973166515597527],
            [110.389499230881995, -6.973147183825586],
            [110.388425752435793, -6.973126953800227],
            [110.388450217540054, -6.972729735026092],
            [110.388547438853237, -6.972585133347332],
            [110.391196161799812, -6.970218639903407],
            [110.391474293215396, -6.969965724820685],
            [110.391646174822924, -6.969839309457448],
            [110.391907308670824, -6.969604463149504],
            [110.392152069231656, -6.969342459175771],
            [110.392452959446885, -6.968990072419652],
            [110.392614478084468, -6.968782234006399],
            [110.392755910964496, -6.968574369716729],
            [110.392932527531613, -6.968285141242026],
            [110.393135003698873, -6.967905491074078],
            [110.393255854477601, -6.967643327271863],
            [110.393375042621329, -6.967354024846168],
            [110.393557029364942, -6.966847711376526],
            [110.393608454158795, -6.966675913184842],
            [110.393712667569332, -6.966151408870789],
            [110.393757182489395, -6.965852965028],
            [110.393813846044964, -6.965346490640558],
            [110.393873938996919, -6.964812884171366],
            [110.393935740733014, -6.964270234377486],
            [110.393981370929922, -6.963763745764508],
            [110.394034158868124, -6.963411039528517],
            [110.39414128815524, -6.962597083178437],
            [110.394177519931716, -6.961918718229301],
            [110.394225002060111, -6.961511732209567],
            [110.394297044721398, -6.960923867907764],
            [110.394363158024149, -6.96032695049006],
            [110.394433572425115, -6.959684811107654],
            [110.394503726852221, -6.959024580375679],
            [110.394569758299454, -6.958490981139945],
            [110.394658056725234, -6.958020728759032],
            [110.394717505065287, -6.957767531143224],
            [110.394879308041951, -6.95733355461801],
            [110.395110406938713, -6.956908712043518],
            [110.395508502355781, -6.956411718664007],
            [110.39578434823666, -6.956167842581864],
            [110.396345847844302, -6.955752466285952],
            [110.396673946453916, -6.955571974664537],
            [110.396927824250341, -6.955472797691301],
            [110.397494466415324, -6.955238336271638],
            [110.398215099426565, -6.95495417324103],
            [110.398215108089914, -6.954954194302275],
            [110.398632943192538, -6.955969979692909],
            [110.399267211714061, -6.957879402655422],
            [110.399723482320354, -6.959881778974303],
            [110.40002243924458, -6.961423146706389],
            [110.400161164390127, -6.962763356215911],
            [110.400255084597021, -6.964631704555295],
            [110.400364295896537, -6.965832331534559],
            [110.400364296433281, -6.965832337435499],
            [110.400412396247745, -6.966361127231528],
            [110.400676181022945, -6.968571705227183],
            [110.400690808175156, -6.968700298810724],
            [110.400690808636895, -6.968700302875177],
            [110.400857949778256, -6.970171566572808],
            [110.400302948057217, -6.970194104048018],
            [110.400124428354658, -6.970202922929237],
            [110.399911100776606, -6.970220743027774],
            [110.399288657627878, -6.970274225036561],
            [110.398487763880027, -6.970282251624724],
            [110.397937482541991, -6.970317732717997],
            [110.397409422806675, -6.970526058625484],
            [110.396929315539737, -6.970639030511223],
            [110.396591407099066, -6.970670150312364],
            [110.396613276537991, -6.97085317539771],
            [110.396107992457488, -6.970899856663383],
            [110.396089204113551, -6.970776782848207],
            [110.395028073252391, -6.970901630197401],
            [110.395159504889833, -6.971829404481287],
            [110.39441414496892, -6.971948344187016],
            [110.394542061921968, -6.973027038525721],
            [110.392998674817292, -6.973160906221898],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 10,
        id: 12,
        area: 284246.46269000001,
        perimeter: 2205.646037,
        kode_pos: 50141,
        kode_kec: 3374140,
        kode_kel: 3374080017,
        kec: "Semarang Barat",
        kel: "Cabean",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.395342719286873, -6.981585089197559],
            [110.396169267162648, -6.981441420204668],
            [110.396985075891592, -6.981288690510909],
            [110.399124065400983, -6.980884372882414],
            [110.399744377838317, -6.980828970303093],
            [110.40045695683061, -6.981213520567407],
            [110.400744798749628, -6.981314364527458],
            [110.400941320543566, -6.981337450083255],
            [110.401087683870188, -6.981264560823354],
            [110.40122497493131, -6.981136853677471],
            [110.401444034843621, -6.981024208647666],
            [110.401877707700876, -6.981113148262547],
            [110.402035212386906, -6.983060231901635],
            [110.402089792972689, -6.983735161956712],
            [110.402153090996961, -6.984868095248974],
            [110.4021537672037, -6.984877615677767],
            [110.401071864466417, -6.984884955337294],
            [110.400500175159436, -6.985056093080781],
            [110.400077184230653, -6.985091737030757],
            [110.398883527934842, -6.984927398083459],
            [110.397923899023013, -6.984935219220511],
            [110.396566799077533, -6.985078212105395],
            [110.396534473476407, -6.984770624414195],
            [110.396501380484494, -6.984544445059381],
            [110.396419766916992, -6.984209657840352],
            [110.39634116102431, -6.983956283783027],
            [110.396041186836072, -6.983367943524733],
            [110.395655725428341, -6.982598584009295],
            [110.395540920005104, -6.982336117998845],
            [110.395368820319362, -6.981747941280043],
            [110.395342719286873, -6.981585089197559],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 11,
        id: 13,
        area: 469414.273239,
        perimeter: 2764.550944,
        kode_pos: 50149,
        kode_kec: 3374140,
        kode_kel: 3374080016,
        kec: "Semarang Barat",
        kel: "Salaman Mloyo",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.38983575316054, -6.983197137672001],
            [110.390684367776544, -6.982935916106658],
            [110.392055871814591, -6.982512548652881],
            [110.392383897933527, -6.982404425634758],
            [110.393012790975888, -6.98218814392664],
            [110.393554502471048, -6.98200793116097],
            [110.394240211721396, -6.981827902669562],
            [110.394857694196887, -6.981683967534205],
            [110.395312154053315, -6.981594095499061],
            [110.395342719286873, -6.981585089197559],
            [110.395368820319362, -6.981747941280043],
            [110.395540920005104, -6.982336117998845],
            [110.395655725428341, -6.982598584009295],
            [110.396041186836072, -6.983367943524733],
            [110.39634116102431, -6.983956283783027],
            [110.396419766916992, -6.984209657840352],
            [110.396501380484494, -6.984544445059381],
            [110.396534473476407, -6.984770624414195],
            [110.396566799077533, -6.985078212105395],
            [110.396590936361832, -6.985304379995276],
            [110.396605749480997, -6.985666218149164],
            [110.396604856567748, -6.986362718944727],
            [110.396543498074678, -6.986778732459186],
            [110.396329823684781, -6.987502097187242],
            [110.39613175062685, -6.987972208342171],
            [110.39598561188167, -6.988315749244254],
            [110.395706936536598, -6.988984757331513],
            [110.395658667823028, -6.989120377589421],
            [110.395623153202934, -6.989237923243123],
            [110.395610352595213, -6.989292179687072],
            [110.395569889036864, -6.989518264707097],
            [110.3955317235704, -6.98971721623654],
            [110.395495833040187, -6.989907062877739],
            [110.395264281747899, -6.989789236893762],
            [110.395136217102518, -6.98971670871265],
            [110.394986969245494, -6.989617016875019],
            [110.394633884015761, -6.989408517471865],
            [110.394405751480477, -6.98927254222171],
            [110.39420248007626, -6.989163735255553],
            [110.393768824998745, -6.988909904309932],
            [110.393207718881953, -6.988547363241855],
            [110.392360439561742, -6.988084952517069],
            [110.391933529591128, -6.987867310621222],
            [110.391858605870709, -6.987831032070465],
            [110.391737025822096, -6.987776602358295],
            [110.391632950977169, -6.987749331624526],
            [110.390923311988956, -6.987612733094995],
            [110.390429662247442, -6.987594004001767],
            [110.389999530206083, -6.987575356601011],
            [110.390208223143134, -6.986879125537829],
            [110.390220324651054, -6.986273094763677],
            [110.390160798999688, -6.985051879406934],
            [110.390072607899029, -6.984120081968141],
            [110.390039683785403, -6.983984357337648],
            [110.38983575316054, -6.983197137672001],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 12,
        id: 14,
        area: 657643.61520799994,
        perimeter: 3266.762723,
        kode_pos: 50149,
        kode_kec: 3374140,
        kode_kel: 3374080018,
        kec: "Semarang Barat",
        kel: "Karang Ayu",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.392998674817292, -6.973160906221898],
            [110.393040985219017, -6.973477385848083],
            [110.393223683240592, -6.973739939496942],
            [110.393534430097844, -6.974084066861597],
            [110.394164354031773, -6.974817559294412],
            [110.394323572477205, -6.975080082473869],
            [110.394388100907022, -6.975279165806741],
            [110.394553563644394, -6.976627154553193],
            [110.394705505815551, -6.977929898508512],
            [110.394773294154888, -6.978454623260358],
            [110.395148754379051, -6.980399882762539],
            [110.395342719286873, -6.981585089197559],
            [110.395312154053315, -6.981594095499061],
            [110.394857694196887, -6.981683967534205],
            [110.394240211721396, -6.981827902669562],
            [110.393554502471048, -6.98200793116097],
            [110.393012790975888, -6.98218814392664],
            [110.392383897933527, -6.982404425634758],
            [110.392055871814591, -6.982512548652881],
            [110.390684367776544, -6.982935916106658],
            [110.38983575316054, -6.983197137672001],
            [110.389779309615051, -6.982970927905057],
            [110.389386131453463, -6.981179416107614],
            [110.389009246737359, -6.979478379898747],
            [110.388211164379101, -6.975417291640193],
            [110.388195629899258, -6.975221654197226],
            [110.388215079560894, -6.974833599882228],
            [110.388295666889235, -6.973565346780466],
            [110.388327558644434, -6.973322443977866],
            [110.388425752435793, -6.973126953800227],
            [110.389499230881995, -6.973147183825586],
            [110.390459930941958, -6.973166515597527],
            [110.390714857629646, -6.97313970810411],
            [110.391709753668522, -6.973186217878596],
            [110.392142894848476, -6.973168684578902],
            [110.392815120362869, -6.973124321866106],
            [110.392998674817292, -6.973160906221898],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 13,
        id: 15,
        area: 982239.74352699996,
        perimeter: 4383.563881,
        kode_pos: 50145,
        kode_kec: 3374140,
        kode_kel: 3374080006,
        kec: "Semarang Barat",
        kel: "Kalibanteng Kulon",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.383687551071816, -6.984464553370535],
            [110.383563185787025, -6.984591027235322],
            [110.383535554473198, -6.984735718453333],
            [110.383657802255428, -6.985016287484085],
            [110.382716557969502, -6.987312602240507],
            [110.382512829532828, -6.988632971951045],
            [110.382417802052558, -6.989256983848188],
            [110.382353470853516, -6.989772490467669],
            [110.382324906862806, -6.989980498485651],
            [110.382257643788321, -6.990143228489666],
            [110.38212235193717, -6.990405369269673],
            [110.381874402851608, -6.990712589474537],
            [110.381676364307538, -6.990929420491796],
            [110.381432259015526, -6.991110009113652],
            [110.381170258863818, -6.991344846799412],
            [110.380883255277027, -6.991661060520199],
            [110.380628762049398, -6.99199540767822],
            [110.380444736765469, -6.99231175645349],
            [110.380349217454977, -6.992447312565103],
            [110.380243218506806, -6.992591900313294],
            [110.380176025826472, -6.992700357299388],
            [110.380126644508209, -6.99281788316408],
            [110.380067289760206, -6.992989668592896],
            [110.380037354265525, -6.993378583528063],
            [110.380042630788793, -6.993668044861204],
            [110.380048449267562, -6.993975597803688],
            [110.38004024148853, -6.994192677783776],
            [110.38002023494596, -6.994346424082375],
            [110.379968801421725, -6.994518219896784],
            [110.379881968188585, -6.99471710542987],
            [110.379842488429276, -6.99483464426998],
            [110.379812567953167, -6.994997422938792],
            [110.379800012450545, -6.995078815438879],
            [110.37980108442089, -6.995124044093875],
            [110.379821889549703, -6.995304980469617],
            [110.379793490025421, -6.995304943061513],
            [110.379642969271586, -6.995313790213908],
            [110.379485399616271, -6.995304537132017],
            [110.379353315874738, -6.99527722669786],
            [110.379267900562368, -6.995259023203011],
            [110.379220949240448, -6.995249915854964],
            [110.379187023784709, -6.995231780229846],
            [110.379140120258796, -6.995186491147279],
            [110.379064726356717, -6.995077846358135],
            [110.378932598828328, -6.994869626811464],
            [110.378719076766103, -6.99459798172216],
            [110.378499944566471, -6.994290147363198],
            [110.378401989862951, -6.994127200033643],
            [110.378205538756049, -6.993783213719688],
            [110.377974891701868, -6.993412045841989],
            [110.377813167270347, -6.993122377945271],
            [110.377651701992306, -6.992850801241001],
            [110.377536255354997, -6.992651648877782],
            [110.377429969696095, -6.992371099590652],
            [110.377317081418539, -6.992162905123909],
            [110.377196012828719, -6.991936608930711],
            [110.376988235082067, -6.991601652704401],
            [110.376878602975935, -6.991284917123536],
            [110.376812419063029, -6.991058693451164],
            [110.37676938575504, -6.990868682178418],
            [110.376743633857572, -6.990660602874105],
            [110.376717965877504, -6.990389205563098],
            [110.376683419887868, -6.990199205513005],
            [110.376628305548152, -6.989945860133608],
            [110.376476043668831, -6.989348659294635],
            [110.376429910602269, -6.989149598456137],
            [110.376433840171899, -6.988959649305518],
            [110.376325638114423, -6.986924280883931],
            [110.376243820789114, -6.986327173201945],
            [110.376240744790664, -6.986300032794137],
            [110.375420385374525, -6.986461594258027],
            [110.375801100351467, -6.985666269714511],
            [110.375901138761719, -6.985322675210747],
            [110.375758615423123, -6.984209896711228],
            [110.375690311372793, -6.983449988826281],
            [110.375722982673793, -6.982066078767999],
            [110.375828531968196, -6.981405900811343],
            [110.376051986581786, -6.98100819671305],
            [110.376126490429982, -6.980718840910097],
            [110.376106648366488, -6.980321000145337],
            [110.376528515527156, -6.980276145360956],
            [110.378002662609433, -6.980106227402303],
            [110.378102554619346, -6.979871177333863],
            [110.378440729863044, -6.97979021385023],
            [110.378954411500814, -6.979628072060089],
            [110.37957652742341, -6.979402884122483],
            [110.380288500001811, -6.980118280580626],
            [110.383687551071816, -6.984464553370535],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 14,
        id: 16,
        area: 443816.24449900002,
        perimeter: 3430.475529,
        kode_pos: 50149,
        kode_kec: 3374140,
        kode_kel: 3374080007,
        kec: "Semarang Barat",
        kel: "Kalibanteng Kidul",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.383657802255428, -6.985016287484085],
            [110.383849129303897, -6.985079855751374],
            [110.384067815166816, -6.98508014146848],
            [110.384143256233017, -6.985152603686949],
            [110.385259644041767, -6.986637516154378],
            [110.385273022689319, -6.986791306435382],
            [110.385304823714947, -6.987569257528737],
            [110.385310679971326, -6.988283856543667],
            [110.385310136363188, -6.98869994700675],
            [110.385316544973122, -6.989857774242375],
            [110.385325759617658, -6.990816604979418],
            [110.385341682850168, -6.991404580609689],
            [110.385341292717584, -6.991703080252776],
            [110.385345206160096, -6.991739267195399],
            [110.385258010925313, -6.991784380710121],
            [110.384912105208201, -6.992145747511773],
            [110.384535102548881, -6.992488982461253],
            [110.384224147691185, -6.992732803385246],
            [110.38393128691456, -6.992985693197739],
            [110.383629501452987, -6.993356162042064],
            [110.383299824159053, -6.993853230491577],
            [110.383125701621736, -6.994169593350872],
            [110.38294852618219, -6.994440724850449],
            [110.382779825986944, -6.994720912825657],
            [110.382664667090694, -6.994946898199456],
            [110.382610840441657, -6.995218191224339],
            [110.38259506644809, -6.99538098869788],
            [110.382556647627752, -6.995552801925667],
            [110.382678886986326, -6.995751962186517],
            [110.382808788821038, -6.995933041561527],
            [110.382976765112886, -6.996204625345777],
            [110.383114222693578, -6.996449032732185],
            [110.383184513488231, -6.99656671573383],
            [110.383234917352624, -6.996747690848307],
            [110.383290613490885, -6.996991991068805],
            [110.383300572794042, -6.99716386773651],
            [110.383259536575977, -6.997389950310323],
            [110.383169732607541, -6.997697237547824],
            [110.383107786366836, -6.997688251408823],
            [110.383020378151343, -6.997679091387308],
            [110.382953634461316, -6.997660912987804],
            [110.382727399209827, -6.997588252728082],
            [110.381721223878799, -6.997261295894381],
            [110.381407604806299, -6.997161383894613],
            [110.38121218372882, -6.997106854339894],
            [110.38111461466579, -6.997079589719538],
            [110.380132402388739, -6.997033070079717],
            [110.379998970916631, -6.997032894353199],
            [110.380007426727786, -6.996978632801093],
            [110.379995387840296, -6.996453980942279],
            [110.379982764202396, -6.996372555280812],
            [110.37991942281883, -6.99592019943355],
            [110.379821889549703, -6.995304980469617],
            [110.37980108442089, -6.995124044093875],
            [110.379800012450545, -6.995078815438879],
            [110.379812567953167, -6.994997422938792],
            [110.379842488429276, -6.99483464426998],
            [110.379881968188585, -6.99471710542987],
            [110.379968801421725, -6.994518219896784],
            [110.38002023494596, -6.994346424082375],
            [110.38004024148853, -6.994192677783776],
            [110.380048449267562, -6.993975597803688],
            [110.380042630788793, -6.993668044861204],
            [110.380037354265525, -6.993378583528063],
            [110.380067289760206, -6.992989668592896],
            [110.380126644508209, -6.99281788316408],
            [110.380176025826472, -6.992700357299388],
            [110.380243218506806, -6.992591900313294],
            [110.380349217454977, -6.992447312565103],
            [110.380444736765469, -6.99231175645349],
            [110.380628762049398, -6.99199540767822],
            [110.380883255277027, -6.991661060520199],
            [110.381170258863818, -6.991344846799412],
            [110.381432259015526, -6.991110009113652],
            [110.381676364307538, -6.990929420491796],
            [110.381874402851608, -6.990712589474537],
            [110.38212235193717, -6.990405369269673],
            [110.382257643788321, -6.990143228489666],
            [110.382324906862806, -6.989980498485651],
            [110.382353470853516, -6.989772490467669],
            [110.382417802052558, -6.989256983848188],
            [110.382512829532828, -6.988632971951045],
            [110.382716557969502, -6.987312602240507],
            [110.383657802255428, -6.985016287484085],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 15,
        id: 17,
        area: 1014250.177301,
        perimeter: 4191.941087,
        kode_pos: 50148,
        kode_kec: 3374140,
        kode_kel: 3374080009,
        kec: "Semarang Barat",
        kel: "Bongsari",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.389924116080337, -6.996883043977578],
            [110.389909083719786, -6.996774478919053],
            [110.389834358479362, -6.996584427310713],
            [110.389690696617961, -6.996321922612703],
            [110.38953562442282, -6.996131766801007],
            [110.388820997853003, -6.995470520784909],
            [110.388527641267643, -6.99523495785167],
            [110.38836147749349, -6.995090014625314],
            [110.388234897894634, -6.994963213704634],
            [110.388093866329214, -6.994854484899547],
            [110.387973475586563, -6.994754828348212],
            [110.387734662854655, -6.99456456318686],
            [110.387511681559815, -6.994383363972915],
            [110.387565576092086, -6.994274888527902],
            [110.38765582459618, -6.994057914803622],
            [110.387769967093988, -6.993741472030954],
            [110.387820148308919, -6.993660128102544],
            [110.387851350933786, -6.99359685042391],
            [110.387893928859015, -6.993488360213919],
            [110.387950404706913, -6.993352751671003],
            [110.387901779416595, -6.993325552088992],
            [110.387874384198824, -6.993289334633137],
            [110.38791185792094, -6.993189883246167],
            [110.387966942717, -6.993036181962418],
            [110.38799825123526, -6.992891495237587],
            [110.388027261131072, -6.992773941910986],
            [110.38807349321506, -6.992683547345369],
            [110.388142959220616, -6.99256604656486],
            [110.388223835737307, -6.992376196882107],
            [110.388370370253568, -6.991951250373889],
            [110.38844221697174, -6.991743297979274],
            [110.388580122652868, -6.991426885734039],
            [110.388704177336237, -6.991101409991326],
            [110.388787894177653, -6.99090251837939],
            [110.388922133274448, -6.990577055759871],
            [110.38907141332669, -6.990215430721093],
            [110.389253003422667, -6.989808620158581],
            [110.389398605126487, -6.989446990194432],
            [110.389499920328561, -6.989202893859947],
            [110.389563491283496, -6.989049203273099],
            [110.389621675206257, -6.988904551163102],
            [110.3896427629507, -6.988786987400963],
            [110.389760249187276, -6.988289638829873],
            [110.389849775628676, -6.987973163363908],
            [110.389898022362473, -6.987855634734583],
            [110.38996187587901, -6.987701944424402],
            [110.389999530206083, -6.987575356601011],
            [110.390429662247442, -6.987594004001767],
            [110.390923311988956, -6.987612733094995],
            [110.391632950977169, -6.987749331624526],
            [110.391737025822096, -6.987776602358295],
            [110.391858605870709, -6.987831032070465],
            [110.391933529591128, -6.987867310621222],
            [110.392360439561742, -6.988084952517069],
            [110.393207718881953, -6.988547363241855],
            [110.393768824998745, -6.988909904309932],
            [110.39420248007626, -6.989163735255553],
            [110.394405751480477, -6.98927254222171],
            [110.394633884015761, -6.989408517471865],
            [110.394986969245494, -6.989617016875019],
            [110.395136217102518, -6.98971670871265],
            [110.395264281747899, -6.989789236893762],
            [110.395495833040187, -6.989907062877739],
            [110.395599284640184, -6.989979621721742],
            [110.395898381598229, -6.990151869344979],
            [110.396608775161511, -6.990586962556613],
            [110.396936683287393, -6.990795428391703],
            [110.397501132171612, -6.991203197125716],
            [110.397656581951281, -6.991320987136267],
            [110.398206580558337, -6.991746827604876],
            [110.398442567551115, -6.99193708410758],
            [110.398859114784145, -6.992299435107103],
            [110.399006066365928, -6.992426288077904],
            [110.398949640012006, -6.992525687654607],
            [110.398939091381351, -6.99258899256104],
            [110.398942688283626, -6.992652315518033],
            [110.398940332497418, -6.992724676359642],
            [110.398921556319124, -6.992806061727072],
            [110.398884061759858, -6.992923605127036],
            [110.398709718248156, -6.993420884009515],
            [110.39861352210292, -6.993646898187022],
            [110.39855389086523, -6.993818686155221],
            [110.398509513654801, -6.994008584553991],
            [110.398473069502145, -6.994189447597875],
            [110.398445787778357, -6.994506004532572],
            [110.398426687295924, -6.994840662869287],
            [110.398413337624618, -6.995102964712339],
            [110.398442431274148, -6.995139183800043],
            [110.398527564697844, -6.995157383517424],
            [110.398647213421683, -6.995175627303116],
            [110.39912843558804, -6.995185287140027],
            [110.399190936074859, -6.995203457856712],
            [110.399245220549759, -6.995230663563196],
            [110.399294118137306, -6.995266907871873],
            [110.399313298376626, -6.995312159744818],
            [110.399307559210243, -6.995375470786841],
            [110.399216795278178, -6.995999493121013],
            [110.399131758690686, -6.996569249832696],
            [110.399104188894867, -6.996668714919353],
            [110.399044095633585, -6.996759093004688],
            [110.398992559156767, -6.996795209127928],
            [110.398834601007223, -6.996867371266036],
            [110.398766678679308, -6.996885375477482],
            [110.398698791069606, -6.996876243286041],
            [110.398380877637692, -6.996812518737862],
            [110.398141590996232, -6.996766985471231],
            [110.398065498941804, -6.996757842701184],
            [110.397983713865173, -6.99677582907218],
            [110.397951167263301, -6.996784832926143],
            [110.397842354749571, -6.996920375913574],
            [110.397782249630978, -6.997019799276012],
            [110.397689152624707, -6.997254862573182],
            [110.397571903490558, -6.997571304223166],
            [110.397376938576542, -6.998041419428679],
            [110.397216391538436, -6.998366850979143],
            [110.397028606245968, -6.99831233757538],
            [110.396792206248918, -6.998221579893507],
            [110.396696922316792, -6.99817623038633],
            [110.396335555886807, -6.998021993959795],
            [110.396188793359741, -6.997967532853594],
            [110.395889916317259, -6.997840512694682],
            [110.395556818540243, -6.997704402940647],
            [110.395052604894062, -6.997531891182008],
            [110.39474126864819, -6.997413899800213],
            [110.394463010115814, -6.99731404172264],
            [110.394233389467729, -6.997232337028565],
            [110.394209613094461, -6.997241351901351],
            [110.394075929388038, -6.997575862273336],
            [110.394044161427971, -6.997639139675098],
            [110.394026717057713, -6.9977838447545],
            [110.394002917320293, -6.997810950525889],
            [110.393982241296158, -6.997829014846811],
            [110.393966374801579, -6.997847085359422],
            [110.393874489089725, -6.998018830983403],
            [110.393715707255708, -6.998289990586684],
            [110.39367859884868, -6.998326124663131],
            [110.392804638190199, -6.997917951833342],
            [110.390752707572531, -6.997010753633573],
            [110.390625502326486, -6.996929179786128],
            [110.3905799533551, -6.996929120819604],
            [110.390424893640528, -6.996947010981234],
            [110.390402896840584, -6.996892709703077],
            [110.390092836124325, -6.996883262609799],
            [110.389924116080337, -6.996883043977578],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 16,
        id: 18,
        area: 1827530.405669,
        perimeter: 7340.595101,
        kode_pos: 50147,
        kode_kec: 3374140,
        kode_kel: 3374080003,
        kec: "Semarang Barat",
        kel: "Manyaran",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.383169732607541, -6.997697237547824],
            [110.383506256406818, -6.997806364388594],
            [110.383684420421361, -6.99786087044037],
            [110.384063381642065, -6.997969911971521],
            [110.384300945716419, -6.998033540986595],
            [110.384502049604691, -6.998069985841868],
            [110.384716697831664, -6.998133584687363],
            [110.384898540020686, -6.998188095109981],
            [110.385131848814524, -6.998260763619092],
            [110.385379008614834, -6.998342495563787],
            [110.385614875628519, -6.998406121729784],
            [110.385868825534573, -6.998487862294621],
            [110.38602605454696, -6.998542340184953],
            [110.386228526147377, -6.998614967947934],
            [110.38641799560034, -6.998678533218873],
            [110.386558839379532, -6.99871489866152],
            [110.386905585380191, -6.998796759645321],
            [110.38758744296112, -6.998987602116395],
            [110.3877101922469, -6.999014898221553],
            [110.387966488739636, -6.99903332254029],
            [110.388415202127533, -6.999024860442798],
            [110.388618016162184, -6.999052260361994],
            [110.389242949791679, -6.999071162771908],
            [110.389467064772873, -6.999035271734219],
            [110.389536696865463, -6.999008250738165],
            [110.389696638239926, -6.999153160545053],
            [110.39000763341852, -6.999533473256447],
            [110.390398894678484, -6.99997720804475],
            [110.390506509118055, -7.000113029420119],
            [110.39069607505823, -7.000321320625236],
            [110.390733066511672, -7.000375641315596],
            [110.390877649158057, -7.000583874209124],
            [110.391093056010476, -7.000936926177188],
            [110.391250016907648, -7.001199447792047],
            [110.391737003379845, -7.00200512414976],
            [110.39187226242008, -7.00220429928136],
            [110.392343854389338, -7.002883318722056],
            [110.392389899551318, -7.00293765100659],
            [110.392423826130468, -7.002955785766848],
            [110.393019000815954, -7.003019872505815],
            [110.393711770320763, -7.003093129791201],
            [110.394892431772917, -7.003275559685821],
            [110.395003618121322, -7.00327570272538],
            [110.395207058695036, -7.003257873438749],
            [110.395453878727892, -7.003167736054206],
            [110.395647297253404, -7.003023257085636],
            [110.395860306750052, -7.002824530358317],
            [110.396169104727775, -7.002499289879589],
            [110.396522013973978, -7.002192196698667],
            [110.396869406992636, -7.0018222766809],
            [110.396929231054287, -7.001894218311282],
            [110.39701030734588, -7.001979498079296],
            [110.397158400314396, -7.00209925302245],
            [110.397341117977518, -7.002171187065979],
            [110.397131686277604, -7.002474099125323],
            [110.395519607149026, -7.004808359597402],
            [110.39521074458915, -7.005262640414442],
            [110.394979191798896, -7.005530015823759],
            [110.394652238488362, -7.005782601239217],
            [110.394413487126101, -7.005939964330708],
            [110.394170249271639, -7.0060610663995],
            [110.39396549996718, -7.006133725179405],
            [110.393638758921568, -7.006221306027063],
            [110.393312605794577, -7.006201408389486],
            [110.39303518113644, -7.006162998997426],
            [110.392589138205722, -7.006091756223891],
            [110.391974561723458, -7.005922448930778],
            [110.391446923487464, -7.005840227777608],
            [110.390913788392439, -7.005801486106213],
            [110.390141172741735, -7.005833099911886],
            [110.389831118918067, -7.005783774549406],
            [110.389569882597641, -7.005848666192671],
            [110.389401116167718, -7.005929985477485],
            [110.389275726394928, -7.006125514858394],
            [110.389112060827657, -7.006467763639665],
            [110.388910062571654, -7.007000218842644],
            [110.388669993251966, -7.007521752604045],
            [110.388400285095059, -7.008004769356622],
            [110.386772389326737, -7.0107699445235],
            [110.38669043744558, -7.011030760372332],
            [110.386673766950906, -7.011297097320353],
            [110.386765192427106, -7.0116240850369],
            [110.386873988684314, -7.01191510624403],
            [110.387157144671747, -7.012453602273659],
            [110.387396478471572, -7.013108392703487],
            [110.38757021160643, -7.013835817308389],
            [110.387620445650199, -7.014374009349284],
            [110.387604849490458, -7.015064827107924],
            [110.386801396632421, -7.014468704806839],
            [110.385684434242052, -7.01362409538754],
            [110.384819959857182, -7.012976690772343],
            [110.383399150515217, -7.011909119396424],
            [110.382761484794514, -7.0114417671276],
            [110.382367785442909, -7.011133093121238],
            [110.381931311204767, -7.010777283083137],
            [110.381580529413043, -7.010408745634311],
            [110.380981681090333, -7.009744564574993],
            [110.380459862433881, -7.00914040375532],
            [110.379322100551875, -7.007846357382616],
            [110.378988452158794, -7.007482120894349],
            [110.378748921227583, -7.007212167510069],
            [110.378450872651968, -7.006891842590366],
            [110.379061166264577, -7.006330375546884],
            [110.379441519745853, -7.006032378130234],
            [110.379959314839923, -7.005562698187239],
            [110.380088882923602, -7.005354823802407],
            [110.380356724842173, -7.00455917763636],
            [110.380421739658431, -7.004387399829399],
            [110.38060047853142, -7.004007726545399],
            [110.380713907685305, -7.003808876103156],
            [110.380754129551917, -7.00377274728003],
            [110.380867367713648, -7.003718623691071],
            [110.38120705820856, -7.003574343610201],
            [110.381499903571083, -7.003339547177901],
            [110.3815925720709, -7.003222078206085],
            [110.381611905682249, -7.003149740038493],
            [110.381587729779667, -7.003032117419722],
            [110.381523332155666, -7.002950623705773],
            [110.381458592055779, -7.00291435677634],
            [110.381385906484113, -7.002896170285174],
            [110.381304992517698, -7.002896063855825],
            [110.381136362683179, -7.002904887453459],
            [110.381043873013624, -7.002886674852324],
            [110.380912129599707, -7.002814137882129],
            [110.380711768913855, -7.002642010618108],
            [110.380545995687115, -7.002415656149416],
            [110.380400616402028, -7.002171237603847],
            [110.380263549244347, -7.001845420953682],
            [110.380247518576326, -7.001773036259413],
            [110.380231511788196, -7.001682560702618],
            [110.380231905730952, -7.001384061474432],
            [110.380305175859846, -7.000959021981112],
            [110.380394750939502, -7.000398322216597],
            [110.380377517026247, -7.000380208625806],
            [110.380248826782349, -7.000352902789579],
            [110.379996032677781, -7.000253069824102],
            [110.379966362497811, -7.000225894385337],
            [110.3799431874085, -7.000207772951304],
            [110.379601829368255, -7.000116868513651],
            [110.379555455336359, -7.000098716473028],
            [110.379277282947513, -6.999935531558926],
            [110.379180339062557, -6.999863040095495],
            [110.378891041762699, -6.999555113171111],
            [110.378726920059208, -6.9993649421632],
            [110.378710017016402, -6.999310647175522],
            [110.37869741749769, -6.999211130643435],
            [110.378678534067362, -6.999156833040386],
            [110.378588276921349, -6.998948668626658],
            [110.37855449482413, -6.998821987785903],
            [110.378550629759431, -6.998749619121047],
            [110.378556937607826, -6.99868630933454],
            [110.378563281348036, -6.998595863259409],
            [110.378601323323508, -6.998496413592565],
            [110.378645836394355, -6.998424108800589],
            [110.378745047362841, -6.998279512644015],
            [110.378801996602064, -6.998216269694265],
            [110.378923792682556, -6.998107885083342],
            [110.379232335122083, -6.997981655884561],
            [110.379418822510388, -6.997945720023015],
            [110.379548691469097, -6.997936845793117],
            [110.379702902875763, -6.997918958161494],
            [110.379775942160123, -6.997882872625303],
            [110.379808548776253, -6.997828642906172],
            [110.379849303772588, -6.997602560413815],
            [110.37987900125583, -6.997394554240649],
            [110.379921912036664, -6.997249883603775],
            [110.379943261766684, -6.997150411799323],
            [110.379998970916631, -6.997032894353199],
            [110.380132402388739, -6.997033070079717],
            [110.38111461466579, -6.997079589719538],
            [110.38121218372882, -6.997106854339894],
            [110.381407604806299, -6.997161383894613],
            [110.381721223878799, -6.997261295894381],
            [110.382727399209827, -6.997588252728082],
            [110.382953634461316, -6.997660912987804],
            [110.383020378151343, -6.997679091387308],
            [110.383107786366836, -6.997688251408823],
            [110.383169732607541, -6.997697237547824],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 17,
        id: 19,
        area: 810436.45169100002,
        perimeter: 5072.688149,
        kode_pos: 50148,
        kode_kec: 3374140,
        kode_kel: 3374080002,
        kec: "Semarang Barat",
        kel: "Ngemplak Simongan",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.389536696865463, -6.999008250738165],
            [110.389606081360128, -6.998954042899262],
            [110.389782759771123, -6.998628635334035],
            [110.389965024685452, -6.998140416561794],
            [110.390018777186143, -6.997923395084127],
            [110.39003479647765, -6.997787733877233],
            [110.390014012453094, -6.997453024753343],
            [110.389992745905516, -6.997272087900697],
            [110.389924116080337, -6.996883043977578],
            [110.390092836124325, -6.996883262609799],
            [110.390402896840584, -6.996892709703077],
            [110.390424893640528, -6.996947010981234],
            [110.3905799533551, -6.996929120819604],
            [110.390625502326486, -6.996929179786128],
            [110.390752707572531, -6.997010753633573],
            [110.392804638190199, -6.997917951833342],
            [110.39367859884868, -6.998326124663131],
            [110.393715707255708, -6.998289990586684],
            [110.393874489089725, -6.998018830983403],
            [110.393966374801579, -6.997847085359422],
            [110.393982241296158, -6.997829014846811],
            [110.394002917320293, -6.997810950525889],
            [110.394026717057713, -6.9977838447545],
            [110.394044161427971, -6.997639139675098],
            [110.394075929388038, -6.997575862273336],
            [110.394209613094461, -6.997241351901351],
            [110.394233389467729, -6.997232337028565],
            [110.394463010115814, -6.99731404172264],
            [110.39474126864819, -6.997413899800213],
            [110.395052604894062, -6.997531891182008],
            [110.395556818540243, -6.997704402940647],
            [110.395889916317259, -6.997840512694682],
            [110.396188793359741, -6.997967532853594],
            [110.396335555886807, -6.998021993959795],
            [110.396696922316792, -6.99817623038633],
            [110.396792206248918, -6.998221579893507],
            [110.397028606245968, -6.99831233757538],
            [110.397216391538436, -6.998366850979143],
            [110.397376938576542, -6.998041419428679],
            [110.397571903490558, -6.997571304223166],
            [110.397689152624707, -6.997254862573182],
            [110.397782249630978, -6.997019799276012],
            [110.397842354749571, -6.996920375913574],
            [110.397951167263301, -6.996784832926143],
            [110.397983713865173, -6.99677582907218],
            [110.398065498941804, -6.996757842701184],
            [110.398141590996232, -6.996766985471231],
            [110.398380877637692, -6.996812518737862],
            [110.398698791069606, -6.996876243286041],
            [110.398766678679308, -6.996885375477482],
            [110.398834601007223, -6.996867371266036],
            [110.398992559156767, -6.996795209127928],
            [110.399044095633585, -6.996759093004688],
            [110.399104188894867, -6.996668714919353],
            [110.399131758690686, -6.996569249832696],
            [110.399216795278178, -6.995999493121013],
            [110.399307559210243, -6.995375470786841],
            [110.399313298376626, -6.995312159744818],
            [110.399294118137306, -6.995266907871873],
            [110.399245220549759, -6.995230663563196],
            [110.399190936074859, -6.995203457856712],
            [110.39912843558804, -6.995185287140027],
            [110.398647213421683, -6.995175627303116],
            [110.398527564697844, -6.995157383517424],
            [110.398442431274148, -6.995139183800043],
            [110.398413337624618, -6.995102964712339],
            [110.398426687295924, -6.994840662869287],
            [110.398445787778357, -6.994506004532572],
            [110.398473069502145, -6.994189447597875],
            [110.398509513654801, -6.994008584553991],
            [110.39855389086523, -6.993818686155221],
            [110.39861352210292, -6.993646898187022],
            [110.398709718248156, -6.993420884009515],
            [110.398884061759858, -6.992923605127036],
            [110.398921556319124, -6.992806061727072],
            [110.398940332497418, -6.992724676359642],
            [110.398942688283626, -6.992652315518033],
            [110.398939091381351, -6.99258899256104],
            [110.398949640012006, -6.992525687654607],
            [110.399006066365928, -6.992426288077904],
            [110.399129369190334, -6.992462598594914],
            [110.399351101799269, -6.992517154288634],
            [110.399793141096822, -6.992635309040833],
            [110.400249609103199, -6.992753481768974],
            [110.400607410636837, -6.992817255619472],
            [110.400942602460901, -6.992862909481381],
            [110.401237949993131, -6.992872330520725],
            [110.401431767048791, -6.992854485902262],
            [110.401553487846996, -6.992800367663608],
            [110.401681750100337, -6.992719121244472],
            [110.401784856259212, -6.99261975186631],
            [110.401887824306755, -6.99262892811546],
            [110.402016970125558, -6.992643500213854],
            [110.402376255213909, -6.992684039762572],
            [110.402371602744452, -6.993161391626511],
            [110.402468834389566, -6.993710542062439],
            [110.402446533353441, -6.99413451485869],
            [110.402385878823964, -6.994482485504386],
            [110.402292083942214, -6.994874808414673],
            [110.402029787964409, -6.995627084547146],
            [110.401041655518597, -6.998322331084882],
            [110.401010808870552, -6.99857047301699],
            [110.401084569539833, -6.9988231021371],
            [110.40157584758505, -6.999729370802692],
            [110.40164973071461, -6.999886210782164],
            [110.401666912385664, -7.000082165159313],
            [110.401614407827552, -7.000247552522601],
            [110.40151391730069, -7.000452065343381],
            [110.401374150806504, -7.000700068720707],
            [110.401199516026153, -7.000952381718978],
            [110.401016204275436, -7.001174205198213],
            [110.400833064555158, -7.001261053094176],
            [110.40042776460615, -7.001277952944759],
            [110.399904820082625, -7.001281640228195],
            [110.399377450954034, -7.001337569930701],
            [110.398892949972947, -7.001474941900399],
            [110.398370386373884, -7.001654129008362],
            [110.39786902975932, -7.001814587505575],
            [110.397589927706761, -7.00197097606506],
            [110.397341117977518, -7.002171187065979],
            [110.397158400314396, -7.00209925302245],
            [110.39701030734588, -7.001979498079296],
            [110.396929231054287, -7.001894218311282],
            [110.396869406992636, -7.0018222766809],
            [110.396522013973978, -7.002192196698667],
            [110.396169104727775, -7.002499289879589],
            [110.395860306750052, -7.002824530358317],
            [110.395647297253404, -7.003023257085636],
            [110.395453878727892, -7.003167736054206],
            [110.395207058695036, -7.003257873438749],
            [110.395003618121322, -7.00327570272538],
            [110.394892431772917, -7.003275559685821],
            [110.393711770320763, -7.003093129791201],
            [110.393019000815954, -7.003019872505815],
            [110.392423826130468, -7.002955785766848],
            [110.392389899551318, -7.00293765100659],
            [110.392343854389338, -7.002883318722056],
            [110.39187226242008, -7.00220429928136],
            [110.391737003379845, -7.00200512414976],
            [110.391250016907648, -7.001199447792047],
            [110.391093056010476, -7.000936926177188],
            [110.390877649158057, -7.000583874209124],
            [110.390733066511672, -7.000375641315596],
            [110.39069607505823, -7.000321320625236],
            [110.390506509118055, -7.000113029420119],
            [110.390398894678484, -6.99997720804475],
            [110.39000763341852, -6.999533473256447],
            [110.389696638239926, -6.999153160545053],
            [110.389536696865463, -6.999008250738165],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 18,
        id: 20,
        area: 537455.66100099997,
        perimeter: 2907.255911,
        kode_pos: 50141,
        kode_kec: 3374140,
        kode_kel: 3374080014,
        kec: "Semarang Barat",
        kel: "Bojong Salaman",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.395495833040187, -6.989907062877739],
            [110.3955317235704, -6.98971721623654],
            [110.395569889036864, -6.989518264707097],
            [110.395610352595213, -6.989292179687072],
            [110.395623153202934, -6.989237923243123],
            [110.395658667823028, -6.989120377589421],
            [110.395706936536598, -6.988984757331513],
            [110.39598561188167, -6.988315749244254],
            [110.39613175062685, -6.987972208342171],
            [110.396329823684781, -6.987502097187242],
            [110.396543498074678, -6.986778732459186],
            [110.396604856567748, -6.986362718944727],
            [110.396605749480997, -6.985666218149164],
            [110.396590936361832, -6.985304379995276],
            [110.396566799077533, -6.985078212105395],
            [110.397923899023013, -6.984935219220511],
            [110.398883527934842, -6.984927398083459],
            [110.400077184230653, -6.985091737030757],
            [110.400500175159436, -6.985056093080781],
            [110.401071864466417, -6.984884955337294],
            [110.4021537672037, -6.984877615677767],
            [110.402207366412611, -6.985632247384983],
            [110.402325133456358, -6.986915469535568],
            [110.402404928095223, -6.987702820820143],
            [110.402464742123925, -6.988367035263749],
            [110.402485786025139, -6.988932396862181],
            [110.402474456310344, -6.989285716813488],
            [110.402430311035161, -6.989774892953508],
            [110.402429806933966, -6.990171713913682],
            [110.402450254129732, -6.990875404468225],
            [110.402450657318624, -6.99088928076895],
            [110.402403008484313, -6.992593880206329],
            [110.402376255213909, -6.992684039762572],
            [110.402016970125558, -6.992643500213854],
            [110.401887824306755, -6.99262892811546],
            [110.401784856259212, -6.99261975186631],
            [110.401681750100337, -6.992719121244472],
            [110.401553487846996, -6.992800367663608],
            [110.401431767048791, -6.992854485902262],
            [110.401237949993131, -6.992872330520725],
            [110.400942602460901, -6.992862909481381],
            [110.400607410636837, -6.992817255619472],
            [110.400249609103199, -6.992753481768974],
            [110.399793141096822, -6.992635309040833],
            [110.399351101799269, -6.992517154288634],
            [110.399129369190334, -6.992462598594914],
            [110.399006066365928, -6.992426288077904],
            [110.398859114784145, -6.992299435107103],
            [110.398442567551115, -6.99193708410758],
            [110.398206580558337, -6.991746827604876],
            [110.397656581951281, -6.991320987136267],
            [110.397501132171612, -6.991203197125716],
            [110.396936683287393, -6.990795428391703],
            [110.396608775161511, -6.990586962556613],
            [110.395898381598229, -6.990151869344979],
            [110.395599284640184, -6.989979621721742],
            [110.395495833040187, -6.989907062877739],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 19,
        id: 21,
        area: 1890293.689758,
        perimeter: 6402.039445,
        kode_pos: 50183,
        kode_kec: 3374140,
        kode_kel: 3374080037,
        kec: "Semarang Barat",
        kel: "Kembang Arum",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.375420385374525, -6.986461594258027],
            [110.376240744790664, -6.986300032794137],
            [110.376243820789114, -6.986327173201945],
            [110.376325638114423, -6.986924280883931],
            [110.376433840171899, -6.988959649305518],
            [110.376429910602269, -6.989149598456137],
            [110.376476043668831, -6.989348659294635],
            [110.376628305548152, -6.989945860133608],
            [110.376683419887868, -6.990199205513005],
            [110.376717965877504, -6.990389205563098],
            [110.376743633857572, -6.990660602874105],
            [110.37676938575504, -6.990868682178418],
            [110.376812419063029, -6.991058693451164],
            [110.376878602975935, -6.991284917123536],
            [110.376988235082067, -6.991601652704401],
            [110.377196012828719, -6.991936608930711],
            [110.377317081418539, -6.992162905123909],
            [110.377429969696095, -6.992371099590652],
            [110.377536255354997, -6.992651648877782],
            [110.377651701992306, -6.992850801241001],
            [110.377813167270347, -6.993122377945271],
            [110.377974891701868, -6.993412045841989],
            [110.378205538756049, -6.993783213719688],
            [110.378401989862951, -6.994127200033643],
            [110.378499944566471, -6.994290147363198],
            [110.378719076766103, -6.99459798172216],
            [110.378932598828328, -6.994869626811464],
            [110.379064726356717, -6.995077846358135],
            [110.379140120258796, -6.995186491147279],
            [110.379187023784709, -6.995231780229846],
            [110.379220949240448, -6.995249915854964],
            [110.379267900562368, -6.995259023203011],
            [110.379353315874738, -6.99527722669786],
            [110.379485399616271, -6.995304537132017],
            [110.379642969271586, -6.995313790213908],
            [110.379793490025421, -6.995304943061513],
            [110.379821889549703, -6.995304980469617],
            [110.37991942281883, -6.99592019943355],
            [110.379982764202396, -6.996372555280812],
            [110.379995387840296, -6.996453980942279],
            [110.380007426727786, -6.996978632801093],
            [110.379998970916631, -6.997032894353199],
            [110.379943261766684, -6.997150411799323],
            [110.379921912036664, -6.997249883603775],
            [110.37987900125583, -6.997394554240649],
            [110.379849303772588, -6.997602560413815],
            [110.379808548776253, -6.997828642906172],
            [110.379775942160123, -6.997882872625303],
            [110.379702902875763, -6.997918958161494],
            [110.379548691469097, -6.997936845793117],
            [110.379418822510388, -6.997945720023015],
            [110.379232335122083, -6.997981655884561],
            [110.378923792682556, -6.998107885083342],
            [110.378801996602064, -6.998216269694265],
            [110.378745047362841, -6.998279512644015],
            [110.378645836394355, -6.998424108800589],
            [110.378601323323508, -6.998496413592565],
            [110.378563281348036, -6.998595863259409],
            [110.378556937607826, -6.99868630933454],
            [110.378550629759431, -6.998749619121047],
            [110.37855449482413, -6.998821987785903],
            [110.378588276921349, -6.998948668626658],
            [110.378678534067362, -6.999156833040386],
            [110.37869741749769, -6.999211130643435],
            [110.378710017016402, -6.999310647175522],
            [110.378726920059208, -6.9993649421632],
            [110.378891041762699, -6.999555113171111],
            [110.379180339062557, -6.999863040095495],
            [110.379277282947513, -6.999935531558926],
            [110.379555455336359, -7.000098716473028],
            [110.379601829368255, -7.000116868513651],
            [110.3799431874085, -7.000207772951304],
            [110.379966362497811, -7.000225894385337],
            [110.379996032677781, -7.000253069824102],
            [110.380248826782349, -7.000352902789579],
            [110.380377517026247, -7.000380208625806],
            [110.380394750939502, -7.000398322216597],
            [110.380305175859846, -7.000959021981112],
            [110.380231905730952, -7.001384061474432],
            [110.380231511788196, -7.001682560702618],
            [110.380247518576326, -7.001773036259413],
            [110.380263549244347, -7.001845420953682],
            [110.380400616402028, -7.002171237603847],
            [110.380545995687115, -7.002415656149416],
            [110.380711768913855, -7.002642010618108],
            [110.380912129599707, -7.002814137882129],
            [110.381043873013624, -7.002886674852324],
            [110.381136362683179, -7.002904887453459],
            [110.381304992517698, -7.002896063855825],
            [110.381385906484113, -7.002896170285174],
            [110.381458592055779, -7.00291435677634],
            [110.381523332155666, -7.002950623705773],
            [110.381587729779667, -7.003032117419722],
            [110.381611905682249, -7.003149740038493],
            [110.3815925720709, -7.003222078206085],
            [110.381499903571083, -7.003339547177901],
            [110.38120705820856, -7.003574343610201],
            [110.380867367713648, -7.003718623691071],
            [110.380754129551917, -7.00377274728003],
            [110.380713907685305, -7.003808876103156],
            [110.38060047853142, -7.004007726545399],
            [110.380421739658431, -7.004387399829399],
            [110.380356724842173, -7.00455917763636],
            [110.380088882923602, -7.005354823802407],
            [110.379959314839923, -7.005562698187239],
            [110.379441519745853, -7.006032378130234],
            [110.379061166264577, -7.006330375546884],
            [110.378450872651968, -7.006891842590366],
            [110.378450869555806, -7.006891839262797],
            [110.378329712986499, -7.006762218542272],
            [110.37757055049255, -7.006027973043914],
            [110.377154373211326, -7.005619833751206],
            [110.376554321701292, -7.004941916320076],
            [110.376041949271666, -7.004303558606902],
            [110.375704696614463, -7.003921818963151],
            [110.375498820352377, -7.003704603601041],
            [110.375187697879383, -7.003465335017502],
            [110.375021154519899, -7.003357738397491],
            [110.374703344832028, -7.003199539972988],
            [110.374210070456286, -7.003045490587509],
            [110.373539164678519, -7.00287586482244],
            [110.373028342306611, -7.002723982218369],
            [110.372576980159167, -7.002572259594836],
            [110.372576948237381, -7.002572248864501],
            [110.372335613584127, -7.002473246427674],
            [110.372089468161676, -7.002332079838869],
            [110.371987703401757, -7.002268625951277],
            [110.371764442529027, -7.002087419169903],
            [110.371582511608381, -7.001888176646917],
            [110.371401809363178, -7.001616572251242],
            [110.371263237357311, -7.001363114973594],
            [110.371161715159147, -7.001118752621798],
            [110.371099223103442, -7.00088348786174],
            [110.371026186292355, -7.000494436659577],
            [110.370913327144081, -6.999843014687025],
            [110.370854353843697, -6.999544647068688],
            [110.370781096862927, -6.999291066546765],
            [110.370655883284783, -6.998992399857671],
            [110.370537350684884, -6.998775150966425],
            [110.370426949930305, -6.998612185520477],
            [110.370313978788758, -6.998467307477925],
            [110.370130594605186, -6.998248132333566],
            [110.369926186989176, -6.998022346527829],
            [110.369742750826006, -6.997814944374648],
            [110.369588145445661, -6.997636425360184],
            [110.369428329923622, -6.997429054742363],
            [110.369305232644038, -6.997240088922944],
            [110.36915077898324, -6.99694881390641],
            [110.369035671551387, -6.996673324999197],
            [110.368904887808213, -6.996345370366206],
            [110.368829045638549, -6.99614597886343],
            [110.36875586674185, -6.995917746343377],
            [110.36869320340864, -6.995676416743665],
            [110.368648876205256, -6.995461334072219],
            [110.368633428772384, -6.995238423421282],
            [110.368623152794072, -6.995073208864214],
            [110.368631382100205, -6.99491879732055],
            [110.368672059290901, -6.994709476032376],
            [110.368715206982387, -6.994540519654167],
            [110.368786866361447, -6.99433133594414],
            [110.368942329315743, -6.993870030974978],
            [110.369050396400269, -6.993555784924141],
            [110.369113026337871, -6.993332293482251],
            [110.369153451385543, -6.993121687248122],
            [110.369195452490246, -6.992902869825023],
            [110.369246012787642, -6.992541120268337],
            [110.36925916834997, -6.992224547769147],
            [110.369255325089256, -6.991926043367074],
            [110.369192244103374, -6.991473642901578],
            [110.369142713720592, -6.991224464228296],
            [110.369054661278156, -6.990893420223744],
            [110.368807301120569, -6.989978453260526],
            [110.368149026703321, -6.987748722601787],
            [110.369441079811153, -6.987583130241409],
            [110.370704763651617, -6.987414309673352],
            [110.370973572792764, -6.987378486728096],
            [110.371533552651258, -6.987297824743918],
            [110.37217247522689, -6.987208221790068],
            [110.37235583452987, -6.987181329655961],
            [110.372476093714894, -6.987163398889524],
            [110.372654372743654, -6.987127454433362],
            [110.372800683373697, -6.987091467372539],
            [110.372918408428049, -6.987064487677762],
            [110.373063587401319, -6.987028499026295],
            [110.373203662001217, -6.987001548981512],
            [110.373959568604207, -6.986803553773179],
            [110.374658881492039, -6.986614527668593],
            [110.374928857735867, -6.986551567616794],
            [110.375318797970536, -6.986479720939649],
            [110.375420385374525, -6.986461594258027],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 20,
        id: 22,
        area: 980824.83501399995,
        perimeter: 4246.640038,
        kode_pos: 50146,
        kode_kec: 3374140,
        kode_kel: 3374080004,
        kec: "Semarang Barat",
        kel: "Krapyak",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.376106648366488, -6.980321000145337],
            [110.376126490429982, -6.980718840910097],
            [110.376051986581786, -6.98100819671305],
            [110.375828531968196, -6.981405900811343],
            [110.375722982673793, -6.982066078767999],
            [110.375690311372793, -6.983449988826281],
            [110.375758615423123, -6.984209896711228],
            [110.375901138761719, -6.985322675210747],
            [110.375801100351467, -6.985666269714511],
            [110.375420385374525, -6.986461594258027],
            [110.375318797970536, -6.986479720939649],
            [110.374928857735867, -6.986551567616794],
            [110.374658881492039, -6.986614527668593],
            [110.373959568604207, -6.986803553773179],
            [110.373203662001217, -6.987001548981512],
            [110.373063587401319, -6.987028499026295],
            [110.372918408428049, -6.987064487677762],
            [110.372800683373697, -6.987091467372539],
            [110.372654372743654, -6.987127454433362],
            [110.372476093714894, -6.987163398889524],
            [110.37235583452987, -6.987181329655961],
            [110.37217247522689, -6.987208221790068],
            [110.371533552651258, -6.987297824743918],
            [110.370973572792764, -6.987378486728096],
            [110.370704763651617, -6.987414309673352],
            [110.369441079811153, -6.987583130241409],
            [110.368149026703321, -6.987748722601787],
            [110.367798650787222, -6.987797919613925],
            [110.366986684131376, -6.987912823622723],
            [110.366752787039047, -6.98793120354362],
            [110.366398218996906, -6.987920400463123],
            [110.365929664947302, -6.987850646946955],
            [110.365600161868755, -6.987780617951744],
            [110.365287914734722, -6.987699283990531],
            [110.364672719535278, -6.987504262579522],
            [110.363493280178204, -6.987079609042794],
            [110.363241191367834, -6.986998355024151],
            [110.362880375427835, -6.98690308304375],
            [110.362534874735474, -6.986848112697126],
            [110.361987055731731, -6.986783025340466],
            [110.362162275482177, -6.986473233376262],
            [110.362407728497374, -6.986125702225465],
            [110.362571489873275, -6.98580812272078],
            [110.36267279950502, -6.985584940088104],
            [110.362855847057602, -6.985308185287582],
            [110.363086180112404, -6.985027199660198],
            [110.363333865298657, -6.984628135593308],
            [110.363437674679389, -6.984145132293478],
            [110.363526640526601, -6.983513944911119],
            [110.363621751478235, -6.983106085424883],
            [110.363706169079251, -6.982659559991716],
            [110.363844204460079, -6.981969421152305],
            [110.364153201379949, -6.980937637510388],
            [110.364477855401503, -6.981019883868991],
            [110.365157744324407, -6.981174571532303],
            [110.365678469511039, -6.981247635402587],
            [110.366378591392007, -6.981293803399842],
            [110.366896597428308, -6.981285453412958],
            [110.367459043440022, -6.981259071576619],
            [110.368134120935366, -6.981205703628783],
            [110.369287965820902, -6.981080611517457],
            [110.369844248370327, -6.981008991377465],
            [110.370341097170083, -6.980955382124343],
            [110.37096525187971, -6.980901942091293],
            [110.371348361800628, -6.980857225535444],
            [110.372059698488755, -6.980767718427255],
            [110.372999078210256, -6.980660422448048],
            [110.374047092086272, -6.980553268697195],
            [110.375326862513617, -6.980401192236785],
            [110.376106648366488, -6.980321000145337],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 21,
        id: 23,
        area: 6660781.850726,
        perimeter: 12396.737333,
        kode_pos: 50149,
        kode_kec: 3374140,
        kode_kel: 3374080005,
        kec: "Semarang Barat",
        kel: "Tambakharjo",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.37957652742341, -6.979402884122483],
            [110.378954411500814, -6.979628072060089],
            [110.378440729863044, -6.97979021385023],
            [110.378102554619346, -6.979871177333863],
            [110.378002662609433, -6.980106227402303],
            [110.376528515527156, -6.980276145360956],
            [110.376106648366488, -6.980321000145337],
            [110.375326862513617, -6.980401192236785],
            [110.374047092086272, -6.980553268697195],
            [110.372999078210256, -6.980660422448048],
            [110.372059698488755, -6.980767718427255],
            [110.371348361800628, -6.980857225535444],
            [110.37096525187971, -6.980901942091293],
            [110.370341097170083, -6.980955382124343],
            [110.369844248370327, -6.981008991377465],
            [110.369287965820902, -6.981080611517457],
            [110.368134120935366, -6.981205703628783],
            [110.367459043440022, -6.981259071576619],
            [110.366896597428308, -6.981285453412958],
            [110.366378591392007, -6.981293803399842],
            [110.365678469511039, -6.981247635402587],
            [110.365157744324407, -6.981174571532303],
            [110.364477855401503, -6.981019883868991],
            [110.364153201379949, -6.980937637510388],
            [110.364302493693202, -6.980304770665115],
            [110.364492384961977, -6.979731696022655],
            [110.365020890828589, -6.978259356047769],
            [110.365320729021704, -6.977428752097547],
            [110.365596813771077, -6.976681860839046],
            [110.365797510454541, -6.976061559104078],
            [110.366326492964703, -6.974527616503877],
            [110.366172102853241, -6.974473726643057],
            [110.366199245504973, -6.974265742782413],
            [110.366155818678308, -6.974097926195378],
            [110.366204470485513, -6.97287671102394],
            [110.366072241885135, -6.971550972454152],
            [110.366948919155121, -6.968616647328476],
            [110.367117165699369, -6.968057199796531],
            [110.365382744145222, -6.966766601015584],
            [110.364380949730887, -6.965996416178873],
            [110.364075250367577, -6.965761662826907],
            [110.362609307203542, -6.964588909696181],
            [110.36007014212062, -6.962535956573311],
            [110.359711592057323, -6.962242682258982],
            [110.359550670503239, -6.962066790953479],
            [110.359397163856428, -6.961825032099295],
            [110.359346193573387, -6.961598051531572],
            [110.359421507544795, -6.960079310056107],
            [110.359504651948484, -6.958187272877824],
            [110.359565660262163, -6.956412321197015],
            [110.359590067608778, -6.955919156201974],
            [110.35965603820658, -6.95458617094402],
            [110.359688658074916, -6.954345806312874],
            [110.359730057371365, -6.954040951149634],
            [110.359903269778641, -6.953602893222075],
            [110.360942236726345, -6.953786363437109],
            [110.361829983292324, -6.953822708274079],
            [110.362788038676186, -6.953867915012477],
            [110.363978977480883, -6.953952957696373],
            [110.364980972181797, -6.954007002394244],
            [110.365719195041081, -6.954113391864864],
            [110.366668285574278, -6.954290329057992],
            [110.367129616688175, -6.954400737677842],
            [110.367920687002055, -6.954428144282772],
            [110.368685434243389, -6.954420381074762],
            [110.369300680171364, -6.954465117656072],
            [110.370117934031299, -6.954633089148389],
            [110.370777142587585, -6.954669098968049],
            [110.371537326263805, -6.954788683787479],
            [110.372451435913106, -6.954833812899855],
            [110.373453563095168, -6.954791223137936],
            [110.374200882609586, -6.954669244315369],
            [110.375088738593178, -6.954626500019167],
            [110.375695254048637, -6.954627300029967],
            [110.37649512818723, -6.954645920627567],
            [110.377242435544147, -6.954532720263827],
            [110.377805243442225, -6.954349009377968],
            [110.378346104636393, -6.954143310555017],
            [110.378487022913319, -6.953932694685599],
            [110.378575258367064, -6.95367809279597],
            [110.378566710693391, -6.953493630846221],
            [110.378483407108803, -6.953339812591415],
            [110.378132105007438, -6.953110983900615],
            [110.377227238287929, -6.952723326862633],
            [110.376954850983893, -6.952643918355776],
            [110.376743843612886, -6.952678774220853],
            [110.376365755866757, -6.95276611022793],
            [110.376190015410998, -6.952756887953768],
            [110.375832931602574, -6.952610971385795],
            [110.375489001893854, -6.952524572606602],
            [110.375178066053422, -6.9525043289923],
            [110.374906949860843, -6.952391581096511],
            [110.374748309369224, -6.952278981532066],
            [110.374669115892644, -6.952126819679355],
            [110.374651968506669, -6.95196027775481],
            [110.37467298556129, -6.951702055979322],
            [110.374931772192383, -6.951444147972799],
            [110.375541921172967, -6.951279672892784],
            [110.376151865449302, -6.951270146477256],
            [110.377242456717866, -6.951307736263272],
            [110.379196615174436, -6.951072711219933],
            [110.380065194324487, -6.950918898682457],
            [110.380540895837242, -6.950795560977656],
            [110.381078826683051, -6.950517354162988],
            [110.381657990334418, -6.950105091946694],
            [110.383656417208954, -6.956975346855877],
            [110.383528231904521, -6.958087772627971],
            [110.383108360495058, -6.96008627393376],
            [110.383204600622278, -6.960692445710448],
            [110.383445644283583, -6.961977216258885],
            [110.383512368792253, -6.963089895672907],
            [110.383598141153982, -6.964347327346719],
            [110.38385798681982, -6.965966804513717],
            [110.383816113164158, -6.96813766175455],
            [110.383745795678294, -6.970208981554217],
            [110.383707740508726, -6.971837115477554],
            [110.383789628500082, -6.974767952472912],
            [110.383868434030205, -6.976161056577711],
            [110.384023070634058, -6.977111031939152],
            [110.384022869643488, -6.977264804537766],
            [110.383985384864857, -6.977373301168059],
            [110.383879343458446, -6.977554072008202],
            [110.383728261565608, -6.977780011319221],
            [110.383524146839804, -6.978105381375533],
            [110.383244402437427, -6.978503016172037],
            [110.382793359875166, -6.979008972269574],
            [110.382559952539978, -6.97923480342544],
            [110.382454948547021, -6.97927084786148],
            [110.382326511124461, -6.979270679765652],
            [110.382128268137805, -6.979216147490463],
            [110.381510296143261, -6.978871610575247],
            [110.381393800097726, -6.978826230597776],
            [110.381288560743968, -6.978826092637558],
            [110.381125279063511, -6.978862060369978],
            [110.380086283599354, -6.979213469456792],
            [110.37957652742341, -6.979402884122483],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 22,
        id: 24,
        area: 477835.05444600002,
        perimeter: 3248.14379,
        kode_pos: 50217,
        kode_kec: 3374120,
        kode_kel: 3374060020,
        kec: "Semarang Utara",
        kel: "Purwosari",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.411389070203626, -6.966915478802282],
            [110.412874737274223, -6.966718325069759],
            [110.413668067258484, -6.966646945589054],
            [110.413996848424006, -6.966602125683896],
            [110.414227136886396, -6.966593365574833],
            [110.414325562201142, -6.966611578553944],
            [110.414418284717371, -6.966665966511363],
            [110.415047046924471, -6.967444659353756],
            [110.415369127401462, -6.967788787456052],
            [110.415793767986983, -6.968232542841576],
            [110.416465624393567, -6.968938923231434],
            [110.417250157928024, -6.969808261119523],
            [110.418191142574159, -6.969067687985707],
            [110.418643614105548, -6.968742606056591],
            [110.418940146263608, -6.968697743126085],
            [110.420097668503445, -6.968545390968125],
            [110.420544783851227, -6.969998088855911],
            [110.420754174751963, -6.970678406516792],
            [110.420749317037618, -6.970680777856429],
            [110.420312823336303, -6.970890872680021],
            [110.419886602355035, -6.971095969407294],
            [110.419535616472331, -6.9712678354555],
            [110.417380579723911, -6.972323076099957],
            [110.416916719363385, -6.972541520125699],
            [110.41646839823359, -6.972752645671685],
            [110.415790012773513, -6.973072113009352],
            [110.415737026418839, -6.97309706544271],
            [110.415623666309472, -6.973150449167874],
            [110.414979226400533, -6.973453929073182],
            [110.414928174850502, -6.973478026708018],
            [110.414928154149649, -6.973478036712991],
            [110.414518127485181, -6.973676207298252],
            [110.413908195668469, -6.97397410918129],
            [110.413733087536201, -6.974055443700791],
            [110.413733051221882, -6.974055460568108],
            [110.413623995050997, -6.974109439058652],
            [110.412891036688691, -6.974533667368295],
            [110.412889037723474, -6.97453241066353],
            [110.412790120054183, -6.974470223316694],
            [110.41273134574574, -6.974401127894093],
            [110.412674582770919, -6.974334396982391],
            [110.412614977633197, -6.974240217897941],
            [110.412614949096181, -6.974240238614211],
            [110.412563892472264, -6.974134214777895],
            [110.41250729621251, -6.97395427748529],
            [110.4124710500611, -6.973755231178311],
            [110.412446266460037, -6.973438607501862],
            [110.412438816376721, -6.972606411311866],
            [110.412419419147739, -6.972280748815252],
            [110.412409267436104, -6.972027461898907],
            [110.412381960320175, -6.971692744047428],
            [110.412337769465054, -6.971285641107046],
            [110.412294801555888, -6.971032313362865],
            [110.41219507034252, -6.970245229874257],
            [110.412063631194542, -6.969250060168391],
            [110.411925381430649, -6.968499110752193],
            [110.411705567626527, -6.967820423884733],
            [110.411404936908937, -6.966960726111956],
            [110.411389070203626, -6.966915478802282],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 23,
        id: 25,
        area: 850562.98564800003,
        perimeter: 4614.017703,
        kode_pos: 50176,
        kode_kec: 3374120,
        kode_kel: 3374060022,
        kec: "Semarang Utara",
        kel: "Kuningan",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.422359327181496, -6.964242654999445],
            [110.42234564882996, -6.964323882329606],
            [110.422258079340949, -6.96445041275413],
            [110.422096696337078, -6.964558761899292],
            [110.421908992941368, -6.964676124334559],
            [110.421716796372152, -6.964766344609193],
            [110.421648302503229, -6.96479339742319],
            [110.421531571807805, -6.964476661175789],
            [110.421504424974017, -6.964467582439345],
            [110.420962581306156, -6.964548329011559],
            [110.420399453750193, -6.964683322014368],
            [110.419911010228304, -6.964818405945213],
            [110.419404133784113, -6.964989648811247],
            [110.418002124873581, -6.965431155815455],
            [110.415820627533961, -6.966188289075148],
            [110.415758281505035, -6.966504805154999],
            [110.41561823145274, -6.966974998959647],
            [110.415369127401462, -6.967788787456052],
            [110.415047046924471, -6.967444659353756],
            [110.414418284717371, -6.966665966511363],
            [110.414325562201142, -6.966611578553944],
            [110.414227136886396, -6.966593365574833],
            [110.413996848424006, -6.966602125683896],
            [110.413668067258484, -6.966646945589054],
            [110.412874737274223, -6.966718325069759],
            [110.411389070203626, -6.966915478802282],
            [110.411376600766815, -6.966879281233108],
            [110.411317779286179, -6.966408841432347],
            [110.411237729452452, -6.965947420700188],
            [110.411032324875151, -6.965965255884564],
            [110.411047125719278, -6.965892910238469],
            [110.410924265853723, -6.964599249166188],
            [110.410704961487568, -6.963061239093943],
            [110.410676814262501, -6.962798884203556],
            [110.410538027140547, -6.96149615766212],
            [110.410283541895865, -6.959134962027552],
            [110.410101558977956, -6.9573889514239],
            [110.40991192818521, -6.95587811452897],
            [110.409912660372569, -6.955145218437373],
            [110.410348052313523, -6.955724884164309],
            [110.410927094828011, -6.956205017192824],
            [110.411836325712045, -6.956414194490329],
            [110.412327920471327, -6.956469078075797],
            [110.412562572794769, -6.956586961015683],
            [110.413039937654986, -6.957166466214493],
            [110.413372575994714, -6.957655336495484],
            [110.413648771522986, -6.958035590458103],
            [110.414024287115041, -6.958624014182739],
            [110.414456278358045, -6.959067779259034],
            [110.415404581289522, -6.959485045559951],
            [110.416285074336528, -6.959377586220514],
            [110.417018009727343, -6.95986694792541],
            [110.417216823715563, -6.960373742083148],
            [110.41753156829482, -6.960925906532899],
            [110.418457449354335, -6.961867779985275],
            [110.419777161764642, -6.962547814929654],
            [110.420852063786228, -6.963318002043787],
            [110.421819414023787, -6.964133283134659],
            [110.422359327181496, -6.964242654999445],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 24,
        id: 26,
        area: 455374.88880299998,
        perimeter: 3500.66069,
        kode_pos: 50178,
        kode_kec: 3374120,
        kode_kel: 3374060023,
        kec: "Semarang Utara",
        kel: "Panggung Kidul",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.410676814262501, -6.962798884203556],
            [110.410704961487568, -6.963061239093943],
            [110.410924265853723, -6.964599249166188],
            [110.411047125719278, -6.965892910238469],
            [110.411032324875151, -6.965965255884564],
            [110.411237729452452, -6.965947420700188],
            [110.411317779286179, -6.966408841432347],
            [110.411376600766815, -6.966879281233108],
            [110.411389070203626, -6.966915478802282],
            [110.411404936908937, -6.966960726111956],
            [110.411195638453236, -6.967032829544109],
            [110.411070292962265, -6.967050764418992],
            [110.410809259023011, -6.966987120568461],
            [110.410689062137351, -6.966959834211618],
            [110.410471820681792, -6.966941472320134],
            [110.410201892798668, -6.966977317650419],
            [110.409709212684248, -6.967103339798029],
            [110.408870980392152, -6.967337475487509],
            [110.40859814392843, -6.967436634777332],
            [110.407712044600771, -6.967715797655384],
            [110.407552888411104, -6.96739914382923],
            [110.407443282724529, -6.967498506987393],
            [110.407288381755421, -6.967172674633593],
            [110.407028512885816, -6.967308031272393],
            [110.406445383254265, -6.9675967554878],
            [110.406387662625448, -6.967379590942707],
            [110.405828058506401, -6.967632161893782],
            [110.40540829572636, -6.967812544061774],
            [110.404328193229745, -6.968263459041681],
            [110.404120150182109, -6.968353651777634],
            [110.403244016991977, -6.968578683772455],
            [110.402484336866564, -6.968659133388177],
            [110.401682325337745, -6.96865811871979],
            [110.400690808636895, -6.968700302875177],
            [110.400690808175156, -6.968700298810724],
            [110.400676181022945, -6.968571705227183],
            [110.400412396247745, -6.966361127231528],
            [110.400364296433281, -6.965832337435499],
            [110.401645269536544, -6.96575446791598],
            [110.403685689464098, -6.96561231781432],
            [110.404858981715194, -6.965288158112649],
            [110.405862467600571, -6.965018055043536],
            [110.405648731744378, -6.964013735663761],
            [110.405606388757292, -6.963941318425198],
            [110.405550161211139, -6.963886974736449],
            [110.405467364442558, -6.9638145066367],
            [110.405412845680232, -6.963751119579749],
            [110.405369881886187, -6.963497791497086],
            [110.407033614314471, -6.963246606217148],
            [110.407991944765712, -6.96311212412821],
            [110.408262447596741, -6.963067235096527],
            [110.409256931321565, -6.962978023156428],
            [110.41016345758041, -6.962870608394345],
            [110.410676814262501, -6.962798884203556],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 25,
        id: 27,
        area: 2206532.0922420002,
        perimeter: 9206.487729,
        kode_pos: 50175,
        kode_kec: 3374120,
        kode_kel: 3374060014,
        kec: "Semarang Utara",
        kel: "Bandarharjo",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.422359327181496, -6.964242654999445],
            [110.421819414023787, -6.964133283134659],
            [110.420852063786228, -6.963318002043787],
            [110.419777161764642, -6.962547814929654],
            [110.418457449354335, -6.961867779985275],
            [110.41753156829482, -6.960925906532899],
            [110.417216823715563, -6.960373742083148],
            [110.417018009727343, -6.95986694792541],
            [110.416285074336528, -6.959377586220514],
            [110.415404581289522, -6.959485045559951],
            [110.414456278358045, -6.959067779259034],
            [110.414024287115041, -6.958624014182739],
            [110.413648771522986, -6.958035590458103],
            [110.413372575994714, -6.957655336495484],
            [110.413039937654986, -6.957166466214493],
            [110.412562572794769, -6.956586961015683],
            [110.412327920471327, -6.956469078075797],
            [110.411836325712045, -6.956414194490329],
            [110.410927094828011, -6.956205017192824],
            [110.410348052313523, -6.955724884164309],
            [110.409912660372569, -6.955145218437373],
            [110.409261663027152, -6.954321638177125],
            [110.40854589835925, -6.953429309107928],
            [110.408148529146885, -6.95271248051298],
            [110.407799231828292, -6.951772852859606],
            [110.407577399997194, -6.950817465932665],
            [110.407300559894352, -6.949265066644364],
            [110.407049761144648, -6.947938429773036],
            [110.407049727586099, -6.947760546002159],
            [110.407226616136896, -6.947606367773727],
            [110.412711462238931, -6.946207801741722],
            [110.412726274161486, -6.946192053342245],
            [110.414698576417791, -6.945739814810833],
            [110.416124878882343, -6.945153678162863],
            [110.416510310438596, -6.944543645972587],
            [110.416601603096538, -6.94391064027293],
            [110.416986309171151, -6.943888501997896],
            [110.417372155755061, -6.942939298979665],
            [110.418696528662693, -6.942881661295313],
            [110.418696580958155, -6.942967913224612],
            [110.41869464183911, -6.9440249933905],
            [110.418693852182102, -6.944455430377555],
            [110.417030437033588, -6.944424560892604],
            [110.417011644296437, -6.945284021770423],
            [110.418681586842055, -6.945333328903793],
            [110.418676684627712, -6.945469891059664],
            [110.418589726337672, -6.945522243726137],
            [110.417295142111186, -6.945491118978308],
            [110.417233016739345, -6.945535345923874],
            [110.417229853752673, -6.94570369456956],
            [110.417280028888527, -6.945762827302792],
            [110.418574610224738, -6.945796905702753],
            [110.41866360034976, -6.945834382077172],
            [110.418662122894034, -6.945875539742967],
            [110.41864683531351, -6.946345888555233],
            [110.418594578672455, -6.946384687407038],
            [110.41786749069918, -6.946360166749412],
            [110.41786109246317, -6.946755935051738],
            [110.418588210063817, -6.946756827374761],
            [110.418635455111371, -6.946795281514893],
            [110.418628966641919, -6.947766014784365],
            [110.418608957687937, -6.9486253155068],
            [110.418685053843646, -6.948625408849243],
            [110.418670476203474, -6.948978166598395],
            [110.418604104936975, -6.948979581280846],
            [110.418577493602271, -6.949828332220051],
            [110.418531499627832, -6.951601075570194],
            [110.418543037898871, -6.951599331123992],
            [110.418590035913255, -6.951592225592864],
            [110.418704715199809, -6.951963232965499],
            [110.418636505998535, -6.951990285844621],
            [110.418853235028692, -6.952650875312779],
            [110.419078590985222, -6.953389912161134],
            [110.419111144045672, -6.953411016028165],
            [110.420003052649776, -6.956284175639822],
            [110.420045986967509, -6.956375936861733],
            [110.420079464362772, -6.956447486283921],
            [110.420187078502636, -6.956418206259292],
            [110.420272230745184, -6.95668658510803],
            [110.420364212870808, -6.956976489748506],
            [110.421081695402279, -6.957149232792868],
            [110.421642307035327, -6.957213236784922],
            [110.422263928341181, -6.957123540557034],
            [110.423233615281191, -6.95694381237555],
            [110.424285832104033, -6.956592317360706],
            [110.424331315583615, -6.957804474310052],
            [110.424482012771946, -6.959034850243588],
            [110.424558698905372, -6.959713358557245],
            [110.424711255965093, -6.961043237550577],
            [110.425016588556943, -6.963757268841457],
            [110.425132679275436, -6.964833828417169],
            [110.425204880862907, -6.965711332882434],
            [110.4251969197568, -6.966208827499626],
            [110.425094092674868, -6.966552432697328],
            [110.425092693308429, -6.967701213565638],
            [110.425120806876791, -6.968542482208883],
            [110.425032512660096, -6.9687505392675],
            [110.424688161073675, -6.968388181721335],
            [110.42344879628979, -6.967916303935646],
            [110.423023352206982, -6.967888648195112],
            [110.422592904599966, -6.96778862173996],
            [110.422292439773756, -6.967580207462841],
            [110.422204846455344, -6.967263506936847],
            [110.422271237503637, -6.966874630393586],
            [110.422722021135769, -6.965843990826779],
            [110.422862152325052, -6.965301430155314],
            [110.422870038391622, -6.964867254371161],
            [110.422567482700117, -6.964287971138057],
            [110.422359327181496, -6.964242654999445],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 26,
        id: 28,
        area: 2419762.9769720002,
        perimeter: 7734.721813,
        kode_pos: 50177,
        kode_kec: 3374120,
        kode_kel: 3374060024,
        kec: "Semarang Utara",
        kel: "Panggung Lor",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.409912660372569, -6.955145218437373],
            [110.40991192818521, -6.95587811452897],
            [110.410101558977956, -6.9573889514239],
            [110.410283541895865, -6.959134962027552],
            [110.410538027140547, -6.96149615766212],
            [110.410676814262501, -6.962798884203556],
            [110.41016345758041, -6.962870608394345],
            [110.409256931321565, -6.962978023156428],
            [110.408262447596741, -6.963067235096527],
            [110.407991944765712, -6.96311212412821],
            [110.407033614314471, -6.963246606217148],
            [110.405369881886187, -6.963497791497086],
            [110.405412845680232, -6.963751119579749],
            [110.405467364442558, -6.9638145066367],
            [110.405550161211139, -6.963886974736449],
            [110.405606388757292, -6.963941318425198],
            [110.405648731744378, -6.964013735663761],
            [110.405862467600571, -6.965018055043536],
            [110.404858981715194, -6.965288158112649],
            [110.403685689464098, -6.96561231781432],
            [110.401645269536544, -6.96575446791598],
            [110.400364296433281, -6.965832337435499],
            [110.400364295896537, -6.965832331534559],
            [110.400255084597021, -6.964631704555295],
            [110.400161164390127, -6.962763356215911],
            [110.40002243924458, -6.961423146706389],
            [110.399723482320354, -6.959881778974303],
            [110.399267211714061, -6.957879402655422],
            [110.398632943192538, -6.955969979692909],
            [110.398215108089914, -6.954954194302275],
            [110.398215099426565, -6.95495417324103],
            [110.398025164335237, -6.954492426786762],
            [110.396687089278018, -6.951759541700389],
            [110.395886608822991, -6.949984062210688],
            [110.39374327171214, -6.945505822969306],
            [110.394963705294799, -6.944959416674815],
            [110.401864729130537, -6.944685939563061],
            [110.402712044374226, -6.950330787947363],
            [110.402798900823342, -6.950359839888221],
            [110.403291130181387, -6.950490701115886],
            [110.403913845300266, -6.950505956169928],
            [110.404580152754534, -6.95040549531331],
            [110.405159748930998, -6.950160212282499],
            [110.405551185581359, -6.949827865055278],
            [110.405349381105296, -6.94907510750965],
            [110.405393100261421, -6.948858093799207],
            [110.406320537338004, -6.94839617680731],
            [110.406845963635192, -6.947938174780858],
            [110.407020676519465, -6.947785881231157],
            [110.407032332477186, -6.947775707782756],
            [110.407049727586099, -6.947760546002159],
            [110.407049761144648, -6.947938429773036],
            [110.407300559894352, -6.949265066644364],
            [110.407577399997194, -6.950817465932665],
            [110.407799231828292, -6.951772852859606],
            [110.408148529146885, -6.95271248051298],
            [110.40854589835925, -6.953429309107928],
            [110.409261663027152, -6.954321638177125],
            [110.409912660372569, -6.955145218437373],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 27,
        id: 29,
        area: 479368.65990700002,
        perimeter: 2853.006733,
        kode_pos: 50171,
        kode_kec: 3374120,
        kode_kel: 3374060019,
        kec: "Semarang Utara",
        kel: "Plombokan",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.407712044600771, -6.967715797655384],
            [110.40859814392843, -6.967436634777332],
            [110.408870980392152, -6.967337475487509],
            [110.409709212684248, -6.967103339798029],
            [110.410201892798668, -6.966977317650419],
            [110.410471820681792, -6.966941472320134],
            [110.410689062137351, -6.966959834211618],
            [110.410809259023011, -6.966987120568461],
            [110.411070292962265, -6.967050764418992],
            [110.411195638453236, -6.967032829544109],
            [110.411404936908937, -6.966960726111956],
            [110.411705567626527, -6.967820423884733],
            [110.411925381430649, -6.968499110752193],
            [110.412063631194542, -6.969250060168391],
            [110.41219507034252, -6.970245229874257],
            [110.412294801555888, -6.971032313362865],
            [110.412337769465054, -6.971285641107046],
            [110.412381960320175, -6.971692744047428],
            [110.412409267436104, -6.972027461898907],
            [110.412419419147739, -6.972280748815252],
            [110.412438816376721, -6.972606411311866],
            [110.412446266460037, -6.973438607501862],
            [110.4124710500611, -6.973755231178311],
            [110.41250729621251, -6.97395427748529],
            [110.412563892472264, -6.974134214777895],
            [110.412614949096181, -6.974240238614211],
            [110.411541140941821, -6.975019760234304],
            [110.410936765346122, -6.975460042007781],
            [110.410551526915185, -6.975734767268342],
            [110.410062090596043, -6.976032295818895],
            [110.409527751863436, -6.976320630763449],
            [110.409468456143955, -6.976352627311889],
            [110.409195924950424, -6.976464825487117],
            [110.408866083778022, -6.976600617276037],
            [110.408512814046205, -6.976723664622649],
            [110.408436771973356, -6.976726088602987],
            [110.408433276055604, -6.976673260022325],
            [110.4084146276289, -6.976391454142552],
            [110.408320378934036, -6.975749105363195],
            [110.408282482516213, -6.975513874806449],
            [110.408078006119908, -6.974563840758757],
            [110.407959269633395, -6.974048098268687],
            [110.407957742608033, -6.973912413816208],
            [110.407725930542426, -6.97242866074226],
            [110.407550843454018, -6.971505799901391],
            [110.407273677464445, -6.97032049138835],
            [110.40723049640485, -6.97023902769228],
            [110.407126662883712, -6.970021805353385],
            [110.407080327141131, -6.969524244562458],
            [110.407074968588233, -6.968610642037086],
            [110.407400345257216, -6.968574868122412],
            [110.407383940548598, -6.967896434767805],
            [110.407440350494056, -6.967806050451112],
            [110.407712044600771, -6.967715797655384],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 28,
        id: 30,
        area: 274730.41097700002,
        perimeter: 2241.491328,
        kode_pos: 50139,
        kode_kec: 3374130,
        kode_kel: 3374070006,
        kec: "Semarang Tengah",
        kel: "Kranggan",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.42342497208017, -6.979110809929616],
            [110.42296948951919, -6.9769974278738],
            [110.422745596706619, -6.975857418000368],
            [110.423446425563384, -6.975641181731389],
            [110.425072545878521, -6.974946658934212],
            [110.425573274063126, -6.974721130099883],
            [110.425962008246714, -6.974640966805667],
            [110.426323843800432, -6.974593710544252],
            [110.426713226558974, -6.974619286586864],
            [110.427110115913891, -6.974669974573257],
            [110.428284785082198, -6.974805830631155],
            [110.428311402052444, -6.974552587900571],
            [110.428663897143622, -6.974553014519363],
            [110.429023431926169, -6.97458058599301],
            [110.429587079334667, -6.974725995770654],
            [110.429846520378192, -6.974943401975052],
            [110.429911107959953, -6.975106299615071],
            [110.429883621200773, -6.975377632518708],
            [110.429712810845842, -6.975793520894729],
            [110.429539433884159, -6.97599231322403],
            [110.429163389120518, -6.976281315816875],
            [110.428930036409398, -6.976480035360136],
            [110.428683909025125, -6.976714921446374],
            [110.428281149608395, -6.977103391771704],
            [110.428125363016918, -6.977284113722862],
            [110.427814266718954, -6.977645558016151],
            [110.42718592182834, -6.978124209050188],
            [110.42680722098882, -6.978268477776743],
            [110.426537448823709, -6.978403833018585],
            [110.426039424788286, -6.978728866672319],
            [110.425771382659974, -6.978837086952717],
            [110.425408894661061, -6.978909009974638],
            [110.425197804274148, -6.978944935025746],
            [110.425078613460187, -6.979017154056449],
            [110.42496340120006, -6.979306470556619],
            [110.424873756382695, -6.979740546607459],
            [110.424751669998173, -6.97986703516396],
            [110.424619193421606, -6.979930192327933],
            [110.42438917004975, -6.97994800283445],
            [110.424267565382237, -6.979911672371142],
            [110.424103285474231, -6.979690096877966],
            [110.423831308963543, -6.979335521762843],
            [110.423691237720504, -6.97922127243416],
            [110.42342497208017, -6.979110809929616],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 29,
        id: 31,
        area: 256960.61914699999,
        perimeter: 2582.393989,
        kode_pos: 50135,
        kode_kec: 3374130,
        kode_kel: 3374070007,
        kec: "Semarang Tengah",
        kel: "Gabahan",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.42342497208017, -6.979110809929616],
            [110.423691237720504, -6.97922127243416],
            [110.423831308963543, -6.979335521762843],
            [110.424103285474231, -6.979690096877966],
            [110.424267565382237, -6.979911672371142],
            [110.42438917004975, -6.97994800283445],
            [110.424619193421606, -6.979930192327933],
            [110.424751669998173, -6.97986703516396],
            [110.424873756382695, -6.979740546607459],
            [110.42496340120006, -6.979306470556619],
            [110.425078613460187, -6.979017154056449],
            [110.425197804274148, -6.978944935025746],
            [110.425408894661061, -6.978909009974638],
            [110.425771382659974, -6.978837086952717],
            [110.426039424788286, -6.978728866672319],
            [110.426537448823709, -6.978403833018585],
            [110.42680722098882, -6.978268477776743],
            [110.42718592182834, -6.978124209050188],
            [110.427814266718954, -6.977645558016151],
            [110.428125363016918, -6.977284113722862],
            [110.42820106369409, -6.97735656969322],
            [110.428532750777251, -6.97795397657414],
            [110.428737296463282, -6.97841554639464],
            [110.428846863542617, -6.978813682476844],
            [110.428970738295547, -6.97931133672288],
            [110.42902513934952, -6.97970940601962],
            [110.429038977604733, -6.979962697697152],
            [110.429006272010426, -6.980641073103662],
            [110.427453760416583, -6.981209060090436],
            [110.426876729416492, -6.981362133449636],
            [110.426269732157564, -6.981497078571888],
            [110.425651190642611, -6.981586781192108],
            [110.424791158173477, -6.981585733325432],
            [110.424276276842463, -6.98181124337363],
            [110.423670693050838, -6.981946186767011],
            [110.423615437901688, -6.982018483448779],
            [110.423704687271297, -6.982371367936167],
            [110.423340454580554, -6.982479469075658],
            [110.423103324067711, -6.982524406729334],
            [110.422938073785105, -6.982551341158945],
            [110.422786108977462, -6.982587337309815],
            [110.422651684316904, -6.982623354879759],
            [110.422477347715741, -6.98267741458721],
            [110.422370375985651, -6.982704420163865],
            [110.421746002686291, -6.982839337932572],
            [110.421286548797724, -6.980496054219428],
            [110.422110303450111, -6.980234674092783],
            [110.422425592105057, -6.980126513866617],
            [110.422501510286949, -6.980045197082241],
            [110.422561086518726, -6.979909587145189],
            [110.422613450899462, -6.979195054866769],
            [110.422635896406007, -6.979019845118821],
            [110.422726139731822, -6.978931895100021],
            [110.422862371326289, -6.978910046618886],
            [110.42342497208017, -6.979110809929616],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 30,
        id: 32,
        area: 245365.69948800001,
        perimeter: 2560.883721,
        kode_pos: 50188,
        kode_kec: 3374130,
        kode_kel: 3374070016,
        kec: "Semarang Tengah",
        kel: "Kauman",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.428311402052444, -6.974552587900571],
            [110.428284785082198, -6.974805830631155],
            [110.427110115913891, -6.974669974573257],
            [110.426713226558974, -6.974619286586864],
            [110.426323843800432, -6.974593710544252],
            [110.425962008246714, -6.974640966805667],
            [110.425573274063126, -6.974721130099883],
            [110.425072545878521, -6.974946658934212],
            [110.423446425563384, -6.975641181731389],
            [110.422745596706619, -6.975857418000368],
            [110.422649168495781, -6.974979884154012],
            [110.422622170199759, -6.974618030129266],
            [110.422538284211328, -6.972890232167374],
            [110.423019391419857, -6.972746091695691],
            [110.423142291736099, -6.972646741012928],
            [110.422935994061959, -6.97144190663254],
            [110.422793054129968, -6.971080699302102],
            [110.422707335898593, -6.971144652204877],
            [110.422481028386514, -6.970356344277183],
            [110.422356672044728, -6.969923166798643],
            [110.422356690400306, -6.969923159667079],
            [110.422550481076698, -6.969859995702192],
            [110.422584548635569, -6.969853833279649],
            [110.4231961986833, -6.969743192495295],
            [110.423626586333327, -6.969662307898729],
            [110.423753667865839, -6.969662462887956],
            [110.423781097224207, -6.969672033429414],
            [110.423846259123607, -6.969696111560229],
            [110.423859562915212, -6.969701027475012],
            [110.423913213624189, -6.969750632948881],
            [110.423984557861772, -6.969841951568678],
            [110.423987467058311, -6.969845675257554],
            [110.424028793461275, -6.969837468955592],
            [110.424066018469787, -6.969804487618871],
            [110.42409912192042, -6.969763244578109],
            [110.424181850289031, -6.969684906959677],
            [110.424479670175316, -6.96940454272565],
            [110.424913989372754, -6.968996365878849],
            [110.424975306992351, -6.968948275090149],
            [110.425007738590736, -6.968922839308109],
            [110.425100734431226, -6.968849602231695],
            [110.425150896972255, -6.968922431133377],
            [110.425333296415118, -6.96921210998038],
            [110.425510027095129, -6.969510827411708],
            [110.425548387643573, -6.969836513190559],
            [110.425494046426735, -6.97148273382741],
            [110.425585581114362, -6.971817529784397],
            [110.425742337060697, -6.972025767586125],
            [110.426324637347506, -6.972415432986605],
            [110.426654080817571, -6.972759563211557],
            [110.426842879284564, -6.973139704715917],
            [110.42699965239531, -6.973800218817557],
            [110.427202063719918, -6.974153240136995],
            [110.427396408331319, -6.974328109223477],
            [110.427599449226932, -6.974461270102521],
            [110.427938820988913, -6.974552136686031],
            [110.428311402052444, -6.974552587900571],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 31,
        id: 33,
        area: 342255.029171,
        perimeter: 3409.597493,
        kode_pos: 50613,
        kode_kec: 3374130,
        kode_kel: 3374070003,
        kec: "Semarang Tengah",
        kel: "Jagalan",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.428125363016918, -6.977284113722862],
            [110.428281149608395, -6.977103391771704],
            [110.428683909025125, -6.976714921446374],
            [110.428930036409398, -6.976480035360136],
            [110.429163389120518, -6.976281315816875],
            [110.429539433884159, -6.97599231322403],
            [110.429712810845842, -6.975793520894729],
            [110.429883621200773, -6.975377632518708],
            [110.429911107959953, -6.975106299615071],
            [110.429846520378192, -6.974943401975052],
            [110.429587079334667, -6.974725995770654],
            [110.429023431926169, -6.97458058599301],
            [110.428663897143622, -6.974553014519363],
            [110.428311402052444, -6.974552587900571],
            [110.428324385391235, -6.974273380481349],
            [110.428309088784815, -6.973980691957768],
            [110.429835580812679, -6.973930288667122],
            [110.430530081084058, -6.973949217718064],
            [110.430532180413394, -6.974084903316861],
            [110.430663163660185, -6.974085061247947],
            [110.430669833137372, -6.974184570206487],
            [110.430887507866771, -6.97418080276774],
            [110.431169907973896, -6.974175914934743],
            [110.431169908028792, -6.974175916810532],
            [110.431243491079229, -6.976202627019858],
            [110.431243491323784, -6.976202635451644],
            [110.431292261141181, -6.977884222586528],
            [110.431534300006362, -6.983098771429947],
            [110.431598863326485, -6.985452531306819],
            [110.431601042934375, -6.985492762232728],
            [110.429946591022002, -6.985526798243511],
            [110.42893881811203, -6.985570610940181],
            [110.428939454353753, -6.985046166335998],
            [110.428952510517021, -6.984078310288027],
            [110.42895346498797, -6.983291350182614],
            [110.428954649695115, -6.982314434153058],
            [110.428967071439445, -6.981636034260331],
            [110.428967466256751, -6.981310395564746],
            [110.429003531243993, -6.980731525116233],
            [110.429006272010426, -6.980641073103662],
            [110.429038977604733, -6.979962697697152],
            [110.42902513934952, -6.97970940601962],
            [110.428970738295547, -6.97931133672288],
            [110.428846863542617, -6.978813682476844],
            [110.428737296463282, -6.97841554639464],
            [110.428532750777251, -6.97795397657414],
            [110.42820106369409, -6.97735656969322],
            [110.428125363016918, -6.977284113722862],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 32,
        id: 34,
        area: 221420.06472200001,
        perimeter: 2484.030355,
        kode_pos: 50138,
        kode_kec: 3374130,
        kode_kel: 3374070014,
        kec: "Semarang Tengah",
        kel: "Bangunharjo",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.42342497208017, -6.979110809929616],
            [110.422862371326289, -6.978910046618886],
            [110.422726139731822, -6.978931895100021],
            [110.422635896406007, -6.979019845118821],
            [110.422613450899462, -6.979195054866769],
            [110.422561086518726, -6.979909587145189],
            [110.422501510286949, -6.980045197082241],
            [110.422425592105057, -6.980126513866617],
            [110.422110303450111, -6.980234674092783],
            [110.421286548797724, -6.980496054219428],
            [110.420944081161565, -6.97869550574285],
            [110.420741508853865, -6.977555521486583],
            [110.420240580529253, -6.974949796414943],
            [110.420001691835154, -6.973891177041945],
            [110.420027759632447, -6.973864072491282],
            [110.422707335898593, -6.971144652204877],
            [110.422793054129968, -6.971080699302102],
            [110.422935994061959, -6.97144190663254],
            [110.423142291736099, -6.972646741012928],
            [110.423019391419857, -6.972746091695691],
            [110.422538284211328, -6.972890232167374],
            [110.422622170199759, -6.974618030129266],
            [110.422649168495781, -6.974979884154012],
            [110.422745596706619, -6.975857418000368],
            [110.42296948951919, -6.9769974278738],
            [110.42342497208017, -6.979110809929616],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 33,
        id: 35,
        area: 285188.07105600002,
        perimeter: 2268.437954,
        kode_pos: 50133,
        kode_kec: 3374130,
        kode_kel: 3374070009,
        kec: "Semarang Tengah",
        kel: "Kembang Sari",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.420001691835154, -6.973891177041945],
            [110.420240580529253, -6.974949796414943],
            [110.420741508853865, -6.977555521486583],
            [110.420944081161565, -6.97869550574285],
            [110.421286548797724, -6.980496054219428],
            [110.42081664686836, -6.980640136309402],
            [110.420225697090331, -6.980838411927811],
            [110.420122664093753, -6.980883512898587],
            [110.419506833500421, -6.98129884941652],
            [110.41929024391905, -6.981434265624786],
            [110.418829983636527, -6.981641745730205],
            [110.418378265990455, -6.981804008304255],
            [110.418022270652429, -6.981884978885503],
            [110.417518814632786, -6.982019887689546],
            [110.417406819203123, -6.981305306257077],
            [110.417177717947837, -6.980120060870372],
            [110.417081297436752, -6.979622438451061],
            [110.416654081034281, -6.978047990992668],
            [110.416482119983527, -6.977441728947356],
            [110.417507958804535, -6.976384670551143],
            [110.418468745094714, -6.975427030090975],
            [110.420001691835154, -6.973891177041945],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 34,
        id: 36,
        area: 265967.54587600002,
        perimeter: 2506.801758,
        kode_pos: 50139,
        kode_kec: 3374130,
        kode_kel: 3374070013,
        kec: "Semarang Tengah",
        kel: "Pandan Sari",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.422707335898593, -6.971144652204877],
            [110.420027759632447, -6.973864072491282],
            [110.420001691835154, -6.973891177041945],
            [110.418468745094714, -6.975427030090975],
            [110.417507958804535, -6.976384670551143],
            [110.416482119983527, -6.977441728947356],
            [110.416425926681569, -6.977242658154557],
            [110.415795535366442, -6.974799589556124],
            [110.415383094603897, -6.974319666855645],
            [110.415179199446186, -6.974029957945309],
            [110.415125411271987, -6.973911705150213],
            [110.414928154149649, -6.973478036712991],
            [110.414928174850502, -6.973478026708018],
            [110.414979226400533, -6.973453929073182],
            [110.415623666309472, -6.973150449167874],
            [110.415737026418839, -6.97309706544271],
            [110.415790012773513, -6.973072113009352],
            [110.41646839823359, -6.972752645671685],
            [110.416916719363385, -6.972541520125699],
            [110.417380579723911, -6.972323076099957],
            [110.419535616472331, -6.9712678354555],
            [110.419886602355035, -6.971095969407294],
            [110.420312823336303, -6.970890872680021],
            [110.420749317037618, -6.970680777856429],
            [110.420754174751963, -6.970678406516792],
            [110.421395917968724, -6.970365132767292],
            [110.421618975068569, -6.970256859560727],
            [110.421656339841533, -6.970238814246039],
            [110.422287274944935, -6.969950129244147],
            [110.422356672044728, -6.969923166798643],
            [110.422481028386514, -6.970356344277183],
            [110.422707335898593, -6.971144652204877],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 35,
        id: 37,
        area: 631638.15567999997,
        perimeter: 3527.430236,
        kode_pos: 50132,
        kode_kec: 3374130,
        kode_kel: 3374070011,
        kec: "Semarang Tengah",
        kel: "Sekayu",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.416482119983527, -6.977441728947356],
            [110.416654081034281, -6.978047990992668],
            [110.417081297436752, -6.979622438451061],
            [110.417177717947837, -6.980120060870372],
            [110.417406819203123, -6.981305306257077],
            [110.417518814632786, -6.982019887689546],
            [110.41741608625459, -6.982047049896865],
            [110.416964174518355, -6.982136946693149],
            [110.416443200108802, -6.982253894146508],
            [110.416251051297465, -6.982298883957293],
            [110.415983288185288, -6.982407098657748],
            [110.415406639253334, -6.982704886205067],
            [110.415099699940271, -6.982921597935596],
            [110.414853335922587, -6.983111248068011],
            [110.414538928076936, -6.983418405215266],
            [110.414391331556274, -6.983581041134975],
            [110.414338633736662, -6.983635248750712],
            [110.413884876189925, -6.98407791494423],
            [110.41342178336356, -6.984511523503224],
            [110.413221678218278, -6.984583638599524],
            [110.413019649064211, -6.984610523677912],
            [110.412714098639114, -6.984619188736386],
            [110.412505619048858, -6.984600838028402],
            [110.41173490210727, -6.984437058090694],
            [110.411633361302449, -6.984418840399517],
            [110.411108524633079, -6.984454367280662],
            [110.410480924939762, -6.98454403812723],
            [110.410131750387706, -6.984597874482112],
            [110.410037536616841, -6.98464160948178],
            [110.409896559260716, -6.984707149824092],
            [110.409733856909313, -6.984777019121112],
            [110.409552636325486, -6.984795535167678],
            [110.409338476935915, -6.984721133158246],
            [110.40895095691279, -6.984292291606834],
            [110.408614858478387, -6.984118312214658],
            [110.408550641066569, -6.984088965791624],
            [110.40945533614682, -6.983457294715029],
            [110.409872081012523, -6.982535175138184],
            [110.411140478763599, -6.97971456280946],
            [110.411653570708054, -6.978566423510747],
            [110.412376824602802, -6.976948178636939],
            [110.412535123640055, -6.976595600823181],
            [110.413095862164482, -6.975438473016903],
            [110.41334131459233, -6.974841774356595],
            [110.413733087536201, -6.974055443700791],
            [110.413908195668469, -6.97397410918129],
            [110.414518127485181, -6.973676207298252],
            [110.414928154149649, -6.973478036712991],
            [110.415125411271987, -6.973911705150213],
            [110.415179199446186, -6.974029957945309],
            [110.415383094603897, -6.974319666855645],
            [110.415795535366442, -6.974799589556124],
            [110.416425926681569, -6.977242658154557],
            [110.416482119983527, -6.977441728947356],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 36,
        id: 38,
        area: 351305.73821500002,
        perimeter: 2530.782789,
        kode_pos: 50134,
        kode_kec: 3374130,
        kode_kel: 3374070001,
        kec: "Semarang Tengah",
        kel: "Miroto",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.417518814632786, -6.982019887689546],
            [110.418022270652429, -6.981884978885503],
            [110.418378265990455, -6.981804008304255],
            [110.418829983636527, -6.981641745730205],
            [110.41929024391905, -6.981434265624786],
            [110.419506833500421, -6.98129884941652],
            [110.420122664093753, -6.980883512898587],
            [110.420225697090331, -6.980838411927811],
            [110.42081664686836, -6.980640136309402],
            [110.421286548797724, -6.980496054219428],
            [110.421746002686291, -6.982839337932572],
            [110.422048627056, -6.984386492954554],
            [110.422350831958099, -6.985951530995396],
            [110.422237668545932, -6.985951599704733],
            [110.42024244210711, -6.985849649683725],
            [110.41819831894253, -6.985711448893601],
            [110.41770130636209, -6.985665607747768],
            [110.416701091286527, -6.985546779713644],
            [110.41645584424495, -6.985519339706927],
            [110.416131133949023, -6.985464664644742],
            [110.415779592465839, -6.985373774077194],
            [110.415344910143233, -6.985255643642409],
            [110.414182329351746, -6.984892380305125],
            [110.41431783543932, -6.983987997769476],
            [110.41442197706732, -6.983734852841927],
            [110.414338633736662, -6.983635248750712],
            [110.414391331556274, -6.983581041134975],
            [110.414538928076936, -6.983418405215266],
            [110.414853335922587, -6.983111248068011],
            [110.415099699940271, -6.982921597935596],
            [110.415406639253334, -6.982704886205067],
            [110.415983288185288, -6.982407098657748],
            [110.416251051297465, -6.982298883957293],
            [110.416443200108802, -6.982253894146508],
            [110.416964174518355, -6.982136946693149],
            [110.41741608625459, -6.982047049896865],
            [110.417518814632786, -6.982019887689546],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 37,
        id: 39,
        area: 347146.31222099997,
        perimeter: 2509.655424,
        kode_pos: 50135,
        kode_kec: 3374130,
        kode_kel: 3374070002,
        kec: "Semarang Tengah",
        kel: "Brumbungan",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.421746002686291, -6.982839337932572],
            [110.422370375985651, -6.982704420163865],
            [110.422477347715741, -6.98267741458721],
            [110.422651684316904, -6.982623354879759],
            [110.422786108977462, -6.982587337309815],
            [110.422938073785105, -6.982551341158945],
            [110.423103324067711, -6.982524406729334],
            [110.423340454580554, -6.982479469075658],
            [110.423704687271297, -6.982371367936167],
            [110.423615437901688, -6.982018483448779],
            [110.423670693050838, -6.981946186767011],
            [110.424276276842463, -6.98181124337363],
            [110.424791158173477, -6.981585733325432],
            [110.425651190642611, -6.981586781192108],
            [110.426269732157564, -6.981497078571888],
            [110.426876729416492, -6.981362133449636],
            [110.427453760416583, -6.981209060090436],
            [110.429006272010426, -6.980641073103662],
            [110.429003531243993, -6.980731525116233],
            [110.428967466256751, -6.981310395564746],
            [110.428967071439445, -6.981636034260331],
            [110.428954649695115, -6.982314434153058],
            [110.42895346498797, -6.983291350182614],
            [110.428952510517021, -6.984078310288027],
            [110.428939454353753, -6.985046166335998],
            [110.42893881811203, -6.985570610940181],
            [110.428469157433668, -6.98559756925926],
            [110.42825357080001, -6.985606158019857],
            [110.426498527533141, -6.985739709392504],
            [110.424585632214445, -6.985864015996436],
            [110.423428244805521, -6.985871647526258],
            [110.422350831958099, -6.985951530995396],
            [110.422048627056, -6.984386492954554],
            [110.421746002686291, -6.982839337932572],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 38,
        id: 40,
        area: 521309.86254900001,
        perimeter: 4734.620979,
        kode_pos: 50241,
        kode_kec: 3374130,
        kode_kel: 3374070018,
        kec: "Semarang Tengah",
        kel: "Pekunden",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.422350831958099, -6.985951530995396],
            [110.423428244805521, -6.985871647526258],
            [110.424585632214445, -6.985864015996436],
            [110.426498527533141, -6.985739709392504],
            [110.42825357080001, -6.985606158019857],
            [110.428469157433668, -6.98559756925926],
            [110.428454397170739, -6.986103905669132],
            [110.428066936058528, -6.9867004407207],
            [110.427894935615797, -6.98692637023441],
            [110.42743446051027, -6.987540907064207],
            [110.427298161051993, -6.987721652031884],
            [110.426599070342249, -6.988435398675342],
            [110.426431128517677, -6.988579922735212],
            [110.426137769304347, -6.988796658228403],
            [110.42595484402699, -6.988932118323629],
            [110.425730036934311, -6.989076572827883],
            [110.425495643906558, -6.989193878953589],
            [110.425340792694186, -6.989275099862181],
            [110.424996552970484, -6.989455590417914],
            [110.42465459840534, -6.989617992443939],
            [110.424396723319546, -6.989735269304817],
            [110.424209679625605, -6.989787248749034],
            [110.42398376025929, -6.989850031626058],
            [110.423983765821276, -6.98985000738188],
            [110.423966034160401, -6.989732103185827],
            [110.42391956996758, -6.989653317715458],
            [110.423110951629113, -6.989227147771387],
            [110.422998680774953, -6.989190828235545],
            [110.422945224087528, -6.989180219915713],
            [110.422906569928671, -6.989172549109394],
            [110.422757924474979, -6.98919053338707],
            [110.422648586376482, -6.989261697485862],
            [110.422527835256361, -6.9894887536362],
            [110.422359353519028, -6.989841322388367],
            [110.421356278332809, -6.989344417233579],
            [110.419715258960309, -6.988544915096863],
            [110.418296906614785, -6.987860011643623],
            [110.417598739559821, -6.98758219479571],
            [110.417003918953228, -6.987415286669637],
            [110.414055753526171, -6.986532762068293],
            [110.413803320992315, -6.9864605909292],
            [110.413656749081852, -6.986417791435978],
            [110.411495908019575, -6.985433626261929],
            [110.410037374209168, -6.984771201668567],
            [110.409896559260716, -6.984707149824092],
            [110.410037536616841, -6.98464160948178],
            [110.410131750387706, -6.984597874482112],
            [110.410480924939762, -6.98454403812723],
            [110.411108524633079, -6.984454367280662],
            [110.411633361302449, -6.984418840399517],
            [110.41173490210727, -6.984437058090694],
            [110.412505619048858, -6.984600838028402],
            [110.412714098639114, -6.984619188736386],
            [110.413019649064211, -6.984610523677912],
            [110.413221678218278, -6.984583638599524],
            [110.41342178336356, -6.984511523503224],
            [110.413884876189925, -6.98407791494423],
            [110.414338633736662, -6.983635248750712],
            [110.41442197706732, -6.983734852841927],
            [110.41431783543932, -6.983987997769476],
            [110.414182329351746, -6.984892380305125],
            [110.415344910143233, -6.985255643642409],
            [110.415779592465839, -6.985373774077194],
            [110.416131133949023, -6.985464664644742],
            [110.41645584424495, -6.985519339706927],
            [110.416701091286527, -6.985546779713644],
            [110.41770130636209, -6.985665607747768],
            [110.41819831894253, -6.985711448893601],
            [110.42024244210711, -6.985849649683725],
            [110.422237668545932, -6.985951599704733],
            [110.422350831958099, -6.985951530995396],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 39,
        id: 41,
        area: 512533.17104699998,
        perimeter: 3121.851945,
        kode_pos: 50241,
        kode_kec: 3374130,
        kode_kel: 3374070017,
        kec: "Semarang Tengah",
        kel: "Karang Kidul",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.428469157433668, -6.98559756925926],
            [110.42893881811203, -6.985570610940181],
            [110.429946591022002, -6.985526798243511],
            [110.431601042934375, -6.985492762232728],
            [110.431859638714315, -6.989841186140612],
            [110.431918686425846, -6.990886944183869],
            [110.431966164405239, -6.992698343644469],
            [110.431980248867518, -6.994273934778962],
            [110.431184368707065, -6.994139611529006],
            [110.430711872564189, -6.993931022252731],
            [110.42953370711858, -6.993336015583258],
            [110.428855846128712, -6.993015132706847],
            [110.428844656357086, -6.993009835730103],
            [110.428741820469838, -6.992960868293419],
            [110.426960120947456, -6.992083538508424],
            [110.42370326273489, -6.990490166326604],
            [110.42395499141621, -6.989975432840135],
            [110.42398376025929, -6.989850031626058],
            [110.424209679625605, -6.989787248749034],
            [110.424396723319546, -6.989735269304817],
            [110.42465459840534, -6.989617992443939],
            [110.424996552970484, -6.989455590417914],
            [110.425340792694186, -6.989275099862181],
            [110.425495643906558, -6.989193878953589],
            [110.425730036934311, -6.989076572827883],
            [110.42595484402699, -6.988932118323629],
            [110.426137769304347, -6.988796658228403],
            [110.426431128517677, -6.988579922735212],
            [110.426599070342249, -6.988435398675342],
            [110.427298161051993, -6.987721652031884],
            [110.42743446051027, -6.987540907064207],
            [110.427894935615797, -6.98692637023441],
            [110.428066936058528, -6.9867004407207],
            [110.428454397170739, -6.986103905669132],
            [110.428469157433668, -6.98559756925926],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 40,
        id: 42,
        area: 472428.28219699999,
        perimeter: 3336.7302,
        kode_pos: 50126,
        kode_kec: 3374110,
        kode_kel: 3374050027,
        kec: "Semarang Timur",
        kel: "Mlatiharjo",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.438709289482702, -6.970422053263158],
            [110.438732659254299, -6.970277498339604],
            [110.438813945065547, -6.969961000573166],
            [110.438889791123145, -6.969698769633759],
            [110.439017590406223, -6.969282825958212],
            [110.439129396588939, -6.968993500982421],
            [110.439584232976728, -6.967619116401367],
            [110.440111705465, -6.965584491574293],
            [110.440299504408898, -6.964906297224079],
            [110.44051713541694, -6.964119591351863],
            [110.44088709086661, -6.96203050506337],
            [110.440940263051942, -6.960845599757508],
            [110.440956485701875, -6.960284947174841],
            [110.441576214953557, -6.960122706013451],
            [110.442382174757824, -6.959883977282639],
            [110.442393843895047, -6.959920891532071],
            [110.442395335947467, -6.959925611503266],
            [110.442442868914867, -6.960418820804366],
            [110.442530661881932, -6.960786248784017],
            [110.442706525524599, -6.961286017335989],
            [110.442798917196654, -6.961532362652475],
            [110.442799690822255, -6.961533780144169],
            [110.443109213221874, -6.962100908312406],
            [110.443284949285442, -6.962525610623919],
            [110.443562726380321, -6.963191705895397],
            [110.443795376231208, -6.963820133939687],
            [110.443975369887795, -6.96441088595525],
            [110.444047850909428, -6.964774235557859],
            [110.444046108755884, -6.965076737548468],
            [110.444044168275084, -6.965087011860298],
            [110.443955196885312, -6.965558090280761],
            [110.443883168698918, -6.965986805539006],
            [110.443796994824709, -6.966540626064735],
            [110.443791523651427, -6.966577879205641],
            [110.443791521583776, -6.966577893284164],
            [110.443731414622491, -6.966987160224196],
            [110.443632764727241, -6.967645289628488],
            [110.443564340501055, -6.968209419492019],
            [110.443496115687793, -6.968604286439231],
            [110.443424064220082, -6.969051808488858],
            [110.443355830237579, -6.969454198177085],
            [110.443291444848512, -6.969785125731017],
            [110.443185331232229, -6.970386825045611],
            [110.443135954343191, -6.970759145655877],
            [110.443067697549424, -6.971180342217886],
            [110.443014600437451, -6.971515044385415],
            [110.442923583580551, -6.972082908725135],
            [110.442847681924704, -6.972601892626271],
            [110.442811808893552, -6.972855429154834],
            [110.442002357399815, -6.972000026709619],
            [110.441409800741994, -6.971420410854286],
            [110.440906037313709, -6.971112266080899],
            [110.440223951156767, -6.970885319558125],
            [110.439260466687514, -6.97058567379594],
            [110.438709289482702, -6.970422053263158],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 41,
        id: 43,
        area: 272117.39075899997,
        perimeter: 2664.219585,
        kode_pos: 50227,
        kode_kec: 3374110,
        kode_kel: 3374050029,
        kec: "Semarang Timur",
        kel: "Rejomulyo",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.439649531965415, -6.960807890603369],
            [110.439683051171301, -6.960871249197799],
            [110.439712049748451, -6.960988875777206],
            [110.439739383160372, -6.961079363713336],
            [110.439746022413445, -6.961206009369397],
            [110.439745829296399, -6.961368829160365],
            [110.439738846716239, -6.961531640909612],
            [110.439731885586014, -6.961676361569719],
            [110.439670075151568, -6.961793880594904],
            [110.439471279423103, -6.961965510649096],
            [110.439288662229004, -6.962091932006194],
            [110.437719143453393, -6.963057943187611],
            [110.435488331441135, -6.964394027210953],
            [110.434614633217379, -6.964962852924581],
            [110.433388227154381, -6.96571216520246],
            [110.432299174564918, -6.96633500131752],
            [110.430959168548881, -6.967102471796459],
            [110.430898000535606, -6.964313693857296],
            [110.43094597655012, -6.964307173079296],
            [110.43104954894396, -6.964280161074065],
            [110.431247401740521, -6.96418994364678],
            [110.431920311824683, -6.963801794085873],
            [110.433256488698689, -6.963043571344852],
            [110.434361635571705, -6.96243884257587],
            [110.435458912784583, -6.96178887384286],
            [110.438013700197075, -6.96038985487718],
            [110.438248086466814, -6.960263495442614],
            [110.438470025316661, -6.960137121109733],
            [110.438574455908309, -6.960101062808185],
            [110.438662163545629, -6.96009212131028],
            [110.438750132582257, -6.960101271217077],
            [110.438875389809297, -6.960155693107158],
            [110.439067606853541, -6.960282558798761],
            [110.439439246654345, -6.960590548151008],
            [110.439589595781726, -6.960717364047919],
            [110.439649531965415, -6.960807890603369],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 42,
        id: 44,
        area: 1202696.28691,
        perimeter: 4919.742044,
        kode_pos: 50228,
        kode_kec: 3374110,
        kode_kel: 3374050030,
        kec: "Semarang Timur",
        kel: "Kemijen",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.440956485701875, -6.960284947174841],
            [110.440438576673714, -6.960465093250644],
            [110.440000170928997, -6.960636440165834],
            [110.439751095851364, -6.960744691981938],
            [110.439649531965415, -6.960807890603369],
            [110.439589595781726, -6.960717364047919],
            [110.439439246654345, -6.960590548151008],
            [110.439067606853541, -6.960282558798761],
            [110.438875389809297, -6.960155693107158],
            [110.438750132582257, -6.960101271217077],
            [110.438662163545629, -6.96009212131028],
            [110.438574455908309, -6.960101062808185],
            [110.438470025316661, -6.960137121109733],
            [110.438248086466814, -6.960263495442614],
            [110.438013700197075, -6.96038985487718],
            [110.435458912784583, -6.96178887384286],
            [110.434361635571705, -6.96243884257587],
            [110.433256488698689, -6.963043571344852],
            [110.431920311824683, -6.963801794085873],
            [110.431247401740521, -6.96418994364678],
            [110.43104954894396, -6.964280161074065],
            [110.43094597655012, -6.964307173079296],
            [110.430898000535606, -6.964313693857296],
            [110.430898000227046, -6.964313679789324],
            [110.430896825635756, -6.964259832382465],
            [110.430612257282689, -6.954640046776228],
            [110.430605552150453, -6.954410279924911],
            [110.432423743507172, -6.953770002898861],
            [110.433653405170404, -6.95333340805507],
            [110.434057521130512, -6.953179311955455],
            [110.434412212651196, -6.953044061834261],
            [110.435510088327604, -6.952601929426876],
            [110.435881508953955, -6.952384682124603],
            [110.436734759177583, -6.951834754117534],
            [110.437084617373159, -6.951655105538983],
            [110.437840759601286, -6.9513415624941],
            [110.439016686545713, -6.950848450895586],
            [110.439990905256224, -6.95044755066121],
            [110.440012932018718, -6.950438486407652],
            [110.440367293240456, -6.950292662696823],
            [110.440738495065375, -6.950139908491902],
            [110.440760235779365, -6.950130980443198],
            [110.440983647833392, -6.950039401712261],
            [110.44086455691756, -6.950282192652514],
            [110.440531841245217, -6.950961460828515],
            [110.440308010319896, -6.951576494682032],
            [110.440191426748427, -6.95205789464764],
            [110.440173219614238, -6.95236106353792],
            [110.440235076475759, -6.952878343799875],
            [110.440292677151973, -6.953221730412277],
            [110.440408100304339, -6.953721239225252],
            [110.440630562654306, -6.954427551920126],
            [110.44094931667594, -6.955351221237851],
            [110.442382174757824, -6.959883977282639],
            [110.441576214953557, -6.960122706013451],
            [110.440956485701875, -6.960284947174841],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 43,
        id: 45,
        area: 446988.04066,
        perimeter: 2843.971679,
        kode_pos: 50126,
        kode_kec: 3374110,
        kode_kel: 3374050026,
        kec: "Semarang Timur",
        kel: "Bugangan",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.433946688695642, -6.969702122531434],
            [110.434356054242315, -6.969693364332705],
            [110.435063856081953, -6.969703256613818],
            [110.435646845559248, -6.969758226525228],
            [110.436104783107581, -6.969822092060884],
            [110.436423849846861, -6.969858654907361],
            [110.437076085301911, -6.969967978963018],
            [110.437672818989995, -6.970122463844156],
            [110.438219479954256, -6.970276888469711],
            [110.438709289482702, -6.970422053263158],
            [110.439260466687514, -6.97058567379594],
            [110.440223951156767, -6.970885319558125],
            [110.440906037313709, -6.971112266080899],
            [110.441409800741994, -6.971420410854286],
            [110.442002357399815, -6.972000026709619],
            [110.442811808893552, -6.972855429154834],
            [110.442733637894037, -6.973542108440686],
            [110.44266903787171, -6.974053582780425],
            [110.442648564441882, -6.974183340042626],
            [110.44264856350064, -6.974183346007997],
            [110.441899390204782, -6.974131613709793],
            [110.441320970275058, -6.974236263126293],
            [110.440852538241145, -6.974297403959168],
            [110.440127968479942, -6.974460564517734],
            [110.440028842050268, -6.974234732704226],
            [110.438714685805792, -6.974682660257328],
            [110.437997910338112, -6.97496664360862],
            [110.437414823693928, -6.975194672946512],
            [110.437063774223745, -6.975325168757095],
            [110.436642609839296, -6.975476200860344],
            [110.436052237564908, -6.97567449808298],
            [110.435603631489897, -6.975845827385194],
            [110.434968128511485, -6.976071205854936],
            [110.434867699227709, -6.975555489654564],
            [110.434661964248335, -6.974569279090238],
            [110.434477923043445, -6.973754959776862],
            [110.434191852182948, -6.972623924165121],
            [110.434101877597215, -6.972162493639495],
            [110.434020019733353, -6.971538253033325],
            [110.433946688695642, -6.969702122531434],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 44,
        id: 46,
        area: 241638.610988,
        perimeter: 2094.45402,
        kode_pos: 50123,
        kode_kec: 3374110,
        kode_kel: 3374050025,
        kec: "Semarang Timur",
        kel: "Kebonagung",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.431041786242247, -6.969798040235338],
            [110.431455113279895, -6.969771292763088],
            [110.431871561873066, -6.969753702563174],
            [110.432273854646994, -6.969745140528486],
            [110.433550881066012, -6.969728582001101],
            [110.433946688695642, -6.969702122531434],
            [110.434020019733353, -6.971538253033325],
            [110.434101877597215, -6.972162493639495],
            [110.434191852182948, -6.972623924165121],
            [110.434477923043445, -6.973754959776862],
            [110.434661964248335, -6.974569279090238],
            [110.434867699227709, -6.975555489654564],
            [110.433374545393264, -6.975924567280702],
            [110.43290057502729, -6.976014453607404],
            [110.432517742679792, -6.976068266840764],
            [110.432222610038735, -6.976122185235709],
            [110.431571023394469, -6.976175723699698],
            [110.431243491323784, -6.976202635451644],
            [110.431243491079229, -6.976202627019858],
            [110.431169908028792, -6.974175916810532],
            [110.431169907973896, -6.974175914934743],
            [110.431041786242247, -6.969798040235338],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 45,
        id: 47,
        area: 414984.64841999998,
        perimeter: 2866.189533,
        kode_pos: 50124,
        kode_kec: 3374110,
        kode_kel: 3374050024,
        kec: "Semarang Timur",
        kel: "Sarirejo",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.434867699227709, -6.975555489654564],
            [110.434968128511485, -6.976071205854936],
            [110.43503853632167, -6.976433111869027],
            [110.435228749906543, -6.977527850272556],
            [110.435228066913595, -6.978097718699325],
            [110.435172326957868, -6.978812249912353],
            [110.435089167577587, -6.979743841283679],
            [110.43500529159509, -6.981272437493088],
            [110.434922163622332, -6.98217689209176],
            [110.434921382105131, -6.982828170130045],
            [110.4348655428347, -6.983624110832459],
            [110.434837460788998, -6.984392948133078],
            [110.434726201606182, -6.98531556209926],
            [110.4345540107864, -6.985315253401907],
            [110.433296386323249, -6.985395153363307],
            [110.43215475402171, -6.985475189646353],
            [110.431607870849732, -6.985492621754136],
            [110.431601042934375, -6.985492762232728],
            [110.431598863326485, -6.985452531306819],
            [110.431534300006362, -6.983098771429947],
            [110.431292261141181, -6.977884222586528],
            [110.431243491323784, -6.976202635451644],
            [110.431571023394469, -6.976175723699698],
            [110.432222610038735, -6.976122185235709],
            [110.432517742679792, -6.976068266840764],
            [110.43290057502729, -6.976014453607404],
            [110.433374545393264, -6.975924567280702],
            [110.434867699227709, -6.975555489654564],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 46,
        id: 48,
        area: 880408.14239299996,
        perimeter: 3924.527059,
        kode_pos: 50125,
        kode_kec: 3374110,
        kode_kel: 3374050023,
        kec: "Semarang Timur",
        kel: "Rejosari",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.434726201606182, -6.98531556209926],
            [110.434837460788998, -6.984392948133078],
            [110.4348655428347, -6.983624110832459],
            [110.434921382105131, -6.982828170130045],
            [110.434922163622332, -6.98217689209176],
            [110.43500529159509, -6.981272437493088],
            [110.435089167577587, -6.979743841283679],
            [110.435172326957868, -6.978812249912353],
            [110.435228066913595, -6.978097718699325],
            [110.435228749906543, -6.977527850272556],
            [110.43503853632167, -6.976433111869027],
            [110.434968128511485, -6.976071205854936],
            [110.435603631489897, -6.975845827385194],
            [110.436052237564908, -6.97567449808298],
            [110.436642609839296, -6.975476200860344],
            [110.437063774223745, -6.975325168757095],
            [110.437414823693928, -6.975194672946512],
            [110.437997910338112, -6.97496664360862],
            [110.438714685805792, -6.974682660257328],
            [110.440028842050268, -6.974234732704226],
            [110.440127968479942, -6.974460564517734],
            [110.440852538241145, -6.974297403959168],
            [110.441320970275058, -6.974236263126293],
            [110.441899390204782, -6.974131613709793],
            [110.44264856350064, -6.974183346007997],
            [110.442542811960351, -6.974853580582488],
            [110.442426183189298, -6.975685744107829],
            [110.442338888829283, -6.97628746511962],
            [110.442207253516685, -6.977095449785549],
            [110.442191046751418, -6.977156173971112],
            [110.441990087199329, -6.97837463011766],
            [110.441944472511594, -6.97874695478793],
            [110.441838256234149, -6.979431403956906],
            [110.44172438132756, -6.980224924586538],
            [110.441674618659221, -6.980594859821006],
            [110.441636836880519, -6.98092174615767],
            [110.441545938713631, -6.981499827826283],
            [110.441413208803013, -6.982319655705068],
            [110.441326013901829, -6.982834864106325],
            [110.441253988394209, -6.983256055465475],
            [110.441109713643115, -6.98428650776443],
            [110.441029935264169, -6.984895759766222],
            [110.440890358946149, -6.985893705170937],
            [110.440890356919098, -6.985893715606894],
            [110.439728451650907, -6.985755616714854],
            [110.4388348385569, -6.98565690387981],
            [110.437819067154891, -6.985545296318377],
            [110.43715998792517, -6.985463100106315],
            [110.436449104299243, -6.985407977702565],
            [110.435065780741596, -6.985324912402763],
            [110.434726201606182, -6.98531556209926],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 47,
        id: 49,
        area: 2273001.596165,
        perimeter: 7258.207703,
        kode_pos: 50117,
        kode_kec: 3374090,
        kode_kel: 3374040012,
        kec: "Genuk",
        kel: "Banjar Dowo",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.481752033558521, -6.969195939704164],
            [110.481717827321759, -6.969150674117514],
            [110.48156608858767, -6.968987686618648],
            [110.481310565277639, -6.968788402725573],
            [110.481096737947567, -6.968489662736366],
            [110.481007217764159, -6.968344834618521],
            [110.480505876662377, -6.967611589148345],
            [110.480023298109472, -6.966796953357696],
            [110.47933232109942, -6.965638354433364],
            [110.479125758450095, -6.965167755071571],
            [110.478726662765311, -6.963819519047986],
            [110.47863994659977, -6.963186230588388],
            [110.478701704676027, -6.962082733891592],
            [110.478783481421857, -6.961295855642829],
            [110.478947606722002, -6.960228653990888],
            [110.479434243075616, -6.958637161365754],
            [110.479799255714511, -6.957280720285226],
            [110.480184286120121, -6.95624089728085],
            [110.480285656767407, -6.955897275104151],
            [110.480326756736829, -6.955309355060901],
            [110.480510033299879, -6.953798937894094],
            [110.480693427183255, -6.952695573480959],
            [110.480997931534759, -6.951303012123737],
            [110.484191354269896, -6.950121392435372],
            [110.485989863184983, -6.949435879093858],
            [110.487000432615105, -6.949093240223172],
            [110.487465132091231, -6.948912830417859],
            [110.490573648973111, -6.947740249472643],
            [110.491947232645174, -6.947237852970241],
            [110.492176271768159, -6.947154080027634],
            [110.492191210785464, -6.947208292558765],
            [110.492207784723334, -6.947316857961759],
            [110.492239566696199, -6.947488759116369],
            [110.492265690923006, -6.947660654208969],
            [110.492270862019211, -6.947850618130971],
            [110.492270745570735, -6.94795916565299],
            [110.492249480416746, -6.948004371059462],
            [110.4922069889179, -6.948058599361684],
            [110.492154352249131, -6.948076634241327],
            [110.492117255029186, -6.948112777039906],
            [110.492058960581346, -6.9481308058457],
            [110.491968953485099, -6.94817593756397],
            [110.491863056051955, -6.94826628041105],
            [110.491794236417221, -6.94833857171654],
            [110.491773254088244, -6.948383777399709],
            [110.491714804148785, -6.948546536172949],
            [110.491629870588, -6.948872087976396],
            [110.491544127106707, -6.949161456339858],
            [110.49145383783673, -6.94946891106961],
            [110.491374414920585, -6.949667829809522],
            [110.491337249440633, -6.949767291905029],
            [110.491310736493091, -6.949957221783231],
            [110.491278565725196, -6.950147145582681],
            [110.491241302909799, -6.950337063909198],
            [110.491214253279466, -6.950499856291795],
            [110.491161206935359, -6.950635483848313],
            [110.491160963703678, -6.950861624453021],
            [110.491144460206158, -6.951476709871455],
            [110.491107323703716, -6.95154903505609],
            [110.491070206653788, -6.951603268989321],
            [110.490927181435623, -6.951756891097478],
            [110.490779034876553, -6.951937644550715],
            [110.490710185293622, -6.952037072505936],
            [110.490656016782083, -6.952163653134585],
            [110.490634965961689, -6.952272178099982],
            [110.490597683177057, -6.952480187573454],
            [110.490570974501892, -6.952588706448001],
            [110.490507460726732, -6.952724322631683],
            [110.490411902226853, -6.952932269391632],
            [110.490221087352609, -6.953330071890594],
            [110.490162919049482, -6.953492830659771],
            [110.490152130157597, -6.953529001574546],
            [110.490162841031164, -6.953565195633499],
            [110.490210269317856, -6.953655703009364],
            [110.490275812446697, -6.953737184245985],
            [110.490292746953202, -6.953773385001042],
            [110.49031164220527, -6.953827679130399],
            [110.490274782380993, -6.953909472229348],
            [110.490244510801801, -6.953976247597654],
            [110.490193110600345, -6.95408987488161],
            [110.490111471886536, -6.954243562733003],
            [110.490063516795871, -6.954379195574371],
            [110.489990198881046, -6.95468666809318],
            [110.489890971859253, -6.954885565103649],
            [110.489723245696538, -6.955120570797137],
            [110.489580765176797, -6.955292284216838],
            [110.489494629054434, -6.955418830185013],
            [110.489387373621881, -6.955454897054405],
            [110.489185339317245, -6.955499907286724],
            [110.488979334702989, -6.955553958774695],
            [110.488880273320547, -6.955599079997342],
            [110.488859007456895, -6.955644285188538],
            [110.488854412708548, -6.955707599635486],
            [110.48882627839339, -6.956087485699933],
            [110.488760752342912, -6.956774882766212],
            [110.488731394625106, -6.958285471059791],
            [110.488740045072205, -6.958918674388055],
            [110.488867968714885, -6.959913831669941],
            [110.488858476583587, -6.960058551464327],
            [110.488812305891983, -6.960112775354633],
            [110.488747776344283, -6.960139842520319],
            [110.488637232721786, -6.960076403674814],
            [110.488462277110997, -6.959931484529953],
            [110.488157960054323, -6.959858790435958],
            [110.488056654113834, -6.959885817725793],
            [110.48803802222757, -6.959849615060117],
            [110.48787116840758, -6.960582130256351],
            [110.487880435325934, -6.960645459671448],
            [110.488286068740265, -6.960682081055316],
            [110.488839081216312, -6.960727906969808],
            [110.488885163887929, -6.960755093636963],
            [110.488894460306028, -6.960791286190484],
            [110.488838963761381, -6.960836454374751],
            [110.48873765763544, -6.960863481796808],
            [110.488700578680692, -6.960881532982618],
            [110.488693447535681, -6.960935799040463],
            [110.488662658693741, -6.961415184010628],
            [110.488655176088358, -6.96153276907583],
            [110.488696477891196, -6.96179513690284],
            [110.488703696112736, -6.961921783483299],
            [110.488692086472199, -6.962193139744095],
            [110.488654090162839, -6.962274509313959],
            [110.488582438907301, -6.962346796867737],
            [110.488480234764424, -6.962419051370468],
            [110.488423518208535, -6.962545628804835],
            [110.488404446525507, -6.962654155694398],
            [110.488385052737073, -6.96279886473877],
            [110.488358342806734, -6.962907383361912],
            [110.488358284005685, -6.962961657055347],
            [110.488403302573374, -6.963187846405519],
            [110.488407184739174, -6.963260215613756],
            [110.488395800243367, -6.963323522682066],
            [110.488270728790525, -6.963612847403117],
            [110.488206305146264, -6.963802735830987],
            [110.488187213732331, -6.963929353928618],
            [110.488206011010732, -6.964074104280283],
            [110.488273622197923, -6.964336500587096],
            [110.488307432717619, -6.964463175930389],
            [110.48830730527331, -6.964580768924058],
            [110.48829948075074, -6.964752627340604],
            [110.488306981750952, -6.964879274213046],
            [110.488329486173896, -6.964996891694995],
            [110.4883398455773, -6.965096404783623],
            [110.48831326149687, -6.965349653523514],
            [110.488301867112995, -6.965422006193939],
            [110.4882791469701, -6.965503392231244],
            [110.488218498767722, -6.965602828464046],
            [110.488184482260806, -6.965666111017025],
            [110.488029667928146, -6.965729262777411],
            [110.487784033197215, -6.96580136172493],
            [110.487481671295598, -6.966009083406581],
            [110.487337830887057, -6.966126520562227],
            [110.487281182295234, -6.966189778495422],
            [110.487234587942751, -6.966248795494587],
            [110.487222866824624, -6.966305370615079],
            [110.487242791508223, -6.966363644407139],
            [110.487277278616574, -6.966397823600396],
            [110.487416952252929, -6.966470340048978],
            [110.487560352728536, -6.966497632380295],
            [110.487590593494204, -6.966524802030102],
            [110.487613078145145, -6.96666051075691],
            [110.487639220860629, -6.96681431469501],
            [110.487643093078489, -6.966895729505183],
            [110.487608734570216, -6.96701328537203],
            [110.487574737511395, -6.967058476638214],
            [110.487521766524026, -6.96712173857857],
            [110.48743471002085, -6.967311602279154],
            [110.487423305610037, -6.967393000521706],
            [110.487415510119561, -6.967537722038972],
            [110.487373629057899, -6.967809045311395],
            [110.487320441754633, -6.968071310687022],
            [110.487237013528159, -6.968306406373895],
            [110.487191552862456, -6.968487269500972],
            [110.487195191247153, -6.968523455938581],
            [110.487210428553681, -6.968559654961178],
            [110.487266969342329, -6.968595898794972],
            [110.48745956566593, -6.968650381438427],
            [110.487535070651532, -6.968677600185539],
            [110.487561350937369, -6.968704765550163],
            [110.487572617845103, -6.968750005881062],
            [110.48757256872743, -6.968795233942203],
            [110.48756120358108, -6.968840449733392],
            [110.487515792095678, -6.968976084832578],
            [110.487454771902435, -6.969156931109153],
            [110.487382718518973, -6.969337765404886],
            [110.487280657898651, -6.969536658371695],
            [110.487254016040254, -6.969581857575108],
            [110.487246581962722, -6.969654214485447],
            [110.487231147995885, -6.969798927690685],
            [110.487208397909811, -6.969907450466525],
            [110.487174351421515, -6.969997869736399],
            [110.487136637034155, -6.970079239399652],
            [110.487125212802397, -6.970178728838997],
            [110.487125328522126, -6.970332504533562],
            [110.487099933912162, -6.970531480643394],
            [110.487059456952309, -6.970811850967414],
            [110.487029252940403, -6.971010821844004],
            [110.48701896025743, -6.971110312502251],
            [110.487008980150947, -6.971182513195385],
            [110.486206126449332, -6.970901379965082],
            [110.48554871501959, -6.970593113523138],
            [110.485022238503589, -6.970330217042349],
            [110.484872955416634, -6.970248643746992],
            [110.484169288538212, -6.969813686429022],
            [110.484022844679274, -6.969723070367539],
            [110.483836784839767, -6.969641456610569],
            [110.483557377652858, -6.969550695158667],
            [110.483267220350569, -6.969459921793993],
            [110.482919361318281, -6.969360039508426],
            [110.482518823386826, -6.969314372988523],
            [110.482310355300442, -6.969287007813686],
            [110.482078436274193, -6.969232480002375],
            [110.481967286095681, -6.969205221327449],
            [110.481855843097435, -6.969187007921023],
            [110.481814256700403, -6.969186962322534],
            [110.481752033558521, -6.969195939704164],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 48,
        id: 50,
        area: 2147473.509666,
        perimeter: 6767.62858,
        kode_pos: 50116,
        kode_kec: 3374090,
        kode_kel: 3374040009,
        kec: "Genuk",
        kel: "Sembungharjo",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.481440080083402, -6.974849105708976],
            [110.481429033229574, -6.974767683068732],
            [110.481429262074812, -6.974559634241198],
            [110.481478939377112, -6.974405913383947],
            [110.48156431224092, -6.974207003644758],
            [110.48159106431973, -6.974062303212252],
            [110.4816026788248, -6.973790947582505],
            [110.481642708421845, -6.973148753006333],
            [110.481616935398065, -6.972660261615043],
            [110.481598641667333, -6.972316508236781],
            [110.48152618432826, -6.972090288382467],
            [110.481382385951846, -6.971909218269346],
            [110.48126488763593, -6.971737222612282],
            [110.48125179021126, -6.97155629596974],
            [110.481278408490638, -6.971275911190419],
            [110.481554150123046, -6.970326424422757],
            [110.481728537220434, -6.969702468349231],
            [110.481774585766232, -6.969503515332637],
            [110.481786070702043, -6.969349752479175],
            [110.481769215883247, -6.969241186620209],
            [110.481752033558521, -6.969195939704164],
            [110.481814256700403, -6.969186962322534],
            [110.481855843097435, -6.969187007921023],
            [110.481967286095681, -6.969205221327449],
            [110.482078436274193, -6.969232480002375],
            [110.482310355300442, -6.969287007813686],
            [110.482518823386826, -6.969314372988523],
            [110.482919361318281, -6.969360039508426],
            [110.483267220350569, -6.969459921793993],
            [110.483557377652858, -6.969550695158667],
            [110.483836784839767, -6.969641456610569],
            [110.484022844679274, -6.969723070367539],
            [110.484169288538212, -6.969813686429022],
            [110.484872955416634, -6.970248643746992],
            [110.485022238503589, -6.970330217042349],
            [110.48554871501959, -6.970593113523138],
            [110.486206126449332, -6.970901379965082],
            [110.487008980150947, -6.971182513195385],
            [110.487150918153148, -6.971255185705838],
            [110.487266273395576, -6.971318630265297],
            [110.487293139270321, -6.971327705043805],
            [110.487360996470741, -6.971363961167578],
            [110.48758122006366, -6.971508930034153],
            [110.487651057630643, -6.971545188267501],
            [110.487712964118018, -6.971590483512835],
            [110.487762169481726, -6.971608628112895],
            [110.488011318108008, -6.971690308801171],
            [110.488186375823361, -6.971744772229751],
            [110.488272621822176, -6.971781048153807],
            [110.4887920980416, -6.971980614304198],
            [110.489528482851526, -6.972252779359361],
            [110.489829334917573, -6.972397834376927],
            [110.490215292104565, -6.972588209198062],
            [110.490526026686197, -6.972751365717029],
            [110.490733257963001, -6.972878227956103],
            [110.490889040008625, -6.972968852154114],
            [110.491122591265409, -6.973086696978664],
            [110.49138191613342, -6.973177432571951],
            [110.491696417052523, -6.973259181787825],
            [110.491884499174162, -6.973304612304252],
            [110.492643022757093, -6.973513477185136],
            [110.493612856876723, -6.973740658774124],
            [110.493901341202204, -6.973813333047215],
            [110.49456173777034, -6.973985907271476],
            [110.495073941502369, -6.97411309402399],
            [110.496001625613459, -6.974340225801561],
            [110.497023206103407, -6.974594592740494],
            [110.497308579666679, -6.974667261690025],
            [110.497446635370693, -6.974701722610945],
            [110.497437120055309, -6.97506761428681],
            [110.497427633699161, -6.975304415700387],
            [110.497455135386062, -6.975492048895083],
            [110.49746394593177, -6.975680538064511],
            [110.497460910980422, -6.975848810039542],
            [110.497454620606447, -6.975974897781248],
            [110.497439709318826, -6.97612374831654],
            [110.497422834636964, -6.97629559739022],
            [110.497399189492953, -6.976449347986422],
            [110.497352385094899, -6.976596034691736],
            [110.49730404946051, -6.976792980750149],
            [110.497287232580319, -6.976910556072158],
            [110.497276748844129, -6.977190959530757],
            [110.497266284383215, -6.977453271739805],
            [110.497245879571665, -6.977751755887977],
            [110.497228837625769, -6.977815057164165],
            [110.497215473459946, -6.977878362356937],
            [110.497208519624763, -6.978032130703157],
            [110.497187345988209, -6.978382709188571],
            [110.497180907769376, -6.978647193060592],
            [110.497217514054384, -6.978882429729373],
            [110.497227621491163, -6.978954805555312],
            [110.49724114312221, -6.979009093753923],
            [110.497294278107574, -6.979130163192223],
            [110.497281870980245, -6.979219338709168],
            [110.49722022255078, -6.979302310817353],
            [110.49720338426765, -6.97939801570611],
            [110.497199805953173, -6.979569878901075],
            [110.497121145767764, -6.979846564048744],
            [110.497080859322068, -6.980101785403662],
            [110.496982785622492, -6.980392800078941],
            [110.496935395071702, -6.980528434022346],
            [110.496864114593663, -6.980781635686618],
            [110.496809105320352, -6.98093494891134],
            [110.496759568132163, -6.981205537707266],
            [110.496713171323179, -6.981417695853713],
            [110.496639078984344, -6.981623673494195],
            [110.496524923161218, -6.981875740452557],
            [110.496423072757722, -6.982092781526962],
            [110.496352132667013, -6.982291709645972],
            [110.496294566651343, -6.982418286993402],
            [110.496243722689982, -6.982608190916622],
            [110.496199726576151, -6.98274382836806],
            [110.496152306483836, -6.982906599043039],
            [110.496081141307457, -6.983051253082723],
            [110.495995840282376, -6.983186846373266],
            [110.495904598101333, -6.983322433294136],
            [110.495789475199274, -6.983566542205357],
            [110.495709520214277, -6.983729278029249],
            [110.49565619747915, -6.983855859803891],
            [110.495585285827573, -6.984027650897419],
            [110.495423073420966, -6.984389302477298],
            [110.495245670865998, -6.984669527072354],
            [110.495219009379468, -6.98473281792374],
            [110.495167666757965, -6.984859401740165],
            [110.495147756301407, -6.984958882321767],
            [110.495147688393942, -6.985022201638808],
            [110.495171737394955, -6.985284550572225],
            [110.495204479049221, -6.985619273825864],
            [110.495192852163555, -6.98590872143728],
            [110.495177419723731, -6.986053434941503],
            [110.495114849305381, -6.986360919242866],
            [110.495059175428807, -6.986568909023207],
            [110.494986019204873, -6.98672260630605],
            [110.494919350486924, -6.98689440177637],
            [110.494854935302641, -6.98707524527341],
            [110.494863501672683, -6.987265212596853],
            [110.494878955033371, -6.987364731041186],
            [110.494905461393785, -6.987446170077501],
            [110.49491869030463, -6.987509503633833],
            [110.494929655920217, -6.987572834764698],
            [110.494927305241148, -6.987654242878149],
            [110.494929471449183, -6.987744701457124],
            [110.494922915917968, -6.987789922561189],
            [110.494889444863546, -6.987871297324316],
            [110.494736332175137, -6.988187730103609],
            [110.494689847072493, -6.988269090895117],
            [110.494594028256003, -6.988449900655453],
            [110.494558313223209, -6.988513181729125],
            [110.494542997059824, -6.988549347802689],
            [110.494531875997112, -6.988630746499365],
            [110.494462856016767, -6.988883949960995],
            [110.494471224098561, -6.989064871430918],
            [110.494232418936804, -6.989019387076451],
            [110.494024819080408, -6.988964890484965],
            [110.493835042799503, -6.988910412949087],
            [110.493699273746799, -6.988883130235536],
            [110.493235432826069, -6.988755992963553],
            [110.493050495500611, -6.988674383458422],
            [110.492613853647271, -6.988511092367514],
            [110.491844653421737, -6.988211758128115],
            [110.491334250575491, -6.987976021411776],
            [110.491127022552689, -6.987840113414443],
            [110.490698422271521, -6.98730595890403],
            [110.490444232492251, -6.986907676917645],
            [110.490289305855725, -6.986545684632747],
            [110.490163900193977, -6.986093268035583],
            [110.490090256369882, -6.985912275994036],
            [110.489971853857568, -6.985785509245733],
            [110.489669053837247, -6.985341946236113],
            [110.489444805364812, -6.984988924316769],
            [110.489289938923605, -6.984572658124034],
            [110.489268038752712, -6.984418858877475],
            [110.489275629926681, -6.984201772235065],
            [110.489320360772538, -6.983912360853202],
            [110.489327961708327, -6.983686228593949],
            [110.489298364117161, -6.983586694728228],
            [110.488847584796005, -6.983161062279422],
            [110.488292821385159, -6.982880046549287],
            [110.487996743354486, -6.982762132206959],
            [110.487826783867646, -6.982698628358916],
            [110.487715787586311, -6.982526641081124],
            [110.487642145189355, -6.982345648739024],
            [110.487565489275724, -6.982074196936336],
            [110.487402691425331, -6.981929290176501],
            [110.486899692774088, -6.98165737482073],
            [110.4863450870603, -6.981493950177096],
            [110.486034269615217, -6.981403155493158],
            [110.48575321691311, -6.981258119364648],
            [110.485524015596781, -6.981040774703725],
            [110.485420629407656, -6.980895932106865],
            [110.48536141642262, -6.980714955215397],
            [110.485368910382476, -6.980588324774939],
            [110.485472865942299, -6.980470845150551],
            [110.485628330374865, -6.980335330422927],
            [110.485672865372393, -6.980226831580148],
            [110.485658312208628, -6.980082085861586],
            [110.485607218152438, -6.979720205525721],
            [110.485614781146452, -6.979530255832813],
            [110.485622373756769, -6.979313169321557],
            [110.485629966352747, -6.97909608280768],
            [110.485664484438445, -6.978833797554748],
            [110.485784907322042, -6.97866206206611],
            [110.485840534674097, -6.978499301570684],
            [110.485838047740117, -6.978445025160498],
            [110.485645760263296, -6.97836340509014],
            [110.485223752963606, -6.978281534446924],
            [110.484978785800479, -6.97825413035109],
            [110.484870755172835, -6.978217829992091],
            [110.484575480247699, -6.978145142704656],
            [110.484450524798078, -6.978063595695213],
            [110.484236483690452, -6.977954814471794],
            [110.484013419410715, -6.977818886450361],
            [110.483924333760598, -6.977791652211078],
            [110.483790548990527, -6.977764369072359],
            [110.483496113363756, -6.977700727672562],
            [110.483174801779327, -6.977637056667842],
            [110.4827822377638, -6.977537124928522],
            [110.482344945038491, -6.977464280660918],
            [110.481943561599195, -6.977409566633584],
            [110.481363401884494, -6.977336564655668],
            [110.480867512623149, -6.977227472221744],
            [110.480867364965675, -6.977173198397074],
            [110.480874676734359, -6.97695611178767],
            [110.480860790530244, -6.976720910643553],
            [110.480874306263445, -6.976521922073286],
            [110.480895663616423, -6.976395307008476],
            [110.480952403986421, -6.976250639622232],
            [110.481058975394944, -6.976069844557732],
            [110.481136943477196, -6.975916154861742],
            [110.481264742529532, -6.975726337454054],
            [110.481385468864616, -6.975536512234099],
            [110.481470832056687, -6.975346648140303],
            [110.481485156366176, -6.975183842855454],
            [110.481457094788837, -6.974975762962914],
            [110.481440080083402, -6.974849105708976],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 49,
        id: 51,
        area: 1741510.36513,
        perimeter: 6324.915285,
        kode_pos: 50115,
        kode_kec: 3374090,
        kode_kel: 3374040019,
        kec: "Genuk",
        kel: "Bangetayu Kulon",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.481440080083402, -6.974849105708976],
            [110.481457094788837, -6.974975762962914],
            [110.481485156366176, -6.975183842855454],
            [110.481470832056687, -6.975346648140303],
            [110.481385468864616, -6.975536512234099],
            [110.481264742529532, -6.975726337454054],
            [110.481136943477196, -6.975916154861742],
            [110.481058975394944, -6.976069844557732],
            [110.480952403986421, -6.976250639622232],
            [110.480895663616423, -6.976395307008476],
            [110.480874306263445, -6.976521922073286],
            [110.480860790530244, -6.976720910643553],
            [110.480874676734359, -6.97695611178767],
            [110.480867364965675, -6.977173198397074],
            [110.480867512623149, -6.977227472221744],
            [110.480867175643667, -6.977345064785625],
            [110.480831310313405, -6.977544028765287],
            [110.480767174306109, -6.977724870419757],
            [110.480674454786353, -6.977914726221412],
            [110.480553444592246, -6.978104550862978],
            [110.480460794718439, -6.978231087418728],
            [110.480382533103679, -6.978393822230234],
            [110.480346717339884, -6.978547558152522],
            [110.4803680286701, -6.978719448190949],
            [110.480424733239033, -6.978864240368837],
            [110.480481457788514, -6.978990941345255],
            [110.480518934746712, -6.979126666738282],
            [110.480518785139751, -6.979262350710601],
            [110.480465984953312, -6.979425113546266],
            [110.480367919632911, -6.979587826528961],
            [110.480247514574629, -6.979741469270676],
            [110.480172081669423, -6.979904207129115],
            [110.480118948456735, -6.980112197581879],
            [110.480096399125614, -6.98029308489973],
            [110.480073556880527, -6.980483017499151],
            [110.480058303189153, -6.980718186496938],
            [110.480027852347035, -6.980880973879604],
            [110.479989985959861, -6.981098026730282],
            [110.479967416529576, -6.98129700522044],
            [110.479936965604224, -6.981459792588177],
            [110.479816539908143, -6.981631526354421],
            [110.479741752263678, -6.981721899953167],
            [110.479628003224079, -6.981739865705599],
            [110.479464492345585, -6.981730639703321],
            [110.479333264559088, -6.981728361872874],
            [110.479204500269418, -6.981769931804843],
            [110.479096559197799, -6.981856712767108],
            [110.478895223919494, -6.982037561807462],
            [110.478453679113301, -6.982480308479576],
            [110.478232863602997, -6.982700427492224],
            [110.47810416060571, -6.982686381083935],
            [110.477841860486294, -6.982633406868046],
            [110.477537227299393, -6.98257452130045],
            [110.477119773942206, -6.982563630709204],
            [110.476674366025605, -6.982663940870337],
            [110.476291569972219, -6.982771272127948],
            [110.475947053006379, -6.982868217582608],
            [110.475661661332353, -6.982979132521852],
            [110.475400555840238, -6.983149166349773],
            [110.474997099388801, -6.983533109849872],
            [110.47495629072823, -6.983571944764776],
            [110.474040192140507, -6.982787384659173],
            [110.474021540690075, -6.982772441692974],
            [110.473499155635366, -6.982353922254795],
            [110.473048047721321, -6.981990493509142],
            [110.471527702520859, -6.980622517258343],
            [110.470350272156082, -6.979586486404064],
            [110.469330021866199, -6.978693545522572],
            [110.469193485037962, -6.978570519454942],
            [110.469167674968375, -6.978547263364012],
            [110.468781334204991, -6.978198439576404],
            [110.467429591324517, -6.97700785699467],
            [110.465934988854926, -6.975671335349921],
            [110.464148910242073, -6.974071513956907],
            [110.462453650833169, -6.972612354114221],
            [110.461130448687541, -6.971448778916334],
            [110.461157925214437, -6.971410630011627],
            [110.461252670434575, -6.971279083978114],
            [110.461342886093547, -6.971027462231314],
            [110.461421162955645, -6.970855685354991],
            [110.461511212842566, -6.970683758265589],
            [110.461598569608057, -6.970432894128875],
            [110.461612186534168, -6.970238831054941],
            [110.461639141581472, -6.970094975914856],
            [110.461649375251355, -6.969931024624495],
            [110.461669770012179, -6.969666699427084],
            [110.461909366113929, -6.969689361029943],
            [110.462070284674567, -6.969734772176579],
            [110.462289136052817, -6.969817920187091],
            [110.462419511678689, -6.969870853408087],
            [110.46258858294749, -6.969961501597004],
            [110.46290920646392, -6.970124686718735],
            [110.463148174919041, -6.970197322991051],
            [110.463410644772722, -6.970251894661102],
            [110.46364369304581, -6.970306432792756],
            [110.464065960741081, -6.970397367832865],
            [110.464237084770531, -6.970424698671074],
            [110.464395205666165, -6.970442969119246],
            [110.464645562451111, -6.970452298442874],
            [110.464974889575004, -6.970425534702337],
            [110.465311016546821, -6.970389732833542],
            [110.465600737536903, -6.970362923829354],
            [110.46587006917899, -6.970354182779842],
            [110.466025391866509, -6.970345312746387],
            [110.466262159232343, -6.970363671446308],
            [110.466640690548004, -6.970355053297754],
            [110.466643004888681, -6.970309827946063],
            [110.466668752284363, -6.970056580407373],
            [110.466779574215181, -6.969622517006042],
            [110.466835305561659, -6.969622579890314],
            [110.467069212973058, -6.969668071715744],
            [110.467491481000152, -6.969759003703643],
            [110.467641650052045, -6.969804400880705],
            [110.467830293593067, -6.969849841344335],
            [110.468119922441176, -6.969904441011453],
            [110.468276346010413, -6.969922708248683],
            [110.468467314031486, -6.969913877508029],
            [110.46860625892748, -6.969877851400684],
            [110.468776605755949, -6.969841860554366],
            [110.469020162693965, -6.969860225494501],
            [110.469133693762387, -6.969869398652437],
            [110.469097614222633, -6.970167862800301],
            [110.469018458756935, -6.970620053666041],
            [110.469231967544303, -6.970692658335594],
            [110.469488750090434, -6.970774357102087],
            [110.469649952840825, -6.970819766066495],
            [110.469896864500484, -6.97087431674894],
            [110.470173177628055, -6.97094699147434],
            [110.470380786476383, -6.970983406639746],
            [110.470574240148352, -6.971028851452421],
            [110.470810139205, -6.971065298135203],
            [110.47106047641752, -6.971092715268849],
            [110.471300619081845, -6.97112916646281],
            [110.471594189619665, -6.971201859695594],
            [110.471765001288745, -6.971256324310366],
            [110.471869917212445, -6.971292624006173],
            [110.472156132524816, -6.971365308680778],
            [110.472410107275252, -6.971428911583291],
            [110.472641167072084, -6.971492488774867],
            [110.472748083664115, -6.971510699315325],
            [110.472886978262608, -6.971519899912352],
            [110.473057598279382, -6.97149295345096],
            [110.473232178904084, -6.971466011342712],
            [110.473458277904172, -6.971411989784938],
            [110.4736685444648, -6.971348904873905],
            [110.473759648868466, -6.971339960761628],
            [110.47395796288302, -6.971340181622425],
            [110.47412457175426, -6.971358458315354],
            [110.474491424610591, -6.97142218575413],
            [110.47453441558693, -6.971431279177539],
            [110.474531243203742, -6.971485549270823],
            [110.474518704828441, -6.971566945756728],
            [110.474506206760523, -6.971612159872368],
            [110.474471642348831, -6.971657349440342],
            [110.474378123460184, -6.971801975049678],
            [110.474256314314999, -6.971946569150712],
            [110.474162542653758, -6.972064057616519],
            [110.474125118895373, -6.97213638077891],
            [110.474125533404603, -6.972272065282167],
            [110.47416448362597, -6.972606795944663],
            [110.474178820872964, -6.972688222329561],
            [110.474183822342596, -6.972769638321337],
            [110.474176305497991, -6.972914359595191],
            [110.474166292955587, -6.97301385007658],
            [110.474135760101973, -6.973249001746971],
            [110.474329791054075, -6.973285400152811],
            [110.474473465342115, -6.97332174245185],
            [110.474628738671626, -6.973358097611416],
            [110.475231150603463, -6.973512542603088],
            [110.475797138881532, -6.973603627194962],
            [110.475938570293977, -6.973621875364131],
            [110.476148171311308, -6.973649244715799],
            [110.476399056123597, -6.973694750959351],
            [110.476715008720319, -6.97374032917397],
            [110.476864108723376, -6.973731448750151],
            [110.477154084470484, -6.973731769868112],
            [110.477307135089831, -6.97373193928396],
            [110.477476291146189, -6.973750217683516],
            [110.477589149479797, -6.973768433751736],
            [110.477782332305623, -6.973804829835308],
            [110.477971817394433, -6.973859312969788],
            [110.478265653823968, -6.973950093758268],
            [110.478590326852228, -6.974040908419398],
            [110.479049339203286, -6.974168053515917],
            [110.479338083813559, -6.97425882804801],
            [110.480062350559322, -6.97448576628801],
            [110.480186485470071, -6.974540176612752],
            [110.480379336134135, -6.974621799358155],
            [110.480513938566318, -6.974676221120191],
            [110.480630738014696, -6.974712532043173],
            [110.480902831831926, -6.974767104903086],
            [110.481102239305642, -6.974803506518238],
            [110.481301929720232, -6.974839908362157],
            [110.481440080083402, -6.974849105708976],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 50,
        id: 52,
        area: 2120130.1074680001,
        perimeter: 6969.372944,
        kode_pos: 50115,
        kode_kec: 3374090,
        kode_kel: 3374040020,
        kec: "Genuk",
        kel: "Bangetayu Wetan",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.480867512623149, -6.977227472221744],
            [110.481363401884494, -6.977336564655668],
            [110.481943561599195, -6.977409566633584],
            [110.482344945038491, -6.977464280660918],
            [110.4827822377638, -6.977537124928522],
            [110.483174801779327, -6.977637056667842],
            [110.483496113363756, -6.977700727672562],
            [110.483790548990527, -6.977764369072359],
            [110.483924333760598, -6.977791652211078],
            [110.484013419410715, -6.977818886450361],
            [110.484236483690452, -6.977954814471794],
            [110.484450524798078, -6.978063595695213],
            [110.484575480247699, -6.978145142704656],
            [110.484870755172835, -6.978217829992091],
            [110.484978785800479, -6.97825413035109],
            [110.485223752963606, -6.978281534446924],
            [110.485645760263296, -6.97836340509014],
            [110.485838047740117, -6.978445025160498],
            [110.485840534674097, -6.978499301570684],
            [110.485784907322042, -6.97866206206611],
            [110.485664484438445, -6.978833797554748],
            [110.485629966352747, -6.97909608280768],
            [110.485622373756769, -6.979313169321557],
            [110.485614781146452, -6.979530255832813],
            [110.485607218152438, -6.979720205525721],
            [110.485658312208628, -6.980082085861586],
            [110.485672865372393, -6.980226831580148],
            [110.485628330374865, -6.980335330422927],
            [110.485472865942299, -6.980470845150551],
            [110.485368910382476, -6.980588324774939],
            [110.48536141642262, -6.980714955215397],
            [110.485420629407656, -6.980895932106865],
            [110.485524015596781, -6.981040774703725],
            [110.48575321691311, -6.981258119364648],
            [110.486034269615217, -6.981403155493158],
            [110.4863450870603, -6.981493950177096],
            [110.486899692774088, -6.98165737482073],
            [110.487402691425331, -6.981929290176501],
            [110.487565489275724, -6.982074196936336],
            [110.487642145189355, -6.982345648739024],
            [110.487715787586311, -6.982526641081124],
            [110.487826783867646, -6.982698628358916],
            [110.487996743354486, -6.982762132206959],
            [110.488292821385159, -6.982880046549287],
            [110.488847584796005, -6.983161062279422],
            [110.489298364117161, -6.983586694728228],
            [110.489327961708327, -6.983686228593949],
            [110.489320360772538, -6.983912360853202],
            [110.489275629926681, -6.984201772235065],
            [110.489268038752712, -6.984418858877475],
            [110.489289938923605, -6.984572658124034],
            [110.489444805364812, -6.984988924316769],
            [110.489669053837247, -6.985341946236113],
            [110.489971853857568, -6.985785509245733],
            [110.490090256369882, -6.985912275994036],
            [110.490163900193977, -6.986093268035583],
            [110.490289305855725, -6.986545684632747],
            [110.490444232492251, -6.986907676917645],
            [110.490698422271521, -6.98730595890403],
            [110.491127022552689, -6.987840113414443],
            [110.491334250575491, -6.987976021411776],
            [110.491844653421737, -6.988211758128115],
            [110.492613853647271, -6.988511092367514],
            [110.493050495500611, -6.988674383458422],
            [110.493235432826069, -6.988755992963553],
            [110.493699273746799, -6.988883130235536],
            [110.493835042799503, -6.988910412949087],
            [110.494024819080408, -6.988964890484965],
            [110.494232418936804, -6.989019387076451],
            [110.494471224098561, -6.989064871430918],
            [110.494470867135732, -6.989327194160997],
            [110.494437620464581, -6.989462842860922],
            [110.494326232242472, -6.989915004554284],
            [110.494275084110939, -6.990122999013495],
            [110.494217487266525, -6.990276712796853],
            [110.49418172339287, -6.990385221890039],
            [110.494146213230906, -6.990520868122179],
            [110.49406613958395, -6.990792150849978],
            [110.494032941318196, -6.990882571432682],
            [110.494016200722982, -6.99092778157076],
            [110.494008249929792, -6.990954909901649],
            [110.494006211141354, -6.991009181451651],
            [110.494006113826103, -6.991099637579607],
            [110.494006035972959, -6.991172002481721],
            [110.494019235634966, -6.9912624728904],
            [110.494028259703398, -6.991289619451881],
            [110.494042366502455, -6.991325817095179],
            [110.494122948486407, -6.991371131747861],
            [110.494002808489796, -6.991805192630745],
            [110.493945835546455, -6.991904633282313],
            [110.493856259214169, -6.992067358253226],
            [110.493701934231581, -6.992193831122385],
            [110.493654931381272, -6.992229963089321],
            [110.493628864237209, -6.992266117557246],
            [110.493605606757697, -6.992320366288378],
            [110.49349837256166, -6.992591619654434],
            [110.493327472765131, -6.993134173149334],
            [110.492969389549089, -6.99383934623041],
            [110.492838965223157, -6.994101528789137],
            [110.492159896278991, -6.995213408157916],
            [110.491939981341375, -6.995556904428958],
            [110.491913591839534, -6.99562924090121],
            [110.491823486603209, -6.996018105249513],
            [110.49151842174912, -6.996614786643192],
            [110.491294348942787, -6.997139190366438],
            [110.491220467096625, -6.997437615880728],
            [110.491207286360037, -6.997591377122064],
            [110.491222953476822, -6.997754215161786],
            [110.49123077724326, -6.997844679787614],
            [110.491220191728928, -6.997953215747362],
            [110.491124457560659, -6.99805261229069],
            [110.491074644052915, -6.998101810126562],
            [110.491041474276443, -6.998133934856073],
            [110.491032136094475, -6.998143519382418],
            [110.490541314500291, -6.997653318990209],
            [110.489901136588912, -6.997066669423945],
            [110.489200924520446, -6.996442795754064],
            [110.487871953398368, -6.99526086477691],
            [110.486954547592802, -6.994436669291174],
            [110.485739899003534, -6.993363473752422],
            [110.484648189584959, -6.992361867984657],
            [110.483173510657764, -6.99102827477935],
            [110.481301571350002, -6.989356958576931],
            [110.479841180286243, -6.988054813384058],
            [110.478455121857436, -6.986801337855097],
            [110.477369170023337, -6.985730499762135],
            [110.47496115806554, -6.983576113211691],
            [110.47495629072823, -6.983571944764776],
            [110.474997099388801, -6.983533109849872],
            [110.475400555840238, -6.983149166349773],
            [110.475661661332353, -6.982979132521852],
            [110.475947053006379, -6.982868217582608],
            [110.476291569972219, -6.982771272127948],
            [110.476674366025605, -6.982663940870337],
            [110.477119773942206, -6.982563630709204],
            [110.477537227299393, -6.98257452130045],
            [110.477841860486294, -6.982633406868046],
            [110.47810416060571, -6.982686381083935],
            [110.478232863602997, -6.982700427492224],
            [110.478453679113301, -6.982480308479576],
            [110.478895223919494, -6.982037561807462],
            [110.479096559197799, -6.981856712767108],
            [110.479204500269418, -6.981769931804843],
            [110.479333264559088, -6.981728361872874],
            [110.479464492345585, -6.981730639703321],
            [110.479628003224079, -6.981739865705599],
            [110.479741752263678, -6.981721899953167],
            [110.479816539908143, -6.981631526354421],
            [110.479936965604224, -6.981459792588177],
            [110.479967416529576, -6.98129700522044],
            [110.479989985959861, -6.981098026730282],
            [110.480027852347035, -6.980880973879604],
            [110.480058303189153, -6.980718186496938],
            [110.480073556880527, -6.980483017499151],
            [110.480096399125614, -6.98029308489973],
            [110.480118948456735, -6.980112197581879],
            [110.480172081669423, -6.979904207129115],
            [110.480247514574629, -6.979741469270676],
            [110.480367919632911, -6.979587826528961],
            [110.480465984953312, -6.979425113546266],
            [110.480518785139751, -6.979262350710601],
            [110.480518934746712, -6.979126666738282],
            [110.480481457788514, -6.978990941345255],
            [110.480424733239033, -6.978864240368837],
            [110.4803680286701, -6.978719448190949],
            [110.480346717339884, -6.978547558152522],
            [110.480382533103679, -6.978393822230234],
            [110.480460794718439, -6.978231087418728],
            [110.480553444592246, -6.978104550862978],
            [110.480674454786353, -6.977914726221412],
            [110.480767174306109, -6.977724870419757],
            [110.480831310313405, -6.977544028765287],
            [110.480867175643667, -6.977345064785625],
            [110.480867512623149, -6.977227472221744],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 51,
        id: 53,
        area: 2529351.6460040002,
        perimeter: 7584.945684,
        kode_pos: 50231,
        kode_kec: 3374090,
        kode_kel: 3374040014,
        kec: "Genuk",
        kel: "Genuksari",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.481440080083402, -6.974849105708976],
            [110.481301929720232, -6.974839908362157],
            [110.481102239305642, -6.974803506518238],
            [110.480902831831926, -6.974767104903086],
            [110.480630738014696, -6.974712532043173],
            [110.480513938566318, -6.974676221120191],
            [110.480379336134135, -6.974621799358155],
            [110.480186485470071, -6.974540176612752],
            [110.480062350559322, -6.97448576628801],
            [110.479338083813559, -6.97425882804801],
            [110.479049339203286, -6.974168053515917],
            [110.478590326852228, -6.974040908419398],
            [110.478265653823968, -6.973950093758268],
            [110.477971817394433, -6.973859312969788],
            [110.477782332305623, -6.973804829835308],
            [110.477589149479797, -6.973768433751736],
            [110.477476291146189, -6.973750217683516],
            [110.477307135089831, -6.97373193928396],
            [110.477154084470484, -6.973731769868112],
            [110.476864108723376, -6.973731448750151],
            [110.476715008720319, -6.97374032917397],
            [110.476399056123597, -6.973694750959351],
            [110.476148171311308, -6.973649244715799],
            [110.475938570293977, -6.973621875364131],
            [110.475797138881532, -6.973603627194962],
            [110.475231150603463, -6.973512542603088],
            [110.474628738671626, -6.973358097611416],
            [110.474473465342115, -6.97332174245185],
            [110.474329791054075, -6.973285400152811],
            [110.474135760101973, -6.973249001746971],
            [110.474166292955587, -6.97301385007658],
            [110.474176305497991, -6.972914359595191],
            [110.474183822342596, -6.972769638321337],
            [110.474178820872964, -6.972688222329561],
            [110.47416448362597, -6.972606795944663],
            [110.474125533404603, -6.972272065282167],
            [110.474125118895373, -6.97213638077891],
            [110.474162542653758, -6.972064057616519],
            [110.474256314314999, -6.971946569150712],
            [110.474378123460184, -6.971801975049678],
            [110.474471642348831, -6.971657349440342],
            [110.474506206760523, -6.971612159872368],
            [110.474518704828441, -6.971566945756728],
            [110.474531243203742, -6.971485549270823],
            [110.47453441558693, -6.971431279177539],
            [110.474491424610591, -6.97142218575413],
            [110.47412457175426, -6.971358458315354],
            [110.47395796288302, -6.971340181622425],
            [110.473759648868466, -6.971339960761628],
            [110.4736685444648, -6.971348904873905],
            [110.473458277904172, -6.971411989784938],
            [110.473232178904084, -6.971466011342712],
            [110.473057598279382, -6.97149295345096],
            [110.472886978262608, -6.971519899912352],
            [110.472748083664115, -6.971510699315325],
            [110.472641167072084, -6.971492488774867],
            [110.472410107275252, -6.971428911583291],
            [110.472156132524816, -6.971365308680778],
            [110.471869917212445, -6.971292624006173],
            [110.471765001288745, -6.971256324310366],
            [110.471594189619665, -6.971201859695594],
            [110.471300619081845, -6.97112916646281],
            [110.47106047641752, -6.971092715268849],
            [110.470810139205, -6.971065298135203],
            [110.470574240148352, -6.971028851452421],
            [110.470380786476383, -6.970983406639746],
            [110.470173177628055, -6.97094699147434],
            [110.469896864500484, -6.97087431674894],
            [110.469649952840825, -6.970819766066495],
            [110.469488750090434, -6.970774357102087],
            [110.469231967544303, -6.970692658335594],
            [110.469018458756935, -6.970620053666041],
            [110.469097614222633, -6.970167862800301],
            [110.469133693762387, -6.969869398652437],
            [110.469248764534939, -6.968838329866125],
            [110.469428828589358, -6.967707832354328],
            [110.469510640899585, -6.967156142701362],
            [110.469575994027736, -6.966649662532296],
            [110.469576990172328, -6.965763194988156],
            [110.469545573254294, -6.965021420635249],
            [110.469570522373544, -6.964469667075993],
            [110.469563456462538, -6.963456552015207],
            [110.469588659326632, -6.962678658698232],
            [110.469548784272632, -6.962163014783111],
            [110.469531722215933, -6.961737852443589],
            [110.469515780030278, -6.961575013762714],
            [110.469467345514857, -6.961375956243544],
            [110.469451069659897, -6.961258345187906],
            [110.469451313492456, -6.96104125105358],
            [110.469468168407261, -6.960643263533886],
            [110.469436610709636, -6.95977485048404],
            [110.469404291174072, -6.959584856628338],
            [110.469375093653937, -6.959385820670664],
            [110.469288802343499, -6.959141492682953],
            [110.469223182691579, -6.958879096666509],
            [110.469075535747081, -6.958354286208777],
            [110.469034456877978, -6.958155236908009],
            [110.468962280097884, -6.957938061513865],
            [110.468896418514575, -6.957639482790108],
            [110.468808562513416, -6.957277560172728],
            [110.468577880040257, -6.956381786783724],
            [110.468457078630607, -6.955884143124083],
            [110.468391278391948, -6.955531466720116],
            [110.468927167264923, -6.955450481771861],
            [110.469859246370305, -6.955243477422498],
            [110.470411299949745, -6.955126502339307],
            [110.47088699279719, -6.954991350061814],
            [110.471836912795126, -6.954766270405971],
            [110.472043504599597, -6.954694001254135],
            [110.47290777794268, -6.954405639294316],
            [110.474241891392836, -6.953900568201569],
            [110.478485974087477, -6.952231821774074],
            [110.480997931534759, -6.951303012123737],
            [110.480693427183255, -6.952695573480959],
            [110.480510033299879, -6.953798937894094],
            [110.480326756736829, -6.955309355060901],
            [110.480285656767407, -6.955897275104151],
            [110.480184286120121, -6.95624089728085],
            [110.479799255714511, -6.957280720285226],
            [110.479434243075616, -6.958637161365754],
            [110.478947606722002, -6.960228653990888],
            [110.478783481421857, -6.961295855642829],
            [110.478701704676027, -6.962082733891592],
            [110.47863994659977, -6.963186230588388],
            [110.478726662765311, -6.963819519047986],
            [110.479125758450095, -6.965167755071571],
            [110.47933232109942, -6.965638354433364],
            [110.480023298109472, -6.966796953357696],
            [110.480505876662377, -6.967611589148345],
            [110.481007217764159, -6.968344834618521],
            [110.481096737947567, -6.968489662736366],
            [110.481310565277639, -6.968788402725573],
            [110.48156608858767, -6.968987686618648],
            [110.481717827321759, -6.969150674117514],
            [110.481752033558521, -6.969195939704164],
            [110.481769215883247, -6.969241186620209],
            [110.481786070702043, -6.969349752479175],
            [110.481774585766232, -6.969503515332637],
            [110.481728537220434, -6.969702468349231],
            [110.481554150123046, -6.970326424422757],
            [110.481278408490638, -6.971275911190419],
            [110.48125179021126, -6.97155629596974],
            [110.48126488763593, -6.971737222612282],
            [110.481382385951846, -6.971909218269346],
            [110.48152618432826, -6.972090288382467],
            [110.481598641667333, -6.972316508236781],
            [110.481616935398065, -6.972660261615043],
            [110.481642708421845, -6.973148753006333],
            [110.4816026788248, -6.973790947582505],
            [110.48159106431973, -6.974062303212252],
            [110.48156431224092, -6.974207003644758],
            [110.481478939377112, -6.974405913383947],
            [110.481429262074812, -6.974559634241198],
            [110.481429033229574, -6.974767683068732],
            [110.481440080083402, -6.974849105708976],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 52,
        id: 54,
        area: 1333030.453127,
        perimeter: 4834.968877,
        kode_pos: 50117,
        kode_kec: 3374090,
        kode_kel: 3374040013,
        kec: "Genuk",
        kel: "Gebangsari",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.468391278391948, -6.955531466720116],
            [110.468457078630607, -6.955884143124083],
            [110.468577880040257, -6.956381786783724],
            [110.468808562513416, -6.957277560172728],
            [110.468896418514575, -6.957639482790108],
            [110.468962280097884, -6.957938061513865],
            [110.469034456877978, -6.958155236908009],
            [110.469075535747081, -6.958354286208777],
            [110.469223182691579, -6.958879096666509],
            [110.469288802343499, -6.959141492682953],
            [110.469375093653937, -6.959385820670664],
            [110.469404291174072, -6.959584856628338],
            [110.469436610709636, -6.95977485048404],
            [110.469468168407261, -6.960643263533886],
            [110.469451313492456, -6.96104125105358],
            [110.469451069659897, -6.961258345187906],
            [110.469467345514857, -6.961375956243544],
            [110.469515780030278, -6.961575013762714],
            [110.469531722215933, -6.961737852443589],
            [110.469548784272632, -6.962163014783111],
            [110.469588659326632, -6.962678658698232],
            [110.469563456462538, -6.963456552015207],
            [110.469570522373544, -6.964469667075993],
            [110.469545573254294, -6.965021420635249],
            [110.469576990172328, -6.965763194988156],
            [110.469575994027736, -6.966649662532296],
            [110.469510640899585, -6.967156142701362],
            [110.469428828589358, -6.967707832354328],
            [110.469248764534939, -6.968838329866125],
            [110.469133693762387, -6.969869398652437],
            [110.469020162693965, -6.969860225494501],
            [110.468776605755949, -6.969841860554366],
            [110.46860625892748, -6.969877851400684],
            [110.468467314031486, -6.969913877508029],
            [110.468276346010413, -6.969922708248683],
            [110.468119922441176, -6.969904441011453],
            [110.467830293593067, -6.969849841344335],
            [110.467641650052045, -6.969804400880705],
            [110.467491481000152, -6.969759003703643],
            [110.467069212973058, -6.969668071715744],
            [110.466835305561659, -6.969622579890314],
            [110.466779574215181, -6.969622517006042],
            [110.466668752284363, -6.970056580407373],
            [110.466643004888681, -6.970309827946063],
            [110.466640690548004, -6.970355053297754],
            [110.466262159232343, -6.970363671446308],
            [110.466025391866509, -6.970345312746387],
            [110.46587006917899, -6.970354182779842],
            [110.465600737536903, -6.970362923829354],
            [110.465311016546821, -6.970389732833542],
            [110.464974889575004, -6.970425534702337],
            [110.464645562451111, -6.970452298442874],
            [110.464395205666165, -6.970442969119246],
            [110.464237084770531, -6.970424698671074],
            [110.464065960741081, -6.970397367832865],
            [110.46364369304581, -6.970306432792756],
            [110.463410644772722, -6.970251894661102],
            [110.463148174919041, -6.970197322991051],
            [110.46290920646392, -6.970124686718735],
            [110.46258858294749, -6.969961501597004],
            [110.462419511678689, -6.969870853408087],
            [110.462289136052817, -6.969817920187091],
            [110.462283189574677, -6.969158778024928],
            [110.462257891115158, -6.968703788799177],
            [110.462217593149475, -6.967947385076338],
            [110.462171857215353, -6.966940131864431],
            [110.462173538601618, -6.965464466867442],
            [110.462110198442161, -6.964106888268485],
            [110.461552942398512, -6.964118246835925],
            [110.46152710710416, -6.963791202991636],
            [110.46148706116071, -6.963185102999726],
            [110.461440981611929, -6.962660406421302],
            [110.461389018825827, -6.961837198718634],
            [110.461337663159782, -6.960977809332102],
            [110.461256650841719, -6.959828927310926],
            [110.46119323847428, -6.959123299162923],
            [110.461003193620343, -6.956590317717133],
            [110.460997552006859, -6.956327989186126],
            [110.46096963831873, -6.956246734230025],
            [110.462818260629987, -6.956067549258102],
            [110.463894777155332, -6.955969455071295],
            [110.465351268635544, -6.95582637252098],
            [110.465557517290151, -6.955808514185402],
            [110.467503451895283, -6.955629794755794],
            [110.468329315098003, -6.955540266839546],
            [110.468391278391948, -6.955531466720116],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 53,
        id: 55,
        area: 2030306.0757329999,
        perimeter: 7559.522788,
        kode_pos: 50112,
        kode_kec: 3374090,
        kode_kel: 3374040022,
        kec: "Genuk",
        kel: "Terboyo Wetan",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.468391278391948, -6.955531466720116],
            [110.468329315098003, -6.955540266839546],
            [110.467503451895283, -6.955629794755794],
            [110.465557517290151, -6.955808514185402],
            [110.465351268635544, -6.95582637252098],
            [110.463894777155332, -6.955969455071295],
            [110.462818260629987, -6.956067549258102],
            [110.462866320710361, -6.955597420091168],
            [110.462874817186531, -6.955090876518266],
            [110.462945147523598, -6.953942165920161],
            [110.463007850423608, -6.953281908666463],
            [110.463000345914338, -6.953164307435348],
            [110.462922623018329, -6.953100900151001],
            [110.462037655270279, -6.952928029573017],
            [110.462037727295495, -6.952864710505376],
            [110.462006804902856, -6.952692809121237],
            [110.461945171028503, -6.952412325737761],
            [110.461836701056029, -6.952023242028408],
            [110.461759683407251, -6.951588966072641],
            [110.46158183432189, -6.950765615035919],
            [110.46149687555814, -6.950349421210148],
            [110.461473550259839, -6.950213710806232],
            [110.461497122654563, -6.950132327259046],
            [110.461559147256821, -6.950069078591589],
            [110.461535981301552, -6.950041915487717],
            [110.461342095023511, -6.949887920043546],
            [110.461287538804285, -6.949851675652293],
            [110.461233162480198, -6.949905887387465],
            [110.461163299403793, -6.949896762356583],
            [110.460050029131793, -6.949244212554883],
            [110.458537069230388, -6.948310707531713],
            [110.457840067550592, -6.947185573596314],
            [110.456950557119498, -6.945460972368051],
            [110.457053979776106, -6.94541306396931],
            [110.456878811038791, -6.945061982356102],
            [110.456457010192992, -6.944480352415718],
            [110.456051493774169, -6.944052251267748],
            [110.455599729753317, -6.943286922072851],
            [110.455109561166765, -6.942521548421167],
            [110.454786308144548, -6.94212643594922],
            [110.454257698233548, -6.941393912480094],
            [110.453685109061396, -6.940738093082619],
            [110.45334270928366, -6.940296356372011],
            [110.452920878833467, -6.939747618877285],
            [110.451767308541349, -6.938105614016582],
            [110.450404873059426, -6.936166290920996],
            [110.45047962148503, -6.936119244849504],
            [110.450449464006311, -6.935906536171674],
            [110.450556230047908, -6.93560284005344],
            [110.450814417424994, -6.935815812538999],
            [110.452341279154709, -6.935048247386842],
            [110.453684672558367, -6.934432369714406],
            [110.454064352727883, -6.934263515423696],
            [110.454350413317172, -6.934136296064997],
            [110.454589963563819, -6.934204987323122],
            [110.454961925901316, -6.934075690672127],
            [110.456018346195052, -6.93548145860275],
            [110.462318396011369, -6.943864662273525],
            [110.462639553385841, -6.944290169326991],
            [110.463068628073188, -6.944689075043266],
            [110.4636845899783, -6.94498786339074],
            [110.465179376903166, -6.945318398503897],
            [110.470418662668422, -6.948550355576449],
            [110.470808049296352, -6.948851009475719],
            [110.471029913478887, -6.949062868346426],
            [110.471220665910622, -6.949383078631881],
            [110.471400955684203, -6.949821985902292],
            [110.471472852588377, -6.950193675997752],
            [110.471638354226926, -6.951699588317333],
            [110.471718189771423, -6.952405234697091],
            [110.471839115286699, -6.952794330642706],
            [110.471955224982452, -6.953017416808539],
            [110.472021554066146, -6.953750388011476],
            [110.472043504599597, -6.954694001254135],
            [110.471836912795126, -6.954766270405971],
            [110.47088699279719, -6.954991350061814],
            [110.470411299949745, -6.955126502339307],
            [110.469859246370305, -6.955243477422498],
            [110.468927167264923, -6.955450481771861],
            [110.468391278391948, -6.955531466720116],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 54,
        id: 56,
        area: 1321299.8430089999,
        perimeter: 4971.466109,
        kode_pos: 50111,
        kode_kec: 3374090,
        kode_kel: 3374040018,
        kec: "Genuk",
        kel: "Muktiharjo Lor",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.46096963831873, -6.956246734230025],
            [110.460997552006859, -6.956327989186126],
            [110.461003193620343, -6.956590317717133],
            [110.46119323847428, -6.959123299162923],
            [110.461256650841719, -6.959828927310926],
            [110.461337663159782, -6.960977809332102],
            [110.461389018825827, -6.961837198718634],
            [110.461440981611929, -6.962660406421302],
            [110.46148706116071, -6.963185102999726],
            [110.46152710710416, -6.963791202991636],
            [110.461552942398512, -6.964118246835925],
            [110.462110198442161, -6.964106888268485],
            [110.462173538601618, -6.965464466867442],
            [110.462171857215353, -6.966940131864431],
            [110.462217593149475, -6.967947385076338],
            [110.462257891115158, -6.968703788799177],
            [110.462283189574677, -6.969158778024928],
            [110.462289136052817, -6.969817920187091],
            [110.462070284674567, -6.969734772176579],
            [110.461909366113929, -6.969689361029943],
            [110.461669770012179, -6.969666699427084],
            [110.461649375251355, -6.969931024624495],
            [110.461639141581472, -6.970094975914856],
            [110.461612186534168, -6.970238831054941],
            [110.461598569608057, -6.970432894128875],
            [110.461511212842566, -6.970683758265589],
            [110.461421162955645, -6.970855685354991],
            [110.461342886093547, -6.971027462231314],
            [110.461252670434575, -6.971279083978114],
            [110.461157925214437, -6.971410630011627],
            [110.461130448687541, -6.971448778916334],
            [110.460574120784017, -6.97095956195702],
            [110.457823698579162, -6.968466332260731],
            [110.455838771698623, -6.966656127659897],
            [110.453551357463098, -6.964622696657319],
            [110.451219463833581, -6.962590628860461],
            [110.451280277858601, -6.962389438962481],
            [110.451347240976773, -6.9621578614973],
            [110.451411144741854, -6.96182473300642],
            [110.451441560263206, -6.961613739956988],
            [110.45147529530044, -6.961278989778196],
            [110.451493221133973, -6.9608839274524],
            [110.451501752071209, -6.96037461332382],
            [110.451519312715533, -6.959594915947442],
            [110.451525646781235, -6.959285313938973],
            [110.451548922239667, -6.95779954690271],
            [110.451557534902747, -6.957465935664921],
            [110.45156023917994, -6.957083067604896],
            [110.452623734809535, -6.956996799778357],
            [110.453482374870418, -6.956943518379144],
            [110.456439160939908, -6.95670269196532],
            [110.459080551896562, -6.95643434982756],
            [110.46091757609743, -6.956255533407044],
            [110.46096963831873, -6.956246734230025],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 55,
        id: 57,
        area: 2944162.615088,
        perimeter: 7953.005322,
        kode_pos: 50112,
        kode_kec: 3374090,
        kode_kel: 3374040023,
        kec: "Genuk",
        kel: "Terboyo Kulon",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.462818260629987, -6.956067549258102],
            [110.46096963831873, -6.956246734230025],
            [110.46091757609743, -6.956255533407044],
            [110.459080551896562, -6.95643434982756],
            [110.456439160939908, -6.95670269196532],
            [110.453482374870418, -6.956943518379144],
            [110.452623734809535, -6.956996799778357],
            [110.45156023917994, -6.957083067604896],
            [110.45156356176976, -6.956939990931715],
            [110.451597085113505, -6.955401877826975],
            [110.451584314252415, -6.954871278889381],
            [110.451478051362571, -6.954157765353837],
            [110.451255681236063, -6.953524373715001],
            [110.45118075326711, -6.953406775710099],
            [110.451133654097248, -6.95333285443597],
            [110.450988442351715, -6.953104947128944],
            [110.450929596371509, -6.953020572513828],
            [110.450761253931191, -6.952779199356243],
            [110.450449332915539, -6.952413224988896],
            [110.450137267242894, -6.95217209359467],
            [110.44977162587729, -6.951957651929191],
            [110.448750429119727, -6.951425880724465],
            [110.448224673716666, -6.951151032593085],
            [110.445356870617289, -6.94965182132635],
            [110.444768152117533, -6.949419280206518],
            [110.444330986689295, -6.949320676706221],
            [110.444036533567186, -6.949284661864752],
            [110.443554653647652, -6.949266261549362],
            [110.443233365435404, -6.949283718929228],
            [110.442956687166983, -6.94931014589852],
            [110.442635335911589, -6.949381107012401],
            [110.442144290165956, -6.949567793962554],
            [110.441777228195917, -6.949716825445981],
            [110.441352671520136, -6.949889199970844],
            [110.44123833057715, -6.949935623676315],
            [110.440983647833392, -6.950039401712261],
            [110.441052419591784, -6.949807752491909],
            [110.441073721440446, -6.949656398569056],
            [110.441198042596383, -6.949303768219495],
            [110.441402367082475, -6.948996459941273],
            [110.442091925771479, -6.947658528580569],
            [110.443006034500371, -6.945407257397894],
            [110.443153959953477, -6.944946107182966],
            [110.443495354192621, -6.944023859964943],
            [110.443759505255898, -6.943354797727848],
            [110.444017372178408, -6.942495770928097],
            [110.444264782014656, -6.94162768606364],
            [110.444462905734341, -6.940813816691755],
            [110.444474530584074, -6.940680624381454],
            [110.444526862146915, -6.940081200265116],
            [110.444597477809737, -6.939770477233402],
            [110.444815598430679, -6.938810836302019],
            [110.444867674281312, -6.938482291386523],
            [110.444922025386902, -6.938065224671496],
            [110.444758627738764, -6.937347554360451],
            [110.445233929240089, -6.937171642847723],
            [110.445523415233794, -6.936905245248408],
            [110.445810900878413, -6.936602717731663],
            [110.446243637873366, -6.936371335185309],
            [110.446742115764707, -6.936156623209788],
            [110.447694463095715, -6.935803367697032],
            [110.448210663615782, -6.935611891676851],
            [110.44873220427597, -6.935418434072624],
            [110.449036155477188, -6.935494741761851],
            [110.449233216468826, -6.935981081770175],
            [110.449995240626194, -6.936424109963562],
            [110.450404873059426, -6.936166290920996],
            [110.451767308541349, -6.938105614016582],
            [110.452920878833467, -6.939747618877285],
            [110.45334270928366, -6.940296356372011],
            [110.453685109061396, -6.940738093082619],
            [110.454257698233548, -6.941393912480094],
            [110.454786308144548, -6.94212643594922],
            [110.455109561166765, -6.942521548421167],
            [110.455599729753317, -6.943286922072851],
            [110.456051493774169, -6.944052251267748],
            [110.456457010192992, -6.944480352415718],
            [110.456878811038791, -6.945061982356102],
            [110.457053979776106, -6.94541306396931],
            [110.456950557119498, -6.945460972368051],
            [110.457840067550592, -6.947185573596314],
            [110.458537069230388, -6.948310707531713],
            [110.460050029131793, -6.949244212554883],
            [110.461163299403793, -6.949896762356583],
            [110.461233162480198, -6.949905887387465],
            [110.461287538804285, -6.949851675652293],
            [110.461342095023511, -6.949887920043546],
            [110.461535981301552, -6.950041915487717],
            [110.461559147256821, -6.950069078591589],
            [110.461497122654563, -6.950132327259046],
            [110.461473550259839, -6.950213710806232],
            [110.46149687555814, -6.950349421210148],
            [110.46158183432189, -6.950765615035919],
            [110.461759683407251, -6.951588966072641],
            [110.461836701056029, -6.952023242028408],
            [110.461945171028503, -6.952412325737761],
            [110.462006804902856, -6.952692809121237],
            [110.462037727295495, -6.952864710505376],
            [110.462037655270279, -6.952928029573017],
            [110.462922623018329, -6.953100900151001],
            [110.463000345914338, -6.953164307435348],
            [110.463007850423608, -6.953281908666463],
            [110.462945147523598, -6.953942165920161],
            [110.462874817186531, -6.955090876518266],
            [110.462866320710361, -6.955597420091168],
            [110.462818260629987, -6.956067549258102],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 56,
        id: 58,
        area: 3552836.381393,
        perimeter: 11440.506614,
        kode_pos: 50118,
        kode_kec: 3374090,
        kode_kel: 3374040015,
        kec: "Genuk",
        kel: "Trimulyo",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.480997931534759, -6.951303012123737],
            [110.478485974087477, -6.952231821774074],
            [110.474241891392836, -6.953900568201569],
            [110.47290777794268, -6.954405639294316],
            [110.472043504599597, -6.954694001254135],
            [110.472021554066146, -6.953750388011476],
            [110.471955224982452, -6.953017416808539],
            [110.471839115286699, -6.952794330642706],
            [110.471718189771423, -6.952405234697091],
            [110.471638354226926, -6.951699588317333],
            [110.471472852588377, -6.950193675997752],
            [110.471400955684203, -6.949821985902292],
            [110.471220665910622, -6.949383078631881],
            [110.471029913478887, -6.949062868346426],
            [110.470808049296352, -6.948851009475719],
            [110.470418662668422, -6.948550355576449],
            [110.465179376903166, -6.945318398503897],
            [110.4636845899783, -6.94498786339074],
            [110.463068628073188, -6.944689075043266],
            [110.462639553385841, -6.944290169326991],
            [110.462318396011369, -6.943864662273525],
            [110.456018346195052, -6.93548145860275],
            [110.454961925901316, -6.934075690672127],
            [110.455369437240563, -6.93393403651872],
            [110.455568190731867, -6.933415144260691],
            [110.45558704008171, -6.933365933574111],
            [110.455765775763268, -6.932926816496128],
            [110.456443408958719, -6.932618879689849],
            [110.456799939598284, -6.932559919694466],
            [110.45729946274065, -6.932144915752812],
            [110.457787415079579, -6.931456805897969],
            [110.458049417229432, -6.930934667736199],
            [110.458410308173697, -6.930823494006043],
            [110.458548639463416, -6.930780880475084],
            [110.459012052821251, -6.930769534766527],
            [110.459452283753976, -6.930247598564934],
            [110.459685621946605, -6.929972235621604],
            [110.460035287124526, -6.929559594066831],
            [110.460249528091026, -6.929239250903954],
            [110.460713209880481, -6.92899043218771],
            [110.460998283624903, -6.929073870622991],
            [110.461735197117989, -6.928872854973319],
            [110.462067731757614, -6.929015714422819],
            [110.462286452781797, -6.92959341868669],
            [110.462553441799926, -6.930298610573868],
            [110.462860424752677, -6.93091061037033],
            [110.462964425358081, -6.931033872333017],
            [110.463180268705912, -6.931289690529606],
            [110.463350375828227, -6.931319028741402],
            [110.46336705826802, -6.93132190594232],
            [110.463398645111823, -6.931711328437332],
            [110.463471138015208, -6.931918570194991],
            [110.463496002505863, -6.931907711966873],
            [110.463537616191005, -6.931880622152148],
            [110.463579219643094, -6.931862577924771],
            [110.463621388847216, -6.931844534332265],
            [110.463664396454021, -6.931835537285362],
            [110.463709960182868, -6.931817497516829],
            [110.463755503458401, -6.931817548926993],
            [110.463802743999253, -6.93181760224845],
            [110.463849135907992, -6.931817654607466],
            [110.463896093572075, -6.931817707600393],
            [110.463941919726537, -6.931817759311908],
            [110.463988604734467, -6.931808766384482],
            [110.464030491043403, -6.93179072243489],
            [110.46406757865131, -6.931763627464241],
            [110.464098160073576, -6.931736525151165],
            [110.464131014729659, -6.931700379796163],
            [110.464174315411398, -6.931682337428028],
            [110.464219868893437, -6.931673343200266],
            [110.464266260791263, -6.931673395517466],
            [110.464311804057132, -6.931673446873237],
            [110.464357923291573, -6.931664453269902],
            [110.464404315189569, -6.931664505573554],
            [110.46445014133306, -6.93166455723491],
            [110.464497381864064, -6.931664610486127],
            [110.464543773763111, -6.931664662776162],
            [110.464591297172305, -6.931664716336869],
            [110.464637971949529, -6.931664768936507],
            [110.464680666028542, -6.931682908255324],
            [110.464701830803193, -6.931728160126482],
            [110.464723854420654, -6.931764367359974],
            [110.464737381509309, -6.931809610624293],
            [110.464755151763597, -6.931854858669019],
            [110.464755666488301, -6.931900087272105],
            [110.464756464090385, -6.931945316193828],
            [110.464758110325207, -6.931990546071622],
            [110.464755796272684, -6.932035771487304],
            [110.464756593874029, -6.932081000408789],
            [110.464764746295884, -6.932126237616906],
            [110.464790447345194, -6.932162448991829],
            [110.464812177904108, -6.93220770149702],
            [110.464829675505172, -6.932243903628099],
            [110.464848577292898, -6.932289152945175],
            [110.464887018064033, -6.93231633306291],
            [110.464932844274131, -6.932316384682552],
            [110.464978660282071, -6.932325481891034],
            [110.465021637311835, -6.932343621502867],
            [110.4650651903018, -6.932352716154739],
            [110.465110713238815, -6.932370858625876],
            [110.465156246378768, -6.932379955499369],
            [110.465202911032392, -6.932389053642501],
            [110.4652464538337, -6.932407193872],
            [110.4652891480048, -6.932425333142459],
            [110.465330427789752, -6.93244347081736],
            [110.46535867480155, -6.932479685031249],
            [110.465362018359812, -6.932524916820774],
            [110.465362229869129, -6.93258823629558],
            [110.465362178891084, -6.932633464264352],
            [110.465362410790789, -6.932678692551425],
            [110.46536151117779, -6.93272391956486],
            [110.465361460198679, -6.932769147533369],
            [110.465361409219241, -6.932814375501793],
            [110.465360792483082, -6.932859602833338],
            [110.465360741502892, -6.932904830801601],
            [110.465360690522374, -6.932950058769745],
            [110.465361205298038, -6.932995287374623],
            [110.465361144120607, -6.933049560936201],
            [110.46537751027796, -6.933085761777792],
            [110.465414536752064, -6.933112940267212],
            [110.465453543379027, -6.933140120982518],
            [110.465491135618691, -6.933167300103274],
            [110.465520231317555, -6.933203515265702],
            [110.465543942137714, -6.933248769972667],
            [110.465563702856585, -6.933284974628799],
            [110.465578644451782, -6.933330219467229],
            [110.465592454535539, -6.933375463032196],
            [110.465602587200706, -6.933420702459284],
            [110.465608193810567, -6.933465936793819],
            [110.465609274363814, -6.933511166035859],
            [110.465611486431385, -6.933556396550943],
            [110.465630105460136, -6.933601645525895],
            [110.465669970966061, -6.933619781587866],
            [110.465715797307979, -6.933619833141603],
            [110.465761623650224, -6.933619884690919],
            [110.465806894424119, -6.933610890005452],
            [110.465852448075267, -6.933601895633672],
            [110.465897991538156, -6.933601946851561],
            [110.46594381788006, -6.933601998383166],
            [110.46598964422229, -6.93360204991035],
            [110.466035470564876, -6.933602101433112],
            [110.466080155209781, -6.933611197273889],
            [110.466119172100548, -6.933629332345533],
            [110.466138387486112, -6.933647445156777],
            [110.46634701964372, -6.933765272504293],
            [110.466447984940203, -6.93403675412998],
            [110.466644300948147, -6.934290251623624],
            [110.466733355062246, -6.934588856645404],
            [110.466830077533757, -6.934860333445743],
            [110.466943318983496, -6.935032327103634],
            [110.467282610171836, -6.935430714569193],
            [110.467693348707257, -6.935937729222998],
            [110.468014931347568, -6.936236594706894],
            [110.468056403108818, -6.936336142859279],
            [110.46805628131105, -6.936444690014916],
            [110.468026436756233, -6.936571295080844],
            [110.467936884072358, -6.936715924444094],
            [110.467882953990724, -6.936878684930065],
            [110.467870849608516, -6.93707767472041],
            [110.467894499903323, -6.937177202909256],
            [110.467995669736439, -6.937267772373308],
            [110.468371163163241, -6.937421968375802],
            [110.468814800800658, -6.937603377183966],
            [110.469231787184597, -6.937839029454128],
            [110.469523821376939, -6.938002176881151],
            [110.469547492304216, -6.938083613818],
            [110.469505807477404, -6.938174023321444],
            [110.469445997959198, -6.938282503777965],
            [110.469380197259497, -6.93843620557052],
            [110.469344290826584, -6.938671351252869],
            [110.469350069287614, -6.938816087451904],
            [110.469439348578163, -6.938915688962658],
            [110.469582102738201, -6.93900630461897],
            [110.469802690016806, -6.939060824760289],
            [110.470022650891138, -6.939169617759912],
            [110.470247966410355, -6.9392965078614],
            [110.470428758329106, -6.939522849890344],
            [110.470954121650806, -6.939984762010836],
            [110.471121416563903, -6.940138723880608],
            [110.471238641314443, -6.940292629905264],
            [110.471271859986942, -6.940437396693755],
            [110.471474525636197, -6.940582352258125],
            [110.47177979732308, -6.940799786875455],
            [110.471942021059277, -6.940935651623206],
            [110.472066075791759, -6.941053382628038],
            [110.472199485895828, -6.941153032788494],
            [110.472333199091452, -6.941234592028396],
            [110.4724955238636, -6.9412800005982],
            [110.472552605911261, -6.941334337735391],
            [110.472581399611272, -6.941388643419381],
            [110.47260023196678, -6.941497211701877],
            [110.472542847740712, -6.941714242596548],
            [110.472561599519651, -6.941895175686144],
            [110.4727237533682, -6.942094359412612],
            [110.472943060017116, -6.942284560984201],
            [110.473296261639192, -6.942393500640536],
            [110.473544583737379, -6.942439004365131],
            [110.474012454261882, -6.942457614618743],
            [110.474136740901614, -6.942367296276575],
            [110.474299186746251, -6.942304157031086],
            [110.47442314022598, -6.94225906630973],
            [110.474642911163798, -6.942286446598389],
            [110.474814561721303, -6.942340910359031],
            [110.475119956463445, -6.942449795760098],
            [110.475291849948206, -6.942540442087498],
            [110.476022715940417, -6.9428940290286],
            [110.476662281233033, -6.943184194866655],
            [110.477129923150372, -6.943410851068505],
            [110.477750213810381, -6.943737176759261],
            [110.478122261527474, -6.943945635539673],
            [110.478303754364461, -6.94405438265413],
            [110.478389339129308, -6.94417206984616],
            [110.478389179704664, -6.944316799580783],
            [110.478312680999409, -6.944425262863689],
            [110.47826496683588, -6.944597077134457],
            [110.47828375074981, -6.944750873324447],
            [110.47836957864692, -6.94490474326942],
            [110.478636636962946, -6.945149268693573],
            [110.478684315241523, -6.945266914166655],
            [110.478731760451282, -6.945339331282725],
            [110.478913819728632, -6.945448078829618],
            [110.478992978127295, -6.945493393915035],
            [110.479151667420695, -6.945502613878912],
            [110.479220398857365, -6.945511734994343],
            [110.479294485241411, -6.945538953221072],
            [110.479400205179473, -6.945611434271957],
            [110.479542690450231, -6.945693001280116],
            [110.479664291876915, -6.945729317227415],
            [110.479812454842815, -6.945792799149443],
            [110.479912819951423, -6.945847182978341],
            [110.479992008329418, -6.945865361077977],
            [110.480457510669567, -6.945983464478485],
            [110.480615837912211, -6.946065048544034],
            [110.480758606437163, -6.946146615520846],
            [110.480864346591673, -6.946201005047674],
            [110.480970126442259, -6.946219212107097],
            [110.481133878286187, -6.946255573837456],
            [110.481173016558401, -6.946265172582123],
            [110.481207974828877, -6.946273746169449],
            [110.481227417297546, -6.94626902295404],
            [110.481245042927497, -6.946264741107735],
            [110.481282130848214, -6.946237644819367],
            [110.481409002907981, -6.946111144893337],
            [110.481517750333822, -6.946002716353751],
            [110.481729795251709, -6.945595895136553],
            [110.48174566660326, -6.945568775613913],
            [110.481771975049639, -6.945568804368911],
            [110.481861923064315, -6.945577948294353],
            [110.482580793287241, -6.945786782683507],
            [110.483284095568905, -6.946004644686217],
            [110.48399499707773, -6.946258696467376],
            [110.484571294350602, -6.946467372775774],
            [110.484772095027338, -6.946512819181092],
            [110.485131819356624, -6.946612711894278],
            [110.485380125569208, -6.946676300897432],
            [110.485483632842005, -6.946703550131084],
            [110.485520730412176, -6.946667407878471],
            [110.485764264248971, -6.945850018142941],
            [110.485906943019231, -6.945865182201747],
            [110.486721522271736, -6.946128728090209],
            [110.487457297426388, -6.94634340711236],
            [110.487815200661785, -6.945266873992785],
            [110.488014137340684, -6.945345887859435],
            [110.488040500347353, -6.945274621971137],
            [110.48816061583473, -6.945319779658297],
            [110.488161513268622, -6.945317099020548],
            [110.488333911432207, -6.944802144516579],
            [110.48879174924771, -6.945069054083809],
            [110.489279669127995, -6.945298472294365],
            [110.489557435558993, -6.945403836779851],
            [110.490175641882388, -6.94562314300634],
            [110.490719975438594, -6.945831777615274],
            [110.491045178889905, -6.945940674451677],
            [110.491196562475508, -6.946002221153394],
            [110.491351711947772, -6.946049551045366],
            [110.491435371822675, -6.946098960846108],
            [110.491515640806867, -6.94618541143867],
            [110.49162129495484, -6.94632120929643],
            [110.491853478849052, -6.946647101151069],
            [110.491943281772947, -6.946791927595001],
            [110.492028229725392, -6.946899285543707],
            [110.492043571012232, -6.946918673986702],
            [110.492101758542717, -6.947000147067681],
            [110.492176271768159, -6.947154080027634],
            [110.491947232645174, -6.947237852970241],
            [110.490573648973111, -6.947740249472643],
            [110.487465132091231, -6.948912830417859],
            [110.487000432615105, -6.949093240223172],
            [110.485989863184983, -6.949435879093858],
            [110.484191354269896, -6.950121392435372],
            [110.480997931534759, -6.951303012123737],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 57,
        id: 59,
        area: 1252786.9337279999,
        perimeter: 5101.29009,
        kode_pos: 50196,
        kode_kec: 3374080,
        kode_kel: 3374041011,
        kec: "Pedurungan",
        kel: "Tlogosari Wetan",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.465747757609265, -6.994713810058315],
            [110.465742106186354, -6.99462334782329],
            [110.465684097515023, -6.993637313520477],
            [110.465663038052028, -6.993248329573075],
            [110.465663305324199, -6.993013144724225],
            [110.465668834884369, -6.992877467254645],
            [110.465679698690366, -6.99277797816698],
            [110.465771897247294, -6.992307712425438],
            [110.465934607401692, -6.991529976742519],
            [110.465978303984713, -6.991168202922135],
            [110.466060527980019, -6.990761244830415],
            [110.466181047671427, -6.990263874250114],
            [110.466304072231509, -6.989802688786455],
            [110.466726911859539, -6.988156870882218],
            [110.466900399859142, -6.987352009938036],
            [110.46695471248357, -6.987107840514982],
            [110.466944023352028, -6.987053554906049],
            [110.466922573370482, -6.987008302714961],
            [110.467124062179678, -6.9859592423899],
            [110.46715142635172, -6.98577836156458],
            [110.467210575009773, -6.985760337233917],
            [110.467485866857615, -6.985742557071169],
            [110.467561131135596, -6.985733596485217],
            [110.467648032479488, -6.985190959303238],
            [110.46777857661769, -6.984331775720193],
            [110.467849085707556, -6.984024305256447],
            [110.467931364413843, -6.983816349535351],
            [110.467979854254352, -6.983716902753651],
            [110.468012470525025, -6.983644574828973],
            [110.468001842641399, -6.983536015763429],
            [110.467991013616441, -6.983355091752141],
            [110.467996603503238, -6.983165140670357],
            [110.468018653158182, -6.982929980201782],
            [110.4679847320172, -6.982848531631336],
            [110.468041186849888, -6.982767184995176],
            [110.468052687106535, -6.982604377342219],
            [110.468120891547315, -6.982333086545545],
            [110.468143779577844, -6.982106972591204],
            [110.468121341058549, -6.981935081071555],
            [110.46805348408283, -6.981898822195118],
            [110.467997205894875, -6.981880667548742],
            [110.467872787854262, -6.981826253676179],
            [110.46785024723323, -6.981744817937317],
            [110.467839217113806, -6.981491528967131],
            [110.467794176798577, -6.981292475171291],
            [110.467876373204646, -6.981156884033394],
            [110.467978069718924, -6.981039406048408],
            [110.468023437025309, -6.980949001298207],
            [110.46811401836716, -6.98090387547134],
            [110.468249551277225, -6.98088593706492],
            [110.468486838855412, -6.980949523586745],
            [110.468566083464566, -6.980922476081084],
            [110.468645116587993, -6.980832109181264],
            [110.468758871101684, -6.980307592946475],
            [110.46908797562152, -6.978978261230539],
            [110.469167535789822, -6.978670800559209],
            [110.469193485037962, -6.978570519454942],
            [110.469330021866199, -6.978693545522572],
            [110.470350272156082, -6.979586486404064],
            [110.471527702520859, -6.980622517258343],
            [110.473048047721321, -6.981990493509142],
            [110.473499155635366, -6.982353922254795],
            [110.474021540690075, -6.982772441692974],
            [110.473375977595225, -6.983542047283023],
            [110.473359518291247, -6.983587256890814],
            [110.473352950679072, -6.983641523141122],
            [110.473365903784824, -6.983695811189744],
            [110.473388758708765, -6.983750110294896],
            [110.473483694752417, -6.983858763463712],
            [110.473688591495034, -6.984048949727664],
            [110.474117623322911, -6.984429343475371],
            [110.474150380197813, -6.984483653593517],
            [110.474170092931189, -6.984565085959007],
            [110.47422217862966, -6.984791283991877],
            [110.474258219330309, -6.984945099345812],
            [110.474422014119085, -6.985207604295924],
            [110.474546454286198, -6.985497202137776],
            [110.474493408847835, -6.985877058142482],
            [110.474341081956183, -6.986998542445081],
            [110.474218702451964, -6.987902965674969],
            [110.474123027645135, -6.98820136363145],
            [110.473616037911398, -6.989222950000064],
            [110.473583098622129, -6.98933146035201],
            [110.473477116719408, -6.989738393736623],
            [110.473135255433064, -6.990335020867665],
            [110.473085270225383, -6.990506831265125],
            [110.473097523387978, -6.991438541171802],
            [110.473077425525531, -6.991700840917531],
            [110.473027245691497, -6.992551070592038],
            [110.472983846356897, -6.993157076789229],
            [110.473055361042739, -6.993717983585566],
            [110.473072092626154, -6.993935096540434],
            [110.473120691314122, -6.994495977681717],
            [110.473094247391785, -6.994866817397948],
            [110.473065590780323, -6.99519242666482],
            [110.473022202193022, -6.995536110618717],
            [110.473006166192462, -6.995707958924014],
            [110.472986794950742, -6.996078806520578],
            [110.472973185227417, -6.99643156937448],
            [110.472850033545967, -6.996431431489877],
            [110.472411675273491, -6.996286210969309],
            [110.47195981861519, -6.996068610194744],
            [110.471877530612289, -6.996032335531361],
            [110.471768052157799, -6.996023167118987],
            [110.471321115605619, -6.995959346316726],
            [110.471191833050725, -6.995950155533232],
            [110.470954203765302, -6.995931797387135],
            [110.470821556791691, -6.995895465955083],
            [110.47064338071263, -6.995840992120448],
            [110.47047228777366, -6.995777480599208],
            [110.470162030769998, -6.995686675665192],
            [110.469892462791549, -6.99564114428877],
            [110.469680316612667, -6.995604723030754],
            [110.46942036789001, -6.995559202225423],
            [110.469178798546068, -6.99552274758869],
            [110.468725628628604, -6.995467962940881],
            [110.468272741765063, -6.995413178184226],
            [110.467950026999958, -6.995331403356754],
            [110.467816269308514, -6.995276978681593],
            [110.467610974971208, -6.995186290750361],
            [110.46714949252204, -6.994977720292374],
            [110.466906853626497, -6.994886989810657],
            [110.466793718242101, -6.994859724960391],
            [110.466599406689227, -6.994814276968575],
            [110.466232799750244, -6.994768633624308],
            [110.465747757609265, -6.994713810058315],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 58,
        id: 60,
        area: 1953263.27003,
        perimeter: 7191.231591,
        kode_pos: 50192,
        kode_kec: 3374080,
        kode_kel: 3374041007,
        kec: "Pedurungan",
        kel: "Pedurungan Tengah",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.465747757609265, -6.994713810058315],
            [110.466232799750244, -6.994768633624308],
            [110.466599406689227, -6.994814276968575],
            [110.466793718242101, -6.994859724960391],
            [110.466906853626497, -6.994886989810657],
            [110.46714949252204, -6.994977720292374],
            [110.467610974971208, -6.995186290750361],
            [110.467816269308514, -6.995276978681593],
            [110.467950026999958, -6.995331403356754],
            [110.468272741765063, -6.995413178184226],
            [110.468725628628604, -6.995467962940881],
            [110.469178798546068, -6.99552274758869],
            [110.46942036789001, -6.995559202225423],
            [110.469680316612667, -6.995604723030754],
            [110.469892462791549, -6.99564114428877],
            [110.470162030769998, -6.995686675665192],
            [110.47047228777366, -6.995777480599208],
            [110.47064338071263, -6.995840992120448],
            [110.470821556791691, -6.995895465955083],
            [110.470954203765302, -6.995931797387135],
            [110.471191833050725, -6.995950155533232],
            [110.471321115605619, -6.995959346316726],
            [110.471768052157799, -6.996023167118987],
            [110.471877530612289, -6.996032335531361],
            [110.47195981861519, -6.996068610194744],
            [110.472411675273491, -6.996286210969309],
            [110.472850033545967, -6.996431431489877],
            [110.472973185227417, -6.99643156937448],
            [110.472961896076797, -6.996585331798675],
            [110.473511300101038, -6.996604037748481],
            [110.474349713653226, -6.996740658578676],
            [110.474863147155219, -6.996795505178786],
            [110.475266899930816, -6.996768818624219],
            [110.475595912145991, -6.996787276444199],
            [110.47598793420056, -6.996878168948599],
            [110.476359798758523, -6.997032357890393],
            [110.476691832686555, -6.997132228694806],
            [110.477046813272253, -6.997204987976302],
            [110.477298862386263, -6.997232404720697],
            [110.477468865513472, -6.997259730261767],
            [110.477897364677773, -6.997368752918051],
            [110.478311688064949, -6.99750489628437],
            [110.478512176197412, -6.997595574441698],
            [110.479242005555236, -6.99794011527634],
            [110.479851373041157, -6.998230248330062],
            [110.479386257039579, -6.999505163679443],
            [110.479130261886709, -7.00022852833548],
            [110.478915742748711, -7.000798163535477],
            [110.47867565984545, -7.001458226239347],
            [110.478513054538595, -7.001901280308672],
            [110.47829882672896, -7.002461869802854],
            [110.478535470461267, -7.002606861770412],
            [110.47903963689339, -7.0028606971884],
            [110.479150178642101, -7.002933184398358],
            [110.479468374320746, -7.003014947044178],
            [110.479789975198827, -7.003087667649787],
            [110.48026317178477, -7.003205783586933],
            [110.480820689141808, -7.003314946553122],
            [110.480884892235096, -7.003333108623115],
            [110.480900412892666, -7.003369308155905],
            [110.480913094339485, -7.003414550155122],
            [110.480833333410573, -7.00364964772367],
            [110.480794463146054, -7.003749106414736],
            [110.481082630067846, -7.003867017275037],
            [110.481162403926632, -7.00387615089319],
            [110.481200891244541, -7.0038671477522],
            [110.481244794050724, -7.003821968178539],
            [110.481291313101721, -7.003713472279517],
            [110.481316816001964, -7.003677318003616],
            [110.481403995752402, -7.003641231741359],
            [110.481562158778615, -7.003632360520491],
            [110.481752241989327, -7.003668752429698],
            [110.48186625930785, -7.003668878059304],
            [110.481917012039801, -7.003569432352275],
            [110.481961500556849, -7.003506162145526],
            [110.482037646303993, -7.003470063605644],
            [110.482113459228415, -7.003479192696746],
            [110.48225913376605, -7.003506489893697],
            [110.48263529949925, -7.003615451084918],
            [110.482913897371887, -7.003688122308344],
            [110.483173549559496, -7.00375172694905],
            [110.483407768749089, -7.003788166703153],
            [110.484174044196394, -7.003933737483978],
            [110.484630064058749, -7.003979652337734],
            [110.484363551528403, -7.004494772817523],
            [110.483296216357857, -7.004376008384225],
            [110.48307184935102, -7.004384807400831],
            [110.482972543774025, -7.004384698226514],
            [110.482839014618449, -7.004375505791704],
            [110.482730918534557, -7.004393478104217],
            [110.482674870099828, -7.00442055325538],
            [110.482592723052733, -7.004510918900714],
            [110.482433055351933, -7.004601199211283],
            [110.482247278947625, -7.004763815530746],
            [110.482134972357997, -7.005007923102311],
            [110.481930140052313, -7.005260974298455],
            [110.481843778834374, -7.005324198342182],
            [110.481314371097085, -7.005377888223609],
            [110.481270205212439, -7.005404976300064],
            [110.481240458368703, -7.005441125881297],
            [110.481127292317097, -7.00569427780716],
            [110.480932444300208, -7.006128251509523],
            [110.480884605834078, -7.006661889048266],
            [110.480844992011143, -7.007177444448076],
            [110.480772356058964, -7.007620598560181],
            [110.480720586233147, -7.008127094886072],
            [110.480701920964222, -7.008886904516276],
            [110.480706382758186, -7.009456782120687],
            [110.479994997329385, -7.009302220292408],
            [110.479908149475989, -7.009293078583795],
            [110.479631722024735, -7.009301818160262],
            [110.479508922676487, -7.009310727758752],
            [110.479452620664702, -7.009310665394611],
            [110.479426904633669, -7.009283500118957],
            [110.479416759499586, -7.009247306495603],
            [110.47939110370703, -7.009165867708133],
            [110.479355475287619, -7.009147737043959],
            [110.479247963948779, -7.009147617918445],
            [110.478674849262347, -7.009065572119979],
            [110.478342160238199, -7.00903806614986],
            [110.478290940850144, -7.009047054885528],
            [110.478275572387062, -7.009128448175435],
            [110.478232537677187, -7.00915553717962],
            [110.478005915130979, -7.009155285505062],
            [110.47744069242033, -7.009100383762918],
            [110.477071476006969, -7.00909997305642],
            [110.476708130395465, -7.009162887744104],
            [110.476602589477366, -7.009171815799493],
            [110.476391820848804, -7.009162535411412],
            [110.476174858200267, -7.009126111250108],
            [110.475923095892895, -7.009089648183445],
            [110.475753341262575, -7.009089458840995],
            [110.475717561213884, -7.009460288153711],
            [110.475623468236208, -7.009858189154219],
            [110.475467353787906, -7.010310294424636],
            [110.475370370804285, -7.010509189126611],
            [110.475358447470938, -7.010545358172682],
            [110.475283108396951, -7.010617638758994],
            [110.475203808345299, -7.010689914906952],
            [110.475132450425946, -7.010744108733016],
            [110.475061112739738, -7.010780211390611],
            [110.475029344096086, -7.010852540609397],
            [110.475009690607052, -7.010970111309796],
            [110.475017328993928, -7.011223396344883],
            [110.475002635998464, -7.011711724019653],
            [110.47482306908006, -7.011630230724572],
            [110.473952790357572, -7.011375980540487],
            [110.472718374991871, -7.011076442946077],
            [110.47184226164245, -7.010785510626863],
            [110.471897829135997, -7.010432934152242],
            [110.472108331740316, -7.009673341520795],
            [110.472336112297796, -7.008895676948141],
            [110.4725210179935, -7.008253647870368],
            [110.472564195273193, -7.008099921349489],
            [110.472533761552995, -7.007991340152731],
            [110.47246627503668, -7.007873671781971],
            [110.472011600804805, -7.007384699585447],
            [110.470987875469632, -7.006225713651611],
            [110.470602176131663, -7.005791091403838],
            [110.470362533757452, -7.005546590714691],
            [110.469832749813762, -7.004930893901684],
            [110.469117323294995, -7.004106938615649],
            [110.468509903229346, -7.003346423546475],
            [110.46724080990785, -7.001771056489029],
            [110.465614789869591, -6.999851549256233],
            [110.465547394476758, -6.999679606690432],
            [110.465615937046067, -6.999589228733259],
            [110.465663534211657, -6.999281733050937],
            [110.465687345627103, -6.998992301511003],
            [110.465699629386293, -6.998639537811751],
            [110.46571209826017, -6.998123953859262],
            [110.46570670215057, -6.997644531939088],
            [110.465703605423784, -6.997382206570105],
            [110.46568880103294, -6.996966093026938],
            [110.465658359178363, -6.996866557078003],
            [110.46555926121188, -6.996685532955766],
            [110.465521252669475, -6.996522669340994],
            [110.465544940463161, -6.996341784607293],
            [110.465676573534608, -6.996025338670492],
            [110.465725523007791, -6.995772117931844],
            [110.465800721898844, -6.995573200456922],
            [110.46584989247981, -6.995374253436069],
            [110.465838802002423, -6.995175238045551],
            [110.465752965114277, -6.994777135042619],
            [110.465747757609265, -6.994713810058315],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 59,
        id: 61,
        area: 2416699.3292189999,
        perimeter: 7398.548102,
        kode_pos: 50196,
        kode_kec: 3374080,
        kode_kel: 3374041010,
        kec: "Pedurungan",
        kel: "Tlogosari Kulon",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.465747757609265, -6.994713810058315],
            [110.465752965114277, -6.994777135042619],
            [110.465838802002423, -6.995175238045551],
            [110.46584989247981, -6.995374253436069],
            [110.465800721898844, -6.995573200456922],
            [110.465725523007791, -6.995772117931844],
            [110.465676573534608, -6.996025338670492],
            [110.465544940463161, -6.996341784607293],
            [110.465521252669475, -6.996522669340994],
            [110.46555926121188, -6.996685532955766],
            [110.465658359178363, -6.996866557078003],
            [110.46568880103294, -6.996966093026938],
            [110.465703605423784, -6.997382206570105],
            [110.46570670215057, -6.997644531939088],
            [110.46571209826017, -6.998123953859262],
            [110.465699629386293, -6.998639537811751],
            [110.465687345627103, -6.998992301511003],
            [110.465663534211657, -6.999281733050937],
            [110.465615937046067, -6.999589228733259],
            [110.465547394476758, -6.999679606690432],
            [110.4654394887406, -6.999507618099631],
            [110.465169379458359, -6.999190715889981],
            [110.464291930171328, -6.998303251125863],
            [110.463589748036128, -6.997542622800315],
            [110.462536537769424, -6.996474043309747],
            [110.461294321009916, -6.995179106170367],
            [110.460673492914736, -6.994536159646938],
            [110.460466648358121, -6.994318828902655],
            [110.460338664490465, -6.994164907481177],
            [110.460233030957212, -6.994011011648208],
            [110.460169520589602, -6.99388430080654],
            [110.460144717595981, -6.993802862203723],
            [110.460087179642002, -6.993649021476442],
            [110.460043494132762, -6.993504242200471],
            [110.459985935463379, -6.993368492589355],
            [110.459933710632242, -6.993268931386982],
            [110.459856861907653, -6.993178387515396],
            [110.45975260173401, -6.993060675468958],
            [110.459631083028484, -6.99292485780625],
            [110.45920410032322, -6.992508266007139],
            [110.458770735491726, -6.991965033864857],
            [110.457886195712575, -6.990869502509169],
            [110.457303099102973, -6.990145185473977],
            [110.456456727739564, -6.989076832535932],
            [110.456345490594416, -6.988877701663809],
            [110.455862779309228, -6.987954496385519],
            [110.454737549682847, -6.985592302400771],
            [110.4546255176176, -6.985592172824461],
            [110.454443062120447, -6.985573870600761],
            [110.452791566011982, -6.985098716276779],
            [110.451721754629403, -6.984790915773591],
            [110.451720810886655, -6.984783959103597],
            [110.451684493456582, -6.984622434128714],
            [110.451418898994959, -6.982778013165166],
            [110.451153380874786, -6.980869387091572],
            [110.450916312591431, -6.97929956661211],
            [110.450901756977061, -6.97920318188137],
            [110.450892370944359, -6.979142267809277],
            [110.45089235968625, -6.979142194745624],
            [110.450786365403289, -6.978439078021197],
            [110.450636171326096, -6.977444514949545],
            [110.450502148628871, -6.976442529228829],
            [110.450502146954577, -6.976442519330825],
            [110.450689471259111, -6.976463549148321],
            [110.450988849040399, -6.976497158405441],
            [110.451248470313601, -6.976569824804276],
            [110.451640763003553, -6.976651690763251],
            [110.451667911238957, -6.97666076786625],
            [110.452032836051814, -6.976679282736556],
            [110.452170872067995, -6.976697534082491],
            [110.452531479850208, -6.976779362550167],
            [110.452765105928577, -6.976824861285324],
            [110.452872033102324, -6.976834030797571],
            [110.453211727245701, -6.9768977433809],
            [110.453429814734591, -6.976925132655365],
            [110.453518919003585, -6.976934281387207],
            [110.453681305901981, -6.976934469347847],
            [110.453976584333873, -6.976998129988871],
            [110.454170310760134, -6.977052627465368],
            [110.454219242694535, -6.977061729614948],
            [110.45426136398298, -6.977088915030374],
            [110.454302039333712, -6.977143235487816],
            [110.454280308176422, -6.977342212954898],
            [110.454251734910599, -6.977586410367527],
            [110.454597926186239, -6.977659175091631],
            [110.454642887063216, -6.977677318186588],
            [110.454938145204011, -6.977759069392212],
            [110.455526283625616, -6.978022069840753],
            [110.455729315206213, -6.978103714177272],
            [110.455779944719751, -6.978112818132868],
            [110.456430720908784, -6.97827638841854],
            [110.456742974972329, -6.978340066968592],
            [110.457351809024829, -6.978566906784986],
            [110.457623618416491, -6.978621492693525],
            [110.457707337536561, -6.97863968005252],
            [110.457926012870402, -6.978648976850088],
            [110.458127378985637, -6.978703481559996],
            [110.458161579317675, -6.978730657559039],
            [110.458204518513796, -6.978784980320643],
            [110.458239567572321, -6.978812157289096],
            [110.458287923587349, -6.978830303952448],
            [110.458418584276487, -6.978866636225448],
            [110.458811142681427, -6.978966587918925],
            [110.460220121907795, -6.979366207125327],
            [110.460528678896338, -6.979447970193146],
            [110.460823697932668, -6.979493535274944],
            [110.463326045912169, -6.980201943394074],
            [110.463947668390446, -6.980383561723772],
            [110.464907519700603, -6.980927386434221],
            [110.465517642835223, -6.981271810332327],
            [110.466008441516109, -6.981561824923855],
            [110.466409239278619, -6.98163464313618],
            [110.467014841741928, -6.981725783632831],
            [110.46685715862192, -6.982566845196921],
            [110.467001632470058, -6.982648418771026],
            [110.467284155300902, -6.982739193828213],
            [110.467498831430589, -6.98278466418489],
            [110.467770089250081, -6.982830198297073],
            [110.4679847320172, -6.982848531631336],
            [110.468018653158182, -6.982929980201782],
            [110.467996603503238, -6.983165140670357],
            [110.467991013616441, -6.983355091752141],
            [110.468001842641399, -6.983536015763429],
            [110.468012470525025, -6.983644574828973],
            [110.467979854254352, -6.983716902753651],
            [110.467931364413843, -6.983816349535351],
            [110.467849085707556, -6.984024305256447],
            [110.46777857661769, -6.984331775720193],
            [110.467648032479488, -6.985190959303238],
            [110.467561131135596, -6.985733596485217],
            [110.467485866857615, -6.985742557071169],
            [110.467210575009773, -6.985760337233917],
            [110.46715142635172, -6.98577836156458],
            [110.467124062179678, -6.9859592423899],
            [110.466922573370482, -6.987008302714961],
            [110.466944023352028, -6.987053554906049],
            [110.46695471248357, -6.987107840514982],
            [110.466900399859142, -6.987352009938036],
            [110.466726911859539, -6.988156870882218],
            [110.466304072231509, -6.989802688786455],
            [110.466181047671427, -6.990263874250114],
            [110.466060527980019, -6.990761244830415],
            [110.465978303984713, -6.991168202922135],
            [110.465934607401692, -6.991529976742519],
            [110.465771897247294, -6.992307712425438],
            [110.465679698690366, -6.99277797816698],
            [110.465668834884369, -6.992877467254645],
            [110.465663305324199, -6.993013144724225],
            [110.465663038052028, -6.993248329573075],
            [110.465684097515023, -6.993637313520477],
            [110.465742106186354, -6.99462334782329],
            [110.465747757609265, -6.994713810058315],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 60,
        id: 62,
        area: 1399357.957829,
        perimeter: 6176.62771,
        kode_pos: 50246,
        kode_kec: 3374080,
        kode_kel: 3374041008,
        kec: "Pedurungan",
        kel: "Palebon",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.459631083028484, -6.99292485780625],
            [110.45975260173401, -6.993060675468958],
            [110.459856861907653, -6.993178387515396],
            [110.459933710632242, -6.993268931386982],
            [110.459985935463379, -6.993368492589355],
            [110.460043494132762, -6.993504242200471],
            [110.460087179642002, -6.993649021476442],
            [110.460144717595981, -6.993802862203723],
            [110.460169520589602, -6.99388430080654],
            [110.460233030957212, -6.994011011648208],
            [110.460338664490465, -6.994164907481177],
            [110.460466648358121, -6.994318828902655],
            [110.460673492914736, -6.994536159646938],
            [110.461294321009916, -6.995179106170367],
            [110.462536537769424, -6.996474043309747],
            [110.463589748036128, -6.997542622800315],
            [110.464291930171328, -6.998303251125863],
            [110.465169379458359, -6.999190715889981],
            [110.4654394887406, -6.999507618099631],
            [110.465547394476758, -6.999679606690432],
            [110.465614789869591, -6.999851549256233],
            [110.46724080990785, -7.001771056489029],
            [110.468509903229346, -7.003346423546475],
            [110.469117323294995, -7.004106938615649],
            [110.469832749813762, -7.004930893901684],
            [110.470362533757452, -7.005546590714691],
            [110.470602176131663, -7.005791091403838],
            [110.470987875469632, -7.006225713651611],
            [110.472011600804805, -7.007384699585447],
            [110.47246627503668, -7.007873671781971],
            [110.472533761552995, -7.007991340152731],
            [110.472564195273193, -7.008099921349489],
            [110.4725210179935, -7.008253647870368],
            [110.472336112297796, -7.008895676948141],
            [110.472108331740316, -7.009673341520795],
            [110.471897829135997, -7.010432934152242],
            [110.47184226164245, -7.010785510626863],
            [110.471591388349239, -7.010713002740443],
            [110.471109416488815, -7.010594867837583],
            [110.470626899475548, -7.010458640676776],
            [110.470357353702397, -7.010385972240343],
            [110.468490926996893, -7.00985922092968],
            [110.465929576773732, -7.009141716109657],
            [110.463617197624714, -7.008433527677676],
            [110.46063586961489, -7.00758887766123],
            [110.459616821877262, -7.007307294573256],
            [110.457724990998557, -7.006753189426575],
            [110.457890279271524, -7.006455023298892],
            [110.458018190478228, -7.006183803782895],
            [110.458152744489027, -7.005794999502026],
            [110.458267976079213, -7.005478537435842],
            [110.458274618995631, -7.005360952713545],
            [110.458268289289521, -7.005207170774111],
            [110.458346049792354, -7.004999212304345],
            [110.45855736858536, -7.004529086147305],
            [110.458614832812543, -7.004502015605078],
            [110.458710439190014, -7.004520216804019],
            [110.458793680490473, -7.004466039204864],
            [110.458895929598683, -7.004366655618396],
            [110.459081178658877, -7.00417691179565],
            [110.459106735318571, -7.004095531069821],
            [110.45913890335612, -7.003923702253396],
            [110.459196440420058, -7.003833312729688],
            [110.459349908309719, -7.003724942351248],
            [110.459484064647512, -7.003679868711123],
            [110.459579733382611, -7.003643796375705],
            [110.459662733154275, -7.00355343604334],
            [110.459765044331121, -7.003399778884494],
            [110.459848410197296, -7.003237054368935],
            [110.459912192089121, -7.003128580768969],
            [110.460080766692059, -7.002676495767528],
            [110.460170429334525, -7.002450459400865],
            [110.460247225335962, -7.002342000673632],
            [110.460330255926664, -7.002224503512011],
            [110.460407041457927, -7.002125090311828],
            [110.460490092753304, -7.00198950199491],
            [110.460541436252655, -7.001871968442225],
            [110.460592600706249, -7.00166397895427],
            [110.460609396835778, -7.001573542490678],
            [110.460621990727759, -7.001446918918318],
            [110.460653791937304, -7.001347454082422],
            [110.460682429084798, -7.001293213478133],
            [110.460720381776937, -7.001257074695084],
            [110.460767701946395, -7.00119380992767],
            [110.460821916003766, -7.001040097327736],
            [110.460812652375779, -7.00097676770164],
            [110.460790730100641, -7.000850104557971],
            [110.460803282371387, -7.000759663215492],
            [110.460857130370613, -7.000678314768322],
            [110.460898215866905, -7.000624088409078],
            [110.460961620776018, -7.000597024336316],
            [110.461021599515092, -7.000597093049281],
            [110.461103666661629, -7.000579095908119],
            [110.461144972773724, -7.00057914321754],
            [110.461195290773318, -7.000615383137639],
            [110.46122379291765, -7.000678734791731],
            [110.461245850195667, -7.000687805623444],
            [110.461305901572686, -7.000624555368876],
            [110.461331436867212, -7.000561265589269],
            [110.461334631963823, -7.000488904657617],
            [110.461315842410045, -7.000344153965939],
            [110.46125263468285, -7.000199352416636],
            [110.461252686558325, -7.00015412460741],
            [110.46125272805844, -7.000117942359966],
            [110.461274816435349, -7.000099876502859],
            [110.4613255521719, -7.000018524424643],
            [110.461357028707084, -6.999955241440905],
            [110.461322678658334, -6.999810472934686],
            [110.461237424130942, -6.999647554998528],
            [110.461189904279223, -6.999638455014564],
            [110.461057194976703, -6.99965639418476],
            [110.460975127992967, -6.999674391330709],
            [110.460902397299009, -6.999692399158224],
            [110.460788674634685, -6.999683223282988],
            [110.460526461576777, -6.99963769487387],
            [110.46045375165609, -6.999637611509472],
            [110.460374786367254, -6.999664657678307],
            [110.460330337066623, -6.999691743420213],
            [110.460302014088171, -6.99971884765381],
            [110.460276782418347, -6.999764046576119],
            [110.460260918220655, -6.999782119524372],
            [110.460232333102937, -6.999791132307498],
            [110.460191309984424, -6.999791085247824],
            [110.460159654348658, -6.999763912214745],
            [110.459907601344099, -6.999248025372244],
            [110.459753126219411, -6.99900361761178],
            [110.459711221171219, -6.998786475775626],
            [110.459810071658012, -6.998442857506303],
            [110.459880495099682, -6.998216799021308],
            [110.459838454845084, -6.998117249477194],
            [110.45974006212488, -6.998062863110151],
            [110.45948728001747, -6.997935934872183],
            [110.459248581434395, -6.997863296118763],
            [110.459122117602618, -6.997863150809101],
            [110.458953299574304, -6.99779059221039],
            [110.458953393315824, -6.997709182176397],
            [110.458981862045476, -6.997555440186384],
            [110.458911644952764, -6.997356356903471],
            [110.4587854417194, -6.997130072531887],
            [110.458719185589601, -6.996930993781797],
            [110.458694665649162, -6.996849555445315],
            [110.458725428973096, -6.996668679392082],
            [110.458713357348344, -6.996587255369456],
            [110.458676640776304, -6.996532939722488],
            [110.458566093560719, -6.996469493594062],
            [110.458498832515005, -6.996406097233476],
            [110.45843127814797, -6.996351746098131],
            [110.45840683124888, -6.996206988837179],
            [110.458382384361528, -6.996062231575233],
            [110.458345678290883, -6.995998870348251],
            [110.458352048379993, -6.995872239681367],
            [110.458389025458729, -6.995700416367624],
            [110.458496804306208, -6.995465355482246],
            [110.458643594087874, -6.995257476131508],
            [110.458666321065337, -6.99517609211472],
            [110.458666612746512, -6.994922816436477],
            [110.45870010088592, -6.99483239921465],
            [110.458733682749624, -6.994660571947565],
            [110.45876718126847, -6.994561109157267],
            [110.458822977973028, -6.99450689985146],
            [110.458906217398734, -6.99445272207223],
            [110.458995377195947, -6.99441664222374],
            [110.459039826041476, -6.994389556569498],
            [110.459073303657021, -6.994308184871493],
            [110.459079045215731, -6.994235826884236],
            [110.45905116156608, -6.994127247980832],
            [110.45903491905726, -6.993982500157442],
            [110.459068134540203, -6.993883037009297],
            [110.459140623077019, -6.993828846827885],
            [110.459229793142114, -6.993783721362325],
            [110.459290944264964, -6.99374760928316],
            [110.459313053197462, -6.993711452373256],
            [110.45928061167622, -6.993630004966617],
            [110.459191566504785, -6.993566583709432],
            [110.459180564063644, -6.99353943435532],
            [110.459219668655223, -6.99348520581909],
            [110.459381064973527, -6.993367798657413],
            [110.459525758883998, -6.99325941784093],
            [110.459570280367402, -6.993169013190544],
            [110.459598151492358, -6.993042407134294],
            [110.459631083028484, -6.99292485780625],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 61,
        id: 63,
        area: 1253856.6848569999,
        perimeter: 6513.348818,
        kode_pos: 50246,
        kode_kec: 3374080,
        kode_kel: 3374041001,
        kec: "Pedurungan",
        kel: "Gemah",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.457724990998557, -7.006753189426575],
            [110.459616821877262, -7.007307294573256],
            [110.46063586961489, -7.00758887766123],
            [110.463617197624714, -7.008433527677676],
            [110.465929576773732, -7.009141716109657],
            [110.468490926996893, -7.00985922092968],
            [110.470357353702397, -7.010385972240343],
            [110.470626899475548, -7.010458640676776],
            [110.471109416488815, -7.010594867837583],
            [110.471591388349239, -7.010713002740443],
            [110.47184226164245, -7.010785510626863],
            [110.472718374991871, -7.011076442946077],
            [110.472458867720846, -7.011944177214185],
            [110.472373694763107, -7.012206403524342],
            [110.472263563102743, -7.012522875277081],
            [110.472212947665568, -7.012748958023994],
            [110.47212306424386, -7.013173999485088],
            [110.472075267728286, -7.013409130953404],
            [110.472000450463852, -7.013770870209179],
            [110.472000042930532, -7.014132693107416],
            [110.471990461796622, -7.01434977634566],
            [110.472018084432321, -7.014693539585158],
            [110.472008003970217, -7.015353855847072],
            [110.472017157170598, -7.015516686640626],
            [110.472054350833034, -7.01565241221502],
            [110.472137396917717, -7.016023374509533],
            [110.472174266963478, -7.016195282046424],
            [110.472155693213381, -7.016358081661981],
            [110.472118153033563, -7.016529905530988],
            [110.471978200313487, -7.016945844946572],
            [110.471847311250912, -7.017352748910795],
            [110.471707378395124, -7.017750597053672],
            [110.471562584430515, -7.017998991849928],
            [110.471473065755816, -7.018152561433275],
            [110.471426265175097, -7.018118811787986],
            [110.471360124339967, -7.018070689252456],
            [110.471102234618499, -7.017820963723414],
            [110.470821684034561, -7.017540033150241],
            [110.470587882530253, -7.017314427682098],
            [110.470025458974177, -7.016668946958698],
            [110.469937590944724, -7.016597985547826],
            [110.46977450136238, -7.016562370269188],
            [110.469211479467447, -7.016446937256862],
            [110.468719493048226, -7.016235211404219],
            [110.467695719483359, -7.015885409789674],
            [110.466805233181375, -7.015583943711947],
            [110.465914835347363, -7.015205944991301],
            [110.46522992123613, -7.015005334138499],
            [110.464611698900683, -7.014786374215695],
            [110.464380593163028, -7.014688320892286],
            [110.464233181327927, -7.014588945718012],
            [110.464108481938823, -7.014475423993056],
            [110.463890160900689, -7.014361795364557],
            [110.463663296480675, -7.014276501740498],
            [110.463313026638829, -7.014186815265877],
            [110.463015223951388, -7.014112778206896],
            [110.462757090909719, -7.014081303628672],
            [110.462561349582714, -7.014069741714501],
            [110.462447885071725, -7.014055439422131],
            [110.462368487551615, -7.014021334710167],
            [110.462325997292808, -7.013964596362869],
            [110.462289307878677, -7.013797319737873],
            [110.462171924487606, -7.013489643698751],
            [110.462041717759035, -7.013231556437639],
            [110.461922868973545, -7.012964978705503],
            [110.461876216058684, -7.012827452735856],
            [110.461854451778777, -7.012683335853181],
            [110.461850209633894, -7.012655929436026],
            [110.461778929723479, -7.012312882244973],
            [110.461801999928866, -7.011986942901619],
            [110.461782231981658, -7.011907554671666],
            [110.461714238206383, -7.011825276697343],
            [110.461547001370448, -7.011697533210837],
            [110.461293283238561, -7.011528590476988],
            [110.461052291084741, -7.011395093277876],
            [110.460853792178469, -7.011315499942083],
            [110.460524823904379, -7.011207411879044],
            [110.460143359877279, -7.011109183971385],
            [110.459854087083272, -7.011020982463011],
            [110.459610225986467, -7.010915826114992],
            [110.459414629910214, -7.010779545860113],
            [110.459251621830887, -7.010675899605871],
            [110.459047443052086, -7.010601967981042],
            [110.458684415474067, -7.010508011898703],
            [110.45803205715913, -7.010376873608213],
            [110.457386839307716, -7.010204642677949],
            [110.457036623225548, -7.010072434642179],
            [110.456764531311137, -7.009848195989504],
            [110.456481150821745, -7.009572923447705],
            [110.456328111106899, -7.009436691231606],
            [110.456101299954355, -7.009308877040789],
            [110.455831888102466, -7.009220696177465],
            [110.455078979357509, -7.00895196513849],
            [110.454852046943586, -7.00892902608778],
            [110.454619457068318, -7.008891907952983],
            [110.454247937475756, -7.008783766338622],
            [110.454056498838042, -7.008733940551862],
            [110.453911885304251, -7.008671413934229],
            [110.45375029612552, -7.008569184799443],
            [110.453614239848662, -7.008466985298809],
            [110.453366349135308, -7.008170493711179],
            [110.453261526246621, -7.008045654593891],
            [110.453125437191702, -7.007971799746567],
            [110.452924107620504, -7.007889365485771],
            [110.452660321655614, -7.00784370667478],
            [110.452417728720391, -7.007870351681894],
            [110.452253197303889, -7.007858822044255],
            [110.452020624810373, -7.007807530292602],
            [110.451759692554077, -7.007747701908463],
            [110.451581006154001, -7.007710645201477],
            [110.451464769691697, -7.007642482024541],
            [110.451328727475186, -7.007528944010145],
            [110.451194065686678, -7.00744800410088],
            [110.450938897266951, -7.00731165103659],
            [110.450615671548505, -7.007149707982817],
            [110.450332113848759, -7.007030328314784],
            [110.450163425354987, -7.006935175941904],
            [110.450035914514274, -7.006804640686938],
            [110.449820585556608, -7.006586133745945],
            [110.44953602937025, -7.006207608023293],
            [110.449978896677877, -7.005723946576735],
            [110.450608409350608, -7.005102421461666],
            [110.451039965584087, -7.004668535071527],
            [110.451204315681224, -7.004503297244326],
            [110.451572442628446, -7.004133181207446],
            [110.451610521182843, -7.004123047572086],
            [110.451978196055961, -7.004394826850664],
            [110.452477170833731, -7.004721048223248],
            [110.453100526123649, -7.005137869400061],
            [110.453328674765388, -7.005282863675853],
            [110.453465524289015, -7.005355387250215],
            [110.45415746405169, -7.005672785693689],
            [110.454956087245023, -7.005972215754019],
            [110.455724239924095, -7.006199244748571],
            [110.45624153605965, -7.00634457237642],
            [110.457578206519983, -7.006707939172276],
            [110.457724990998557, -7.006753189426575],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 62,
        id: 64,
        area: 1423555.6549730001,
        perimeter: 6329.975352,
        kode_pos: 50198,
        kode_kec: 3374080,
        kode_kel: 3374041009,
        kec: "Pedurungan",
        kel: "Kalicari",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.459631083028484, -6.99292485780625],
            [110.459598151492358, -6.993042407134294],
            [110.459570280367402, -6.993169013190544],
            [110.459525758883998, -6.99325941784093],
            [110.459381064973527, -6.993367798657413],
            [110.459219668655223, -6.99348520581909],
            [110.459180564063644, -6.99353943435532],
            [110.459191566504785, -6.993566583709432],
            [110.45928061167622, -6.993630004966617],
            [110.459313053197462, -6.993711452373256],
            [110.459290944264964, -6.99374760928316],
            [110.459229793142114, -6.993783721362325],
            [110.459140623077019, -6.993828846827885],
            [110.459068134540203, -6.993883037009297],
            [110.45903491905726, -6.993982500157442],
            [110.45905116156608, -6.994127247980832],
            [110.459079045215731, -6.994235826884236],
            [110.459073303657021, -6.994308184871493],
            [110.459039826041476, -6.994389556569498],
            [110.458995377195947, -6.99441664222374],
            [110.458906217398734, -6.99445272207223],
            [110.458822977973028, -6.99450689985146],
            [110.45876718126847, -6.994561109157267],
            [110.458733682749624, -6.994660571947565],
            [110.45870010088592, -6.99483239921465],
            [110.458666612746512, -6.994922816436477],
            [110.458666321065337, -6.99517609211472],
            [110.458643594087874, -6.995257476131508],
            [110.458496804306208, -6.995465355482246],
            [110.458389025458729, -6.995700416367624],
            [110.458352048379993, -6.995872239681367],
            [110.458345678290883, -6.995998870348251],
            [110.458382384361528, -6.996062231575233],
            [110.45840683124888, -6.996206988837179],
            [110.45843127814797, -6.996351746098131],
            [110.458498832515005, -6.996406097233476],
            [110.458566093560719, -6.996469493594062],
            [110.458676640776304, -6.996532939722488],
            [110.458713357348344, -6.996587255369456],
            [110.458725428973096, -6.996668679392082],
            [110.458694665649162, -6.996849555445315],
            [110.458719185589601, -6.996930993781797],
            [110.4587854417194, -6.997130072531887],
            [110.458911644952764, -6.997356356903471],
            [110.458981862045476, -6.997555440186384],
            [110.458953393315824, -6.997709182176397],
            [110.458953299574304, -6.99779059221039],
            [110.459122117602618, -6.997863150809101],
            [110.459248581434395, -6.997863296118763],
            [110.45948728001747, -6.997935934872183],
            [110.45974006212488, -6.998062863110151],
            [110.459838454845084, -6.998117249477194],
            [110.459880495099682, -6.998216799021308],
            [110.459810071658012, -6.998442857506303],
            [110.459711221171219, -6.998786475775626],
            [110.459753126219411, -6.99900361761178],
            [110.459907601344099, -6.999248025372244],
            [110.460159654348658, -6.999763912214745],
            [110.460191309984424, -6.999791085247824],
            [110.460232333102937, -6.999791132307498],
            [110.460260918220655, -6.999782119524372],
            [110.460276782418347, -6.999764046576119],
            [110.460302014088171, -6.99971884765381],
            [110.460330337066623, -6.999691743420213],
            [110.460374786367254, -6.999664657678307],
            [110.46045375165609, -6.999637611509472],
            [110.460526461576777, -6.99963769487387],
            [110.460788674634685, -6.999683223282988],
            [110.460902397299009, -6.999692399158224],
            [110.460975127992967, -6.999674391330709],
            [110.461057194976703, -6.99965639418476],
            [110.461189904279223, -6.999638455014564],
            [110.461237424130942, -6.999647554998528],
            [110.461322678658334, -6.999810472934686],
            [110.461357028707084, -6.999955241440905],
            [110.4613255521719, -7.000018524424643],
            [110.461274816435349, -7.000099876502859],
            [110.46125272805844, -7.000117942359966],
            [110.461252686558325, -7.00015412460741],
            [110.46125263468285, -7.000199352416636],
            [110.461315842410045, -7.000344153965939],
            [110.461334631963823, -7.000488904657617],
            [110.461331436867212, -7.000561265589269],
            [110.461305901572686, -7.000624555368876],
            [110.461245850195667, -7.000687805623444],
            [110.46122379291765, -7.000678734791731],
            [110.461195290773318, -7.000615383137639],
            [110.461144972773724, -7.00057914321754],
            [110.461103666661629, -7.000579095908119],
            [110.461021599515092, -7.000597093049281],
            [110.460961620776018, -7.000597024336316],
            [110.460898215866905, -7.000624088409078],
            [110.460857130370613, -7.000678314768322],
            [110.460803282371387, -7.000759663215492],
            [110.460790730100641, -7.000850104557971],
            [110.460812652375779, -7.00097676770164],
            [110.460821916003766, -7.001040097327736],
            [110.460767701946395, -7.00119380992767],
            [110.460720381776937, -7.001257074695084],
            [110.460682429084798, -7.001293213478133],
            [110.460653791937304, -7.001347454082422],
            [110.460621990727759, -7.001446918918318],
            [110.460609396835778, -7.001573542490678],
            [110.460592600706249, -7.00166397895427],
            [110.460541436252655, -7.001871968442225],
            [110.460490092753304, -7.00198950199491],
            [110.460407041457927, -7.002125090311828],
            [110.460330255926664, -7.002224503512011],
            [110.460247225335962, -7.002342000673632],
            [110.460170429334525, -7.002450459400865],
            [110.460080766692059, -7.002676495767528],
            [110.459912192089121, -7.003128580768969],
            [110.459848410197296, -7.003237054368935],
            [110.459765044331121, -7.003399778884494],
            [110.459662733154275, -7.00355343604334],
            [110.459579733382611, -7.003643796375705],
            [110.459484064647512, -7.003679868711123],
            [110.459349908309719, -7.003724942351248],
            [110.459196440420058, -7.003833312729688],
            [110.45913890335612, -7.003923702253396],
            [110.459106735318571, -7.004095531069821],
            [110.459081178658877, -7.00417691179565],
            [110.458895929598683, -7.004366655618396],
            [110.458793680490473, -7.004466039204864],
            [110.458710439190014, -7.004520216804019],
            [110.458614832812543, -7.004502015605078],
            [110.45855736858536, -7.004529086147305],
            [110.458346049792354, -7.004999212304345],
            [110.458268289289521, -7.005207170774111],
            [110.458274618995631, -7.005360952713545],
            [110.458267976079213, -7.005478537435842],
            [110.458152744489027, -7.005794999502026],
            [110.458018190478228, -7.006183803782895],
            [110.457890279271524, -7.006455023298892],
            [110.457724990998557, -7.006753189426575],
            [110.457578206519983, -7.006707939172276],
            [110.45624153605965, -7.00634457237642],
            [110.455724239924095, -7.006199244748571],
            [110.454956087245023, -7.005972215754019],
            [110.45415746405169, -7.005672785693689],
            [110.453465524289015, -7.005355387250215],
            [110.453328674765388, -7.005282863675853],
            [110.453100526123649, -7.005137869400061],
            [110.452477170833731, -7.004721048223248],
            [110.451978196055961, -7.004394826850664],
            [110.451610521182843, -7.004123047572086],
            [110.452175293000195, -7.003527071478262],
            [110.452431054336841, -7.003269926600252],
            [110.452640938905503, -7.003023360898506],
            [110.452828375185533, -7.002769289877784],
            [110.453045853804298, -7.002429244140697],
            [110.453207133614313, -7.002137747051539],
            [110.453357202257351, -7.001831278675179],
            [110.453443547559772, -7.001607006222281],
            [110.45350091765259, -7.001446810212792],
            [110.453545362963482, -7.001315800545122],
            [110.453589843892871, -7.001154153205766],
            [110.45363518673922, -7.000956547733828],
            [110.453689159702037, -7.00071683017219],
            [110.453725531115069, -7.000511646586093],
            [110.453752658853446, -7.000315209508163],
            [110.453774722148481, -7.000051739440156],
            [110.453792305534307, -6.999732527378485],
            [110.453779367541244, -6.999413369555305],
            [110.453759693838322, -6.999164016400036],
            [110.45372827745669, -6.99891113958142],
            [110.453675368381582, -6.998528046038764],
            [110.453568901526495, -6.997752365700918],
            [110.453555839501846, -6.997657200220387],
            [110.453520170710092, -6.997417402486353],
            [110.453404861249751, -6.996642187207706],
            [110.45275395181055, -6.992064329936925],
            [110.452509470620726, -6.990319677987417],
            [110.452495491087689, -6.990219917797195],
            [110.452447903469988, -6.989900704936116],
            [110.452445692970301, -6.989885877123015],
            [110.452388530908578, -6.989499408200009],
            [110.452222743785512, -6.988382622818947],
            [110.451950081139458, -6.986473988847566],
            [110.451721754629403, -6.984790915773591],
            [110.452791566011982, -6.985098716276779],
            [110.454443062120447, -6.985573870600761],
            [110.4546255176176, -6.985592172824461],
            [110.454737549682847, -6.985592302400771],
            [110.455862779309228, -6.987954496385519],
            [110.456345490594416, -6.988877701663809],
            [110.456456727739564, -6.989076832535932],
            [110.457303099102973, -6.990145185473977],
            [110.457886195712575, -6.990869502509169],
            [110.458770735491726, -6.991965033864857],
            [110.45920410032322, -6.992508266007139],
            [110.459631083028484, -6.99292485780625],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 63,
        id: 65,
        area: 2335381.7493070001,
        perimeter: 7471.172618,
        kode_pos: 50113,
        kode_kec: 3374080,
        kode_kel: 3374041006,
        kec: "Pedurungan",
        kel: "Tlogomulyo",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.484630064058749, -7.003979652337734],
            [110.484174044196394, -7.003933737483978],
            [110.483407768749089, -7.003788166703153],
            [110.483173549559496, -7.00375172694905],
            [110.482913897371887, -7.003688122308344],
            [110.48263529949925, -7.003615451084918],
            [110.48225913376605, -7.003506489893697],
            [110.482113459228415, -7.003479192696746],
            [110.482037646303993, -7.003470063605644],
            [110.481961500556849, -7.003506162145526],
            [110.481917012039801, -7.003569432352275],
            [110.48186625930785, -7.003668878059304],
            [110.481752241989327, -7.003668752429698],
            [110.481562158778615, -7.003632360520491],
            [110.481403995752402, -7.003641231741359],
            [110.481316816001964, -7.003677318003616],
            [110.481291313101721, -7.003713472279517],
            [110.481244794050724, -7.003821968178539],
            [110.481200891244541, -7.0038671477522],
            [110.481162403926632, -7.00387615089319],
            [110.481082630067846, -7.003867017275037],
            [110.480794463146054, -7.003749106414736],
            [110.480833333410573, -7.00364964772367],
            [110.480913094339485, -7.003414550155122],
            [110.480900412892666, -7.003369308155905],
            [110.480884892235096, -7.003333108623115],
            [110.480820689141808, -7.003314946553122],
            [110.48026317178477, -7.003205783586933],
            [110.479789975198827, -7.003087667649787],
            [110.479468374320746, -7.003014947044178],
            [110.479150178642101, -7.002933184398358],
            [110.47903963689339, -7.0028606971884],
            [110.478535470461267, -7.002606861770412],
            [110.47829882672896, -7.002461869802854],
            [110.478513054538595, -7.001901280308672],
            [110.47867565984545, -7.001458226239347],
            [110.478915742748711, -7.000798163535477],
            [110.479130261886709, -7.00022852833548],
            [110.479386257039579, -6.999505163679443],
            [110.479851373041157, -6.998230248330062],
            [110.479242005555236, -6.99794011527634],
            [110.478512176197412, -6.997595574441698],
            [110.478311688064949, -6.99750489628437],
            [110.477897364677773, -6.997368752918051],
            [110.477468865513472, -6.997259730261767],
            [110.477298862386263, -6.997232404720697],
            [110.477046813272253, -6.997204987976302],
            [110.476691832686555, -6.997132228694806],
            [110.476359798758523, -6.997032357890393],
            [110.47598793420056, -6.996878168948599],
            [110.475595912145991, -6.996787276444199],
            [110.475266899930816, -6.996768818624219],
            [110.474863147155219, -6.996795505178786],
            [110.474349713653226, -6.996740658578676],
            [110.473511300101038, -6.996604037748481],
            [110.472961896076797, -6.996585331798675],
            [110.472973185227417, -6.99643156937448],
            [110.472986794950742, -6.996078806520578],
            [110.473006166192462, -6.995707958924014],
            [110.473022202193022, -6.995536110618717],
            [110.473065590780323, -6.99519242666482],
            [110.473094247391785, -6.994866817397948],
            [110.473120691314122, -6.994495977681717],
            [110.473072092626154, -6.993935096540434],
            [110.473055361042739, -6.993717983585566],
            [110.472983846356897, -6.993157076789229],
            [110.473027245691497, -6.992551070592038],
            [110.473077425525531, -6.991700840917531],
            [110.473097523387978, -6.991438541171802],
            [110.473085270225383, -6.990506831265125],
            [110.473135255433064, -6.990335020867665],
            [110.473477116719408, -6.989738393736623],
            [110.473583098622129, -6.98933146035201],
            [110.473616037911398, -6.989222950000064],
            [110.474123027645135, -6.98820136363145],
            [110.474218702451964, -6.987902965674969],
            [110.474341081956183, -6.986998542445081],
            [110.474493408847835, -6.985877058142482],
            [110.474546454286198, -6.985497202137776],
            [110.474422014119085, -6.985207604295924],
            [110.474258219330309, -6.984945099345812],
            [110.47422217862966, -6.984791283991877],
            [110.474170092931189, -6.984565085959007],
            [110.474150380197813, -6.984483653593517],
            [110.474117623322911, -6.984429343475371],
            [110.473688591495034, -6.984048949727664],
            [110.473483694752417, -6.983858763463712],
            [110.473388758708765, -6.983750110294896],
            [110.473365903784824, -6.983695811189744],
            [110.473352950679072, -6.983641523141122],
            [110.473359518291247, -6.983587256890814],
            [110.473375977595225, -6.983542047283023],
            [110.474021540690075, -6.982772441692974],
            [110.474040192140507, -6.982787384659173],
            [110.47495629072823, -6.983571944764776],
            [110.47496115806554, -6.983576113211691],
            [110.477369170023337, -6.985730499762135],
            [110.478455121857436, -6.986801337855097],
            [110.479841180286243, -6.988054813384058],
            [110.481301571350002, -6.989356958576931],
            [110.483173510657764, -6.99102827477935],
            [110.484648189584959, -6.992361867984657],
            [110.485739899003534, -6.993363473752422],
            [110.486954547592802, -6.994436669291174],
            [110.487871953398368, -6.99526086477691],
            [110.489200924520446, -6.996442795754064],
            [110.489901136588912, -6.997066669423945],
            [110.490541314500291, -6.997653318990209],
            [110.491032136094475, -6.998143519382418],
            [110.490935828082627, -6.998242367996951],
            [110.490828190233799, -6.998622167437827],
            [110.490762307965298, -6.998848236552282],
            [110.490617841279814, -6.999273224137756],
            [110.490544612707396, -6.999490239634213],
            [110.490431443364884, -6.999752439937158],
            [110.490263562432958, -7.000114082696041],
            [110.490187484707903, -7.000349186250557],
            [110.490152801575931, -7.000502924114209],
            [110.490055620677921, -7.000891780206248],
            [110.489979670398, -7.001009290860083],
            [110.489895535042294, -7.001108701396041],
            [110.489652211700516, -7.001379805909076],
            [110.489431541385386, -7.001632843633788],
            [110.48912504746778, -7.001976244161376],
            [110.489015146279712, -7.00209371775136],
            [110.488639530381448, -7.00251845332187],
            [110.488430195531862, -7.002753411588793],
            [110.488364752040809, -7.002834750905549],
            [110.488296449739835, -7.002943223933831],
            [110.488238898415617, -7.00305170864749],
            [110.488141639456757, -7.003250606245337],
            [110.488023717274785, -7.003458526932343],
            [110.487863853505218, -7.003729721179316],
            [110.487748477365813, -7.003937644548365],
            [110.487567637538618, -7.004244998254082],
            [110.487413461291283, -7.004489061671483],
            [110.487358495369676, -7.004561366624486],
            [110.487307832458924, -7.004579478592819],
            [110.487107944930713, -7.004452546107961],
            [110.486814449801628, -7.004289404951867],
            [110.48639792345837, -7.004089946841153],
            [110.486189821688754, -7.003972126617465],
            [110.486163227042923, -7.003972097559989],
            [110.485960401855806, -7.003944739080568],
            [110.485538381764286, -7.003853821529912],
            [110.485522716582551, -7.00369098347115],
            [110.484799727927864, -7.003545462306358],
            [110.484671077980877, -7.003988556030205],
            [110.484630064058749, -7.003979652337734],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 64,
        id: 66,
        area: 1137426.8854139999,
        perimeter: 4848.048832,
        kode_pos: 50192,
        kode_kec: 3374080,
        kode_kel: 3374041005,
        kec: "Pedurungan",
        kel: "Pedurungan Lor",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.475002635998464, -7.011711724019653],
            [110.475017328993928, -7.011223396344883],
            [110.475009690607052, -7.010970111309796],
            [110.475029344096086, -7.010852540609397],
            [110.475061112739738, -7.010780211390611],
            [110.475132450425946, -7.010744108733016],
            [110.475203808345299, -7.010689914906952],
            [110.475283108396951, -7.010617638758994],
            [110.475358447470938, -7.010545358172682],
            [110.475370370804285, -7.010509189126611],
            [110.475467353787906, -7.010310294424636],
            [110.475623468236208, -7.009858189154219],
            [110.475717561213884, -7.009460288153711],
            [110.475753341262575, -7.009089458840995],
            [110.475923095892895, -7.009089648183445],
            [110.476174858200267, -7.009126111250108],
            [110.476391820848804, -7.009162535411412],
            [110.476602589477366, -7.009171815799493],
            [110.476708130395465, -7.009162887744104],
            [110.477071476006969, -7.00909997305642],
            [110.47744069242033, -7.009100383762918],
            [110.478005915130979, -7.009155285505062],
            [110.478232537677187, -7.00915553717962],
            [110.478275572387062, -7.009128448175435],
            [110.478290940850144, -7.009047054885528],
            [110.478342160238199, -7.00903806614986],
            [110.478674849262347, -7.009065572119979],
            [110.479247963948779, -7.009147617918445],
            [110.479355475287619, -7.009147737043959],
            [110.47939110370703, -7.009165867708133],
            [110.479416759499586, -7.009247306495603],
            [110.479426904633669, -7.009283500118957],
            [110.479452620664702, -7.009310665394611],
            [110.479508922676487, -7.009310727758752],
            [110.479631722024735, -7.009301818160262],
            [110.479908149475989, -7.009293078583795],
            [110.479994997329385, -7.009302220292408],
            [110.480706382758186, -7.009456782120687],
            [110.480701920964222, -7.008886904516276],
            [110.480720586233147, -7.008127094886072],
            [110.480772356058964, -7.007620598560181],
            [110.480844992011143, -7.007177444448076],
            [110.480884605834078, -7.006661889048266],
            [110.480932444300208, -7.006128251509523],
            [110.481127292317097, -7.00569427780716],
            [110.481240458368703, -7.005441125881297],
            [110.481270205212439, -7.005404976300064],
            [110.481314371097085, -7.005377888223609],
            [110.481843778834374, -7.005324198342182],
            [110.481930140052313, -7.005260974298455],
            [110.482134972357997, -7.005007923102311],
            [110.482247278947625, -7.004763815530746],
            [110.482433055351933, -7.004601199211283],
            [110.482592723052733, -7.004510918900714],
            [110.482674870099828, -7.00442055325538],
            [110.482730918534557, -7.004393478104217],
            [110.482839014618449, -7.004375505791704],
            [110.482972543774025, -7.004384698226514],
            [110.48307184935102, -7.004384807400831],
            [110.483296216357857, -7.004376008384225],
            [110.484363551528403, -7.004494772817523],
            [110.484630064058749, -7.003979652337734],
            [110.484671077980877, -7.003988556030205],
            [110.484799727927864, -7.003545462306358],
            [110.485522716582551, -7.00369098347115],
            [110.485538381764286, -7.003853821529912],
            [110.485960401855806, -7.003944739080568],
            [110.486163227042923, -7.003972097559989],
            [110.486189821688754, -7.003972126617465],
            [110.48639792345837, -7.004089946841153],
            [110.486814449801628, -7.004289404951867],
            [110.487107944930713, -7.004452546107961],
            [110.487307832458924, -7.004579478592819],
            [110.487474460332876, -7.004850952524389],
            [110.487540019314181, -7.004923388850782],
            [110.487723175331055, -7.005086409395951],
            [110.488244128124052, -7.005521165955508],
            [110.488335666912633, -7.005638858510966],
            [110.488355129424477, -7.005693153350695],
            [110.488364933117026, -7.005783620118664],
            [110.488364864090016, -7.005846939312884],
            [110.488305338687212, -7.006208698921013],
            [110.488232982972107, -7.006660900636454],
            [110.488193400101522, -7.006896043385058],
            [110.488193350772136, -7.006941271377596],
            [110.488206306065578, -7.006995559138231],
            [110.488232851565002, -7.007040816085226],
            [110.488373346296285, -7.007149516334078],
            [110.488429013248705, -7.007212896186021],
            [110.488445363661313, -7.007267187636887],
            [110.488451782156886, -7.007348605107092],
            [110.488441771340916, -7.00744809591589],
            [110.488316331076874, -7.008063060799024],
            [110.488165006114869, -7.008804635938316],
            [110.488075957106858, -7.009256819359547],
            [110.488036442952577, -7.00942864286636],
            [110.487934273915641, -7.009717990996108],
            [110.48782586062606, -7.010025423499921],
            [110.487549862545592, -7.010676406283058],
            [110.487510377698214, -7.010821092924545],
            [110.48741810659746, -7.011372774266788],
            [110.487362316839011, -7.011680264007408],
            [110.487368715372853, -7.011779772658898],
            [110.487388158196154, -7.011852158725889],
            [110.487370567304879, -7.011897367560961],
            [110.487264634067429, -7.012005799219277],
            [110.487110310418203, -7.012123223622279],
            [110.486995556856925, -7.012276873607915],
            [110.486822977229863, -7.012529962044042],
            [110.486734575293923, -7.012647458319252],
            [110.486611040830283, -7.012810144198799],
            [110.486567078165208, -7.012909597796305],
            [110.486502457406814, -7.013271351334851],
            [110.486400361254326, -7.013750656724546],
            [110.486380427478863, -7.013868227777646],
            [110.486358279825311, -7.013940568387016],
            [110.486287662290877, -7.014094266410828],
            [110.486214711881871, -7.014311281093317],
            [110.48613269778221, -7.014537331442723],
            [110.486009033626118, -7.014817609800568],
            [110.485938366223522, -7.015016535709195],
            [110.485854068571768, -7.015260674690379],
            [110.485737164544645, -7.015568097152847],
            [110.48564407601971, -7.015830317651512],
            [110.485599859683319, -7.01590263402616],
            [110.485560090759364, -7.016047320085913],
            [110.485505569885888, -7.016228067247153],
            [110.485450994245298, -7.016200975762311],
            [110.482183904169744, -7.014759133567832],
            [110.477970261430031, -7.012972486334656],
            [110.476571551812512, -7.012392011834274],
            [110.475002635998464, -7.011711724019653],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 65,
        id: 67,
        area: 1504859.421116,
        perimeter: 8747.800785,
        kode_pos: 50192,
        kode_kec: 3374080,
        kode_kel: 3374041002,
        kec: "Pedurungan",
        kel: "Pedurungan Kidul",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.475002635998464, -7.011711724019653],
            [110.476571551812512, -7.012392011834274],
            [110.477970261430031, -7.012972486334656],
            [110.482183904169744, -7.014759133567832],
            [110.485450994245298, -7.016200975762311],
            [110.485505569885888, -7.016228067247153],
            [110.48556071104538, -7.016255474603272],
            [110.485465532615095, -7.016617089382217],
            [110.485408321283856, -7.016671300282225],
            [110.485313530003538, -7.016680241965551],
            [110.485199529334949, -7.016662025760067],
            [110.485003235999258, -7.016607536851614],
            [110.484845068559522, -7.016616408910384],
            [110.484534808862819, -7.016769843544494],
            [110.484395518015063, -7.016851100976225],
            [110.484300413898751, -7.016887178907488],
            [110.484186716081922, -7.016850871588247],
            [110.484040987499654, -7.016868802636508],
            [110.483971376894246, -7.016877771720076],
            [110.483914468224285, -7.016913891556372],
            [110.483838270519399, -7.016995218172777],
            [110.483768540304823, -7.017112734284225],
            [110.483686018414033, -7.017284509906763],
            [110.483603576214492, -7.017383920805699],
            [110.483451523277623, -7.017492300686019],
            [110.483337695727329, -7.017573585793806],
            [110.483255173590564, -7.017745361319764],
            [110.483159899580428, -7.017935213980039],
            [110.482726843395824, -7.018604111116814],
            [110.482390798158889, -7.019046974846606],
            [110.482238604425291, -7.019281992460243],
            [110.482054692259439, -7.019544111790306],
            [110.481794954341268, -7.01980614729738],
            [110.481636675715606, -7.019914519616112],
            [110.481446931562388, -7.020077130672251],
            [110.481257703047604, -7.020284970183815],
            [110.480917168373949, -7.020691645299986],
            [110.480697615523127, -7.020944678960895],
            [110.480328250923094, -7.021324184875912],
            [110.480235591606501, -7.021450720523331],
            [110.480094663043346, -7.021730977726246],
            [110.480015544147406, -7.021893710693124],
            [110.479850283112555, -7.022173940824441],
            [110.479674786034408, -7.022499387482656],
            [110.479640743636253, -7.022580760040923],
            [110.479596920640788, -7.02280685120466],
            [110.479572246538496, -7.023114373927137],
            [110.479508569164707, -7.023638947538315],
            [110.479439839037923, -7.024127333147985],
            [110.479434777672338, -7.024353467291503],
            [110.479449602146062, -7.024507258785128],
            [110.479502835229511, -7.024724412045966],
            [110.479623690940883, -7.025194916885848],
            [110.479749679907798, -7.025629245037356],
            [110.479933439502162, -7.026271685832598],
            [110.480098869048803, -7.026859832703431],
            [110.480248817888224, -7.027375597642737],
            [110.480428041669299, -7.028027078856449],
            [110.480636631018868, -7.028732866106472],
            [110.480888582384082, -7.02962865872611],
            [110.481189262027129, -7.030723508196741],
            [110.481477048112737, -7.031709796168228],
            [110.481766109589728, -7.032822723687234],
            [110.482113953561054, -7.03399903521717],
            [110.482377813440479, -7.034876749234963],
            [110.482666948608127, -7.035926357292303],
            [110.48297872023997, -7.036975990220008],
            [110.483182516519108, -7.03767272574953],
            [110.483362576185741, -7.038342298240077],
            [110.483626390317056, -7.039265239640221],
            [110.483746151164979, -7.03970860571757],
            [110.483830378280004, -7.039790108950683],
            [110.483962725784323, -7.039853574059601],
            [110.484191294060736, -7.039899054029371],
            [110.4842048094944, -7.03990332991855],
            [110.484248957432811, -7.039917297149533],
            [110.484242131816984, -7.039946943226173],
            [110.484236485086569, -7.039971468572444],
            [110.484135923444512, -7.04033318135525],
            [110.484034972413909, -7.040658711307881],
            [110.483546735421683, -7.04066204285921],
            [110.482930294498956, -7.040626761284114],
            [110.482467655686023, -7.040565906119601],
            [110.482219494963402, -7.040481482613623],
            [110.481807471603574, -7.040250724111696],
            [110.481399901442799, -7.040002254644441],
            [110.481205066366726, -7.039802738561479],
            [110.481139659208154, -7.039620195723836],
            [110.48114692928641, -7.039460763701954],
            [110.481210899678672, -7.039340368784156],
            [110.481388380848827, -7.039198840985101],
            [110.481466470170162, -7.03913869459472],
            [110.481528751751938, -7.038942120816638],
            [110.48163182753153, -7.038736734428218],
            [110.48182718218824, -7.038467674144637],
            [110.481960432170837, -7.038235747716887],
            [110.482099027246818, -7.037980796914753],
            [110.482141730162809, -7.037849748955762],
            [110.482127666057707, -7.037739896868607],
            [110.482066803563939, -7.037616529167281],
            [110.481925099042812, -7.03747748228948],
            [110.48165575659533, -7.037307114650411],
            [110.481366538445968, -7.037150897302144],
            [110.481220548630901, -7.037038773147303],
            [110.481078816210527, -7.036925236425571],
            [110.481019355360743, -7.036817459863606],
            [110.481011001284074, -7.036675726069823],
            [110.481076460685628, -7.036491556741349],
            [110.481239920140098, -7.036201202597069],
            [110.481291133788673, -7.036070872764474],
            [110.481291253350548, -7.035963162244626],
            [110.481257326993557, -7.035855414003126],
            [110.481193590025896, -7.035766056917898],
            [110.48104622457906, -7.035615665586313],
            [110.480921537594497, -7.035482306301634],
            [110.480771369809148, -7.03530073238999],
            [110.480696293833631, -7.035202859200822],
            [110.480642539074097, -7.035066744026931],
            [110.480631376299556, -7.034899496694869],
            [110.480658497766385, -7.034747881523254],
            [110.480664383091835, -7.034557977171056],
            [110.480650515436196, -7.034271678223963],
            [110.480591247115697, -7.03399099790322],
            [110.480500792295061, -7.033690441548056],
            [110.480186950133415, -7.032717862996551],
            [110.48007379235365, -7.032422950365852],
            [110.479947929069013, -7.03207275103966],
            [110.479850503512367, -7.031663058959387],
            [110.479760248189578, -7.031183929802999],
            [110.479705228476291, -7.03091034034507],
            [110.47962600649744, -7.03071467249814],
            [110.479525532944407, -7.030494887862711],
            [110.479502990512259, -7.030355972774699],
            [110.479506098931125, -7.030112210022351],
            [110.479514809032182, -7.029933646778027],
            [110.479490770772145, -7.029864175051473],
            [110.479368960658817, -7.029696804770501],
            [110.479241520571591, -7.029489745336127],
            [110.479111281352402, -7.02924866887364],
            [110.479039163702382, -7.029043088131229],
            [110.479011000889841, -7.028855980436659],
            [110.478985776233372, -7.028578172303788],
            [110.4789592987796, -7.028151552010776],
            [110.478945460484951, -7.027839742640468],
            [110.478913261491883, -7.027455633339587],
            [110.478885155604601, -7.027217504863557],
            [110.47882445618275, -7.026949577994984],
            [110.478728291453976, -7.026683028931079],
            [110.478607956193969, -7.026466056560374],
            [110.478457807794882, -7.026270309680211],
            [110.478260874246374, -7.026050417525006],
            [110.478144783936386, -7.025843370498433],
            [110.478071259652467, -7.025627867363399],
            [110.478017588771394, -7.025418055304088],
            [110.477976645333896, -7.025243688544037],
            [110.477917214972123, -7.025110401312312],
            [110.477680803259489, -7.02467504371283],
            [110.477482605634179, -7.024317677090531],
            [110.477400418690891, -7.024238219770695],
            [110.47728131799164, -7.024187066198403],
            [110.477126764817115, -7.024125952353406],
            [110.477024961244837, -7.024057958637657],
            [110.476919955225952, -7.023859279623395],
            [110.47674452486774, -7.023439579348917],
            [110.476610215029183, -7.023035514610009],
            [110.47647588955212, -7.022645622276873],
            [110.476338771584196, -7.022216043933194],
            [110.476285006661499, -7.022091266335056],
            [110.476231168840144, -7.022031681927771],
            [110.476160270539921, -7.022006092347131],
            [110.476066666147233, -7.021988980866871],
            [110.475950365177638, -7.021971844013279],
            [110.475828409111188, -7.021937693871612],
            [110.475781633902557, -7.021905044965749],
            [110.475690955489995, -7.021808570960371],
            [110.475552081822073, -7.021680863671144],
            [110.475416042125332, -7.021555994004683],
            [110.475365060602485, -7.021479405763294],
            [110.475353769486048, -7.021428372316301],
            [110.475360946314836, -7.021353266357669],
            [110.475392316021342, -7.021208742446978],
            [110.475389653598043, -7.021052842517806],
            [110.475338783273585, -7.020877047249289],
            [110.475234021449978, -7.020689853742791],
            [110.475164600710826, -7.020611827616771],
            [110.475034171997919, -7.020543653939647],
            [110.474648550685629, -7.02034764251841],
            [110.474197677303295, -7.020151557684621],
            [110.473918381184944, -7.020013771878229],
            [110.473736927164467, -7.019908692290398],
            [110.473657575497811, -7.019832072072185],
            [110.47355565482053, -7.01964204694645],
            [110.473433928993899, -7.019403813260269],
            [110.473347572459332, -7.019249236680507],
            [110.473239910977341, -7.019118729333241],
            [110.473103856699296, -7.019008031512482],
            [110.472891189294316, -7.018908585567772],
            [110.472533876992571, -7.018769294135491],
            [110.472331099603238, -7.018705290180472],
            [110.472138256745325, -7.018637045582465],
            [110.471999305133181, -7.018580199497783],
            [110.471905752753941, -7.018517735483898],
            [110.471789561527359, -7.018404225176399],
            [110.471632257124568, -7.018269409895619],
            [110.471473065755816, -7.018152561433275],
            [110.471562584430515, -7.017998991849928],
            [110.471707378395124, -7.017750597053672],
            [110.471847311250912, -7.017352748910795],
            [110.471978200313487, -7.016945844946572],
            [110.472118153033563, -7.016529905530988],
            [110.472155693213381, -7.016358081661981],
            [110.472174266963478, -7.016195282046424],
            [110.472137396917717, -7.016023374509533],
            [110.472054350833034, -7.01565241221502],
            [110.472017157170598, -7.015516686640626],
            [110.472008003970217, -7.015353855847072],
            [110.472018084432321, -7.014693539585158],
            [110.471990461796622, -7.01434977634566],
            [110.472000042930532, -7.014132693107416],
            [110.472000450463852, -7.013770870209179],
            [110.472075267728286, -7.013409130953404],
            [110.47212306424386, -7.013173999485088],
            [110.472212947665568, -7.012748958023994],
            [110.472263563102743, -7.012522875277081],
            [110.472373694763107, -7.012206403524342],
            [110.472458867720846, -7.011944177214185],
            [110.472718374991871, -7.011076442946077],
            [110.473952790357572, -7.011375980540487],
            [110.47482306908006, -7.011630230724572],
            [110.475002635998464, -7.011711724019653],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 66,
        id: 68,
        area: 2717247.4696069998,
        perimeter: 7257.650489,
        kode_pos: 50194,
        kode_kec: 3374080,
        kode_kel: 3374041004,
        kec: "Pedurungan",
        kel: "Penggaron kidul",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.485505569885888, -7.016228067247153],
            [110.485560090759364, -7.016047320085913],
            [110.485599859683319, -7.01590263402616],
            [110.48564407601971, -7.015830317651512],
            [110.485737164544645, -7.015568097152847],
            [110.485854068571768, -7.015260674690379],
            [110.485938366223522, -7.015016535709195],
            [110.486009033626118, -7.014817609800568],
            [110.48613269778221, -7.014537331442723],
            [110.486214711881871, -7.014311281093317],
            [110.486287662290877, -7.014094266410828],
            [110.486358279825311, -7.013940568387016],
            [110.486380427478863, -7.013868227777646],
            [110.486400361254326, -7.013750656724546],
            [110.486502457406814, -7.013271351334851],
            [110.486567078165208, -7.012909597796305],
            [110.486611040830283, -7.012810144198799],
            [110.486734575293923, -7.012647458319252],
            [110.486822977229863, -7.012529962044042],
            [110.486995556856925, -7.012276873607915],
            [110.487110310418203, -7.012123223622279],
            [110.487264634067429, -7.012005799219277],
            [110.487370567304879, -7.011897367560961],
            [110.487388158196154, -7.011852158725889],
            [110.487368715372853, -7.011779772658898],
            [110.487362316839011, -7.011680264007408],
            [110.48741810659746, -7.011372774266788],
            [110.487510377698214, -7.010821092924545],
            [110.487549862545592, -7.010676406283058],
            [110.48782586062606, -7.010025423499921],
            [110.487934273915641, -7.009717990996108],
            [110.488036442952577, -7.00942864286636],
            [110.488075957106858, -7.009256819359547],
            [110.488165006114869, -7.008804635938316],
            [110.488316331076874, -7.008063060799024],
            [110.488441771340916, -7.00744809591589],
            [110.488451782156886, -7.007348605107092],
            [110.488445363661313, -7.007267187636887],
            [110.488429013248705, -7.007212896186021],
            [110.488373346296285, -7.007149516334078],
            [110.488232851565002, -7.007040816085226],
            [110.488206306065578, -7.006995559138231],
            [110.488193350772136, -7.006941271377596],
            [110.488193400101522, -7.006896043385058],
            [110.488232982972107, -7.006660900636454],
            [110.488305338687212, -7.006208698921013],
            [110.488364864090016, -7.005846939312884],
            [110.488364933117026, -7.005783620118664],
            [110.488355129424477, -7.005693153350695],
            [110.488335666912633, -7.005638858510966],
            [110.488244128124052, -7.005521165955508],
            [110.487723175331055, -7.005086409395951],
            [110.487540019314181, -7.004923388850782],
            [110.487474460332876, -7.004850952524389],
            [110.487307832458924, -7.004579478592819],
            [110.487358495369676, -7.004561366624486],
            [110.487413461291283, -7.004489061671483],
            [110.487567637538618, -7.004244998254082],
            [110.487748477365813, -7.003937644548365],
            [110.487863853505218, -7.003729721179316],
            [110.488023717274785, -7.003458526932343],
            [110.488141639456757, -7.003250606245337],
            [110.488238898415617, -7.00305170864749],
            [110.488296449739835, -7.002943223933831],
            [110.488364752040809, -7.002834750905549],
            [110.488430195531862, -7.002753411588793],
            [110.488639530381448, -7.00251845332187],
            [110.489015146279712, -7.00209371775136],
            [110.48912504746778, -7.001976244161376],
            [110.489431541385386, -7.001632843633788],
            [110.489652211700516, -7.001379805909076],
            [110.489895535042294, -7.001108701396041],
            [110.489979670398, -7.001009290860083],
            [110.490055620677921, -7.000891780206248],
            [110.490152801575931, -7.000502924114209],
            [110.490187484707903, -7.000349186250557],
            [110.490263562432958, -7.000114082696041],
            [110.490431443364884, -6.999752439937158],
            [110.490544612707396, -6.999490239634213],
            [110.490617841279814, -6.999273224137756],
            [110.490762307965298, -6.998848236552282],
            [110.490828190233799, -6.998622167437827],
            [110.490935828082627, -6.998242367996951],
            [110.491032136094475, -6.998143519382418],
            [110.491048483839165, -6.998159846411323],
            [110.492742012210385, -6.999689844961036],
            [110.493833017405819, -7.000647307098177],
            [110.494793645793436, -7.001430516876601],
            [110.495771570864108, -7.002286579583892],
            [110.496480346167715, -7.002944753627692],
            [110.498125422583513, -7.004437206446919],
            [110.498054873730808, -7.004530843977425],
            [110.498050171990315, -7.004536718464993],
            [110.498026338778232, -7.004600012388762],
            [110.498031042059694, -7.004699519268379],
            [110.498120486774582, -7.004925755332467],
            [110.498186146695147, -7.005170057246961],
            [110.498195367106106, -7.005278614569774],
            [110.498181046870869, -7.005441420508794],
            [110.498152599802026, -7.005586120120365],
            [110.498113684548642, -7.005730808553603],
            [110.497853469561377, -7.006454180525888],
            [110.49769737737023, -7.006906294886318],
            [110.497631272072141, -7.007078091065116],
            [110.497612238936625, -7.007150315395663],
            [110.497609352442183, -7.007161268666385],
            [110.497716426655543, -7.007203353701301],
            [110.497799894539185, -7.007272394953246],
            [110.4977820281334, -7.00752148698414],
            [110.501948087604518, -7.009528604050445],
            [110.500435234451146, -7.013898260015286],
            [110.500432186029528, -7.013907064839742],
            [110.50026785712042, -7.014448610687303],
            [110.500289843930062, -7.014514746785399],
            [110.500364768256659, -7.014580939211819],
            [110.500386759783069, -7.014642667798037],
            [110.500284915590598, -7.014999568003736],
            [110.500282646420828, -7.015059067017601],
            [110.500595738251604, -7.015173995472754],
            [110.500507090896988, -7.015566169864859],
            [110.500480490550345, -7.015689551936648],
            [110.50047584418914, -7.015909922654739],
            [110.500506635237144, -7.015993698165774],
            [110.500541776702335, -7.016134775975316],
            [110.500546019068224, -7.016293450963768],
            [110.500567996591471, -7.016368402068954],
            [110.500656146275944, -7.016443423563202],
            [110.500841339111233, -7.016527363263715],
            [110.501255849173191, -7.016686474312396],
            [110.501284091413027, -7.016743972421172],
            [110.501381527134384, -7.016942340771178],
            [110.501355918076712, -7.016961223809469],
            [110.501231735178379, -7.017064392976998],
            [110.500921134654291, -7.01745660699976],
            [110.500810561330965, -7.017725072249848],
            [110.500734614103891, -7.017842066036552],
            [110.500541310398987, -7.018124216671167],
            [110.500345230173181, -7.01842426977087],
            [110.500201574435962, -7.018688567561843],
            [110.50011703532553, -7.018930985593931],
            [110.500085865472471, -7.018988672642264],
            [110.500062825358242, -7.019125885799109],
            [110.50000918710316, -7.019331685202181],
            [110.49997085193958, -7.019499379318679],
            [110.499902105802491, -7.019560300590198],
            [110.49984862209925, -7.019621238118109],
            [110.499825630722327, -7.019712705407128],
            [110.499787360592975, -7.019819405039204],
            [110.499749106731016, -7.019910856051623],
            [110.49972612346042, -7.019994699024912],
            [110.499733599963903, -7.020139569000727],
            [110.499734790213836, -7.020141219250606],
            [110.499713363877447, -7.020286920256037],
            [110.499711823603803, -7.020404511671344],
            [110.499602466774903, -7.020540079349209],
            [110.499554197084834, -7.020572030670302],
            [110.499549977826106, -7.020581583502355],
            [110.4994353788246, -7.020703450300665],
            [110.499267352897661, -7.020832884363082],
            [110.499129860021228, -7.020954726640894],
            [110.499003175373559, -7.021132848344807],
            [110.498998776549868, -7.021140095971986],
            [110.49826263564573, -7.020881424386089],
            [110.497878054194473, -7.020746286253565],
            [110.49692379014509, -7.020419621828538],
            [110.495938405037592, -7.020092922120049],
            [110.494871263936233, -7.019757086818123],
            [110.49346843165749, -7.019285202781714],
            [110.492807183107885, -7.019058348286958],
            [110.492199379799402, -7.018858687567125],
            [110.491339574059936, -7.018577342548092],
            [110.490506647861423, -7.018296025203891],
            [110.490165268766134, -7.018187107197496],
            [110.489746113531538, -7.018050967583757],
            [110.489262772826891, -7.017878575346279],
            [110.48880402769494, -7.017724300634875],
            [110.48873926629679, -7.017697093280467],
            [110.488429000563812, -7.017597253562596],
            [110.48556071104538, -7.016255474603272],
            [110.485505569885888, -7.016228067247153],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 67,
        id: 69,
        area: 2887131.6956449999,
        perimeter: 8590.70085,
        kode_pos: 50232,
        kode_kec: 3374040,
        kode_kel: 3374032004,
        kec: "Gajah Mungkur",
        kel: "Gajah Mungkur",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.401689715443695, -7.005346699426161],
            [110.401946241958214, -7.005410270375256],
            [110.402316172751952, -7.005510241073027],
            [110.402512697579454, -7.005591900193931],
            [110.402799133100274, -7.005718900864057],
            [110.402972458766897, -7.005800530337281],
            [110.403045711740745, -7.005818714318579],
            [110.40318233855217, -7.005836978741859],
            [110.403342493645624, -7.00581909105412],
            [110.403539145267715, -7.005801249614067],
            [110.403732673281965, -7.005792449612708],
            [110.404028289072698, -7.00581996088759],
            [110.404068440588688, -7.005838102751557],
            [110.404101507649187, -7.005865281117294],
            [110.404271346750775, -7.006019269591396],
            [110.404311498294319, -7.006037411436686],
            [110.404528088056239, -7.006137186153668],
            [110.404641198337856, -7.006182556833125],
            [110.40475800953898, -7.0062098412026],
            [110.404881327898039, -7.006237133781515],
            [110.405317837883942, -7.006264822609492],
            [110.405558059254929, -7.0062470354464],
            [110.405678017022495, -7.0062471871116],
            [110.405771368927631, -7.006256350604076],
            [110.405986104662844, -7.006256621980379],
            [110.406352882494943, -7.006166630398238],
            [110.406536305682408, -7.006094498074311],
            [110.406589857477101, -7.006031247254498],
            [110.406586508196639, -7.005995061074115],
            [110.406553203801664, -7.005931700618305],
            [110.406519865099682, -7.00589547658188],
            [110.406376788126749, -7.005831977532614],
            [110.406280121355678, -7.005759491565442],
            [110.406140248133397, -7.005623632594598],
            [110.406060330890114, -7.005505940306223],
            [110.405937138563715, -7.005379147843901],
            [110.40589393214195, -7.005315774839539],
            [110.405867417933322, -7.005252422924838],
            [110.405811143373199, -7.005008123654704],
            [110.405827961519677, -7.004908644545933],
            [110.405904895742083, -7.004700695542265],
            [110.406015191348288, -7.00451087963646],
            [110.406081757246795, -7.004447645299867],
            [110.40620859613955, -7.004375441576724],
            [110.406328610641339, -7.004330365665729],
            [110.406722286977967, -7.004222316637041],
            [110.406982074360485, -7.004168371379795],
            [110.407068341525459, -7.004186571138731],
            [110.407124607954614, -7.00421377854947],
            [110.40718084012147, -7.004268122380284],
            [110.407248389028851, -7.004322480470374],
            [110.407323553903112, -7.004394939130897],
            [110.407436075432514, -7.004458399367063],
            [110.407623570349912, -7.004521954032449],
            [110.407781438778372, -7.004522152880909],
            [110.407901713300831, -7.004495167868488],
            [110.407976777922016, -7.004422898452319],
            [110.408026708380845, -7.004314449677037],
            [110.408178306977277, -7.004350788199762],
            [110.408688271877921, -7.004459975641291],
            [110.409484425352503, -7.004669022459026],
            [110.409894260574319, -7.00475999189927],
            [110.41031316074438, -7.004841926868742],
            [110.410913649508061, -7.004960270992465],
            [110.411493474218076, -7.005087634006822],
            [110.412281493687857, -7.005242393106429],
            [110.412688817831523, -7.005306220560701],
            [110.413182375899751, -7.005415382800086],
            [110.413368468868441, -7.005469888018315],
            [110.413459240713252, -7.005506183262774],
            [110.413515258718732, -7.005506253142571],
            [110.413608421340939, -7.005469939935086],
            [110.413696564744001, -7.005542661266204],
            [110.414198238376613, -7.005950334110135],
            [110.414330747023726, -7.006095227207533],
            [110.414679724051908, -7.006439390856253],
            [110.415194289842418, -7.00694657933925],
            [110.415688942039068, -7.007480927118698],
            [110.415868763204884, -7.007656419462771],
            [110.416006904884924, -7.007937943735964],
            [110.416131174368815, -7.008349529591602],
            [110.416158367851878, -7.00864381962076],
            [110.416158080842024, -7.008874436159704],
            [110.416125404320127, -7.009164972846182],
            [110.415982039585586, -7.009646467906859],
            [110.4157953973057, -7.010142986977799],
            [110.415705048679541, -7.010511142126413],
            [110.41567957983176, -7.010801798445968],
            [110.415713057151763, -7.011078939306146],
            [110.415773706237729, -7.011264873967312],
            [110.415898448726978, -7.01151543321864],
            [110.416084523267841, -7.011814166077664],
            [110.416274547702372, -7.01212194928821],
            [110.416429036589946, -7.012339233276469],
            [110.416520036647682, -7.012420755838162],
            [110.416527967994611, -7.012424384537032],
            [110.416698460129624, -7.012502386958201],
            [110.41701546003776, -7.012629417544379],
            [110.417178028723654, -7.012720074318214],
            [110.417352412830184, -7.012865018713635],
            [110.417491658283438, -7.013055146987324],
            [110.417610227914253, -7.013263340595144],
            [110.417694020434766, -7.013453400055545],
            [110.417768126184512, -7.013697720513826],
            [110.417870616420174, -7.014095849701524],
            [110.418123917658306, -7.015020949091197],
            [110.418175359822854, -7.015208824268307],
            [110.418289854898688, -7.015616212688811],
            [110.418308145591752, -7.015687916233557],
            [110.418555807513272, -7.016647530585304],
            [110.418682714518866, -7.017059834685928],
            [110.419117715763349, -7.018747074382504],
            [110.419530274288789, -7.020320763015408],
            [110.418992729858147, -7.020514752667012],
            [110.418743177977973, -7.020592125404185],
            [110.418464518168065, -7.020672665978612],
            [110.418202213118434, -7.02075018476333],
            [110.417946578933737, -7.020816751722085],
            [110.417774992936131, -7.020830443881019],
            [110.417640237583214, -7.020817847541465],
            [110.417505505429915, -7.020786607556953],
            [110.41736457114807, -7.020740859227943],
            [110.417194417951364, -7.020698226482597],
            [110.417016338531781, -7.020661709081363],
            [110.416858457573127, -7.020646883142189],
            [110.416636301308117, -7.020616123370722],
            [110.416434346721076, -7.020570644730412],
            [110.416179888333843, -7.020464972020307],
            [110.416110534852166, -7.020589986712436],
            [110.415036753309266, -7.020246267561696],
            [110.414566334996252, -7.020146126815388],
            [110.414018098815859, -7.020088554445429],
            [110.413568656160734, -7.020042432992674],
            [110.413362435645212, -7.020015038728957],
            [110.413202086035326, -7.019990614343105],
            [110.412995463984444, -7.01995243056404],
            [110.412797261616774, -7.01992689901127],
            [110.412689756656391, -7.019888839130299],
            [110.412622344072744, -7.019831866749085],
            [110.412548604889409, -7.019774886438944],
            [110.412422170360244, -7.019696770507783],
            [110.412261986002662, -7.019624933162843],
            [110.41205750218468, -7.019563575077738],
            [110.411989854321462, -7.019694122000667],
            [110.411340726263148, -7.019487868280118],
            [110.411194178470723, -7.019930913444516],
            [110.411170050003818, -7.01999420159756],
            [110.411053379929442, -7.020301601829816],
            [110.410955665877154, -7.020609025815865],
            [110.410876070477187, -7.020907427029745],
            [110.410786201320448, -7.02124758199105],
            [110.410684545661169, -7.021961266107924],
            [110.40953657137122, -7.021920166302404],
            [110.409573737394652, -7.021502297709288],
            [110.408776942454324, -7.021418931324531],
            [110.408059540144862, -7.021329562839563],
            [110.407491704989326, -7.02127393744081],
            [110.407229187262402, -7.021224798195907],
            [110.406910389638426, -7.021165991342166],
            [110.406912938798115, -7.020842865300319],
            [110.406886157413439, -7.020708194249909],
            [110.406884059200934, -7.020591506028555],
            [110.406897702809133, -7.020459130033444],
            [110.406942733426604, -7.020371672776379],
            [110.407001279737329, -7.020252817248731],
            [110.407028399224743, -7.020120458275371],
            [110.407002761578681, -7.020025135418487],
            [110.406987055062899, -7.019916569765658],
            [110.406987169588348, -7.019826115074538],
            [110.406995206081518, -7.019735670394694],
            [110.407019166666146, -7.019581927451578],
            [110.407035159463149, -7.019464356371959],
            [110.407035285422623, -7.019364856208925],
            [110.407036211004353, -7.019272253763338],
            [110.407019693416771, -7.019165835861187],
            [110.406996030598151, -7.019084396604859],
            [110.407008039493917, -7.01898491145649],
            [110.407039807470426, -7.01892163320966],
            [110.407124406489544, -7.018796647058149],
            [110.407230115297423, -7.018680095006037],
            [110.40735089842191, -7.018578282297109],
            [110.407460173822727, -7.018554006062414],
            [110.407578017480731, -7.018574260659031],
            [110.407654221136568, -7.018558559412195],
            [110.407621536200068, -7.018261239164612],
            [110.40731613367339, -7.018324980292839],
            [110.406964081343133, -7.018396899448531],
            [110.406375410522671, -7.01854088296435],
            [110.406101059588977, -7.018694309067823],
            [110.40595270271281, -7.018775530641084],
            [110.405786238467556, -7.018856729235831],
            [110.405683478630536, -7.018901826520243],
            [110.405511141711358, -7.018928744682537],
            [110.405308566047808, -7.018928488017682],
            [110.405113698340557, -7.018873968150588],
            [110.404929390900932, -7.018747097751182],
            [110.404497642235199, -7.018303321569949],
            [110.404040171114559, -7.017841421431759],
            [110.403773878216157, -7.01744308214637],
            [110.403532345037618, -7.017153319952359],
            [110.403365672248199, -7.016954107597819],
            [110.403169280902205, -7.016763902889744],
            [110.402924509480314, -7.016573636579815],
            [110.402730301174159, -7.016446752836265],
            [110.402533725781154, -7.016401275307999],
            [110.402264617742532, -7.01643711464979],
            [110.402252521186725, -7.016382826383593],
            [110.402283082618837, -7.016156728360493],
            [110.402253005776984, -7.016002916949841],
            [110.402227692522516, -7.015885293513062],
            [110.402140973051274, -7.015776637361415],
            [110.401969624488885, -7.015695009838607],
            [110.401789799794642, -7.01560432598144],
            [110.401708917970311, -7.015577086490225],
            [110.401564106731783, -7.015540720029124],
            [110.401358195353481, -7.015495230148296],
            [110.401196645396965, -7.015495024139415],
            [110.400903604881989, -7.015440377458598],
            [110.400804511897547, -7.015494523858655],
            [110.400772212232894, -7.015530664537495],
            [110.400720685254583, -7.015557735197104],
            [110.40065929061106, -7.015557656829007],
            [110.400575568061626, -7.015539458997303],
            [110.400481706518192, -7.015485066305574],
            [110.400318505339811, -7.015448675988139],
            [110.400130112332548, -7.015421298905108],
            [110.399957280200383, -7.015393941635279],
            [110.399794598622478, -7.015393733700656],
            [110.399675239252431, -7.015366444682651],
            [110.399517130840707, -7.015330060606637],
            [110.399362405958414, -7.015302726280826],
            [110.399177466168382, -7.015230125878404],
            [110.399044584511373, -7.01515759203969],
            [110.398924376487386, -7.015130301753007],
            [110.398812032346498, -7.015148248866806],
            [110.398691812738662, -7.015130003979126],
            [110.3985874485073, -7.015102733898374],
            [110.39850275783806, -7.015048597888565],
            [110.39844033958137, -7.014991890825101],
            [110.398375055787554, -7.014903236591129],
            [110.398282816170294, -7.014821933992383],
            [110.39818841244842, -7.014749449237271],
            [110.398140361826734, -7.01471320576554],
            [110.398078891201308, -7.014666184805018],
            [110.397990090408527, -7.014619566264183],
            [110.39792537052719, -7.014177807331824],
            [110.397719041341958, -7.014022785070561],
            [110.397851910284032, -7.013944348380769],
            [110.397962764766049, -7.013777450463128],
            [110.398077861272967, -7.013591487193716],
            [110.398187844528266, -7.013428809650382],
            [110.398285552974727, -7.013230071025202],
            [110.398339924110303, -7.013011514718539],
            [110.398357415288217, -7.012792911159809],
            [110.398696461479673, -7.012987406596329],
            [110.398914690032669, -7.013055105639533],
            [110.399127547311153, -7.012533358251061],
            [110.399369223753936, -7.012668161533566],
            [110.399803541045301, -7.012923457833082],
            [110.400101183660524, -7.012114593343089],
            [110.400375874474506, -7.011411129040269],
            [110.40017636319341, -7.011311143469824],
            [110.400505029665666, -7.010773016243505],
            [110.401096171808362, -7.010161137543045],
            [110.4012871960349, -7.01025233209819],
            [110.401455569131031, -7.010361983018944],
            [110.401513292932961, -7.010245604993234],
            [110.401679430267919, -7.009906283115109],
            [110.401754697448268, -7.009660059338537],
            [110.401778137412407, -7.009444340583784],
            [110.401783532042231, -7.009416757873121],
            [110.401753014925774, -7.009258878043542],
            [110.401659001076553, -7.008995690050783],
            [110.401637235532135, -7.008857551481514],
            [110.401648376946298, -7.008723839281681],
            [110.401685814826379, -7.008616467389479],
            [110.401760561938175, -7.008502566278217],
            [110.401023895148384, -7.008052219671819],
            [110.400964652665166, -7.008052144168501],
            [110.4009738155805, -7.007749627327589],
            [110.400945478971011, -7.007602711423604],
            [110.400864591628476, -7.007370231347323],
            [110.40081274879293, -7.007055104377462],
            [110.400757530625981, -7.006813435100423],
            [110.400854729193526, -7.006797376883335],
            [110.400958003957186, -7.006779303624661],
            [110.401077480194814, -7.006757205456532],
            [110.401142237066125, -7.006779538362274],
            [110.40124742795939, -7.006846423574194],
            [110.401467855140197, -7.007040889651551],
            [110.401570946789192, -7.007166432289149],
            [110.40164179284595, -7.007176636298866],
            [110.401777919359475, -7.006798552631252],
            [110.401616193456377, -7.006649081235433],
            [110.401442157458476, -7.006458904596829],
            [110.401442511373915, -7.006276252499576],
            [110.401540094154157, -7.005958802734924],
            [110.401689715443695, -7.005346699426161],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 68,
        id: 70,
        area: 607510.27014899999,
        perimeter: 4099.340059,
        kode_pos: 50233,
        kode_kec: 3374040,
        kode_kel: 3374032005,
        kec: "Gajah Mungkur",
        kel: "Bendan Ngisor",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.401689715443695, -7.005346699426161],
            [110.401540094154157, -7.005958802734924],
            [110.401442511373915, -7.006276252499576],
            [110.401442157458476, -7.006458904596829],
            [110.401616193456377, -7.006649081235433],
            [110.401777919359475, -7.006798552631252],
            [110.40164179284595, -7.007176636298866],
            [110.401570946789192, -7.007166432289149],
            [110.401467855140197, -7.007040889651551],
            [110.40124742795939, -7.006846423574194],
            [110.401142237066125, -7.006779538362274],
            [110.401077480194814, -7.006757205456532],
            [110.400958003957186, -7.006779303624661],
            [110.400854729193526, -7.006797376883335],
            [110.400757530625981, -7.006813435100423],
            [110.40081274879293, -7.007055104377462],
            [110.400864591628476, -7.007370231347323],
            [110.400945478971011, -7.007602711423604],
            [110.4009738155805, -7.007749627327589],
            [110.400964652665166, -7.008052144168501],
            [110.401023895148384, -7.008052219671819],
            [110.401760561938175, -7.008502566278217],
            [110.401685814826379, -7.008616467389479],
            [110.401648376946298, -7.008723839281681],
            [110.401637235532135, -7.008857551481514],
            [110.401659001076553, -7.008995690050783],
            [110.401753014925774, -7.009258878043542],
            [110.401783532042231, -7.009416757873121],
            [110.401778137412407, -7.009444340583784],
            [110.401754697448268, -7.009660059338537],
            [110.401679430267919, -7.009906283115109],
            [110.401513292932961, -7.010245604993234],
            [110.401455569131031, -7.010361983018944],
            [110.4012871960349, -7.01025233209819],
            [110.401096171808362, -7.010161137543045],
            [110.400505029665666, -7.010773016243505],
            [110.40017636319341, -7.011311143469824],
            [110.400375874474506, -7.011411129040269],
            [110.400101183660524, -7.012114593343089],
            [110.399803541045301, -7.012923457833082],
            [110.399369223753936, -7.012668161533566],
            [110.399127547311153, -7.012533358251061],
            [110.398914690032669, -7.013055105639533],
            [110.398696461479673, -7.012987406596329],
            [110.398357415288217, -7.012792911159809],
            [110.398339924110303, -7.013011514718539],
            [110.398285552974727, -7.013230071025202],
            [110.398187844528266, -7.013428809650382],
            [110.398077861272967, -7.013591487193716],
            [110.397962764766049, -7.013777450463128],
            [110.397851910284032, -7.013944348380769],
            [110.397719041341958, -7.014022785070561],
            [110.39792537052719, -7.014177807331824],
            [110.397990090408527, -7.014619566264183],
            [110.397920080291314, -7.014622427691307],
            [110.397828929553597, -7.014664412344158],
            [110.397674479250526, -7.014977841224051],
            [110.397477846851714, -7.015300311682291],
            [110.397178210451614, -7.015535109281974],
            [110.396999909630253, -7.015624122883934],
            [110.396692730716225, -7.015959622727046],
            [110.396175364945591, -7.016822194973133],
            [110.395836053576588, -7.016693106902613],
            [110.395220644417051, -7.016419047480019],
            [110.39473088729882, -7.016210370586272],
            [110.39403115175179, -7.015920013060795],
            [110.394175522653256, -7.015540289871391],
            [110.394350234103541, -7.014988741913419],
            [110.394615772706771, -7.014211174302567],
            [110.394964959408654, -7.013288986744259],
            [110.395010119248852, -7.013153362917203],
            [110.395036617827131, -7.013008669579637],
            [110.39514003349629, -7.012674120487597],
            [110.395411312284011, -7.012050332476085],
            [110.395534225723267, -7.011733899273416],
            [110.395903073101977, -7.010920281538302],
            [110.396083864887359, -7.010477285964562],
            [110.396161629888582, -7.010287431049493],
            [110.396180984981527, -7.010197001225132],
            [110.396213517453859, -7.009979951752796],
            [110.39622647012871, -7.00980810446585],
            [110.396239600922556, -7.009717666636742],
            [110.396317140995947, -7.009482584033232],
            [110.396407718607961, -7.009229427213158],
            [110.396453218660938, -7.009048576239418],
            [110.39644679295526, -7.008985249691526],
            [110.396395371268582, -7.008930910822583],
            [110.396317967480556, -7.008840356691223],
            [110.396260318039538, -7.008568918516208],
            [110.396145092679589, -7.008188860717723],
            [110.396081250591521, -7.007673186865839],
            [110.396062303128545, -7.007447025753809],
            [110.396100954806542, -7.007311393352405],
            [110.396191803291742, -7.007067282344443],
            [110.396211487668026, -7.006940671033321],
            [110.396211662250494, -7.006804989189983],
            [110.396218402158809, -7.006624088422024],
            [110.39622494060167, -7.006379869093825],
            [110.396264061224556, -7.006099509719453],
            [110.396296546670072, -7.005918642003173],
            [110.396361342960816, -7.005692588407601],
            [110.396541969072985, -7.005376228753645],
            [110.396813423259687, -7.005050940059249],
            [110.396844103915697, -7.005005770710668],
            [110.397165094546779, -7.005268482425529],
            [110.397201556147877, -7.005295665592702],
            [110.397460945743219, -7.005332179968108],
            [110.397858116616362, -7.005368870676518],
            [110.398186608005247, -7.005351200301538],
            [110.398330625036408, -7.00534233914177],
            [110.398363172240366, -7.005333335314819],
            [110.398505289982822, -7.005261153342031],
            [110.399016316575768, -7.004981397040482],
            [110.399207119196177, -7.00489118610642],
            [110.399344935044539, -7.004864225744867],
            [110.399600433239215, -7.004846461098889],
            [110.399774710795953, -7.004846683596828],
            [110.400121227698889, -7.004892353189986],
            [110.40027507739488, -7.004937776834608],
            [110.400557368322254, -7.00501898824805],
            [110.400937825430248, -7.005140838978065],
            [110.401689715443695, -7.005346699426161],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 69,
        id: 71,
        area: 1296056.397866,
        perimeter: 6960.756953,
        kode_pos: 50253,
        kode_kec: 3374040,
        kode_kel: 3374032002,
        kec: "Gajah Mungkur",
        kel: "Bendan Duwur",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.397990090408527, -7.014619566264183],
            [110.398078891201308, -7.014666184805018],
            [110.398140361826734, -7.01471320576554],
            [110.39818841244842, -7.014749449237271],
            [110.398282816170294, -7.014821933992383],
            [110.398375055787554, -7.014903236591129],
            [110.39844033958137, -7.014991890825101],
            [110.39850275783806, -7.015048597888565],
            [110.3985874485073, -7.015102733898374],
            [110.398691812738662, -7.015130003979126],
            [110.398812032346498, -7.015148248866806],
            [110.398924376487386, -7.015130301753007],
            [110.399044584511373, -7.01515759203969],
            [110.399177466168382, -7.015230125878404],
            [110.399362405958414, -7.015302726280826],
            [110.399517130840707, -7.015330060606637],
            [110.399675239252431, -7.015366444682651],
            [110.399794598622478, -7.015393733700656],
            [110.399957280200383, -7.015393941635279],
            [110.400130112332548, -7.015421298905108],
            [110.400318505339811, -7.015448675988139],
            [110.400481706518192, -7.015485066305574],
            [110.400575568061626, -7.015539458997303],
            [110.40065929061106, -7.015557656829007],
            [110.400720685254583, -7.015557735197104],
            [110.400772212232894, -7.015530664537495],
            [110.400804511897547, -7.015494523858655],
            [110.400903604881989, -7.015440377458598],
            [110.401196645396965, -7.015495024139415],
            [110.401358195353481, -7.015495230148296],
            [110.401564106731783, -7.015540720029124],
            [110.401708917970311, -7.015577086490225],
            [110.401789799794642, -7.01560432598144],
            [110.401969624488885, -7.015695009838607],
            [110.402140973051274, -7.015776637361415],
            [110.402227692522516, -7.015885293513062],
            [110.402253005776984, -7.016002916949841],
            [110.402283082618837, -7.016156728360493],
            [110.402252521186725, -7.016382826383593],
            [110.402264617742532, -7.01643711464979],
            [110.402533725781154, -7.016401275307999],
            [110.402730301174159, -7.016446752836265],
            [110.402924509480314, -7.016573636579815],
            [110.403169280902205, -7.016763902889744],
            [110.403365672248199, -7.016954107597819],
            [110.403532345037618, -7.017153319952359],
            [110.403773878216157, -7.01744308214637],
            [110.404040171114559, -7.017841421431759],
            [110.404497642235199, -7.018303321569949],
            [110.404929390900932, -7.018747097751182],
            [110.405113698340557, -7.018873968150588],
            [110.405308566047808, -7.018928488017682],
            [110.405511141711358, -7.018928744682537],
            [110.405683478630536, -7.018901826520243],
            [110.405786238467556, -7.018856729235831],
            [110.40595270271281, -7.018775530641084],
            [110.406101059588977, -7.018694309067823],
            [110.406375410522671, -7.01854088296435],
            [110.406964081343133, -7.018396899448531],
            [110.40731613367339, -7.018324980292839],
            [110.407621536200068, -7.018261239164612],
            [110.407654221136568, -7.018558559412195],
            [110.407578017480731, -7.018574260659031],
            [110.407460173822727, -7.018554006062414],
            [110.40735089842191, -7.018578282297109],
            [110.407230115297423, -7.018680095006037],
            [110.407124406489544, -7.018796647058149],
            [110.407039807470426, -7.01892163320966],
            [110.407008039493917, -7.01898491145649],
            [110.406996030598151, -7.019084396604859],
            [110.407019693416771, -7.019165835861187],
            [110.407036211004353, -7.019272253763338],
            [110.407035285422623, -7.019364856208925],
            [110.407035159463149, -7.019464356371959],
            [110.407019166666146, -7.019581927451578],
            [110.406995206081518, -7.019735670394694],
            [110.406987169588348, -7.019826115074538],
            [110.406987055062899, -7.019916569765658],
            [110.407002761578681, -7.020025135418487],
            [110.407028399224743, -7.020120458275371],
            [110.407001279737329, -7.020252817248731],
            [110.406942733426604, -7.020371672776379],
            [110.406897702809133, -7.020459130033444],
            [110.406884059200934, -7.020591506028555],
            [110.406886157413439, -7.020708194249909],
            [110.406912938798115, -7.020842865300319],
            [110.406910389638426, -7.021165991342166],
            [110.406934708148128, -7.021388173452618],
            [110.406956974844149, -7.021540790418997],
            [110.406905106634554, -7.021706777379954],
            [110.40685111745853, -7.021774027711672],
            [110.406805238489639, -7.021843027807117],
            [110.406767856395689, -7.021899583798817],
            [110.406726592166066, -7.022060019925558],
            [110.406728186588282, -7.022141431283022],
            [110.406740849622892, -7.022195720192523],
            [110.406774166475486, -7.022250035222849],
            [110.406826722508612, -7.022304374581268],
            [110.406891727432196, -7.022358729675902],
            [110.406922520867369, -7.022394950541907],
            [110.406934923915799, -7.022431148154237],
            [110.406934878084598, -7.022467330026375],
            [110.406916170388016, -7.022494442819772],
            [110.406847915709037, -7.022548629405923],
            [110.406826367252137, -7.022584784084863],
            [110.40681074884462, -7.022629991744354],
            [110.406810714463063, -7.022657128147619],
            [110.406823106047469, -7.022702371230119],
            [110.406838575475518, -7.022774754651513],
            [110.406838506714067, -7.022829027457954],
            [110.406841527254429, -7.022901395135585],
            [110.406850512233234, -7.022955679391644],
            [110.406884712313243, -7.022982859088179],
            [110.406915482866566, -7.023037170892643],
            [110.406962005811479, -7.023163866472129],
            [110.406980293108305, -7.023245298935841],
            [110.406998863340007, -7.023326731756827],
            [110.407029588096322, -7.023417225427317],
            [110.407048932092522, -7.02352279611382],
            [110.407038607665271, -7.023652710371812],
            [110.407050995589216, -7.023715753408617],
            [110.407057151301174, -7.023770034084921],
            [110.407072349314959, -7.023833371675874],
            [110.407107643382886, -7.024014157989262],
            [110.407135095135629, -7.024158736828842],
            [110.407123623517464, -7.024231806437651],
            [110.407043815854465, -7.024353512381857],
            [110.406975418070516, -7.024450871368302],
            [110.406949328545807, -7.024514177934581],
            [110.406978405845422, -7.024658758834578],
            [110.407022173056404, -7.02475625966262],
            [110.40716994866419, -7.024876629275998],
            [110.407164911463028, -7.025003302031775],
            [110.407031609669403, -7.025006381656858],
            [110.406955764250696, -7.024963909486025],
            [110.406738725773295, -7.024764634342062],
            [110.406616931809197, -7.024646888976872],
            [110.406454713278947, -7.024501955975182],
            [110.406327815238114, -7.02439324956547],
            [110.406262787176061, -7.024356985311532],
            [110.406185570175126, -7.024338796575797],
            [110.406079863977908, -7.024339299762699],
            [110.406021299860171, -7.024373331497577],
            [110.405995173116835, -7.024465871592156],
            [110.405931701319531, -7.024524258462241],
            [110.405902391415736, -7.024563199513155],
            [110.405791260247696, -7.025029172805749],
            [110.405580788204091, -7.025282704390726],
            [110.405182797774557, -7.024889342906381],
            [110.404622746482246, -7.024299163336773],
            [110.403610599767177, -7.023212320968031],
            [110.402971853928094, -7.022493640534906],
            [110.402554827865046, -7.021982431278175],
            [110.402216564154358, -7.021550112444758],
            [110.401337834117584, -7.023492487001796],
            [110.401107196936749, -7.023407565879282],
            [110.40092331922331, -7.023302277256917],
            [110.400806579517408, -7.023229174113116],
            [110.400561513829956, -7.023007264277154],
            [110.39956663178387, -7.023828457503098],
            [110.39914924565258, -7.023524237252952],
            [110.39886416272239, -7.02335425971167],
            [110.398559824439914, -7.02319610357786],
            [110.398229090786003, -7.023098014708292],
            [110.397977177032956, -7.023105204084695],
            [110.397698901452728, -7.023146166456614],
            [110.397428169537591, -7.02316835669424],
            [110.397172616930931, -7.023081632678172],
            [110.396902102809122, -7.02293478799493],
            [110.396691803097724, -7.02274294465371],
            [110.396313198330873, -7.022275724816166],
            [110.396199966296408, -7.022134164204176],
            [110.396114005648258, -7.02198341968657],
            [110.396028150528139, -7.0217509026261],
            [110.39593633490783, -7.021536357177038],
            [110.395806293541256, -7.021351962533585],
            [110.395555111205653, -7.021062017615781],
            [110.395258122391141, -7.020769025027063],
            [110.394788376934201, -7.020322146783059],
            [110.394427096117795, -7.019968302129008],
            [110.394098938450284, -7.019628740621449],
            [110.393928792588596, -7.019516451945706],
            [110.393789726300284, -7.019482465344137],
            [110.393609230059766, -7.019504770108099],
            [110.393433402956191, -7.019569820378845],
            [110.393312675293004, -7.019655740762299],
            [110.393222068187043, -7.019767522997843],
            [110.393146247868174, -7.019917367782742],
            [110.393059554949645, -7.020086290455758],
            [110.392932820457645, -7.020253480814151],
            [110.392760265876177, -7.020442625705906],
            [110.392587761392306, -7.020593036186757],
            [110.392419625949515, -7.020696110210315],
            [110.392195501106798, -7.020790503992095],
            [110.391919722523227, -7.020854703961914],
            [110.391635345187368, -7.02090598114899],
            [110.391282753197359, -7.020923976833679],
            [110.391069792390041, -7.020875512527492],
            [110.390961788776124, -7.020748117777863],
            [110.390897285873692, -7.02062631754452],
            [110.390869349191036, -7.020473686113896],
            [110.390869558104811, -7.020313059898538],
            [110.390841710230191, -7.020092162324682],
            [110.39070524720735, -7.019943405641849],
            [110.390424047643975, -7.019826586216368],
            [110.390134793948064, -7.019721803151007],
            [110.389757094902535, -7.019632967549883],
            [110.389293012125293, -7.019536735851271],
            [110.38890925349871, -7.019455175094272],
            [110.388511573834677, -7.019277967973558],
            [110.388218411840512, -7.019088849827416],
            [110.387877212753025, -7.018751089432224],
            [110.387756511066655, -7.018595652264374],
            [110.387654714913793, -7.018452714680326],
            [110.387569830917187, -7.018156275444531],
            [110.387540800315392, -7.017831064355578],
            [110.387526583409212, -7.017433559695444],
            [110.387626945582653, -7.017163548928019],
            [110.387732200049797, -7.016753285943555],
            [110.387789274035981, -7.016222254112577],
            [110.387765521161342, -7.015908387610389],
            [110.387629134522498, -7.015497809434759],
            [110.387611156048905, -7.015173014648008],
            [110.387604849490458, -7.015064827107924],
            [110.387788524122485, -7.015206919394352],
            [110.388290576852214, -7.015588787748479],
            [110.388656618527648, -7.015849505211711],
            [110.388925452383404, -7.016024302264089],
            [110.389234366897924, -7.016193431748015],
            [110.389597670175803, -7.016359771979616],
            [110.389989660610766, -7.016477532824614],
            [110.390284401731932, -7.016540831075574],
            [110.391082866317163, -7.016644819853485],
            [110.391598005846944, -7.016711262925005],
            [110.393755930683128, -7.016942837952866],
            [110.393822186799298, -7.016616907408372],
            [110.39389417492211, -7.016285264661697],
            [110.393962038374127, -7.016100833130892],
            [110.39403115175179, -7.015920013060795],
            [110.39473088729882, -7.016210370586272],
            [110.395220644417051, -7.016419047480019],
            [110.395836053576588, -7.016693106902613],
            [110.396175364945591, -7.016822194973133],
            [110.396692730716225, -7.015959622727046],
            [110.396999909630253, -7.015624122883934],
            [110.397178210451614, -7.015535109281974],
            [110.397477846851714, -7.015300311682291],
            [110.397674479250526, -7.014977841224051],
            [110.397828929553597, -7.014664412344158],
            [110.397920080291314, -7.014622427691307],
            [110.397990090408527, -7.014619566264183],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 70,
        id: 72,
        area: 462765.09669600002,
        perimeter: 4098.268819,
        kode_pos: 50231,
        kode_kec: 3374040,
        kode_kel: 3374032007,
        kec: "Gajah Mungkur",
        kel: "Bendungan",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.413608421340939, -7.005469939935086],
            [110.413515258718732, -7.005506253142571],
            [110.413459240713252, -7.005506183262774],
            [110.413368468868441, -7.005469888018315],
            [110.413182375899751, -7.005415382800086],
            [110.412688817831523, -7.005306220560701],
            [110.412281493687857, -7.005242393106429],
            [110.411493474218076, -7.005087634006822],
            [110.410913649508061, -7.004960270992465],
            [110.41031316074438, -7.004841926868742],
            [110.409894260574319, -7.00475999189927],
            [110.409484425352503, -7.004669022459026],
            [110.408688271877921, -7.004459975641291],
            [110.408178306977277, -7.004350788199762],
            [110.408026708380845, -7.004314449677037],
            [110.407929204316503, -7.00423288324465],
            [110.40785349634811, -7.004142333009121],
            [110.407799595920068, -7.004033719244292],
            [110.407734844302368, -7.003780363955022],
            [110.407627046007164, -7.003336999133701],
            [110.407583942231568, -7.003192216987419],
            [110.407373059539225, -7.002830131687666],
            [110.406984576459976, -7.00218741224127],
            [110.406932951186519, -7.002069755781386],
            [110.406896263400142, -7.0019973456027],
            [110.406833810078439, -7.001716856658327],
            [110.406705191425814, -7.001183010542841],
            [110.406635359613063, -7.000920603256612],
            [110.406569080054908, -7.000757700836311],
            [110.406296469236352, -7.000214627514355],
            [110.40623781716458, -7.000060780174672],
            [110.40620459321589, -6.999934101389426],
            [110.406197386065656, -6.999816500925258],
            [110.406183040375879, -6.999527027142205],
            [110.406161258855505, -6.999300862403905],
            [110.406113652038911, -6.999137983503931],
            [110.405896448071573, -6.998631161910184],
            [110.405767782199078, -6.998359634774173],
            [110.405642374125634, -6.998196657602597],
            [110.405384416695654, -6.997924967091969],
            [110.405266601751649, -6.997798181387362],
            [110.405215225877839, -6.997707661575754],
            [110.405189312286524, -6.997617173937386],
            [110.405200540586605, -6.997463414809904],
            [110.405245152710435, -6.99730969786332],
            [110.405337565781522, -6.99716508678851],
            [110.405485112632846, -6.997047681795213],
            [110.405584744028786, -6.997011625656786],
            [110.405703013395296, -6.997002729497463],
            [110.406473907579297, -6.997039884090992],
            [110.40658086040456, -6.997030973441408],
            [110.406732276367507, -6.996985936839743],
            [110.406991336495722, -6.996832489896954],
            [110.407644711727485, -6.996507674761943],
            [110.408042784355573, -6.996272992637181],
            [110.408283080708983, -6.996110365345777],
            [110.408585683913245, -6.99607952487858],
            [110.40890030036887, -6.996039102269062],
            [110.409337520785186, -6.99596209731906],
            [110.409570498587726, -6.995868508761045],
            [110.409823907728153, -6.995770863917657],
            [110.410032225220206, -6.995795615649159],
            [110.410338384145092, -6.995983760811637],
            [110.410583320683301, -6.996126929629632],
            [110.41072216333248, -6.99617200295723],
            [110.411322686882784, -6.996180917797932],
            [110.411792517814689, -6.996161096174948],
            [110.412630165391832, -6.996027443402155],
            [110.413502155489084, -6.995877491620089],
            [110.413490287338462, -6.995936233217457],
            [110.413481922366756, -6.995977753041657],
            [110.413389503285572, -6.996093301899497],
            [110.413275332355937, -6.99616188751068],
            [110.413179146949631, -6.996225150102792],
            [110.413092801817697, -6.996270269978016],
            [110.412958304854129, -6.996360557304454],
            [110.412854928351166, -6.996450883402832],
            [110.412699733370332, -6.996577326837095],
            [110.412561796053467, -6.996703791748511],
            [110.412291590791455, -6.996947683007286],
            [110.412153698519688, -6.997037965833561],
            [110.411988091934191, -6.99711916849533],
            [110.411812311708729, -6.997191312881491],
            [110.411701929903188, -6.997227356938601],
            [110.411505519522791, -6.997281384419908],
            [110.411422840607671, -6.997335554040438],
            [110.411319440943188, -6.997443970725004],
            [110.41122959844958, -6.997570495338389],
            [110.411157002348574, -6.997706087004868],
            [110.411139608536004, -6.997814611227237],
            [110.411128382149528, -6.997968370656685],
            [110.411117619545536, -6.998203540150184],
            [110.411100203020254, -6.998330155334778],
            [110.411079493550233, -6.998375356912485],
            [110.411003581665284, -6.998447625900982],
            [110.410851803197801, -6.998555981900219],
            [110.410769101290896, -6.998628242344897],
            [110.410696550259175, -6.998727651976292],
            [110.410654802894584, -6.998854236662425],
            [110.410599452880987, -6.998998895300413],
            [110.410540741897165, -6.999116413226885],
            [110.4104613667145, -6.999242950752565],
            [110.4104162355992, -6.999360485669747],
            [110.410371319251709, -6.999532293829531],
            [110.410347112475435, -6.999658900449962],
            [110.410343184768337, -6.999857896452644],
            [110.410329003949428, -7.000111152582666],
            [110.410301345346397, -7.000282982349833],
            [110.410270099667997, -7.000382443646783],
            [110.410218449945731, -7.000509015851913],
            [110.410142355805789, -7.000726012361777],
            [110.410082840031563, -7.001033484596244],
            [110.410044611081602, -7.001286710534854],
            [110.410023639995032, -7.001539958108078],
            [110.409995628841841, -7.001992197740592],
            [110.409984956337169, -7.002155003266548],
            [110.410016054479584, -7.002173133262623],
            [110.410105682363309, -7.002218473146272],
            [110.410190432503512, -7.002318079870218],
            [110.410243178953067, -7.00244478294214],
            [110.410258297429507, -7.002571438833158],
            [110.410242883559164, -7.002679965450771],
            [110.410205153291017, -7.002761327597915],
            [110.410079933329357, -7.002896852986217],
            [110.409905734453474, -7.003059453370162],
            [110.409810820624585, -7.003167880211215],
            [110.409719776618005, -7.00334867583978],
            [110.409678209036258, -7.003556670031568],
            [110.410197792785127, -7.003665867870875],
            [110.410449781804303, -7.003738547791374],
            [110.410622825418798, -7.003820174146961],
            [110.410854998984036, -7.003901874523495],
            [110.411173711564956, -7.004010819644635],
            [110.411595678145972, -7.004128939264476],
            [110.411832435429318, -7.00416541743289],
            [110.412023111055518, -7.004174701376986],
            [110.412452875355982, -7.004166193031212],
            [110.412656304977489, -7.004157401657357],
            [110.412796881462185, -7.004184713710499],
            [110.412956436123622, -7.004193958416772],
            [110.413138895879328, -7.004212277154053],
            [110.413492159286577, -7.004294127381246],
            [110.413683050184815, -7.004357683928303],
            [110.413819881100068, -7.004439264016844],
            [110.413742328484389, -7.004918578840846],
            [110.413630610255709, -7.005343578023917],
            [110.413608421340939, -7.005469939935086],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 71,
        id: 73,
        area: 844547.85160399997,
        perimeter: 5164.429609,
        kode_pos: 50237,
        kode_kec: 3374040,
        kode_kel: 3374032006,
        kec: "Gajah Mungkur",
        kel: "Petompon",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.401689715443695, -7.005346699426161],
            [110.400937825430248, -7.005140838978065],
            [110.400557368322254, -7.00501898824805],
            [110.40027507739488, -7.004937776834608],
            [110.400121227698889, -7.004892353189986],
            [110.399774710795953, -7.004846683596828],
            [110.399600433239215, -7.004846461098889],
            [110.399344935044539, -7.004864225744867],
            [110.399207119196177, -7.00489118610642],
            [110.399016316575768, -7.004981397040482],
            [110.398505289982822, -7.005261153342031],
            [110.398363172240366, -7.005333335314819],
            [110.398330625036408, -7.00534233914177],
            [110.398186608005247, -7.005351200301538],
            [110.397858116616362, -7.005368870676518],
            [110.397460945743219, -7.005332179968108],
            [110.397201556147877, -7.005295665592702],
            [110.397165094546779, -7.005268482425529],
            [110.396844103915697, -7.005005770710668],
            [110.3971745504235, -7.004734811615765],
            [110.39740016373986, -7.004635600587389],
            [110.397625551762602, -7.004530996807136],
            [110.397568170677175, -7.004432309082107],
            [110.397502467629579, -7.004271252895749],
            [110.39744410043501, -7.004100450223306],
            [110.397393044041891, -7.003939412793382],
            [110.397334683181199, -7.003763732180313],
            [110.397275084590632, -7.003601464310025],
            [110.397262966797157, -7.003533157625442],
            [110.397302274773196, -7.003338090415015],
            [110.397321966316184, -7.003211289216773],
            [110.39719509752598, -7.002719769594639],
            [110.397131686277604, -7.002474099125323],
            [110.397341117977518, -7.002171187065979],
            [110.397589927706761, -7.00197097606506],
            [110.39786902975932, -7.001814587505575],
            [110.398370386373884, -7.001654129008362],
            [110.398892949972947, -7.001474941900399],
            [110.399377450954034, -7.001337569930701],
            [110.399904820082625, -7.001281640228195],
            [110.40042776460615, -7.001277952944759],
            [110.400833064555158, -7.001261053094176],
            [110.401016204275436, -7.001174205198213],
            [110.401199516026153, -7.000952381718978],
            [110.401374150806504, -7.000700068720707],
            [110.40151391730069, -7.000452065343381],
            [110.401614407827552, -7.000247552522601],
            [110.401666912385664, -7.000082165159313],
            [110.40164973071461, -6.999886210782164],
            [110.40157584758505, -6.999729370802692],
            [110.401084569539833, -6.9988231021371],
            [110.401010808870552, -6.99857047301699],
            [110.401041655518597, -6.998322331084882],
            [110.402029787964409, -6.995627084547146],
            [110.40216730309379, -6.995724566300454],
            [110.402209073136035, -6.995754138259762],
            [110.402315978305765, -6.995829823690687],
            [110.402610546378128, -6.995958181718724],
            [110.402790306852339, -6.996016431675193],
            [110.403016937252673, -6.99604479421696],
            [110.403255330483205, -6.99604081135131],
            [110.403640801450237, -6.995995049684423],
            [110.404021123432642, -6.995958097799655],
            [110.404274012973389, -6.995960289537729],
            [110.404438863414043, -6.995958626429098],
            [110.404624329351833, -6.995949502635215],
            [110.404823325087705, -6.995944166847607],
            [110.405076693707642, -6.995879178757756],
            [110.405191137722511, -6.995834423883705],
            [110.405285170931037, -6.995777397885955],
            [110.405411937799769, -6.995679595500504],
            [110.405600174425615, -6.995430845160781],
            [110.405767953629862, -6.99520655953529],
            [110.405959183286583, -6.994951274295608],
            [110.406170460602269, -6.994705764603882],
            [110.406398919355325, -6.994588461260594],
            [110.406838317316158, -6.994454837891824],
            [110.406838349880601, -6.994454827988782],
            [110.406928248259092, -6.994589128236028],
            [110.407116663402135, -6.994751101270844],
            [110.407335197224342, -6.994942414856717],
            [110.407563291040816, -6.995114566282491],
            [110.407804070517784, -6.995322915542676],
            [110.407945084629475, -6.995454208509739],
            [110.408055181285675, -6.99563982361887],
            [110.408201014505735, -6.995983735745015],
            [110.408283080708983, -6.996110365345777],
            [110.408042784355573, -6.996272992637181],
            [110.407644711727485, -6.996507674761943],
            [110.406991336495722, -6.996832489896954],
            [110.406732276367507, -6.996985936839743],
            [110.40658086040456, -6.997030973441408],
            [110.406473907579297, -6.997039884090992],
            [110.405703013395296, -6.997002729497463],
            [110.405584744028786, -6.997011625656786],
            [110.405485112632846, -6.997047681795213],
            [110.405337565781522, -6.99716508678851],
            [110.405245152710435, -6.99730969786332],
            [110.405200540586605, -6.997463414809904],
            [110.405189312286524, -6.997617173937386],
            [110.405215225877839, -6.997707661575754],
            [110.405266601751649, -6.997798181387362],
            [110.405384416695654, -6.997924967091969],
            [110.405642374125634, -6.998196657602597],
            [110.405767782199078, -6.998359634774173],
            [110.405896448071573, -6.998631161910184],
            [110.406113652038911, -6.999137983503931],
            [110.406161258855505, -6.999300862403905],
            [110.406183040375879, -6.999527027142205],
            [110.406197386065656, -6.999816500925258],
            [110.40620459321589, -6.999934101389426],
            [110.40623781716458, -7.000060780174672],
            [110.406296469236352, -7.000214627514355],
            [110.406569080054908, -7.000757700836311],
            [110.406635359613063, -7.000920603256612],
            [110.406705191425814, -7.001183010542841],
            [110.406833810078439, -7.001716856658327],
            [110.406896263400142, -7.0019973456027],
            [110.406932951186519, -7.002069755781386],
            [110.406984576459976, -7.00218741224127],
            [110.407373059539225, -7.002830131687666],
            [110.407583942231568, -7.003192216987419],
            [110.407627046007164, -7.003336999133701],
            [110.407734844302368, -7.003780363955022],
            [110.407799595920068, -7.004033719244292],
            [110.40785349634811, -7.004142333009121],
            [110.407929204316503, -7.00423288324465],
            [110.408026708380845, -7.004314449677037],
            [110.407976777922016, -7.004422898452319],
            [110.407901713300831, -7.004495167868488],
            [110.407781438778372, -7.004522152880909],
            [110.407623570349912, -7.004521954032449],
            [110.407436075432514, -7.004458399367063],
            [110.407323553903112, -7.004394939130897],
            [110.407248389028851, -7.004322480470374],
            [110.40718084012147, -7.004268122380284],
            [110.407124607954614, -7.00421377854947],
            [110.407068341525459, -7.004186571138731],
            [110.406982074360485, -7.004168371379795],
            [110.406722286977967, -7.004222316637041],
            [110.406328610641339, -7.004330365665729],
            [110.40620859613955, -7.004375441576724],
            [110.406081757246795, -7.004447645299867],
            [110.406015191348288, -7.00451087963646],
            [110.405904895742083, -7.004700695542265],
            [110.405827961519677, -7.004908644545933],
            [110.405811143373199, -7.005008123654704],
            [110.405867417933322, -7.005252422924838],
            [110.40589393214195, -7.005315774839539],
            [110.405937138563715, -7.005379147843901],
            [110.406060330890114, -7.005505940306223],
            [110.406140248133397, -7.005623632594598],
            [110.406280121355678, -7.005759491565442],
            [110.406376788126749, -7.005831977532614],
            [110.406519865099682, -7.00589547658188],
            [110.406553203801664, -7.005931700618305],
            [110.406586508196639, -7.005995061074115],
            [110.406589857477101, -7.006031247254498],
            [110.406536305682408, -7.006094498074311],
            [110.406352882494943, -7.006166630398238],
            [110.405986104662844, -7.006256621980379],
            [110.405771368927631, -7.006256350604076],
            [110.405678017022495, -7.0062471871116],
            [110.405558059254929, -7.0062470354464],
            [110.405317837883942, -7.006264822609492],
            [110.404881327898039, -7.006237133781515],
            [110.40475800953898, -7.0062098412026],
            [110.404641198337856, -7.006182556833125],
            [110.404528088056239, -7.006137186153668],
            [110.404311498294319, -7.006037411436686],
            [110.404271346750775, -7.006019269591396],
            [110.404101507649187, -7.005865281117294],
            [110.404068440588688, -7.005838102751557],
            [110.404028289072698, -7.00581996088759],
            [110.403732673281965, -7.005792449612708],
            [110.403539145267715, -7.005801249614067],
            [110.403342493645624, -7.00581909105412],
            [110.40318233855217, -7.005836978741859],
            [110.403045711740745, -7.005818714318579],
            [110.402972458766897, -7.005800530337281],
            [110.402799133100274, -7.005718900864057],
            [110.402512697579454, -7.005591900193931],
            [110.402316172751952, -7.005510241073027],
            [110.401946241958214, -7.005410270375256],
            [110.401689715443695, -7.005346699426161],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 72,
        id: 74,
        area: 1042732.953314,
        perimeter: 4632.508094,
        kode_pos: 50232,
        kode_kec: 3374040,
        kode_kel: 3374032001,
        kec: "Gajah Mungkur",
        kel: "Sampangan",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.39403115175179, -7.015920013060795],
            [110.393962038374127, -7.016100833130892],
            [110.39389417492211, -7.016285264661697],
            [110.393822186799298, -7.016616907408372],
            [110.393755930683128, -7.016942837952866],
            [110.391598005846944, -7.016711262925005],
            [110.391082866317163, -7.016644819853485],
            [110.390284401731932, -7.016540831075574],
            [110.389989660610766, -7.016477532824614],
            [110.389597670175803, -7.016359771979616],
            [110.389234366897924, -7.016193431748015],
            [110.388925452383404, -7.016024302264089],
            [110.388656618527648, -7.015849505211711],
            [110.388290576852214, -7.015588787748479],
            [110.387788524122485, -7.015206919394352],
            [110.387604849490458, -7.015064827107924],
            [110.387620445650199, -7.014374009349284],
            [110.38757021160643, -7.013835817308389],
            [110.387396478471572, -7.013108392703487],
            [110.387157144671747, -7.012453602273659],
            [110.386873988684314, -7.01191510624403],
            [110.386765192427106, -7.0116240850369],
            [110.386673766950906, -7.011297097320353],
            [110.38669043744558, -7.011030760372332],
            [110.386772389326737, -7.0107699445235],
            [110.388400285095059, -7.008004769356622],
            [110.388669993251966, -7.007521752604045],
            [110.388910062571654, -7.007000218842644],
            [110.389112060827657, -7.006467763639665],
            [110.389275726394928, -7.006125514858394],
            [110.389401116167718, -7.005929985477485],
            [110.389569882597641, -7.005848666192671],
            [110.389831118918067, -7.005783774549406],
            [110.390141172741735, -7.005833099911886],
            [110.390913788392439, -7.005801486106213],
            [110.391446923487464, -7.005840227777608],
            [110.391974561723458, -7.005922448930778],
            [110.392589138205722, -7.006091756223891],
            [110.39303518113644, -7.006162998997426],
            [110.393312605794577, -7.006201408389486],
            [110.393638758921568, -7.006221306027063],
            [110.39396549996718, -7.006133725179405],
            [110.394170249271639, -7.0060610663995],
            [110.394413487126101, -7.005939964330708],
            [110.394652238488362, -7.005782601239217],
            [110.394979191798896, -7.005530015823759],
            [110.39521074458915, -7.005262640414442],
            [110.395519607149026, -7.004808359597402],
            [110.397131686277604, -7.002474099125323],
            [110.39719509752598, -7.002719769594639],
            [110.397321966316184, -7.003211289216773],
            [110.397302274773196, -7.003338090415015],
            [110.397262966797157, -7.003533157625442],
            [110.397275084590632, -7.003601464310025],
            [110.397334683181199, -7.003763732180313],
            [110.397393044041891, -7.003939412793382],
            [110.39744410043501, -7.004100450223306],
            [110.397502467629579, -7.004271252895749],
            [110.397568170677175, -7.004432309082107],
            [110.397625551762602, -7.004530996807136],
            [110.39740016373986, -7.004635600587389],
            [110.3971745504235, -7.004734811615765],
            [110.396844103915697, -7.005005770710668],
            [110.396813423259687, -7.005050940059249],
            [110.396541969072985, -7.005376228753645],
            [110.396361342960816, -7.005692588407601],
            [110.396296546670072, -7.005918642003173],
            [110.396264061224556, -7.006099509719453],
            [110.39622494060167, -7.006379869093825],
            [110.396218402158809, -7.006624088422024],
            [110.396211662250494, -7.006804989189983],
            [110.396211487668026, -7.006940671033321],
            [110.396191803291742, -7.007067282344443],
            [110.396100954806542, -7.007311393352405],
            [110.396062303128545, -7.007447025753809],
            [110.396081250591521, -7.007673186865839],
            [110.396145092679589, -7.008188860717723],
            [110.396260318039538, -7.008568918516208],
            [110.396317967480556, -7.008840356691223],
            [110.396395371268582, -7.008930910822583],
            [110.39644679295526, -7.008985249691526],
            [110.396453218660938, -7.009048576239418],
            [110.396407718607961, -7.009229427213158],
            [110.396317140995947, -7.009482584033232],
            [110.396239600922556, -7.009717666636742],
            [110.39622647012871, -7.00980810446585],
            [110.396213517453859, -7.009979951752796],
            [110.396180984981527, -7.010197001225132],
            [110.396161629888582, -7.010287431049493],
            [110.396083864887359, -7.010477285964562],
            [110.395903073101977, -7.010920281538302],
            [110.395534225723267, -7.011733899273416],
            [110.395411312284011, -7.012050332476085],
            [110.39514003349629, -7.012674120487597],
            [110.395036617827131, -7.013008669579637],
            [110.395010119248852, -7.013153362917203],
            [110.394964959408654, -7.013288986744259],
            [110.394615772706771, -7.014211174302567],
            [110.394350234103541, -7.014988741913419],
            [110.394175522653256, -7.015540289871391],
            [110.39403115175179, -7.015920013060795],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 73,
        id: 75,
        area: 1416280.2151269999,
        perimeter: 6234.142008,
        kode_pos: 50255,
        kode_kec: 3374060,
        kode_kel: 3374051002,
        kec: "Candisari",
        kel: "Karanganyar Gunung",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.427193183411134, -7.017653180576539],
            [110.427881436686931, -7.017047945919033],
            [110.428811395541103, -7.016370776722887],
            [110.42933606268177, -7.01627180185964],
            [110.429597302947215, -7.016190709659142],
            [110.429907129452246, -7.01617299495391],
            [110.430363000993239, -7.016110229662525],
            [110.430655645868271, -7.016029174954594],
            [110.43078477018922, -7.015938876305217],
            [110.430870933599223, -7.015812343449706],
            [110.430896965482489, -7.015577191418701],
            [110.4309492268208, -7.014944068166416],
            [110.431046043728827, -7.014410499621938],
            [110.431367852042797, -7.014564663321853],
            [110.431584702396975, -7.014691563203437],
            [110.431828724711139, -7.014809450348378],
            [110.43195511578574, -7.0148729219376],
            [110.432176548062628, -7.014954599487163],
            [110.432411538960011, -7.015054384378346],
            [110.432642025048537, -7.015136072665213],
            [110.432777763841571, -7.015190509817327],
            [110.432931294178246, -7.015272104997927],
            [110.433048914835794, -7.015335565720555],
            [110.433134564869619, -7.015398987818066],
            [110.433184283298303, -7.015462366525107],
            [110.433193238480882, -7.015543787071358],
            [110.433211302004281, -7.015579990982435],
            [110.433292697271696, -7.015652453445546],
            [110.433369282816074, -7.015724910091556],
            [110.433463681814274, -7.015806433748926],
            [110.433562063682601, -7.015869871141851],
            [110.433649400512849, -7.015942340719012],
            [110.433703939749407, -7.015996679668371],
            [110.433714930163958, -7.016032875032069],
            [110.433670846985933, -7.016222777916253],
            [110.433615979749092, -7.016439804360704],
            [110.433566433394802, -7.016702064844417],
            [110.433573158037234, -7.016756346116082],
            [110.433599121719766, -7.016810650601451],
            [110.433640102409299, -7.016846882151366],
            [110.433686480584413, -7.016865029156972],
            [110.433755797719954, -7.016865112776131],
            [110.433806735525465, -7.016856128690711],
            [110.433959810006158, -7.016847267768746],
            [110.434167511258821, -7.016820381590898],
            [110.434320618489963, -7.016784384007142],
            [110.434544685814018, -7.016793699490728],
            [110.434714180962644, -7.016775812571404],
            [110.434861912537031, -7.016739808333201],
            [110.434964952525775, -7.016694704727342],
            [110.435138985370926, -7.016667777583543],
            [110.435244593468042, -7.016604585945225],
            [110.435381323504515, -7.016541431713248],
            [110.435647438268433, -7.016406068760967],
            [110.435972379894721, -7.016288867385243],
            [110.436260268845984, -7.016162575749197],
            [110.43649437982306, -7.016054310440076],
            [110.436777676456742, -7.01598228613962],
            [110.437027598535224, -7.015901176110285],
            [110.437126688053894, -7.015847021728322],
            [110.437182239442407, -7.015765678536828],
            [110.437257617391907, -7.015666268036897],
            [110.4373290017584, -7.015593989333159],
            [110.437507364872047, -7.01549470219514],
            [110.437650068335344, -7.015404417807355],
            [110.437820487677783, -7.015323212090787],
            [110.437887845847712, -7.015305201669633],
            [110.437939284204404, -7.015350490915412],
            [110.437978839662122, -7.015395765932434],
            [110.437998557736748, -7.015468153802567],
            [110.438034108854112, -7.015549606156727],
            [110.438085525560552, -7.015612986430392],
            [110.438144885913502, -7.015658285138397],
            [110.438212211595001, -7.015667411236012],
            [110.438291431038124, -7.015667506020102],
            [110.438406299232568, -7.015667643433291],
            [110.438434047715802, -7.015649585555617],
            [110.438518424859382, -7.015595413265057],
            [110.438586370574697, -7.015559312378864],
            [110.438672674001978, -7.015550370033324],
            [110.438708039818621, -7.015550412313916],
            [110.438747116177794, -7.015523322423747],
            [110.438764973044712, -7.015496207165626],
            [110.438842799494907, -7.015478209119834],
            [110.438917198335048, -7.015487343569136],
            [110.438945741426593, -7.015514514282691],
            [110.439009367412766, -7.015541726914513],
            [110.439193803050529, -7.015569083855153],
            [110.439204238862544, -7.015596232925832],
            [110.439441821465394, -7.015659835391633],
            [110.439689894058048, -7.015705359189316],
            [110.439705716323445, -7.015723469143518],
            [110.439894875363024, -7.015823195743736],
            [110.439956466919469, -7.015895633508639],
            [110.440141861587335, -7.016067719819523],
            [110.440249766800292, -7.016212577071439],
            [110.440373265475912, -7.016330316281516],
            [110.44049674261359, -7.016466146511397],
            [110.440550706110201, -7.016529529581842],
            [110.440620230620283, -7.016592931189567],
            [110.440806571741248, -7.016683608566238],
            [110.440934422515312, -7.016710897457233],
            [110.441154572355629, -7.016684022976397],
            [110.441462647888741, -7.016711526241875],
            [110.441748382985693, -7.016729957202464],
            [110.442041986232738, -7.016793625038131],
            [110.442242767820375, -7.01687527349594],
            [110.442389596433387, -7.016884493470536],
            [110.44252876448931, -7.016911795381747],
            [110.442621687140075, -7.016930400875795],
            [110.442863202366098, -7.016978872867215],
            [110.442786178953313, -7.01716613944845],
            [110.442697112781914, -7.01738236335185],
            [110.442440492585831, -7.017861472183553],
            [110.442134445540489, -7.018268157767023],
            [110.441886613840722, -7.018494001575095],
            [110.441456664689369, -7.018882448192057],
            [110.441043440017026, -7.01924377766465],
            [110.440646624600333, -7.019605126278752],
            [110.44015240521999, -7.020029677042213],
            [110.440051857172932, -7.020120012410282],
            [110.439434344383898, -7.020490141960294],
            [110.439068353973312, -7.020625387543808],
            [110.438649183433739, -7.020751523666164],
            [110.438344979802409, -7.020796387254554],
            [110.437612007301098, -7.020949283240007],
            [110.437255080731646, -7.021075492671849],
            [110.436607389142097, -7.02136417224975],
            [110.436161393289737, -7.021680229919886],
            [110.435633945097322, -7.022204236254149],
            [110.43496732566868, -7.022936121357126],
            [110.434329617776839, -7.023622812575291],
            [110.433895598365069, -7.024092656124028],
            [110.433369732990684, -7.024707116584835],
            [110.43326167886876, -7.024854846236972],
            [110.433105319381497, -7.025068617891074],
            [110.432891146715534, -7.025529680522326],
            [110.432571858533905, -7.026578574673275],
            [110.432252448003055, -7.027726969019906],
            [110.431908313246566, -7.02873060469292],
            [110.43155723541409, -7.029625685312331],
            [110.431470448489762, -7.029797444867478],
            [110.431385098166999, -7.029951115107856],
            [110.431190075931596, -7.030249380482774],
            [110.430935002793916, -7.030460000176916],
            [110.430607579167699, -7.0306930563617],
            [110.430227660981302, -7.030858930438113],
            [110.429944256437778, -7.030922785527783],
            [110.429611218055172, -7.030969070969205],
            [110.429213955663258, -7.030986096187172],
            [110.428682314503916, -7.031017557721469],
            [110.428159631101792, -7.031095889711698],
            [110.428167492399012, -7.030912904860056],
            [110.428181170266399, -7.03085176741215],
            [110.428257934196665, -7.030779496994871],
            [110.428342609231123, -7.030716281728543],
            [110.428677193346559, -7.030590052660405],
            [110.428872540104862, -7.030490790174716],
            [110.428939978062672, -7.030409462749338],
            [110.428970689671146, -7.030282862983671],
            [110.428952530838046, -7.030092885057399],
            [110.428800397803514, -7.029559014299224],
            [110.428581607462846, -7.028699423729995],
            [110.428447876924707, -7.028156529817543],
            [110.428350468208166, -7.02775840840399],
            [110.428308032646456, -7.027523173281702],
            [110.428247681763878, -7.026898959195857],
            [110.428241950182468, -7.026727087434213],
            [110.428235058148061, -7.026347167425448],
            [110.428239434764123, -7.026238626589885],
            [110.428239633702049, -7.026075807571496],
            [110.428255581782579, -7.02575923400947],
            [110.428293324301393, -7.024972320239348],
            [110.428337107565682, -7.024104004155204],
            [110.428356050405142, -7.023651751469261],
            [110.428307993840136, -7.023154189529069],
            [110.428277713494268, -7.022928014724831],
            [110.428260136163217, -7.022493808537943],
            [110.428247952336704, -7.022276701303872],
            [110.42818750900382, -7.021960034566548],
            [110.428041647399084, -7.021389989239139],
            [110.427852703502964, -7.020883209955483],
            [110.427669995467355, -7.020367392708328],
            [110.427639943162049, -7.020186445732759],
            [110.42765797769647, -7.020014602929468],
            [110.427770863989096, -7.019553419285843],
            [110.427908119282009, -7.018829945311807],
            [110.427965418580811, -7.018477239998242],
            [110.427942972132669, -7.018323438849561],
            [110.427897575422833, -7.018196746276227],
            [110.427846464950264, -7.018115274319301],
            [110.427698898318383, -7.018015593719912],
            [110.42752274502979, -7.017924923728644],
            [110.427358213946619, -7.017816176823507],
            [110.4272618462998, -7.01772560407885],
            [110.427193183411134, -7.017653180576539],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 74,
        id: 76,
        area: 5754659.5518619996,
        perimeter: 13003.877399000001,
        kode_pos: 50273,
        kode_kec: 3374070,
        kode_kel: 3374052007,
        kec: "Tembalang",
        kel: "Sendang Mulyo",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.471620712283169, -7.05036869461181],
            [110.471516246445375, -7.050169724788129],
            [110.471348857569268, -7.049816758234635],
            [110.471156003557809, -7.049463762816197],
            [110.471044716731768, -7.049291771047912],
            [110.470850114147751, -7.048984001393562],
            [110.470372490816501, -7.04824172373988],
            [110.470306404285225, -7.048133102025685],
            [110.469753076132662, -7.047282191229839],
            [110.469292078007172, -7.04660325050492],
            [110.468988949668017, -7.046186810190411],
            [110.468652171854458, -7.045752240337209],
            [110.468370852061753, -7.045317733339171],
            [110.468289790861448, -7.045191003231549],
            [110.468203960855377, -7.04502808542229],
            [110.468179102859892, -7.044991874883739],
            [110.468150304244944, -7.044937568728793],
            [110.467740168572561, -7.044322003556348],
            [110.467383710911861, -7.043778863750704],
            [110.466941228559037, -7.043244671250185],
            [110.466683633824914, -7.042602142455594],
            [110.466355624284276, -7.041679120709788],
            [110.466051244994844, -7.040629487849722],
            [110.465868349540543, -7.039987043967892],
            [110.465328978424921, -7.039317056179725],
            [110.46521534959858, -7.039217425128144],
            [110.464980735601415, -7.039018154541613],
            [110.464408262689744, -7.038348128000927],
            [110.464233695300464, -7.038094652419171],
            [110.463825875432889, -7.037442904863157],
            [110.463533813027425, -7.037008383202907],
            [110.463397874386104, -7.036872543951401],
            [110.463049844787506, -7.036636960254744],
            [110.462542938368543, -7.036283601648361],
            [110.462053847542549, -7.035939308577061],
            [110.461617332143746, -7.035640303306609],
            [110.460954360095755, -7.035187262490139],
            [110.460808771504205, -7.035078548141959],
            [110.460635328574512, -7.034834118274743],
            [110.460338240496014, -7.034345315771798],
            [110.460053912154621, -7.034082666806167],
            [110.460009820772456, -7.034037388171996],
            [110.459370064023787, -7.033575326577476],
            [110.45867521943488, -7.033040836069183],
            [110.458321578355211, -7.032769060498346],
            [110.458293640872782, -7.032705709289238],
            [110.458321808924879, -7.032570058509164],
            [110.458463403419756, -7.031973215443317],
            [110.458605427055801, -7.031249734976133],
            [110.458827133600437, -7.030616802040653],
            [110.458963361757426, -7.030255137017025],
            [110.459043610171776, -7.029857225071646],
            [110.45913347772256, -7.029215094090696],
            [110.459299464388721, -7.02831977519938],
            [110.459389446831679, -7.027822373179814],
            [110.459513222025819, -7.026728003060313],
            [110.459589664762603, -7.026194403073271],
            [110.459782832564869, -7.025525254127315],
            [110.459849435736274, -7.025181599502782],
            [110.459840307898375, -7.024928313245832],
            [110.460027085422226, -7.024964710622368],
            [110.460193135938013, -7.024992038502633],
            [110.460450259713568, -7.025046607820928],
            [110.460900637462359, -7.025092353671424],
            [110.460982991905155, -7.025074357222963],
            [110.461042501656323, -7.024993015553918],
            [110.461093806321841, -7.024911664445436],
            [110.462562775746818, -7.022959508054512],
            [110.462781814441755, -7.022670300836264],
            [110.462871859454566, -7.022607084987531],
            [110.46298786155414, -7.02260721781206],
            [110.46306508123044, -7.022625397349707],
            [110.46321917544698, -7.022716029401176],
            [110.463424984096449, -7.022860993979449],
            [110.463759140498738, -7.023096560921764],
            [110.46429915820741, -7.023431864093895],
            [110.465032023138519, -7.023866887993631],
            [110.465893572444855, -7.024347285282706],
            [110.466521091824319, -7.024619366754326],
            [110.467232452977512, -7.024810132549671],
            [110.467653041330152, -7.02492820268704],
            [110.467890057396829, -7.025000836305948],
            [110.467989557495315, -7.025082359375897],
            [110.468042929149149, -7.025172875669575],
            [110.468249527611718, -7.025372112648105],
            [110.468341157082293, -7.025408398854704],
            [110.468455956328583, -7.025471848035592],
            [110.468761612831926, -7.025644060377899],
            [110.468998655806857, -7.025942832881799],
            [110.469465025592712, -7.026359457342782],
            [110.46980097352693, -7.026766888325532],
            [110.469930867345852, -7.026993174621468],
            [110.470153949357339, -7.027129110469971],
            [110.470268759528651, -7.027183513687578],
            [110.470329832540827, -7.027219765007648],
            [110.470571578271418, -7.027364767338774],
            [110.470845398149237, -7.027419349994243],
            [110.470977205054055, -7.02745568108164],
            [110.471118781787538, -7.027609615664202],
            [110.471128834531314, -7.027727219513785],
            [110.471209944983741, -7.027808721224811],
            [110.471331545910488, -7.027863131863053],
            [110.471554499101103, -7.027863383285963],
            [110.471808101249522, -7.027782258937464],
            [110.472102295772686, -7.027583587639532],
            [110.47245001581733, -7.027339748484153],
            [110.472795472028338, -7.027095906495569],
            [110.473199161575906, -7.026897357572408],
            [110.473730403580717, -7.026744179393933],
            [110.474036640879959, -7.026654067268186],
            [110.47447775824979, -7.026383194502497],
            [110.474823182317024, -7.026166487594157],
            [110.475033977823529, -7.025940100390495],
            [110.47519551638517, -7.025669397428048],
            [110.475373607983627, -7.025417236009177],
            [110.475564028236477, -7.025216412962476],
            [110.475707505436588, -7.025089893148458],
            [110.475900540004275, -7.025015753139747],
            [110.476151583183096, -7.024831521092164],
            [110.47621790122092, -7.024685637460355],
            [110.47652924707414, -7.024804403663776],
            [110.476628676207028, -7.024628263814039],
            [110.476683989844389, -7.024463090384128],
            [110.477024961244837, -7.024057958637657],
            [110.477126764817115, -7.024125952353406],
            [110.47728131799164, -7.024187066198403],
            [110.477400418690891, -7.024238219770695],
            [110.477482605634179, -7.024317677090531],
            [110.477680803259489, -7.02467504371283],
            [110.477917214972123, -7.025110401312312],
            [110.477976645333896, -7.025243688544037],
            [110.478017588771394, -7.025418055304088],
            [110.478071259652467, -7.025627867363399],
            [110.478144783936386, -7.025843370498433],
            [110.478260874246374, -7.026050417525006],
            [110.478457807794882, -7.026270309680211],
            [110.478607956193969, -7.026466056560374],
            [110.478728291453976, -7.026683028931079],
            [110.47882445618275, -7.026949577994984],
            [110.478885155604601, -7.027217504863557],
            [110.478913261491883, -7.027455633339587],
            [110.478945460484951, -7.027839742640468],
            [110.4789592987796, -7.028151552010776],
            [110.478985776233372, -7.028578172303788],
            [110.479011000889841, -7.028855980436659],
            [110.479039163702382, -7.029043088131229],
            [110.479111281352402, -7.02924866887364],
            [110.479241520571591, -7.029489745336127],
            [110.479368960658817, -7.029696804770501],
            [110.479490770772145, -7.029864175051473],
            [110.479514809032182, -7.029933646778027],
            [110.479506098931125, -7.030112210022351],
            [110.479502990512259, -7.030355972774699],
            [110.479525532944407, -7.030494887862711],
            [110.47962600649744, -7.03071467249814],
            [110.479705228476291, -7.03091034034507],
            [110.479760248189578, -7.031183929802999],
            [110.479850503512367, -7.031663058959387],
            [110.479947929069013, -7.03207275103966],
            [110.48007379235365, -7.032422950365852],
            [110.480186950133415, -7.032717862996551],
            [110.480500792295061, -7.033690441548056],
            [110.480591247115697, -7.03399099790322],
            [110.480650515436196, -7.034271678223963],
            [110.480664383091835, -7.034557977171056],
            [110.480658497766385, -7.034747881523254],
            [110.480631376299556, -7.034899496694869],
            [110.480642539074097, -7.035066744026931],
            [110.480696293833631, -7.035202859200822],
            [110.480771369809148, -7.03530073238999],
            [110.480921537594497, -7.035482306301634],
            [110.48104622457906, -7.035615665586313],
            [110.481193590025896, -7.035766056917898],
            [110.481257326993557, -7.035855414003126],
            [110.481291253350548, -7.035963162244626],
            [110.481291133788673, -7.036070872764474],
            [110.481239920140098, -7.036201202597069],
            [110.481076460685628, -7.036491556741349],
            [110.481011001284074, -7.036675726069823],
            [110.481019355360743, -7.036817459863606],
            [110.481078816210527, -7.036925236425571],
            [110.481220548630901, -7.037038773147303],
            [110.481366538445968, -7.037150897302144],
            [110.48165575659533, -7.037307114650411],
            [110.481925099042812, -7.03747748228948],
            [110.482066803563939, -7.037616529167281],
            [110.482127666057707, -7.037739896868607],
            [110.482141730162809, -7.037849748955762],
            [110.482099027246818, -7.037980796914753],
            [110.481960432170837, -7.038235747716887],
            [110.48182718218824, -7.038467674144637],
            [110.48163182753153, -7.038736734428218],
            [110.481528751751938, -7.038942120816638],
            [110.481466470170162, -7.03913869459472],
            [110.481388380848827, -7.039198840985101],
            [110.481210899678672, -7.039340368784156],
            [110.48114692928641, -7.039460763701954],
            [110.481139659208154, -7.039620195723836],
            [110.481205066366726, -7.039802738561479],
            [110.481399901442799, -7.040002254644441],
            [110.481807471603574, -7.040250724111696],
            [110.482219494963402, -7.040481482613623],
            [110.482467655686023, -7.040565906119601],
            [110.482930294498956, -7.040626761284114],
            [110.483546735421683, -7.04066204285921],
            [110.484034972413909, -7.040658711307881],
            [110.48403903022502, -7.040847783847224],
            [110.484027692624721, -7.041025854942343],
            [110.483984104931537, -7.041246618587251],
            [110.48386336073456, -7.041436442784625],
            [110.483651875415731, -7.041696963835314],
            [110.483503129701404, -7.041878593283092],
            [110.483398881604515, -7.042119633008489],
            [110.483424729155217, -7.042253224232569],
            [110.483524840924247, -7.042394317608887],
            [110.483701690974925, -7.042557917506049],
            [110.484162984342134, -7.042730292662224],
            [110.484704281684316, -7.042966074668777],
            [110.485015212137242, -7.043246830443889],
            [110.485145007879112, -7.04357261462263],
            [110.48515497442817, -7.043771628596743],
            [110.485044525783792, -7.043861962901886],
            [110.484823758159195, -7.04392503889082],
            [110.484362273492138, -7.043924530289482],
            [110.483850777469414, -7.043860646914771],
            [110.483309016449184, -7.043787683965482],
            [110.48280772689138, -7.043705719571167],
            [110.482396354052725, -7.043678127698877],
            [110.482276041979588, -7.043732268049157],
            [110.481914539712179, -7.043894688274404],
            [110.481653685545965, -7.044129584458468],
            [110.481593136770996, -7.044382793744995],
            [110.481653173206752, -7.044590908770853],
            [110.481793313608179, -7.044771975812913],
            [110.482003675009807, -7.044907892684257],
            [110.482280488806524, -7.045080065396796],
            [110.482432876555961, -7.045188781139021],
            [110.482604787692509, -7.045297518440401],
            [110.48271027663553, -7.045342863093405],
            [110.482820009800221, -7.045388212418606],
            [110.482941100944785, -7.045397391930886],
            [110.483128977529873, -7.045397599657765],
            [110.48340655823128, -7.045388860840446],
            [110.483668576866776, -7.04538010467266],
            [110.483887587606617, -7.045371300885949],
            [110.484055920885908, -7.045389577844404],
            [110.48420044666581, -7.045444010836279],
            [110.484286362512222, -7.045534561499399],
            [110.484302007606274, -7.045715490537453],
            [110.484274391804604, -7.045869235089308],
            [110.484191954237502, -7.045959600035257],
            [110.484027764929081, -7.046031783570181],
            [110.483832218472457, -7.046058704483169],
            [110.483581810293188, -7.046058427952344],
            [110.483358837975373, -7.046067227194227],
            [110.483217930328706, -7.046067071460692],
            [110.483140044815073, -7.046102057175512],
            [110.483098877659828, -7.046122564043602],
            [110.483045906168627, -7.046187098673083],
            [110.482995860535638, -7.046263380748877],
            [110.482954540446968, -7.04642188199277],
            [110.482960291334209, -7.046536394476253],
            [110.482989585862114, -7.046621572453559],
            [110.483065928641338, -7.046683314021736],
            [110.483151130389189, -7.046706896664999],
            [110.483262830922129, -7.046686467766928],
            [110.483506619442466, -7.046601275428777],
            [110.483682112697977, -7.046523882870729],
            [110.48382811562017, -7.046475887013278],
            [110.483884336817795, -7.046490663750714],
            [110.483937851933163, -7.046530853735068],
            [110.483993996213727, -7.046615190613165],
            [110.484039410857307, -7.046715568006053],
            [110.484063379514623, -7.046835987179925],
            [110.484011405434444, -7.047008689056019],
            [110.483914731605154, -7.047168700891285],
            [110.483749974969371, -7.047330001971766],
            [110.483614577909464, -7.047517759911933],
            [110.483529128953364, -7.047717317214741],
            [110.483446537537432, -7.047990279014937],
            [110.483390448127366, -7.048216293214209],
            [110.483375610560046, -7.048345463207299],
            [110.483396046284454, -7.048468800080732],
            [110.483516040604613, -7.048565639543028],
            [110.483677225410716, -7.048633650789637],
            [110.483876636249903, -7.048672027198267],
            [110.484148166296023, -7.048731680993431],
            [110.484436647728003, -7.048812551197023],
            [110.484627487039859, -7.048927230139194],
            [110.48487767719125, -7.049097088875053],
            [110.485115108382345, -7.049292370866042],
            [110.485326998095303, -7.049559697352758],
            [110.485500746247894, -7.049780346463974],
            [110.485712743927891, -7.04995016274745],
            [110.485814396293065, -7.050128336675473],
            [110.485860866626467, -7.050319168586975],
            [110.485886090445163, -7.05053541455372],
            [110.485907019162724, -7.05079829109289],
            [110.485933224889038, -7.051073828142309],
            [110.485970952210906, -7.051367614907801],
            [110.486012165434829, -7.051637214719965],
            [110.486046452672682, -7.051913718561756],
            [110.486008184148616, -7.052130692771891],
            [110.485882920357668, -7.05231146669913],
            [110.485817585080056, -7.05242147317012],
            [110.48571368692194, -7.052531945228323],
            [110.485623648420955, -7.052621697565388],
            [110.485582008282236, -7.052739149796029],
            [110.485529948541839, -7.05289460463077],
            [110.485488247321527, -7.053067349997079],
            [110.485481172509694, -7.053209031068977],
            [110.485460302803389, -7.05331268285676],
            [110.485387563973205, -7.053398998363825],
            [110.48529066404852, -7.053436905669011],
            [110.485207096618339, -7.053459512004282],
            [110.485136621674513, -7.053477525492835],
            [110.484956694405881, -7.053450190345729],
            [110.484640385584413, -7.053387807012133],
            [110.484391356729859, -7.053356429727273],
            [110.484170062796252, -7.053269789740136],
            [110.484031832071253, -7.053145227345199],
            [110.483855573081854, -7.052999887940738],
            [110.483807274269438, -7.052882336510407],
            [110.483814318963411, -7.052768302065886],
            [110.483893972546937, -7.05268545028096],
            [110.484091254398081, -7.052588905114325],
            [110.484336976512125, -7.052482045860262],
            [110.484440813814402, -7.052426867272136],
            [110.484638034331667, -7.052385615000328],
            [110.48477650181502, -7.052295916281754],
            [110.484890758641541, -7.052202734994991],
            [110.484939317297204, -7.052085290460814],
            [110.485022452674343, -7.051981707341914],
            [110.485095183694568, -7.051902303518892],
            [110.485109104462325, -7.051826290691311],
            [110.485066846607978, -7.05172204324988],
            [110.485067712373819, -7.051719114461943],
            [110.484998658162226, -7.051601540257801],
            [110.484921674652696, -7.051519323022423],
            [110.484742921862846, -7.051380085408969],
            [110.484549306154705, -7.051290020356571],
            [110.484251964350889, -7.05115491499966],
            [110.484027208444743, -7.051071727017113],
            [110.483868123753624, -7.051036993041818],
            [110.483622532456749, -7.051026354186876],
            [110.483376918207227, -7.051036450129338],
            [110.483183176542269, -7.051060426626887],
            [110.482984163687689, -7.051158697445969],
            [110.482828388777051, -7.051258743952618],
            [110.482759132035085, -7.051324327951761],
            [110.482710618948275, -7.051400302382707],
            [110.482656030878445, -7.051526932989834],
            [110.482634221364208, -7.051666316286251],
            [110.482623744064256, -7.051756156124444],
            [110.482632470748101, -7.05185823590535],
            [110.482662530998098, -7.051939363708271],
            [110.482777018288814, -7.052142123062564],
            [110.482855355579218, -7.052299079099706],
            [110.48290840546413, -7.052407894274709],
            [110.482963630077506, -7.052518541797371],
            [110.482968376175762, -7.052624603417323],
            [110.482942675535924, -7.05269822148759],
            [110.482892195714939, -7.052722759295091],
            [110.482825374491526, -7.05274678196803],
            [110.482712188763415, -7.052752217397522],
            [110.482563777132242, -7.052733517123886],
            [110.482413549216034, -7.052679596605823],
            [110.482248492532477, -7.052612684484402],
            [110.48205184670681, -7.052588369819156],
            [110.481881125027769, -7.052610423543182],
            [110.481740058856232, -7.052660313936349],
            [110.481591509210404, -7.0527658036331],
            [110.481457808906129, -7.052865748997754],
            [110.481270279169195, -7.052986024011791],
            [110.481023310389361, -7.053165547725959],
            [110.480900450700219, -7.053325204295918],
            [110.480753604099036, -7.05344767823984],
            [110.480711210227071, -7.053517382581684],
            [110.480709616616707, -7.053586419314865],
            [110.48070725367478, -7.05373795517198],
            [110.480690248489367, -7.053878333490608],
            [110.480679095794187, -7.053963563643046],
            [110.480311318691463, -7.053854636315669],
            [110.480042290270774, -7.053799474434596],
            [110.479869288806114, -7.053812997397915],
            [110.479957244151137, -7.053426559138551],
            [110.479643562136573, -7.053326708378489],
            [110.478938366758058, -7.05314501058814],
            [110.478218539547314, -7.052890930803356],
            [110.477849965865204, -7.052791017566245],
            [110.477152998042712, -7.052591235229356],
            [110.476832506197638, -7.052509466191182],
            [110.476525020052364, -7.052436757091897],
            [110.475862654777771, -7.052164647083776],
            [110.475556048431415, -7.052064801637038],
            [110.474660219869335, -7.051819564562759],
            [110.474143170599646, -7.05165616251234],
            [110.473685500374415, -7.05152900910332],
            [110.473591046151228, -7.051483674821312],
            [110.473445824409254, -7.051293554156853],
            [110.473180423909014, -7.050786702952077],
            [110.473051593159653, -7.050614691811679],
            [110.472953177895434, -7.050569352949614],
            [110.472402074470793, -7.050496366399176],
            [110.472163599430317, -7.050450869201716],
            [110.471620712283169, -7.05036869461181],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 75,
        id: 77,
        area: 1884584.6466959999,
        perimeter: 8290.97526,
        kode_pos: 50276,
        kode_kec: 3374070,
        kode_kel: 3374052008,
        kec: "Tembalang",
        kel: "Sambiroto",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.459840307898375, -7.024928313245832],
            [110.459849435736274, -7.025181599502782],
            [110.459782832564869, -7.025525254127315],
            [110.459589664762603, -7.026194403073271],
            [110.459513222025819, -7.026728003060313],
            [110.459389446831679, -7.027822373179814],
            [110.459299464388721, -7.02831977519938],
            [110.45913347772256, -7.029215094090696],
            [110.459043610171776, -7.029857225071646],
            [110.458963361757426, -7.030255137017025],
            [110.458827133600437, -7.030616802040653],
            [110.458605427055801, -7.031249734976133],
            [110.458463403419756, -7.031973215443317],
            [110.458321808924879, -7.032570058509164],
            [110.458293640872782, -7.032705709289238],
            [110.458321578355211, -7.032769060498346],
            [110.45867521943488, -7.033040836069183],
            [110.459370064023787, -7.033575326577476],
            [110.460009820772456, -7.034037388171996],
            [110.460053912154621, -7.034082666806167],
            [110.460033483131156, -7.034127871046332],
            [110.459939201809064, -7.034182035679568],
            [110.459891060401915, -7.034218162392962],
            [110.459872061689111, -7.034254322715141],
            [110.459852843452055, -7.034480439514844],
            [110.459810330087208, -7.034787939463321],
            [110.459757620378141, -7.035104473193677],
            [110.459608260312123, -7.035321394231367],
            [110.459398120407116, -7.035493017247064],
            [110.459343691358399, -7.035583409973785],
            [110.459255613399279, -7.035655672715929],
            [110.459152885361888, -7.035673645173145],
            [110.459043995471049, -7.035637337145847],
            [110.458968733524642, -7.035637250182167],
            [110.458892297925075, -7.035673344077068],
            [110.45870998000872, -7.035763588896217],
            [110.458509050891536, -7.035799538776894],
            [110.458464912294914, -7.035799487725505],
            [110.458331197142243, -7.035699831927951],
            [110.458293566168336, -7.03569978838995],
            [110.458274588239803, -7.035717857543569],
            [110.458232660735973, -7.035763036808119],
            [110.458169355422328, -7.035943874662808],
            [110.458158582477054, -7.036206183305761],
            [110.458139531096094, -7.036287571254697],
            [110.458055665454154, -7.036386975286275],
            [110.457947707892643, -7.036522533625127],
            [110.45790766641295, -7.036649125029792],
            [110.45786995140341, -7.036721445793793],
            [110.457786190592742, -7.036730394339147],
            [110.457258517552134, -7.036720737303827],
            [110.456966670427676, -7.036593761098556],
            [110.456661012002556, -7.036421540927],
            [110.456406042145971, -7.036213197218835],
            [110.456364753971727, -7.036195058170433],
            [110.456232840840386, -7.036249178288703],
            [110.456151343625322, -7.036258129174878],
            [110.456063433556309, -7.036185662623942],
            [110.456017314362285, -7.036185609037863],
            [110.455726148202686, -7.036203361729724],
            [110.455678069504529, -7.036185214728262],
            [110.455638227677795, -7.036139940644214],
            [110.455596468510194, -7.036040391016903],
            [110.455558858603965, -7.036022256177985],
            [110.455406668662846, -7.03599494251598],
            [110.455190817317458, -7.035967554728412],
            [110.455141302875688, -7.035967497106655],
            [110.455118091275708, -7.035976515643807],
            [110.455097666880178, -7.036021719628117],
            [110.455097614140556, -7.036066947322138],
            [110.455091640762049, -7.036094077022867],
            [110.455065316817624, -7.036103091934283],
            [110.455009850078227, -7.036112072920107],
            [110.454867258999769, -7.036102861358327],
            [110.454706863838879, -7.036075537915802],
            [110.454593153749371, -7.036048268808894],
            [110.454505735945489, -7.036039121411116],
            [110.454397642427509, -7.036048041002609],
            [110.454310214067164, -7.036047939106234],
            [110.454231577967263, -7.03602975634353],
            [110.454147000141944, -7.036011566640525],
            [110.454094666875008, -7.036002460071557],
            [110.4540655240924, -7.036002426089273],
            [110.454042029534321, -7.036011444241391],
            [110.454011429777509, -7.036047590754622],
            [110.454000069914258, -7.036083759704279],
            [110.453977968910024, -7.036110870576803],
            [110.453948239101251, -7.036128927000785],
            [110.453914852215888, -7.036128888058335],
            [110.453829729655368, -7.036092606563864],
            [110.453726194882577, -7.036074394669483],
            [110.453552176414036, -7.036083237136072],
            [110.453500398456342, -7.036083176697844],
            [110.453474650947484, -7.03608314664173],
            [110.453407813704359, -7.036137341904734],
            [110.453311561298264, -7.036182457260323],
            [110.453196592512342, -7.036263732927379],
            [110.45314845041041, -7.036299858887846],
            [110.453115325283676, -7.036317911289341],
            [110.453074571367651, -7.036326909227911],
            [110.453037410961173, -7.036408275744706],
            [110.453018966928269, -7.036453481934561],
            [110.452978181230208, -7.036489616469916],
            [110.452955818389682, -7.036498635886282],
            [110.452896652180485, -7.036525703387228],
            [110.452785350866876, -7.036616028773773],
            [110.452637288504604, -7.036688220054086],
            [110.452496561341135, -7.03677851095689],
            [110.4524187104292, -7.036814602085694],
            [110.452337474827786, -7.036841643694053],
            [110.452278025603761, -7.036868710779269],
            [110.452244596257145, -7.036904853850253],
            [110.452222495114924, -7.036931964628092],
            [110.452204061586443, -7.036968125243084],
            [110.452177978064867, -7.037013322450865],
            [110.452151915750989, -7.037040428588809],
            [110.452129814596731, -7.037067539361205],
            [110.452126104553187, -7.037094671657899],
            [110.452133429177763, -7.037121816869631],
            [110.452137075579984, -7.037148957776309],
            [110.452114638441913, -7.03722129588544],
            [110.451998105960172, -7.037429207033253],
            [110.451953631262569, -7.037474382692362],
            [110.451904357180638, -7.037510507180833],
            [110.45185054543073, -7.037555671895279],
            [110.451828161292894, -7.037582782318253],
            [110.451787930672012, -7.037627962929333],
            [110.451756764755586, -7.037664108608278],
            [110.45173863533067, -7.03768217846405],
            [110.451685148951796, -7.037691161355842],
            [110.451537736929239, -7.037690988646381],
            [110.451430502466025, -7.037690862980198],
            [110.451345577814195, -7.037726945620757],
            [110.45127845715713, -7.037781140207012],
            [110.451215821031226, -7.037871522219082],
            [110.45117980255813, -7.037943844343402],
            [110.451121619045708, -7.038097550371388],
            [110.451081388313014, -7.038142730912027],
            [110.451036651779006, -7.03816981507503],
            [110.450929395951192, -7.038187780348854],
            [110.450866823499894, -7.038223889113987],
            [110.450826603366409, -7.038260024098261],
            [110.450808724921089, -7.038305230839268],
            [110.450781813633114, -7.038332335891671],
            [110.450741593483642, -7.038368470867241],
            [110.450705617441955, -7.038404610819991],
            [110.450687749615639, -7.038440772023575],
            [110.450665344093366, -7.038485973443691],
            [110.450625102644906, -7.038540199469408],
            [110.450575828352271, -7.038576323799714],
            [110.45054470483916, -7.038576287260679],
            [110.450513298385147, -7.038576250387373],
            [110.450459843814556, -7.03855809653666],
            [110.450400436844902, -7.038548981233272],
            [110.450324283138542, -7.038585073969231],
            [110.450289123937338, -7.03864835147431],
            [110.450236107498739, -7.038738744627701],
            [110.450189064744706, -7.038802008162931],
            [110.450165538038974, -7.038838162693458],
            [110.450141979384867, -7.038901453813017],
            [110.450106798834838, -7.038982822359982],
            [110.450071607621823, -7.039073236433077],
            [110.450059606935326, -7.039172723297917],
            [110.450041894159796, -7.039317431160661],
            [110.450024277231478, -7.039380729252803],
            [110.449994798057404, -7.039425922317055],
            [110.449883201905962, -7.03952529210237],
            [110.449783521224489, -7.039597539242102],
            [110.449742484034161, -7.039606536529461],
            [110.449654489193023, -7.039606433046553],
            [110.449513658903271, -7.03954294860362],
            [110.449384701534456, -7.039488523632953],
            [110.44929672805182, -7.039470329027162],
            [110.449220616786221, -7.039470239449626],
            [110.449109084488299, -7.039515335866372],
            [110.44908808279763, -7.039569584386844],
            [110.449083198102571, -7.039632897422003],
            [110.449087890741637, -7.039732403895903],
            [110.449072996380195, -7.039886160552911],
            [110.44904853533788, -7.039994678240078],
            [110.449053238638314, -7.040085139184448],
            [110.449092471524693, -7.040166595244303],
            [110.449170739747217, -7.040257142798117],
            [110.449283243986542, -7.040347730638538],
            [110.44938613890406, -7.040429261605617],
            [110.449435306843071, -7.040483592710819],
            [110.449484202514725, -7.040528877950154],
            [110.449528309504018, -7.040556066468707],
            [110.449650748846409, -7.040619529295504],
            [110.449802843874309, -7.040728254675085],
            [110.44989582533195, -7.040818819425502],
            [110.449974083165642, -7.040918412392141],
            [110.450107146656663, -7.041090434102403],
            [110.450241256814635, -7.04133482134638],
            [110.450274558856108, -7.041407224815084],
            [110.450288033614285, -7.041497696068046],
            [110.450287905821, -7.041606242420598],
            [110.450301108291043, -7.041687667811234],
            [110.450341473287978, -7.041769125118066],
            [110.450381544707071, -7.041859627619196],
            [110.450428385479483, -7.041968229154539],
            [110.450441881570029, -7.042040609345635],
            [110.45044180703924, -7.042103928051527],
            [110.450421643540722, -7.042167223155471],
            [110.450367809791302, -7.042230478696307],
            [110.450246592923875, -7.04232983720672],
            [110.450145833154153, -7.042356855398571],
            [110.450085283288445, -7.042356784217999],
            [110.450031785694421, -7.042374812403745],
            [110.449957611920595, -7.042410907352269],
            [110.449856788165988, -7.042492198649338],
            [110.449776021376323, -7.042600650137986],
            [110.44972890349969, -7.042727232278164],
            [110.44970163429673, -7.04281765560192],
            [110.449646657936071, -7.042889955240932],
            [110.449624775299469, -7.042971339355475],
            [110.449608289914195, -7.043034638738841],
            [110.449558700108469, -7.043097899162311],
            [110.449498358426325, -7.043161146924271],
            [110.449432598121348, -7.04326057045833],
            [110.449399680617759, -7.043341939429766],
            [110.449344725483598, -7.043396150105641],
            [110.449246207648031, -7.043441261799765],
            [110.449032259346666, -7.043477191961304],
            [110.448884279675767, -7.043477017607615],
            [110.448511642547928, -7.043476578348978],
            [110.448084418665161, -7.043457983303243],
            [110.447854134459647, -7.043430574877148],
            [110.447722891327146, -7.043394237766113],
            [110.447552927740162, -7.043321672735646],
            [110.447438170354957, -7.043222036262252],
            [110.447395205745693, -7.043185803352462],
            [110.447353949513783, -7.043140526920877],
            [110.447308470554702, -7.043077154422459],
            [110.447287084649403, -7.04297762824481],
            [110.447244195047389, -7.042878076654017],
            [110.447222734186568, -7.042841869143819],
            [110.447196158924598, -7.042823746661881],
            [110.447137045170578, -7.042805585718845],
            [110.447072598351241, -7.042751236317612],
            [110.446997693357872, -7.042687829007456],
            [110.44680458239516, -7.042570008682396],
            [110.446713549704853, -7.042506582257207],
            [110.446665241460749, -7.042443206359307],
            [110.446633114596125, -7.042334621919603],
            [110.446611664528376, -7.042289368862706],
            [110.446563628539522, -7.042235038814712],
            [110.446483075562881, -7.042162579214067],
            [110.446268482747357, -7.042026642200594],
            [110.446209662809139, -7.04199943596239],
            [110.446137838236467, -7.041963168784272],
            [110.446082119954681, -7.041945011739021],
            [110.44605269389784, -7.041944976893964],
            [110.446029786259288, -7.04193590423103],
            [110.446010306134369, -7.041899699019964],
            [110.446016867482726, -7.041854479113201],
            [110.446023450294362, -7.041791168160643],
            [110.446003970175113, -7.0417549629496],
            [110.445974554863938, -7.041745882577863],
            [110.445938642605412, -7.04172774897407],
            [110.445902468876383, -7.041691523987371],
            [110.445902501080155, -7.041664387419517],
            [110.445902554752834, -7.041619159806358],
            [110.445912522151133, -7.041564898399908],
            [110.445922500280602, -7.041501591470223],
            [110.445919169373369, -7.041447314312497],
            [110.445906196952834, -7.041411116806161],
            [110.445870306191637, -7.041374892153719],
            [110.445844035480178, -7.041338678894274],
            [110.44583447984806, -7.041284394362976],
            [110.445841062670141, -7.041221083412759],
            [110.445864045412506, -7.041166837422977],
            [110.445864077616079, -7.041139700854695],
            [110.44585421757229, -7.041103507033885],
            [110.445818273160626, -7.041112509992721],
            [110.445778933439684, -7.041121508926487],
            [110.44574299976243, -7.041121466356726],
            [110.445707076822927, -7.04111237826167],
            [110.445674276988711, -7.041094248329194],
            [110.445635001700566, -7.041048974116129],
            [110.445573939865895, -7.04100367408249],
            [110.445463373895336, -7.040949269832853],
            [110.445370339623807, -7.040903931877474],
            [110.445312966828197, -7.040849590651383],
            [110.445250848504841, -7.040740970584102],
            [110.445220136773017, -7.040632387751763],
            [110.445189088378342, -7.040569032192299],
            [110.445153763620596, -7.040532808164028],
            [110.445087347501641, -7.04046941065602],
            [110.445034272666391, -7.04036984682612],
            [110.445012289200236, -7.040297456474756],
            [110.445012364447848, -7.040234137822202],
            [110.445034541396794, -7.040143708780349],
            [110.445052484959533, -7.040044229180913],
            [110.445052527953564, -7.040008047093123],
            [110.445021425872298, -7.039989919135498],
            [110.444986058169974, -7.039989877185589],
            [110.444888661744315, -7.040044034855838],
            [110.444751055316161, -7.040125281392477],
            [110.444715666094837, -7.040143330464105],
            [110.444662462299675, -7.040152312855604],
            [110.444613807084593, -7.040143209571956],
            [110.444587221323246, -7.040134132480834],
            [110.444560657079379, -7.040106964345969],
            [110.444520826857669, -7.040052643859562],
            [110.444485523710568, -7.039998328744997],
            [110.444454432414886, -7.039971155230402],
            [110.444419086238753, -7.039953022195756],
            [110.444379191477807, -7.03995297482496],
            [110.444299401956727, -7.039952880073163],
            [110.444232921454272, -7.039943755582226],
            [110.444179760738265, -7.039916555837408],
            [110.444131148625317, -7.039871270423808],
            [110.444056015395446, -7.039762634763584],
            [110.443935121085119, -7.039590625983927],
            [110.443845927048415, -7.039409609322001],
            [110.443825641451369, -7.039337220947518],
            [110.443833922142176, -7.039273912057148],
            [110.443862280877255, -7.039219672561067],
            [110.443907333128237, -7.039165452900083],
            [110.443988644891988, -7.039075094182478],
            [110.444070228799021, -7.038993781304879],
            [110.444135402104052, -7.038912448918594],
            [110.444168069476433, -7.038803941313447],
            [110.444168198640455, -7.038695395060516],
            [110.444160100994125, -7.038604930104161],
            [110.444131882268977, -7.038541577851317],
            [110.444087242227909, -7.038487251625807],
            [110.443985426656809, -7.038450948541561],
            [110.443891805612253, -7.038423700706907],
            [110.443805247324704, -7.03840550678126],
            [110.443641249511131, -7.038314856524114],
            [110.443391019587196, -7.038169830471948],
            [110.443002475142166, -7.037988457585423],
            [110.442803969725333, -7.037888720457836],
            [110.442706474039667, -7.037789103530628],
            [110.442692130416418, -7.037716722195876],
            [110.442692259903197, -7.037608175965254],
            [110.442706808537821, -7.037508692434],
            [110.442749737471232, -7.037336878430827],
            [110.442759497680612, -7.03721929813003],
            [110.442754752435206, -7.037165019287012],
            [110.442731050226143, -7.037110717876253],
            [110.442697695702719, -7.037083541570031],
            [110.442640606617871, -7.037029200405541],
            [110.44244018586997, -7.036875187684797],
            [110.442378286868362, -7.036820840763195],
            [110.442318576747354, -7.03673710567065],
            [110.442326245074469, -7.036567548715754],
            [110.442403258833863, -7.036522367952597],
            [110.442709865606219, -7.036368958995221],
            [110.442846185822532, -7.036332634465032],
            [110.443002740152025, -7.03634217094542],
            [110.443278519770715, -7.036414863248837],
            [110.443409162337545, -7.036478337326407],
            [110.443534625851214, -7.03661416948384],
            [110.443729366561257, -7.036786266075263],
            [110.443981042889106, -7.036904157021],
            [110.444221760020113, -7.036958716086736],
            [110.444287412799881, -7.036949748495516],
            [110.444408117229585, -7.036805163213609],
            [110.444688070134106, -7.036461765046318],
            [110.444936043309156, -7.036362558231469],
            [110.445209262429302, -7.036209107976929],
            [110.445350466848197, -7.035956000263088],
            [110.445368399377841, -7.035865566140048],
            [110.445293187482321, -7.035585065396745],
            [110.445136568177375, -7.035476333375404],
            [110.444867826388418, -7.035195603195439],
            [110.44477582478801, -7.034996492313039],
            [110.444772046330939, -7.034842713716781],
            [110.444841602639301, -7.034643794387466],
            [110.444933088416704, -7.034562493012185],
            [110.44532825282532, -7.034409187125902],
            [110.445555244420447, -7.034346137177779],
            [110.445683698377096, -7.034346289249457],
            [110.445883596302849, -7.034464117825885],
            [110.446013010509091, -7.034608999557603],
            [110.446181864687205, -7.034898656521104],
            [110.446372809720827, -7.035170248479802],
            [110.446532760239393, -7.035333257273171],
            [110.446595561957892, -7.035342377043988],
            [110.446673674509199, -7.035324378284074],
            [110.446761460517521, -7.035261163245442],
            [110.446861594872232, -7.035044188608699],
            [110.447069907981145, -7.034745931838679],
            [110.447344833837903, -7.034583436675685],
            [110.447601304374032, -7.034475192808896],
            [110.447848048038963, -7.034457392733128],
            [110.448333004484908, -7.034457964304003],
            [110.448696873984474, -7.034449347289477],
            [110.448930677124196, -7.034368212605486],
            [110.44918868984449, -7.03415142312889],
            [110.449445715009702, -7.034052224394751],
            [110.450061697266349, -7.03379062737448],
            [110.450392926298676, -7.033628196403402],
            [110.450753446514511, -7.033339161891845],
            [110.450892426070268, -7.033049867385765],
            [110.450977102683169, -7.032742418080593],
            [110.45105115117606, -7.03257063948929],
            [110.4511994247475, -7.032317537930097],
            [110.451323606036198, -7.032100590278321],
            [110.451427122189401, -7.031892663930514],
            [110.451516197852499, -7.031693766191257],
            [110.451586390931979, -7.031431527489374],
            [110.451594776443756, -7.031277762994518],
            [110.451516625796231, -7.031087715003792],
            [110.451328412940143, -7.030897538121695],
            [110.451228458608668, -7.030735960139125],
            [110.451168692541557, -7.030600185625961],
            [110.451172842135691, -7.03046847728919],
            [110.451280848157538, -7.030348864518331],
            [110.451065379499852, -7.030125098825545],
            [110.450682237632932, -7.029801353789792],
            [110.450506672739493, -7.029617547739748],
            [110.450339037028343, -7.029485637946391],
            [110.450123451916213, -7.029361654453983],
            [110.449903918855114, -7.029197753151968],
            [110.449830534659696, -7.029137687633121],
            [110.449921404288446, -7.029008241042636],
            [110.450006621459451, -7.028896922648232],
            [110.450126473156317, -7.028752334546726],
            [110.450200153219441, -7.028652919977961],
            [110.450255725413825, -7.028553484154377],
            [110.450278728182283, -7.028481146761504],
            [110.450283382694579, -7.02837260565822],
            [110.450352620726463, -7.028200821470056],
            [110.450463513847467, -7.027974812824343],
            [110.450599379444824, -7.027685514580695],
            [110.450687595084233, -7.027495661469173],
            [110.450760783278113, -7.027332927370241],
            [110.450797713377384, -7.027206332967705],
            [110.450834328669757, -7.027106874835916],
            [110.450849253570766, -7.026925981355361],
            [110.45087852315919, -7.026817469062746],
            [110.450937239082478, -7.026690900159893],
            [110.45104732516279, -7.026428708172341],
            [110.45119418186313, -7.026175604741616],
            [110.4513252047322, -7.025913437170554],
            [110.451381352798123, -7.025804956283275],
            [110.451415640750056, -7.025759768656601],
            [110.451458689024506, -7.025723636826005],
            [110.45155353528321, -7.025669474486162],
            [110.451639631788566, -7.025597210791909],
            [110.451777285684599, -7.025470734065732],
            [110.451854947098354, -7.025353232711158],
            [110.451898037676159, -7.025280918681702],
            [110.451945340457812, -7.025235746222283],
            [110.452134739128766, -7.025136466506312],
            [110.452237758491037, -7.025109450214503],
            [110.452427093558697, -7.025064443605102],
            [110.452676693308433, -7.025019507265513],
            [110.45292174494719, -7.024992656587061],
            [110.453141049672254, -7.024965775751134],
            [110.453356099821534, -7.024947935403429],
            [110.453463624892862, -7.024939015192106],
            [110.45355846022413, -7.024893897955374],
            [110.453734896334737, -7.024749374681845],
            [110.453960290253775, -7.024595862791268],
            [110.454181995356976, -7.024451392040616],
            [110.454394593766608, -7.024352138350971],
            [110.454457729907887, -7.024316029587148],
            [110.454534771899461, -7.024243754770353],
            [110.454697858541223, -7.023900213349179],
            [110.454947240658342, -7.023312542144809],
            [110.455041122802697, -7.023113649011083],
            [110.455147977615297, -7.022950953145484],
            [110.455247957790277, -7.022860613715222],
            [110.455347591857034, -7.022824547197488],
            [110.45549022100947, -7.022797576129466],
            [110.455668216612125, -7.022770646061852],
            [110.455739578495383, -7.022716455531255],
            [110.455805627744809, -7.022607985481231],
            [110.455843174466295, -7.022436163452391],
            [110.455871331858248, -7.022309558312892],
            [110.45589485721456, -7.022273403372187],
            [110.455913562222094, -7.022246288396332],
            [110.455950940699012, -7.022219195078057],
            [110.455997624378284, -7.022219249221114],
            [110.456448402042653, -7.022174565123331],
            [110.456465056268058, -7.022210535044711],
            [110.456734052402254, -7.02250051502466],
            [110.457038999917387, -7.022790326008312],
            [110.457363847409837, -7.022998749808379],
            [110.457711308811128, -7.023225290670738],
            [110.458219098920679, -7.023533426581652],
            [110.458693252137863, -7.02381438648195],
            [110.458994272063407, -7.024077055116626],
            [110.459505889277921, -7.024502786476907],
            [110.4597077258924, -7.024656793632258],
            [110.459784055172292, -7.024711154947542],
            [110.459835717093171, -7.02481071564787],
            [110.459840307898375, -7.024928313245832],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 76,
        id: 78,
        area: 3720760.0115990001,
        perimeter: 10044.671257,
        kode_pos: 50275,
        kode_kec: 3374070,
        kode_kel: 3374052004,
        kec: "Tembalang",
        kel: "Tembalang",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.451283957173445, -7.047588626425835],
            [110.450811431151138, -7.047789185513368],
            [110.450722306455816, -7.047862562964802],
            [110.450648566439412, -7.048021043105733],
            [110.450582573380927, -7.048175664853991],
            [110.450463915513808, -7.048318240522914],
            [110.450367377320575, -7.048363354700521],
            [110.450290373111002, -7.048399446293382],
            [110.450248486298946, -7.048408442564145],
            [110.45019521355124, -7.048376318443046],
            [110.450125586129573, -7.048329826737844],
            [110.450063710298451, -7.048275609178998],
            [110.449955343262417, -7.048252276761366],
            [110.449866291329258, -7.048263774397196],
            [110.449773331669334, -7.048306207274589],
            [110.449715158304556, -7.048395090879336],
            [110.449691803173437, -7.04850335288546],
            [110.449682934166489, -7.04859773328252],
            [110.449668192498208, -7.048828193595582],
            [110.44949380900097, -7.048978820014915],
            [110.449076679595663, -7.049230206624949],
            [110.448957985698058, -7.049347658660214],
            [110.448936155991674, -7.049383815065948],
            [110.448919889039743, -7.049501387862028],
            [110.448903686208766, -7.04956468751134],
            [110.448811850827596, -7.049700262246281],
            [110.448736196874819, -7.049790628366365],
            [110.448617241185971, -7.049889988913521],
            [110.448487837771196, -7.049971246022738],
            [110.448325623059489, -7.050043418818857],
            [110.448271809456102, -7.050088582958583],
            [110.448255627916282, -7.050133791529507],
            [110.448252910150856, -7.05027851688939],
            [110.448249439858188, -7.050341831540136],
            [110.448239114593321, -7.050459411308383],
            [110.448218015882858, -7.050595069423111],
            [110.448183399865002, -7.050676438358773],
            [110.448138325452334, -7.050748749402396],
            [110.448103741525401, -7.050802981762256],
            [110.448017062969953, -7.050884289179344],
            [110.447996364906388, -7.050920446864723],
            [110.44797527680366, -7.051047059436542],
            [110.447951044124466, -7.051200804894325],
            [110.447937104676356, -7.051264107165526],
            [110.447905926778361, -7.051309297995096],
            [110.447835386876307, -7.051381578904395],
            [110.447749263346097, -7.051471932448327],
            [110.447675034345849, -7.051553254502328],
            [110.447640199500412, -7.051580349918284],
            [110.447547066483054, -7.051616421926639],
            [110.447509957316697, -7.051652560176203],
            [110.44748894407023, -7.051715854061121],
            [110.447486626882707, -7.05176107898974],
            [110.447481992505288, -7.05185152884658],
            [110.447481928187372, -7.051905801973201],
            [110.447472547991964, -7.051941973013508],
            [110.447449303293666, -7.05197812765403],
            [110.447405152527736, -7.051987120961806],
            [110.447353949479748, -7.051978014854972],
            [110.447268248105047, -7.051950776857389],
            [110.447170695062425, -7.05189638821933],
            [110.447064087678697, -7.051841988845402],
            [110.446922416122732, -7.051769456870131],
            [110.44680419716633, -7.051724089226381],
            [110.446713664278988, -7.05171493647677],
            [110.446676597984961, -7.051714892574637],
            [110.446651100385665, -7.051741998970824],
            [110.44662077118069, -7.051787190706996],
            [110.4466127841944, -7.051841454440682],
            [110.446628837127335, -7.051904792184626],
            [110.446672346848686, -7.051959116919351],
            [110.446753728808915, -7.052049668636593],
            [110.446791819592335, -7.052140169074653],
            [110.446802715072934, -7.052257773902324],
            [110.446791572719349, -7.052348216032446],
            [110.446747944869756, -7.052393392023599],
            [110.446671777848138, -7.052438529465464],
            [110.446578852376575, -7.052537920231805],
            [110.446518770469893, -7.052619258831252],
            [110.446431754642546, -7.052745793160658],
            [110.446393449048927, -7.05283620307914],
            [110.44639334162575, -7.052926658269605],
            [110.446398588758427, -7.053035210869816],
            [110.446414935381966, -7.053089503435209],
            [110.446490919943017, -7.053198139871466],
            [110.446545411096054, -7.053297705296446],
            [110.446545378875967, -7.053324841853787],
            [110.44653968765985, -7.053351971705085],
            [110.446523538040495, -7.053370043630695],
            [110.446468885761774, -7.053406160987965],
            [110.446409129634773, -7.053451317820061],
            [110.446218596922819, -7.053541547251786],
            [110.445930141958215, -7.053649751499069],
            [110.445617876036849, -7.053794109413959],
            [110.445182472808824, -7.053983548477862],
            [110.44440930478909, -7.054344450420702],
            [110.443538463733702, -7.054750462235294],
            [110.442708649112802, -7.05515652126949],
            [110.442240399652363, -7.055364009233791],
            [110.441690605753394, -7.055616626811168],
            [110.44121157075962, -7.055851237339684],
            [110.440879615645414, -7.056011267140941],
            [110.440894548411606, -7.056655909285309],
            [110.441313458632123, -7.056774002605064],
            [110.441563522817674, -7.056828574949048],
            [110.441900692856692, -7.056919433404505],
            [110.442254274249962, -7.057010311234903],
            [110.442633941482867, -7.057055992332431],
            [110.442854622299848, -7.057074346823207],
            [110.44287734669318, -7.057237193392933],
            [110.442869490638301, -7.057418094512816],
            [110.442857956193237, -7.057598991240158],
            [110.442834440365701, -7.057861283384344],
            [110.442815341903881, -7.057978852404566],
            [110.442769483583433, -7.058232072340414],
            [110.442693087953629, -7.058467164753029],
            [110.442578754905171, -7.058720302881917],
            [110.442471854296755, -7.058910131184649],
            [110.442399005431838, -7.059018590431391],
            [110.442311345489387, -7.059208441673537],
            [110.442212019385494, -7.059452552085956],
            [110.442116546157806, -7.059787122300547],
            [110.442078054839314, -7.060031305391332],
            [110.44205724749699, -7.060157917827292],
            [110.441940258638382, -7.060501507769216],
            [110.441851401317265, -7.060745630576737],
            [110.441759746886547, -7.060962613445551],
            [110.441664131003463, -7.061179591546853],
            [110.441553507590029, -7.061405597176627],
            [110.441423610594271, -7.061658716255755],
            [110.441344460036376, -7.06183048637322],
            [110.441281187451622, -7.061975138927211],
            [110.441265265992087, -7.062038438501824],
            [110.44125381752329, -7.062146971035881],
            [110.441242129519438, -7.062219321199869],
            [110.441195595265157, -7.06256299523056],
            [110.441128089647307, -7.062933780679046],
            [110.441100360596849, -7.063168930972195],
            [110.441070008874391, -7.063467396754551],
            [110.441041430874392, -7.063702546016719],
            [110.441029166004796, -7.063783940993211],
            [110.441012852836309, -7.063937695272513],
            [110.4410078254772, -7.064118454098992],
            [110.440760619524752, -7.06403689358297],
            [110.440287647637078, -7.063927779946352],
            [110.439767727115409, -7.063800518415788],
            [110.439460839895602, -7.063700648972431],
            [110.439274720405564, -7.063646152135216],
            [110.439222428092791, -7.063600861676842],
            [110.43915121046102, -7.063528411904604],
            [110.439115601653427, -7.063492187014977],
            [110.439077990360857, -7.063474050748164],
            [110.438916477244547, -7.063428628887441],
            [110.438637515809035, -7.06340115665714],
            [110.438597902036733, -7.063401108975449],
            [110.438320932255181, -7.063364593440175],
            [110.438256146367237, -7.063355469898814],
            [110.438224727392864, -7.063364477571242],
            [110.438195289105764, -7.063373487627328],
            [110.438176298334241, -7.063400601297589],
            [110.438158689582195, -7.063454853177866],
            [110.438137957395369, -7.063518146809526],
            [110.438102228555408, -7.063581422371932],
            [110.438062244449938, -7.063653738318693],
            [110.438028672243078, -7.063698713747556],
            [110.437946254483592, -7.063635507518262],
            [110.437796364484853, -7.06357200825185],
            [110.437378831402384, -7.063481049606277],
            [110.436974619299519, -7.063372015653075],
            [110.436493542117304, -7.063181478885523],
            [110.436262205208095, -7.063081698692078],
            [110.436067152413756, -7.062927689188004],
            [110.435958096151211, -7.062791874636297],
            [110.43584495930746, -7.062599131256186],
            [110.435793909678551, -7.062384628016844],
            [110.435766835814746, -7.06207704785343],
            [110.435762812825132, -7.061660949419187],
            [110.435744955887174, -7.061452881034756],
            [110.435712948793054, -7.061233416467587],
            [110.435652760091713, -7.060953682002141],
            [110.43556504036188, -7.060500070489588],
            [110.435492565648602, -7.059953256853263],
            [110.435427660037632, -7.059403932889238],
            [110.435408391750755, -7.059227277789527],
            [110.435385898079915, -7.059109658908535],
            [110.435354446534717, -7.05902400569382],
            [110.435306962964447, -7.058891009180984],
            [110.435251195063728, -7.058657220279494],
            [110.435224108403148, -7.058593868912071],
            [110.435192744557924, -7.058557648905571],
            [110.435107108711023, -7.05847613564787],
            [110.434973028127956, -7.058386228325451],
            [110.434971967214778, -7.058385516933102],
            [110.434722328916294, -7.058213349913782],
            [110.434488633265886, -7.058027162970756],
            [110.434327401698795, -7.057883357562852],
            [110.434163841741736, -7.057697079019586],
            [110.434007601640843, -7.05747985402078],
            [110.433799133613974, -7.057217224671866],
            [110.43363275008339, -7.056990885052925],
            [110.433479676417349, -7.056755516026913],
            [110.433379187798607, -7.056556392830997],
            [110.433311738514959, -7.056411582788988],
            [110.433222287258658, -7.056214125251248],
            [110.433139255756672, -7.056055297586563],
            [110.433033566557143, -7.055863689235752],
            [110.43288003082624, -7.055614074853093],
            [110.432791934162253, -7.055472877304546],
            [110.432668585422618, -7.055286286382647],
            [110.432557821992972, -7.055119866519059],
            [110.432426935198876, -7.05491059112126],
            [110.432366571034265, -7.054776985638167],
            [110.432297843488186, -7.054619340074344],
            [110.432250179231488, -7.054492644987623],
            [110.432211558369318, -7.054375006402945],
            [110.432175811102312, -7.054221189270884],
            [110.432161240988293, -7.054103579926815],
            [110.432153123296317, -7.054074061282878],
            [110.432146310785939, -7.054049288713389],
            [110.43211352170556, -7.054022112312586],
            [110.432101441885152, -7.054014045768338],
            [110.432086380597013, -7.05400398828794],
            [110.431920278225078, -7.05401283177372],
            [110.431304874165193, -7.054003037257736],
            [110.430848193773159, -7.054002480909195],
            [110.430720050829223, -7.053975188196437],
            [110.430663483047383, -7.053957028221477],
            [110.430607231370644, -7.053911732102812],
            [110.430401959381086, -7.053730269910901],
            [110.430400213640652, -7.053728674844743],
            [110.429996097325926, -7.053359437225585],
            [110.430429569970343, -7.053156825829192],
            [110.430762255564318, -7.053001323579263],
            [110.431290857006132, -7.052736648957323],
            [110.431629789210447, -7.052538060297681],
            [110.431876163919881, -7.052366495333843],
            [110.43216296934105, -7.052151446433233],
            [110.432433126212814, -7.051897307882322],
            [110.432690719111264, -7.051605361779111],
            [110.43288520518135, -7.051361208914197],
            [110.433174779537552, -7.050911974740911],
            [110.433395725230071, -7.050464895426463],
            [110.433714448657838, -7.049674166746299],
            [110.434135819008603, -7.048615349780623],
            [110.434476385423025, -7.047765483540499],
            [110.43475740046982, -7.047024091118592],
            [110.43489386065032, -7.046490570612786],
            [110.434934245382436, -7.046183650304307],
            [110.434952237366304, -7.045904010243618],
            [110.434955271797705, -7.045480741980814],
            [110.434895383820219, -7.044954099159733],
            [110.434769893179492, -7.044418997321897],
            [110.434529234006362, -7.043474787293017],
            [110.434051906680423, -7.041514517970938],
            [110.433861657104174, -7.040586597456751],
            [110.433701204286848, -7.039805365771067],
            [110.433527322900474, -7.039005305971234],
            [110.433514249282155, -7.03894515078346],
            [110.433593621599329, -7.038933963592738],
            [110.433823752813282, -7.038901527496298],
            [110.434143245463432, -7.038744945615158],
            [110.434411963042933, -7.038664254546108],
            [110.434776794255896, -7.038735584228476],
            [110.435308044346357, -7.038811425454821],
            [110.435665730696755, -7.038912171280667],
            [110.435979606547065, -7.03896678886306],
            [110.436298283098594, -7.038955381702223],
            [110.436277169862365, -7.038846877885433],
            [110.43627029623957, -7.038674719124984],
            [110.436291671291514, -7.038566266485785],
            [110.436386172194773, -7.038497991752481],
            [110.436504300419543, -7.038410879638788],
            [110.436516173830384, -7.038351938286898],
            [110.436459596789518, -7.038290556293311],
            [110.436346354544, -7.038240897183725],
            [110.436223778641974, -7.038101614224954],
            [110.43617279040231, -7.037982020732829],
            [110.43619504206039, -7.037849621785901],
            [110.436278019590517, -7.037739366936012],
            [110.436383055618705, -7.037656727329289],
            [110.436467831901751, -7.037581420291212],
            [110.436488249120018, -7.037443501371491],
            [110.436527098344371, -7.03728721217044],
            [110.436425786549819, -7.037334910616658],
            [110.436284052975864, -7.037316347482219],
            [110.43621596320628, -7.037294194517718],
            [110.436136934089333, -7.037183744530179],
            [110.435850326985459, -7.03669783811725],
            [110.436376532906579, -7.036614651535099],
            [110.436419289450285, -7.036587566442173],
            [110.436592890063849, -7.036161672342409],
            [110.437012775207975, -7.036044465657394],
            [110.437427598144311, -7.035955592517472],
            [110.437812449347703, -7.035910827179339],
            [110.437940566226047, -7.035956208631858],
            [110.438093222673757, -7.036064938184822],
            [110.438129602151491, -7.036164482630507],
            [110.438196745869419, -7.036327382674036],
            [110.438277579452105, -7.036399843878273],
            [110.438374993617103, -7.036390934750736],
            [110.438798975529991, -7.036500887545556],
            [110.439186374380853, -7.036606259602693],
            [110.43933388984162, -7.036601440689516],
            [110.43982405007624, -7.036494621853481],
            [110.440573244305043, -7.036164558498617],
            [110.440935973519018, -7.035992643201314],
            [110.441139815552106, -7.035926694717384],
            [110.441289833970444, -7.035919380351235],
            [110.44154733528822, -7.035932176604648],
            [110.441807309936195, -7.035967455921479],
            [110.441969772491319, -7.036007614491823],
            [110.442097879432851, -7.036083300223189],
            [110.442201890184165, -7.036214579978892],
            [110.442201967962447, -7.036386445176734],
            [110.442214592251972, -7.03647691553658],
            [110.442267132596385, -7.036549342389675],
            [110.442326245074469, -7.036567548715754],
            [110.442318576747354, -7.03673710567065],
            [110.442378286868362, -7.036820840763195],
            [110.44244018586997, -7.036875187684797],
            [110.442640606617871, -7.037029200405541],
            [110.442697695702719, -7.037083541570031],
            [110.442731050226143, -7.037110717876253],
            [110.442754752435206, -7.037165019287012],
            [110.442759497680612, -7.03721929813003],
            [110.442749737471232, -7.037336878430827],
            [110.442706808537821, -7.037508692434],
            [110.442692259903197, -7.037608175965254],
            [110.442692130416418, -7.037716722195876],
            [110.442706474039667, -7.037789103530628],
            [110.442803969725333, -7.037888720457836],
            [110.443002475142166, -7.037988457585423],
            [110.443391019587196, -7.038169830471948],
            [110.443641249511131, -7.038314856524114],
            [110.443805247324704, -7.03840550678126],
            [110.443891805612253, -7.038423700706907],
            [110.443985426656809, -7.038450948541561],
            [110.444087242227909, -7.038487251625807],
            [110.444131882268977, -7.038541577851317],
            [110.444160100994125, -7.038604930104161],
            [110.444168198640455, -7.038695395060516],
            [110.444168069476433, -7.038803941313447],
            [110.444135402104052, -7.038912448918594],
            [110.444070228799021, -7.038993781304879],
            [110.443988644891988, -7.039075094182478],
            [110.443907333128237, -7.039165452900083],
            [110.443862280877255, -7.039219672561067],
            [110.443833922142176, -7.039273912057148],
            [110.443825641451369, -7.039337220947518],
            [110.443845927048415, -7.039409609322001],
            [110.443935121085119, -7.039590625983927],
            [110.444056015395446, -7.039762634763584],
            [110.444131148625317, -7.039871270423808],
            [110.444179760738265, -7.039916555837408],
            [110.444232921454272, -7.039943755582226],
            [110.444299401956727, -7.039952880073163],
            [110.444379191477807, -7.03995297482496],
            [110.444419086238753, -7.039953022195756],
            [110.444454432414886, -7.039971155230402],
            [110.444485523710568, -7.039998328744997],
            [110.444520826857669, -7.040052643859562],
            [110.444560657079379, -7.040106964345969],
            [110.444587221323246, -7.040134132480834],
            [110.444613807084593, -7.040143209571956],
            [110.444662462299675, -7.040152312855604],
            [110.444715666094837, -7.040143330464105],
            [110.444751055316161, -7.040125281392477],
            [110.444888661744315, -7.040044034855838],
            [110.444986058169974, -7.039989877185589],
            [110.445021425872298, -7.039989919135498],
            [110.445052527953564, -7.040008047093123],
            [110.445052484959533, -7.040044229180913],
            [110.445034541396794, -7.040143708780349],
            [110.445012364447848, -7.040234137822202],
            [110.445012289200236, -7.040297456474756],
            [110.445034272666391, -7.04036984682612],
            [110.445087347501641, -7.04046941065602],
            [110.445153763620596, -7.040532808164028],
            [110.445189088378342, -7.040569032192299],
            [110.445220136773017, -7.040632387751763],
            [110.445250848504841, -7.040740970584102],
            [110.445312966828197, -7.040849590651383],
            [110.445370339623807, -7.040903931877474],
            [110.445463373895336, -7.040949269832853],
            [110.445573939865895, -7.04100367408249],
            [110.445635001700566, -7.041048974116129],
            [110.445674276988711, -7.041094248329194],
            [110.445707076822927, -7.04111237826167],
            [110.44574299976243, -7.041121466356726],
            [110.445778933439684, -7.041121508926487],
            [110.445818273160626, -7.041112509992721],
            [110.44585421757229, -7.041103507033885],
            [110.445864077616079, -7.041139700854695],
            [110.445864045412506, -7.041166837422977],
            [110.445841062670141, -7.041221083412759],
            [110.44583447984806, -7.041284394362976],
            [110.445844035480178, -7.041338678894274],
            [110.445870306191637, -7.041374892153719],
            [110.445906196952834, -7.041411116806161],
            [110.445919169373369, -7.041447314312497],
            [110.445922500280602, -7.041501591470223],
            [110.445912522151133, -7.041564898399908],
            [110.445902554752834, -7.041619159806358],
            [110.445902501080155, -7.041664387419517],
            [110.445902468876383, -7.041691523987371],
            [110.445938642605412, -7.04172774897407],
            [110.445974554863938, -7.041745882577863],
            [110.446003970175113, -7.0417549629496],
            [110.446023450294362, -7.041791168160643],
            [110.446016867482726, -7.041854479113201],
            [110.446010306134369, -7.041899699019964],
            [110.446029786259288, -7.04193590423103],
            [110.44605269389784, -7.041944976893964],
            [110.446082119954681, -7.041945011739021],
            [110.446137838236467, -7.041963168784272],
            [110.446209662809139, -7.04199943596239],
            [110.446268482747357, -7.042026642200594],
            [110.446483075562881, -7.042162579214067],
            [110.446563628539522, -7.042235038814712],
            [110.446611664528376, -7.042289368862706],
            [110.446633114596125, -7.042334621919603],
            [110.446665241460749, -7.042443206359307],
            [110.446713549704853, -7.042506582257207],
            [110.44680458239516, -7.042570008682396],
            [110.446997693357872, -7.042687829007456],
            [110.447072598351241, -7.042751236317612],
            [110.447137045170578, -7.042805585718845],
            [110.447196158924598, -7.042823746661881],
            [110.447222734186568, -7.042841869143819],
            [110.447244195047389, -7.042878076654017],
            [110.447287084649403, -7.04297762824481],
            [110.447308470554702, -7.043077154422459],
            [110.447353949513783, -7.043140526920877],
            [110.447395205745693, -7.043185803352462],
            [110.447438170354957, -7.043222036262252],
            [110.447552927740162, -7.043321672735646],
            [110.447722891327146, -7.043394237766113],
            [110.447854134459647, -7.043430574877148],
            [110.448084418665161, -7.043457983303243],
            [110.448511642547928, -7.043476578348978],
            [110.448884279675767, -7.043477017607615],
            [110.449032259346666, -7.043477191961304],
            [110.449246207648031, -7.043441261799765],
            [110.449344725483598, -7.043396150105641],
            [110.449399680617759, -7.043341939429766],
            [110.449519323201102, -7.043378264562146],
            [110.449539097273018, -7.043405424456281],
            [110.449547259979681, -7.043441616223825],
            [110.449547185312582, -7.043504934916381],
            [110.449555022410181, -7.043577308460748],
            [110.449570781942938, -7.043649691329293],
            [110.449602647961342, -7.043740184233378],
            [110.449638464543725, -7.043839727325114],
            [110.449678274353914, -7.043912138493704],
            [110.449730250779268, -7.043984563975751],
            [110.449777972392496, -7.044066029987147],
            [110.449793742633943, -7.044129367323008],
            [110.449797639877403, -7.044183645149316],
            [110.44978961080264, -7.044274091103978],
            [110.449781571059845, -7.044373582585433],
            [110.449796198867389, -7.044445964117064],
            [110.449812807290797, -7.044518347978699],
            [110.449820046555956, -7.044617857436672],
            [110.449812562033941, -7.044726395110502],
            [110.449782703539967, -7.044852997536604],
            [110.449752636737458, -7.044916280935135],
            [110.449738174555264, -7.044943400535698],
            [110.449738089232611, -7.045015764753441],
            [110.449767713127329, -7.045088163932527],
            [110.449872285462121, -7.045187787916357],
            [110.449943155393257, -7.045314508852758],
            [110.450022460413095, -7.045486467402739],
            [110.450075398275501, -7.045703622630299],
            [110.450146161743703, -7.045920798818429],
            [110.450296315763453, -7.046237569294582],
            [110.450516897506702, -7.046816743187467],
            [110.450534563313951, -7.046952447055513],
            [110.45055187161249, -7.047151469283758],
            [110.450587144020446, -7.047232920603932],
            [110.450781955575849, -7.047350741562202],
            [110.451283957173445, -7.047588626425835],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 77,
        id: 79,
        area: 2385454.6637149998,
        perimeter: 8858.398211,
        kode_pos: 50272,
        kode_kec: 3374070,
        kode_kel: 3374052006,
        kec: "Tembalang",
        kel: "Mangunharjo",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.449399680617759, -7.043341939429766],
            [110.449432598121348, -7.04326057045833],
            [110.449498358426325, -7.043161146924271],
            [110.449558700108469, -7.043097899162311],
            [110.449608289914195, -7.043034638738841],
            [110.449624775299469, -7.042971339355475],
            [110.449646657936071, -7.042889955240932],
            [110.44970163429673, -7.04281765560192],
            [110.44972890349969, -7.042727232278164],
            [110.449776021376323, -7.042600650137986],
            [110.449856788165988, -7.042492198649338],
            [110.449957611920595, -7.042410907352269],
            [110.450031785694421, -7.042374812403745],
            [110.450085283288445, -7.042356784217999],
            [110.450145833154153, -7.042356855398571],
            [110.450246592923875, -7.04232983720672],
            [110.450367809791302, -7.042230478696307],
            [110.450421643540722, -7.042167223155471],
            [110.45044180703924, -7.042103928051527],
            [110.450441881570029, -7.042040609345635],
            [110.450428385479483, -7.041968229154539],
            [110.450381544707071, -7.041859627619196],
            [110.450341473287978, -7.041769125118066],
            [110.450301108291043, -7.041687667811234],
            [110.450287905821, -7.041606242420598],
            [110.450288033614285, -7.041497696068046],
            [110.450274558856108, -7.041407224815084],
            [110.450241256814635, -7.04133482134638],
            [110.450107146656663, -7.041090434102403],
            [110.449974083165642, -7.040918412392141],
            [110.44989582533195, -7.040818819425502],
            [110.449802843874309, -7.040728254675085],
            [110.449650748846409, -7.040619529295504],
            [110.449528309504018, -7.040556066468707],
            [110.449484202514725, -7.040528877950154],
            [110.449435306843071, -7.040483592710819],
            [110.44938613890406, -7.040429261605617],
            [110.449283243986542, -7.040347730638538],
            [110.449170739747217, -7.040257142798117],
            [110.449092471524693, -7.040166595244303],
            [110.449053238638314, -7.040085139184448],
            [110.44904853533788, -7.039994678240078],
            [110.449072996380195, -7.039886160552911],
            [110.449087890741637, -7.039732403895903],
            [110.449083198102571, -7.039632897422003],
            [110.44908808279763, -7.039569584386844],
            [110.449109084488299, -7.039515335866372],
            [110.449220616786221, -7.039470239449626],
            [110.44929672805182, -7.039470329027162],
            [110.449384701534456, -7.039488523632953],
            [110.449513658903271, -7.03954294860362],
            [110.449654489193023, -7.039606433046553],
            [110.449742484034161, -7.039606536529461],
            [110.449783521224489, -7.039597539242102],
            [110.449883201905962, -7.03952529210237],
            [110.449994798057404, -7.039425922317055],
            [110.450024277231478, -7.039380729252803],
            [110.450041894159796, -7.039317431160661],
            [110.450059606935326, -7.039172723297917],
            [110.450071607621823, -7.039073236433077],
            [110.450106798834838, -7.038982822359982],
            [110.450141979384867, -7.038901453813017],
            [110.450165538038974, -7.038838162693458],
            [110.450189064744706, -7.038802008162931],
            [110.450236107498739, -7.038738744627701],
            [110.450289123937338, -7.03864835147431],
            [110.450324283138542, -7.038585073969231],
            [110.450400436844902, -7.038548981233272],
            [110.450459843814556, -7.03855809653666],
            [110.450513298385147, -7.038576250387373],
            [110.45054470483916, -7.038576287260679],
            [110.450575828352271, -7.038576323799714],
            [110.450625102644906, -7.038540199469408],
            [110.450665344093366, -7.038485973443691],
            [110.450687749615639, -7.038440772023575],
            [110.450705617441955, -7.038404610819991],
            [110.450741593483642, -7.038368470867241],
            [110.450781813633114, -7.038332335891671],
            [110.450808724921089, -7.038305230839268],
            [110.450826603366409, -7.038260024098261],
            [110.450866823499894, -7.038223889113987],
            [110.450929395951192, -7.038187780348854],
            [110.451036651779006, -7.03816981507503],
            [110.451081388313014, -7.038142730912027],
            [110.451121619045708, -7.038097550371388],
            [110.45117980255813, -7.037943844343402],
            [110.451215821031226, -7.037871522219082],
            [110.45127845715713, -7.037781140207012],
            [110.451345577814195, -7.037726945620757],
            [110.451430502466025, -7.037690862980198],
            [110.451537736929239, -7.037690988646381],
            [110.451685148951796, -7.037691161355842],
            [110.45173863533067, -7.03768217846405],
            [110.451756764755586, -7.037664108608278],
            [110.451787930672012, -7.037627962929333],
            [110.451828161292894, -7.037582782318253],
            [110.45185054543073, -7.037555671895279],
            [110.451904357180638, -7.037510507180833],
            [110.451953631262569, -7.037474382692362],
            [110.451998105960172, -7.037429207033253],
            [110.452114638441913, -7.03722129588544],
            [110.452137075579984, -7.037148957776309],
            [110.452133429177763, -7.037121816869631],
            [110.452126104553187, -7.037094671657899],
            [110.452129814596731, -7.037067539361205],
            [110.452151915750989, -7.037040428588809],
            [110.452177978064867, -7.037013322450865],
            [110.452204061586443, -7.036968125243084],
            [110.452222495114924, -7.036931964628092],
            [110.452244596257145, -7.036904853850253],
            [110.452278025603761, -7.036868710779269],
            [110.452337474827786, -7.036841643694053],
            [110.4524187104292, -7.036814602085694],
            [110.452496561341135, -7.03677851095689],
            [110.452637288504604, -7.036688220054086],
            [110.452785350866876, -7.036616028773773],
            [110.452896652180485, -7.036525703387228],
            [110.452955818389682, -7.036498635886282],
            [110.452978181230208, -7.036489616469916],
            [110.453018966928269, -7.036453481934561],
            [110.453037410961173, -7.036408275744706],
            [110.453074571367651, -7.036326909227911],
            [110.453115325283676, -7.036317911289341],
            [110.45314845041041, -7.036299858887846],
            [110.453196592512342, -7.036263732927379],
            [110.453311561298264, -7.036182457260323],
            [110.453407813704359, -7.036137341904734],
            [110.453474650947484, -7.03608314664173],
            [110.453500398456342, -7.036083176697844],
            [110.453552176414036, -7.036083237136072],
            [110.453726194882577, -7.036074394669483],
            [110.453829729655368, -7.036092606563864],
            [110.453914852215888, -7.036128888058335],
            [110.453948239101251, -7.036128927000785],
            [110.453977968910024, -7.036110870576803],
            [110.454000069914258, -7.036083759704279],
            [110.454011429777509, -7.036047590754622],
            [110.454042029534321, -7.036011444241391],
            [110.4540655240924, -7.036002426089273],
            [110.454094666875008, -7.036002460071557],
            [110.454147000141944, -7.036011566640525],
            [110.454231577967263, -7.03602975634353],
            [110.454310214067164, -7.036047939106234],
            [110.454397642427509, -7.036048041002609],
            [110.454505735945489, -7.036039121411116],
            [110.454593153749371, -7.036048268808894],
            [110.454706863838879, -7.036075537915802],
            [110.454867258999769, -7.036102861358327],
            [110.455009850078227, -7.036112072920107],
            [110.455065316817624, -7.036103091934283],
            [110.455091640762049, -7.036094077022867],
            [110.455097614140556, -7.036066947322138],
            [110.455097666880178, -7.036021719628117],
            [110.455118091275708, -7.035976515643807],
            [110.455141302875688, -7.035967497106655],
            [110.455190817317458, -7.035967554728412],
            [110.455406668662846, -7.03599494251598],
            [110.455558858603965, -7.036022256177985],
            [110.455596468510194, -7.036040391016903],
            [110.455638227677795, -7.036139940644214],
            [110.455678069504529, -7.036185214728262],
            [110.455726148202686, -7.036203361729724],
            [110.456017314362285, -7.036185609037863],
            [110.456063433556309, -7.036185662623942],
            [110.456151343625322, -7.036258129174878],
            [110.456232840840386, -7.036249178288703],
            [110.456364753971727, -7.036195058170433],
            [110.456406042145971, -7.036213197218835],
            [110.456661012002556, -7.036421540927],
            [110.456966670427676, -7.036593761098556],
            [110.457258517552134, -7.036720737303827],
            [110.457786190592742, -7.036730394339147],
            [110.45786995140341, -7.036721445793793],
            [110.45790766641295, -7.036649125029792],
            [110.457947707892643, -7.036522533625127],
            [110.458055665454154, -7.036386975286275],
            [110.458139531096094, -7.036287571254697],
            [110.458158582477054, -7.036206183305761],
            [110.458169355422328, -7.035943874662808],
            [110.458232660735973, -7.035763036808119],
            [110.458274588239803, -7.035717857543569],
            [110.458293566168336, -7.03569978838995],
            [110.458331197142243, -7.035699831927951],
            [110.458464912294914, -7.035799487725505],
            [110.458509050891536, -7.035799538776894],
            [110.45870998000872, -7.035763588896217],
            [110.458892297925075, -7.035673344077068],
            [110.458968733524642, -7.035637250182167],
            [110.459043995471049, -7.035637337145847],
            [110.459152885361888, -7.035673645173145],
            [110.459255613399279, -7.035655672715929],
            [110.459343691358399, -7.035583409973785],
            [110.459398120407116, -7.035493017247064],
            [110.459608260312123, -7.035321394231367],
            [110.459757620378141, -7.035104473193677],
            [110.459810330087208, -7.034787939463321],
            [110.459852843452055, -7.034480439514844],
            [110.459872061689111, -7.034254322715141],
            [110.459891060401915, -7.034218162392962],
            [110.459939201809064, -7.034182035679568],
            [110.460033483131156, -7.034127871046332],
            [110.460053912154621, -7.034082666806167],
            [110.460338240496014, -7.034345315771798],
            [110.460635328574512, -7.034834118274743],
            [110.460808771504205, -7.035078548141959],
            [110.460954360095755, -7.035187262490139],
            [110.461617332143746, -7.035640303306609],
            [110.462053847542549, -7.035939308577061],
            [110.462542938368543, -7.036283601648361],
            [110.463049844787506, -7.036636960254744],
            [110.463397874386104, -7.036872543951401],
            [110.463533813027425, -7.037008383202907],
            [110.463825875432889, -7.037442904863157],
            [110.464233695300464, -7.038094652419171],
            [110.464408262689744, -7.038348128000927],
            [110.464980735601415, -7.039018154541613],
            [110.46521534959858, -7.039217425128144],
            [110.465328978424921, -7.039317056179725],
            [110.465868349540543, -7.039987043967892],
            [110.466051244994844, -7.040629487849722],
            [110.466355624284276, -7.041679120709788],
            [110.466683633824914, -7.042602142455594],
            [110.466941228559037, -7.043244671250185],
            [110.467383710911861, -7.043778863750704],
            [110.467740168572561, -7.044322003556348],
            [110.468150304244944, -7.044937568728793],
            [110.468179102859892, -7.044991874883739],
            [110.468203960855377, -7.04502808542229],
            [110.468289790861448, -7.045191003231549],
            [110.468370852061753, -7.045317733339171],
            [110.468652171854458, -7.045752240337209],
            [110.468988949668017, -7.046186810190411],
            [110.469292078007172, -7.04660325050492],
            [110.469753076132662, -7.047282191229839],
            [110.470306404285225, -7.048133102025685],
            [110.470372490816501, -7.04824172373988],
            [110.470850114147751, -7.048984001393562],
            [110.471044716731768, -7.049291771047912],
            [110.471156003557809, -7.049463762816197],
            [110.471348857569268, -7.049816758234635],
            [110.471516246445375, -7.050169724788129],
            [110.471620712283169, -7.05036869461181],
            [110.47142145438734, -7.050422893502331],
            [110.470897895783708, -7.050512756598522],
            [110.470303558609373, -7.050638721125973],
            [110.470095112648266, -7.050810350537567],
            [110.469725385349093, -7.050981796816474],
            [110.469292781226173, -7.051207444755358],
            [110.469029524829921, -7.051306646850636],
            [110.468923094565341, -7.051342708136731],
            [110.468562513649445, -7.051432753744056],
            [110.468337234146716, -7.051477725241567],
            [110.468061872511541, -7.051522639603778],
            [110.467325492762058, -7.051648438424345],
            [110.466847811556889, -7.051702166878536],
            [110.466406358199848, -7.05174689065646],
            [110.465982751345521, -7.051773543290545],
            [110.465131199936835, -7.051908252465232],
            [110.465073184850368, -7.051917231594699],
            [110.465036401376565, -7.051917189472086],
            [110.464993959040555, -7.051917024515536],
            [110.464908360079534, -7.051799450552214],
            [110.464899053883599, -7.051772303214482],
            [110.464913232512671, -7.051745182777336],
            [110.465007244465525, -7.051681971535238],
            [110.465167256288197, -7.051555516919239],
            [110.465200140692389, -7.051501281206867],
            [110.465200275570723, -7.051383689083014],
            [110.465059667683931, -7.051121206868026],
            [110.464961240129014, -7.050840681806123],
            [110.464843247679127, -7.050596316558694],
            [110.464566269260914, -7.050324632447523],
            [110.464401959117026, -7.050252079655341],
            [110.46394166389274, -7.050197278448961],
            [110.463738275787207, -7.050151817274563],
            [110.46330163208286, -7.049952313671507],
            [110.463001045288877, -7.049789148292457],
            [110.462700616610476, -7.049734529599598],
            [110.462512843146968, -7.049643858085514],
            [110.462465663759914, -7.049580484919677],
            [110.462470703236505, -7.049381488469712],
            [110.462503671162381, -7.049254888595753],
            [110.462767177531731, -7.048938597098993],
            [110.462912968605181, -7.048875445770826],
            [110.462964485944298, -7.048857413859029],
            [110.46299769498286, -7.048766996442607],
            [110.462997757466553, -7.048712723168053],
            [110.462960187902354, -7.048658406651366],
            [110.462836360969462, -7.048567808768262],
            [110.462498132769312, -7.048413645427628],
            [110.462366697691138, -7.048295902009929],
            [110.46224932662841, -7.048250539196064],
            [110.462174345556349, -7.048250452919064],
            [110.462099029384405, -7.048295594027392],
            [110.46207095499777, -7.048349835055033],
            [110.462073963106747, -7.048440294082472],
            [110.462092460315176, -7.048594089832465],
            [110.46205019679455, -7.048684496750832],
            [110.461956205989253, -7.048729616332947],
            [110.461791802928673, -7.04873847258704],
            [110.461646105735923, -7.048720213663924],
            [110.461453554124347, -7.048602399595335],
            [110.461326908498847, -7.048502752531546],
            [110.461186084208322, -7.048430225756801],
            [110.461097028897697, -7.048366804193031],
            [110.460997556817759, -7.048258142825804],
            [110.460791067320955, -7.047959401338741],
            [110.460692769014557, -7.04781455905175],
            [110.460678705319111, -7.04774217838653],
            [110.460678904007736, -7.047570313074924],
            [110.460674271680858, -7.04741653329778],
            [110.460721366332848, -7.047308040974054],
            [110.460716881008082, -7.047271853579455],
            [110.460674543558852, -7.047181349183746],
            [110.460566017523092, -7.047072677299394],
            [110.460340917548322, -7.046963870829209],
            [110.460298517416803, -7.046927639660564],
            [110.460167187989811, -7.046719440267847],
            [110.460143787166885, -7.046647048809445],
            [110.460134533666846, -7.04657467368811],
            [110.460092793334809, -7.046212803306842],
            [110.460031414640994, -7.045950411340278],
            [110.459951801853279, -7.045796544960035],
            [110.45979684177459, -7.045714955953124],
            [110.459519879758844, -7.045678453650869],
            [110.459383489426585, -7.045687341517805],
            [110.45926854007908, -7.045750527463186],
            [110.459136802538168, -7.04589510393595],
            [110.459117740315136, -7.045985537415105],
            [110.459141078041085, -7.046112202158368],
            [110.459277112096885, -7.04641086277147],
            [110.459258091804202, -7.046465114090668],
            [110.459215922333598, -7.046474110865145],
            [110.45908435234081, -7.046473958649728],
            [110.458746189647968, -7.04650974946372],
            [110.45842669087358, -7.046554607203097],
            [110.458313837332412, -7.046518294244217],
            [110.458229592883995, -7.046454877759898],
            [110.458192045073204, -7.046382469831795],
            [110.458187392542911, -7.046246781164641],
            [110.458253644531766, -7.045966445834659],
            [110.458347488612873, -7.045803734629999],
            [110.458437172421782, -7.045568654163144],
            [110.458479646219857, -7.045297336785365],
            [110.458461097778454, -7.045188768673151],
            [110.458430560668631, -7.045170642199674],
            [110.458360725648333, -7.045125333546521],
            [110.458241071261824, -7.045098058268449],
            [110.458126813858371, -7.045052698111128],
            [110.458023339913851, -7.044980213770777],
            [110.457936821702532, -7.044925840169798],
            [110.457785572119292, -7.044817118187805],
            [110.457687484750849, -7.04473559447478],
            [110.457612913392467, -7.044626961366117],
            [110.457589848563103, -7.044509342443416],
            [110.457571289836039, -7.044409819849788],
            [110.457571352911685, -7.044355546613878],
            [110.457515382397332, -7.044310253930197],
            [110.457454559773439, -7.044301137816739],
            [110.457389178487404, -7.044319153059837],
            [110.457314449499734, -7.044346202996659],
            [110.45723516182909, -7.044400384282791],
            [110.457160432821013, -7.044427434193226],
            [110.457085693282934, -7.044463529629581],
            [110.456997078908856, -7.044508654492397],
            [110.456926898041772, -7.044517618543322],
            [110.456875706062164, -7.044499467987515],
            [110.456824252196483, -7.044463226021416],
            [110.456782450048365, -7.044399858611177],
            [110.456749702145217, -7.044336501716342],
            [110.456720947063999, -7.044246012807467],
            [110.456674125650494, -7.044119320706335],
            [110.456655609249751, -7.043983615938499],
            [110.456618114514043, -7.043865980219909],
            [110.456552209504224, -7.043847812539061],
            [110.456477218677421, -7.043856770940152],
            [110.456331417970119, -7.043928965865371],
            [110.455922434839025, -7.044281266648767],
            [110.455696748554459, -7.044434778345305],
            [110.455616863159506, -7.044516095291585],
            [110.455527420924682, -7.0445431278036],
            [110.455438314396588, -7.044524932946986],
            [110.455274007890949, -7.044452377191629],
            [110.455178425340307, -7.044407038099664],
            [110.454944903097726, -7.044243946111439],
            [110.454898248919761, -7.044216755088342],
            [110.454856687750905, -7.044189569998073],
            [110.454815084324139, -7.044198567045406],
            [110.454757887240163, -7.044234682550207],
            [110.454685432271901, -7.0442526891605],
            [110.454628288019506, -7.044243576973463],
            [110.454591809299316, -7.044225443335142],
            [110.454566103619214, -7.044189231165117],
            [110.454514158095833, -7.044089669557053],
            [110.454441652798877, -7.043908674036326],
            [110.454327586460522, -7.043700493375149],
            [110.454187751144843, -7.043510373712905],
            [110.45406848622585, -7.04339264240191],
            [110.453949179042439, -7.043311093201626],
            [110.453876483585077, -7.04329291722895],
            [110.453788183875474, -7.043310905207518],
            [110.453689666415471, -7.043356017872456],
            [110.453601281984689, -7.04344637008382],
            [110.45353347831697, -7.043600065145019],
            [110.453528279422656, -7.043690514528985],
            [110.453528205292898, -7.043753833263259],
            [110.453554130273389, -7.043844319014395],
            [110.453595334261323, -7.043934822616542],
            [110.453590114191726, -7.044043363067427],
            [110.453574403989933, -7.044169982351808],
            [110.453511976138515, -7.044323683678121],
            [110.453501422454835, -7.044396035709144],
            [110.453511838451831, -7.044441275609566],
            [110.453573938027404, -7.044567985816466],
            [110.453641077696133, -7.044739929634066],
            [110.453651387819434, -7.044875624864618],
            [110.453652183707661, -7.044920853522422],
            [110.453653764894085, -7.045020356370956],
            [110.453629587481657, -7.04512887466622],
            [110.453641605862941, -7.045255526345879],
            [110.453641510551506, -7.045336936143166],
            [110.453599465193108, -7.045481615735461],
            [110.453539353667608, -7.045590092025521],
            [110.453389558990892, -7.045689417919226],
            [110.453203840770087, -7.045779656203004],
            [110.45310195918124, -7.045797628127859],
            [110.452904431392056, -7.045824533661531],
            [110.452712866660747, -7.045833355002618],
            [110.452539127496351, -7.04584219713793],
            [110.452353536466205, -7.045823888695679],
            [110.4522879037264, -7.045814766268991],
            [110.452257628558272, -7.045814730801008],
            [110.452191691639072, -7.045823699090839],
            [110.452125984561604, -7.045877895355315],
            [110.452095907367266, -7.045950224451702],
            [110.452101732392421, -7.046049732249714],
            [110.452119430516504, -7.046158299504532],
            [110.452167403843788, -7.046266902236866],
            [110.452287224197903, -7.046393680231604],
            [110.452376550155421, -7.046466149220819],
            [110.452436417916672, -7.046565720321195],
            [110.452478145312554, -7.046692406798919],
            [110.452496168942758, -7.04676479225367],
            [110.452483917342093, -7.046837142249486],
            [110.45243431441952, -7.047075604950387],
            [110.452345058443981, -7.047261139631694],
            [110.452263699222073, -7.047315189082102],
            [110.452174620244108, -7.047349892049019],
            [110.451968067168366, -7.047352187807199],
            [110.451283957173445, -7.047588626425835],
            [110.450781955575849, -7.047350741562202],
            [110.450587144020446, -7.047232920603932],
            [110.45055187161249, -7.047151469283758],
            [110.450534563313951, -7.046952447055513],
            [110.450516897506702, -7.046816743187467],
            [110.450296315763453, -7.046237569294582],
            [110.450146161743703, -7.045920798818429],
            [110.450075398275501, -7.045703622630299],
            [110.450022460413095, -7.045486467402739],
            [110.449943155393257, -7.045314508852758],
            [110.449872285462121, -7.045187787916357],
            [110.449767713127329, -7.045088163932527],
            [110.449738089232611, -7.045015764753441],
            [110.449738174555264, -7.044943400535698],
            [110.449752636737458, -7.044916280935135],
            [110.449782703539967, -7.044852997536604],
            [110.449812562033941, -7.044726395110502],
            [110.449820046555956, -7.044617857436672],
            [110.449812807290797, -7.044518347978699],
            [110.449796198867389, -7.044445964117064],
            [110.449781571059845, -7.044373582585433],
            [110.44978961080264, -7.044274091103978],
            [110.449797639877403, -7.044183645149316],
            [110.449793742633943, -7.044129367323008],
            [110.449777972392496, -7.044066029987147],
            [110.449730250779268, -7.043984563975751],
            [110.449678274353914, -7.043912138493704],
            [110.449638464543725, -7.043839727325114],
            [110.449602647961342, -7.043740184233378],
            [110.449570781942938, -7.043649691329293],
            [110.449555022410181, -7.043577308460748],
            [110.449547185312582, -7.043504934916381],
            [110.449547259979681, -7.043441616223825],
            [110.449539097273018, -7.043405424456281],
            [110.449519323201102, -7.043378264562146],
            [110.449399680617759, -7.043341939429766],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 78,
        id: 80,
        area: 5942254.8160319999,
        perimeter: 14098.865361,
        kode_pos: 50271,
        kode_kec: 3374070,
        kode_kel: 3374052002,
        kec: "Tembalang",
        kel: "Meteseh",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.464993959040555, -7.051917024515536],
            [110.465036401376565, -7.051917189472086],
            [110.465073184850368, -7.051917231594699],
            [110.465131199936835, -7.051908252465232],
            [110.465982751345521, -7.051773543290545],
            [110.466406358199848, -7.05174689065646],
            [110.466847811556889, -7.051702166878536],
            [110.467325492762058, -7.051648438424345],
            [110.468061872511541, -7.051522639603778],
            [110.468337234146716, -7.051477725241567],
            [110.468562513649445, -7.051432753744056],
            [110.468923094565341, -7.051342708136731],
            [110.469029524829921, -7.051306646850636],
            [110.469292781226173, -7.051207444755358],
            [110.469725385349093, -7.050981796816474],
            [110.470095112648266, -7.050810350537567],
            [110.470303558609373, -7.050638721125973],
            [110.470897895783708, -7.050512756598522],
            [110.47142145438734, -7.050422893502331],
            [110.471620712283169, -7.05036869461181],
            [110.472163599430317, -7.050450869201716],
            [110.472402074470793, -7.050496366399176],
            [110.472953177895434, -7.050569352949614],
            [110.473051593159653, -7.050614691811679],
            [110.473180423909014, -7.050786702952077],
            [110.473445824409254, -7.051293554156853],
            [110.473591046151228, -7.051483674821312],
            [110.473685500374415, -7.05152900910332],
            [110.474143170599646, -7.05165616251234],
            [110.474660219869335, -7.051819564562759],
            [110.475556048431415, -7.052064801637038],
            [110.475862654777771, -7.052164647083776],
            [110.476525020052364, -7.052436757091897],
            [110.476832506197638, -7.052509466191182],
            [110.477152998042712, -7.052591235229356],
            [110.477849965865204, -7.052791017566245],
            [110.478218539547314, -7.052890930803356],
            [110.478938366758058, -7.05314501058814],
            [110.479643562136573, -7.053326708378489],
            [110.479957244151137, -7.053426559138551],
            [110.479869288806114, -7.053812997397915],
            [110.480042290270774, -7.053799474434596],
            [110.480311318691463, -7.053854636315669],
            [110.480679095794187, -7.053963563643046],
            [110.480669815110716, -7.054034487640291],
            [110.480647341615523, -7.054221675045306],
            [110.480658321219124, -7.054358852773896],
            [110.480718044106595, -7.054460143187906],
            [110.480778232301176, -7.054501651434209],
            [110.48085616519981, -7.054556280058554],
            [110.481019934541621, -7.054572045519837],
            [110.48115332248652, -7.054555332482026],
            [110.481305075846393, -7.054547522356775],
            [110.481436827315093, -7.054571604822242],
            [110.481596345357289, -7.054763271052323],
            [110.481648010030227, -7.054918681045592],
            [110.481642020366067, -7.054938180032834],
            [110.481475218925155, -7.055481196277696],
            [110.481335897695431, -7.055764565603154],
            [110.481173621259913, -7.055856964588626],
            [110.480756442803056, -7.056001155934736],
            [110.480617327367824, -7.056099366675078],
            [110.480559240563352, -7.056249743372256],
            [110.48058217599214, -7.05645807225724],
            [110.480792477621236, -7.057271927538885],
            [110.4809456418606, -7.057737227768123],
            [110.481078643541167, -7.057928320406673],
            [110.481257942144097, -7.058154181700027],
            [110.481344589235832, -7.058362581368352],
            [110.481361720227497, -7.058582476188151],
            [110.48126883652489, -7.058773317714669],
            [110.481147076810785, -7.058888906453958],
            [110.480932639459297, -7.059010178289087],
            [110.480695053568269, -7.059114065646146],
            [110.480301188296167, -7.059125199602373],
            [110.480029044338295, -7.059055462015126],
            [110.479878712224036, -7.058823846356353],
            [110.47972249136717, -7.05867901714264],
            [110.47947349613456, -7.058626663669639],
            [110.479079689562369, -7.058585720863483],
            [110.478604627552301, -7.058695128220909],
            [110.478256910264179, -7.058874111884533],
            [110.477955645008223, -7.058948995604358],
            [110.47776438116594, -7.059064505642118],
            [110.47767156731264, -7.059191698199066],
            [110.477706014003743, -7.05946368821547],
            [110.477757836578547, -7.05973569767599],
            [110.477699689931754, -7.059938149638381],
            [110.47763585511953, -7.060048016003041],
            [110.477438720863191, -7.0602329537021],
            [110.477299090359551, -7.060788272569399],
            [110.477142369460566, -7.061088979388339],
            [110.476742281504769, -7.061481992437378],
            [110.47651616688195, -7.061684255758104],
            [110.47644645496726, -7.061869335913842],
            [110.476446253250529, -7.062048707873525],
            [110.476463355895277, -7.062291747447809],
            [110.476422506156766, -7.06256365297113],
            [110.476260050633002, -7.062812277156884],
            [110.475987488469244, -7.063112853370778],
            [110.475784501579398, -7.063349859417184],
            [110.475668427035004, -7.063558032084721],
            [110.475656578584704, -7.063806067304108],
            [110.475747395426168, -7.064068490934276],
            [110.476038105206513, -7.064455308341962],
            [110.476252129525676, -7.064704355314065],
            [110.476715256282333, -7.064913178421722],
            [110.477068380653392, -7.065081374349186],
            [110.477305625900783, -7.065284157337926],
            [110.477421173893489, -7.065544665834162],
            [110.477507872326726, -7.065706776586143],
            [110.477663912762679, -7.06601362000761],
            [110.477727378281969, -7.066233566694533],
            [110.477611180318902, -7.066551677483068],
            [110.477535643427856, -7.066765682211617],
            [110.477292156762644, -7.066962140032977],
            [110.476787930334879, -7.067245098107008],
            [110.475930375411707, -7.067539230869763],
            [110.475269866694191, -7.067729432294637],
            [110.474394163002259, -7.06793847057598],
            [110.473857691399687, -7.067919774177683],
            [110.472562557403279, -7.06769217131732],
            [110.472153810882673, -7.067668052040282],
            [110.47184089592092, -7.067794993849855],
            [110.471429647488861, -7.067806100063374],
            [110.47104237449814, -7.067798995416739],
            [110.470764863054086, -7.06773536147483],
            [110.470426993993712, -7.06749979314082],
            [110.470285051321127, -7.067409176247609],
            [110.470046177290087, -7.067209902395616],
            [110.469811213351207, -7.067055860684136],
            [110.46959445177076, -7.066820429533263],
            [110.469554874253589, -7.066539972132806],
            [110.469587589834902, -7.066386234829499],
            [110.469721934500555, -7.065943155183588],
            [110.469776582076335, -7.065662804946498],
            [110.469765794079606, -7.065445699214238],
            [110.469747070492545, -7.06525247815538],
            [110.469602481216214, -7.065067155543045],
            [110.469423087229018, -7.064928082831481],
            [110.469145110432194, -7.064893049299151],
            [110.468907492841097, -7.065020074948209],
            [110.46866962407006, -7.065366975463673],
            [110.468286584225353, -7.066037737000349],
            [110.468094924955224, -7.066494627462285],
            [110.467914822874391, -7.066974675762725],
            [110.467604787514702, -7.067469441608236],
            [110.467256795452897, -7.067921321847478],
            [110.466911163496135, -7.068402717896151],
            [110.466829796231238, -7.068645644768266],
            [110.466777409050337, -7.068871246208779],
            [110.466701973006266, -7.068992669861045],
            [110.46654552775226, -7.069044566508839],
            [110.466192218927091, -7.069038375762422],
            [110.465846641147451, -7.06905040223097],
            [110.465277080709086, -7.069031540446924],
            [110.46447190507962, -7.069100050139204],
            [110.46389693067357, -7.069147664504077],
            [110.463766465157093, -7.069165605503471],
            [110.463447905330497, -7.069364240952173],
            [110.463341900139042, -7.069579003662025],
            [110.463185046956056, -7.069983855985142],
            [110.463097738407939, -7.07035407125708],
            [110.463131258767106, -7.071091576255017],
            [110.463255071546385, -7.071444495340566],
            [110.463345234664885, -7.071708323754394],
            [110.463559261476917, -7.071951590043014],
            [110.463954294591957, -7.072159898875261],
            [110.464369998683736, -7.072377470270461],
            [110.464670743252157, -7.072473625408587],
            [110.464861729283157, -7.072606927146298],
            [110.465022428167288, -7.072694814329039],
            [110.465185880507647, -7.072786671222947],
            [110.465417557950559, -7.072792723361515],
            [110.465690985237345, -7.072767946122774],
            [110.465952473770926, -7.072741109344776],
            [110.466370399009193, -7.072750633876653],
            [110.467103365314372, -7.072670063025068],
            [110.467219086613284, -7.072679240956609],
            [110.467283277639908, -7.072715496593085],
            [110.467330459826485, -7.072778869435006],
            [110.467368818359816, -7.072887459956887],
            [110.467398877709456, -7.073077450979296],
            [110.467419433469004, -7.073412160007536],
            [110.467367540300785, -7.073755831735676],
            [110.46720778303343, -7.07409938005738],
            [110.466919841264712, -7.074528360320509],
            [110.466647244979086, -7.074917484053153],
            [110.466455574487924, -7.075292004630718],
            [110.466285986764873, -7.075651854722704],
            [110.466182808501955, -7.07582808473547],
            [110.466109144671677, -7.075923522263526],
            [110.465954621976721, -7.075974779963301],
            [110.464814364402159, -7.076120427520505],
            [110.463902225803878, -7.076178160851583],
            [110.463181438133731, -7.076140591107551],
            [110.462618932139648, -7.075981963593547],
            [110.462207123018743, -7.075900661847],
            [110.461706947097539, -7.075907431674686],
            [110.460853635162195, -7.075979922723486],
            [110.460250415324666, -7.076045354350645],
            [110.4600149143278, -7.076155298847406],
            [110.459778951854403, -7.076662025927569],
            [110.459638489637797, -7.077271733430556],
            [110.459558585228862, -7.078029147166889],
            [110.459484965830455, -7.077941126702608],
            [110.459420890944756, -7.07783920332011],
            [110.459363384037076, -7.077770103165396],
            [110.459354901702511, -7.077759910811418],
            [110.459156914345272, -7.077539008042878],
            [110.459047443172764, -7.077508703364359],
            [110.459034125856334, -7.077595448117907],
            [110.458967172973431, -7.077686644655985],
            [110.458870969190968, -7.077747399231194],
            [110.458698681147624, -7.077867598424794],
            [110.458573971503967, -7.077952327509613],
            [110.458442599793628, -7.078083258053569],
            [110.458336726479686, -7.078205730816465],
            [110.458211959480437, -7.078339498107465],
            [110.458033353461104, -7.078498664904863],
            [110.457897269038796, -7.07862298846062],
            [110.457753625760333, -7.078752961438082],
            [110.457666703037489, -7.078815101173379],
            [110.457570313633482, -7.078899862953906],
            [110.457453129256351, -7.079007233565778],
            [110.457324601123403, -7.079127793555123],
            [110.457206210284753, -7.079267872152466],
            [110.456990144199239, -7.079476974062641],
            [110.456825840956782, -7.079635977244002],
            [110.456671498896455, -7.079763424357694],
            [110.456545787410477, -7.079895305792335],
            [110.456424532880163, -7.079983182983991],
            [110.456188872525587, -7.080129857797453],
            [110.456037356560302, -7.08019500715984],
            [110.455903009298922, -7.080294558893454],
            [110.456047476563754, -7.080370368968167],
            [110.455906056275396, -7.080631509462381],
            [110.455725069101732, -7.080880569793528],
            [110.455456192515911, -7.081234393326242],
            [110.455418356768121, -7.081213719633778],
            [110.455256095344893, -7.081636432487746],
            [110.455107984261005, -7.081732529590559],
            [110.45490798853902, -7.081958900366502],
            [110.454254898336771, -7.081542047649237],
            [110.454056641583108, -7.08120894543707],
            [110.453903331425252, -7.080799079853066],
            [110.453594039465344, -7.080345018571915],
            [110.453359002138441, -7.080477983698858],
            [110.453159815007908, -7.080558285286306],
            [110.452994035750734, -7.080595933298333],
            [110.452847287673748, -7.080631017062393],
            [110.4526819299154, -7.080719836961874],
            [110.452480851068415, -7.080874970936443],
            [110.452374412685074, -7.080985847262801],
            [110.452146681043217, -7.081233871949291],
            [110.451789859271997, -7.081566850897384],
            [110.45140393011809, -7.08185286035998],
            [110.451063419656435, -7.082091988219945],
            [110.450739190594689, -7.082263160433468],
            [110.450643318300877, -7.082300822895663],
            [110.450520195103721, -7.082305407832558],
            [110.450439454882414, -7.082272517078714],
            [110.450369863988456, -7.08220931559768],
            [110.450199974539615, -7.08201975705638],
            [110.450007430262744, -7.081807513483406],
            [110.449703116489076, -7.081578953222053],
            [110.449499133858041, -7.081449236499533],
            [110.449312959307107, -7.08133086987129],
            [110.449060321243948, -7.081244793320828],
            [110.448757392105236, -7.081215302534593],
            [110.448754474675212, -7.080943400780274],
            [110.448740083777423, -7.080783157946114],
            [110.448706209113837, -7.080658497755959],
            [110.448641569399953, -7.080519235128833],
            [110.448544538779473, -7.080370223454042],
            [110.448423142928277, -7.079966147681641],
            [110.447936719136308, -7.079236593210527],
            [110.447724257456755, -7.078979356660886],
            [110.44748232959688, -7.07873484037046],
            [110.447323487330522, -7.078569067230172],
            [110.447260422179795, -7.078468648931077],
            [110.447263722120525, -7.078418481147101],
            [110.447294600631224, -7.078337595714919],
            [110.447335256761917, -7.078200979867225],
            [110.447368818820067, -7.078056291340938],
            [110.447387388207304, -7.077967082654665],
            [110.447392331346805, -7.077897495513023],
            [110.447371343023789, -7.077835969794729],
            [110.447292239858072, -7.077730561564924],
            [110.447240249709793, -7.077667181163283],
            [110.447196433253879, -7.077630947034394],
            [110.447143506825526, -7.077608452682689],
            [110.447089282740166, -7.077585406665063],
            [110.44712655770806, -7.077533755797953],
            [110.44727177405187, -7.077332534223089],
            [110.447581548960727, -7.076916807830375],
            [110.447930981248078, -7.07646494611096],
            [110.44817556224379, -7.075904413499694],
            [110.448392609266961, -7.075416212292923],
            [110.448631476151988, -7.074900900197124],
            [110.448899605621975, -7.074286121757846],
            [110.44917140212074, -7.073680392959099],
            [110.449488754731959, -7.073074717778907],
            [110.449712819371598, -7.072631751631886],
            [110.449962392896765, -7.072152633338495],
            [110.450268584358483, -7.0718906744039],
            [110.450672786897982, -7.071538375510264],
            [110.450844149282858, -7.07139384903963],
            [110.451155433054495, -7.07113189544025],
            [110.451388555296333, -7.070924122768449],
            [110.451546650097185, -7.070752443781304],
            [110.451750744362286, -7.070445135864642],
            [110.451785318857262, -7.070399948880279],
            [110.451823582493915, -7.070345720699844],
            [110.451863565208825, -7.070273403471335],
            [110.451923931713097, -7.070192064681581],
            [110.45197466688731, -7.070119760088096],
            [110.452034761050015, -7.070029375426599],
            [110.452096542291812, -7.069948038272642],
            [110.452138505647838, -7.069875723342377],
            [110.452195759489129, -7.069794380848861],
            [110.452528242124913, -7.06955054216427],
            [110.453009112380997, -7.069207376521236],
            [110.453210290301001, -7.068972428683326],
            [110.453247704771471, -7.068918199364873],
            [110.453336366806155, -7.068836893550629],
            [110.45341115316505, -7.06876461697959],
            [110.453406700225401, -7.068701293004511],
            [110.453406764006743, -7.068647019862683],
            [110.453429987938449, -7.068628956026955],
            [110.453453537344529, -7.068574710426783],
            [110.453472308321935, -7.068493322612706],
            [110.453504912412612, -7.068439087626449],
            [110.453542348057141, -7.068366767229262],
            [110.453579773063083, -7.068303492351996],
            [110.453617166181942, -7.068267354043131],
            [110.453621757281397, -7.068213086205692],
            [110.453607956035782, -7.068158796807116],
            [110.45361733618833, -7.068122625656847],
            [110.453645370389594, -7.068104567446274],
            [110.453692090512433, -7.068077485599273],
            [110.453720156577774, -7.068032290810669],
            [110.453748222637472, -7.067987096019869],
            [110.453790132439494, -7.067960008524025],
            [110.453874224368391, -7.067914879375995],
            [110.453925754762409, -7.067887803138975],
            [110.453967664545914, -7.067860715625573],
            [110.453991171375321, -7.067842652088069],
            [110.453991224463124, -7.067797424463796],
            [110.453991277550571, -7.067752196839437],
            [110.454042546193932, -7.067707029206009],
            [110.454073169447113, -7.06765279184888],
            [110.454051226049998, -7.067544219698359],
            [110.454058752829695, -7.067399499913592],
            [110.454095381878517, -7.067290996360718],
            [110.454102940477426, -7.067119139996441],
            [110.454124834421862, -7.067028710257386],
            [110.454175851847864, -7.066956405693064],
            [110.454205102685975, -7.066865984561849],
            [110.454197820052627, -7.066802657269442],
            [110.45415431875908, -7.066739287570171],
            [110.454161456391816, -7.06668502269971],
            [110.454168951266382, -7.066567439481011],
            [110.454205548375683, -7.066486072485739],
            [110.454190940668227, -7.066395600002523],
            [110.454198424913884, -7.066287062306734],
            [110.454227718137531, -7.066160459064567],
            [110.454242591101391, -7.066024793400365],
            [110.454213552546122, -7.065934304024068],
            [110.454228057664551, -7.065871002235403],
            [110.454293778127749, -7.065807760393202],
            [110.454337438480493, -7.065735447184363],
            [110.454315799250224, -7.065608784318385],
            [110.454334559312485, -7.065536441964807],
            [110.454439023683292, -7.065491336514647],
            [110.454530450782244, -7.065464306865646],
            [110.454569562538396, -7.06541007938567],
            [110.454628523713922, -7.065319692962181],
            [110.45473274745062, -7.065238405001056],
            [110.454817783038067, -7.065111866881301],
            [110.454915749849135, -7.065057708176095],
            [110.454987433393427, -7.06497638210918],
            [110.455013656139855, -7.064813593039627],
            [110.455046510948137, -7.064786494817085],
            [110.455111665230248, -7.064723252175058],
            [110.45515086157495, -7.064596660411912],
            [110.455183737538562, -7.064551471121827],
            [110.455268405211783, -7.064497296791277],
            [110.455346879571238, -7.064415978588486],
            [110.455438454592851, -7.064262311342466],
            [110.455529630191577, -7.064208144557054],
            [110.455660094445534, -7.064190205777723],
            [110.455771013517506, -7.064190335234729],
            [110.455842633321268, -7.06416328218614],
            [110.455895064930203, -7.064090979031191],
            [110.45590171040665, -7.063973394744512],
            [110.455940790151885, -7.063946303712163],
            [110.456012682293448, -7.063928296493063],
            [110.456117135587192, -7.06389223615335],
            [110.456149749589713, -7.063828955393515],
            [110.456188839878067, -7.063792818808447],
            [110.456273726857759, -7.063792917783275],
            [110.456364860014602, -7.06377493293906],
            [110.456520004728134, -7.063702749420397],
            [110.456554022883978, -7.063648515805588],
            [110.456578388828106, -7.063621407569172],
            [110.456583262453435, -7.063567139991568],
            [110.456588178319279, -7.063476690291894],
            [110.456622521644277, -7.063386274877224],
            [110.456671232375058, -7.063350149453675],
            [110.456734373871356, -7.063314040833649],
            [110.456792747325764, -7.063241744478512],
            [110.456821976268145, -7.063169414173909],
            [110.456827196144317, -7.063060873733891],
            [110.456846542712611, -7.062970440830626],
            [110.45687601236645, -7.062934292973799],
            [110.4569439323854, -7.062925326514072],
            [110.457007314553323, -7.062925400305105],
            [110.457067343449879, -7.062889288009509],
            [110.457102514401569, -7.062816964597888],
            [110.457104600587911, -7.062726511588183],
            [110.45710980983651, -7.06262701666912],
            [110.457172982844284, -7.062563771383456],
            [110.457348510401147, -7.062482565721124],
            [110.45752401681932, -7.062419451052346],
            [110.457672999113427, -7.062292986663081],
            [110.457787156707894, -7.062184572831906],
            [110.457918910161254, -7.062030951689894],
            [110.458068280473867, -7.061814032150342],
            [110.458226348578151, -7.061660441472634],
            [110.458427991696382, -7.061506901319994],
            [110.458542264635014, -7.0612989863959],
            [110.458665203907657, -7.061181536956921],
            [110.458814436911965, -7.061082209075165],
            [110.458963596280171, -7.061046199885294],
            [110.459279122691967, -7.061019429038832],
            [110.459393248101037, -7.06093815136347],
            [110.45947229755582, -7.060847787473431],
            [110.459735299159817, -7.060730499967406],
            [110.459814338020777, -7.06064918154729],
            [110.459858311442702, -7.060549731421444],
            [110.459955008840666, -7.060368932377745],
            [110.460051601276447, -7.060278588676082],
            [110.460208871916279, -7.059835538725601],
            [110.460308199582059, -7.059093918539674],
            [110.46045658257286, -7.058261899493694],
            [110.460355358415569, -7.057710128154699],
            [110.460378005285477, -7.057700984526507],
            [110.460401647556637, -7.05756532860013],
            [110.460441449364041, -7.056914094954775],
            [110.460543856742291, -7.056199614765847],
            [110.460801939168178, -7.05519585664848],
            [110.461070972805956, -7.054752935170943],
            [110.461162491589221, -7.054644494153425],
            [110.461325963577352, -7.054463771696846],
            [110.461501602333698, -7.054283063202279],
            [110.461660724458824, -7.053948561176528],
            [110.461737005381636, -7.053803920239546],
            [110.461971926591005, -7.053496642091847],
            [110.462467434412588, -7.053198709334101],
            [110.46251358662704, -7.053171625782175],
            [110.462667982302975, -7.053008983433357],
            [110.463152065320784, -7.052557262268396],
            [110.463452200646756, -7.052376695986237],
            [110.463929483196196, -7.052178241705487],
            [110.464267753862799, -7.052051991967113],
            [110.464905930376133, -7.051944176717565],
            [110.464993959040555, -7.051917024515536],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 79,
        id: 81,
        area: 2891581.8249169998,
        perimeter: 9451.614913,
        kode_pos: 50277,
        kode_kec: 3374070,
        kode_kel: 3374052005,
        kec: "Tembalang",
        kel: "Bulusan",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.451283957173445, -7.047588626425835],
            [110.451968067168366, -7.047352187807199],
            [110.452174620244108, -7.047349892049019],
            [110.452263699222073, -7.047315189082102],
            [110.452345058443981, -7.047261139631694],
            [110.45243431441952, -7.047075604950387],
            [110.452483917342093, -7.046837142249486],
            [110.452496168942758, -7.04676479225367],
            [110.452478145312554, -7.046692406798919],
            [110.452436417916672, -7.046565720321195],
            [110.452376550155421, -7.046466149220819],
            [110.452287224197903, -7.046393680231604],
            [110.452167403843788, -7.046266902236866],
            [110.452119430516504, -7.046158299504532],
            [110.452101732392421, -7.046049732249714],
            [110.452095907367266, -7.045950224451702],
            [110.452125984561604, -7.045877895355315],
            [110.452191691639072, -7.045823699090839],
            [110.452257628558272, -7.045814730801008],
            [110.4522879037264, -7.045814766268991],
            [110.452353536466205, -7.045823888695679],
            [110.452539127496351, -7.04584219713793],
            [110.452712866660747, -7.045833355002618],
            [110.452904431392056, -7.045824533661531],
            [110.45310195918124, -7.045797628127859],
            [110.453203840770087, -7.045779656203004],
            [110.453389558990892, -7.045689417919226],
            [110.453539353667608, -7.045590092025521],
            [110.453599465193108, -7.045481615735461],
            [110.453641510551506, -7.045336936143166],
            [110.453641605862941, -7.045255526345879],
            [110.453629587481657, -7.04512887466622],
            [110.453653764894085, -7.045020356370956],
            [110.453652183707661, -7.044920853522422],
            [110.453651387819434, -7.044875624864618],
            [110.453641077696133, -7.044739929634066],
            [110.453573938027404, -7.044567985816466],
            [110.453511838451831, -7.044441275609566],
            [110.453501422454835, -7.044396035709144],
            [110.453511976138515, -7.044323683678121],
            [110.453574403989933, -7.044169982351808],
            [110.453590114191726, -7.044043363067427],
            [110.453595334261323, -7.043934822616542],
            [110.453554130273389, -7.043844319014395],
            [110.453528205292898, -7.043753833263259],
            [110.453528279422656, -7.043690514528985],
            [110.45353347831697, -7.043600065145019],
            [110.453601281984689, -7.04344637008382],
            [110.453689666415471, -7.043356017872456],
            [110.453788183875474, -7.043310905207518],
            [110.453876483585077, -7.04329291722895],
            [110.453949179042439, -7.043311093201626],
            [110.45406848622585, -7.04339264240191],
            [110.454187751144843, -7.043510373712905],
            [110.454327586460522, -7.043700493375149],
            [110.454441652798877, -7.043908674036326],
            [110.454514158095833, -7.044089669557053],
            [110.454566103619214, -7.044189231165117],
            [110.454591809299316, -7.044225443335142],
            [110.454628288019506, -7.044243576973463],
            [110.454685432271901, -7.0442526891605],
            [110.454757887240163, -7.044234682550207],
            [110.454815084324139, -7.044198567045406],
            [110.454856687750905, -7.044189569998073],
            [110.454898248919761, -7.044216755088342],
            [110.454944903097726, -7.044243946111439],
            [110.455178425340307, -7.044407038099664],
            [110.455274007890949, -7.044452377191629],
            [110.455438314396588, -7.044524932946986],
            [110.455527420924682, -7.0445431278036],
            [110.455616863159506, -7.044516095291585],
            [110.455696748554459, -7.044434778345305],
            [110.455922434839025, -7.044281266648767],
            [110.456331417970119, -7.043928965865371],
            [110.456477218677421, -7.043856770940152],
            [110.456552209504224, -7.043847812539061],
            [110.456618114514043, -7.043865980219909],
            [110.456655609249751, -7.043983615938499],
            [110.456674125650494, -7.044119320706335],
            [110.456720947063999, -7.044246012807467],
            [110.456749702145217, -7.044336501716342],
            [110.456782450048365, -7.044399858611177],
            [110.456824252196483, -7.044463226021416],
            [110.456875706062164, -7.044499467987515],
            [110.456926898041772, -7.044517618543322],
            [110.456997078908856, -7.044508654492397],
            [110.457085693282934, -7.044463529629581],
            [110.457160432821013, -7.044427434193226],
            [110.45723516182909, -7.044400384282791],
            [110.457314449499734, -7.044346202996659],
            [110.457389178487404, -7.044319153059837],
            [110.457454559773439, -7.044301137816739],
            [110.457515382397332, -7.044310253930197],
            [110.457571352911685, -7.044355546613878],
            [110.457571289836039, -7.044409819849788],
            [110.457589848563103, -7.044509342443416],
            [110.457612913392467, -7.044626961366117],
            [110.457687484750849, -7.04473559447478],
            [110.457785572119292, -7.044817118187805],
            [110.457936821702532, -7.044925840169798],
            [110.458023339913851, -7.044980213770777],
            [110.458126813858371, -7.045052698111128],
            [110.458241071261824, -7.045098058268449],
            [110.458360725648333, -7.045125333546521],
            [110.458430560668631, -7.045170642199674],
            [110.458461097778454, -7.045188768673151],
            [110.458479646219857, -7.045297336785365],
            [110.458437172421782, -7.045568654163144],
            [110.458347488612873, -7.045803734629999],
            [110.458253644531766, -7.045966445834659],
            [110.458187392542911, -7.046246781164641],
            [110.458192045073204, -7.046382469831795],
            [110.458229592883995, -7.046454877759898],
            [110.458313837332412, -7.046518294244217],
            [110.45842669087358, -7.046554607203097],
            [110.458746189647968, -7.04650974946372],
            [110.45908435234081, -7.046473958649728],
            [110.459215922333598, -7.046474110865145],
            [110.459258091804202, -7.046465114090668],
            [110.459277112096885, -7.04641086277147],
            [110.459141078041085, -7.046112202158368],
            [110.459117740315136, -7.045985537415105],
            [110.459136802538168, -7.04589510393595],
            [110.45926854007908, -7.045750527463186],
            [110.459383489426585, -7.045687341517805],
            [110.459519879758844, -7.045678453650869],
            [110.45979684177459, -7.045714955953124],
            [110.459951801853279, -7.045796544960035],
            [110.460031414640994, -7.045950411340278],
            [110.460092793334809, -7.046212803306842],
            [110.460134533666846, -7.04657467368811],
            [110.460143787166885, -7.046647048809445],
            [110.460167187989811, -7.046719440267847],
            [110.460298517416803, -7.046927639660564],
            [110.460340917548322, -7.046963870829209],
            [110.460566017523092, -7.047072677299394],
            [110.460674543558852, -7.047181349183746],
            [110.460716881008082, -7.047271853579455],
            [110.460721366332848, -7.047308040974054],
            [110.460674271680858, -7.04741653329778],
            [110.460678904007736, -7.047570313074924],
            [110.460678705319111, -7.04774217838653],
            [110.460692769014557, -7.04781455905175],
            [110.460791067320955, -7.047959401338741],
            [110.460997556817759, -7.048258142825804],
            [110.461097028897697, -7.048366804193031],
            [110.461186084208322, -7.048430225756801],
            [110.461326908498847, -7.048502752531546],
            [110.461453554124347, -7.048602399595335],
            [110.461646105735923, -7.048720213663924],
            [110.461791802928673, -7.04873847258704],
            [110.461956205989253, -7.048729616332947],
            [110.46205019679455, -7.048684496750832],
            [110.462092460315176, -7.048594089832465],
            [110.462073963106747, -7.048440294082472],
            [110.46207095499777, -7.048349835055033],
            [110.462099029384405, -7.048295594027392],
            [110.462174345556349, -7.048250452919064],
            [110.46224932662841, -7.048250539196064],
            [110.462366697691138, -7.048295902009929],
            [110.462498132769312, -7.048413645427628],
            [110.462836360969462, -7.048567808768262],
            [110.462960187902354, -7.048658406651366],
            [110.462997757466553, -7.048712723168053],
            [110.46299769498286, -7.048766996442607],
            [110.462964485944298, -7.048857413859029],
            [110.462912968605181, -7.048875445770826],
            [110.462767177531731, -7.048938597098993],
            [110.462503671162381, -7.049254888595753],
            [110.462470703236505, -7.049381488469712],
            [110.462465663759914, -7.049580484919677],
            [110.462512843146968, -7.049643858085514],
            [110.462700616610476, -7.049734529599598],
            [110.463001045288877, -7.049789148292457],
            [110.46330163208286, -7.049952313671507],
            [110.463738275787207, -7.050151817274563],
            [110.46394166389274, -7.050197278448961],
            [110.464401959117026, -7.050252079655341],
            [110.464566269260914, -7.050324632447523],
            [110.464843247679127, -7.050596316558694],
            [110.464961240129014, -7.050840681806123],
            [110.465059667683931, -7.051121206868026],
            [110.465200275570723, -7.051383689083014],
            [110.465200140692389, -7.051501281206867],
            [110.465167256288197, -7.051555516919239],
            [110.465007244465525, -7.051681971535238],
            [110.464913232512671, -7.051745182777336],
            [110.464899053883599, -7.051772303214482],
            [110.464908360079534, -7.051799450552214],
            [110.464993959040555, -7.051917024515536],
            [110.464905930376133, -7.051944176717565],
            [110.464267753862799, -7.052051991967113],
            [110.463929483196196, -7.052178241705487],
            [110.463452200646756, -7.052376695986237],
            [110.463152065320784, -7.052557262268396],
            [110.462667982302975, -7.053008983433357],
            [110.46251358662704, -7.053171625782175],
            [110.462467434412588, -7.053198709334101],
            [110.461971926591005, -7.053496642091847],
            [110.461737005381636, -7.053803920239546],
            [110.461660724458824, -7.053948561176528],
            [110.461501602333698, -7.054283063202279],
            [110.461325963577352, -7.054463771696846],
            [110.461162491589221, -7.054644494153425],
            [110.461070972805956, -7.054752935170943],
            [110.460801939168178, -7.05519585664848],
            [110.460543856742291, -7.056199614765847],
            [110.460441449364041, -7.056914094954775],
            [110.460401647556637, -7.05756532860013],
            [110.460378005285477, -7.057700984526507],
            [110.460355358415569, -7.057710128154699],
            [110.460305537855461, -7.057728037393129],
            [110.459629520954479, -7.057763437456468],
            [110.458910453128951, -7.05783496882129],
            [110.458633127555956, -7.057861783891152],
            [110.458287274715431, -7.057933747002935],
            [110.458053460716542, -7.058014885501178],
            [110.457533988848382, -7.058231375150815],
            [110.456885553072965, -7.058393440856397],
            [110.456310822058484, -7.058437999418068],
            [110.45599285645396, -7.058374310097175],
            [110.455303553176265, -7.058156413110633],
            [110.454758450750461, -7.058029139047763],
            [110.45430214290505, -7.057947195899543],
            [110.453583612207794, -7.057801626629394],
            [110.452874366361527, -7.05770129486718],
            [110.452318545044207, -7.057546868861955],
            [110.452274132118589, -7.057537771209974],
            [110.452078948015696, -7.057492314442734],
            [110.451963796960499, -7.05748313372161],
            [110.451877485786568, -7.057492077915297],
            [110.45176251123965, -7.05757335273166],
            [110.451579164030605, -7.057808321327304],
            [110.451406243277603, -7.058079484238485],
            [110.451300360879529, -7.058368816959266],
            [110.45120400282741, -7.058739570672441],
            [110.451127013358004, -7.059001800691616],
            [110.451050141069274, -7.059164529927562],
            [110.450954342820722, -7.059300100270505],
            [110.450800811294286, -7.059444648182221],
            [110.450599145517771, -7.059616275973204],
            [110.450532000090263, -7.059688561196976],
            [110.450435811880084, -7.059914586302587],
            [110.450298846146396, -7.060167699945884],
            [110.450039473085326, -7.060565397819583],
            [110.449684215028654, -7.061171029689851],
            [110.449290730910676, -7.061559523293577],
            [110.44916572305489, -7.061749331891168],
            [110.448868138421659, -7.062156029354518],
            [110.448647427032384, -7.062399997887293],
            [110.448455358221906, -7.06258972697793],
            [110.448225015194538, -7.062842729415501],
            [110.448052330540065, -7.063150073134972],
            [110.447992866910027, -7.063186184883199],
            [110.44782984142816, -7.063222174029563],
            [110.447676479358037, -7.06322199244175],
            [110.447436826500052, -7.063212663051296],
            [110.447063074098764, -7.063185083521947],
            [110.446948194047806, -7.063184947315197],
            [110.446478379893293, -7.063274845268187],
            [110.446123477960853, -7.063337742631319],
            [110.445943539583027, -7.063319437854806],
            [110.445789142637167, -7.06323784467247],
            [110.445532108251214, -7.063092810753005],
            [110.445416465601539, -7.063020309052733],
            [110.445306983337957, -7.063002087810343],
            [110.445236233658576, -7.063011049191727],
            [110.445152424891404, -7.063056177131545],
            [110.445068802009899, -7.063182715008992],
            [110.445010537247654, -7.063399738299691],
            [110.444971753238661, -7.063652966863073],
            [110.444861731405297, -7.064087021128851],
            [110.444790808902866, -7.064240710624227],
            [110.444615901016093, -7.064511868093689],
            [110.444511077556101, -7.064620289532539],
            [110.444446269709843, -7.064629257851425],
            [110.444361393546714, -7.064620111200583],
            [110.444206953294398, -7.064574699531589],
            [110.443842853148411, -7.064519992287046],
            [110.443404085075571, -7.064438059080017],
            [110.443080154153066, -7.064392444720281],
            [110.442710950304786, -7.064346776039351],
            [110.442347079365973, -7.06433729553418],
            [110.442017739971021, -7.06431881054456],
            [110.441761990935859, -7.064282322382095],
            [110.441476542409163, -7.064236752992935],
            [110.441250514737646, -7.064191254632163],
            [110.4410078254772, -7.064118454098992],
            [110.441012852836309, -7.063937695272513],
            [110.441029166004796, -7.063783940993211],
            [110.441041430874392, -7.063702546016719],
            [110.441070008874391, -7.063467396754551],
            [110.441100360596849, -7.063168930972195],
            [110.441128089647307, -7.062933780679046],
            [110.441195595265157, -7.06256299523056],
            [110.441242129519438, -7.062219321199869],
            [110.44125381752329, -7.062146971035881],
            [110.441265265992087, -7.062038438501824],
            [110.441281187451622, -7.061975138927211],
            [110.441344460036376, -7.06183048637322],
            [110.441423610594271, -7.061658716255755],
            [110.441553507590029, -7.061405597176627],
            [110.441664131003463, -7.061179591546853],
            [110.441759746886547, -7.060962613445551],
            [110.441851401317265, -7.060745630576737],
            [110.441940258638382, -7.060501507769216],
            [110.44205724749699, -7.060157917827292],
            [110.442078054839314, -7.060031305391332],
            [110.442116546157806, -7.059787122300547],
            [110.442212019385494, -7.059452552085956],
            [110.442311345489387, -7.059208441673537],
            [110.442399005431838, -7.059018590431391],
            [110.442471854296755, -7.058910131184649],
            [110.442578754905171, -7.058720302881917],
            [110.442693087953629, -7.058467164753029],
            [110.442769483583433, -7.058232072340414],
            [110.442815341903881, -7.057978852404566],
            [110.442834440365701, -7.057861283384344],
            [110.442857956193237, -7.057598991240158],
            [110.442869490638301, -7.057418094512816],
            [110.44287734669318, -7.057237193392933],
            [110.442854622299848, -7.057074346823207],
            [110.442633941482867, -7.057055992332431],
            [110.442254274249962, -7.057010311234903],
            [110.441900692856692, -7.056919433404505],
            [110.441563522817674, -7.056828574949048],
            [110.441313458632123, -7.056774002605064],
            [110.440894548411606, -7.056655909285309],
            [110.440879615645414, -7.056011267140941],
            [110.44121157075962, -7.055851237339684],
            [110.441690605753394, -7.055616626811168],
            [110.442240399652363, -7.055364009233791],
            [110.442708649112802, -7.05515652126949],
            [110.443538463733702, -7.054750462235294],
            [110.44440930478909, -7.054344450420702],
            [110.445182472808824, -7.053983548477862],
            [110.445617876036849, -7.053794109413959],
            [110.445930141958215, -7.053649751499069],
            [110.446218596922819, -7.053541547251786],
            [110.446409129634773, -7.053451317820061],
            [110.446468885761774, -7.053406160987965],
            [110.446523538040495, -7.053370043630695],
            [110.44653968765985, -7.053351971705085],
            [110.446545378875967, -7.053324841853787],
            [110.446545411096054, -7.053297705296446],
            [110.446490919943017, -7.053198139871466],
            [110.446414935381966, -7.053089503435209],
            [110.446398588758427, -7.053035210869816],
            [110.44639334162575, -7.052926658269605],
            [110.446393449048927, -7.05283620307914],
            [110.446431754642546, -7.052745793160658],
            [110.446518770469893, -7.052619258831252],
            [110.446578852376575, -7.052537920231805],
            [110.446671777848138, -7.052438529465464],
            [110.446747944869756, -7.052393392023599],
            [110.446791572719349, -7.052348216032446],
            [110.446802715072934, -7.052257773902324],
            [110.446791819592335, -7.052140169074653],
            [110.446753728808915, -7.052049668636593],
            [110.446672346848686, -7.051959116919351],
            [110.446628837127335, -7.051904792184626],
            [110.4466127841944, -7.051841454440682],
            [110.44662077118069, -7.051787190706996],
            [110.446651100385665, -7.051741998970824],
            [110.446676597984961, -7.051714892574637],
            [110.446713664278988, -7.05171493647677],
            [110.44680419716633, -7.051724089226381],
            [110.446922416122732, -7.051769456870131],
            [110.447064087678697, -7.051841988845402],
            [110.447170695062425, -7.05189638821933],
            [110.447268248105047, -7.051950776857389],
            [110.447353949479748, -7.051978014854972],
            [110.447405152527736, -7.051987120961806],
            [110.447449303293666, -7.05197812765403],
            [110.447472547991964, -7.051941973013508],
            [110.447481928187372, -7.051905801973201],
            [110.447481992505288, -7.05185152884658],
            [110.447486626882707, -7.05176107898974],
            [110.44748894407023, -7.051715854061121],
            [110.447509957316697, -7.051652560176203],
            [110.447547066483054, -7.051616421926639],
            [110.447640199500412, -7.051580349918284],
            [110.447675034345849, -7.051553254502328],
            [110.447749263346097, -7.051471932448327],
            [110.447835386876307, -7.051381578904395],
            [110.447905926778361, -7.051309297995096],
            [110.447937104676356, -7.051264107165526],
            [110.447951044124466, -7.051200804894325],
            [110.44797527680366, -7.051047059436542],
            [110.447996364906388, -7.050920446864723],
            [110.448017062969953, -7.050884289179344],
            [110.448103741525401, -7.050802981762256],
            [110.448138325452334, -7.050748749402396],
            [110.448183399865002, -7.050676438358773],
            [110.448218015882858, -7.050595069423111],
            [110.448239114593321, -7.050459411308383],
            [110.448249439858188, -7.050341831540136],
            [110.448252910150856, -7.05027851688939],
            [110.448255627916282, -7.050133791529507],
            [110.448271809456102, -7.050088582958583],
            [110.448325623059489, -7.050043418818857],
            [110.448487837771196, -7.049971246022738],
            [110.448617241185971, -7.049889988913521],
            [110.448736196874819, -7.049790628366365],
            [110.448811850827596, -7.049700262246281],
            [110.448903686208766, -7.04956468751134],
            [110.448919889039743, -7.049501387862028],
            [110.448936155991674, -7.049383815065948],
            [110.448957985698058, -7.049347658660214],
            [110.449076679595663, -7.049230206624949],
            [110.44949380900097, -7.048978820014915],
            [110.449668192498208, -7.048828193595582],
            [110.449682934166489, -7.04859773328252],
            [110.449691803173437, -7.04850335288546],
            [110.449715158304556, -7.048395090879336],
            [110.449773331669334, -7.048306207274589],
            [110.449866291329258, -7.048263774397196],
            [110.449955343262417, -7.048252276761366],
            [110.450063710298451, -7.048275609178998],
            [110.450125586129573, -7.048329826737844],
            [110.45019521355124, -7.048376318443046],
            [110.450248486298946, -7.048408442564145],
            [110.450290373111002, -7.048399446293382],
            [110.450367377320575, -7.048363354700521],
            [110.450463915513808, -7.048318240522914],
            [110.450582573380927, -7.048175664853991],
            [110.450648566439412, -7.048021043105733],
            [110.450722306455816, -7.047862562964802],
            [110.450811431151138, -7.047789185513368],
            [110.451283957173445, -7.047588626425835],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 80,
        id: 82,
        area: 1722868.295589,
        perimeter: 6421.132973,
        kode_pos: 50269,
        kode_kec: 3374030,
        kode_kel: 3374031008,
        kec: "Banyumanik",
        kel: "Sumur Boto",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.421227526905312, -7.045352209372234],
            [110.421299029613778, -7.045026660002375],
            [110.421327503419391, -7.044881967295713],
            [110.421364272279035, -7.044664920875525],
            [110.421420644604893, -7.044392213508941],
            [110.421518268771408, -7.044104777137221],
            [110.421676936062198, -7.043742666931693],
            [110.421785024723576, -7.043533959012353],
            [110.422088343048387, -7.043811975338354],
            [110.422326323749516, -7.043994719441544],
            [110.42268932636911, -7.044207365093131],
            [110.423161569611395, -7.044404821973617],
            [110.423539868345856, -7.044631425976175],
            [110.423816008355999, -7.044881043691604],
            [110.424029379291142, -7.04507525872067],
            [110.424284019990154, -7.045256436190368],
            [110.424974818524973, -7.045664380002185],
            [110.42532799289333, -7.045845724283526],
            [110.425903420192626, -7.046146229666439],
            [110.426535092525853, -7.046489437246272],
            [110.426899144997577, -7.046705213511893],
            [110.427170284243431, -7.046914706657112],
            [110.427379414066664, -7.047104756962498],
            [110.427560911029076, -7.047295744474321],
            [110.427864361539008, -7.047648890716685],
            [110.428286688730353, -7.048201183354518],
            [110.428695241172207, -7.048681094785615],
            [110.428885137286471, -7.048880328034557],
            [110.42932753864639, -7.049215552244685],
            [110.429649560241614, -7.04942399205938],
            [110.43015915371619, -7.049650751465757],
            [110.430809871994029, -7.049931955337529],
            [110.43131011838426, -7.050167747695712],
            [110.431866932731353, -7.050421699364302],
            [110.432390143039541, -7.050621336521865],
            [110.432886517818687, -7.050784758505103],
            [110.433174779537552, -7.050911974740911],
            [110.43288520518135, -7.051361208914197],
            [110.432690719111264, -7.051605361779111],
            [110.432433126212814, -7.051897307882322],
            [110.43216296934105, -7.052151446433233],
            [110.431876163919881, -7.052366495333843],
            [110.431629789210447, -7.052538060297681],
            [110.431290857006132, -7.052736648957323],
            [110.430762255564318, -7.053001323579263],
            [110.430429569970343, -7.053156825829192],
            [110.429996097325926, -7.053359437225585],
            [110.42872186249862, -7.053972748122953],
            [110.427529097248964, -7.054541154179942],
            [110.426782786026436, -7.054911104211106],
            [110.425101931437752, -7.05570504164847],
            [110.423812615879854, -7.056318546040969],
            [110.4231441666591, -7.056643358386077],
            [110.422696269754383, -7.056859896573831],
            [110.421755191393132, -7.057301960951528],
            [110.421186960280195, -7.057581771850851],
            [110.420892228387359, -7.057726029222263],
            [110.420252364716276, -7.058041827058224],
            [110.419402131963935, -7.058465908153624],
            [110.41821443846392, -7.059034294782328],
            [110.41748454741419, -7.059386158208198],
            [110.416420024475315, -7.059882329750966],
            [110.414966495382473, -7.060558920691447],
            [110.414594139781173, -7.060775545173844],
            [110.414223967813911, -7.061055490426117],
            [110.413808409043227, -7.061425833104464],
            [110.413548565079253, -7.061724007310239],
            [110.413366850116262, -7.061995143166678],
            [110.413086314343701, -7.062374700329721],
            [110.413432227660977, -7.061533906009429],
            [110.413791211106485, -7.060702173201854],
            [110.41398001209609, -7.060195863543801],
            [110.414175083095031, -7.059653379717576],
            [110.414547013663494, -7.058423661134404],
            [110.414716470950296, -7.057772599022663],
            [110.415022616504473, -7.056877480088003],
            [110.415139951919699, -7.056497716845778],
            [110.41547839728716, -7.055566455955674],
            [110.415863607125857, -7.054571934784721],
            [110.416266742621758, -7.053722163292647],
            [110.416866016040515, -7.052601271618082],
            [110.417177903288774, -7.052086068107021],
            [110.41744437132688, -7.051688399028627],
            [110.417814748255836, -7.051236586322628],
            [110.418375490384136, -7.050604100481791],
            [110.418999100196075, -7.04970032726894],
            [110.419669641147763, -7.048597610437102],
            [110.420163578049568, -7.047756992613799],
            [110.420619248169274, -7.046970599636984],
            [110.420807855523307, -7.046609013559282],
            [110.420970442924116, -7.046238349654544],
            [110.421113563018295, -7.045822434083286],
            [110.421191754083438, -7.045514984021805],
            [110.421227526905312, -7.045352209372234],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 81,
        id: 83,
        area: 3352943.4093829999,
        perimeter: 10056.489305,
        kode_pos: 50263,
        kode_kec: 3374030,
        kode_kel: 3374031009,
        kec: "Banyumanik",
        kel: "Srondol Kulon",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.421227526905312, -7.045352209372234],
            [110.421191754083438, -7.045514984021805],
            [110.421113563018295, -7.045822434083286],
            [110.420970442924116, -7.046238349654544],
            [110.420807855523307, -7.046609013559282],
            [110.420619248169274, -7.046970599636984],
            [110.420163578049568, -7.047756992613799],
            [110.419669641147763, -7.048597610437102],
            [110.418999100196075, -7.04970032726894],
            [110.418375490384136, -7.050604100481791],
            [110.417814748255836, -7.051236586322628],
            [110.41744437132688, -7.051688399028627],
            [110.417177903288774, -7.052086068107021],
            [110.416866016040515, -7.052601271618082],
            [110.416266742621758, -7.053722163292647],
            [110.415863607125857, -7.054571934784721],
            [110.41547839728716, -7.055566455955674],
            [110.415139951919699, -7.056497716845778],
            [110.415022616504473, -7.056877480088003],
            [110.414716470950296, -7.057772599022663],
            [110.414547013663494, -7.058423661134404],
            [110.414175083095031, -7.059653379717576],
            [110.41398001209609, -7.060195863543801],
            [110.413791211106485, -7.060702173201854],
            [110.413432227660977, -7.061533906009429],
            [110.413086314343701, -7.062374700329721],
            [110.413022388838215, -7.062528392999832],
            [110.412872724610921, -7.062962387514277],
            [110.412611199410065, -7.063694741785523],
            [110.41241571598276, -7.064336724287424],
            [110.412285142919814, -7.064888333715855],
            [110.412134679881234, -7.065729373170663],
            [110.412017210560322, -7.066434772010799],
            [110.411925787124673, -7.066905021321038],
            [110.411735556797737, -7.068089738260777],
            [110.411630941828847, -7.068921789672198],
            [110.411511906951588, -7.069744777317964],
            [110.411390305305801, -7.070359715788627],
            [110.411311173344714, -7.070730480132763],
            [110.411188278855761, -7.071472053436939],
            [110.411119474978037, -7.072177513155688],
            [110.411115406910227, -7.072485053996855],
            [110.411154253534505, -7.073091149596244],
            [110.411172360527743, -7.073244945475679],
            [110.411175194872527, -7.073271861467583],
            [110.410755933832348, -7.073244418827208],
            [110.410276656803248, -7.073198584889828],
            [110.409965435192163, -7.073171054327846],
            [110.409649380338081, -7.073161608369731],
            [110.409588543937005, -7.073161531256035],
            [110.409538517470011, -7.07311624050209],
            [110.40951016833364, -7.072935295216328],
            [110.409463709789676, -7.072754326970132],
            [110.409435188315172, -7.072709063472674],
            [110.409338733532977, -7.072681804768743],
            [110.408845072411538, -7.072599769309828],
            [110.408557631477223, -7.072563222559859],
            [110.408432349449185, -7.072508790673737],
            [110.408362221532016, -7.072472519743868],
            [110.408105623322186, -7.072436011897488],
            [110.407858917222796, -7.072408561952878],
            [110.407563010806783, -7.072353912914457],
            [110.407355942183287, -7.072308422191495],
            [110.407237439878998, -7.072263044089976],
            [110.407030325187165, -7.072253735024003],
            [110.406902121535893, -7.072271662735551],
            [110.40672437690732, -7.072307618243533],
            [110.406556035310686, -7.072290798034806],
            [110.406388833346838, -7.07227100891622],
            [110.406230965168604, -7.072252716787469],
            [110.406073108563831, -7.072225379158837],
            [110.40582639122367, -7.072206973603745],
            [110.405698233822136, -7.072188719194007],
            [110.405451562807073, -7.072134131657576],
            [110.405214807002551, -7.072070511194396],
            [110.404997777366148, -7.072070234035616],
            [110.404682018183195, -7.072051739693266],
            [110.404539304912504, -7.072026512288368],
            [110.404469991827085, -7.072014249551897],
            [110.404517475194112, -7.071933104855839],
            [110.404717083531423, -7.071593654444508],
            [110.404988990342531, -7.071062137129911],
            [110.405069790726216, -7.070877592949472],
            [110.405072350351602, -7.070799489600076],
            [110.405016625441661, -7.070716719103653],
            [110.404886994233891, -7.070540177357512],
            [110.404732943704801, -7.07034730506519],
            [110.404676456478697, -7.070254706904247],
            [110.404649604561044, -7.070160945600771],
            [110.404628567911843, -7.070066168164341],
            [110.404633782747652, -7.069965038621868],
            [110.404691841443523, -7.069810972393137],
            [110.404804641442936, -7.069583117128856],
            [110.404893244962267, -7.069343367862048],
            [110.404949902864487, -7.069108036357132],
            [110.404982325422111, -7.068935754618845],
            [110.405009652054986, -7.06864345694095],
            [110.405037742264909, -7.068289277507073],
            [110.405085369071458, -7.068147357517113],
            [110.405137700066234, -7.06804856067412],
            [110.405193447596801, -7.067937862220949],
            [110.405299527070255, -7.067786061561945],
            [110.405476848139926, -7.067626658781173],
            [110.40581158944363, -7.06740655012566],
            [110.406091031219219, -7.067142605590381],
            [110.406105723622773, -7.067020639387682],
            [110.406022900699554, -7.066881180423192],
            [110.405893873931646, -7.066718311546685],
            [110.405690724239335, -7.066433421135602],
            [110.405574727864732, -7.066200921031089],
            [110.405577820722172, -7.066055704837377],
            [110.405630390082081, -7.065869890080752],
            [110.405682985361963, -7.065663744526353],
            [110.405802645620867, -7.065303751075897],
            [110.405919416891308, -7.064929231878045],
            [110.406093166427823, -7.064407938760947],
            [110.406179269176548, -7.064066955104295],
            [110.406237760092765, -7.063796920067724],
            [110.40625546703842, -7.063590729970618],
            [110.406229504999772, -7.063430954730945],
            [110.406163971900398, -7.063232928456255],
            [110.406096343031805, -7.062980602658981],
            [110.406047091339332, -7.062798127071393],
            [110.405965992232211, -7.062605768629546],
            [110.405829545280454, -7.062451661463601],
            [110.405733819225048, -7.062283084127127],
            [110.405661410992039, -7.062068066032912],
            [110.405612216738291, -7.061887930349347],
            [110.405496206512552, -7.06166704787237],
            [110.405330724075, -7.061483859555634],
            [110.40513620588986, -7.06127159013621],
            [110.404998138993619, -7.061085105557141],
            [110.404938668033481, -7.06092155420705],
            [110.404936932131747, -7.060773462421144],
            [110.40494477039222, -7.060665770910615],
            [110.404985053231613, -7.060553796048493],
            [110.40503876695675, -7.060434339383512],
            [110.405054845830009, -7.060398580751132],
            [110.405170992360624, -7.060276585957025],
            [110.405348520487493, -7.060174901095655],
            [110.405583226835262, -7.060046887769825],
            [110.405681078320114, -7.059962803019968],
            [110.405764004696508, -7.059849437382897],
            [110.405864382536052, -7.059638008625861],
            [110.405975130809637, -7.05926356548219],
            [110.40603129433579, -7.059012590971919],
            [110.406120949546747, -7.058772816667834],
            [110.406235691461603, -7.058566547055385],
            [110.406361843657152, -7.058170612700572],
            [110.406434569472651, -7.057859735525125],
            [110.406477226555168, -7.057593079127733],
            [110.406520148093037, -7.057175170194512],
            [110.406522596008287, -7.056915586946214],
            [110.406534432606065, -7.056391194694778],
            [110.406561766204561, -7.056066683637011],
            [110.406550304712383, -7.055801446533095],
            [110.406496323113259, -7.055621271685443],
            [110.406379259057431, -7.055459337628716],
            [110.406320823008969, -7.055303056897848],
            [110.406312812601385, -7.055015738451163],
            [110.406321510309823, -7.054787416206477],
            [110.406296670955555, -7.054535941018435],
            [110.406251292981565, -7.054333501698245],
            [110.406163008955843, -7.054168362084236],
            [110.406021088611453, -7.054007745235683],
            [110.405807433487823, -7.053808473126722],
            [110.405691693400783, -7.053654998975703],
            [110.405627919792806, -7.053466749934807],
            [110.405622602291388, -7.053257538132325],
            [110.405684231910669, -7.053098620729783],
            [110.405782123619119, -7.052967643455451],
            [110.405941367473943, -7.052895321605392],
            [110.406125690699412, -7.052862083195937],
            [110.406374211577088, -7.052845662712478],
            [110.406603212393819, -7.052809691489427],
            [110.406723327226729, -7.052768003056874],
            [110.406826753151762, -7.052676084177297],
            [110.406893931048373, -7.05254506762881],
            [110.406918922533805, -7.05242526839776],
            [110.406886327582455, -7.052321371913429],
            [110.406778692061096, -7.052186608254479],
            [110.406683376095401, -7.052081901924587],
            [110.406578773203847, -7.051955565227473],
            [110.406505836524204, -7.051784303988442],
            [110.406438704631412, -7.051589971207221],
            [110.406253343340254, -7.051128877876214],
            [110.406111320761653, -7.050875886728672],
            [110.405904263340332, -7.050651727454371],
            [110.405795968092576, -7.050531399488452],
            [110.405760728388884, -7.050407584142713],
            [110.405772685244074, -7.050283828820986],
            [110.405822599169156, -7.050131370321486],
            [110.405914755401966, -7.049906804046856],
            [110.406039156656533, -7.049506181405761],
            [110.40608088136841, -7.049170285828721],
            [110.406103392536096, -7.048821790183919],
            [110.406164368068744, -7.048469025503793],
            [110.406199160072163, -7.048137216336288],
            [110.406194965589719, -7.047975888758114],
            [110.406158599169174, -7.047810443685592],
            [110.406083514038869, -7.047648858982495],
            [110.406003035595575, -7.047519836377572],
            [110.405747362076994, -7.047232948257299],
            [110.405364713208755, -7.046882431515317],
            [110.405187856650329, -7.046684112542593],
            [110.405072996674406, -7.046534650263143],
            [110.405003390595297, -7.046414623860524],
            [110.404953475426893, -7.046306858755911],
            [110.404936272195457, -7.04621067473997],
            [110.404968114789995, -7.046097365065023],
            [110.405059855975281, -7.0459146526914],
            [110.405135094040531, -7.04578973766674],
            [110.405216173532068, -7.04561290250904],
            [110.405262601284306, -7.045436023254823],
            [110.405270593328041, -7.045207167555079],
            [110.405238280503269, -7.045081199358173],
            [110.405159651001696, -7.044728217798933],
            [110.405080994743841, -7.044396303751559],
            [110.404980877899476, -7.043948285615001],
            [110.404922380177965, -7.043761547199269],
            [110.404841678295938, -7.043545712747747],
            [110.404841621625053, -7.043443008351488],
            [110.404862983908956, -7.043296869099034],
            [110.404911285084737, -7.04316038033337],
            [110.405005950004096, -7.042897016526476],
            [110.405092887521405, -7.042654798530394],
            [110.405100969732118, -7.042627207035054],
            [110.40526510618497, -7.042645672786944],
            [110.405583097602786, -7.042673213426341],
            [110.405846515939928, -7.042673548113607],
            [110.405978221757579, -7.042565169717689],
            [110.406022250276834, -7.042429543529263],
            [110.406022422901273, -7.042293861646354],
            [110.406197869378104, -7.042275993452981],
            [110.406450172318941, -7.042339631990445],
            [110.406691521100939, -7.042339938181194],
            [110.406933672754604, -7.042376427165096],
            [110.407152259930797, -7.042476204487558],
            [110.407188961890625, -7.042539569330838],
            [110.407280439632558, -7.042693458340385],
            [110.407451313265611, -7.042711765793237],
            [110.407646906116938, -7.042648695207153],
            [110.40769557203285, -7.042648756836051],
            [110.407744509415181, -7.04265786427973],
            [110.4079518901314, -7.042893309207918],
            [110.408024690220543, -7.043273311361844],
            [110.408085713883835, -7.04334575240803],
            [110.408146546374468, -7.04334582939387],
            [110.408446139545546, -7.043156253408034],
            [110.408659863673492, -7.043075114380781],
            [110.408794272429304, -7.043066238820593],
            [110.408947060939894, -7.043066431926274],
            [110.409197201034999, -7.043274793963706],
            [110.409538685845263, -7.043519453131881],
            [110.40969131437582, -7.043646282508545],
            [110.409770538158739, -7.043646382499896],
            [110.409947922892314, -7.043438560311893],
            [110.410161998469846, -7.043303148153461],
            [110.410357182678041, -7.043339576190521],
            [110.410568063187256, -7.04349361517479],
            [110.410586385888777, -7.043547911148705],
            [110.410574048047522, -7.0436835778029],
            [110.410616680939938, -7.043755995375126],
            [110.410702083803315, -7.043792284918951],
            [110.41088541894861, -7.043801561381898],
            [110.410976763632505, -7.043837858362765],
            [110.411251464048931, -7.044091477742434],
            [110.411592984833533, -7.044308999139468],
            [110.411910239256926, -7.044698353951115],
            [110.412051471369679, -7.044888486676802],
            [110.412194718375559, -7.04505148545389],
            [110.412337762271079, -7.045151165561798],
            [110.412474627031202, -7.045214655935605],
            [110.412598261813542, -7.045223856754752],
            [110.412695899980463, -7.045205888439659],
            [110.412793877993039, -7.045142693114522],
            [110.412900106848127, -7.045043326192958],
            [110.412927031957281, -7.04500717806577],
            [110.412942153103131, -7.044907696732488],
            [110.412941632730551, -7.044871514145154],
            [110.412934958894468, -7.044328776750016],
            [110.4128498857186, -7.044030168981164],
            [110.412972254841847, -7.043695639698023],
            [110.413064166992143, -7.043505799890577],
            [110.413351125932778, -7.043687069624787],
            [110.413692772526261, -7.043805089369392],
            [110.413863578856365, -7.043877667359894],
            [110.41426098812569, -7.043977665625893],
            [110.414560273240198, -7.044032313305702],
            [110.414835033155455, -7.044014566217294],
            [110.415305612315791, -7.04375283566958],
            [110.416003914278861, -7.043753708190292],
            [110.416559590698327, -7.043772492736239],
            [110.417096717805308, -7.043917890371845],
            [110.41789253484248, -7.044226428342753],
            [110.418790019706677, -7.044462727473382],
            [110.419378304940679, -7.044653413655888],
            [110.419941363782982, -7.044880249820462],
            [110.420373487781532, -7.045052650085572],
            [110.420859937298431, -7.045225117267074],
            [110.421227526905312, -7.045352209372234],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 82,
        id: 84,
        area: 1938665.2768079999,
        perimeter: 6863.749213,
        kode_pos: 50263,
        kode_kec: 3374030,
        kode_kel: 3374031006,
        kec: "Banyumanik",
        kel: "Srondol Wetan",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.413086314343701, -7.062374700329721],
            [110.413366850116262, -7.061995143166678],
            [110.413548565079253, -7.061724007310239],
            [110.413808409043227, -7.061425833104464],
            [110.414223967813911, -7.061055490426117],
            [110.414594139781173, -7.060775545173844],
            [110.414966495382473, -7.060558920691447],
            [110.416420024475315, -7.059882329750966],
            [110.41748454741419, -7.059386158208198],
            [110.41821443846392, -7.059034294782328],
            [110.419402131963935, -7.058465908153624],
            [110.420252364716276, -7.058041827058224],
            [110.420892228387359, -7.057726029222263],
            [110.421186960280195, -7.057581771850851],
            [110.421294483861033, -7.057807937550517],
            [110.421334516864917, -7.057925578575762],
            [110.421417538095824, -7.058287501166232],
            [110.421492942205347, -7.058631323321551],
            [110.421512863264553, -7.058767030389486],
            [110.421516667306449, -7.058893671987401],
            [110.42149606089049, -7.059309739052265],
            [110.421483895994697, -7.059535861255107],
            [110.421474174486391, -7.059617258624269],
            [110.421648994120076, -7.059653657294375],
            [110.422152764012608, -7.059789963781537],
            [110.422354996520411, -7.059853532619952],
            [110.422437290841586, -7.059889816457629],
            [110.422363238258498, -7.060052543657649],
            [110.42229415533032, -7.060314777408764],
            [110.422175375944775, -7.060721677468424],
            [110.4220321949702, -7.061182820194431],
            [110.421953241958221, -7.061417905152198],
            [110.421837765374448, -7.061671035806093],
            [110.42170444009605, -7.061942235273804],
            [110.421577588876886, -7.062240579186958],
            [110.421484238482378, -7.062448509697931],
            [110.421396535839591, -7.062665492677535],
            [110.421737010469414, -7.062828733728273],
            [110.422068702535171, -7.063001009157095],
            [110.422480426114802, -7.063209565438282],
            [110.422832230976709, -7.063363774285619],
            [110.423190566469302, -7.063499899977201],
            [110.423516101827417, -7.06361789388095],
            [110.423817325632953, -7.063717766548125],
            [110.424401359217129, -7.063935579589756],
            [110.424720126682502, -7.064035473356953],
            [110.42507200007006, -7.06413540773203],
            [110.425669683715213, -7.064298963122519],
            [110.425975175170805, -7.064380748727906],
            [110.4264067980356, -7.064516962285877],
            [110.426835353231596, -7.064616989702421],
            [110.427240423314018, -7.064716987893432],
            [110.427426280732377, -7.064753398136175],
            [110.42763226142462, -7.064762696511529],
            [110.427619239145614, -7.064997863494575],
            [110.427619050003599, -7.065151636739845],
            [110.427572217604606, -7.065269170729696],
            [110.427505034592528, -7.06536858872895],
            [110.427411264796575, -7.065458928568598],
            [110.427337629388092, -7.065513111123738],
            [110.427237146519374, -7.06554012419244],
            [110.42705633747407, -7.06553990204585],
            [110.426848930548246, -7.065539647134084],
            [110.426704894680725, -7.065548515550798],
            [110.426614620496011, -7.065557450040156],
            [110.426567605171982, -7.065593574207207],
            [110.426497365128839, -7.065647760795733],
            [110.426446998910208, -7.065647698840719],
            [110.426423524688914, -7.065638624466827],
            [110.42638679612719, -7.065593351799311],
            [110.426356914277974, -7.065502860070311],
            [110.426323409767846, -7.065367136399998],
            [110.426310166590198, -7.065321892623149],
            [110.426280251312591, -7.065258537339266],
            [110.426200059104872, -7.065122756214378],
            [110.426169838589203, -7.065077491542346],
            [110.426116382230489, -7.065059334760932],
            [110.42603944053711, -7.065041149065837],
            [110.42591551701598, -7.065031951013946],
            [110.425684625020835, -7.065031666687279],
            [110.425571442673757, -7.065031527269709],
            [110.425471808707144, -7.065058541004362],
            [110.425241970334682, -7.065121576220644],
            [110.42508576750302, -7.065130429147452],
            [110.425033703617501, -7.065130364951025],
            [110.424990389010873, -7.06514840252749],
            [110.424838657565573, -7.065202488356128],
            [110.424660905324259, -7.06524749653185],
            [110.424547972343049, -7.065274493650932],
            [110.424392018854164, -7.065310483128645],
            [110.424292079440235, -7.065355587210438],
            [110.424153274288059, -7.065482052709365],
            [110.424066272426302, -7.06559049116691],
            [110.424018601433971, -7.065698978194014],
            [110.423996713029865, -7.065780360588803],
            [110.424005383896429, -7.065861780736158],
            [110.424018243122916, -7.065988433521503],
            [110.424044051167016, -7.0661693752597],
            [110.424056899208651, -7.066305073522729],
            [110.424052304719567, -7.066359340802842],
            [110.424028469178154, -7.066413584311482],
            [110.423974379666362, -7.066449699452827],
            [110.423923674426362, -7.066494864261552],
            [110.423886268141374, -7.066540045498797],
            [110.423866077403616, -7.066621429977531],
            [110.423862614713414, -7.066675698651765],
            [110.423879536113787, -7.066720947025379],
            [110.423946801433956, -7.066784348595901],
            [110.424273597324898, -7.067028980609448],
            [110.424529371315984, -7.067273524745021],
            [110.424669006732429, -7.067391288521356],
            [110.424761976631572, -7.067490903687711],
            [110.424808269786553, -7.067581415748511],
            [110.424816159039608, -7.067608561963848],
            [110.424816091932129, -7.067662834841231],
            [110.424816047193531, -7.067699016759416],
            [110.42473053811257, -7.067744138699904],
            [110.42463345013536, -7.067771155344387],
            [110.424600299353727, -7.067807296393845],
            [110.424562204009803, -7.067951977250595],
            [110.424538222950417, -7.068123812006244],
            [110.424505004985548, -7.06821422591888],
            [110.424471537625706, -7.068277503042069],
            [110.424395615389386, -7.068349773224327],
            [110.424319704325015, -7.068412997913618],
            [110.42426279620588, -7.068440064094942],
            [110.424144248057459, -7.068430872144421],
            [110.424068471352967, -7.068385551052008],
            [110.423907331036034, -7.068267760508597],
            [110.423860110704439, -7.068240565665005],
            [110.42381739529219, -7.068231467369336],
            [110.423774646264519, -7.068249505502889],
            [110.423741506590531, -7.068276601004773],
            [110.423603809778498, -7.06842115859578],
            [110.423532392297588, -7.068511525177582],
            [110.423455032671811, -7.068601884395078],
            [110.423393280560276, -7.068656080944073],
            [110.423345676334023, -7.068710294989312],
            [110.42327460901349, -7.068746389012981],
            [110.423184628367224, -7.068746277654998],
            [110.423137068984545, -7.06876430977025],
            [110.423109327879715, -7.068773320922359],
            [110.423085242788019, -7.068800427578979],
            [110.42306115769351, -7.068827534234095],
            [110.42302802915944, -7.068845584201633],
            [110.423003705988293, -7.06883650859817],
            [110.4229375386968, -7.06880024471325],
            [110.422835480649795, -7.068727754413564],
            [110.422784310159585, -7.068691509080999],
            [110.42274812522129, -7.068664327794427],
            [110.422718142907442, -7.068655245167763],
            [110.422660691220599, -7.068664219490805],
            [110.422633765302663, -7.068700368092962],
            [110.422597501767441, -7.068736505124595],
            [110.422555307339152, -7.068763589314967],
            [110.422458807435234, -7.068772515228267],
            [110.422398797875999, -7.068790531836779],
            [110.422365658088665, -7.068817627230581],
            [110.422284110004441, -7.068862753594114],
            [110.422196947694189, -7.068871691026131],
            [110.422154786947615, -7.068871638752718],
            [110.422121692097207, -7.068862552228358],
            [110.422076475012716, -7.06881726871486],
            [110.422007251474213, -7.06873577347098],
            [110.421962306129245, -7.068699535773577],
            [110.421922997464222, -7.068681396040889],
            [110.4218749171079, -7.06866324542418],
            [110.421763454191989, -7.068645016167999],
            [110.421685946229672, -7.068626829020724],
            [110.421599632835182, -7.068635767397109],
            [110.421518673137342, -7.06866280337816],
            [110.421410583231818, -7.068662669200748],
            [110.421340375920195, -7.068689718498186],
            [110.421313461158618, -7.068716821542027],
            [110.421313382377292, -7.068780139856722],
            [110.421334763384053, -7.06887966676747],
            [110.421437285845798, -7.069033567344321],
            [110.421518088099361, -7.069133168015716],
            [110.421566666900731, -7.069205592216724],
            [110.421566621899487, -7.069241774111726],
            [110.421544789112986, -7.069277928962355],
            [110.421474559207226, -7.069323069219524],
            [110.421377436996224, -7.069377221562009],
            [110.421242432118959, -7.069404190373726],
            [110.421031911141938, -7.069403928868711],
            [110.420967113692299, -7.069403848359454],
            [110.420956316227119, -7.069440016889744],
            [110.420931304639254, -7.069756577840675],
            [110.420928950862148, -7.0698289388077],
            [110.420928872015935, -7.069892257115061],
            [110.420976103574731, -7.069910406780934],
            [110.421036645454265, -7.06991952749376],
            [110.421456239329487, -7.069947185105664],
            [110.421487919261196, -7.069956417875188],
            [110.42150864458344, -7.07012815991475],
            [110.421496525614941, -7.070544237279038],
            [110.421461962770849, -7.071032650659749],
            [110.421457855019881, -7.071150236887658],
            [110.421397031547471, -7.071367253033651],
            [110.421240885839396, -7.071783151450788],
            [110.421061707415944, -7.072289476018573],
            [110.420871210148718, -7.072795786402338],
            [110.420745124860119, -7.073157449018879],
            [110.420634681125293, -7.073455812645473],
            [110.420556380976407, -7.07361853395768],
            [110.420307444949188, -7.07401622547236],
            [110.419983942894049, -7.074531415227742],
            [110.419658470658518, -7.075037556751608],
            [110.419385990923914, -7.075480260318228],
            [110.419321226326787, -7.075453228480863],
            [110.418888351209389, -7.075407461025913],
            [110.418529963077546, -7.07530751331712],
            [110.418247420177465, -7.07519861463027],
            [110.417951793207124, -7.075143972306059],
            [110.417772689828269, -7.075134702911164],
            [110.417350420370269, -7.075206538547902],
            [110.417035744335436, -7.07522423564037],
            [110.416756744167117, -7.075223886251122],
            [110.416311487572912, -7.075123828064852],
            [110.415964758634772, -7.074978665673438],
            [110.415653648934949, -7.074860684168961],
            [110.415029143591212, -7.074642808652071],
            [110.414435481644702, -7.074424971127473],
            [110.413854961999832, -7.074107649219457],
            [110.413569082674215, -7.073953516269199],
            [110.413138622115511, -7.073790155518865],
            [110.412662853924289, -7.073653873674759],
            [110.411921229216929, -7.073417755570794],
            [110.411695767125082, -7.073372243481149],
            [110.411440299850952, -7.07333573884021],
            [110.411177961690754, -7.073318463676961],
            [110.411175194872527, -7.073271861467583],
            [110.411172360527743, -7.073244945475679],
            [110.411154253534505, -7.073091149596244],
            [110.411115406910227, -7.072485053996855],
            [110.411119474978037, -7.072177513155688],
            [110.411188278855761, -7.071472053436939],
            [110.411311173344714, -7.070730480132763],
            [110.411390305305801, -7.070359715788627],
            [110.411511906951588, -7.069744777317964],
            [110.411630941828847, -7.068921789672198],
            [110.411735556797737, -7.068089738260777],
            [110.411925787124673, -7.066905021321038],
            [110.412017210560322, -7.066434772010799],
            [110.412134679881234, -7.065729373170663],
            [110.412285142919814, -7.064888333715855],
            [110.41241571598276, -7.064336724287424],
            [110.412611199410065, -7.063694741785523],
            [110.412872724610921, -7.062962387514277],
            [110.413022388838215, -7.062528392999832],
            [110.413086314343701, -7.062374700329721],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 83,
        id: 85,
        area: 2437845.413768,
        perimeter: 8268.59548,
        kode_pos: 50268,
        kode_kec: 3374030,
        kode_kel: 3374031007,
        kec: "Banyumanik",
        kel: "Pedalangan",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.421487919261196, -7.069956417875188],
            [110.421456239329487, -7.069947185105664],
            [110.421036645454265, -7.06991952749376],
            [110.420976103574731, -7.069910406780934],
            [110.420928872015935, -7.069892257115061],
            [110.420928950862148, -7.0698289388077],
            [110.420931304639254, -7.069756577840675],
            [110.420956316227119, -7.069440016889744],
            [110.420967113692299, -7.069403848359454],
            [110.421031911141938, -7.069403928868711],
            [110.421242432118959, -7.069404190373726],
            [110.421377436996224, -7.069377221562009],
            [110.421474559207226, -7.069323069219524],
            [110.421544789112986, -7.069277928962355],
            [110.421566621899487, -7.069241774111726],
            [110.421566666900731, -7.069205592216724],
            [110.421518088099361, -7.069133168015716],
            [110.421437285845798, -7.069033567344321],
            [110.421334763384053, -7.06887966676747],
            [110.421313382377292, -7.068780139856722],
            [110.421313461158618, -7.068716821542027],
            [110.421340375920195, -7.068689718498186],
            [110.421410583231818, -7.068662669200748],
            [110.421518673137342, -7.06866280337816],
            [110.421599632835182, -7.068635767397109],
            [110.421685946229672, -7.068626829020724],
            [110.421763454191989, -7.068645016167999],
            [110.4218749171079, -7.06866324542418],
            [110.421922997464222, -7.068681396040889],
            [110.421962306129245, -7.068699535773577],
            [110.422007251474213, -7.06873577347098],
            [110.422076475012716, -7.06881726871486],
            [110.422121692097207, -7.068862552228358],
            [110.422154786947615, -7.068871638752718],
            [110.422196947694189, -7.068871691026131],
            [110.422284110004441, -7.068862753594114],
            [110.422365658088665, -7.068817627230581],
            [110.422398797875999, -7.068790531836779],
            [110.422458807435234, -7.068772515228267],
            [110.422555307339152, -7.068763589314967],
            [110.422597501767441, -7.068736505124595],
            [110.422633765302663, -7.068700368092962],
            [110.422660691220599, -7.068664219490805],
            [110.422718142907442, -7.068655245167763],
            [110.42274812522129, -7.068664327794427],
            [110.422784310159585, -7.068691509080999],
            [110.422835480649795, -7.068727754413564],
            [110.4229375386968, -7.06880024471325],
            [110.423003705988293, -7.06883650859817],
            [110.42302802915944, -7.068845584201633],
            [110.42306115769351, -7.068827534234095],
            [110.423085242788019, -7.068800427578979],
            [110.423109327879715, -7.068773320922359],
            [110.423137068984545, -7.06876430977025],
            [110.423184628367224, -7.068746277654998],
            [110.42327460901349, -7.068746389012981],
            [110.423345676334023, -7.068710294989312],
            [110.423393280560276, -7.068656080944073],
            [110.423455032671811, -7.068601884395078],
            [110.423532392297588, -7.068511525177582],
            [110.423603809778498, -7.06842115859578],
            [110.423741506590531, -7.068276601004773],
            [110.423774646264519, -7.068249505502889],
            [110.42381739529219, -7.068231467369336],
            [110.423860110704439, -7.068240565665005],
            [110.423907331036034, -7.068267760508597],
            [110.424068471352967, -7.068385551052008],
            [110.424144248057459, -7.068430872144421],
            [110.42426279620588, -7.068440064094942],
            [110.424319704325015, -7.068412997913618],
            [110.424395615389386, -7.068349773224327],
            [110.424471537625706, -7.068277503042069],
            [110.424505004985548, -7.06821422591888],
            [110.424538222950417, -7.068123812006244],
            [110.424562204009803, -7.067951977250595],
            [110.424600299353727, -7.067807296393845],
            [110.42463345013536, -7.067771155344387],
            [110.42473053811257, -7.067744138699904],
            [110.424816047193531, -7.067699016759416],
            [110.424816091932129, -7.067662834841231],
            [110.424816159039608, -7.067608561963848],
            [110.424808269786553, -7.067581415748511],
            [110.424761976631572, -7.067490903687711],
            [110.424669006732429, -7.067391288521356],
            [110.424529371315984, -7.067273524745021],
            [110.424273597324898, -7.067028980609448],
            [110.423946801433956, -7.066784348595901],
            [110.423879536113787, -7.066720947025379],
            [110.423862614713414, -7.066675698651765],
            [110.423866077403616, -7.066621429977531],
            [110.423886268141374, -7.066540045498797],
            [110.423923674426362, -7.066494864261552],
            [110.423974379666362, -7.066449699452827],
            [110.424028469178154, -7.066413584311482],
            [110.424052304719567, -7.066359340802842],
            [110.424056899208651, -7.066305073522729],
            [110.424044051167016, -7.0661693752597],
            [110.424018243122916, -7.065988433521503],
            [110.424005383896429, -7.065861780736158],
            [110.423996713029865, -7.065780360588803],
            [110.424018601433971, -7.065698978194014],
            [110.424066272426302, -7.06559049116691],
            [110.424153274288059, -7.065482052709365],
            [110.424292079440235, -7.065355587210438],
            [110.424392018854164, -7.065310483128645],
            [110.424547972343049, -7.065274493650932],
            [110.424660905324259, -7.06524749653185],
            [110.424838657565573, -7.065202488356128],
            [110.424990389010873, -7.06514840252749],
            [110.425033703617501, -7.065130364951025],
            [110.42508576750302, -7.065130429147452],
            [110.425241970334682, -7.065121576220644],
            [110.425471808707144, -7.065058541004362],
            [110.425571442673757, -7.065031527269709],
            [110.425684625020835, -7.065031666687279],
            [110.42591551701598, -7.065031951013946],
            [110.42603944053711, -7.065041149065837],
            [110.426116382230489, -7.065059334760932],
            [110.426169838589203, -7.065077491542346],
            [110.426200059104872, -7.065122756214378],
            [110.426280251312591, -7.065258537339266],
            [110.426310166590198, -7.065321892623149],
            [110.426323409767846, -7.065367136399998],
            [110.426356914277974, -7.065502860070311],
            [110.42638679612719, -7.065593351799311],
            [110.426423524688914, -7.065638624466827],
            [110.426446998910208, -7.065647698840719],
            [110.426497365128839, -7.065647760795733],
            [110.426567605171982, -7.065593574207207],
            [110.426614620496011, -7.065557450040156],
            [110.426704894680725, -7.065548515550798],
            [110.426848930548246, -7.065539647134084],
            [110.42705633747407, -7.06553990204585],
            [110.427237146519374, -7.06554012419244],
            [110.427337629388092, -7.065513111123738],
            [110.427411264796575, -7.065458928568598],
            [110.427505034592528, -7.06536858872895],
            [110.427572217604606, -7.065269170729696],
            [110.427619050003599, -7.065151636739845],
            [110.427619239145614, -7.064997863494575],
            [110.42763226142462, -7.064762696511529],
            [110.427426280732377, -7.064753398136175],
            [110.427240423314018, -7.064716987893432],
            [110.426835353231596, -7.064616989702421],
            [110.4264067980356, -7.064516962285877],
            [110.425975175170805, -7.064380748727906],
            [110.425669683715213, -7.064298963122519],
            [110.42507200007006, -7.06413540773203],
            [110.424720126682502, -7.064035473356953],
            [110.424401359217129, -7.063935579589756],
            [110.423817325632953, -7.063717766548125],
            [110.423516101827417, -7.06361789388095],
            [110.423190566469302, -7.063499899977201],
            [110.422832230976709, -7.063363774285619],
            [110.422480426114802, -7.063209565438282],
            [110.422068702535171, -7.063001009157095],
            [110.421737010469414, -7.062828733728273],
            [110.421396535839591, -7.062665492677535],
            [110.421484238482378, -7.062448509697931],
            [110.421577588876886, -7.062240579186958],
            [110.42170444009605, -7.061942235273804],
            [110.421837765374448, -7.061671035806093],
            [110.421953241958221, -7.061417905152198],
            [110.4220321949702, -7.061182820194431],
            [110.422175375944775, -7.060721677468424],
            [110.42229415533032, -7.060314777408764],
            [110.422363238258498, -7.060052543657649],
            [110.422437290841586, -7.059889816457629],
            [110.422354996520411, -7.059853532619952],
            [110.422152764012608, -7.059789963781537],
            [110.421648994120076, -7.059653657294375],
            [110.421474174486391, -7.059617258624269],
            [110.421483895994697, -7.059535861255107],
            [110.42149606089049, -7.059309739052265],
            [110.421516667306449, -7.058893671987401],
            [110.421512863264553, -7.058767030389486],
            [110.421492942205347, -7.058631323321551],
            [110.421417538095824, -7.058287501166232],
            [110.421334516864917, -7.057925578575762],
            [110.421294483861033, -7.057807937550517],
            [110.421186960280195, -7.057581771850851],
            [110.421755191393132, -7.057301960951528],
            [110.422696269754383, -7.056859896573831],
            [110.4231441666591, -7.056643358386077],
            [110.423812615879854, -7.056318546040969],
            [110.425101931437752, -7.05570504164847],
            [110.426782786026436, -7.054911104211106],
            [110.427529097248964, -7.054541154179942],
            [110.42872186249862, -7.053972748122953],
            [110.429996097325926, -7.053359437225585],
            [110.430400213640652, -7.053728674844743],
            [110.430401959381086, -7.053730269910901],
            [110.430607231370644, -7.053911732102812],
            [110.430663483047383, -7.053957028221477],
            [110.430720050829223, -7.053975188196437],
            [110.430848193773159, -7.054002480909195],
            [110.431304874165193, -7.054003037257736],
            [110.431920278225078, -7.05401283177372],
            [110.432086380597013, -7.05400398828794],
            [110.432101441885152, -7.054014045768338],
            [110.43211352170556, -7.054022112312586],
            [110.432146310785939, -7.054049288713389],
            [110.432153123296317, -7.054074061282878],
            [110.432161240988293, -7.054103579926815],
            [110.432175811102312, -7.054221189270884],
            [110.432211558369318, -7.054375006402945],
            [110.432250179231488, -7.054492644987623],
            [110.432297843488186, -7.054619340074344],
            [110.432366571034265, -7.054776985638167],
            [110.432426935198876, -7.05491059112126],
            [110.432557821992972, -7.055119866519059],
            [110.432668585422618, -7.055286286382647],
            [110.432791934162253, -7.055472877304546],
            [110.43288003082624, -7.055614074853093],
            [110.433033566557143, -7.055863689235752],
            [110.433139255756672, -7.056055297586563],
            [110.433222287258658, -7.056214125251248],
            [110.433311738514959, -7.056411582788988],
            [110.433379187798607, -7.056556392830997],
            [110.433479676417349, -7.056755516026913],
            [110.43363275008339, -7.056990885052925],
            [110.433799133613974, -7.057217224671866],
            [110.434007601640843, -7.05747985402078],
            [110.434163841741736, -7.057697079019586],
            [110.434327401698795, -7.057883357562852],
            [110.434488633265886, -7.058027162970756],
            [110.434722328916294, -7.058213349913782],
            [110.434971967214778, -7.058385516933102],
            [110.434973028127956, -7.058386228325451],
            [110.435107108711023, -7.05847613564787],
            [110.435192744557924, -7.058557648905571],
            [110.435224108403148, -7.058593868912071],
            [110.435251195063728, -7.058657220279494],
            [110.435306962964447, -7.058891009180984],
            [110.435354446534717, -7.05902400569382],
            [110.435385898079915, -7.059109658908535],
            [110.435408391750755, -7.059227277789527],
            [110.435427660037632, -7.059403932889238],
            [110.435492565648602, -7.059953256853263],
            [110.43556504036188, -7.060500070489588],
            [110.435652760091713, -7.060953682002141],
            [110.435712948793054, -7.061233416467587],
            [110.435744955887174, -7.061452881034756],
            [110.435762812825132, -7.061660949419187],
            [110.435766835814746, -7.06207704785343],
            [110.435793909678551, -7.062384628016844],
            [110.43584495930746, -7.062599131256186],
            [110.435958096151211, -7.062791874636297],
            [110.436067152413756, -7.062927689188004],
            [110.436262205208095, -7.063081698692078],
            [110.436493542117304, -7.063181478885523],
            [110.436974619299519, -7.063372015653075],
            [110.437378831402384, -7.063481049606277],
            [110.437796364484853, -7.06357200825185],
            [110.437946254483592, -7.063635507518262],
            [110.438028672243078, -7.063698713747556],
            [110.438100301499972, -7.063771375869417],
            [110.438136715430815, -7.063843783862445],
            [110.438159242699072, -7.063934266154152],
            [110.438159122585404, -7.064033766676331],
            [110.43814765050594, -7.064395573457785],
            [110.438147432097225, -7.064576483493862],
            [110.438181440477166, -7.064766480282046],
            [110.438215023963139, -7.065074068249658],
            [110.438171799421056, -7.065486738785622],
            [110.438151434726393, -7.065642921696705],
            [110.438124246463516, -7.065734281435344],
            [110.438050362592634, -7.065801526811506],
            [110.437878704471828, -7.065934852050947],
            [110.437699464991169, -7.066080765448907],
            [110.437495883956956, -7.066131526355056],
            [110.437036524175184, -7.066230472537912],
            [110.436741595218152, -7.066302480406717],
            [110.436536420375703, -7.066406892924581],
            [110.43634206349229, -7.066540190108051],
            [110.436036575942097, -7.066806884895583],
            [110.435670477101695, -7.067141531872905],
            [110.435389102579791, -7.067431533133262],
            [110.435051621937006, -7.067931896943567],
            [110.434872553443498, -7.068254048462335],
            [110.43484192862347, -7.068343552311391],
            [110.434816968087887, -7.068416502063656],
            [110.434088909943881, -7.068361643715745],
            [110.43338018757099, -7.068288418589112],
            [110.433365701604188, -7.068333628509214],
            [110.433235728338445, -7.06887620083074],
            [110.433139647339743, -7.069237904198349],
            [110.43311068630949, -7.069319278518704],
            [110.433090530073187, -7.069373527031327],
            [110.433047193255845, -7.069409656330434],
            [110.432937100186678, -7.069427613386112],
            [110.432932223707553, -7.069481880482912],
            [110.43288206994707, -7.069771275608902],
            [110.432863545226397, -7.069879799121944],
            [110.432809940683057, -7.070214417551797],
            [110.432766044945694, -7.070476683748631],
            [110.432731553030237, -7.070684688357901],
            [110.432674358072376, -7.070946938341147],
            [110.43265904513045, -7.070974056207043],
            [110.432552108394276, -7.070955834967951],
            [110.432161418232752, -7.070892040441171],
            [110.432133111209765, -7.07090105144384],
            [110.432075666070816, -7.071136164508114],
            [110.432009121940993, -7.071407448477114],
            [110.431915630182644, -7.071732972583659],
            [110.431846866383594, -7.071968071791667],
            [110.431829855594714, -7.071995187551944],
            [110.431351252563388, -7.071858921041222],
            [110.430836214427003, -7.071668336540075],
            [110.430394724194031, -7.071495932432279],
            [110.430274305746195, -7.071857605193846],
            [110.430200131604678, -7.072119833973559],
            [110.42990991406802, -7.072038069396144],
            [110.429469589052005, -7.071838529342755],
            [110.429013318692995, -7.071720378842541],
            [110.427766453759645, -7.071375120063905],
            [110.427044879654034, -7.071166186809131],
            [110.426201983629724, -7.070902830119297],
            [110.425215051225521, -7.070648339803149],
            [110.424687239524758, -7.07049391500902],
            [110.424244870644387, -7.070348640722274],
            [110.42355582917493, -7.070148787993991],
            [110.423229667354676, -7.070076020459714],
            [110.422907422174632, -7.070039439513696],
            [110.422352302025487, -7.070002569717602],
            [110.421721337844374, -7.069974650636675],
            [110.421487919261196, -7.069956417875188],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 84,
        id: 86,
        area: 1667327.746512,
        perimeter: 7155.110557,
        kode_pos: 50264,
        kode_kec: 3374030,
        kode_kel: 3374031005,
        kec: "Banyumanik",
        kel: "Banyumanik",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.411175194872527, -7.073271861467583],
            [110.411177961690754, -7.073318463676961],
            [110.411440299850952, -7.07333573884021],
            [110.411695767125082, -7.073372243481149],
            [110.411921229216929, -7.073417755570794],
            [110.412662853924289, -7.073653873674759],
            [110.413138622115511, -7.073790155518865],
            [110.413569082674215, -7.073953516269199],
            [110.413854961999832, -7.074107649219457],
            [110.414435481644702, -7.074424971127473],
            [110.415029143591212, -7.074642808652071],
            [110.415653648934949, -7.074860684168961],
            [110.415964758634772, -7.074978665673438],
            [110.416311487572912, -7.075123828064852],
            [110.416756744167117, -7.075223886251122],
            [110.417035744335436, -7.07522423564037],
            [110.417350420370269, -7.075206538547902],
            [110.417772689828269, -7.075134702911164],
            [110.417951793207124, -7.075143972306059],
            [110.418247420177465, -7.07519861463027],
            [110.418529963077546, -7.07530751331712],
            [110.418888351209389, -7.075407461025913],
            [110.419321226326787, -7.075453228480863],
            [110.419385990923914, -7.075480260318228],
            [110.419471433889498, -7.075489597711956],
            [110.419600679697538, -7.075544031731807],
            [110.419622456470364, -7.075553104359112],
            [110.420353880210428, -7.075807289032211],
            [110.420510290598486, -7.075861756632562],
            [110.420921548550297, -7.075997950580345],
            [110.42097528884311, -7.076016108386634],
            [110.420982216355924, -7.0761337082964],
            [110.420942647835545, -7.076323614249681],
            [110.420888852218894, -7.076576820885044],
            [110.42090992784297, -7.076694438391593],
            [110.421032326705074, -7.076794090954259],
            [110.421193253649577, -7.076857609457157],
            [110.421357859159485, -7.076921132480233],
            [110.421490150622162, -7.077029842715174],
            [110.421532776657372, -7.077111305047933],
            [110.421532664020091, -7.077201759754155],
            [110.421489688771274, -7.077400707006941],
            [110.42141408256434, -7.077671977566147],
            [110.421320649556037, -7.07794322593996],
            [110.42127745878139, -7.078087899983063],
            [110.421187625213904, -7.078422471173181],
            [110.421109698895933, -7.078729778285207],
            [110.421055550934199, -7.078820308169552],
            [110.420922921753942, -7.079209098930268],
            [110.420715545437773, -7.079860115575168],
            [110.420509458378845, -7.080384496924156],
            [110.420394100368796, -7.080764263465226],
            [110.420183586695359, -7.081207229745246],
            [110.42007403436871, -7.081469412143039],
            [110.419982037086186, -7.08172257090945],
            [110.419844810367834, -7.081939491321902],
            [110.419748896551894, -7.082156463215468],
            [110.419547877788304, -7.082472804213498],
            [110.419469077439587, -7.082581251628207],
            [110.419363995131306, -7.082662529782283],
            [110.419281912275579, -7.082680518271115],
            [110.419227288463489, -7.082689495551506],
            [110.419131419486462, -7.08264414844843],
            [110.419076806997637, -7.082644080246727],
            [110.41899467884997, -7.082698250541847],
            [110.418674688798447, -7.083113942726304],
            [110.418464625271525, -7.083421226368844],
            [110.418335037417947, -7.083638155774453],
            [110.418225200429887, -7.083900337223962],
            [110.41806511932684, -7.084289092436494],
            [110.417900555840731, -7.084641660050791],
            [110.4178265312798, -7.084777124402745],
            [110.417635041592376, -7.084713691419243],
            [110.417370603315092, -7.08460481447282],
            [110.417162690466199, -7.084550281083414],
            [110.41626458786142, -7.084295881332929],
            [110.415968163539873, -7.084196008955147],
            [110.415763160467975, -7.084078160310677],
            [110.415432237242115, -7.083960153324966],
            [110.414712145834258, -7.08368788350493],
            [110.414370119028263, -7.083624134657774],
            [110.413806812486868, -7.083560106722884],
            [110.413350819055509, -7.083441940673302],
            [110.412761120136608, -7.083215059777103],
            [110.411954512892265, -7.082888403858298],
            [110.411606319291693, -7.082779417923941],
            [110.410803774540454, -7.082597492720735],
            [110.410776632786522, -7.082579367409086],
            [110.410960159738949, -7.081557462177515],
            [110.411121079114693, -7.08073452842402],
            [110.41119920790274, -7.080264263011915],
            [110.411324006344529, -7.07958601085129],
            [110.411415751290789, -7.079088626166928],
            [110.411388361133191, -7.079043364184942],
            [110.410996242321829, -7.078988595268216],
            [110.410579303194638, -7.078870476326763],
            [110.410132393160325, -7.07873422811094],
            [110.410095879389615, -7.07874322729678],
            [110.410077746751455, -7.07876129524508],
            [110.41003574176942, -7.078860742124635],
            [110.409926039522247, -7.079014375963028],
            [110.409706657799006, -7.079303552639947],
            [110.409441944054052, -7.079637898983751],
            [110.409303096122287, -7.079791495649448],
            [110.409111207196261, -7.080044525018592],
            [110.408969858405442, -7.080161936532741],
            [110.408841912509402, -7.080197955854535],
            [110.408673265818607, -7.080197741564505],
            [110.408162573031106, -7.080151864970526],
            [110.407355391709856, -7.080060382992428],
            [110.406885775322678, -7.079978375491981],
            [110.406478675141869, -7.079935293772666],
            [110.40607738265895, -7.079896189968466],
            [110.405369190134849, -7.079850261784153],
            [110.40468250892134, -7.079839880074159],
            [110.404352879288282, -7.079821367336165],
            [110.404068841691213, -7.079775776388472],
            [110.403898636853825, -7.079667012915626],
            [110.403807754809279, -7.079485987373636],
            [110.403774135963872, -7.079223626085522],
            [110.403763049688038, -7.079042702766101],
            [110.403626812386548, -7.078924937279255],
            [110.403315375297041, -7.07876977957072],
            [110.4033153982909, -7.078769754528595],
            [110.403468194440322, -7.078659641816528],
            [110.403589539178839, -7.078602879717293],
            [110.403720515588546, -7.078569572909747],
            [110.403868088687901, -7.078555420918292],
            [110.404126889652559, -7.078638343038387],
            [110.404317181344368, -7.078691501055386],
            [110.404376893076588, -7.0786684986033],
            [110.404421249822121, -7.07860893494186],
            [110.404433851311111, -7.078490942047755],
            [110.404394864510536, -7.078353965456899],
            [110.404331029275951, -7.078239566991279],
            [110.4042064717339, -7.078102745118373],
            [110.404084226378856, -7.077975389342199],
            [110.403984284276916, -7.077866589397262],
            [110.403915995462555, -7.077765909230451],
            [110.403887284902922, -7.077635092178797],
            [110.403882690902606, -7.077349180680178],
            [110.403845916277902, -7.077183952176206],
            [110.403793620254234, -7.07711185108453],
            [110.403730164407349, -7.077052149384826],
            [110.403653182639644, -7.07703089513929],
            [110.40353494166925, -7.077068361525147],
            [110.403358630534612, -7.077131165324624],
            [110.402960170765553, -7.077371906202318],
            [110.402757631100471, -7.077528133998729],
            [110.402562742210037, -7.077623777812809],
            [110.402352847789516, -7.07761020514012],
            [110.402189347557879, -7.077546368854579],
            [110.402046297466214, -7.077438109773423],
            [110.401926878380706, -7.077248867176247],
            [110.401922325705144, -7.077092141482201],
            [110.401970797712366, -7.076933774964871],
            [110.402123349856211, -7.076736187970959],
            [110.402447875503512, -7.076464924754212],
            [110.402746158543962, -7.076297952696223],
            [110.403173146242935, -7.076195680646034],
            [110.403539347783706, -7.076138866972191],
            [110.403823823924441, -7.076079110139216],
            [110.403898949419201, -7.076046511259571],
            [110.403947163783116, -7.075981182841302],
            [110.403966667621404, -7.075785037367345],
            [110.403906687010505, -7.07560393934704],
            [110.4038534449586, -7.075500252946863],
            [110.403765519165944, -7.075408149020305],
            [110.403256860943415, -7.075057573626424],
            [110.402965547120971, -7.074881151604629],
            [110.402891709054657, -7.074820117109087],
            [110.40285913754505, -7.074726700959982],
            [110.402884322045566, -7.074575856237291],
            [110.403009725221992, -7.074370230402501],
            [110.403190509290837, -7.074205500275768],
            [110.4034844308826, -7.074045042317026],
            [110.403711780194186, -7.07401336315069],
            [110.403921973235541, -7.074049814064999],
            [110.404031033501653, -7.074176590057719],
            [110.404128293109352, -7.074356277104612],
            [110.404207041882643, -7.074500620849601],
            [110.404314728069451, -7.074598843845703],
            [110.404447457705857, -7.074665705273523],
            [110.404651934531046, -7.074694221461661],
            [110.404935846492094, -7.074693309371947],
            [110.405278661220308, -7.074590697168709],
            [110.405605134105926, -7.074495482620299],
            [110.40579015499452, -7.074426168647663],
            [110.405888212956825, -7.074306754524648],
            [110.405949337283772, -7.074145997891566],
            [110.40597881312965, -7.074052846045523],
            [110.405974762832471, -7.073958545593801],
            [110.405934865139216, -7.073793152523264],
            [110.405869663880381, -7.073662373623121],
            [110.405819642644119, -7.073566063160543],
            [110.40574433942453, -7.073471545252804],
            [110.405615789342477, -7.073394634647216],
            [110.405392998856158, -7.073275410775017],
            [110.404991424951604, -7.073084229476454],
            [110.404394145337562, -7.072752305110541],
            [110.404321048333159, -7.072671765508743],
            [110.404288919029881, -7.072556484519232],
            [110.404294396145545, -7.072437096141332],
            [110.404319318944047, -7.072350802186818],
            [110.404375570142776, -7.072191025215956],
            [110.40446998638842, -7.072014258846046],
            [110.404469991827085, -7.072014249551897],
            [110.404539304912504, -7.072026512288368],
            [110.404682018183195, -7.072051739693266],
            [110.404997777366148, -7.072070234035616],
            [110.405214807002551, -7.072070511194396],
            [110.405451562807073, -7.072134131657576],
            [110.405698233822136, -7.072188719194007],
            [110.40582639122367, -7.072206973603745],
            [110.406073108563831, -7.072225379158837],
            [110.406230965168604, -7.072252716787469],
            [110.406388833346838, -7.07227100891622],
            [110.406556035310686, -7.072290798034806],
            [110.40672437690732, -7.072307618243533],
            [110.406902121535893, -7.072271662735551],
            [110.407030325187165, -7.072253735024003],
            [110.407237439878998, -7.072263044089976],
            [110.407355942183287, -7.072308422191495],
            [110.407563010806783, -7.072353912914457],
            [110.407858917222796, -7.072408561952878],
            [110.408105623322186, -7.072436011897488],
            [110.408362221532016, -7.072472519743868],
            [110.408432349449185, -7.072508790673737],
            [110.408557631477223, -7.072563222559859],
            [110.408845072411538, -7.072599769309828],
            [110.409338733532977, -7.072681804768743],
            [110.409435188315172, -7.072709063472674],
            [110.409463709789676, -7.072754326970132],
            [110.40951016833364, -7.072935295216328],
            [110.409538517470011, -7.07311624050209],
            [110.409588543937005, -7.073161531256035],
            [110.409649380338081, -7.073161608369731],
            [110.409965435192163, -7.073171054327846],
            [110.410276656803248, -7.073198584889828],
            [110.410755933832348, -7.073244418827208],
            [110.411175194872527, -7.073271861467583],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 85,
        id: 87,
        area: 1446134.1303290001,
        perimeter: 6517.373689,
        kode_pos: 50267,
        kode_kec: 3374030,
        kode_kel: 3374031004,
        kec: "Banyumanik",
        kel: "Padangsari",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.419385990923914, -7.075480260318228],
            [110.419658470658518, -7.075037556751608],
            [110.419983942894049, -7.074531415227742],
            [110.420307444949188, -7.07401622547236],
            [110.420556380976407, -7.07361853395768],
            [110.420634681125293, -7.073455812645473],
            [110.420745124860119, -7.073157449018879],
            [110.420871210148718, -7.072795786402338],
            [110.421061707415944, -7.072289476018573],
            [110.421240885839396, -7.071783151450788],
            [110.421397031547471, -7.071367253033651],
            [110.421457855019881, -7.071150236887658],
            [110.421461962770849, -7.071032650659749],
            [110.421496525614941, -7.070544237279038],
            [110.42150864458344, -7.07012815991475],
            [110.421487919261196, -7.069956417875188],
            [110.421721337844374, -7.069974650636675],
            [110.422352302025487, -7.070002569717602],
            [110.422907422174632, -7.070039439513696],
            [110.423229667354676, -7.070076020459714],
            [110.42355582917493, -7.070148787993991],
            [110.424244870644387, -7.070348640722274],
            [110.424687239524758, -7.07049391500902],
            [110.425215051225521, -7.070648339803149],
            [110.426201983629724, -7.070902830119297],
            [110.427044879654034, -7.071166186809131],
            [110.427766453759645, -7.071375120063905],
            [110.429013318692995, -7.071720378842541],
            [110.429469589052005, -7.071838529342755],
            [110.42990991406802, -7.072038069396144],
            [110.430200131604678, -7.072119833973559],
            [110.430274305746195, -7.071857605193846],
            [110.430394724194031, -7.071495932432279],
            [110.430836214427003, -7.071668336540075],
            [110.431351252563388, -7.071858921041222],
            [110.431829855594714, -7.071995187551944],
            [110.431846866383594, -7.071968071791667],
            [110.431915630182644, -7.071732972583659],
            [110.432009121940993, -7.071407448477114],
            [110.432075666070816, -7.071136164508114],
            [110.432133111209765, -7.07090105144384],
            [110.432161418232752, -7.070892040441171],
            [110.432552108394276, -7.070955834967951],
            [110.43265904513045, -7.070974056207043],
            [110.432674358072376, -7.070946938341147],
            [110.432731553030237, -7.070684688357901],
            [110.432766044945694, -7.070476683748631],
            [110.432809940683057, -7.070214417551797],
            [110.432863545226397, -7.069879799121944],
            [110.43288206994707, -7.069771275608902],
            [110.432932223707553, -7.069481880482912],
            [110.432937100186678, -7.069427613386112],
            [110.433047193255845, -7.069409656330434],
            [110.433090530073187, -7.069373527031327],
            [110.43311068630949, -7.069319278518704],
            [110.433139647339743, -7.069237904198349],
            [110.433235728338445, -7.06887620083074],
            [110.433365701604188, -7.068333628509214],
            [110.43338018757099, -7.068288418589112],
            [110.434088909943881, -7.068361643715745],
            [110.434816968087887, -7.068416502063656],
            [110.43490998566827, -7.068430170007705],
            [110.435501817300107, -7.068517130800814],
            [110.436283500064391, -7.06862662304975],
            [110.436876754721126, -7.068717795354945],
            [110.437128780658014, -7.068750719647466],
            [110.437435861554249, -7.069252465817038],
            [110.437564220011851, -7.069471815019444],
            [110.437860237351188, -7.069723034558177],
            [110.438026769223086, -7.069831781576353],
            [110.438221882865108, -7.069958866910261],
            [110.438362118734844, -7.070031187179567],
            [110.438568276109621, -7.070130936267457],
            [110.438807767090879, -7.070236525645623],
            [110.439087272242958, -7.070359756937773],
            [110.439046671878515, -7.070479455634175],
            [110.439020498606652, -7.070556620087678],
            [110.438978239359145, -7.070637978830725],
            [110.438924966486752, -7.070701233278246],
            [110.438882707208052, -7.070782592009775],
            [110.438848392629112, -7.070845869277669],
            [110.438817745604098, -7.070918196473189],
            [110.438787065814324, -7.071017660166548],
            [110.438783321822456, -7.071071928737065],
            [110.438588099090211, -7.071523969183702],
            [110.438431739907884, -7.071894646749008],
            [110.438406445988832, -7.072220254705142],
            [110.438372053547596, -7.0725820337313],
            [110.438333927883534, -7.07298903580341],
            [110.438335450768861, -7.07313376583112],
            [110.43835686839769, -7.073206155753615],
            [110.43835132817695, -7.073341831751331],
            [110.438267037158781, -7.073549776866769],
            [110.438268658402862, -7.073613097403759],
            [110.438327556911517, -7.073812169695891],
            [110.4383506723333, -7.073884561666518],
            [110.438327218028292, -7.074092580146859],
            [110.438201701843496, -7.074228111418293],
            [110.438061776389972, -7.074345534240067],
            [110.438015151827003, -7.074526388195475],
            [110.43803453402343, -7.074644003227722],
            [110.43815595652535, -7.074852177984751],
            [110.438086270961065, -7.074915430988256],
            [110.437844522510659, -7.074996548795373],
            [110.437694247516049, -7.07501445837057],
            [110.43738951877512, -7.074995999265125],
            [110.437217870082335, -7.074905336745699],
            [110.437088317112142, -7.074868998113658],
            [110.4369038146572, -7.074877820547999],
            [110.436640887749462, -7.074922730070005],
            [110.436506719684587, -7.074958749763597],
            [110.43622855663375, -7.074967458575887],
            [110.436024518764242, -7.07498530251267],
            [110.435676343156445, -7.075093426769659],
            [110.4354957361723, -7.075156526399085],
            [110.435257577812351, -7.075310011221089],
            [110.435190732670037, -7.075364203179808],
            [110.435174581809676, -7.075382274600747],
            [110.435161988510089, -7.075499850905214],
            [110.43518872702829, -7.075617474922103],
            [110.435258192948993, -7.075735150765619],
            [110.435356532114966, -7.075843816111404],
            [110.435374314810687, -7.075880019702542],
            [110.435384990458431, -7.075943351193678],
            [110.435359718811185, -7.076015684596657],
            [110.435241580291517, -7.076133132886524],
            [110.435148452441851, -7.076160156427097],
            [110.435071780438051, -7.076151017892953],
            [110.434989449176442, -7.076141872478461],
            [110.434725716576267, -7.076150597847263],
            [110.43454293343602, -7.076141330379754],
            [110.434408797931468, -7.076150212951838],
            [110.434317389848061, -7.07615914740197],
            [110.434281747515428, -7.076150058589441],
            [110.434172578854742, -7.076104698402804],
            [110.43408575327615, -7.07606841085532],
            [110.433867599827508, -7.076059100131758],
            [110.433735162127306, -7.076067984575103],
            [110.433394419548549, -7.076112797533169],
            [110.433134321380805, -7.076157708433948],
            [110.432853511664078, -7.076247821467476],
            [110.432485799034438, -7.076364964902003],
            [110.432362382915827, -7.076400996447822],
            [110.432197687327516, -7.076409841111438],
            [110.432006448497859, -7.076373425827953],
            [110.431749933746616, -7.076264566792899],
            [110.431639056511116, -7.076228249444722],
            [110.431409162591791, -7.076101331731097],
            [110.43127512676179, -7.076028804017539],
            [110.431210916344511, -7.076010634577288],
            [110.431124906721379, -7.076001483993351],
            [110.430980007605697, -7.076019397923361],
            [110.430912901100626, -7.076055497900653],
            [110.430819739840473, -7.076109657014053],
            [110.430793130249313, -7.07611866998143],
            [110.430728636881213, -7.076100500129294],
            [110.430651715322711, -7.076064224072213],
            [110.430553299690175, -7.076018876215546],
            [110.430492767826635, -7.076000711177744],
            [110.430431908651144, -7.076018727727224],
            [110.430361123593386, -7.076054823121424],
            [110.430146188408742, -7.076190242570903],
            [110.430038912187612, -7.076217247747223],
            [110.429870504868859, -7.076253223538675],
            [110.429803725623231, -7.076253141756082],
            [110.429760715262034, -7.076253089077476],
            [110.429705254533616, -7.076253021143937],
            [110.42944030129118, -7.07633410598754],
            [110.429300101304904, -7.076442480109201],
            [110.429235746466063, -7.07654190167954],
            [110.429233970897812, -7.076605217979896],
            [110.429233826428842, -7.076722809260023],
            [110.429219361967441, -7.07674992801568],
            [110.429168406366912, -7.076767956530293],
            [110.429054272153721, -7.076849226032969],
            [110.428996808382593, -7.07686724654532],
            [110.428835802302657, -7.076867049030625],
            [110.428678441376732, -7.076893992421153],
            [110.428642776883947, -7.07690299414559],
            [110.428535172963848, -7.076966180534576],
            [110.428503209241555, -7.076957095799703],
            [110.428388675898447, -7.076902682206233],
            [110.428353022531695, -7.076902638426365],
            [110.428324160282585, -7.076902602983519],
            [110.428256182314058, -7.076956792470138],
            [110.428150625886204, -7.076965708310137],
            [110.428014926033541, -7.076866041152515],
            [110.427928960918422, -7.076820708046982],
            [110.427875480882108, -7.076820642324101],
            [110.427639128643335, -7.07688367025099],
            [110.427478011120002, -7.07697392706829],
            [110.427374085568275, -7.077037117698748],
            [110.427259768412142, -7.077036977063745],
            [110.427181126934926, -7.077018789314471],
            [110.427131031287175, -7.077027773162096],
            [110.427070149567356, -7.077063880209069],
            [110.427027072211871, -7.07711810014843],
            [110.427048499327938, -7.077181444973739],
            [110.427087686207187, -7.07729908461551],
            [110.427083963059303, -7.077335262005829],
            [110.42700152028408, -7.077416569967546],
            [110.426876316508114, -7.077524961743161],
            [110.426666223597167, -7.077633248928289],
            [110.426494691825553, -7.077678265078601],
            [110.426465807200657, -7.077696320473262],
            [110.426419334150751, -7.077750536160608],
            [110.426318997446529, -7.077886094884835],
            [110.426272569050539, -7.077904128642583],
            [110.426186559250368, -7.077894977126609],
            [110.426079304823574, -7.077903890383175],
            [110.426036260805873, -7.077930973780778],
            [110.426003947050091, -7.077976161389085],
            [110.42594662068197, -7.078111773057557],
            [110.425846406756847, -7.078147831412149],
            [110.425792654805832, -7.078138719611592],
            [110.425674703618853, -7.078102392119883],
            [110.425542377234251, -7.078020819405476],
            [110.425506734987223, -7.078011729924064],
            [110.425470765024343, -7.078038821997094],
            [110.425467324704798, -7.078074999712006],
            [110.42545271452741, -7.078219709522519],
            [110.42541699393891, -7.078273938368118],
            [110.42527734722475, -7.078391357321484],
            [110.42514317751754, -7.078427373584205],
            [110.425064547117955, -7.078400139990321],
            [110.425032278027231, -7.078409145617099],
            [110.424978481275346, -7.078436215624046],
            [110.424899694082441, -7.078535618659981],
            [110.424749260919086, -7.078680160575228],
            [110.424662982100287, -7.078888100168922],
            [110.424576182099372, -7.079059857139804],
            [110.424533115475867, -7.079105031330688],
            [110.424475934252555, -7.079123051596486],
            [110.424389834600504, -7.079186263528982],
            [110.424332384258832, -7.079421375158272],
            [110.424282209820674, -7.079493676991933],
            [110.424110237178283, -7.079665328468995],
            [110.423912265312651, -7.079809811222644],
            [110.423604480508416, -7.079972248843309],
            [110.423382445112026, -7.080125747024428],
            [110.423293028190656, -7.080125636210409],
            [110.4231855576963, -7.080080275569653],
            [110.423146281759358, -7.080034999450884],
            [110.42314633794382, -7.079989772090383],
            [110.42314308844432, -7.079872176743283],
            [110.423093104817781, -7.079790705403205],
            [110.422978638982016, -7.07968201764797],
            [110.422823154578481, -7.079564233508833],
            [110.422429787120606, -7.079373790259109],
            [110.422047150135569, -7.07920145099667],
            [110.421627478940849, -7.079001928964798],
            [110.421173263972463, -7.078820454586877],
            [110.421109698895933, -7.078729778285207],
            [110.421187625213904, -7.078422471173181],
            [110.42127745878139, -7.078087899983063],
            [110.421320649556037, -7.07794322593996],
            [110.42141408256434, -7.077671977566147],
            [110.421489688771274, -7.077400707006941],
            [110.421532664020091, -7.077201759754155],
            [110.421532776657372, -7.077111305047933],
            [110.421490150622162, -7.077029842715174],
            [110.421357859159485, -7.076921132480233],
            [110.421193253649577, -7.076857609457157],
            [110.421032326705074, -7.076794090954259],
            [110.42090992784297, -7.076694438391593],
            [110.420888852218894, -7.076576820885044],
            [110.420942647835545, -7.076323614249681],
            [110.420982216355924, -7.0761337082964],
            [110.42097528884311, -7.076016108386634],
            [110.420921548550297, -7.075997950580345],
            [110.420510290598486, -7.075861756632562],
            [110.420353880210428, -7.075807289032211],
            [110.419622456470364, -7.075553104359112],
            [110.419600679697538, -7.075544031731807],
            [110.419471433889498, -7.075489597711956],
            [110.419385990923914, -7.075480260318228],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 86,
        id: 88,
        area: 3484536.5556359999,
        perimeter: 10823.805766,
        kode_pos: 50266,
        kode_kec: 3374030,
        kode_kel: 3374031002,
        kec: "Banyumanik",
        kel: "Gedawang",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.4178265312798, -7.084777124402745],
            [110.417900555840731, -7.084641660050791],
            [110.41806511932684, -7.084289092436494],
            [110.418225200429887, -7.083900337223962],
            [110.418335037417947, -7.083638155774453],
            [110.418464625271525, -7.083421226368844],
            [110.418674688798447, -7.083113942726304],
            [110.41899467884997, -7.082698250541847],
            [110.419076806997637, -7.082644080246727],
            [110.419131419486462, -7.08264414844843],
            [110.419227288463489, -7.082689495551506],
            [110.419281912275579, -7.082680518271115],
            [110.419363995131306, -7.082662529782283],
            [110.419469077439587, -7.082581251628207],
            [110.419547877788304, -7.082472804213498],
            [110.419748896551894, -7.082156463215468],
            [110.419844810367834, -7.081939491321902],
            [110.419982037086186, -7.08172257090945],
            [110.42007403436871, -7.081469412143039],
            [110.420183586695359, -7.081207229745246],
            [110.420394100368796, -7.080764263465226],
            [110.420509458378845, -7.080384496924156],
            [110.420715545437773, -7.079860115575168],
            [110.420922921753942, -7.079209098930268],
            [110.421055550934199, -7.078820308169552],
            [110.421109698895933, -7.078729778285207],
            [110.421173263972463, -7.078820454586877],
            [110.421627478940849, -7.079001928964798],
            [110.422047150135569, -7.07920145099667],
            [110.422429787120606, -7.079373790259109],
            [110.422823154578481, -7.079564233508833],
            [110.422978638982016, -7.07968201764797],
            [110.423093104817781, -7.079790705403205],
            [110.42314308844432, -7.079872176743283],
            [110.42314633794382, -7.079989772090383],
            [110.423146281759358, -7.080034999450884],
            [110.4231855576963, -7.080080275569653],
            [110.423293028190656, -7.080125636210409],
            [110.423382445112026, -7.080125747024428],
            [110.423604480508416, -7.079972248843309],
            [110.423912265312651, -7.079809811222644],
            [110.424110237178283, -7.079665328468995],
            [110.424282209820674, -7.079493676991933],
            [110.424332384258832, -7.079421375158272],
            [110.424389834600504, -7.079186263528982],
            [110.424475934252555, -7.079123051596486],
            [110.424533115475867, -7.079105031330688],
            [110.424576182099372, -7.079059857139804],
            [110.424662982100287, -7.078888100168922],
            [110.424749260919086, -7.078680160575228],
            [110.424899694082441, -7.078535618659981],
            [110.424978481275346, -7.078436215624046],
            [110.425032278027231, -7.078409145617099],
            [110.425064547117955, -7.078400139990321],
            [110.42514317751754, -7.078427373584205],
            [110.42527734722475, -7.078391357321484],
            [110.42541699393891, -7.078273938368118],
            [110.42545271452741, -7.078219709522519],
            [110.425467324704798, -7.078074999712006],
            [110.425470765024343, -7.078038821997094],
            [110.425506734987223, -7.078011729924064],
            [110.425542377234251, -7.078020819405476],
            [110.425674703618853, -7.078102392119883],
            [110.425792654805832, -7.078138719611592],
            [110.425846406756847, -7.078147831412149],
            [110.42594662068197, -7.078111773057557],
            [110.426003947050091, -7.077976161389085],
            [110.426036260805873, -7.077930973780778],
            [110.426079304823574, -7.077903890383175],
            [110.426186559250368, -7.077894977126609],
            [110.426272569050539, -7.077904128642583],
            [110.426318997446529, -7.077886094884835],
            [110.426419334150751, -7.077750536160608],
            [110.426465807200657, -7.077696320473262],
            [110.426494691825553, -7.077678265078601],
            [110.426666223597167, -7.077633248928289],
            [110.426876316508114, -7.077524961743161],
            [110.42700152028408, -7.077416569967546],
            [110.427083963059303, -7.077335262005829],
            [110.427087686207187, -7.07729908461551],
            [110.427048499327938, -7.077181444973739],
            [110.427027072211871, -7.07711810014843],
            [110.427070149567356, -7.077063880209069],
            [110.427131031287175, -7.077027773162096],
            [110.427181126934926, -7.077018789314471],
            [110.427259768412142, -7.077036977063745],
            [110.427374085568275, -7.077037117698748],
            [110.427478011120002, -7.07697392706829],
            [110.427639128643335, -7.07688367025099],
            [110.427875480882108, -7.076820642324101],
            [110.427928960918422, -7.076820708046982],
            [110.428014926033541, -7.076866041152515],
            [110.428150625886204, -7.076965708310137],
            [110.428256182314058, -7.076956792470138],
            [110.428324160282585, -7.076902602983519],
            [110.428353022531695, -7.076902638426365],
            [110.428388675898447, -7.076902682206233],
            [110.428503209241555, -7.076957095799703],
            [110.428535172963848, -7.076966180534576],
            [110.428642776883947, -7.07690299414559],
            [110.428678441376732, -7.076893992421153],
            [110.428835802302657, -7.076867049030625],
            [110.428996808382593, -7.07686724654532],
            [110.429054272153721, -7.076849226032969],
            [110.429168406366912, -7.076767956530293],
            [110.429219361967441, -7.07674992801568],
            [110.429233826428842, -7.076722809260023],
            [110.429233970897812, -7.076605217979896],
            [110.429235746466063, -7.07654190167954],
            [110.429300101304904, -7.076442480109201],
            [110.42944030129118, -7.07633410598754],
            [110.429705254533616, -7.076253021143937],
            [110.429760715262034, -7.076253089077476],
            [110.429803725623231, -7.076253141756082],
            [110.429870504868859, -7.076253223538675],
            [110.430038912187612, -7.076217247747223],
            [110.430146188408742, -7.076190242570903],
            [110.430361123593386, -7.076054823121424],
            [110.430431908651144, -7.076018727727224],
            [110.430492767826635, -7.076000711177744],
            [110.430553299690175, -7.076018876215546],
            [110.430651715322711, -7.076064224072213],
            [110.430728636881213, -7.076100500129294],
            [110.430793130249313, -7.07611866998143],
            [110.430819739840473, -7.076109657014053],
            [110.430912901100626, -7.076055497900653],
            [110.430980007605697, -7.076019397923361],
            [110.431124906721379, -7.076001483993351],
            [110.431210916344511, -7.076010634577288],
            [110.43127512676179, -7.076028804017539],
            [110.431409162591791, -7.076101331731097],
            [110.431639056511116, -7.076228249444722],
            [110.431749933746616, -7.076264566792899],
            [110.432006448497859, -7.076373425827953],
            [110.432197687327516, -7.076409841111438],
            [110.432362382915827, -7.076400996447822],
            [110.432485799034438, -7.076364964902003],
            [110.432853511664078, -7.076247821467476],
            [110.433134321380805, -7.076157708433948],
            [110.433394419548549, -7.076112797533169],
            [110.433735162127306, -7.076067984575103],
            [110.433867599827508, -7.076059100131758],
            [110.43408575327615, -7.07606841085532],
            [110.434172578854742, -7.076104698402804],
            [110.434281747515428, -7.076150058589441],
            [110.434317389848061, -7.07615914740197],
            [110.434408797931468, -7.076150212951838],
            [110.43454293343602, -7.076141330379754],
            [110.434725716576267, -7.076150597847263],
            [110.434989449176442, -7.076141872478461],
            [110.435071780438051, -7.076151017892953],
            [110.435148452441851, -7.076160156427097],
            [110.435241580291517, -7.076133132886524],
            [110.435359718811185, -7.076015684596657],
            [110.435384990458431, -7.075943351193678],
            [110.435374314810687, -7.075880019702542],
            [110.435356532114966, -7.075843816111404],
            [110.435258192948993, -7.075735150765619],
            [110.43518872702829, -7.075617474922103],
            [110.435161988510089, -7.075499850905214],
            [110.435174581809676, -7.075382274600747],
            [110.435190732670037, -7.075364203179808],
            [110.435257577812351, -7.075310011221089],
            [110.4354957361723, -7.075156526399085],
            [110.435676343156445, -7.075093426769659],
            [110.436024518764242, -7.07498530251267],
            [110.43622855663375, -7.074967458575887],
            [110.436506719684587, -7.074958749763597],
            [110.436640887749462, -7.074922730070005],
            [110.4369038146572, -7.074877820547999],
            [110.437088317112142, -7.074868998113658],
            [110.437217870082335, -7.074905336745699],
            [110.43738951877512, -7.074995999265125],
            [110.437694247516049, -7.07501445837057],
            [110.437844522510659, -7.074996548795373],
            [110.438086270961065, -7.074915430988256],
            [110.43815595652535, -7.074852177984751],
            [110.438225837084119, -7.074861326319089],
            [110.438413746029923, -7.074843461955283],
            [110.438508527527858, -7.074852621764816],
            [110.438583991084542, -7.074925076846473],
            [110.438642106529656, -7.075069875097154],
            [110.438676898869431, -7.075314145854764],
            [110.438635084782945, -7.075495005675132],
            [110.43858566344619, -7.075648719781197],
            [110.438583083991261, -7.075675853204937],
            [110.438578806757917, -7.07570298458143],
            [110.438606678016598, -7.07582060983508],
            [110.438613392626507, -7.075883936510383],
            [110.438613327051286, -7.075938209499009],
            [110.438613272404851, -7.075983436989436],
            [110.438641176470455, -7.076073925747388],
            [110.438718272447076, -7.076200655856828],
            [110.43883715964148, -7.076399800422592],
            [110.439096135740797, -7.076581022746424],
            [110.439179740084441, -7.076707760634458],
            [110.439277666444141, -7.076924970880388],
            [110.439277448084198, -7.077105880854586],
            [110.439263429994554, -7.077232501134032],
            [110.439200132471456, -7.077395244091774],
            [110.439144050981668, -7.077440404081814],
            [110.438933699416111, -7.077530605698185],
            [110.438779429324796, -7.077575647278644],
            [110.438646370588799, -7.077629759897108],
            [110.438580657304897, -7.077683953707097],
            [110.438534174640381, -7.077747216214031],
            [110.438414970143739, -7.077810390981602],
            [110.438204629229631, -7.077891546746011],
            [110.43802255185193, -7.077999873063699],
            [110.437910105535366, -7.078090192378722],
            [110.437791019606863, -7.078289049752617],
            [110.437754809365615, -7.07851514373765],
            [110.437706833252804, -7.078876906131791],
            [110.43770658136475, -7.079084952528325],
            [110.437682365052424, -7.079220605898041],
            [110.437646656683981, -7.079265790292435],
            [110.437431993802946, -7.079410258975416],
            [110.437110207557836, -7.079455097400066],
            [110.436669270964046, -7.079517882390343],
            [110.436526167387541, -7.079454390588247],
            [110.436347333393883, -7.079454174014807],
            [110.436132845794944, -7.079453914173119],
            [110.436025547072632, -7.079499011678629],
            [110.435941984447922, -7.079571274446541],
            [110.435882352973948, -7.079743066771099],
            [110.435834191157994, -7.080023419055519],
            [110.435781883706255, -7.080222356752261],
            [110.435706221960999, -7.080312720053277],
            [110.435605659202693, -7.080403053135687],
            [110.435536505605867, -7.080493424297721],
            [110.435479758487361, -7.080619992518632],
            [110.43545443155098, -7.080737553349893],
            [110.435441544104123, -7.080864174777064],
            [110.435473049325182, -7.081017986582748],
            [110.435497818365306, -7.081126562690947],
            [110.435510408869675, -7.081244169526463],
            [110.435522749405493, -7.081334639544711],
            [110.435528864685338, -7.081425102009522],
            [110.435522529444953, -7.08151554936753],
            [110.435516249192716, -7.081560769269559],
            [110.435491021185982, -7.081596920675675],
            [110.43545330965182, -7.081660193444233],
            [110.43545321065352, -7.08174160286256],
            [110.435465584182879, -7.081804936407874],
            [110.435522056510308, -7.081904505481811],
            [110.435534735015707, -7.081949748387386],
            [110.435547119558123, -7.082004036440335],
            [110.435540828299196, -7.082058301832345],
            [110.435521803534328, -7.082112551772822],
            [110.435490328225924, -7.082166786603235],
            [110.435477822686849, -7.082211998947968],
            [110.435477745685546, -7.082275317383239],
            [110.435496660465091, -7.082311522353616],
            [110.435521506552789, -7.082356780025105],
            [110.435546569617216, -7.082456310983658],
            [110.435552706897539, -7.08252868246385],
            [110.435559127145964, -7.082601054287253],
            [110.435584190227658, -7.082700585244424],
            [110.435584014244554, -7.082845313095851],
            [110.435564978445512, -7.082908608524753],
            [110.435539574357705, -7.083089487774735],
            [110.435495417381773, -7.083333662786568],
            [110.435482202842053, -7.083496465813697],
            [110.435475856530005, -7.083595958650343],
            [110.435456820677672, -7.083659254069188],
            [110.435425334239284, -7.083722534372821],
            [110.435337133178678, -7.083885246354592],
            [110.435267945939145, -7.084002753887324],
            [110.435229973202311, -7.08404793529046],
            [110.435160896002458, -7.084074987907077],
            [110.435035269252921, -7.084065789807626],
            [110.434922104112971, -7.084047561313811],
            [110.434857881413905, -7.084038437772654],
            [110.434832631177485, -7.084092680102573],
            [110.434832565083269, -7.084146953036659],
            [110.434876204816732, -7.084327916116965],
            [110.434901278827766, -7.084418401610386],
            [110.434894943378637, -7.084508848935371],
            [110.434888651986967, -7.084563114304034],
            [110.434907272814499, -7.084608364443663],
            [110.434969481773749, -7.084644622044449],
            [110.434989245498784, -7.084680828067776],
            [110.43499230308251, -7.084726059294887],
            [110.43497274522646, -7.084753172038415],
            [110.434900522208196, -7.08480735729052],
            [110.434864258192505, -7.084843495228656],
            [110.434834774396094, -7.084888686906202],
            [110.434772084122315, -7.085015247736931],
            [110.434742578267247, -7.085078530383735],
            [110.434736036933828, -7.08510565893758],
            [110.434735992857171, -7.085141840891473],
            [110.434758847198111, -7.085196141684908],
            [110.434804644039275, -7.085232379361104],
            [110.434860322756364, -7.085286720047774],
            [110.434889990399739, -7.085322938112961],
            [110.434902973910383, -7.085350090398289],
            [110.434909449141969, -7.085377234773826],
            [110.434912756637189, -7.085449602809646],
            [110.434892893740766, -7.085494806179455],
            [110.434863387865875, -7.08555808883152],
            [110.434824022145094, -7.085585177488784],
            [110.434754933670831, -7.085621275511638],
            [110.434692092543344, -7.085639290115836],
            [110.434645935553348, -7.085666370499571],
            [110.434626355589785, -7.085711574198851],
            [110.434609888280406, -7.085756781681971],
            [110.434586618689337, -7.085811026393525],
            [110.434504502566341, -7.085856154033129],
            [110.434337190700106, -7.085919269015207],
            [110.434228791506612, -7.085937228122468],
            [110.434150126118595, -7.085937132390586],
            [110.434054787627176, -7.085918925349477],
            [110.433982641566729, -7.085909792024601],
            [110.433894039161544, -7.085936820653254],
            [110.433821848950373, -7.08596386925035],
            [110.433764894901415, -7.086027118391706],
            [110.433732320339601, -7.086054215218663],
            [110.433679677051487, -7.086063196598508],
            [110.433564769521851, -7.086081147618545],
            [110.433518928531271, -7.086081091767363],
            [110.433476211246116, -7.086071994218349],
            [110.433443408925882, -7.086053863248244],
            [110.433390754587947, -7.086071890080929],
            [110.433302141076908, -7.086107964077244],
            [110.433220024792135, -7.086153091475322],
            [110.433085265181404, -7.086207200167812],
            [110.433013052780723, -7.086252339602592],
            [110.43296068137596, -7.086270366728392],
            [110.432914557402356, -7.086270310471014],
            [110.432845862242999, -7.086215953683735],
            [110.432813059941225, -7.086197822670413],
            [110.43277345532465, -7.086188728854406],
            [110.432747422167012, -7.086188697092219],
            [110.432717688290751, -7.086206751809891],
            [110.432684808569206, -7.086251939181749],
            [110.432648771933344, -7.086333304691172],
            [110.432642208332425, -7.086378524172388],
            [110.432638746329459, -7.086432792936598],
            [110.432618861044332, -7.086496087155675],
            [110.432592750428057, -7.086559373775397],
            [110.432559848539441, -7.086622652103738],
            [110.432553013018378, -7.086658825752289],
            [110.432569392051008, -7.086685982238038],
            [110.432592584478328, -7.086695056043244],
            [110.432654804561167, -7.086722268473776],
            [110.432726873277772, -7.086794720405129],
            [110.432779450300089, -7.086840012049986],
            [110.432854687012707, -7.086867240337903],
            [110.432917212220332, -7.086876362111523],
            [110.433005715350347, -7.086930743054529],
            [110.433117105879148, -7.087012288388457],
            [110.433238683356549, -7.08709384611841],
            [110.433313875941792, -7.08715725627895],
            [110.433399277556433, -7.087202587874251],
            [110.43348440726578, -7.087238873624561],
            [110.433573215596937, -7.087275163842683],
            [110.433678175310106, -7.087293382723234],
            [110.433822489922051, -7.087293558516227],
            [110.433976425512171, -7.087293745979376],
            [110.434154413543453, -7.087293962670106],
            [110.434246073710497, -7.087312165235351],
            [110.434283653545123, -7.087357438472399],
            [110.434337623581428, -7.087420822652826],
            [110.434391321691905, -7.087475160996654],
            [110.434402313403183, -7.087511356372765],
            [110.434396892855091, -7.087547531778954],
            [110.434364296152296, -7.087592719616772],
            [110.43426176170756, -7.087674004337785],
            [110.434245588281712, -7.087710166653578],
            [110.434261651382656, -7.087764459205006],
            [110.434315599395447, -7.087845934363922],
            [110.434460924758355, -7.087945611706421],
            [110.434573980541415, -7.088054295254318],
            [110.434622541178214, -7.088144809330309],
            [110.434627785302354, -7.088253361715443],
            [110.434627630934273, -7.088379998534342],
            [110.434622166281713, -7.088452355890444],
            [110.434600605382542, -7.088497557164223],
            [110.434525008117447, -7.088533647195248],
            [110.434373846649251, -7.088578690757766],
            [110.434260581156195, -7.088641871398725],
            [110.4341891952987, -7.088705102996347],
            [110.434118669371117, -7.088759290128817],
            [110.434069954252521, -7.088795412809638],
            [110.434011074256716, -7.088813432107126],
            [110.433936641904822, -7.088822386955334],
            [110.433825107251778, -7.088858433091218],
            [110.433698009189186, -7.088894460235085],
            [110.433625535524172, -7.088921508416055],
            [110.433566666547136, -7.088930482169342],
            [110.43342483186504, -7.088984582270824],
            [110.433219045736735, -7.08903860433806],
            [110.433130703543966, -7.089083724080588],
            [110.433107172731511, -7.089119877369533],
            [110.433072029049129, -7.089165061988373],
            [110.433048409759351, -7.089273579147345],
            [110.433042412074585, -7.089318799318758],
            [110.432995405704929, -7.089345878461993],
            [110.43291308325486, -7.089327687018682],
            [110.432719336624913, -7.089255086578033],
            [110.432648876852966, -7.089255000564413],
            [110.432578394946404, -7.089273005507065],
            [110.432448933296257, -7.089390438877119],
            [110.432331006958464, -7.089562159275544],
            [110.432260436399702, -7.089652528027773],
            [110.432207387878563, -7.089761009168996],
            [110.432195697448591, -7.0898333588544],
            [110.432219029005964, -7.089960024309343],
            [110.432306866991723, -7.090095814071676],
            [110.432389089887792, -7.090195414976233],
            [110.432418408202963, -7.09028590575255],
            [110.432429877169938, -7.090394465717879],
            [110.432429755372311, -7.090493966028512],
            [110.432464861107007, -7.09071110082025],
            [110.43251172372014, -7.090801613022093],
            [110.432570493314003, -7.090874048769356],
            [110.432681796102287, -7.091027958126769],
            [110.432752134404609, -7.091127544470299],
            [110.432821131151016, -7.091166889716843],
            [110.432404600248844, -7.091732496438525],
            [110.432392880026612, -7.091806061340741],
            [110.432435343679359, -7.09195327166495],
            [110.432512693038291, -7.092104397194678],
            [110.432570748937493, -7.092181919925443],
            [110.432605535084576, -7.092267159409912],
            [110.432617075168324, -7.092340752727973],
            [110.43259368688409, -7.092445284108081],
            [110.432543180528398, -7.092534291996552],
            [110.432527607284698, -7.092588489240005],
            [110.432544846283704, -7.092756967989406],
            [110.432501989008301, -7.092931182204391],
            [110.432451449381617, -7.093047298168014],
            [110.432412539895381, -7.093163428335648],
            [110.43240852564162, -7.093275728551544],
            [110.432443288112651, -7.093380330971299],
            [110.43248968551552, -7.093481075015436],
            [110.432524438533591, -7.093593422600088],
            [110.432572653429261, -7.093792919914241],
            [110.432611245284292, -7.093936252907737],
            [110.432607197867412, -7.094075661217031],
            [110.432587657743753, -7.09420343282565],
            [110.432560416302906, -7.094288596528551],
            [110.432537032615016, -7.094389255303799],
            [110.432480423968585, -7.094712547411653],
            [110.432425865067302, -7.094944836139785],
            [110.432414040366183, -7.095103597880638],
            [110.432473989892586, -7.095217912562357],
            [110.432508705059078, -7.09536124082434],
            [110.43255124486997, -7.095446489795361],
            [110.432597642468309, -7.095547233838104],
            [110.432647945281474, -7.095624747108297],
            [110.432709925758815, -7.09566354873997],
            [110.432799057677812, -7.09569076576206],
            [110.432876554635584, -7.095721841147037],
            [110.432923023428486, -7.09576449639621],
            [110.432969435355602, -7.09585362265579],
            [110.433000785215981, -7.095975163462338],
            [110.433000672655254, -7.09606713734485],
            [110.432986069684063, -7.096120367627787],
            [110.432966620773911, -7.096173591991532],
            [110.43293747406247, -7.096231645247205],
            [110.432937420736522, -7.096275211822213],
            [110.43295190522015, -7.096318796153315],
            [110.432976093429971, -7.096352710859899],
            [110.433043918776278, -7.096367315904689],
            [110.433106915960522, -7.096367392830826],
            [110.433155345718816, -7.096391655652534],
            [110.433184373963059, -7.096430416996967],
            [110.433232614220216, -7.096609583195279],
            [110.433266399593379, -7.096720961412942],
            [110.433314817553665, -7.096754905682769],
            [110.43340197937377, -7.096808260187709],
            [110.433503619845837, -7.096910039727009],
            [110.433600408499316, -7.097016654065676],
            [110.433629395388053, -7.097089300504068],
            [110.433806020928245, -7.097295247337502],
            [110.433883390437543, -7.097430882371039],
            [110.433955890377206, -7.097585874409853],
            [110.434028372617263, -7.097755388633662],
            [110.434139634369615, -7.097915268648912],
            [110.434313893626211, -7.098075225423215],
            [110.434471236481599, -7.09819885598975],
            [110.434519642865581, -7.09824248161393],
            [110.434383518566449, -7.098600530403476],
            [110.43438347718255, -7.098634415522447],
            [110.434451231982052, -7.098707109166034],
            [110.434795094491122, -7.098872113165517],
            [110.435029883562095, -7.098965724462529],
            [110.43502988213443, -7.098965725012581],
            [110.434815174298208, -7.099048447490128],
            [110.434495839329969, -7.099172281447061],
            [110.434160019380741, -7.09921742053772],
            [110.433876113578918, -7.099181877799275],
            [110.433693738302367, -7.09916923299857],
            [110.433540344248527, -7.099185608853019],
            [110.433415911124285, -7.099247568422132],
            [110.433287297258104, -7.099338508199881],
            [110.433183514233392, -7.099462604380044],
            [110.433137941938028, -7.099441844895303],
            [110.433026091443011, -7.099383737560474],
            [110.432943277929624, -7.099309102625346],
            [110.432930928414038, -7.099238694546576],
            [110.432922724138507, -7.09916829153267],
            [110.432931197148605, -7.099019234378722],
            [110.432893976228002, -7.098948795909051],
            [110.432848429351864, -7.098907332611127],
            [110.432744925281881, -7.098803687015444],
            [110.432670468322485, -7.098675232323201],
            [110.432658154365797, -7.098575838935413],
            [110.432658265960157, -7.098484742263937],
            [110.432716344839505, -7.098447546377177],
            [110.432884249496311, -7.098429118125906],
            [110.432967235221, -7.098362967287986],
            [110.432988012064826, -7.098321585027475],
            [110.432992268819106, -7.098230493413595],
            [110.432934296453212, -7.098180733420148],
            [110.43286801336302, -7.098147526322776],
            [110.432780983860425, -7.09813085692092],
            [110.432714634858058, -7.098151479655831],
            [110.432656576306542, -7.09817211251436],
            [110.432594402958912, -7.098167895751018],
            [110.432577857557504, -7.098138890176866],
            [110.432540646899383, -7.09806017017049],
            [110.432536557477647, -7.098014616767027],
            [110.432557354632166, -7.097956671495686],
            [110.432565700885831, -7.097911133294427],
            [110.432553326084218, -7.097861428998113],
            [110.432520220088023, -7.097815840121251],
            [110.432458102601643, -7.097766075007877],
            [110.432387679458358, -7.097728722022772],
            [110.432288265138411, -7.097670629752399],
            [110.432039683756969, -7.097562665809084],
            [110.431973405903875, -7.097525317837726],
            [110.431907082355949, -7.09752523667461],
            [110.431861515390352, -7.097500336325058],
            [110.431844995452281, -7.097450626942494],
            [110.431849196541293, -7.097405083684493],
            [110.431787099503325, -7.097338755461024],
            [110.431729076583949, -7.097330402907715],
            [110.4316435655481, -7.09734323810596],
            [110.431534721766326, -7.097368984601291],
            [110.431405151873392, -7.097394705683671],
            [110.431348199575368, -7.097358404241962],
            [110.43132235549588, -7.097306613041555],
            [110.431312100473804, -7.097218609258992],
            [110.431265587508875, -7.097120209148676],
            [110.431110230841924, -7.09704755543514],
            [110.431032527098367, -7.097031932344687],
            [110.430944396735924, -7.097068055998982],
            [110.430866578529105, -7.097145599907974],
            [110.430839035774497, -7.09717304556851],
            [110.430778384529916, -7.097233482986243],
            [110.430685021696561, -7.097311007798642],
            [110.430570983637622, -7.097347099600884],
            [110.430373939333691, -7.097465904776324],
            [110.430171783449097, -7.097527768101458],
            [110.429985140247155, -7.097615530147759],
            [110.429819242202811, -7.097687789737728],
            [110.42960923419804, -7.097814342564691],
            [110.429329374281991, -7.09786058215407],
            [110.429127281912514, -7.097870685556396],
            [110.428888880549522, -7.097911799908602],
            [110.428660906058823, -7.09790116745307],
            [110.428484740482062, -7.097895774630551],
            [110.428277492135919, -7.097885167506638],
            [110.428204944356139, -7.0978902540953],
            [110.428137526979157, -7.097936754602499],
            [110.428054603351811, -7.097952180285816],
            [110.427976925172331, -7.097915852907994],
            [110.42793034895648, -7.097869211948547],
            [110.427863085003864, -7.097791489780576],
            [110.427780244525096, -7.097739628157294],
            [110.427686977061285, -7.097739513173032],
            [110.427578165022126, -7.097739379001045],
            [110.427319018351739, -7.097795994809697],
            [110.427078074195762, -7.097798285458125],
            [110.426974450083591, -7.097792981596608],
            [110.426891475225148, -7.09784981460863],
            [110.426735984597073, -7.097885854251402],
            [110.426611621554997, -7.097890876593902],
            [110.426451000647077, -7.097885502203447],
            [110.426290405391953, -7.097859423991204],
            [110.426134927555822, -7.097885111545647],
            [110.426031245725355, -7.097926390945323],
            [110.425906780031383, -7.098014228157525],
            [110.425787450900586, -7.098138303330098],
            [110.425673386674262, -7.098195097644949],
            [110.425561954913974, -7.098218251520019],
            [110.425266543531237, -7.098269645309089],
            [110.424965924862391, -7.098341736246589],
            [110.424665415420748, -7.098325836001096],
            [110.424458186403598, -7.098299699343957],
            [110.424250970285769, -7.098263210716775],
            [110.42406964897458, -7.098237106026167],
            [110.423836576802927, -7.098159177556691],
            [110.423587934374481, -7.098101933429119],
            [110.423406626071085, -7.098065476609504],
            [110.423318520805253, -7.098080894995419],
            [110.423235564832993, -7.098122199496613],
            [110.423085274793877, -7.098142716508375],
            [110.422935081478471, -7.098085594392818],
            [110.422847130995109, -7.097976790165023],
            [110.422793743267533, -7.097910985816235],
            [110.422762199157461, -7.097940452927023],
            [110.42264184328846, -7.098056244434976],
            [110.422504705355962, -7.098143121232227],
            [110.422377108767648, -7.098157342311285],
            [110.422344717308363, -7.09754458357916],
            [110.422378482058363, -7.097056106839966],
            [110.422453732365199, -7.096534561776046],
            [110.422545312500887, -7.096211756494495],
            [110.42273649297519, -7.095781435284266],
            [110.42279473163093, -7.095607628105881],
            [110.422844939227204, -7.095226811426468],
            [110.422919930758852, -7.09491226537325],
            [110.42320198240607, -7.094634428663742],
            [110.422957467708258, -7.09419999173177],
            [110.422944256670263, -7.093901474465874],
            [110.42288030469939, -7.093901395000859],
            [110.422349978897145, -7.093927872131216],
            [110.422099298388545, -7.09390042383279],
            [110.42207643395281, -7.093855167986263],
            [110.422072560598991, -7.093330525375999],
            [110.422086504218015, -7.093041087387279],
            [110.422059508016687, -7.092905371607283],
            [110.422012296699364, -7.092869130945454],
            [110.421734112024026, -7.0928868756653],
            [110.421127712977878, -7.092877074863562],
            [110.420963373695713, -7.0928225971621],
            [110.420585084617244, -7.092785943530304],
            [110.420562197766102, -7.092758778549526],
            [110.420648334468908, -7.092668431218992],
            [110.420931249965037, -7.092487874440064],
            [110.420954272422676, -7.092406493838588],
            [110.420931464664534, -7.092316010628993],
            [110.420612656367339, -7.092008066871699],
            [110.420398468914328, -7.091763571803649],
            [110.420453829088245, -7.091392776295322],
            [110.420472165418317, -7.091211889617655],
            [110.42036711486935, -7.091039894498132],
            [110.420158032592241, -7.090560223314614],
            [110.420181167982321, -7.09016225117788],
            [110.420305505030669, -7.089845814588172],
            [110.420690163014186, -7.089312611051462],
            [110.421137956292995, -7.08875234931504],
            [110.421320967768949, -7.088128439104291],
            [110.421321159620291, -7.087974666181342],
            [110.421266444092367, -7.087829870377124],
            [110.421372067159865, -7.086862135464695],
            [110.421395348661832, -7.086572709058617],
            [110.421299919599136, -7.086400726146965],
            [110.421249618881518, -7.086346390648333],
            [110.421017154135171, -7.086237555441858],
            [110.420387299621439, -7.085983497212072],
            [110.419856711135708, -7.085774789443995],
            [110.419524065013931, -7.085674874005085],
            [110.419396231333749, -7.085620441525401],
            [110.419305217494895, -7.085538918564892],
            [110.419227842761401, -7.085412185235727],
            [110.419027388369301, -7.085276252653096],
            [110.418679009948917, -7.08508586222258],
            [110.417963419320657, -7.084831693735633],
            [110.4178265312798, -7.084777124402745],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 87,
        id: 89,
        area: 3873718.4497099998,
        perimeter: 11230.270327,
        kode_pos: 50229,
        kode_kec: 3374020,
        kode_kel: 3374020009,
        kec: "Gunungpati",
        kel: "Pongangan",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.372605376387256, -7.0646104983274],
            [110.37184291519705, -7.064754198399452],
            [110.371655834484983, -7.064790127978996],
            [110.371468790401295, -7.064798921306052],
            [110.37136664440537, -7.064798783611113],
            [110.371256070929974, -7.064753407491893],
            [110.371162486870475, -7.064699008852053],
            [110.371060684978602, -7.064653644518859],
            [110.370864955022213, -7.064599108042404],
            [110.370499207253374, -7.064517205784621],
            [110.370082466952184, -7.064480461401389],
            [110.369708145136499, -7.064461864797433],
            [110.36951257587674, -7.064497782039807],
            [110.369427370373472, -7.064524803044075],
            [110.369384878452394, -7.064560927201526],
            [110.369359326911706, -7.064624210478646],
            [110.369359216592414, -7.064705618970462],
            [110.369359032724006, -7.064841299789522],
            [110.369401218241237, -7.065031310336184],
            [110.369401144695786, -7.065085582663588],
            [110.369392619333496, -7.065112707347155],
            [110.369350127352277, -7.065148831495539],
            [110.369179740602561, -7.065184782629004],
            [110.36897560882521, -7.065274960452739],
            [110.368830909395015, -7.065356173232324],
            [110.368660707316891, -7.065464487640255],
            [110.36859238059283, -7.065563894549078],
            [110.368521470431901, -7.065690434154173],
            [110.368453168196993, -7.065771750264126],
            [110.368178321807974, -7.065635696854399],
            [110.367617114736817, -7.065508300393013],
            [110.367392536045372, -7.06544467793527],
            [110.366977394755096, -7.065272251892398],
            [110.366685571986494, -7.065136174615701],
            [110.36652831177561, -7.065090733995227],
            [110.36619165954626, -7.065045049515595],
            [110.366000666436335, -7.065044789864178],
            [110.365899553700146, -7.065117015559808],
            [110.365809746718966, -7.065198302006195],
            [110.36562972639544, -7.065451328264372],
            [110.3655622479247, -7.065550735826682],
            [110.365505866266091, -7.065604931489467],
            [110.365472145511902, -7.065641067189587],
            [110.36543846175941, -7.065650066744603],
            [110.365359825476077, -7.065631868918815],
            [110.365270363788966, -7.065459884589658],
            [110.365185576622267, -7.065387405981331],
            [110.365110348172422, -7.065360167360704],
            [110.365017847263886, -7.065341950603449],
            [110.364936627444052, -7.065350885383981],
            [110.364780388066237, -7.065386854141789],
            [110.364687579518872, -7.065386727693232],
            [110.364542732348596, -7.065368439514708],
            [110.364409769213282, -7.065350167492099],
            [110.364305359613368, -7.06535002515392],
            [110.364189631902192, -7.065349867358814],
            [110.364044735327852, -7.06536776054185],
            [110.363900208254364, -7.065322336418005],
            [110.363715034961714, -7.065204493628658],
            [110.363454670604199, -7.065032275766069],
            [110.363379528957552, -7.064941719238464],
            [110.363310071140475, -7.064833079673471],
            [110.363292959549355, -7.064724511578645],
            [110.363298767103529, -7.064615974777745],
            [110.363310504275532, -7.064516491466401],
            [110.36332810919005, -7.064471288531323],
            [110.363380221899234, -7.06443517810057],
            [110.363403473474648, -7.064399028264815],
            [110.363403522966465, -7.064362846754324],
            [110.363328567038437, -7.064136609563076],
            [110.363305562959127, -7.063991851844841],
            [110.363282497018204, -7.063892321013899],
            [110.36320628564296, -7.063756536040671],
            [110.363136778513251, -7.063684077973561],
            [110.362986371974017, -7.0635934186178],
            [110.36274120146679, -7.063484538956559],
            [110.362555954872406, -7.063420968048503],
            [110.362441987339309, -7.063375585301444],
            [110.362327736879038, -7.063330202141578],
            [110.362241215687433, -7.063284856873837],
            [110.362174168583707, -7.063275719794983],
            [110.362087597833195, -7.063266556003838],
            [110.361969324127188, -7.063266394221067],
            [110.361890663625658, -7.063266286607309],
            [110.361843152586189, -7.063248130817506],
            [110.361784335896601, -7.063220914160659],
            [110.361729209985839, -7.063184657155703],
            [110.361634815870858, -7.063103119440814],
            [110.361567818438019, -7.063057800778596],
            [110.361528550242866, -7.063012520066958],
            [110.361489023919304, -7.062949148215683],
            [110.36141842261101, -7.06284955225126],
            [110.361328014755159, -7.062749929158279],
            [110.36122032210011, -7.062668373162699],
            [110.361133838393854, -7.062595891586074],
            [110.361055227680069, -7.06255960234492],
            [110.360984231821504, -7.062541414305832],
            [110.36092113375976, -7.062541327859178],
            [110.360834501091901, -7.062577390718555],
            [110.360751829725587, -7.0626134589895],
            [110.360692680548155, -7.062622423313465],
            [110.360661027307316, -7.062595243756707],
            [110.360637604494698, -7.062549984698832],
            [110.360602359868295, -7.062459482482976],
            [110.36054734597127, -7.062341816993426],
            [110.360484372201853, -7.062251276757471],
            [110.360401616762616, -7.062142618611277],
            [110.360315158145511, -7.062052046155351],
            [110.360283787915449, -7.062024866965816],
            [110.360224688532284, -7.061997649744917],
            [110.360126246596678, -7.061979423942401],
            [110.360006878764906, -7.061952124021269],
            [110.359955689588872, -7.061933963010302],
            [110.35992038295467, -7.061888687618814],
            [110.359872946768249, -7.061816259412656],
            [110.359829729976568, -7.061761927771784],
            [110.359763040752398, -7.061698518524747],
            [110.359688121106345, -7.06165318875117],
            [110.359530604872901, -7.061589654798729],
            [110.359333808390389, -7.061489885299784],
            [110.359204033835709, -7.061417343954062],
            [110.359116664488937, -7.061371996996381],
            [110.35901754233852, -7.061317427930499],
            [110.358946219916874, -7.061245127384576],
            [110.358874450247242, -7.061172665657428],
            [110.358831258545393, -7.061100243196868],
            [110.358816927747156, -7.061027860398499],
            [110.358795573031273, -7.060919286394893],
            [110.358759912452172, -7.060720238849684],
            [110.358781603473133, -7.060584587841204],
            [110.358839437293383, -7.060503258826485],
            [110.358868065019067, -7.060467116612396],
            [110.358918712992931, -7.060467186205572],
            [110.359026491880428, -7.060485425058518],
            [110.359141615019524, -7.060512719363587],
            [110.35917754962091, -7.060512768719565],
            [110.359244105015932, -7.060467633181462],
            [110.359294582055952, -7.060386293998407],
            [110.359345108855734, -7.060268773319947],
            [110.359345220855658, -7.060187364971575],
            [110.359316705187737, -7.060142098872186],
            [110.359259045739918, -7.06009679275207],
            [110.359201706593538, -7.060024350898748],
            [110.359165626002593, -7.059924802069401],
            [110.359165675791985, -7.059888620582014],
            [110.359180193332037, -7.059825322796735],
            [110.359216215003826, -7.059762054541103],
            [110.359295540326485, -7.059689800352412],
            [110.359468546654654, -7.05959958397042],
            [110.35960533544376, -7.059509317816315],
            [110.359634245995167, -7.059473175931129],
            [110.359634333057116, -7.059409858321113],
            [110.359634395243745, -7.059364631456712],
            [110.359605584202569, -7.05932841035998],
            [110.35954820776594, -7.059283104668865],
            [110.359396954527881, -7.059192443164306],
            [110.359339590572006, -7.059138092078164],
            [110.359325209955045, -7.059101890777488],
            [110.359325272168931, -7.059056663915237],
            [110.35934691313787, -7.058957194339229],
            [110.359383267424192, -7.058857744958177],
            [110.359491282314536, -7.05870412158885],
            [110.359617862885159, -7.058423888198575],
            [110.35964721796654, -7.058270156839945],
            [110.359676893274951, -7.058089289744549],
            [110.359701599822017, -7.057817961921081],
            [110.35972142161836, -7.057600899734873],
            [110.359721645399887, -7.057438083007241],
            [110.359692442617288, -7.057275225913925],
            [110.359648747358449, -7.0571575758931],
            [110.359610440556096, -7.05703088787103],
            [110.359595801887679, -7.056976595443773],
            [110.35956643752003, -7.056931328204479],
            [110.35949830935941, -7.056886007778425],
            [110.359444857167972, -7.056867843653637],
            [110.359356885397887, -7.056849632150592],
            [110.35931306586771, -7.056822435840651],
            [110.359293592255625, -7.056786227552053],
            [110.359279261456436, -7.056713844760108],
            [110.359269495135564, -7.056614332061645],
            [110.359279572437813, -7.056487710426929],
            [110.359294127130852, -7.056397276497733],
            [110.359323665573569, -7.056315908520888],
            [110.35938714521194, -7.056243632506771],
            [110.359494494049301, -7.05616237127952],
            [110.359548033197399, -7.056117217774195],
            [110.359601942306213, -7.056008747031009],
            [110.359606814562639, -7.055963526758211],
            [110.359626387622711, -7.05592737204058],
            [110.359685019773337, -7.055882225501162],
            [110.359728888961996, -7.055873240273661],
            [110.359767935624774, -7.055873293820273],
            [110.359831303288487, -7.055882426104146],
            [110.359948418432381, -7.055900677458278],
            [110.360016621049667, -7.055891725562099],
            [110.360070135273958, -7.055864662739054],
            [110.36016301636424, -7.055810517684787],
            [110.360202125124573, -7.055765344318218],
            [110.360225709062988, -7.055693013497134],
            [110.360230593672512, -7.055638747836083],
            [110.360218513898516, -7.055575413536575],
            [110.360191487633614, -7.055475877185895],
            [110.360176836508629, -7.05543063014695],
            [110.360157067507942, -7.055403466879045],
            [110.360127665906106, -7.055385335801976],
            [110.360026703676667, -7.055349015859343],
            [110.359933110497963, -7.055303660606095],
            [110.359859028219176, -7.055267377477279],
            [110.359799966967657, -7.05521302414985],
            [110.359763246068184, -7.055167746841039],
            [110.359738691869836, -7.055122486213356],
            [110.359706547837504, -7.055041033610038],
            [110.359674637046808, -7.054995762891878],
            [110.359632810713379, -7.054959523965358],
            [110.359585891354087, -7.054923278050472],
            [110.359561337184545, -7.054878017415705],
            [110.359544164482287, -7.05481467612131],
            [110.359529563161416, -7.054733247570371],
            [110.359522306011328, -7.054660874484039],
            [110.359529737194862, -7.054606612327119],
            [110.359554502664565, -7.054498101594327],
            [110.35956671906159, -7.054461936782171],
            [110.359561971129821, -7.054416703311943],
            [110.359547320091735, -7.054371456259716],
            [110.35954482329899, -7.054335271268513],
            [110.359551983955257, -7.054271963347016],
            [110.359567374896159, -7.054190575928696],
            [110.359557313142275, -7.054100108211572],
            [110.359516177085098, -7.053973416309898],
            [110.359485447882221, -7.053891965638289],
            [110.359433755854766, -7.05382857699601],
            [110.359366784782424, -7.053765167390076],
            [110.359320443882453, -7.053719876861137],
            [110.359258873673781, -7.053638383868317],
            [110.359181495851132, -7.053529733005105],
            [110.359104363697682, -7.053448218642817],
            [110.359021843172457, -7.053375742265975],
            [110.358980865864837, -7.053339504461214],
            [110.358955217364482, -7.053267106128881],
            [110.358949891164301, -7.053230917253922],
            [110.358950015559969, -7.053140463513309],
            [110.358970819822233, -7.053031947372988],
            [110.358971289348688, -7.052896267148551],
            [110.358925197355333, -7.05267006910633],
            [110.358920353129477, -7.052489154631287],
            [110.358946200606781, -7.052416826974397],
            [110.359008289863908, -7.052326458266649],
            [110.359101232491014, -7.052227086489554],
            [110.359116831746846, -7.052199971716727],
            [110.359116881485036, -7.052163790217742],
            [110.359096462793616, -7.05199189975855],
            [110.359055351869941, -7.051847117083502],
            [110.359029790466963, -7.051711401133715],
            [110.359014390195014, -7.051593789909012],
            [110.359009458860697, -7.051476193048394],
            [110.359019756764951, -7.051394798650665],
            [110.359051300334698, -7.051295342617025],
            [110.359158772325173, -7.05112362760028],
            [110.359235428217033, -7.050933779501976],
            [110.359296805081172, -7.050743910431541],
            [110.359358551820932, -7.050490724104255],
            [110.359397146826225, -7.050201324446883],
            [110.359397320782691, -7.050074689182413],
            [110.359374563909967, -7.049957067872266],
            [110.359313500748428, -7.049712758537183],
            [110.359298520057081, -7.049495648562349],
            [110.359313972937159, -7.04936903424478],
            [110.359367893600123, -7.049251518055751],
            [110.359411236336442, -7.049007351848527],
            [110.359418987594509, -7.048925953931628],
            [110.359441181340841, -7.048835530416527],
            [110.359467016064514, -7.048772248070726],
            [110.359503860695128, -7.048727071599597],
            [110.359596261391673, -7.048609608105093],
            [110.359699376599849, -7.048519295449766],
            [110.359780754870002, -7.04839277141412],
            [110.359806614347917, -7.048311398287065],
            [110.359817810476898, -7.048193823494556],
            [110.359830791143537, -7.048012933382659],
            [110.359860256901328, -7.047777793477515],
            [110.359932717359769, -7.04755175783811],
            [110.360070847442756, -7.047099677203894],
            [110.360165426714673, -7.046837490199895],
            [110.360209086512683, -7.046774232186465],
            [110.360281310967579, -7.046720058654238],
            [110.360491193645018, -7.046557528691389],
            [110.360686695756939, -7.046358797367565],
            [110.360751945705857, -7.046232251038186],
            [110.360759438485005, -7.046132761917355],
            [110.360752168763696, -7.046069434198587],
            [110.360701658657248, -7.045969865765853],
            [110.36062228860817, -7.045870257861894],
            [110.360441749779852, -7.045680057599386],
            [110.360355380710828, -7.045526167705326],
            [110.360319337905551, -7.0453994828443],
            [110.360298107213566, -7.045200455075149],
            [110.360306575087193, -7.044802469210202],
            [110.360320614931908, -7.044467808737144],
            [110.360299471028199, -7.044205463304566],
            [110.360321321561401, -7.0439522220793],
            [110.360335949820751, -7.043807515735256],
            [110.360379720831219, -7.043662849247774],
            [110.360473966554352, -7.043436843199886],
            [110.360532512274858, -7.043246969903485],
            [110.360554670310435, -7.042975638266724],
            [110.360540500617063, -7.042785665540239],
            [110.360490127150271, -7.042586597917315],
            [110.360403624311942, -7.042324163105513],
            [110.36040377301542, -7.042215618535222],
            [110.360411253334931, -7.042125174784833],
            [110.360432942528405, -7.041989523471289],
            [110.360498241382558, -7.041826795580262],
            [110.360592424663707, -7.041646016359511],
            [110.360701308266059, -7.041474302599796],
            [110.360802748717603, -7.041365896428617],
            [110.360932716343115, -7.041293710797109],
            [110.361134279591226, -7.04121260259151],
            [110.361240369008783, -7.041221767765347],
            [110.361387767352184, -7.041231014395364],
            [110.361653113778033, -7.0412675581345],
            [110.362100905916549, -7.041340532139844],
            [110.362471530202257, -7.04135912816246],
            [110.362888211760989, -7.041423013632619],
            [110.363104560814079, -7.041495671447326],
            [110.363536767250011, -7.041586713704191],
            [110.363737370562276, -7.041586986588106],
            [110.363907428858468, -7.041578172449698],
            [110.364185360587854, -7.041515232449085],
            [110.364772383996751, -7.041362258026769],
            [110.365606744273876, -7.041173436666869],
            [110.36591577425655, -7.04112862857953],
            [110.366440600152714, -7.041147430429406],
            [110.366873175991017, -7.041175152268774],
            [110.367320932538235, -7.041275257523001],
            [110.367675860994098, -7.041393327725062],
            [110.368046216603517, -7.041610918035992],
            [110.368310692278584, -7.041873592174268],
            [110.368526466042994, -7.042163336677983],
            [110.368788825540548, -7.042317462788835],
            [110.36929826448025, -7.042417649511178],
            [110.369668902627495, -7.042427194535014],
            [110.369827041235609, -7.042445498440167],
            [110.370037943034106, -7.042572418327116],
            [110.370281985468651, -7.042672246503806],
            [110.370670634479993, -7.04275417832228],
            [110.371003876254747, -7.042799853658235],
            [110.371181428251006, -7.042899591971107],
            [110.371458687520231, -7.043126100054947],
            [110.371691476799214, -7.043388729922183],
            [110.371846996376661, -7.043461302161628],
            [110.372013526328359, -7.04355197997213],
            [110.372202174338938, -7.043606505750573],
            [110.372313357626012, -7.043615700405453],
            [110.372413271962344, -7.04358869824115],
            [110.37260847298856, -7.043399006365186],
            [110.37267278545562, -7.043335774706263],
            [110.372712469978666, -7.043281555424138],
            [110.37272726793816, -7.043218257342358],
            [110.372752276035328, -7.043136882115714],
            [110.372767061829364, -7.043082629433667],
            [110.372791726204341, -7.043046480835392],
            [110.372841240663902, -7.043046547232522],
            [110.372900658016007, -7.043046626902158],
            [110.373023983312251, -7.043073928495555],
            [110.373117882884486, -7.043101190618391],
            [110.373241527586615, -7.04310135632677],
            [110.373300674153981, -7.043092390163864],
            [110.373592247833372, -7.042984235723876],
            [110.373706062436128, -7.042930115620083],
            [110.373834662676373, -7.042821742758044],
            [110.373879156998385, -7.042767529799211],
            [110.373908902055973, -7.042740433354355],
            [110.373943384301057, -7.042767615772052],
            [110.373958319451845, -7.04281286286597],
            [110.373958258800158, -7.042858089888116],
            [110.373997538902358, -7.042894324145276],
            [110.374047053357245, -7.042894390413331],
            [110.374101402043593, -7.042876372303509],
            [110.374215240821499, -7.04280416125826],
            [110.374299370901412, -7.042731910440595],
            [110.374319225170098, -7.042695755314647],
            [110.374330049613405, -7.042641497266095],
            [110.374305567222791, -7.042541964881741],
            [110.374310732872303, -7.042487699263443],
            [110.37432548209469, -7.04246058272847],
            [110.37436003707893, -7.042433492684465],
            [110.374414397821951, -7.042406429126735],
            [110.374547993725017, -7.042370426095324],
            [110.374597568715089, -7.042325265271574],
            [110.374612342149078, -7.042280057913897],
            [110.374612378499108, -7.042252921696544],
            [110.374587859709138, -7.042180525539157],
            [110.374563009517047, -7.042144310625638],
            [110.374543252235981, -7.042108102520825],
            [110.374523814251148, -7.042044758576588],
            [110.374523911194672, -7.041972395330746],
            [110.374514105266343, -7.041900018843449],
            [110.374499194317778, -7.041836680951849],
            [110.374509206247936, -7.041755285541202],
            [110.374529108922147, -7.041682948776321],
            [110.374578671718751, -7.041646833352947],
            [110.374652801741576, -7.041646932454178],
            [110.374884739081409, -7.041701514981342],
            [110.37497867485223, -7.041701640497526],
            [110.375052865425047, -7.041656512502269],
            [110.375128236162325, -7.04157520437789],
            [110.375202463013437, -7.041502940134335],
            [110.375245506045175, -7.041475861349554],
            [110.375271831470016, -7.041466851082462],
            [110.375330989912541, -7.041448839233136],
            [110.375385597127718, -7.041448912146473],
            [110.375439933503742, -7.04143993926816],
            [110.375518897663298, -7.041421953835454],
            [110.375607764680254, -7.041403981606233],
            [110.375677084717353, -7.041404074121927],
            [110.37573620686041, -7.041413198443423],
            [110.375840021268715, -7.041431427812471],
            [110.375894357638572, -7.041422454880419],
            [110.375938803233197, -7.041404423323288],
            [110.376018086577446, -7.041359301955815],
            [110.376126771381166, -7.041332310632945],
            [110.376270221410394, -7.041332501911348],
            [110.376437955872376, -7.041368907216789],
            [110.376581417998338, -7.041360052991223],
            [110.376625887718106, -7.041323930546646],
            [110.376645765896967, -7.041269684479274],
            [110.376650672461437, -7.041197327610569],
            [110.376640854129747, -7.041133996547615],
            [110.376601598091042, -7.041079671685342],
            [110.376556942071289, -7.041043430475941],
            [110.376463349736511, -7.040998078618056],
            [110.376438499445015, -7.040961863795087],
            [110.376458353468678, -7.040925708554407],
            [110.376557195682281, -7.040853476873074],
            [110.376586976695421, -7.040799244001991],
            [110.376602093196922, -7.040708809885236],
            [110.376606987679338, -7.040645498425212],
            [110.376631687853433, -7.040582213353381],
            [110.376661456764282, -7.040537025884651],
            [110.376817241909833, -7.040410597438611],
            [110.37691583722625, -7.040311229057057],
            [110.376947327857366, -7.040247953005184],
            [110.376947400264726, -7.040193680541491],
            [110.376938466919711, -7.040103214378755],
            [110.376902678588849, -7.039994621601346],
            [110.376889477044728, -7.039922240607662],
            [110.376889778752243, -7.039696105340798],
            [110.376894438506781, -7.039596611850889],
            [110.376912348066554, -7.039533317710878],
            [110.376970717729876, -7.039470077440693],
            [110.3770466053913, -7.039424951337085],
            [110.377149643003207, -7.039388906785066],
            [110.377230304395241, -7.039370923285209],
            [110.377248165657207, -7.0393438107729],
            [110.37725698496935, -7.0393076407994],
            [110.377257021150228, -7.039280504564875],
            [110.37723926843681, -7.039226208374463],
            [110.377217283722956, -7.039162861123991],
            [110.377123529360603, -7.039027054931848],
            [110.377078994109596, -7.038900359669456],
            [110.377079066487667, -7.038846087201279],
            [110.377101563295881, -7.038737572015745],
            [110.377119767784322, -7.038665232825206],
            [110.377146755383848, -7.038583859894959],
            [110.377164676920103, -7.038511520324508],
            [110.377160198167118, -7.038475332653241],
            [110.37714244548765, -7.03842103646098],
            [110.377106596894734, -7.038357670756679],
            [110.377039673478848, -7.038258081978749],
            [110.37700840015674, -7.038158540648338],
            [110.377026532252543, -7.038140473928324],
            [110.377062170255712, -7.038149566790721],
            [110.377151505996267, -7.038203958253026],
            [110.377209766914348, -7.038222126638701],
            [110.377258997967203, -7.038222192146741],
            [110.377303714084647, -7.038213206214565],
            [110.377335156271712, -7.038186111762225],
            [110.377362155855977, -7.038095693400026],
            [110.377362324640984, -7.037969057629557],
            [110.377358086990569, -7.037751961713618],
            [110.377335891710786, -7.037634341619413],
            [110.377313636158888, -7.037561948585963],
            [110.37726029375338, -7.037462377909109],
            [110.37721123159119, -7.037335676638006],
            [110.377197747100851, -7.03726329527082],
            [110.377202322316137, -7.037227119645249],
            [110.377229237453605, -7.037200019170387],
            [110.377273965528104, -7.037181987819768],
            [110.377336494485633, -7.037182071000815],
            [110.377430453498107, -7.037164105119633],
            [110.377721360372746, -7.037128310221549],
            [110.377810768291496, -7.037128429070237],
            [110.377877788097948, -7.037155654435776],
            [110.377958400907687, -7.037173852426559],
            [110.378039037807895, -7.037173959576652],
            [110.378138113603001, -7.037138012370053],
            [110.37810918197863, -7.037192143632316],
            [110.378058181160142, -7.037246348449792],
            [110.377996723719193, -7.037291493936102],
            [110.377940347081591, -7.037345691594847],
            [110.377897551388855, -7.037399907295567],
            [110.377846514385865, -7.037481248325053],
            [110.377833168178256, -7.037517412302184],
            [110.37783311999388, -7.037553593954907],
            [110.377843739082905, -7.037653107790934],
            [110.377864862975002, -7.037725499302192],
            [110.377883464532118, -7.037779796601905],
            [110.377912829739785, -7.037825062778035],
            [110.37796652752732, -7.037870361291235],
            [110.378033240424784, -7.037915677094539],
            [110.378105329138151, -7.03796100003232],
            [110.378195465634818, -7.038051574085559],
            [110.378270371717988, -7.038105946173538],
            [110.378305985675198, -7.038133129770665],
            [110.378341882506476, -7.038160364309864],
            [110.378378531994215, -7.038259862147581],
            [110.378385521166535, -7.038323189439256],
            [110.378388796045286, -7.038413648086935],
            [110.378360568580533, -7.038576428329333],
            [110.378339697465165, -7.038739218339138],
            [110.378332503642881, -7.038829663078634],
            [110.378339432617253, -7.038938217437278],
            [110.378332226749421, -7.039037707589198],
            [110.378325020876687, -7.039137197740318],
            [110.378307641279562, -7.039227628945432],
            [110.378259891001264, -7.039390383232099],
            [110.378141532569174, -7.039670634257714],
            [110.378010543998897, -7.040086549877063],
            [110.377979264169653, -7.040204098865157],
            [110.377896664025116, -7.040402988470657],
            [110.3778375356531, -7.040610954694642],
            [110.377740505490465, -7.040809825068255],
            [110.37771908016984, -7.040963568842938],
            [110.377692092493106, -7.041044941794669],
            [110.377696601078043, -7.041271083481414],
            [110.377714395739929, -7.041506288267209],
            [110.377744429127148, -7.041687236766777],
            [110.377762368470215, -7.041813896613459],
            [110.377792546539609, -7.041886300174014],
            [110.377828666344541, -7.041958711636027],
            [110.377840760416092, -7.042013000286596],
            [110.377827377885609, -7.042076300479488],
            [110.377768074765982, -7.04220285758101],
            [110.377729167375804, -7.042311350950608],
            [110.377696544923737, -7.042374625540965],
            [110.377663651580448, -7.042428854340654],
            [110.377610374512656, -7.042492101441843],
            [110.377527129940518, -7.04253721780218],
            [110.377405381422662, -7.042600373749602],
            [110.377319295351072, -7.042654531713473],
            [110.377200364108617, -7.042726736778848],
            [110.377117414468245, -7.042762808025477],
            [110.37692178232065, -7.04285300171478],
            [110.376765822072258, -7.042898021053113],
            [110.37654760293735, -7.04295200277681],
            [110.376454172355494, -7.042997105351466],
            [110.376402026824209, -7.04306035380224],
            [110.376360608830268, -7.043141707399215],
            [110.376355148378536, -7.043205018090162],
            [110.376360403394514, -7.043295479342773],
            [110.376417105186945, -7.043422190891765],
            [110.37654670894652, -7.043621363023656],
            [110.376697286642397, -7.043793426807567],
            [110.376764505688683, -7.043883970642597],
            [110.376821514746894, -7.043992591714512],
            [110.376852457062839, -7.044128314322411],
            [110.376857688017182, -7.044236866393109],
            [110.376836508861729, -7.044417746669153],
            [110.37678482037812, -7.044562404581612],
            [110.376698968482216, -7.044652744399716],
            [110.376578880691639, -7.04474303855563],
            [110.376418958598336, -7.044788052435043],
            [110.376259072746208, -7.044805930034008],
            [110.376160868030269, -7.044823889872742],
            [110.376007477713202, -7.044850821473007],
            [110.375807636659431, -7.044913872708167],
            [110.37557892346463, -7.044985930720197],
            [110.375516050022426, -7.045031073865948],
            [110.375441810500661, -7.045112383509454],
            [110.375355982470637, -7.045184632235198],
            [110.375240409254914, -7.04528397746401],
            [110.37515005410232, -7.045356220096773],
            [110.375058591383976, -7.045410370385976],
            [110.374898668955481, -7.045455383711074],
            [110.37473281685196, -7.045491343627893],
            [110.374652708084554, -7.04551837275231],
            [110.374544280923686, -7.045563454821754],
            [110.374495283564869, -7.045599570955646],
            [110.374393291264312, -7.045698934117739],
            [110.374251553679358, -7.045897743679796],
            [110.374124824012185, -7.046087527858871],
            [110.374054941269677, -7.046295478948948],
            [110.374050094855164, -7.046322608718443],
            [110.374049985642912, -7.046404017347489],
            [110.374089637763873, -7.046584978827919],
            [110.374169394888597, -7.046820266516193],
            [110.374214730187916, -7.046983144759554],
            [110.374231819811058, -7.047109803510528],
            [110.374214402602419, -7.047227370646667],
            [110.374088497067945, -7.04743524671418],
            [110.373960671510787, -7.047597893070354],
            [110.373893161214795, -7.04772443850656],
            [110.37383814892587, -7.047814818998353],
            [110.373783136614662, -7.047905199481445],
            [110.373719648140522, -7.047986523179965],
            [110.373647659212509, -7.048076880894231],
            [110.37356325720954, -7.048140085703377],
            [110.373436377295292, -7.048230369804378],
            [110.373280095568205, -7.04830252360583],
            [110.373119581807401, -7.048365626257004],
            [110.372959375269161, -7.048410638427576],
            [110.372853813197494, -7.048428587620692],
            [110.372739738501082, -7.048464616197155],
            [110.372646848022285, -7.048527809430047],
            [110.372579078620944, -7.048636263448678],
            [110.37251123617439, -7.048798989851933],
            [110.372444601494848, -7.049115489929668],
            [110.372345440363915, -7.049422900883162],
            [110.372187402424657, -7.049748323529674],
            [110.372075064383921, -7.049965262538655],
            [110.371983773723926, -7.050100821068387],
            [110.371962209071242, -7.050146019156245],
            [110.37195676004319, -7.050200284319023],
            [110.371978154157176, -7.050281721805694],
            [110.372111487366624, -7.050652763001136],
            [110.372213042881583, -7.050879034837171],
            [110.372255609192166, -7.050996682423442],
            [110.372266263666106, -7.051069060056661],
            [110.372260778119696, -7.051150461417856],
            [110.372184915233333, -7.051385540253265],
            [110.372006863127638, -7.051864707883258],
            [110.37190797173001, -7.052181164408464],
            [110.371895683216948, -7.05227160202104],
            [110.371901810520157, -7.052343973568779],
            [110.371883592332466, -7.052425357792917],
            [110.371822859187347, -7.052560957321123],
            [110.371738026868783, -7.052732706075649],
            [110.37169540215757, -7.052868329935742],
            [110.371683003844353, -7.053040176103765],
            [110.371700905109179, -7.053193972205072],
            [110.37177343933341, -7.053329750970812],
            [110.371918407783042, -7.053465627145569],
            [110.372105793867277, -7.053619651139277],
            [110.372261441368664, -7.053809814061608],
            [110.372405561271833, -7.053945688967141],
            [110.372443415188371, -7.053990966905935],
            [110.372450932950088, -7.054081431146781],
            [110.372458438534011, -7.054180940784854],
            [110.372404866997371, -7.054461276615514],
            [110.372214558951413, -7.054795701096853],
            [110.37207750560566, -7.055084970028069],
            [110.372008813011291, -7.055247695073843],
            [110.371993658341466, -7.055365265054731],
            [110.372024232245053, -7.05545576030242],
            [110.372039029997836, -7.055501007267823],
            [110.372092029067559, -7.055645805148556],
            [110.372099276000256, -7.05572722360829],
            [110.372015526126262, -7.055935155454335],
            [110.371920531421992, -7.056088799676233],
            [110.371832868903155, -7.05626054456202],
            [110.371817762915555, -7.05634193294337],
            [110.371840252397504, -7.056450508147314],
            [110.37189330026267, -7.056559124466736],
            [110.37209818150599, -7.056749353739088],
            [110.372606447050259, -7.057102808216783],
            [110.372856637108654, -7.057265961854129],
            [110.37306081629977, -7.057347644853066],
            [110.373240403387271, -7.057411203920716],
            [110.373397415477996, -7.057429505548263],
            [110.373504345693163, -7.057447739908017],
            [110.37356173559391, -7.057483998589516],
            [110.373599884999635, -7.057520231444614],
            [110.373623013809663, -7.057574534969481],
            [110.373622940834451, -7.057628807358346],
            [110.373603590858337, -7.057710190120789],
            [110.373488470374113, -7.057890943896708],
            [110.373339027795737, -7.05813496945047],
            [110.373246345267773, -7.058252435377128],
            [110.373082298651141, -7.058415032492462],
            [110.37302125760516, -7.058568722517305],
            [110.373011761991165, -7.058686300130169],
            [110.373034781191848, -7.058822012250541],
            [110.373086414821628, -7.058930626571187],
            [110.373170365584741, -7.058994057231025],
            [110.373306096131515, -7.059057557415576],
            [110.373455949859007, -7.059139167352765],
            [110.373657263980107, -7.059247982559695],
            [110.373802295530396, -7.059338631337402],
            [110.373914492806122, -7.059438281437343],
            [110.37399869025468, -7.05952884853858],
            [110.374064200885314, -7.059628435975783],
            [110.374101404389734, -7.059737030852155],
            [110.374110288592121, -7.059863678570906],
            [110.374097434266787, -7.059954115472699],
            [110.373975239576907, -7.060134859830662],
            [110.373641105954846, -7.06053240960666],
            [110.373454668986042, -7.060722112959184],
            [110.373384068232085, -7.060830563098795],
            [110.373351653608793, -7.060948109928522],
            [110.37331270968096, -7.06129178329787],
            [110.37327394831685, -7.061499775713825],
            [110.37326021126087, -7.061825392101803],
            [110.37325947487966, -7.061952026883469],
            [110.373262593082657, -7.062368120030235],
            [110.373262373879029, -7.062530937151804],
            [110.373281444099106, -7.062657598542713],
            [110.373329302343876, -7.062838571083859],
            [110.373412590490574, -7.062974364159958],
            [110.373540633792089, -7.063074035690886],
            [110.373848598141464, -7.063201085008712],
            [110.373976407315098, -7.063264574466648],
            [110.374070310923258, -7.063291836738244],
            [110.374194797698635, -7.063301049202538],
            [110.374322348319851, -7.063346447398041],
            [110.374406017061943, -7.06340987752896],
            [110.374489920155881, -7.063509489615678],
            [110.374536695751686, -7.063654278967892],
            [110.37456343036834, -7.063762859786005],
            [110.374570148297465, -7.063817141277418],
            [110.374566679937416, -7.06387140910808],
            [110.374539738671203, -7.06391660004453],
            [110.374472593822361, -7.06397982788194],
            [110.374361627780601, -7.064015860681186],
            [110.374210506779747, -7.064033748744595],
            [110.373938318568662, -7.064024338006305],
            [110.373733460916711, -7.064024062945898],
            [110.373518380454172, -7.064050910298077],
            [110.373374038116282, -7.064077852604393],
            [110.37326644305783, -7.064131980485763],
            [110.373165626655975, -7.064195162862816],
            [110.372971006656186, -7.064366764027318],
            [110.372849215094107, -7.064457054354962],
            [110.372714161280499, -7.06452019057539],
            [110.372648738216711, -7.064565329615299],
            [110.372605376387256, -7.0646104983274],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 88,
        id: 90,
        area: 4380153.6295480002,
        perimeter: 12564.477242000001,
        kode_pos: 50222,
        kode_kec: 3374020,
        kode_kel: 3374020016,
        kec: "Gunungpati",
        kel: "Kandri",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.361134279591226, -7.04121260259151],
            [110.360932716343115, -7.041293710797109],
            [110.360802748717603, -7.041365896428617],
            [110.360701308266059, -7.041474302599796],
            [110.360592424663707, -7.041646016359511],
            [110.360498241382558, -7.041826795580262],
            [110.360432942528405, -7.041989523471289],
            [110.360411253334931, -7.042125174784833],
            [110.36040377301542, -7.042215618535222],
            [110.360403624311942, -7.042324163105513],
            [110.360490127150271, -7.042586597917315],
            [110.360540500617063, -7.042785665540239],
            [110.360554670310435, -7.042975638266724],
            [110.360532512274858, -7.043246969903485],
            [110.360473966554352, -7.043436843199886],
            [110.360379720831219, -7.043662849247774],
            [110.360335949820751, -7.043807515735256],
            [110.360321321561401, -7.0439522220793],
            [110.360299471028199, -7.044205463304566],
            [110.360320614931908, -7.044467808737144],
            [110.360306575087193, -7.044802469210202],
            [110.360298107213566, -7.045200455075149],
            [110.360319337905551, -7.0453994828443],
            [110.360355380710828, -7.045526167705326],
            [110.360441749779852, -7.045680057599386],
            [110.36062228860817, -7.045870257861894],
            [110.360701658657248, -7.045969865765853],
            [110.360752168763696, -7.046069434198587],
            [110.360759438485005, -7.046132761917355],
            [110.360751945705857, -7.046232251038186],
            [110.360686695756939, -7.046358797367565],
            [110.360491193645018, -7.046557528691389],
            [110.360281310967579, -7.046720058654238],
            [110.360209086512683, -7.046774232186465],
            [110.360165426714673, -7.046837490199895],
            [110.360070847442756, -7.047099677203894],
            [110.359932717359769, -7.04755175783811],
            [110.359860256901328, -7.047777793477515],
            [110.359830791143537, -7.048012933382659],
            [110.359817810476898, -7.048193823494556],
            [110.359806614347917, -7.048311398287065],
            [110.359780754870002, -7.04839277141412],
            [110.359699376599849, -7.048519295449766],
            [110.359596261391673, -7.048609608105093],
            [110.359503860695128, -7.048727071599597],
            [110.359467016064514, -7.048772248070726],
            [110.359441181340841, -7.048835530416527],
            [110.359418987594509, -7.048925953931628],
            [110.359411236336442, -7.049007351848527],
            [110.359367893600123, -7.049251518055751],
            [110.359313972937159, -7.04936903424478],
            [110.359298520057081, -7.049495648562349],
            [110.359313500748428, -7.049712758537183],
            [110.359374563909967, -7.049957067872266],
            [110.359397320782691, -7.050074689182413],
            [110.359397146826225, -7.050201324446883],
            [110.359358551820932, -7.050490724104255],
            [110.359296805081172, -7.050743910431541],
            [110.359235428217033, -7.050933779501976],
            [110.359158772325173, -7.05112362760028],
            [110.359051300334698, -7.051295342617025],
            [110.359019756764951, -7.051394798650665],
            [110.359009458860697, -7.051476193048394],
            [110.359014390195014, -7.051593789909012],
            [110.359029790466963, -7.051711401133715],
            [110.359055351869941, -7.051847117083502],
            [110.359096462793616, -7.05199189975855],
            [110.359116881485036, -7.052163790217742],
            [110.359116831746846, -7.052199971716727],
            [110.359101232491014, -7.052227086489554],
            [110.359008289863908, -7.052326458266649],
            [110.358946200606781, -7.052416826974397],
            [110.358920353129477, -7.052489154631287],
            [110.358925197355333, -7.05267006910633],
            [110.358971289348688, -7.052896267148551],
            [110.358970819822233, -7.053031947372988],
            [110.358950015559969, -7.053140463513309],
            [110.358949891164301, -7.053230917253922],
            [110.358955217364482, -7.053267106128881],
            [110.358980865864837, -7.053339504461214],
            [110.359021843172457, -7.053375742265975],
            [110.359104363697682, -7.053448218642817],
            [110.359181495851132, -7.053529733005105],
            [110.359258873673781, -7.053638383868317],
            [110.359320443882453, -7.053719876861137],
            [110.359366784782424, -7.053765167390076],
            [110.359433755854766, -7.05382857699601],
            [110.359485447882221, -7.053891965638289],
            [110.359516177085098, -7.053973416309898],
            [110.359557313142275, -7.054100108211572],
            [110.359567374896159, -7.054190575928696],
            [110.359551983955257, -7.054271963347016],
            [110.35954482329899, -7.054335271268513],
            [110.359547320091735, -7.054371456259716],
            [110.359561971129821, -7.054416703311943],
            [110.35956671906159, -7.054461936782171],
            [110.359554502664565, -7.054498101594327],
            [110.359529737194862, -7.054606612327119],
            [110.359522306011328, -7.054660874484039],
            [110.359529563161416, -7.054733247570371],
            [110.359544164482287, -7.05481467612131],
            [110.359561337184545, -7.054878017415705],
            [110.359585891354087, -7.054923278050472],
            [110.359632810713379, -7.054959523965358],
            [110.359674637046808, -7.054995762891878],
            [110.359706547837504, -7.055041033610038],
            [110.359738691869836, -7.055122486213356],
            [110.359763246068184, -7.055167746841039],
            [110.359799966967657, -7.05521302414985],
            [110.359859028219176, -7.055267377477279],
            [110.359933110497963, -7.055303660606095],
            [110.360026703676667, -7.055349015859343],
            [110.360127665906106, -7.055385335801976],
            [110.360157067507942, -7.055403466879045],
            [110.360176836508629, -7.05543063014695],
            [110.360191487633614, -7.055475877185895],
            [110.360218513898516, -7.055575413536575],
            [110.360230593672512, -7.055638747836083],
            [110.360225709062988, -7.055693013497134],
            [110.360202125124573, -7.055765344318218],
            [110.36016301636424, -7.055810517684787],
            [110.360070135273958, -7.055864662739054],
            [110.360016621049667, -7.055891725562099],
            [110.359948418432381, -7.055900677458278],
            [110.359831303288487, -7.055882426104146],
            [110.359767935624774, -7.055873293820273],
            [110.359728888961996, -7.055873240273661],
            [110.359685019773337, -7.055882225501162],
            [110.359626387622711, -7.05592737204058],
            [110.359606814562639, -7.055963526758211],
            [110.359601942306213, -7.056008747031009],
            [110.359548033197399, -7.056117217774195],
            [110.359494494049301, -7.05616237127952],
            [110.35938714521194, -7.056243632506771],
            [110.359323665573569, -7.056315908520888],
            [110.359294127130852, -7.056397276497733],
            [110.359279572437813, -7.056487710426929],
            [110.359269495135564, -7.056614332061645],
            [110.359279261456436, -7.056713844760108],
            [110.359293592255625, -7.056786227552053],
            [110.35931306586771, -7.056822435840651],
            [110.359356885397887, -7.056849632150592],
            [110.359444857167972, -7.056867843653637],
            [110.35949830935941, -7.056886007778425],
            [110.35956643752003, -7.056931328204479],
            [110.359595801887679, -7.056976595443773],
            [110.359610440556096, -7.05703088787103],
            [110.359648747358449, -7.0571575758931],
            [110.359692442617288, -7.057275225913925],
            [110.359721645399887, -7.057438083007241],
            [110.35972142161836, -7.057600899734873],
            [110.359701599822017, -7.057817961921081],
            [110.359676893274951, -7.058089289744549],
            [110.35964721796654, -7.058270156839945],
            [110.359617862885159, -7.058423888198575],
            [110.359491282314536, -7.05870412158885],
            [110.359383267424192, -7.058857744958177],
            [110.35934691313787, -7.058957194339229],
            [110.359325272168931, -7.059056663915237],
            [110.359325209955045, -7.059101890777488],
            [110.359339590572006, -7.059138092078164],
            [110.359396954527881, -7.059192443164306],
            [110.35954820776594, -7.059283104668865],
            [110.359605584202569, -7.05932841035998],
            [110.359634395243745, -7.059364631456712],
            [110.359634333057116, -7.059409858321113],
            [110.359634245995167, -7.059473175931129],
            [110.35960533544376, -7.059509317816315],
            [110.359468546654654, -7.05959958397042],
            [110.359295540326485, -7.059689800352412],
            [110.359216215003826, -7.059762054541103],
            [110.359180193332037, -7.059825322796735],
            [110.359165675791985, -7.059888620582014],
            [110.359165626002593, -7.059924802069401],
            [110.359201706593538, -7.060024350898748],
            [110.359259045739918, -7.06009679275207],
            [110.359316705187737, -7.060142098872186],
            [110.359345220855658, -7.060187364971575],
            [110.359345108855734, -7.060268773319947],
            [110.359294582055952, -7.060386293998407],
            [110.359244105015932, -7.060467633181462],
            [110.35917754962091, -7.060512768719565],
            [110.359141615019524, -7.060512719363587],
            [110.359026491880428, -7.060485425058518],
            [110.358918712992931, -7.060467186205572],
            [110.358868065019067, -7.060467116612396],
            [110.358839437293383, -7.060503258826485],
            [110.358781603473133, -7.060584587841204],
            [110.358759912452172, -7.060720238849684],
            [110.358795573031273, -7.060919286394893],
            [110.358816927747156, -7.061027860398499],
            [110.358831258545393, -7.061100243196868],
            [110.358874450247242, -7.061172665657428],
            [110.358946219916874, -7.061245127384576],
            [110.35901754233852, -7.061317427930499],
            [110.359054132509058, -7.061371911088864],
            [110.35907391411493, -7.061390029042525],
            [110.359073851855854, -7.06143525589803],
            [110.359060649364423, -7.061570918581807],
            [110.359033790526652, -7.061760834828339],
            [110.358997128026843, -7.061878374495512],
            [110.35895382063643, -7.062095404291445],
            [110.35893046921899, -7.062203916852623],
            [110.358818488330982, -7.062565578438802],
            [110.35871141710598, -7.062854883631376],
            [110.358640122215846, -7.063053784113617],
            [110.358525877233674, -7.06341544246487],
            [110.358447358756621, -7.063722877597752],
            [110.358361446003158, -7.064057438688577],
            [110.358252206316322, -7.064482421502754],
            [110.358173158940545, -7.064762719659553],
            [110.358142733349354, -7.064871222403779],
            [110.358139508589161, -7.064952626432197],
            [110.358145300871428, -7.065061179028191],
            [110.358175439535813, -7.065160719751885],
            [110.358338474620865, -7.065531804942599],
            [110.35838049736185, -7.065631362012518],
            [110.358471075750444, -7.065812394362782],
            [110.358516543857689, -7.065875774622699],
            [110.358604159487584, -7.065948258249869],
            [110.358779478091407, -7.066029907880069],
            [110.35884619267506, -7.066075219590593],
            [110.358645787148006, -7.066129223230123],
            [110.358451756225548, -7.066074683955781],
            [110.358235863122715, -7.066074386821199],
            [110.357836986872869, -7.066010519893035],
            [110.357125352413391, -7.065810540730715],
            [110.355960969536639, -7.065628026123478],
            [110.355864004465218, -7.065564574505189],
            [110.355357204229506, -7.065382966467472],
            [110.354882918859076, -7.065219493659111],
            [110.354236296669754, -7.06486582880795],
            [110.35407479386258, -7.064820378226237],
            [110.35362201395553, -7.064656934011295],
            [110.352964402525387, -7.064475114464213],
            [110.352166561049401, -7.064211690869667],
            [110.352101802939032, -7.064184464778273],
            [110.351406403527847, -7.063912136903546],
            [110.350607897248054, -7.063721072857884],
            [110.34991801973915, -7.06354817292984],
            [110.349917284480526, -7.063466840305543],
            [110.349902735270675, -7.063349230195763],
            [110.349858860260042, -7.063159216235468],
            [110.349844361603758, -7.063005424708744],
            [110.34982992612224, -7.062806406411429],
            [110.349727285944695, -7.062552992924582],
            [110.349727412298392, -7.062462539385163],
            [110.34978667023978, -7.062173170096786],
            [110.349786733407655, -7.06212794332585],
            [110.349787213469824, -7.061784219863863],
            [110.349728574674614, -7.061630366805988],
            [110.349669809567132, -7.061566967285475],
            [110.349610736254192, -7.06152165807261],
            [110.349566659285259, -7.061476369762],
            [110.349537295705929, -7.061431101962977],
            [110.349552122817855, -7.061349714291049],
            [110.349596427218017, -7.061232186227039],
            [110.349640668420591, -7.061159884927765],
            [110.349626346721877, -7.060879458432368],
            [110.349597147420752, -7.060716601031967],
            [110.349494343459156, -7.06058077713088],
            [110.349376737736947, -7.060508250173705],
            [110.349288621951303, -7.060390537460724],
            [110.349274136127491, -7.060227700569293],
            [110.349274338366229, -7.060082974904745],
            [110.349303916711136, -7.059974471692618],
            [110.349318844901561, -7.059820721188717],
            [110.349245493129828, -7.059666847577218],
            [110.349260408681161, -7.059522142428297],
            [110.349275248387883, -7.059431709403119],
            [110.349328028441434, -7.059323238535807],
            [110.349360229063166, -7.059160466734341],
            [110.349373421587686, -7.059033849916311],
            [110.349378075116363, -7.058943402681275],
            [110.349371693667251, -7.058852940061359],
            [110.349353403131076, -7.058780551582695],
            [110.349317286877493, -7.058708138250581],
            [110.349273235455343, -7.058644759224586],
            [110.349239382806488, -7.058572349044259],
            [110.349229259872175, -7.058527108067924],
            [110.349222287279176, -7.058454735367668],
            [110.349218710060512, -7.058382367401769],
            [110.349213718103343, -7.058309997463033],
            [110.349208468474416, -7.058219536420302],
            [110.349199553164709, -7.0581200248937],
            [110.349176735513254, -7.058047630098398],
            [110.349139757853663, -7.057984260929014],
            [110.349082433264329, -7.05790277263783],
            [110.349028491422885, -7.05783033443115],
            [110.348951347913243, -7.057757863857437],
            [110.348885497034644, -7.057703499769765],
            [110.348819646171677, -7.057649135674085],
            [110.348732058968437, -7.057558559755802],
            [110.34866283807186, -7.057486100195818],
            [110.348593063950631, -7.057404594483724],
            [110.348536022472203, -7.057323106533578],
            [110.348499044952902, -7.057259737324736],
            [110.348423894900023, -7.057178224089002],
            [110.348345619804562, -7.057105751851876],
            [110.348280900877299, -7.057051389272304],
            [110.348196105392972, -7.056987953281288],
            [110.34812286012604, -7.056960714889778],
            [110.348026117624244, -7.056942489041048],
            [110.347935059388107, -7.056906180371976],
            [110.347853634019231, -7.056860839773365],
            [110.347815512179565, -7.056806514292585],
            [110.347780270466387, -7.056716011351967],
            [110.347760590829409, -7.056625530152769],
            [110.347758736831864, -7.056535073858703],
            [110.347771916985351, -7.056417502457675],
            [110.347777947496738, -7.056354193289572],
            [110.347782613921026, -7.056254700733242],
            [110.347802829728451, -7.056164275269506],
            [110.347828679130927, -7.056091948414925],
            [110.347858515091872, -7.05600153638507],
            [110.347873637837935, -7.055911103800829],
            [110.347861855374802, -7.055838724376452],
            [110.347818936183188, -7.055775346829362],
            [110.34774970301909, -7.055711932518805],
            [110.347681601658039, -7.055648519780817],
            [110.347638682507608, -7.055585142220504],
            [110.347616160803355, -7.055503702417244],
            [110.347605611478443, -7.055358961748274],
            [110.347596193938926, -7.055214222660165],
            [110.347599610261284, -7.054997138537926],
            [110.347615670480934, -7.054843389674241],
            [110.347638610007863, -7.054626332822652],
            [110.347638698639102, -7.054563015350053],
            [110.347629745680976, -7.05449063987495],
            [110.347611763799591, -7.054400161043924],
            [110.347566450201029, -7.054228235688806],
            [110.347552699851548, -7.054146808139353],
            [110.347543746908954, -7.054074432663284],
            [110.347526010010057, -7.054011090285443],
            [110.34750063360093, -7.053947737233246],
            [110.347452068377237, -7.053875306408036],
            [110.347416505968198, -7.053811939119585],
            [110.347375605611646, -7.053721428257346],
            [110.34730167672933, -7.053576599009692],
            [110.347220095716793, -7.053440804428817],
            [110.3471969575956, -7.05339554523482],
            [110.347186847598081, -7.053341258879867],
            [110.347186923606401, -7.053286986762433],
            [110.347197481229216, -7.05322368392471],
            [110.347217941905228, -7.053160394927562],
            [110.34726162935192, -7.053079047648526],
            [110.347320363494433, -7.052961539906538],
            [110.347361504403395, -7.052880189055657],
            [110.347423105922076, -7.052735549188005],
            [110.347456273706428, -7.052690368670365],
            [110.347505003414369, -7.052645209889175],
            [110.347574105092548, -7.052600079557823],
            [110.347866331238137, -7.052437670947777],
            [110.347986400736616, -7.05236547558671],
            [110.348045328995681, -7.052311285603901],
            [110.348091524761884, -7.052257077842767],
            [110.348127264238713, -7.052193810111318],
            [110.348150592114834, -7.052103388937991],
            [110.348181289166632, -7.05200393267233],
            [110.348222429832433, -7.051922581720753],
            [110.348289035419612, -7.051841266291157],
            [110.348368386091778, -7.051750923255068],
            [110.348442618465853, -7.051678663810537],
            [110.348553080237963, -7.051597409501692],
            [110.348645408311427, -7.051534220627411],
            [110.348737994035034, -7.051489122839699],
            [110.348794029492524, -7.051480155580424],
            [110.348832522448447, -7.051471163862422],
            [110.348868713958552, -7.051489305056152],
            [110.348968812829142, -7.051534671435923],
            [110.349049967126518, -7.051570966023718],
            [110.349094659590932, -7.051580073672175],
            [110.349128895770164, -7.051580121373541],
            [110.349156649499434, -7.051562069291587],
            [110.349167156292737, -7.051534947805292],
            [110.349167219424572, -7.051489721019383],
            [110.349150394328575, -7.051381153081651],
            [110.3491398066204, -7.051263548458569],
            [110.349135827622291, -7.051073590043264],
            [110.349144379051907, -7.051028375082097],
            [110.349161663844214, -7.051010308411772],
            [110.34919366168559, -7.050992262236389],
            [110.349244899666971, -7.050974242858573],
            [110.349385560337481, -7.050947302637641],
            [110.349417841110323, -7.050929256839686],
            [110.349437106491635, -7.050911192915809],
            [110.349439124954287, -7.050884059600018],
            [110.349428706535434, -7.050847863590688],
            [110.349398752417898, -7.050820685752827],
            [110.349285984315557, -7.050730074952332],
            [110.349258293758965, -7.050702900259354],
            [110.349247592414187, -7.050666703853278],
            [110.349249893828485, -7.050639570933027],
            [110.349260425836675, -7.050594358725768],
            [110.349281992619979, -7.050549161886695],
            [110.34934381302962, -7.050449748852153],
            [110.349354615345135, -7.050413582393147],
            [110.349350434305038, -7.050368349693187],
            [110.349331244671646, -7.050332141468113],
            [110.349295065847656, -7.050304954958033],
            [110.349256598240416, -7.050295856008616],
            [110.349205398206635, -7.050286739321949],
            [110.34907978420847, -7.05027751896825],
            [110.349017819727379, -7.050277432640232],
            [110.348983608899914, -7.050259294224831],
            [110.348960432844819, -7.050241171182776],
            [110.34895396304475, -7.050214026043254],
            [110.348962514468269, -7.050168811083938],
            [110.348979528916871, -7.050141698665813],
            [110.34900732044116, -7.050096510512009],
            [110.349112185996759, -7.049970021349693],
            [110.349137713956139, -7.04992483003261],
            [110.349150805059594, -7.049870576015222],
            [110.349148617261093, -7.049816300715599],
            [110.349133697048742, -7.049762007681033],
            [110.349093488756793, -7.049581044166104],
            [110.349087056827514, -7.049526762954399],
            [110.349095916410107, -7.049463457667428],
            [110.349112993940011, -7.049391118451091],
            [110.349186013039471, -7.049174131136247],
            [110.349209610831196, -7.049092755618002],
            [110.349215936525837, -7.049020401420846],
            [110.349209787525922, -7.048966120602959],
            [110.349192616409425, -7.048902779059768],
            [110.349158764342292, -7.048830368909627],
            [110.349101183031692, -7.048730789581629],
            [110.349065054831357, -7.048667421632149],
            [110.349028901392714, -7.048622144396711],
            [110.348966987632153, -7.048585876650333],
            [110.348926577456993, -7.048549638853977],
            [110.348898899667262, -7.048513418793123],
            [110.348830591918201, -7.048395733744643],
            [110.348800701122173, -7.048323329094533],
            [110.348781574742972, -7.048241894066673],
            [110.348760556175392, -7.048097138774346],
            [110.34876261253379, -7.048042869387374],
            [110.348775728862108, -7.047970524659643],
            [110.348801281979078, -7.04790724263424],
            [110.348859115653511, -7.047825914827193],
            [110.348875589377727, -7.047780710898348],
            [110.348877607842539, -7.047753577583133],
            [110.348867202141335, -7.047708336208774],
            [110.348826867800341, -7.047617826261763],
            [110.348788463555621, -7.047563500503855],
            [110.348730819322114, -7.04750914793622],
            [110.348671194514338, -7.047454792602742],
            [110.348571147039706, -7.047373244803559],
            [110.34838375927194, -7.047228257613824],
            [110.348285654577651, -7.047173848594574],
            [110.348213284890036, -7.047128520816069],
            [110.348157853754103, -7.047110352772269],
            [110.348087439397261, -7.047083118451959],
            [110.347951099838056, -7.047055792166648],
            [110.347879836681713, -7.047028556632838],
            [110.347832906441582, -7.047001355037649],
            [110.347775532689298, -7.046956048118675],
            [110.347679767538594, -7.046847370003872],
            [110.347647883773377, -7.046784007893274],
            [110.347630712927327, -7.04672066631368],
            [110.347609656803769, -7.046603047068158],
            [110.347598980950849, -7.046548759925888],
            [110.347575560131062, -7.046503500370601],
            [110.347537426412927, -7.04645822028021],
            [110.347484026570044, -7.046403873505545],
            [110.347418215316381, -7.046322373279652],
            [110.34734135676166, -7.046249902997347],
            [110.347271276143431, -7.046186487541188],
            [110.347211639023186, -7.04614117740536],
            [110.347177170936845, -7.046104947782439],
            [110.347166469830626, -7.046068751346162],
            [110.347160038138838, -7.046014470124895],
            [110.347160152036153, -7.045933061923667],
            [110.347166938065499, -7.04573407318409],
            [110.347182250238902, -7.045507960226288],
            [110.347184357356369, -7.045417509432349],
            [110.347175945086235, -7.045363225445103],
            [110.347159019317829, -7.045327020317954],
            [110.347133309766505, -7.045299848299083],
            [110.347105594330202, -7.04529076422514],
            [110.347069390704291, -7.045281668296373],
            [110.347037418518951, -7.045281623647647],
            [110.347001189581377, -7.045290618425128],
            [110.346945708084974, -7.045308631682869],
            [110.346905235048553, -7.045317620524699],
            [110.34684127801502, -7.045326576557525],
            [110.34678354565348, -7.045335541277744],
            [110.346713338617391, -7.045362579306482],
            [110.346642861298506, -7.04538057157354],
            [110.346606632348298, -7.045389566319002],
            [110.346561940536418, -7.04538045848917],
            [110.346531961591978, -7.045371371218441],
            [110.34649350712462, -7.045353226727303],
            [110.346454524784335, -7.045307945378296],
            [110.346429123554501, -7.045262683010757],
            [110.346390783119432, -7.045163130320915],
            [110.346367451162635, -7.04505455323744],
            [110.346359064329548, -7.044982178534775],
            [110.346361479871376, -7.044873637443187],
            [110.346366416526919, -7.04478319062],
            [110.346367869228956, -7.04475605653338],
            [110.346369989155278, -7.044656560399682],
            [110.346370103168198, -7.044575152206622],
            [110.346357497584989, -7.044484680861203],
            [110.346331851468847, -7.044412282031931],
            [110.346291581211105, -7.044276545151115],
            [110.346289393712794, -7.044222269858222],
            [110.346300196069947, -7.044186103468767],
            [110.346319486594126, -7.044149948944001],
            [110.346340732352218, -7.044131887896367],
            [110.346372717121497, -7.044122887230499],
            [110.346417704415089, -7.044122950107851],
            [110.346481623289137, -7.044141130183593],
            [110.346579456857398, -7.044186493759748],
            [110.346754186564397, -7.044277191607876],
            [110.346873485589839, -7.044349721233155],
            [110.346928929000484, -7.044358844040721],
            [110.346967459345976, -7.044322716355007],
            [110.346965516740255, -7.044295577521217],
            [110.346952564823084, -7.044250332565672],
            [110.346946390788673, -7.044214142448973],
            [110.346944215869669, -7.04415082179601],
            [110.346950824714241, -7.044078468035584],
            [110.346950887993401, -7.044033241255319],
            [110.346942463105052, -7.043988002621551],
            [110.346929523851969, -7.043933712309708],
            [110.3469106049169, -7.043906549768105],
            [110.346872188518446, -7.0438612692488],
            [110.346778392076558, -7.043761639134763],
            [110.346746495952459, -7.043707322340884],
            [110.346731846435986, -7.043662075010252],
            [110.346731897073283, -7.04362589358699],
            [110.346742429099393, -7.043580681430895],
            [110.346761694244492, -7.043562617593306],
            [110.346785190809356, -7.043553605038541],
            [110.346821406954177, -7.043553655621118],
            [110.34687034268137, -7.043562769338275],
            [110.346932268278124, -7.04358999193764],
            [110.346983442212874, -7.043617199516867],
            [110.347019607749132, -7.043653431509906],
            [110.347058024147728, -7.04369871201724],
            [110.347115359494552, -7.04377115505668],
            [110.347146174518684, -7.043789288823659],
            [110.347169645787517, -7.043798366963134],
            [110.347195122930245, -7.043789357153],
            [110.347218632150714, -7.04377129922018],
            [110.347239890508845, -7.043744192769918],
            [110.347273095536906, -7.043671876120558],
            [110.347372634674741, -7.043509198293552],
            [110.347498575531702, -7.043283239648498],
            [110.347515897997866, -7.043238036940448],
            [110.347522185860626, -7.043192818837357],
            [110.347522287005646, -7.04312045597788],
            [110.347510186921852, -7.042668170339764],
            [110.347493261204875, -7.042631965227184],
            [110.347476310204286, -7.042613850829305],
            [110.347448594914127, -7.042604766788719],
            [110.347422847547733, -7.042604730867299],
            [110.347384342677813, -7.042622767894686],
            [110.347314148737055, -7.042640760700247],
            [110.347273675940869, -7.04264974959693],
            [110.347232932853146, -7.042649692737992],
            [110.34719674207247, -7.042631551479647],
            [110.347179803733155, -7.042604391715054],
            [110.347171353537547, -7.042577243797054],
            [110.347171429430489, -7.042522971654854],
            [110.347179993462717, -7.042468711359244],
            [110.347212400167734, -7.042360212089443],
            [110.347280803195488, -7.042206536171617],
            [110.34741980939161, -7.041944414236834],
            [110.347446991070385, -7.041782068585873],
            [110.347422158948419, -7.04168940784125],
            [110.347343797911734, -7.041625172757441],
            [110.347219129501795, -7.041525247623296],
            [110.347122978683544, -7.041432487344659],
            [110.347101782316372, -7.041289956070873],
            [110.347069808447174, -7.041204410431796],
            [110.346948691194257, -7.041115177822127],
            [110.346724151417831, -7.041033430807384],
            [110.346826119795637, -7.040886773188739],
            [110.34691305852273, -7.040657828690991],
            [110.34706152794665, -7.040151121613252],
            [110.347102363011345, -7.039794853889398],
            [110.347108263800109, -7.039537909689196],
            [110.347061997330712, -7.039325031917959],
            [110.346919059479191, -7.039104032854514],
            [110.346728794580869, -7.038967521443974],
            [110.346484502535702, -7.038849590486432],
            [110.34631269279096, -7.038695579162121],
            [110.346168187715634, -7.038485092638866],
            [110.346071220660107, -7.038221007792846],
            [110.346034503113131, -7.037961637811462],
            [110.346079386541305, -7.037740861234598],
            [110.34617882562037, -7.0376003164708],
            [110.346341026708757, -7.037526781043328],
            [110.346436536226051, -7.037445254407499],
            [110.346495682325013, -7.037329983065108],
            [110.346477710453541, -7.037110941839329],
            [110.346488654661741, -7.036875528293494],
            [110.346563552222364, -7.036451436755479],
            [110.346557462097209, -7.036252056088377],
            [110.346587557483417, -7.035984854548016],
            [110.346685516871133, -7.03576865123737],
            [110.346766314473683, -7.035679682790739],
            [110.346906499025934, -7.035631095804109],
            [110.347020386545751, -7.035638407001913],
            [110.347196460102793, -7.035680328765609],
            [110.347455835232083, -7.035814980424655],
            [110.347691819401305, -7.035992935584],
            [110.347979020713709, -7.036227841003781],
            [110.348224478314492, -7.036371734283535],
            [110.348570189556085, -7.036500699864308],
            [110.348822213303251, -7.036506856212823],
            [110.349044650882561, -7.036539580427317],
            [110.349229956442585, -7.036609298472121],
            [110.349299301749028, -7.036739054296257],
            [110.349262875121042, -7.036863478475905],
            [110.349034640705597, -7.036998841845819],
            [110.348806135837009, -7.037125159335832],
            [110.34864036154039, -7.037284559686692],
            [110.348496442073483, -7.037451064343418],
            [110.34839341314742, -7.037676353097733],
            [110.348454125867931, -7.037881659884883],
            [110.348643970054226, -7.038020844911643],
            [110.348921945291366, -7.038113845546564],
            [110.349116663524441, -7.038081701524905],
            [110.349322967557796, -7.038003279939918],
            [110.349627005072577, -7.037735108923189],
            [110.349970599456768, -7.037304931657443],
            [110.350574680713137, -7.036217174129445],
            [110.350834180573969, -7.035725941841137],
            [110.35101272994919, -7.035102057870539],
            [110.351165230469718, -7.03469522691281],
            [110.351396411251784, -7.034270414088835],
            [110.351557185781132, -7.034017365949912],
            [110.351743531406697, -7.033890988538629],
            [110.352123981508328, -7.033755834225358],
            [110.352335532986913, -7.033611400648622],
            [110.352454074614371, -7.03341256604753],
            [110.352564486252817, -7.033159447840946],
            [110.35262214705466, -7.032996710564761],
            [110.352657735853612, -7.032916381968612],
            [110.352762043038766, -7.032831380954559],
            [110.352925981230172, -7.032749771464895],
            [110.353093030648566, -7.032696494057992],
            [110.3532948223734, -7.032580313781027],
            [110.353421015610138, -7.032454586440442],
            [110.353504723048459, -7.032295751262483],
            [110.353586970507436, -7.032053504262455],
            [110.353691153709974, -7.031896271061418],
            [110.353810971753802, -7.031824042957826],
            [110.353965357983782, -7.031814813281813],
            [110.354119670298076, -7.031859091623256],
            [110.354270758274012, -7.031956873643836],
            [110.354484699374353, -7.032168053667196],
            [110.354819666918075, -7.032572974023171],
            [110.355409567996389, -7.033163949971951],
            [110.355585825836428, -7.033286946587006],
            [110.355751146001381, -7.033345403522629],
            [110.355962250152075, -7.033329956167198],
            [110.356176548131671, -7.033283037700751],
            [110.35638141195831, -7.033223516005156],
            [110.356620974381315, -7.033132566474039],
            [110.357013493170584, -7.032926941573177],
            [110.35734776883514, -7.03269133475856],
            [110.357492885624481, -7.032549894482718],
            [110.357574984088629, -7.032414662809379],
            [110.357652402191277, -7.032246375512206],
            [110.357677865466428, -7.032057557936241],
            [110.357662316315867, -7.031909602160398],
            [110.357586925443471, -7.031748974156248],
            [110.357481589980139, -7.031599321468815],
            [110.357264570715301, -7.031334630284119],
            [110.357064790560656, -7.031134487273696],
            [110.356948422260331, -7.03098954066081],
            [110.356917159961554, -7.030813235438366],
            [110.356936244557787, -7.030681064899228],
            [110.356961543513037, -7.030611853713511],
            [110.357012024553327, -7.030558414792163],
            [110.357097126094217, -7.03053020370244],
            [110.357197932367527, -7.030536637099257],
            [110.357421524672375, -7.030603042181684],
            [110.357695455362901, -7.030719876844373],
            [110.357908006078887, -7.030797282967139],
            [110.358144248035941, -7.030829082098204],
            [110.35839942308175, -7.030838874246411],
            [110.358579060276213, -7.03079505462644],
            [110.358686258240496, -7.03073539807107],
            [110.35877456200005, -7.030669420544952],
            [110.358872330084751, -7.030594013323481],
            [110.359020488362404, -7.030531265189197],
            [110.359185967704391, -7.030473262008925],
            [110.359415887601557, -7.030366657992102],
            [110.359415893649825, -7.030366654895183],
            [110.359392621994061, -7.030572879942142],
            [110.3594224199434, -7.030746537697463],
            [110.35944955082222, -7.030909474705576],
            [110.35947032967249, -7.031402489691233],
            [110.35947428715447, -7.031712639345704],
            [110.359474039327736, -7.031893547010553],
            [110.359431227708782, -7.032164850470067],
            [110.359367196080925, -7.032436124895999],
            [110.359352851131774, -7.03258083167151],
            [110.35937382318717, -7.032761768349411],
            [110.359403827034498, -7.032964415922038],
            [110.359486808303828, -7.033105648062589],
            [110.359652891152251, -7.033213929058208],
            [110.359904809747803, -7.033337520066756],
            [110.360186264759079, -7.033434358553229],
            [110.360483766609917, -7.033563370094584],
            [110.360705162741695, -7.033695263777083],
            [110.360731426070046, -7.033731481234483],
            [110.360713822341253, -7.033776684212344],
            [110.360590155661328, -7.033794606183732],
            [110.360219647165337, -7.033902644972382],
            [110.360043317653549, -7.033947631046667],
            [110.359822862864675, -7.033983511333233],
            [110.359514107987494, -7.034037361553025],
            [110.359319965961575, -7.034073277582483],
            [110.359258236840617, -7.034109374721726],
            [110.359214037195059, -7.034154541237623],
            [110.359187637559231, -7.034217822904288],
            [110.359187525947959, -7.034299231340372],
            [110.359265069477971, -7.034413713614552],
            [110.359396396253146, -7.034472837328241],
            [110.359575969546185, -7.034550781916757],
            [110.359750168316467, -7.034636756916797],
            [110.359886807430016, -7.03473339761259],
            [110.359977812147349, -7.034862127196429],
            [110.360060687161322, -7.035052468974485],
            [110.360108724618414, -7.035224008209672],
            [110.360159337516819, -7.035473249928877],
            [110.360173144369028, -7.035630252889901],
            [110.360181250395684, -7.035702627166899],
            [110.36017298336759, -7.03574784286872],
            [110.360148638602212, -7.035756854998116],
            [110.360124306221763, -7.035756821743285],
            [110.360083588404677, -7.035738675292338],
            [110.360026708131642, -7.035539598752017],
            [110.359961610414373, -7.035349556374057],
            [110.359896609839367, -7.035295195117198],
            [110.359792177735571, -7.035293236633366],
            [110.359684825282926, -7.035352033873298],
            [110.359547973083451, -7.035410790734828],
            [110.359333323141342, -7.035488196025168],
            [110.359132115172017, -7.035541506142221],
            [110.358981875942902, -7.035584168857474],
            [110.358909841621227, -7.035628524771273],
            [110.358844592938084, -7.035755070989296],
            [110.358836226494503, -7.03587264970007],
            [110.358909159097777, -7.036126020708063],
            [110.359145270813087, -7.036433887504869],
            [110.359283623691084, -7.036642121036036],
            [110.359348549945096, -7.036750754672137],
            [110.359372671535468, -7.036904559451103],
            [110.35933997273456, -7.037022104874203],
            [110.35925842532211, -7.037067220247727],
            [110.359078932605328, -7.037148383098242],
            [110.358948707838223, -7.037202477157883],
            [110.35889964829363, -7.037283818542234],
            [110.358817976658131, -7.037419387629859],
            [110.358641593357916, -7.037708598672414],
            [110.35860712217476, -7.037880413968596],
            [110.358641121463364, -7.038052323090366],
            [110.35872638186909, -7.038188120864225],
            [110.358931607245779, -7.038324082964006],
            [110.35908533835152, -7.038459974467596],
            [110.359187783618921, -7.038650068089716],
            [110.359324069680497, -7.038921616600124],
            [110.359597319743301, -7.039383305885167],
            [110.359801816600495, -7.039844900987755],
            [110.359989461548636, -7.040216018998473],
            [110.360142933297524, -7.040541863230752],
            [110.360262539458461, -7.040804343334151],
            [110.360399012535737, -7.040940210899882],
            [110.36065545136357, -7.041076242391834],
            [110.361134279591226, -7.04121260259151],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 89,
        id: 91,
        area: 3269157.456696,
        perimeter: 10023.875345,
        kode_pos: 50229,
        kode_kec: 3374020,
        kode_kel: 3374020017,
        kec: "Gunungpati",
        kel: "Kali Segoro",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.372024232245053, -7.05545576030242],
            [110.371993658341466, -7.055365265054731],
            [110.372008813011291, -7.055247695073843],
            [110.37207750560566, -7.055084970028069],
            [110.372214558951413, -7.054795701096853],
            [110.372404866997371, -7.054461276615514],
            [110.372458438534011, -7.054180940784854],
            [110.372450932950088, -7.054081431146781],
            [110.372443415188371, -7.053990966905935],
            [110.372405561271833, -7.053945688967141],
            [110.372261441368664, -7.053809814061608],
            [110.372105793867277, -7.053619651139277],
            [110.371918407783042, -7.053465627145569],
            [110.37177343933341, -7.053329750970812],
            [110.371700905109179, -7.053193972205072],
            [110.371683003844353, -7.053040176103765],
            [110.37169540215757, -7.052868329935742],
            [110.371738026868783, -7.052732706075649],
            [110.371822859187347, -7.052560957321123],
            [110.371883592332466, -7.052425357792917],
            [110.371901810520157, -7.052343973568779],
            [110.371895683216948, -7.05227160202104],
            [110.37190797173001, -7.052181164408464],
            [110.372006863127638, -7.051864707883258],
            [110.372184915233333, -7.051385540253265],
            [110.372260778119696, -7.051150461417856],
            [110.372266263666106, -7.051069060056661],
            [110.372255609192166, -7.050996682423442],
            [110.372213042881583, -7.050879034837171],
            [110.372111487366624, -7.050652763001136],
            [110.371978154157176, -7.050281721805694],
            [110.37195676004319, -7.050200284319023],
            [110.371962209071242, -7.050146019156245],
            [110.371983773723926, -7.050100821068387],
            [110.372075064383921, -7.049965262538655],
            [110.372187402424657, -7.049748323529674],
            [110.372345440363915, -7.049422900883162],
            [110.372444601494848, -7.049115489929668],
            [110.37251123617439, -7.048798989851933],
            [110.372579078620944, -7.048636263448678],
            [110.372646848022285, -7.048527809430047],
            [110.372739738501082, -7.048464616197155],
            [110.372853813197494, -7.048428587620692],
            [110.372959375269161, -7.048410638427576],
            [110.373119581807401, -7.048365626257004],
            [110.373280095568205, -7.04830252360583],
            [110.373436377295292, -7.048230369804378],
            [110.37356325720954, -7.048140085703377],
            [110.373647659212509, -7.048076880894231],
            [110.373719648140522, -7.047986523179965],
            [110.373783136614662, -7.047905199481445],
            [110.37383814892587, -7.047814818998353],
            [110.373893161214795, -7.04772443850656],
            [110.373960671510787, -7.047597893070354],
            [110.374088497067945, -7.04743524671418],
            [110.374214402602419, -7.047227370646667],
            [110.374231819811058, -7.047109803510528],
            [110.374214730187916, -7.046983144759554],
            [110.374169394888597, -7.046820266516193],
            [110.374089637763873, -7.046584978827919],
            [110.374049985642912, -7.046404017347489],
            [110.374050094855164, -7.046322608718443],
            [110.374054941269677, -7.046295478948948],
            [110.374124824012185, -7.046087527858871],
            [110.374251553679358, -7.045897743679796],
            [110.374393291264312, -7.045698934117739],
            [110.374495283564869, -7.045599570955646],
            [110.374544280923686, -7.045563454821754],
            [110.374652708084554, -7.04551837275231],
            [110.37473281685196, -7.045491343627893],
            [110.374898668955481, -7.045455383711074],
            [110.375058591383976, -7.045410370385976],
            [110.37515005410232, -7.045356220096773],
            [110.375240409254914, -7.04528397746401],
            [110.375355982470637, -7.045184632235198],
            [110.375441810500661, -7.045112383509454],
            [110.375516050022426, -7.045031073865948],
            [110.37557892346463, -7.044985930720197],
            [110.375807636659431, -7.044913872708167],
            [110.376007477713202, -7.044850821473007],
            [110.376160868030269, -7.044823889872742],
            [110.376259072746208, -7.044805930034008],
            [110.376418958598336, -7.044788052435043],
            [110.376578880691639, -7.04474303855563],
            [110.376698968482216, -7.044652744399716],
            [110.37678482037812, -7.044562404581612],
            [110.376836508861729, -7.044417746669153],
            [110.376857688017182, -7.044236866393109],
            [110.376852457062839, -7.044128314322411],
            [110.376821514746894, -7.043992591714512],
            [110.376764505688683, -7.043883970642597],
            [110.376697286642397, -7.043793426807567],
            [110.37654670894652, -7.043621363023656],
            [110.376417105186945, -7.043422190891765],
            [110.376360403394514, -7.043295479342773],
            [110.376355148378536, -7.043205018090162],
            [110.376360608830268, -7.043141707399215],
            [110.376402026824209, -7.04306035380224],
            [110.376454172355494, -7.042997105351466],
            [110.37654760293735, -7.04295200277681],
            [110.376765822072258, -7.042898021053113],
            [110.37692178232065, -7.04285300171478],
            [110.377117414468245, -7.042762808025477],
            [110.377200364108617, -7.042726736778848],
            [110.377319295351072, -7.042654531713473],
            [110.377405381422662, -7.042600373749602],
            [110.377527129940518, -7.04253721780218],
            [110.377610374512656, -7.042492101441843],
            [110.377663651580448, -7.042428854340654],
            [110.377696544923737, -7.042374625540965],
            [110.377729167375804, -7.042311350950608],
            [110.377768074765982, -7.04220285758101],
            [110.377827377885609, -7.042076300479488],
            [110.377840760416092, -7.042013000286596],
            [110.377828666344541, -7.041958711636027],
            [110.377792546539609, -7.041886300174014],
            [110.377762368470215, -7.041813896613459],
            [110.377744429127148, -7.041687236766777],
            [110.377714395739929, -7.041506288267209],
            [110.377696601078043, -7.041271083481414],
            [110.377692092493106, -7.041044941794669],
            [110.37771908016984, -7.040963568842938],
            [110.377740505490465, -7.040809825068255],
            [110.3778375356531, -7.040610954694642],
            [110.377896664025116, -7.040402988470657],
            [110.377979264169653, -7.040204098865157],
            [110.378010543998897, -7.040086549877063],
            [110.378141532569174, -7.039670634257714],
            [110.378259891001264, -7.039390383232099],
            [110.378307641279562, -7.039227628945432],
            [110.378325020876687, -7.039137197740318],
            [110.378332226749421, -7.039037707589198],
            [110.378339432617253, -7.038938217437278],
            [110.378332503642881, -7.038829663078634],
            [110.378339697465165, -7.038739218339138],
            [110.378360568580533, -7.038576428329333],
            [110.378388796045286, -7.038413648086935],
            [110.378385521166535, -7.038323189439256],
            [110.378378531994215, -7.038259862147581],
            [110.378341882506476, -7.038160364309864],
            [110.378465869202927, -7.038115251259493],
            [110.378589928004587, -7.038015916256992],
            [110.378717062972285, -7.037943721591503],
            [110.378785641980613, -7.037862403742769],
            [110.378847713415837, -7.037781077245518],
            [110.378873767663919, -7.037763020959584],
            [110.378919332529819, -7.037754035992684],
            [110.378961761039292, -7.037763137721739],
            [110.379033259912504, -7.037826550602162],
            [110.379111477160976, -7.0379442449726],
            [110.379199338349679, -7.038043861262006],
            [110.379244807082429, -7.038107239588628],
            [110.37934259516706, -7.038188778158812],
            [110.379401079044996, -7.038252173732285],
            [110.379459857891888, -7.038306524259128],
            [110.379537816415748, -7.038406127365665],
            [110.379622601508686, -7.038478603215375],
            [110.379704051437187, -7.038505847471619],
            [110.379847440652114, -7.038551264658556],
            [110.379964811658695, -7.038587601901781],
            [110.38002335570097, -7.038605770325526],
            [110.380161086235276, -7.038651179925137],
            [110.380240013797817, -7.038660329886854],
            [110.380288113175226, -7.038660393581556],
            [110.380356325115244, -7.038642393035502],
            [110.380433368101308, -7.038579177004565],
            [110.380555765022592, -7.038452702957148],
            [110.380606765719151, -7.038398497856428],
            [110.380632537006989, -7.038380441095663],
            [110.380680919296523, -7.038380505121915],
            [110.380723299943611, -7.038425788370285],
            [110.380728886741878, -7.038480068364819],
            [110.380691924988795, -7.038615700959116],
            [110.380634718719563, -7.038868897389606],
            [110.380611927775803, -7.038986457858694],
            [110.380608966454218, -7.039085953705066],
            [110.38061168787678, -7.039167366206107],
            [110.380631397567285, -7.039239755758929],
            [110.380760117430597, -7.039466061943511],
            [110.380827312783438, -7.039574696060858],
            [110.380867605058185, -7.039701385442129],
            [110.380912304459173, -7.039918534986488],
            [110.380988493986763, -7.04007240815486],
            [110.381046587485372, -7.040217211942676],
            [110.381073629700907, -7.040307702050214],
            [110.381073545783622, -7.04037101997395],
            [110.381059904788458, -7.040416229096676],
            [110.381006074512825, -7.040470430484288],
            [110.380893711894018, -7.040497418116964],
            [110.380664483598139, -7.040533296471568],
            [110.380489344564197, -7.040533064603641],
            [110.380363707798068, -7.040541943664045],
            [110.380212865479137, -7.04056888016548],
            [110.380107293335257, -7.040595876598436],
            [110.380028046488178, -7.040613862459127],
            [110.379967744518368, -7.040640918844096],
            [110.379933755842302, -7.040668010094601],
            [110.379903397319879, -7.040731287879369],
            [110.379903289215562, -7.040812696618861],
            [110.379914414582387, -7.040957438266593],
            [110.379914234405149, -7.041093119497896],
            [110.379898914303965, -7.041337325835517],
            [110.379909238900765, -7.041445884695023],
            [110.379897813217099, -7.04152727843034],
            [110.379859496282819, -7.041617681948448],
            [110.379824869558533, -7.041699044923105],
            [110.379799049987881, -7.041753283277894],
            [110.379789923835133, -7.041807543763464],
            [110.379789875772701, -7.041843725423054],
            [110.379801392180553, -7.041907058707531],
            [110.379831239582572, -7.04201564344897],
            [110.379935319908142, -7.042260008060186],
            [110.380032422933837, -7.042431999959295],
            [110.380093899622594, -7.042585853766063],
            [110.3801389538189, -7.04274873123467],
            [110.380219917414735, -7.042929747148032],
            [110.3802878418023, -7.043128836636954],
            [110.380336255364895, -7.043318854837945],
            [110.380394108832505, -7.043644567009839],
            [110.380445881743242, -7.043861725947936],
            [110.380459956739998, -7.043916017181249],
            [110.380459059892786, -7.043952197717682],
            [110.380443685040035, -7.044024540797735],
            [110.380419797942395, -7.044114963460872],
            [110.380401087824609, -7.044142074964307],
            [110.380375599132265, -7.044160132054241],
            [110.380345041520258, -7.044160091564212],
            [110.380223106026136, -7.044150884544379],
            [110.38012804991817, -7.044141713123425],
            [110.380038110779864, -7.044114457606443],
            [110.379965160116683, -7.044078179167603],
            [110.379902395332806, -7.044041914224854],
            [110.379878652344757, -7.044023791880861],
            [110.37985862364215, -7.043978538170926],
            [110.379850195511253, -7.043933299843905],
            [110.379843453007439, -7.043897109182218],
            [110.379823129353625, -7.043860900510261],
            [110.379794269408265, -7.043860862237254],
            [110.379719476915525, -7.043933126481255],
            [110.379682008531873, -7.044023531081172],
            [110.379664947934856, -7.044086826458971],
            [110.379647827220523, -7.044195348904311],
            [110.379628972781603, -7.044331005336662],
            [110.379604399389422, -7.044511881326676],
            [110.379591498704713, -7.044638500223174],
            [110.379571229514525, -7.044774154770621],
            [110.37956861089377, -7.044828423872067],
            [110.379578712603006, -7.044891755280696],
            [110.379603822227367, -7.044946061172642],
            [110.379700172956476, -7.045045688725205],
            [110.379751042290579, -7.045090983354052],
            [110.37979176180751, -7.045109128226442],
            [110.379870395476928, -7.045127323381299],
            [110.380012727026028, -7.045118466699066],
            [110.380134662762373, -7.045127673795112],
            [110.380365789882347, -7.045155116434779],
            [110.380454621491637, -7.045164279577605],
            [110.380523081319623, -7.045173415714173],
            [110.380598849606784, -7.045218743246203],
            [110.380678765997516, -7.045336439709448],
            [110.38072495235113, -7.045499318642753],
            [110.380733272549008, -7.045625965696495],
            [110.380733164513543, -7.045707374431825],
            [110.380722599632378, -7.045779723885353],
            [110.380672326837583, -7.045924384185414],
            [110.380652057868915, -7.046060038794892],
            [110.380625329244438, -7.046159503121439],
            [110.380605060246097, -7.046295157725441],
            [110.380577988610554, -7.046439848743768],
            [110.380559489286256, -7.046521233104944],
            [110.380579663725982, -7.046883077048022],
            [110.380588019903172, -7.046982587852804],
            [110.380621581993807, -7.047064041195877],
            [110.380684817054032, -7.047172670142634],
            [110.380697489439044, -7.04721791408357],
            [110.380697453417071, -7.047245050326685],
            [110.380671880499264, -7.047326425316726],
            [110.380638090220373, -7.04741683484615],
            [110.380638042185055, -7.047453016502912],
            [110.380688568878284, -7.047543537752287],
            [110.380735772317706, -7.047579782013448],
            [110.380773662659607, -7.047597923072074],
            [110.380874944235231, -7.04760710266816],
            [110.381115445764934, -7.047607421168144],
            [110.38146996134131, -7.047616935864516],
            [110.381613118514821, -7.047626170714396],
            [110.381739853014054, -7.047644429230325],
            [110.381836890491257, -7.047653603006594],
            [110.381938160135903, -7.047671827791466],
            [110.38201818509414, -7.04770811533164],
            [110.382073016146734, -7.047753414984951],
            [110.382110870621005, -7.047798692190539],
            [110.382131712569858, -7.047871083201871],
            [110.382148485494525, -7.048024877725406],
            [110.38215734799175, -7.04816961635894],
            [110.382155825448265, -7.048251023237238],
            [110.382146687391298, -7.048314329183119],
            [110.382152569299052, -7.048359564120256],
            [110.382167493419431, -7.048413856443084],
            [110.382188642301145, -7.04846815699429],
            [110.382188582398257, -7.048513384076506],
            [110.382164424362784, -7.048594761030624],
            [110.382101160176092, -7.048721313440368],
            [110.382086056312147, -7.048802702358547],
            [110.3820921971936, -7.048866028501915],
            [110.382133965034782, -7.048947492613767],
            [110.382169568032964, -7.048983721412315],
            [110.38222013112059, -7.049047106281744],
            [110.382287092991447, -7.049119558255573],
            [110.382361411427212, -7.049192019944474],
            [110.382411679641194, -7.049264449839411],
            [110.38247861761964, -7.049354992623645],
            [110.382565102721202, -7.04942747036299],
            [110.382656114957499, -7.049499954069271],
            [110.382945363443682, -7.049654108471791],
            [110.383080208233537, -7.049744740850423],
            [110.383189871021983, -7.049835339953984],
            [110.383333072679832, -7.050025483068617],
            [110.383397193458677, -7.050106976591249],
            [110.383460525219348, -7.050143241896833],
            [110.383523845032784, -7.050188552612704],
            [110.383658522721689, -7.050405820700929],
            [110.383881714496766, -7.050659387227382],
            [110.383995645731375, -7.050731900930003],
            [110.384239990797923, -7.050822677375487],
            [110.38436445081642, -7.050849977697475],
            [110.384435717164905, -7.050877207905059],
            [110.384518560395165, -7.050922544224198],
            [110.384696094797917, -7.051040368732813],
            [110.384790797918114, -7.051103811498924],
            [110.38484594838495, -7.051121974991076],
            [110.384924890128275, -7.051122078928554],
            [110.385067235484001, -7.051104175439644],
            [110.385114487351899, -7.051104237632182],
            [110.385201315785295, -7.051131488211447],
            [110.385343518052977, -7.051222129687264],
            [110.385394659632212, -7.05127646958931],
            [110.385426289908352, -7.051321738383068],
            [110.385430203454547, -7.051357925277644],
            [110.385426158758477, -7.05142123801257],
            [110.385406269108927, -7.05148452990206],
            [110.385406233338955, -7.051511666164403],
            [110.385437863630258, -7.051556934958404],
            [110.385520707088446, -7.051602271117413],
            [110.385572203195522, -7.05160233885065],
            [110.38563531199371, -7.051593376413601],
            [110.385694459551232, -7.051584408758853],
            [110.385781359612039, -7.051557386712112],
            [110.385840790109356, -7.051548419410197],
            [110.385919720026223, -7.051557568607457],
            [110.38597487060072, -7.051575731974429],
            [110.386113123837106, -7.051657322610389],
            [110.386295787702821, -7.051748016992737],
            [110.3865643481641, -7.051929278496573],
            [110.386757692793125, -7.052074259370609],
            [110.386824643922481, -7.052155756206486],
            [110.386879747026569, -7.052210101167402],
            [110.386919335687978, -7.052228244005774],
            [110.387006164459578, -7.052255494275465],
            [110.387061315167742, -7.052273657524411],
            [110.387104841305259, -7.052309896392702],
            [110.387159920679963, -7.052382332171453],
            [110.387213597229987, -7.052445720665497],
            [110.387256840449226, -7.052481959150569],
            [110.387324145998051, -7.052509183755343],
            [110.387399138651361, -7.052500236675374],
            [110.387489988179397, -7.052482264937121],
            [110.387541201456443, -7.052482332090667],
            [110.387592662032489, -7.052509535881915],
            [110.387631955923055, -7.052536723717441],
            [110.387714963935991, -7.05267251413251],
            [110.387777894673576, -7.052799232780403],
            [110.38782502795047, -7.052889748957355],
            [110.387864592954941, -7.052925982572064],
            [110.387907848148586, -7.052953175579853],
            [110.387987061318299, -7.052962324822051],
            [110.38801846839084, -7.052962365974643],
            [110.388042212155923, -7.052980487965131],
            [110.388077815933045, -7.053016716373615],
            [110.388101229261906, -7.053071019689851],
            [110.388156603700793, -7.053134410315691],
            [110.388195873933782, -7.053179688960181],
            [110.38820715618786, -7.053206840060251],
            [110.388221208552395, -7.053279221990842],
            [110.38826642068662, -7.053324508413919],
            [110.388337074308012, -7.053387919034715],
            [110.388401797932119, -7.053442276440135],
            [110.388496502081352, -7.053505718534111],
            [110.38858361414897, -7.053532968907986],
            [110.388641924954456, -7.053514954360287],
            [110.388736488553363, -7.05346985093121],
            [110.388783764415891, -7.053451821921752],
            [110.388812919811116, -7.053442814635567],
            [110.388838385035243, -7.053442847959928],
            [110.388889279906621, -7.05347005088232],
            [110.388951174142363, -7.053524404515563],
            [110.388979939875554, -7.053596805683422],
            [110.389008964849694, -7.053687298072353],
            [110.389016226617585, -7.05375967110523],
            [110.389016108045524, -7.05385012536548],
            [110.388997833416823, -7.053976737637161],
            [110.388972249578529, -7.054067158577653],
            [110.388972166568593, -7.054130476558511],
            [110.38898640714865, -7.054275222252533],
            [110.389015503303739, -7.054311442087289],
            [110.389091851695227, -7.054347723739115],
            [110.389124626180902, -7.0543839483801],
            [110.389168081546273, -7.054474459639837],
            [110.389186154626159, -7.054501619605126],
            [110.389284278176945, -7.054546975153126],
            [110.389332614812346, -7.054583220134317],
            [110.389354354373751, -7.054619430330746],
            [110.389379784110091, -7.054646599910074],
            [110.389423334353765, -7.05466474774047],
            [110.389594270314518, -7.054637834892462],
            [110.389638127208329, -7.054637892219843],
            [110.389663580655622, -7.05464697093162],
            [110.389714416482491, -7.05471940091244],
            [110.389750598261912, -7.054746584526014],
            [110.389856066727802, -7.054800995003836],
            [110.389954154894056, -7.054873486703112],
            [110.390037812748133, -7.054945959534055],
            [110.390066897189158, -7.054991224740229],
            [110.390088648665326, -7.055018389481006],
            [110.390114090305659, -7.055036513598022],
            [110.390161330779861, -7.055045620741836],
            [110.390445080283428, -7.055082173021084],
            [110.390496010926498, -7.055082239505804],
            [110.390532192783027, -7.055109423064724],
            [110.390557622617848, -7.055136592587528],
            [110.390568598445128, -7.055181834131119],
            [110.390557504321578, -7.055227046868546],
            [110.390542872856045, -7.055380800309795],
            [110.390520979367864, -7.055462180722303],
            [110.390512667389245, -7.055543578862231],
            [110.390518844927755, -7.055579768699626],
            [110.390540855743566, -7.055625024648441],
            [110.390572415827009, -7.055724565721329],
            [110.390597456277433, -7.055833143726393],
            [110.39061318309507, -7.055923618687408],
            [110.390628709775967, -7.055950775283369],
            [110.390649600652225, -7.055986984323573],
            [110.390670184925199, -7.056041283849703],
            [110.390693244768812, -7.056149859266519],
            [110.390741805491686, -7.056231331632383],
            [110.390785544274323, -7.056321843145485],
            [110.390820004893882, -7.056367115331139],
            [110.390854760294744, -7.056403342455802],
            [110.390891743816027, -7.056466708816213],
            [110.390965758183569, -7.05655725981731],
            [110.390990869697646, -7.056611565239505],
            [110.391009720868851, -7.056692998825094],
            [110.391025459605672, -7.056774428349987],
            [110.391025305902247, -7.056892018917289],
            [110.391037896734346, -7.05700058066618],
            [110.391059824896388, -7.057109154595389],
            [110.391106900091103, -7.0572448976579],
            [110.39116676714292, -7.057335430183533],
            [110.391235983370251, -7.057416929452427],
            [110.391330641441968, -7.057516552777321],
            [110.39138205553526, -7.057579937926162],
            [110.391397877077097, -7.05759804944355],
            [110.391423035957146, -7.057616173134755],
            [110.39146406365019, -7.057616226625502],
            [110.39156197602982, -7.057607308822064],
            [110.391615477132333, -7.057589287669859],
            [110.391685129981525, -7.057553196672179],
            [110.391745174345914, -7.05750804769775],
            [110.391801799685851, -7.057480985149044],
            [110.391861808605867, -7.057462972445986],
            [110.39192178210088, -7.057472096023377],
            [110.391953472450012, -7.057472137305986],
            [110.391994511940013, -7.057463145319382],
            [110.392048024817555, -7.057436078684892],
            [110.392139806689883, -7.05735478920904],
            [110.392228228539338, -7.057246359000878],
            [110.392313514306181, -7.057156015580725],
            [110.392341809243618, -7.057156052414914],
            [110.392395534233259, -7.057183258686455],
            [110.392439627152939, -7.057219497861495],
            [110.392467886706882, -7.057246670979967],
            [110.392512191816152, -7.057337183100771],
            [110.392527683267929, -7.057391475937179],
            [110.392540262662095, -7.057509083104905],
            [110.392552865650103, -7.057608599411192],
            [110.392584155117973, -7.057699094589199],
            [110.392729025319369, -7.05791637379874],
            [110.392808439904968, -7.057988840685389],
            [110.392840083134132, -7.058025063634247],
            [110.392843160213104, -7.058052203975637],
            [110.392832805598601, -7.058115508628799],
            [110.392719970855012, -7.058133452722013],
            [110.392571634289652, -7.058187532367442],
            [110.392426386546717, -7.058259706874524],
            [110.39233808253131, -7.05827768280413],
            [110.392022227632694, -7.058340589575401],
            [110.391873902746781, -7.058385623556926],
            [110.391647436348222, -7.05846673741559],
            [110.391440222308887, -7.058538830818174],
            [110.391279117273768, -7.058620029708965],
            [110.391167823253824, -7.058692248079979],
            [110.390969839964626, -7.058845762285837],
            [110.390816279762603, -7.058999334386193],
            [110.390632408251221, -7.05918000317373],
            [110.390395824904061, -7.059423921110837],
            [110.390273177007472, -7.059523260730137],
            [110.389991132999697, -7.059694755496626],
            [110.389563804566379, -7.059965559875365],
            [110.389304396134293, -7.060137083791357],
            [110.389063367473312, -7.060317677050931],
            [110.388854300246209, -7.060507357473046],
            [110.388763342293871, -7.060606738147339],
            [110.388731309427456, -7.060651923373638],
            [110.388661831971916, -7.060769423041745],
            [110.388557185353378, -7.060950194672759],
            [110.388434111153671, -7.061158078429767],
            [110.388306828214482, -7.061338820312337],
            [110.388133942382964, -7.061555684089655],
            [110.38798375199687, -7.061727350378882],
            [110.38787441362706, -7.061817661287624],
            [110.387733643749598, -7.061926021786598],
            [110.387674411598837, -7.061998307530034],
            [110.38752504595486, -7.062188065596255],
            [110.387267118968737, -7.062522407976264],
            [110.387026156845835, -7.062865817907653],
            [110.386839911477523, -7.063127890706872],
            [110.386725631263928, -7.063317694572985],
            [110.386640224071726, -7.063498490940628],
            [110.386572630928086, -7.063688356150752],
            [110.386504707077663, -7.063914402643999],
            [110.38644086050347, -7.064267090550481],
            [110.386394014878888, -7.064601709939891],
            [110.386381043429466, -7.064782601531723],
            [110.386338135176018, -7.06513531694727],
            [110.386299718311037, -7.065515174557569],
            [110.386282371218513, -7.065795560132011],
            [110.386265354770956, -7.066039764406686],
            [110.386262111024337, -7.066139259891934],
            [110.386148929220653, -7.066139110882435],
            [110.386086667294535, -7.066148074331778],
            [110.385985062752837, -7.066166031390822],
            [110.385877516160221, -7.066183980599842],
            [110.385751011608306, -7.066201904805912],
            [110.385645469572211, -7.066201765738494],
            [110.38553341958449, -7.066201618069729],
            [110.385350666821793, -7.066174240872926],
            [110.385199875988206, -7.066155951186358],
            [110.385032095948262, -7.066146684472667],
            [110.384901948860445, -7.06613746735155],
            [110.384747467700294, -7.066128218082081],
            [110.384604292763285, -7.066128029115623],
            [110.384447842906809, -7.066109731719683],
            [110.384253772105637, -7.066082339157768],
            [110.384110621142881, -7.066064059215055],
            [110.383967729197167, -7.066063870428614],
            [110.383876310988924, -7.066081840482167],
            [110.38375600747014, -7.066117863193847],
            [110.383555838894111, -7.066208052845926],
            [110.38309202376945, -7.066460711292282],
            [110.382988980334829, -7.06649675663173],
            [110.382752497274751, -7.06665926125629],
            [110.382563056568273, -7.066767555499632],
            [110.38249369664986, -7.066794599906126],
            [110.382398906823013, -7.066794474334801],
            [110.382176823421389, -7.066767043782032],
            [110.381954481133278, -7.066721521932581],
            [110.381674145227322, -7.066666877638895],
            [110.381553901552948, -7.066657672711835],
            [110.381437324268973, -7.066657518043095],
            [110.38136091450788, -7.066666462074702],
            [110.381229575466776, -7.066702469459086],
            [110.380930938023639, -7.06679252720586],
            [110.380748667088554, -7.066828466808742],
            [110.380639151603177, -7.066837366740129],
            [110.380551706577435, -7.066846295973352],
            [110.380449842909186, -7.066846160604944],
            [110.380300466963604, -7.066827871212136],
            [110.380278799949664, -7.066737388187606],
            [110.380230902522626, -7.066583552336232],
            [110.380192649671926, -7.066411638460023],
            [110.380154360692401, -7.066266860800133],
            [110.380087198355767, -7.066131090169117],
            [110.379962385744705, -7.0659409703332],
            [110.379808772212954, -7.065705585052333],
            [110.379712700526738, -7.06560595761109],
            [110.379654731167989, -7.065578744221667],
            [110.379597008589485, -7.065578667418277],
            [110.379500756044635, -7.065614721017606],
            [110.379298678748256, -7.065650633725816],
            [110.379192878148828, -7.065632402022572],
            [110.379067933265404, -7.065541781436323],
            [110.378991114705244, -7.065433134081337],
            [110.378986166400679, -7.06532458244479],
            [110.379009170143107, -7.065261295138642],
            [110.379047417159498, -7.065225164392817],
            [110.379108571338449, -7.065198109567755],
            [110.37925377478031, -7.065162121213985],
            [110.379383771094538, -7.065071840045045],
            [110.379444961423786, -7.065017648956212],
            [110.379490920498483, -7.064927255903461],
            [110.37953690367452, -7.064818772033127],
            [110.379575271207003, -7.064692187179829],
            [110.379575391798099, -7.064601733122723],
            [110.379567945006002, -7.064456996466186],
            [110.379529915167254, -7.064330309961905],
            [110.37947661794621, -7.064194557718919],
            [110.37933434741133, -7.063941096584344],
            [110.379202829223615, -7.063687649729733],
            [110.379093170909556, -7.063379959421798],
            [110.379005709075045, -7.063189889128065],
            [110.378917891939864, -7.063054090877085],
            [110.37880793215929, -7.062972535650363],
            [110.37862126824173, -7.062908969062013],
            [110.378401426513321, -7.062899630711708],
            [110.378302393271767, -7.062899498720399],
            [110.378236501675417, -7.062872274627986],
            [110.378181669384546, -7.062826974430532],
            [110.378148624411267, -7.062781703275089],
            [110.378126921692413, -7.062718356399833],
            [110.378127320453373, -7.062419858067186],
            [110.378105395216707, -7.062311283794089],
            [110.378039624562533, -7.062193605641486],
            [110.37797376933888, -7.062139245309463],
            [110.377863785769449, -7.062075780697843],
            [110.377688205837799, -7.061976046879573],
            [110.377545460447536, -7.061867311395367],
            [110.377468618606002, -7.061776754663059],
            [110.377402884472758, -7.061631940229302],
            [110.377314630541434, -7.061188596895989],
            [110.377237849340361, -7.061052813121472],
            [110.37716103188292, -7.06094416554641],
            [110.377018299027398, -7.060826384531818],
            [110.37688657711378, -7.060726709022332],
            [110.376809747684604, -7.060627106802317],
            [110.376809808213906, -7.06058187978979],
            [110.376820976449068, -7.060482395100858],
            [110.376920408555577, -7.060184029069234],
            [110.376997721999018, -7.059921815147626],
            [110.37699787931102, -7.059804224907109],
            [110.376975930209923, -7.059713741408971],
            [110.376921146807931, -7.059632259488292],
            [110.376800213644941, -7.059505462128581],
            [110.376668564771123, -7.059351514172358],
            [110.376536952309252, -7.059170429977865],
            [110.376316542211811, -7.058953045415811],
            [110.376162847818833, -7.058780977041838],
            [110.376042282851003, -7.058590862083197],
            [110.375998643821674, -7.058427986211856],
            [110.375998789234629, -7.058319441388277],
            [110.376020770197329, -7.058174744084138],
            [110.376065188926972, -7.057966758843244],
            [110.376065297968069, -7.057885350222965],
            [110.376043312761993, -7.057822002904188],
            [110.375999516264983, -7.057776717262776],
            [110.375955388357596, -7.057767612848143],
            [110.375834557071201, -7.05777649670109],
            [110.375614596685864, -7.057857611267974],
            [110.375504493182703, -7.057884600218681],
            [110.375383673996296, -7.057884438550339],
            [110.375350888311075, -7.057857258422556],
            [110.375317856070893, -7.057802941711082],
            [110.375295931623697, -7.057694367361135],
            [110.375263279394431, -7.057567687817772],
            [110.375219297103484, -7.057450038525038],
            [110.37519777695195, -7.057251010541102],
            [110.375198080230703, -7.057024875514703],
            [110.375154243574002, -7.05679868140598],
            [110.37512613072775, -7.056662962515579],
            [110.374993965809381, -7.056472831826208],
            [110.374759891195993, -7.056318746301289],
            [110.374365951227446, -7.056164446464535],
            [110.373982763557109, -7.056010160734629],
            [110.37342898310105, -7.055837554970156],
            [110.372971344374477, -7.055710304780454],
            [110.372673178648839, -7.05566467718236],
            [110.37242823146876, -7.055601030108632],
            [110.372076969311493, -7.055482967467527],
            [110.372024232245053, -7.05545576030242],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 90,
        id: 92,
        area: 3160437.2857980002,
        perimeter: 12186.417649000001,
        kode_pos: 50228,
        kode_kec: 3374020,
        kode_kel: 3374020008,
        kec: "Gunungpati",
        kel: "Ngijo",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.372605376387256, -7.0646104983274],
            [110.372648738216711, -7.064565329615299],
            [110.372714161280499, -7.06452019057539],
            [110.372849215094107, -7.064457054354962],
            [110.372971006656186, -7.064366764027318],
            [110.373165626655975, -7.064195162862816],
            [110.37326644305783, -7.064131980485763],
            [110.373374038116282, -7.064077852604393],
            [110.373518380454172, -7.064050910298077],
            [110.373733460916711, -7.064024062945898],
            [110.373938318568662, -7.064024338006305],
            [110.374210506779747, -7.064033748744595],
            [110.374361627780601, -7.064015860681186],
            [110.374472593822361, -7.06397982788194],
            [110.374539738671203, -7.06391660004453],
            [110.374566679937416, -7.06387140910808],
            [110.374570148297465, -7.063817141277418],
            [110.37456343036834, -7.063762859786005],
            [110.374536695751686, -7.063654278967892],
            [110.374489920155881, -7.063509489615678],
            [110.374406017061943, -7.06340987752896],
            [110.374322348319851, -7.063346447398041],
            [110.374194797698635, -7.063301049202538],
            [110.374070310923258, -7.063291836738244],
            [110.373976407315098, -7.063264574466648],
            [110.373848598141464, -7.063201085008712],
            [110.373540633792089, -7.063074035690886],
            [110.373412590490574, -7.062974364159958],
            [110.373329302343876, -7.062838571083859],
            [110.373281444099106, -7.062657598542713],
            [110.373262373879029, -7.062530937151804],
            [110.373262593082657, -7.062368120030235],
            [110.37325947487966, -7.061952026883469],
            [110.37326021126087, -7.061825392101803],
            [110.37327394831685, -7.061499775713825],
            [110.37331270968096, -7.06129178329787],
            [110.373351653608793, -7.060948109928522],
            [110.373384068232085, -7.060830563098795],
            [110.373454668986042, -7.060722112959184],
            [110.373641105954846, -7.06053240960666],
            [110.373975239576907, -7.060134859830662],
            [110.374097434266787, -7.059954115472699],
            [110.374110288592121, -7.059863678570906],
            [110.374101404389734, -7.059737030852155],
            [110.374064200885314, -7.059628435975783],
            [110.37399869025468, -7.05952884853858],
            [110.373914492806122, -7.059438281437343],
            [110.373802295530396, -7.059338631337402],
            [110.373657263980107, -7.059247982559695],
            [110.373455949859007, -7.059139167352765],
            [110.373306096131515, -7.059057557415576],
            [110.373170365584741, -7.058994057231025],
            [110.373086414821628, -7.058930626571187],
            [110.373034781191848, -7.058822012250541],
            [110.373011761991165, -7.058686300130169],
            [110.37302125760516, -7.058568722517305],
            [110.373082298651141, -7.058415032492462],
            [110.373246345267773, -7.058252435377128],
            [110.373339027795737, -7.05813496945047],
            [110.373488470374113, -7.057890943896708],
            [110.373603590858337, -7.057710190120789],
            [110.373622940834451, -7.057628807358346],
            [110.373623013809663, -7.057574534969481],
            [110.373599884999635, -7.057520231444614],
            [110.37356173559391, -7.057483998589516],
            [110.373504345693163, -7.057447739908017],
            [110.373397415477996, -7.057429505548263],
            [110.373240403387271, -7.057411203920716],
            [110.37306081629977, -7.057347644853066],
            [110.372856637108654, -7.057265961854129],
            [110.372606447050259, -7.057102808216783],
            [110.37209818150599, -7.056749353739088],
            [110.37189330026267, -7.056559124466736],
            [110.371840252397504, -7.056450508147314],
            [110.371817762915555, -7.05634193294337],
            [110.371832868903155, -7.05626054456202],
            [110.371920531421992, -7.056088799676233],
            [110.372015526126262, -7.055935155454335],
            [110.372099276000256, -7.05572722360829],
            [110.372092029067559, -7.055645805148556],
            [110.372039029997836, -7.055501007267823],
            [110.372024232245053, -7.05545576030242],
            [110.372076969311493, -7.055482967467527],
            [110.37242823146876, -7.055601030108632],
            [110.372673178648839, -7.05566467718236],
            [110.372971344374477, -7.055710304780454],
            [110.37342898310105, -7.055837554970156],
            [110.373982763557109, -7.056010160734629],
            [110.374365951227446, -7.056164446464535],
            [110.374759891195993, -7.056318746301289],
            [110.374993965809381, -7.056472831826208],
            [110.37512613072775, -7.056662962515579],
            [110.375154243574002, -7.05679868140598],
            [110.375198080230703, -7.057024875514703],
            [110.37519777695195, -7.057251010541102],
            [110.375219297103484, -7.057450038525038],
            [110.375263279394431, -7.057567687817772],
            [110.375295931623697, -7.057694367361135],
            [110.375317856070893, -7.057802941711082],
            [110.375350888311075, -7.057857258422556],
            [110.375383673996296, -7.057884438550339],
            [110.375504493182703, -7.057884600218681],
            [110.375614596685864, -7.057857611267974],
            [110.375834557071201, -7.05777649670109],
            [110.375955388357596, -7.057767612848143],
            [110.375999516264983, -7.057776717262776],
            [110.376043312761993, -7.057822002904188],
            [110.376065297968069, -7.057885350222965],
            [110.376065188926972, -7.057966758843244],
            [110.376020770197329, -7.058174744084138],
            [110.375998789234629, -7.058319441388277],
            [110.375998643821674, -7.058427986211856],
            [110.376042282851003, -7.058590862083197],
            [110.376162847818833, -7.058780977041838],
            [110.376316542211811, -7.058953045415811],
            [110.376536952309252, -7.059170429977865],
            [110.376668564771123, -7.059351514172358],
            [110.376800213644941, -7.059505462128581],
            [110.376921146807931, -7.059632259488292],
            [110.376975930209923, -7.059713741408971],
            [110.37699787931102, -7.059804224907109],
            [110.376997721999018, -7.059921815147626],
            [110.376920408555577, -7.060184029069234],
            [110.376820976449068, -7.060482395100858],
            [110.376809808213906, -7.06058187978979],
            [110.376809747684604, -7.060627106802317],
            [110.37688657711378, -7.060726709022332],
            [110.377018299027398, -7.060826384531818],
            [110.37716103188292, -7.06094416554641],
            [110.377237849340361, -7.061052813121472],
            [110.377314630541434, -7.061188596895989],
            [110.377402884472758, -7.061631940229302],
            [110.377468618606002, -7.061776754663059],
            [110.377545460447536, -7.061867311395367],
            [110.377688205837799, -7.061976046879573],
            [110.377863785769449, -7.062075780697843],
            [110.37797376933888, -7.062139245309463],
            [110.378039624562533, -7.062193605641486],
            [110.378105395216707, -7.062311283794089],
            [110.378127320453373, -7.062419858067186],
            [110.378126921692413, -7.062718356399833],
            [110.378148624411267, -7.062781703275089],
            [110.378181669384546, -7.062826974430532],
            [110.378236501675417, -7.062872274627986],
            [110.378302393271767, -7.062899498720399],
            [110.378401426513321, -7.062899630711708],
            [110.37862126824173, -7.062908969062013],
            [110.37880793215929, -7.062972535650363],
            [110.378917891939864, -7.063054090877085],
            [110.379005709075045, -7.063189889128065],
            [110.379093170909556, -7.063379959421798],
            [110.379202829223615, -7.063687649729733],
            [110.37933434741133, -7.063941096584344],
            [110.37947661794621, -7.064194557718919],
            [110.379529915167254, -7.064330309961905],
            [110.379567945006002, -7.064456996466186],
            [110.379575391798099, -7.064601733122723],
            [110.379575271207003, -7.064692187179829],
            [110.37953690367452, -7.064818772033127],
            [110.379490920498483, -7.064927255903461],
            [110.379444961423786, -7.065017648956212],
            [110.379383771094538, -7.065071840045045],
            [110.37925377478031, -7.065162121213985],
            [110.379108571338449, -7.065198109567755],
            [110.379047417159498, -7.065225164392817],
            [110.379009170143107, -7.065261295138642],
            [110.378986166400679, -7.06532458244479],
            [110.378991114705244, -7.065433134081337],
            [110.379067933265404, -7.065541781436323],
            [110.379192878148828, -7.065632402022572],
            [110.379298678748256, -7.065650633725816],
            [110.379500756044635, -7.065614721017606],
            [110.379597008589485, -7.065578667418277],
            [110.379654731167989, -7.065578744221667],
            [110.379712700526738, -7.06560595761109],
            [110.379808772212954, -7.065705585052333],
            [110.379962385744705, -7.0659409703332],
            [110.380087198355767, -7.066131090169117],
            [110.380154360692401, -7.066266860800133],
            [110.380192649671926, -7.066411638460023],
            [110.380230902522626, -7.066583552336232],
            [110.380278799949664, -7.066737388187606],
            [110.380300466963604, -7.066827871212136],
            [110.380449842909186, -7.066846160604944],
            [110.380551706577435, -7.066846295973352],
            [110.380639151603177, -7.066837366740129],
            [110.380748667088554, -7.066828466808742],
            [110.380930938023639, -7.06679252720586],
            [110.381229575466776, -7.066702469459086],
            [110.38136091450788, -7.066666462074702],
            [110.381437324268973, -7.066657518043095],
            [110.381553901552948, -7.066657672711835],
            [110.381674145227322, -7.066666877638895],
            [110.381954481133278, -7.066721521932581],
            [110.382176823421389, -7.066767043782032],
            [110.382398906823013, -7.066794474334801],
            [110.38249369664986, -7.066794599906126],
            [110.382563056568273, -7.066767555499632],
            [110.382752497274751, -7.06665926125629],
            [110.382988980334829, -7.06649675663173],
            [110.38309202376945, -7.066460711292282],
            [110.383555838894111, -7.066208052845926],
            [110.38375600747014, -7.066117863193847],
            [110.383876310988924, -7.066081840482167],
            [110.383967729197167, -7.066063870428614],
            [110.384110621142881, -7.066064059215055],
            [110.384253772105637, -7.066082339157768],
            [110.384447842906809, -7.066109731719683],
            [110.384604292763285, -7.066128029115623],
            [110.384747467700294, -7.066128218082081],
            [110.384901948860445, -7.06613746735155],
            [110.385032095948262, -7.066146684472667],
            [110.385199875988206, -7.066155951186358],
            [110.385350666821793, -7.066174240872926],
            [110.38553341958449, -7.066201618069729],
            [110.385645469572211, -7.066201765738494],
            [110.385751011608306, -7.066201904805912],
            [110.385877516160221, -7.066183980599842],
            [110.385985062752837, -7.066166031390822],
            [110.386086667294535, -7.066148074331778],
            [110.386148929220653, -7.066139110882435],
            [110.386262111024337, -7.066139259891934],
            [110.386265354770956, -7.066039764406686],
            [110.386282371218513, -7.065795560132011],
            [110.386299718311037, -7.065515174557569],
            [110.386338135176018, -7.06513531694727],
            [110.386381043429466, -7.064782601531723],
            [110.386394014878888, -7.064601709939891],
            [110.38644086050347, -7.064267090550481],
            [110.386504707077663, -7.063914402643999],
            [110.386572630928086, -7.063688356150752],
            [110.386640224071726, -7.063498490940628],
            [110.386725631263928, -7.063317694572985],
            [110.386839911477523, -7.063127890706872],
            [110.387026156845835, -7.062865817907653],
            [110.387267118968737, -7.062522407976264],
            [110.38752504595486, -7.062188065596255],
            [110.387674411598837, -7.061998307530034],
            [110.387733643749598, -7.061926021786598],
            [110.38787441362706, -7.061817661287624],
            [110.38798375199687, -7.061727350378882],
            [110.388133942382964, -7.061555684089655],
            [110.388306828214482, -7.061338820312337],
            [110.388434111153671, -7.061158078429767],
            [110.388557185353378, -7.060950194672759],
            [110.388661831971916, -7.060769423041745],
            [110.388731309427456, -7.060651923373638],
            [110.388763342293871, -7.060606738147339],
            [110.388854300246209, -7.060507357473046],
            [110.389063367473312, -7.060317677050931],
            [110.389304396134293, -7.060137083791357],
            [110.389563804566379, -7.059965559875365],
            [110.389991132999697, -7.059694755496626],
            [110.390273177007472, -7.059523260730137],
            [110.390395824904061, -7.059423921110837],
            [110.390632408251221, -7.05918000317373],
            [110.390816279762603, -7.058999334386193],
            [110.390969839964626, -7.058845762285837],
            [110.391167823253824, -7.058692248079979],
            [110.391279117273768, -7.058620029708965],
            [110.391440222308887, -7.058538830818174],
            [110.391647436348222, -7.05846673741559],
            [110.391873902746781, -7.058385623556926],
            [110.392022227632694, -7.058340589575401],
            [110.39233808253131, -7.05827768280413],
            [110.392426386546717, -7.058259706874524],
            [110.392571634289652, -7.058187532367442],
            [110.392719970855012, -7.058133452722013],
            [110.392832805598601, -7.058115508628799],
            [110.392830274189663, -7.058169778012497],
            [110.392823695594927, -7.058224042130943],
            [110.392795329841846, -7.058278277904566],
            [110.392678601090765, -7.05839571682195],
            [110.392620219000463, -7.058468004413057],
            [110.392542289732447, -7.058558357444228],
            [110.3924793567991, -7.058648729981048],
            [110.392460045387892, -7.058702977514158],
            [110.392425159902913, -7.058766250215963],
            [110.392379769842051, -7.058856645572889],
            [110.392362144310653, -7.058919940739849],
            [110.392347006321401, -7.059028466369613],
            [110.392331962737416, -7.059064628561132],
            [110.392305860474579, -7.059118867241323],
            [110.392295037537536, -7.059173125816844],
            [110.392279616568871, -7.059281651072741],
            [110.392258006086024, -7.059363031932002],
            [110.392225891002994, -7.059471535441921],
            [110.392217921162583, -7.059507706840143],
            [110.392229392295064, -7.059607221671303],
            [110.392291900491486, -7.059842484641546],
            [110.392346344795271, -7.059969191769561],
            [110.392361848091298, -7.060014439181952],
            [110.392365762195993, -7.06005062605762],
            [110.392365691379212, -7.060104898633185],
            [110.392322529352754, -7.060222433202482],
            [110.392216610744754, -7.06051174946321],
            [110.392176855860455, -7.060620243004888],
            [110.392157497082124, -7.060710672225619],
            [110.39211240151019, -7.060792022468499],
            [110.392093030900128, -7.060891497112702],
            [110.392096320034454, -7.060972910393112],
            [110.392131311571617, -7.061045319536197],
            [110.392208144558523, -7.061144919520769],
            [110.392237217750264, -7.061199230059593],
            [110.392246731848729, -7.061280651449613],
            [110.392246578354587, -7.061398242021049],
            [110.392223765080374, -7.061533893958863],
            [110.392204677401352, -7.061633368972597],
            [110.392185353996553, -7.061696661902472],
            [110.392182182477498, -7.06174188498874],
            [110.392185259523757, -7.061769025329003],
            [110.392268482452678, -7.061959088075042],
            [110.392309946560829, -7.062058641973993],
            [110.392338701440181, -7.062140088426928],
            [110.392351351652053, -7.062203423012466],
            [110.392354617207829, -7.062302927147934],
            [110.392347991230523, -7.062393372953782],
            [110.392335163906566, -7.062465719792938],
            [110.392345550501872, -7.062529051429535],
            [110.392439573036043, -7.062682946450064],
            [110.39250232952142, -7.062728255408732],
            [110.392570473925915, -7.062764525932616],
            [110.392758849692129, -7.062819043879307],
            [110.392842839492886, -7.062855334994897],
            [110.392895103192274, -7.062918721135589],
            [110.392947343312812, -7.063000198129697],
            [110.393010017326191, -7.063108825031601],
            [110.393041259983789, -7.063235501913669],
            [110.393062068772167, -7.063335028882939],
            [110.393103698164694, -7.063525037390539],
            [110.393145221440179, -7.063796454758143],
            [110.393145115294203, -7.063877863620215],
            [110.393119001009708, -7.063941147748886],
            [110.393061195917085, -7.064004390632133],
            [110.393008802318235, -7.064040504217778],
            [110.392952176205526, -7.064067566843269],
            [110.392895573685195, -7.064076538604087],
            [110.39286585175644, -7.064085545354252],
            [110.392836106225772, -7.064112642959413],
            [110.392806325288348, -7.064166876847628],
            [110.392785351312853, -7.064193985869556],
            [110.392737744280282, -7.064248196540675],
            [110.392704874437456, -7.064284335510861],
            [110.392681035501639, -7.0643385771266],
            [110.392663091372071, -7.064429008191697],
            [110.392627120449717, -7.064673188312533],
            [110.392600015507, -7.064845016428511],
            [110.392564186188864, -7.064980651402466],
            [110.392499000002587, -7.065279066096037],
            [110.392450637946368, -7.065478002823189],
            [110.392444294844097, -7.065568448984626],
            [110.39243795122843, -7.065875985766481],
            [110.392425465263216, -7.066120196441947],
            [110.392407037219471, -7.066364399369254],
            [110.392400965224098, -7.06646389132056],
            [110.392377373062374, -7.066762360162784],
            [110.39233040189319, -7.066979389544475],
            [110.39224590394771, -7.067114961014924],
            [110.392170743482438, -7.06725054464088],
            [110.392123713049756, -7.06751280111472],
            [110.392029617307529, -7.06784735971805],
            [110.391954220159604, -7.068163851798873],
            [110.391869556295518, -7.068426059119601],
            [110.39175679684088, -7.068751547829516],
            [110.391709235532957, -7.068986667199162],
            [110.391634002550276, -7.069393613792184],
            [110.391530627564208, -7.069682932893923],
            [110.391521808557002, -7.069719103136531],
            [110.391401785130171, -7.070189309237514],
            [110.39129823216615, -7.070614309601592],
            [110.391206929071572, -7.070976007856376],
            [110.391103553391744, -7.071265326791084],
            [110.391054742467887, -7.071373808252255],
            [110.391038566473824, -7.07140996886092],
            [110.391008254425799, -7.07143706555214],
            [110.390947677717762, -7.071455077237835],
            [110.390838750742603, -7.071445889382718],
            [110.390651220734739, -7.071391371511034],
            [110.390326798463505, -7.071291447258315],
            [110.390128539927289, -7.071218824272915],
            [110.390007493324234, -7.071173438617877],
            [110.389931967336949, -7.071155248852226],
            [110.389877874925588, -7.071191359762143],
            [110.389823994237219, -7.071281743559449],
            [110.389734777412215, -7.071562035217129],
            [110.389592728344113, -7.071996030025669],
            [110.389442437541277, -7.072457150263084],
            [110.389284458979702, -7.072954442076526],
            [110.389170493238851, -7.073334200877173],
            [110.389095104863614, -7.073641646760159],
            [110.389013750441634, -7.07396717565908],
            [110.388959279521302, -7.0740756493974],
            [110.388943067578992, -7.074138946161229],
            [110.38894465829739, -7.074220357151083],
            [110.388908049466323, -7.074301718015452],
            [110.388796784298137, -7.074563889561301],
            [110.388518981113705, -7.075160523411377],
            [110.388479152903685, -7.075241879998394],
            [110.388414509303686, -7.075205613366263],
            [110.388152372000434, -7.075078632938287],
            [110.387624654654431, -7.074860848871135],
            [110.38734009241503, -7.074788111112308],
            [110.386929130794627, -7.074651888780752],
            [110.386509385753541, -7.074524699964132],
            [110.386149050525134, -7.07440663453333],
            [110.385756964266193, -7.074333754101668],
            [110.385403986366995, -7.074215697826238],
            [110.385195258077374, -7.074143058891786],
            [110.384967216806999, -7.074124666926257],
            [110.384343080519272, -7.074069569675833],
            [110.383945865719255, -7.074023817262689],
            [110.383617386104106, -7.07399624634578],
            [110.383399507561677, -7.073995957923504],
            [110.38336780413664, -7.074004961371278],
            [110.383340062138643, -7.074013970062285],
            [110.383258226927452, -7.07405908881524],
            [110.383217173838858, -7.074077125294242],
            [110.383148649694377, -7.074113216231081],
            [110.383083497036054, -7.074167402471052],
            [110.383022010824547, -7.074230638981859],
            [110.382927948245566, -7.07432096858882],
            [110.38282424098638, -7.074429376237961],
            [110.382741189717294, -7.074537811239816],
            [110.382716771100434, -7.074601096834058],
            [110.38269118459101, -7.074691517147371],
            [110.382650222118571, -7.074854280457307],
            [110.382594545707434, -7.075017024250715],
            [110.382543727647587, -7.075143592784839],
            [110.382533974797937, -7.075243079500981],
            [110.382536082218749, -7.075360672789504],
            [110.382539303931068, -7.075704403117734],
            [110.382440027433788, -7.075885179894771],
            [110.382383598107594, -7.075975559263672],
            [110.382289486929039, -7.076102070317356],
            [110.38219057739866, -7.076219529557817],
            [110.381769345828047, -7.076571741802721],
            [110.381658599873717, -7.076653003506561],
            [110.381369552503372, -7.076761164535154],
            [110.381226338239856, -7.076788110458554],
            [110.380870102755523, -7.076778591398424],
            [110.380389635510952, -7.076777952151478],
            [110.380221249778458, -7.076795818838446],
            [110.379989962264034, -7.076876919616293],
            [110.379818072283882, -7.076976190242842],
            [110.379440276393638, -7.07721991302366],
            [110.379202642536015, -7.077391459081246],
            [110.379079680290204, -7.077508885484925],
            [110.378822412972937, -7.077761813870819],
            [110.378598263952554, -7.078005740888899],
            [110.378329087502337, -7.078276743879428],
            [110.378130440849972, -7.07849356844485],
            [110.377972564727258, -7.078692356573193],
            [110.377916122074566, -7.078791780681812],
            [110.377832676846396, -7.078981622816682],
            [110.377737335021038, -7.079180494435221],
            [110.377662487597121, -7.079288939304316],
            [110.377595082013556, -7.079334076221523],
            [110.377413311222313, -7.079415241787055],
            [110.37725962640495, -7.079442172388435],
            [110.377002636126164, -7.079487055471287],
            [110.376357324569014, -7.079603781443102],
            [110.376078533914168, -7.079657680193223],
            [110.37559001956275, -7.079747479039271],
            [110.375194082405173, -7.079801220035311],
            [110.374987483454618, -7.079828078812093],
            [110.374629168000524, -7.079890915253594],
            [110.374099328567226, -7.079989702353918],
            [110.373688345336248, -7.08007960342034],
            [110.373555033475313, -7.080106560181207],
            [110.373485105166736, -7.080133602249172],
            [110.373428193119224, -7.080160661833387],
            [110.373288546178074, -7.080269018630973],
            [110.373199586087537, -7.080350307448506],
            [110.373043983214046, -7.080540051310025],
            [110.372891468366419, -7.080747890077864],
            [110.372761110016711, -7.080892440824295],
            [110.37263337158609, -7.080982722636056],
            [110.372484164606462, -7.081045839241749],
            [110.372363585752097, -7.081072812803724],
            [110.372328902964938, -7.081081811417651],
            [110.372324351744425, -7.081000396647793],
            [110.372316858492042, -7.080891841698623],
            [110.372287477584109, -7.080647576169637],
            [110.372287807752372, -7.080403350722123],
            [110.37228476853501, -7.080349074201038],
            [110.372271567128934, -7.080276693163347],
            [110.372265095679865, -7.080249548222296],
            [110.372238816868261, -7.080222376558741],
            [110.372146607841415, -7.08019511593689],
            [110.371886626664818, -7.080149538048979],
            [110.371609975262274, -7.080085846702887],
            [110.371491709301964, -7.080076641571615],
            [110.371425779109671, -7.080076552513918],
            [110.371396112721996, -7.080085557839985],
            [110.371403781527405, -7.080022250384988],
            [110.371443764900192, -7.079958986581087],
            [110.371544068154378, -7.079859622633064],
            [110.371614291710017, -7.079823535858893],
            [110.371789838317596, -7.079742364259031],
            [110.371869965508708, -7.079706290812765],
            [110.371914993168957, -7.079679215381094],
            [110.371935169106195, -7.079615924790501],
            [110.371935242508314, -7.079561652470332],
            [110.371920355631744, -7.0794802237489],
            [110.371820505257801, -7.079244908491138],
            [110.371760454667822, -7.079082010172352],
            [110.371755705501755, -7.079036776745646],
            [110.371765682572985, -7.078982517795291],
            [110.371805960993711, -7.078910208940474],
            [110.371845944205646, -7.078846945085751],
            [110.371866058937528, -7.078828881427939],
            [110.371921224190032, -7.078838001283544],
            [110.371961072829734, -7.078874236674189],
            [110.372026068358622, -7.078937642205401],
            [110.372081196939419, -7.078973898204986],
            [110.372146265870299, -7.078983031398105],
            [110.372276428194127, -7.078983206982672],
            [110.372401792397824, -7.078974330656096],
            [110.372501997462251, -7.078947329564163],
            [110.372547590936648, -7.078920254823728],
            [110.372567766747991, -7.078856964193166],
            [110.37257297003454, -7.078775562566877],
            [110.372567986706983, -7.078694147207162],
            [110.372578012551855, -7.078603706676841],
            [110.372613197510475, -7.078531390870678],
            [110.3726234940833, -7.078449996107436],
            [110.372613466316565, -7.078332392327558],
            [110.372593583786625, -7.078178593643782],
            [110.372578660160002, -7.078124301097517],
            [110.372563712098739, -7.078088099327701],
            [110.372523875689026, -7.078042818600925],
            [110.372453762461362, -7.077997497051247],
            [110.372293692112748, -7.077933963376029],
            [110.372238588079043, -7.077879616633655],
            [110.37215353911175, -7.07778904787773],
            [110.372088604847832, -7.077680415435379],
            [110.372048583382764, -7.077562771191269],
            [110.372063861531529, -7.077354747504512],
            [110.37205426359327, -7.077128599449338],
            [110.372059183962833, -7.077047197447322],
            [110.372139703640201, -7.07692971579679],
            [110.372244804148892, -7.076839403490205],
            [110.37236011562608, -7.07673101411281],
            [110.372440586295355, -7.076649713951996],
            [110.372480544768209, -7.076604540789521],
            [110.372505825959081, -7.076532211623852],
            [110.372525767550314, -7.076432739042895],
            [110.372531510281007, -7.07616138461878],
            [110.372551930250069, -7.075917186187993],
            [110.372582229343095, -7.075691091876251],
            [110.372607522670037, -7.075609717307068],
            [110.372642707356377, -7.075537401469087],
            [110.372697711404626, -7.075456066923556],
            [110.372752998392329, -7.075374732750733],
            [110.372838242426198, -7.075320575139536],
            [110.37295856119411, -7.075275510152803],
            [110.373143702034227, -7.075212441591113],
            [110.373340690524628, -7.075176525118108],
            [110.373451056855686, -7.075167628231904],
            [110.373526016685204, -7.075185819906333],
            [110.373586262635541, -7.075203991774723],
            [110.373601198512731, -7.075249238906274],
            [110.37361612219901, -7.075303531428796],
            [110.373625952618269, -7.07535781709932],
            [110.373660978625523, -7.075403091255429],
            [110.373701085718366, -7.075457417651804],
            [110.373776021225609, -7.075493700073697],
            [110.37394119647044, -7.075548194646419],
            [110.374031448389943, -7.075557361399549],
            [110.3740863669194, -7.075539344412973],
            [110.374131677003561, -7.075512269095537],
            [110.374196842981078, -7.075449038826518],
            [110.374297181180467, -7.075322537951275],
            [110.374342244810379, -7.075268326052277],
            [110.374387259716229, -7.075250295718455],
            [110.374427513019072, -7.07519607734641],
            [110.374472661856657, -7.0750785476822],
            [110.374492885903635, -7.074979075348441],
            [110.374492995485681, -7.074897666811949],
            [110.374478217813049, -7.07473482959303],
            [110.374428316699493, -7.074599081418531],
            [110.374418312822556, -7.0744633868394],
            [110.374419307814932, -7.074354843263514],
            [110.374444354303407, -7.074246331995763],
            [110.374484619677389, -7.074183068215025],
            [110.374574673605167, -7.074128916707293],
            [110.374620056551137, -7.074047568965148],
            [110.374630081820456, -7.073957128328809],
            [110.374585371354513, -7.073749023860903],
            [110.374500480830193, -7.073540865428841],
            [110.374435461274558, -7.073495551052034],
            [110.374395342064332, -7.073450270115059],
            [110.374370514785184, -7.073395964307265],
            [110.374355712806263, -7.073251217868729],
            [110.374340959534749, -7.073070289856057],
            [110.374350936119356, -7.07301603079868],
            [110.37438610813966, -7.072952760173389],
            [110.374461189398417, -7.072880497734159],
            [110.374526662235368, -7.072799176967912],
            [110.374576584535475, -7.072708789900173],
            [110.374605345825586, -7.072573147362029],
            [110.374618471406833, -7.072491756290755],
            [110.374587096545639, -7.072256533494798],
            [110.374619250195622, -7.071912851060531],
            [110.374607585011603, -7.071750018007866],
            [110.374618507736599, -7.071623396920611],
            [110.374651205986225, -7.071505850473264],
            [110.374694324966114, -7.071424499652585],
            [110.374753818946004, -7.071370307036545],
            [110.374851241318979, -7.071307119896746],
            [110.374899712104053, -7.07124386705585],
            [110.374916220898996, -7.071171525914729],
            [110.37491628170379, -7.071126298938999],
            [110.374899943168643, -7.071072004548683],
            [110.374846254330279, -7.071017660041983],
            [110.374738249957389, -7.070954197228079],
            [110.374549283474664, -7.070917761944799],
            [110.374441501278497, -7.070899526419429],
            [110.374213584214004, -7.070790675421433],
            [110.374062570579824, -7.070727154693378],
            [110.373997834398295, -7.070681840668514],
            [110.373959918211895, -7.070681789723457],
            [110.373868228089222, -7.070690711923632],
            [110.373776537964616, -7.070699634105448],
            [110.373679225258471, -7.070681412482007],
            [110.373555339016136, -7.070645064292041],
            [110.373474203609831, -7.070590682740955],
            [110.373404152283598, -7.07050013444695],
            [110.373361301369542, -7.070382486499599],
            [110.373361362318704, -7.070337259535837],
            [110.373377600536614, -7.070255872691293],
            [110.373453344879294, -7.070111247998812],
            [110.373550654766959, -7.069921425234334],
            [110.373572649531042, -7.069767682838688],
            [110.373583782291789, -7.069695334526505],
            [110.373588924236543, -7.069659159799563],
            [110.373578293752814, -7.069568691411229],
            [110.373508376580219, -7.069378643813644],
            [110.373438410713547, -7.069224777782392],
            [110.373389852018619, -7.069143303805333],
            [110.373260121653402, -7.069034584438201],
            [110.372962449930313, -7.06882613960923],
            [110.372918984555426, -7.068744672445311],
            [110.372897294559166, -7.068672279988151],
            [110.372890342635586, -7.068581816543648],
            [110.372901536433986, -7.068464241287566],
            [110.372949328991055, -7.068274351997569],
            [110.37301802359363, -7.068111627043983],
            [110.373086988907957, -7.067957947848702],
            [110.373114569628228, -7.067858485438419],
            [110.373199133943871, -7.067677690968392],
            [110.37325992999925, -7.067496864518391],
            [110.37327453129582, -7.06737024840899],
            [110.373274653158177, -7.06727979447122],
            [110.373256702492995, -7.067162180003971],
            [110.373220076846465, -7.067044540426714],
            [110.373154565503086, -7.066944952826333],
            [110.373066761749328, -7.066800108188975],
            [110.372993930234571, -7.066673374496513],
            [110.372957280301733, -7.066573825692443],
            [110.372950882078683, -7.066492408397205],
            [110.372958187553962, -7.06632055543376],
            [110.37294401291048, -7.066130582758126],
            [110.372908111793478, -7.065895353808718],
            [110.372886616949273, -7.065678235054746],
            [110.372872466713289, -7.065470171588458],
            [110.372836258318941, -7.065253033041664],
            [110.372761212219174, -7.06509011471173],
            [110.372622279753855, -7.064881883370956],
            [110.372583354191534, -7.064791376897348],
            [110.372583451753059, -7.06471901374879],
            [110.372587769216764, -7.064664747097885],
            [110.372605376387256, -7.0646104983274],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 91,
        id: 93,
        area: 2679027.906825,
        perimeter: 9196.087105000001,
        kode_pos: 50223,
        kode_kec: 3374020,
        kode_kel: 3374020011,
        kec: "Gunungpati",
        kel: "Cepoko",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.34991801973915, -7.06354817292984],
            [110.350607897248054, -7.063721072857884],
            [110.351406403527847, -7.063912136903546],
            [110.352101802939032, -7.064184464778273],
            [110.352166561049401, -7.064211690869667],
            [110.352964402525387, -7.064475114464213],
            [110.35362201395553, -7.064656934011295],
            [110.35407479386258, -7.064820378226237],
            [110.354236296669754, -7.06486582880795],
            [110.354882918859076, -7.065219493659111],
            [110.355357204229506, -7.065382966467472],
            [110.355864004465218, -7.065564574505189],
            [110.355960969536639, -7.065628026123478],
            [110.357125352413391, -7.065810540730715],
            [110.357836986872869, -7.066010519893035],
            [110.358235863122715, -7.066074386821199],
            [110.358451756225548, -7.066074683955781],
            [110.358645787148006, -7.066129223230123],
            [110.35884619267506, -7.066075219590593],
            [110.35888831526853, -7.066102420646667],
            [110.358915416440141, -7.066147684844495],
            [110.358942467765104, -7.066229130517179],
            [110.358954447805019, -7.066364827782209],
            [110.358952767605373, -7.066762822457202],
            [110.358959301763505, -7.067359826908691],
            [110.358966060246928, -7.067794014715091],
            [110.358928553167615, -7.068318595484474],
            [110.358846472433228, -7.06895165953093],
            [110.358816608672939, -7.069268206912963],
            [110.358808940065998, -7.069494330979313],
            [110.358808690635456, -7.06967523832865],
            [110.358808391310149, -7.069892327145996],
            [110.358830429076519, -7.070326535924498],
            [110.35882351803906, -7.070824022549441],
            [110.358860310853984, -7.071023071634037],
            [110.358891261396778, -7.071149749604009],
            [110.35903330148922, -7.071357988883983],
            [110.359329623962225, -7.071720211870799],
            [110.359477901921707, -7.071919414237041],
            [110.359558357626867, -7.072055205630448],
            [110.359607713056008, -7.072172863489017],
            [110.359625880956756, -7.072335705395323],
            [110.359644244598471, -7.072561865264603],
            [110.35966208488766, -7.073167930574708],
            [110.359673803444196, -7.073493580534222],
            [110.359704417856818, -7.073864483392656],
            [110.35971545701814, -7.074000179337824],
            [110.359722498572509, -7.074090642863955],
            [110.359759363023045, -7.074443463540927],
            [110.359784626213781, -7.074796268264545],
            [110.35980848708806, -7.075140025672669],
            [110.359840911509124, -7.075429522546691],
            [110.35988155422443, -7.075709985333273],
            [110.359954799570531, -7.075945266017645],
            [110.359987460840046, -7.076062900911452],
            [110.359901711764905, -7.076071828410138],
            [110.359790879037277, -7.076008358329979],
            [110.359719623210893, -7.075972078806308],
            [110.35967975091657, -7.075953933203372],
            [110.359639853692244, -7.075953878330246],
            [110.359586644925869, -7.075962850527139],
            [110.359515351717633, -7.07595370707298],
            [110.359435607154467, -7.075917415830655],
            [110.359342280573856, -7.075881105886649],
            [110.359284594363501, -7.075853890355975],
            [110.359244734573423, -7.075826699352304],
            [110.359133656474768, -7.075736092641947],
            [110.359095469503558, -7.075726994695127],
            [110.359043970989788, -7.075726923802979],
            [110.358997012297166, -7.075717813772719],
            [110.358956291181045, -7.075699666943442],
            [110.358943607952142, -7.075663467950575],
            [110.358930629286291, -7.075636313933233],
            [110.358896403751729, -7.075627221425474],
            [110.358845188209841, -7.075627150901952],
            [110.358800210244311, -7.075618043580588],
            [110.358721006752944, -7.075599843734864],
            [110.358682536845052, -7.075590745366134],
            [110.358648298834936, -7.075590698205716],
            [110.358620285917723, -7.075590659618046],
            [110.358566806712574, -7.075590585945998],
            [110.358530575504332, -7.075599581412854],
            [110.358485868020551, -7.07559951981631],
            [110.358440877578431, -7.075599457825582],
            [110.358404375899283, -7.075599407528024],
            [110.358347796640388, -7.075590284177339],
            [110.358308194908417, -7.075581184218609],
            [110.35827170572432, -7.075572088546588],
            [110.358232374461238, -7.075572034335793],
            [110.358202110371678, -7.075562947238851],
            [110.358162804099408, -7.075544802294235],
            [110.358131691137231, -7.07553571402281],
            [110.358094070126555, -7.075526616777177],
            [110.358064925381498, -7.075526576596045],
            [110.358029555545528, -7.075526527830085],
            [110.357986545825312, -7.075526468527059],
            [110.357905053724835, -7.075526356152536],
            [110.357861195129658, -7.075526295667289],
            [110.357817041074753, -7.075535280151041],
            [110.357776012066267, -7.075535223560367],
            [110.357724796545781, -7.075535152914521],
            [110.357680654992677, -7.07553509202187],
            [110.357631407678809, -7.075544069461273],
            [110.357609311891153, -7.075562129737046],
            [110.357575319330408, -7.075589218977167],
            [110.357533378907533, -7.075634388007742],
            [110.357498549968824, -7.075652430707175],
            [110.357448441255485, -7.075670452317024],
            [110.357418164670179, -7.075670410532597],
            [110.357367810525048, -7.075661295655146],
            [110.357338395327972, -7.075652209674733],
            [110.357322870131853, -7.075625052105952],
            [110.357293775432069, -7.075588830426247],
            [110.357264360241331, -7.075579744441669],
            [110.35722334374644, -7.075570642439618],
            [110.357196783172284, -7.075543469632716],
            [110.357173077215435, -7.075498210006622],
            [110.357154734961966, -7.075462003164581],
            [110.357137499516497, -7.075443888609169],
            [110.357090565937483, -7.075416687668159],
            [110.357052096088523, -7.075407589168877],
            [110.357026912776746, -7.075407554393593],
            [110.356979608627526, -7.075443670585204],
            [110.356935146575609, -7.075470745317458],
            [110.356904562009575, -7.075488793833594],
            [110.356851636231255, -7.075497766108939],
            [110.356765371423336, -7.075470510806255],
            [110.356696084140495, -7.075443278945431],
            [110.356654218797431, -7.075434175722447],
            [110.356590270164645, -7.075434087359032],
            [110.356503967807541, -7.075433968093598],
            [110.35643748507789, -7.075424830828673],
            [110.356398424271191, -7.075433822216099],
            [110.356365010104582, -7.075451866783891],
            [110.356292535123686, -7.075478902727033],
            [110.356239892299584, -7.075487875323066],
            [110.356161808270215, -7.075478721978264],
            [110.356092521029723, -7.075451490030712],
            [110.356056339944217, -7.075424303860559],
            [110.356022988448018, -7.075397121601461],
            [110.355978601575188, -7.075369924076756],
            [110.35593393174932, -7.075342726156856],
            [110.355898058717344, -7.075297449647153],
            [110.355867869937867, -7.075234090243943],
            [110.355837913967022, -7.075206912670799],
            [110.355815585335407, -7.075188791025585],
            [110.355772022289145, -7.075179685378013],
            [110.355737451205343, -7.075215819054074],
            [110.355714789452179, -7.075233878451919],
            [110.355683330902295, -7.075270016429942],
            [110.35564254726296, -7.075297096123789],
            [110.355612528583279, -7.075315145334832],
            [110.355592966819017, -7.075342254392853],
            [110.355605083855181, -7.075378452669951],
            [110.355637869406024, -7.075405634173505],
            [110.355663568455114, -7.075441851245437],
            [110.355676804789923, -7.075487096447139],
            [110.355640793793967, -7.075541318874002],
            [110.355603135216157, -7.075559357509777],
            [110.355537180777674, -7.075577356977503],
            [110.355510004207758, -7.075586364737014],
            [110.35547548326079, -7.075586316951648],
            [110.355448589648049, -7.075595325099108],
            [110.355424513072137, -7.075613382521283],
            [110.355397594359644, -7.075640481383642],
            [110.355323528808029, -7.075794150233466],
            [110.355200115396244, -7.076029159108336],
            [110.35510617980681, -7.076228027268025],
            [110.355057555252031, -7.076399822046668],
            [110.354997203747999, -7.076662054321719],
            [110.354918880926576, -7.076824762550686],
            [110.354672291685731, -7.07712291814675],
            [110.354432002658896, -7.077366810073432],
            [110.354245796414304, -7.077583640762744],
            [110.354113452985516, -7.077728183117894],
            [110.354023358553391, -7.077809466451559],
            [110.353915154656605, -7.077890724627769],
            [110.353843144429078, -7.077990123767852],
            [110.353807107843366, -7.078062436720652],
            [110.353721880025887, -7.078306543446026],
            [110.35360755755454, -7.078514428248949],
            [110.353523342091364, -7.078640946486763],
            [110.353469145567232, -7.078749415664118],
            [110.353445213192629, -7.078866972250229],
            [110.353396983978314, -7.078957358961126],
            [110.353318735733069, -7.079065794695182],
            [110.353072477584817, -7.079327768193685],
            [110.352915798645583, -7.079472276288977],
            [110.35285572271934, -7.079535510341626],
            [110.352810656309231, -7.079589719889592],
            [110.352800419292265, -7.079625887133627],
            [110.352796935515542, -7.079689199880856],
            [110.352790018384752, -7.079779643963446],
            [110.352779730931772, -7.0798519926171],
            [110.35276267762427, -7.079906241118767],
            [110.352728904390929, -7.079978557102099],
            [110.352701651861722, -7.080041836782753],
            [110.352687968851441, -7.080114180708222],
            [110.352691200461337, -7.080231775013493],
            [110.352723350117728, -7.080512226206079],
            [110.352745451885028, -7.080693164350265],
            [110.352754832998542, -7.080865039427688],
            [110.352754719511779, -7.08094644759805],
            [110.352728586153717, -7.081018774199596],
            [110.352666774379202, -7.081109141897299],
            [110.352647174342977, -7.081163386843051],
            [110.35260784812283, -7.081362330248842],
            [110.35255183253328, -7.081760248541726],
            [110.352545223480561, -7.08183260229633],
            [110.352551681150487, -7.081868792760271],
            [110.352574538017691, -7.081914051416754],
            [110.35261014080703, -7.08195028244145],
            [110.352977764875376, -7.082113610559247],
            [110.353100186762489, -7.082186143779189],
            [110.353157822877378, -7.082249541517354],
            [110.353184358334786, -7.082294805265332],
            [110.353210547817639, -7.082385295381312],
            [110.353236611277666, -7.0825662390214],
            [110.353273207390117, -7.082701970454983],
            [110.353299422111334, -7.082774369863021],
            [110.35336777310728, -7.082864918590202],
            [110.35342063676849, -7.082901173559402],
            [110.353515391553103, -7.082928441379392],
            [110.353615535243378, -7.082946671299462],
            [110.353636436714083, -7.082973836457191],
            [110.353657608554329, -7.083010047360447],
            [110.353668002611343, -7.083064334027485],
            [110.353673240356173, -7.08316384038194],
            [110.353646452878436, -7.083299483714078],
            [110.353598807497775, -7.08357982397868],
            [110.353566825830455, -7.083787823041487],
            [110.353566737637081, -7.083851140509609],
            [110.353608710223938, -7.083986879400294],
            [110.353703559249666, -7.084149827886006],
            [110.353856095171963, -7.084339992624143],
            [110.353629554542351, -7.08425826947424],
            [110.353271486304635, -7.084140181850692],
            [110.35311591933079, -7.084094738638163],
            [110.3529147952529, -7.084049231989936],
            [110.35282879954876, -7.084031021589427],
            [110.35266666142121, -7.084030795931488],
            [110.352571095448639, -7.083976390689983],
            [110.352332797039892, -7.083804196895275],
            [110.352084852806058, -7.083650080290551],
            [110.351979987087844, -7.083568525881879],
            [110.351836971905016, -7.083450736819486],
            [110.351655895767948, -7.083233395627707],
            [110.351522552062548, -7.083079438497579],
            [110.351312833765505, -7.082907284094598],
            [110.351150514272035, -7.082834694760182],
            [110.350978755883162, -7.082834455131088],
            [110.350682688286426, -7.082897359480929],
            [110.350482274706692, -7.082951351853704],
            [110.350234027710513, -7.083014322680965],
            [110.349622870218994, -7.083185330415541],
            [110.349431781527457, -7.083248380714994],
            [110.349336346944725, -7.083302519394326],
            [110.349259832813033, -7.083383820612854],
            [110.349145118645794, -7.083465068360491],
            [110.349059253986354, -7.083555401816996],
            [110.348925631971028, -7.083600441590444],
            [110.348839560427393, -7.083636502538684],
            [110.348658104908779, -7.083690520618511],
            [110.348543390569048, -7.083771768200126],
            [110.348486117724747, -7.083853096222517],
            [110.348476420784863, -7.083907354805189],
            [110.348476319196934, -7.083979717552215],
            [110.348495767475413, -7.084034016964728],
            [110.348512103160871, -7.084088312016344],
            [110.348555848207894, -7.084169781547113],
            [110.348588583087547, -7.084233144930001],
            [110.348643106165341, -7.084296538829189],
            [110.348725076721834, -7.084359971163546],
            [110.34877453187552, -7.084405267229372],
            [110.348790880296875, -7.084450516930757],
            [110.348790715282846, -7.084568106399203],
            [110.348774163756573, -7.084667582196278],
            [110.348740879272754, -7.084794170641056],
            [110.348718976800811, -7.084875548216782],
            [110.348675041224354, -7.084929758845838],
            [110.348614731822906, -7.084956810449889],
            [110.348560119789525, -7.08495673394447],
            [110.34847812375989, -7.084911392261102],
            [110.348390531979405, -7.084820815919993],
            [110.348297602031934, -7.08470309600219],
            [110.348226667068317, -7.084639679060853],
            [110.348171848346638, -7.084585330061223],
            [110.348106238899973, -7.084558102010262],
            [110.347947597983037, -7.084485516712749],
            [110.347832803754613, -7.084422038218173],
            [110.347745186767952, -7.084349552457362],
            [110.347663216362903, -7.084286119962643],
            [110.347586584136309, -7.084249831022766],
            [110.347531972197345, -7.084249754404207],
            [110.347477077295579, -7.084249677382198],
            [110.347444189944156, -7.084294858031627],
            [110.347389231436239, -7.084340007705105],
            [110.347361952455159, -7.084421377653848],
            [110.347339830415436, -7.084457528042721],
            [110.347323329477149, -7.084520822396318],
            [110.347312475083356, -7.084593170032926],
            [110.34730133772149, -7.084665517271581],
            [110.347301185047883, -7.084774061363003],
            [110.347295643336736, -7.084891643248342],
            [110.347289869550252, -7.084973043372276],
            [110.347256994848294, -7.085009178659957],
            [110.347218731102416, -7.085054351737956],
            [110.347136375885768, -7.085063281474516],
            [110.346923021120048, -7.085062981868893],
            [110.346791751350864, -7.085044706767754],
            [110.346611001134548, -7.084999226033012],
            [110.346457869879501, -7.084945093407984],
            [110.346304666013623, -7.084917387273104],
            [110.34618728703802, -7.084881040820809],
            [110.345997384086857, -7.084853835048935],
            [110.345785059995038, -7.084809359510864],
            [110.345500814219321, -7.08490796254115],
            [110.345173817642305, -7.084867900967422],
            [110.344998728974829, -7.084867654372174],
            [110.344777432417942, -7.084837641701776],
            [110.34439762568266, -7.084764504177448],
            [110.344245741270512, -7.084708188319022],
            [110.34412962472534, -7.084642963936072],
            [110.343989590238138, -7.084538130602692],
            [110.344036954349022, -7.084479231151355],
            [110.344055085145484, -7.084456684665955],
            [110.344123081587867, -7.084333757912643],
            [110.34415719948079, -7.08418760518607],
            [110.344155586015262, -7.084066363185581],
            [110.344118427681423, -7.083838094828546],
            [110.344140016640026, -7.08371688554809],
            [110.344200823343527, -7.083629607372528],
            [110.344365196337279, -7.083508599387213],
            [110.344659762936573, -7.08335831733914],
            [110.344923656812924, -7.083214917094503],
            [110.345165628202068, -7.083039644387106],
            [110.345448535102207, -7.082826705735946],
            [110.345744367609029, -7.082571058925681],
            [110.345864078927136, -7.082414760651941],
            [110.346003657733078, -7.082234322706001],
            [110.346168235705832, -7.081967113362117],
            [110.346297740228479, -7.081768533917154],
            [110.346412702307319, -7.081544480337111],
            [110.346560129276469, -7.081199492934657],
            [110.346675200019277, -7.080898012605968],
            [110.346725458809061, -7.080754520970895],
            [110.346751553284534, -7.080570668937709],
            [110.346748459981256, -7.080473881083103],
            [110.346727699924642, -7.080309319966032],
            [110.346679430413147, -7.080186659745929],
            [110.346631126879387, -7.080088195352513],
            [110.346569891888322, -7.079999391147584],
            [110.34648441097066, -7.079928296528573],
            [110.346384481941413, -7.079879235015823],
            [110.346261661997175, -7.079868300477062],
            [110.346122447618214, -7.079887912236543],
            [110.3459256910443, -7.079943642089924],
            [110.345726990401545, -7.080006272017588],
            [110.345549347158766, -7.08002215278519],
            [110.345413452667557, -7.080013550415179],
            [110.34529264572727, -7.079992756627292],
            [110.345200176827291, -7.079958978198126],
            [110.345131299947766, -7.079902198332975],
            [110.345083762878929, -7.079841224727848],
            [110.345045882994086, -7.079785937935716],
            [110.345020208835891, -7.079671374677856],
            [110.345002699344917, -7.079492300589494],
            [110.345007048198411, -7.079262213918835],
            [110.345007215774231, -7.079143398865906],
            [110.345007520217607, -7.07892753637681],
            [110.345007248657609, -7.078786502191909],
            [110.345034148635293, -7.078695610219736],
            [110.34514148698635, -7.078517467476708],
            [110.345343531750032, -7.078259225712398],
            [110.345535125972091, -7.078005652111856],
            [110.345711930025885, -7.077739125806125],
            [110.345813921073656, -7.077555626526301],
            [110.34590951138297, -7.077382048610574],
            [110.345966081618656, -7.077233128523303],
            [110.346000168095514, -7.077108370733512],
            [110.346028897711989, -7.076985388351725],
            [110.346007832668377, -7.076884691989655],
            [110.345973828182849, -7.076801668338485],
            [110.345898950444791, -7.076742726171162],
            [110.345777733465411, -7.07672155220884],
            [110.345559860700675, -7.07672976903411],
            [110.345383193073388, -7.076711691259993],
            [110.345231539957922, -7.076674036284854],
            [110.345071008239884, -7.076604275887509],
            [110.344960412547053, -7.076561329773885],
            [110.344881952869429, -7.076511297107332],
            [110.344851684621489, -7.07645954931184],
            [110.344825041242714, -7.076368581981327],
            [110.344835878111837, -7.07627766739613],
            [110.344882468256742, -7.076145795547855],
            [110.344909373085443, -7.076051337691959],
            [110.344918498053332, -7.075908715490247],
            [110.344866960897278, -7.075751744429099],
            [110.34479753583868, -7.075623275200522],
            [110.344717470083751, -7.075446651663594],
            [110.344505542725003, -7.075120075717507],
            [110.34433456719195, -7.074863091939633],
            [110.344188555928412, -7.07462218971274],
            [110.344081729128121, -7.074438396655977],
            [110.34398021345288, -7.07423154959036],
            [110.343838114873861, -7.073801544667749],
            [110.343777808005541, -7.073535801964972],
            [110.34369434209367, -7.073239716681583],
            [110.343626818262962, -7.073029234869244],
            [110.343543239203484, -7.07281338160991],
            [110.343459635031707, -7.072615357682855],
            [110.343356338142456, -7.072460096491033],
            [110.343210262559523, -7.072265550336889],
            [110.34308553362888, -7.072120956504842],
            [110.342928661524937, -7.071988797871266],
            [110.342736109524523, -7.071845891226904],
            [110.342554240961661, -7.071720828965338],
            [110.342463339500057, -7.071635119670028],
            [110.342386688364812, -7.071569042787875],
            [110.342340360415889, -7.071515489265142],
            [110.342278025183504, -7.071422688515929],
            [110.34224795730978, -7.071329625780148],
            [110.342235377755529, -7.071290690934121],
            [110.342224896255715, -7.071130211766231],
            [110.342212627483661, -7.070971513011742],
            [110.342168223100572, -7.07081990063431],
            [110.342077410176614, -7.070671788598138],
            [110.341911612567131, -7.070541400018278],
            [110.341727932219356, -7.070435947245337],
            [110.341151930487911, -7.070101723870043],
            [110.340966498605539, -7.069973090167664],
            [110.340838178737883, -7.069844537193973],
            [110.340790111881645, -7.069758888218461],
            [110.34078671930645, -7.06963407782574],
            [110.34085468623941, -7.069530763620539],
            [110.340965408709508, -7.069482780921726],
            [110.341093895252698, -7.069493660239207],
            [110.341225920989231, -7.069525939771725],
            [110.341502384963718, -7.069647570263605],
            [110.341707497065926, -7.069742355749186],
            [110.341900169012547, -7.06979968189649],
            [110.342073284464931, -7.0698034922314],
            [110.3421643242404, -7.0697911402022],
            [110.34225185756307, -7.06973420976637],
            [110.342300191991527, -7.069630867610355],
            [110.342332551927754, -7.069465100085931],
            [110.342409603586063, -7.069247690431586],
            [110.342509924899105, -7.068982174268551],
            [110.342513869260387, -7.068974849582829],
            [110.34254985899031, -7.0689830158018],
            [110.345559319771027, -7.069665864290458],
            [110.346282001337613, -7.069856831301276],
            [110.346757237982345, -7.069947951409962],
            [110.346890509628011, -7.069948138225886],
            [110.346909924910392, -7.069622532340297],
            [110.346986547711523, -7.069260825158276],
            [110.347063081477231, -7.068962435373646],
            [110.347158636521556, -7.068618845385657],
            [110.347330547093279, -7.06810350035298],
            [110.347483127597741, -7.0676514457113],
            [110.347559356217261, -7.067570144125806],
            [110.347635546754788, -7.067515978569813],
            [110.347769058691583, -7.067344303449222],
            [110.347826198570331, -7.067154430703096],
            [110.348035824662418, -7.066982861968418],
            [110.348207213952065, -7.066838375765211],
            [110.348340442522712, -7.066666700043251],
            [110.348416746837145, -7.06653112618559],
            [110.348531017414999, -7.066359423894922],
            [110.348512426898239, -7.066097082243757],
            [110.348474941916422, -7.065789487347064],
            [110.348475423198991, -7.065445764028902],
            [110.34870384967958, -7.065183767472877],
            [110.348894575517107, -7.064767946860536],
            [110.349104756742477, -7.064198382001722],
            [110.349333409838593, -7.063773568686265],
            [110.349485953558698, -7.063547647221041],
            [110.349828349453944, -7.063530033932149],
            [110.34991801973915, -7.06354817292984],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 92,
        id: 94,
        area: 2383030.144605,
        perimeter: 7983.929397,
        kode_pos: 40224,
        kode_kec: 3374020,
        kode_kel: 3374020010,
        kec: "Gunungpati",
        kel: "Nongko Sawit",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.35884619267506, -7.066075219590593],
            [110.358779478091407, -7.066029907880069],
            [110.358604159487584, -7.065948258249869],
            [110.358516543857689, -7.065875774622699],
            [110.358471075750444, -7.065812394362782],
            [110.35838049736185, -7.065631362012518],
            [110.358338474620865, -7.065531804942599],
            [110.358175439535813, -7.065160719751885],
            [110.358145300871428, -7.065061179028191],
            [110.358139508589161, -7.064952626432197],
            [110.358142733349354, -7.064871222403779],
            [110.358173158940545, -7.064762719659553],
            [110.358252206316322, -7.064482421502754],
            [110.358361446003158, -7.064057438688577],
            [110.358447358756621, -7.063722877597752],
            [110.358525877233674, -7.06341544246487],
            [110.358640122215846, -7.063053784113617],
            [110.35871141710598, -7.062854883631376],
            [110.358818488330982, -7.062565578438802],
            [110.35893046921899, -7.062203916852623],
            [110.35895382063643, -7.062095404291445],
            [110.358997128026843, -7.061878374495512],
            [110.359033790526652, -7.061760834828339],
            [110.359060649364423, -7.061570918581807],
            [110.359073851855854, -7.06143525589803],
            [110.35907391411493, -7.061390029042525],
            [110.359054132509058, -7.061371911088864],
            [110.35901754233852, -7.061317427930499],
            [110.359116664488937, -7.061371996996381],
            [110.359204033835709, -7.061417343954062],
            [110.359333808390389, -7.061489885299784],
            [110.359530604872901, -7.061589654798729],
            [110.359688121106345, -7.06165318875117],
            [110.359763040752398, -7.061698518524747],
            [110.359829729976568, -7.061761927771784],
            [110.359872946768249, -7.061816259412656],
            [110.35992038295467, -7.061888687618814],
            [110.359955689588872, -7.061933963010302],
            [110.360006878764906, -7.061952124021269],
            [110.360126246596678, -7.061979423942401],
            [110.360224688532284, -7.061997649744917],
            [110.360283787915449, -7.062024866965816],
            [110.360315158145511, -7.062052046155351],
            [110.360401616762616, -7.062142618611277],
            [110.360484372201853, -7.062251276757471],
            [110.36054734597127, -7.062341816993426],
            [110.360602359868295, -7.062459482482976],
            [110.360637604494698, -7.062549984698832],
            [110.360661027307316, -7.062595243756707],
            [110.360692680548155, -7.062622423313465],
            [110.360751829725587, -7.0626134589895],
            [110.360834501091901, -7.062577390718555],
            [110.36092113375976, -7.062541327859178],
            [110.360984231821504, -7.062541414305832],
            [110.361055227680069, -7.06255960234492],
            [110.361133838393854, -7.062595891586074],
            [110.36122032210011, -7.062668373162699],
            [110.361328014755159, -7.062749929158279],
            [110.36141842261101, -7.06284955225126],
            [110.361489023919304, -7.062949148215683],
            [110.361528550242866, -7.063012520066958],
            [110.361567818438019, -7.063057800778596],
            [110.361634815870858, -7.063103119440814],
            [110.361729209985839, -7.063184657155703],
            [110.361784335896601, -7.063220914160659],
            [110.361843152586189, -7.063248130817506],
            [110.361890663625658, -7.063266286607309],
            [110.361969324127188, -7.063266394221067],
            [110.362087597833195, -7.063266556003838],
            [110.362174168583707, -7.063275719794983],
            [110.362241215687433, -7.063284856873837],
            [110.362327736879038, -7.063330202141578],
            [110.362441987339309, -7.063375585301444],
            [110.362555954872406, -7.063420968048503],
            [110.36274120146679, -7.063484538956559],
            [110.362986371974017, -7.0635934186178],
            [110.363136778513251, -7.063684077973561],
            [110.36320628564296, -7.063756536040671],
            [110.363282497018204, -7.063892321013899],
            [110.363305562959127, -7.063991851844841],
            [110.363328567038437, -7.064136609563076],
            [110.363403522966465, -7.064362846754324],
            [110.363403473474648, -7.064399028264815],
            [110.363380221899234, -7.06443517810057],
            [110.36332810919005, -7.064471288531323],
            [110.363310504275532, -7.064516491466401],
            [110.363298767103529, -7.064615974777745],
            [110.363292959549355, -7.064724511578645],
            [110.363310071140475, -7.064833079673471],
            [110.363379528957552, -7.064941719238464],
            [110.363454670604199, -7.065032275766069],
            [110.363715034961714, -7.065204493628658],
            [110.363900208254364, -7.065322336418005],
            [110.364044735327852, -7.06536776054185],
            [110.364189631902192, -7.065349867358814],
            [110.364305359613368, -7.06535002515392],
            [110.364409769213282, -7.065350167492099],
            [110.364542732348596, -7.065368439514708],
            [110.364687579518872, -7.065386727693232],
            [110.364780388066237, -7.065386854141789],
            [110.364936627444052, -7.065350885383981],
            [110.365017847263886, -7.065341950603449],
            [110.365110348172422, -7.065360167360704],
            [110.365185576622267, -7.065387405981331],
            [110.365270363788966, -7.065459884589658],
            [110.365359825476077, -7.065631868918815],
            [110.36543846175941, -7.065650066744603],
            [110.365472145511902, -7.065641067189587],
            [110.365505866266091, -7.065604931489467],
            [110.3655622479247, -7.065550735826682],
            [110.36562972639544, -7.065451328264372],
            [110.365809746718966, -7.065198302006195],
            [110.365899553700146, -7.065117015559808],
            [110.366000666436335, -7.065044789864178],
            [110.36619165954626, -7.065045049515595],
            [110.36652831177561, -7.065090733995227],
            [110.366685571986494, -7.065136174615701],
            [110.366977394755096, -7.065272251892398],
            [110.367392536045372, -7.06544467793527],
            [110.367617114736817, -7.065508300393013],
            [110.368178321807974, -7.065635696854399],
            [110.368453168196993, -7.065771750264126],
            [110.368521470431901, -7.065690434154173],
            [110.36859238059283, -7.065563894549078],
            [110.368660707316891, -7.065464487640255],
            [110.368830909395015, -7.065356173232324],
            [110.36897560882521, -7.065274960452739],
            [110.369179740602561, -7.065184782629004],
            [110.369350127352277, -7.065148831495539],
            [110.369392619333496, -7.065112707347155],
            [110.369401144695786, -7.065085582663588],
            [110.369401218241237, -7.065031310336184],
            [110.369359032724006, -7.064841299789522],
            [110.369359216592414, -7.064705618970462],
            [110.369359326911706, -7.064624210478646],
            [110.369384878452394, -7.064560927201526],
            [110.369427370373472, -7.064524803044075],
            [110.36951257587674, -7.064497782039807],
            [110.369708145136499, -7.064461864797433],
            [110.370082466952184, -7.064480461401389],
            [110.370499207253374, -7.064517205784621],
            [110.370864955022213, -7.064599108042404],
            [110.371060684978602, -7.064653644518859],
            [110.371162486870475, -7.064699008852053],
            [110.371256070929974, -7.064753407491893],
            [110.37136664440537, -7.064798783611113],
            [110.371468790401295, -7.064798921306052],
            [110.371655834484983, -7.064790127978996],
            [110.37184291519705, -7.064754198399452],
            [110.372605376387256, -7.0646104983274],
            [110.372587769216764, -7.064664747097885],
            [110.372583451753059, -7.06471901374879],
            [110.372583354191534, -7.064791376897348],
            [110.372622279753855, -7.064881883370956],
            [110.372761212219174, -7.06509011471173],
            [110.372836258318941, -7.065253033041664],
            [110.372872466713289, -7.065470171588458],
            [110.372886616949273, -7.065678235054746],
            [110.372908111793478, -7.065895353808718],
            [110.37294401291048, -7.066130582758126],
            [110.372958187553962, -7.06632055543376],
            [110.372950882078683, -7.066492408397205],
            [110.372957280301733, -7.066573825692443],
            [110.372993930234571, -7.066673374496513],
            [110.373066761749328, -7.066800108188975],
            [110.373154565503086, -7.066944952826333],
            [110.373220076846465, -7.067044540426714],
            [110.373256702492995, -7.067162180003971],
            [110.373274653158177, -7.06727979447122],
            [110.37327453129582, -7.06737024840899],
            [110.37325992999925, -7.067496864518391],
            [110.373199133943871, -7.067677690968392],
            [110.373114569628228, -7.067858485438419],
            [110.373086988907957, -7.067957947848702],
            [110.37301802359363, -7.068111627043983],
            [110.372949328991055, -7.068274351997569],
            [110.372901536433986, -7.068464241287566],
            [110.372890342635586, -7.068581816543648],
            [110.372897294559166, -7.068672279988151],
            [110.372918984555426, -7.068744672445311],
            [110.372962449930313, -7.06882613960923],
            [110.373260121653402, -7.069034584438201],
            [110.373389852018619, -7.069143303805333],
            [110.373438410713547, -7.069224777782392],
            [110.373508376580219, -7.069378643813644],
            [110.373578293752814, -7.069568691411229],
            [110.373588924236543, -7.069659159799563],
            [110.373583782291789, -7.069695334526505],
            [110.373572649531042, -7.069767682838688],
            [110.373550654766959, -7.069921425234334],
            [110.373453344879294, -7.070111247998812],
            [110.373377600536614, -7.070255872691293],
            [110.373361362318704, -7.070337259535837],
            [110.373361301369542, -7.070382486499599],
            [110.373404152283598, -7.07050013444695],
            [110.373474203609831, -7.070590682740955],
            [110.373555339016136, -7.070645064292041],
            [110.373679225258471, -7.070681412482007],
            [110.373776537964616, -7.070699634105448],
            [110.373868228089222, -7.070690711923632],
            [110.373959918211895, -7.070681789723457],
            [110.373997834398295, -7.070681840668514],
            [110.374062570579824, -7.070727154693378],
            [110.374213584214004, -7.070790675421433],
            [110.374441501278497, -7.070899526419429],
            [110.374549283474664, -7.070917761944799],
            [110.374738249957389, -7.070954197228079],
            [110.374846254330279, -7.071017660041983],
            [110.374899943168643, -7.071072004548683],
            [110.37491628170379, -7.071126298938999],
            [110.374916220898996, -7.071171525914729],
            [110.374899712104053, -7.07124386705585],
            [110.374851241318979, -7.071307119896746],
            [110.374753818946004, -7.071370307036545],
            [110.374694324966114, -7.071424499652585],
            [110.374651205986225, -7.071505850473264],
            [110.374618507736599, -7.071623396920611],
            [110.374607585011603, -7.071750018007866],
            [110.374619250195622, -7.071912851060531],
            [110.374587096545639, -7.072256533494798],
            [110.374618471406833, -7.072491756290755],
            [110.374605345825586, -7.072573147362029],
            [110.374576584535475, -7.072708789900173],
            [110.374526662235368, -7.072799176967912],
            [110.374461189398417, -7.072880497734159],
            [110.37438610813966, -7.072952760173389],
            [110.374350936119356, -7.07301603079868],
            [110.374340959534749, -7.073070289856057],
            [110.374355712806263, -7.073251217868729],
            [110.374370514785184, -7.073395964307265],
            [110.374395342064332, -7.073450270115059],
            [110.374435461274558, -7.073495551052034],
            [110.374500480830193, -7.073540865428841],
            [110.374585371354513, -7.073749023860903],
            [110.374630081820456, -7.073957128328809],
            [110.374620056551137, -7.074047568965148],
            [110.374574673605167, -7.074128916707293],
            [110.374484619677389, -7.074183068215025],
            [110.374444354303407, -7.074246331995763],
            [110.374419307814932, -7.074354843263514],
            [110.374418312822556, -7.0744633868394],
            [110.374428316699493, -7.074599081418531],
            [110.374478217813049, -7.07473482959303],
            [110.374492995485681, -7.074897666811949],
            [110.374492885903635, -7.074979075348441],
            [110.374472661856657, -7.0750785476822],
            [110.374427513019072, -7.07519607734641],
            [110.374387259716229, -7.075250295718455],
            [110.374342244810379, -7.075268326052277],
            [110.374297181180467, -7.075322537951275],
            [110.374196842981078, -7.075449038826518],
            [110.374131677003561, -7.075512269095537],
            [110.3740863669194, -7.075539344412973],
            [110.374031448389943, -7.075557361399549],
            [110.37394119647044, -7.075548194646419],
            [110.373776021225609, -7.075493700073697],
            [110.373701085718366, -7.075457417651804],
            [110.373660978625523, -7.075403091255429],
            [110.373625952618269, -7.07535781709932],
            [110.37361612219901, -7.075303531428796],
            [110.373601198512731, -7.075249238906274],
            [110.373586262635541, -7.075203991774723],
            [110.373526016685204, -7.075185819906333],
            [110.373451056855686, -7.075167628231904],
            [110.373340690524628, -7.075176525118108],
            [110.373143702034227, -7.075212441591113],
            [110.37295856119411, -7.075275510152803],
            [110.372838242426198, -7.075320575139536],
            [110.372752998392329, -7.075374732750733],
            [110.372697711404626, -7.075456066923556],
            [110.372642707356377, -7.075537401469087],
            [110.372607522670037, -7.075609717307068],
            [110.372582229343095, -7.075691091876251],
            [110.372551930250069, -7.075917186187993],
            [110.372531510281007, -7.07616138461878],
            [110.372525767550314, -7.076432739042895],
            [110.372505825959081, -7.076532211623852],
            [110.372480544768209, -7.076604540789521],
            [110.372440586295355, -7.076649713951996],
            [110.37236011562608, -7.07673101411281],
            [110.372244804148892, -7.076839403490205],
            [110.372139703640201, -7.07692971579679],
            [110.372059183962833, -7.077047197447322],
            [110.37205426359327, -7.077128599449338],
            [110.372063861531529, -7.077354747504512],
            [110.372048583382764, -7.077562771191269],
            [110.372088604847832, -7.077680415435379],
            [110.37215353911175, -7.07778904787773],
            [110.372238588079043, -7.077879616633655],
            [110.372293692112748, -7.077933963376029],
            [110.372453762461362, -7.077997497051247],
            [110.372523875689026, -7.078042818600925],
            [110.372563712098739, -7.078088099327701],
            [110.372578660160002, -7.078124301097517],
            [110.372593583786625, -7.078178593643782],
            [110.372613466316565, -7.078332392327558],
            [110.3726234940833, -7.078449996107436],
            [110.372613197510475, -7.078531390870678],
            [110.372578012551855, -7.078603706676841],
            [110.372567986706983, -7.078694147207162],
            [110.37257297003454, -7.078775562566877],
            [110.372567766747991, -7.078856964193166],
            [110.372547590936648, -7.078920254823728],
            [110.372501997462251, -7.078947329564163],
            [110.372401792397824, -7.078974330656096],
            [110.372276428194127, -7.078983206982672],
            [110.372146265870299, -7.078983031398105],
            [110.372081196939419, -7.078973898204986],
            [110.372026068358622, -7.078937642205401],
            [110.371961072829734, -7.078874236674189],
            [110.371921224190032, -7.078838001283544],
            [110.371866058937528, -7.078828881427939],
            [110.371845944205646, -7.078846945085751],
            [110.371805960993711, -7.078910208940474],
            [110.371765682572985, -7.078982517795291],
            [110.371755705501755, -7.079036776745646],
            [110.371760454667822, -7.079082010172352],
            [110.371820505257801, -7.079244908491138],
            [110.371920355631744, -7.0794802237489],
            [110.371935242508314, -7.079561652470332],
            [110.371935169106195, -7.079615924790501],
            [110.371914993168957, -7.079679215381094],
            [110.371869965508708, -7.079706290812765],
            [110.371789838317596, -7.079742364259031],
            [110.371614291710017, -7.079823535858893],
            [110.371544068154378, -7.079859622633064],
            [110.371443764900192, -7.079958986581087],
            [110.371403781527405, -7.080022250384988],
            [110.371396112721996, -7.080085557839985],
            [110.37131044204142, -7.0802030323227],
            [110.371145647752428, -7.080284218249575],
            [110.371036670507678, -7.080311207158369],
            [110.370945249171072, -7.080329174374128],
            [110.370865429284493, -7.080347157257916],
            [110.37081587410627, -7.080374226454103],
            [110.370789226616253, -7.080410372023775],
            [110.370743288646054, -7.080482673106983],
            [110.370705114236856, -7.080672574893979],
            [110.370689723921572, -7.080753962684052],
            [110.370666754911653, -7.080790113218783],
            [110.370602190394337, -7.080826207484763],
            [110.370514644576687, -7.080907497650085],
            [110.370497322534661, -7.08095270121482],
            [110.370493853397349, -7.081006968922412],
            [110.370500558653333, -7.081070295796382],
            [110.370497101778668, -7.08111551812009],
            [110.370466160715566, -7.081187839455268],
            [110.3703102783806, -7.081359418747033],
            [110.37025799900681, -7.081323238735001],
            [110.370180823762695, -7.081268861866546],
            [110.370068007495178, -7.081205391331086],
            [110.369936786405063, -7.081150941245482],
            [110.369808075179662, -7.081123630722168],
            [110.369678527364542, -7.081087273632297],
            [110.369495745736188, -7.081077980564741],
            [110.36927170072201, -7.081032449895567],
            [110.368683310611843, -7.080905016301799],
            [110.368116143070779, -7.080777610814174],
            [110.367844044046251, -7.08069583263347],
            [110.367254560037225, -7.080541259631149],
            [110.366961509810153, -7.08046849786127],
            [110.36666283746105, -7.080368592080167],
            [110.366362492170239, -7.080250593052969],
            [110.366076898113604, -7.080105477762253],
            [110.365960379557919, -7.080060092043047],
            [110.365885148753392, -7.080032853349034],
            [110.365706969270505, -7.079969292751976],
            [110.365561601048739, -7.079914822220052],
            [110.365369568902395, -7.079842197222143],
            [110.365229011116625, -7.079787733153506],
            [110.36507345640014, -7.079733248582897],
            [110.364944475374443, -7.079696891012172],
            [110.364831032514445, -7.079678645398754],
            [110.364712496357441, -7.079660392805059],
            [110.364571581494914, -7.079660200405542],
            [110.364447361368377, -7.079660030764961],
            [110.364316633129164, -7.0796598522008],
            [110.364132129814109, -7.079668645511675],
            [110.363975639691475, -7.079677477037909],
            [110.363630426618357, -7.07967700507421],
            [110.363462359873765, -7.079667729817561],
            [110.363490497045689, -7.079577314417145],
            [110.36355043745327, -7.07940553401023],
            [110.363605776059401, -7.079288019632476],
            [110.363685240700619, -7.07911626589773],
            [110.363805243059275, -7.07889029522292],
            [110.363926131340918, -7.078637189545693],
            [110.364184872735109, -7.078140046619924],
            [110.364297775796246, -7.077932156849187],
            [110.364321028073377, -7.07789600704232],
            [110.364437388413378, -7.077642894990516],
            [110.364557377605067, -7.077425969415597],
            [110.364716477019826, -7.077163870215422],
            [110.364823449787167, -7.076946926781671],
            [110.364955938288176, -7.076693836550301],
            [110.36506236962552, -7.076458801514002],
            [110.36525181920365, -7.076142471097707],
            [110.36539624092282, -7.075853215386553],
            [110.365523610793517, -7.075618208738264],
            [110.365660921138954, -7.075356079401636],
            [110.365760499023793, -7.075166261769784],
            [110.365850197952881, -7.074958339864834],
            [110.36580808647507, -7.074922100933933],
            [110.365707427241546, -7.074867691462552],
            [110.365607592211916, -7.074831373882795],
            [110.365330686853397, -7.074749588007304],
            [110.365167481517986, -7.074704138569558],
            [110.364969779998148, -7.074640551249223],
            [110.364344639643917, -7.074494972045068],
            [110.364033496810478, -7.074413138698321],
            [110.363460163294477, -7.074249538323185],
            [110.363138563774271, -7.074158644722402],
            [110.362884824733186, -7.074104025313185],
            [110.362523303564714, -7.074031167467083],
            [110.362262540393218, -7.073940356560427],
            [110.362029432709093, -7.073903855732847],
            [110.36178039248054, -7.073930650674507],
            [110.361724071431539, -7.07393961887542],
            [110.361550901183904, -7.073939381505495],
            [110.361345153493261, -7.07396623555891],
            [110.361035560229055, -7.073992947051165],
            [110.360943881863776, -7.073992821256279],
            [110.360822209961796, -7.073992654278409],
            [110.360703933557573, -7.07399249192992],
            [110.360602634629871, -7.073992352861167],
            [110.360343445204009, -7.073991996931319],
            [110.360309207300489, -7.073991949903601],
            [110.360153014553561, -7.073991735331998],
            [110.359912192495727, -7.074009495166926],
            [110.359779485257434, -7.074009312748574],
            [110.35971545701814, -7.074000179337824],
            [110.359704417856818, -7.073864483392656],
            [110.359673803444196, -7.073493580534222],
            [110.35966208488766, -7.073167930574708],
            [110.359644244598471, -7.072561865264603],
            [110.359625880956756, -7.072335705395323],
            [110.359607713056008, -7.072172863489017],
            [110.359558357626867, -7.072055205630448],
            [110.359477901921707, -7.071919414237041],
            [110.359329623962225, -7.071720211870799],
            [110.35903330148922, -7.071357988883983],
            [110.358891261396778, -7.071149749604009],
            [110.358860310853984, -7.071023071634037],
            [110.35882351803906, -7.070824022549441],
            [110.358830429076519, -7.070326535924498],
            [110.358808391310149, -7.069892327145996],
            [110.358808690635456, -7.06967523832865],
            [110.358808940065998, -7.069494330979313],
            [110.358816608672939, -7.069268206912963],
            [110.358846472433228, -7.06895165953093],
            [110.358928553167615, -7.068318595484474],
            [110.358966060246928, -7.067794014715091],
            [110.358959301763505, -7.067359826908691],
            [110.358952767605373, -7.066762822457202],
            [110.358954447805019, -7.066364827782209],
            [110.358942467765104, -7.066229130517179],
            [110.358915416440141, -7.066147684844495],
            [110.35888831526853, -7.066102420646667],
            [110.35884619267506, -7.066075219590593],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 93,
        id: 95,
        area: 4861475.5136740003,
        perimeter: 13284.887836,
        kode_pos: 50229,
        kode_kec: 3374020,
        kode_kel: 3374020001,
        kec: "Gunungpati",
        kel: "Gunungpati",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.35971545701814, -7.074000179337824],
            [110.359779485257434, -7.074009312748574],
            [110.359912192495727, -7.074009495166926],
            [110.360153014553561, -7.073991735331998],
            [110.360309207300489, -7.073991949903601],
            [110.360343445204009, -7.073991996931319],
            [110.360602634629871, -7.073992352861167],
            [110.360703933557573, -7.07399249192992],
            [110.360822209961796, -7.073992654278409],
            [110.360943881863776, -7.073992821256279],
            [110.361035560229055, -7.073992947051165],
            [110.361345153493261, -7.07396623555891],
            [110.361550901183904, -7.073939381505495],
            [110.361724071431539, -7.07393961887542],
            [110.36178039248054, -7.073930650674507],
            [110.362029432709093, -7.073903855732847],
            [110.362262540393218, -7.073940356560427],
            [110.362523303564714, -7.074031167467083],
            [110.362884824733186, -7.074104025313185],
            [110.363138563774271, -7.074158644722402],
            [110.363460163294477, -7.074249538323185],
            [110.364033496810478, -7.074413138698321],
            [110.364344639643917, -7.074494972045068],
            [110.364969779998148, -7.074640551249223],
            [110.365167481517986, -7.074704138569558],
            [110.365330686853397, -7.074749588007304],
            [110.365607592211916, -7.074831373882795],
            [110.365707427241546, -7.074867691462552],
            [110.36580808647507, -7.074922100933933],
            [110.365850197952881, -7.074958339864834],
            [110.365760499023793, -7.075166261769784],
            [110.365660921138954, -7.075356079401636],
            [110.365523610793517, -7.075618208738264],
            [110.36539624092282, -7.075853215386553],
            [110.36525181920365, -7.076142471097707],
            [110.36506236962552, -7.076458801514002],
            [110.364955938288176, -7.076693836550301],
            [110.364823449787167, -7.076946926781671],
            [110.364716477019826, -7.077163870215422],
            [110.364557377605067, -7.077425969415597],
            [110.364437388413378, -7.077642894990516],
            [110.364321028073377, -7.07789600704232],
            [110.364297775796246, -7.077932156849187],
            [110.364184872735109, -7.078140046619924],
            [110.363926131340918, -7.078637189545693],
            [110.363805243059275, -7.07889029522292],
            [110.363685240700619, -7.07911626589773],
            [110.363605776059401, -7.079288019632476],
            [110.36355043745327, -7.07940553401023],
            [110.363490497045689, -7.079577314417145],
            [110.363462359873765, -7.079667729817561],
            [110.363630426618357, -7.07967700507421],
            [110.363975639691475, -7.079677477037909],
            [110.364132129814109, -7.079668645511675],
            [110.364316633129164, -7.0796598522008],
            [110.364447361368377, -7.079660030764961],
            [110.364571581494914, -7.079660200405542],
            [110.364712496357441, -7.079660392805059],
            [110.364831032514445, -7.079678645398754],
            [110.364944475374443, -7.079696891012172],
            [110.36507345640014, -7.079733248582897],
            [110.365229011116625, -7.079787733153506],
            [110.365369568902395, -7.079842197222143],
            [110.365561601048739, -7.079914822220052],
            [110.365706969270505, -7.079969292751976],
            [110.365885148753392, -7.080032853349034],
            [110.365960379557919, -7.080060092043047],
            [110.366076898113604, -7.080105477762253],
            [110.366362492170239, -7.080250593052969],
            [110.36666283746105, -7.080368592080167],
            [110.366961509810153, -7.08046849786127],
            [110.367254560037225, -7.080541259631149],
            [110.367844044046251, -7.08069583263347],
            [110.368116143070779, -7.080777610814174],
            [110.368683310611843, -7.080905016301799],
            [110.36927170072201, -7.081032449895567],
            [110.369495745736188, -7.081077980564741],
            [110.369678527364542, -7.081087273632297],
            [110.369808075179662, -7.081123630722168],
            [110.369936786405063, -7.081150941245482],
            [110.370068007495178, -7.081205391331086],
            [110.370180823762695, -7.081268861866546],
            [110.37025799900681, -7.081323238735001],
            [110.3703102783806, -7.081359418747033],
            [110.370115447772179, -7.081485862945319],
            [110.370033868514724, -7.081549070279601],
            [110.36998619570106, -7.081648505112684],
            [110.369955500653603, -7.08174796293252],
            [110.369905022722079, -7.081829303156272],
            [110.369870427546758, -7.081883528690423],
            [110.369854815457032, -7.08191968913816],
            [110.369828979850837, -7.081982971932196],
            [110.369804878855206, -7.082019120877921],
            [110.369763566214715, -7.082019064909595],
            [110.369708388373468, -7.082018990151656],
            [110.369584524157389, -7.081964549920007],
            [110.369458666944283, -7.081919152354534],
            [110.369407229074724, -7.081873855640954],
            [110.369242150639622, -7.081746996273114],
            [110.369131868785672, -7.08169257434688],
            [110.369082091873864, -7.081674416047005],
            [110.369037396007101, -7.081665310031025],
            [110.369004596883968, -7.081647174749849],
            [110.368930534347427, -7.08159280190468],
            [110.368888139085996, -7.081556562804183],
            [110.368820277167103, -7.081520289149676],
            [110.368763143252366, -7.081502120836714],
            [110.368694961489211, -7.081492982922387],
            [110.368584593652884, -7.081501878537643],
            [110.368467422400215, -7.081519810287436],
            [110.368425273231523, -7.081510707672023],
            [110.368343509259105, -7.081501551267587],
            [110.368275622780942, -7.081483368297163],
            [110.368244189283303, -7.081501416405211],
            [110.368218132078454, -7.081519471812071],
            [110.368175872123516, -7.081591777588794],
            [110.368125393916671, -7.08167311759389],
            [110.368074915691366, -7.081754457591661],
            [110.368030367358642, -7.081844851033573],
            [110.367991847435263, -7.08187193489007],
            [110.367908643967453, -7.081880867239873],
            [110.367811021697463, -7.081880734584701],
            [110.367692497121453, -7.081853437313224],
            [110.36764216665803, -7.081826232715312],
            [110.36760370832647, -7.081808089646546],
            [110.367568362591456, -7.081789950806343],
            [110.367500156177712, -7.081798903471522],
            [110.367405314259628, -7.08183495609161],
            [110.367266034798561, -7.081879993646427],
            [110.367168338557263, -7.081934133121186],
            [110.367120726767567, -7.081988340714446],
            [110.367076239893976, -7.082033507159409],
            [110.367061464145365, -7.082078714025633],
            [110.36704353879793, -7.082151052787323],
            [110.367049308357053, -7.082277696152532],
            [110.367056283771461, -7.082350068794879],
            [110.367056160434942, -7.082440522565619],
            [110.36703905928961, -7.082530953233789],
            [110.367014650424252, -7.082585192381158],
            [110.366978024779016, -7.082675596475453],
            [110.366951302843887, -7.082766014044144],
            [110.366912351773649, -7.082901641932476],
            [110.366894905922649, -7.083037299087184],
            [110.366887437821802, -7.083118697461655],
            [110.366870410639365, -7.083154855857216],
            [110.366848573068708, -7.083191007703597],
            [110.366829023884478, -7.083209071877548],
            [110.366787674140866, -7.083236151764398],
            [110.366737564853622, -7.083254174327797],
            [110.366648123583019, -7.083272143327615],
            [110.366534089139435, -7.083271988029664],
            [110.366403089214145, -7.083262764200335],
            [110.366297863707686, -7.083235484667877],
            [110.366203686156211, -7.083199174775437],
            [110.366140339320921, -7.083171952274276],
            [110.366062586049864, -7.083126619346119],
            [110.36600433258144, -7.083099403769942],
            [110.36594350773376, -7.083090275465113],
            [110.365890039946024, -7.083081157182643],
            [110.365834296092515, -7.08308108118244],
            [110.365749099292287, -7.083099055796418],
            [110.365661626414777, -7.083126072681242],
            [110.365600789211101, -7.083125989706129],
            [110.365530331241246, -7.083125893599425],
            [110.365469506405006, -7.083116765233137],
            [110.365438097431806, -7.083116722382898],
            [110.365389402937041, -7.083134746727863],
            [110.365335922792354, -7.083134673755984],
            [110.365296873798172, -7.083134620471045],
            [110.365231250955418, -7.083116440136015],
            [110.365172997546253, -7.083089224460144],
            [110.365088699113628, -7.083071018615966],
            [110.365030408596994, -7.083070939043203],
            [110.364947783205622, -7.083070826238231],
            [110.364869918687944, -7.083106901479094],
            [110.364840736294227, -7.083133997798407],
            [110.364818873817853, -7.083188240281221],
            [110.364806632103821, -7.083242495901444],
            [110.364801710307489, -7.083323897686844],
            [110.364803726431205, -7.083504808232759],
            [110.364808425402586, -7.08358622315654],
            [110.364806787747625, -7.083749037931502],
            [110.364792170875816, -7.083884698809621],
            [110.364748875551811, -7.084092683627474],
            [110.364681873087449, -7.084255409103712],
            [110.364619742891179, -7.084372914277419],
            [110.364548299605062, -7.084472315935924],
            [110.364447969126132, -7.084589768878112],
            [110.364409607850362, -7.084707306498061],
            [110.364404673556749, -7.084797753638071],
            [110.364428626442177, -7.084870149484746],
            [110.364442601232085, -7.084996804023268],
            [110.364442514507459, -7.085060121623449],
            [110.36442345674466, -7.085132458681374],
            [110.364380272610475, -7.085259035087593],
            [110.364327800171793, -7.085349417236698],
            [110.364256135660895, -7.085403591585496],
            [110.36416067727599, -7.085475824159845],
            [110.364074752894908, -7.085611387464281],
            [110.364026820195875, -7.085692730391001],
            [110.364007749935951, -7.085774112792248],
            [110.364002753537861, -7.085909786767618],
            [110.364077466597266, -7.0861088874853],
            [110.364081870062307, -7.086199347381704],
            [110.363978044269672, -7.086389158486474],
            [110.363955220999969, -7.086524808067437],
            [110.364027203657429, -7.086651541962905],
            [110.364063212486187, -7.086805362802193],
            [110.364089982652473, -7.086886807905595],
            [110.364112508337044, -7.086968247202077],
            [110.36409050916113, -7.08712198868856],
            [110.364107559299512, -7.087275783590799],
            [110.364141354052492, -7.087393419849902],
            [110.364183833985422, -7.087574385696255],
            [110.364226154960974, -7.087664897452924],
            [110.36429427548974, -7.087719262943597],
            [110.364362358847785, -7.087800764536312],
            [110.364413365075819, -7.087954605867671],
            [110.364438720520369, -7.088036049023684],
            [110.364472823152695, -7.088135594913616],
            [110.36462238660279, -7.088434297177121],
            [110.364673343406366, -7.088624319971554],
            [110.364724015261203, -7.089022386282714],
            [110.364655423589809, -7.089311744905429],
            [110.364612608749241, -7.089375004078145],
            [110.364535825397951, -7.089447262184079],
            [110.364271729893588, -7.089510218679798],
            [110.364075558954383, -7.089564222589351],
            [110.363598205084898, -7.089554523724245],
            [110.363274515061804, -7.089535989556767],
            [110.363223297941403, -7.089535919377874],
            [110.363112583105277, -7.089590039960774],
            [110.363035799503777, -7.089662297794181],
            [110.362831052370169, -7.089779607090779],
            [110.362762844738583, -7.089788558945292],
            [110.362660693420366, -7.089788418852203],
            [110.362592547954947, -7.089752143849565],
            [110.362515988083501, -7.089661584994896],
            [110.362439403368839, -7.089589116862232],
            [110.362371233076232, -7.089570932563068],
            [110.362286059862797, -7.089570815687497],
            [110.362200504154913, -7.089643061333695],
            [110.362046973984732, -7.089760440560611],
            [110.361825223637865, -7.089895816808145],
            [110.361450117118039, -7.090021936841349],
            [110.361313589631237, -7.090121248399054],
            [110.361262530830189, -7.090211632025644],
            [110.361236656148748, -7.090302050265798],
            [110.361228683203237, -7.090338220828732],
            [110.361317285927967, -7.09051925024376],
            [110.361464817278389, -7.090854132113376],
            [110.3617009179452, -7.091189135641166],
            [110.361730430380959, -7.091333902288063],
            [110.361730243579871, -7.091469582750901],
            [110.361730143951121, -7.091541945664088],
            [110.361759360986653, -7.091695757285147],
            [110.361818227503605, -7.091894836543072],
            [110.361788649301673, -7.092003340483343],
            [110.361655233915869, -7.092102656363706],
            [110.361300066764912, -7.092337348064912],
            [110.36116653898759, -7.092518072050532],
            [110.361166351989937, -7.092653752491489],
            [110.361166090187041, -7.092843705107491],
            [110.361165828376954, -7.093033657721933],
            [110.361180539016473, -7.093241721687101],
            [110.361224778253998, -7.093377463226149],
            [110.361313185739135, -7.093495174742745],
            [110.361682690941564, -7.093739907987326],
            [110.361874678114134, -7.093848716380734],
            [110.361963393731514, -7.093948337455554],
            [110.362052084483025, -7.094066049243864],
            [110.362051922607534, -7.094183638971969],
            [110.362007371742564, -7.094274031578058],
            [110.361895495308829, -7.09455428466264],
            [110.361873631842542, -7.094608526901746],
            [110.361873407626518, -7.094771343438715],
            [110.361917734370223, -7.094843767391875],
            [110.361947013856735, -7.094952352187682],
            [110.361932124995477, -7.095078967052977],
            [110.361798754943649, -7.095350145167489],
            [110.361709503300858, -7.095639474637385],
            [110.361709054682393, -7.095965107685295],
            [110.361767946477556, -7.096146096235002],
            [110.361945008314095, -7.096408655615924],
            [110.362373358381092, -7.096870558513845],
            [110.362683501568469, -7.097278026525218],
            [110.362774407080593, -7.097431922794776],
            [110.362877365284817, -7.097875287780634],
            [110.362995013357221, -7.098336763675302],
            [110.363098419722533, -7.098454495534886],
            [110.363201605353552, -7.098527000167207],
            [110.363260992376055, -7.09855421778755],
            [110.363467758634698, -7.098617819075612],
            [110.363645329010097, -7.098717561773905],
            [110.363704346118482, -7.098808096507475],
            [110.363704122412017, -7.0989709130733],
            [110.363688925967708, -7.099115618345849],
            [110.363600156406349, -7.099260222730691],
            [110.363451937472476, -7.099422836315015],
            [110.363362934543915, -7.099531258796955],
            [110.363333393357635, -7.099612626697559],
            [110.363345953061625, -7.099739279261138],
            [110.363449111104728, -7.100037918349429],
            [110.363552381168105, -7.100255149147238],
            [110.36368506820871, -7.100481465651708],
            [110.363847591966206, -7.10061736922221],
            [110.363950999022862, -7.100735100944949],
            [110.363995264467263, -7.100852751583675],
            [110.363979869173221, -7.10114218268723],
            [110.363964943229988, -7.101295933708936],
            [110.363905602652835, -7.101440578454004],
            [110.363831671643425, -7.101494749370411],
            [110.363713363291652, -7.101512677888949],
            [110.363595754637643, -7.101639151903337],
            [110.363476727948409, -7.101973667682264],
            [110.363461839103792, -7.102100282575813],
            [110.363461739594854, -7.10217264548043],
            [110.363491277860717, -7.102299321332803],
            [110.363491165915093, -7.102380729600253],
            [110.363461291791751, -7.102498278548409],
            [110.363372238444782, -7.102642882416387],
            [110.363268715952785, -7.102814602538777],
            [110.363238692402646, -7.103040695811139],
            [110.363311839792544, -7.103556382850375],
            [110.363311366906515, -7.103900106613702],
            [110.363340718531774, -7.104162462903972],
            [110.363392154638873, -7.104373113394799],
            [110.363392147528856, -7.104373107859494],
            [110.363291594076856, -7.10432271336328],
            [110.363129770228937, -7.104293334393687],
            [110.36302365546652, -7.10427463435006],
            [110.362893619006996, -7.104285058187795],
            [110.362792745577096, -7.104314076365537],
            [110.362736945263975, -7.104369662609926],
            [110.362641247393597, -7.104494110006336],
            [110.362558835273347, -7.104605322565137],
            [110.362487066366057, -7.104695344817406],
            [110.3623945105264, -7.104825439996622],
            [110.362186584164803, -7.104980767967316],
            [110.3619413428505, -7.105283995518226],
            [110.361731251381087, -7.105241722406321],
            [110.361507869073066, -7.105212688998315],
            [110.361488493812942, -7.104825967840974],
            [110.361387215477464, -7.104746246970216],
            [110.361164011248846, -7.104683693986675],
            [110.360795315742351, -7.104688373059117],
            [110.360582396881583, -7.104698453784069],
            [110.36032790699187, -7.104729225583297],
            [110.360052588515543, -7.104801465522323],
            [110.359611000041539, -7.104946095493709],
            [110.359522629185179, -7.105013406105377],
            [110.359485791329234, -7.105366079895919],
            [110.359418184390819, -7.105438606286959],
            [110.35930912121735, -7.105448829858472],
            [110.359153602396262, -7.105407117929787],
            [110.35904405463647, -7.105317332959758],
            [110.358577569277188, -7.105231038077538],
            [110.358229303779524, -7.105211965541418],
            [110.358151136350514, -7.105979386027691],
            [110.358050046170902, -7.106021739009996],
            [110.357669820233994, -7.106042458875765],
            [110.357337524970035, -7.106010128703677],
            [110.357124971621644, -7.105906257523984],
            [110.356861899106477, -7.105804971991416],
            [110.356558820427807, -7.105793928267437],
            [110.356306733439993, -7.105802801077068],
            [110.355990658044874, -7.105828469610329],
            [110.355721365721536, -7.10597100134657],
            [110.35557657837775, -7.106030040529553],
            [110.355513466256198, -7.106055775411953],
            [110.355418048705459, -7.106086265448811],
            [110.355360166228422, -7.10606917237422],
            [110.355339776165764, -7.106035118824087],
            [110.35531263051233, -7.105960225640048],
            [110.355258263361335, -7.105864879483008],
            [110.355200508956358, -7.105755918530912],
            [110.355151229873201, -7.105675890779951],
            [110.355079769917296, -7.105624753593647],
            [110.355018457553811, -7.105624668310962],
            [110.354936684017233, -7.105641567154096],
            [110.354837855539785, -7.105675454850684],
            [110.354708413575196, -7.105678677244181],
            [110.354602853281222, -7.105654712686957],
            [110.354555246583516, -7.105596803587356],
            [110.354470247198876, -7.105484402115549],
            [110.354402388251671, -7.105293766547955],
            [110.354385532720144, -7.105167849858628],
            [110.354368624969581, -7.105079360810713],
            [110.354281943965944, -7.104951645635439],
            [110.354128905523908, -7.104777904018878],
            [110.353955558052363, -7.104512266046937],
            [110.353894359869912, -7.104430520324946],
            [110.35383644916061, -7.104433842158485],
            [110.353795517348217, -7.104474615355362],
            [110.353710252220409, -7.104552754476557],
            [110.353655652676764, -7.104624131282779],
            [110.353614749349617, -7.104644489385719],
            [110.353543199417075, -7.104657999725767],
            [110.353502353128846, -7.104637527669552],
            [110.353361148947371, -7.104526748930327],
            [110.353225023271236, -7.104438093608948],
            [110.353092308637699, -7.104346040491313],
            [110.353040943890321, -7.104280290445127],
            [110.353037328307039, -7.104266670438248],
            [110.353021049967793, -7.104217660690697],
            [110.353012941239129, -7.104171383842132],
            [110.352950354516338, -7.104116866483063],
            [110.35291501251001, -7.104062387130903],
            [110.352882379758896, -7.104018797565288],
            [110.352893372777785, -7.103950775351442],
            [110.352931610483637, -7.103882791149069],
            [110.352948044817495, -7.103820219528433],
            [110.352931755083702, -7.1037793742696],
            [110.352912740884278, -7.103738525209211],
            [110.352861029266421, -7.103700352028735],
            [110.352768465865793, -7.103651235828212],
            [110.352716739044737, -7.103623948620415],
            [110.352673265571511, -7.103539521383863],
            [110.352662493323692, -7.103449696781998],
            [110.352683090500889, -7.10333270094019],
            [110.352680484035105, -7.103248330738454],
            [110.352653357386245, -7.103163926315574],
            [110.352618049739959, -7.103084953477603],
            [110.352582719263495, -7.103022309618175],
            [110.352582791593932, -7.102970601180164],
            [110.35258829000955, -7.102935229327864],
            [110.352626493401615, -7.102891738615113],
            [110.352686473458562, -7.102861885799311],
            [110.352757366582637, -7.102821162194158],
            [110.352806502048622, -7.102753193198309],
            [110.352844743456842, -7.102682487494894],
            [110.352848921472543, -7.102617177270275],
            [110.352851725840495, -7.10256002963569],
            [110.352827281792528, -7.102505565488407],
            [110.352778325215212, -7.102445624135529],
            [110.35270484470287, -7.102388370060922],
            [110.352631352790453, -7.102339280466689],
            [110.352557910380256, -7.102254811403621],
            [110.352457200565723, -7.102186633282248],
            [110.352353777746728, -7.102110286847767],
            [110.352266682668969, -7.102047570701141],
            [110.352191843865825, -7.101987593157078],
            [110.352161951008341, -7.101933121374215],
            [110.352118374929077, -7.101922174511747],
            [110.352072009637183, -7.101957489282165],
            [110.352003818200643, -7.102014545575436],
            [110.351976543145355, -7.10203627949085],
            [110.351938404547454, -7.102033504712029],
            [110.351927533388036, -7.102014439012468],
            [110.351922168297321, -7.101954558477369],
            [110.351911441928124, -7.101832075910412],
            [110.351889688189544, -7.101802109000047],
            [110.351840727998237, -7.101744889060635],
            [110.35179994499633, -7.101684959040463],
            [110.351834122628048, -7.101597918734228],
            [110.35185067892894, -7.101448259263902],
            [110.351837235745592, -7.101320329896151],
            [110.351823704928066, -7.101254994948278],
            [110.351780182270801, -7.101205947109058],
            [110.35173533550487, -7.101129682398187],
            [110.351667353779959, -7.101037056345803],
            [110.351615688296533, -7.100966225101289],
            [110.351561313624586, -7.100884504058937],
            [110.351441799968271, -7.100625794353038],
            [110.351406511753623, -7.1005332139686],
            [110.351360280007498, -7.100473276304848],
            [110.351292229812231, -7.100429637144642],
            [110.351254182873404, -7.100361546409982],
            [110.35122430547294, -7.100296188597174],
            [110.351186250926645, -7.100233540851063],
            [110.351178199621813, -7.100146441540847],
            [110.351246650143423, -7.099904323595807],
            [110.351284990635165, -7.099762859105983],
            [110.35129876154771, -7.099656739788175],
            [110.351282514017257, -7.099585958029863],
            [110.351241689315245, -7.099555964439477],
            [110.351143700900366, -7.099490511400975],
            [110.351086510495037, -7.09947410242409],
            [110.351053870584195, -7.099435955758859],
            [110.351021280261733, -7.099362429639664],
            [110.350988750976171, -7.099245359577893],
            [110.350928965860547, -7.099136415900744],
            [110.350875961351974, -7.099049253714791],
            [110.35082974507003, -7.098978430029016],
            [110.350805305235568, -7.098921244309308],
            [110.35080809453062, -7.098874982683908],
            [110.35085994660129, -7.098812460674234],
            [110.350958136985412, -7.098733674451385],
            [110.350997729051485, -7.098671135277717],
            [110.351016906897016, -7.098594960046136],
            [110.351036061853975, -7.09853511379148],
            [110.351036141937925, -7.098477962366537],
            [110.351008989102766, -7.098412608357776],
            [110.35097635307946, -7.098371740196327],
            [110.350916522291769, -7.098295454480682],
            [110.350886633605, -7.098238261145909],
            [110.350886805244954, -7.098115793808644],
            [110.350901938381071, -7.098009676404004],
            [110.350972930008751, -7.097898194102052],
            [110.351065671302976, -7.097819400215197],
            [110.351142077411538, -7.097732418966984],
            [110.351174835403739, -7.097686199224181],
            [110.351183100191562, -7.097620894730042],
            [110.351187221147867, -7.097596406972628],
            [110.351154627093976, -7.097525602363448],
            [110.351064778072413, -7.097484654236147],
            [110.350939519103008, -7.097438213578915],
            [110.350879707497526, -7.097348320384601],
            [110.350814435613941, -7.097266584053649],
            [110.350792773680567, -7.097171301197185],
            [110.350782116264099, -7.09699983167992],
            [110.350779559660566, -7.096880082025124],
            [110.350791899676153, -7.096822947743931],
            [110.350805590459359, -7.096773979856415],
            [110.350792071306586, -7.096700480401526],
            [110.350767662084237, -7.096621522713602],
            [110.350707793389304, -7.096572451953763],
            [110.350656086536972, -7.096531557108421],
            [110.350605764795858, -7.096474335184935],
            [110.350575933472527, -7.096376319393506],
            [110.350576047923923, -7.096294674500585],
            [110.350557114305559, -7.096196673952184],
            [110.350502747945896, -7.096109509844087],
            [110.35035856346758, -7.095959625525864],
            [110.350263338496177, -7.095866961220699],
            [110.350078238248045, -7.095752399090193],
            [110.349969353098615, -7.095686930626959],
            [110.349884961020024, -7.095640546946003],
            [110.349857793207875, -7.095586078876358],
            [110.349830732332748, -7.095455408914086],
            [110.349825459168457, -7.095330212462195],
            [110.349806552465239, -7.095213161424167],
            [110.349754998610692, -7.095063406651969],
            [110.349689727225197, -7.094981670202207],
            [110.349586329340056, -7.09488899433857],
            [110.349496496257245, -7.094837159978139],
            [110.349447498834252, -7.094807154822929],
            [110.349354968120466, -7.094736266149382],
            [110.349326438210269, -7.094681796148439],
            [110.349312946077532, -7.094589246199153],
            [110.349310366882222, -7.094485825535446],
            [110.349305063274656, -7.094382401054022],
            [110.349261606453538, -7.0942870876159],
            [110.349215200686899, -7.094235758198502],
            [110.349131037329172, -7.094142665071965],
            [110.348931129680096, -7.093902892756031],
            [110.348852274794666, -7.093793922158239],
            [110.348811538697674, -7.093701334004536],
            [110.348762640868983, -7.093600569900759],
            [110.348721912452874, -7.093502538750108],
            [110.348670252009882, -7.093428985776554],
            [110.348610410718294, -7.093360864327368],
            [110.348520581867987, -7.093306308316012],
            [110.348368042002647, -7.09328704382423],
            [110.348265953380945, -7.093232470567127],
            [110.348244272960471, -7.093150795117924],
            [110.348260745668426, -7.093061008701975],
            [110.348312750394413, -7.092889627109405],
            [110.348334713967304, -7.092769911880494],
            [110.348375752156059, -7.092647501906799],
            [110.348377248263787, -7.092552251469154],
            [110.348350096088964, -7.09248689735408],
            [110.348301274993872, -7.092331703304114],
            [110.348238816632602, -7.092187376121599],
            [110.348195314283743, -7.092124720561106],
            [110.348100082848987, -7.09203749889953],
            [110.34800349308, -7.091947553813987],
            [110.347962703696425, -7.09189306654626],
            [110.347949204213975, -7.091805959569644],
            [110.347924837780511, -7.091697065332508],
            [110.34787864557731, -7.091609912465486],
            [110.347807998935011, -7.091476459750892],
            [110.347775451709936, -7.091372997026339],
            [110.347779637846457, -7.0913022438805],
            [110.347766157549742, -7.091201529423544],
            [110.347730931903584, -7.091065404926647],
            [110.347654878716099, -7.090902008097348],
            [110.347608694290585, -7.090809412221457],
            [110.347597949913123, -7.090700537101131],
            [110.347534202524102, -7.090504499527526],
            [110.347501663070005, -7.090395593800412],
            [110.347455559149125, -7.090245846510351],
            [110.347423035047328, -7.090126054798096],
            [110.347427274830167, -7.090017200719158],
            [110.347441072932199, -7.089892031057331],
            [110.347473926386868, -7.089777774155586],
            [110.347493127274106, -7.08968527008666],
            [110.347517715646831, -7.089636317595336],
            [110.34755321302984, -7.089579215915781],
            [110.347556010164567, -7.089527511324753],
            [110.347534272548273, -7.089486658290465],
            [110.34742540069314, -7.089413024893685],
            [110.347375045605105, -7.0893802961691],
            [110.347356032391801, -7.089339446954273],
            [110.347339743561889, -7.089298601565069],
            [110.347347977985336, -7.089255069115434],
            [110.347375271575672, -7.089219727933758],
            [110.347465268018155, -7.08915453828627],
            [110.347653399511998, -7.089048663865374],
            [110.347797910250264, -7.088964500155789],
            [110.347848364833141, -7.08892646994266],
            [110.347859350366562, -7.088863890830468],
            [110.347870393298564, -7.088760489279913],
            [110.347903235064862, -7.088654396812935],
            [110.347941456693377, -7.088597298912603],
            [110.347985084978276, -7.088570145107382],
            [110.348072253546746, -7.088578431890742],
            [110.348148532263679, -7.088581260381959],
            [110.348194842840215, -7.088584046833291],
            [110.348246640415965, -7.088559625960285],
            [110.348282149092512, -7.088494359718407],
            [110.348298663610379, -7.088374636810164],
            [110.348309660536074, -7.088303893190708],
            [110.348301560067426, -7.088252173304189],
            [110.348260736513822, -7.088222179545631],
            [110.348192642450556, -7.08821119805167],
            [110.348116344668014, -7.088221977053401],
            [110.348078130745535, -7.088273631981778],
            [110.348007235792011, -7.088317076558651],
            [110.347971807440388, -7.088325191362769],
            [110.347898264623254, -7.08831420218108],
            [110.347830189694093, -7.088289613154209],
            [110.347778506944593, -7.088232389106222],
            [110.347740434595593, -7.088183348657283],
            [110.347717487921187, -7.088033633888204],
            [110.347687588711807, -7.087984604906268],
            [110.34764952787404, -7.087927399964621],
            [110.347614264145776, -7.087818490423909],
            [110.347587188856892, -7.087698706369907],
            [110.347551871563468, -7.087627897767628],
            [110.347519209742202, -7.087606079911922],
            [110.347445655568819, -7.087603255156921],
            [110.347366660314947, -7.087594979748816],
            [110.347279507272987, -7.087575806868155],
            [110.34720871960981, -7.087543049449923],
            [110.347181518042603, -7.087513074737473],
            [110.34715434329101, -7.087464049554487],
            [110.347138146458846, -7.08735788826798],
            [110.347134167155261, -7.087281680651324],
            [110.347147965186579, -7.087156510983385],
            [110.347145351893786, -7.087077583783564],
            [110.347126400060944, -7.086993190635464],
            [110.347088400652979, -7.086892441729042],
            [110.347034028252708, -7.086810720330556],
            [110.346968731593833, -7.086748034088653],
            [110.346892537493773, -7.08668533252962],
            [110.346813599891135, -7.086636234609617],
            [110.346785024721257, -7.086614422452649],
            [110.346774169416975, -7.086584470690116],
            [110.346782396157096, -7.086546381236948],
            [110.346845213620853, -7.086434887957858],
            [110.346899873289644, -7.086312497203231],
            [110.346951804734559, -7.08619282410777],
            [110.347003755302509, -7.086059543525015],
            [110.347035276965215, -7.085923512741684],
            [110.347054473878302, -7.085833730164828],
            [110.347035483791686, -7.085776551972149],
            [110.34699739255322, -7.085741118961447],
            [110.34691566177699, -7.085741004166547],
            [110.346812093983885, -7.085770795190882],
            [110.3467002764597, -7.085855004617933],
            [110.346626661259393, -7.085895723687107],
            [110.346574921417925, -7.085879321969631],
            [110.346542271263516, -7.085849339572707],
            [110.346502825547702, -7.085808461619142],
            [110.346475681621598, -7.085737664445083],
            [110.346483130821312, -7.08564506785998],
            [110.346524108441741, -7.085600847082677],
            [110.346581842875239, -7.085480169821105],
            [110.346593509605967, -7.08537092861462],
            [110.346590781594358, -7.085359503033918],
            [110.34655917345232, -7.085227120360211],
            [110.346541152342013, -7.085110731850699],
            [110.346457869879501, -7.084945093407984],
            [110.346611001134548, -7.084999226033012],
            [110.346791751350864, -7.085044706767754],
            [110.346923021120048, -7.085062981868893],
            [110.347136375885768, -7.085063281474516],
            [110.347218731102416, -7.085054351737956],
            [110.347256994848294, -7.085009178659957],
            [110.347289869550252, -7.084973043372276],
            [110.347295643336736, -7.084891643248342],
            [110.347301185047883, -7.084774061363003],
            [110.34730133772149, -7.084665517271581],
            [110.347312475083356, -7.084593170032926],
            [110.347323329477149, -7.084520822396318],
            [110.347339830415436, -7.084457528042721],
            [110.347361952455159, -7.084421377653848],
            [110.347389231436239, -7.084340007705105],
            [110.347444189944156, -7.084294858031627],
            [110.347477077295579, -7.084249677382198],
            [110.347531972197345, -7.084249754404207],
            [110.347586584136309, -7.084249831022766],
            [110.347663216362903, -7.084286119962643],
            [110.347745186767952, -7.084349552457362],
            [110.347832803754613, -7.084422038218173],
            [110.347947597983037, -7.084485516712749],
            [110.348106238899973, -7.084558102010262],
            [110.348171848346638, -7.084585330061223],
            [110.348226667068317, -7.084639679060853],
            [110.348297602031934, -7.08470309600219],
            [110.348390531979405, -7.084820815919993],
            [110.34847812375989, -7.084911392261102],
            [110.348560119789525, -7.08495673394447],
            [110.348614731822906, -7.084956810449889],
            [110.348675041224354, -7.084929758845838],
            [110.348718976800811, -7.084875548216782],
            [110.348740879272754, -7.084794170641056],
            [110.348774163756573, -7.084667582196278],
            [110.348790715282846, -7.084568106399203],
            [110.348790880296875, -7.084450516930757],
            [110.34877453187552, -7.084405267229372],
            [110.348725076721834, -7.084359971163546],
            [110.348643106165341, -7.084296538829189],
            [110.348588583087547, -7.084233144930001],
            [110.348555848207894, -7.084169781547113],
            [110.348512103160871, -7.084088312016344],
            [110.348495767475413, -7.084034016964728],
            [110.348476319196934, -7.083979717552215],
            [110.348476420784863, -7.083907354805189],
            [110.348486117724747, -7.083853096222517],
            [110.348543390569048, -7.083771768200126],
            [110.348658104908779, -7.083690520618511],
            [110.348839560427393, -7.083636502538684],
            [110.348925631971028, -7.083600441590444],
            [110.349059253986354, -7.083555401816996],
            [110.349145118645794, -7.083465068360491],
            [110.349259832813033, -7.083383820612854],
            [110.349336346944725, -7.083302519394326],
            [110.349431781527457, -7.083248380714994],
            [110.349622870218994, -7.083185330415541],
            [110.350234027710513, -7.083014322680965],
            [110.350482274706692, -7.082951351853704],
            [110.350682688286426, -7.082897359480929],
            [110.350978755883162, -7.082834455131088],
            [110.351150514272035, -7.082834694760182],
            [110.351312833765505, -7.082907284094598],
            [110.351522552062548, -7.083079438497579],
            [110.351655895767948, -7.083233395627707],
            [110.351836971905016, -7.083450736819486],
            [110.351979987087844, -7.083568525881879],
            [110.352084852806058, -7.083650080290551],
            [110.352332797039892, -7.083804196895275],
            [110.352571095448639, -7.083976390689983],
            [110.35266666142121, -7.084030795931488],
            [110.35282879954876, -7.084031021589427],
            [110.3529147952529, -7.084049231989936],
            [110.35311591933079, -7.084094738638163],
            [110.353271486304635, -7.084140181850692],
            [110.353629554542351, -7.08425826947424],
            [110.353856095171963, -7.084339992624143],
            [110.353703559249666, -7.084149827886006],
            [110.353608710223938, -7.083986879400294],
            [110.353566737637081, -7.083851140509609],
            [110.353566825830455, -7.083787823041487],
            [110.353598807497775, -7.08357982397868],
            [110.353646452878436, -7.083299483714078],
            [110.353673240356173, -7.08316384038194],
            [110.353668002611343, -7.083064334027485],
            [110.353657608554329, -7.083010047360447],
            [110.353636436714083, -7.082973836457191],
            [110.353615535243378, -7.082946671299462],
            [110.353515391553103, -7.082928441379392],
            [110.35342063676849, -7.082901173559402],
            [110.35336777310728, -7.082864918590202],
            [110.353299422111334, -7.082774369863021],
            [110.353273207390117, -7.082701970454983],
            [110.353236611277666, -7.0825662390214],
            [110.353210547817639, -7.082385295381312],
            [110.353184358334786, -7.082294805265332],
            [110.353157822877378, -7.082249541517354],
            [110.353100186762489, -7.082186143779189],
            [110.352977764875376, -7.082113610559247],
            [110.35261014080703, -7.08195028244145],
            [110.352574538017691, -7.081914051416754],
            [110.352551681150487, -7.081868792760271],
            [110.352545223480561, -7.08183260229633],
            [110.35255183253328, -7.081760248541726],
            [110.35260784812283, -7.081362330248842],
            [110.352647174342977, -7.081163386843051],
            [110.352666774379202, -7.081109141897299],
            [110.352728586153717, -7.081018774199596],
            [110.352754719511779, -7.08094644759805],
            [110.352754832998542, -7.080865039427688],
            [110.352745451885028, -7.080693164350265],
            [110.352723350117728, -7.080512226206079],
            [110.352691200461337, -7.080231775013493],
            [110.352687968851441, -7.080114180708222],
            [110.352701651861722, -7.080041836782753],
            [110.352728904390929, -7.079978557102099],
            [110.35276267762427, -7.079906241118767],
            [110.352779730931772, -7.0798519926171],
            [110.352790018384752, -7.079779643963446],
            [110.352796935515542, -7.079689199880856],
            [110.352800419292265, -7.079625887133627],
            [110.352810656309231, -7.079589719889592],
            [110.35285572271934, -7.079535510341626],
            [110.352915798645583, -7.079472276288977],
            [110.353072477584817, -7.079327768193685],
            [110.353318735733069, -7.079065794695182],
            [110.353396983978314, -7.078957358961126],
            [110.353445213192629, -7.078866972250229],
            [110.353469145567232, -7.078749415664118],
            [110.353523342091364, -7.078640946486763],
            [110.35360755755454, -7.078514428248949],
            [110.353721880025887, -7.078306543446026],
            [110.353807107843366, -7.078062436720652],
            [110.353843144429078, -7.077990123767852],
            [110.353915154656605, -7.077890724627769],
            [110.354023358553391, -7.077809466451559],
            [110.354113452985516, -7.077728183117894],
            [110.354245796414304, -7.077583640762744],
            [110.354432002658896, -7.077366810073432],
            [110.354672291685731, -7.07712291814675],
            [110.354918880926576, -7.076824762550686],
            [110.354997203747999, -7.076662054321719],
            [110.355057555252031, -7.076399822046668],
            [110.35510617980681, -7.076228027268025],
            [110.355200115396244, -7.076029159108336],
            [110.355323528808029, -7.075794150233466],
            [110.355397594359644, -7.075640481383642],
            [110.355424513072137, -7.075613382521283],
            [110.355448589648049, -7.075595325099108],
            [110.35547548326079, -7.075586316951648],
            [110.355510004207758, -7.075586364737014],
            [110.355537180777674, -7.075577356977503],
            [110.355603135216157, -7.075559357509777],
            [110.355640793793967, -7.075541318874002],
            [110.355676804789923, -7.075487096447139],
            [110.355663568455114, -7.075441851245437],
            [110.355637869406024, -7.075405634173505],
            [110.355605083855181, -7.075378452669951],
            [110.355592966819017, -7.075342254392853],
            [110.355612528583279, -7.075315145334832],
            [110.35564254726296, -7.075297096123789],
            [110.355683330902295, -7.075270016429942],
            [110.355714789452179, -7.075233878451919],
            [110.355737451205343, -7.075215819054074],
            [110.355772022289145, -7.075179685378013],
            [110.355815585335407, -7.075188791025585],
            [110.355837913967022, -7.075206912670799],
            [110.355867869937867, -7.075234090243943],
            [110.355898058717344, -7.075297449647153],
            [110.35593393174932, -7.075342726156856],
            [110.355978601575188, -7.075369924076756],
            [110.356022988448018, -7.075397121601461],
            [110.356056339944217, -7.075424303860559],
            [110.356092521029723, -7.075451490030712],
            [110.356161808270215, -7.075478721978264],
            [110.356239892299584, -7.075487875323066],
            [110.356292535123686, -7.075478902727033],
            [110.356365010104582, -7.075451866783891],
            [110.356398424271191, -7.075433822216099],
            [110.35643748507789, -7.075424830828673],
            [110.356503967807541, -7.075433968093598],
            [110.356590270164645, -7.075434087359032],
            [110.356654218797431, -7.075434175722447],
            [110.356696084140495, -7.075443278945431],
            [110.356765371423336, -7.075470510806255],
            [110.356851636231255, -7.075497766108939],
            [110.356904562009575, -7.075488793833594],
            [110.356935146575609, -7.075470745317458],
            [110.356979608627526, -7.075443670585204],
            [110.357026912776746, -7.075407554393593],
            [110.357052096088523, -7.075407589168877],
            [110.357090565937483, -7.075416687668159],
            [110.357137499516497, -7.075443888609169],
            [110.357154734961966, -7.075462003164581],
            [110.357173077215435, -7.075498210006622],
            [110.357196783172284, -7.075543469632716],
            [110.35722334374644, -7.075570642439618],
            [110.357264360241331, -7.075579744441669],
            [110.357293775432069, -7.075588830426247],
            [110.357322870131853, -7.075625052105952],
            [110.357338395327972, -7.075652209674733],
            [110.357367810525048, -7.075661295655146],
            [110.357418164670179, -7.075670410532597],
            [110.357448441255485, -7.075670452317024],
            [110.357498549968824, -7.075652430707175],
            [110.357533378907533, -7.075634388007742],
            [110.357575319330408, -7.075589218977167],
            [110.357609311891153, -7.075562129737046],
            [110.357631407678809, -7.075544069461273],
            [110.357680654992677, -7.07553509202187],
            [110.357724796545781, -7.075535152914521],
            [110.357776012066267, -7.075535223560367],
            [110.357817041074753, -7.075535280151041],
            [110.357861195129658, -7.075526295667289],
            [110.357905053724835, -7.075526356152536],
            [110.357986545825312, -7.075526468527059],
            [110.358029555545528, -7.075526527830085],
            [110.358064925381498, -7.075526576596045],
            [110.358094070126555, -7.075526616777177],
            [110.358131691137231, -7.07553571402281],
            [110.358162804099408, -7.075544802294235],
            [110.358202110371678, -7.075562947238851],
            [110.358232374461238, -7.075572034335793],
            [110.35827170572432, -7.075572088546588],
            [110.358308194908417, -7.075581184218609],
            [110.358347796640388, -7.075590284177339],
            [110.358404375899283, -7.075599407528024],
            [110.358440877578431, -7.075599457825582],
            [110.358485868020551, -7.07559951981631],
            [110.358530575504332, -7.075599581412854],
            [110.358566806712574, -7.075590585945998],
            [110.358620285917723, -7.075590659618046],
            [110.358648298834936, -7.075590698205716],
            [110.358682536845052, -7.075590745366134],
            [110.358721006752944, -7.075599843734864],
            [110.358800210244311, -7.075618043580588],
            [110.358845188209841, -7.075627150901952],
            [110.358896403751729, -7.075627221425474],
            [110.358930629286291, -7.075636313933233],
            [110.358943607952142, -7.075663467950575],
            [110.358956291181045, -7.075699666943442],
            [110.358997012297166, -7.075717813772719],
            [110.359043970989788, -7.075726923802979],
            [110.359095469503558, -7.075726994695127],
            [110.359133656474768, -7.075736092641947],
            [110.359244734573423, -7.075826699352304],
            [110.359284594363501, -7.075853890355975],
            [110.359342280573856, -7.075881105886649],
            [110.359435607154467, -7.075917415830655],
            [110.359515351717633, -7.07595370707298],
            [110.359586644925869, -7.075962850527139],
            [110.359639853692244, -7.075953878330246],
            [110.35967975091657, -7.075953933203372],
            [110.359719623210893, -7.075972078806308],
            [110.359790879037277, -7.076008358329979],
            [110.359901711764905, -7.076071828410138],
            [110.359987460840046, -7.076062900911452],
            [110.359954799570531, -7.075945266017645],
            [110.35988155422443, -7.075709985333273],
            [110.359840911509124, -7.075429522546691],
            [110.35980848708806, -7.075140025672669],
            [110.359784626213781, -7.074796268264545],
            [110.359759363023045, -7.074443463540927],
            [110.359722498572509, -7.074090642863955],
            [110.35971545701814, -7.074000179337824],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 94,
        id: 96,
        area: 7232131.1264110003,
        perimeter: 22742.749656,
        kode_pos: 50225,
        kode_kec: 3374020,
        kode_kel: 3374020002,
        kec: "Gunungpati",
        kel: "Plalangan",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.3703102783806, -7.081359418747033],
            [110.370466160715566, -7.081187839455268],
            [110.370497101778668, -7.08111551812009],
            [110.370500558653333, -7.081070295796382],
            [110.370493853397349, -7.081006968922412],
            [110.370497322534661, -7.08095270121482],
            [110.370514644576687, -7.080907497650085],
            [110.370602190394337, -7.080826207484763],
            [110.370666754911653, -7.080790113218783],
            [110.370689723921572, -7.080753962684052],
            [110.370705114236856, -7.080672574893979],
            [110.370743288646054, -7.080482673106983],
            [110.370789226616253, -7.080410372023775],
            [110.37081587410627, -7.080374226454103],
            [110.370865429284493, -7.080347157257916],
            [110.370945249171072, -7.080329174374128],
            [110.371036670507678, -7.080311207158369],
            [110.371145647752428, -7.080284218249575],
            [110.37131044204142, -7.0802030323227],
            [110.371396112721996, -7.080085557839985],
            [110.371425779109671, -7.080076552513918],
            [110.371491709301964, -7.080076641571615],
            [110.371609975262274, -7.080085846702887],
            [110.371886626664818, -7.080149538048979],
            [110.372146607841415, -7.08019511593689],
            [110.372238816868261, -7.080222376558741],
            [110.372265095679865, -7.080249548222296],
            [110.372271567128934, -7.080276693163347],
            [110.37228476853501, -7.080349074201038],
            [110.372287807752372, -7.080403350722123],
            [110.372287477584109, -7.080647576169637],
            [110.372316858492042, -7.080891841698623],
            [110.372324351744425, -7.081000396647793],
            [110.372328902964938, -7.081081811417651],
            [110.37231911166873, -7.08110893441789],
            [110.372309417556437, -7.081163193758631],
            [110.372300006404259, -7.081217453480614],
            [110.372287077395242, -7.081362162487086],
            [110.372261488417848, -7.081452581991124],
            [110.372204453825589, -7.08157009526777],
            [110.372153410362259, -7.081651435008864],
            [110.372134402894403, -7.081687590967192],
            [110.372137454322555, -7.081732822099173],
            [110.37215665912386, -7.081759984226569],
            [110.372200764696061, -7.081787179957895],
            [110.372307368472434, -7.081841596226668],
            [110.372391335206686, -7.081895981933445],
            [110.372426644485728, -7.081941256584374],
            [110.372434494091934, -7.081995539592684],
            [110.372434408494442, -7.082058857299011],
            [110.372412387959997, -7.082230690249068],
            [110.372373131080167, -7.082384409133745],
            [110.372347332465367, -7.082420555935464],
            [110.37225132215255, -7.082483744195872],
            [110.372053666649236, -7.082592022238469],
            [110.372006645714322, -7.082628140367509],
            [110.371959685964967, -7.08261903156333],
            [110.371938217434732, -7.082591866370167],
            [110.371922395942647, -7.08257375420322],
            [110.371881353959708, -7.082582744186134],
            [110.371863785726646, -7.082600811266453],
            [110.371849871539922, -7.082636974084289],
            [110.37182429462861, -7.082718348160392],
            [110.371822252674875, -7.082763572411775],
            [110.371822215950516, -7.082790708567559],
            [110.371830102214929, -7.082817855423385],
            [110.371845640746713, -7.082835967211563],
            [110.371869090028611, -7.082863135084092],
            [110.371908397533687, -7.082881278968117],
            [110.371976838089921, -7.082908507587473],
            [110.372002329322058, -7.082890451201009],
            [110.372049350285636, -7.082854333075178],
            [110.372080808227338, -7.082818193934668],
            [110.372109965745395, -7.082809187893045],
            [110.372129748747355, -7.082827305402256],
            [110.372129687569426, -7.082872532331098],
            [110.372115761156735, -7.082917740543295],
            [110.372094158003662, -7.082990074598047],
            [110.37209212830922, -7.083026253466667],
            [110.372100014592618, -7.083053400319386],
            [110.372117533891213, -7.083071514773863],
            [110.372166449967992, -7.083098717011381],
            [110.372230916842142, -7.083134985635695],
            [110.372268231403893, -7.083162172204834],
            [110.372279796233286, -7.0831893240201],
            [110.372279747300126, -7.083225505563662],
            [110.37226411086921, -7.083279756874447],
            [110.372226649484944, -7.083361114933423],
            [110.372213006026854, -7.083406323529065],
            [110.372212957086987, -7.083442505071828],
            [110.372224509683733, -7.083478702274163],
            [110.372236345246392, -7.083514899858248],
            [110.37221281026585, -7.083551049699773],
            [110.372177415249496, -7.083569092725871],
            [110.372151923983708, -7.083587149118729],
            [110.372112789239139, -7.08365041410074],
            [110.372077333021281, -7.083713684044894],
            [110.372063714012526, -7.083740801862676],
            [110.372063677297177, -7.083767938018585],
            [110.37208711439699, -7.083804151269633],
            [110.372122177520907, -7.083822289411997],
            [110.372149600605397, -7.083840417237909],
            [110.372171622892708, -7.083876628575471],
            [110.372179213989881, -7.083912820430992],
            [110.37218905656843, -7.083958060727772],
            [110.372184960462164, -7.084057554619523],
            [110.372184874805441, -7.084120872317207],
            [110.372186806611481, -7.084157056532655],
            [110.372218105570184, -7.084238507401965],
            [110.372311756245423, -7.084455723463048],
            [110.372351544191318, -7.084537185784233],
            [110.372417340465773, -7.084636774008035],
            [110.372441060597737, -7.084672987628076],
            [110.372462836674643, -7.084682062417268],
            [110.372490272053867, -7.084691144842386],
            [110.372530736058707, -7.08469119944403],
            [110.372629490869073, -7.084691332687804],
            [110.372713519269738, -7.08470049144819],
            [110.37275199029483, -7.084709588744833],
            [110.372790436869735, -7.084736776810801],
            [110.372828588258827, -7.084773009878554],
            [110.372930813840298, -7.08492691958971],
            [110.37302116716512, -7.085071767872361],
            [110.373114350198136, -7.085216619956429],
            [110.373199880998968, -7.085370507113711],
            [110.373261999015924, -7.08547009028194],
            [110.373331461948325, -7.085578728746458],
            [110.373349793632897, -7.085623980469758],
            [110.37336806423869, -7.085714459126661],
            [110.373382434410431, -7.085759705510068],
            [110.373399092742943, -7.085786864170316],
            [110.373455637040749, -7.085823121984113],
            [110.373499484527485, -7.08583222647289],
            [110.373528912972702, -7.085832266126012],
            [110.373550689127796, -7.085841340870453],
            [110.373590809213567, -7.085886621943096],
            [110.373612831702417, -7.085922833227653],
            [110.373639357225812, -7.085977141385174],
            [110.373661626054471, -7.0860404892108],
            [110.373679908942506, -7.086121922473427],
            [110.373697023338849, -7.086230490372424],
            [110.3737015141634, -7.086257632632997],
            [110.373695793786439, -7.086302851944992],
            [110.373673932126209, -7.086357094915114],
            [110.373654653792187, -7.086384205153538],
            [110.373629999098426, -7.086411308147397],
            [110.373613562634304, -7.086429376809084],
            [110.373597089529085, -7.086474581630821],
            [110.373574981523035, -7.086501688052321],
            [110.373547792336296, -7.086519742222388],
            [110.373481848987055, -7.086528698762357],
            [110.37343544252353, -7.086528636220778],
            [110.37332309270883, -7.086537530191633],
            [110.373262808680323, -7.086546494328576],
            [110.373161470101124, -7.086573493909207],
            [110.373095231551986, -7.08659149539745],
            [110.373021389583499, -7.086582350414472],
            [110.372961105546707, -7.086591314511249],
            [110.372925439572441, -7.086600311806767],
            [110.372887190218492, -7.086636441820967],
            [110.37285686391489, -7.086672582519809],
            [110.372812906307203, -7.086744886434036],
            [110.372777413216767, -7.086835292566895],
            [110.372755305147223, -7.08686239894328],
            [110.372738868634229, -7.086880467569297],
            [110.37271139642931, -7.086898521303535],
            [110.37268391199045, -7.086925620421099],
            [110.372656722747323, -7.086943674533493],
            [110.372629226069591, -7.086979819032295],
            [110.372579904265393, -7.087043070277112],
            [110.37244820247362, -7.087133346515497],
            [110.372415304928126, -7.087187574510068],
            [110.37239061340648, -7.087241813580724],
            [110.372379504277845, -7.087296070987988],
            [110.372382284990536, -7.087332256346373],
            [110.372390454309013, -7.08735940357916],
            [110.372423217256255, -7.087404674817915],
            [110.372450628316173, -7.087431848027508],
            [110.3725272266513, -7.087495269239403],
            [110.372573830524587, -7.08755864995516],
            [110.372622956643411, -7.087640124874008],
            [110.372650331027174, -7.087694434228198],
            [110.372653099526886, -7.087739664971198],
            [110.372650208687489, -7.087784888075966],
            [110.372636516163794, -7.087866278206541],
            [110.372641806896169, -7.087929603155955],
            [110.372652805908359, -7.08795675420494],
            [110.372685581153618, -7.087992980045267],
            [110.372740415606344, -7.088038281054773],
            [110.372788741825445, -7.08808357327634],
            [110.372811059536204, -7.088110739596085],
            [110.372818379988573, -7.088137885678366],
            [110.372821997405254, -7.088183117566662],
            [110.372810851598743, -7.088264511139085],
            [110.3727772902064, -7.088391101467732],
            [110.372762502506959, -7.08844535391874],
            [110.372758504264425, -7.088472484726496],
            [110.372769786261173, -7.088499636155697],
            [110.372799165941785, -7.088535857408802],
            [110.372843530670281, -7.088581144282678],
            [110.372884475335624, -7.088644517340921],
            [110.372898845524489, -7.088689763737452],
            [110.372908417497996, -7.08872595825821],
            [110.372910915283413, -7.088762143233796],
            [110.372903496972242, -7.088807360230889],
            [110.37287677570292, -7.088897778187278],
            [110.372862234265256, -7.088979167175735],
            [110.372845158343949, -7.089051507341667],
            [110.37284990763365, -7.089096740756285],
            [110.372857215866446, -7.089132932222977],
            [110.372852368716465, -7.089160061885137],
            [110.372832795005735, -7.089196217074455],
            [110.372789427524225, -7.089250430952232],
            [110.372801263239808, -7.089286628529841],
            [110.372823027300598, -7.089304748704498],
            [110.372849601817606, -7.089322875370108],
            [110.372893178876311, -7.089322934176273],
            [110.372919765628907, -7.089332015453567],
            [110.37294636461364, -7.089332051344755],
            [110.372997298840076, -7.089332120068314],
            [110.373043127415229, -7.089341227299585],
            [110.373077059110557, -7.089359363878686],
            [110.373113266780152, -7.089368458124299],
            [110.373139865767953, -7.089368494004596],
            [110.373200457589078, -7.08934143952853],
            [110.373229369221079, -7.089305296916161],
            [110.37324639619662, -7.089269138273359],
            [110.373270570712876, -7.089178716858241],
            [110.373314269996058, -7.089088321769842],
            [110.373336119630821, -7.089043124223411],
            [110.373365289755583, -7.08902507275217],
            [110.373389342015145, -7.089025105182579],
            [110.373442503291983, -7.089052313063543],
            [110.373483779927909, -7.089079504917255],
            [110.373524798039597, -7.089088605614705],
            [110.373568362863523, -7.089097709741476],
            [110.373652945807066, -7.089115914550871],
            [110.37371347649173, -7.089134086932552],
            [110.373764103306726, -7.089152245961161],
            [110.37382459736088, -7.089197554487905],
            [110.373871508967028, -7.089242844708163],
            [110.373890714148317, -7.089270006791455],
            [110.373900298421333, -7.089297155912387],
            [110.373902796283801, -7.089333340888822],
            [110.373897863703291, -7.089423788270965],
            [110.373890408832267, -7.089496141448818],
            [110.373909811504035, -7.089586621617119],
            [110.373924181816847, -7.089631867991463],
            [110.373958089148942, -7.089668095283502],
            [110.374003881151026, -7.089704338583569],
            [110.37405703033447, -7.089740591789875],
            [110.374088403180139, -7.089767770256905],
            [110.374107620603084, -7.08978588694713],
            [110.374139264214591, -7.089822111178888],
            [110.374181094690186, -7.089858349128201],
            [110.374280084776586, -7.089894664046053],
            [110.374345438258672, -7.089903797446514],
            [110.374376847745424, -7.089903839735537],
            [110.374408269435804, -7.089894836635443],
            [110.374447048302073, -7.089885843437326],
            [110.374502805267454, -7.089876873089815],
            [110.374560801577132, -7.089885996556828],
            [110.374584841686882, -7.089895074316579],
            [110.374652717496687, -7.089922301876777],
            [110.374691164602041, -7.089949489827156],
            [110.374734692946276, -7.089985730015928],
            [110.374761255387568, -7.090012901969221],
            [110.374768539387006, -7.090067184193895],
            [110.374758845277341, -7.09012144357369],
            [110.374739210673653, -7.0902028257886],
            [110.374722122779303, -7.090284211428845],
            [110.374683431668842, -7.09043793122475],
            [110.374639512888024, -7.090691143417083],
            [110.374624874064637, -7.090844895572799],
            [110.374622205334305, -7.090935346014736],
            [110.3746390492648, -7.091034868123812],
            [110.374658193555717, -7.091107257118573],
            [110.37468244349661, -7.091170607581286],
            [110.37468719297074, -7.091215840990653],
            [110.374687144169471, -7.091252022538486],
            [110.374679689366047, -7.091324375731573],
            [110.374682138484033, -7.091396742254546],
            [110.37469675528267, -7.09146912515455],
            [110.374716441132918, -7.09155960568343],
            [110.374714140776732, -7.09158673879721],
            [110.374691171452213, -7.091622889494501],
            [110.374673037007028, -7.091640955891831],
            [110.374618977644545, -7.091649928526321],
            [110.374575683322121, -7.091649870243779],
            [110.374544815247077, -7.091667919492862],
            [110.374530310580766, -7.091722172382486],
            [110.374511475934838, -7.091839737261405],
            [110.374492714508747, -7.091903029820035],
            [110.374477668298809, -7.091939191171632],
            [110.374466559249115, -7.091993448629467],
            [110.374470176824744, -7.092038680514229],
            [110.374473806606645, -7.092074867012677],
            [110.374518615708183, -7.092210608388897],
            [110.374533293520869, -7.092237764360082],
            [110.374570891889078, -7.092264951190915],
            [110.374676427423793, -7.092274138671232],
            [110.374818490471412, -7.092265284482976],
            [110.375008929160558, -7.092265540742409],
            [110.375068906569297, -7.092274666837085],
            [110.375102579979711, -7.09227471213652],
            [110.375177296209614, -7.092265767236627],
            [110.37527803347183, -7.092265902723321],
            [110.375337998699507, -7.092284074171396],
            [110.375364007548313, -7.092302199953428],
            [110.375390274992085, -7.092338416890156],
            [110.375416235093738, -7.092392724220779],
            [110.375479805831901, -7.092465172925674],
            [110.375513418326932, -7.092510445136051],
            [110.375558091030811, -7.092537641404093],
            [110.375625376974796, -7.092582958873288],
            [110.37565168100592, -7.092592039633685],
            [110.375782327905668, -7.092655533050454],
            [110.375950253834006, -7.092773348948008],
            [110.376100869961505, -7.092927323162058],
            [110.376224050234569, -7.093072215085235],
            [110.376362130649824, -7.093289490231239],
            [110.376421715729549, -7.09338002428707],
            [110.376462600430244, -7.093488624042728],
            [110.3764811304614, -7.093597193785342],
            [110.37650362209007, -7.093705768845807],
            [110.376533261010863, -7.09376008106737],
            [110.37662286542583, -7.093832564590403],
            [110.376693830286072, -7.093877886865404],
            [110.37681822801359, -7.093959462455437],
            [110.376907808190055, -7.094050036709542],
            [110.377056961548064, -7.094240190330367],
            [110.377120362574502, -7.094439274308763],
            [110.377128952308581, -7.094574966923221],
            [110.377131450416954, -7.094611151898658],
            [110.377142401192245, -7.094674484429996],
            [110.37724348361651, -7.094801415291956],
            [110.377302898004302, -7.09484656239448],
            [110.377336559461085, -7.0948556529384],
            [110.37737023307362, -7.094855698089797],
            [110.377471278217072, -7.094837742748599],
            [110.377779126782045, -7.094752805500571],
            [110.378048179887656, -7.094706837766578],
            [110.378351821148499, -7.09467610501973],
            [110.378467544351864, -7.094685305328951],
            [110.378516215448386, -7.094685370468916],
            [110.378557197791764, -7.094721606948665],
            [110.378609486873387, -7.094766903963923],
            [110.378646778471747, -7.09481218090678],
            [110.378676615785054, -7.094929811142666],
            [110.378721438484249, -7.095056506835855],
            [110.378773618472422, -7.095183212369182],
            [110.378810897991158, -7.095237534694894],
            [110.378881887548687, -7.095264765884873],
            [110.378919215585711, -7.095282906632604],
            [110.378986538646558, -7.095301087494453],
            [110.379064909739867, -7.095310237711979],
            [110.379174359268575, -7.095355611107292],
            [110.379297613859208, -7.095446229980384],
            [110.379476848510521, -7.095573105278993],
            [110.379655974262661, -7.095781389060358],
            [110.379712164633403, -7.095871918231309],
            [110.379734398344496, -7.095962402034502],
            [110.379752892872517, -7.096098107891861],
            [110.37979368168925, -7.096279070581061],
            [110.379834579511524, -7.096378624723539],
            [110.379849455973726, -7.096469098695464],
            [110.379853049857942, -7.096532421366132],
            [110.379841941269348, -7.096586678987176],
            [110.379808182747311, -7.096649951760072],
            [110.379695943853903, -7.096785482951735],
            [110.379635832478201, -7.096875856724332],
            [110.379613380707866, -7.096948189996848],
            [110.379595432268147, -7.097038620104589],
            [110.379598917082987, -7.097183351311378],
            [110.379595008248472, -7.09735520886378],
            [110.379568768747788, -7.097508945750276],
            [110.379553650001526, -7.097599379633775],
            [110.379542234135329, -7.097671727647374],
            [110.379560837679605, -7.097726024967965],
            [110.379594450935983, -7.097771296940698],
            [110.379635728712529, -7.097798488336758],
            [110.379684105059312, -7.097807598397547],
            [110.379811099586973, -7.097852995140099],
            [110.379867374981146, -7.097880206555523],
            [110.379945746536748, -7.097889356662722],
            [110.379983099042391, -7.09788940655782],
            [110.380016785000223, -7.097880406142853],
            [110.380054173826778, -7.097853319851226],
            [110.380076565053827, -7.097826213526581],
            [110.38014788192055, -7.097609218926614],
            [110.380200377237387, -7.097500744100709],
            [110.380241739750645, -7.097464617685387],
            [110.380264118844536, -7.097446556742838],
            [110.380294114011576, -7.097446596787294],
            [110.380353785071463, -7.097473812675624],
            [110.38042104802129, -7.097537220332327],
            [110.38045466135506, -7.097582492249232],
            [110.380480610216907, -7.097645844756244],
            [110.38048054972505, -7.097691071728538],
            [110.380461788786604, -7.097754364562911],
            [110.380384012044686, -7.097935168955833],
            [110.380201636377961, -7.098251514816991],
            [110.38015596865047, -7.098332862520977],
            [110.38030781342006, -7.098444322178636],
            [110.380521862047345, -7.098606322592888],
            [110.380947655333443, -7.098873244315367],
            [110.381408085819587, -7.098830456861624],
            [110.381817495964853, -7.098663788161933],
            [110.382368434687109, -7.098367251925795],
            [110.382774223298739, -7.098126260057192],
            [110.383019967796784, -7.097951940789524],
            [110.383094627609083, -7.09775509879726],
            [110.383094790928695, -7.097632475231596],
            [110.383041490216712, -7.09745930181395],
            [110.382989320866031, -7.097323551250457],
            [110.382989477498967, -7.097205961064528],
            [110.383064742496344, -7.096998016548906],
            [110.383132553759324, -7.096862425432027],
            [110.383177666920162, -7.09677203122094],
            [110.383223026905753, -7.09670877357816],
            [110.383260403424273, -7.096690732402127],
            [110.384551803761084, -7.09739798876435],
            [110.384671983375867, -7.09746146600848],
            [110.384814553977506, -7.097497836645243],
            [110.38505523761161, -7.097380565115534],
            [110.385130724685212, -7.097217847576879],
            [110.385183206825957, -7.097118417483765],
            [110.385281139608566, -7.097100456338515],
            [110.385416424864317, -7.097082544624229],
            [110.385829793800411, -7.096911228722612],
            [110.386040318656782, -7.09670346253017],
            [110.38628118135226, -7.096450509226385],
            [110.386407590973107, -7.096296904156456],
            [110.386890171846886, -7.095999042773052],
            [110.386979969955718, -7.09592679796862],
            [110.387041753743333, -7.095854516161391],
            [110.387069839836442, -7.095800280705578],
            [110.387098009683328, -7.095682727401683],
            [110.387294775418368, -7.095393533567467],
            [110.387457867204276, -7.095104295206211],
            [110.387542208854413, -7.09487827087349],
            [110.387609687710693, -7.094778860208894],
            [110.387727404079229, -7.094779015432625],
            [110.387789103935674, -7.094770051355849],
            [110.387957520092229, -7.094734091662608],
            [110.388288350225736, -7.094707391303025],
            [110.388596837485622, -7.094671615863011],
            [110.388866250717925, -7.094653879644714],
            [110.389079364434494, -7.094627023780325],
            [110.389368152325204, -7.09459122190209],
            [110.389583873507632, -7.095939274071495],
            [110.389657711454362, -7.096382597032885],
            [110.389609251155136, -7.097296121351093],
            [110.389581465820513, -7.097766446952196],
            [110.390703592675578, -7.098310646887013],
            [110.391152447067654, -7.098483099024882],
            [110.392059144895924, -7.098448105820798],
            [110.392843762129871, -7.098503405458687],
            [110.393068950637144, -7.098548927139944],
            [110.393197314455563, -7.098630503861288],
            [110.393527990774643, -7.099155571109283],
            [110.393685902682819, -7.099146732009079],
            [110.39388532904502, -7.099201265100103],
            [110.393969289778198, -7.09926469277363],
            [110.394154401796612, -7.099445843117499],
            [110.39421739877541, -7.099527334248465],
            [110.394307890723809, -7.099789769911689],
            [110.394417247487723, -7.100124593643679],
            [110.394483345501669, -7.100215134227486],
            [110.394521806542855, -7.100233275280239],
            [110.394567365636206, -7.100233334725657],
            [110.394661950487219, -7.100179185527584],
            [110.394812611792531, -7.10008892771353],
            [110.394889652098982, -7.100034755582197],
            [110.394917666686609, -7.100034792112896],
            [110.394938583243444, -7.100052910253742],
            [110.395022414277037, -7.100215837359665],
            [110.395120146633928, -7.100351646274954],
            [110.395173086136751, -7.100550714824696],
            [110.395190441589079, -7.100695464386204],
            [110.395235635049858, -7.100758841329233],
            [110.395435062329341, -7.100813373824656],
            [110.395560986711502, -7.100813537884915],
            [110.395652152259757, -7.100777474900905],
            [110.396097827877867, -7.100787100618371],
            [110.396367386225975, -7.100877905738895],
            [110.39649319273606, -7.100968523751125],
            [110.39659787041596, -7.10120384122742],
            [110.396612443718212, -7.101312405409486],
            [110.396573829220671, -7.10141185498053],
            [110.396527857290451, -7.101728385427848],
            [110.396541958961848, -7.101764585512775],
            [110.396562852078389, -7.101800794431282],
            [110.396590819618652, -7.101837012550648],
            [110.396723736209367, -7.101900503462863],
            [110.396800694131329, -7.101909648968404],
            [110.397080888595568, -7.102090921927315],
            [110.397231291018343, -7.102199662612627],
            [110.397274221087841, -7.102263036447412],
            [110.397280859423503, -7.102380635745972],
            [110.397277357692275, -7.102462040123662],
            [110.397315654152862, -7.102606816863397],
            [110.397305007318465, -7.102742484575331],
            [110.397280258715753, -7.102841952217557],
            [110.397199904894563, -7.102832802365204],
            [110.39703896142224, -7.102778320569548],
            [110.396961142631909, -7.10277821940807],
            [110.396779093845907, -7.102850346183953],
            [110.396740502806139, -7.102931704914567],
            [110.396722769558949, -7.103076408815428],
            [110.396771536050878, -7.103221199203753],
            [110.396935781074177, -7.103348048877908],
            [110.397040742256749, -7.103366276208136],
            [110.397236940107717, -7.103294167732125],
            [110.397397919115932, -7.103321513212175],
            [110.397593704416948, -7.103348903845064],
            [110.397707213830046, -7.103321914945241],
            [110.397772640550244, -7.103276772715257],
            [110.39782311651382, -7.103195429317188],
            [110.397837630757778, -7.103132130101216],
            [110.397953037997894, -7.102951371166013],
            [110.398000401138304, -7.102870023701739],
            [110.398146664545266, -7.102680259311748],
            [110.398254608298558, -7.102580899536495],
            [110.39834662297342, -7.102544837138587],
            [110.398430113047453, -7.102535899980927],
            [110.398536182537413, -7.102572219276971],
            [110.398611584712867, -7.102689907738334],
            [110.398661684405511, -7.102898017773545],
            [110.398717703164991, -7.103124226355152],
            [110.398753512077505, -7.103223772596925],
            [110.398785724544013, -7.103259996105619],
            [110.398829008399275, -7.10326909764663],
            [110.398902889105415, -7.10325110252063],
            [110.398957922990178, -7.103228285953403],
            [110.398957481590614, -7.103230880110967],
            [110.398963464817498, -7.103367822245],
            [110.398978701623278, -7.103495544838334],
            [110.399003154052565, -7.103643281023797],
            [110.399095254334597, -7.103886497349681],
            [110.399209741093031, -7.10410362727357],
            [110.399276386739871, -7.104278253837991],
            [110.399284004061542, -7.104428325044016],
            [110.399229894920609, -7.104582113805864],
            [110.399126579041663, -7.104672756672964],
            [110.398958671740331, -7.104686386378566],
            [110.398870916385647, -7.104655500863614],
            [110.398760112989038, -7.104581504976389],
            [110.398524857877021, -7.104268866474868],
            [110.398475623318149, -7.104227260732416],
            [110.398378602481444, -7.104214826175751],
            [110.398305725065995, -7.104227434704175],
            [110.3979517419518, -7.104386431780842],
            [110.397738070013347, -7.104472347026754],
            [110.397645191106008, -7.104564883531022],
            [110.397632478293389, -7.104701590098212],
            [110.397603761645669, -7.104905290804544],
            [110.3975107086588, -7.105131425695171],
            [110.397483907281199, -7.105242980076541],
            [110.397485321326883, -7.105339912958648],
            [110.397506754887999, -7.105439949029095],
            [110.397551280507201, -7.105549246630353],
            [110.39762655949859, -7.105698587470778],
            [110.397714739646176, -7.105823386996564],
            [110.397849562515631, -7.105957359924938],
            [110.398063452257389, -7.106117650818815],
            [110.398445319063427, -7.106365194538969],
            [110.39875541229145, -7.106552496180472],
            [110.399034796592815, -7.106706630855724],
            [110.399359344608968, -7.106842594245699],
            [110.399598078733732, -7.106910475129431],
            [110.399792105670073, -7.106947652497691],
            [110.400038490085976, -7.106994129080862],
            [110.400211179843012, -7.107037630704885],
            [110.400349502378461, -7.107089923975588],
            [110.400484985641896, -7.107158654676081],
            [110.400835797569812, -7.10742442651824],
            [110.401001942435144, -7.107583115883799],
            [110.401136568788459, -7.107740530818116],
            [110.401280258963382, -7.107949659594328],
            [110.401362202042307, -7.108102639899499],
            [110.401452266611614, -7.108339144732249],
            [110.401518643642405, -7.108543383584628],
            [110.401615259941678, -7.1088968985128],
            [110.401714575970317, -7.109173398600739],
            [110.401851305065989, -7.10944898251052],
            [110.401971242393131, -7.109607611960258],
            [110.402062940592373, -7.109742165072673],
            [110.401877018654773, -7.109994038870538],
            [110.400582794545954, -7.110872719835804],
            [110.400148358056427, -7.111206349448233],
            [110.39965386786497, -7.111586096730794],
            [110.399390732774762, -7.111788172712989],
            [110.39906667011067, -7.112041832109048],
            [110.398971577408815, -7.112261141150072],
            [110.398954688158952, -7.112304206587259],
            [110.398897335055693, -7.112450449627094],
            [110.398901461157095, -7.112833884216898],
            [110.398838569405683, -7.112882950548903],
            [110.398517215465887, -7.113133661320182],
            [110.398498549213357, -7.113137060954265],
            [110.398434893137846, -7.113148654456961],
            [110.398290548061539, -7.11317494358621],
            [110.398221230782568, -7.113133276877005],
            [110.398045459800329, -7.113156146628001],
            [110.397916616023835, -7.113350297830483],
            [110.397531840401555, -7.113363362125757],
            [110.397411433951476, -7.113487935451096],
            [110.397457181661096, -7.113871424086067],
            [110.397244225450393, -7.114037453586818],
            [110.397174667101567, -7.11418057151126],
            [110.396998895698275, -7.114203440826193],
            [110.396882956881612, -7.114448130107309],
            [110.396482119949198, -7.115530587783197],
            [110.395907422266234, -7.11546785759316],
            [110.395773202703509, -7.115331764887487],
            [110.39578257023237, -7.115230307172868],
            [110.395607156736048, -7.115193180097918],
            [110.395436517596636, -7.11503614018104],
            [110.395367202086362, -7.115077560108421],
            [110.395191782656312, -7.115045045145145],
            [110.395131683837533, -7.11510031388109],
            [110.394967594260237, -7.115100453516939],
            [110.394657587033578, -7.114362308513512],
            [110.394881864810827, -7.114214107394503],
            [110.39536369616043, -7.114408530750152],
            [110.395648186548939, -7.113641193977534],
            [110.395656266141799, -7.113619401390633],
            [110.395507495366331, -7.113438007949644],
            [110.395404007137671, -7.113308444806218],
            [110.395306946756165, -7.11321771845581],
            [110.395248766433539, -7.113120571447861],
            [110.395243781171473, -7.113090450387983],
            [110.395229483082133, -7.113004061010384],
            [110.395184251509193, -7.11291340230609],
            [110.395113063254939, -7.112855066720586],
            [110.394996491024287, -7.112822557465178],
            [110.394892791261654, -7.112854779027807],
            [110.394782629846262, -7.112874049317671],
            [110.394724246279935, -7.112932215657933],
            [110.394698204794182, -7.113029252670632],
            [110.394646198072323, -7.113165084163419],
            [110.394581225652061, -7.113307370102696],
            [110.394490347284602, -7.113443150760287],
            [110.39439312594935, -7.113475380664873],
            [110.394315399760544, -7.113462336226338],
            [110.394133965147844, -7.113487984541547],
            [110.394004333831973, -7.11353311476939],
            [110.393848872985799, -7.113513497139961],
            [110.393791102078609, -7.113500100560954],
            [110.393680480437041, -7.113474448327],
            [110.393466737761202, -7.113435340071285],
            [110.393233559349085, -7.113396206259525],
            [110.393094149158813, -7.113369064105833],
            [110.393032773949599, -7.11335711478329],
            [110.392793142547177, -7.113298558098431],
            [110.392540554034412, -7.113239984301873],
            [110.392287923049835, -7.113213767316562],
            [110.392093514286572, -7.113252340608302],
            [110.391866576298156, -7.113394413498248],
            [110.391738089524551, -7.113455852504321],
            [110.391675873229843, -7.113472337577138],
            [110.391592958149062, -7.113463945280422],
            [110.391510103057286, -7.113409994399432],
            [110.391489464270308, -7.113339558465043],
            [110.391141622664748, -7.112999482359267],
            [110.391100203370172, -7.112966294353418],
            [110.390989755518319, -7.112919858138548],
            [110.390971739196303, -7.112912283431106],
            [110.390766628684943, -7.112812613031104],
            [110.390770900540801, -7.112717359667213],
            [110.390795963896281, -7.112576575002323],
            [110.390812783781882, -7.11239850423868],
            [110.390618252197569, -7.112137321604464],
            [110.39029917388541, -7.11199608415567],
            [110.390033937352044, -7.111900476034518],
            [110.389901299998471, -7.111867167825519],
            [110.389764467175723, -7.111871129302409],
            [110.389654557853319, -7.111895834680806],
            [110.389567425734924, -7.111941278515568],
            [110.389459381653595, -7.112123370675937],
            [110.389417787496413, -7.112222716511784],
            [110.389380169992606, -7.11245046009472],
            [110.389313621437694, -7.112607756734754],
            [110.389280358086992, -7.112678121676213],
            [110.389197300920813, -7.112777412814957],
            [110.389081128127373, -7.112835243325424],
            [110.388935953165912, -7.112876468771927],
            [110.388794990199102, -7.112867999407891],
            [110.388678954292672, -7.112822287659488],
            [110.388583671780111, -7.112760036504254],
            [110.388557202170091, -7.112744255083409],
            [110.388441820776336, -7.112873570408431],
            [110.388364779242394, -7.113050887302248],
            [110.38825412197501, -7.113237749976299],
            [110.388153147995297, -7.113362289140058],
            [110.388057831343687, -7.113435799436586],
            [110.388029237148416, -7.113442683667919],
            [110.38788826307875, -7.113442497385506],
            [110.387763874196892, -7.113442332983044],
            [110.387692747319193, -7.113442238960968],
            [110.387679516880198, -7.113437413429486],
            [110.387580847045214, -7.113432698496219],
            [110.387562803061513, -7.113431836263045],
            [110.387314424442934, -7.113436930634554],
            [110.387221661247381, -7.113638047494588],
            [110.387040050557587, -7.114020761447189],
            [110.386825707746993, -7.114443610620094],
            [110.386690947099908, -7.114562067562924],
            [110.386413449995786, -7.11484247038371],
            [110.386215272843671, -7.115016206311094],
            [110.386045023517539, -7.115123132091254],
            [110.38583215147834, -7.115153220463356],
            [110.385684247397904, -7.115104431229734],
            [110.385475590868509, -7.115013042420866],
            [110.385291174530309, -7.114984451714655],
            [110.385143176273232, -7.115006527042957],
            [110.384960700692588, -7.11504272950235],
            [110.384823163428734, -7.114819828720576],
            [110.384831327902816, -7.114777320657976],
            [110.384817260442958, -7.114686190024193],
            [110.384752556591593, -7.11456664618222],
            [110.384862185226964, -7.11443721037608],
            [110.384893401376331, -7.114254412590661],
            [110.384890060737419, -7.114254862074894],
            [110.38454191907077, -7.114301704528757],
            [110.384391358768681, -7.114265685248605],
            [110.384188303877806, -7.114155569837819],
            [110.38403068962468, -7.114031187115446],
            [110.383968670857413, -7.113928423008562],
            [110.383894689561671, -7.113832806816212],
            [110.383830222938315, -7.113773022441036],
            [110.383751339491567, -7.113768141601083],
            [110.383645946054841, -7.11392321775118],
            [110.383600304852635, -7.114087925177494],
            [110.383363670310203, -7.114061342786691],
            [110.383239413200542, -7.114020582277344],
            [110.383122347045116, -7.113965503648886],
            [110.383155968460073, -7.113850927104006],
            [110.383201523733192, -7.113750694113262],
            [110.383294948336285, -7.113607541858046],
            [110.38337884553178, -7.11343810950598],
            [110.38336233755885, -7.113268543475028],
            [110.383274102130329, -7.113106045780775],
            [110.38337704434629, -7.112996337386436],
            [110.383346326614529, -7.112726458769399],
            [110.383370592554712, -7.112456653343416],
            [110.383212109259333, -7.112330186481949],
            [110.383118797455836, -7.112275789777331],
            [110.383009391897119, -7.112194235393689],
            [110.382945781224194, -7.112148923623661],
            [110.382880767728494, -7.112094564568104],
            [110.382809257732148, -7.11203115144068],
            [110.382742275472239, -7.111967744336433],
            [110.382688581316557, -7.111913400339168],
            [110.382608311007772, -7.111840930103115],
            [110.382556277430865, -7.111798884023007],
            [110.382475121792197, -7.111705646031462],
            [110.382401185806685, -7.111576598489714],
            [110.382396358773264, -7.111568363445333],
            [110.382312935050066, -7.111426040357051],
            [110.382255720495039, -7.111306566867331],
            [110.382263029373192, -7.111258122762189],
            [110.382272702062409, -7.111210145944596],
            [110.382284828188062, -7.111123780626098],
            [110.38226092220863, -7.111037367267983],
            [110.382246599144736, -7.110970162571508],
            [110.382244261130381, -7.110922169739942],
            [110.382248641578215, -7.110890398814955],
            [110.382251538084759, -7.110869390756086],
            [110.382263612902364, -7.110821417139898],
            [110.382274512295908, -7.110754246070031],
            [110.382267427709749, -7.110663056166432],
            [110.382277186940684, -7.110550293346077],
            [110.382303783562364, -7.110420756569421],
            [110.382284624172826, -7.110377540284374],
            [110.382227037018311, -7.1103294737969],
            [110.382123825325706, -7.110269349040429],
            [110.38202655806586, -7.110254822418833],
            [110.382019396558036, -7.110221220068405],
            [110.38202425214223, -7.110182834772774],
            [110.382074857323872, -7.110062927971262],
            [110.382127903088048, -7.109914230586288],
            [110.382182226825904, -7.109707947235633],
            [110.382201645860548, -7.109556805516892],
            [110.382189853198867, -7.109393624758461],
            [110.382151544131716, -7.109299993739046],
            [110.382080835175813, -7.109184724147047],
            [110.381903282802256, -7.109030920293413],
            [110.381874502152357, -7.108997289108428],
            [110.381812098116242, -7.108958814103368],
            [110.381764087616233, -7.108934755205334],
            [110.38170885148692, -7.108925083577508],
            [110.381624796234036, -7.108910574521285],
            [110.381576750453519, -7.108912909899909],
            [110.381495068454541, -7.108919999357764],
            [110.381444633382912, -7.1089127335917],
            [110.381406228213734, -7.108891086963531],
            [110.381341434947387, -7.108843010768403],
            [110.381288629862382, -7.108811746967553],
            [110.381158982384818, -7.108761184682498],
            [110.380996931913671, -7.108691383205407],
            [110.380838478260557, -7.108626385451227],
            [110.380535909932888, -7.108551597115405],
            [110.380166511411772, -7.109052595627999],
            [110.380010025609337, -7.109311530686107],
            [110.379988258446545, -7.109421877870147],
            [110.37998567292901, -7.109558645048578],
            [110.379992757064045, -7.10964983495103],
            [110.379975864923352, -7.109707399987081],
            [110.379863969484376, -7.109853618835709],
            [110.379724397936286, -7.11003819236084],
            [110.379592158244719, -7.110129195749062],
            [110.379515254536685, -7.110155487110776],
            [110.379462423688381, -7.110143418943367],
            [110.379402408985996, -7.110114544757367],
            [110.379361617802601, -7.110080897342333],
            [110.379299184876245, -7.110064017327826],
            [110.379243910033949, -7.110083139161428],
            [110.379186197614658, -7.110128652051331],
            [110.37910214870945, -7.110109343565997],
            [110.379013211756501, -7.110152415109319],
            [110.378783824097624, -7.110140110228161],
            [110.378507604533226, -7.110120543973965],
            [110.378411503066872, -7.110132412494415],
            [110.378334586403383, -7.110168301573262],
            [110.378281697519654, -7.11019942390866],
            [110.378233567722376, -7.110264145399344],
            [110.378192647541454, -7.110326477074383],
            [110.378122814592814, -7.110453556009325],
            [110.378120253901329, -7.110461195363091],
            [110.378118003867897, -7.110458348520808],
            [110.37798814653901, -7.110563751512933],
            [110.377897605969039, -7.110645285705535],
            [110.377807962457481, -7.110769920502842],
            [110.377667973796093, -7.110905659763611],
            [110.37755409967798, -7.11102839993715],
            [110.377442092083768, -7.111149280567722],
            [110.377212557230308, -7.111337035815843],
            [110.377134160586692, -7.111415135143925],
            [110.376999861636349, -7.111478263152009],
            [110.376942062656767, -7.111487495511392],
            [110.376921688297855, -7.111390643228559],
            [110.376918093154458, -7.11129195149377],
            [110.376970425347835, -7.111189610933732],
            [110.377054476931932, -7.111064968808714],
            [110.377173905783295, -7.110970166493564],
            [110.377242740339341, -7.11079054671101],
            [110.377232506598375, -7.110676745005978],
            [110.37723775306138, -7.110574361661398],
            [110.377317057926263, -7.110479560672568],
            [110.377391034588797, -7.110421661007475],
            [110.377494000209069, -7.110395436131594],
            [110.377557398608431, -7.110353340154508],
            [110.377568019426519, -7.110305900634157],
            [110.377568104603867, -7.110242629032447],
            [110.37756292551127, -7.110168805073353],
            [110.377526167912336, -7.110026394336534],
            [110.377505146339431, -7.109957821738847],
            [110.377378569734745, -7.109878562044917],
            [110.377299470918473, -7.109820456691312],
            [110.37724147176074, -7.109772924966721],
            [110.377199307994374, -7.109725414517855],
            [110.37721522859222, -7.109662164200311],
            [110.377239152086347, -7.109535652922111],
            [110.377233916235681, -7.109504010028918],
            [110.377196988339733, -7.109488142474436],
            [110.377065048224708, -7.109472147213321],
            [110.376959528820976, -7.109435096865917],
            [110.376901551061209, -7.109371747206197],
            [110.376880522499761, -7.109308447221911],
            [110.376864822132816, -7.109208245903238],
            [110.376854414433311, -7.109097506415413],
            [110.376844056471853, -7.108949858495966],
            [110.376828377433625, -7.108833839277099],
            [110.376796912794447, -7.108680890334149],
            [110.376797189896905, -7.108475257643837],
            [110.376794664355543, -7.108390891965348],
            [110.376773806365392, -7.10820104878352],
            [110.376673786002371, -7.108000553868165],
            [110.376542080882999, -7.107810561607148],
            [110.376468388896868, -7.107657555852747],
            [110.376421053582078, -7.107530948758253],
            [110.376421281048763, -7.107362224503432],
            [110.376453086689651, -7.107262087077491],
            [110.376521770254314, -7.107214725674105],
            [110.376661691294061, -7.107183277980839],
            [110.376772545433283, -7.107178154382195],
            [110.377004817673409, -7.107162648658432],
            [110.377084058032722, -7.107115301352287],
            [110.377110556683519, -7.107036247308649],
            [110.377131783995864, -7.106951913536725],
            [110.377110769682929, -7.106878068295587],
            [110.377095111901781, -7.106746231174351],
            [110.37710055362335, -7.106624967688603],
            [110.377121922899633, -7.106435181236445],
            [110.377159070621786, -7.106287597125087],
            [110.377248981212148, -7.106155901811041],
            [110.377375918729072, -7.105966257116734],
            [110.377386567808571, -7.105897727047347],
            [110.377391973946644, -7.105802826717851],
            [110.3774159937729, -7.105733812359157],
            [110.377475132835954, -7.105657719557836],
            [110.377588358285593, -7.10551513642771],
            [110.37767804654176, -7.105430041803547],
            [110.377784928190579, -7.105246972971033],
            [110.377853352336359, -7.10511498151573],
            [110.377874874154386, -7.104970144872674],
            [110.377887830773759, -7.104850861239471],
            [110.37788373149516, -7.104727293973318],
            [110.377883880433899, -7.104616514657568],
            [110.37795448010182, -7.104452570461644],
            [110.378018638663605, -7.104320573241793],
            [110.378035820526492, -7.104231120518707],
            [110.378035935058861, -7.104145905656197],
            [110.378031807097813, -7.104043642100486],
            [110.378031921627866, -7.103958427237353],
            [110.378053368865139, -7.10386898023095],
            [110.378100585961377, -7.103647484498672],
            [110.378171133854309, -7.103521886933688],
            [110.378243840161261, -7.103377118867418],
            [110.378320708792089, -7.103309049885367],
            [110.378457378703231, -7.103177149757797],
            [110.378551388922958, -7.103049453181062],
            [110.378690405866323, -7.10275777793191],
            [110.378703528021532, -7.102514932666051],
            [110.37871223607884, -7.102382861027945],
            [110.378733734555553, -7.102255067269907],
            [110.378751059129726, -7.102059095891573],
            [110.378755581828329, -7.101867368118329],
            [110.378745224300715, -7.101639404026545],
            [110.378753943743632, -7.101498810891843],
            [110.378818113038434, -7.101358292000808],
            [110.378954793627685, -7.101217870164529],
            [110.379206536327388, -7.101154295789785],
            [110.379274833700393, -7.101116040397363],
            [110.379287675517304, -7.101081971559066],
            [110.379249372864905, -7.101018009029845],
            [110.379179122794611, -7.100922048109905],
            [110.379013019099418, -7.100738613482672],
            [110.378791340001271, -7.10064884094934],
            [110.378766665870288, -7.100637125397631],
            [110.37867630157389, -7.100626811478157],
            [110.378475702760966, -7.100642360756971],
            [110.378290896533059, -7.100689566983954],
            [110.378222249558519, -7.100710565581094],
            [110.378032164927447, -7.100757764626848],
            [110.377776114953903, -7.100794329842895],
            [110.377649434352819, -7.100794159941606],
            [110.377596594063988, -7.100836270224406],
            [110.37739033390352, -7.10113653425406],
            [110.377295224100635, -7.101210223639962],
            [110.377210791577227, -7.101194292376625],
            [110.377147479603352, -7.1011731168079],
            [110.377094795302369, -7.101099229053609],
            [110.377063408915149, -7.100888281130259],
            [110.377053050841837, -7.100740633173833],
            [110.376990058209614, -7.100482189010213],
            [110.376898254666202, -7.100060254170526],
            [110.376798285373226, -7.099822850915263],
            [110.376624249009069, -7.099711891623759],
            [110.376465962563103, -7.099664225167985],
            [110.376291834021529, -7.099621810013208],
            [110.376175831218092, -7.099532019139353],
            [110.376107425881742, -7.099373747859654],
            [110.376023277930955, -7.099146911034863],
            [110.375915313596252, -7.098967495969726],
            [110.375773097028642, -7.098745853667069],
            [110.375641337737264, -7.098598042410446],
            [110.375661059065081, -7.098425001545023],
            [110.37573419653927, -7.098240647056701],
            [110.375870635058703, -7.097959297213241],
            [110.37602648424182, -7.097697389497532],
            [110.376299007453639, -7.097396806302134],
            [110.376415969169585, -7.097144554210722],
            [110.376401783504889, -7.09685329373777],
            [110.37622756978223, -7.096319117098128],
            [110.376111817501837, -7.095673376430579],
            [110.376170762178177, -7.095202615327492],
            [110.376374999825615, -7.095091247117302],
            [110.376600687130647, -7.094968756830291],
            [110.376443447920281, -7.094751013879365],
            [110.376239660295212, -7.094546896330613],
            [110.375981817301835, -7.09465983485484],
            [110.375839047885364, -7.094849458196184],
            [110.375764955316257, -7.095000862229436],
            [110.375738483364614, -7.095054956156702],
            [110.375314426459241, -7.095895372611974],
            [110.375109376729171, -7.09627736335949],
            [110.374920108208443, -7.096560059737243],
            [110.37443926011666, -7.096399229344429],
            [110.373919571338533, -7.096214075998167],
            [110.373387633335767, -7.096050955496623],
            [110.373308307275735, -7.096162892072836],
            [110.373275184153954, -7.0962617093985],
            [110.373294808386333, -7.096386961135202],
            [110.373314495036837, -7.096466077338658],
            [110.373294531988549, -7.096591275636352],
            [110.373195260548599, -7.096815228892626],
            [110.37314561587101, -7.096933796298877],
            [110.373171775539475, -7.097105192476132],
            [110.373184480794023, -7.097467703743614],
            [110.373177472617968, -7.097770871169315],
            [110.373163857591905, -7.098080620469643],
            [110.373163313459855, -7.098482658640497],
            [110.373043623144838, -7.099167940325909],
            [110.372881420747547, -7.099576350838967],
            [110.372709321142253, -7.099984747884488],
            [110.372662921321634, -7.100142864373181],
            [110.372669313871057, -7.100294461428896],
            [110.372725280236821, -7.100380217497607],
            [110.372804303689847, -7.100492367930538],
            [110.372850310701409, -7.10062424613023],
            [110.372955734913816, -7.100729841425422],
            [110.3730677660418, -7.100828854826709],
            [110.373126835182035, -7.101059612669961],
            [110.373132916296711, -7.101121199068547],
            [110.373085634844713, -7.101350521613904],
            [110.373013571945449, -7.101589296377519],
            [110.372901545846062, -7.101907641148106],
            [110.372837464585871, -7.102138464248209],
            [110.372781321943293, -7.102393185278079],
            [110.372749049257138, -7.102679788187258],
            [110.372732924852514, -7.102762936713346],
            [110.372723317468598, -7.102812478978148],
            [110.372780628851473, -7.102832976167272],
            [110.372873683861073, -7.102859506444155],
            [110.373100613651744, -7.102869128520969],
            [110.373391293501342, -7.102917926181669],
            [110.373386802092739, -7.103252720643157],
            [110.373491373111563, -7.103563459985501],
            [110.373599753073862, -7.104043621592253],
            [110.373657836582353, -7.104390601792677],
            [110.373778604237458, -7.104669093038036],
            [110.373790511236649, -7.104822391287417],
            [110.373745813178076, -7.105028051730267],
            [110.373543550713464, -7.105289971697109],
            [110.37323620858642, -7.105616289379698],
            [110.373011746639534, -7.105870111570601],
            [110.372781221737327, -7.106127959167247],
            [110.372680174793572, -7.10619639604402],
            [110.372397235536212, -7.106393666392399],
            [110.372005172583641, -7.106659362317889],
            [110.371867497129202, -7.106937503833388],
            [110.371818946028881, -7.107006011618716],
            [110.371814475370769, -7.107017501815497],
            [110.371810287989803, -7.107013028031806],
            [110.371519501180899, -7.107383421528419],
            [110.371258909052329, -7.107522096485115],
            [110.37109727947211, -7.107521877231239],
            [110.370917806947517, -7.107436422800679],
            [110.370734058326619, -7.107193995046481],
            [110.370572795030697, -7.106924689056787],
            [110.370326087179478, -7.106758417017839],
            [110.370209391552905, -7.106731349800764],
            [110.370097926188805, -7.106757126314737],
            [110.369803020184847, -7.106996170026078],
            [110.369635230887368, -7.107203652784632],
            [110.369571465961968, -7.107370888723208],
            [110.369582797938421, -7.107532457036552],
            [110.369695223213256, -7.107685508202238],
            [110.369654582236535, -7.107838351221656],
            [110.3694606310127, -7.108169847842665],
            [110.369252341556376, -7.108426318144781],
            [110.369032562646069, -7.108636614724166],
            [110.369020262568171, -7.109184723806627],
            [110.368955971281721, -7.109254385178529],
            [110.368760160695572, -7.109314188874823],
            [110.368537577197074, -7.10932585583538],
            [110.368292347441667, -7.109276048102354],
            [110.368171128092641, -7.10921818540983],
            [110.368143662684716, -7.109075578389596],
            [110.368452139795068, -7.108581011619517],
            [110.368481162090703, -7.108477195753289],
            [110.368472647645646, -7.10836755899562],
            [110.368383307320101, -7.108228963379066],
            [110.368334293288697, -7.108168314263529],
            [110.36834592426942, -7.108110632663715],
            [110.368395095884537, -7.108055887091052],
            [110.368476106877523, -7.107949257185663],
            [110.368531267235028, -7.107738736631641],
            [110.368499719338772, -7.107577140785468],
            [110.368473853008979, -7.107484789622025],
            [110.368372471191705, -7.107424144581909],
            [110.368261678603957, -7.107436288528124],
            [110.368029072497407, -7.107427192670221],
            [110.3678615982503, -7.107340040312184],
            [110.367666139095491, -7.107291482619321],
            [110.367416989245868, -7.107224608275749],
            [110.367292258299628, -7.107400645785146],
            [110.367048226970709, -7.107562673795958],
            [110.366826435731113, -7.107669381652775],
            [110.366512206463113, -7.107838693510904],
            [110.366175873167592, -7.107963694605017],
            [110.365894986361809, -7.108062941117534],
            [110.365599469901341, -7.10805515670072],
            [110.365241275620093, -7.107958725673278],
            [110.364919946309868, -7.107917695259747],
            [110.364923903859918, -7.107725819403863],
            [110.364920432712225, -7.107563453565604],
            [110.364876534457963, -7.107249741329703],
            [110.364758562229341, -7.107076148530475],
            [110.364599843780979, -7.106983680401679],
            [110.364370968454494, -7.106868975891756],
            [110.364149430669002, -7.106791181583965],
            [110.364208794457582, -7.106603071763258],
            [110.364283005455249, -7.106363321946757],
            [110.364290672476258, -7.106160381118526],
            [110.364268802179041, -7.105946329715961],
            [110.364195184590884, -7.105754347503112],
            [110.364040531028579, -7.105392513025402],
            [110.363922840055167, -7.105100967532075],
            [110.36388635376008, -7.10501666554108],
            [110.363748695513451, -7.104780571898463],
            [110.363585849112312, -7.104529865273538],
            [110.36352606120299, -7.104479687606388],
            [110.363487782300325, -7.10444756160272],
            [110.363392154638873, -7.104373113394799],
            [110.363340718531774, -7.104162462903972],
            [110.363311366906515, -7.103900106613702],
            [110.363311839792544, -7.103556382850375],
            [110.363238692402646, -7.103040695811139],
            [110.363268715952785, -7.102814602538777],
            [110.363372238444782, -7.102642882416387],
            [110.363461291791751, -7.102498278548409],
            [110.363491165915093, -7.102380729600253],
            [110.363491277860717, -7.102299321332803],
            [110.363461739594854, -7.10217264548043],
            [110.363461839103792, -7.102100282575813],
            [110.363476727948409, -7.101973667682264],
            [110.363595754637643, -7.101639151903337],
            [110.363713363291652, -7.101512677888949],
            [110.363831671643425, -7.101494749370411],
            [110.363905602652835, -7.101440578454004],
            [110.363964943229988, -7.101295933708936],
            [110.363979869173221, -7.10114218268723],
            [110.363995264467263, -7.100852751583675],
            [110.363950999022862, -7.100735100944949],
            [110.363847591966206, -7.10061736922221],
            [110.36368506820871, -7.100481465651708],
            [110.363552381168105, -7.100255149147238],
            [110.363449111104728, -7.100037918349429],
            [110.363345953061625, -7.099739279261138],
            [110.363333393357635, -7.099612626697559],
            [110.363362934543915, -7.099531258796955],
            [110.363451937472476, -7.099422836315015],
            [110.363600156406349, -7.099260222730691],
            [110.363688925967708, -7.099115618345849],
            [110.363704122412017, -7.0989709130733],
            [110.363704346118482, -7.098808096507475],
            [110.363645329010097, -7.098717561773905],
            [110.363467758634698, -7.098617819075612],
            [110.363260992376055, -7.09855421778755],
            [110.363201605353552, -7.098527000167207],
            [110.363098419722533, -7.098454495534886],
            [110.362995013357221, -7.098336763675302],
            [110.362877365284817, -7.097875287780634],
            [110.362774407080593, -7.097431922794776],
            [110.362683501568469, -7.097278026525218],
            [110.362373358381092, -7.096870558513845],
            [110.361945008314095, -7.096408655615924],
            [110.361767946477556, -7.096146096235002],
            [110.361709054682393, -7.095965107685295],
            [110.361709503300858, -7.095639474637385],
            [110.361798754943649, -7.095350145167489],
            [110.361932124995477, -7.095078967052977],
            [110.361947013856735, -7.094952352187682],
            [110.361917734370223, -7.094843767391875],
            [110.361873407626518, -7.094771343438715],
            [110.361873631842542, -7.094608526901746],
            [110.361895495308829, -7.09455428466264],
            [110.362007371742564, -7.094274031578058],
            [110.362051922607534, -7.094183638971969],
            [110.362052084483025, -7.094066049243864],
            [110.361963393731514, -7.093948337455554],
            [110.361874678114134, -7.093848716380734],
            [110.361682690941564, -7.093739907987326],
            [110.361313185739135, -7.093495174742745],
            [110.361224778253998, -7.093377463226149],
            [110.361180539016473, -7.093241721687101],
            [110.361165828376954, -7.093033657721933],
            [110.361166090187041, -7.092843705107491],
            [110.361166351989937, -7.092653752491489],
            [110.36116653898759, -7.092518072050532],
            [110.361300066764912, -7.092337348064912],
            [110.361655233915869, -7.092102656363706],
            [110.361788649301673, -7.092003340483343],
            [110.361818227503605, -7.091894836543072],
            [110.361759360986653, -7.091695757285147],
            [110.361730143951121, -7.091541945664088],
            [110.361730243579871, -7.091469582750901],
            [110.361730430380959, -7.091333902288063],
            [110.3617009179452, -7.091189135641166],
            [110.361464817278389, -7.090854132113376],
            [110.361317285927967, -7.09051925024376],
            [110.361228683203237, -7.090338220828732],
            [110.361236656148748, -7.090302050265798],
            [110.361262530830189, -7.090211632025644],
            [110.361313589631237, -7.090121248399054],
            [110.361450117118039, -7.090021936841349],
            [110.361825223637865, -7.089895816808145],
            [110.362046973984732, -7.089760440560611],
            [110.362200504154913, -7.089643061333695],
            [110.362286059862797, -7.089570815687497],
            [110.362371233076232, -7.089570932563068],
            [110.362439403368839, -7.089589116862232],
            [110.362515988083501, -7.089661584994896],
            [110.362592547954947, -7.089752143849565],
            [110.362660693420366, -7.089788418852203],
            [110.362762844738583, -7.089788558945292],
            [110.362831052370169, -7.089779607090779],
            [110.363035799503777, -7.089662297794181],
            [110.363112583105277, -7.089590039960774],
            [110.363223297941403, -7.089535919377874],
            [110.363274515061804, -7.089535989556767],
            [110.363598205084898, -7.089554523724245],
            [110.364075558954383, -7.089564222589351],
            [110.364271729893588, -7.089510218679798],
            [110.364535825397951, -7.089447262184079],
            [110.364612608749241, -7.089375004078145],
            [110.364655423589809, -7.089311744905429],
            [110.364724015261203, -7.089022386282714],
            [110.364673343406366, -7.088624319971554],
            [110.36462238660279, -7.088434297177121],
            [110.364472823152695, -7.088135594913616],
            [110.364438720520369, -7.088036049023684],
            [110.364413365075819, -7.087954605867671],
            [110.364362358847785, -7.087800764536312],
            [110.36429427548974, -7.087719262943597],
            [110.364226154960974, -7.087664897452924],
            [110.364183833985422, -7.087574385696255],
            [110.364141354052492, -7.087393419849902],
            [110.364107559299512, -7.087275783590799],
            [110.36409050916113, -7.08712198868856],
            [110.364112508337044, -7.086968247202077],
            [110.364089982652473, -7.086886807905595],
            [110.364063212486187, -7.086805362802193],
            [110.364027203657429, -7.086651541962905],
            [110.363955220999969, -7.086524808067437],
            [110.363978044269672, -7.086389158486474],
            [110.364081870062307, -7.086199347381704],
            [110.364077466597266, -7.0861088874853],
            [110.364002753537861, -7.085909786767618],
            [110.364007749935951, -7.085774112792248],
            [110.364026820195875, -7.085692730391001],
            [110.364074752894908, -7.085611387464281],
            [110.36416067727599, -7.085475824159845],
            [110.364256135660895, -7.085403591585496],
            [110.364327800171793, -7.085349417236698],
            [110.364380272610475, -7.085259035087593],
            [110.36442345674466, -7.085132458681374],
            [110.364442514507459, -7.085060121623449],
            [110.364442601232085, -7.084996804023268],
            [110.364428626442177, -7.084870149484746],
            [110.364404673556749, -7.084797753638071],
            [110.364409607850362, -7.084707306498061],
            [110.364447969126132, -7.084589768878112],
            [110.364548299605062, -7.084472315935924],
            [110.364619742891179, -7.084372914277419],
            [110.364681873087449, -7.084255409103712],
            [110.364748875551811, -7.084092683627474],
            [110.364792170875816, -7.083884698809621],
            [110.364806787747625, -7.083749037931502],
            [110.364808425402586, -7.08358622315654],
            [110.364803726431205, -7.083504808232759],
            [110.364801710307489, -7.083323897686844],
            [110.364806632103821, -7.083242495901444],
            [110.364818873817853, -7.083188240281221],
            [110.364840736294227, -7.083133997798407],
            [110.364869918687944, -7.083106901479094],
            [110.364947783205622, -7.083070826238231],
            [110.365030408596994, -7.083070939043203],
            [110.365088699113628, -7.083071018615966],
            [110.365172997546253, -7.083089224460144],
            [110.365231250955418, -7.083116440136015],
            [110.365296873798172, -7.083134620471045],
            [110.365335922792354, -7.083134673755984],
            [110.365389402937041, -7.083134746727863],
            [110.365438097431806, -7.083116722382898],
            [110.365469506405006, -7.083116765233137],
            [110.365530331241246, -7.083125893599425],
            [110.365600789211101, -7.083125989706129],
            [110.365661626414777, -7.083126072681242],
            [110.365749099292287, -7.083099055796418],
            [110.365834296092515, -7.08308108118244],
            [110.365890039946024, -7.083081157182643],
            [110.36594350773376, -7.083090275465113],
            [110.36600433258144, -7.083099403769942],
            [110.366062586049864, -7.083126619346119],
            [110.366140339320921, -7.083171952274276],
            [110.366203686156211, -7.083199174775437],
            [110.366297863707686, -7.083235484667877],
            [110.366403089214145, -7.083262764200335],
            [110.366534089139435, -7.083271988029664],
            [110.366648123583019, -7.083272143327615],
            [110.366737564853622, -7.083254174327797],
            [110.366787674140866, -7.083236151764398],
            [110.366829023884478, -7.083209071877548],
            [110.366848573068708, -7.083191007703597],
            [110.366870410639365, -7.083154855857216],
            [110.366887437821802, -7.083118697461655],
            [110.366894905922649, -7.083037299087184],
            [110.366912351773649, -7.082901641932476],
            [110.366951302843887, -7.082766014044144],
            [110.366978024779016, -7.082675596475453],
            [110.367014650424252, -7.082585192381158],
            [110.36703905928961, -7.082530953233789],
            [110.367056160434942, -7.082440522565619],
            [110.367056283771461, -7.082350068794879],
            [110.367049308357053, -7.082277696152532],
            [110.36704353879793, -7.082151052787323],
            [110.367061464145365, -7.082078714025633],
            [110.367076239893976, -7.082033507159409],
            [110.367120726767567, -7.081988340714446],
            [110.367168338557263, -7.081934133121186],
            [110.367266034798561, -7.081879993646427],
            [110.367405314259628, -7.08183495609161],
            [110.367500156177712, -7.081798903471522],
            [110.367568362591456, -7.081789950806343],
            [110.36760370832647, -7.081808089646546],
            [110.36764216665803, -7.081826232715312],
            [110.367692497121453, -7.081853437313224],
            [110.367811021697463, -7.081880734584701],
            [110.367908643967453, -7.081880867239873],
            [110.367991847435263, -7.08187193489007],
            [110.368030367358642, -7.081844851033573],
            [110.368074915691366, -7.081754457591661],
            [110.368125393916671, -7.08167311759389],
            [110.368175872123516, -7.081591777588794],
            [110.368218132078454, -7.081519471812071],
            [110.368244189283303, -7.081501416405211],
            [110.368275622780942, -7.081483368297163],
            [110.368343509259105, -7.081501551267587],
            [110.368425273231523, -7.081510707672023],
            [110.368467422400215, -7.081519810287436],
            [110.368584593652884, -7.081501878537643],
            [110.368694961489211, -7.081492982922387],
            [110.368763143252366, -7.081502120836714],
            [110.368820277167103, -7.081520289149676],
            [110.368888139085996, -7.081556562804183],
            [110.368930534347427, -7.08159280190468],
            [110.369004596883968, -7.081647174749849],
            [110.369037396007101, -7.081665310031025],
            [110.369082091873864, -7.081674416047005],
            [110.369131868785672, -7.08169257434688],
            [110.369242150639622, -7.081746996273114],
            [110.369407229074724, -7.081873855640954],
            [110.369458666944283, -7.081919152354534],
            [110.369584524157389, -7.081964549920007],
            [110.369708388373468, -7.082018990151656],
            [110.369763566214715, -7.082019064909595],
            [110.369804878855206, -7.082019120877921],
            [110.369828979850837, -7.081982971932196],
            [110.369854815457032, -7.08191968913816],
            [110.369870427546758, -7.081883528690423],
            [110.369905022722079, -7.081829303156272],
            [110.369955500653603, -7.08174796293252],
            [110.36998619570106, -7.081648505112684],
            [110.370033868514724, -7.081549070279601],
            [110.370115447772179, -7.081485862945319],
            [110.3703102783806, -7.081359418747033],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 95,
        id: 97,
        area: 5847312.032102,
        perimeter: 11793.187826,
        kode_pos: 50229,
        kode_kec: 3374020,
        kode_kel: 3374020013,
        kec: "Gunungpati",
        kel: "Sekaran",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.378138113603001, -7.037138012370053],
            [110.378281941765863, -7.037065737101601],
            [110.378532617248865, -7.036858025071312],
            [110.378714563067291, -7.036632130812692],
            [110.378874439056105, -7.03627957114517],
            [110.379051087969955, -7.036334078072938],
            [110.379157411830036, -7.036379446247457],
            [110.379205167938778, -7.036424736737574],
            [110.379231727881177, -7.036451908253315],
            [110.379237067616259, -7.036479051629776],
            [110.379239848909378, -7.036515237045588],
            [110.379226514857734, -7.036542355659844],
            [110.379183731404012, -7.036587526083675],
            [110.379133296644511, -7.036641731786844],
            [110.379101240710142, -7.036705007292256],
            [110.379095816840064, -7.036741181824701],
            [110.379109066765452, -7.036777381125352],
            [110.37915959217365, -7.036867902453916],
            [110.379260444232116, -7.03698562681271],
            [110.379354812832432, -7.037085251693472],
            [110.379415313187337, -7.037121513634002],
            [110.379460005146427, -7.037130618314796],
            [110.37949622102424, -7.037130666323598],
            [110.379613675725068, -7.037103685708642],
            [110.379795711986759, -7.037022518038213],
            [110.379864489567737, -7.037004518293074],
            [110.379896744327695, -7.037004561022417],
            [110.37992925801511, -7.037022694958313],
            [110.379953271605473, -7.037049863065954],
            [110.379961428748288, -7.037086055602534],
            [110.379953163566924, -7.037131271818994],
            [110.37991260759577, -7.037203581558708],
            [110.379860192284681, -7.037257784716671],
            [110.379827594535627, -7.03730296869199],
            [110.379839688771028, -7.037357257311401],
            [110.379940565118901, -7.037456890711789],
            [110.38000133650425, -7.037502198375929],
            [110.380061836967826, -7.037538460241367],
            [110.380118412331058, -7.037547580602573],
            [110.380179243750362, -7.037547661159946],
            [110.380248045399242, -7.037511570529628],
            [110.380373542368957, -7.037394146042462],
            [110.380438394862722, -7.037349004719332],
            [110.380527332944922, -7.037276758960632],
            [110.380608360765663, -7.037195457281478],
            [110.380689340597783, -7.037150337258566],
            [110.3808430351217, -7.037105313406146],
            [110.380927940074301, -7.037087334833831],
            [110.380984527394048, -7.037087409668446],
            [110.381049307892852, -7.03709654076457],
            [110.381089743863512, -7.037114685099499],
            [110.381150208432658, -7.037178083084939],
            [110.381181012587064, -7.037205260113756],
            [110.381218631185945, -7.037214355279155],
            [110.381254281208967, -7.037214402404468],
            [110.381286253055237, -7.037214444665337],
            [110.381362976808404, -7.037178364330186],
            [110.381398638806104, -7.037169366024812],
            [110.38143061065017, -7.037169408275552],
            [110.381464539100776, -7.037187543980336],
            [110.381498455579617, -7.03721472510249],
            [110.381526996264753, -7.037241899119641],
            [110.381551870749206, -7.037260022855947],
            [110.38158213300693, -7.037269108273677],
            [110.381607031440325, -7.0372691411678],
            [110.381639015264412, -7.03726013798507],
            [110.38169791398974, -7.037224034045663],
            [110.381774733471659, -7.037115590279542],
            [110.381848157677638, -7.037007142013657],
            [110.381873211667639, -7.036889584427787],
            [110.381889386915461, -7.036853424041535],
            [110.381919697019157, -7.036826327753973],
            [110.381989052353433, -7.036799283013377],
            [110.382098018785967, -7.036772290548734],
            [110.38223305124086, -7.036718196146419],
            [110.382378847207832, -7.036655070466144],
            [110.382443416486893, -7.036609928464641],
            [110.382532660984779, -7.03651959181326],
            [110.382601474275702, -7.036474455384569],
            [110.382682417963423, -7.036456471253574],
            [110.382763337748514, -7.036456577952173],
            [110.382848254484685, -7.036429553592089],
            [110.382896672430959, -7.036402481104761],
            [110.382941376364656, -7.03640254003096],
            [110.382993695690161, -7.036420699866368],
            [110.383046286013169, -7.036447905491702],
            [110.383114979830225, -7.036493223208886],
            [110.38317972449255, -7.036529490275121],
            [110.383236299829122, -7.036538610254505],
            [110.383296860155241, -7.036529644599573],
            [110.383345549089213, -7.036511617860344],
            [110.383394285766244, -7.036457409423141],
            [110.383446760281174, -7.036357978707637],
            [110.383483330670629, -7.036303754235296],
            [110.383519877183687, -7.036267620606116],
            [110.383560384798159, -7.036231492189311],
            [110.383633111338781, -7.0362225425064],
            [110.383759017957544, -7.036222708256242],
            [110.383850171132181, -7.036186646474862],
            [110.383954681920954, -7.036105375052113],
            [110.384019852629706, -7.036033097283734],
            [110.384071972490361, -7.035987938655363],
            [110.384150086696778, -7.035969950535639],
            [110.38426724607838, -7.035952013755447],
            [110.384423745485023, -7.035925083231986],
            [110.384547436207001, -7.035889064085385],
            [110.384645120618615, -7.03583491984124],
            [110.38476883512179, -7.035780809780946],
            [110.384899045220124, -7.035735753660917],
            [110.385010027554074, -7.035681626804426],
            [110.385094661115119, -7.035654601640775],
            [110.385146733231949, -7.035645624585181],
            [110.385218316027817, -7.035645718585539],
            [110.38532237697035, -7.03569108242753],
            [110.385393959775541, -7.035691176401639],
            [110.385530924584671, -7.035673265293512],
            [110.38570185359545, -7.035646353265775],
            [110.385827194236128, -7.035646517703504],
            [110.385916578235978, -7.035664725834967],
            [110.386014980543152, -7.035710082107127],
            [110.386068431687008, -7.035728243087244],
            [110.386158110533529, -7.035737406117573],
            [110.386260816324153, -7.035737540764132],
            [110.386341441224999, -7.035746691891042],
            [110.386484240815534, -7.035810197148205],
            [110.386587193943484, -7.035837468381834],
            [110.386743657632891, -7.035837673355083],
            [110.386869033954184, -7.035810701230401],
            [110.386985320709726, -7.035810853504787],
            [110.38707017782616, -7.035829055495066],
            [110.387146240203052, -7.03586533684879],
            [110.387208745377833, -7.035883509555646],
            [110.387315954480798, -7.035901740758901],
            [110.38737422748396, -7.035910862460444],
            [110.387441200369182, -7.03597426821244],
            [110.387494922691772, -7.036001474837406],
            [110.38766466076251, -7.036019787766337],
            [110.387754080488875, -7.036010859266819],
            [110.387888487086656, -7.036001989570375],
            [110.388355001726197, -7.036038781073573],
            [110.389005801780172, -7.036220539833828],
            [110.389258322210679, -7.036329414715529],
            [110.389763280758984, -7.036610482136759],
            [110.389957233674707, -7.036719280321356],
            [110.390229359913207, -7.036764862118003],
            [110.390559712235415, -7.036855746821447],
            [110.390715009247657, -7.036883085329724],
            [110.390870683559896, -7.036838060670603],
            [110.390948208291888, -7.036838161542126],
            [110.39111538868643, -7.036865515380024],
            [110.391378284301851, -7.03682967545167],
            [110.39168474040936, -7.036802937432968],
            [110.391976802024914, -7.036749044155693],
            [110.392181270798474, -7.036604582434402],
            [110.392444142049357, -7.036369741873012],
            [110.392969919943368, -7.036090014830378],
            [110.393451677715404, -7.035936866492181],
            [110.393889874721452, -7.035774615782607],
            [110.394488402640121, -7.035684935676643],
            [110.394984802048, -7.035586077365827],
            [110.395633292395658, -7.035586914909579],
            [110.396027376149334, -7.035623605280984],
            [110.396523553365768, -7.035696608772896],
            [110.39697582891003, -7.035805737053967],
            [110.397442887394305, -7.035860611168771],
            [110.397720142079919, -7.035879058844098],
            [110.398187045992543, -7.035834431934093],
            [110.398406073978293, -7.035807577035847],
            [110.398891760040556, -7.035898655512495],
            [110.399154561838074, -7.035935174717836],
            [110.399489973854386, -7.036053196107937],
            [110.400452956451517, -7.036380067124883],
            [110.401167602620347, -7.036643300383834],
            [110.401462769113508, -7.036815541766418],
            [110.401623102013232, -7.036888110489215],
            [110.401929563195651, -7.037078456978235],
            [110.402527409653416, -7.037522448456749],
            [110.40290640177183, -7.037857614469067],
            [110.403052236078338, -7.037984437073455],
            [110.403139421318102, -7.038174503142943],
            [110.403168263498372, -7.038409722155213],
            [110.403284828255366, -7.038636007552839],
            [110.403386680297956, -7.038862274172272],
            [110.403477059006022, -7.038989025973287],
            [110.403642474347336, -7.039070646034941],
            [110.403757878818709, -7.039097929499247],
            [110.403873589333969, -7.03910712238349],
            [110.403959445525345, -7.039156991628178],
            [110.404099155418791, -7.039279273628932],
            [110.404192762122236, -7.039315574705873],
            [110.404443910209551, -7.039368502090519],
            [110.404882505687041, -7.039429105887696],
            [110.404890722595752, -7.039430241271276],
            [110.404819614449337, -7.039679366783623],
            [110.404714758059086, -7.039884852760088],
            [110.404562669689597, -7.040086907791465],
            [110.40448147416798, -7.040258715715783],
            [110.404423875193743, -7.040447407701334],
            [110.404447295554846, -7.040602494722391],
            [110.404551688050574, -7.040761055611869],
            [110.404696581732409, -7.040909555592177],
            [110.404908882876143, -7.04111544503548],
            [110.40507396507877, -7.041307791114228],
            [110.405129097478351, -7.04143166217172],
            [110.405159734541797, -7.041560558398759],
            [110.405167233936851, -7.041718273846723],
            [110.40518224743019, -7.042022165302281],
            [110.405195573520373, -7.042139500058411],
            [110.405187694333208, -7.042279886763274],
            [110.405150892370855, -7.042456778298619],
            [110.405100969732118, -7.042627207035054],
            [110.405092887521405, -7.042654798530394],
            [110.405005950004096, -7.042897016526476],
            [110.404911285084737, -7.04316038033337],
            [110.404862983908956, -7.043296869099034],
            [110.404841621625053, -7.043443008351488],
            [110.404841678295938, -7.043545712747747],
            [110.404922380177965, -7.043761547199269],
            [110.404980877899476, -7.043948285615001],
            [110.405080994743841, -7.044396303751559],
            [110.405159651001696, -7.044728217798933],
            [110.405238280503269, -7.045081199358173],
            [110.405270593328041, -7.045207167555079],
            [110.405262601284306, -7.045436023254823],
            [110.405216173532068, -7.04561290250904],
            [110.405135094040531, -7.04578973766674],
            [110.405059855975281, -7.0459146526914],
            [110.404968114789995, -7.046097365065023],
            [110.404936272195457, -7.04621067473997],
            [110.404953475426893, -7.046306858755911],
            [110.405003390595297, -7.046414623860524],
            [110.405072996674406, -7.046534650263143],
            [110.405187856650329, -7.046684112542593],
            [110.405364713208755, -7.046882431515317],
            [110.405747362076994, -7.047232948257299],
            [110.406003035595575, -7.047519836377572],
            [110.406083514038869, -7.047648858982495],
            [110.406158599169174, -7.047810443685592],
            [110.406194965589719, -7.047975888758114],
            [110.406199160072163, -7.048137216336288],
            [110.406164368068744, -7.048469025503793],
            [110.406103392536096, -7.048821790183919],
            [110.40608088136841, -7.049170285828721],
            [110.406039156656533, -7.049506181405761],
            [110.405914755401966, -7.049906804046856],
            [110.405822599169156, -7.050131370321486],
            [110.405772685244074, -7.050283828820986],
            [110.405760728388884, -7.050407584142713],
            [110.405795968092576, -7.050531399488452],
            [110.405904263340332, -7.050651727454371],
            [110.406111320761653, -7.050875886728672],
            [110.406253343340254, -7.051128877876214],
            [110.406438704631412, -7.051589971207221],
            [110.406505836524204, -7.051784303988442],
            [110.406578773203847, -7.051955565227473],
            [110.406683376095401, -7.052081901924587],
            [110.406778692061096, -7.052186608254479],
            [110.406886327582455, -7.052321371913429],
            [110.406918922533805, -7.05242526839776],
            [110.406893931048373, -7.05254506762881],
            [110.406826753151762, -7.052676084177297],
            [110.406723327226729, -7.052768003056874],
            [110.406603212393819, -7.052809691489427],
            [110.406374211577088, -7.052845662712478],
            [110.406125690699412, -7.052862083195937],
            [110.405941367473943, -7.052895321605392],
            [110.405782123619119, -7.052967643455451],
            [110.405684231910669, -7.053098620729783],
            [110.405622602291388, -7.053257538132325],
            [110.405627919792806, -7.053466749934807],
            [110.405691693400783, -7.053654998975703],
            [110.405807433487823, -7.053808473126722],
            [110.406021088611453, -7.054007745235683],
            [110.406163008955843, -7.054168362084236],
            [110.406251292981565, -7.054333501698245],
            [110.406296670955555, -7.054535941018435],
            [110.406321510309823, -7.054787416206477],
            [110.406312812601385, -7.055015738451163],
            [110.406320823008969, -7.055303056897848],
            [110.406379259057431, -7.055459337628716],
            [110.406496323113259, -7.055621271685443],
            [110.406550304712383, -7.055801446533095],
            [110.406561766204561, -7.056066683637011],
            [110.406534432606065, -7.056391194694778],
            [110.406522596008287, -7.056915586946214],
            [110.406520148093037, -7.057175170194512],
            [110.406477226555168, -7.057593079127733],
            [110.406434569472651, -7.057859735525125],
            [110.406361843657152, -7.058170612700572],
            [110.406235691461603, -7.058566547055385],
            [110.406120949546747, -7.058772816667834],
            [110.40603129433579, -7.059012590971919],
            [110.405975130809637, -7.05926356548219],
            [110.405864382536052, -7.059638008625861],
            [110.405764004696508, -7.059849437382897],
            [110.405681078320114, -7.059962803019968],
            [110.405583226835262, -7.060046887769825],
            [110.405348520487493, -7.060174901095655],
            [110.405170992360624, -7.060276585957025],
            [110.405054845830009, -7.060398580751132],
            [110.40503876695675, -7.060434339383512],
            [110.404932531519066, -7.060473867653135],
            [110.404802338893049, -7.060500837984159],
            [110.404700193201379, -7.060500707669781],
            [110.404597787691358, -7.060482486072956],
            [110.404467676030123, -7.060446138163646],
            [110.404328226976745, -7.06040977829908],
            [110.40422608131388, -7.060409647882586],
            [110.40412364112602, -7.060418562531129],
            [110.40394698647053, -7.060490700597864],
            [110.403770106723186, -7.060517610990717],
            [110.403667961047105, -7.060517480450059],
            [110.403547186886016, -7.060481144223306],
            [110.403426152971321, -7.060426716709845],
            [110.40328673880461, -7.060363220199099],
            [110.403203360737834, -7.060290749863995],
            [110.402999053274655, -7.060082442877921],
            [110.402869023075624, -7.059982776414155],
            [110.402747989379648, -7.059928348741143],
            [110.40263656446426, -7.059882978801474],
            [110.402594191351525, -7.059828651786031],
            [110.402589225864887, -7.05972914535434],
            [110.4026196873196, -7.059584456969536],
            [110.402665110365604, -7.059466924116848],
            [110.402765778477715, -7.05929518919367],
            [110.402821069949212, -7.059204805334132],
            [110.402650260259108, -7.059132223028492],
            [110.402397383213128, -7.059068581040913],
            [110.402156413411191, -7.058986863254106],
            [110.402060834037087, -7.058941513497281],
            [110.401860113099374, -7.058805574371216],
            [110.401423124418699, -7.058452241158129],
            [110.40129757572295, -7.058388761901877],
            [110.400976047030895, -7.058243621986795],
            [110.400637293497226, -7.058071323370315],
            [110.399788447658267, -7.057627005129485],
            [110.399501982503836, -7.057500000277049],
            [110.399064751912988, -7.057336619363859],
            [110.398592742197778, -7.057155102346455],
            [110.398150454274813, -7.056964577746568],
            [110.397997154054408, -7.056919152798732],
            [110.397904676319129, -7.056882851710345],
            [110.397743430184832, -7.056855507343873],
            [110.397561823410058, -7.056819091193504],
            [110.397482903911481, -7.056800898431209],
            [110.397342324849447, -7.056764535150648],
            [110.397088624592783, -7.056682798477159],
            [110.396906781842972, -7.056610199963401],
            [110.396872498141875, -7.056646337484668],
            [110.396786448296311, -7.056890453536868],
            [110.396721135663725, -7.057071278193885],
            [110.396569571948163, -7.057432900395801],
            [110.396542090669215, -7.057460001225724],
            [110.396511237425344, -7.057469006789405],
            [110.396483791296333, -7.057468971303842],
            [110.396439379899903, -7.057459868427912],
            [110.39618567947511, -7.057378131247372],
            [110.395784338093534, -7.057250975594555],
            [110.395460183486946, -7.057169146837478],
            [110.395025737446346, -7.05704194768907],
            [110.394555085913169, -7.056905655749691],
            [110.394104748219803, -7.056814616967775],
            [110.393979967597346, -7.056814454995141],
            [110.393855163441074, -7.056832383855211],
            [110.393649989915616, -7.056859253743694],
            [110.393521212677314, -7.056886222799397],
            [110.393448730558163, -7.056922310416281],
            [110.393388391693492, -7.056976504696039],
            [110.393311582855915, -7.057075904798216],
            [110.393140009648164, -7.05737418152836],
            [110.393069708529211, -7.057473590039804],
            [110.392991744165599, -7.057591079465352],
            [110.392984587917312, -7.057654388285018],
            [110.392969709513167, -7.057781005187039],
            [110.39296249431942, -7.057889541160953],
            [110.39295013886975, -7.058034252234017],
            [110.392935342954488, -7.058097551114244],
            [110.392911268628637, -7.058115610693606],
            [110.392889469690289, -7.058124627785078],
            [110.392832805598601, -7.058115508628799],
            [110.392843160213104, -7.058052203975637],
            [110.392840083134132, -7.058025063634247],
            [110.392808439904968, -7.057988840685389],
            [110.392729025319369, -7.05791637379874],
            [110.392584155117973, -7.057699094589199],
            [110.392552865650103, -7.057608599411192],
            [110.392540262662095, -7.057509083104905],
            [110.392527683267929, -7.057391475937179],
            [110.392512191816152, -7.057337183100771],
            [110.392467886706882, -7.057246670979967],
            [110.392439627152939, -7.057219497861495],
            [110.392395534233259, -7.057183258686455],
            [110.392341809243618, -7.057156052414914],
            [110.392313514306181, -7.057156015580725],
            [110.392228228539338, -7.057246359000878],
            [110.392139806689883, -7.05735478920904],
            [110.392048024817555, -7.057436078684892],
            [110.391994511940013, -7.057463145319382],
            [110.391953472450012, -7.057472137305986],
            [110.39192178210088, -7.057472096023377],
            [110.391861808605867, -7.057462972445986],
            [110.391801799685851, -7.057480985149044],
            [110.391745174345914, -7.05750804769775],
            [110.391685129981525, -7.057553196672179],
            [110.391615477132333, -7.057589287669859],
            [110.39156197602982, -7.057607308822064],
            [110.39146406365019, -7.057616226625502],
            [110.391423035957146, -7.057616173134755],
            [110.391397877077097, -7.05759804944355],
            [110.39138205553526, -7.057579937926162],
            [110.391330641441968, -7.057516552777321],
            [110.391235983370251, -7.057416929452427],
            [110.39116676714292, -7.057335430183533],
            [110.391106900091103, -7.0572448976579],
            [110.391059824896388, -7.057109154595389],
            [110.391037896734346, -7.05700058066618],
            [110.391025305902247, -7.056892018917289],
            [110.391025459605672, -7.056774428349987],
            [110.391009720868851, -7.056692998825094],
            [110.390990869697646, -7.056611565239505],
            [110.390965758183569, -7.05655725981731],
            [110.390891743816027, -7.056466708816213],
            [110.390854760294744, -7.056403342455802],
            [110.390820004893882, -7.056367115331139],
            [110.390785544274323, -7.056321843145485],
            [110.390741805491686, -7.056231331632383],
            [110.390693244768812, -7.056149859266519],
            [110.390670184925199, -7.056041283849703],
            [110.390649600652225, -7.055986984323573],
            [110.390628709775967, -7.055950775283369],
            [110.39061318309507, -7.055923618687408],
            [110.390597456277433, -7.055833143726393],
            [110.390572415827009, -7.055724565721329],
            [110.390540855743566, -7.055625024648441],
            [110.390518844927755, -7.055579768699626],
            [110.390512667389245, -7.055543578862231],
            [110.390520979367864, -7.055462180722303],
            [110.390542872856045, -7.055380800309795],
            [110.390557504321578, -7.055227046868546],
            [110.390568598445128, -7.055181834131119],
            [110.390557622617848, -7.055136592587528],
            [110.390532192783027, -7.055109423064724],
            [110.390496010926498, -7.055082239505804],
            [110.390445080283428, -7.055082173021084],
            [110.390161330779861, -7.055045620741836],
            [110.390114090305659, -7.055036513598022],
            [110.390088648665326, -7.055018389481006],
            [110.390066897189158, -7.054991224740229],
            [110.390037812748133, -7.054945959534055],
            [110.389954154894056, -7.054873486703112],
            [110.389856066727802, -7.054800995003836],
            [110.389750598261912, -7.054746584526014],
            [110.389714416482491, -7.05471940091244],
            [110.389663580655622, -7.05464697093162],
            [110.389638127208329, -7.054637892219843],
            [110.389594270314518, -7.054637834892462],
            [110.389423334353765, -7.05466474774047],
            [110.389379784110091, -7.054646599910074],
            [110.389354354373751, -7.054619430330746],
            [110.389332614812346, -7.054583220134317],
            [110.389284278176945, -7.054546975153126],
            [110.389186154626159, -7.054501619605126],
            [110.389168081546273, -7.054474459639837],
            [110.389124626180902, -7.0543839483801],
            [110.389091851695227, -7.054347723739115],
            [110.389015503303739, -7.054311442087289],
            [110.38898640714865, -7.054275222252533],
            [110.388972166568593, -7.054130476558511],
            [110.388972249578529, -7.054067158577653],
            [110.388997833416823, -7.053976737637161],
            [110.389016108045524, -7.05385012536548],
            [110.389016226617585, -7.05375967110523],
            [110.389008964849694, -7.053687298072353],
            [110.388979939875554, -7.053596805683422],
            [110.388951174142363, -7.053524404515563],
            [110.388889279906621, -7.05347005088232],
            [110.388838385035243, -7.053442847959928],
            [110.388812919811116, -7.053442814635567],
            [110.388783764415891, -7.053451821921752],
            [110.388736488553363, -7.05346985093121],
            [110.388641924954456, -7.053514954360287],
            [110.38858361414897, -7.053532968907986],
            [110.388496502081352, -7.053505718534111],
            [110.388401797932119, -7.053442276440135],
            [110.388337074308012, -7.053387919034715],
            [110.38826642068662, -7.053324508413919],
            [110.388221208552395, -7.053279221990842],
            [110.38820715618786, -7.053206840060251],
            [110.388195873933782, -7.053179688960181],
            [110.388156603700793, -7.053134410315691],
            [110.388101229261906, -7.053071019689851],
            [110.388077815933045, -7.053016716373615],
            [110.388042212155923, -7.052980487965131],
            [110.38801846839084, -7.052962365974643],
            [110.387987061318299, -7.052962324822051],
            [110.387907848148586, -7.052953175579853],
            [110.387864592954941, -7.052925982572064],
            [110.38782502795047, -7.052889748957355],
            [110.387777894673576, -7.052799232780403],
            [110.387714963935991, -7.05267251413251],
            [110.387631955923055, -7.052536723717441],
            [110.387592662032489, -7.052509535881915],
            [110.387541201456443, -7.052482332090667],
            [110.387489988179397, -7.052482264937121],
            [110.387399138651361, -7.052500236675374],
            [110.387324145998051, -7.052509183755343],
            [110.387256840449226, -7.052481959150569],
            [110.387213597229987, -7.052445720665497],
            [110.387159920679963, -7.052382332171453],
            [110.387104841305259, -7.052309896392702],
            [110.387061315167742, -7.052273657524411],
            [110.387006164459578, -7.052255494275465],
            [110.386919335687978, -7.052228244005774],
            [110.386879747026569, -7.052210101167402],
            [110.386824643922481, -7.052155756206486],
            [110.386757692793125, -7.052074259370609],
            [110.3865643481641, -7.051929278496573],
            [110.386295787702821, -7.051748016992737],
            [110.386113123837106, -7.051657322610389],
            [110.38597487060072, -7.051575731974429],
            [110.385919720026223, -7.051557568607457],
            [110.385840790109356, -7.051548419410197],
            [110.385781359612039, -7.051557386712112],
            [110.385694459551232, -7.051584408758853],
            [110.38563531199371, -7.051593376413601],
            [110.385572203195522, -7.05160233885065],
            [110.385520707088446, -7.051602271117413],
            [110.385437863630258, -7.051556934958404],
            [110.385406233338955, -7.051511666164403],
            [110.385406269108927, -7.05148452990206],
            [110.385426158758477, -7.05142123801257],
            [110.385430203454547, -7.051357925277644],
            [110.385426289908352, -7.051321738383068],
            [110.385394659632212, -7.05127646958931],
            [110.385343518052977, -7.051222129687264],
            [110.385201315785295, -7.051131488211447],
            [110.385114487351899, -7.051104237632182],
            [110.385067235484001, -7.051104175439644],
            [110.384924890128275, -7.051122078928554],
            [110.38484594838495, -7.051121974991076],
            [110.384790797918114, -7.051103811498924],
            [110.384696094797917, -7.051040368732813],
            [110.384518560395165, -7.050922544224198],
            [110.384435717164905, -7.050877207905059],
            [110.38436445081642, -7.050849977697475],
            [110.384239990797923, -7.050822677375487],
            [110.383995645731375, -7.050731900930003],
            [110.383881714496766, -7.050659387227382],
            [110.383658522721689, -7.050405820700929],
            [110.383523845032784, -7.050188552612704],
            [110.383460525219348, -7.050143241896833],
            [110.383397193458677, -7.050106976591249],
            [110.383333072679832, -7.050025483068617],
            [110.383189871021983, -7.049835339953984],
            [110.383080208233537, -7.049744740850423],
            [110.382945363443682, -7.049654108471791],
            [110.382656114957499, -7.049499954069271],
            [110.382565102721202, -7.04942747036299],
            [110.38247861761964, -7.049354992623645],
            [110.382411679641194, -7.049264449839411],
            [110.382361411427212, -7.049192019944474],
            [110.382287092991447, -7.049119558255573],
            [110.38222013112059, -7.049047106281744],
            [110.382169568032964, -7.048983721412315],
            [110.382133965034782, -7.048947492613767],
            [110.3820921971936, -7.048866028501915],
            [110.382086056312147, -7.048802702358547],
            [110.382101160176092, -7.048721313440368],
            [110.382164424362784, -7.048594761030624],
            [110.382188582398257, -7.048513384076506],
            [110.382188642301145, -7.04846815699429],
            [110.382167493419431, -7.048413856443084],
            [110.382152569299052, -7.048359564120256],
            [110.382146687391298, -7.048314329183119],
            [110.382155825448265, -7.048251023237238],
            [110.38215734799175, -7.04816961635894],
            [110.382148485494525, -7.048024877725406],
            [110.382131712569858, -7.047871083201871],
            [110.382110870621005, -7.047798692190539],
            [110.382073016146734, -7.047753414984951],
            [110.38201818509414, -7.04770811533164],
            [110.381938160135903, -7.047671827791466],
            [110.381836890491257, -7.047653603006594],
            [110.381739853014054, -7.047644429230325],
            [110.381613118514821, -7.047626170714396],
            [110.38146996134131, -7.047616935864516],
            [110.381115445764934, -7.047607421168144],
            [110.380874944235231, -7.04760710266816],
            [110.380773662659607, -7.047597923072074],
            [110.380735772317706, -7.047579782013448],
            [110.380688568878284, -7.047543537752287],
            [110.380638042185055, -7.047453016502912],
            [110.380638090220373, -7.04741683484615],
            [110.380671880499264, -7.047326425316726],
            [110.380697453417071, -7.047245050326685],
            [110.380697489439044, -7.04721791408357],
            [110.380684817054032, -7.047172670142634],
            [110.380621581993807, -7.047064041195877],
            [110.380588019903172, -7.046982587852804],
            [110.380579663725982, -7.046883077048022],
            [110.380559489286256, -7.046521233104944],
            [110.380577988610554, -7.046439848743768],
            [110.380605060246097, -7.046295157725441],
            [110.380625329244438, -7.046159503121439],
            [110.380652057868915, -7.046060038794892],
            [110.380672326837583, -7.045924384185414],
            [110.380722599632378, -7.045779723885353],
            [110.380733164513543, -7.045707374431825],
            [110.380733272549008, -7.045625965696495],
            [110.38072495235113, -7.045499318642753],
            [110.380678765997516, -7.045336439709448],
            [110.380598849606784, -7.045218743246203],
            [110.380523081319623, -7.045173415714173],
            [110.380454621491637, -7.045164279577605],
            [110.380365789882347, -7.045155116434779],
            [110.380134662762373, -7.045127673795112],
            [110.380012727026028, -7.045118466699066],
            [110.379870395476928, -7.045127323381299],
            [110.37979176180751, -7.045109128226442],
            [110.379751042290579, -7.045090983354052],
            [110.379700172956476, -7.045045688725205],
            [110.379603822227367, -7.044946061172642],
            [110.379578712603006, -7.044891755280696],
            [110.37956861089377, -7.044828423872067],
            [110.379571229514525, -7.044774154770621],
            [110.379591498704713, -7.044638500223174],
            [110.379604399389422, -7.044511881326676],
            [110.379628972781603, -7.044331005336662],
            [110.379647827220523, -7.044195348904311],
            [110.379664947934856, -7.044086826458971],
            [110.379682008531873, -7.044023531081172],
            [110.379719476915525, -7.043933126481255],
            [110.379794269408265, -7.043860862237254],
            [110.379823129353625, -7.043860900510261],
            [110.379843453007439, -7.043897109182218],
            [110.379850195511253, -7.043933299843905],
            [110.37985862364215, -7.043978538170926],
            [110.379878652344757, -7.044023791880861],
            [110.379902395332806, -7.044041914224854],
            [110.379965160116683, -7.044078179167603],
            [110.380038110779864, -7.044114457606443],
            [110.38012804991817, -7.044141713123425],
            [110.380223106026136, -7.044150884544379],
            [110.380345041520258, -7.044160091564212],
            [110.380375599132265, -7.044160132054241],
            [110.380401087824609, -7.044142074964307],
            [110.380419797942395, -7.044114963460872],
            [110.380443685040035, -7.044024540797735],
            [110.380459059892786, -7.043952197717682],
            [110.380459956739998, -7.043916017181249],
            [110.380445881743242, -7.043861725947936],
            [110.380394108832505, -7.043644567009839],
            [110.380336255364895, -7.043318854837945],
            [110.3802878418023, -7.043128836636954],
            [110.380219917414735, -7.042929747148032],
            [110.3801389538189, -7.04274873123467],
            [110.380093899622594, -7.042585853766063],
            [110.380032422933837, -7.042431999959295],
            [110.379935319908142, -7.042260008060186],
            [110.379831239582572, -7.04201564344897],
            [110.379801392180553, -7.041907058707531],
            [110.379789875772701, -7.041843725423054],
            [110.379789923835133, -7.041807543763464],
            [110.379799049987881, -7.041753283277894],
            [110.379824869558533, -7.041699044923105],
            [110.379859496282819, -7.041617681948448],
            [110.379897813217099, -7.04152727843034],
            [110.379909238900765, -7.041445884695023],
            [110.379898914303965, -7.041337325835517],
            [110.379914234405149, -7.041093119497896],
            [110.379914414582387, -7.040957438266593],
            [110.379903289215562, -7.040812696618861],
            [110.379903397319879, -7.040731287879369],
            [110.379933755842302, -7.040668010094601],
            [110.379967744518368, -7.040640918844096],
            [110.380028046488178, -7.040613862459127],
            [110.380107293335257, -7.040595876598436],
            [110.380212865479137, -7.04056888016548],
            [110.380363707798068, -7.040541943664045],
            [110.380489344564197, -7.040533064603641],
            [110.380664483598139, -7.040533296471568],
            [110.380893711894018, -7.040497418116964],
            [110.381006074512825, -7.040470430484288],
            [110.381059904788458, -7.040416229096676],
            [110.381073545783622, -7.04037101997395],
            [110.381073629700907, -7.040307702050214],
            [110.381046587485372, -7.040217211942676],
            [110.380988493986763, -7.04007240815486],
            [110.380912304459173, -7.039918534986488],
            [110.380867605058185, -7.039701385442129],
            [110.380827312783438, -7.039574696060858],
            [110.380760117430597, -7.039466061943511],
            [110.380631397567285, -7.039239755758929],
            [110.38061168787678, -7.039167366206107],
            [110.380608966454218, -7.039085953705066],
            [110.380611927775803, -7.038986457858694],
            [110.380634718719563, -7.038868897389606],
            [110.380691924988795, -7.038615700959116],
            [110.380728886741878, -7.038480068364819],
            [110.380723299943611, -7.038425788370285],
            [110.380680919296523, -7.038380505121915],
            [110.380632537006989, -7.038380441095663],
            [110.380606765719151, -7.038398497856428],
            [110.380555765022592, -7.038452702957148],
            [110.380433368101308, -7.038579177004565],
            [110.380356325115244, -7.038642393035502],
            [110.380288113175226, -7.038660393581556],
            [110.380240013797817, -7.038660329886854],
            [110.380161086235276, -7.038651179925137],
            [110.38002335570097, -7.038605770325526],
            [110.379964811658695, -7.038587601901781],
            [110.379847440652114, -7.038551264658556],
            [110.379704051437187, -7.038505847471619],
            [110.379622601508686, -7.038478603215375],
            [110.379537816415748, -7.038406127365665],
            [110.379459857891888, -7.038306524259128],
            [110.379401079044996, -7.038252173732285],
            [110.37934259516706, -7.038188778158812],
            [110.379244807082429, -7.038107239588628],
            [110.379199338349679, -7.038043861262006],
            [110.379111477160976, -7.0379442449726],
            [110.379033259912504, -7.037826550602162],
            [110.378961761039292, -7.037763137721739],
            [110.378919332529819, -7.037754035992684],
            [110.378873767663919, -7.037763020959584],
            [110.378847713415837, -7.037781077245518],
            [110.378785641980613, -7.037862403742769],
            [110.378717062972285, -7.037943721591503],
            [110.378589928004587, -7.038015916256992],
            [110.378465869202927, -7.038115251259493],
            [110.378341882506476, -7.038160364309864],
            [110.378305985675198, -7.038133129770665],
            [110.378270371717988, -7.038105946173538],
            [110.378195465634818, -7.038051574085559],
            [110.378105329138151, -7.03796100003232],
            [110.378033240424784, -7.037915677094539],
            [110.37796652752732, -7.037870361291235],
            [110.377912829739785, -7.037825062778035],
            [110.377883464532118, -7.037779796601905],
            [110.377864862975002, -7.037725499302192],
            [110.377843739082905, -7.037653107790934],
            [110.37783311999388, -7.037553593954907],
            [110.377833168178256, -7.037517412302184],
            [110.377846514385865, -7.037481248325053],
            [110.377897551388855, -7.037399907295567],
            [110.377940347081591, -7.037345691594847],
            [110.377996723719193, -7.037291493936102],
            [110.378058181160142, -7.037246348449792],
            [110.37810918197863, -7.037192143632316],
            [110.378138113603001, -7.037138012370053],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 96,
        id: 98,
        area: 4361613.0761169996,
        perimeter: 10926.339169000001,
        kode_pos: 50222,
        kode_kec: 3374020,
        kode_kel: 3374020014,
        kec: "Gunungpati",
        kel: "Sadeng",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.378138113603001, -7.037138012370053],
            [110.378039037807895, -7.037173959576652],
            [110.377958400907687, -7.037173852426559],
            [110.377877788097948, -7.037155654435776],
            [110.377810768291496, -7.037128429070237],
            [110.377721360372746, -7.037128310221549],
            [110.377430453498107, -7.037164105119633],
            [110.377336494485633, -7.037182071000815],
            [110.377273965528104, -7.037181987819768],
            [110.377229237453605, -7.037200019170387],
            [110.377202322316137, -7.037227119645249],
            [110.377197747100851, -7.03726329527082],
            [110.37721123159119, -7.037335676638006],
            [110.37726029375338, -7.037462377909109],
            [110.377313636158888, -7.037561948585963],
            [110.377335891710786, -7.037634341619413],
            [110.377358086990569, -7.037751961713618],
            [110.377362324640984, -7.037969057629557],
            [110.377362155855977, -7.038095693400026],
            [110.377335156271712, -7.038186111762225],
            [110.377303714084647, -7.038213206214565],
            [110.377258997967203, -7.038222192146741],
            [110.377209766914348, -7.038222126638701],
            [110.377151505996267, -7.038203958253026],
            [110.377062170255712, -7.038149566790721],
            [110.377026532252543, -7.038140473928324],
            [110.37700840015674, -7.038158540648338],
            [110.377039673478848, -7.038258081978749],
            [110.377106596894734, -7.038357670756679],
            [110.37714244548765, -7.03842103646098],
            [110.377160198167118, -7.038475332653241],
            [110.377164676920103, -7.038511520324508],
            [110.377146755383848, -7.038583859894959],
            [110.377119767784322, -7.038665232825206],
            [110.377101563295881, -7.038737572015745],
            [110.377079066487667, -7.038846087201279],
            [110.377078994109596, -7.038900359669456],
            [110.377123529360603, -7.039027054931848],
            [110.377217283722956, -7.039162861123991],
            [110.37723926843681, -7.039226208374463],
            [110.377257021150228, -7.039280504564875],
            [110.37725698496935, -7.0393076407994],
            [110.377248165657207, -7.0393438107729],
            [110.377230304395241, -7.039370923285209],
            [110.377149643003207, -7.039388906785066],
            [110.3770466053913, -7.039424951337085],
            [110.376970717729876, -7.039470077440693],
            [110.376912348066554, -7.039533317710878],
            [110.376894438506781, -7.039596611850889],
            [110.376889778752243, -7.039696105340798],
            [110.376889477044728, -7.039922240607662],
            [110.376902678588849, -7.039994621601346],
            [110.376938466919711, -7.040103214378755],
            [110.376947400264726, -7.040193680541491],
            [110.376947327857366, -7.040247953005184],
            [110.37691583722625, -7.040311229057057],
            [110.376817241909833, -7.040410597438611],
            [110.376661456764282, -7.040537025884651],
            [110.376631687853433, -7.040582213353381],
            [110.376606987679338, -7.040645498425212],
            [110.376602093196922, -7.040708809885236],
            [110.376586976695421, -7.040799244001991],
            [110.376557195682281, -7.040853476873074],
            [110.376458353468678, -7.040925708554407],
            [110.376438499445015, -7.040961863795087],
            [110.376463349736511, -7.040998078618056],
            [110.376556942071289, -7.041043430475941],
            [110.376601598091042, -7.041079671685342],
            [110.376640854129747, -7.041133996547615],
            [110.376650672461437, -7.041197327610569],
            [110.376645765896967, -7.041269684479274],
            [110.376625887718106, -7.041323930546646],
            [110.376581417998338, -7.041360052991223],
            [110.376437955872376, -7.041368907216789],
            [110.376270221410394, -7.041332501911348],
            [110.376126771381166, -7.041332310632945],
            [110.376018086577446, -7.041359301955815],
            [110.375938803233197, -7.041404423323288],
            [110.375894357638572, -7.041422454880419],
            [110.375840021268715, -7.041431427812471],
            [110.37573620686041, -7.041413198443423],
            [110.375677084717353, -7.041404074121927],
            [110.375607764680254, -7.041403981606233],
            [110.375518897663298, -7.041421953835454],
            [110.375439933503742, -7.04143993926816],
            [110.375385597127718, -7.041448912146473],
            [110.375330989912541, -7.041448839233136],
            [110.375271831470016, -7.041466851082462],
            [110.375245506045175, -7.041475861349554],
            [110.375202463013437, -7.041502940134335],
            [110.375128236162325, -7.04157520437789],
            [110.375052865425047, -7.041656512502269],
            [110.37497867485223, -7.041701640497526],
            [110.374884739081409, -7.041701514981342],
            [110.374652801741576, -7.041646932454178],
            [110.374578671718751, -7.041646833352947],
            [110.374529108922147, -7.041682948776321],
            [110.374509206247936, -7.041755285541202],
            [110.374499194317778, -7.041836680951849],
            [110.374514105266343, -7.041900018843449],
            [110.374523911194672, -7.041972395330746],
            [110.374523814251148, -7.042044758576588],
            [110.374543252235981, -7.042108102520825],
            [110.374563009517047, -7.042144310625638],
            [110.374587859709138, -7.042180525539157],
            [110.374612378499108, -7.042252921696544],
            [110.374612342149078, -7.042280057913897],
            [110.374597568715089, -7.042325265271574],
            [110.374547993725017, -7.042370426095324],
            [110.374414397821951, -7.042406429126735],
            [110.37436003707893, -7.042433492684465],
            [110.37432548209469, -7.04246058272847],
            [110.374310732872303, -7.042487699263443],
            [110.374305567222791, -7.042541964881741],
            [110.374330049613405, -7.042641497266095],
            [110.374319225170098, -7.042695755314647],
            [110.374299370901412, -7.042731910440595],
            [110.374215240821499, -7.04280416125826],
            [110.374101402043593, -7.042876372303509],
            [110.374047053357245, -7.042894390413331],
            [110.373997538902358, -7.042894324145276],
            [110.373958258800158, -7.042858089888116],
            [110.373958319451845, -7.04281286286597],
            [110.373943384301057, -7.042767615772052],
            [110.373908902055973, -7.042740433354355],
            [110.373879156998385, -7.042767529799211],
            [110.373834662676373, -7.042821742758044],
            [110.373706062436128, -7.042930115620083],
            [110.373592247833372, -7.042984235723876],
            [110.373300674153981, -7.043092390163864],
            [110.373241527586615, -7.04310135632677],
            [110.373117882884486, -7.043101190618391],
            [110.373023983312251, -7.043073928495555],
            [110.372900658016007, -7.043046626902158],
            [110.372841240663902, -7.043046547232522],
            [110.372791726204341, -7.043046480835392],
            [110.372767061829364, -7.043082629433667],
            [110.372752276035328, -7.043136882115714],
            [110.37272726793816, -7.043218257342358],
            [110.372712469978666, -7.043281555424138],
            [110.37267278545562, -7.043335774706263],
            [110.37260847298856, -7.043399006365186],
            [110.372413271962344, -7.04358869824115],
            [110.372313357626012, -7.043615700405453],
            [110.372202174338938, -7.043606505750573],
            [110.372013526328359, -7.04355197997213],
            [110.371846996376661, -7.043461302161628],
            [110.371691476799214, -7.043388729922183],
            [110.371458687520231, -7.043126100054947],
            [110.371181428251006, -7.042899591971107],
            [110.371003876254747, -7.042799853658235],
            [110.370670634479993, -7.04275417832228],
            [110.370281985468651, -7.042672246503806],
            [110.370037943034106, -7.042572418327116],
            [110.369827041235609, -7.042445498440167],
            [110.369668902627495, -7.042427194535014],
            [110.36929826448025, -7.042417649511178],
            [110.368788825540548, -7.042317462788835],
            [110.368526466042994, -7.042163336677983],
            [110.368310692278584, -7.041873592174268],
            [110.368046216603517, -7.041610918035992],
            [110.367675860994098, -7.041393327725062],
            [110.367320932538235, -7.041275257523001],
            [110.366873175991017, -7.041175152268774],
            [110.366440600152714, -7.041147430429406],
            [110.36591577425655, -7.04112862857953],
            [110.365606744273876, -7.041173436666869],
            [110.364772383996751, -7.041362258026769],
            [110.364185360587854, -7.041515232449085],
            [110.363907428858468, -7.041578172449698],
            [110.363737370562276, -7.041586986588106],
            [110.363536767250011, -7.041586713704191],
            [110.363104560814079, -7.041495671447326],
            [110.362888211760989, -7.041423013632619],
            [110.362471530202257, -7.04135912816246],
            [110.362100905916549, -7.041340532139844],
            [110.361653113778033, -7.0412675581345],
            [110.361387767352184, -7.041231014395364],
            [110.361240369008783, -7.041221767765347],
            [110.361134279591226, -7.04121260259151],
            [110.36065545136357, -7.041076242391834],
            [110.360399012535737, -7.040940210899882],
            [110.360262539458461, -7.040804343334151],
            [110.360142933297524, -7.040541863230752],
            [110.359989461548636, -7.040216018998473],
            [110.359801816600495, -7.039844900987755],
            [110.359597319743301, -7.039383305885167],
            [110.359324069680497, -7.038921616600124],
            [110.359187783618921, -7.038650068089716],
            [110.35908533835152, -7.038459974467596],
            [110.358931607245779, -7.038324082964006],
            [110.35872638186909, -7.038188120864225],
            [110.358641121463364, -7.038052323090366],
            [110.35860712217476, -7.037880413968596],
            [110.358641593357916, -7.037708598672414],
            [110.358817976658131, -7.037419387629859],
            [110.35889964829363, -7.037283818542234],
            [110.358948707838223, -7.037202477157883],
            [110.359078932605328, -7.037148383098242],
            [110.35925842532211, -7.037067220247727],
            [110.35933997273456, -7.037022104874203],
            [110.359372671535468, -7.036904559451103],
            [110.359348549945096, -7.036750754672137],
            [110.359283623691084, -7.036642121036036],
            [110.359145270813087, -7.036433887504869],
            [110.358909159097777, -7.036126020708063],
            [110.358836226494503, -7.03587264970007],
            [110.358844592938084, -7.035755070989296],
            [110.358909841621227, -7.035628524771273],
            [110.358981875942902, -7.035584168857474],
            [110.359132115172017, -7.035541506142221],
            [110.359333323141342, -7.035488196025168],
            [110.359547973083451, -7.035410790734828],
            [110.359684825282926, -7.035352033873298],
            [110.359792177735571, -7.035293236633366],
            [110.359896609839367, -7.035295195117198],
            [110.359961610414373, -7.035349556374057],
            [110.360026708131642, -7.035539598752017],
            [110.360083588404677, -7.035738675292338],
            [110.360124306221763, -7.035756821743285],
            [110.360148638602212, -7.035756854998116],
            [110.36017298336759, -7.03574784286872],
            [110.360181250395684, -7.035702627166899],
            [110.360173144369028, -7.035630252889901],
            [110.360159337516819, -7.035473249928877],
            [110.360108724618414, -7.035224008209672],
            [110.360060687161322, -7.035052468974485],
            [110.359977812147349, -7.034862127196429],
            [110.359886807430016, -7.03473339761259],
            [110.359750168316467, -7.034636756916797],
            [110.359575969546185, -7.034550781916757],
            [110.359396396253146, -7.034472837328241],
            [110.359265069477971, -7.034413713614552],
            [110.359187525947959, -7.034299231340372],
            [110.359187637559231, -7.034217822904288],
            [110.359214037195059, -7.034154541237623],
            [110.359258236840617, -7.034109374721726],
            [110.359319965961575, -7.034073277582483],
            [110.359514107987494, -7.034037361553025],
            [110.359822862864675, -7.033983511333233],
            [110.360043317653549, -7.033947631046667],
            [110.360219647165337, -7.033902644972382],
            [110.360590155661328, -7.033794606183732],
            [110.360713822341253, -7.033776684212344],
            [110.360731426070046, -7.033731481234483],
            [110.360705162741695, -7.033695263777083],
            [110.360483766609917, -7.033563370094584],
            [110.360186264759079, -7.033434358553229],
            [110.359904809747803, -7.033337520066756],
            [110.359652891152251, -7.033213929058208],
            [110.359486808303828, -7.033105648062589],
            [110.359403827034498, -7.032964415922038],
            [110.35937382318717, -7.032761768349411],
            [110.359352851131774, -7.03258083167151],
            [110.359367196080925, -7.032436124895999],
            [110.359431227708782, -7.032164850470067],
            [110.359474039327736, -7.031893547010553],
            [110.35947428715447, -7.031712639345704],
            [110.35947032967249, -7.031402489691233],
            [110.35944955082222, -7.030909474705576],
            [110.3594224199434, -7.030746537697463],
            [110.359392621994061, -7.030572879942142],
            [110.359415893649825, -7.030366654895183],
            [110.359636786240571, -7.030253550374521],
            [110.359728304887469, -7.030140363895131],
            [110.359741057563213, -7.030030217313777],
            [110.359758585477124, -7.02988388051011],
            [110.359796576304362, -7.029748588063273],
            [110.359859770801819, -7.029613330042338],
            [110.359967058793899, -7.029487574866224],
            [110.36006794245435, -7.02943735195457],
            [110.360197150101797, -7.029409200496202],
            [110.36029165962114, -7.029412477069022],
            [110.360436572214894, -7.029418969961469],
            [110.360521647572256, -7.029409643437437],
            [110.360584695518085, -7.029381401579191],
            [110.360633585745191, -7.029338976448328],
            [110.360684152293416, -7.029222586320405],
            [110.360706399097737, -7.029080977200765],
            [110.360719259036017, -7.028892142115791],
            [110.360702316519607, -7.028610413830971],
            [110.360709116397601, -7.028245308017415],
            [110.360706417574434, -7.027914812224674],
            [110.360664142416809, -7.02772747569651],
            [110.360541567418778, -7.027513275499842],
            [110.360431435116169, -7.027415551379202],
            [110.360314980449502, -7.027333556327187],
            [110.360261500320505, -7.027276827551882],
            [110.360252173790442, -7.027185536053848],
            [110.360283794443447, -7.027100595526724],
            [110.360359517007268, -7.027018862721383],
            [110.360454103490028, -7.026965483533512],
            [110.360602204491414, -7.026943652762158],
            [110.360721904179201, -7.02695640619591],
            [110.360869897686513, -7.027013263796071],
            [110.36112175708665, -7.027142656445351],
            [110.361455433754799, -7.027341406412952],
            [110.36177026384388, -7.027499212424437],
            [110.362028454730492, -7.02760658043706],
            [110.362286753005108, -7.027635259840657],
            [110.362445882908048, -7.02761186973127],
            [110.36267599868539, -7.027514608781163],
            [110.362846264417001, -7.02741097126695],
            [110.363010374877732, -7.027200308775309],
            [110.363083045122195, -7.027046177795705],
            [110.363176195353915, -7.026890500849673],
            [110.363340266928816, -7.026708166066805],
            [110.363624290658066, -7.026352879040029],
            [110.363842061201879, -7.025993862551317],
            [110.363990058914567, -7.025757997387375],
            [110.364123242021734, -7.025556538356843],
            [110.36424656653567, -7.025364901991486],
            [110.364330557267735, -7.025138785969351],
            [110.364387497252082, -7.02489542010957],
            [110.364417593548268, -7.024482345416918],
            [110.364457735164308, -7.023921743073045],
            [110.364463165159336, -7.02354797925701],
            [110.364446266785109, -7.023304513286837],
            [110.364345845630538, -7.022942901120635],
            [110.36422331664599, -7.022549291703538],
            [110.364110318957444, -7.022386843149373],
            [110.363943039969854, -7.022322681803391],
            [110.363805215981458, -7.022317576849887],
            [110.363746105596448, -7.022347004922489],
            [110.363632693693646, -7.022489474268999],
            [110.363479694228289, -7.022784349133115],
            [110.363267416821841, -7.02323160271871],
            [110.363129438708299, -7.023339612268876],
            [110.362971964470702, -7.023304972117751],
            [110.362809668136649, -7.023196554759346],
            [110.362603115974736, -7.023053650927226],
            [110.362381856639672, -7.022866464660814],
            [110.362278664422433, -7.022733537286672],
            [110.362224751110588, -7.02256133258364],
            [110.362224898496336, -7.022453135905304],
            [110.36230379954381, -7.022349964340089],
            [110.36239742123972, -7.02228123904912],
            [110.36261412603227, -7.022197926880942],
            [110.362845625087502, -7.022094962538596],
            [110.362968801852276, -7.022011523142792],
            [110.363052625182718, -7.021908358113035],
            [110.363105098290632, -7.021784818223469],
            [110.363143594908422, -7.02161917916299],
            [110.36316501272006, -7.021504498858514],
            [110.363250221770357, -7.02140701887086],
            [110.363474473485752, -7.02125254573508],
            [110.363619985321563, -7.021147023679257],
            [110.363807275458967, -7.02098375741996],
            [110.364030202781194, -7.020676719940171],
            [110.364387440196722, -7.020107368859224],
            [110.364521573471137, -7.019896178374555],
            [110.364750416243467, -7.019556160259946],
            [110.365025310493465, -7.019277336495373],
            [110.365314624651589, -7.019045604858121],
            [110.365598149197467, -7.018904449791152],
            [110.365943937813455, -7.018814332507352],
            [110.366291728246921, -7.018796856891628],
            [110.366538944271767, -7.018809474498873],
            [110.366807663234127, -7.018930493974795],
            [110.367127756349049, -7.019195253591103],
            [110.367405396431053, -7.019434423879491],
            [110.367758129956499, -7.019761647681277],
            [110.368322001752489, -7.020249909187008],
            [110.368627674014931, -7.020493766854992],
            [110.368825649133598, -7.020760125638265],
            [110.368927313714408, -7.02100937019068],
            [110.369097978917267, -7.02142296747328],
            [110.369192717458276, -7.021700435113114],
            [110.369373540774646, -7.022080001478267],
            [110.369526366289179, -7.022210422355874],
            [110.369679306216653, -7.022255920226137],
            [110.369821088034911, -7.022171187606987],
            [110.370025485330814, -7.021877061996361],
            [110.370207063295567, -7.021696136473946],
            [110.370473534949042, -7.021594586388847],
            [110.370660993605924, -7.021578862996223],
            [110.370825591964675, -7.021608990855361],
            [110.370945228654804, -7.021683918975029],
            [110.371277726577205, -7.021952342101383],
            [110.371509901645325, -7.022071545638063],
            [110.371797814270664, -7.022088807007241],
            [110.37212200402513, -7.021998765286492],
            [110.372337498012413, -7.021880161253612],
            [110.37249065063375, -7.021767135266683],
            [110.37253619664034, -7.021608672948422],
            [110.372536439404328, -7.021427503851839],
            [110.37255882030027, -7.021028124212667],
            [110.372610993870865, -7.020765202847095],
            [110.37272401452887, -7.020613384355872],
            [110.372923063379361, -7.020465558170109],
            [110.373773663864839, -7.020019432112209],
            [110.373960777757006, -7.019934758608273],
            [110.374125073572856, -7.019963285517713],
            [110.37423818020379, -7.020133282770432],
            [110.374521166403213, -7.020394091245727],
            [110.375081593520505, -7.02073470574144],
            [110.3753822089989, -7.020964826698943],
            [110.378178251404933, -7.023105173315776],
            [110.378342511114013, -7.02316200656589],
            [110.378494629279729, -7.023018289632077],
            [110.378645060442665, -7.023353342300805],
            [110.378798975069515, -7.02377868168917],
            [110.378986148612825, -7.024086474418464],
            [110.379188775501149, -7.024475696477229],
            [110.379281108334908, -7.024828590732742],
            [110.379373062396482, -7.025253847967163],
            [110.379382015219591, -7.025543313782477],
            [110.379341635288824, -7.025697032746293],
            [110.37929840203509, -7.025868838797925],
            [110.379250936795898, -7.026031593802235],
            [110.379175082522963, -7.026266674690186],
            [110.379080391876613, -7.026411276253293],
            [110.379044351730244, -7.026492637435719],
            [110.378960117404674, -7.026646298251441],
            [110.378766292934017, -7.026872177304354],
            [110.378567459622616, -7.027034731577639],
            [110.378234457029677, -7.027242334972984],
            [110.37812003288272, -7.027332637516038],
            [110.377931209974179, -7.027413795862293],
            [110.377827067463556, -7.027431748505529],
            [110.377648291431981, -7.02740437487283],
            [110.377549006874091, -7.027386152173304],
            [110.377479405960543, -7.027386059741867],
            [110.377434678862727, -7.027404091202684],
            [110.377370122547603, -7.027440187184033],
            [110.377215943424673, -7.027638981859932],
            [110.377101163808476, -7.027783556245094],
            [110.37698659477536, -7.027982403463563],
            [110.376963472583583, -7.028136145060508],
            [110.376980382022438, -7.028398485045998],
            [110.377019913923732, -7.028669900533675],
            [110.377019685037496, -7.028841763421699],
            [110.377031218799061, -7.029104096256088],
            [110.377145065434377, -7.02944797397151],
            [110.377356006056317, -7.029755798972292],
            [110.377606960756651, -7.029973222734538],
            [110.377989100018937, -7.030263184007752],
            [110.378154693888064, -7.030417176162894],
            [110.378251571344578, -7.030543940786157],
            [110.378314141861154, -7.030724932467517],
            [110.378331431160973, -7.030914909493173],
            [110.378319861318786, -7.031104848223009],
            [110.378222478582501, -7.031357991089922],
            [110.378165013361027, -7.031593096049498],
            [110.378113266891077, -7.031782981427522],
            [110.37810736760639, -7.03196388223202],
            [110.378124163142829, -7.032099586006137],
            [110.378124349827246, -7.032171949707871],
            [110.378101335962583, -7.032244282607377],
            [110.377981311983717, -7.032289350397444],
            [110.377792854177798, -7.032307190962829],
            [110.377655583253159, -7.032343190324246],
            [110.377575711697858, -7.032406402212276],
            [110.377554750527494, -7.032424465219569],
            [110.377484215754905, -7.032487689496754],
            [110.377392412754688, -7.032587067212853],
            [110.377289773895185, -7.032749748512039],
            [110.377216361499634, -7.032849150634646],
            [110.37715032945583, -7.032930471697673],
            [110.377076670225605, -7.033002737175406],
            [110.377051994707841, -7.033047931504463],
            [110.376940091133051, -7.033156327805743],
            [110.376825532575438, -7.033346129404672],
            [110.376722598344813, -7.03351785557865],
            [110.376642383216719, -7.033626293962279],
            [110.376608015475654, -7.033725747930974],
            [110.37651071563117, -7.033915572398417],
            [110.376482030658451, -7.033996943061786],
            [110.376467197400288, -7.034087377594171],
            [110.376384056213979, -7.034268175445406],
            [110.376300692375608, -7.034403745839326],
            [110.376277888605415, -7.034530351454735],
            [110.376273944885639, -7.034729345610838],
            [110.376288819724266, -7.034819819700872],
            [110.376322675355709, -7.03489222821727],
            [110.376364230593893, -7.034919419848328],
            [110.376420793464078, -7.034937586035008],
            [110.376537645642159, -7.034937741638759],
            [110.376684820130166, -7.034901755866027],
            [110.377258335823299, -7.034685428666705],
            [110.377575048451632, -7.034604440892751],
            [110.377756162926872, -7.034577545309391],
            [110.377997223473159, -7.034577865577046],
            [110.378260292791751, -7.034623442098588],
            [110.378623117972225, -7.034759605179236],
            [110.378917105996251, -7.034958994718643],
            [110.379067068177278, -7.03516723854893],
            [110.379135629193371, -7.035312056381633],
            [110.379147946166356, -7.035411572468494],
            [110.379110370343099, -7.035583385850818],
            [110.378972135937673, -7.035917883489597],
            [110.378921779788882, -7.036125861616757],
            [110.378874439056105, -7.03627957114517],
            [110.378714563067291, -7.036632130812692],
            [110.378532617248865, -7.036858025071312],
            [110.378281941765863, -7.037065737101601],
            [110.378138113603001, -7.037138012370053],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 97,
        id: 99,
        area: 3608767.5120910001,
        perimeter: 9366.708695,
        kode_pos: 50228,
        kode_kec: 3374020,
        kode_kel: 3374020007,
        kec: "Gunungpati",
        kel: "Patemon",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.388479152903685, -7.075241879998394],
            [110.388518981113705, -7.075160523411377],
            [110.388796784298137, -7.074563889561301],
            [110.388908049466323, -7.074301718015452],
            [110.38894465829739, -7.074220357151083],
            [110.388943067578992, -7.074138946161229],
            [110.388959279521302, -7.0740756493974],
            [110.389013750441634, -7.07396717565908],
            [110.389095104863614, -7.073641646760159],
            [110.389170493238851, -7.073334200877173],
            [110.389284458979702, -7.072954442076526],
            [110.389442437541277, -7.072457150263084],
            [110.389592728344113, -7.071996030025669],
            [110.389734777412215, -7.071562035217129],
            [110.389823994237219, -7.071281743559449],
            [110.389877874925588, -7.071191359762143],
            [110.389931967336949, -7.071155248852226],
            [110.390007493324234, -7.071173438617877],
            [110.390128539927289, -7.071218824272915],
            [110.390326798463505, -7.071291447258315],
            [110.390651220734739, -7.071391371511034],
            [110.390838750742603, -7.071445889382718],
            [110.390947677717762, -7.071455077237835],
            [110.391008254425799, -7.07143706555214],
            [110.391038566473824, -7.07140996886092],
            [110.391054742467887, -7.071373808252255],
            [110.391103553391744, -7.071265326791084],
            [110.391206929071572, -7.070976007856376],
            [110.39129823216615, -7.070614309601592],
            [110.391401785130171, -7.070189309237514],
            [110.391521808557002, -7.069719103136531],
            [110.391530627564208, -7.069682932893923],
            [110.391634002550276, -7.069393613792184],
            [110.391709235532957, -7.068986667199162],
            [110.39175679684088, -7.068751547829516],
            [110.391869556295518, -7.068426059119601],
            [110.391954220159604, -7.068163851798873],
            [110.392029617307529, -7.06784735971805],
            [110.392123713049756, -7.06751280111472],
            [110.392170743482438, -7.06725054464088],
            [110.39224590394771, -7.067114961014924],
            [110.39233040189319, -7.066979389544475],
            [110.392377373062374, -7.066762360162784],
            [110.392400965224098, -7.06646389132056],
            [110.392407037219471, -7.066364399369254],
            [110.392425465263216, -7.066120196441947],
            [110.39243795122843, -7.065875985766481],
            [110.392444294844097, -7.065568448984626],
            [110.392450637946368, -7.065478002823189],
            [110.392499000002587, -7.065279066096037],
            [110.392564186188864, -7.064980651402466],
            [110.392600015507, -7.064845016428511],
            [110.392627120449717, -7.064673188312533],
            [110.392663091372071, -7.064429008191697],
            [110.392681035501639, -7.0643385771266],
            [110.392704874437456, -7.064284335510861],
            [110.392737744280282, -7.064248196540675],
            [110.392785351312853, -7.064193985869556],
            [110.392806325288348, -7.064166876847628],
            [110.392836106225772, -7.064112642959413],
            [110.39286585175644, -7.064085545354252],
            [110.392895573685195, -7.064076538604087],
            [110.392952176205526, -7.064067566843269],
            [110.393008802318235, -7.064040504217778],
            [110.393061195917085, -7.064004390632133],
            [110.393119001009708, -7.063941147748886],
            [110.393145115294203, -7.063877863620215],
            [110.393145221440179, -7.063796454758143],
            [110.393103698164694, -7.063525037390539],
            [110.393062068772167, -7.063335028882939],
            [110.393041259983789, -7.063235501913669],
            [110.393010017326191, -7.063108825031601],
            [110.392947343312812, -7.063000198129697],
            [110.392895103192274, -7.062918721135589],
            [110.392842839492886, -7.062855334994897],
            [110.392758849692129, -7.062819043879307],
            [110.392570473925915, -7.062764525932616],
            [110.39250232952142, -7.062728255408732],
            [110.392439573036043, -7.062682946450064],
            [110.392345550501872, -7.062529051429535],
            [110.392335163906566, -7.062465719792938],
            [110.392347991230523, -7.062393372953782],
            [110.392354617207829, -7.062302927147934],
            [110.392351351652053, -7.062203423012466],
            [110.392338701440181, -7.062140088426928],
            [110.392309946560829, -7.062058641973993],
            [110.392268482452678, -7.061959088075042],
            [110.392185259523757, -7.061769025329003],
            [110.392182182477498, -7.06174188498874],
            [110.392185353996553, -7.061696661902472],
            [110.392204677401352, -7.061633368972597],
            [110.392223765080374, -7.061533893958863],
            [110.392246578354587, -7.061398242021049],
            [110.392246731848729, -7.061280651449613],
            [110.392237217750264, -7.061199230059593],
            [110.392208144558523, -7.061144919520769],
            [110.392131311571617, -7.061045319536197],
            [110.392096320034454, -7.060972910393112],
            [110.392093030900128, -7.060891497112702],
            [110.39211240151019, -7.060792022468499],
            [110.392157497082124, -7.060710672225619],
            [110.392176855860455, -7.060620243004888],
            [110.392216610744754, -7.06051174946321],
            [110.392322529352754, -7.060222433202482],
            [110.392365691379212, -7.060104898633185],
            [110.392365762195993, -7.06005062605762],
            [110.392361848091298, -7.060014439181952],
            [110.392346344795271, -7.059969191769561],
            [110.392291900491486, -7.059842484641546],
            [110.392229392295064, -7.059607221671303],
            [110.392217921162583, -7.059507706840143],
            [110.392225891002994, -7.059471535441921],
            [110.392258006086024, -7.059363031932002],
            [110.392279616568871, -7.059281651072741],
            [110.392295037537536, -7.059173125816844],
            [110.392305860474579, -7.059118867241323],
            [110.392331962737416, -7.059064628561132],
            [110.392347006321401, -7.059028466369613],
            [110.392362144310653, -7.058919940739849],
            [110.392379769842051, -7.058856645572889],
            [110.392425159902913, -7.058766250215963],
            [110.392460045387892, -7.058702977514158],
            [110.3924793567991, -7.058648729981048],
            [110.392542289732447, -7.058558357444228],
            [110.392620219000463, -7.058468004413057],
            [110.392678601090765, -7.05839571682195],
            [110.392795329841846, -7.058278277904566],
            [110.392823695594927, -7.058224042130943],
            [110.392830274189663, -7.058169778012497],
            [110.392832805598601, -7.058115508628799],
            [110.392889469690289, -7.058124627785078],
            [110.392911268628637, -7.058115610693606],
            [110.392935342954488, -7.058097551114244],
            [110.39295013886975, -7.058034252234017],
            [110.39296249431942, -7.057889541160953],
            [110.392969709513167, -7.057781005187039],
            [110.392984587917312, -7.057654388285018],
            [110.392991744165599, -7.057591079465352],
            [110.393069708529211, -7.057473590039804],
            [110.393140009648164, -7.05737418152836],
            [110.393311582855915, -7.057075904798216],
            [110.393388391693492, -7.056976504696039],
            [110.393448730558163, -7.056922310416281],
            [110.393521212677314, -7.056886222799397],
            [110.393649989915616, -7.056859253743694],
            [110.393855163441074, -7.056832383855211],
            [110.393979967597346, -7.056814454995141],
            [110.394104748219803, -7.056814616967775],
            [110.394555085913169, -7.056905655749691],
            [110.395025737446346, -7.05704194768907],
            [110.395460183486946, -7.057169146837478],
            [110.395784338093534, -7.057250975594555],
            [110.39618567947511, -7.057378131247372],
            [110.396439379899903, -7.057459868427912],
            [110.396483791296333, -7.057468971303842],
            [110.396511237425344, -7.057469006789405],
            [110.396542090669215, -7.057460001225724],
            [110.396569571948163, -7.057432900395801],
            [110.396721135663725, -7.057071278193885],
            [110.396786448296311, -7.056890453536868],
            [110.396872498141875, -7.056646337484668],
            [110.396906781842972, -7.056610199963401],
            [110.397088624592783, -7.056682798477159],
            [110.397342324849447, -7.056764535150648],
            [110.397482903911481, -7.056800898431209],
            [110.397561823410058, -7.056819091193504],
            [110.397743430184832, -7.056855507343873],
            [110.397904676319129, -7.056882851710345],
            [110.397997154054408, -7.056919152798732],
            [110.398150454274813, -7.056964577746568],
            [110.398592742197778, -7.057155102346455],
            [110.399064751912988, -7.057336619363859],
            [110.399501982503836, -7.057500000277049],
            [110.399788447658267, -7.057627005129485],
            [110.400637293497226, -7.058071323370315],
            [110.400976047030895, -7.058243621986795],
            [110.40129757572295, -7.058388761901877],
            [110.401423124418699, -7.058452241158129],
            [110.401860113099374, -7.058805574371216],
            [110.402060834037087, -7.058941513497281],
            [110.402156413411191, -7.058986863254106],
            [110.402397383213128, -7.059068581040913],
            [110.402650260259108, -7.059132223028492],
            [110.402821069949212, -7.059204805334132],
            [110.402765778477715, -7.05929518919367],
            [110.402665110365604, -7.059466924116848],
            [110.4026196873196, -7.059584456969536],
            [110.402589225864887, -7.05972914535434],
            [110.402594191351525, -7.059828651786031],
            [110.40263656446426, -7.059882978801474],
            [110.402747989379648, -7.059928348741143],
            [110.402869023075624, -7.059982776414155],
            [110.402999053274655, -7.060082442877921],
            [110.403203360737834, -7.060290749863995],
            [110.40328673880461, -7.060363220199099],
            [110.403426152971321, -7.060426716709845],
            [110.403547186886016, -7.060481144223306],
            [110.403667961047105, -7.060517480450059],
            [110.403770106723186, -7.060517610990717],
            [110.40394698647053, -7.060490700597864],
            [110.40412364112602, -7.060418562531129],
            [110.40422608131388, -7.060409647882586],
            [110.404328226976745, -7.06040977829908],
            [110.404467676030123, -7.060446138163646],
            [110.404597787691358, -7.060482486072956],
            [110.404700193201379, -7.060500707669781],
            [110.404802338893049, -7.060500837984159],
            [110.404932531519066, -7.060473867653135],
            [110.40503876695675, -7.060434339383512],
            [110.404985053231613, -7.060553796048493],
            [110.40494477039222, -7.060665770910615],
            [110.404936932131747, -7.060773462421144],
            [110.404938668033481, -7.06092155420705],
            [110.404998138993619, -7.061085105557141],
            [110.40513620588986, -7.06127159013621],
            [110.405330724075, -7.061483859555634],
            [110.405496206512552, -7.06166704787237],
            [110.405612216738291, -7.061887930349347],
            [110.405661410992039, -7.062068066032912],
            [110.405733819225048, -7.062283084127127],
            [110.405829545280454, -7.062451661463601],
            [110.405965992232211, -7.062605768629546],
            [110.406047091339332, -7.062798127071393],
            [110.406096343031805, -7.062980602658981],
            [110.406163971900398, -7.063232928456255],
            [110.406229504999772, -7.063430954730945],
            [110.40625546703842, -7.063590729970618],
            [110.406237760092765, -7.063796920067724],
            [110.406179269176548, -7.064066955104295],
            [110.406093166427823, -7.064407938760947],
            [110.405919416891308, -7.064929231878045],
            [110.405802645620867, -7.065303751075897],
            [110.405682985361963, -7.065663744526353],
            [110.405630390082081, -7.065869890080752],
            [110.405577820722172, -7.066055704837377],
            [110.405574727864732, -7.066200921031089],
            [110.405690724239335, -7.066433421135602],
            [110.405893873931646, -7.066718311546685],
            [110.406022900699554, -7.066881180423192],
            [110.406105723622773, -7.067020639387682],
            [110.406091031219219, -7.067142605590381],
            [110.40581158944363, -7.06740655012566],
            [110.405476848139926, -7.067626658781173],
            [110.405299527070255, -7.067786061561945],
            [110.405193447596801, -7.067937862220949],
            [110.405137700066234, -7.06804856067412],
            [110.405085369071458, -7.068147357517113],
            [110.405037742264909, -7.068289277507073],
            [110.405009652054986, -7.06864345694095],
            [110.404982325422111, -7.068935754618845],
            [110.404949902864487, -7.069108036357132],
            [110.404893244962267, -7.069343367862048],
            [110.404804641442936, -7.069583117128856],
            [110.404691841443523, -7.069810972393137],
            [110.404633782747652, -7.069965038621868],
            [110.404628567911843, -7.070066168164341],
            [110.404649604561044, -7.070160945600771],
            [110.404676456478697, -7.070254706904247],
            [110.404732943704801, -7.07034730506519],
            [110.404886994233891, -7.070540177357512],
            [110.405016625441661, -7.070716719103653],
            [110.405072350351602, -7.070799489600076],
            [110.405069790726216, -7.070877592949472],
            [110.404988990342531, -7.071062137129911],
            [110.404717083531423, -7.071593654444508],
            [110.404517475194112, -7.071933104855839],
            [110.404469991827085, -7.072014249551897],
            [110.40446998638842, -7.072014258846046],
            [110.404375570142776, -7.072191025215956],
            [110.404319318944047, -7.072350802186818],
            [110.404294396145545, -7.072437096141332],
            [110.404288919029881, -7.072556484519232],
            [110.404321048333159, -7.072671765508743],
            [110.404394145337562, -7.072752305110541],
            [110.404991424951604, -7.073084229476454],
            [110.405392998856158, -7.073275410775017],
            [110.405615789342477, -7.073394634647216],
            [110.40574433942453, -7.073471545252804],
            [110.405819642644119, -7.073566063160543],
            [110.405869663880381, -7.073662373623121],
            [110.405934865139216, -7.073793152523264],
            [110.405974762832471, -7.073958545593801],
            [110.40597881312965, -7.074052846045523],
            [110.405949337283772, -7.074145997891566],
            [110.405888212956825, -7.074306754524648],
            [110.40579015499452, -7.074426168647663],
            [110.405605134105926, -7.074495482620299],
            [110.405278661220308, -7.074590697168709],
            [110.404935846492094, -7.074693309371947],
            [110.404651934531046, -7.074694221461661],
            [110.404447457705857, -7.074665705273523],
            [110.404314728069451, -7.074598843845703],
            [110.404207041882643, -7.074500620849601],
            [110.404128293109352, -7.074356277104612],
            [110.404031033501653, -7.074176590057719],
            [110.403921973235541, -7.074049814064999],
            [110.403711780194186, -7.07401336315069],
            [110.4034844308826, -7.074045042317026],
            [110.403190509290837, -7.074205500275768],
            [110.403009725221992, -7.074370230402501],
            [110.402884322045566, -7.074575856237291],
            [110.40285913754505, -7.074726700959982],
            [110.402891709054657, -7.074820117109087],
            [110.402965547120971, -7.074881151604629],
            [110.403256860943415, -7.075057573626424],
            [110.403765519165944, -7.075408149020305],
            [110.4038534449586, -7.075500252946863],
            [110.403906687010505, -7.07560393934704],
            [110.403966667621404, -7.075785037367345],
            [110.403947163783116, -7.075981182841302],
            [110.403898949419201, -7.076046511259571],
            [110.403823823924441, -7.076079110139216],
            [110.403539347783706, -7.076138866972191],
            [110.403173146242935, -7.076195680646034],
            [110.402746158543962, -7.076297952696223],
            [110.402447875503512, -7.076464924754212],
            [110.402123349856211, -7.076736187970959],
            [110.401970797712366, -7.076933774964871],
            [110.401922325705144, -7.077092141482201],
            [110.401926878380706, -7.077248867176247],
            [110.402046297466214, -7.077438109773423],
            [110.402189347557879, -7.077546368854579],
            [110.402352847789516, -7.07761020514012],
            [110.402562742210037, -7.077623777812809],
            [110.402757631100471, -7.077528133998729],
            [110.402960170765553, -7.077371906202318],
            [110.403358630534612, -7.077131165324624],
            [110.40353494166925, -7.077068361525147],
            [110.403653182639644, -7.07703089513929],
            [110.403730164407349, -7.077052149384826],
            [110.403793620254234, -7.07711185108453],
            [110.403845916277902, -7.077183952176206],
            [110.403882690902606, -7.077349180680178],
            [110.403887284902922, -7.077635092178797],
            [110.403915995462555, -7.077765909230451],
            [110.403984284276916, -7.077866589397262],
            [110.404084226378856, -7.077975389342199],
            [110.4042064717339, -7.078102745118373],
            [110.404331029275951, -7.078239566991279],
            [110.404394864510536, -7.078353965456899],
            [110.404433851311111, -7.078490942047755],
            [110.404421249822121, -7.07860893494186],
            [110.404376893076588, -7.0786684986033],
            [110.404317181344368, -7.078691501055386],
            [110.404126889652559, -7.078638343038387],
            [110.403868088687901, -7.078555420918292],
            [110.403720515588546, -7.078569572909747],
            [110.403589539178839, -7.078602879717293],
            [110.403468194440322, -7.078659641816528],
            [110.4033153982909, -7.078769754528595],
            [110.403315375297041, -7.07876977957072],
            [110.403082061855017, -7.079023876318231],
            [110.402750825865468, -7.079436404751731],
            [110.402494225527988, -7.07967602707283],
            [110.402160085040009, -7.079682873499877],
            [110.401644618640674, -7.079609847360193],
            [110.400921798590772, -7.079491326512925],
            [110.400233984676035, -7.079436167840521],
            [110.399527801788935, -7.079362893565174],
            [110.398769553989482, -7.079289550973941],
            [110.398053797894121, -7.079180080344309],
            [110.397356706045429, -7.079079678256221],
            [110.39720616974509, -7.079079483295196],
            [110.397102853035378, -7.079106485795728],
            [110.397037453356077, -7.079133537400794],
            [110.396964413668698, -7.079160579096101],
            [110.396886268892345, -7.079196659607819],
            [110.396443420026941, -7.079205130917837],
            [110.395449111676839, -7.079185749419577],
            [110.394687976794259, -7.07915762370912],
            [110.394402219676977, -7.079130115616017],
            [110.393934623757815, -7.079020961616691],
            [110.39362401332879, -7.078957238931684],
            [110.393395697875278, -7.078929805075421],
            [110.392821307326841, -7.078910965165087],
            [110.392477260272685, -7.078883379859345],
            [110.392186728719722, -7.078828727886413],
            [110.391938346820282, -7.078783176246433],
            [110.391788942617509, -7.078782981020162],
            [110.391672597639101, -7.078819010704432],
            [110.391371737368914, -7.078872889962918],
            [110.390724519027387, -7.078935361142058],
            [110.390492501622532, -7.078926011898714],
            [110.390198881549153, -7.078853263789378],
            [110.389952492540942, -7.078798668282425],
            [110.389786995185304, -7.078771315017966],
            [110.389264953394161, -7.078752539380634],
            [110.388931918620813, -7.078743056795643],
            [110.388474734892441, -7.07867913827505],
            [110.387925835814173, -7.078642234997893],
            [110.387632591607911, -7.078632803820086],
            [110.38757434370217, -7.078252819127056],
            [110.387564574507053, -7.077936216227571],
            [110.387587262408516, -7.07768297403935],
            [110.387727653490288, -7.077221841780202],
            [110.387841043765334, -7.076851128249561],
            [110.387975137645569, -7.0764442601637],
            [110.388123709905145, -7.076001229314286],
            [110.388314136165533, -7.075576344236437],
            [110.388479152903685, -7.075241879998394],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 98,
        id: 100,
        area: 3411794.130748,
        perimeter: 8774.016447,
        kode_pos: 50227,
        kode_kec: 3374020,
        kode_kel: 3374020006,
        kec: "Gunungpati",
        kel: "Mangunsari",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.372328902964938, -7.081081811417651],
            [110.372363585752097, -7.081072812803724],
            [110.372484164606462, -7.081045839241749],
            [110.37263337158609, -7.080982722636056],
            [110.372761110016711, -7.080892440824295],
            [110.372891468366419, -7.080747890077864],
            [110.373043983214046, -7.080540051310025],
            [110.373199586087537, -7.080350307448506],
            [110.373288546178074, -7.080269018630973],
            [110.373428193119224, -7.080160661833387],
            [110.373485105166736, -7.080133602249172],
            [110.373555033475313, -7.080106560181207],
            [110.373688345336248, -7.08007960342034],
            [110.374099328567226, -7.079989702353918],
            [110.374629168000524, -7.079890915253594],
            [110.374987483454618, -7.079828078812093],
            [110.375194082405173, -7.079801220035311],
            [110.37559001956275, -7.079747479039271],
            [110.376078533914168, -7.079657680193223],
            [110.376357324569014, -7.079603781443102],
            [110.377002636126164, -7.079487055471287],
            [110.37725962640495, -7.079442172388435],
            [110.377413311222313, -7.079415241787055],
            [110.377595082013556, -7.079334076221523],
            [110.377662487597121, -7.079288939304316],
            [110.377737335021038, -7.079180494435221],
            [110.377832676846396, -7.078981622816682],
            [110.377916122074566, -7.078791780681812],
            [110.377972564727258, -7.078692356573193],
            [110.378130440849972, -7.07849356844485],
            [110.378329087502337, -7.078276743879428],
            [110.378598263952554, -7.078005740888899],
            [110.378822412972937, -7.077761813870819],
            [110.379079680290204, -7.077508885484925],
            [110.379202642536015, -7.077391459081246],
            [110.379440276393638, -7.07721991302366],
            [110.379818072283882, -7.076976190242842],
            [110.379989962264034, -7.076876919616293],
            [110.380221249778458, -7.076795818838446],
            [110.380389635510952, -7.076777952151478],
            [110.380870102755523, -7.076778591398424],
            [110.381226338239856, -7.076788110458554],
            [110.381369552503372, -7.076761164535154],
            [110.381658599873717, -7.076653003506561],
            [110.381769345828047, -7.076571741802721],
            [110.38219057739866, -7.076219529557817],
            [110.382289486929039, -7.076102070317356],
            [110.382383598107594, -7.075975559263672],
            [110.382440027433788, -7.075885179894771],
            [110.382539303931068, -7.075704403117734],
            [110.382536082218749, -7.075360672789504],
            [110.382533974797937, -7.075243079500981],
            [110.382543727647587, -7.075143592784839],
            [110.382594545707434, -7.075017024250715],
            [110.382650222118571, -7.074854280457307],
            [110.38269118459101, -7.074691517147371],
            [110.382716771100434, -7.074601096834058],
            [110.382741189717294, -7.074537811239816],
            [110.38282424098638, -7.074429376237961],
            [110.382927948245566, -7.07432096858882],
            [110.383022010824547, -7.074230638981859],
            [110.383083497036054, -7.074167402471052],
            [110.383148649694377, -7.074113216231081],
            [110.383217173838858, -7.074077125294242],
            [110.383258226927452, -7.07405908881524],
            [110.383340062138643, -7.074013970062285],
            [110.38336780413664, -7.074004961371278],
            [110.383399507561677, -7.073995957923504],
            [110.383617386104106, -7.07399624634578],
            [110.383945865719255, -7.074023817262689],
            [110.384343080519272, -7.074069569675833],
            [110.384967216806999, -7.074124666926257],
            [110.385195258077374, -7.074143058891786],
            [110.385403986366995, -7.074215697826238],
            [110.385756964266193, -7.074333754101668],
            [110.386149050525134, -7.07440663453333],
            [110.386509385753541, -7.074524699964132],
            [110.386929130794627, -7.074651888780752],
            [110.38734009241503, -7.074788111112308],
            [110.387624654654431, -7.074860848871135],
            [110.388152372000434, -7.075078632938287],
            [110.388414509303686, -7.075205613366263],
            [110.388479152903685, -7.075241879998394],
            [110.388314136165533, -7.075576344236437],
            [110.388123709905145, -7.076001229314286],
            [110.387975137645569, -7.0764442601637],
            [110.387841043765334, -7.076851128249561],
            [110.387727653490288, -7.077221841780202],
            [110.387587262408516, -7.07768297403935],
            [110.387564574507053, -7.077936216227571],
            [110.38757434370217, -7.078252819127056],
            [110.387632591607911, -7.078632803820086],
            [110.387678452039978, -7.07891327248252],
            [110.387756590890461, -7.079311374194796],
            [110.387798805488927, -7.079700383208307],
            [110.387796228483083, -7.080152651294647],
            [110.387793985371019, -7.080351647790306],
            [110.387769364289895, -7.080568705697802],
            [110.387678187107682, -7.080830903158183],
            [110.387566812002973, -7.081174482860156],
            [110.38740635265988, -7.081617497597099],
            [110.38731116577118, -7.081915871370881],
            [110.387233781618988, -7.082232359417908],
            [110.387193031126998, -7.082449395999208],
            [110.387166213856432, -7.082829268622388],
            [110.387169936510162, -7.083010182074149],
            [110.387195667050037, -7.083453441911368],
            [110.38721321549373, -7.083878600108031],
            [110.387241117907365, -7.084177135964484],
            [110.387249025322987, -7.084403282057292],
            [110.387280710130398, -7.084837504295752],
            [110.387268423153998, -7.08492794237329],
            [110.387227612606125, -7.085190205967959],
            [110.387184865265837, -7.085633375527038],
            [110.387210563671161, -7.085886681334103],
            [110.387307167291098, -7.086230534849081],
            [110.387391595205287, -7.086366327500768],
            [110.387611807896704, -7.086746525541374],
            [110.387833292524846, -7.087235270298728],
            [110.387889671164714, -7.087398162223531],
            [110.388020478815108, -7.087769196958955],
            [110.388209825237809, -7.088167445006014],
            [110.38841391016976, -7.088547621536642],
            [110.388695975497328, -7.089018354853131],
            [110.388820770284056, -7.08944365408663],
            [110.38891714915367, -7.089959370206362],
            [110.388947093089897, -7.090212681554276],
            [110.388936333988838, -7.090646847919659],
            [110.388945805598368, -7.091189586008494],
            [110.389019946225019, -7.091831908838147],
            [110.389091858980308, -7.092447092445226],
            [110.389169830327418, -7.093188919982923],
            [110.389221855018178, -7.093650305159512],
            [110.389299853570989, -7.094156951618824],
            [110.389368152325204, -7.09459122190209],
            [110.389079364434494, -7.094627023780325],
            [110.388866250717925, -7.094653879644714],
            [110.388596837485622, -7.094671615863011],
            [110.388288350225736, -7.094707391303025],
            [110.387957520092229, -7.094734091662608],
            [110.387789103935674, -7.094770051355849],
            [110.387727404079229, -7.094779015432625],
            [110.387609687710693, -7.094778860208894],
            [110.387542208854413, -7.09487827087349],
            [110.387457867204276, -7.095104295206211],
            [110.387294775418368, -7.095393533567467],
            [110.387098009683328, -7.095682727401683],
            [110.387069839836442, -7.095800280705578],
            [110.387041753743333, -7.095854516161391],
            [110.386979969955718, -7.09592679796862],
            [110.386890171846886, -7.095999042773052],
            [110.386407590973107, -7.096296904156456],
            [110.38628118135226, -7.096450509226385],
            [110.386040318656782, -7.09670346253017],
            [110.385829793800411, -7.096911228722612],
            [110.385416424864317, -7.097082544624229],
            [110.385281139608566, -7.097100456338515],
            [110.385183206825957, -7.097118417483765],
            [110.385130724685212, -7.097217847576879],
            [110.38505523761161, -7.097380565115534],
            [110.384814553977506, -7.097497836645243],
            [110.384671983375867, -7.09746146600848],
            [110.384551803761084, -7.09739798876435],
            [110.383260403424273, -7.096690732402127],
            [110.383223026905753, -7.09670877357816],
            [110.383177666920162, -7.09677203122094],
            [110.383132553759324, -7.096862425432027],
            [110.383064742496344, -7.096998016548906],
            [110.382989477498967, -7.097205961064528],
            [110.382989320866031, -7.097323551250457],
            [110.383041490216712, -7.09745930181395],
            [110.383094790928695, -7.097632475231596],
            [110.383094627609083, -7.09775509879726],
            [110.383019967796784, -7.097951940789524],
            [110.382774223298739, -7.098126260057192],
            [110.382368434687109, -7.098367251925795],
            [110.381817495964853, -7.098663788161933],
            [110.381408085819587, -7.098830456861624],
            [110.380947655333443, -7.098873244315367],
            [110.380521862047345, -7.098606322592888],
            [110.38030781342006, -7.098444322178636],
            [110.38015596865047, -7.098332862520977],
            [110.380201636377961, -7.098251514816991],
            [110.380384012044686, -7.097935168955833],
            [110.380461788786604, -7.097754364562911],
            [110.38048054972505, -7.097691071728538],
            [110.380480610216907, -7.097645844756244],
            [110.38045466135506, -7.097582492249232],
            [110.38042104802129, -7.097537220332327],
            [110.380353785071463, -7.097473812675624],
            [110.380294114011576, -7.097446596787294],
            [110.380264118844536, -7.097446556742838],
            [110.380241739750645, -7.097464617685387],
            [110.380200377237387, -7.097500744100709],
            [110.38014788192055, -7.097609218926614],
            [110.380076565053827, -7.097826213526581],
            [110.380054173826778, -7.097853319851226],
            [110.380016785000223, -7.097880406142853],
            [110.379983099042391, -7.09788940655782],
            [110.379945746536748, -7.097889356662722],
            [110.379867374981146, -7.097880206555523],
            [110.379811099586973, -7.097852995140099],
            [110.379684105059312, -7.097807598397547],
            [110.379635728712529, -7.097798488336758],
            [110.379594450935983, -7.097771296940698],
            [110.379560837679605, -7.097726024967965],
            [110.379542234135329, -7.097671727647374],
            [110.379553650001526, -7.097599379633775],
            [110.379568768747788, -7.097508945750276],
            [110.379595008248472, -7.09735520886378],
            [110.379598917082987, -7.097183351311378],
            [110.379595432268147, -7.097038620104589],
            [110.379613380707866, -7.096948189996848],
            [110.379635832478201, -7.096875856724332],
            [110.379695943853903, -7.096785482951735],
            [110.379808182747311, -7.096649951760072],
            [110.379841941269348, -7.096586678987176],
            [110.379853049857942, -7.096532421366132],
            [110.379849455973726, -7.096469098695464],
            [110.379834579511524, -7.096378624723539],
            [110.37979368168925, -7.096279070581061],
            [110.379752892872517, -7.096098107891861],
            [110.379734398344496, -7.095962402034502],
            [110.379712164633403, -7.095871918231309],
            [110.379655974262661, -7.095781389060358],
            [110.379476848510521, -7.095573105278993],
            [110.379297613859208, -7.095446229980384],
            [110.379174359268575, -7.095355611107292],
            [110.379064909739867, -7.095310237711979],
            [110.378986538646558, -7.095301087494453],
            [110.378919215585711, -7.095282906632604],
            [110.378881887548687, -7.095264765884873],
            [110.378810897991158, -7.095237534694894],
            [110.378773618472422, -7.095183212369182],
            [110.378721438484249, -7.095056506835855],
            [110.378676615785054, -7.094929811142666],
            [110.378646778471747, -7.09481218090678],
            [110.378609486873387, -7.094766903963923],
            [110.378557197791764, -7.094721606948665],
            [110.378516215448386, -7.094685370468916],
            [110.378467544351864, -7.094685305328951],
            [110.378351821148499, -7.09467610501973],
            [110.378048179887656, -7.094706837766578],
            [110.377779126782045, -7.094752805500571],
            [110.377471278217072, -7.094837742748599],
            [110.37737023307362, -7.094855698089797],
            [110.377336559461085, -7.0948556529384],
            [110.377302898004302, -7.09484656239448],
            [110.37724348361651, -7.094801415291956],
            [110.377142401192245, -7.094674484429996],
            [110.377131450416954, -7.094611151898658],
            [110.377128952308581, -7.094574966923221],
            [110.377120362574502, -7.094439274308763],
            [110.377056961548064, -7.094240190330367],
            [110.376907808190055, -7.094050036709542],
            [110.37681822801359, -7.093959462455437],
            [110.376693830286072, -7.093877886865404],
            [110.37662286542583, -7.093832564590403],
            [110.376533261010863, -7.09376008106737],
            [110.37650362209007, -7.093705768845807],
            [110.3764811304614, -7.093597193785342],
            [110.376462600430244, -7.093488624042728],
            [110.376421715729549, -7.09338002428707],
            [110.376362130649824, -7.093289490231239],
            [110.376224050234569, -7.093072215085235],
            [110.376100869961505, -7.092927323162058],
            [110.375950253834006, -7.092773348948008],
            [110.375782327905668, -7.092655533050454],
            [110.37565168100592, -7.092592039633685],
            [110.375625376974796, -7.092582958873288],
            [110.375558091030811, -7.092537641404093],
            [110.375513418326932, -7.092510445136051],
            [110.375479805831901, -7.092465172925674],
            [110.375416235093738, -7.092392724220779],
            [110.375390274992085, -7.092338416890156],
            [110.375364007548313, -7.092302199953428],
            [110.375337998699507, -7.092284074171396],
            [110.37527803347183, -7.092265902723321],
            [110.375177296209614, -7.092265767236627],
            [110.375102579979711, -7.09227471213652],
            [110.375068906569297, -7.092274666837085],
            [110.375008929160558, -7.092265540742409],
            [110.374818490471412, -7.092265284482976],
            [110.374676427423793, -7.092274138671232],
            [110.374570891889078, -7.092264951190915],
            [110.374533293520869, -7.092237764360082],
            [110.374518615708183, -7.092210608388897],
            [110.374473806606645, -7.092074867012677],
            [110.374470176824744, -7.092038680514229],
            [110.374466559249115, -7.091993448629467],
            [110.374477668298809, -7.091939191171632],
            [110.374492714508747, -7.091903029820035],
            [110.374511475934838, -7.091839737261405],
            [110.374530310580766, -7.091722172382486],
            [110.374544815247077, -7.091667919492862],
            [110.374575683322121, -7.091649870243779],
            [110.374618977644545, -7.091649928526321],
            [110.374673037007028, -7.091640955891831],
            [110.374691171452213, -7.091622889494501],
            [110.374714140776732, -7.09158673879721],
            [110.374716441132918, -7.09155960568343],
            [110.37469675528267, -7.09146912515455],
            [110.374682138484033, -7.091396742254546],
            [110.374679689366047, -7.091324375731573],
            [110.374687144169471, -7.091252022538486],
            [110.37468719297074, -7.091215840990653],
            [110.37468244349661, -7.091170607581286],
            [110.374658193555717, -7.091107257118573],
            [110.3746390492648, -7.091034868123812],
            [110.374622205334305, -7.090935346014736],
            [110.374624874064637, -7.090844895572799],
            [110.374639512888024, -7.090691143417083],
            [110.374683431668842, -7.09043793122475],
            [110.374722122779303, -7.090284211428845],
            [110.374739210673653, -7.0902028257886],
            [110.374758845277341, -7.09012144357369],
            [110.374768539387006, -7.090067184193895],
            [110.374761255387568, -7.090012901969221],
            [110.374734692946276, -7.089985730015928],
            [110.374691164602041, -7.089949489827156],
            [110.374652717496687, -7.089922301876777],
            [110.374584841686882, -7.089895074316579],
            [110.374560801577132, -7.089885996556828],
            [110.374502805267454, -7.089876873089815],
            [110.374447048302073, -7.089885843437326],
            [110.374408269435804, -7.089894836635443],
            [110.374376847745424, -7.089903839735537],
            [110.374345438258672, -7.089903797446514],
            [110.374280084776586, -7.089894664046053],
            [110.374181094690186, -7.089858349128201],
            [110.374139264214591, -7.089822111178888],
            [110.374107620603084, -7.08978588694713],
            [110.374088403180139, -7.089767770256905],
            [110.37405703033447, -7.089740591789875],
            [110.374003881151026, -7.089704338583569],
            [110.373958089148942, -7.089668095283502],
            [110.373924181816847, -7.089631867991463],
            [110.373909811504035, -7.089586621617119],
            [110.373890408832267, -7.089496141448818],
            [110.373897863703291, -7.089423788270965],
            [110.373902796283801, -7.089333340888822],
            [110.373900298421333, -7.089297155912387],
            [110.373890714148317, -7.089270006791455],
            [110.373871508967028, -7.089242844708163],
            [110.37382459736088, -7.089197554487905],
            [110.373764103306726, -7.089152245961161],
            [110.37371347649173, -7.089134086932552],
            [110.373652945807066, -7.089115914550871],
            [110.373568362863523, -7.089097709741476],
            [110.373524798039597, -7.089088605614705],
            [110.373483779927909, -7.089079504917255],
            [110.373442503291983, -7.089052313063543],
            [110.373389342015145, -7.089025105182579],
            [110.373365289755583, -7.08902507275217],
            [110.373336119630821, -7.089043124223411],
            [110.373314269996058, -7.089088321769842],
            [110.373270570712876, -7.089178716858241],
            [110.37324639619662, -7.089269138273359],
            [110.373229369221079, -7.089305296916161],
            [110.373200457589078, -7.08934143952853],
            [110.373139865767953, -7.089368494004596],
            [110.373113266780152, -7.089368458124299],
            [110.373077059110557, -7.089359363878686],
            [110.373043127415229, -7.089341227299585],
            [110.372997298840076, -7.089332120068314],
            [110.37294636461364, -7.089332051344755],
            [110.372919765628907, -7.089332015453567],
            [110.372893178876311, -7.089322934176273],
            [110.372849601817606, -7.089322875370108],
            [110.372823027300598, -7.089304748704498],
            [110.372801263239808, -7.089286628529841],
            [110.372789427524225, -7.089250430952232],
            [110.372832795005735, -7.089196217074455],
            [110.372852368716465, -7.089160061885137],
            [110.372857215866446, -7.089132932222977],
            [110.37284990763365, -7.089096740756285],
            [110.372845158343949, -7.089051507341667],
            [110.372862234265256, -7.088979167175735],
            [110.37287677570292, -7.088897778187278],
            [110.372903496972242, -7.088807360230889],
            [110.372910915283413, -7.088762143233796],
            [110.372908417497996, -7.08872595825821],
            [110.372898845524489, -7.088689763737452],
            [110.372884475335624, -7.088644517340921],
            [110.372843530670281, -7.088581144282678],
            [110.372799165941785, -7.088535857408802],
            [110.372769786261173, -7.088499636155697],
            [110.372758504264425, -7.088472484726496],
            [110.372762502506959, -7.08844535391874],
            [110.3727772902064, -7.088391101467732],
            [110.372810851598743, -7.088264511139085],
            [110.372821997405254, -7.088183117566662],
            [110.372818379988573, -7.088137885678366],
            [110.372811059536204, -7.088110739596085],
            [110.372788741825445, -7.08808357327634],
            [110.372740415606344, -7.088038281054773],
            [110.372685581153618, -7.087992980045267],
            [110.372652805908359, -7.08795675420494],
            [110.372641806896169, -7.087929603155955],
            [110.372636516163794, -7.087866278206541],
            [110.372650208687489, -7.087784888075966],
            [110.372653099526886, -7.087739664971198],
            [110.372650331027174, -7.087694434228198],
            [110.372622956643411, -7.087640124874008],
            [110.372573830524587, -7.08755864995516],
            [110.3725272266513, -7.087495269239403],
            [110.372450628316173, -7.087431848027508],
            [110.372423217256255, -7.087404674817915],
            [110.372390454309013, -7.08735940357916],
            [110.372382284990536, -7.087332256346373],
            [110.372379504277845, -7.087296070987988],
            [110.37239061340648, -7.087241813580724],
            [110.372415304928126, -7.087187574510068],
            [110.37244820247362, -7.087133346515497],
            [110.372579904265393, -7.087043070277112],
            [110.372629226069591, -7.086979819032295],
            [110.372656722747323, -7.086943674533493],
            [110.37268391199045, -7.086925620421099],
            [110.37271139642931, -7.086898521303535],
            [110.372738868634229, -7.086880467569297],
            [110.372755305147223, -7.08686239894328],
            [110.372777413216767, -7.086835292566895],
            [110.372812906307203, -7.086744886434036],
            [110.37285686391489, -7.086672582519809],
            [110.372887190218492, -7.086636441820967],
            [110.372925439572441, -7.086600311806767],
            [110.372961105546707, -7.086591314511249],
            [110.373021389583499, -7.086582350414472],
            [110.373095231551986, -7.08659149539745],
            [110.373161470101124, -7.086573493909207],
            [110.373262808680323, -7.086546494328576],
            [110.37332309270883, -7.086537530191633],
            [110.37343544252353, -7.086528636220778],
            [110.373481848987055, -7.086528698762357],
            [110.373547792336296, -7.086519742222388],
            [110.373574981523035, -7.086501688052321],
            [110.373597089529085, -7.086474581630821],
            [110.373613562634304, -7.086429376809084],
            [110.373629999098426, -7.086411308147397],
            [110.373654653792187, -7.086384205153538],
            [110.373673932126209, -7.086357094915114],
            [110.373695793786439, -7.086302851944992],
            [110.3737015141634, -7.086257632632997],
            [110.373697023338849, -7.086230490372424],
            [110.373679908942506, -7.086121922473427],
            [110.373661626054471, -7.0860404892108],
            [110.373639357225812, -7.085977141385174],
            [110.373612831702417, -7.085922833227653],
            [110.373590809213567, -7.085886621943096],
            [110.373550689127796, -7.085841340870453],
            [110.373528912972702, -7.085832266126012],
            [110.373499484527485, -7.08583222647289],
            [110.373455637040749, -7.085823121984113],
            [110.373399092742943, -7.085786864170316],
            [110.373382434410431, -7.085759705510068],
            [110.37336806423869, -7.085714459126661],
            [110.373349793632897, -7.085623980469758],
            [110.373331461948325, -7.085578728746458],
            [110.373261999015924, -7.08547009028194],
            [110.373199880998968, -7.085370507113711],
            [110.373114350198136, -7.085216619956429],
            [110.37302116716512, -7.085071767872361],
            [110.372930813840298, -7.08492691958971],
            [110.372828588258827, -7.084773009878554],
            [110.372790436869735, -7.084736776810801],
            [110.37275199029483, -7.084709588744833],
            [110.372713519269738, -7.08470049144819],
            [110.372629490869073, -7.084691332687804],
            [110.372530736058707, -7.08469119944403],
            [110.372490272053867, -7.084691144842386],
            [110.372462836674643, -7.084682062417268],
            [110.372441060597737, -7.084672987628076],
            [110.372417340465773, -7.084636774008035],
            [110.372351544191318, -7.084537185784233],
            [110.372311756245423, -7.084455723463048],
            [110.372218105570184, -7.084238507401965],
            [110.372186806611481, -7.084157056532655],
            [110.372184874805441, -7.084120872317207],
            [110.372184960462164, -7.084057554619523],
            [110.37218905656843, -7.083958060727772],
            [110.372179213989881, -7.083912820430992],
            [110.372171622892708, -7.083876628575471],
            [110.372149600605397, -7.083840417237909],
            [110.372122177520907, -7.083822289411997],
            [110.37208711439699, -7.083804151269633],
            [110.372063677297177, -7.083767938018585],
            [110.372063714012526, -7.083740801862676],
            [110.372077333021281, -7.083713684044894],
            [110.372112789239139, -7.08365041410074],
            [110.372151923983708, -7.083587149118729],
            [110.372177415249496, -7.083569092725871],
            [110.37221281026585, -7.083551049699773],
            [110.372236345246392, -7.083514899858248],
            [110.372224509683733, -7.083478702274163],
            [110.372212957086987, -7.083442505071828],
            [110.372213006026854, -7.083406323529065],
            [110.372226649484944, -7.083361114933423],
            [110.37226411086921, -7.083279756874447],
            [110.372279747300126, -7.083225505563662],
            [110.372279796233286, -7.0831893240201],
            [110.372268231403893, -7.083162172204834],
            [110.372230916842142, -7.083134985635695],
            [110.372166449967992, -7.083098717011381],
            [110.372117533891213, -7.083071514773863],
            [110.372100014592618, -7.083053400319386],
            [110.37209212830922, -7.083026253466667],
            [110.372094158003662, -7.082990074598047],
            [110.372115761156735, -7.082917740543295],
            [110.372129687569426, -7.082872532331098],
            [110.372129748747355, -7.082827305402256],
            [110.372109965745395, -7.082809187893045],
            [110.372080808227338, -7.082818193934668],
            [110.372049350285636, -7.082854333075178],
            [110.372002329322058, -7.082890451201009],
            [110.371976838089921, -7.082908507587473],
            [110.371908397533687, -7.082881278968117],
            [110.371869090028611, -7.082863135084092],
            [110.371845640746713, -7.082835967211563],
            [110.371830102214929, -7.082817855423385],
            [110.371822215950516, -7.082790708567559],
            [110.371822252674875, -7.082763572411775],
            [110.37182429462861, -7.082718348160392],
            [110.371849871539922, -7.082636974084289],
            [110.371863785726646, -7.082600811266453],
            [110.371881353959708, -7.082582744186134],
            [110.371922395942647, -7.08257375420322],
            [110.371938217434732, -7.082591866370167],
            [110.371959685964967, -7.08261903156333],
            [110.372006645714322, -7.082628140367509],
            [110.372053666649236, -7.082592022238469],
            [110.37225132215255, -7.082483744195872],
            [110.372347332465367, -7.082420555935464],
            [110.372373131080167, -7.082384409133745],
            [110.372412387959997, -7.082230690249068],
            [110.372434408494442, -7.082058857299011],
            [110.372434494091934, -7.081995539592684],
            [110.372426644485728, -7.081941256584374],
            [110.372391335206686, -7.081895981933445],
            [110.372307368472434, -7.081841596226668],
            [110.372200764696061, -7.081787179957895],
            [110.37215665912386, -7.081759984226569],
            [110.372137454322555, -7.081732822099173],
            [110.372134402894403, -7.081687590967192],
            [110.372153410362259, -7.081651435008864],
            [110.372204453825589, -7.08157009526777],
            [110.372261488417848, -7.081452581991124],
            [110.372287077395242, -7.081362162487086],
            [110.372300006404259, -7.081217453480614],
            [110.372309417556437, -7.081163193758631],
            [110.37231911166873, -7.08110893441789],
            [110.372328902964938, -7.081081811417651],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 99,
        id: 101,
        area: 3655632.075245,
        perimeter: 8699.403521,
        kode_pos: 50227,
        kode_kec: 3374020,
        kode_kel: 3374020005,
        kec: "Gunungpati",
        kel: "Pakintelan",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.389368152325204, -7.09459122190209],
            [110.389299853570989, -7.094156951618824],
            [110.389221855018178, -7.093650305159512],
            [110.389169830327418, -7.093188919982923],
            [110.389091858980308, -7.092447092445226],
            [110.389019946225019, -7.091831908838147],
            [110.388945805598368, -7.091189586008494],
            [110.388936333988838, -7.090646847919659],
            [110.388947093089897, -7.090212681554276],
            [110.38891714915367, -7.089959370206362],
            [110.388820770284056, -7.08944365408663],
            [110.388695975497328, -7.089018354853131],
            [110.38841391016976, -7.088547621536642],
            [110.388209825237809, -7.088167445006014],
            [110.388020478815108, -7.087769196958955],
            [110.387889671164714, -7.087398162223531],
            [110.387833292524846, -7.087235270298728],
            [110.387611807896704, -7.086746525541374],
            [110.387391595205287, -7.086366327500768],
            [110.387307167291098, -7.086230534849081],
            [110.387210563671161, -7.085886681334103],
            [110.387184865265837, -7.085633375527038],
            [110.387227612606125, -7.085190205967959],
            [110.387268423153998, -7.08492794237329],
            [110.387280710130398, -7.084837504295752],
            [110.387249025322987, -7.084403282057292],
            [110.387241117907365, -7.084177135964484],
            [110.38721321549373, -7.083878600108031],
            [110.387195667050037, -7.083453441911368],
            [110.387169936510162, -7.083010182074149],
            [110.387166213856432, -7.082829268622388],
            [110.387193031126998, -7.082449395999208],
            [110.387233781618988, -7.082232359417908],
            [110.38731116577118, -7.081915871370881],
            [110.38740635265988, -7.081617497597099],
            [110.387566812002973, -7.081174482860156],
            [110.387678187107682, -7.080830903158183],
            [110.387769364289895, -7.080568705697802],
            [110.387793985371019, -7.080351647790306],
            [110.387796228483083, -7.080152651294647],
            [110.387798805488927, -7.079700383208307],
            [110.387756590890461, -7.079311374194796],
            [110.387678452039978, -7.07891327248252],
            [110.387632591607911, -7.078632803820086],
            [110.387925835814173, -7.078642234997893],
            [110.388474734892441, -7.07867913827505],
            [110.388931918620813, -7.078743056795643],
            [110.389264953394161, -7.078752539380634],
            [110.389786995185304, -7.078771315017966],
            [110.389952492540942, -7.078798668282425],
            [110.390198881549153, -7.078853263789378],
            [110.390492501622532, -7.078926011898714],
            [110.390724519027387, -7.078935361142058],
            [110.391371737368914, -7.078872889962918],
            [110.391672597639101, -7.078819010704432],
            [110.391788942617509, -7.078782981020162],
            [110.391938346820282, -7.078783176246433],
            [110.392186728719722, -7.078828727886413],
            [110.392477260272685, -7.078883379859345],
            [110.392821307326841, -7.078910965165087],
            [110.393395697875278, -7.078929805075421],
            [110.39362401332879, -7.078957238931684],
            [110.393934623757815, -7.079020961616691],
            [110.394402219676977, -7.079130115616017],
            [110.394687976794259, -7.07915762370912],
            [110.395449111676839, -7.079185749419577],
            [110.396443420026941, -7.079205130917837],
            [110.396886268892345, -7.079196659607819],
            [110.396964413668698, -7.079160579096101],
            [110.397037453356077, -7.079133537400794],
            [110.397102853035378, -7.079106485795728],
            [110.39720616974509, -7.079079483295196],
            [110.397356706045429, -7.079079678256221],
            [110.398053797894121, -7.079180080344309],
            [110.398769553989482, -7.079289550973941],
            [110.399527801788935, -7.079362893565174],
            [110.400233984676035, -7.079436167840521],
            [110.400921798590772, -7.079491326512925],
            [110.401644618640674, -7.079609847360193],
            [110.402160085040009, -7.079682873499877],
            [110.402494225527988, -7.07967602707283],
            [110.402269077664371, -7.079886638918864],
            [110.402201585689696, -7.080004283757552],
            [110.402171051757833, -7.080111497854687],
            [110.402147479476142, -7.080363073338478],
            [110.402211454347636, -7.08067667512105],
            [110.402250298503503, -7.081270483944064],
            [110.402251600539657, -7.08168164840527],
            [110.402277599892528, -7.082034842172598],
            [110.402325021559065, -7.082125357625678],
            [110.402437548632065, -7.082197774897204],
            [110.402593315734464, -7.082192088977457],
            [110.402835113046848, -7.082136222218432],
            [110.40310768160677, -7.082048419342532],
            [110.403448921884149, -7.081971982688834],
            [110.403657973189482, -7.08198235913337],
            [110.40388109296164, -7.082036676081285],
            [110.40397010099035, -7.082110576113591],
            [110.404033483434375, -7.082227974907539],
            [110.404069882847537, -7.082368417997453],
            [110.404081224454515, -7.082531907854114],
            [110.404102165139008, -7.082716565637826],
            [110.40410576626104, -7.082910817402319],
            [110.404109030517347, -7.083229812671545],
            [110.404189134075509, -7.083957166268545],
            [110.404192782155135, -7.084193534575199],
            [110.404153914070008, -7.08439754288259],
            [110.404078599489722, -7.084576307601795],
            [110.403991772838395, -7.08472428572507],
            [110.403875857228115, -7.084901760736971],
            [110.403573317654235, -7.085248793388924],
            [110.403399774979931, -7.085458203771615],
            [110.403279009326795, -7.085637723134471],
            [110.403237709489488, -7.085725325963318],
            [110.403230306206012, -7.085826624461401],
            [110.403272598772375, -7.085983743639275],
            [110.403472012239192, -7.086225964382472],
            [110.403794638537747, -7.086485323071993],
            [110.403938853061533, -7.086693512875293],
            [110.404006419127484, -7.087024709311495],
            [110.404067461088559, -7.087312580339705],
            [110.404065153653093, -7.087614839267323],
            [110.404030912420282, -7.087805820252258],
            [110.403856389278673, -7.088034826307214],
            [110.403639212871155, -7.088386882468935],
            [110.403380972122989, -7.088932501025193],
            [110.403209332005602, -7.089350742036242],
            [110.402985945187893, -7.089887204051093],
            [110.402825315331853, -7.090130530908195],
            [110.402740857064074, -7.090315457646665],
            [110.402713739662218, -7.090442356499723],
            [110.402740574644213, -7.090534706419401],
            [110.4028585404578, -7.09072911805249],
            [110.403015471413767, -7.090962794498767],
            [110.403131725543801, -7.091184494237873],
            [110.403208690544758, -7.091389034953893],
            [110.403251411569087, -7.091535446616445],
            [110.40324759059655, -7.091714213798262],
            [110.403240247729926, -7.091832380239225],
            [110.403210755621885, -7.092009280256265],
            [110.403162828286455, -7.092149230412826],
            [110.403100868670862, -7.092266305073688],
            [110.402968339389602, -7.092475161652219],
            [110.402839065376838, -7.09265207613316],
            [110.402345502594869, -7.093131125820622],
            [110.401796766164296, -7.093554918898759],
            [110.401362826398156, -7.093932164286706],
            [110.401132989714782, -7.094212037585822],
            [110.401080673166973, -7.094349811191091],
            [110.401073172560075, -7.094462415093246],
            [110.401064982468611, -7.094572886462183],
            [110.401080261535213, -7.094668298729177],
            [110.401120142633005, -7.094796053108166],
            [110.401192362764803, -7.094928464957154],
            [110.401332339475374, -7.09507173428574],
            [110.401592351523533, -7.095295164954782],
            [110.401740045557176, -7.095426135404648],
            [110.4018369415694, -7.095533961528159],
            [110.401899978172565, -7.095621742352541],
            [110.401919852330266, -7.09573716219191],
            [110.401891983015076, -7.095849443351089],
            [110.401825615611187, -7.095955520550389],
            [110.401746944128192, -7.0960477345751],
            [110.401655876390976, -7.096108760906598],
            [110.401406118384628, -7.096244120648588],
            [110.401206754816201, -7.096321894695939],
            [110.400985599608489, -7.096406782893435],
            [110.400956261398477, -7.096463672852498],
            [110.400959222511133, -7.096555992045706],
            [110.401037500353553, -7.096768418400691],
            [110.401137867500566, -7.097013739808287],
            [110.401160254389822, -7.097128606718983],
            [110.401166533076463, -7.097249524767738],
            [110.401139787631195, -7.097471685783855],
            [110.401104093420869, -7.097679349317658],
            [110.401049924166117, -7.097880834647263],
            [110.400927924262177, -7.098128390106591],
            [110.400852438114484, -7.098261884363914],
            [110.400818272084152, -7.098355959798919],
            [110.400806898382754, -7.098447139995867],
            [110.400828199395434, -7.098656184920022],
            [110.400877498698193, -7.098899743087866],
            [110.400946584211937, -7.09918234792711],
            [110.401012522464072, -7.099408605674305],
            [110.401047874627565, -7.099465579109236],
            [110.401069328707578, -7.099550229213986],
            [110.401063024413375, -7.09966099951197],
            [110.401043233518408, -7.099731382871299],
            [110.401001358816302, -7.099869571427755],
            [110.400878019322917, -7.100164622364961],
            [110.400702913586613, -7.100455296758938],
            [110.400512821941433, -7.100657604144583],
            [110.400333454496078, -7.100907331302443],
            [110.400167018614994, -7.101165694364345],
            [110.399996262642787, -7.10142836139782],
            [110.399869766618863, -7.101698088287404],
            [110.399703037533399, -7.101991743049519],
            [110.399608926005783, -7.102111631187245],
            [110.399518566778951, -7.102171155582321],
            [110.399330672993798, -7.10234114294447],
            [110.399238822168357, -7.102451180145204],
            [110.399168572700276, -7.102582272615194],
            [110.399041119333731, -7.102874035931],
            [110.398978056966328, -7.103109956150121],
            [110.398957922990178, -7.103228285953403],
            [110.398902889105415, -7.10325110252063],
            [110.398829008399275, -7.10326909764663],
            [110.398785724544013, -7.103259996105619],
            [110.398753512077505, -7.103223772596925],
            [110.398717703164991, -7.103124226355152],
            [110.398661684405511, -7.102898017773545],
            [110.398611584712867, -7.102689907738334],
            [110.398536182537413, -7.102572219276971],
            [110.398430113047453, -7.102535899980927],
            [110.39834662297342, -7.102544837138587],
            [110.398254608298558, -7.102580899536495],
            [110.398146664545266, -7.102680259311748],
            [110.398000401138304, -7.102870023701739],
            [110.397953037997894, -7.102951371166013],
            [110.397837630757778, -7.103132130101216],
            [110.39782311651382, -7.103195429317188],
            [110.397772640550244, -7.103276772715257],
            [110.397707213830046, -7.103321914945241],
            [110.397593704416948, -7.103348903845064],
            [110.397397919115932, -7.103321513212175],
            [110.397236940107717, -7.103294167732125],
            [110.397040742256749, -7.103366276208136],
            [110.396935781074177, -7.103348048877908],
            [110.396771536050878, -7.103221199203753],
            [110.396722769558949, -7.103076408815428],
            [110.396740502806139, -7.102931704914567],
            [110.396779093845907, -7.102850346183953],
            [110.396961142631909, -7.10277821940807],
            [110.39703896142224, -7.102778320569548],
            [110.397199904894563, -7.102832802365204],
            [110.397280258715753, -7.102841952217557],
            [110.397305007318465, -7.102742484575331],
            [110.397315654152862, -7.102606816863397],
            [110.397277357692275, -7.102462040123662],
            [110.397280859423503, -7.102380635745972],
            [110.397274221087841, -7.102263036447412],
            [110.397231291018343, -7.102199662612627],
            [110.397080888595568, -7.102090921927315],
            [110.396800694131329, -7.101909648968404],
            [110.396723736209367, -7.101900503462863],
            [110.396590819618652, -7.101837012550648],
            [110.396562852078389, -7.101800794431282],
            [110.396541958961848, -7.101764585512775],
            [110.396527857290451, -7.101728385427848],
            [110.396573829220671, -7.10141185498053],
            [110.396612443718212, -7.101312405409486],
            [110.39659787041596, -7.10120384122742],
            [110.39649319273606, -7.100968523751125],
            [110.396367386225975, -7.100877905738895],
            [110.396097827877867, -7.100787100618371],
            [110.395652152259757, -7.100777474900905],
            [110.395560986711502, -7.100813537884915],
            [110.395435062329341, -7.100813373824656],
            [110.395235635049858, -7.100758841329233],
            [110.395190441589079, -7.100695464386204],
            [110.395173086136751, -7.100550714824696],
            [110.395120146633928, -7.100351646274954],
            [110.395022414277037, -7.100215837359665],
            [110.394938583243444, -7.100052910253742],
            [110.394917666686609, -7.100034792112896],
            [110.394889652098982, -7.100034755582197],
            [110.394812611792531, -7.10008892771353],
            [110.394661950487219, -7.100179185527584],
            [110.394567365636206, -7.100233334725657],
            [110.394521806542855, -7.100233275280239],
            [110.394483345501669, -7.100215134227486],
            [110.394417247487723, -7.100124593643679],
            [110.394307890723809, -7.099789769911689],
            [110.39421739877541, -7.099527334248465],
            [110.394154401796612, -7.099445843117499],
            [110.393969289778198, -7.09926469277363],
            [110.39388532904502, -7.099201265100103],
            [110.393685902682819, -7.099146732009079],
            [110.393527990774643, -7.099155571109283],
            [110.393197314455563, -7.098630503861288],
            [110.393068950637144, -7.098548927139944],
            [110.392843762129871, -7.098503405458687],
            [110.392059144895924, -7.098448105820798],
            [110.391152447067654, -7.098483099024882],
            [110.390703592675578, -7.098310646887013],
            [110.389581465820513, -7.097766446952196],
            [110.389609251155136, -7.097296121351093],
            [110.389657711454362, -7.096382597032885],
            [110.389583873507632, -7.095939274071495],
            [110.389368152325204, -7.09459122190209],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 100,
        id: 102,
        area: 10119437.596882001,
        perimeter: 17348.524199,
        kode_pos: 50214,
        kode_kec: 3374010,
        kode_kel: 3374010013,
        kec: "Mijen",
        kel: "Wonoplumbon",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.296757412079231, -7.038595134167913],
            [110.296748239182236, -7.038650859307849],
            [110.296738510522829, -7.038723206961656],
            [110.296646926347805, -7.039229605222142],
            [110.296602434610577, -7.039464715721836],
            [110.296464046617686, -7.03986250014225],
            [110.296186986901859, -7.040658068344368],
            [110.29600228546829, -7.041372367725421],
            [110.295775166858647, -7.042258463438689],
            [110.295383033713136, -7.043560393220342],
            [110.295235152149814, -7.044247611619736],
            [110.295234660436165, -7.044573240927088],
            [110.295234455548595, -7.044708919803806],
            [110.295233840861641, -7.045115956429211],
            [110.295235760709957, -7.046092849419439],
            [110.295310641280196, -7.046907037357841],
            [110.295590254033172, -7.048734605048231],
            [110.295777155696314, -7.050688666802577],
            [110.295965260190741, -7.051846745865116],
            [110.295812542288203, -7.052362096479113],
            [110.295811831783823, -7.052832449817344],
            [110.295867527900185, -7.053610427604972],
            [110.296003610971937, -7.054180485208867],
            [110.296151179428591, -7.0557003141628],
            [110.296204027263542, -7.056179793488691],
            [110.29623938723401, -7.056559748405671],
            [110.296268873727996, -7.056894468083362],
            [110.296310138559647, -7.057111616922903],
            [110.296351836648682, -7.057229268373899],
            [110.296351741013311, -7.057292585164187],
            [110.296303868825035, -7.05732869410185],
            [110.29621996708201, -7.057428065644151],
            [110.296003967287888, -7.057690052910332],
            [110.295788359585771, -7.057879678441254],
            [110.295573103049009, -7.058024078011037],
            [110.295405340008145, -7.058195684991661],
            [110.295273524490753, -7.058358300906327],
            [110.29512986626834, -7.058493763066808],
            [110.294919276121902, -7.058800774222223],
            [110.294919275731246, -7.05880077377022],
            [110.294888386632024, -7.058764756485006],
            [110.2948615826609, -7.058737209271956],
            [110.294844315714855, -7.058719463531871],
            [110.294791756463994, -7.058674157748846],
            [110.294688915247903, -7.058574504336702],
            [110.294535226245543, -7.058420502412893],
            [110.29441094986781, -7.058275590186934],
            [110.294294582305014, -7.058139735165113],
            [110.294213016957684, -7.058003932748639],
            [110.294187917148832, -7.057949623160685],
            [110.294131506481278, -7.057831949317095],
            [110.294078408925685, -7.05776855211082],
            [110.293963159647035, -7.057641743988277],
            [110.293949902404691, -7.057614588119695],
            [110.29390533434858, -7.057524067985227],
            [110.29389446405682, -7.057415508281859],
            [110.293879363320087, -7.057297896907012],
            [110.293895290435159, -7.057243649370297],
            [110.29391172859566, -7.057225583692564],
            [110.293934984824034, -7.057189437783647],
            [110.29397477515964, -7.057071909434391],
            [110.293976258417743, -7.057026685318175],
            [110.293980087470288, -7.056927193117958],
            [110.293961281033461, -7.056827666682128],
            [110.293946125452521, -7.056746236311762],
            [110.293900183871884, -7.056628578288467],
            [110.293871433312958, -7.056556172625383],
            [110.293829370714775, -7.05649279209634],
            [110.293798853962912, -7.056465610117701],
            [110.293747696063392, -7.05642935163919],
            [110.293623593657784, -7.05635680170278],
            [110.293571573240868, -7.056329587173671],
            [110.293502900327113, -7.056275211624715],
            [110.293456255847758, -7.056248005219792],
            [110.293405650169831, -7.056220792815313],
            [110.293345155206524, -7.056184520165936],
            [110.293276171959675, -7.056148234660121],
            [110.293218748228583, -7.056139102457864],
            [110.293147446566508, -7.056138994507518],
            [110.293083245929864, -7.056120806757612],
            [110.293050210208946, -7.056075530380951],
            [110.29301858920941, -7.056030256144842],
            [110.292966002983093, -7.05600304069808],
            [110.292930635107268, -7.056002987134522],
            [110.292810398060084, -7.055993759749074],
            [110.292729490095013, -7.055984591915739],
            [110.292623413942181, -7.055966340665786],
            [110.292532064566998, -7.055939066439368],
            [110.292412717591162, -7.055902704494453],
            [110.292340325418266, -7.055875458963823],
            [110.292189316358005, -7.055820958434301],
            [110.292148330867875, -7.055793760489403],
            [110.292049082160318, -7.055728216852699],
            [110.291961267774852, -7.055636396127917],
            [110.291924017892583, -7.055567288632307],
            [110.291900317719836, -7.055481580936041],
            [110.291883300974234, -7.055355484733385],
            [110.29188349835448, -7.055225594322359],
            [110.291895141814834, -7.055112912686845],
            [110.291933574059769, -7.054986900571251],
            [110.291981583860746, -7.054849442026836],
            [110.292046084909401, -7.054744354262906],
            [110.292087001042631, -7.05468150798731],
            [110.292148310848631, -7.054599464151607],
            [110.29221914017991, -7.054544176942285],
            [110.292330315453142, -7.054455336477511],
            [110.292573292389292, -7.05426392040049],
            [110.292749399278833, -7.054132386254825],
            [110.292857460737821, -7.054067188404443],
            [110.292963729293191, -7.054012370852484],
            [110.293046037197712, -7.053977021206443],
            [110.293107759662789, -7.053949978825576],
            [110.293213151167791, -7.053859685627662],
            [110.293269538862532, -7.053805499333446],
            [110.293330978352216, -7.053778456491148],
            [110.293388995109723, -7.0537694990054],
            [110.293418138102581, -7.053769543099468],
            [110.293585895103377, -7.053787887429448],
            [110.29369900325041, -7.053833284870606],
            [110.293771057454009, -7.053896710749456],
            [110.293853187948642, -7.054032514038261],
            [110.293858522731199, -7.054059657923585],
            [110.293894223386189, -7.054213481544536],
            [110.293914243748759, -7.054258738180638],
            [110.293955699194797, -7.054349253590158],
            [110.294037090599588, -7.054412693555343],
            [110.294089663029652, -7.054448954124097],
            [110.294192626569924, -7.054467200309174],
            [110.294224329788889, -7.054458202953537],
            [110.294321433730588, -7.05442216861316],
            [110.29441944126151, -7.05434995452121],
            [110.294465994388034, -7.054250526846379],
            [110.29446354375996, -7.054187206226337],
            [110.294460823882503, -7.054114839925423],
            [110.294446882178093, -7.053979139752181],
            [110.294440739613833, -7.053924858827818],
            [110.294388883720771, -7.053789101370302],
            [110.294249639968626, -7.053626076036631],
            [110.294130811455844, -7.053523225634811],
            [110.294027729696325, -7.053412280630284],
            [110.293878828763241, -7.053255422531882],
            [110.293754758234215, -7.053115793368144],
            [110.293519998682385, -7.052838465360328],
            [110.293250950381832, -7.052477038413388],
            [110.293082967421299, -7.052295319180529],
            [110.292960742771754, -7.052199626280433],
            [110.292848052209976, -7.052121139209622],
            [110.29273914226502, -7.05207131021093],
            [110.292603466934054, -7.052019530498991],
            [110.292488549900867, -7.051986217388357],
            [110.292404683319901, -7.051975295791217],
            [110.292324381302763, -7.05197326398596],
            [110.292217293583747, -7.051982652529841],
            [110.292136982868669, -7.051986351152774],
            [110.291987434759264, -7.052003548666193],
            [110.29186923599849, -7.052027968813467],
            [110.291791571221765, -7.052048478106009],
            [110.291586175505145, -7.052081024038011],
            [110.29139877122968, -7.052097931208512],
            [110.291246814467144, -7.052110968755199],
            [110.291183718727325, -7.052110873016161],
            [110.290985759987436, -7.052106855289005],
            [110.290827254944645, -7.052083196168252],
            [110.290733471922891, -7.052041526936727],
            [110.290664688022915, -7.052001541925351],
            [110.290584514463774, -7.051922870989809],
            [110.290500530891151, -7.051827235577597],
            [110.290424125660749, -7.051777455437504],
            [110.290351532857542, -7.051735321706689],
            [110.290252177606774, -7.051689326991182],
            [110.290142323691313, -7.051666073549573],
            [110.290091620301411, -7.051654700196011],
            [110.290022828320176, -7.05162785345108],
            [110.289955976304554, -7.051593428214852],
            [110.289900305977312, -7.05154811726428],
            [110.289799372281237, -7.051463240287231],
            [110.289652336008302, -7.05133885653157],
            [110.289520595955281, -7.051214495999444],
            [110.289434680339369, -7.051132228586514],
            [110.289356401176036, -7.051057613403801],
            [110.289195989774214, -7.05092747871129],
            [110.28909854684224, -7.05088148668644],
            [110.28900920275909, -7.050886457564427],
            [110.288907924156604, -7.050877258160453],
            [110.288812049005244, -7.050849976431438],
            [110.288615502568391, -7.050786360350703],
            [110.288581563619942, -7.050777263408396],
            [110.28842404910398, -7.050722751949229],
            [110.288233458324001, -7.050650099525107],
            [110.288046545822453, -7.05057745263391],
            [110.287987156169123, -7.050559271604996],
            [110.287849689066292, -7.050531926304423],
            [110.287645475933843, -7.050486388679765],
            [110.287513640027683, -7.050477142394105],
            [110.287452200731408, -7.050504184484711],
            [110.287418730956716, -7.050558405010863],
            [110.287384570886161, -7.050694031836881],
            [110.287328976511432, -7.050784399653963],
            [110.287254183703539, -7.050847602389245],
            [110.287126142923626, -7.050946904909087],
            [110.287094115433476, -7.05098303708264],
            [110.286959228702784, -7.051118510145548],
            [110.286931176176424, -7.051145603111356],
            [110.286860502842956, -7.051290219404231],
            [110.286765979493637, -7.051489070830658],
            [110.286746387455125, -7.051534267219147],
            [110.286658765275035, -7.051660767069185],
            [110.286542524574713, -7.051814358968489],
            [110.28637116584747, -7.051931685592228],
            [110.28626693310099, -7.052003888417882],
            [110.286188503278908, -7.052039949611638],
            [110.286064212725393, -7.052103179494297],
            [110.285999139695519, -7.052145103451342],
            [110.28593595526867, -7.052202311527951],
            [110.285825022991304, -7.05222506379806],
            [110.285714201918239, -7.052175230310882],
            [110.285639720951593, -7.052117811755094],
            [110.285574823517706, -7.052045126604805],
            [110.285479421625539, -7.051915090132662],
            [110.285305662179766, -7.051762011907899],
            [110.285175800175296, -7.051660574989474],
            [110.284996275583566, -7.051526589395837],
            [110.284828196826396, -7.051409812659731],
            [110.284658220793716, -7.051283482191248],
            [110.284465347574823, -7.051126554122952],
            [110.284358403729456, -7.051042343503931],
            [110.284205627295037, -7.050921769704773],
            [110.28414837830762, -7.050849096109409],
            [110.28409923724746, -7.050770416588408],
            [110.284084607729113, -7.050716122638766],
            [110.284046902188891, -7.050580386029331],
            [110.284016697140018, -7.050535113465921],
            [110.283957432704696, -7.050435524835236],
            [110.28389642898847, -7.050363069298996],
            [110.283810285397379, -7.050263439456863],
            [110.283758011154035, -7.050218133051985],
            [110.283613071916875, -7.050082232027777],
            [110.283491092495083, -7.049919230410522],
            [110.283463703029426, -7.04988300739172],
            [110.283364006361367, -7.049765266191183],
            [110.283321931907054, -7.049710930135003],
            [110.283250543063147, -7.049584187077929],
            [110.28316871333891, -7.049439337488422],
            [110.283156305756862, -7.04941218269243],
            [110.283100506412822, -7.04926737302597],
            [110.283090404055798, -7.049213086003178],
            [110.283056191476462, -7.049014037926131],
            [110.28301677479871, -7.048887343896189],
            [110.282978392556416, -7.048823968229597],
            [110.282938043663748, -7.048751544289207],
            [110.282867448237013, -7.048660983426263],
            [110.282822558460381, -7.048597597764379],
            [110.282731874492427, -7.048507006057375],
            [110.282675384299083, -7.048443602580454],
            [110.282586667041883, -7.048362059122518],
            [110.282480152569718, -7.048262397821277],
            [110.282433254490286, -7.048217099553145],
            [110.282369677017059, -7.048162730417813],
            [110.282338085419042, -7.04809936513798],
            [110.282342116124212, -7.048054145061863],
            [110.282359486636835, -7.04798180970893],
            [110.282341290518531, -7.047855148225877],
            [110.282296836899377, -7.047692265411785],
            [110.282277355974415, -7.047665099738913],
            [110.282181069213379, -7.04753831833498],
            [110.282146309693104, -7.047511129193857],
            [110.282143136097076, -7.047508259714792],
            [110.281995995932874, -7.047375219528901],
            [110.281976218179466, -7.04735709864285],
            [110.281828381331437, -7.04726641899967],
            [110.281651981839104, -7.04716665016934],
            [110.281623998937491, -7.047148516660884],
            [110.281496009602748, -7.047030731671299],
            [110.281382153239747, -7.046922013631049],
            [110.281356744660144, -7.046885793563209],
            [110.28124549053318, -7.046740898490489],
            [110.281242137096768, -7.046713757580033],
            [110.281212438444825, -7.046523761635592],
            [110.281223185782309, -7.046342873129979],
            [110.281231149803858, -7.046315749620041],
            [110.281286656785682, -7.046098748917164],
            [110.281323151259272, -7.045917899982419],
            [110.281339686935397, -7.045836518133432],
            [110.281320911319611, -7.045718900996381],
            [110.281297947395302, -7.045565096412723],
            [110.281270572265967, -7.045519828071238],
            [110.281170158781762, -7.045429237475245],
            [110.281084192787233, -7.045381351451749],
            [110.280958098570693, -7.045318122472708],
            [110.280835843088298, -7.045245348598188],
            [110.280650524395966, -7.045151465985587],
            [110.28042894868905, -7.045011683822018],
            [110.280297159950877, -7.044921703768164],
            [110.280222710266855, -7.044845183024925],
            [110.280173656103486, -7.044767384807839],
            [110.280110247739159, -7.044621521924649],
            [110.280085583434925, -7.044497323969693],
            [110.280057288268821, -7.044269716903698],
            [110.279969245882441, -7.043934907098569],
            [110.279812960519948, -7.04363617319834],
            [110.279511655598114, -7.043255808631687],
            [110.279190206961516, -7.042911593866031],
            [110.278939932422176, -7.042648895886008],
            [110.278723002435029, -7.042413384961183],
            [110.278602357392501, -7.042304655931744],
            [110.278469683512654, -7.042196919323318],
            [110.278353224229789, -7.042085950789928],
            [110.278116508462617, -7.041846816414455],
            [110.277935216530622, -7.041623048716683],
            [110.277792187852171, -7.041384058813194],
            [110.277693611662855, -7.041181642963769],
            [110.277534757267418, -7.040940505870368],
            [110.277433635652969, -7.040802818701321],
            [110.277353368395481, -7.040779772893447],
            [110.277320767356841, -7.040842757577266],
            [110.277297583781277, -7.040940365425963],
            [110.277287044676839, -7.040971790552962],
            [110.277229022794174, -7.041181310297503],
            [110.277186760864083, -7.041375133675476],
            [110.277118321430947, -7.041537475188219],
            [110.277097080893853, -7.041705129860481],
            [110.277070489622361, -7.041940899303531],
            [110.276986306231748, -7.042108456682246],
            [110.276839188069204, -7.042270676510197],
            [110.276621279405163, -7.042419686222779],
            [110.276379779220036, -7.042560798971913],
            [110.276075384783084, -7.042670372843391],
            [110.275786782845884, -7.042743289327545],
            [110.275624245479548, -7.042701115771654],
            [110.275398871029395, -7.042590721802815],
            [110.274974389335284, -7.042343773127343],
            [110.273933996951655, -7.041836477085475],
            [110.273210938756264, -7.04133229217256],
            [110.271850821612617, -7.04067776870628],
            [110.272191013690147, -7.040127377280831],
            [110.272303444031252, -7.039979300279066],
            [110.272401267406025, -7.039914366216302],
            [110.272383491855507, -7.039708232305995],
            [110.272376529230911, -7.039531042404625],
            [110.27237309016742, -7.039415328273033],
            [110.272366065574815, -7.039277913168582],
            [110.272388035019134, -7.039115231849445],
            [110.272435294654144, -7.038981517043516],
            [110.272540581698138, -7.038771958540768],
            [110.272671417838808, -7.038425035514909],
            [110.272722499289912, -7.038161154201623],
            [110.272751937947334, -7.037850232545082],
            [110.272770541117808, -7.03752483044328],
            [110.272763550150344, -7.037365719976479],
            [110.272637099397755, -7.037220887494203],
            [110.272535916330853, -7.037119485053892],
            [110.272525210414642, -7.037021839116073],
            [110.272597799268794, -7.036891779519742],
            [110.272631129783107, -7.036746816376346],
            [110.272589387352866, -7.036717747349038],
            [110.272540025820376, -7.036691347438026],
            [110.272447851347238, -7.036663235784092],
            [110.272357326199199, -7.036633481476531],
            [110.272301434060836, -7.036570876986161],
            [110.27225040531296, -7.036557636057757],
            [110.272136874398996, -7.036496587142102],
            [110.272092550560288, -7.036407677414712],
            [110.272003764515773, -7.036318698544553],
            [110.271924784655411, -7.036277445715213],
            [110.271840823543698, -7.036262508327608],
            [110.271761802684665, -7.036247578610427],
            [110.271671349473849, -7.03617175869302],
            [110.271610673676463, -7.036008789490005],
            [110.27151310588107, -7.03583939351651],
            [110.271418040017068, -7.035668465799248],
            [110.271375576337775, -7.035494335706434],
            [110.27139563816155, -7.035277607968989],
            [110.271507667829468, -7.035110286726508],
            [110.271639329134558, -7.035002112070048],
            [110.271820259070893, -7.034920287809023],
            [110.272007640787763, -7.034917294953715],
            [110.272221218695336, -7.034980027286478],
            [110.272484096095113, -7.035049404585126],
            [110.272717326458348, -7.035158146425654],
            [110.272993200030882, -7.035326070476051],
            [110.273252642350599, -7.035490684650017],
            [110.273435705827382, -7.035567001082034],
            [110.273595574859797, -7.035548007662059],
            [110.273787244723323, -7.035477094074816],
            [110.273994856791177, -7.035332692185826],
            [110.27409868991127, -7.035151948340383],
            [110.274133502297403, -7.034962052227125],
            [110.274133783265512, -7.034781147820072],
            [110.274153132839203, -7.034527911032209],
            [110.274101651876705, -7.034337881141989],
            [110.273981250925175, -7.034132144428896],
            [110.27383713790536, -7.033957570870161],
            [110.273702355657704, -7.033793436420109],
            [110.273561221138536, -7.033651995691822],
            [110.273360757041516, -7.033612273955413],
            [110.273150328950294, -7.0336382212348],
            [110.272913520703213, -7.033716675108448],
            [110.272594473632992, -7.033831127627165],
            [110.272282062388086, -7.033906179494386],
            [110.272028970836288, -7.033886080768528],
            [110.271845004738125, -7.033806973306094],
            [110.271700593769225, -7.033658958524884],
            [110.271635211110095, -7.033425676719406],
            [110.27165864691203, -7.033153122316706],
            [110.271780341636472, -7.032986536513724],
            [110.271882536013024, -7.032926858754979],
            [110.271994406323913, -7.032861348107032],
            [110.272047059288084, -7.032825303443057],
            [110.272047151339351, -7.032766187491925],
            [110.272020960385547, -7.032697178008488],
            [110.271971088865229, -7.032588842552123],
            [110.27190858886533, -7.032389750150892],
            [110.271785334183335, -7.032016976668753],
            [110.271637907595476, -7.031694893179851],
            [110.271464285264145, -7.031307084378179],
            [110.271326715805685, -7.030988300382456],
            [110.271267887660954, -7.030768165659349],
            [110.271274030111229, -7.030544209598153],
            [110.271317913287518, -7.030308451653987],
            [110.271403597359679, -7.030170647356534],
            [110.271587899603318, -7.030032996359844],
            [110.271795166632799, -7.029924939018741],
            [110.271929995978283, -7.029892306264752],
            [110.272097524251379, -7.029971388016373],
            [110.272241864762293, -7.030008125551174],
            [110.27231206215717, -7.030000443440093],
            [110.272351126705885, -7.029953757288493],
            [110.272382489853456, -7.029844730080639],
            [110.272297010940562, -7.029649818923073],
            [110.272157222464202, -7.029275627264695],
            [110.27209933138333, -7.029093757306607],
            [110.271974852503263, -7.02870269545685],
            [110.271889883247098, -7.02818055732221],
            [110.271820340995475, -7.02776751905656],
            [110.271789681282641, -7.027424661422807],
            [110.271852564981742, -7.027105322515992],
            [110.272075319976437, -7.026782336303951],
            [110.272240867671684, -7.02657900293117],
            [110.272270595943539, -7.02648709068484],
            [110.272221399574903, -7.026414761284975],
            [110.272089970915218, -7.026375146487571],
            [110.27186543244386, -7.026353497806841],
            [110.271712039544624, -7.026308874944689],
            [110.271386826999802, -7.026163863577304],
            [110.271035367871235, -7.025985968916777],
            [110.270774580897708, -7.02576746707834],
            [110.270642519955729, -7.025440033977906],
            [110.270557455284987, -7.024980224673917],
            [110.270507149009276, -7.024734725654603],
            [110.270546286084297, -7.024641292918457],
            [110.270616740019349, -7.024612510721731],
            [110.270686715667892, -7.024602555680173],
            [110.270920554569145, -7.024673039545183],
            [110.271195027558718, -7.024784189744784],
            [110.271505158241823, -7.024845353169352],
            [110.271778110039818, -7.024837986025412],
            [110.272019953566257, -7.024776032461918],
            [110.272168254084718, -7.024690560163998],
            [110.272184020248403, -7.024581508704347],
            [110.27212194908239, -7.024378842770436],
            [110.272036483163916, -7.024176140515181],
            [110.271764222644819, -7.023739414085807],
            [110.271358530538421, -7.023224435465941],
            [110.271105782297354, -7.022987578299595],
            [110.270942980706849, -7.02279151502689],
            [110.270507250387482, -7.022167546653679],
            [110.269773731496585, -7.021273828486452],
            [110.270603098587216, -7.020710753209245],
            [110.271336166357813, -7.020688518909842],
            [110.271991203655418, -7.020697326939024],
            [110.272962135082864, -7.020663772414928],
            [110.273406681375462, -7.020633296526755],
            [110.273625079580114, -7.020602470139785],
            [110.273921689362254, -7.020423732990232],
            [110.274116837746732, -7.020299376668487],
            [110.274326566521452, -7.020048720715234],
            [110.274351218475928, -7.020019258069662],
            [110.274871143749607, -7.019151349295061],
            [110.274129187422673, -7.017362134806913],
            [110.273841694059897, -7.016691651807234],
            [110.273445546978948, -7.015685981407405],
            [110.273372074792888, -7.015292414981086],
            [110.273356853010853, -7.015050865989187],
            [110.273396338797809, -7.014731490224058],
            [110.273521627824721, -7.014396664912685],
            [110.273756283188618, -7.013937350389842],
            [110.274018116756267, -7.013552093582859],
            [110.275120659325566, -7.011606009947035],
            [110.275190902098217, -7.011567162596092],
            [110.275268822508309, -7.011606238552528],
            [110.275323132011579, -7.011785518728695],
            [110.275315117208024, -7.011925747017712],
            [110.275361725008068, -7.012042686131059],
            [110.275540900051823, -7.012159829720412],
            [110.275634488953699, -7.012152182906598],
            [110.275712590023829, -7.0120743918607],
            [110.27593947861223, -7.011934740630731],
            [110.276156919508523, -7.011857078442406],
            [110.276361652101258, -7.011811017099627],
            [110.277903773848422, -7.011956736643973],
            [110.278321490361222, -7.011986891095487],
            [110.278608298536639, -7.012077977210892],
            [110.278858706988458, -7.012138051671148],
            [110.280424916287657, -7.012295854855799],
            [110.281561643041954, -7.012390369250808],
            [110.281970657631263, -7.012428605912279],
            [110.282996953272189, -7.012527962898948],
            [110.283410907041699, -7.01261886161273],
            [110.284261371385483, -7.012820751701384],
            [110.28452546164138, -7.012822949210554],
            [110.284711142204202, -7.012827231813289],
            [110.284885518535106, -7.012853873510741],
            [110.284926189641311, -7.012935069262212],
            [110.284957639050575, -7.013065815569371],
            [110.284989146344515, -7.013158617259103],
            [110.285010113705241, -7.013245078784483],
            [110.285031042493074, -7.013356836720378],
            [110.285071018423707, -7.013430678946944],
            [110.285155655969206, -7.013442227396819],
            [110.285274708766565, -7.013478589681042],
            [110.285463655827172, -7.013506013012184],
            [110.285541754655299, -7.013497086558536],
            [110.285664837256135, -7.013488228515862],
            [110.285755087723103, -7.013488365781742],
            [110.285796096882933, -7.013497473421462],
            [110.285829170593459, -7.013515614263317],
            [110.285923285459376, -7.013579074285303],
            [110.286013763760693, -7.013615392942453],
            [110.286054745387602, -7.013642591065821],
            [110.286091752407117, -7.01367882841732],
            [110.286155786910001, -7.013743738295894],
            [110.286190056006731, -7.013778475853758],
            [110.286263787176836, -7.013850950106916],
            [110.286321150489428, -7.013896263654998],
            [110.286349994264171, -7.013905352762563],
            [110.286423697941217, -7.013995917500579],
            [110.286485291277586, -7.014050282733255],
            [110.286514121298168, -7.014068417085028],
            [110.286559091370336, -7.014077530682422],
            [110.286604385659402, -7.01405950894574],
            [110.286657857015129, -7.014059590174253],
            [110.286694622490828, -7.014068691295612],
            [110.28671922252677, -7.014077773936064],
            [110.286751972111844, -7.014123050051068],
            [110.286801413823838, -7.014168351516023],
            [110.286826000105677, -7.014186479405223],
            [110.28687522771871, -7.014186554165366],
            [110.286936972492398, -7.014141421553744],
            [110.286973765499667, -7.01413243214651],
            [110.287014788507093, -7.014132494433603],
            [110.287043618556169, -7.014150628755383],
            [110.287084572781993, -7.014195917307324],
            [110.287129812044142, -7.014214076536113],
            [110.287211789304266, -7.014259427352819],
            [110.287321068295284, -7.014245694237965],
            [110.287443418214451, -7.014262744230383],
            [110.287610230320837, -7.014178675715415],
            [110.287736931415807, -7.014109302580046],
            [110.287890923766909, -7.014130616527576],
            [110.288023759271312, -7.014189843076711],
            [110.288221964036651, -7.014274465165665],
            [110.288454026869772, -7.014293789225395],
            [110.288565670475876, -7.014414116698533],
            [110.288588604493583, -7.014595442938637],
            [110.288653746646602, -7.01477050900109],
            [110.28894923227837, -7.014707715329792],
            [110.289054648651074, -7.014760575970821],
            [110.289145218831507, -7.014864007095261],
            [110.289351908576819, -7.014919129025686],
            [110.289539701696725, -7.014913089088192],
            [110.289649564884243, -7.014816285418817],
            [110.289926211230124, -7.014654384684587],
            [110.290304200860234, -7.014448368076277],
            [110.290664531612521, -7.014232964749923],
            [110.290979942804668, -7.014253948118196],
            [110.291217503583908, -7.014321224426578],
            [110.291359549120912, -7.014218008295098],
            [110.291507380384445, -7.014140341017423],
            [110.29172763249727, -7.014107877332996],
            [110.291844006172127, -7.014030162492762],
            [110.292209028005658, -7.013881469242548],
            [110.292434888718944, -7.013860776523408],
            [110.292685550987684, -7.013891747700796],
            [110.293204097030227, -7.013955627682276],
            [110.293690085521334, -7.014034754926067],
            [110.294132944131746, -7.014120804850575],
            [110.294100631666183, -7.014587459600155],
            [110.293998477027671, -7.014787321721182],
            [110.293892766053645, -7.015016420522239],
            [110.293856288403845, -7.015139182317658],
            [110.293788959321859, -7.015535603381823],
            [110.293733316256066, -7.015946078186872],
            [110.293695309856517, -7.016306283164676],
            [110.293664214929152, -7.016741358194071],
            [110.29364165313649, -7.017146326730609],
            [110.293564019035159, -7.017679877414689],
            [110.293387657872543, -7.018419947637716],
            [110.293292148748236, -7.018870131434891],
            [110.293254307443533, -7.019120386410719],
            [110.293241162815576, -7.019296988604872],
            [110.293235077911959, -7.019450207788727],
            [110.29323015138516, -7.019611616490164],
            [110.293202996654301, -7.019762464569902],
            [110.293138081601441, -7.020109762337008],
            [110.293123739641047, -7.02030390793933],
            [110.293130485156453, -7.02048872785391],
            [110.293139660430441, -7.020615067330025],
            [110.293165279960817, -7.020705756280871],
            [110.293200269862169, -7.020793535142866],
            [110.293306793531315, -7.020996927857992],
            [110.293559205992409, -7.021501733388914],
            [110.293773706412523, -7.021915831390222],
            [110.294013065731818, -7.022343125663408],
            [110.294322227710239, -7.022384388408041],
            [110.294761187723296, -7.022431835896255],
            [110.295208959292196, -7.022458826780851],
            [110.295627435846797, -7.022503318488505],
            [110.295917059795258, -7.02259440372895],
            [110.29606913404055, -7.022676509766141],
            [110.296130440099731, -7.022781873204393],
            [110.296136608448293, -7.022901838083042],
            [110.296128641599736, -7.022990123301332],
            [110.29611748999676, -7.023110109822604],
            [110.29607704017063, -7.023480905952013],
            [110.296034218037093, -7.023924060812177],
            [110.29600309089804, -7.024303916192727],
            [110.295974632447269, -7.024602367974812],
            [110.29593658172746, -7.024882714768927],
            [110.295936432050368, -7.024982212710487],
            [110.295952732810434, -7.025054599489741],
            [110.295990252910897, -7.025127018129785],
            [110.296069564223075, -7.02525377124012],
            [110.296254444561967, -7.025543497997733],
            [110.296505420773045, -7.025905686193884],
            [110.296561600473893, -7.025987178088932],
            [110.296596574408056, -7.026059592873727],
            [110.296636276504159, -7.026186286482776],
            [110.29667845696882, -7.026358210251715],
            [110.296734392043916, -7.026602516963355],
            [110.296759744013684, -7.026864868368894],
            [110.296787536134929, -7.027009634681275],
            [110.296834175542614, -7.02722679157576],
            [110.296854758609115, -7.027461999957969],
            [110.296887292550579, -7.027651999823759],
            [110.296915313285822, -7.027832947629601],
            [110.296947956010527, -7.027950585347516],
            [110.296996981290505, -7.028086338214911],
            [110.297081048697976, -7.028249279503279],
            [110.297233179158127, -7.028493730449012],
            [110.297381106627682, -7.028711039189538],
            [110.29743359514589, -7.028801570773408],
            [110.29746431190074, -7.028883024425578],
            [110.29748582556897, -7.029063962461777],
            [110.297501101266263, -7.029253936434948],
            [110.297522614951674, -7.02943487446973],
            [110.297553209490118, -7.029597735538639],
            [110.297571774014315, -7.029670125681409],
            [110.297681330181263, -7.030004965604007],
            [110.297733737386821, -7.030149768783001],
            [110.297846163772874, -7.030457477116675],
            [110.297890945594702, -7.030593223567855],
            [110.297915598811358, -7.030756075721358],
            [110.298016853205866, -7.031344171260951],
            [110.298040078259092, -7.031516066560152],
            [110.298059867442717, -7.031715092578069],
            [110.298135092645552, -7.032303149099087],
            [110.298179616686781, -7.032610755646322],
            [110.298251637016037, -7.033072173305169],
            [110.298293048932265, -7.033379775183146],
            [110.29830188543356, -7.033524513048203],
            [110.298303338604583, -7.033687330428096],
            [110.298268859673357, -7.033850093965929],
            [110.298228534245311, -7.033949531715181],
            [110.298147736224024, -7.034057954090923],
            [110.298023622692597, -7.034184402095525],
            [110.29790559768395, -7.034401312068659],
            [110.297829500605971, -7.034582103723134],
            [110.297692543726811, -7.034970845690476],
            [110.297635133472255, -7.035142620033703],
            [110.297604938901586, -7.03527825404526],
            [110.297567603059576, -7.035459103773323],
            [110.297461564072719, -7.035983571297963],
            [110.297403707435379, -7.036263888351336],
            [110.297333643851033, -7.03657132292314],
            [110.297255671673398, -7.036869700312995],
            [110.297170101039853, -7.037140930410825],
            [110.297081175936725, -7.037385019586869],
            [110.297026635232442, -7.037529662241119],
            [110.29700810853906, -7.037620087266832],
            [110.296996951684477, -7.03770147807511],
            [110.29700615233196, -7.037791944744214],
            [110.29700710711063, -7.037909534882895],
            [110.296991355265774, -7.03803614521202],
            [110.296963464540369, -7.038144646735437],
            [110.296915202660699, -7.038253117653095],
            [110.296797107865402, -7.038515253475843],
            [110.296757412079231, -7.038595134167913],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 101,
        id: 103,
        area: 3540296.149284,
        perimeter: 8325.80357,
        kode_pos: 50215,
        kode_kec: 3374010,
        kode_kel: 3374010007,
        kec: "Mijen",
        kel: "Wonolopo",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.311615123121086, -7.061105326838313],
            [110.311435606007393, -7.061077926012161],
            [110.310989748154611, -7.061032041243486],
            [110.310610652554033, -7.060995300014913],
            [110.310469219187439, -7.060967955099323],
            [110.310241783335997, -7.060931437700144],
            [110.310003002841967, -7.060912994016629],
            [110.309907380155366, -7.060903807285994],
            [110.309803255656405, -7.060903653259075],
            [110.30964916758667, -7.060442114981317],
            [110.309560644198314, -7.060224896794531],
            [110.309464710625249, -7.060043848830058],
            [110.309420368513415, -7.059989511406402],
            [110.309360167999102, -7.059944195807058],
            [110.30923122494805, -7.059889733140867],
            [110.309073475086521, -7.05979904659108],
            [110.308983282329805, -7.059753686537937],
            [110.30881358170366, -7.05970820870512],
            [110.308741430418337, -7.059708101825838],
            [110.308678306557681, -7.059726098907018],
            [110.308473642422214, -7.059789112731434],
            [110.308383369136521, -7.059798024236576],
            [110.30831261914021, -7.059806964664449],
            [110.308241343558436, -7.059788768409056],
            [110.308118059633486, -7.059734313840959],
            [110.307987432580887, -7.059670803055777],
            [110.307829319463295, -7.059634387358082],
            [110.307732026338016, -7.059607107138793],
            [110.307605697365517, -7.059507421444671],
            [110.307477145231616, -7.059380596532542],
            [110.307417079516455, -7.059244827943396],
            [110.307399994687572, -7.05912721373399],
            [110.307400263709724, -7.058946308204765],
            [110.307404723106089, -7.058801590079085],
            [110.307415825235395, -7.058756380072613],
            [110.307465987294691, -7.058702182732653],
            [110.307542503616233, -7.058620888599424],
            [110.307640537209693, -7.058530581075503],
            [110.307662377788134, -7.058494432287396],
            [110.307662418122973, -7.058467296455855],
            [110.307636575380769, -7.05834062395907],
            [110.307584727994282, -7.058005871048724],
            [110.307502631874456, -7.057463031422913],
            [110.307448790563313, -7.057137320842897],
            [110.307437324302171, -7.0570468508586],
            [110.307330330928863, -7.057073827997162],
            [110.307141526216824, -7.057127819575324],
            [110.306888749079874, -7.057199806680098],
            [110.30675823086986, -7.057253884636988],
            [110.306498366542485, -7.057334906287402],
            [110.30646155647878, -7.056973039780923],
            [110.306429906938178, -7.056755905666809],
            [110.306375931603185, -7.05633069658328],
            [110.306376052794491, -7.056249289103291],
            [110.306022979148423, -7.056221628503891],
            [110.305810528688568, -7.056194176763018],
            [110.305506660427682, -7.05618467953153],
            [110.305398845326096, -7.056193564426612],
            [110.305056981676884, -7.056238282129546],
            [110.304987646932844, -7.056247224207138],
            [110.30496455347776, -7.056174827483023],
            [110.304911939841318, -7.055975752706795],
            [110.304903896396326, -7.055867197215938],
            [110.304895812472324, -7.055785777543522],
            [110.30487098090498, -7.055740514108109],
            [110.304839587663025, -7.055731422073551],
            [110.30449525885858, -7.055721863969408],
            [110.304336244592818, -7.055721627062665],
            [110.304240043794124, -7.055721483712013],
            [110.304309554668706, -7.055405002074317],
            [110.304308490415792, -7.055359774028355],
            [110.304261818275279, -7.055350659192533],
            [110.30393760579986, -7.055323040097251],
            [110.303557895957297, -7.055322473876155],
            [110.303218916444123, -7.055331013421033],
            [110.303121004562669, -7.055339912583903],
            [110.303060171770042, -7.055339821784553],
            [110.302973833499081, -7.055366828770645],
            [110.302907314777798, -7.055384820041545],
            [110.302734611128415, -7.055456924474449],
            [110.302251639046915, -7.055637108720485],
            [110.302000222764136, -7.055745276371394],
            [110.301866026280237, -7.055799347448906],
            [110.301762118272137, -7.055844418496744],
            [110.3017108783976, -7.055862432435837],
            [110.301657105584937, -7.055871397293293],
            [110.301561470697308, -7.055871254234157],
            [110.301408964179444, -7.055871026060873],
            [110.301201324393517, -7.055843579461529],
            [110.301055635674373, -7.055825270806693],
            [110.300922706564094, -7.055788890642137],
            [110.300685129171441, -7.055725217851333],
            [110.300523070548707, -7.055679748661583],
            [110.300366643665228, -7.055652378426839],
            [110.300206794318115, -7.0556430935802],
            [110.29998441453256, -7.055633714930497],
            [110.299776774997426, -7.055606267715933],
            [110.299605311432103, -7.055606010530449],
            [110.299346149094873, -7.055596576399703],
            [110.29916449959596, -7.055596303766732],
            [110.298917193689888, -7.05560497776001],
            [110.298646686451804, -7.05561361676934],
            [110.29831874176881, -7.055622169302562],
            [110.29820894622307, -7.055631049560018],
            [110.297959080215094, -7.055648764473982],
            [110.297498987487913, -7.055666162981921],
            [110.296447516493984, -7.055700760810145],
            [110.296151179428591, -7.0557003141628],
            [110.296003610971937, -7.054180485208867],
            [110.295867527900185, -7.053610427604972],
            [110.295811831783823, -7.052832449817344],
            [110.295812542288203, -7.052362096479113],
            [110.295965260190741, -7.051846745865116],
            [110.295777155696314, -7.050688666802577],
            [110.295590254033172, -7.048734605048231],
            [110.295310641280196, -7.046907037357841],
            [110.295235760709957, -7.046092849419439],
            [110.295233840861641, -7.045115956429211],
            [110.295234455548595, -7.044708919803806],
            [110.295234660436165, -7.044573240927088],
            [110.295235152149814, -7.044247611619736],
            [110.295383033713136, -7.043560393220342],
            [110.295775166858647, -7.042258463438689],
            [110.29600228546829, -7.041372367725421],
            [110.296186986901859, -7.040658068344368],
            [110.296464046617686, -7.03986250014225],
            [110.296602434610577, -7.039464715721836],
            [110.296646926347805, -7.039229605222142],
            [110.296738510522829, -7.038723206961656],
            [110.296748239182236, -7.038650859307849],
            [110.296757412079231, -7.038595134167913],
            [110.297116603981891, -7.038660457989686],
            [110.297514891682582, -7.038715327728247],
            [110.297665384734415, -7.038733644177256],
            [110.297788419718998, -7.038760964665115],
            [110.298167317926584, -7.038877103016349],
            [110.298612209280293, -7.039024513377399],
            [110.298729572177749, -7.039060870416701],
            [110.29894284942273, -7.039097371127744],
            [110.299168899418888, -7.03910675500804],
            [110.299475868319035, -7.039116259920033],
            [110.299734628475079, -7.039122480660375],
            [110.300059518807956, -7.039144269133153],
            [110.300717376254383, -7.03920496016109],
            [110.301147256725727, -7.039289067134519],
            [110.301623761061364, -7.039399874405858],
            [110.301955314832085, -7.039525938533179],
            [110.302276955479499, -7.039693347840258],
            [110.30264279624943, -7.039880461602092],
            [110.303178080133677, -7.040136563211825],
            [110.303541515737962, -7.040289304885252],
            [110.304084234400918, -7.040503684197653],
            [110.304518983792249, -7.040619708511662],
            [110.304919354790059, -7.040720952277731],
            [110.305219003353884, -7.040807317059386],
            [110.305368814902735, -7.040859091333417],
            [110.30554558980694, -7.040955092863672],
            [110.306147049375866, -7.041326667467702],
            [110.306630688796147, -7.041614601893031],
            [110.306792728428476, -7.041705671461894],
            [110.306914201404069, -7.041786654716643],
            [110.307077427261447, -7.041931939405019],
            [110.307189377660009, -7.042058421419275],
            [110.307470078771729, -7.042420649196505],
            [110.307574846660572, -7.042556483846083],
            [110.307742084823829, -7.04277002723228],
            [110.307957906828449, -7.043047744630264],
            [110.30813447211635, -7.043286126281645],
            [110.308482721546213, -7.04374078856234],
            [110.30861280049497, -7.043844084586858],
            [110.308804276848036, -7.043969564981657],
            [110.309108737427962, -7.044127125296735],
            [110.309508030708443, -7.044359357624291],
            [110.309702886966647, -7.044461862385991],
            [110.310147173135803, -7.044779193769105],
            [110.310746927859469, -7.045184308379721],
            [110.311089231380222, -7.045410945565153],
            [110.311359831189193, -7.045595991861787],
            [110.311615091587882, -7.045792755867392],
            [110.311836734535206, -7.045999991500784],
            [110.311995062684687, -7.046271583822822],
            [110.312069571678421, -7.046398327809118],
            [110.312159292710902, -7.046570320719715],
            [110.312179247995033, -7.046660803181518],
            [110.312215197441063, -7.04684176227568],
            [110.312275885513586, -7.047131301485608],
            [110.312328485538401, -7.047277027029175],
            [110.313302948864433, -7.047660552410348],
            [110.3132064211662, -7.048127653881656],
            [110.313184192072271, -7.04823616495175],
            [110.313185972875047, -7.048371847237416],
            [110.313211533926435, -7.048498519157608],
            [110.313266144561808, -7.048688550943361],
            [110.313391440264198, -7.049104819367009],
            [110.313448209680416, -7.049286957569723],
            [110.313543466141084, -7.049483970638891],
            [110.313812604887588, -7.05001413364688],
            [110.314062828731068, -7.050471647492166],
            [110.314463161299557, -7.051069225689038],
            [110.314498732116178, -7.051123549759067],
            [110.314828436668591, -7.0516486615817],
            [110.314975839559978, -7.051864901608329],
            [110.315031459231719, -7.051920630303298],
            [110.315085795513738, -7.051947208611307],
            [110.315152098965939, -7.051952605549433],
            [110.315565929738966, -7.051920088776834],
            [110.316022201627462, -7.051886308844359],
            [110.316351146194307, -7.051858966795285],
            [110.316562025186329, -7.051841152678402],
            [110.316723881214784, -7.051832344135632],
            [110.316882005984397, -7.05185971134664],
            [110.316973002679148, -7.051859844407502],
            [110.316908890097253, -7.052049702311152],
            [110.316800934685645, -7.052348039873396],
            [110.316669994490496, -7.052691570348368],
            [110.316541949818372, -7.05298987842226],
            [110.316388683263597, -7.053315285470815],
            [110.316308115234264, -7.053459892576747],
            [110.316072432271085, -7.053839450708701],
            [110.315865083068815, -7.054191914247641],
            [110.315754482870659, -7.054363613164723],
            [110.315697491719703, -7.054444937475203],
            [110.315480548424929, -7.054779296095745],
            [110.31539603190808, -7.054914851883409],
            [110.314929189588227, -7.055673973375501],
            [110.314857175478934, -7.055773366129101],
            [110.314596064973614, -7.056125750005654],
            [110.314306013840863, -7.056532363047035],
            [110.314233353684969, -7.056686026586619],
            [110.313759167405891, -7.057626041889562],
            [110.31371974473609, -7.057689301088597],
            [110.313482655921874, -7.058059810066752],
            [110.313339138815365, -7.058294776947253],
            [110.313092119578045, -7.058683361662605],
            [110.312855029712836, -7.059053870209153],
            [110.312543297598069, -7.059578038835591],
            [110.31230681276449, -7.059921411980462],
            [110.312081968473606, -7.06023766621572],
            [110.311839177900453, -7.060635301576751],
            [110.311793489750258, -7.060725687226372],
            [110.311709200441854, -7.060897423657743],
            [110.311615123121086, -7.061105326838313],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 102,
        id: 104,
        area: 4606544.3838339997,
        perimeter: 10076.141135,
        kode_pos: 50213,
        kode_kec: 3374010,
        kode_kel: 3374010012,
        kec: "Mijen",
        kel: "Ngadirgo",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.316973002679148, -7.051859844407502],
            [110.316882005984397, -7.05185971134664],
            [110.316723881214784, -7.051832344135632],
            [110.316562025186329, -7.051841152678402],
            [110.316351146194307, -7.051858966795285],
            [110.316022201627462, -7.051886308844359],
            [110.315565929738966, -7.051920088776834],
            [110.315152098965939, -7.051952605549433],
            [110.315085795513738, -7.051947208611307],
            [110.315031459231719, -7.051920630303298],
            [110.314975839559978, -7.051864901608329],
            [110.314828436668591, -7.0516486615817],
            [110.314498732116178, -7.051123549759067],
            [110.314463161299557, -7.051069225689038],
            [110.314062828731068, -7.050471647492166],
            [110.313812604887588, -7.05001413364688],
            [110.313543466141084, -7.049483970638891],
            [110.313448209680416, -7.049286957569723],
            [110.313391440264198, -7.049104819367009],
            [110.313266144561808, -7.048688550943361],
            [110.313211533926435, -7.048498519157608],
            [110.313185972875047, -7.048371847237416],
            [110.313184192072271, -7.04823616495175],
            [110.3132064211662, -7.048127653881656],
            [110.313302948864433, -7.047660552410348],
            [110.312328485538401, -7.047277027029175],
            [110.312275885513586, -7.047131301485608],
            [110.312215197441063, -7.04684176227568],
            [110.312179247995033, -7.046660803181518],
            [110.312159292710902, -7.046570320719715],
            [110.312069571678421, -7.046398327809118],
            [110.311995062684687, -7.046271583822822],
            [110.311836734535206, -7.045999991500784],
            [110.311615091587882, -7.045792755867392],
            [110.311359831189193, -7.045595991861787],
            [110.311089231380222, -7.045410945565153],
            [110.310746927859469, -7.045184308379721],
            [110.310147173135803, -7.044779193769105],
            [110.309702886966647, -7.044461862385991],
            [110.309508030708443, -7.044359357624291],
            [110.309108737427962, -7.044127125296735],
            [110.308804276848036, -7.043969564981657],
            [110.30861280049497, -7.043844084586858],
            [110.308482721546213, -7.04374078856234],
            [110.30813447211635, -7.043286126281645],
            [110.307957906828449, -7.043047744630264],
            [110.307742084823829, -7.04277002723228],
            [110.307574846660572, -7.042556483846083],
            [110.307470078771729, -7.042420649196505],
            [110.307189377660009, -7.042058421419275],
            [110.307077427261447, -7.041931939405019],
            [110.306914201404069, -7.041786654716643],
            [110.306792728428476, -7.041705671461894],
            [110.306630688796147, -7.041614601893031],
            [110.306147049375866, -7.041326667467702],
            [110.30554558980694, -7.040955092863672],
            [110.305368814902735, -7.040859091333417],
            [110.305219003353884, -7.040807317059386],
            [110.304919354790059, -7.040720952277731],
            [110.304518983792249, -7.040619708511662],
            [110.304084234400918, -7.040503684197653],
            [110.303541515737962, -7.040289304885252],
            [110.303178080133677, -7.040136563211825],
            [110.30264279624943, -7.039880461602092],
            [110.302276955479499, -7.039693347840258],
            [110.301955314832085, -7.039525938533179],
            [110.301623761061364, -7.039399874405858],
            [110.301147256725727, -7.039289067134519],
            [110.300717376254383, -7.03920496016109],
            [110.300059518807956, -7.039144269133153],
            [110.299734628475079, -7.039122480660375],
            [110.299475868319035, -7.039116259920033],
            [110.299168899418888, -7.03910675500804],
            [110.29894284942273, -7.039097371127744],
            [110.298729572177749, -7.039060870416701],
            [110.298612209280293, -7.039024513377399],
            [110.298167317926584, -7.038877103016349],
            [110.297788419718998, -7.038760964665115],
            [110.297665384734415, -7.038733644177256],
            [110.297514891682582, -7.038715327728247],
            [110.297116603981891, -7.038660457989686],
            [110.296757412079231, -7.038595134167913],
            [110.296797107865402, -7.038515253475843],
            [110.296915202660699, -7.038253117653095],
            [110.296963464540369, -7.038144646735437],
            [110.296991355265774, -7.03803614521202],
            [110.29700710711063, -7.037909534882895],
            [110.29700615233196, -7.037791944744214],
            [110.296996951684477, -7.03770147807511],
            [110.29700810853906, -7.037620087266832],
            [110.297026635232442, -7.037529662241119],
            [110.297081175936725, -7.037385019586869],
            [110.297170101039853, -7.037140930410825],
            [110.297255671673398, -7.036869700312995],
            [110.297333643851033, -7.03657132292314],
            [110.297403707435379, -7.036263888351336],
            [110.297461564072719, -7.035983571297963],
            [110.297567603059576, -7.035459103773323],
            [110.297604938901586, -7.03527825404526],
            [110.297635133472255, -7.035142620033703],
            [110.297692543726811, -7.034970845690476],
            [110.297829500605971, -7.034582103723134],
            [110.29790559768395, -7.034401312068659],
            [110.298023622692597, -7.034184402095525],
            [110.298147736224024, -7.034057954090923],
            [110.298228534245311, -7.033949531715181],
            [110.298268859673357, -7.033850093965929],
            [110.298303338604583, -7.033687330428096],
            [110.29830188543356, -7.033524513048203],
            [110.298293048932265, -7.033379775183146],
            [110.298251637016037, -7.033072173305169],
            [110.298179616686781, -7.032610755646322],
            [110.298135092645552, -7.032303149099087],
            [110.298059867442717, -7.031715092578069],
            [110.298040078259092, -7.031516066560152],
            [110.298016853205866, -7.031344171260951],
            [110.297915598811358, -7.030756075721358],
            [110.297890945594702, -7.030593223567855],
            [110.297846163772874, -7.030457477116675],
            [110.297733737386821, -7.030149768783001],
            [110.297681330181263, -7.030004965604007],
            [110.297571774014315, -7.029670125681409],
            [110.297553209490118, -7.029597735538639],
            [110.297522614951674, -7.02943487446973],
            [110.297501101266263, -7.029253936434948],
            [110.29748582556897, -7.029063962461777],
            [110.29746431190074, -7.028883024425578],
            [110.29743359514589, -7.028801570773408],
            [110.297381106627682, -7.028711039189538],
            [110.297233179158127, -7.028493730449012],
            [110.297081048697976, -7.028249279503279],
            [110.296996981290505, -7.028086338214911],
            [110.296947956010527, -7.027950585347516],
            [110.296915313285822, -7.027832947629601],
            [110.296887292550579, -7.027651999823759],
            [110.296854758609115, -7.027461999957969],
            [110.296834175542614, -7.02722679157576],
            [110.296787536134929, -7.027009634681275],
            [110.296759744013684, -7.026864868368894],
            [110.296734392043916, -7.026602516963355],
            [110.29667845696882, -7.026358210251715],
            [110.296636276504159, -7.026186286482776],
            [110.296596574408056, -7.026059592873727],
            [110.296561600473893, -7.025987178088932],
            [110.296505420773045, -7.025905686193884],
            [110.296254444561967, -7.025543497997733],
            [110.296069564223075, -7.02525377124012],
            [110.295990252910897, -7.025127018129785],
            [110.295952732810434, -7.025054599489741],
            [110.295936432050368, -7.024982212710487],
            [110.29593658172746, -7.024882714768927],
            [110.295974632447269, -7.024602367974812],
            [110.29600309089804, -7.024303916192727],
            [110.296034218037093, -7.023924060812177],
            [110.29607704017063, -7.023480905952013],
            [110.29611748999676, -7.023110109822604],
            [110.296128641599736, -7.022990123301332],
            [110.296136608448293, -7.022901838083042],
            [110.29654993572764, -7.02298925140404],
            [110.296837258407535, -7.023063950929125],
            [110.297052711457098, -7.023145969441303],
            [110.297253519916808, -7.023238447468954],
            [110.297456286785859, -7.023359477704129],
            [110.297604005681023, -7.023465104795683],
            [110.2976963666692, -7.023543033369868],
            [110.297827929965948, -7.023620456223552],
            [110.297921731661987, -7.02362506636075],
            [110.2980530892834, -7.023605458007991],
            [110.298308463619207, -7.023504339626829],
            [110.298457248014955, -7.023430293518605],
            [110.298647900610334, -7.023340032964985],
            [110.298863891771475, -7.02325018111812],
            [110.299186172333734, -7.023146686614226],
            [110.299486118486342, -7.023065439130495],
            [110.29975626700832, -7.023026232564253],
            [110.299961942417568, -7.023019112724174],
            [110.300202278590959, -7.023031849503979],
            [110.300432677359822, -7.02306190070951],
            [110.300829064818913, -7.023112004276624],
            [110.301151101075021, -7.023171899232614],
            [110.301485486227591, -7.023259044240429],
            [110.301767839615238, -7.023343635934866],
            [110.302025292447794, -7.023509886037942],
            [110.302391691375135, -7.023730761844808],
            [110.302862073543409, -7.024011206999091],
            [110.303035300991553, -7.024162477643389],
            [110.303369403610546, -7.024440244162643],
            [110.303641640310857, -7.024663454840263],
            [110.303921322007099, -7.024879249557554],
            [110.304243117166578, -7.025102533502267],
            [110.30443874221919, -7.025189470659989],
            [110.304740905916617, -7.025283992615015],
            [110.305085146238198, -7.025410759895688],
            [110.305491345275485, -7.02553019184506],
            [110.30577616049257, -7.025627163100921],
            [110.306219440377163, -7.025806064406506],
            [110.306524053485816, -7.025920393924479],
            [110.306755953476213, -7.026011408681081],
            [110.30693778091198, -7.026074994672246],
            [110.307109449862239, -7.026120474966755],
            [110.307346771026161, -7.02615700689709],
            [110.307540561815983, -7.026166338487779],
            [110.307798307239906, -7.026166719123967],
            [110.308063961226779, -7.026176156601319],
            [110.308278392285445, -7.026194563663243],
            [110.308461984447533, -7.026212925138925],
            [110.308662835112173, -7.026231311995239],
            [110.308800862880844, -7.02625865145695],
            [110.308927843198077, -7.026295019905989],
            [110.309030431134602, -7.026295171123039],
            [110.309040730663583, -7.026295186303549],
            [110.309221785323714, -7.026295453123663],
            [110.309310076206387, -7.026295583212507],
            [110.309443334353602, -7.026295779525169],
            [110.309793340520599, -7.026278204338694],
            [110.310083649295407, -7.026260541037818],
            [110.310406494559388, -7.026242925416268],
            [110.310685769210608, -7.026225245507152],
            [110.31092874167102, -7.026192650516411],
            [110.311235597323119, -7.026157713968775],
            [110.311507532891568, -7.026126954600406],
            [110.31175797707688, -7.02610597355386],
            [110.312083110845535, -7.026055436997041],
            [110.312293391347552, -7.02601051891529],
            [110.312405251503918, -7.025983352048732],
            [110.312487049482215, -7.026110301453956],
            [110.312576807370363, -7.026255158171963],
            [110.312742190272886, -7.026535805531784],
            [110.312807386850324, -7.026644444933967],
            [110.312874807018858, -7.026780223545411],
            [110.313028457462806, -7.027151306824607],
            [110.313129103770478, -7.027395677933662],
            [110.313206050693225, -7.027594787707253],
            [110.313324658466485, -7.027938683618622],
            [110.313387485726281, -7.028119682048449],
            [110.313513113825593, -7.02849976949271],
            [110.313606687424837, -7.02874413017676],
            [110.313769582696452, -7.029178544077798],
            [110.313918437969818, -7.029540574828776],
            [110.314023894936511, -7.029784952858389],
            [110.314135523371078, -7.030065521185782],
            [110.314200574797852, -7.030273658763398],
            [110.314270356484528, -7.03053607517896],
            [110.314293627370915, -7.030680834357984],
            [110.314354006176217, -7.030988463604879],
            [110.314426011668104, -7.031278019222495],
            [110.314465652739756, -7.0314499383133],
            [110.314525708998616, -7.031784703036387],
            [110.314611781606189, -7.032128551143007],
            [110.314644765064756, -7.032210007283802],
            [110.314693367213053, -7.032445256715874],
            [110.314755956810515, -7.032789070449883],
            [110.31483290964259, -7.033178131792189],
            [110.314904354319012, -7.033657638270092],
            [110.314932408777707, -7.033820495069818],
            [110.314992934034123, -7.034028625966836],
            [110.315102490538962, -7.034372508375567],
            [110.315174359682473, -7.034562565210032],
            [110.315309512767982, -7.034816031820323],
            [110.315414511549847, -7.034988046432108],
            [110.315524589928415, -7.035169113772726],
            [110.315808773837531, -7.035667021779664],
            [110.315918273406382, -7.035857133527436],
            [110.316142608798316, -7.036264500725882],
            [110.316241949527893, -7.036436506936377],
            [110.316351436198147, -7.036635663913246],
            [110.316467417177606, -7.036843875676341],
            [110.316543597331872, -7.036988712056182],
            [110.316606532602393, -7.037097347803248],
            [110.3167855020574, -7.037378014039871],
            [110.316919604348698, -7.037577206848761],
            [110.316995824420729, -7.037694907266549],
            [110.317134940356226, -7.037948379279336],
            [110.317250908752882, -7.038165636205099],
            [110.317353592580787, -7.038373828410823],
            [110.317462770756691, -7.038591075395225],
            [110.317543182262497, -7.038744963152145],
            [110.31776188802894, -7.039134230934674],
            [110.317887746467648, -7.039360547489598],
            [110.318036739616417, -7.039632124354531],
            [110.31823650273121, -7.040012319046311],
            [110.318412092155441, -7.040283934576139],
            [110.318704026726692, -7.040709490002187],
            [110.318883051470081, -7.040953974426935],
            [110.319248753955335, -7.041433908928079],
            [110.319394768040738, -7.041615027887084],
            [110.319520706965022, -7.041787072282108],
            [110.319666398783355, -7.041995326681941],
            [110.319782448314598, -7.042158311313574],
            [110.319928449646383, -7.042348475448779],
            [110.320061817451176, -7.042665255748203],
            [110.32027020951729, -7.043144960934731],
            [110.320476002866556, -7.043660843588892],
            [110.320628249156513, -7.044031923170497],
            [110.320754031167368, -7.044312511012733],
            [110.320856948007687, -7.044556884316663],
            [110.320926096658724, -7.044674573990543],
            [110.320959943910822, -7.044746985759148],
            [110.321004477012465, -7.044864639677882],
            [110.321048470577125, -7.044964202156551],
            [110.321114145121811, -7.045136158728363],
            [110.321212897166987, -7.045326253970397],
            [110.321256621030102, -7.045416770717116],
            [110.321300331753264, -7.045516332769139],
            [110.321398952401751, -7.045796881065865],
            [110.321464535094393, -7.046032154763621],
            [110.321541112999242, -7.046294580395149],
            [110.321606603749544, -7.046593171239579],
            [110.321683089742635, -7.0469189140162],
            [110.32169392711026, -7.047054609652595],
            [110.321704685574218, -7.047244577137892],
            [110.321703883326123, -7.047796340932261],
            [110.3217030547072, -7.048366195329101],
            [110.321680564453672, -7.048655613144354],
            [110.321669371928692, -7.048764140819037],
            [110.321678774323743, -7.048845562406254],
            [110.321683798245829, -7.048890796331545],
            [110.321563479946803, -7.049008210919657],
            [110.321288163732405, -7.049264869559246],
            [110.320954235706864, -7.049580531827574],
            [110.320729373039924, -7.049773972748928],
            [110.320348537500209, -7.050097239484773],
            [110.320003672222981, -7.050363170479248],
            [110.319765658248755, -7.050509362027173],
            [110.319456487388223, -7.050682093069133],
            [110.318976253758422, -7.050810169112764],
            [110.3187094936075, -7.050856405909456],
            [110.3180203021506, -7.051017480249767],
            [110.317833542974327, -7.051068273844746],
            [110.317611208565722, -7.051130116758636],
            [110.317471091192033, -7.051200960771029],
            [110.317299843854002, -7.051326648451327],
            [110.317172321417232, -7.051462141839616],
            [110.317074873423635, -7.051626673878687],
            [110.316973002679148, -7.051859844407502],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 103,
        id: 105,
        area: 6849038.4347400004,
        perimeter: 11318.355419,
        kode_pos: 50519,
        kode_kec: 3374010,
        kode_kel: 3374010008,
        kec: "Mijen",
        kel: "Mijen",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.311615123121086, -7.061105326838313],
            [110.311709200441854, -7.060897423657743],
            [110.311793489750258, -7.060725687226372],
            [110.311839177900453, -7.060635301576751],
            [110.312081968473606, -7.06023766621572],
            [110.31230681276449, -7.059921411980462],
            [110.312543297598069, -7.059578038835591],
            [110.312855029712836, -7.059053870209153],
            [110.313092119578045, -7.058683361662605],
            [110.313339138815365, -7.058294776947253],
            [110.313482655921874, -7.058059810066752],
            [110.31371974473609, -7.057689301088597],
            [110.313759167405891, -7.057626041889562],
            [110.314233353684969, -7.056686026586619],
            [110.314306013840863, -7.056532363047035],
            [110.314596064973614, -7.056125750005654],
            [110.314857175478934, -7.055773366129101],
            [110.314929189588227, -7.055673973375501],
            [110.31539603190808, -7.054914851883409],
            [110.315480548424929, -7.054779296095745],
            [110.315697491719703, -7.054444937475203],
            [110.315754482870659, -7.054363613164723],
            [110.315865083068815, -7.054191914247641],
            [110.316072432271085, -7.053839450708701],
            [110.316308115234264, -7.053459892576747],
            [110.316388683263597, -7.053315285470815],
            [110.316541949818372, -7.05298987842226],
            [110.316669994490496, -7.052691570348368],
            [110.316800934685645, -7.052348039873396],
            [110.316908890097253, -7.052049702311152],
            [110.316973002679148, -7.051859844407502],
            [110.317074873423635, -7.051626673878687],
            [110.317172321417232, -7.051462141839616],
            [110.317299843854002, -7.051326648451327],
            [110.317471091192033, -7.051200960771029],
            [110.317611208565722, -7.051130116758636],
            [110.317833542974327, -7.051068273844746],
            [110.3180203021506, -7.051017480249767],
            [110.3187094936075, -7.050856405909456],
            [110.318976253758422, -7.050810169112764],
            [110.319456487388223, -7.050682093069133],
            [110.319765658248755, -7.050509362027173],
            [110.320003672222981, -7.050363170479248],
            [110.320348537500209, -7.050097239484773],
            [110.320729373039924, -7.049773972748928],
            [110.320954235706864, -7.049580531827574],
            [110.321288163732405, -7.049264869559246],
            [110.321563479946803, -7.049008210919657],
            [110.321683798245829, -7.048890796331545],
            [110.321706753066735, -7.048936056282733],
            [110.321805407216672, -7.048999516750535],
            [110.322440675203694, -7.049344160705672],
            [110.323168117312463, -7.049734164045248],
            [110.323686920577259, -7.050006275126464],
            [110.323976133422306, -7.050169509594665],
            [110.324407561193226, -7.050405312205723],
            [110.325254355884695, -7.050831666490756],
            [110.325518466529317, -7.050940591846027],
            [110.325734259633222, -7.051004220665595],
            [110.326269067609545, -7.051167808227155],
            [110.326668438140132, -7.051267882680484],
            [110.326748767590104, -7.051286089121263],
            [110.326911933819062, -7.051349641581207],
            [110.32722404099647, -7.051530997870088],
            [110.327776967626406, -7.051884561968976],
            [110.327973956964058, -7.052038616129582],
            [110.328143823032562, -7.052165495195912],
            [110.328483311599655, -7.052392116803942],
            [110.328632601262498, -7.052464694106087],
            [110.329036640400702, -7.052664272114208],
            [110.329715749615758, -7.05302706074621],
            [110.329784478851167, -7.053045250054969],
            [110.32983965281673, -7.053045329224751],
            [110.329959093944751, -7.053018364569735],
            [110.330080772610032, -7.053009493772803],
            [110.330143020159994, -7.053009583052149],
            [110.330817876465233, -7.05318241193106],
            [110.331332369161032, -7.053309783599734],
            [110.331671784844787, -7.053391677508171],
            [110.331915347545447, -7.053428207356313],
            [110.332211576720496, -7.053437676357526],
            [110.332466509082607, -7.053438040803464],
            [110.332581397220721, -7.053429159655016],
            [110.332967332588737, -7.053429711019685],
            [110.3332590347473, -7.053439172892774],
            [110.333390011880596, -7.053457450550712],
            [110.333415168014014, -7.053475577148419],
            [110.333450700897629, -7.053756033592184],
            [110.333477797223082, -7.054000296608747],
            [110.333491402784318, -7.054181222947319],
            [110.333490988842399, -7.054470673425953],
            [110.333485326638652, -7.054868660558423],
            [110.333471009538783, -7.055185227218451],
            [110.333457221173347, -7.055329933063405],
            [110.333408552911365, -7.055528861181759],
            [110.333332283613828, -7.055836294019626],
            [110.333323112887498, -7.05591768902965],
            [110.333364189967867, -7.056080563895388],
            [110.333421317325218, -7.056297733746508],
            [110.333462355641089, -7.05648774458706],
            [110.333485028384686, -7.056659638514145],
            [110.333514723027747, -7.056867723845886],
            [110.33355384548581, -7.05701250522156],
            [110.333583747193558, -7.057075865326199],
            [110.333659408554269, -7.057193562823794],
            [110.333771852961689, -7.057311312813154],
            [110.333879487338436, -7.057429055915854],
            [110.333957464128702, -7.057510575297757],
            [110.334046938301256, -7.057664473838091],
            [110.334090369823201, -7.057764034605094],
            [110.334147703028293, -7.058035476770036],
            [110.334161308822175, -7.058216403093529],
            [110.334183852552727, -7.058478750277598],
            [110.334211001027938, -7.058686831956043],
            [110.334340395425372, -7.059021694324841],
            [110.334532680487513, -7.059501371893266],
            [110.33456006178271, -7.05954663766968],
            [110.334587481855351, -7.059564767462545],
            [110.33461971204045, -7.059582904112796],
            [110.334665549561848, -7.059582969470768],
            [110.334720737278246, -7.059574002808733],
            [110.33488150322647, -7.059538050598209],
            [110.335157441757332, -7.059493217088503],
            [110.335341113505919, -7.05946634269518],
            [110.335511165036039, -7.059466584875699],
            [110.335701858848509, -7.05947590172833],
            [110.335685202731696, -7.059647739626046],
            [110.335615659234193, -7.060199406818859],
            [110.335506437146933, -7.06099524183295],
            [110.335482141237605, -7.061167068819166],
            [110.335464919023963, -7.061338905877375],
            [110.33544610129151, -7.061637375512869],
            [110.335395310264602, -7.06193579958993],
            [110.335357946912765, -7.062143789321729],
            [110.335254808882226, -7.062641136385749],
            [110.335177908675803, -7.062993795267122],
            [110.335063439258576, -7.063500171428294],
            [110.334971760669788, -7.063898035889808],
            [110.334947567716739, -7.063997500183238],
            [110.334924763651728, -7.064115057144319],
            [110.334902035695066, -7.064377339725434],
            [110.334918379309158, -7.064820584918602],
            [110.334966505374567, -7.065001560449127],
            [110.335003067823081, -7.06535438102951],
            [110.335004259922016, -7.065906148733068],
            [110.334999165330032, -7.06610513903806],
            [110.334936639902423, -7.066891994764621],
            [110.334893178560264, -7.067208519782467],
            [110.334871402593961, -7.067398440923546],
            [110.33478701158252, -7.068239537404549],
            [110.334738530178058, -7.068628417945393],
            [110.334540660007349, -7.069948755399717],
            [110.334494085423046, -7.070265275842071],
            [110.334459202511866, -7.070518495580361],
            [110.334447471913933, -7.070608932238371],
            [110.334391585556645, -7.070907348665132],
            [110.334361795693795, -7.071160575656055],
            [110.334361614393103, -7.071287210171445],
            [110.334423130193926, -7.071404887494174],
            [110.334517263524248, -7.071468339358879],
            [110.334569158917546, -7.071586002924348],
            [110.334555922889152, -7.071739754818474],
            [110.334529735650605, -7.071848261503866],
            [110.33454913007715, -7.071938742620093],
            [110.334584743587641, -7.071965929516327],
            [110.33466928246267, -7.072011276973447],
            [110.334786412982766, -7.072020489593417],
            [110.334878373434819, -7.072020620905109],
            [110.334937150482816, -7.07207497687469],
            [110.334997303503059, -7.07215647082776],
            [110.335095834974297, -7.072310382292942],
            [110.335108386841554, -7.072437034996374],
            [110.335091306005879, -7.072509373349667],
            [110.335035151413337, -7.072599746605368],
            [110.334751034420847, -7.073015426606624],
            [110.3346217475911, -7.073196148745616],
            [110.3345830972399, -7.073313682956595],
            [110.334588073901827, -7.073395098132347],
            [110.33437580504642, -7.073430976173949],
            [110.334157915919903, -7.073439710061293],
            [110.334019049299329, -7.073394284812897],
            [110.333837534139761, -7.073294526490922],
            [110.333728673895166, -7.073240098740376],
            [110.333601678564847, -7.073203735697326],
            [110.333504972504429, -7.073158370627344],
            [110.333341618624488, -7.073022456763571],
            [110.333224604695147, -7.072931835881981],
            [110.33315219399023, -7.07291364154058],
            [110.333091615518484, -7.07293164548271],
            [110.33301874015315, -7.073040085187077],
            [110.332982417974421, -7.073112395874714],
            [110.332915871436256, -7.073148481919302],
            [110.332631319029133, -7.073274709001681],
            [110.332467588665708, -7.073401109081013],
            [110.332261567817739, -7.073617901826194],
            [110.332192500545986, -7.073635893468232],
            [110.332125927882089, -7.073690070021255],
            [110.331762843202668, -7.073725730537345],
            [110.331441866906246, -7.07379763255453],
            [110.331248207782963, -7.07387876251746],
            [110.331108980335557, -7.073887607903115],
            [110.330981972084416, -7.073860289464548],
            [110.330752394059743, -7.073733324938356],
            [110.330586429145868, -7.073642633081626],
            [110.330489684261863, -7.073624403348687],
            [110.330398933588299, -7.073570000882503],
            [110.330344593018694, -7.073578968084561],
            [110.330289891289411, -7.073642206757562],
            [110.330271677834432, -7.073714543228413],
            [110.330256576889326, -7.073786884173888],
            [110.330243339314805, -7.073940635791492],
            [110.330204483071469, -7.074003897236382],
            [110.330129626189617, -7.074112333555947],
            [110.330057407153433, -7.074157456329412],
            [110.329946732132228, -7.074184433095998],
            [110.329742063433969, -7.07424745588767],
            [110.329538552612391, -7.074292389588723],
            [110.32934784006099, -7.074292114974522],
            [110.32920859945034, -7.074310005088569],
            [110.329038451298189, -7.074373077280301],
            [110.328918438628818, -7.07440004033792],
            [110.328652777309941, -7.074571518682128],
            [110.328578037606036, -7.07459854691261],
            [110.328485240964014, -7.074589367774713],
            [110.328325370582149, -7.074589137205494],
            [110.328021396914735, -7.074642970625349],
            [110.327906464273909, -7.074678986068739],
            [110.327800843012724, -7.074733105579348],
            [110.327726011644657, -7.07482345082761],
            [110.327705316559047, -7.074850556932015],
            [110.327630589855801, -7.074868539692038],
            [110.32742714409855, -7.07486824588298],
            [110.327246630924108, -7.074858939791084],
            [110.327130710432598, -7.074795455011945],
            [110.327028088927122, -7.074731989426764],
            [110.3269830988542, -7.074731924404382],
            [110.32688801239199, -7.074740832291589],
            [110.326807922618841, -7.074749761839819],
            [110.3267679864488, -7.074776840080845],
            [110.326727997853126, -7.074840099545815],
            [110.326707776884874, -7.074930523566791],
            [110.326679093422655, -7.075002844697462],
            [110.326645086204167, -7.075038976823963],
            [110.326588159494264, -7.075075075801236],
            [110.326516558360581, -7.075084017569747],
            [110.326459914592959, -7.075120116939072],
            [110.326421919732496, -7.075174333928944],
            [110.326359590586804, -7.075228515709833],
            [110.326280297138013, -7.075273627610148],
            [110.326208321200937, -7.075345886058915],
            [110.32611203719344, -7.075400018664255],
            [110.326027071437423, -7.075454167630854],
            [110.325906143898266, -7.075526355165009],
            [110.325834180989744, -7.07558956824096],
            [110.325783143748851, -7.075661856929985],
            [110.325689584182797, -7.07578835597109],
            [110.325651589176374, -7.075842572882886],
            [110.325621516958506, -7.075896801266591],
            [110.325576204439599, -7.075923871600923],
            [110.325524962947611, -7.075941888017845],
            [110.325461027779198, -7.075932750068463],
            [110.325374186225616, -7.075914533596627],
            [110.325326932397161, -7.075914465123356],
            [110.325288976745782, -7.075941546088789],
            [110.325270235900206, -7.07598674554351],
            [110.325254864263599, -7.076050040525539],
            [110.325217125868448, -7.076122348411805],
            [110.325179091365527, -7.076203701187067],
            [110.325179025675169, -7.076248927704668],
            [110.325201622842144, -7.076276096426213],
            [110.32523187299563, -7.076294230916432],
            [110.325271487015129, -7.076294288330653],
            [110.325373634595508, -7.076294436361771],
            [110.3254565279492, -7.076303601796451],
            [110.325541402008753, -7.076312770086163],
            [110.325594275635552, -7.076339982652199],
            [110.325645142308971, -7.076385282952389],
            [110.325680972864589, -7.076457697439268],
            [110.325690435872616, -7.076566255026465],
            [110.325675011748459, -7.076665731241553],
            [110.325620322162749, -7.076719923959261],
            [110.325548411546805, -7.076746955750638],
            [110.325469183426392, -7.076746840958648],
            [110.325386020171393, -7.076728629805132],
            [110.325297467594339, -7.076719456149086],
            [110.325217390610121, -7.076719340084394],
            [110.325022715827245, -7.076719057862618],
            [110.32491263220615, -7.076727943559265],
            [110.324859692858496, -7.076745957429503],
            [110.324810997870401, -7.076763977449285],
            [110.324730894590914, -7.076781951905128],
            [110.32461627048491, -7.076799876264802],
            [110.324451536578607, -7.076835818522546],
            [110.324345670984044, -7.076862800844122],
            [110.324296113803072, -7.076889958434083],
            [110.324230750736561, -7.076889769996306],
            [110.324130287635199, -7.076898669468235],
            [110.3240199210166, -7.076907554537222],
            [110.323914364675147, -7.076916446565817],
            [110.323833978391178, -7.076934420450462],
            [110.323716215363987, -7.076970430661503],
            [110.32362649146495, -7.076988390943098],
            [110.323573012477027, -7.07698831323657],
            [110.323468331336585, -7.076979115794876],
            [110.323341336079181, -7.076942749938353],
            [110.323281375428465, -7.076924572139492],
            [110.323222790038372, -7.076933532288944],
            [110.323189940606099, -7.076951575167709],
            [110.323156525256294, -7.076969617221073],
            [110.323134467745106, -7.07696053983086],
            [110.323091767513318, -7.076942387104792],
            [110.323046277252445, -7.076897094362564],
            [110.322980749746762, -7.07681559120293],
            [110.32293047559827, -7.076752200857467],
            [110.322880161912039, -7.076715946404785],
            [110.322811725776916, -7.076688710903779],
            [110.322733925684787, -7.076679552406515],
            [110.322655803075406, -7.076697529380668],
            [110.32254737764093, -7.076733552879552],
            [110.322464161784893, -7.076751522408033],
            [110.322384624370017, -7.07676949727536],
            [110.322271711171709, -7.076778378222774],
            [110.322204933225265, -7.076778280999981],
            [110.322071120777025, -7.076759995518641],
            [110.321955108252993, -7.076759826558951],
            [110.321872214947092, -7.076750660499766],
            [110.321805463419636, -7.076732472626067],
            [110.321759058414997, -7.07673240501944],
            [110.321722504092335, -7.076768533025134],
            [110.321698709260104, -7.076786588987066],
            [110.321657035279898, -7.076840800158761],
            [110.321579359648425, -7.076940185438782],
            [110.321520991147921, -7.076994372264937],
            [110.321495472128859, -7.077030516332596],
            [110.321490595794003, -7.077075735802697],
            [110.321497590464077, -7.077130017891089],
            [110.321476625180139, -7.077148077963116],
            [110.321436431991188, -7.077157064690276],
            [110.321350669415366, -7.077175030295812],
            [110.321229293827756, -7.077165808011665],
            [110.321126889623486, -7.07714756804927],
            [110.321059302427187, -7.077120333532811],
            [110.320960616356288, -7.077074963014622],
            [110.320835319079777, -7.07703859896721],
            [110.320710278330168, -7.077020325890434],
            [110.320603360098289, -7.076993033916647],
            [110.320490513006249, -7.076956687951358],
            [110.320356200873348, -7.076893174681636],
            [110.320304432911115, -7.076884053785227],
            [110.32026450945483, -7.076902086118478],
            [110.320179003386187, -7.076938142509379],
            [110.32011780516531, -7.076992325011785],
            [110.320087179572155, -7.0770375068467],
            [110.320041504268644, -7.07711884794767],
            [110.319998415288154, -7.077173056882234],
            [110.319931248422321, -7.077245321260795],
            [110.319848735605959, -7.077362789767051],
            [110.319744678223813, -7.077507362705734],
            [110.319639705658233, -7.077697160836053],
            [110.319588950213088, -7.077769449139444],
            [110.319507056132409, -7.077850737223729],
            [110.319377125445044, -7.077886728492848],
            [110.319268160139615, -7.077904659764306],
            [110.319173059766044, -7.077922611290282],
            [110.319078215824618, -7.077958653792821],
            [110.319022406805914, -7.078003798697586],
            [110.318977593351036, -7.078076095614766],
            [110.318957963147156, -7.078148429375826],
            [110.318915965983777, -7.078229775715683],
            [110.318879345009265, -7.078311129917837],
            [110.318831971609868, -7.078392468378965],
            [110.31880103630327, -7.078455740273009],
            [110.318776675362571, -7.07847379523689],
            [110.318722603805085, -7.078491806709504],
            [110.318648159250401, -7.078509788350312],
            [110.318549636696844, -7.078545825343669],
            [110.318370431196556, -7.078608880082212],
            [110.318237644095134, -7.078662957438194],
            [110.318149564383319, -7.078717100255686],
            [110.318096301812076, -7.078762248761643],
            [110.318043362034629, -7.07878026180776],
            [110.317920584671114, -7.078761991265559],
            [110.317778632587903, -7.078698466043683],
            [110.317723172760125, -7.078698384742816],
            [110.317636534361554, -7.078734438954138],
            [110.317593241738763, -7.078734375476713],
            [110.317469885206819, -7.078725149276671],
            [110.317448124004017, -7.078707026747255],
            [110.317448177177013, -7.078670845595203],
            [110.317451016246878, -7.078471852992632],
            [110.317440936096787, -7.078399475748839],
            [110.317413003004532, -7.078345162937413],
            [110.31732110810789, -7.078299801618575],
            [110.317147694729599, -7.078272411304924],
            [110.317035400302458, -7.078245110617715],
            [110.316951171937419, -7.078181669867035],
            [110.316881697316333, -7.07809111483721],
            [110.316826357308813, -7.078009625852664],
            [110.31673966222904, -7.077891909610186],
            [110.316677221712681, -7.077828500801525],
            [110.316610200659269, -7.077801266486052],
            [110.316562650458621, -7.077810241976136],
            [110.316527833352936, -7.077819236158522],
            [110.316482506879893, -7.077855350823565],
            [110.316423315470928, -7.07789144512121],
            [110.316378311878751, -7.077900424331237],
            [110.316291443854112, -7.077900296737549],
            [110.316246117356386, -7.077936411376924],
            [110.316221769674087, -7.077945420914588],
            [110.316089924680085, -7.077936181900505],
            [110.315968266173115, -7.077926957819886],
            [110.315888215780703, -7.077908749559841],
            [110.315756344163319, -7.077917601023104],
            [110.315558207126571, -7.077962536227453],
            [110.315471325774908, -7.077971453764144],
            [110.315314823487597, -7.077989314178853],
            [110.315043683297517, -7.078034141760684],
            [110.314768082467069, -7.078033736098339],
            [110.314504312479059, -7.078069528906567],
            [110.314328528968147, -7.078114496491161],
            [110.314268501864035, -7.078141543962365],
            [110.314188640997997, -7.078186652804147],
            [110.314068546676367, -7.078267883543801],
            [110.313726020706341, -7.07836687692316],
            [110.313472100548054, -7.078438864845165],
            [110.313204827798714, -7.078547014116087],
            [110.313039486710764, -7.078610087192249],
            [110.312903573214854, -7.078673203651668],
            [110.312511515725376, -7.078781168252134],
            [110.312444414496724, -7.078808205018939],
            [110.312359244122462, -7.078808079183236],
            [110.312182988051759, -7.078789728127158],
            [110.311974744346557, -7.078780375014583],
            [110.311818564929808, -7.078771098800206],
            [110.31154405545594, -7.078797828690633],
            [110.311487060212343, -7.078879152042231],
            [110.311439672112328, -7.078969534895412],
            [110.311420606630094, -7.079041869056695],
            [110.311292790036063, -7.078987408143592],
            [110.311160446145877, -7.078932940497037],
            [110.311032508850317, -7.07895988699956],
            [110.310740941232581, -7.079040862917259],
            [110.31050425397747, -7.079130965250833],
            [110.310168733620856, -7.079275192724103],
            [110.310044367145252, -7.079374506582043],
            [110.309871534092494, -7.079528020285529],
            [110.309624013350799, -7.079672377819039],
            [110.30935454277828, -7.079735294998256],
            [110.309096134442981, -7.0797801378559],
            [110.308815682238489, -7.079806857240033],
            [110.308707541623235, -7.079797651307685],
            [110.30873557787325, -7.079634877719178],
            [110.308792061378568, -7.079327421721135],
            [110.308900784039892, -7.078902454493531],
            [110.308961457833846, -7.078631185836207],
            [110.309018129765732, -7.078387047095974],
            [110.30913493660006, -7.078043499389105],
            [110.309304022560227, -7.077555304524774],
            [110.309438412588278, -7.077184646899063],
            [110.309585804941008, -7.076823053785929],
            [110.309711328755483, -7.076515699937845],
            [110.309808476131877, -7.076262575734981],
            [110.309853384780752, -7.076126962893689],
            [110.309949144067886, -7.075855745995075],
            [110.309978988791386, -7.07576533727948],
            [110.310005410286507, -7.075503062883288],
            [110.310062321962008, -7.07490615773529],
            [110.31007371982129, -7.0746619516477],
            [110.31008734005151, -7.074254933528157],
            [110.310093348276126, -7.074019764741816],
            [110.310090099535373, -7.073730310464477],
            [110.310088387389669, -7.073549402011066],
            [110.310081675036542, -7.073115217861682],
            [110.31007376472931, -7.072916209630848],
            [110.310064290950521, -7.072626746121212],
            [110.310061514086513, -7.072400609602248],
            [110.310071295619977, -7.072292080534512],
            [110.310087881463701, -7.072174516246953],
            [110.310173574043091, -7.071821876586951],
            [110.310345120937512, -7.071198005081621],
            [110.310483427669737, -7.070664537239466],
            [110.3105792924555, -7.070320957778356],
            [110.310743563159704, -7.069832754695042],
            [110.310826803365742, -7.069606745347125],
            [110.310887043931942, -7.069434973757269],
            [110.311005261298178, -7.069091427190831],
            [110.311085887654656, -7.068910640397988],
            [110.311434437330135, -7.068178486190701],
            [110.31160132152398, -7.067835011278408],
            [110.311664890011457, -7.067708470946263],
            [110.311765995373364, -7.067455351809047],
            [110.311792523934542, -7.067310666155369],
            [110.31179818101208, -7.06712072317657],
            [110.311787723263521, -7.066921711103362],
            [110.311738162858191, -7.066568871172238],
            [110.31167530383631, -7.066216011603292],
            [110.311617471075451, -7.065908385959211],
            [110.311583218813524, -7.065727429352152],
            [110.311486000689882, -7.06526597541635],
            [110.311454455656005, -7.064976479181779],
            [110.311407602721744, -7.064515099607068],
            [110.311365575241368, -7.064234633190026],
            [110.311276589792328, -7.063755100784499],
            [110.311213731299759, -7.063402241181794],
            [110.311202667679098, -7.06323036410696],
            [110.311235998309527, -7.062886691853246],
            [110.311292920614989, -7.062470692002768],
            [110.311389992628321, -7.061882890647527],
            [110.311494489887934, -7.061439825042839],
            [110.311529169979735, -7.061331332585878],
            [110.311615123121086, -7.061105326838313],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 104,
        id: 106,
        area: 3389798.442454,
        perimeter: 8969.67398,
        kode_pos: 50217,
        kode_kec: 3374010,
        kode_kel: 3374010005,
        kec: "Mijen",
        kel: "Purwosari",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.334738530178058, -7.068628417945393],
            [110.334941436043479, -7.068682979596572],
            [110.335392900137975, -7.068774077064854],
            [110.335767992874494, -7.068846974578157],
            [110.33606643120315, -7.06890167191049],
            [110.336471941218164, -7.069074111011342],
            [110.336869631243673, -7.069373173505281],
            [110.336999712595215, -7.06942763067403],
            [110.337320980544035, -7.069545677158254],
            [110.337711328087678, -7.069636685578328],
            [110.337810619165836, -7.069654917382239],
            [110.338510454337026, -7.069791591596259],
            [110.338873330134149, -7.069900650781513],
            [110.339464511678301, -7.07003716944747],
            [110.33991046098231, -7.070227753781359],
            [110.340180155655148, -7.070399997452483],
            [110.340439355552022, -7.070590316832447],
            [110.34072938491731, -7.070789725063625],
            [110.341030282207214, -7.070907740329859],
            [110.341362085828024, -7.070980572340484],
            [110.341818634334913, -7.071080716434255],
            [110.342077950540229, -7.071189626964053],
            [110.342188236983063, -7.071280446724739],
            [110.34224795730978, -7.071329625780148],
            [110.342278025183504, -7.071422688515929],
            [110.342340360415889, -7.071515489265142],
            [110.342386688364812, -7.071569042787875],
            [110.342463339500057, -7.071635119670028],
            [110.342554240961661, -7.071720828965338],
            [110.342736109524523, -7.071845891226904],
            [110.342928661524937, -7.071988797871266],
            [110.34308553362888, -7.072120956504842],
            [110.343210262559523, -7.072265550336889],
            [110.343356338142456, -7.072460096491033],
            [110.343459635031707, -7.072615357682855],
            [110.343543239203484, -7.07281338160991],
            [110.343626818262962, -7.073029234869244],
            [110.34369434209367, -7.073239716681583],
            [110.343777808005541, -7.073535801964972],
            [110.343838114873861, -7.073801544667749],
            [110.34398021345288, -7.07423154959036],
            [110.344081729128121, -7.074438396655977],
            [110.344188555928412, -7.07462218971274],
            [110.34433456719195, -7.074863091939633],
            [110.344505542725003, -7.075120075717507],
            [110.344717470083751, -7.075446651663594],
            [110.34479753583868, -7.075623275200522],
            [110.344866960897278, -7.075751744429099],
            [110.344918498053332, -7.075908715490247],
            [110.344909373085443, -7.076051337691959],
            [110.344882468256742, -7.076145795547855],
            [110.344835878111837, -7.07627766739613],
            [110.344825041242714, -7.076368581981327],
            [110.344851684621489, -7.07645954931184],
            [110.344881952869429, -7.076511297107332],
            [110.344960412547053, -7.076561329773885],
            [110.345071008239884, -7.076604275887509],
            [110.345231539957922, -7.076674036284854],
            [110.345383193073388, -7.076711691259993],
            [110.345559860700675, -7.07672976903411],
            [110.345777733465411, -7.07672155220884],
            [110.345898950444791, -7.076742726171162],
            [110.345973828182849, -7.076801668338485],
            [110.346007832668377, -7.076884691989655],
            [110.346028897711989, -7.076985388351725],
            [110.346000168095514, -7.077108370733512],
            [110.345966081618656, -7.077233128523303],
            [110.34590951138297, -7.077382048610574],
            [110.345813921073656, -7.077555626526301],
            [110.345711930025885, -7.077739125806125],
            [110.345535125972091, -7.078005652111856],
            [110.345343531750032, -7.078259225712398],
            [110.34514148698635, -7.078517467476708],
            [110.345034148635293, -7.078695610219736],
            [110.345007248657609, -7.078786502191909],
            [110.345007520217607, -7.07892753637681],
            [110.345007215774231, -7.079143398865906],
            [110.345007048198411, -7.079262213918835],
            [110.345002699344917, -7.079492300589494],
            [110.345020208835891, -7.079671374677856],
            [110.345045882994086, -7.079785937935716],
            [110.345083762878929, -7.079841224727848],
            [110.345131299947766, -7.079902198332975],
            [110.345200176827291, -7.079958978198126],
            [110.34529264572727, -7.079992756627292],
            [110.345413452667557, -7.080013550415179],
            [110.345549347158766, -7.08002215278519],
            [110.345726990401545, -7.080006272017588],
            [110.3459256910443, -7.079943642089924],
            [110.346122447618214, -7.079887912236543],
            [110.346261661997175, -7.079868300477062],
            [110.346384481941413, -7.079879235015823],
            [110.34648441097066, -7.079928296528573],
            [110.346569891888322, -7.079999391147584],
            [110.346631126879387, -7.080088195352513],
            [110.346679430413147, -7.080186659745929],
            [110.346727699924642, -7.080309319966032],
            [110.346748459981256, -7.080473881083103],
            [110.346751553284534, -7.080570668937709],
            [110.346725458809061, -7.080754520970895],
            [110.346675200019277, -7.080898012605968],
            [110.346560129276469, -7.081199492934657],
            [110.346412702307319, -7.081544480337111],
            [110.346297740228479, -7.081768533917154],
            [110.346168235705832, -7.081967113362117],
            [110.346003657733078, -7.082234322706001],
            [110.345864078927136, -7.082414760651941],
            [110.345744367609029, -7.082571058925681],
            [110.345448535102207, -7.082826705735946],
            [110.345165628202068, -7.083039644387106],
            [110.344923656812924, -7.083214917094503],
            [110.344659762936573, -7.08335831733914],
            [110.344365196337279, -7.083508599387213],
            [110.344200823343527, -7.083629607372528],
            [110.344140016640026, -7.08371688554809],
            [110.344118427681423, -7.083838094828546],
            [110.344155586015262, -7.084066363185581],
            [110.34415719948079, -7.08418760518607],
            [110.344123081587867, -7.084333757912643],
            [110.344055085145484, -7.084456684665955],
            [110.344036954349022, -7.084479231151355],
            [110.343417135584772, -7.084632913132367],
            [110.343317713025101, -7.08470513555696],
            [110.3432409275146, -7.084777389929327],
            [110.343173801150755, -7.084822521875894],
            [110.343111510763933, -7.084849569940309],
            [110.34303136805481, -7.084894683476305],
            [110.342929192597481, -7.084912629810926],
            [110.34287173818656, -7.084921593972346],
            [110.34277583916419, -7.084903367738789],
            [110.342667232419345, -7.08486703282138],
            [110.342635283170509, -7.084848896955606],
            [110.342628685310828, -7.084912205081975],
            [110.342660173261564, -7.085255972914059],
            [110.342656983774191, -7.085310240507551],
            [110.342554538069621, -7.085319141017634],
            [110.342308668191663, -7.085300702608953],
            [110.342011298946346, -7.085282191198267],
            [110.341701517838231, -7.085236525981258],
            [110.341362617233983, -7.085172728620857],
            [110.341136837954267, -7.085154318060868],
            [110.340644571287285, -7.085090302853549],
            [110.340459320173977, -7.085026722704604],
            [110.340190890730227, -7.084953979121726],
            [110.340050515144682, -7.084971870606778],
            [110.33975964162056, -7.084962412347296],
            [110.339500730112178, -7.084962044652798],
            [110.339166344687712, -7.084907297494175],
            [110.338882030978326, -7.084861666637779],
            [110.338750967416431, -7.084897661654981],
            [110.338702799200576, -7.084942819873366],
            [110.338642141911407, -7.085015096353164],
            [110.33855880884829, -7.085114476597368],
            [110.338494755951899, -7.085186748223038],
            [110.338440349587614, -7.08524094287687],
            [110.338360437798627, -7.085322237259113],
            [110.338273979118938, -7.085430658335317],
            [110.338184124833973, -7.085539074558035],
            [110.338132997827316, -7.085674681916056],
            [110.338097472510569, -7.085783175449684],
            [110.338036815012401, -7.085855451828992],
            [110.337979321724603, -7.085891551336464],
            [110.337798173210828, -7.085927474732078],
            [110.337667160935851, -7.085927288114765],
            [110.337453279003412, -7.085899847353615],
            [110.337309250408978, -7.085899642087433],
            [110.337098455279872, -7.085890296246406],
            [110.336913152765035, -7.085862895988338],
            [110.336724493506622, -7.085808354850725],
            [110.336542586624645, -7.085780959293936],
            [110.33636997875908, -7.085780712957863],
            [110.336193988276491, -7.0857714163896],
            [110.335913674837784, -7.085698653394125],
            [110.335868722462664, -7.085671453172177],
            [110.335785852971583, -7.085644198781081],
            [110.335578183605421, -7.085625811391601],
            [110.33547577670447, -7.085607574365729],
            [110.335300030425159, -7.08562541382724],
            [110.335130496374902, -7.085652307441271],
            [110.335012178716312, -7.085679274245628],
            [110.3349515337555, -7.085742504861305],
            [110.334881036918205, -7.085769540028467],
            [110.334756545897861, -7.085760316587623],
            [110.334670203086688, -7.085787329048808],
            [110.334580721721352, -7.085832427672839],
            [110.334500578140577, -7.085877539643868],
            [110.334404614531636, -7.085904538281156],
            [110.334308689848072, -7.085904400947365],
            [110.333644210410782, -7.086156718434016],
            [110.333426855244042, -7.086183542856357],
            [110.333315006561236, -7.086237654481637],
            [110.333231698202056, -7.086318943010025],
            [110.333171039927166, -7.08639121867572],
            [110.333065351356666, -7.086490565733137],
            [110.333036476029591, -7.086499569641163],
            [110.33292157965883, -7.086508450124497],
            [110.332847970007819, -7.086535480494299],
            [110.332684956743989, -7.086553337197229],
            [110.332576285568479, -7.086562226529005],
            [110.332502688908363, -7.086580211528256],
            [110.332400473831399, -7.086625291414201],
            [110.332224340026855, -7.086715491759526],
            [110.332003845921378, -7.086760401636963],
            [110.331578794831373, -7.086786926670869],
            [110.331517996664687, -7.086759703259275],
            [110.331492582041221, -7.086723485393594],
            [110.331456131826471, -7.086687251656922],
            [110.331133839813404, -7.086487790818159],
            [110.331058558390495, -7.086496727831543],
            [110.331009039718992, -7.086496656576707],
            [110.330930684827493, -7.086478453159184],
            [110.330817242431706, -7.086460199225114],
            [110.330710269066174, -7.086469090563194],
            [110.330665521634714, -7.086496162126551],
            [110.330625571532167, -7.08653228592093],
            [110.330585582267233, -7.086595545639981],
            [110.33055354208598, -7.086640726144693],
            [110.330483044913464, -7.08666776060716],
            [110.330280403583828, -7.086694604708867],
            [110.330181340094526, -7.086712552641765],
            [110.330034224984843, -7.086694249996593],
            [110.329911470915576, -7.08665789176627],
            [110.329818619565415, -7.086684893902379],
            [110.329537261966152, -7.086747805497048],
            [110.329252186539193, -7.086837847527581],
            [110.32915788106483, -7.086891983403575],
            [110.328912006636656, -7.087072535030338],
            [110.328825624073417, -7.087126682268043],
            [110.328700784550477, -7.087162683303933],
            [110.328598673784313, -7.087135399860777],
            [110.32848352049713, -7.087126188204545],
            [110.328400315964714, -7.087135113327329],
            [110.328277404772933, -7.087207298328082],
            [110.328090155867073, -7.087351752889619],
            [110.327972364139399, -7.087405854552544],
            [110.327834804211705, -7.087432791628847],
            [110.327671738159367, -7.087486827740561],
            [110.327607696457079, -7.087550052366067],
            [110.327546984742625, -7.087658508410822],
            [110.327516293453471, -7.087748917233351],
            [110.327503363338408, -7.087884578361433],
            [110.327482602010861, -7.087956910901152],
            [110.327442342353095, -7.088011124581508],
            [110.327381774822129, -7.088020082259749],
            [110.327311356004486, -7.087992844384899],
            [110.327190648237902, -7.087911261778705],
            [110.327110852256226, -7.087911146267238],
            [110.327026167135855, -7.08796529559193],
            [110.326895049560761, -7.088037468302392],
            [110.3266807009704, -7.088136656370054],
            [110.326589546976422, -7.088163660273337],
            [110.326408963022061, -7.088199579881333],
            [110.326268290196722, -7.088226511951389],
            [110.32616751537094, -7.088253501821912],
            [110.326096938894509, -7.088334807373463],
            [110.326023052612129, -7.088551787903209],
            [110.325985912255092, -7.088795957663169],
            [110.325952220016134, -7.08900395113885],
            [110.325918063785082, -7.089284306485804],
            [110.325826169066374, -7.089094221487953],
            [110.325697821272513, -7.089012627412293],
            [110.325621874664705, -7.08889492807226],
            [110.325574224584742, -7.088777269786199],
            [110.325546368598282, -7.088279736843337],
            [110.32568964708986, -7.088017630485304],
            [110.325732789180066, -7.087927239880947],
            [110.325766323612697, -7.087827790015209],
            [110.325780629605816, -7.087719266935656],
            [110.325752365771891, -7.087502138276295],
            [110.325752497251429, -7.087411685273931],
            [110.325589404288124, -7.087094862511909],
            [110.325370929575627, -7.086723687429823],
            [110.325061531825867, -7.086415697510779],
            [110.324657045674755, -7.08611661462326],
            [110.324376260774372, -7.085980526912834],
            [110.324313818134385, -7.085917118948148],
            [110.324404952224072, -7.085709209081059],
            [110.324424608373874, -7.08561878446619],
            [110.324424687395933, -7.085564512675339],
            [110.324379248911882, -7.085483038793955],
            [110.324356730427965, -7.085401598214781],
            [110.324373221294863, -7.085347350271642],
            [110.324410717248398, -7.085247906258802],
            [110.324430360199599, -7.085166526938611],
            [110.324426477739408, -7.085112249391091],
            [110.324410980644359, -7.085067000286451],
            [110.324379624479008, -7.085030773456796],
            [110.324330711604844, -7.085003474190103],
            [110.324358224689007, -7.084958379821321],
            [110.324401557461925, -7.084931306828389],
            [110.324583864308366, -7.084877299757617],
            [110.324660020707498, -7.084850274413513],
            [110.324727944861735, -7.084841327738365],
            [110.324790505889382, -7.084823327947007],
            [110.325059879472704, -7.084633767310175],
            [110.325326462672606, -7.084417066487848],
            [110.325489619838038, -7.084299714008964],
            [110.325663891306959, -7.084128105658376],
            [110.325722260349295, -7.084073918368109],
            [110.325632732250128, -7.083956199388342],
            [110.325567729866378, -7.083901833204611],
            [110.325528516467642, -7.083820368453686],
            [110.325497831374378, -7.083711780108355],
            [110.325460302625459, -7.083639363117117],
            [110.325450477843333, -7.083585076946097],
            [110.32545876261193, -7.08353081704045],
            [110.32545355724163, -7.083413220327047],
            [110.325442613762519, -7.083349887213072],
            [110.325442692639982, -7.083295615405504],
            [110.325448443893322, -7.083232306504184],
            [110.325481859643531, -7.08321426432529],
            [110.325448706809141, -7.083051400477331],
            [110.325434637613654, -7.082997108150382],
            [110.32543471648809, -7.082942836342081],
            [110.32541696879936, -7.082888538679985],
            [110.325428135746748, -7.082798101670516],
            [110.325439210671632, -7.082770981770389],
            [110.325423022392428, -7.082617187844209],
            [110.325393067948781, -7.082590008440548],
            [110.325384631698867, -7.08255381492334],
            [110.325387579622131, -7.082472411313895],
            [110.325407597219169, -7.082327715214867],
            [110.325435228454779, -7.082201120795236],
            [110.325467117227873, -7.082065487224635],
            [110.325484226306486, -7.081975058824073],
            [110.325519010360225, -7.081794202838638],
            [110.325526255217767, -7.08167662416855],
            [110.325512330597135, -7.081522833521073],
            [110.325499432690137, -7.081441406930915],
            [110.325502472557162, -7.081296686199283],
            [110.325501136602981, -7.081242412335091],
            [110.325476894007608, -7.081179059939226],
            [110.325402738313556, -7.080998045997467],
            [110.325344040325831, -7.080889417031335],
            [110.325288481212993, -7.080762701969693],
            [110.325222386882871, -7.080681198229458],
            [110.325123673302741, -7.080653919093978],
            [110.32507506993673, -7.080608621989262],
            [110.325039443287437, -7.080590479667104],
            [110.32500210573221, -7.080581380180974],
            [110.324980910041461, -7.080563258790026],
            [110.324965103739999, -7.080536099896737],
            [110.324954956636915, -7.08050894921359],
            [110.324947935243401, -7.080472757744676],
            [110.324883755974014, -7.08043648334527],
            [110.324852077630624, -7.080427392059738],
            [110.32481928060254, -7.080409253828531],
            [110.32479790719313, -7.080318769610281],
            [110.324785009426748, -7.080237343011161],
            [110.324785088346061, -7.080183071203732],
            [110.324739288349321, -7.080155868779239],
            [110.324696304799289, -7.080137715758426],
            [110.324666363704168, -7.080101491023208],
            [110.32464064069525, -7.080083363048869],
            [110.324601914706008, -7.080056170879662],
            [110.324573335792991, -7.080056129396719],
            [110.324562070040585, -7.080019931763799],
            [110.324554778879829, -7.079974694580168],
            [110.324566446003629, -7.079929484915617],
            [110.324612160347854, -7.079821007428035],
            [110.324665271075077, -7.079685404706895],
            [110.324705622295539, -7.079567874100875],
            [110.324688427550925, -7.079522622546022],
            [110.324607119471366, -7.079395870060668],
            [110.324531391676658, -7.079323397585583],
            [110.324478609902414, -7.079232867768781],
            [110.324408297850169, -7.079133267178708],
            [110.324367222707224, -7.078970391787012],
            [110.324345823108914, -7.078897998158926],
            [110.324318777500594, -7.078816551013102],
            [110.324320337049599, -7.078717054756975],
            [110.324311749563037, -7.078590407808496],
            [110.324325015680444, -7.078418565985652],
            [110.324319191981175, -7.0783371496494],
            [110.324323837744217, -7.078255748511915],
            [110.324295160210838, -7.07812907239616],
            [110.324249735514272, -7.078038553246337],
            [110.324215023766541, -7.077975185608087],
            [110.324117909883014, -7.077821274157824],
            [110.324039444966076, -7.077685480414972],
            [110.324005299186354, -7.077622113587181],
            [110.324046933503297, -7.077595038090288],
            [110.324084079973403, -7.077540820112182],
            [110.324088390151658, -7.077495599770528],
            [110.324082770478071, -7.077468455649995],
            [110.324062719951499, -7.077441290574239],
            [110.324027093580384, -7.077423148198892],
            [110.324004213480208, -7.077395979011953],
            [110.324037128731504, -7.077332709571328],
            [110.324071781204765, -7.077242306689464],
            [110.324107526011957, -7.077179041350229],
            [110.324136183705903, -7.077124811037756],
            [110.324184918229804, -7.077079655190945],
            [110.324233639586296, -7.077043544639482],
            [110.324256611788456, -7.077007396704834],
            [110.324264034474126, -7.076962180876651],
            [110.324276833352414, -7.076916972852024],
            [110.324296113803072, -7.076889958434083],
            [110.324345670984044, -7.076862800844122],
            [110.324451536578607, -7.076835818522546],
            [110.32461627048491, -7.076799876264802],
            [110.324730894590914, -7.076781951905128],
            [110.324810997870401, -7.076763977449285],
            [110.324859692858496, -7.076745957429503],
            [110.32491263220615, -7.076727943559265],
            [110.325022715827245, -7.076719057862618],
            [110.325217390610121, -7.076719340084394],
            [110.325297467594339, -7.076719456149086],
            [110.325386020171393, -7.076728629805132],
            [110.325469183426392, -7.076746840958648],
            [110.325548411546805, -7.076746955750638],
            [110.325620322162749, -7.076719923959261],
            [110.325675011748459, -7.076665731241553],
            [110.325690435872616, -7.076566255026465],
            [110.325680972864589, -7.076457697439268],
            [110.325645142308971, -7.076385282952389],
            [110.325594275635552, -7.076339982652199],
            [110.325541402008753, -7.076312770086163],
            [110.3254565279492, -7.076303601796451],
            [110.325373634595508, -7.076294436361771],
            [110.325271487015129, -7.076294288330653],
            [110.32523187299563, -7.076294230916432],
            [110.325201622842144, -7.076276096426213],
            [110.325179025675169, -7.076248927704668],
            [110.325179091365527, -7.076203701187067],
            [110.325217125868448, -7.076122348411805],
            [110.325254864263599, -7.076050040525539],
            [110.325270235900206, -7.07598674554351],
            [110.325288976745782, -7.075941546088789],
            [110.325326932397161, -7.075914465123356],
            [110.325374186225616, -7.075914533596627],
            [110.325461027779198, -7.075932750068463],
            [110.325524962947611, -7.075941888017845],
            [110.325576204439599, -7.075923871600923],
            [110.325621516958506, -7.075896801266591],
            [110.325651589176374, -7.075842572882886],
            [110.325689584182797, -7.07578835597109],
            [110.325783143748851, -7.075661856929985],
            [110.325834180989744, -7.07558956824096],
            [110.325906143898266, -7.075526355165009],
            [110.326027071437423, -7.075454167630854],
            [110.32611203719344, -7.075400018664255],
            [110.326208321200937, -7.075345886058915],
            [110.326280297138013, -7.075273627610148],
            [110.326359590586804, -7.075228515709833],
            [110.326421919732496, -7.075174333928944],
            [110.326459914592959, -7.075120116939072],
            [110.326516558360581, -7.075084017569747],
            [110.326588159494264, -7.075075075801236],
            [110.326645086204167, -7.075038976823963],
            [110.326679093422655, -7.075002844697462],
            [110.326707776884874, -7.074930523566791],
            [110.326727997853126, -7.074840099545815],
            [110.3267679864488, -7.074776840080845],
            [110.326807922618841, -7.074749761839819],
            [110.32688801239199, -7.074740832291589],
            [110.3269830988542, -7.074731924404382],
            [110.327028088927122, -7.074731989426764],
            [110.327130710432598, -7.074795455011945],
            [110.327246630924108, -7.074858939791084],
            [110.32742714409855, -7.07486824588298],
            [110.327630589855801, -7.074868539692038],
            [110.327705316559047, -7.074850556932015],
            [110.327726011644657, -7.07482345082761],
            [110.327800843012724, -7.074733105579348],
            [110.327906464273909, -7.074678986068739],
            [110.328021396914735, -7.074642970625349],
            [110.328325370582149, -7.074589137205494],
            [110.328485240964014, -7.074589367774713],
            [110.328578037606036, -7.07459854691261],
            [110.328652777309941, -7.074571518682128],
            [110.328918438628818, -7.07440004033792],
            [110.329038451298189, -7.074373077280301],
            [110.32920859945034, -7.074310005088569],
            [110.32934784006099, -7.074292114974522],
            [110.329538552612391, -7.074292389588723],
            [110.329742063433969, -7.07424745588767],
            [110.329946732132228, -7.074184433095998],
            [110.330057407153433, -7.074157456329412],
            [110.330129626189617, -7.074112333555947],
            [110.330204483071469, -7.074003897236382],
            [110.330243339314805, -7.073940635791492],
            [110.330256576889326, -7.073786884173888],
            [110.330271677834432, -7.073714543228413],
            [110.330289891289411, -7.073642206757562],
            [110.330344593018694, -7.073578968084561],
            [110.330398933588299, -7.073570000882503],
            [110.330489684261863, -7.073624403348687],
            [110.330586429145868, -7.073642633081626],
            [110.330752394059743, -7.073733324938356],
            [110.330981972084416, -7.073860289464548],
            [110.331108980335557, -7.073887607903115],
            [110.331248207782963, -7.07387876251746],
            [110.331441866906246, -7.07379763255453],
            [110.331762843202668, -7.073725730537345],
            [110.332125927882089, -7.073690070021255],
            [110.332192500545986, -7.073635893468232],
            [110.332261567817739, -7.073617901826194],
            [110.332467588665708, -7.073401109081013],
            [110.332631319029133, -7.073274709001681],
            [110.332915871436256, -7.073148481919302],
            [110.332982417974421, -7.073112395874714],
            [110.33301874015315, -7.073040085187077],
            [110.333091615518484, -7.07293164548271],
            [110.33315219399023, -7.07291364154058],
            [110.333224604695147, -7.072931835881981],
            [110.333341618624488, -7.073022456763571],
            [110.333504972504429, -7.073158370627344],
            [110.333601678564847, -7.073203735697326],
            [110.333728673895166, -7.073240098740376],
            [110.333837534139761, -7.073294526490922],
            [110.334019049299329, -7.073394284812897],
            [110.334157915919903, -7.073439710061293],
            [110.33437580504642, -7.073430976173949],
            [110.334588073901827, -7.073395098132347],
            [110.3345830972399, -7.073313682956595],
            [110.3346217475911, -7.073196148745616],
            [110.334751034420847, -7.073015426606624],
            [110.335035151413337, -7.072599746605368],
            [110.335091306005879, -7.072509373349667],
            [110.335108386841554, -7.072437034996374],
            [110.335095834974297, -7.072310382292942],
            [110.334997303503059, -7.07215647082776],
            [110.334937150482816, -7.07207497687469],
            [110.334878373434819, -7.072020620905109],
            [110.334786412982766, -7.072020489593417],
            [110.33466928246267, -7.072011276973447],
            [110.334584743587641, -7.071965929516327],
            [110.33454913007715, -7.071938742620093],
            [110.334529735650605, -7.071848261503866],
            [110.334555922889152, -7.071739754818474],
            [110.334569158917546, -7.071586002924348],
            [110.334517263524248, -7.071468339358879],
            [110.334423130193926, -7.071404887494174],
            [110.334361614393103, -7.071287210171445],
            [110.334361795693795, -7.071160575656055],
            [110.334391585556645, -7.070907348665132],
            [110.334447471913933, -7.070608932238371],
            [110.334459202511866, -7.070518495580361],
            [110.334494085423046, -7.070265275842071],
            [110.334540660007349, -7.069948755399717],
            [110.334738530178058, -7.068628417945393],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 105,
        id: 107,
        area: 2883039.9045790001,
        perimeter: 11082.698087000001,
        kode_pos: 50219,
        kode_kec: 3374010,
        kode_kel: 3374010009,
        kec: "Mijen",
        kel: "Jati Barang",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.334738530178058, -7.068628417945393],
            [110.33478701158252, -7.068239537404549],
            [110.334871402593961, -7.067398440923546],
            [110.334893178560264, -7.067208519782467],
            [110.334936639902423, -7.066891994764621],
            [110.334999165330032, -7.06610513903806],
            [110.335004259922016, -7.065906148733068],
            [110.335003067823081, -7.06535438102951],
            [110.334966505374567, -7.065001560449127],
            [110.334918379309158, -7.064820584918602],
            [110.334902035695066, -7.064377339725434],
            [110.334924763651728, -7.064115057144319],
            [110.334947567716739, -7.063997500183238],
            [110.334971760669788, -7.063898035889808],
            [110.335063439258576, -7.063500171428294],
            [110.335177908675803, -7.062993795267122],
            [110.335254808882226, -7.062641136385749],
            [110.335357946912765, -7.062143789321729],
            [110.335395310264602, -7.06193579958993],
            [110.33544610129151, -7.061637375512869],
            [110.335464919023963, -7.061338905877375],
            [110.335482141237605, -7.061167068819166],
            [110.335506437146933, -7.06099524183295],
            [110.335615659234193, -7.060199406818859],
            [110.335685202731696, -7.059647739626046],
            [110.335701858848509, -7.05947590172833],
            [110.335511165036039, -7.059466584875699],
            [110.335341113505919, -7.05946634269518],
            [110.335157441757332, -7.059493217088503],
            [110.33488150322647, -7.059538050598209],
            [110.334720737278246, -7.059574002808733],
            [110.334665549561848, -7.059582969470768],
            [110.33461971204045, -7.059582904112796],
            [110.334587481855351, -7.059564767462545],
            [110.33456006178271, -7.05954663766968],
            [110.334532680487513, -7.059501371893266],
            [110.334340395425372, -7.059021694324841],
            [110.334211001027938, -7.058686831956043],
            [110.334183852552727, -7.058478750277598],
            [110.334161308822175, -7.058216403093529],
            [110.334147703028293, -7.058035476770036],
            [110.334090369823201, -7.057764034605094],
            [110.334046938301256, -7.057664473838091],
            [110.333957464128702, -7.057510575297757],
            [110.333879487338436, -7.057429055915854],
            [110.333771852961689, -7.057311312813154],
            [110.333659408554269, -7.057193562823794],
            [110.333583747193558, -7.057075865326199],
            [110.33355384548581, -7.05701250522156],
            [110.333514723027747, -7.056867723845886],
            [110.333485028384686, -7.056659638514145],
            [110.333462355641089, -7.05648774458706],
            [110.333421317325218, -7.056297733746508],
            [110.333364189967867, -7.056080563895388],
            [110.333323112887498, -7.05591768902965],
            [110.333332283613828, -7.055836294019626],
            [110.333408552911365, -7.055528861181759],
            [110.333457221173347, -7.055329933063405],
            [110.333471009538783, -7.055185227218451],
            [110.333485326638652, -7.054868660558423],
            [110.333490988842399, -7.054470673425953],
            [110.333491402784318, -7.054181222947319],
            [110.333477797223082, -7.054000296608747],
            [110.333450700897629, -7.053756033592184],
            [110.333415168014014, -7.053475577148419],
            [110.333390011880596, -7.053457450550712],
            [110.3332590347473, -7.053439172892774],
            [110.332967332588737, -7.053429711019685],
            [110.332581397220721, -7.053429159655016],
            [110.332466509082607, -7.053438040803464],
            [110.332211576720496, -7.053437676357526],
            [110.331915347545447, -7.053428207356313],
            [110.331671784844787, -7.053391677508171],
            [110.331332369161032, -7.053309783599734],
            [110.330817876465233, -7.05318241193106],
            [110.330143020159994, -7.053009583052149],
            [110.330080772610032, -7.053009493772803],
            [110.329959093944751, -7.053018364569735],
            [110.32983965281673, -7.053045329224751],
            [110.329784478851167, -7.053045250054969],
            [110.329715749615758, -7.05302706074621],
            [110.329036640400702, -7.052664272114208],
            [110.328632601262498, -7.052464694106087],
            [110.328483311599655, -7.052392116803942],
            [110.328143823032562, -7.052165495195912],
            [110.327973956964058, -7.052038616129582],
            [110.327776967626406, -7.051884561968976],
            [110.32722404099647, -7.051530997870088],
            [110.326911933819062, -7.051349641581207],
            [110.326748767590104, -7.051286089121263],
            [110.326668438140132, -7.051267882680484],
            [110.326269067609545, -7.051167808227155],
            [110.325734259633222, -7.051004220665595],
            [110.325518466529317, -7.050940591846027],
            [110.325254355884695, -7.050831666490756],
            [110.324407561193226, -7.050405312205723],
            [110.323976133422306, -7.050169509594665],
            [110.323686920577259, -7.050006275126464],
            [110.323168117312463, -7.049734164045248],
            [110.322440675203694, -7.049344160705672],
            [110.321805407216672, -7.048999516750535],
            [110.321706753066735, -7.048936056282733],
            [110.321683798245829, -7.048890796331545],
            [110.321678774323743, -7.048845562406254],
            [110.321840373106596, -7.04869202637741],
            [110.32211868058684, -7.048375843682934],
            [110.322263782495639, -7.048213238220163],
            [110.322856394902232, -7.047535697505658],
            [110.323185116705119, -7.047183405740132],
            [110.323460257554771, -7.046903398793996],
            [110.323667104840496, -7.046695655499661],
            [110.32377534493456, -7.046587268112557],
            [110.323927183810426, -7.046460853086174],
            [110.324032851130426, -7.046370552570657],
            [110.32421216114858, -7.046226086493142],
            [110.324440334122372, -7.046063768159707],
            [110.325200008833207, -7.045463164812579],
            [110.325546024327551, -7.045187799222744],
            [110.326022553766165, -7.044754310230973],
            [110.32628291343849, -7.044519506679731],
            [110.326684384713843, -7.044140180758164],
            [110.327114201261054, -7.043724713855868],
            [110.327693037520021, -7.043173780624227],
            [110.327938708794278, -7.042920864180136],
            [110.328087511189324, -7.04274017115924],
            [110.328179069082964, -7.042622713247087],
            [110.328295512364974, -7.042514336390621],
            [110.328437741883761, -7.042378860498303],
            [110.328654448731697, -7.042198264743476],
            [110.329009937381116, -7.041918369196283],
            [110.329326624339174, -7.041665553738166],
            [110.329443080112483, -7.041548131218923],
            [110.329654514296962, -7.041295164612739],
            [110.329839276881742, -7.041060233657681],
            [110.329953079599832, -7.041051368030476],
            [110.330256361212406, -7.041069892988553],
            [110.330948976062672, -7.041079929352797],
            [110.331633090004388, -7.041098997897173],
            [110.33207192418584, -7.041099624626709],
            [110.332503132056843, -7.041091194714449],
            [110.332814619106628, -7.041109729732778],
            [110.333034434814863, -7.041128133863819],
            [110.333139931328972, -7.041155420303805],
            [110.333306773400921, -7.041218975549635],
            [110.333511503190849, -7.041300675420447],
            [110.333864684427539, -7.041445904075692],
            [110.334223215906633, -7.041609230802325],
            [110.334423380387946, -7.04171805986305],
            [110.334706908238616, -7.041899370236766],
            [110.334880978334951, -7.042053388774299],
            [110.334978179835559, -7.042143980505883],
            [110.335050534436178, -7.042198355486463],
            [110.335131107113767, -7.042243696784976],
            [110.335308971571848, -7.042316312395497],
            [110.335798555768292, -7.042443642813149],
            [110.336129476691838, -7.042525520799988],
            [110.336454726146172, -7.042616435873084],
            [110.336703608313698, -7.042689151727632],
            [110.33661414879252, -7.042924204108675],
            [110.336485643835033, -7.043358198891831],
            [110.336385072305163, -7.043647507566194],
            [110.336250946701043, -7.044054358193247],
            [110.33617814140851, -7.044316570134556],
            [110.336127791105895, -7.044506451099434],
            [110.336099844382886, -7.044660182445675],
            [110.335901632849442, -7.044768445231115],
            [110.335888540437765, -7.044822698759644],
            [110.335842498393802, -7.044967359024486],
            [110.335822872626537, -7.045039693976726],
            [110.335804468438369, -7.045247710965823],
            [110.335791543065895, -7.045383372906695],
            [110.335739249832272, -7.045944110517462],
            [110.335722247354184, -7.046161174833332],
            [110.335634831501821, -7.046351003025738],
            [110.335591194388655, -7.046396167773617],
            [110.335520073467123, -7.046468429509954],
            [110.335469337071601, -7.046531674858097],
            [110.335459395529639, -7.046558796783207],
            [110.335548495806151, -7.046577014139246],
            [110.3355912068387, -7.046586120197285],
            [110.335652308962722, -7.046595252388106],
            [110.335880293785124, -7.046839800862696],
            [110.335960532879497, -7.046921323029513],
            [110.33606919448593, -7.047111429794455],
            [110.336093437237551, -7.047174781700036],
            [110.336226766041861, -7.047527739833782],
            [110.336245928773195, -7.047582039166724],
            [110.336355516502948, -7.047916872856124],
            [110.336381405499083, -7.048016408503567],
            [110.33639433069888, -7.048079744329822],
            [110.336388041661749, -7.048124962167743],
            [110.336372441312264, -7.04815207607747],
            [110.336354294493304, -7.048179186370642],
            [110.336344031408913, -7.048233443917676],
            [110.336385160618718, -7.048360137265893],
            [110.336405313606662, -7.048513936888381],
            [110.336450416894735, -7.048631590522659],
            [110.336495828878526, -7.048731153885517],
            [110.336561381172316, -7.048794564423464],
            [110.336588157665133, -7.04886696526362],
            [110.336640681740519, -7.049138400430207],
            [110.336666236442014, -7.049274117008175],
            [110.336691778290287, -7.049418878920611],
            [110.336655252887752, -7.049834913327729],
            [110.336669297054485, -7.049907296091406],
            [110.336698864435505, -7.050006836951175],
            [110.336778564023163, -7.050070267557268],
            [110.336813327300447, -7.050097452959691],
            [110.336840232474117, -7.050278398216238],
            [110.336875870283379, -7.050486491926214],
            [110.336888795588663, -7.050549827744828],
            [110.337017109941556, -7.051047504285893],
            [110.337045481373266, -7.051192270202635],
            [110.337071319194038, -7.05132798716966],
            [110.337148112494773, -7.051644683509285],
            [110.337169719072236, -7.051771349115899],
            [110.33717752574249, -7.051852768374001],
            [110.337187531661542, -7.05197941751963],
            [110.337205408556201, -7.052142259246635],
            [110.337226976581675, -7.05229606085693],
            [110.337213974072014, -7.052485994827268],
            [110.33737727104581, -7.052459090448622],
            [110.337709510971777, -7.052414334874257],
            [110.339111778255031, -7.052217323164444],
            [110.339687915206099, -7.052172911159588],
            [110.340483298680738, -7.052076977874793],
            [110.340495226443025, -7.052087689926715],
            [110.340651263233369, -7.05221012373114],
            [110.34079419491799, -7.052283653582718],
            [110.340907021905011, -7.052351500292366],
            [110.341027398369633, -7.05240431596206],
            [110.341232469569405, -7.052455370851034],
            [110.341407790021918, -7.052473836104352],
            [110.341594739183691, -7.052528371826122],
            [110.341696779703113, -7.05260087853908],
            [110.341744237632028, -7.052655217590422],
            [110.341821069371321, -7.052796518354837],
            [110.341896075197894, -7.052992165732962],
            [110.341916647660327, -7.053084324938685],
            [110.341927823157704, -7.053167069860982],
            [110.341937161733227, -7.053217848649642],
            [110.341922054907798, -7.05325355132636],
            [110.341865548307936, -7.053285435248642],
            [110.341720577421782, -7.053322835109667],
            [110.341507882796876, -7.053324241132295],
            [110.341272820039279, -7.053278682851876],
            [110.341073317320863, -7.053205349459517],
            [110.340858047867144, -7.053061009037919],
            [110.340773241401948, -7.053006617189618],
            [110.340659596443601, -7.052971619783922],
            [110.340520340897939, -7.052960141932004],
            [110.340428075304814, -7.052988039165421],
            [110.340392252601717, -7.053037049585826],
            [110.340405222075205, -7.053181843917019],
            [110.340421958310614, -7.05332476335934],
            [110.340458901336618, -7.053472448905935],
            [110.340526678234554, -7.053636405205597],
            [110.340646360125419, -7.053872373580711],
            [110.340821925047024, -7.054196346016256],
            [110.341029460477358, -7.054544343120895],
            [110.341204442495993, -7.054750592740453],
            [110.341349005298866, -7.054896494767195],
            [110.341524747065662, -7.055021271137134],
            [110.342118211018104, -7.05537869539473],
            [110.342304892635838, -7.055487606219137],
            [110.34242465228499, -7.055593636886299],
            [110.342483078873371, -7.055688437789125],
            [110.342524718947743, -7.055822216831568],
            [110.342543940297901, -7.056033967869553],
            [110.342529816544683, -7.056162096697344],
            [110.342468271072988, -7.05630130210129],
            [110.342380142684277, -7.05637375698049],
            [110.342229847197316, -7.056409726685159],
            [110.34200797716359, -7.05642601643583],
            [110.341690109665578, -7.056403281576925],
            [110.341377913904864, -7.056313694321394],
            [110.341210677486771, -7.056257741537665],
            [110.341037789082705, -7.056254711696104],
            [110.340889966434034, -7.056274003905898],
            [110.340769966573447, -7.056337908807925],
            [110.340647055879884, -7.056488170623904],
            [110.340174640452261, -7.05729539684085],
            [110.340107516939113, -7.057434593963874],
            [110.340057042064828, -7.05763231725529],
            [110.339992351810125, -7.058022243400542],
            [110.339913647362579, -7.058462294819664],
            [110.339888384152474, -7.058579264366383],
            [110.339854786714312, -7.058673935401774],
            [110.33981005460268, -7.058754661482408],
            [110.339724820673766, -7.058875572559791],
            [110.339689645414154, -7.058938840278075],
            [110.339653541455561, -7.059002379790053],
            [110.339639464149116, -7.0590970784222],
            [110.339638651524069, -7.059182992710426],
            [110.339663384198431, -7.059300617324181],
            [110.339728231357824, -7.059463525473603],
            [110.339822560188139, -7.059768724873316],
            [110.339858586975751, -7.05992756871037],
            [110.339863911446358, -7.060105869988416],
            [110.339838588855301, -7.060264627026962],
            [110.339802135498857, -7.060406653273795],
            [110.339737717001299, -7.06060435673409],
            [110.33968456746048, -7.060721286779927],
            [110.339636971410627, -7.060854939714406],
            [110.339552969241765, -7.061097188844916],
            [110.339477324917837, -7.061345021462128],
            [110.33945747656054, -7.061576218027865],
            [110.339462852219754, -7.061718303446721],
            [110.339490489399751, -7.061893850458121],
            [110.339501477875373, -7.062010871269325],
            [110.33949571486859, -7.062141797557652],
            [110.339476044323206, -7.062247631597931],
            [110.339406146949869, -7.062375681221684],
            [110.339319430816474, -7.062564995470632],
            [110.339252250792427, -7.062743194014406],
            [110.339221390064893, -7.06287408473322],
            [110.339274195991294, -7.062999522299815],
            [110.339315950246188, -7.063052512390511],
            [110.339399529949887, -7.063108347559083],
            [110.339525041849768, -7.063091810276803],
            [110.339745426308383, -7.063033619681992],
            [110.339977012625354, -7.062942014755844],
            [110.340211395382639, -7.062844841982517],
            [110.340356423629999, -7.06283111791356],
            [110.340428868673399, -7.062873007959745],
            [110.340537459302396, -7.062990166777188],
            [110.340651540423579, -7.063168621811896],
            [110.340804512501194, -7.063452993710858],
            [110.340951895658321, -7.063745715195088],
            [110.341043687771005, -7.06391020946062],
            [110.341160542148984, -7.064099811680802],
            [110.341355375872354, -7.064361955865211],
            [110.341541879374603, -7.064599015661263],
            [110.341720052615514, -7.064810991075753],
            [110.341750581829672, -7.064914110261875],
            [110.34175040844157, -7.065036686986673],
            [110.341719575775798, -7.065148077066781],
            [110.341680392936567, -7.065248311992949],
            [110.34159935681447, -7.065365202856068],
            [110.341482044653617, -7.065498757507499],
            [110.341392642508268, -7.065615636508794],
            [110.341314379114323, -7.065743674584446],
            [110.341266759205155, -7.065894042658196],
            [110.341266494950105, -7.066080693553726],
            [110.341283073001264, -7.066189364730413],
            [110.341305196798274, -7.06632033046086],
            [110.341374664510056, -7.06649593651692],
            [110.341510965206339, -7.066738497131661],
            [110.341711310395098, -7.067048008321448],
            [110.34189496333174, -7.067329637478677],
            [110.342028566492573, -7.067508119850302],
            [110.342173348011869, -7.067669902927055],
            [110.342418383415165, -7.067929331825193],
            [110.342546504128549, -7.06804094617585],
            [110.342618895047622, -7.068121837652526],
            [110.34270520172754, -7.068222249627611],
            [110.34276919523262, -7.06832541596561],
            [110.342780227842383, -7.068411792574903],
            [110.342760569443627, -7.068509269265797],
            [110.34271025151078, -7.068595559354257],
            [110.342671088204213, -7.068681865168245],
            [110.34260118659715, -7.068812701062589],
            [110.342513869260387, -7.068974849582829],
            [110.342509924899105, -7.068982174268551],
            [110.342409603586063, -7.069247690431586],
            [110.342332551927754, -7.069465100085931],
            [110.342300191991527, -7.069630867610355],
            [110.34225185756307, -7.06973420976637],
            [110.3421643242404, -7.0697911402022],
            [110.342073284464931, -7.0698034922314],
            [110.341900169012547, -7.06979968189649],
            [110.341707497065926, -7.069742355749186],
            [110.341502384963718, -7.069647570263605],
            [110.341225920989231, -7.069525939771725],
            [110.341093895252698, -7.069493660239207],
            [110.340965408709508, -7.069482780921726],
            [110.34085468623941, -7.069530763620539],
            [110.34078671930645, -7.06963407782574],
            [110.340790111881645, -7.069758888218461],
            [110.340838178737883, -7.069844537193973],
            [110.340966498605539, -7.069973090167664],
            [110.341151930487911, -7.070101723870043],
            [110.341727932219356, -7.070435947245337],
            [110.341911612567131, -7.070541400018278],
            [110.342077410176614, -7.070671788598138],
            [110.342168223100572, -7.07081990063431],
            [110.342212627483661, -7.070971513011742],
            [110.342224896255715, -7.071130211766231],
            [110.342235377755529, -7.071290690934121],
            [110.34224795730978, -7.071329625780148],
            [110.342188236983063, -7.071280446724739],
            [110.342077950540229, -7.071189626964053],
            [110.341818634334913, -7.071080716434255],
            [110.341362085828024, -7.070980572340484],
            [110.341030282207214, -7.070907740329859],
            [110.34072938491731, -7.070789725063625],
            [110.340439355552022, -7.070590316832447],
            [110.340180155655148, -7.070399997452483],
            [110.33991046098231, -7.070227753781359],
            [110.339464511678301, -7.07003716944747],
            [110.338873330134149, -7.069900650781513],
            [110.338510454337026, -7.069791591596259],
            [110.337810619165836, -7.069654917382239],
            [110.337711328087678, -7.069636685578328],
            [110.337320980544035, -7.069545677158254],
            [110.336999712595215, -7.06942763067403],
            [110.336869631243673, -7.069373173505281],
            [110.336471941218164, -7.069074111011342],
            [110.33606643120315, -7.06890167191049],
            [110.335767992874494, -7.068846974578157],
            [110.335392900137975, -7.068774077064854],
            [110.334941436043479, -7.068682979596572],
            [110.334738530178058, -7.068628417945393],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 106,
        id: 108,
        area: 5537120.6555679999,
        perimeter: 10102.48749,
        kode_pos: 50212,
        kode_kec: 3374010,
        kode_kel: 3374010015,
        kec: "Mijen",
        kel: "Pesantren",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.329839276881742, -7.041060233657681],
            [110.329654514296962, -7.041295164612739],
            [110.329443080112483, -7.041548131218923],
            [110.329326624339174, -7.041665553738166],
            [110.329009937381116, -7.041918369196283],
            [110.328654448731697, -7.042198264743476],
            [110.328437741883761, -7.042378860498303],
            [110.328295512364974, -7.042514336390621],
            [110.328179069082964, -7.042622713247087],
            [110.328087511189324, -7.04274017115924],
            [110.327938708794278, -7.042920864180136],
            [110.327693037520021, -7.043173780624227],
            [110.327114201261054, -7.043724713855868],
            [110.326684384713843, -7.044140180758164],
            [110.32628291343849, -7.044519506679731],
            [110.326022553766165, -7.044754310230973],
            [110.325546024327551, -7.045187799222744],
            [110.325200008833207, -7.045463164812579],
            [110.324440334122372, -7.046063768159707],
            [110.32421216114858, -7.046226086493142],
            [110.324032851130426, -7.046370552570657],
            [110.323927183810426, -7.046460853086174],
            [110.32377534493456, -7.046587268112557],
            [110.323667104840496, -7.046695655499661],
            [110.323460257554771, -7.046903398793996],
            [110.323185116705119, -7.047183405740132],
            [110.322856394902232, -7.047535697505658],
            [110.322263782495639, -7.048213238220163],
            [110.32211868058684, -7.048375843682934],
            [110.321840373106596, -7.04869202637741],
            [110.321678774323743, -7.048845562406254],
            [110.321669371928692, -7.048764140819037],
            [110.321680564453672, -7.048655613144354],
            [110.3217030547072, -7.048366195329101],
            [110.321703883326123, -7.047796340932261],
            [110.321704685574218, -7.047244577137892],
            [110.32169392711026, -7.047054609652595],
            [110.321683089742635, -7.0469189140162],
            [110.321606603749544, -7.046593171239579],
            [110.321541112999242, -7.046294580395149],
            [110.321464535094393, -7.046032154763621],
            [110.321398952401751, -7.045796881065865],
            [110.321300331753264, -7.045516332769139],
            [110.321256621030102, -7.045416770717116],
            [110.321212897166987, -7.045326253970397],
            [110.321114145121811, -7.045136158728363],
            [110.321048470577125, -7.044964202156551],
            [110.321004477012465, -7.044864639677882],
            [110.320959943910822, -7.044746985759148],
            [110.320926096658724, -7.044674573990543],
            [110.320856948007687, -7.044556884316663],
            [110.320754031167368, -7.044312511012733],
            [110.320628249156513, -7.044031923170497],
            [110.320476002866556, -7.043660843588892],
            [110.32027020951729, -7.043144960934731],
            [110.320061817451176, -7.042665255748203],
            [110.319928449646383, -7.042348475448779],
            [110.319782448314598, -7.042158311313574],
            [110.319666398783355, -7.041995326681941],
            [110.319520706965022, -7.041787072282108],
            [110.319394768040738, -7.041615027887084],
            [110.319248753955335, -7.041433908928079],
            [110.318883051470081, -7.040953974426935],
            [110.318704026726692, -7.040709490002187],
            [110.318412092155441, -7.040283934576139],
            [110.31823650273121, -7.040012319046311],
            [110.318036739616417, -7.039632124354531],
            [110.317887746467648, -7.039360547489598],
            [110.31776188802894, -7.039134230934674],
            [110.317543182262497, -7.038744963152145],
            [110.317462770756691, -7.038591075395225],
            [110.317353592580787, -7.038373828410823],
            [110.317250908752882, -7.038165636205099],
            [110.317134940356226, -7.037948379279336],
            [110.316995824420729, -7.037694907266549],
            [110.316919604348698, -7.037577206848761],
            [110.3167855020574, -7.037378014039871],
            [110.316606532602393, -7.037097347803248],
            [110.316543597331872, -7.036988712056182],
            [110.316467417177606, -7.036843875676341],
            [110.316351436198147, -7.036635663913246],
            [110.316241949527893, -7.036436506936377],
            [110.316142608798316, -7.036264500725882],
            [110.315918273406382, -7.035857133527436],
            [110.315808773837531, -7.035667021779664],
            [110.315524589928415, -7.035169113772726],
            [110.315414511549847, -7.034988046432108],
            [110.315309512767982, -7.034816031820323],
            [110.315174359682473, -7.034562565210032],
            [110.315102490538962, -7.034372508375567],
            [110.314992934034123, -7.034028625966836],
            [110.314932408777707, -7.033820495069818],
            [110.314904354319012, -7.033657638270092],
            [110.31483290964259, -7.033178131792189],
            [110.314755956810515, -7.032789070449883],
            [110.314693367213053, -7.032445256715874],
            [110.314644765064756, -7.032210007283802],
            [110.314611781606189, -7.032128551143007],
            [110.314525708998616, -7.031784703036387],
            [110.314465652739756, -7.0314499383133],
            [110.314426011668104, -7.031278019222495],
            [110.314354006176217, -7.030988463604879],
            [110.314293627370915, -7.030680834357984],
            [110.314270356484528, -7.03053607517896],
            [110.314200574797852, -7.030273658763398],
            [110.314135523371078, -7.030065521185782],
            [110.314023894936511, -7.029784952858389],
            [110.313918437969818, -7.029540574828776],
            [110.313769582696452, -7.029178544077798],
            [110.313606687424837, -7.02874413017676],
            [110.313513113825593, -7.02849976949271],
            [110.313387485726281, -7.028119682048449],
            [110.313324658466485, -7.027938683618622],
            [110.313206050693225, -7.027594787707253],
            [110.313129103770478, -7.027395677933662],
            [110.313028457462806, -7.027151306824607],
            [110.312874807018858, -7.026780223545411],
            [110.312807386850324, -7.026644444933967],
            [110.312742190272886, -7.026535805531784],
            [110.312576807370363, -7.026255158171963],
            [110.312487049482215, -7.026110301453956],
            [110.312405251503918, -7.025983352048732],
            [110.312209095715218, -7.025715102955398],
            [110.311887140415223, -7.025291136054268],
            [110.311703013469, -7.024987531680972],
            [110.311565265216842, -7.024770241842274],
            [110.311448776386555, -7.024525847239977],
            [110.311377732118359, -7.024353881891298],
            [110.311239927522678, -7.023982821514276],
            [110.311106689846341, -7.02358463187011],
            [110.31094698065084, -7.023294947111771],
            [110.310939022030993, -7.023280507806763],
            [110.310742406563421, -7.022923788536585],
            [110.310529075462767, -7.022543571709114],
            [110.310352551397202, -7.022145318296793],
            [110.310204116963021, -7.021692834251294],
            [110.310044985013036, -7.021204153182087],
            [110.309902169561354, -7.0207788132906],
            [110.309786344006383, -7.020471102180196],
            [110.309681856825463, -7.020269134246653],
            [110.309454231811571, -7.019909681091058],
            [110.309816046956186, -7.019731840997472],
            [110.310582630775784, -7.019463260279822],
            [110.311150568353597, -7.01929751019185],
            [110.31161320186817, -7.019201014987496],
            [110.312838231905971, -7.018982681302297],
            [110.313747443965539, -7.018908652235521],
            [110.315083050640581, -7.018882591508242],
            [110.315549834004329, -7.018934041095089],
            [110.316162394055482, -7.0192014690502],
            [110.316713013118616, -7.019476738666634],
            [110.316820906011898, -7.0195371834313],
            [110.317082818888878, -7.019605784944391],
            [110.317951122469836, -7.0198180547419],
            [110.318652707774987, -7.020018975312976],
            [110.319487548436072, -7.020312106249026],
            [110.320649771465654, -7.020151568943289],
            [110.321221568195355, -7.020061171648083],
            [110.321742456893972, -7.01948681169351],
            [110.321865511924088, -7.019494922139981],
            [110.323217049696282, -7.019641643304776],
            [110.324703559309526, -7.019792521222213],
            [110.326368960900766, -7.019773100407865],
            [110.328326181489274, -7.019732276813671],
            [110.330330298364316, -7.020211097879844],
            [110.331967308444163, -7.020616011527825],
            [110.332058718095183, -7.020544748164099],
            [110.332931859980391, -7.020712575278653],
            [110.332935875084047, -7.020680850533634],
            [110.333685950511594, -7.020848501299835],
            [110.334332803264346, -7.021019970772589],
            [110.334820876962013, -7.021179315367672],
            [110.334644915838638, -7.022083384045174],
            [110.335168753835546, -7.022218981263856],
            [110.335791675238397, -7.022291228176072],
            [110.335570406857912, -7.022805846778096],
            [110.335425961247736, -7.023113184418008],
            [110.335323786962277, -7.023339173654248],
            [110.335253055875768, -7.023538071344299],
            [110.335118932264351, -7.023935086098586],
            [110.335033501310946, -7.024193322972764],
            [110.334978606360323, -7.02451279317896],
            [110.334940704811117, -7.024845885259484],
            [110.334909617980017, -7.025172188099533],
            [110.334871788755805, -7.025454288551458],
            [110.334833795185475, -7.025851969959534],
            [110.334795845011399, -7.02621905639078],
            [110.334750963938134, -7.026674518591125],
            [110.3347131295791, -7.026960018431868],
            [110.334671926415353, -7.027221717352999],
            [110.334654642792472, -7.027412061836674],
            [110.334610032113929, -7.02767715535024],
            [110.334479640972916, -7.02844524513444],
            [110.334349321855001, -7.029162343226511],
            [110.33421906030766, -7.029838647952755],
            [110.333976183129991, -7.030745954829057],
            [110.333846036627079, -7.03134067278501],
            [110.333772846951391, -7.03164061043154],
            [110.333726242894187, -7.031833422021366],
            [110.333674893634296, -7.032050913488632],
            [110.333613384919843, -7.032234396037423],
            [110.333541658605753, -7.032424662924862],
            [110.333503261885724, -7.032599034518545],
            [110.333480395262512, -7.032761818354319],
            [110.333438727022852, -7.033022818801103],
            [110.333402201387344, -7.033232065476778],
            [110.33331807952473, -7.033494260992931],
            [110.333213804873992, -7.03380165455538],
            [110.333123072095148, -7.034136203421503],
            [110.333032120771918, -7.034425525178692],
            [110.332902561394832, -7.034805245425698],
            [110.332733274960674, -7.035266317163894],
            [110.332545610425015, -7.035718317249786],
            [110.332422442732693, -7.035980456823367],
            [110.332254223854619, -7.036287758845289],
            [110.332013701502916, -7.036703501815808],
            [110.331777370959841, -7.037155431997251],
            [110.331524847968595, -7.037652565626652],
            [110.331375301492173, -7.037959893868728],
            [110.331135831426991, -7.038429909796085],
            [110.330844518968419, -7.038945078156087],
            [110.330723947602593, -7.039171039405144],
            [110.330500190890461, -7.039731530800179],
            [110.330448476200303, -7.039885227686226],
            [110.3303318006122, -7.040156421087861],
            [110.330107593774642, -7.040635503434585],
            [110.329949069189709, -7.040888546083186],
            [110.329857224317848, -7.041033140045893],
            [110.329839276881742, -7.041060233657681],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 107,
        id: 109,
        area: 3050766.362677,
        perimeter: 9270.329728000001,
        kode_pos: 50275,
        kode_kec: 3374010,
        kode_kel: 3374010014,
        kec: "Mijen",
        kel: "Jatisari",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.311615123121086, -7.061105326838313],
            [110.311529169979735, -7.061331332585878],
            [110.311494489887934, -7.061439825042839],
            [110.311389992628321, -7.061882890647527],
            [110.311292920614989, -7.062470692002768],
            [110.311235998309527, -7.062886691853246],
            [110.311202667679098, -7.06323036410696],
            [110.311213731299759, -7.063402241181794],
            [110.311276589792328, -7.063755100784499],
            [110.311365575241368, -7.064234633190026],
            [110.311407602721744, -7.064515099607068],
            [110.311454455656005, -7.064976479181779],
            [110.311486000689882, -7.06526597541635],
            [110.311583218813524, -7.065727429352152],
            [110.311617471075451, -7.065908385959211],
            [110.31167530383631, -7.066216011603292],
            [110.311738162858191, -7.066568871172238],
            [110.311787723263521, -7.066921711103362],
            [110.31179818101208, -7.06712072317657],
            [110.311792523934542, -7.067310666155369],
            [110.311765995373364, -7.067455351809047],
            [110.311664890011457, -7.067708470946263],
            [110.31160132152398, -7.067835011278408],
            [110.311434437330135, -7.068178486190701],
            [110.311085887654656, -7.068910640397988],
            [110.311005261298178, -7.069091427190831],
            [110.310887043931942, -7.069434973757269],
            [110.310826803365742, -7.069606745347125],
            [110.310743563159704, -7.069832754695042],
            [110.3105792924555, -7.070320957778356],
            [110.310483427669737, -7.070664537239466],
            [110.310345120937512, -7.071198005081621],
            [110.310173574043091, -7.071821876586951],
            [110.310087881463701, -7.072174516246953],
            [110.310071295619977, -7.072292080534512],
            [110.310061514086513, -7.072400609602248],
            [110.310064290950521, -7.072626746121212],
            [110.31007376472931, -7.072916209630848],
            [110.310081675036542, -7.073115217861682],
            [110.310088387389669, -7.073549402011066],
            [110.310090099535373, -7.073730310464477],
            [110.310093348276126, -7.074019764741816],
            [110.31008734005151, -7.074254933528157],
            [110.31007371982129, -7.0746619516477],
            [110.310062321962008, -7.07490615773529],
            [110.310005410286507, -7.075503062883288],
            [110.309978988791386, -7.07576533727948],
            [110.309949144067886, -7.075855745995075],
            [110.309853384780752, -7.076126962893689],
            [110.309808476131877, -7.076262575734981],
            [110.309711328755483, -7.076515699937845],
            [110.309585804941008, -7.076823053785929],
            [110.309438412588278, -7.077184646899063],
            [110.309304022560227, -7.077555304524774],
            [110.30913493660006, -7.078043499389105],
            [110.309018129765732, -7.078387047095974],
            [110.308961457833846, -7.078631185836207],
            [110.308900784039892, -7.078902454493531],
            [110.308792061378568, -7.079327421721135],
            [110.30873557787325, -7.079634877719178],
            [110.308707541623235, -7.079797651307685],
            [110.308715417117881, -7.079951432953113],
            [110.308601523235637, -7.079969354318018],
            [110.30850391601227, -7.079960163986239],
            [110.308337805889309, -7.079968962395205],
            [110.307922355396954, -7.08001357111842],
            [110.307786791299776, -7.080031460048028],
            [110.307680371924164, -7.080049392301304],
            [110.307562634201346, -7.080067307686549],
            [110.307449140850309, -7.080085229358306],
            [110.307365371632244, -7.080094149959957],
            [110.307314425602712, -7.080103119409864],
            [110.307254680867956, -7.080130166330894],
            [110.307132065337953, -7.080193300780793],
            [110.307068049207089, -7.080238431890615],
            [110.306992984177697, -7.080292591821966],
            [110.306896346763111, -7.080391946042261],
            [110.306830902286251, -7.080446120273751],
            [110.30670317985836, -7.080518292275005],
            [110.306592461937186, -7.080572399006073],
            [110.306261170422161, -7.080725675025225],
            [110.306127815697522, -7.080779747882145],
            [110.305967566328121, -7.080842825865116],
            [110.305677101703353, -7.080941890620418],
            [110.305491130023768, -7.080986839470882],
            [110.305353584901269, -7.081004724697273],
            [110.305264425756619, -7.081022682137021],
            [110.305109098857102, -7.081037004321654],
            [110.304491239298073, -7.081181039132892],
            [110.304297760910927, -7.08118074985242],
            [110.304114277260311, -7.081156642479902],
            [110.304025724657464, -7.081147464736892],
            [110.303921339919071, -7.081129218008647],
            [110.303777879728969, -7.081129003346708],
            [110.303594481629318, -7.081155864703207],
            [110.30331178772019, -7.081164406929729],
            [110.302743814949224, -7.081172705742708],
            [110.302615013696723, -7.081063944744423],
            [110.302475678062507, -7.080955192519369],
            [110.30182750652736, -7.080520046976189],
            [110.301528322896985, -7.080368281353977],
            [110.301396433938365, -7.080335610642389],
            [110.301270391460491, -7.080311169304302],
            [110.301112636876084, -7.080220479613256],
            [110.301018346683264, -7.080075613555922],
            [110.300993572954596, -7.079935782340639],
            [110.300984253788911, -7.079775314971375],
            [110.301003570704566, -7.079645453164231],
            [110.301034311560855, -7.079548081220389],
            [110.30115110725103, -7.07944510803754],
            [110.301246883743318, -7.079332552462677],
            [110.301356373299839, -7.07920777459513],
            [110.301501468157525, -7.079153379675981],
            [110.301642835249538, -7.079135842342113],
            [110.302193670510832, -7.079148687646335],
            [110.30241352616251, -7.079175759497508],
            [110.302566436942115, -7.079216102042264],
            [110.302694469879413, -7.079269778381883],
            [110.302845474319227, -7.079306297663712],
            [110.303009889559874, -7.07932564554491],
            [110.30315136775387, -7.079339228542614],
            [110.303208780638428, -7.079306841790327],
            [110.303247072130603, -7.079274426401428],
            [110.303275863106251, -7.079201883422534],
            [110.303289226156579, -7.079109389750641],
            [110.303236846315002, -7.0788759185106],
            [110.303132452664642, -7.078486815123085],
            [110.303038408485222, -7.077989183848088],
            [110.302954738841549, -7.077554885066315],
            [110.30292106549112, -7.077354227323483],
            [110.302918551707634, -7.077156838508072],
            [110.302898956918483, -7.076796427451371],
            [110.302880435963729, -7.076606540042427],
            [110.302822964839123, -7.076467766103378],
            [110.302746334788395, -7.076333460013956],
            [110.302685566947247, -7.076284184950259],
            [110.302601459212369, -7.076266867523967],
            [110.302370126411077, -7.076243599011728],
            [110.302234399917836, -7.076220473688488],
            [110.302171374372065, -7.076170715065153],
            [110.30207971861357, -7.076086530638069],
            [110.302037717865787, -7.076042533996441],
            [110.302003431625664, -7.075954615240252],
            [110.301988477314822, -7.075834833969003],
            [110.30198483265724, -7.075606938255982],
            [110.301968229027622, -7.07520386962197],
            [110.301931789551872, -7.074957356822436],
            [110.301906176525733, -7.074866865622807],
            [110.301878970590082, -7.074796871742127],
            [110.301821698842318, -7.074735660757506],
            [110.301708986543673, -7.074658528519858],
            [110.301600440621471, -7.074586003497374],
            [110.301503280903972, -7.074468269134445],
            [110.301474920009667, -7.074323502121197],
            [110.301501158305385, -7.073997911411852],
            [110.301549750006345, -7.073747700293425],
            [110.301611267772245, -7.073528124159066],
            [110.301694129428967, -7.073247442550808],
            [110.301794621250522, -7.073030506475365],
            [110.301860823850319, -7.072875223577319],
            [110.301889266034792, -7.072750244637541],
            [110.301883742807718, -7.072659783550409],
            [110.301837972239738, -7.072614488543739],
            [110.301731247896129, -7.072577044374942],
            [110.301601264576249, -7.072550107259428],
            [110.301513313251434, -7.072546155059699],
            [110.301413326803228, -7.072568625459837],
            [110.301241681643859, -7.07262024388769],
            [110.30113457187943, -7.072641094951929],
            [110.301006457617561, -7.072642812872722],
            [110.300898256065594, -7.072631169637699],
            [110.300786949224275, -7.072513413939012],
            [110.300730010320265, -7.072368604002777],
            [110.300730173597586, -7.072260060901399],
            [110.300798017130219, -7.072115438276949],
            [110.300879587249952, -7.071817433458575],
            [110.300920201066006, -7.071511868840684],
            [110.300926224602904, -7.071320861897424],
            [110.300913106982009, -7.071143197349055],
            [110.300901809559704, -7.071026660646335],
            [110.300873124713746, -7.070903483561314],
            [110.30082753050614, -7.07083171287878],
            [110.300751132576835, -7.070774293430141],
            [110.300661370062429, -7.070703482792107],
            [110.300618094765667, -7.070586516021906],
            [110.300602425313343, -7.070483725939735],
            [110.300612196338307, -7.070369420463437],
            [110.300633850354913, -7.070269954892467],
            [110.300673701651618, -7.070132363520762],
            [110.300721679369076, -7.070015915775238],
            [110.300762017281301, -7.069893726077844],
            [110.300811901301458, -7.069781101497725],
            [110.300873249268065, -7.069674224589113],
            [110.30094032488347, -7.069573086741615],
            [110.301007452658297, -7.06944739499906],
            [110.301054234845566, -7.06938414820583],
            [110.301151160826294, -7.06923721481058],
            [110.301218221962472, -7.069145627688149],
            [110.301296807299849, -7.069019674948612],
            [110.301365866549844, -7.068870785988017],
            [110.301390918890775, -7.068740932648808],
            [110.30139496400399, -7.068593856374832],
            [110.301398841050215, -7.068462046242968],
            [110.301378079290473, -7.06834442646097],
            [110.301322895523711, -7.068200255333374],
            [110.301265710666783, -7.068081739672469],
            [110.30116048124853, -7.067973243704191],
            [110.301116934009244, -7.067955087846449],
            [110.301079328739505, -7.067936940895158],
            [110.300970743616887, -7.067891551650714],
            [110.300925485095803, -7.067882438490908],
            [110.30078631413744, -7.067855093892557],
            [110.300723216134543, -7.06785499923227],
            [110.300558552440719, -7.067845706881581],
            [110.300526579015326, -7.067845658900217],
            [110.30047423314241, -7.067845580341922],
            [110.300414544156823, -7.067836445476258],
            [110.300352363038655, -7.067791125742789],
            [110.300287352427077, -7.06774580175451],
            [110.300173350557145, -7.067727540042877],
            [110.30013854763682, -7.067727487787375],
            [110.300050160934376, -7.067720805747954],
            [110.299939902727644, -7.067736234757624],
            [110.299816837124865, -7.067752928023006],
            [110.299715473367698, -7.067768057020254],
            [110.299623681558899, -7.067775559739546],
            [110.299547206600977, -7.067769714341702],
            [110.299455446436582, -7.067756205320254],
            [110.299371374916063, -7.06771596560553],
            [110.299300719685959, -7.067654734291529],
            [110.299243428953417, -7.067606894177037],
            [110.299209094807026, -7.067551447933753],
            [110.29919583392811, -7.067469291140003],
            [110.299196023853668, -7.067343220901372],
            [110.299250672027767, -7.067147256002317],
            [110.299268068235207, -7.067056829372451],
            [110.299286545096848, -7.066909750776482],
            [110.299328826755016, -7.066766552364128],
            [110.299382508977757, -7.066671125068403],
            [110.299438097509196, -7.06657952094795],
            [110.29951224973685, -7.066496389091477],
            [110.299579714342713, -7.066415082947993],
            [110.299700550526893, -7.066249457645933],
            [110.299775285644756, -7.066140690794652],
            [110.299832791861434, -7.06604526917205],
            [110.299920945977632, -7.065913600524232],
            [110.300014899506536, -7.065739917043124],
            [110.300089645955325, -7.06562350950125],
            [110.300221783509727, -7.065488086482874],
            [110.30032900363102, -7.065392739389786],
            [110.300453446785625, -7.065287867307243],
            [110.300604687706738, -7.065163933780393],
            [110.300704305288974, -7.065038012617411],
            [110.300767576105301, -7.0649234978858],
            [110.300821303718109, -7.064797507870833],
            [110.300827730921071, -7.064700285904808],
            [110.300797068169246, -7.06465308044064],
            [110.300749089203492, -7.064571600959286],
            [110.30066912346544, -7.064499118786791],
            [110.300582366986347, -7.064426626417604],
            [110.300514085077566, -7.06438063219066],
            [110.300416678310057, -7.064307899982721],
            [110.300275345533791, -7.064200718966259],
            [110.300189368209416, -7.064156656268578],
            [110.300081727576554, -7.064118335711572],
            [110.299993232760784, -7.064072976480228],
            [110.299887195168509, -7.064027590897464],
            [110.299824406154414, -7.063993744846022],
            [110.299757578396736, -7.063930609227218],
            [110.299665945436601, -7.063833053471964],
            [110.299559136665152, -7.06365524819359],
            [110.299458067061877, -7.063475541358187],
            [110.29934939237728, -7.063267170684484],
            [110.299286492446555, -7.063135275159135],
            [110.299212212153776, -7.062942237414804],
            [110.299124544557984, -7.062751089706699],
            [110.299027316687997, -7.062559927620345],
            [110.298933956176356, -7.062340118936053],
            [110.298884392028086, -7.06224071613335],
            [110.298825296338435, -7.062122197402362],
            [110.298777661561772, -7.062011336533649],
            [110.298731941748173, -7.061898568380852],
            [110.298669062289775, -7.061753301712415],
            [110.298587010427639, -7.061642389086203],
            [110.298464811024942, -7.06152759575722],
            [110.298380763708437, -7.061472074730306],
            [110.298289053766126, -7.061426092962181],
            [110.298233641366707, -7.061401177535551],
            [110.29817246975027, -7.06139153471505],
            [110.298104332231958, -7.061401780421241],
            [110.29802082207172, -7.061428790602259],
            [110.297923474798267, -7.06143768938794],
            [110.297759945302502, -7.06142839797879],
            [110.297701361713322, -7.061437355066862],
            [110.297530138082166, -7.06146423310255],
            [110.297436184367285, -7.061484024266966],
            [110.297332918555213, -7.061493419533144],
            [110.29725837693293, -7.061474205659214],
            [110.297191547004189, -7.061412979890777],
            [110.297133638131939, -7.061337002018953],
            [110.29707132545208, -7.061192183714381],
            [110.297064575706258, -7.061165037715723],
            [110.297036768859243, -7.061029316674693],
            [110.297019901332831, -7.060956929049502],
            [110.296998816930696, -7.060866444517572],
            [110.296970708190642, -7.060776564169631],
            [110.296938318540526, -7.06070010899839],
            [110.296896420521421, -7.060589256633323],
            [110.296867889623883, -7.060489885364986],
            [110.296850816663877, -7.060400082158665],
            [110.296845213171679, -7.060312206395985],
            [110.296853054653113, -7.060184237553577],
            [110.296849426669993, -7.060054341266923],
            [110.296834346537423, -7.059911056608053],
            [110.296811609534345, -7.059773490887387],
            [110.296773639556719, -7.059593878709175],
            [110.296741287501561, -7.059492591519778],
            [110.296697428518854, -7.059414208895858],
            [110.296628721505272, -7.059330058339432],
            [110.296529372612696, -7.059278334287922],
            [110.296403188625703, -7.059270503414662],
            [110.296284519988063, -7.059273399234778],
            [110.296165911273008, -7.059309401457864],
            [110.2960999303547, -7.059345483036607],
            [110.295999133488294, -7.059390557356372],
            [110.295924970262973, -7.05940540340991],
            [110.295812658895088, -7.059399321270063],
            [110.295704007922467, -7.059399157298276],
            [110.295508194567716, -7.059370391535233],
            [110.295395418093705, -7.059347299360936],
            [110.295322817872275, -7.059308986562974],
            [110.295244507519428, -7.059253473704404],
            [110.295149023402857, -7.059175012992982],
            [110.295099393878516, -7.059119543432834],
            [110.295051725568598, -7.059031604136986],
            [110.295018550363849, -7.058945858594567],
            [110.294974251240987, -7.058864384213913],
            [110.294919276121902, -7.058800774222223],
            [110.29512986626834, -7.058493763066808],
            [110.295273524490753, -7.058358300906327],
            [110.295405340008145, -7.058195684991661],
            [110.295573103049009, -7.058024078011037],
            [110.295788359585771, -7.057879678441254],
            [110.296003967287888, -7.057690052910332],
            [110.29621996708201, -7.057428065644151],
            [110.296303868825035, -7.05732869410185],
            [110.296351741013311, -7.057292585164187],
            [110.296351836648682, -7.057229268373899],
            [110.296310138559647, -7.057111616922903],
            [110.296268873727996, -7.056894468083362],
            [110.29623938723401, -7.056559748405671],
            [110.296204027263542, -7.056179793488691],
            [110.296151179428591, -7.0557003141628],
            [110.296447516493984, -7.055700760810145],
            [110.297498987487913, -7.055666162981921],
            [110.297959080215094, -7.055648764473982],
            [110.29820894622307, -7.055631049560018],
            [110.29831874176881, -7.055622169302562],
            [110.298646686451804, -7.05561361676934],
            [110.298917193689888, -7.05560497776001],
            [110.29916449959596, -7.055596303766732],
            [110.299346149094873, -7.055596576399703],
            [110.299605311432103, -7.055606010530449],
            [110.299776774997426, -7.055606267715933],
            [110.29998441453256, -7.055633714930497],
            [110.300206794318115, -7.0556430935802],
            [110.300366643665228, -7.055652378426839],
            [110.300523070548707, -7.055679748661583],
            [110.300685129171441, -7.055725217851333],
            [110.300922706564094, -7.055788890642137],
            [110.301055635674373, -7.055825270806693],
            [110.301201324393517, -7.055843579461529],
            [110.301408964179444, -7.055871026060873],
            [110.301561470697308, -7.055871254234157],
            [110.301657105584937, -7.055871397293293],
            [110.3017108783976, -7.055862432435837],
            [110.301762118272137, -7.055844418496744],
            [110.301866026280237, -7.055799347448906],
            [110.302000222764136, -7.055745276371394],
            [110.302251639046915, -7.055637108720485],
            [110.302734611128415, -7.055456924474449],
            [110.302907314777798, -7.055384820041545],
            [110.302973833499081, -7.055366828770645],
            [110.303060171770042, -7.055339821784553],
            [110.303121004562669, -7.055339912583903],
            [110.303218916444123, -7.055331013421033],
            [110.303557895957297, -7.055322473876155],
            [110.30393760579986, -7.055323040097251],
            [110.304261818275279, -7.055350659192533],
            [110.304308490415792, -7.055359774028355],
            [110.304309554668706, -7.055405002074317],
            [110.304240043794124, -7.055721483712013],
            [110.304336244592818, -7.055721627062665],
            [110.30449525885858, -7.055721863969408],
            [110.304839587663025, -7.055731422073551],
            [110.30487098090498, -7.055740514108109],
            [110.304895812472324, -7.055785777543522],
            [110.304903896396326, -7.055867197215938],
            [110.304911939841318, -7.055975752706795],
            [110.30496455347776, -7.056174827483023],
            [110.304987646932844, -7.056247224207138],
            [110.305056981676884, -7.056238282129546],
            [110.305398845326096, -7.056193564426612],
            [110.305506660427682, -7.05618467953153],
            [110.305810528688568, -7.056194176763018],
            [110.306022979148423, -7.056221628503891],
            [110.306376052794491, -7.056249289103291],
            [110.306375931603185, -7.05633069658328],
            [110.306429906938178, -7.056755905666809],
            [110.30646155647878, -7.056973039780923],
            [110.306498366542485, -7.057334906287402],
            [110.30675823086986, -7.057253884636988],
            [110.306888749079874, -7.057199806680098],
            [110.307141526216824, -7.057127819575324],
            [110.307330330928863, -7.057073827997162],
            [110.307437324302171, -7.0570468508586],
            [110.307448790563313, -7.057137320842897],
            [110.307502631874456, -7.057463031422913],
            [110.307584727994282, -7.058005871048724],
            [110.307636575380769, -7.05834062395907],
            [110.307662418122973, -7.058467296455855],
            [110.307662377788134, -7.058494432287396],
            [110.307640537209693, -7.058530581075503],
            [110.307542503616233, -7.058620888599424],
            [110.307465987294691, -7.058702182732653],
            [110.307415825235395, -7.058756380072613],
            [110.307404723106089, -7.058801590079085],
            [110.307400263709724, -7.058946308204765],
            [110.307399994687572, -7.05912721373399],
            [110.307417079516455, -7.059244827943396],
            [110.307477145231616, -7.059380596532542],
            [110.307605697365517, -7.059507421444671],
            [110.307732026338016, -7.059607107138793],
            [110.307829319463295, -7.059634387358082],
            [110.307987432580887, -7.059670803055777],
            [110.308118059633486, -7.059734313840959],
            [110.308241343558436, -7.059788768409056],
            [110.30831261914021, -7.059806964664449],
            [110.308383369136521, -7.059798024236576],
            [110.308473642422214, -7.059789112731434],
            [110.308678306557681, -7.059726098907018],
            [110.308741430418337, -7.059708101825838],
            [110.30881358170366, -7.05970820870512],
            [110.308983282329805, -7.059753686537937],
            [110.309073475086521, -7.05979904659108],
            [110.30923122494805, -7.059889733140867],
            [110.309360167999102, -7.059944195807058],
            [110.309420368513415, -7.059989511406402],
            [110.309464710625249, -7.060043848830058],
            [110.309560644198314, -7.060224896794531],
            [110.30964916758667, -7.060442114981317],
            [110.309803255656405, -7.060903653259075],
            [110.309907380155366, -7.060903807285994],
            [110.310003002841967, -7.060912994016629],
            [110.310241783335997, -7.060931437700144],
            [110.310469219187439, -7.060967955099323],
            [110.310610652554033, -7.060995300014913],
            [110.310989748154611, -7.061032041243486],
            [110.311435606007393, -7.061077926012161],
            [110.311615123121086, -7.061105326838313],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 108,
        id: 110,
        area: 1567036.612525,
        perimeter: 6063.153371,
        kode_pos: 50215,
        kode_kec: 3374010,
        kode_kel: 3374010006,
        kec: "Mijen",
        kel: "Tambangan",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.308707541623235, -7.079797651307685],
            [110.308815682238489, -7.079806857240033],
            [110.309096134442981, -7.0797801378559],
            [110.30935454277828, -7.079735294998256],
            [110.309624013350799, -7.079672377819039],
            [110.309871534092494, -7.079528020285529],
            [110.310044367145252, -7.079374506582043],
            [110.310168733620856, -7.079275192724103],
            [110.31050425397747, -7.079130965250833],
            [110.310740941232581, -7.079040862917259],
            [110.311032508850317, -7.07895988699956],
            [110.311160446145877, -7.078932940497037],
            [110.311292790036063, -7.078987408143592],
            [110.311420606630094, -7.079041869056695],
            [110.311439672112328, -7.078969534895412],
            [110.311487060212343, -7.078879152042231],
            [110.31154405545594, -7.078797828690633],
            [110.311818564929808, -7.078771098800206],
            [110.311974744346557, -7.078780375014583],
            [110.312182988051759, -7.078789728127158],
            [110.312359244122462, -7.078808079183236],
            [110.312444414496724, -7.078808205018939],
            [110.312511515725376, -7.078781168252134],
            [110.312903573214854, -7.078673203651668],
            [110.313039486710764, -7.078610087192249],
            [110.313204827798714, -7.078547014116087],
            [110.313472100548054, -7.078438864845165],
            [110.313726020706341, -7.07836687692316],
            [110.314068546676367, -7.078267883543801],
            [110.314188640997997, -7.078186652804147],
            [110.314268501864035, -7.078141543962365],
            [110.314328528968147, -7.078114496491161],
            [110.314504312479059, -7.078069528906567],
            [110.314768082467069, -7.078033736098339],
            [110.315043683297517, -7.078034141760684],
            [110.315314823487597, -7.077989314178853],
            [110.315471325774908, -7.077971453764144],
            [110.315558207126571, -7.077962536227453],
            [110.315756344163319, -7.077917601023104],
            [110.315888215780703, -7.077908749559841],
            [110.315968266173115, -7.077926957819886],
            [110.316089924680085, -7.077936181900505],
            [110.316221769674087, -7.077945420914588],
            [110.316246117356386, -7.077936411376924],
            [110.316291443854112, -7.077900296737549],
            [110.316378311878751, -7.077900424331237],
            [110.316423315470928, -7.07789144512121],
            [110.316482506879893, -7.077855350823565],
            [110.316527833352936, -7.077819236158522],
            [110.316562650458621, -7.077810241976136],
            [110.316610200659269, -7.077801266486052],
            [110.316677221712681, -7.077828500801525],
            [110.31673966222904, -7.077891909610186],
            [110.316826357308813, -7.078009625852664],
            [110.316881697316333, -7.07809111483721],
            [110.316951171937419, -7.078181669867035],
            [110.317035400302458, -7.078245110617715],
            [110.317147694729599, -7.078272411304924],
            [110.31732110810789, -7.078299801618575],
            [110.317413003004532, -7.078345162937413],
            [110.317440936096787, -7.078399475748839],
            [110.317451016246878, -7.078471852992632],
            [110.317448177177013, -7.078670845595203],
            [110.317448124004017, -7.078707026747255],
            [110.317469885206819, -7.078725149276671],
            [110.317593241738763, -7.078734375476713],
            [110.317636534361554, -7.078734438954138],
            [110.317723172760125, -7.078698384742816],
            [110.317778632587903, -7.078698466043683],
            [110.317920584671114, -7.078761991265559],
            [110.318043362034629, -7.07878026180776],
            [110.318096301812076, -7.078762248761643],
            [110.318149564383319, -7.078717100255686],
            [110.318237644095134, -7.078662957438194],
            [110.318370431196556, -7.078608880082212],
            [110.318549636696844, -7.078545825343669],
            [110.318648159250401, -7.078509788350312],
            [110.318722603805085, -7.078491806709504],
            [110.318776675362571, -7.07847379523689],
            [110.31880103630327, -7.078455740273009],
            [110.318831971609868, -7.078392468378965],
            [110.318879345009265, -7.078311129917837],
            [110.318915965983777, -7.078229775715683],
            [110.318957963147156, -7.078148429375826],
            [110.318977593351036, -7.078076095614766],
            [110.319022406805914, -7.078003798697586],
            [110.319078215824618, -7.077958653792821],
            [110.319173059766044, -7.077922611290282],
            [110.319268160139615, -7.077904659764306],
            [110.319377125445044, -7.077886728492848],
            [110.319507056132409, -7.077850737223729],
            [110.319588950213088, -7.077769449139444],
            [110.319639705658233, -7.077697160836053],
            [110.319744678223813, -7.077507362705734],
            [110.319848735605959, -7.077362789767051],
            [110.319931248422321, -7.077245321260795],
            [110.319998415288154, -7.077173056882234],
            [110.320041504268644, -7.07711884794767],
            [110.320087179572155, -7.0770375068467],
            [110.32011780516531, -7.076992325011785],
            [110.320179003386187, -7.076938142509379],
            [110.32026450945483, -7.076902086118478],
            [110.320304432911115, -7.076884053785227],
            [110.320356200873348, -7.076893174681636],
            [110.320490513006249, -7.076956687951358],
            [110.320603360098289, -7.076993033916647],
            [110.320710278330168, -7.077020325890434],
            [110.320835319079777, -7.07703859896721],
            [110.320960616356288, -7.077074963014622],
            [110.321059302427187, -7.077120333532811],
            [110.321126889623486, -7.07714756804927],
            [110.321229293827756, -7.077165808011665],
            [110.321350669415366, -7.077175030295812],
            [110.321436431991188, -7.077157064690276],
            [110.321476625180139, -7.077148077963116],
            [110.321497590464077, -7.077130017891089],
            [110.321490595794003, -7.077075735802697],
            [110.321495472128859, -7.077030516332596],
            [110.321520991147921, -7.076994372264937],
            [110.321579359648425, -7.076940185438782],
            [110.321657035279898, -7.076840800158761],
            [110.321698709260104, -7.076786588987066],
            [110.321722504092335, -7.076768533025134],
            [110.321759058414997, -7.07673240501944],
            [110.321805463419636, -7.076732472626067],
            [110.321872214947092, -7.076750660499766],
            [110.321955108252993, -7.076759826558951],
            [110.322071120777025, -7.076759995518641],
            [110.322204933225265, -7.076778280999981],
            [110.322271711171709, -7.076778378222774],
            [110.322384624370017, -7.07676949727536],
            [110.322464161784893, -7.076751522408033],
            [110.32254737764093, -7.076733552879552],
            [110.322655803075406, -7.076697529380668],
            [110.322733925684787, -7.076679552406515],
            [110.322811725776916, -7.076688710903779],
            [110.322880161912039, -7.076715946404785],
            [110.32293047559827, -7.076752200857467],
            [110.322980749746762, -7.07681559120293],
            [110.323046277252445, -7.076897094362564],
            [110.323091767513318, -7.076942387104792],
            [110.323134467745106, -7.07696053983086],
            [110.323156525256294, -7.076969617221073],
            [110.323189940606099, -7.076951575167709],
            [110.323222790038372, -7.076933532288944],
            [110.323281375428465, -7.076924572139492],
            [110.323341336079181, -7.076942749938353],
            [110.323468331336585, -7.076979115794876],
            [110.323573012477027, -7.07698831323657],
            [110.32362649146495, -7.076988390943098],
            [110.323716215363987, -7.076970430661503],
            [110.323833978391178, -7.076934420450462],
            [110.323914364675147, -7.076916446565817],
            [110.3240199210166, -7.076907554537222],
            [110.324130287635199, -7.076898669468235],
            [110.324230750736561, -7.076889769996306],
            [110.324296113803072, -7.076889958434083],
            [110.324276833352414, -7.076916972852024],
            [110.324264034474126, -7.076962180876651],
            [110.324256611788456, -7.077007396704834],
            [110.324233639586296, -7.077043544639482],
            [110.324184918229804, -7.077079655190945],
            [110.324136183705903, -7.077124811037756],
            [110.324107526011957, -7.077179041350229],
            [110.324071781204765, -7.077242306689464],
            [110.324037128731504, -7.077332709571328],
            [110.324004213480208, -7.077395979011953],
            [110.324027093580384, -7.077423148198892],
            [110.324062719951499, -7.077441290574239],
            [110.324082770478071, -7.077468455649995],
            [110.324088390151658, -7.077495599770528],
            [110.324084079973403, -7.077540820112182],
            [110.324046933503297, -7.077595038090288],
            [110.324005299186354, -7.077622113587181],
            [110.324039444966076, -7.077685480414972],
            [110.324117909883014, -7.077821274157824],
            [110.324215023766541, -7.077975185608087],
            [110.324249735514272, -7.078038553246337],
            [110.324295160210838, -7.07812907239616],
            [110.324323837744217, -7.078255748511915],
            [110.324319191981175, -7.0783371496494],
            [110.324325015680444, -7.078418565985652],
            [110.324311749563037, -7.078590407808496],
            [110.324320337049599, -7.078717054756975],
            [110.324318777500594, -7.078816551013102],
            [110.324345823108914, -7.078897998158926],
            [110.324367222707224, -7.078970391787012],
            [110.324408297850169, -7.079133267178708],
            [110.324478609902414, -7.079232867768781],
            [110.324531391676658, -7.079323397585583],
            [110.324607119471366, -7.079395870060668],
            [110.324688427550925, -7.079522622546022],
            [110.324705622295539, -7.079567874100875],
            [110.324665271075077, -7.079685404706895],
            [110.324612160347854, -7.079821007428035],
            [110.324566446003629, -7.079929484915617],
            [110.324554778879829, -7.079974694580168],
            [110.324562070040585, -7.080019931763799],
            [110.324573335792991, -7.080056129396719],
            [110.324601914706008, -7.080056170879662],
            [110.32464064069525, -7.080083363048869],
            [110.324666363704168, -7.080101491023208],
            [110.324696304799289, -7.080137715758426],
            [110.324739288349321, -7.080155868779239],
            [110.324785088346061, -7.080183071203732],
            [110.324785009426748, -7.080237343011161],
            [110.32479790719313, -7.080318769610281],
            [110.32481928060254, -7.080409253828531],
            [110.324852077630624, -7.080427392059738],
            [110.324883755974014, -7.08043648334527],
            [110.324947935243401, -7.080472757744676],
            [110.324954956636915, -7.08050894921359],
            [110.324965103739999, -7.080536099896737],
            [110.324980910041461, -7.080563258790026],
            [110.32500210573221, -7.080581380180974],
            [110.325039443287437, -7.080590479667104],
            [110.32507506993673, -7.080608621989262],
            [110.325123673302741, -7.080653919093978],
            [110.325222386882871, -7.080681198229458],
            [110.325288481212993, -7.080762701969693],
            [110.325344040325831, -7.080889417031335],
            [110.325402738313556, -7.080998045997467],
            [110.325476894007608, -7.081179059939226],
            [110.325501136602981, -7.081242412335091],
            [110.325502472557162, -7.081296686199283],
            [110.325499432690137, -7.081441406930915],
            [110.325512330597135, -7.081522833521073],
            [110.325526255217767, -7.08167662416855],
            [110.325519010360225, -7.081794202838638],
            [110.325484226306486, -7.081975058824073],
            [110.325467117227873, -7.082065487224635],
            [110.325435228454779, -7.082201120795236],
            [110.325407597219169, -7.082327715214867],
            [110.325387579622131, -7.082472411313895],
            [110.325384631698867, -7.08255381492334],
            [110.325393067948781, -7.082590008440548],
            [110.325423022392428, -7.082617187844209],
            [110.325439210671632, -7.082770981770389],
            [110.325428135746748, -7.082798101670516],
            [110.32541696879936, -7.082888538679985],
            [110.32543471648809, -7.082942836342081],
            [110.325434637613654, -7.082997108150382],
            [110.325448706809141, -7.083051400477331],
            [110.325481859643531, -7.08321426432529],
            [110.325448443893322, -7.083232306504184],
            [110.325442692639982, -7.083295615405504],
            [110.325442613762519, -7.083349887213072],
            [110.32545355724163, -7.083413220327047],
            [110.32545876261193, -7.08353081704045],
            [110.325450477843333, -7.083585076946097],
            [110.325460302625459, -7.083639363117117],
            [110.325497831374378, -7.083711780108355],
            [110.325528516467642, -7.083820368453686],
            [110.325567729866378, -7.083901833204611],
            [110.325632732250128, -7.083956199388342],
            [110.325722260349295, -7.084073918368109],
            [110.325663891306959, -7.084128105658376],
            [110.325489619838038, -7.084299714008964],
            [110.325326462672606, -7.084417066487848],
            [110.325059879472704, -7.084633767310175],
            [110.324790505889382, -7.084823327947007],
            [110.324727944861735, -7.084841327738365],
            [110.324660020707498, -7.084850274413513],
            [110.324583864308366, -7.084877299757617],
            [110.324401557461925, -7.084931306828389],
            [110.324358224689007, -7.084958379821321],
            [110.324330711604844, -7.085003474190103],
            [110.3242953411537, -7.085003515035315],
            [110.323901470502804, -7.084993897200556],
            [110.323629247396511, -7.085002546627609],
            [110.323390157284607, -7.084993153475171],
            [110.32260550240882, -7.084992011070095],
            [110.322278950567906, -7.085000580556199],
            [110.322157289945167, -7.084991357908532],
            [110.321485267518014, -7.084981332475976],
            [110.321225521314901, -7.084971908073888],
            [110.320790594987727, -7.084980318302001],
            [110.32043007452009, -7.084997882179072],
            [110.320235126748358, -7.084988551920239],
            [110.320062506503021, -7.084997344848988],
            [110.31994112894273, -7.084988122039466],
            [110.31981975139027, -7.084978899198712],
            [110.319492660299858, -7.084969375317187],
            [110.319324863700359, -7.084969129722078],
            [110.318964383121369, -7.084959556592853],
            [110.318642951362989, -7.084950040405023],
            [110.318391101753321, -7.084958716610726],
            [110.318197259388157, -7.084967477736019],
            [110.317922786108312, -7.084967075207745],
            [110.317605585564621, -7.084966609816084],
            [110.317320346451794, -7.084975236437836],
            [110.317136138124894, -7.084974965957626],
            [110.316914821825691, -7.085001776806205],
            [110.316746149780812, -7.085019619600918],
            [110.316539264522518, -7.085046451471127],
            [110.316417550843795, -7.085073408465712],
            [110.316231281803283, -7.085127406410795],
            [110.316062786011344, -7.08521761162971],
            [110.315966781826461, -7.085271742221438],
            [110.31590387086591, -7.085334966783096],
            [110.315878324245261, -7.085389201009096],
            [110.315848669435226, -7.085542927509867],
            [110.315805016053901, -7.085787086424471],
            [110.315781129981588, -7.086058410324306],
            [110.31577284402438, -7.086112669939631],
            [110.315766175799411, -7.086221203744644],
            [110.315761541671165, -7.086293559336519],
            [110.315763711989661, -7.086356879641151],
            [110.315758864401573, -7.086573959740909],
            [110.315758744330566, -7.086655367276538],
            [110.315754070165639, -7.08675485871151],
            [110.315733766311965, -7.08689955364633],
            [110.315720077338426, -7.086971895906148],
            [110.315669645906979, -7.08720699949562],
            [110.315660484324525, -7.087279348415962],
            [110.315644504925487, -7.087369777900053],
            [110.315617193632164, -7.087469235999532],
            [110.315567111769951, -7.08765911357454],
            [110.315533049352084, -7.087731425822179],
            [110.315039070059896, -7.089404078523255],
            [110.31491194340785, -7.089838065463488],
            [110.314875483331903, -7.090000827081234],
            [110.314784934524539, -7.090000693565219],
            [110.314622229640676, -7.090000453609301],
            [110.314314983152528, -7.089963819139473],
            [110.314183025808404, -7.089836990240713],
            [110.314090320365793, -7.089764491032783],
            [110.31401348774304, -7.089673924646871],
            [110.3138964340108, -7.089610434758647],
            [110.313751030300367, -7.089583084165],
            [110.313617780703922, -7.089564796775992],
            [110.313524738885008, -7.089528478155626],
            [110.313415608275477, -7.089464999859803],
            [110.312975543173351, -7.089310579456973],
            [110.312789849972063, -7.089165580178213],
            [110.312442314727704, -7.089011296130549],
            [110.312377812284694, -7.089002155403262],
            [110.312218044794193, -7.088929556633271],
            [110.312141562733558, -7.08879376403185],
            [110.312077059122686, -7.088594672094952],
            [110.312053288941229, -7.088404685740641],
            [110.312053503609221, -7.08825996135634],
            [110.312013214211348, -7.088142312900547],
            [110.311900055562916, -7.088124054801694],
            [110.311764839187546, -7.088096713977251],
            [110.311805653055856, -7.088051552686527],
            [110.311823263905694, -7.08800635229352],
            [110.311830984446104, -7.087952091962753],
            [110.311831038127409, -7.087915910867757],
            [110.311823451792577, -7.087879718461285],
            [110.31180058542823, -7.087843503431402],
            [110.311727391830274, -7.08778007800095],
            [110.311635226278412, -7.087725669758309],
            [110.31159028878929, -7.087689422033788],
            [110.311498689199908, -7.087635014605291],
            [110.311370667933531, -7.087526281446979],
            [110.311251714982248, -7.0874085164024],
            [110.311197021589606, -7.0872727559704],
            [110.31117893791172, -7.087064687441813],
            [110.311179233397752, -7.086865691438446],
            [110.311152135210946, -7.086630473672107],
            [110.311084465932211, -7.086467558128718],
            [110.310900579705347, -7.0862501985791],
            [110.310656355468154, -7.086077975973272],
            [110.310524642864081, -7.085978282453232],
            [110.310378957610084, -7.085950930509645],
            [110.310246369325498, -7.085869326203416],
            [110.310081550302996, -7.085769583455816],
            [110.309935110437749, -7.085678913225168],
            [110.309842985888054, -7.085597368870347],
            [110.309890725314176, -7.085461760365797],
            [110.309925529177178, -7.085271860908628],
            [110.310023634701878, -7.084756424888281],
            [110.310088890123467, -7.084448981792407],
            [110.310113884274188, -7.084195750701802],
            [110.31012284432029, -7.084069129907067],
            [110.310114328003806, -7.083897256726567],
            [110.310080655058968, -7.083707255643236],
            [110.310029195945987, -7.083490092296793],
            [110.309977615843422, -7.083354336395304],
            [110.309857100980992, -7.083146115873484],
            [110.309736599672263, -7.08292885005736],
            [110.309573115417862, -7.082693429836642],
            [110.309400859560228, -7.082457996549173],
            [110.309344402123003, -7.082367459814632],
            [110.309249960463958, -7.082132142015721],
            [110.309181227846238, -7.081923998256434],
            [110.308966931965912, -7.081037241477644],
            [110.308765019604138, -7.080195729495983],
            [110.308715417117881, -7.079951432953113],
            [110.308707541623235, -7.079797651307685],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 109,
        id: 111,
        area: 2561787.5398909999,
        perimeter: 9283.470192000001,
        kode_pos: 50216,
        kode_kec: 3374010,
        kode_kel: 3374010001,
        kec: "Mijen",
        kel: "Cangkiran",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.308715417117881, -7.079951432953113],
            [110.308765019604138, -7.080195729495983],
            [110.308966931965912, -7.081037241477644],
            [110.309181227846238, -7.081923998256434],
            [110.309249960463958, -7.082132142015721],
            [110.309344402123003, -7.082367459814632],
            [110.309400859560228, -7.082457996549173],
            [110.309573115417862, -7.082693429836642],
            [110.309736599672263, -7.08292885005736],
            [110.309857100980992, -7.083146115873484],
            [110.309977615843422, -7.083354336395304],
            [110.310029195945987, -7.083490092296793],
            [110.310080655058968, -7.083707255643236],
            [110.310114328003806, -7.083897256726567],
            [110.31012284432029, -7.084069129907067],
            [110.310113884274188, -7.084195750701802],
            [110.310088890123467, -7.084448981792407],
            [110.310023634701878, -7.084756424888281],
            [110.309925529177178, -7.085271860908628],
            [110.309890725314176, -7.085461760365797],
            [110.309842985888054, -7.085597368870347],
            [110.309935110437749, -7.085678913225168],
            [110.310081550302996, -7.085769583455816],
            [110.310246369325498, -7.085869326203416],
            [110.310378957610084, -7.085950930509645],
            [110.310524642864081, -7.085978282453232],
            [110.310656355468154, -7.086077975973272],
            [110.310900579705347, -7.0862501985791],
            [110.311084465932211, -7.086467558128718],
            [110.311152135210946, -7.086630473672107],
            [110.311179233397752, -7.086865691438446],
            [110.31117893791172, -7.087064687441813],
            [110.311197021589606, -7.0872727559704],
            [110.311251714982248, -7.0874085164024],
            [110.311370667933531, -7.087526281446979],
            [110.311498689199908, -7.087635014605291],
            [110.31159028878929, -7.087689422033788],
            [110.311635226278412, -7.087725669758309],
            [110.311727391830274, -7.08778007800095],
            [110.31180058542823, -7.087843503431402],
            [110.311823451792577, -7.087879718461285],
            [110.311831038127409, -7.087915910867757],
            [110.311830984446104, -7.087952091962753],
            [110.311823263905694, -7.08800635229352],
            [110.311805653055856, -7.088051552686527],
            [110.311764839187546, -7.088096713977251],
            [110.31172771724799, -7.08813284492614],
            [110.31167334794678, -7.088159900291816],
            [110.31160481702824, -7.088195979969178],
            [110.311557562085625, -7.088195909977838],
            [110.31151823012776, -7.088195851717909],
            [110.311495862566218, -7.088204863877674],
            [110.311479073711894, -7.088268156057517],
            [110.311490028732791, -7.088322444043007],
            [110.311501266719503, -7.08837673244753],
            [110.311517867594361, -7.088440074088977],
            [110.311523446307788, -7.088494354109928],
            [110.311517746747057, -7.088521481545426],
            [110.311512020330028, -7.088566699526557],
            [110.311506590303381, -7.088602872653733],
            [110.311495204603546, -7.088648082250626],
            [110.311483832329614, -7.088684246574147],
            [110.311461437885086, -7.088711349275223],
            [110.311427698016359, -7.088756525750995],
            [110.311416622127993, -7.08878364521855],
            [110.311421958155591, -7.088810789002066],
            [110.31142189100548, -7.088856015365161],
            [110.311410815113817, -7.088883134832201],
            [110.311388420654652, -7.08891023752819],
            [110.311365716365941, -7.088955430348469],
            [110.31133759545655, -7.089027751017515],
            [110.311331855578928, -7.089082014265506],
            [110.311331801849761, -7.089118195354877],
            [110.311337407406285, -7.08915438483052],
            [110.311359707858358, -7.089190599045311],
            [110.311393340318915, -7.089217784758429],
            [110.311482986297278, -7.089254098753209],
            [110.311561636964129, -7.089263260565725],
            [110.311662345431515, -7.089281500330303],
            [110.311718332106764, -7.089308719132544],
            [110.311779978077794, -7.089335946309793],
            [110.311891964886357, -7.089381338608955],
            [110.312015297138331, -7.089408657092956],
            [110.312127634043819, -7.089408823389357],
            [110.312262042183661, -7.089409022322929],
            [110.312351768759811, -7.089391064514942],
            [110.312413441610218, -7.08940020106412],
            [110.31250304749102, -7.089463650696241],
            [110.312626299369015, -7.089545240671434],
            [110.312687918621577, -7.0895905582856],
            [110.312760533292504, -7.089663028037944],
            [110.312822125765621, -7.089726436186745],
            [110.312838700047493, -7.089807868349287],
            [110.312810632914363, -7.089844008022522],
            [110.312777176039404, -7.089889185017284],
            [110.31276013111956, -7.089934386281872],
            [110.312737763489253, -7.089943398494994],
            [110.312660110444668, -7.090024691288058],
            [110.31259606650957, -7.090087913609705],
            [110.312551600782456, -7.090114983709743],
            [110.312433308019152, -7.090123853980665],
            [110.312359171201436, -7.090123744274223],
            [110.312305124896113, -7.090123664289935],
            [110.312230947830201, -7.090150690384017],
            [110.312152216480357, -7.090195800310329],
            [110.312132355256395, -7.090231952082083],
            [110.31212234412844, -7.09030429960788],
            [110.312166622038575, -7.090403863381676],
            [110.312230451397937, -7.090485365506697],
            [110.312289443537651, -7.090584951052144],
            [110.312358366317184, -7.090666460702243],
            [110.312417291433491, -7.090811272604338],
            [110.31247145982438, -7.090919896288006],
            [110.312486080134249, -7.09098323497841],
            [110.312510550588399, -7.091082769418892],
            [110.31254491143477, -7.091191363787075],
            [110.312579339355651, -7.091254731784372],
            [110.3125990396574, -7.09132712328296],
            [110.312633534650544, -7.09134526490783],
            [110.312682690223113, -7.091399609395268],
            [110.312702444185618, -7.091435819794992],
            [110.312721888365786, -7.091490120323655],
            [110.312736778277369, -7.091562504702334],
            [110.312751412045515, -7.091616798114019],
            [110.312766059225751, -7.091662046251363],
            [110.31275607496201, -7.091716303244869],
            [110.312751197492688, -7.091761522498756],
            [110.312741253453879, -7.091788643669392],
            [110.312725030520284, -7.0918519367257],
            [110.312717835674292, -7.091933333725073],
            [110.312717755209931, -7.091987605368334],
            [110.312728104260145, -7.092069028322749],
            [110.312738776510798, -7.09212331587409],
            [110.312749475584383, -7.092159512877591],
            [110.312774026595051, -7.092204775667351],
            [110.312812995480826, -7.09225910507885],
            [110.312851991192971, -7.092295343939832],
            [110.312880517157041, -7.092331567311413],
            [110.312894625249513, -7.09235872406147],
            [110.312919216506614, -7.09237685102327],
            [110.312958239050687, -7.092394999328581],
            [110.312983153497925, -7.092385990883278],
            [110.313029303827179, -7.092367968548237],
            [110.313150210882796, -7.092313875573214],
            [110.313249612473186, -7.092259750771105],
            [110.313352396238358, -7.092214676239569],
            [110.313409339450601, -7.09216953393229],
            [110.313455570124844, -7.092097239899761],
            [110.313494686442382, -7.092052071232821],
            [110.31354086351169, -7.092015958290752],
            [110.313594128121565, -7.091970810520517],
            [110.313626143475801, -7.091943721938539],
            [110.313651057888421, -7.09193471345366],
            [110.313693502808604, -7.091934776162064],
            [110.313764500528663, -7.091952971637207],
            [110.313831792917085, -7.091989252220524],
            [110.313927678290554, -7.092016529728255],
            [110.314019871730679, -7.092052847061524],
            [110.314122548321762, -7.0920801345597],
            [110.314179397773458, -7.092098309085285],
            [110.314243308005928, -7.092125539324074],
            [110.314310600462221, -7.092161819843763],
            [110.314353248099906, -7.092216154572968],
            [110.31439561278097, -7.092270488881459],
            [110.314420433553835, -7.092324797294608],
            [110.314445227576343, -7.09239719626086],
            [110.314462691231711, -7.092451493814602],
            [110.314469698506542, -7.092496730639733],
            [110.314469631618508, -7.092541957024708],
            [110.314487405001984, -7.09257816444159],
            [110.314501499822285, -7.092614366429339],
            [110.314505124879545, -7.092650552966077],
            [110.314512158913914, -7.092677699236848],
            [110.314502740750996, -7.092731957118266],
            [110.314478055761256, -7.092777147171283],
            [110.314459339824353, -7.092804255438955],
            [110.314447132119909, -7.092831373311319],
            [110.314453303873705, -7.092867563606625],
            [110.314486910027242, -7.092912839688381],
            [110.314511461249253, -7.092958102404981],
            [110.314545350381437, -7.093003378901116],
            [110.314569888236718, -7.093057686892439],
            [110.31457887629395, -7.093102926639675],
            [110.314588147319469, -7.093148166804388],
            [110.314594319086297, -7.093184357098791],
            [110.31459709525015, -7.093220542382345],
            [110.314600154381139, -7.093256728083438],
            [110.314621592992481, -7.093301986203072],
            [110.314633990037933, -7.093338185683435],
            [110.314667582864686, -7.093392507033208],
            [110.314695260139359, -7.093428729059134],
            [110.31472292404375, -7.093473996360877],
            [110.314756813230943, -7.093519272846184],
            [110.314781337760067, -7.093582626107311],
            [110.314802776401933, -7.093627884221094],
            [110.314824188301856, -7.093691232888635],
            [110.314836302403833, -7.093727431948055],
            [110.314838202938319, -7.093781706533507],
            [110.314845223630741, -7.093817898078648],
            [110.314857041396806, -7.093863141997562],
            [110.314873682983134, -7.093899347735587],
            [110.314895121646103, -7.093944605846763],
            [110.31490440608394, -7.093980800730863],
            [110.314916197115011, -7.094044135203418],
            [110.314937662530568, -7.094071302758839],
            [110.314954317500522, -7.09409846321767],
            [110.31496135157569, -7.094125609484594],
            [110.314966121911013, -7.094152752412279],
            [110.314968345538503, -7.094179891583366],
            [110.314977886210897, -7.094234177438627],
            [110.314991994479612, -7.094261334140127],
            [110.315006358976476, -7.094306581813356],
            [110.315034615632698, -7.094333759382826],
            [110.315065688599759, -7.094369986401771],
            [110.315082033880486, -7.094415236994924],
            [110.315089350934116, -7.094442383678063],
            [110.31507967655304, -7.094478550598017],
            [110.31505136640601, -7.094487554139731],
            [110.315087169619687, -7.094578059917608],
            [110.315099230282016, -7.09465044008247],
            [110.315103044768946, -7.094749943976161],
            [110.315102817485027, -7.09490371369029],
            [110.31510698178694, -7.094957991614261],
            [110.315127288640397, -7.095003248050109],
            [110.31515186675756, -7.095030420190808],
            [110.315196805214725, -7.095066667654618],
            [110.315233537602381, -7.095102903013346],
            [110.315266281707068, -7.095157223082838],
            [110.31529475456226, -7.095229627446784],
            [110.315294674361965, -7.095283899111778],
            [110.315294594161031, -7.095338170776644],
            [110.315261676264285, -7.095401439314265],
            [110.315224810169042, -7.095455656728812],
            [110.315224756695073, -7.095491837837939],
            [110.315224703220821, -7.09552801894701],
            [110.31518778363224, -7.0956184174656],
            [110.315138426783264, -7.095699752337775],
            [110.315117986194593, -7.095744948670644],
            [110.31509323421443, -7.095835365125425],
            [110.315073319434759, -7.095907698120407],
            [110.31505085792972, -7.095980027356646],
            [110.315040024880958, -7.096034283153649],
            [110.315038179962372, -7.096133778692461],
            [110.315068323892959, -7.096224276127847],
            [110.315103937849599, -7.096251464556286],
            [110.315164479813859, -7.096260599160627],
            [110.315282221600668, -7.096242682230014],
            [110.315359202498712, -7.096233750459449],
            [110.315416079239853, -7.096233834329237],
            [110.315490999165249, -7.096279171280604],
            [110.315544400103718, -7.096333521794149],
            [110.315578236105864, -7.096414979352086],
            [110.315583492246333, -7.096496394776273],
            [110.315572619149535, -7.096577786421882],
            [110.315518950883643, -7.096704341457087],
            [110.315470645634818, -7.09683994968754],
            [110.315445327704097, -7.096930365323353],
            [110.315430519729858, -7.096993660564832],
            [110.315402049012675, -7.097111207436943],
            [110.315385529839276, -7.097183545448446],
            [110.315369024028769, -7.09724683818178],
            [110.315287178996556, -7.09729194394879],
            [110.315209605236873, -7.097318965408244],
            [110.315168534538188, -7.097346040707769],
            [110.315107036530136, -7.097409267048849],
            [110.315070156860344, -7.097472529705642],
            [110.315021136238343, -7.097517683851161],
            [110.314967897817084, -7.097544741174604],
            [110.314881834910679, -7.097571750045167],
            [110.314841073901462, -7.097580735177629],
            [110.314820376975661, -7.097607840513994],
            [110.314816348497445, -7.097653061044672],
            [110.314828435911735, -7.097707350659376],
            [110.314857245304268, -7.097743574365662],
            [110.31489397785495, -7.097779809764914],
            [110.314967469729638, -7.097834190004436],
            [110.315249336561976, -7.098006466529413],
            [110.315318853565998, -7.098069886152291],
            [110.315363765568208, -7.098124224177766],
            [110.315424996255189, -7.098241903342763],
            [110.31545740412264, -7.098332404104012],
            [110.315457230319041, -7.098449992699674],
            [110.315436722762243, -7.098540415415139],
            [110.315399776174672, -7.098648904472707],
            [110.315325976551506, -7.098802565639368],
            [110.315309510773929, -7.098838722531518],
            [110.315305212723771, -7.098874897374038],
            [110.3153011842696, -7.09892011790996],
            [110.315305065615348, -7.098974395411061],
            [110.315317476198572, -7.099001549607992],
            [110.315337809959004, -7.099028715493549],
            [110.315366336496126, -7.099064938760418],
            [110.315403069185393, -7.099101174131185],
            [110.315435853662834, -7.099128358379653],
            [110.31549720480983, -7.099164630057421],
            [110.315546105256871, -7.099200883365172],
            [110.315570670230926, -7.099237100778989],
            [110.315582771127808, -7.099282345106326],
            [110.315570536541031, -7.099327553543569],
            [110.315562276911848, -7.099363722546537],
            [110.315537591604567, -7.099408912619149],
            [110.315504969738143, -7.099463136279609],
            [110.315455612467133, -7.099544471138269],
            [110.315422950463102, -7.099625830618965],
            [110.315377864470264, -7.099689081175532],
            [110.315365346878451, -7.099734289186163],
            [110.31537345942111, -7.099797618224308],
            [110.315381585338926, -7.099851901986475],
            [110.315381518469891, -7.099897128365821],
            [110.315377463256567, -7.099960439451954],
            [110.315394064937209, -7.100023781014301],
            [110.31546335275857, -7.100051019119693],
            [110.315586162260104, -7.100051200272274],
            [110.315647620487724, -7.10001510973113],
            [110.315774391581201, -7.100015296675522],
            [110.31590930202394, -7.100060722067201],
            [110.316068467860134, -7.100160454953516],
            [110.316211437608061, -7.100305390409313],
            [110.316416944146198, -7.100450417963748],
            [110.316506648668479, -7.100640501355947],
            [110.316559540227061, -7.100848621107034],
            [110.316596152978207, -7.100966263902923],
            [110.316657477758483, -7.101020626012096],
            [110.316685924410663, -7.101111120883599],
            [110.316710382764001, -7.101219700475375],
            [110.316705884521781, -7.10139155450281],
            [110.316673222567616, -7.101472914073658],
            [110.316668924578607, -7.101509088932594],
            [110.31668529690954, -7.101536248939206],
            [110.316726301174143, -7.101554399926217],
            [110.316791627702443, -7.101581632026208],
            [110.316836566889762, -7.101617879394189],
            [110.31684047510646, -7.101654066339688],
            [110.316816019349034, -7.101735438004344],
            [110.316791267259418, -7.101825854527661],
            [110.31680759955816, -7.101880150364743],
            [110.316840061288701, -7.101934469954244],
            [110.316938119249244, -7.102025067322288],
            [110.317089349399311, -7.102133833547332],
            [110.317207781481486, -7.102224460859239],
            [110.317301621668918, -7.102296961357289],
            [110.317338301395424, -7.102369377723276],
            [110.317350645474221, -7.102441758275043],
            [110.317317700564459, -7.102523117474933],
            [110.31726442162217, -7.102577310849088],
            [110.317195026556902, -7.102622435195399],
            [110.317063903348966, -7.102694604545593],
            [110.317014868952157, -7.102748804135263],
            [110.316940799432572, -7.102893419826356],
            [110.316908124036999, -7.102983824679907],
            [110.316908043937318, -7.103038096345172],
            [110.316907950486907, -7.103101413287819],
            [110.316920024882705, -7.103164748149977],
            [110.31695272962763, -7.103246203987836],
            [110.316952662879828, -7.103291430375478],
            [110.316919771229493, -7.103336608421969],
            [110.316788837274771, -7.103472095025618],
            [110.316755945595062, -7.103517273057388],
            [110.316764085054658, -7.103562511531404],
            [110.316796803145806, -7.10363492210185],
            [110.316833562991548, -7.103653066843352],
            [110.316931701387574, -7.103689392576875],
            [110.316992810316364, -7.103698527869716],
            [110.317025568499616, -7.103743802595512],
            [110.31704184747737, -7.103834279538917],
            [110.317025341563422, -7.103897572312187],
            [110.316963842829324, -7.103960798822745],
            [110.316902357430365, -7.104014980046234],
            [110.316849131667809, -7.104032992240078],
            [110.316788036054334, -7.104014811647565],
            [110.316734836999117, -7.104014733274221],
            [110.316693805813316, -7.104014672822528],
            [110.316656979162914, -7.10404175445139],
            [110.31663199733255, -7.104095989419236],
            [110.316602906923251, -7.104249716588829],
            [110.316562025100112, -7.104340109304379],
            [110.316545492378111, -7.104421492603837],
            [110.316553591745247, -7.104493866908256],
            [110.316569964157836, -7.104521026924917],
            [110.316618838508361, -7.104575370726195],
            [110.316704769116328, -7.104638814421744],
            [110.316770082705304, -7.104675091839269],
            [110.316831488085924, -7.104675182305271],
            [110.316880442606504, -7.104675254422112],
            [110.316917229240815, -7.104675308610557],
            [110.316937926408457, -7.104648203207299],
            [110.316958327247505, -7.104630142663073],
            [110.316986961036037, -7.10459400365023],
            [110.317040213561995, -7.104557900892266],
            [110.317085206435621, -7.104557967151917],
            [110.31711803142548, -7.104558015489537],
            [110.317142353845625, -7.104567096601933],
            [110.317297186126879, -7.104920091159538],
            [110.317284951501492, -7.104965299632878],
            [110.31720737649762, -7.104992321305783],
            [110.317133480152137, -7.105019348382073],
            [110.31703947926917, -7.105055391132494],
            [110.31701084545243, -7.105091530145533],
            [110.316986146567672, -7.10514576554537],
            [110.316989947979337, -7.105254314702841],
            [110.317010444887998, -7.105362888451815],
            [110.317006539364641, -7.105516557677979],
            [110.316982981534963, -7.105673627452761],
            [110.316989714021759, -7.105762772859648],
            [110.317047962530864, -7.105827996133887],
            [110.317130215095787, -7.105906967908396],
            [110.317174720299818, -7.105982455792828],
            [110.317222254829289, -7.106129681658105],
            [110.31722579101762, -7.106140634080123],
            [110.317225756868055, -7.106140634029833],
            [110.317169997804044, -7.106140551913485],
            [110.31716976548249, -7.106297950294287],
            [110.317175561772515, -7.106312093179953],
            [110.317311453927928, -7.106643668348362],
            [110.317257416804637, -7.106800987494871],
            [110.317211196379077, -7.106870021300646],
            [110.317230276399002, -7.10696218523791],
            [110.317295418218237, -7.107092806943069],
            [110.316629512160659, -7.107358469821207],
            [110.315781963981351, -7.107733423711516],
            [110.314579387246553, -7.107760054166546],
            [110.314141118103038, -7.107426636906668],
            [110.313625810557738, -7.106991651277366],
            [110.313167404567736, -7.106540516829107],
            [110.312745556849038, -7.106093494352425],
            [110.312120881811822, -7.105443262843306],
            [110.311406904057449, -7.104744200162023],
            [110.310899950884689, -7.104159072598216],
            [110.310453949715637, -7.103565918897541],
            [110.3099995220133, -7.10317566011651],
            [110.309761216110431, -7.102245987899334],
            [110.309156832835981, -7.10161607414735],
            [110.308352949627334, -7.101278049534985],
            [110.307122548472549, -7.100906921656673],
            [110.306821090134534, -7.101084243913333],
            [110.306758400411397, -7.101039145207507],
            [110.3066772077362, -7.101343012421073],
            [110.306566549480848, -7.1012053340503],
            [110.306525211124622, -7.10128388093075],
            [110.306134989663192, -7.101616448483234],
            [110.306030086853355, -7.101601318497885],
            [110.305902666396008, -7.101608614416796],
            [110.305790167897186, -7.101660851860196],
            [110.30566637685061, -7.10174676179495],
            [110.305527663821508, -7.101787730126154],
            [110.305377815498929, -7.101757559631674],
            [110.305254220987649, -7.101712455332524],
            [110.305160610763778, -7.101663652643593],
            [110.305070809703039, -7.101573679687839],
            [110.305003576944983, -7.101427591496623],
            [110.304878499531497, -7.101122327719978],
            [110.304800216027502, -7.100848951820193],
            [110.304733157701037, -7.100586822539003],
            [110.304699673532241, -7.10042581181515],
            [110.304679302185605, -7.100266692373899],
            [110.304642025758326, -7.100135622113892],
            [110.304627238868591, -7.100000842285745],
            [110.304649936378809, -7.099858632090675],
            [110.304702561796503, -7.099750156153278],
            [110.304803907883695, -7.099638009928815],
            [110.304862157015648, -7.099527670756687],
            [110.304873657435735, -7.099355497615165],
            [110.304900012229865, -7.099273185174618],
            [110.30496759866017, -7.099183447945586],
            [110.30500895389838, -7.09909367142246],
            [110.304979093402324, -7.099015018054907],
            [110.30491546781451, -7.098962517000142],
            [110.304829302204098, -7.098947414865315],
            [110.304731883437668, -7.098939782394507],
            [110.30462319464938, -7.098950849361224],
            [110.304499499761548, -7.098973123619974],
            [110.30442076131618, -7.099002951763938],
            [110.304338320538818, -7.099002828195619],
            [110.304297284883717, -7.098977236421935],
            [110.304209644096574, -7.098852816825985],
            [110.304085595338449, -7.098604054418568],
            [110.303998196390026, -7.098318791593011],
            [110.303961909289882, -7.098114027188408],
            [110.303932908194071, -7.097931206921708],
            [110.303852597611936, -7.097799487202412],
            [110.303772177176995, -7.097740878017051],
            [110.303669810936938, -7.097674924827779],
            [110.303633370125823, -7.097572515170488],
            [110.303611666125803, -7.097404327983355],
            [110.303619270837231, -7.097214251551382],
            [110.303670899007727, -7.096951130444384],
            [110.303685866551845, -7.096731820764092],
            [110.303657008464228, -7.09645395677846],
            [110.303693822838397, -7.096307790571221],
            [110.303752495042502, -7.0962274567793],
            [110.303753331783966, -7.096086144259452],
            [110.303742424632176, -7.095886863913859],
            [110.303738177516266, -7.09573013305819],
            [110.303748382904217, -7.095650666657326],
            [110.303720603602471, -7.095492781052006],
            [110.30370087249068, -7.095199452777763],
            [110.303712317961882, -7.095040506528982],
            [110.303766247521025, -7.09494879161831],
            [110.303844824817389, -7.094861591493483],
            [110.303907662416592, -7.094807951579579],
            [110.303950291157491, -7.094778909500445],
            [110.304019819055043, -7.094747668811782],
            [110.304111753383921, -7.094720939532813],
            [110.30421038139616, -7.094714370558163],
            [110.30435380548758, -7.094728018965463],
            [110.304427782380614, -7.094719174093666],
            [110.304468163047119, -7.09469460643898],
            [110.304546713293519, -7.094625317510914],
            [110.304691541911424, -7.094449779023591],
            [110.304797118860719, -7.094293212557252],
            [110.304858906817756, -7.094148329562609],
            [110.304860097335393, -7.094145537985844],
            [110.304873692988508, -7.094047045771329],
            [110.304832502405873, -7.093864511979516],
            [110.304769234594517, -7.093458052945316],
            [110.304692488779196, -7.093071724027639],
            [110.304630209110087, -7.092753703878975],
            [110.304613585728461, -7.092629418825155],
            [110.304618410945096, -7.09240105600901],
            [110.304636720917088, -7.09214808523378],
            [110.30467517907546, -7.091910817065111],
            [110.304854439685911, -7.091940191330186],
            [110.305026164108426, -7.09195870031972],
            [110.305065088645804, -7.09196289571784],
            [110.305123874964991, -7.091958980164333],
            [110.305073515171713, -7.09173201048761],
            [110.30506471790531, -7.091560137015255],
            [110.305021776757968, -7.091324895509456],
            [110.305040035684897, -7.091225424745896],
            [110.305158504926936, -7.091098968022655],
            [110.305231364197624, -7.090818673296743],
            [110.305281894600611, -7.090710205490259],
            [110.305384261745203, -7.090565634050529],
            [110.305384383704578, -7.0904842267071],
            [110.305359321804005, -7.090402781714533],
            [110.305343679052797, -7.090267079119024],
            [110.305269260999083, -7.090077016966896],
            [110.305244077158903, -7.09007697931352],
            [110.305176179234678, -7.090067832509366],
            [110.305151063168807, -7.090022568550406],
            [110.305105804108393, -7.089823504702092],
            [110.305156227643593, -7.089597448090236],
            [110.305124698304084, -7.089488857578326],
            [110.305136206621938, -7.089362240858256],
            [110.305111939483751, -7.089316978169215],
            [110.305092199773952, -7.089271722249287],
            [110.304917924551177, -7.088873469283756],
            [110.304807690724445, -7.088791896880798],
            [110.304571487593662, -7.088366415344784],
            [110.304597925250192, -7.088285047381583],
            [110.304750605213229, -7.088176732430413],
            [110.304819245023012, -7.088068291733275],
            [110.30490946115718, -7.087534755090958],
            [110.30483079917542, -7.087344686559644],
            [110.304799729206451, -7.087118508073537],
            [110.304747247275969, -7.087018931494713],
            [110.304673746543429, -7.086783644262719],
            [110.304626857889758, -7.086539351549386],
            [110.304606461006273, -7.086177509808833],
            [110.304485841393102, -7.085851699273694],
            [110.304485612669254, -7.085815517808524],
            [110.304781233101593, -7.085110427985757],
            [110.304791973714075, -7.084929538411565],
            [110.304645159132164, -7.084712232134764],
            [110.304545126433908, -7.084621629727232],
            [110.304571672259414, -7.084467899642995],
            [110.304560772332252, -7.084377430528175],
            [110.304382185502064, -7.08428200173262],
            [110.304119422920522, -7.084141593000954],
            [110.304089839456836, -7.084103971520946],
            [110.304080652104133, -7.084078357497793],
            [110.304091928631237, -7.084039153409956],
            [110.304119998117969, -7.084022386428307],
            [110.304164868968101, -7.084022453567939],
            [110.304305123974714, -7.084000251422192],
            [110.304406133775458, -7.08396678454505],
            [110.304462323102314, -7.083899632643671],
            [110.304462457457333, -7.083809984923245],
            [110.304445765251657, -7.083720312037473],
            [110.304445949989045, -7.083597046421299],
            [110.304479695461112, -7.083535463941054],
            [110.304494860820924, -7.083531280167141],
            [110.304482584691456, -7.083478878010353],
            [110.304415553918702, -7.083294999524244],
            [110.30437972447281, -7.083250121977184],
            [110.30430799169676, -7.083209673122568],
            [110.304263114204772, -7.083214088390354],
            [110.304181921164073, -7.08322794684461],
            [110.304076729719696, -7.083243070743368],
            [110.304002175719688, -7.083229588064142],
            [110.303931104106695, -7.08319117965292],
            [110.303828321460415, -7.083128089390764],
            [110.303738408598434, -7.083025042596064],
            [110.30354138084671, -7.082755803890168],
            [110.30333540608693, -7.082468622138672],
            [110.303255824892744, -7.082361258046299],
            [110.303196663555426, -7.082230847378871],
            [110.303084890649231, -7.081961736227993],
            [110.302991052645709, -7.081701616721716],
            [110.30292858376562, -7.081468438577707],
            [110.302870400074212, -7.081369738702409],
            [110.302794247983513, -7.08128445907943],
            [110.30279318882134, -7.081283928064191],
            [110.302743814949224, -7.081172705742708],
            [110.30331178772019, -7.081164406929729],
            [110.303594481629318, -7.081155864703207],
            [110.303777879728969, -7.081129003346708],
            [110.303921339919071, -7.081129218008647],
            [110.304025724657464, -7.081147464736892],
            [110.304114277260311, -7.081156642479902],
            [110.304297760910927, -7.08118074985242],
            [110.304491239298073, -7.081181039132892],
            [110.305109098857102, -7.081037004321654],
            [110.305264425756619, -7.081022682137021],
            [110.305353584901269, -7.081004724697273],
            [110.305491130023768, -7.080986839470882],
            [110.305677101703353, -7.080941890620418],
            [110.305967566328121, -7.080842825865116],
            [110.306127815697522, -7.080779747882145],
            [110.306261170422161, -7.080725675025225],
            [110.306592461937186, -7.080572399006073],
            [110.30670317985836, -7.080518292275005],
            [110.306830902286251, -7.080446120273751],
            [110.306896346763111, -7.080391946042261],
            [110.306992984177697, -7.080292591821966],
            [110.307068049207089, -7.080238431890615],
            [110.307132065337953, -7.080193300780793],
            [110.307254680867956, -7.080130166330894],
            [110.307314425602712, -7.080103119409864],
            [110.307365371632244, -7.080094149959957],
            [110.307449140850309, -7.080085229358306],
            [110.307562634201346, -7.080067307686549],
            [110.307680371924164, -7.080049392301304],
            [110.307786791299776, -7.080031460048028],
            [110.307922355396954, -7.08001357111842],
            [110.308337805889309, -7.079968962395205],
            [110.30850391601227, -7.079960163986239],
            [110.308601523235637, -7.079969354318018],
            [110.308715417117881, -7.079951432953113],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 110,
        id: 112,
        area: 2514975.0175660001,
        perimeter: 8865.600977,
        kode_pos: 50216,
        kode_kec: 3374010,
        kode_kel: 3374010002,
        kec: "Mijen",
        kel: "Bubakan",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.311764839187546, -7.088096713977251],
            [110.311900055562916, -7.088124054801694],
            [110.312013214211348, -7.088142312900547],
            [110.312053503609221, -7.08825996135634],
            [110.312053288941229, -7.088404685740641],
            [110.312077059122686, -7.088594672094952],
            [110.312141562733558, -7.08879376403185],
            [110.312218044794193, -7.088929556633271],
            [110.312377812284694, -7.089002155403262],
            [110.312442314727704, -7.089011296130549],
            [110.312789849972063, -7.089165580178213],
            [110.312975543173351, -7.089310579456973],
            [110.313415608275477, -7.089464999859803],
            [110.313524738885008, -7.089528478155626],
            [110.313617780703922, -7.089564796775992],
            [110.313751030300367, -7.089583084165],
            [110.3138964340108, -7.089610434758647],
            [110.31401348774304, -7.089673924646871],
            [110.314090320365793, -7.089764491032783],
            [110.314183025808404, -7.089836990240713],
            [110.314314983152528, -7.089963819139473],
            [110.314622229640676, -7.090000453609301],
            [110.314784934524539, -7.090000693565219],
            [110.314875483331903, -7.090000827081234],
            [110.31491194340785, -7.089838065463488],
            [110.315039070059896, -7.089404078523255],
            [110.315533049352084, -7.087731425822179],
            [110.315567111769951, -7.08765911357454],
            [110.315617193632164, -7.087469235999532],
            [110.315644504925487, -7.087369777900053],
            [110.315660484324525, -7.087279348415962],
            [110.315669645906979, -7.08720699949562],
            [110.315720077338426, -7.086971895906148],
            [110.315733766311965, -7.08689955364633],
            [110.315754070165639, -7.08675485871151],
            [110.315758744330566, -7.086655367276538],
            [110.315758864401573, -7.086573959740909],
            [110.315763711989661, -7.086356879641151],
            [110.315761541671165, -7.086293559336519],
            [110.315766175799411, -7.086221203744644],
            [110.31577284402438, -7.086112669939631],
            [110.315781129981588, -7.086058410324306],
            [110.315805016053901, -7.085787086424471],
            [110.315848669435226, -7.085542927509867],
            [110.315878324245261, -7.085389201009096],
            [110.31590387086591, -7.085334966783096],
            [110.315966781826461, -7.085271742221438],
            [110.316062786011344, -7.08521761162971],
            [110.316231281803283, -7.085127406410795],
            [110.316417550843795, -7.085073408465712],
            [110.316539264522518, -7.085046451471127],
            [110.316746149780812, -7.085019619600918],
            [110.316914821825691, -7.085001776806205],
            [110.317136138124894, -7.084974965957626],
            [110.317320346451794, -7.084975236437836],
            [110.317605585564621, -7.084966609816084],
            [110.317922786108312, -7.084967075207745],
            [110.318197259388157, -7.084967477736019],
            [110.318391101753321, -7.084958716610726],
            [110.318642951362989, -7.084950040405023],
            [110.318964383121369, -7.084959556592853],
            [110.319324863700359, -7.084969129722078],
            [110.319492660299858, -7.084969375317187],
            [110.31981975139027, -7.084978899198712],
            [110.31994112894273, -7.084988122039466],
            [110.320062506503021, -7.084997344848988],
            [110.320235126748358, -7.084988551920239],
            [110.32043007452009, -7.084997882179072],
            [110.320790594987727, -7.084980318302001],
            [110.321225521314901, -7.084971908073888],
            [110.321485267518014, -7.084981332475976],
            [110.322157289945167, -7.084991357908532],
            [110.322278950567906, -7.085000580556199],
            [110.32260550240882, -7.084992011070095],
            [110.323390157284607, -7.084993153475171],
            [110.323629247396511, -7.085002546627609],
            [110.323901470502804, -7.084993897200556],
            [110.3242953411537, -7.085003515035315],
            [110.324330711604844, -7.085003474190103],
            [110.324379624479008, -7.085030773456796],
            [110.324410980644359, -7.085067000286451],
            [110.324426477739408, -7.085112249391091],
            [110.324430360199599, -7.085166526938611],
            [110.324410717248398, -7.085247906258802],
            [110.324373221294863, -7.085347350271642],
            [110.324356730427965, -7.085401598214781],
            [110.324379248911882, -7.085483038793955],
            [110.324424687395933, -7.085564512675339],
            [110.324424608373874, -7.08561878446619],
            [110.324404952224072, -7.085709209081059],
            [110.324313818134385, -7.085917118948148],
            [110.324376260774372, -7.085980526912834],
            [110.324657045674755, -7.08611661462326],
            [110.325061531825867, -7.086415697510779],
            [110.325370929575627, -7.086723687429823],
            [110.325589404288124, -7.087094862511909],
            [110.325752497251429, -7.087411685273931],
            [110.325752365771891, -7.087502138276295],
            [110.325780629605816, -7.087719266935656],
            [110.325766323612697, -7.087827790015209],
            [110.325732789180066, -7.087927239880947],
            [110.32568964708986, -7.088017630485304],
            [110.325546368598282, -7.088279736843337],
            [110.325574224584742, -7.088777269786199],
            [110.325621874664705, -7.08889492807226],
            [110.325697821272513, -7.089012627412293],
            [110.325826169066374, -7.089094221487953],
            [110.325918063785082, -7.089284306485804],
            [110.325924529456188, -7.089365723734433],
            [110.325961209763761, -7.089438139489847],
            [110.32610006687618, -7.089492612789465],
            [110.32628225715834, -7.089520012917712],
            [110.326438684475875, -7.089556420954951],
            [110.326601606893036, -7.089601883673889],
            [110.326767872404105, -7.08968353246312],
            [110.326916785169502, -7.089828473307227],
            [110.327009440361309, -7.089937151354313],
            [110.327160965602474, -7.090036869312549],
            [110.327277646743369, -7.090163672745622],
            [110.327315650294153, -7.090299407580328],
            [110.327325831332971, -7.090498419382063],
            [110.327376890725844, -7.09060703715202],
            [110.327467953339522, -7.090643350253548],
            [110.32756389198714, -7.090634443788543],
            [110.327654671657939, -7.090670756445798],
            [110.327729861971591, -7.090725137173908],
            [110.327798504736549, -7.090806644383848],
            [110.32802497173013, -7.091132603569019],
            [110.328039337469846, -7.091177850956803],
            [110.328034395934097, -7.091268297029671],
            [110.328016773340735, -7.091322543472445],
            [110.327967123064198, -7.091412924873674],
            [110.327903107032583, -7.091458058876671],
            [110.327834267440011, -7.091512231212273],
            [110.327810123461362, -7.091575513529597],
            [110.327778030172823, -7.091656874985202],
            [110.3277348749304, -7.091756311072228],
            [110.32767566926556, -7.091801451997817],
            [110.327587614085871, -7.091837505836949],
            [110.327539766438179, -7.091855527220099],
            [110.32750123045102, -7.09189165271939],
            [110.3274643791366, -7.091936825974491],
            [110.32741584742476, -7.092036254238311],
            [110.327385464957231, -7.092108572812368],
            [110.327383675280629, -7.092171887464336],
            [110.327390052274438, -7.092262349902273],
            [110.327383439028537, -7.092334702889902],
            [110.327359321188808, -7.092379894569103],
            [110.327327293387896, -7.092416029471863],
            [110.327281025326457, -7.092515460990245],
            [110.327270837648413, -7.09257873407883],
            [110.32725505212268, -7.092669193815527],
            [110.327214851746092, -7.092877177949268],
            [110.327171360759309, -7.093012794749779],
            [110.327169597300013, -7.093058018793988],
            [110.327179100022803, -7.093139440436865],
            [110.327207857682708, -7.093211844651476],
            [110.327325678696312, -7.09352860149911],
            [110.327320815726779, -7.093564775735153],
            [110.327272619269664, -7.093628023165304],
            [110.327203687346497, -7.093745512468798],
            [110.327141289918629, -7.093844920586681],
            [110.327064204387881, -7.09392621677237],
            [110.327004945825635, -7.094007538776197],
            [110.326929439815373, -7.094170245093117],
            [110.326905578513234, -7.094233527741692],
            [110.326897201632534, -7.09435110474428],
            [110.326898807472986, -7.094414424302935],
            [110.326874689460993, -7.094459615938317],
            [110.326820254395614, -7.094531899580456],
            [110.326464753946937, -7.094594701328642],
            [110.326440451810242, -7.094766527118173],
            [110.326402863269081, -7.094929288312802],
            [110.326293486310348, -7.095227625110944],
            [110.326214788239994, -7.095444598532723],
            [110.32619424346349, -7.095562157840925],
            [110.326180246429431, -7.095652590698171],
            [110.326173099976188, -7.095896803882876],
            [110.326173007886865, -7.095960120967],
            [110.326189880803355, -7.096032507989006],
            [110.326203680526461, -7.096077754600207],
            [110.326230792970051, -7.096113975214037],
            [110.32643158290864, -7.096195674413282],
            [110.326625917214841, -7.096241182898753],
            [110.326758472186796, -7.096349918948048],
            [110.326827141794737, -7.096413335746838],
            [110.32689516662667, -7.096531023509688],
            [110.326936013248812, -7.096657717181074],
            [110.326976524354208, -7.096820591635624],
            [110.326983249924297, -7.096865827975625],
            [110.326972668746464, -7.09713717217143],
            [110.326982750532792, -7.097209549330546],
            [110.327169256041159, -7.097969626401311],
            [110.327376458352816, -7.0987025974711],
            [110.327389949004711, -7.09876593424799],
            [110.327396648351808, -7.098829261182487],
            [110.327416706987862, -7.099046377881947],
            [110.327416523065821, -7.099173012065389],
            [110.327409547825795, -7.0992996364055],
            [110.327392372465667, -7.099435291278555],
            [110.32732382155065, -7.099679415490874],
            [110.327292767384307, -7.099824095552962],
            [110.327090937252109, -7.100456975104745],
            [110.327025768499695, -7.100710149455298],
            [110.326977966176273, -7.100890986447956],
            [110.326943516117353, -7.101035661535661],
            [110.326911746000178, -7.101211905687338],
            [110.326887211803708, -7.101349612979866],
            [110.326881479806772, -7.101469746709315],
            [110.32685401251689, -7.101868126255824],
            [110.326885491060978, -7.102120444652604],
            [110.326885488210166, -7.102120442347608],
            [110.326471853443877, -7.101988881708093],
            [110.326070712244004, -7.101938201765818],
            [110.325754197682116, -7.101890775573152],
            [110.325535012099323, -7.101984986108176],
            [110.325356504933495, -7.101962888341045],
            [110.325130725824323, -7.101875207130457],
            [110.324829940436516, -7.101604502641924],
            [110.324368956837901, -7.101750992496473],
            [110.323657409557953, -7.101990532181139],
            [110.323279506294028, -7.102039936570953],
            [110.323090020440617, -7.102050579215675],
            [110.322747501220775, -7.102060998392853],
            [110.322561664345187, -7.102068006448291],
            [110.322428235866695, -7.102073794712676],
            [110.322393605293584, -7.102177069871102],
            [110.32231770380109, -7.102637228193802],
            [110.322237155467008, -7.102721603146998],
            [110.322135623821154, -7.102827958200281],
            [110.321999337212034, -7.103142285943554],
            [110.321997238487342, -7.103147126381159],
            [110.322009560870583, -7.103294305278908],
            [110.321931235282335, -7.10326914211916],
            [110.321780885234674, -7.103196907398264],
            [110.321674532823366, -7.103046459916516],
            [110.32151187902349, -7.102814521922357],
            [110.321368248059528, -7.102435450843455],
            [110.321293360150577, -7.102203641186297],
            [110.321136911488054, -7.102015547328],
            [110.32094264708175, -7.101965165691891],
            [110.320679382781449, -7.101942862682354],
            [110.320322290965123, -7.101779523387228],
            [110.320115640611249, -7.101625797579783],
            [110.319833606518543, -7.101578417952214],
            [110.319730269954491, -7.101509382647744],
            [110.319639268857387, -7.101578132994626],
            [110.319482199726849, -7.101812733720454],
            [110.319318824634379, -7.102072373772197],
            [110.319224523105007, -7.102253838094997],
            [110.319211598340729, -7.102516829932586],
            [110.319189343777921, -7.102729710776788],
            [110.319160820210854, -7.102942582414349],
            [110.319119574400361, -7.103280678604253],
            [110.319065919533273, -7.103531086300224],
            [110.318836424217466, -7.103991018159224],
            [110.318685724179545, -7.104156744061423],
            [110.318651097207891, -7.10425688776346],
            [110.318650940460486, -7.104363344266966],
            [110.318666281007296, -7.104588804593448],
            [110.318687932050466, -7.104786094466929],
            [110.318744002682493, -7.105024138952441],
            [110.318784446946751, -7.10523084967406],
            [110.318715280422367, -7.105371646671018],
            [110.318583465269526, -7.105484171867855],
            [110.318372577017058, -7.106078767109615],
            [110.317894472466051, -7.105925442765081],
            [110.317590506490362, -7.105827962981958],
            [110.317585527421102, -7.105826366226986],
            [110.317523753149061, -7.106018224981859],
            [110.317527262207946, -7.106244730765679],
            [110.317397819291884, -7.106275407265313],
            [110.31726967730296, -7.106305775419473],
            [110.317266077724895, -7.106140693406163],
            [110.31722579101762, -7.106140634080123],
            [110.317222254829289, -7.106129681658105],
            [110.317174720299818, -7.105982455792828],
            [110.317130215095787, -7.105906967908396],
            [110.317047962530864, -7.105827996133887],
            [110.316989714021759, -7.105762772859648],
            [110.316982981534963, -7.105673627452761],
            [110.317006539364641, -7.105516557677979],
            [110.317010444887998, -7.105362888451815],
            [110.316989947979337, -7.105254314702841],
            [110.316986146567672, -7.10514576554537],
            [110.31701084545243, -7.105091530145533],
            [110.31703947926917, -7.105055391132494],
            [110.317133480152137, -7.105019348382073],
            [110.31720737649762, -7.104992321305783],
            [110.317284951501492, -7.104965299632878],
            [110.317297186126879, -7.104920091159538],
            [110.317142353845625, -7.104567096601933],
            [110.31711803142548, -7.104558015489537],
            [110.317085206435621, -7.104557967151917],
            [110.317040213561995, -7.104557900892266],
            [110.316986961036037, -7.10459400365023],
            [110.316958327247505, -7.104630142663073],
            [110.316937926408457, -7.104648203207299],
            [110.316917229240815, -7.104675308610557],
            [110.316880442606504, -7.104675254422112],
            [110.316831488085924, -7.104675182305271],
            [110.316770082705304, -7.104675091839269],
            [110.316704769116328, -7.104638814421744],
            [110.316618838508361, -7.104575370726195],
            [110.316569964157836, -7.104521026924917],
            [110.316553591745247, -7.104493866908256],
            [110.316545492378111, -7.104421492603837],
            [110.316562025100112, -7.104340109304379],
            [110.316602906923251, -7.104249716588829],
            [110.31663199733255, -7.104095989419236],
            [110.316656979162914, -7.10404175445139],
            [110.316693805813316, -7.104014672822528],
            [110.316734836999117, -7.104014733274221],
            [110.316788036054334, -7.104014811647565],
            [110.316849131667809, -7.104032992240078],
            [110.316902357430365, -7.104014980046234],
            [110.316963842829324, -7.103960798822745],
            [110.317025341563422, -7.103897572312187],
            [110.31704184747737, -7.103834279538917],
            [110.317025568499616, -7.103743802595512],
            [110.316992810316364, -7.103698527869716],
            [110.316931701387574, -7.103689392576875],
            [110.316833562991548, -7.103653066843352],
            [110.316796803145806, -7.10363492210185],
            [110.316764085054658, -7.103562511531404],
            [110.316755945595062, -7.103517273057388],
            [110.316788837274771, -7.103472095025618],
            [110.316919771229493, -7.103336608421969],
            [110.316952662879828, -7.103291430375478],
            [110.31695272962763, -7.103246203987836],
            [110.316920024882705, -7.103164748149977],
            [110.316907950486907, -7.103101413287819],
            [110.316908043937318, -7.103038096345172],
            [110.316908124036999, -7.102983824679907],
            [110.316940799432572, -7.102893419826356],
            [110.317014868952157, -7.102748804135263],
            [110.317063903348966, -7.102694604545593],
            [110.317195026556902, -7.102622435195399],
            [110.31726442162217, -7.102577310849088],
            [110.317317700564459, -7.102523117474933],
            [110.317350645474221, -7.102441758275043],
            [110.317338301395424, -7.102369377723276],
            [110.317301621668918, -7.102296961357289],
            [110.317207781481486, -7.102224460859239],
            [110.317089349399311, -7.102133833547332],
            [110.316938119249244, -7.102025067322288],
            [110.316840061288701, -7.101934469954244],
            [110.31680759955816, -7.101880150364743],
            [110.316791267259418, -7.101825854527661],
            [110.316816019349034, -7.101735438004344],
            [110.31684047510646, -7.101654066339688],
            [110.316836566889762, -7.101617879394189],
            [110.316791627702443, -7.101581632026208],
            [110.316726301174143, -7.101554399926217],
            [110.31668529690954, -7.101536248939206],
            [110.316668924578607, -7.101509088932594],
            [110.316673222567616, -7.101472914073658],
            [110.316705884521781, -7.10139155450281],
            [110.316710382764001, -7.101219700475375],
            [110.316685924410663, -7.101111120883599],
            [110.316657477758483, -7.101020626012096],
            [110.316596152978207, -7.100966263902923],
            [110.316559540227061, -7.100848621107034],
            [110.316506648668479, -7.100640501355947],
            [110.316416944146198, -7.100450417963748],
            [110.316211437608061, -7.100305390409313],
            [110.316068467860134, -7.100160454953516],
            [110.31590930202394, -7.100060722067201],
            [110.315774391581201, -7.100015296675522],
            [110.315647620487724, -7.10001510973113],
            [110.315586162260104, -7.100051200272274],
            [110.31546335275857, -7.100051019119693],
            [110.315394064937209, -7.100023781014301],
            [110.315377463256567, -7.099960439451954],
            [110.315381518469891, -7.099897128365821],
            [110.315381585338926, -7.099851901986475],
            [110.31537345942111, -7.099797618224308],
            [110.315365346878451, -7.099734289186163],
            [110.315377864470264, -7.099689081175532],
            [110.315422950463102, -7.099625830618965],
            [110.315455612467133, -7.099544471138269],
            [110.315504969738143, -7.099463136279609],
            [110.315537591604567, -7.099408912619149],
            [110.315562276911848, -7.099363722546537],
            [110.315570536541031, -7.099327553543569],
            [110.315582771127808, -7.099282345106326],
            [110.315570670230926, -7.099237100778989],
            [110.315546105256871, -7.099200883365172],
            [110.31549720480983, -7.099164630057421],
            [110.315435853662834, -7.099128358379653],
            [110.315403069185393, -7.099101174131185],
            [110.315366336496126, -7.099064938760418],
            [110.315337809959004, -7.099028715493549],
            [110.315317476198572, -7.099001549607992],
            [110.315305065615348, -7.098974395411061],
            [110.3153011842696, -7.09892011790996],
            [110.315305212723771, -7.098874897374038],
            [110.315309510773929, -7.098838722531518],
            [110.315325976551506, -7.098802565639368],
            [110.315399776174672, -7.098648904472707],
            [110.315436722762243, -7.098540415415139],
            [110.315457230319041, -7.098449992699674],
            [110.31545740412264, -7.098332404104012],
            [110.315424996255189, -7.098241903342763],
            [110.315363765568208, -7.098124224177766],
            [110.315318853565998, -7.098069886152291],
            [110.315249336561976, -7.098006466529413],
            [110.314967469729638, -7.097834190004436],
            [110.31489397785495, -7.097779809764914],
            [110.314857245304268, -7.097743574365662],
            [110.314828435911735, -7.097707350659376],
            [110.314816348497445, -7.097653061044672],
            [110.314820376975661, -7.097607840513994],
            [110.314841073901462, -7.097580735177629],
            [110.314881834910679, -7.097571750045167],
            [110.314967897817084, -7.097544741174604],
            [110.315021136238343, -7.097517683851161],
            [110.315070156860344, -7.097472529705642],
            [110.315107036530136, -7.097409267048849],
            [110.315168534538188, -7.097346040707769],
            [110.315209605236873, -7.097318965408244],
            [110.315287178996556, -7.09729194394879],
            [110.315369024028769, -7.09724683818178],
            [110.315385529839276, -7.097183545448446],
            [110.315402049012675, -7.097111207436943],
            [110.315430519729858, -7.096993660564832],
            [110.315445327704097, -7.096930365323353],
            [110.315470645634818, -7.09683994968754],
            [110.315518950883643, -7.096704341457087],
            [110.315572619149535, -7.096577786421882],
            [110.315583492246333, -7.096496394776273],
            [110.315578236105864, -7.096414979352086],
            [110.315544400103718, -7.096333521794149],
            [110.315490999165249, -7.096279171280604],
            [110.315416079239853, -7.096233834329237],
            [110.315359202498712, -7.096233750459449],
            [110.315282221600668, -7.096242682230014],
            [110.315164479813859, -7.096260599160627],
            [110.315103937849599, -7.096251464556286],
            [110.315068323892959, -7.096224276127847],
            [110.315038179962372, -7.096133778692461],
            [110.315040024880958, -7.096034283153649],
            [110.31505085792972, -7.095980027356646],
            [110.315073319434759, -7.095907698120407],
            [110.31509323421443, -7.095835365125425],
            [110.315117986194593, -7.095744948670644],
            [110.315138426783264, -7.095699752337775],
            [110.31518778363224, -7.0956184174656],
            [110.315224703220821, -7.09552801894701],
            [110.315224756695073, -7.095491837837939],
            [110.315224810169042, -7.095455656728812],
            [110.315261676264285, -7.095401439314265],
            [110.315294594161031, -7.095338170776644],
            [110.315294674361965, -7.095283899111778],
            [110.31529475456226, -7.095229627446784],
            [110.315266281707068, -7.095157223082838],
            [110.315233537602381, -7.095102903013346],
            [110.315196805214725, -7.095066667654618],
            [110.31515186675756, -7.095030420190808],
            [110.315127288640397, -7.095003248050109],
            [110.31510698178694, -7.094957991614261],
            [110.315102817485027, -7.09490371369029],
            [110.315103044768946, -7.094749943976161],
            [110.315099230282016, -7.09465044008247],
            [110.315087169619687, -7.094578059917608],
            [110.31505136640601, -7.094487554139731],
            [110.31507967655304, -7.094478550598017],
            [110.315089350934116, -7.094442383678063],
            [110.315082033880486, -7.094415236994924],
            [110.315065688599759, -7.094369986401771],
            [110.315034615632698, -7.094333759382826],
            [110.315006358976476, -7.094306581813356],
            [110.314991994479612, -7.094261334140127],
            [110.314977886210897, -7.094234177438627],
            [110.314968345538503, -7.094179891583366],
            [110.314966121911013, -7.094152752412279],
            [110.31496135157569, -7.094125609484594],
            [110.314954317500522, -7.09409846321767],
            [110.314937662530568, -7.094071302758839],
            [110.314916197115011, -7.094044135203418],
            [110.31490440608394, -7.093980800730863],
            [110.314895121646103, -7.093944605846763],
            [110.314873682983134, -7.093899347735587],
            [110.314857041396806, -7.093863141997562],
            [110.314845223630741, -7.093817898078648],
            [110.314838202938319, -7.093781706533507],
            [110.314836302403833, -7.093727431948055],
            [110.314824188301856, -7.093691232888635],
            [110.314802776401933, -7.093627884221094],
            [110.314781337760067, -7.093582626107311],
            [110.314756813230943, -7.093519272846184],
            [110.31472292404375, -7.093473996360877],
            [110.314695260139359, -7.093428729059134],
            [110.314667582864686, -7.093392507033208],
            [110.314633990037933, -7.093338185683435],
            [110.314621592992481, -7.093301986203072],
            [110.314600154381139, -7.093256728083438],
            [110.31459709525015, -7.093220542382345],
            [110.314594319086297, -7.093184357098791],
            [110.314588147319469, -7.093148166804388],
            [110.31457887629395, -7.093102926639675],
            [110.314569888236718, -7.093057686892439],
            [110.314545350381437, -7.093003378901116],
            [110.314511461249253, -7.092958102404981],
            [110.314486910027242, -7.092912839688381],
            [110.314453303873705, -7.092867563606625],
            [110.314447132119909, -7.092831373311319],
            [110.314459339824353, -7.092804255438955],
            [110.314478055761256, -7.092777147171283],
            [110.314502740750996, -7.092731957118266],
            [110.314512158913914, -7.092677699236848],
            [110.314505124879545, -7.092650552966077],
            [110.314501499822285, -7.092614366429339],
            [110.314487405001984, -7.09257816444159],
            [110.314469631618508, -7.092541957024708],
            [110.314469698506542, -7.092496730639733],
            [110.314462691231711, -7.092451493814602],
            [110.314445227576343, -7.09239719626086],
            [110.314420433553835, -7.092324797294608],
            [110.31439561278097, -7.092270488881459],
            [110.314353248099906, -7.092216154572968],
            [110.314310600462221, -7.092161819843763],
            [110.314243308005928, -7.092125539324074],
            [110.314179397773458, -7.092098309085285],
            [110.314122548321762, -7.0920801345597],
            [110.314019871730679, -7.092052847061524],
            [110.313927678290554, -7.092016529728255],
            [110.313831792917085, -7.091989252220524],
            [110.313764500528663, -7.091952971637207],
            [110.313693502808604, -7.091934776162064],
            [110.313651057888421, -7.09193471345366],
            [110.313626143475801, -7.091943721938539],
            [110.313594128121565, -7.091970810520517],
            [110.31354086351169, -7.092015958290752],
            [110.313494686442382, -7.092052071232821],
            [110.313455570124844, -7.092097239899761],
            [110.313409339450601, -7.09216953393229],
            [110.313352396238358, -7.092214676239569],
            [110.313249612473186, -7.092259750771105],
            [110.313150210882796, -7.092313875573214],
            [110.313029303827179, -7.092367968548237],
            [110.312983153497925, -7.092385990883278],
            [110.312958239050687, -7.092394999328581],
            [110.312919216506614, -7.09237685102327],
            [110.312894625249513, -7.09235872406147],
            [110.312880517157041, -7.092331567311413],
            [110.312851991192971, -7.092295343939832],
            [110.312812995480826, -7.09225910507885],
            [110.312774026595051, -7.092204775667351],
            [110.312749475584383, -7.092159512877591],
            [110.312738776510798, -7.09212331587409],
            [110.312728104260145, -7.092069028322749],
            [110.312717755209931, -7.091987605368334],
            [110.312717835674292, -7.091933333725073],
            [110.312725030520284, -7.0918519367257],
            [110.312741253453879, -7.091788643669392],
            [110.312751197492688, -7.091761522498756],
            [110.31275607496201, -7.091716303244869],
            [110.312766059225751, -7.091662046251363],
            [110.312751412045515, -7.091616798114019],
            [110.312736778277369, -7.091562504702334],
            [110.312721888365786, -7.091490120323655],
            [110.312702444185618, -7.091435819794992],
            [110.312682690223113, -7.091399609395268],
            [110.312633534650544, -7.09134526490783],
            [110.3125990396574, -7.09132712328296],
            [110.312579339355651, -7.091254731784372],
            [110.31254491143477, -7.091191363787075],
            [110.312510550588399, -7.091082769418892],
            [110.312486080134249, -7.09098323497841],
            [110.31247145982438, -7.090919896288006],
            [110.312417291433491, -7.090811272604338],
            [110.312358366317184, -7.090666460702243],
            [110.312289443537651, -7.090584951052144],
            [110.312230451397937, -7.090485365506697],
            [110.312166622038575, -7.090403863381676],
            [110.31212234412844, -7.09030429960788],
            [110.312132355256395, -7.090231952082083],
            [110.312152216480357, -7.090195800310329],
            [110.312230947830201, -7.090150690384017],
            [110.312305124896113, -7.090123664289935],
            [110.312359171201436, -7.090123744274223],
            [110.312433308019152, -7.090123853980665],
            [110.312551600782456, -7.090114983709743],
            [110.31259606650957, -7.090087913609705],
            [110.312660110444668, -7.090024691288058],
            [110.312737763489253, -7.089943398494994],
            [110.31276013111956, -7.089934386281872],
            [110.312777176039404, -7.089889185017284],
            [110.312810632914363, -7.089844008022522],
            [110.312838700047493, -7.089807868349287],
            [110.312822125765621, -7.089726436186745],
            [110.312760533292504, -7.089663028037944],
            [110.312687918621577, -7.0895905582856],
            [110.312626299369015, -7.089545240671434],
            [110.31250304749102, -7.089463650696241],
            [110.312413441610218, -7.08940020106412],
            [110.312351768759811, -7.089391064514942],
            [110.312262042183661, -7.089409022322929],
            [110.312127634043819, -7.089408823389357],
            [110.312015297138331, -7.089408657092956],
            [110.311891964886357, -7.089381338608955],
            [110.311779978077794, -7.089335946309793],
            [110.311718332106764, -7.089308719132544],
            [110.311662345431515, -7.089281500330303],
            [110.311561636964129, -7.089263260565725],
            [110.311482986297278, -7.089254098753209],
            [110.311393340318915, -7.089217784758429],
            [110.311359707858358, -7.089190599045311],
            [110.311337407406285, -7.08915438483052],
            [110.311331801849761, -7.089118195354877],
            [110.311331855578928, -7.089082014265506],
            [110.31133759545655, -7.089027751017515],
            [110.311365716365941, -7.088955430348469],
            [110.311388420654652, -7.08891023752819],
            [110.311410815113817, -7.088883134832201],
            [110.31142189100548, -7.088856015365161],
            [110.311421958155591, -7.088810789002066],
            [110.311416622127993, -7.08878364521855],
            [110.311427698016359, -7.088756525750995],
            [110.311461437885086, -7.088711349275223],
            [110.311483832329614, -7.088684246574147],
            [110.311495204603546, -7.088648082250626],
            [110.311506590303381, -7.088602872653733],
            [110.311512020330028, -7.088566699526557],
            [110.311517746747057, -7.088521481545426],
            [110.311523446307788, -7.088494354109928],
            [110.311517867594361, -7.088440074088977],
            [110.311501266719503, -7.08837673244753],
            [110.311490028732791, -7.088322444043007],
            [110.311479073711894, -7.088268156057517],
            [110.311495862566218, -7.088204863877674],
            [110.31151823012776, -7.088195851717909],
            [110.311557562085625, -7.088195909977838],
            [110.31160481702824, -7.088195979969178],
            [110.31167334794678, -7.088159900291816],
            [110.31172771724799, -7.08813284492614],
            [110.311764839187546, -7.088096713977251],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 111,
        id: 113,
        area: 1709365.2145410001,
        perimeter: 7055.425194,
        kode_pos: 50217,
        kode_kec: 3374010,
        kode_kel: 3374010004,
        kec: "Mijen",
        kel: "Polaman",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.327270837648413, -7.09257873407883],
            [110.327281025326457, -7.092515460990245],
            [110.327327293387896, -7.092416029471863],
            [110.327359321188808, -7.092379894569103],
            [110.327383439028537, -7.092334702889902],
            [110.327390052274438, -7.092262349902273],
            [110.327383675280629, -7.092171887464336],
            [110.327385464957231, -7.092108572812368],
            [110.32741584742476, -7.092036254238311],
            [110.3274643791366, -7.091936825974491],
            [110.32750123045102, -7.09189165271939],
            [110.327539766438179, -7.091855527220099],
            [110.327587614085871, -7.091837505836949],
            [110.32767566926556, -7.091801451997817],
            [110.3277348749304, -7.091756311072228],
            [110.327778030172823, -7.091656874985202],
            [110.327810123461362, -7.091575513529597],
            [110.327834267440011, -7.091512231212273],
            [110.327903107032583, -7.091458058876671],
            [110.327967123064198, -7.091412924873674],
            [110.328016773340735, -7.091322543472445],
            [110.328034395934097, -7.091268297029671],
            [110.328039337469846, -7.091177850956803],
            [110.32802497173013, -7.091132603569019],
            [110.327798504736549, -7.090806644383848],
            [110.327729861971591, -7.090725137173908],
            [110.327654671657939, -7.090670756445798],
            [110.32756389198714, -7.090634443788543],
            [110.327467953339522, -7.090643350253548],
            [110.327376890725844, -7.09060703715202],
            [110.327325831332971, -7.090498419382063],
            [110.327315650294153, -7.090299407580328],
            [110.327277646743369, -7.090163672745622],
            [110.327160965602474, -7.090036869312549],
            [110.327009440361309, -7.089937151354313],
            [110.326916785169502, -7.089828473307227],
            [110.326767872404105, -7.08968353246312],
            [110.326601606893036, -7.089601883673889],
            [110.326438684475875, -7.089556420954951],
            [110.32628225715834, -7.089520012917712],
            [110.32610006687618, -7.089492612789465],
            [110.325961209763761, -7.089438139489847],
            [110.325924529456188, -7.089365723734433],
            [110.325918063785082, -7.089284306485804],
            [110.325952220016134, -7.08900395113885],
            [110.325985912255092, -7.088795957663169],
            [110.326023052612129, -7.088551787903209],
            [110.326096938894509, -7.088334807373463],
            [110.32616751537094, -7.088253501821912],
            [110.326268290196722, -7.088226511951389],
            [110.326408963022061, -7.088199579881333],
            [110.326589546976422, -7.088163660273337],
            [110.3266807009704, -7.088136656370054],
            [110.326895049560761, -7.088037468302392],
            [110.327026167135855, -7.08796529559193],
            [110.327110852256226, -7.087911146267238],
            [110.327190648237902, -7.087911261778705],
            [110.327311356004486, -7.087992844384899],
            [110.327381774822129, -7.088020082259749],
            [110.327442342353095, -7.088011124581508],
            [110.327482602010861, -7.087956910901152],
            [110.327503363338408, -7.087884578361433],
            [110.327516293453471, -7.087748917233351],
            [110.327546984742625, -7.087658508410822],
            [110.327607696457079, -7.087550052366067],
            [110.327671738159367, -7.087486827740561],
            [110.327834804211705, -7.087432791628847],
            [110.327972364139399, -7.087405854552544],
            [110.328090155867073, -7.087351752889619],
            [110.328277404772933, -7.087207298328082],
            [110.328400315964714, -7.087135113327329],
            [110.32848352049713, -7.087126188204545],
            [110.328598673784313, -7.087135399860777],
            [110.328700784550477, -7.087162683303933],
            [110.328825624073417, -7.087126682268043],
            [110.328912006636656, -7.087072535030338],
            [110.32915788106483, -7.086891983403575],
            [110.329252186539193, -7.086837847527581],
            [110.329537261966152, -7.086747805497048],
            [110.329818619565415, -7.086684893902379],
            [110.329911470915576, -7.08665789176627],
            [110.330034224984843, -7.086694249996593],
            [110.330181340094526, -7.086712552641765],
            [110.330280403583828, -7.086694604708867],
            [110.330483044913464, -7.08666776060716],
            [110.33055354208598, -7.086640726144693],
            [110.330585582267233, -7.086595545639981],
            [110.330625571532167, -7.08653228592093],
            [110.330665521634714, -7.086496162126551],
            [110.330710269066174, -7.086469090563194],
            [110.330817242431706, -7.086460199225114],
            [110.330930684827493, -7.086478453159184],
            [110.331009039718992, -7.086496656576707],
            [110.331058558390495, -7.086496727831543],
            [110.331133839813404, -7.086487790818159],
            [110.331456131826471, -7.086687251656922],
            [110.331492582041221, -7.086723485393594],
            [110.331517996664687, -7.086759703259275],
            [110.331578794831373, -7.086786926670869],
            [110.332003845921378, -7.086760401636963],
            [110.332224340026855, -7.086715491759526],
            [110.332400473831399, -7.086625291414201],
            [110.332502688908363, -7.086580211528256],
            [110.332576285568479, -7.086562226529005],
            [110.332684956743989, -7.086553337197229],
            [110.332847970007819, -7.086535480494299],
            [110.33292157965883, -7.086508450124497],
            [110.333036476029591, -7.086499569641163],
            [110.333065351356666, -7.086490565733137],
            [110.333171039927166, -7.08639121867572],
            [110.333231698202056, -7.086318943010025],
            [110.333315006561236, -7.086237654481637],
            [110.333426855244042, -7.086183542856357],
            [110.333644210410782, -7.086156718434016],
            [110.334308689848072, -7.085904400947365],
            [110.334404614531636, -7.085904538281156],
            [110.334500578140577, -7.085877539643868],
            [110.334580721721352, -7.085832427672839],
            [110.334670203086688, -7.085787329048808],
            [110.334756545897861, -7.085760316587623],
            [110.334881036918205, -7.085769540028467],
            [110.3349515337555, -7.085742504861305],
            [110.335012178716312, -7.085679274245628],
            [110.335130496374902, -7.085652307441271],
            [110.335300030425159, -7.08562541382724],
            [110.33547577670447, -7.085607574365729],
            [110.335578183605421, -7.085625811391601],
            [110.335785852971583, -7.085644198781081],
            [110.335868722462664, -7.085671453172177],
            [110.335913674837784, -7.085698653394125],
            [110.336193988276491, -7.0857714163896],
            [110.33636997875908, -7.085780712957863],
            [110.336542586624645, -7.085780959293936],
            [110.336724493506622, -7.085808354850725],
            [110.336913152765035, -7.085862895988338],
            [110.337098455279872, -7.085890296246406],
            [110.337309250408978, -7.085899642087433],
            [110.337453279003412, -7.085899847353615],
            [110.337667160935851, -7.085927288114765],
            [110.337798173210828, -7.085927474732078],
            [110.337979321724603, -7.085891551336464],
            [110.338036815012401, -7.085855451828992],
            [110.338097472510569, -7.085783175449684],
            [110.338132997827316, -7.085674681916056],
            [110.338184124833973, -7.085539074558035],
            [110.338273979118938, -7.085430658335317],
            [110.338360437798627, -7.085322237259113],
            [110.338440349587614, -7.08524094287687],
            [110.338494755951899, -7.085186748223038],
            [110.33855880884829, -7.085114476597368],
            [110.338642141911407, -7.085015096353164],
            [110.338702799200576, -7.084942819873366],
            [110.338750967416431, -7.084897661654981],
            [110.338882030978326, -7.084861666637779],
            [110.339166344687712, -7.084907297494175],
            [110.339500730112178, -7.084962044652798],
            [110.33975964162056, -7.084962412347296],
            [110.340050515144682, -7.084971870606778],
            [110.340190890730227, -7.084953979121726],
            [110.340459320173977, -7.085026722704604],
            [110.340644571287285, -7.085090302853549],
            [110.341136837954267, -7.085154318060868],
            [110.341362617233983, -7.085172728620857],
            [110.341701517838231, -7.085236525981258],
            [110.342011298946346, -7.085282191198267],
            [110.342308668191663, -7.085300702608953],
            [110.342554538069621, -7.085319141017634],
            [110.342656983774191, -7.085310240507551],
            [110.342660173261564, -7.085255972914059],
            [110.342628685310828, -7.084912205081975],
            [110.342635283170509, -7.084848896955606],
            [110.342667232419345, -7.08486703282138],
            [110.34277583916419, -7.084903367738789],
            [110.34287173818656, -7.084921593972346],
            [110.342929192597481, -7.084912629810926],
            [110.34303136805481, -7.084894683476305],
            [110.343111510763933, -7.084849569940309],
            [110.343173801150755, -7.084822521875894],
            [110.3432409275146, -7.084777389929327],
            [110.343317713025101, -7.08470513555696],
            [110.343417135584772, -7.084632913132367],
            [110.344036954349022, -7.084479231151355],
            [110.343989590238138, -7.084538130602692],
            [110.34412962472534, -7.084642963936072],
            [110.344245741270512, -7.084708188319022],
            [110.34439762568266, -7.084764504177448],
            [110.344777432417942, -7.084837641701776],
            [110.344998728974829, -7.084867654372174],
            [110.345173817642305, -7.084867900967422],
            [110.345500814219321, -7.08490796254115],
            [110.345785059995038, -7.084809359510864],
            [110.345997384086857, -7.084853835048935],
            [110.346141126248398, -7.085353462484459],
            [110.346151862601786, -7.085467780611276],
            [110.346121779614336, -7.085549383326818],
            [110.346059058018213, -7.085592839126763],
            [110.34601815429113, -7.085619996601895],
            [110.346077890713445, -7.085761598652384],
            [110.346103418344825, -7.085806371262482],
            [110.346101824306515, -7.08580859394733],
            [110.346185691561985, -7.085963342611524],
            [110.346247839670667, -7.086058829655356],
            [110.346268539152931, -7.086102410776352],
            [110.346269504929623, -7.086119552889484],
            [110.346271577302048, -7.086156336590789],
            [110.34628767458841, -7.086519292682132],
            [110.346285408546592, -7.086654093349813],
            [110.346290432188169, -7.086772313023813],
            [110.346315231274644, -7.086853230207314],
            [110.346345173834578, -7.086967337109236],
            [110.346332480588785, -7.087135305600481],
            [110.346313556134106, -7.087305339232248],
            [110.346271753736218, -7.087504375361791],
            [110.346213436097614, -7.08763702328347],
            [110.34611874294886, -7.087800728603499],
            [110.346060524636982, -7.087862863858557],
            [110.345980524876779, -7.087912525025906],
            [110.345843417909833, -7.087972475335816],
            [110.345728661777684, -7.088016380387134],
            [110.345708398706222, -7.088024132920485],
            [110.345664739301668, -7.088067623466493],
            [110.345602368709521, -7.088129752809373],
            [110.345556624412069, -7.088179462122871],
            [110.345458983599883, -7.088224950540324],
            [110.345367591543734, -7.088255930402111],
            [110.345299059936437, -7.088270351207582],
            [110.345236794670228, -7.088257820066897],
            [110.345054060298423, -7.08828452336851],
            [110.34474985387871, -7.088325572694078],
            [110.344666804240362, -7.08832960341264],
            [110.344405187032436, -7.088349973509663],
            [110.344330432974431, -7.088360237582161],
            [110.344222464335402, -7.088368380875203],
            [110.34409788547309, -7.08837753765471],
            [110.343988883183783, -7.08838256857748],
            [110.34389286748619, -7.088379840666026],
            [110.343734524945077, -7.088408133297762],
            [110.343604699337007, -7.088457205190674],
            [110.34350863226085, -7.088490770448872],
            [110.343454072389108, -7.08853476384258],
            [110.34337873687592, -7.088589097397994],
            [110.343331962337999, -7.088633101774145],
            [110.34326186108548, -7.088656334142591],
            [110.343183884869489, -7.088594045484392],
            [110.342856596598622, -7.088686782697684],
            [110.342622117791166, -7.088963161155442],
            [110.342383759359208, -7.089223944678396],
            [110.342266522474347, -7.089360185119464],
            [110.342236728626517, -7.089504412794825],
            [110.342245420135356, -7.089529441292259],
            [110.342327474268501, -7.08976572829741],
            [110.342422310652111, -7.089936029841935],
            [110.342520990836093, -7.090189441788053],
            [110.342624915798154, -7.090413243286735],
            [110.34274646206876, -7.090626423731035],
            [110.342868083921445, -7.090786352133259],
            [110.342989695075318, -7.090953887941652],
            [110.343103680009534, -7.09112902038914],
            [110.343270962954264, -7.091311835605237],
            [110.343445872336389, -7.091487054102397],
            [110.343620727994065, -7.091700309705062],
            [110.343757592264652, -7.091852652008482],
            [110.343955413257319, -7.091982258024261],
            [110.344138207781654, -7.091967301238708],
            [110.344278459461478, -7.091958110612637],
            [110.344402932464476, -7.091993826558594],
            [110.344522813746337, -7.092131714261511],
            [110.344549202072386, -7.092340550563673],
            [110.344482292080897, -7.092482617212802],
            [110.344373396501808, -7.09259905142856],
            [110.344353187441669, -7.092645219497133],
            [110.344308007079093, -7.092748435046717],
            [110.344244369567278, -7.092926427344298],
            [110.344073679173235, -7.093167586550149],
            [110.343883107749093, -7.093460163534851],
            [110.343732202569086, -7.09371718004616],
            [110.343561304189237, -7.094104761771776],
            [110.343450003493089, -7.094369748889585],
            [110.343414068633535, -7.094567567075751],
            [110.343429699948643, -7.094701929612306],
            [110.343429705137325, -7.094701935069806],
            [110.343231393374623, -7.094595704780975],
            [110.343044034981929, -7.094463970024099],
            [110.342856658055879, -7.094345382147902],
            [110.342665958338543, -7.094249796704053],
            [110.34244892733247, -7.094160747393505],
            [110.342212131561595, -7.0940881037098],
            [110.342080540025634, -7.09407477033495],
            [110.341971882277235, -7.094130491019133],
            [110.341896089719199, -7.094212552191795],
            [110.341853181025087, -7.094307806924596],
            [110.341859626167661, -7.094403131598659],
            [110.3419186827619, -7.09452153803054],
            [110.342010557965224, -7.094699152330467],
            [110.342066268368896, -7.094856994921479],
            [110.34207909340148, -7.095093658575143],
            [110.342068041872153, -7.095349743193714],
            [110.342025167850551, -7.095449181241258],
            [110.34199191906238, -7.095548632923218],
            [110.341935209438731, -7.095629960660587],
            [110.341883310285965, -7.095711295207387],
            [110.341793776086718, -7.095792576395757],
            [110.341671134345972, -7.095873810621699],
            [110.341581625788905, -7.09593700110988],
            [110.341562641074717, -7.095955064875931],
            [110.341505969893561, -7.096009256569861],
            [110.341373900014517, -7.096153794742638],
            [110.341303041686814, -7.096235102312402],
            [110.341255708464203, -7.096289307217639],
            [110.341216864343409, -7.096343524162786],
            [110.341187113858069, -7.096370617977448],
            [110.341153941995728, -7.096415797621505],
            [110.341118249121806, -7.096442882997875],
            [110.341076330892747, -7.096469959535494],
            [110.341031312849893, -7.096487986325744],
            [110.340962551016105, -7.096487888720157],
            [110.34089097234758, -7.096478741762337],
            [110.340840346369262, -7.096460579200072],
            [110.340810402981276, -7.096424355313867],
            [110.340732740571639, -7.096315700924353],
            [110.340688147106263, -7.096234229514692],
            [110.340634511480275, -7.096143699917739],
            [110.340610574873367, -7.096062257838906],
            [110.340589776694088, -7.09596272953138],
            [110.340575242487049, -7.095836074091792],
            [110.34055138314902, -7.095700360064894],
            [110.340533723410402, -7.095582745527945],
            [110.340512938134808, -7.095474171894027],
            [110.340488975823234, -7.095410820460575],
            [110.340442105841191, -7.095338391145159],
            [110.340399184572703, -7.095275012777954],
            [110.340338719194591, -7.095211609484129],
            [110.340281083529447, -7.095148210204092],
            [110.340191999612315, -7.095111902260249],
            [110.340114234494294, -7.095075610385782],
            [110.340050862188249, -7.095066474981171],
            [110.339990332465504, -7.095048298266536],
            [110.339895254690077, -7.095048163117125],
            [110.339739608541549, -7.095056987171577],
            [110.339607165895885, -7.095065844173623],
            [110.339448690049281, -7.095074664105118],
            [110.339313404807569, -7.095092562319421],
            [110.339212384675406, -7.095092418574374],
            [110.33910310682171, -7.095128444415766],
            [110.339010832935472, -7.095146403754823],
            [110.338846080110812, -7.095191395901236],
            [110.338636322179966, -7.095245369226497],
            [110.338431348861945, -7.095317439947534],
            [110.338206567609973, -7.095389482340842],
            [110.337981503327427, -7.095461524215183],
            [110.337791836057804, -7.095515525782303],
            [110.337686815498145, -7.095542512004547],
            [110.337551813067435, -7.095560410089891],
            [110.337331932643181, -7.095569141668803],
            [110.337257189817123, -7.095596171001407],
            [110.337132013660138, -7.095668355006979],
            [110.336982219091595, -7.095740503821208],
            [110.336877172556854, -7.095785580486227],
            [110.336782325866949, -7.095821626359384],
            [110.336742118248722, -7.095839659595969],
            [110.336677318149057, -7.095839567023787],
            [110.336550869479296, -7.095812250349019],
            [110.336441129001628, -7.095775912184579],
            [110.336368148702945, -7.095757717208837],
            [110.336307374983875, -7.095712403665327],
            [110.336266988056082, -7.095658073923151],
            [110.336238807709847, -7.09557662562309],
            [110.336214588947158, -7.095495182985357],
            [110.336170022301204, -7.095395620586748],
            [110.336141816064483, -7.095332262915873],
            [110.336068669531684, -7.095232659648613],
            [110.335963560450935, -7.095123965330643],
            [110.33586211705564, -7.095024321570329],
            [110.335752763531673, -7.094915621141903],
            [110.335659461491687, -7.094861215661964],
            [110.335481063585775, -7.094752416394677],
            [110.335363735188722, -7.094679885810789],
            [110.335209633833017, -7.09459825722524],
            [110.335079841907728, -7.094534754067634],
            [110.335029216421418, -7.094516590910986],
            [110.33492234502971, -7.094453120533448],
            [110.334833004755168, -7.094398720577617],
            [110.334769118713496, -7.094353402402302],
            [110.334574348009596, -7.094217443370088],
            [110.334334946332774, -7.094027148302816],
            [110.334128844163757, -7.093900218196382],
            [110.333492145428721, -7.093519400992817],
            [110.333206350821811, -7.093319993504585],
            [110.332948531245734, -7.093147762028702],
            [110.332614619556523, -7.092957330441421],
            [110.332273054997344, -7.092775932965628],
            [110.332034360582753, -7.09268513648783],
            [110.331949509392501, -7.092657878476444],
            [110.331884156903882, -7.092648739153351],
            [110.331775497331193, -7.092648582851087],
            [110.331690903051168, -7.092639415820106],
            [110.33166516603977, -7.092630333462124],
            [110.331606617830317, -7.092612158562935],
            [110.33154439104743, -7.092593978363093],
            [110.331474293361566, -7.092539605516794],
            [110.331429366754634, -7.092494314218051],
            [110.331279720204805, -7.0922679656241],
            [110.33118877437181, -7.092150245437598],
            [110.331150343025286, -7.09211400879582],
            [110.331067242018662, -7.092050571848363],
            [110.33085577838979, -7.091914587394671],
            [110.330750323061636, -7.091851118197356],
            [110.330689794224909, -7.091832940341029],
            [110.330621046287789, -7.091823795960885],
            [110.33053614310667, -7.091832718942689],
            [110.330058272497496, -7.091985800578088],
            [110.329863782549353, -7.092048837330498],
            [110.329726195117757, -7.092093865454005],
            [110.329649511013557, -7.092093754801949],
            [110.329571155271822, -7.092075551075832],
            [110.329459501004251, -7.091993982003861],
            [110.329354137482056, -7.091867195369404],
            [110.329253906684187, -7.09171328015445],
            [110.329192015462482, -7.091658918847223],
            [110.329121892037023, -7.091622636292693],
            [110.329032217728766, -7.091604416139512],
            [110.328925256265478, -7.091604261645605],
            [110.328567211531663, -7.091667061570337],
            [110.328396207828021, -7.091730131646726],
            [110.328086649719054, -7.091919635784524],
            [110.327939619382761, -7.092037012280677],
            [110.327722578034681, -7.092235695297508],
            [110.32756416321179, -7.092398281782674],
            [110.327479437896585, -7.092479567011974],
            [110.327431563927789, -7.092515678979677],
            [110.327367560814565, -7.092551767586229],
            [110.327270837648413, -7.09257873407883],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 112,
        id: 114,
        area: 5218317.4687649999,
        perimeter: 11267.175655,
        kode_pos: 50187,
        kode_kec: 3374160,
        kode_kel: 3374091009,
        kec: "Ngaliyan",
        kel: "Gondoriyo",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.302274875363196, -6.977412002726344],
            [110.302942339683938, -6.977407832065055],
            [110.303295076416859, -6.978231890891223],
            [110.303577784882521, -6.97854050336897],
            [110.304042617522882, -6.97878875575416],
            [110.304719555671809, -6.979219206024841],
            [110.305219628789047, -6.97957360931908],
            [110.30584120807562, -6.979827143261948],
            [110.305743406835788, -6.980219474139017],
            [110.305713139396204, -6.980625974826846],
            [110.305780927560733, -6.980643013960708],
            [110.305839034511962, -6.981477364136069],
            [110.305660487784181, -6.981841298117551],
            [110.305605127217802, -6.982019080148524],
            [110.30620381753188, -6.983932885973529],
            [110.306432534507294, -6.984264765935915],
            [110.306563605355819, -6.984480935847706],
            [110.306639515288637, -6.98473937308687],
            [110.308543959085469, -6.985554338358021],
            [110.309474331170208, -6.986752750562449],
            [110.31007404032745, -6.986851960115725],
            [110.311451322005141, -6.987200475291775],
            [110.311927365612775, -6.988545034081774],
            [110.311958731230462, -6.989132240173217],
            [110.311947559367013, -6.989439362572777],
            [110.312667670443858, -6.989512936202535],
            [110.313011387597925, -6.989540815937649],
            [110.313856797666475, -6.989805204382969],
            [110.314534351284863, -6.989808970770659],
            [110.314958557055604, -6.989778829469389],
            [110.315066232554614, -6.989528939811085],
            [110.315441415616391, -6.989625760475019],
            [110.31599088817255, -6.989771283830398],
            [110.316291766966032, -6.989862173891672],
            [110.316439738511633, -6.989464393640264],
            [110.316575426646281, -6.989148003557391],
            [110.316654915684694, -6.988958166688838],
            [110.316743118825201, -6.988749227440814],
            [110.317187192398322, -6.98878519118322],
            [110.317622051950053, -6.988841978656215],
            [110.317985774605759, -6.988905822578476],
            [110.318215696890221, -6.988960427329601],
            [110.318564077431972, -6.989069475342975],
            [110.318783506240095, -6.989142155344513],
            [110.3189498308206, -6.989195736509054],
            [110.319237317166611, -6.989196151907312],
            [110.31940973972327, -6.989244275183385],
            [110.31970183756745, -6.989369169943599],
            [110.320105528044166, -6.989511958448539],
            [110.32073169343586, -6.989581302224613],
            [110.320851479553937, -6.989581474885086],
            [110.320917746382477, -6.989614304701511],
            [110.320961356202915, -6.989675891205534],
            [110.320994977231237, -6.989777155995271],
            [110.321044425671957, -6.989922105541101],
            [110.321101859453591, -6.990039281711353],
            [110.321150827520725, -6.990140559842588],
            [110.321112328946754, -6.990394087960484],
            [110.321132524908151, -6.990643514701142],
            [110.321281861896978, -6.990940072941528],
            [110.321445087454904, -6.991127767123309],
            [110.321559844759093, -6.991225933984873],
            [110.321652458582747, -6.99129004158044],
            [110.322289580437214, -6.991670863236059],
            [110.323165563797076, -6.992187707656921],
            [110.323726664422637, -6.992514146228845],
            [110.323787411268569, -6.992568505554265],
            [110.324303335672141, -6.993030558944633],
            [110.324641863574939, -6.993345887891492],
            [110.324824763744118, -6.993173982070098],
            [110.324933380908917, -6.993132817431442],
            [110.325038477630585, -6.993143298142201],
            [110.325167663728493, -6.993177916877253],
            [110.325312328467007, -6.99323321792227],
            [110.325441442982822, -6.993317765228182],
            [110.325672116918511, -6.992978248542361],
            [110.325873933280221, -6.992734315302437],
            [110.325982621364858, -6.992870148730206],
            [110.326070771613118, -6.992951683071851],
            [110.326169402411736, -6.993024187038094],
            [110.326231564165468, -6.993078548104249],
            [110.326614989604124, -6.993413774660035],
            [110.326687644937266, -6.99345005996516],
            [110.327341042370747, -6.993731399747698],
            [110.328335612791079, -6.994221374250071],
            [110.328276895935048, -6.994329729253184],
            [110.328197450538809, -6.994293434483446],
            [110.328134117972141, -6.994266208049845],
            [110.328046699465389, -6.994266083316581],
            [110.327976164912769, -6.994329300173806],
            [110.327907288992023, -6.994419655461648],
            [110.327825373411898, -6.994528082839961],
            [110.327710022904398, -6.994672643897613],
            [110.327643384268285, -6.994781093046291],
            [110.327607582586609, -6.994889586218396],
            [110.327565634544598, -6.994943798461419],
            [110.32749623145655, -6.995007016845159],
            [110.327371892377982, -6.995106338171281],
            [110.327289732476686, -6.995187629009807],
            [110.32723411414409, -6.995305139184499],
            [110.327203069972214, -6.995449820536587],
            [110.327216237194506, -6.995540292921468],
            [110.327264742096716, -6.995648906514654],
            [110.327306727174431, -6.995766556145891],
            [110.327392934765797, -6.996019949312052],
            [110.327417122531344, -6.996119482796121],
            [110.327407952942337, -6.996200877909247],
            [110.327377025250485, -6.996264151220901],
            [110.327303738885831, -6.996273091862884],
            [110.327195371809538, -6.996281982358678],
            [110.327102307717084, -6.996272803989905],
            [110.326927148223518, -6.996299689661092],
            [110.326752876316675, -6.996299440467129],
            [110.326637745341841, -6.996290230448674],
            [110.326584597348045, -6.996263018359321],
            [110.326522692147691, -6.996226748381336],
            [110.326467306824185, -6.996181442368659],
            [110.326381032591527, -6.996172273574517],
            [110.326292533992572, -6.996135965516629],
            [110.326161882000264, -6.996099597110426],
            [110.326059751931879, -6.996099450919027],
            [110.325880322900929, -6.996144420797842],
            [110.325743021299871, -6.996207541650714],
            [110.325689730615878, -6.996279828154519],
            [110.325618357054097, -6.996532995820584],
            [110.32552040292525, -6.99677708002821],
            [110.325395803269444, -6.997057307429915],
            [110.325364797334629, -6.997174852581888],
            [110.325366660823576, -6.997256263429152],
            [110.325404453815736, -6.997337725770747],
            [110.325483886458898, -6.997383066370239],
            [110.325576937724307, -6.997401290411233],
            [110.325748406860612, -6.997383445357104],
            [110.325921290537451, -6.997365602264995],
            [110.325987774276925, -6.997365697470348],
            [110.326082779989193, -6.997402014919849],
            [110.326169285458846, -6.997447365537214],
            [110.326206821640298, -6.99751073675194],
            [110.326208955210092, -6.997601193349555],
            [110.326184469320523, -6.997709702548077],
            [110.326117726070891, -6.99789051407604],
            [110.326061284292848, -6.997989932154327],
            [110.325979367616313, -6.99809835909494],
            [110.325846231206327, -6.998215758005883],
            [110.325697586603184, -6.998296953240704],
            [110.325626755217755, -6.998369214576176],
            [110.325595479295302, -6.998477713994435],
            [110.325596712065192, -6.998604350700993],
            [110.325636986458306, -6.998731043353573],
            [110.325648764822091, -6.998803423054262],
            [110.32567120289923, -6.998939135499335],
            [110.325657441420418, -6.999065750719044],
            [110.32564941605024, -6.999138102040694],
            [110.325625469771097, -6.999264702661986],
            [110.325586350185247, -6.999318918713814],
            [110.325550368581489, -6.999355048554078],
            [110.325457200040375, -6.999418232475408],
            [110.325368853948746, -6.999472377937535],
            [110.325319279607868, -6.999517533621308],
            [110.325274231830534, -6.999562695789516],
            [110.325180715330632, -6.999671105907631],
            [110.325132490527508, -6.999761490260874],
            [110.324964334380184, -7.000032609590921],
            [110.324902516758897, -7.000132019761423],
            [110.324874993248912, -7.000385250094998],
            [110.324865706051042, -7.000548053083044],
            [110.324870925340946, -7.000656604781617],
            [110.324900848945717, -7.000701874474153],
            [110.324987598768359, -7.000774361752431],
            [110.325060833857464, -7.000801602873772],
            [110.325123884139586, -7.000828829374792],
            [110.325162860950726, -7.000874112040215],
            [110.325261428159976, -7.000991842981771],
            [110.325315360334315, -7.001064283139999],
            [110.325340396542188, -7.001163817909618],
            [110.32536576764609, -7.001227171753083],
            [110.32537166981308, -7.001254316271247],
            [110.325359748533828, -7.001281435229831],
            [110.325331174436755, -7.001281394252016],
            [110.325260742382227, -7.001272247887518],
            [110.325184382180353, -7.001254047658181],
            [110.325122990314426, -7.001253959592055],
            [110.325079408894524, -7.001262942420896],
            [110.325031570795531, -7.001280964489062],
            [110.324993891517266, -7.001317091829833],
            [110.324932382623658, -7.001398411725408],
            [110.324881380460951, -7.001452610637794],
            [110.324821312098891, -7.001515841881572],
            [110.324760921790045, -7.001606208704422],
            [110.324738493700934, -7.00166044861144],
            [110.324731085960138, -7.00169661937829],
            [110.324733798018102, -7.001778031422115],
            [110.324711291870074, -7.001886543314288],
            [110.324678074069595, -7.001967903776166],
            [110.324624538611303, -7.002013053664073],
            [110.324549553854396, -7.002021991346937],
            [110.324497189063948, -7.002040006850909],
            [110.324458095120775, -7.002076132106446],
            [110.324411593396221, -7.002148428116189],
            [110.324344117095848, -7.002247830039757],
            [110.324269067215269, -7.002301994325378],
            [110.324182148014557, -7.002347096194982],
            [110.324072582781511, -7.002401210853257],
            [110.324015665290602, -7.00243731045227],
            [110.323972044678371, -7.002473429154627],
            [110.323939444623676, -7.00251860904085],
            [110.32390800226149, -7.002545699891665],
            [110.323851110792361, -7.002563708808778],
            [110.323731155878079, -7.00256353635538],
            [110.32368927179516, -7.002572521481316],
            [110.323648776214725, -7.002599599295546],
            [110.323614234823779, -7.002617640320508],
            [110.323570640228311, -7.002635668323028],
            [110.323476121367023, -7.002653623077538],
            [110.323387839611584, -7.002662541437383],
            [110.323339731439432, -7.002671517577106],
            [110.323311401073852, -7.00269861286065],
            [110.323288728957053, -7.002725716283],
            [110.323275366879983, -7.002770923793312],
            [110.323281164638374, -7.002870430952346],
            [110.323294383313524, -7.002924722053302],
            [110.323326004356701, -7.002969994283295],
            [110.323462429640927, -7.003123961445284],
            [110.323534133112503, -7.003232608750888],
            [110.323573070838222, -7.003305027533809],
            [110.323617523487385, -7.003476953067456],
            [110.323613972147626, -7.003585492128277],
            [110.323603426167921, -7.003639749044463],
            [110.323568845596057, -7.00368492604325],
            [110.323515026918344, -7.003730075365667],
            [110.323424982299301, -7.003784217911854],
            [110.32334258935667, -7.003829326101724],
            [110.323248044167229, -7.003865371441221],
            [110.323193981648586, -7.003883384331383],
            [110.323150373876956, -7.003910457608764],
            [110.32313527513638, -7.003982798647816],
            [110.323130052274436, -7.004073244593316],
            [110.323109064511797, -7.004109395768809],
            [110.323045896554476, -7.004163576916381],
            [110.322966589468621, -7.004226780164047],
            [110.322888440110546, -7.004271894371662],
            [110.322819317873893, -7.004335112255801],
            [110.322771414137193, -7.004398360686992],
            [110.322756380567256, -7.004425475071969],
            [110.32274728818129, -7.004452598013111],
            [110.322742683260657, -7.00450686345336],
            [110.322723662791901, -7.004552062785145],
            [110.32257520716793, -7.004696574458742],
            [110.322477471365204, -7.004786887092208],
            [110.322312658825297, -7.004895193720064],
            [110.322269077031919, -7.004904176249508],
            [110.322086366940866, -7.00486773148673],
            [110.322023316354134, -7.004840504547978],
            [110.321932013597376, -7.004786100829537],
            [110.321897498118332, -7.004786051055119],
            [110.32186750925969, -7.004786007806437],
            [110.321722013164703, -7.004840070011472],
            [110.321554153774997, -7.004903145244283],
            [110.321498663442341, -7.004930201204052],
            [110.321450746395826, -7.005002494800153],
            [110.321392130915882, -7.005038591578311],
            [110.321321646122442, -7.005065625873518],
            [110.321186617706132, -7.005119703007165],
            [110.321066570616892, -7.005182847073905],
            [110.321042753506617, -7.005218994050192],
            [110.321040912090453, -7.005318490153286],
            [110.320984363562445, -7.005490269985995],
            [110.320930261526712, -7.005535418556738],
            [110.320856921316519, -7.005580539331028],
            [110.320778823913287, -7.005589471854247],
            [110.320652927104831, -7.00558928995829],
            [110.320486652150294, -7.005534777626901],
            [110.32041625892154, -7.005498494520693],
            [110.320354932053263, -7.005453179170144],
            [110.320323607184534, -7.005398861843817],
            [110.320266497848849, -7.005371643261551],
            [110.320205131709315, -7.005353463858768],
            [110.320070269721654, -7.005362314190682],
            [110.319870994953078, -7.004973076355093],
            [110.319815378913347, -7.004891587845518],
            [110.319754387532825, -7.004810091554501],
            [110.319617292415899, -7.004728485144052],
            [110.319471683715378, -7.004664957055354],
            [110.319381756555401, -7.004637690881007],
            [110.318916909231405, -7.0044561110413],
            [110.318477794278323, -7.004283613407019],
            [110.318193341202189, -7.004174657017395],
            [110.318028494808956, -7.004111100622409],
            [110.317237785508837, -7.003892865203779],
            [110.317066688971664, -7.00384739008885],
            [110.31687185411657, -7.003783789762821],
            [110.316649010953071, -7.003720148663642],
            [110.316477644811968, -7.003665627619472],
            [110.316190041113444, -7.003583801517124],
            [110.315991206204913, -7.003547331031344],
            [110.315506695525215, -7.003465218098983],
            [110.314781788645504, -7.003328482505996],
            [110.314511956198245, -7.003282862638629],
            [110.314176823912334, -7.00320096608286],
            [110.313797287757652, -7.00310995918583],
            [110.313373466845292, -7.002928434059265],
            [110.313054810154014, -7.00280133415636],
            [110.312664886325422, -7.002656039258679],
            [110.31252745734777, -7.00261061177302],
            [110.311924797475356, -7.002455959986013],
            [110.311187808120323, -7.002264929471431],
            [110.310305186504237, -7.002028457733511],
            [110.309879887162126, -7.001892154119789],
            [110.309732826470693, -7.001855757055275],
            [110.309488081623414, -7.001873488497535],
            [110.309168714731584, -7.001845883619197],
            [110.308966433203523, -7.001845586506009],
            [110.30879020614411, -7.001827236949548],
            [110.308531974821236, -7.001781630833281],
            [110.308303718816802, -7.00174511397685],
            [110.308062488932521, -7.001681441971399],
            [110.307794086334496, -7.001626775184248],
            [110.307592384118692, -7.001617433023132],
            [110.307498484344364, -7.001599204165915],
            [110.307238056770174, -7.001508367540191],
            [110.307111056462958, -7.00149008986309],
            [110.306896340447622, -7.001480728242268],
            [110.306697750369494, -7.001471390290468],
            [110.30635937563261, -7.001479936798086],
            [110.306254994894346, -7.001470737561751],
            [110.306138462361716, -7.001452475063483],
            [110.305875758547941, -7.001370679674373],
            [110.30539641215168, -7.001243337751138],
            [110.305249931346381, -7.001197894878998],
            [110.305103733484074, -7.001152452381838],
            [110.304950153206548, -7.001125089562752],
            [110.30481496213558, -7.001097753869256],
            [110.304038155504813, -7.000861427226665],
            [110.303688975824983, -7.000716185570342],
            [110.303504100094045, -7.00061641349159],
            [110.303281085256998, -7.000480403627391],
            [110.303100736276221, -7.00038063810982],
            [110.302923229900188, -7.000271831431265],
            [110.302641356741901, -7.000144779400606],
            [110.302480784860705, -7.000063133600159],
            [110.302337780310566, -6.999963423179289],
            [110.302276469524671, -6.999909060412512],
            [110.302200501202307, -6.999818494665513],
            [110.302130420255068, -6.99976411887673],
            [110.302023008708517, -6.999700642397456],
            [110.301813911360057, -6.999627969720127],
            [110.30166150257628, -6.999094070364341],
            [110.301589804512062, -6.998795468784526],
            [110.301544659602328, -6.998524042532761],
            [110.301475600962945, -6.997782224745174],
            [110.30149858324053, -6.997547080827554],
            [110.301450137131482, -6.997022381025158],
            [110.301429390201477, -6.996895715913016],
            [110.301335100219831, -6.996569944861847],
            [110.301317128613235, -6.996479465101369],
            [110.301193549942468, -6.995683294508654],
            [110.301137533881274, -6.995493259878268],
            [110.301091877810975, -6.995375603092889],
            [110.30100614315819, -6.995194569646008],
            [110.300872098801165, -6.994968237917725],
            [110.300400250174363, -6.994180595303316],
            [110.299672952811818, -6.993193575804829],
            [110.299438841993648, -6.99291282312601],
            [110.299294762664047, -6.992586977768304],
            [110.299119577673764, -6.992062089360958],
            [110.298949821885003, -6.99169097919939],
            [110.298737051404729, -6.99132885027429],
            [110.298228378139896, -6.99057733248139],
            [110.297924325322768, -6.990151750175905],
            [110.297755338749141, -6.989834912688119],
            [110.2976193042022, -6.98942767123209],
            [110.297461218438912, -6.988631448790914],
            [110.297296425246913, -6.987590993101476],
            [110.297296803458934, -6.987337725136885],
            [110.29733123790183, -6.98701214549916],
            [110.297477555888264, -6.985836473895239],
            [110.297636037052072, -6.984850771665103],
            [110.29786435423236, -6.983322454830819],
            [110.297942906818733, -6.982816034552094],
            [110.29801384761987, -6.982481463754168],
            [110.298119962107421, -6.982083628112641],
            [110.298200561444091, -6.981911887175476],
            [110.298502023635109, -6.981604795073612],
            [110.298640226235847, -6.981505502164499],
            [110.2990958537155, -6.981017732584359],
            [110.299612734578403, -6.980620506509823],
            [110.300269189901968, -6.979961172712012],
            [110.30051274779342, -6.979724316675066],
            [110.300788210036615, -6.979455404523241],
            [110.300988499968312, -6.979117117397668],
            [110.301126389666152, -6.97880464282475],
            [110.301274932292102, -6.978591185394073],
            [110.301549877530618, -6.978306173859895],
            [110.301791445628893, -6.978013699286264],
            [110.302073837168024, -6.977710164718452],
            [110.302274875363196, -6.977412002726344],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 113,
        id: 115,
        area: 8762203.9119070005,
        perimeter: 18693.87274,
        kode_pos: 50187,
        kode_kec: 3374160,
        kode_kel: 3374091001,
        kec: "Ngaliyan",
        kel: "Podorejo",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.302274875363196, -6.977412002726344],
            [110.302073837168024, -6.977710164718452],
            [110.301791445628893, -6.978013699286264],
            [110.301549877530618, -6.978306173859895],
            [110.301274932292102, -6.978591185394073],
            [110.301126389666152, -6.97880464282475],
            [110.300988499968312, -6.979117117397668],
            [110.300788210036615, -6.979455404523241],
            [110.30051274779342, -6.979724316675066],
            [110.300269189901968, -6.979961172712012],
            [110.299612734578403, -6.980620506509823],
            [110.2990958537155, -6.981017732584359],
            [110.298640226235847, -6.981505502164499],
            [110.298502023635109, -6.981604795073612],
            [110.298200561444091, -6.981911887175476],
            [110.298119962107421, -6.982083628112641],
            [110.29801384761987, -6.982481463754168],
            [110.297942906818733, -6.982816034552094],
            [110.29786435423236, -6.983322454830819],
            [110.297636037052072, -6.984850771665103],
            [110.297477555888264, -6.985836473895239],
            [110.29733123790183, -6.98701214549916],
            [110.297296803458934, -6.987337725136885],
            [110.297296425246913, -6.987590993101476],
            [110.297461218438912, -6.988631448790914],
            [110.2976193042022, -6.98942767123209],
            [110.297755338749141, -6.989834912688119],
            [110.297924325322768, -6.990151750175905],
            [110.298228378139896, -6.99057733248139],
            [110.298737051404729, -6.99132885027429],
            [110.298949821885003, -6.99169097919939],
            [110.299119577673764, -6.992062089360958],
            [110.299294762664047, -6.992586977768304],
            [110.299438841993648, -6.99291282312601],
            [110.299672952811818, -6.993193575804829],
            [110.300400250174363, -6.994180595303316],
            [110.300872098801165, -6.994968237917725],
            [110.30100614315819, -6.995194569646008],
            [110.301091877810975, -6.995375603092889],
            [110.301137533881274, -6.995493259878268],
            [110.301193549942468, -6.995683294508654],
            [110.301317128613235, -6.996479465101369],
            [110.301335100219831, -6.996569944861847],
            [110.301429390201477, -6.996895715913016],
            [110.301450137131482, -6.997022381025158],
            [110.30149858324053, -6.997547080827554],
            [110.301475600962945, -6.997782224745174],
            [110.301544659602328, -6.998524042532761],
            [110.301589804512062, -6.998795468784526],
            [110.30166150257628, -6.999094070364341],
            [110.301813911360057, -6.999627969720127],
            [110.302068423414383, -6.999981114337038],
            [110.302212209445557, -7.00012605253903],
            [110.302795113878403, -7.000624408695693],
            [110.303040694318241, -7.000805678763538],
            [110.303387099535811, -7.001104687065339],
            [110.303746748358705, -7.001439895998201],
            [110.303921103130378, -7.001575833694027],
            [110.304811026716308, -7.00241836379983],
            [110.305197863855298, -7.002735521358659],
            [110.305608372468356, -7.003116030795501],
            [110.305779253229019, -7.003306234663828],
            [110.306089705500057, -7.003623278848479],
            [110.306140536100429, -7.003686671052382],
            [110.306189615768773, -7.003786241929348],
            [110.306225102298967, -7.003894838064545],
            [110.306237133852747, -7.003985308965952],
            [110.306231384216446, -7.004238569306271],
            [110.3061849930194, -7.004808355697103],
            [110.306131513341612, -7.005197225308626],
            [110.306101566761171, -7.005359996773556],
            [110.3060358125893, -7.005631259152371],
            [110.305847168796973, -7.006164654177557],
            [110.305681035326316, -7.006589538564833],
            [110.305564491813513, -7.006960224243246],
            [110.305447974868954, -7.00731281928358],
            [110.305140146883502, -7.008470164213272],
            [110.304885235751343, -7.009618541710925],
            [110.304686408888003, -7.010911726854949],
            [110.304689669776678, -7.011002184777274],
            [110.304739830347913, -7.011517841660102],
            [110.304758721289758, -7.011942999163088],
            [110.304786731363137, -7.012132992116109],
            [110.304837187084289, -7.01244965261859],
            [110.30491562487164, -7.012784445148144],
            [110.305018812016982, -7.013218772697657],
            [110.305064133851374, -7.013372610021766],
            [110.305449999667132, -7.014160122798996],
            [110.305667366062593, -7.014676026921491],
            [110.305824888015337, -7.015101389403129],
            [110.305905508154865, -7.015300505414722],
            [110.306132103000863, -7.01569883400403],
            [110.306358132370192, -7.016097161679075],
            [110.306494757606416, -7.016305405677741],
            [110.306585131324923, -7.016414082908228],
            [110.306702328947424, -7.016558980986018],
            [110.30715686508556, -7.017020962971343],
            [110.307397821703645, -7.017274587306886],
            [110.307611698406191, -7.017473899719834],
            [110.307657774360266, -7.017501103623406],
            [110.307687171369977, -7.017519237609482],
            [110.307713469620538, -7.017528321710732],
            [110.307739754507566, -7.017546451103717],
            [110.308007509027235, -7.017854386580407],
            [110.308095929659743, -7.017944970069372],
            [110.308113996521286, -7.017972132643091],
            [110.308120706473545, -7.018026414414724],
            [110.308120612965226, -7.01808973147024],
            [110.308136955590882, -7.018134982128447],
            [110.308194321768184, -7.018180293257969],
            [110.308300876155755, -7.018252812818956],
            [110.308425833803895, -7.018316314163656],
            [110.308472462381346, -7.018352564127033],
            [110.308531472742459, -7.018434058899156],
            [110.308620109757527, -7.01856986919668],
            [110.308677369246723, -7.018687542631975],
            [110.308793922143238, -7.018886711226865],
            [110.308861057272168, -7.019022489820747],
            [110.308957859178861, -7.019185448039723],
            [110.309056102443733, -7.019330317737216],
            [110.309202052840959, -7.019547620171833],
            [110.309327699671613, -7.019719666112213],
            [110.30942990406399, -7.019864541580584],
            [110.309454231811571, -7.019909681091058],
            [110.309681856825463, -7.020269134246653],
            [110.309786344006383, -7.020471102180196],
            [110.309902169561354, -7.0207788132906],
            [110.310044985013036, -7.021204153182087],
            [110.310204116963021, -7.021692834251294],
            [110.310352551397202, -7.022145318296793],
            [110.310529075462767, -7.022543571709114],
            [110.310742406563421, -7.022923788536585],
            [110.310939022030993, -7.023280507806763],
            [110.31094698065084, -7.023294947111771],
            [110.311106689846341, -7.02358463187011],
            [110.311239927522678, -7.023982821514276],
            [110.311377732118359, -7.024353881891298],
            [110.311448776386555, -7.024525847239977],
            [110.311565265216842, -7.024770241842274],
            [110.311703013469, -7.024987531680972],
            [110.311887140415223, -7.025291136054268],
            [110.312209095715218, -7.025715102955398],
            [110.312405251503918, -7.025983352048732],
            [110.312293391347552, -7.02601051891529],
            [110.312083110845535, -7.026055436997041],
            [110.31175797707688, -7.02610597355386],
            [110.311507532891568, -7.026126954600406],
            [110.311235597323119, -7.026157713968775],
            [110.31092874167102, -7.026192650516411],
            [110.310685769210608, -7.026225245507152],
            [110.310406494559388, -7.026242925416268],
            [110.310083649295407, -7.026260541037818],
            [110.309793340520599, -7.026278204338694],
            [110.309443334353602, -7.026295779525169],
            [110.309310076206387, -7.026295583212507],
            [110.309221785323714, -7.026295453123663],
            [110.309040730663583, -7.026295186303549],
            [110.309030431134602, -7.026295171123039],
            [110.308927843198077, -7.026295019905989],
            [110.308800862880844, -7.02625865145695],
            [110.308662835112173, -7.026231311995239],
            [110.308461984447533, -7.026212925138925],
            [110.308278392285445, -7.026194563663243],
            [110.308063961226779, -7.026176156601319],
            [110.307798307239906, -7.026166719123967],
            [110.307540561815983, -7.026166338487779],
            [110.307346771026161, -7.02615700689709],
            [110.307109449862239, -7.026120474966755],
            [110.30693778091198, -7.026074994672246],
            [110.306755953476213, -7.026011408681081],
            [110.306524053485816, -7.025920393924479],
            [110.306219440377163, -7.025806064406506],
            [110.30577616049257, -7.025627163100921],
            [110.305491345275485, -7.02553019184506],
            [110.305085146238198, -7.025410759895688],
            [110.304740905916617, -7.025283992615015],
            [110.30443874221919, -7.025189470659989],
            [110.304243117166578, -7.025102533502267],
            [110.303921322007099, -7.024879249557554],
            [110.303641640310857, -7.024663454840263],
            [110.303369403610546, -7.024440244162643],
            [110.303035300991553, -7.024162477643389],
            [110.302862073543409, -7.024011206999091],
            [110.302391691375135, -7.023730761844808],
            [110.302025292447794, -7.023509886037942],
            [110.301767839615238, -7.023343635934866],
            [110.301485486227591, -7.023259044240429],
            [110.301151101075021, -7.023171899232614],
            [110.300829064818913, -7.023112004276624],
            [110.300432677359822, -7.02306190070951],
            [110.300202278590959, -7.023031849503979],
            [110.299961942417568, -7.023019112724174],
            [110.29975626700832, -7.023026232564253],
            [110.299486118486342, -7.023065439130495],
            [110.299186172333734, -7.023146686614226],
            [110.298863891771475, -7.02325018111812],
            [110.298647900610334, -7.023340032964985],
            [110.298457248014955, -7.023430293518605],
            [110.298308463619207, -7.023504339626829],
            [110.2980530892834, -7.023605458007991],
            [110.297921731661987, -7.02362506636075],
            [110.297827929965948, -7.023620456223552],
            [110.2976963666692, -7.023543033369868],
            [110.297604005681023, -7.023465104795683],
            [110.297456286785859, -7.023359477704129],
            [110.297253519916808, -7.023238447468954],
            [110.297052711457098, -7.023145969441303],
            [110.296837258407535, -7.023063950929125],
            [110.29654993572764, -7.02298925140404],
            [110.296136608448293, -7.022901838083042],
            [110.296130440099731, -7.022781873204393],
            [110.29606913404055, -7.022676509766141],
            [110.295917059795258, -7.02259440372895],
            [110.295627435846797, -7.022503318488505],
            [110.295208959292196, -7.022458826780851],
            [110.294761187723296, -7.022431835896255],
            [110.294322227710239, -7.022384388408041],
            [110.294013065731818, -7.022343125663408],
            [110.293773706412523, -7.021915831390222],
            [110.293559205992409, -7.021501733388914],
            [110.293306793531315, -7.020996927857992],
            [110.293200269862169, -7.020793535142866],
            [110.293165279960817, -7.020705756280871],
            [110.293139660430441, -7.020615067330025],
            [110.293130485156453, -7.02048872785391],
            [110.293123739641047, -7.02030390793933],
            [110.293138081601441, -7.020109762337008],
            [110.293202996654301, -7.019762464569902],
            [110.29323015138516, -7.019611616490164],
            [110.293235077911959, -7.019450207788727],
            [110.293241162815576, -7.019296988604872],
            [110.293254307443533, -7.019120386410719],
            [110.293292148748236, -7.018870131434891],
            [110.293387657872543, -7.018419947637716],
            [110.293564019035159, -7.017679877414689],
            [110.29364165313649, -7.017146326730609],
            [110.293664214929152, -7.016741358194071],
            [110.293695309856517, -7.016306283164676],
            [110.293733316256066, -7.015946078186872],
            [110.293788959321859, -7.015535603381823],
            [110.293856288403845, -7.015139182317658],
            [110.293892766053645, -7.015016420522239],
            [110.293998477027671, -7.014787321721182],
            [110.294100631666183, -7.014587459600155],
            [110.294132944131746, -7.014120804850575],
            [110.293690085521334, -7.014034754926067],
            [110.293204097030227, -7.013955627682276],
            [110.292685550987684, -7.013891747700796],
            [110.292434888718944, -7.013860776523408],
            [110.292209028005658, -7.013881469242548],
            [110.291844006172127, -7.014030162492762],
            [110.29172763249727, -7.014107877332996],
            [110.291507380384445, -7.014140341017423],
            [110.291359549120912, -7.014218008295098],
            [110.291217503583908, -7.014321224426578],
            [110.290979942804668, -7.014253948118196],
            [110.290664531612521, -7.014232964749923],
            [110.290304200860234, -7.014448368076277],
            [110.289926211230124, -7.014654384684587],
            [110.289649564884243, -7.014816285418817],
            [110.289539701696725, -7.014913089088192],
            [110.289351908576819, -7.014919129025686],
            [110.289145218831507, -7.014864007095261],
            [110.289054648651074, -7.014760575970821],
            [110.28894923227837, -7.014707715329792],
            [110.288653746646602, -7.01477050900109],
            [110.288588604493583, -7.014595442938637],
            [110.288565670475876, -7.014414116698533],
            [110.288454026869772, -7.014293789225395],
            [110.288221964036651, -7.014274465165665],
            [110.288023759271312, -7.014189843076711],
            [110.287890923766909, -7.014130616527576],
            [110.287736931415807, -7.014109302580046],
            [110.287610230320837, -7.014178675715415],
            [110.287443418214451, -7.014262744230383],
            [110.287321068295284, -7.014245694237965],
            [110.287211789304266, -7.014259427352819],
            [110.287129812044142, -7.014214076536113],
            [110.287084572781993, -7.014195917307324],
            [110.287043618556169, -7.014150628755383],
            [110.287014788507093, -7.014132494433603],
            [110.286973765499667, -7.01413243214651],
            [110.286936972492398, -7.014141421553744],
            [110.28687522771871, -7.014186554165366],
            [110.286826000105677, -7.014186479405223],
            [110.286801413823838, -7.014168351516023],
            [110.286751972111844, -7.014123050051068],
            [110.28671922252677, -7.014077773936064],
            [110.286694622490828, -7.014068691295612],
            [110.286657857015129, -7.014059590174253],
            [110.286604385659402, -7.01405950894574],
            [110.286559091370336, -7.014077530682422],
            [110.286514121298168, -7.014068417085028],
            [110.286485291277586, -7.014050282733255],
            [110.286423697941217, -7.013995917500579],
            [110.286349994264171, -7.013905352762563],
            [110.286321150489428, -7.013896263654998],
            [110.286263787176836, -7.013850950106916],
            [110.286190056006731, -7.013778475853758],
            [110.286155786910001, -7.013743738295894],
            [110.286091752407117, -7.01367882841732],
            [110.286054745387602, -7.013642591065821],
            [110.286013763760693, -7.013615392942453],
            [110.285923285459376, -7.013579074285303],
            [110.285829170593459, -7.013515614263317],
            [110.285796096882933, -7.013497473421462],
            [110.285755087723103, -7.013488365781742],
            [110.285664837256135, -7.013488228515862],
            [110.285541754655299, -7.013497086558536],
            [110.285463655827172, -7.013506013012184],
            [110.285274708766565, -7.013478589681042],
            [110.285155655969206, -7.013442227396819],
            [110.285071018423707, -7.013430678946944],
            [110.285031042493074, -7.013356836720378],
            [110.285010113705241, -7.013245078784483],
            [110.284989146344515, -7.013158617259103],
            [110.284957639050575, -7.013065815569371],
            [110.284926189641311, -7.012935069262212],
            [110.284885518535106, -7.012853873510741],
            [110.284711142204202, -7.012827231813289],
            [110.28452546164138, -7.012822949210554],
            [110.284652828704267, -7.012456766732285],
            [110.284676499886984, -7.011675233724049],
            [110.28470374168694, -7.011500095929755],
            [110.284753475964791, -7.011316008823534],
            [110.28483240180374, -7.011152179140614],
            [110.285008106213297, -7.010909890643071],
            [110.285204024216384, -7.010678862255441],
            [110.285348360642772, -7.010369149208276],
            [110.28547930541319, -7.009996530846302],
            [110.285562924898088, -7.009702446574748],
            [110.285621643233014, -7.009522864747085],
            [110.285657910165725, -7.009325281666156],
            [110.285659879744529, -7.009291429479179],
            [110.285671759917534, -7.009087238502642],
            [110.285631599228964, -7.008889539234818],
            [110.285582597582419, -7.008593007415894],
            [110.285537777045093, -7.008503103725105],
            [110.285477183830267, -7.008437880828552],
            [110.285270694870079, -7.008230945080974],
            [110.285140517140945, -7.00810048554988],
            [110.28496539588896, -7.007960974061797],
            [110.284963786236148, -7.007960876200373],
            [110.284964322177743, -7.007957502689809],
            [110.284297919052179, -7.007444346071956],
            [110.283444368390434, -7.006939032640521],
            [110.282656034707088, -7.006863709405427],
            [110.282873564429536, -7.005703780429301],
            [110.282836022657534, -7.005355010868889],
            [110.282583389750684, -7.004918064189355],
            [110.282582860745777, -7.004917149236666],
            [110.281542078811995, -7.004966281862638],
            [110.280274271981227, -7.004515219758853],
            [110.280235562601092, -7.004501447615596],
            [110.280275799364816, -7.00426775782937],
            [110.280204543562292, -7.004101580048344],
            [110.280235763060261, -7.003908524673212],
            [110.280390590282394, -7.003771658369154],
            [110.280574541781874, -7.003587013349879],
            [110.280635349344138, -7.003319326564617],
            [110.280934191026674, -7.002796114428463],
            [110.281000291762993, -7.002211915637618],
            [110.28095902578265, -7.002146441482761],
            [110.280543205937803, -7.001486684977818],
            [110.280683413455279, -6.99910003447109],
            [110.280972703004295, -6.999022406845758],
            [110.281406140805615, -6.998957179094043],
            [110.281737840885199, -6.998913827846501],
            [110.281980984943118, -6.998874164714648],
            [110.282185323620624, -6.998738130756733],
            [110.282346408901859, -6.998577674771245],
            [110.282554527208589, -6.998444727290675],
            [110.282754859402033, -6.998272572308373],
            [110.282982753557832, -6.998033826808578],
            [110.283187193706084, -6.997740171703771],
            [110.283379834964265, -6.997466096286307],
            [110.283623420042218, -6.997227374285396],
            [110.283823727431113, -6.997070896927098],
            [110.284102397314811, -6.996977251523716],
            [110.284333819093277, -6.996997201198075],
            [110.284553400563439, -6.997064167422539],
            [110.28476112388455, -6.997189908857159],
            [110.28495717401627, -6.997252919595928],
            [110.285168927783516, -6.99730811499622],
            [110.286192760424527, -6.997341025180786],
            [110.286314465998728, -6.997278496830009],
            [110.286455738491966, -6.99724735455731],
            [110.286589081833966, -6.997271074013596],
            [110.28685173263068, -6.997392978279152],
            [110.287083083504427, -6.997459961208237],
            [110.28719203519907, -6.997477139373222],
            [110.287334090648983, -6.99749953689254],
            [110.287432300258573, -6.997409535766492],
            [110.287561958517514, -6.997276467086593],
            [110.287609146391873, -6.997202066916728],
            [110.28761334858477, -6.997017854080582],
            [110.287574458172202, -6.996794380452635],
            [110.287519905864912, -6.996551285332151],
            [110.287481033341336, -6.99631605305538],
            [110.287434273437569, -6.996108245740433],
            [110.287340409018157, -6.995919964908051],
            [110.287226936106862, -6.995727734819857],
            [110.287109451209076, -6.995594291995158],
            [110.286901941893007, -6.995327447866701],
            [110.286792463552885, -6.995088189066929],
            [110.286800541280044, -6.994935338578398],
            [110.286887106584587, -6.994763009176052],
            [110.286981374917346, -6.994684760788189],
            [110.287212986029388, -6.99457928339717],
            [110.287087845822342, -6.994320403165965],
            [110.287056890693975, -6.994038148169466],
            [110.287081040220357, -6.993634470312365],
            [110.287085707643698, -6.993328460422731],
            [110.287038572031392, -6.993183656910456],
            [110.286936696048244, -6.993105111529852],
            [110.286846547119495, -6.993054020789096],
            [110.286756309025193, -6.993061723257599],
            [110.286673791809733, -6.993151748104199],
            [110.286673506371542, -6.993339886417997],
            [110.286661369020862, -6.993582880578654],
            [110.286649065212345, -6.993780063357671],
            [110.286621682238419, -6.993884626359419],
            [110.286554880175444, -6.99395899674313],
            [110.286480327500826, -6.993970642460748],
            [110.286354908206135, -6.993895980833868],
            [110.286170770666374, -6.993738919485865],
            [110.286049333891896, -6.993625068258182],
            [110.285931837917175, -6.993499464310029],
            [110.285810436986395, -6.993362095747043],
            [110.285626341528371, -6.99317759739109],
            [110.285465700084018, -6.99304800834638],
            [110.285359990908589, -6.992910663495508],
            [110.285309243742645, -6.992745965135383],
            [110.285242930231433, -6.992498932471816],
            [110.285196260919577, -6.992232331833542],
            [110.285149430772222, -6.992071558964713],
            [110.285098624156788, -6.991946056063474],
            [110.285012410458748, -6.991887131871493],
            [110.284847715610425, -6.991843766748064],
            [110.284730005767258, -6.991859266252481],
            [110.28460433093133, -6.991953144744405],
            [110.284521807594757, -6.992047088742652],
            [110.284435331534297, -6.992160624495755],
            [110.28434487290896, -6.992313349731683],
            [110.2842936011739, -6.992493571376337],
            [110.284234471714171, -6.99268162017969],
            [110.284187259998717, -6.992771698201911],
            [110.284073395205866, -6.992838157576251],
            [110.283955661228774, -6.992869335042321],
            [110.283841933692187, -6.992845644800558],
            [110.283740094204845, -6.992743581514345],
            [110.283681370529976, -6.992665101112416],
            [110.283685472532056, -6.992547520706132],
            [110.2836935391949, -6.992402509444269],
            [110.283646727478541, -6.992229977840372],
            [110.283611988915624, -6.991857567297275],
            [110.283604513380908, -6.991614543524717],
            [110.283593073184605, -6.991398950605785],
            [110.283565822132942, -6.991261724740484],
            [110.283511146903905, -6.991100939837369],
            [110.283413266524406, -6.990975365210114],
            [110.283327106987059, -6.990881164835363],
            [110.283221339175213, -6.990783015061509],
            [110.2830880815482, -6.99070442121171],
            [110.282962597974006, -6.990672873814398],
            [110.282782176521863, -6.990653001419519],
            [110.282609541018417, -6.990672336354968],
            [110.282409385722929, -6.990730824851589],
            [110.282201498327893, -6.990714829953895],
            [110.281974032584912, -6.990675287805995],
            [110.281750501715734, -6.99062791244367],
            [110.281672146403025, -6.990561160576239],
            [110.281648776896205, -6.990451377448544],
            [110.28169206594211, -6.99036129371718],
            [110.281790215033908, -6.990310489144036],
            [110.281908002295353, -6.990244036263835],
            [110.281974906151405, -6.990103034289111],
            [110.282069395528424, -6.989879763699975],
            [110.282124572605895, -6.989711306946522],
            [110.282168094741152, -6.98946836089962],
            [110.282195799858229, -6.989307701385341],
            [110.282215581523687, -6.989197984001639],
            [110.282278490513988, -6.989104010519227],
            [110.282470799424672, -6.989045510059328],
            [110.282949433871565, -6.989014882158339],
            [110.283157290718734, -6.989050474382563],
            [110.283306239289445, -6.98912909203651],
            [110.283596876382219, -6.989270882237712],
            [110.283804040535742, -6.989392459080539],
            [110.284015796633696, -6.989443735097487],
            [110.284204069383819, -6.989459699350271],
            [110.2843100100839, -6.989444182042435],
            [110.284376799830653, -6.989377651027675],
            [110.284357376404159, -6.989252195740355],
            [110.284381098301324, -6.98913072553052],
            [110.284459590942774, -6.989107327385766],
            [110.284773460142105, -6.989080366971336],
            [110.285008902240861, -6.989033689575438],
            [110.285177685748948, -6.988967313166293],
            [110.285299430830946, -6.988877348015391],
            [110.285354547098649, -6.988748086225102],
            [110.285346874075557, -6.988634407440691],
            [110.285299930982234, -6.988548106002107],
            [110.285225563761244, -6.988438245627409],
            [110.285088478817968, -6.988296933657017],
            [110.284978764462423, -6.988214456528591],
            [110.284967150821558, -6.988112530436998],
            [110.284932000175161, -6.988010568635885],
            [110.284783200559758, -6.987833962813746],
            [110.284615013335284, -6.987508384384828],
            [110.284509496213914, -6.987245613948244],
            [110.284490108538876, -6.987096641376432],
            [110.284541498580239, -6.98683802865345],
            [110.284589078935113, -6.986504938560053],
            [110.284648284677488, -6.986265935450989],
            [110.284719395805382, -6.985936800583492],
            [110.284786381498293, -6.985740924366382],
            [110.284943532308816, -6.985584380458699],
            [110.285053472325885, -6.985517914714307],
            [110.285171228178442, -6.985471058577028],
            [110.285308609980007, -6.985416393043212],
            [110.285449896716003, -6.985373492056502],
            [110.285587236821655, -6.985346263275188],
            [110.285697093432873, -6.985334671046688],
            [110.285775621062427, -6.985287755315509],
            [110.285885543069256, -6.985233048003837],
            [110.285983678780099, -6.985190081489326],
            [110.286081802585514, -6.985154954050572],
            [110.286160288543925, -6.98513547509179],
            [110.286226970401728, -6.985139495599149],
            [110.286247815314468, -6.985448750011104],
            [110.286300814444473, -6.985594276129951],
            [110.286438065461539, -6.985625840341594],
            [110.286583298772896, -6.985567266750978],
            [110.286865931545151, -6.985442268458011],
            [110.287113205428156, -6.985352492549112],
            [110.287387927057964, -6.98527059710392],
            [110.287603669968178, -6.985278762244483],
            [110.287854706366502, -6.985294819714707],
            [110.288141113333396, -6.985267815299677],
            [110.288372648285218, -6.985209371413077],
            [110.288549246022029, -6.985162603195596],
            [110.288631607348293, -6.985174486144065],
            [110.288713867993408, -6.985253001450369],
            [110.288804039440308, -6.985288413514448],
            [110.289043295662282, -6.985312291707947],
            [110.289278652777099, -6.985320485662253],
            [110.289466936116781, -6.985328608562458],
            [110.289655183980429, -6.985360248700507],
            [110.289808156091922, -6.985372237826507],
            [110.289937609051549, -6.985372432800662],
            [110.290059287320545, -6.985325581296906],
            [110.290173102203141, -6.985290476605377],
            [110.290255605459777, -6.985208290014261],
            [110.290310666708663, -6.985114303417152],
            [110.290334398600493, -6.984984993604344],
            [110.290361976475296, -6.984906643877821],
            [110.290397405873904, -6.984824386402794],
            [110.290515196593532, -6.984754011540557],
            [110.290660334746832, -6.984758149461669],
            [110.290762280630787, -6.984789659307723],
            [110.290852475804613, -6.984809392774593],
            [110.290954504368855, -6.984786028835805],
            [110.291068348585682, -6.984731326140614],
            [110.291135183954268, -6.98463343756295],
            [110.291119646163295, -6.984531505584481],
            [110.29113945498149, -6.984402189805453],
            [110.291347540916092, -6.984284915688053],
            [110.291653696963266, -6.98416778879],
            [110.291720313945177, -6.984214923605662],
            [110.291755371566609, -6.984379597901306],
            [110.291802191745631, -6.984548209424513],
            [110.291872654959519, -6.9846463043194],
            [110.292045087775264, -6.984760230566549],
            [110.292143140437673, -6.98477213646227],
            [110.292241275608816, -6.984729168576944],
            [110.292374828027789, -6.984611782117415],
            [110.292457348480895, -6.984517836424193],
            [110.29255544823377, -6.984498385792841],
            [110.292747548252393, -6.98457706522797],
            [110.292943594703573, -6.984640072253272],
            [110.293061220248561, -6.984679444253788],
            [110.293139694183552, -6.984667803205861],
            [110.29321434706381, -6.984644193346045],
            [110.293291071248518, -6.984743607428379],
            [110.294049033664294, -6.984114417834985],
            [110.294345715026935, -6.983668785697398],
            [110.295062579428972, -6.982522288757892],
            [110.296095223142842, -6.980778087178499],
            [110.296508903133343, -6.980154578103066],
            [110.296833482906223, -6.979521890469456],
            [110.296955937973877, -6.979169305956954],
            [110.296972602984823, -6.978807518497741],
            [110.29694223965204, -6.978490887510962],
            [110.296848634732299, -6.97787837866581],
            [110.296868674585184, -6.977456575277354],
            [110.296966204041809, -6.977136709114315],
            [110.297207909965692, -6.976763629354275],
            [110.297745881571316, -6.976318921258843],
            [110.2983573688701, -6.976552239764494],
            [110.298908412079882, -6.976628844103561],
            [110.299383615856769, -6.976700282888165],
            [110.299899084033058, -6.976898090358016],
            [110.300379037291506, -6.977176682713266],
            [110.300707573890506, -6.977268112344099],
            [110.301541647123429, -6.977436075766878],
            [110.302274875363196, -6.977412002726344],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 114,
        id: 116,
        area: 5024939.0658480003,
        perimeter: 11281.303267,
        kode_pos: 50188,
        kode_kec: 3374160,
        kode_kel: 3374091002,
        kec: "Ngaliyan",
        kel: "Wates",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.301813911360057, -6.999627969720127],
            [110.302023008708517, -6.999700642397456],
            [110.302130420255068, -6.99976411887673],
            [110.302200501202307, -6.999818494665513],
            [110.302276469524671, -6.999909060412512],
            [110.302337780310566, -6.999963423179289],
            [110.302480784860705, -7.000063133600159],
            [110.302641356741901, -7.000144779400606],
            [110.302923229900188, -7.000271831431265],
            [110.303100736276221, -7.00038063810982],
            [110.303281085256998, -7.000480403627391],
            [110.303504100094045, -7.00061641349159],
            [110.303688975824983, -7.000716185570342],
            [110.304038155504813, -7.000861427226665],
            [110.30481496213558, -7.001097753869256],
            [110.304950153206548, -7.001125089562752],
            [110.305103733484074, -7.001152452381838],
            [110.305249931346381, -7.001197894878998],
            [110.30539641215168, -7.001243337751138],
            [110.305875758547941, -7.001370679674373],
            [110.306138462361716, -7.001452475063483],
            [110.306254994894346, -7.001470737561751],
            [110.30635937563261, -7.001479936798086],
            [110.306697750369494, -7.001471390290468],
            [110.306896340447622, -7.001480728242268],
            [110.307111056462958, -7.00149008986309],
            [110.307238056770174, -7.001508367540191],
            [110.307498484344364, -7.001599204165915],
            [110.307592384118692, -7.001617433023132],
            [110.307794086334496, -7.001626775184248],
            [110.308062488932521, -7.001681441971399],
            [110.308303718816802, -7.00174511397685],
            [110.308531974821236, -7.001781630833281],
            [110.30879020614411, -7.001827236949548],
            [110.308966433203523, -7.001845586506009],
            [110.309168714731584, -7.001845883619197],
            [110.309488081623414, -7.001873488497535],
            [110.309732826470693, -7.001855757055275],
            [110.309879887162126, -7.001892154119789],
            [110.310305186504237, -7.002028457733511],
            [110.311187808120323, -7.002264929471431],
            [110.311924797475356, -7.002455959986013],
            [110.31252745734777, -7.00261061177302],
            [110.312664886325422, -7.002656039258679],
            [110.313054810154014, -7.00280133415636],
            [110.313373466845292, -7.002928434059265],
            [110.313797287757652, -7.00310995918583],
            [110.314176823912334, -7.00320096608286],
            [110.314511956198245, -7.003282862638629],
            [110.314781788645504, -7.003328482505996],
            [110.315506695525215, -7.003465218098983],
            [110.315991206204913, -7.003547331031344],
            [110.316190041113444, -7.003583801517124],
            [110.316477644811968, -7.003665627619472],
            [110.316649010953071, -7.003720148663642],
            [110.31687185411657, -7.003783789762821],
            [110.317066688971664, -7.00384739008885],
            [110.317237785508837, -7.003892865203779],
            [110.318028494808956, -7.004111100622409],
            [110.318193341202189, -7.004174657017395],
            [110.318477794278323, -7.004283613407019],
            [110.318916909231405, -7.0044561110413],
            [110.319381756555401, -7.004637690881007],
            [110.319471683715378, -7.004664957055354],
            [110.319617292415899, -7.004728485144052],
            [110.319754387532825, -7.004810091554501],
            [110.319815378913347, -7.004891587845518],
            [110.319870994953078, -7.004973076355093],
            [110.320070269721654, -7.005362314190682],
            [110.320140082353916, -7.005534276609287],
            [110.320190362455392, -7.005588621354504],
            [110.320421524282182, -7.005769862353649],
            [110.320664943263182, -7.005887803578656],
            [110.320936989086704, -7.005969604672346],
            [110.321077558298597, -7.005996943716122],
            [110.321328155098755, -7.006042532251191],
            [110.321597681082281, -7.006106238658321],
            [110.321884129747758, -7.006206150700638],
            [110.32203421366944, -7.006305865941938],
            [110.322206082093686, -7.006405612550264],
            [110.32243729740776, -7.00655067136457],
            [110.322735228582715, -7.006732007580249],
            [110.322914144101119, -7.006849854799499],
            [110.323236110413902, -7.007040270696105],
            [110.323328776159286, -7.007130857555928],
            [110.323409572539902, -7.007212381970294],
            [110.32362059734993, -7.007429773974406],
            [110.324018690942069, -7.00780120586872],
            [110.324331018876833, -7.008398647859688],
            [110.324450109887195, -7.00860686204008],
            [110.32453283502899, -7.008724570459518],
            [110.324618737403256, -7.00879705669795],
            [110.324683164201687, -7.008851421367989],
            [110.324883569670945, -7.008978344178491],
            [110.325310370250747, -7.009259362932137],
            [110.325481944584709, -7.00936815342417],
            [110.325716557440643, -7.00951321571727],
            [110.32584035801365, -7.009594801491008],
            [110.325988398505018, -7.009739739451796],
            [110.326159882221802, -7.009911847042514],
            [110.326276288157359, -7.01002055816167],
            [110.326426335319553, -7.010147408184582],
            [110.326583725427867, -7.010283314041127],
            [110.326798599803382, -7.01038312081926],
            [110.327106297172648, -7.010464969754276],
            [110.327760130188352, -7.010655858105675],
            [110.327905498009471, -7.010692247489757],
            [110.328296861759654, -7.010828487480725],
            [110.328778720649623, -7.010991992529206],
            [110.329027006103459, -7.011073755385493],
            [110.329727624388767, -7.011391342941968],
            [110.330190425587375, -7.011627182296611],
            [110.330564390862591, -7.011862894644529],
            [110.33083879496688, -7.012080374192265],
            [110.331287000907565, -7.012433781435244],
            [110.331701219290309, -7.012615278137726],
            [110.331970969422571, -7.012724206177478],
            [110.332302357437044, -7.012860357780241],
            [110.332510200058636, -7.012933016082577],
            [110.3326247051194, -7.01298745096052],
            [110.332882341671763, -7.013060179859858],
            [110.333053996539576, -7.013114695812732],
            [110.333142537457547, -7.013123866897871],
            [110.333526729221745, -7.013133457602141],
            [110.333975388127939, -7.013170275505703],
            [110.334180762012991, -7.01318865742689],
            [110.334345356975732, -7.013234117554015],
            [110.334559707311385, -7.01330678421647],
            [110.334805474642323, -7.013370449922405],
            [110.335094245852531, -7.013434176391238],
            [110.335399709425502, -7.013497926311757],
            [110.335886794837805, -7.013570978274473],
            [110.336068120859537, -7.013589325386088],
            [110.336421475018454, -7.013598870165582],
            [110.336772295734193, -7.013599365735411],
            [110.336965825771784, -7.013590593636433],
            [110.337529183332705, -7.013546161811488],
            [110.337776480933485, -7.013528419847586],
            [110.337967439134587, -7.013537734443674],
            [110.338136933742845, -7.013519882605201],
            [110.338296958570268, -7.013492971991842],
            [110.338382420992033, -7.013782544241356],
            [110.33840761572111, -7.013972532507653],
            [110.338426405422894, -7.014090148789325],
            [110.338459255929607, -7.014669098747077],
            [110.338465354576911, -7.014958559173691],
            [110.338468804787169, -7.015320378823202],
            [110.338468345150488, -7.015646011480571],
            [110.338457585399127, -7.016053037943863],
            [110.338442260537661, -7.016487194072432],
            [110.338430894555927, -7.016722357651256],
            [110.338422189791288, -7.016876116656483],
            [110.338375356322018, -7.017183593189203],
            [110.338326644508555, -7.017418704111087],
            [110.338262912193031, -7.017671884581429],
            [110.338167403015206, -7.017988337803082],
            [110.338046764679774, -7.018268574075615],
            [110.337880343800521, -7.018512564241254],
            [110.3378199661352, -7.018593887359762],
            [110.337756218946126, -7.018657114979242],
            [110.337602348713574, -7.018828759792555],
            [110.337408226498866, -7.019054619910276],
            [110.337316920460125, -7.019199216840323],
            [110.337223299779552, -7.019379991940892],
            [110.337134964962658, -7.019624092041272],
            [110.337095446553278, -7.019759716697278],
            [110.337049703794932, -7.01989533255286],
            [110.336956597478135, -7.020112289759856],
            [110.336848496094774, -7.020329225744745],
            [110.336659337369994, -7.02064554610706],
            [110.336544458340242, -7.02085342704584],
            [110.336429553567783, -7.021079398636489],
            [110.336308128591597, -7.02131440632578],
            [110.336253897678674, -7.021450010050974],
            [110.336227195461333, -7.021513357049291],
            [110.336227178986604, -7.021513386254515],
            [110.335791719660563, -7.022291233234411],
            [110.335791675238397, -7.022291228176072],
            [110.335168753835546, -7.022218981263856],
            [110.334644915838638, -7.022083384045174],
            [110.334820876962013, -7.021179315367672],
            [110.334332803264346, -7.021019970772589],
            [110.333685950511594, -7.020848501299835],
            [110.332935875084047, -7.020680850533634],
            [110.332931859980391, -7.020712575278653],
            [110.332058718095183, -7.020544748164099],
            [110.331967308444163, -7.020616011527825],
            [110.330330298364316, -7.020211097879844],
            [110.328326181489274, -7.019732276813671],
            [110.326368960900766, -7.019773100407865],
            [110.324703559309526, -7.019792521222213],
            [110.323217049696282, -7.019641643304776],
            [110.321865511924088, -7.019494922139981],
            [110.321742456893972, -7.01948681169351],
            [110.321221568195355, -7.020061171648083],
            [110.320649771465654, -7.020151568943289],
            [110.319487548436072, -7.020312106249026],
            [110.318652707774987, -7.020018975312976],
            [110.317951122469836, -7.0198180547419],
            [110.317082818888878, -7.019605784944391],
            [110.316820906011898, -7.0195371834313],
            [110.316713013118616, -7.019476738666634],
            [110.316162394055482, -7.0192014690502],
            [110.315549834004329, -7.018934041095089],
            [110.315083050640581, -7.018882591508242],
            [110.313747443965539, -7.018908652235521],
            [110.312838231905971, -7.018982681302297],
            [110.31161320186817, -7.019201014987496],
            [110.311150568353597, -7.01929751019185],
            [110.310582630775784, -7.019463260279822],
            [110.309816046956186, -7.019731840997472],
            [110.309454231811571, -7.019909681091058],
            [110.30942990406399, -7.019864541580584],
            [110.309327699671613, -7.019719666112213],
            [110.309202052840959, -7.019547620171833],
            [110.309056102443733, -7.019330317737216],
            [110.308957859178861, -7.019185448039723],
            [110.308861057272168, -7.019022489820747],
            [110.308793922143238, -7.018886711226865],
            [110.308677369246723, -7.018687542631975],
            [110.308620109757527, -7.01856986919668],
            [110.308531472742459, -7.018434058899156],
            [110.308472462381346, -7.018352564127033],
            [110.308425833803895, -7.018316314163656],
            [110.308300876155755, -7.018252812818956],
            [110.308194321768184, -7.018180293257969],
            [110.308136955590882, -7.018134982128447],
            [110.308120612965226, -7.01808973147024],
            [110.308120706473545, -7.018026414414724],
            [110.308113996521286, -7.017972132643091],
            [110.308095929659743, -7.017944970069372],
            [110.308007509027235, -7.017854386580407],
            [110.307739754507566, -7.017546451103717],
            [110.307713469620538, -7.017528321710732],
            [110.307687171369977, -7.017519237609482],
            [110.307657774360266, -7.017501103623406],
            [110.307611698406191, -7.017473899719834],
            [110.307397821703645, -7.017274587306886],
            [110.30715686508556, -7.017020962971343],
            [110.306702328947424, -7.016558980986018],
            [110.306585131324923, -7.016414082908228],
            [110.306494757606416, -7.016305405677741],
            [110.306358132370192, -7.016097161679075],
            [110.306132103000863, -7.01569883400403],
            [110.305905508154865, -7.015300505414722],
            [110.305824888015337, -7.015101389403129],
            [110.305667366062593, -7.014676026921491],
            [110.305449999667132, -7.014160122798996],
            [110.305064133851374, -7.013372610021766],
            [110.305018812016982, -7.013218772697657],
            [110.30491562487164, -7.012784445148144],
            [110.304837187084289, -7.01244965261859],
            [110.304786731363137, -7.012132992116109],
            [110.304758721289758, -7.011942999163088],
            [110.304739830347913, -7.011517841660102],
            [110.304689669776678, -7.011002184777274],
            [110.304686408888003, -7.010911726854949],
            [110.304885235751343, -7.009618541710925],
            [110.305140146883502, -7.008470164213272],
            [110.305447974868954, -7.00731281928358],
            [110.305564491813513, -7.006960224243246],
            [110.305681035326316, -7.006589538564833],
            [110.305847168796973, -7.006164654177557],
            [110.3060358125893, -7.005631259152371],
            [110.306101566761171, -7.005359996773556],
            [110.306131513341612, -7.005197225308626],
            [110.3061849930194, -7.004808355697103],
            [110.306231384216446, -7.004238569306271],
            [110.306237133852747, -7.003985308965952],
            [110.306225102298967, -7.003894838064545],
            [110.306189615768773, -7.003786241929348],
            [110.306140536100429, -7.003686671052382],
            [110.306089705500057, -7.003623278848479],
            [110.305779253229019, -7.003306234663828],
            [110.305608372468356, -7.003116030795501],
            [110.305197863855298, -7.002735521358659],
            [110.304811026716308, -7.00241836379983],
            [110.303921103130378, -7.001575833694027],
            [110.303746748358705, -7.001439895998201],
            [110.303387099535811, -7.001104687065339],
            [110.303040694318241, -7.000805678763538],
            [110.302795113878403, -7.000624408695693],
            [110.302212209445557, -7.00012605253903],
            [110.302068423414383, -6.999981114337038],
            [110.301813911360057, -6.999627969720127],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 115,
        id: 117,
        area: 4386636.4083909998,
        perimeter: 10502.402963,
        kode_pos: 50185,
        kode_kec: 3374160,
        kode_kel: 3374091008,
        kec: "Ngaliyan",
        kel: "Tambakaji",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.328335612791079, -6.994221374250071],
            [110.327341042370747, -6.993731399747698],
            [110.326687644937266, -6.99345005996516],
            [110.326614989604124, -6.993413774660035],
            [110.326231564165468, -6.993078548104249],
            [110.326169402411736, -6.993024187038094],
            [110.326070771613118, -6.992951683071851],
            [110.325982621364858, -6.992870148730206],
            [110.325873933280221, -6.992734315302437],
            [110.326112441288288, -6.99249947489463],
            [110.326449875412962, -6.992156233983619],
            [110.326775157025054, -6.991803930054282],
            [110.326823920481488, -6.991731636881005],
            [110.326918786581885, -6.991469457059867],
            [110.326980127284784, -6.991306728252994],
            [110.327020699324976, -6.991225377989461],
            [110.327085858275396, -6.991162153557147],
            [110.327402792142138, -6.990909336096339],
            [110.327463694478936, -6.990855150884229],
            [110.327479897706027, -6.990800901858943],
            [110.327467862100193, -6.990710431086581],
            [110.327448060347521, -6.990511404917259],
            [110.327403749309369, -6.990239980886137],
            [110.327395957301462, -6.990149516170207],
            [110.327424312495978, -6.990104329849725],
            [110.327875109821079, -6.990014519515505],
            [110.327960315984214, -6.98997845960942],
            [110.32815541853823, -6.989852102787583],
            [110.328362724653928, -6.989698627185506],
            [110.328521088077565, -6.989545081737995],
            [110.328745793115345, -6.989292131751542],
            [110.328961252366412, -6.988975850929132],
            [110.329087480317355, -6.988740851220279],
            [110.3291690092409, -6.988505787840461],
            [110.329197493122777, -6.988370147935965],
            [110.329209838548167, -6.98824353042161],
            [110.329198125010194, -6.987926926033384],
            [110.329193971716435, -6.987863602578501],
            [110.329169231703844, -6.98755602501087],
            [110.329147654436298, -6.987411268485699],
            [110.329113294931417, -6.987302675221265],
            [110.329025518250177, -6.987157824468002],
            [110.328932623560391, -6.987031057143048],
            [110.328800675348447, -6.9869132795612],
            [110.328641890098496, -6.986768327639386],
            [110.328573608167204, -6.986641595310357],
            [110.328512707563831, -6.986496782762917],
            [110.32843952704836, -6.986234363003065],
            [110.328403020712358, -6.986044358382989],
            [110.328381328597629, -6.985782011963559],
            [110.328362182616118, -6.985519669169575],
            [110.328347704008891, -6.985356832013929],
            [110.328321267115257, -6.985049252016843],
            [110.328285019012114, -6.984678340500592],
            [110.328251265427753, -6.984343613986837],
            [110.328246392608847, -6.984189835873481],
            [110.328244439148563, -6.983972744375938],
            [110.328259254300434, -6.983701404582316],
            [110.328245045720834, -6.98354761316771],
            [110.328215882095918, -6.983366664360657],
            [110.328186770110136, -6.983149534172669],
            [110.328184378868713, -6.983040986405945],
            [110.328211448042524, -6.982905344510595],
            [110.328329213209997, -6.982652242046328],
            [110.328503764746216, -6.982254494554764],
            [110.328783234039804, -6.981685034329338],
            [110.329046191326754, -6.981187913303538],
            [110.329141812060413, -6.980989051237636],
            [110.329321004050897, -6.980708899654301],
            [110.329443770041877, -6.980519121455814],
            [110.329541859300903, -6.980374534984192],
            [110.329713283808019, -6.980184825835683],
            [110.329912496642123, -6.979949929236052],
            [110.330008052403031, -6.979796293673659],
            [110.330044957936934, -6.979705892394166],
            [110.330084641073654, -6.979452678426656],
            [110.330158246296094, -6.979217603338136],
            [110.330227299337679, -6.979000612505405],
            [110.330244311837404, -6.978973500544506],
            [110.330552684159613, -6.978765894602321],
            [110.330622109977625, -6.978684584755317],
            [110.330732401642436, -6.978512879153179],
            [110.330840571339692, -6.978241671438016],
            [110.330992718536535, -6.977880072317015],
            [110.331171303361771, -6.977428056872909],
            [110.331189473011122, -6.977382855763439],
            [110.331265104538232, -6.97729176062113],
            [110.331319934928246, -6.977349065677041],
            [110.331718484636369, -6.977759362639983],
            [110.332171682195337, -6.978189768023076],
            [110.332437437072045, -6.978425057334825],
            [110.332794235719206, -6.978718748323946],
            [110.333036270074743, -6.978974035185494],
            [110.333287376072818, -6.979258471304293],
            [110.333502067956289, -6.97951736140734],
            [110.333851410624405, -6.979929408406825],
            [110.334129798883367, -6.980253945428081],
            [110.334508269644232, -6.98068970658459],
            [110.334792105808063, -6.98102881924829],
            [110.335281541496244, -6.981614061169146],
            [110.335619946882176, -6.982029733769687],
            [110.335965653270335, -6.982438132315548],
            [110.336276796197964, -6.982800956151698],
            [110.336546075078388, -6.983125479202354],
            [110.336820819379952, -6.983451830849587],
            [110.337053712261735, -6.983730776628569],
            [110.337290273570318, -6.983993338155055],
            [110.337479559195131, -6.984177528659847],
            [110.337721680821403, -6.984374540555221],
            [110.338062181689892, -6.984599005983614],
            [110.338300735486953, -6.984741381552916],
            [110.33863404651224, -6.984894816143858],
            [110.339118590863805, -6.985075778047693],
            [110.339586755685986, -6.98524032722301],
            [110.340133272621742, -6.985417732877067],
            [110.341405679625183, -6.985833733777655],
            [110.341909580545945, -6.985968198674402],
            [110.342198728353154, -6.986028051651257],
            [110.342522634719558, -6.986051622711186],
            [110.34303501671954, -6.986030869137303],
            [110.34353090348624, -6.985986972797088],
            [110.344159033103679, -6.985926746165857],
            [110.344661537657359, -6.985877903698885],
            [110.345134311063987, -6.985815808393927],
            [110.34570459654536, -6.985753847939215],
            [110.345888641353355, -6.985737094545005],
            [110.346048411822451, -6.98572294883336],
            [110.346519484123291, -6.985695528623872],
            [110.346884801129534, -6.985653099074788],
            [110.347301415125372, -6.985566153120494],
            [110.347711470641528, -6.985441216075255],
            [110.348067015029471, -6.985294735401636],
            [110.348414350341457, -6.985108610078227],
            [110.348784780386126, -6.984872535451312],
            [110.349021036053969, -6.984656747290225],
            [110.349215565236733, -6.984399152031791],
            [110.349723004760918, -6.983591878810068],
            [110.350065463754461, -6.983056401705097],
            [110.350329004093368, -6.982728987400202],
            [110.350540361265473, -6.982557276467156],
            [110.350793866188653, -6.982440793894098],
            [110.351089518930891, -6.982379539619244],
            [110.351368860107002, -6.982370187865292],
            [110.351726068341065, -6.982422604080379],
            [110.352180676453671, -6.982504361710251],
            [110.352651426610677, -6.98265753815904],
            [110.35307347938388, -6.982794420762107],
            [110.353764983033514, -6.983029031958978],
            [110.354570078674953, -6.983328704133037],
            [110.355316657972139, -6.983667238918733],
            [110.355938520059127, -6.983969206346648],
            [110.356232142134843, -6.984131434179113],
            [110.35642632148263, -6.984258848943776],
            [110.356784548315872, -6.984552937984527],
            [110.357061763410442, -6.984870034468901],
            [110.357237250350295, -6.985131508632016],
            [110.3572715353598, -6.985182542864316],
            [110.35736193631061, -6.985318935476408],
            [110.357558098891815, -6.985689092960031],
            [110.357680458758225, -6.98588576382498],
            [110.357800631158383, -6.985989959143848],
            [110.357969379506912, -6.986103467845865],
            [110.35812662230056, -6.986170724555626],
            [110.358431900345948, -6.986270548091801],
            [110.358767298106912, -6.986340358829811],
            [110.359401086719473, -6.986470682072935],
            [110.360062646948052, -6.986596418566152],
            [110.360492911866899, -6.986664045076077],
            [110.360688821064826, -6.986682664324171],
            [110.360658200070773, -6.986785701275161],
            [110.360604472916307, -6.986975582206514],
            [110.360438309349249, -6.987255764773449],
            [110.360291801323243, -6.987436474334945],
            [110.360062414139861, -6.987608025904976],
            [110.359523884478918, -6.987932929455551],
            [110.358956214798724, -6.988257792685541],
            [110.358808685216175, -6.988357091570323],
            [110.358455249319107, -6.988419928497311],
            [110.358016621612876, -6.98850978535304],
            [110.357736741121187, -6.988572721912925],
            [110.357421140279399, -6.988689882074907],
            [110.357148516314439, -6.988825191462242],
            [110.356846137585904, -6.988996641717145],
            [110.356669172746933, -6.989104945105692],
            [110.356514545061074, -6.989222324333487],
            [110.356352241698744, -6.989366829251636],
            [110.356057069347628, -6.989646833705521],
            [110.355837777591915, -6.989881714632383],
            [110.355668068658986, -6.990062390785078],
            [110.355520426335801, -6.990243097047305],
            [110.355299079291527, -6.990532247308707],
            [110.354919018564232, -6.991029224531562],
            [110.354723489811192, -6.991264137354716],
            [110.354493542803041, -6.991634684018952],
            [110.354434573830261, -6.991725057278534],
            [110.354349835385179, -6.991833486022056],
            [110.354231726190889, -6.99193282359372],
            [110.354158108029551, -6.991977949696381],
            [110.354062127704083, -6.992032090528199],
            [110.353922309052152, -6.992077125807311],
            [110.353774864292049, -6.992113105174901],
            [110.353126572879205, -6.992220760194821],
            [110.352722776037936, -6.992283523317069],
            [110.352538503294141, -6.992355633286524],
            [110.352321105337438, -6.992445788436731],
            [110.352203353271463, -6.99249085352062],
            [110.352085293349887, -6.992554008953994],
            [110.351882656326026, -6.992608002581933],
            [110.35168737487561, -6.992662006242812],
            [110.3515769907942, -6.992698035899441],
            [110.351462658459013, -6.992725014691085],
            [110.351400048407072, -6.992788246284991],
            [110.351340833237501, -6.992851482544322],
            [110.351285553808808, -6.992932815016879],
            [110.351226546627643, -6.993050323948401],
            [110.351106539327304, -6.993294384433943],
            [110.350992240012317, -6.993502271141194],
            [110.350874054801395, -6.993655880055246],
            [110.35078566237253, -6.993746212207122],
            [110.350674895126275, -6.993854604287628],
            [110.350557067602239, -6.993953941201248],
            [110.350445168623111, -6.994062328741065],
            [110.350350552033646, -6.994152655125623],
            [110.350225085823723, -6.994251981403714],
            [110.350114651258551, -6.994324192194021],
            [110.349996540593068, -6.99442352855319],
            [110.349878454897663, -6.994504774116916],
            [110.349742250460224, -6.994595040024064],
            [110.349583721170958, -6.994667184256045],
            [110.349413026737182, -6.99473931162207],
            [110.349180940931404, -6.994811354072149],
            [110.348904696146477, -6.994901426133251],
            [110.348753534823558, -6.994964534856366],
            [110.348686988449259, -6.995009669788138],
            [110.348525359417863, -6.995072763952124],
            [110.34843419988448, -6.995117864788714],
            [110.34835205581804, -6.995190114267641],
            [110.348279210116416, -6.99528951278404],
            [110.348206314209506, -6.995425092794036],
            [110.348074988300652, -6.995669136615133],
            [110.348021650691905, -6.995777607483973],
            [110.347941436615855, -6.995886041120698],
            [110.347824752220049, -6.995976333432816],
            [110.34762629423561, -6.996075557797194],
            [110.347356234809155, -6.996192773598971],
            [110.347267017638885, -6.996265013047382],
            [110.347172142239074, -6.996337244629763],
            [110.346939326153944, -6.996526874946881],
            [110.346775910929438, -6.996653283699183],
            [110.34661898998111, -6.996788746791174],
            [110.346472536629975, -6.996924224354398],
            [110.346342209094644, -6.99705972425667],
            [110.346138211451347, -6.997276530297049],
            [110.345913278222682, -6.997493307137608],
            [110.345750132711117, -6.997628761236715],
            [110.345569138523445, -6.997782281230945],
            [110.345379694696661, -6.997908653226234],
            [110.345013588188195, -6.998125233194331],
            [110.344740402385881, -6.998251488353973],
            [110.344537711816827, -6.998341659979227],
            [110.344407226650986, -6.998386705165286],
            [110.344312105366782, -6.998431799583543],
            [110.344214167578968, -6.998467844670006],
            [110.344116229777768, -6.998503889734632],
            [110.344021416616883, -6.998530893746889],
            [110.343882186507017, -6.998557741492151],
            [110.343874868579363, -6.99853068945803],
            [110.343748584132157, -6.998403877984635],
            [110.343711843584671, -6.998376690595911],
            [110.343605802448877, -6.998340361171191],
            [110.343314015165021, -6.998213318740306],
            [110.343050481205026, -6.998113451732785],
            [110.342758888578572, -6.998049726941585],
            [110.342632465368951, -6.998022414231526],
            [110.34239911453129, -6.997985906719246],
            [110.341984738823683, -6.997922009897461],
            [110.341581692366631, -6.997849083187844],
            [110.341058730004647, -6.997748852134833],
            [110.340736288041185, -6.997694128325874],
            [110.34052474710235, -6.997639559699937],
            [110.340382789504659, -6.997594133882108],
            [110.340213119413519, -6.997539623799239],
            [110.340035257669257, -6.997476056793936],
            [110.339895871788059, -6.997412543685718],
            [110.339659962684152, -6.997385076652558],
            [110.339222081105177, -6.997339235258705],
            [110.338653790766259, -6.997284164880439],
            [110.33820994287484, -6.997256404931727],
            [110.337871041989501, -6.997237837581494],
            [110.337583951958138, -6.997192206779686],
            [110.337409447447598, -6.997155779674477],
            [110.337029035514917, -6.997082881049733],
            [110.336739720728403, -6.997010110495105],
            [110.336551662244588, -6.996955573239555],
            [110.336319816658005, -6.996855747315629],
            [110.336145916565926, -6.996792184496681],
            [110.335868497133461, -6.996710384837848],
            [110.335686084307, -6.996664900592895],
            [110.335408601093789, -6.996628327443307],
            [110.335109308304808, -6.996609814072094],
            [110.334843926298561, -6.996618484539629],
            [110.334736973667503, -6.996627378776203],
            [110.334730324690995, -6.996527870309049],
            [110.334720497266815, -6.99607558791311],
            [110.334712715431081, -6.995777079696973],
            [110.334696075011394, -6.995740874701056],
            [110.334660479688637, -6.995704642918366],
            [110.334589199418161, -6.995695496810734],
            [110.334471239164003, -6.995686284708906],
            [110.334134307355214, -6.995676762938383],
            [110.333692699267644, -6.995667092798405],
            [110.333309627724375, -6.995675595875468],
            [110.332950037637445, -6.995684131908892],
            [110.332753133030067, -6.995683852890795],
            [110.33245807188996, -6.99567438926228],
            [110.332039469590327, -6.995601432633793],
            [110.331691568921002, -6.995546666738059],
            [110.331128413445327, -6.995464458749407],
            [110.331015584377795, -6.995428116991291],
            [110.330581229935632, -6.995291819183281],
            [110.33044122886001, -6.995264484025101],
            [110.330145808598175, -6.995110292667579],
            [110.32993037422861, -6.995010487147357],
            [110.329783082160716, -6.994937914619805],
            [110.329487662349209, -6.994783722887267],
            [110.329036167866931, -6.994565991095087],
            [110.328623985110028, -6.994357360316855],
            [110.328335612791079, -6.994221374250071],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 116,
        id: 118,
        area: 2987096.981993,
        perimeter: 8280.846033,
        kode_pos: 50189,
        kode_kec: 3374160,
        kode_kel: 3374091003,
        kec: "Ngaliyan",
        kel: "Bringin",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.320070269721654, -7.005362314190682],
            [110.320205131709315, -7.005353463858768],
            [110.320266497848849, -7.005371643261551],
            [110.320323607184534, -7.005398861843817],
            [110.320354932053263, -7.005453179170144],
            [110.32041625892154, -7.005498494520693],
            [110.320486652150294, -7.005534777626901],
            [110.320652927104831, -7.00558928995829],
            [110.320778823913287, -7.005589471854247],
            [110.320856921316519, -7.005580539331028],
            [110.320930261526712, -7.005535418556738],
            [110.320984363562445, -7.005490269985995],
            [110.321040912090453, -7.005318490153286],
            [110.321042753506617, -7.005218994050192],
            [110.321066570616892, -7.005182847073905],
            [110.321186617706132, -7.005119703007165],
            [110.321321646122442, -7.005065625873518],
            [110.321392130915882, -7.005038591578311],
            [110.321450746395826, -7.005002494800153],
            [110.321498663442341, -7.004930201204052],
            [110.321554153774997, -7.004903145244283],
            [110.321722013164703, -7.004840070011472],
            [110.32186750925969, -7.004786007806437],
            [110.321897498118332, -7.004786051055119],
            [110.321932013597376, -7.004786100829537],
            [110.322023316354134, -7.004840504547978],
            [110.322086366940866, -7.00486773148673],
            [110.322269077031919, -7.004904176249508],
            [110.322312658825297, -7.004895193720064],
            [110.322477471365204, -7.004786887092208],
            [110.32257520716793, -7.004696574458742],
            [110.322723662791901, -7.004552062785145],
            [110.322742683260657, -7.00450686345336],
            [110.32274728818129, -7.004452598013111],
            [110.322756380567256, -7.004425475071969],
            [110.322771414137193, -7.004398360686992],
            [110.322819317873893, -7.004335112255801],
            [110.322888440110546, -7.004271894371662],
            [110.322966589468621, -7.004226780164047],
            [110.323045896554476, -7.004163576916381],
            [110.323109064511797, -7.004109395768809],
            [110.323130052274436, -7.004073244593316],
            [110.32313527513638, -7.003982798647816],
            [110.323150373876956, -7.003910457608764],
            [110.323193981648586, -7.003883384331383],
            [110.323248044167229, -7.003865371441221],
            [110.32334258935667, -7.003829326101724],
            [110.323424982299301, -7.003784217911854],
            [110.323515026918344, -7.003730075365667],
            [110.323568845596057, -7.00368492604325],
            [110.323603426167921, -7.003639749044463],
            [110.323613972147626, -7.003585492128277],
            [110.323617523487385, -7.003476953067456],
            [110.323573070838222, -7.003305027533809],
            [110.323534133112503, -7.003232608750888],
            [110.323462429640927, -7.003123961445284],
            [110.323326004356701, -7.002969994283295],
            [110.323294383313524, -7.002924722053302],
            [110.323281164638374, -7.002870430952346],
            [110.323275366879983, -7.002770923793312],
            [110.323288728957053, -7.002725716283],
            [110.323311401073852, -7.00269861286065],
            [110.323339731439432, -7.002671517577106],
            [110.323387839611584, -7.002662541437383],
            [110.323476121367023, -7.002653623077538],
            [110.323570640228311, -7.002635668323028],
            [110.323614234823779, -7.002617640320508],
            [110.323648776214725, -7.002599599295546],
            [110.32368927179516, -7.002572521481316],
            [110.323731155878079, -7.00256353635538],
            [110.323851110792361, -7.002563708808778],
            [110.32390800226149, -7.002545699891665],
            [110.323939444623676, -7.00251860904085],
            [110.323972044678371, -7.002473429154627],
            [110.324015665290602, -7.00243731045227],
            [110.324072582781511, -7.002401210853257],
            [110.324182148014557, -7.002347096194982],
            [110.324269067215269, -7.002301994325378],
            [110.324344117095848, -7.002247830039757],
            [110.324411593396221, -7.002148428116189],
            [110.324458095120775, -7.002076132106446],
            [110.324497189063948, -7.002040006850909],
            [110.324549553854396, -7.002021991346937],
            [110.324624538611303, -7.002013053664073],
            [110.324678074069595, -7.001967903776166],
            [110.324711291870074, -7.001886543314288],
            [110.324733798018102, -7.001778031422115],
            [110.324731085960138, -7.00169661937829],
            [110.324738493700934, -7.00166044861144],
            [110.324760921790045, -7.001606208704422],
            [110.324821312098891, -7.001515841881572],
            [110.324881380460951, -7.001452610637794],
            [110.324932382623658, -7.001398411725408],
            [110.324993891517266, -7.001317091829833],
            [110.325031570795531, -7.001280964489062],
            [110.325079408894524, -7.001262942420896],
            [110.325122990314426, -7.001253959592055],
            [110.325184382180353, -7.001254047658181],
            [110.325260742382227, -7.001272247887518],
            [110.325331174436755, -7.001281394252016],
            [110.325359748533828, -7.001281435229831],
            [110.32537166981308, -7.001254316271247],
            [110.32536576764609, -7.001227171753083],
            [110.325340396542188, -7.001163817909618],
            [110.325315360334315, -7.001064283139999],
            [110.325261428159976, -7.000991842981771],
            [110.325162860950726, -7.000874112040215],
            [110.325123884139586, -7.000828829374792],
            [110.325060833857464, -7.000801602873772],
            [110.324987598768359, -7.000774361752431],
            [110.324900848945717, -7.000701874474153],
            [110.324870925340946, -7.000656604781617],
            [110.324865706051042, -7.000548053083044],
            [110.324874993248912, -7.000385250094998],
            [110.324902516758897, -7.000132019761423],
            [110.324964334380184, -7.000032609590921],
            [110.325132490527508, -6.999761490260874],
            [110.325180715330632, -6.999671105907631],
            [110.325274231830534, -6.999562695789516],
            [110.325319279607868, -6.999517533621308],
            [110.325368853948746, -6.999472377937535],
            [110.325457200040375, -6.999418232475408],
            [110.325550368581489, -6.999355048554078],
            [110.325586350185247, -6.999318918713814],
            [110.325625469771097, -6.999264702661986],
            [110.32564941605024, -6.999138102040694],
            [110.325657441420418, -6.999065750719044],
            [110.32567120289923, -6.998939135499335],
            [110.325648764822091, -6.998803423054262],
            [110.325636986458306, -6.998731043353573],
            [110.325596712065192, -6.998604350700993],
            [110.325595479295302, -6.998477713994435],
            [110.325626755217755, -6.998369214576176],
            [110.325697586603184, -6.998296953240704],
            [110.325846231206327, -6.998215758005883],
            [110.325979367616313, -6.99809835909494],
            [110.326061284292848, -6.997989932154327],
            [110.326117726070891, -6.99789051407604],
            [110.326184469320523, -6.997709702548077],
            [110.326208955210092, -6.997601193349555],
            [110.326206821640298, -6.99751073675194],
            [110.326169285458846, -6.997447365537214],
            [110.326082779989193, -6.997402014919849],
            [110.325987774276925, -6.997365697470348],
            [110.325921290537451, -6.997365602264995],
            [110.325748406860612, -6.997383445357104],
            [110.325576937724307, -6.997401290411233],
            [110.325483886458898, -6.997383066370239],
            [110.325404453815736, -6.997337725770747],
            [110.325366660823576, -6.997256263429152],
            [110.325364797334629, -6.997174852581888],
            [110.325395803269444, -6.997057307429915],
            [110.32552040292525, -6.99677708002821],
            [110.325618357054097, -6.996532995820584],
            [110.325689730615878, -6.996279828154519],
            [110.325743021299871, -6.996207541650714],
            [110.325880322900929, -6.996144420797842],
            [110.326059751931879, -6.996099450919027],
            [110.326161882000264, -6.996099597110426],
            [110.326292533992572, -6.996135965516629],
            [110.326381032591527, -6.996172273574517],
            [110.326467306824185, -6.996181442368659],
            [110.326522692147691, -6.996226748381336],
            [110.326584597348045, -6.996263018359321],
            [110.326637745341841, -6.996290230448674],
            [110.326752876316675, -6.996299440467129],
            [110.326927148223518, -6.996299689661092],
            [110.327102307717084, -6.996272803989905],
            [110.327195371809538, -6.996281982358678],
            [110.327303738885831, -6.996273091862884],
            [110.327377025250485, -6.996264151220901],
            [110.327407952942337, -6.996200877909247],
            [110.327417122531344, -6.996119482796121],
            [110.327392934765797, -6.996019949312052],
            [110.327306727174431, -6.995766556145891],
            [110.327264742096716, -6.995648906514654],
            [110.327216237194506, -6.995540292921468],
            [110.327203069972214, -6.995449820536587],
            [110.32723411414409, -6.995305139184499],
            [110.327289732476686, -6.995187629009807],
            [110.327371892377982, -6.995106338171281],
            [110.32749623145655, -6.995007016845159],
            [110.327565634544598, -6.994943798461419],
            [110.327607582586609, -6.994889586218396],
            [110.327643384268285, -6.994781093046291],
            [110.327710022904398, -6.994672643897613],
            [110.327825373411898, -6.994528082839961],
            [110.327907288992023, -6.994419655461648],
            [110.327976164912769, -6.994329300173806],
            [110.328046699465389, -6.994266083316581],
            [110.328134117972141, -6.994266208049845],
            [110.328197450538809, -6.994293434483446],
            [110.328276895935048, -6.994329729253184],
            [110.328335612791079, -6.994221374250071],
            [110.328623985110028, -6.994357360316855],
            [110.329036167866931, -6.994565991095087],
            [110.329487662349209, -6.994783722887267],
            [110.329783082160716, -6.994937914619805],
            [110.32993037422861, -6.995010487147357],
            [110.330145808598175, -6.995110292667579],
            [110.33044122886001, -6.995264484025101],
            [110.330581229935632, -6.995291819183281],
            [110.331015584377795, -6.995428116991291],
            [110.331128413445327, -6.995464458749407],
            [110.331691568921002, -6.995546666738059],
            [110.332039469590327, -6.995601432633793],
            [110.33245807188996, -6.99567438926228],
            [110.332753133030067, -6.995683852890795],
            [110.332950037637445, -6.995684131908892],
            [110.333309627724375, -6.995675595875468],
            [110.333692699267644, -6.995667092798405],
            [110.334134307355214, -6.995676762938383],
            [110.334471239164003, -6.995686284708906],
            [110.334589199418161, -6.995695496810734],
            [110.334660479688637, -6.995704642918366],
            [110.334696075011394, -6.995740874701056],
            [110.334712715431081, -6.995777079696973],
            [110.334720497266815, -6.99607558791311],
            [110.334730324690995, -6.996527870309049],
            [110.334736973667503, -6.996627378776203],
            [110.334843926298561, -6.996618484539629],
            [110.335109308304808, -6.996609814072094],
            [110.335408601093789, -6.996628327443307],
            [110.335686084307, -6.996664900592895],
            [110.335868497133461, -6.996710384837848],
            [110.336145916565926, -6.996792184496681],
            [110.336319816658005, -6.996855747315629],
            [110.336551662244588, -6.996955573239555],
            [110.336739720728403, -6.997010110495105],
            [110.337029035514917, -6.997082881049733],
            [110.337409447447598, -6.997155779674477],
            [110.337583951958138, -6.997192206779686],
            [110.337871041989501, -6.997237837581494],
            [110.33820994287484, -6.997256404931727],
            [110.338653790766259, -6.997284164880439],
            [110.339222081105177, -6.997339235258705],
            [110.339659962684152, -6.997385076652558],
            [110.339895871788059, -6.997412543685718],
            [110.340035257669257, -6.997476056793936],
            [110.340213119413519, -6.997539623799239],
            [110.340382789504659, -6.997594133882108],
            [110.34052474710235, -6.997639559699937],
            [110.340736288041185, -6.997694128325874],
            [110.341058730004647, -6.997748852134833],
            [110.341581692366631, -6.997849083187844],
            [110.341984738823683, -6.997922009897461],
            [110.34239911453129, -6.997985906719246],
            [110.342632465368951, -6.998022414231526],
            [110.342758888578572, -6.998049726941585],
            [110.343050481205026, -6.998113451732785],
            [110.343314015165021, -6.998213318740306],
            [110.343605802448877, -6.998340361171191],
            [110.343711843584671, -6.998376690595911],
            [110.343748584132157, -6.998403877984635],
            [110.343874868579363, -6.99853068945803],
            [110.343882186507017, -6.998557741492151],
            [110.343616330487222, -6.998702191260046],
            [110.343415362296895, -6.998774273995435],
            [110.343190925050465, -6.99883727850021],
            [110.342853310527161, -6.998909170248182],
            [110.34270503962621, -6.998927053945112],
            [110.342502128831811, -6.998971997405778],
            [110.342198708442297, -6.999071072586414],
            [110.342082330475179, -6.999143272953107],
            [110.341927117561724, -6.99926969127223],
            [110.341858538901064, -6.999351003799289],
            [110.341806098856466, -6.999423293506213],
            [110.341650104440404, -6.999703482105086],
            [110.341524199942285, -6.999911349694695],
            [110.341440255090561, -7.000055958304225],
            [110.341254592499254, -7.000308969057119],
            [110.341026133794273, -7.000616192055392],
            [110.340802509686881, -7.000905330926794],
            [110.340634746092178, -7.001104094170087],
            [110.340544589355687, -7.001239648482541],
            [110.34047278378651, -7.001402364635372],
            [110.340358684378529, -7.001664520627973],
            [110.340238643656875, -7.001926668245289],
            [110.340083470698232, -7.002224948039681],
            [110.339800179005167, -7.002694910110123],
            [110.339667199966968, -7.00290276709683],
            [110.339494072500244, -7.003092476899484],
            [110.339406550333095, -7.003164716985523],
            [110.33925867838191, -7.00330018990057],
            [110.33921441650763, -7.003390581469686],
            [110.339199514010971, -7.00352624116274],
            [110.339199144786733, -7.003788556529519],
            [110.339186120800562, -7.003996581859727],
            [110.339160123703664, -7.004376451081863],
            [110.339140095956466, -7.004937236178462],
            [110.339120516590427, -7.005380432017737],
            [110.339093051044273, -7.005597482398616],
            [110.339006058577624, -7.005895857453737],
            [110.338940437551074, -7.006085718043288],
            [110.338848749995364, -7.006302678080148],
            [110.338806455373827, -7.006402117706413],
            [110.338669078008678, -7.006718512534014],
            [110.338537769187141, -7.006944462110458],
            [110.338480699035316, -7.007089107765241],
            [110.338207200204408, -7.007432446933277],
            [110.337797362799364, -7.007956501258362],
            [110.337733785191617, -7.008101137615037],
            [110.337682874947845, -7.008291018664653],
            [110.337656878032405, -7.008869885756193],
            [110.337654359267432, -7.009050789618311],
            [110.337714973286324, -7.009602642652072],
            [110.33772950620336, -7.009729298321627],
            [110.337747989148355, -7.010064003077066],
            [110.337756016900926, -7.010389647722777],
            [110.337753793801468, -7.010561506623268],
            [110.337750815171432, -7.011068043148927],
            [110.337735464704934, -7.011520290003229],
            [110.337770716469095, -7.011800746162157],
            [110.337875413079786, -7.012189844649775],
            [110.33795573851836, -7.012407046746793],
            [110.338136374728805, -7.01291384203737],
            [110.338197795836493, -7.013094835988064],
            [110.338237778918796, -7.013230572877022],
            [110.338288140685421, -7.013429641977512],
            [110.338296958570268, -7.013492971991842],
            [110.338136933742845, -7.013519882605201],
            [110.337967439134587, -7.013537734443674],
            [110.337776480933485, -7.013528419847586],
            [110.337529183332705, -7.013546161811488],
            [110.336965825771784, -7.013590593636433],
            [110.336772295734193, -7.013599365735411],
            [110.336421475018454, -7.013598870165582],
            [110.336068120859537, -7.013589325386088],
            [110.335886794837805, -7.013570978274473],
            [110.335399709425502, -7.013497926311757],
            [110.335094245852531, -7.013434176391238],
            [110.334805474642323, -7.013370449922405],
            [110.334559707311385, -7.01330678421647],
            [110.334345356975732, -7.013234117554015],
            [110.334180762012991, -7.01318865742689],
            [110.333975388127939, -7.013170275505703],
            [110.333526729221745, -7.013133457602141],
            [110.333142537457547, -7.013123866897871],
            [110.333053996539576, -7.013114695812732],
            [110.332882341671763, -7.013060179859858],
            [110.3326247051194, -7.01298745096052],
            [110.332510200058636, -7.012933016082577],
            [110.332302357437044, -7.012860357780241],
            [110.331970969422571, -7.012724206177478],
            [110.331701219290309, -7.012615278137726],
            [110.331287000907565, -7.012433781435244],
            [110.33083879496688, -7.012080374192265],
            [110.330564390862591, -7.011862894644529],
            [110.330190425587375, -7.011627182296611],
            [110.329727624388767, -7.011391342941968],
            [110.329027006103459, -7.011073755385493],
            [110.328778720649623, -7.010991992529206],
            [110.328296861759654, -7.010828487480725],
            [110.327905498009471, -7.010692247489757],
            [110.327760130188352, -7.010655858105675],
            [110.327106297172648, -7.010464969754276],
            [110.326798599803382, -7.01038312081926],
            [110.326583725427867, -7.010283314041127],
            [110.326426335319553, -7.010147408184582],
            [110.326276288157359, -7.01002055816167],
            [110.326159882221802, -7.009911847042514],
            [110.325988398505018, -7.009739739451796],
            [110.32584035801365, -7.009594801491008],
            [110.325716557440643, -7.00951321571727],
            [110.325481944584709, -7.00936815342417],
            [110.325310370250747, -7.009259362932137],
            [110.324883569670945, -7.008978344178491],
            [110.324683164201687, -7.008851421367989],
            [110.324618737403256, -7.00879705669795],
            [110.32453283502899, -7.008724570459518],
            [110.324450109887195, -7.00860686204008],
            [110.324331018876833, -7.008398647859688],
            [110.324018690942069, -7.00780120586872],
            [110.32362059734993, -7.007429773974406],
            [110.323409572539902, -7.007212381970294],
            [110.323328776159286, -7.007130857555928],
            [110.323236110413902, -7.007040270696105],
            [110.322914144101119, -7.006849854799499],
            [110.322735228582715, -7.006732007580249],
            [110.32243729740776, -7.00655067136457],
            [110.322206082093686, -7.006405612550264],
            [110.32203421366944, -7.006305865941938],
            [110.321884129747758, -7.006206150700638],
            [110.321597681082281, -7.006106238658321],
            [110.321328155098755, -7.006042532251191],
            [110.321077558298597, -7.005996943716122],
            [110.320936989086704, -7.005969604672346],
            [110.320664943263182, -7.005887803578656],
            [110.320421524282182, -7.005769862353649],
            [110.320190362455392, -7.005588621354504],
            [110.320140082353916, -7.005534276609287],
            [110.320070269721654, -7.005362314190682],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 117,
        id: 119,
        area: 5888284.5109050004,
        perimeter: 10806.721409,
        kode_pos: 50181,
        kode_kec: 3374160,
        kode_kel: 3374091004,
        kec: "Ngaliyan",
        kel: "Ngaliyan",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.343882186507017, -6.998557741492151],
            [110.344021416616883, -6.998530893746889],
            [110.344116229777768, -6.998503889734632],
            [110.344214167578968, -6.998467844670006],
            [110.344312105366782, -6.998431799583543],
            [110.344407226650986, -6.998386705165286],
            [110.344537711816827, -6.998341659979227],
            [110.344740402385881, -6.998251488353973],
            [110.345013588188195, -6.998125233194331],
            [110.345379694696661, -6.997908653226234],
            [110.345569138523445, -6.997782281230945],
            [110.345750132711117, -6.997628761236715],
            [110.345913278222682, -6.997493307137608],
            [110.346138211451347, -6.997276530297049],
            [110.346342209094644, -6.99705972425667],
            [110.346472536629975, -6.996924224354398],
            [110.34661898998111, -6.996788746791174],
            [110.346775910929438, -6.996653283699183],
            [110.346939326153944, -6.996526874946881],
            [110.347172142239074, -6.996337244629763],
            [110.347267017638885, -6.996265013047382],
            [110.347356234809155, -6.996192773598971],
            [110.34762629423561, -6.996075557797194],
            [110.347824752220049, -6.995976333432816],
            [110.347941436615855, -6.995886041120698],
            [110.348021650691905, -6.995777607483973],
            [110.348074988300652, -6.995669136615133],
            [110.348206314209506, -6.995425092794036],
            [110.348279210116416, -6.99528951278404],
            [110.34835205581804, -6.995190114267641],
            [110.34843419988448, -6.995117864788714],
            [110.348525359417863, -6.995072763952124],
            [110.348686988449259, -6.995009669788138],
            [110.348753534823558, -6.994964534856366],
            [110.348904696146477, -6.994901426133251],
            [110.349180940931404, -6.994811354072149],
            [110.349413026737182, -6.99473931162207],
            [110.349583721170958, -6.994667184256045],
            [110.349742250460224, -6.994595040024064],
            [110.349878454897663, -6.994504774116916],
            [110.349996540593068, -6.99442352855319],
            [110.350114651258551, -6.994324192194021],
            [110.350225085823723, -6.994251981403714],
            [110.350350552033646, -6.994152655125623],
            [110.350445168623111, -6.994062328741065],
            [110.350601184420753, -6.994171091618132],
            [110.350693325309123, -6.994234536468258],
            [110.350770509516607, -6.994270824484102],
            [110.350969257240791, -6.994370597848902],
            [110.351208448591777, -6.994479472261776],
            [110.351492044347253, -6.99459745311963],
            [110.351790068638337, -6.994715453681606],
            [110.351988841736983, -6.99479713588326],
            [110.352143505956903, -6.994860666517233],
            [110.352261121533459, -6.994915100737982],
            [110.352397384249215, -6.994987651382466],
            [110.352487274745471, -6.995042047418653],
            [110.352683993016697, -6.9951779989373],
            [110.352790009443154, -6.995232417091312],
            [110.352991624514388, -6.995305057407087],
            [110.353352468150121, -6.995414097969357],
            [110.353525521939133, -6.995477653439199],
            [110.353878124169711, -6.995613818517372],
            [110.354276545646755, -6.995759091598389],
            [110.354737453316318, -6.995931586226473],
            [110.354894098722596, -6.995995118736442],
            [110.35507759583929, -6.996076778794636],
            [110.355650301120249, -6.996421288455113],
            [110.355886923782435, -6.996548247972625],
            [110.356126363295346, -6.996684256642563],
            [110.356478451056432, -6.996784237578655],
            [110.356771718263403, -6.996866047075752],
            [110.356967406029852, -6.996929632293824],
            [110.357392644490844, -6.997120166597345],
            [110.357950002910925, -6.99731088077875],
            [110.358307122284145, -6.997456094340576],
            [110.358443989668004, -6.997501507967483],
            [110.358710590010404, -6.997637552447665],
            [110.359115386267192, -6.997882329931549],
            [110.359211206992683, -6.997945778284411],
            [110.359800769382915, -6.998380760429688],
            [110.359999422456298, -6.998552893474748],
            [110.360499364687513, -6.998942525904553],
            [110.360921629828425, -6.999241598234688],
            [110.361467405486877, -6.999631292004746],
            [110.361681632230699, -6.999794400149726],
            [110.361947041830774, -6.999975668450581],
            [110.362176804570595, -7.000156888297827],
            [110.36224994401033, -7.000256487024886],
            [110.36234288926417, -7.000356112797178],
            [110.362330919092528, -7.00041941428172],
            [110.362320303926111, -7.000527944898555],
            [110.362371622264106, -7.000654650287995],
            [110.362566599386156, -7.000826777420652],
            [110.362740973038854, -7.000962694906214],
            [110.362976948405247, -7.001152968258681],
            [110.363120533743427, -7.001252662259629],
            [110.363192308037952, -7.001316077347901],
            [110.363274464324249, -7.00144282440647],
            [110.363335906601506, -7.001614770511909],
            [110.363397287606844, -7.001831943620744],
            [110.363448135289758, -7.002089955590875],
            [110.363587772968856, -7.002280392869785],
            [110.363776408205382, -7.002447545013508],
            [110.363999188157607, -7.002649798166136],
            [110.364414526786959, -7.002992030119336],
            [110.364310213104062, -7.004155246762596],
            [110.363899814206064, -7.007447509383501],
            [110.360219289525403, -7.007008031357444],
            [110.360152549302569, -7.00720165895136],
            [110.360013657332743, -7.007494814916002],
            [110.359808349417946, -7.007743601867005],
            [110.359386789585898, -7.008141533912008],
            [110.358740557664234, -7.008768853640154],
            [110.358357789025575, -7.009155767894164],
            [110.358126844185279, -7.009414896710899],
            [110.357884018304233, -7.009753572038422],
            [110.357752071445361, -7.010030132172147],
            [110.357658148326735, -7.010351714559787],
            [110.357441105210015, -7.012087963984682],
            [110.354040000918729, -7.011688272215697],
            [110.353709195819107, -7.014244877080003],
            [110.353473594194099, -7.014362167344274],
            [110.352933392005895, -7.014416771309213],
            [110.352600701118021, -7.014637704861352],
            [110.352208081260571, -7.015746248527334],
            [110.35212883112446, -7.015735339988421],
            [110.351767421516428, -7.017060980941425],
            [110.350703748637571, -7.019714669488788],
            [110.350493976686593, -7.020290335431508],
            [110.350424780907986, -7.020822999159511],
            [110.350553485281054, -7.021546722363324],
            [110.35052053400905, -7.02192464801383],
            [110.350552636501376, -7.022158674673715],
            [110.35066025739286, -7.022497197946822],
            [110.350807761116513, -7.02265579008755],
            [110.350974497445378, -7.02278633337614],
            [110.351038643205271, -7.022836555141875],
            [110.35088253783772, -7.022859391275505],
            [110.350504568542902, -7.023034015942793],
            [110.350245829441945, -7.023081529768273],
            [110.350206929096075, -7.023088668846233],
            [110.349859388376629, -7.022961551800697],
            [110.349821501371167, -7.022943408507796],
            [110.349617869814494, -7.022888853861406],
            [110.349208708129368, -7.0229244678536],
            [110.349110004585015, -7.022897194730498],
            [110.348685753634754, -7.0227971065492],
            [110.348559606320322, -7.022769795205128],
            [110.348470792684921, -7.022751581074161],
            [110.348115833747954, -7.022669679415153],
            [110.348087836662472, -7.022660595119261],
            [110.348028535312366, -7.022655248051801],
            [110.347476509126224, -7.022605472747122],
            [110.347408323962668, -7.022605377884259],
            [110.346467595142911, -7.022604068080054],
            [110.346070844562021, -7.022666832769269],
            [110.345893412082646, -7.022693721501087],
            [110.345732106368828, -7.022720632659753],
            [110.345670415867531, -7.022729591981792],
            [110.345607310734778, -7.022738549321815],
            [110.345532039783762, -7.02274748967765],
            [110.345440076198869, -7.022756406725986],
            [110.34533198583199, -7.022765301246729],
            [110.345010261054171, -7.022791988213076],
            [110.344875292713553, -7.022800845091781],
            [110.344708919589948, -7.022809658055912],
            [110.344641570550479, -7.022818609333999],
            [110.344430828712845, -7.022791178691879],
            [110.343584209099333, -7.022663305839533],
            [110.343356520856858, -7.022642727876293],
            [110.343129637436775, -7.022610589658989],
            [110.342882881587116, -7.022554558096722],
            [110.342652173436463, -7.022411042445327],
            [110.342508796737079, -7.022328920882778],
            [110.342373713497523, -7.022251549483384],
            [110.342126974726838, -7.022183584874782],
            [110.341987621922826, -7.022191344542443],
            [110.341569546725651, -7.022226555966776],
            [110.341522500291305, -7.022237128867395],
            [110.341147406353798, -7.02232142477742],
            [110.340992425847773, -7.022373952850517],
            [110.340856894286901, -7.022287101165747],
            [110.340780241312743, -7.022180022159295],
            [110.340729429375571, -7.022098542407014],
            [110.340621953287922, -7.021872257066944],
            [110.340581686067736, -7.021736519899386],
            [110.340496227307597, -7.021546446973353],
            [110.340415012483632, -7.021356380003569],
            [110.340244599971811, -7.021220459815835],
            [110.340199678661605, -7.021175169787073],
            [110.340138605161499, -7.021147947777158],
            [110.34002088299421, -7.021165872921736],
            [110.339896074952449, -7.02119283343565],
            [110.339628309220501, -7.021281298598932],
            [110.339419387330338, -7.021364024474665],
            [110.339262836191509, -7.021451335393967],
            [110.339089537403126, -7.021535421891653],
            [110.338990694475072, -7.021607645571669],
            [110.338780032300591, -7.021724938466167],
            [110.338622893961769, -7.0218061252527],
            [110.338453859940444, -7.021896340572345],
            [110.338309344037668, -7.021953712929516],
            [110.338055274647147, -7.021949388160174],
            [110.337582733792658, -7.021895111232111],
            [110.337166208781781, -7.021809311877012],
            [110.336753516919188, -7.021681806653398],
            [110.336227195461333, -7.021513357049291],
            [110.336253897678674, -7.021450010050974],
            [110.336308128591597, -7.02131440632578],
            [110.336429553567783, -7.021079398636489],
            [110.336544458340242, -7.02085342704584],
            [110.336659337369994, -7.02064554610706],
            [110.336848496094774, -7.020329225744745],
            [110.336956597478135, -7.020112289759856],
            [110.337049703794932, -7.01989533255286],
            [110.337095446553278, -7.019759716697278],
            [110.337134964962658, -7.019624092041272],
            [110.337223299779552, -7.019379991940892],
            [110.337316920460125, -7.019199216840323],
            [110.337408226498866, -7.019054619910276],
            [110.337602348713574, -7.018828759792555],
            [110.337756218946126, -7.018657114979242],
            [110.3378199661352, -7.018593887359762],
            [110.337880343800521, -7.018512564241254],
            [110.338046764679774, -7.018268574075615],
            [110.338167403015206, -7.017988337803082],
            [110.338262912193031, -7.017671884581429],
            [110.338326644508555, -7.017418704111087],
            [110.338375356322018, -7.017183593189203],
            [110.338422189791288, -7.016876116656483],
            [110.338430894555927, -7.016722357651256],
            [110.338442260537661, -7.016487194072432],
            [110.338457585399127, -7.016053037943863],
            [110.338468345150488, -7.015646011480571],
            [110.338468804787169, -7.015320378823202],
            [110.338465354576911, -7.014958559173691],
            [110.338459255929607, -7.014669098747077],
            [110.338426405422894, -7.014090148789325],
            [110.33840761572111, -7.013972532507653],
            [110.338382420992033, -7.013782544241356],
            [110.338296958570268, -7.013492971991842],
            [110.338288140685421, -7.013429641977512],
            [110.338237778918796, -7.013230572877022],
            [110.338197795836493, -7.013094835988064],
            [110.338136374728805, -7.01291384203737],
            [110.33795573851836, -7.012407046746793],
            [110.337875413079786, -7.012189844649775],
            [110.337770716469095, -7.011800746162157],
            [110.337735464704934, -7.011520290003229],
            [110.337750815171432, -7.011068043148927],
            [110.337753793801468, -7.010561506623268],
            [110.337756016900926, -7.010389647722777],
            [110.337747989148355, -7.010064003077066],
            [110.33772950620336, -7.009729298321627],
            [110.337714973286324, -7.009602642652072],
            [110.337654359267432, -7.009050789618311],
            [110.337656878032405, -7.008869885756193],
            [110.337682874947845, -7.008291018664653],
            [110.337733785191617, -7.008101137615037],
            [110.337797362799364, -7.007956501258362],
            [110.338207200204408, -7.007432446933277],
            [110.338480699035316, -7.007089107765241],
            [110.338537769187141, -7.006944462110458],
            [110.338669078008678, -7.006718512534014],
            [110.338806455373827, -7.006402117706413],
            [110.338848749995364, -7.006302678080148],
            [110.338940437551074, -7.006085718043288],
            [110.339006058577624, -7.005895857453737],
            [110.339093051044273, -7.005597482398616],
            [110.339120516590427, -7.005380432017737],
            [110.339140095956466, -7.004937236178462],
            [110.339160123703664, -7.004376451081863],
            [110.339186120800562, -7.003996581859727],
            [110.339199144786733, -7.003788556529519],
            [110.339199514010971, -7.00352624116274],
            [110.33921441650763, -7.003390581469686],
            [110.33925867838191, -7.00330018990057],
            [110.339406550333095, -7.003164716985523],
            [110.339494072500244, -7.003092476899484],
            [110.339667199966968, -7.00290276709683],
            [110.339800179005167, -7.002694910110123],
            [110.340083470698232, -7.002224948039681],
            [110.340238643656875, -7.001926668245289],
            [110.340358684378529, -7.001664520627973],
            [110.34047278378651, -7.001402364635372],
            [110.340544589355687, -7.001239648482541],
            [110.340634746092178, -7.001104094170087],
            [110.340802509686881, -7.000905330926794],
            [110.341026133794273, -7.000616192055392],
            [110.341254592499254, -7.000308969057119],
            [110.341440255090561, -7.000055958304225],
            [110.341524199942285, -6.999911349694695],
            [110.341650104440404, -6.999703482105086],
            [110.341806098856466, -6.999423293506213],
            [110.341858538901064, -6.999351003799289],
            [110.341927117561724, -6.99926969127223],
            [110.342082330475179, -6.999143272953107],
            [110.342198708442297, -6.999071072586414],
            [110.342502128831811, -6.998971997405778],
            [110.34270503962621, -6.998927053945112],
            [110.342853310527161, -6.998909170248182],
            [110.343190925050465, -6.99883727850021],
            [110.343415362296895, -6.998774273995435],
            [110.343616330487222, -6.998702191260046],
            [110.343882186507017, -6.998557741492151],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 118,
        id: 120,
        area: 1146456.7122599999,
        perimeter: 4706.215847,
        kode_pos: 50166,
        kode_kec: 3374100,
        kode_kel: 3374042004,
        kec: "Gayamsari",
        kel: "Sambirejo",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.448912440973345, -6.977697123835924],
            [110.448962029699018, -6.97875618803465],
            [110.448966952722827, -6.978900922803696],
            [110.449027153167336, -6.979434681353597],
            [110.449020611435074, -6.980673915904222],
            [110.449020336329667, -6.980909100224628],
            [110.448980755582255, -6.981370377709454],
            [110.448785459567716, -6.981930974436269],
            [110.448590386821039, -6.983025259437841],
            [110.448480117598336, -6.98369450205041],
            [110.448224493955195, -6.985277176141896],
            [110.448114085557052, -6.986064010639485],
            [110.448062755292341, -6.98665191192369],
            [110.448062521905115, -6.986850913958552],
            [110.448087372007862, -6.98713135539513],
            [110.448146192547497, -6.987393745483205],
            [110.44817950879424, -6.987692287945293],
            [110.448162403368187, -6.988045044727229],
            [110.44796878581856, -6.989582562945603],
            [110.447950269200675, -6.989691087951516],
            [110.447527108125215, -6.989627273103785],
            [110.446560206786003, -6.989309544012513],
            [110.446041603472494, -6.989091841326973],
            [110.44546022646594, -6.988846927579834],
            [110.443821309893934, -6.988139443129847],
            [110.443022313297107, -6.987948543010487],
            [110.442398614438574, -6.987848304503564],
            [110.44163459258418, -6.987747898981278],
            [110.441312401145481, -6.987711334996836],
            [110.441263217787579, -6.987675094501007],
            [110.441228169188278, -6.987647916306123],
            [110.440582726134807, -6.987610753362119],
            [110.440599713670665, -6.987375891550503],
            [110.440709649803665, -6.986732822960672],
            [110.440769342163179, -6.986516744140683],
            [110.440890356919098, -6.985893715606894],
            [110.440890358946149, -6.985893705170937],
            [110.441029935264169, -6.984895759766222],
            [110.441109713643115, -6.98428650776443],
            [110.441253988394209, -6.983256055465475],
            [110.441326013901829, -6.982834864106325],
            [110.441413208803013, -6.982319655705068],
            [110.441545938713631, -6.981499827826283],
            [110.441636836880519, -6.98092174615767],
            [110.441674618659221, -6.980594859821006],
            [110.44172438132756, -6.980224924586538],
            [110.441838256234149, -6.979431403956906],
            [110.441944472511594, -6.97874695478793],
            [110.441990087199329, -6.97837463011766],
            [110.442191046751418, -6.977156173971112],
            [110.442207253516685, -6.977095449785549],
            [110.442338888829283, -6.97628746511962],
            [110.442426183189298, -6.975685744107829],
            [110.442542811960351, -6.974853580582488],
            [110.44264856350064, -6.974183346007997],
            [110.442648564441882, -6.974183340042626],
            [110.44266903787171, -6.974053582780425],
            [110.442733637894037, -6.973542108440686],
            [110.442811808893552, -6.972855429154834],
            [110.442868086161937, -6.972914649816639],
            [110.443439123715081, -6.973512329849872],
            [110.444194381033313, -6.974309228429079],
            [110.44547312318609, -6.975251470004419],
            [110.447325142269847, -6.976583339114691],
            [110.448912440973345, -6.977697123835924],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 119,
        id: 121,
        area: 1895752.9547,
        perimeter: 5659.594152,
        kode_pos: 50274,
        kode_kec: 3374070,
        kode_kel: 3374052010,
        kec: "Tembalang",
        kel: "Tandang",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.44482071564201, -7.011468844510691],
            [110.444894130693996, -7.011506676897192],
            [110.445121835475888, -7.011623224072343],
            [110.446075877780189, -7.012085672888892],
            [110.446581677955422, -7.012384772135857],
            [110.446863647481337, -7.01247555974458],
            [110.447246840346409, -7.012620739613734],
            [110.44747081049546, -7.012711458618949],
            [110.448308915823574, -7.012884309227054],
            [110.448706591934723, -7.012984277222715],
            [110.449567017634251, -7.013184288510048],
            [110.449993499708896, -7.013329516975188],
            [110.450166837710057, -7.013411129908439],
            [110.450296962364675, -7.013429373336072],
            [110.450513075280625, -7.013465808448021],
            [110.450918989126421, -7.013538647619283],
            [110.451145015160819, -7.013566048483789],
            [110.451466093770179, -7.013602605825833],
            [110.451670900437662, -7.013629981657331],
            [110.451880495726058, -7.013675454099976],
            [110.451967290572838, -7.013729828721996],
            [110.452242030487199, -7.013956288131642],
            [110.452600934847297, -7.014309483294239],
            [110.452846470621495, -7.014590181684853],
            [110.453117102740194, -7.014943273645976],
            [110.453510618691141, -7.015459328538486],
            [110.453732672624255, -7.015739999206407],
            [110.45392280870665, -7.015975404876975],
            [110.454227906914213, -7.016373764176239],
            [110.454582444334179, -7.016835499658141],
            [110.454910135812995, -7.017270067068246],
            [110.455043425498587, -7.017487315227159],
            [110.455167106248538, -7.017695506643726],
            [110.455357694000497, -7.018030413494968],
            [110.455496904574304, -7.018265759538116],
            [110.455725572609595, -7.018700211592281],
            [110.455963588226496, -7.019125628837261],
            [110.456097056780862, -7.019433332553432],
            [110.45630599370817, -7.020048672696964],
            [110.456466990420168, -7.020528273852856],
            [110.456645571111977, -7.020971713097773],
            [110.456678537569601, -7.021089343545435],
            [110.456678506085296, -7.021116480186265],
            [110.456650988334417, -7.021179767230265],
            [110.456620662259411, -7.021224959901263],
            [110.456523731426813, -7.021613806647692],
            [110.456462565253773, -7.021903193654709],
            [110.456451425931846, -7.021993636331946],
            [110.456443136919134, -7.022065991194329],
            [110.45644309491594, -7.022102173379173],
            [110.456448402042653, -7.022174565123331],
            [110.455997624378284, -7.022219249221114],
            [110.455950940699012, -7.022219195078057],
            [110.455913562222094, -7.022246288396332],
            [110.45589485721456, -7.022273403372187],
            [110.455871331858248, -7.022309558312892],
            [110.455843174466295, -7.022436163452391],
            [110.455805627744809, -7.022607985481231],
            [110.455739578495383, -7.022716455531255],
            [110.455668216612125, -7.022770646061852],
            [110.45549022100947, -7.022797576129466],
            [110.455347591857034, -7.022824547197488],
            [110.455247957790277, -7.022860613715222],
            [110.455147977615297, -7.022950953145484],
            [110.455041122802697, -7.023113649011083],
            [110.454947240658342, -7.023312542144809],
            [110.454697858541223, -7.023900213349179],
            [110.454534771899461, -7.024243754770353],
            [110.454457729907887, -7.024316029587148],
            [110.454394593766608, -7.024352138350971],
            [110.454181995356976, -7.024451392040616],
            [110.453960290253775, -7.024595862791268],
            [110.453734896334737, -7.024749374681845],
            [110.45355846022413, -7.024893897955374],
            [110.453463624892862, -7.024939015192106],
            [110.453356099821534, -7.024947935403429],
            [110.453141049672254, -7.024965775751134],
            [110.45292174494719, -7.024992656587061],
            [110.452676693308433, -7.025019507265513],
            [110.452427093558697, -7.025064443605102],
            [110.452237758491037, -7.025109450214503],
            [110.452134739128766, -7.025136466506312],
            [110.451945340457812, -7.025235746222283],
            [110.451898037676159, -7.025280918681702],
            [110.451854947098354, -7.025353232711158],
            [110.451777285684599, -7.025470734065732],
            [110.451639631788566, -7.025597210791909],
            [110.45155353528321, -7.025669474486162],
            [110.451458689024506, -7.025723636826005],
            [110.451415640750056, -7.025759768656601],
            [110.451381352798123, -7.025804956283275],
            [110.4513252047322, -7.025913437170554],
            [110.45119418186313, -7.026175604741616],
            [110.45104732516279, -7.026428708172341],
            [110.450937239082478, -7.026690900159893],
            [110.45087852315919, -7.026817469062746],
            [110.450849253570766, -7.026925981355361],
            [110.450834328669757, -7.027106874835916],
            [110.450797713377384, -7.027206332967705],
            [110.450760783278113, -7.027332927370241],
            [110.450687595084233, -7.027495661469173],
            [110.450599379444824, -7.027685514580695],
            [110.450463513847467, -7.027974812824343],
            [110.450352620726463, -7.028200821470056],
            [110.450283382694579, -7.02837260565822],
            [110.450278728182283, -7.028481146761504],
            [110.450255725413825, -7.028553484154377],
            [110.450200153219441, -7.028652919977961],
            [110.450126473156317, -7.028752334546726],
            [110.450006621459451, -7.028896922648232],
            [110.449921404288446, -7.029008241042636],
            [110.449620527048836, -7.028734722418705],
            [110.449412134196308, -7.028647188874397],
            [110.449158166761109, -7.028478264426407],
            [110.4486352333426, -7.027936483466578],
            [110.448581363438379, -7.027791691379083],
            [110.448506390779087, -7.027547373467696],
            [110.448357258636591, -7.027372326185136],
            [110.448178015921172, -7.027212301858493],
            [110.447853954989682, -7.027058146008909],
            [110.447744534295353, -7.026994698304626],
            [110.447623505932867, -7.026878380256515],
            [110.44750445531119, -7.02671999575607],
            [110.447299951566762, -7.02658640283539],
            [110.446946029887755, -7.026390402723656],
            [110.4464017710512, -7.026121278642317],
            [110.446207914305461, -7.026014368224446],
            [110.446113708068793, -7.025916465594011],
            [110.446039023400701, -7.025864814647422],
            [110.445912722316677, -7.0258184367642],
            [110.445818918135487, -7.025771277709634],
            [110.445701145045263, -7.025647496336528],
            [110.445578571885349, -7.025458880835941],
            [110.445505656476527, -7.025416122026964],
            [110.445365103565408, -7.02538217332348],
            [110.445117693245834, -7.025413885024657],
            [110.444891699952379, -7.025394059251579],
            [110.444742253040076, -7.025356543787255],
            [110.444246235441568, -7.024929230378811],
            [110.444292941350952, -7.024563012800772],
            [110.443910467853613, -7.024434541666572],
            [110.443604528111095, -7.0242990489546],
            [110.443565424364394, -7.0242581080096],
            [110.443460551896337, -7.024203606053546],
            [110.44296771291549, -7.024045813588375],
            [110.443026652977522, -7.023868081231296],
            [110.443033839278186, -7.023811193009479],
            [110.443005841742831, -7.023759834836092],
            [110.44272605164258, -7.02349718186221],
            [110.442462410490521, -7.023216456865905],
            [110.442212307240723, -7.022971929990517],
            [110.44198426209168, -7.022736474779187],
            [110.441799734605311, -7.022546298945289],
            [110.44177748012639, -7.022464862632313],
            [110.441779343718338, -7.022184588113315],
            [110.441778105615057, -7.021940222254337],
            [110.441749607007424, -7.02175071501312],
            [110.441753403701469, -7.021551580906939],
            [110.441813976137837, -7.021279940747413],
            [110.441920333661713, -7.021062974388815],
            [110.442079995390344, -7.020782085231875],
            [110.442170985703228, -7.020590166874213],
            [110.442157170047778, -7.020330567502738],
            [110.442180607289998, -7.020131593532538],
            [110.442210433383408, -7.02003212807171],
            [110.442262163507465, -7.019833187723876],
            [110.442291957248315, -7.019760858830236],
            [110.44243617282406, -7.019589164982939],
            [110.44252850462, -7.019507864835083],
            [110.442617104487326, -7.019471787928023],
            [110.442687281664959, -7.019462825740755],
            [110.442829584239064, -7.019472040264961],
            [110.442934855381381, -7.019454074168594],
            [110.443078941577141, -7.019390926417916],
            [110.443192808092675, -7.01928251506432],
            [110.443260807813829, -7.019201185884902],
            [110.443300546870006, -7.019092686548783],
            [110.443357096943146, -7.018884706205787],
            [110.443419749666134, -7.018541049981464],
            [110.443435525666658, -7.018360157880469],
            [110.443506752034963, -7.017944147467422],
            [110.443557761449966, -7.017636659552353],
            [110.443603756918563, -7.017501030961243],
            [110.443668217851055, -7.01730210545979],
            [110.443695855162446, -7.017139318470353],
            [110.443696188077254, -7.016858907079903],
            [110.443687349175164, -7.016677985774532],
            [110.443669130716117, -7.016533235519161],
            [110.443669549525794, -7.016180459890895],
            [110.44365136329516, -7.016008573046649],
            [110.443615059009502, -7.015845710272282],
            [110.443621082893941, -7.015755603650734],
            [110.443785776111056, -7.015140439097342],
            [110.443556523932571, -7.014995113839905],
            [110.443572268504354, -7.01495015091445],
            [110.443875951772014, -7.014081652020572],
            [110.443919839473793, -7.013956137969156],
            [110.444011729926913, -7.013693341011846],
            [110.444199648713393, -7.013168921920816],
            [110.444604178158329, -7.012020616314071],
            [110.444757192864117, -7.01159565658914],
            [110.44482071564201, -7.011468844510691],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 120,
        id: 124,
        area: 773379.37612000003,
        perimeter: 3663.701178,
        kode_pos: 50241,
        kode_kec: 3374050,
        kode_kel: 3374030039,
        kec: "Semarang Selatan",
        kel: "Pleburan",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.419356439565718, -6.997475916787626],
            [110.419499034445423, -6.997019944904822],
            [110.419875984223808, -6.99601635768662],
            [110.42009405835104, -6.99560053273545],
            [110.420354636000312, -6.995130486886817],
            [110.420605843524029, -6.99468756585707],
            [110.42135574072492, -6.993394979172287],
            [110.421913463720557, -6.992391611651044],
            [110.42248211152436, -6.991364914638699],
            [110.422953340325805, -6.991592667526241],
            [110.423044693539666, -6.991598438290419],
            [110.423134161723794, -6.991555647107164],
            [110.423195731251596, -6.99149985968005],
            [110.42370326273489, -6.990490166326604],
            [110.426960120947456, -6.992083538508424],
            [110.428741820469838, -6.992960868293419],
            [110.428844656357086, -6.993009835730103],
            [110.428855846128712, -6.993015132706847],
            [110.428640316258011, -6.993468974309347],
            [110.428490898096683, -6.993783603745721],
            [110.428188597801707, -6.994371196023762],
            [110.427764989455284, -6.995139551136235],
            [110.427683323080103, -6.995293225810494],
            [110.427593103137667, -6.995501163217567],
            [110.427772654356374, -6.99558279120065],
            [110.428075196193063, -6.995727887219556],
            [110.428366737580859, -6.995845833123187],
            [110.428321696065282, -6.996126189742563],
            [110.428203354386355, -6.996659732045093],
            [110.428101935554125, -6.99700333881302],
            [110.428022220572672, -6.997645474243388],
            [110.427976477200531, -6.998269559866608],
            [110.427970036930617, -6.998911784283638],
            [110.427974869166221, -6.999590204481947],
            [110.427999343765663, -7.000169147777518],
            [110.427337840369972, -7.00020452523041],
            [110.42660896964648, -7.000266956218693],
            [110.426210773230011, -7.000375017043244],
            [110.425448024899467, -7.000609269762347],
            [110.42520117748154, -7.000726560007481],
            [110.424797167004741, -7.000961249735953],
            [110.424488521400065, -7.001177964717118],
            [110.424370627587379, -7.001349670190689],
            [110.424287067004315, -7.001470877074989],
            [110.424207655058794, -7.001379210122598],
            [110.424045197250251, -7.001191695493509],
            [110.423790988210442, -7.000893043173946],
            [110.423550126521363, -7.000636480827296],
            [110.423125483779145, -7.000208425081141],
            [110.423054200261262, -7.000140519082302],
            [110.422830746422505, -6.999927652630428],
            [110.422739772427946, -6.999845652069131],
            [110.422489078701645, -6.99961968587787],
            [110.422364501781104, -6.999465759204764],
            [110.422090529480542, -6.999094556691571],
            [110.421847051748003, -6.998697087372117],
            [110.421713275135758, -6.998526715667193],
            [110.421567985848867, -6.998379279085675],
            [110.421373808244397, -6.998252443143601],
            [110.421096740776022, -6.998098328687927],
            [110.420890030152137, -6.99801666476609],
            [110.420104498584891, -6.997738722922102],
            [110.419356439565718, -6.997475916787626],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 121,
        id: 125,
        area: 281645.334279,
        perimeter: 2618.214273,
        kode_pos: 50124,
        kode_kec: 3374110,
        kode_kel: 3374050021,
        kec: "Semarang Timur",
        kel: "Karangturi",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.431601042934375, -6.985492762232728],
            [110.431607870849732, -6.985492621754136],
            [110.43215475402171, -6.985475189646353],
            [110.433296386323249, -6.985395153363307],
            [110.4345540107864, -6.985315253401907],
            [110.434726201606182, -6.98531556209926],
            [110.434631127223682, -6.986590766955582],
            [110.434524494117724, -6.98798365204858],
            [110.434471242549606, -6.988743413382687],
            [110.434391712649301, -6.989476006518363],
            [110.434285240692247, -6.990733208416325],
            [110.434205046119246, -6.99178239439835],
            [110.434099051520377, -6.99287677697548],
            [110.43396660395409, -6.99385353568313],
            [110.433867157913738, -6.995013382832085],
            [110.432233579381887, -6.994347788509185],
            [110.431983752885074, -6.994274866447524],
            [110.431980248867518, -6.994273934778962],
            [110.431966164405239, -6.992698343644469],
            [110.431918686425846, -6.990886944183869],
            [110.431859638714315, -6.989841186140612],
            [110.431601042934375, -6.985492762232728],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 122,
        id: 126,
        area: 724646.92544499994,
        perimeter: 3740.209478,
        kode_pos: 50163,
        kode_kec: 3374100,
        kode_kel: 3374042005,
        kec: "Gayamsari",
        kel: "Sawah Besar",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.448912440973345, -6.977697123835924],
            [110.447325142269847, -6.976583339114691],
            [110.44547312318609, -6.975251470004419],
            [110.444194381033313, -6.974309228429079],
            [110.443439123715081, -6.973512329849872],
            [110.442868086161937, -6.972914649816639],
            [110.442811808893552, -6.972855429154834],
            [110.442847681924704, -6.972601892626271],
            [110.442923583580551, -6.972082908725135],
            [110.443014600437451, -6.971515044385415],
            [110.443067697549424, -6.971180342217886],
            [110.443135954343191, -6.970759145655877],
            [110.443185331232229, -6.970386825045611],
            [110.443291444848512, -6.969785125731017],
            [110.443355830237579, -6.969454198177085],
            [110.443424064220082, -6.969051808488858],
            [110.443496115687793, -6.968604286439231],
            [110.443564340501055, -6.968209419492019],
            [110.443632764727241, -6.967645289628488],
            [110.443731414622491, -6.966987160224196],
            [110.443791521583776, -6.966577893284164],
            [110.443791523651427, -6.966577879205641],
            [110.444501876692129, -6.966692472475528],
            [110.444762589805251, -6.966738762560153],
            [110.445051636574235, -6.96680242090209],
            [110.445312938211202, -6.966884137644154],
            [110.445560377964554, -6.966965837990489],
            [110.446152846869595, -6.967138398228237],
            [110.446306973097293, -6.967183806606333],
            [110.446511695638932, -6.967265456440717],
            [110.446765600081918, -6.967383346015612],
            [110.446999408300584, -6.967510257518023],
            [110.447129173725145, -6.967582773838078],
            [110.44717811509426, -6.967582831080153],
            [110.447350672338061, -6.96759207842922],
            [110.447547894674628, -6.96755612671458],
            [110.447705500670736, -6.967529174194315],
            [110.447786430691295, -6.96751117761568],
            [110.447907228170479, -6.967511318722448],
            [110.448046088812305, -6.967547663157987],
            [110.448205003517074, -6.967611167666299],
            [110.448379173624588, -6.967692781062328],
            [110.448454120507648, -6.967710959656181],
            [110.448531896379777, -6.967729141539211],
            [110.448720034279873, -6.967720315437078],
            [110.449042810625571, -6.967729737353435],
            [110.449083303797835, -6.96773146317168],
            [110.449266184193874, -6.967739257487492],
            [110.449257754712491, -6.96789686335989],
            [110.449244187988057, -6.968051118094367],
            [110.449243871591619, -6.968322205844458],
            [110.449385514100896, -6.969306848781793],
            [110.449658478042835, -6.970944396289018],
            [110.449817875135295, -6.972011099620694],
            [110.45013320509878, -6.974055328398994],
            [110.450370507999793, -6.975664298460999],
            [110.450502146954577, -6.976442519330825],
            [110.450502148628871, -6.976442529228829],
            [110.450636171326096, -6.977444514949545],
            [110.450786365403289, -6.978439078021197],
            [110.45089235968625, -6.979142194745624],
            [110.450892370944359, -6.979142267809277],
            [110.450019950474143, -6.978505493191115],
            [110.448912440973345, -6.977697123835924],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 123,
        id: 127,
        area: 352347.59311100002,
        perimeter: 2565.011394,
        kode_pos: 50249,
        kode_kec: 3374050,
        kode_kel: 3374030042,
        kec: "Semarang Selatan",
        kel: "Lamper Lor",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.436962098550026, -7.005887538927817],
            [110.437062686250101, -7.005525990854436],
            [110.437120172459586, -7.00524564806478],
            [110.437117233012074, -7.004865732030183],
            [110.437121018540026, -7.004540097257593],
            [110.437075402477419, -7.004359131926928],
            [110.437018121652926, -7.004232425846128],
            [110.436670923605263, -7.003806869873818],
            [110.43655664250862, -7.002612722223812],
            [110.436480220567788, -7.002169399401351],
            [110.436410970710398, -7.002115043219146],
            [110.436287955536969, -7.002069668135825],
            [110.436049520606872, -7.002015109126515],
            [110.43581085716211, -7.001915322050042],
            [110.435741672638997, -7.001806692644779],
            [110.435741792196254, -7.001707191900391],
            [110.43647512524592, -7.001228657512715],
            [110.436834136183194, -7.001002948984237],
            [110.437313233875315, -7.000668837333023],
            [110.43748830438868, -7.000479090373071],
            [110.437663374759651, -7.000289343335163],
            [110.437857029461554, -6.999936798718596],
            [110.437995493423486, -6.999602279160391],
            [110.438429273590856, -6.998598742062247],
            [110.438503076508113, -6.998467305243418],
            [110.438914858828653, -6.998883744516015],
            [110.439200756967978, -6.99913994192138],
            [110.439631534815859, -6.999484732768147],
            [110.439992781996082, -6.999714681285954],
            [110.440438714008565, -6.999984237546466],
            [110.440698577598667, -7.000236886984338],
            [110.440704927974437, -7.000411274502704],
            [110.440712814922009, -7.000624362530541],
            [110.440737300162127, -7.000985347838164],
            [110.440831271601638, -7.00149458203422],
            [110.440902366385174, -7.002013994315172],
            [110.440958107780602, -7.002912227613101],
            [110.440994244522273, -7.003211169867163],
            [110.44108362990842, -7.003583097216893],
            [110.441149496619104, -7.00394858589806],
            [110.441139696235126, -7.004237342084289],
            [110.441138064302407, -7.004667006646116],
            [110.441196579492242, -7.004974011181064],
            [110.441257654772258, -7.00528101875299],
            [110.441282917090732, -7.005564963638409],
            [110.441308109394626, -7.005907737643803],
            [110.44135889292474, -7.006258215430901],
            [110.441452047086784, -7.006681906962671],
            [110.441160488918058, -7.006733936393863],
            [110.441090596575322, -7.006742898842484],
            [110.440970899299501, -7.006760847596332],
            [110.440914576398825, -7.006778871695975],
            [110.440793192361753, -7.006787772856204],
            [110.44058862944668, -7.006796575007431],
            [110.44028903732881, -7.006778127310478],
            [110.439861433065701, -7.006632889352816],
            [110.439518912722562, -7.006551071248719],
            [110.439275393279658, -7.006487462076511],
            [110.437960211171799, -7.006151206670723],
            [110.436962098550026, -7.005887538927817],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 124,
        id: 128,
        area: 1728770.8830210001,
        perimeter: 7860.683349,
        kode_pos: 50113,
        kode_kec: 3374090,
        kode_kel: 3374040017,
        kec: "Genuk",
        kel: "Penggaron Lor",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.494793645793436, -7.001430516876601],
            [110.493833017405819, -7.000647307098177],
            [110.492742012210385, -6.999689844961036],
            [110.491048483839165, -6.998159846411323],
            [110.491032136094475, -6.998143519382418],
            [110.491041474276443, -6.998133934856073],
            [110.491074644052915, -6.998101810126562],
            [110.491124457560659, -6.99805261229069],
            [110.491220191728928, -6.997953215747362],
            [110.49123077724326, -6.997844679787614],
            [110.491222953476822, -6.997754215161786],
            [110.491207286360037, -6.997591377122064],
            [110.491220467096625, -6.997437615880728],
            [110.491294348942787, -6.997139190366438],
            [110.49151842174912, -6.996614786643192],
            [110.491823486603209, -6.996018105249513],
            [110.491913591839534, -6.99562924090121],
            [110.491939981341375, -6.995556904428958],
            [110.492159896278991, -6.995213408157916],
            [110.492838965223157, -6.994101528789137],
            [110.492969389549089, -6.99383934623041],
            [110.493327472765131, -6.993134173149334],
            [110.49349837256166, -6.992591619654434],
            [110.493605606757697, -6.992320366288378],
            [110.493628864237209, -6.992266117557246],
            [110.493654931381272, -6.992229963089321],
            [110.493701934231581, -6.992193831122385],
            [110.493856259214169, -6.992067358253226],
            [110.493945835546455, -6.991904633282313],
            [110.494002808489796, -6.991805192630745],
            [110.494122948486407, -6.991371131747861],
            [110.494042366502455, -6.991325817095179],
            [110.494028259703398, -6.991289619451881],
            [110.494019235634966, -6.9912624728904],
            [110.494006035972959, -6.991172002481721],
            [110.494006113826103, -6.991099637579607],
            [110.494006211141354, -6.991009181451651],
            [110.494008249929792, -6.990954909901649],
            [110.494016200722982, -6.99092778157076],
            [110.494032941318196, -6.990882571432682],
            [110.49406613958395, -6.990792150849978],
            [110.494146213230906, -6.990520868122179],
            [110.49418172339287, -6.990385221890039],
            [110.494217487266525, -6.990276712796853],
            [110.494275084110939, -6.990122999013495],
            [110.494326232242472, -6.989915004554284],
            [110.494437620464581, -6.989462842860922],
            [110.494470867135732, -6.989327194160997],
            [110.494471224098561, -6.989064871430918],
            [110.494462856016767, -6.988883949960995],
            [110.494531875997112, -6.988630746499365],
            [110.494542997059824, -6.988549347802689],
            [110.494558313223209, -6.988513181729125],
            [110.494594028256003, -6.988449900655453],
            [110.494689847072493, -6.988269090895117],
            [110.494736332175137, -6.988187730103609],
            [110.494889444863546, -6.987871297324316],
            [110.494922915917968, -6.987789922561189],
            [110.494929471449183, -6.987744701457124],
            [110.494927305241148, -6.987654242878149],
            [110.494929655920217, -6.987572834764698],
            [110.49491869030463, -6.987509503633833],
            [110.494905461393785, -6.987446170077501],
            [110.494878955033371, -6.987364731041186],
            [110.494863501672683, -6.987265212596853],
            [110.494854935302641, -6.98707524527341],
            [110.494919350486924, -6.98689440177637],
            [110.494986019204873, -6.98672260630605],
            [110.495059175428807, -6.986568909023207],
            [110.495114849305381, -6.986360919242866],
            [110.495177419723731, -6.986053434941503],
            [110.495192852163555, -6.98590872143728],
            [110.495204479049221, -6.985619273825864],
            [110.495171737394955, -6.985284550572225],
            [110.495147688393942, -6.985022201638808],
            [110.495147756301407, -6.984958882321767],
            [110.495167666757965, -6.984859401740165],
            [110.495219009379468, -6.98473281792374],
            [110.495245670865998, -6.984669527072354],
            [110.495423073420966, -6.984389302477298],
            [110.495585285827573, -6.984027650897419],
            [110.49565619747915, -6.983855859803891],
            [110.495709520214277, -6.983729278029249],
            [110.495789475199274, -6.983566542205357],
            [110.495904598101333, -6.983322433294136],
            [110.495995840282376, -6.983186846373266],
            [110.496081141307457, -6.983051253082723],
            [110.496152306483836, -6.982906599043039],
            [110.496199726576151, -6.98274382836806],
            [110.496243722689982, -6.982608190916622],
            [110.496294566651343, -6.982418286993402],
            [110.496352132667013, -6.982291709645972],
            [110.496423072757722, -6.982092781526962],
            [110.496524923161218, -6.981875740452557],
            [110.496639078984344, -6.981623673494195],
            [110.496713171323179, -6.981417695853713],
            [110.496759568132163, -6.981205537707266],
            [110.496809105320352, -6.98093494891134],
            [110.496864114593663, -6.980781635686618],
            [110.496935395071702, -6.980528434022346],
            [110.496982785622492, -6.980392800078941],
            [110.497080859322068, -6.980101785403662],
            [110.497121145767764, -6.979846564048744],
            [110.497199805953173, -6.979569878901075],
            [110.49720338426765, -6.97939801570611],
            [110.49722022255078, -6.979302310817353],
            [110.497281870980245, -6.979219338709168],
            [110.497294278107574, -6.979130163192223],
            [110.49724114312221, -6.979009093753923],
            [110.497227621491163, -6.978954805555312],
            [110.497217514054384, -6.978882429729373],
            [110.497180907769376, -6.978647193060592],
            [110.497187345988209, -6.978382709188571],
            [110.497208519624763, -6.978032130703157],
            [110.497215473459946, -6.977878362356937],
            [110.497228837625769, -6.977815057164165],
            [110.497245879571665, -6.977751755887977],
            [110.497266284383215, -6.977453271739805],
            [110.497276748844129, -6.977190959530757],
            [110.497287232580319, -6.976910556072158],
            [110.49730404946051, -6.976792980750149],
            [110.497352385094899, -6.976596034691736],
            [110.497399189492953, -6.976449347986422],
            [110.497422834636964, -6.97629559739022],
            [110.497439709318826, -6.97612374831654],
            [110.497454620606447, -6.975974897781248],
            [110.497460910980422, -6.975848810039542],
            [110.49746394593177, -6.975680538064511],
            [110.497455135386062, -6.975492048895083],
            [110.497427633699161, -6.975304415700387],
            [110.497437120055309, -6.97506761428681],
            [110.497446635370693, -6.974701722610945],
            [110.497564843067508, -6.974712762597392],
            [110.497730323027852, -6.974731029910554],
            [110.497879677436856, -6.974749280019724],
            [110.498511609604591, -6.974822316648539],
            [110.498891190907415, -6.974895084709424],
            [110.499137807047674, -6.974967711452844],
            [110.49966636430446, -6.97514918454893],
            [110.500136672318888, -6.975303458505331],
            [110.500525201885168, -6.975475736787035],
            [110.5014814039762, -6.976028531334717],
            [110.501744041633742, -6.976200675733074],
            [110.502071445819041, -6.976390979591692],
            [110.502797188111714, -6.976844026518375],
            [110.502969938852672, -6.976943710484321],
            [110.50311778437738, -6.977052413818028],
            [110.503768946455835, -6.977614554799028],
            [110.504013856102517, -6.977909546617837],
            [110.504138052497382, -6.978090778977168],
            [110.504408773559831, -6.978579949664777],
            [110.504460655042976, -6.978673538175388],
            [110.504232389548434, -6.979002047194195],
            [110.504226255450192, -6.979011061884187],
            [110.504180080103851, -6.979051125598162],
            [110.504130901854481, -6.97909379474676],
            [110.504040299600575, -6.979172405094752],
            [110.504011015146858, -6.979278462805565],
            [110.503849618865573, -6.97986298080781],
            [110.503756362644168, -6.980326342761572],
            [110.503583281272753, -6.981083145353105],
            [110.503537664641854, -6.981271289163502],
            [110.503466012591844, -6.981532711909005],
            [110.50327279455702, -6.982276779675097],
            [110.502975763745553, -6.983276216035827],
            [110.502793926169318, -6.983955765539842],
            [110.502714274859542, -6.98408368475852],
            [110.502262918146911, -6.98480708870671],
            [110.501871759410903, -6.985372120012739],
            [110.502085438025802, -6.985541816166351],
            [110.502769859264475, -6.98582944216138],
            [110.502981809850198, -6.985917943711779],
            [110.502752028497113, -6.986658496823712],
            [110.502253602963094, -6.987933405472971],
            [110.502059319482768, -6.988394527657869],
            [110.501823562269266, -6.988747058271422],
            [110.501563263292638, -6.989298566732172],
            [110.501007002516388, -6.989080882839032],
            [110.500265083917668, -6.988926320915937],
            [110.499968461666157, -6.988781276166974],
            [110.499671724176324, -6.988744778702712],
            [110.499448994509137, -6.988816907242845],
            [110.499300516939172, -6.989033844667222],
            [110.498780924222629, -6.989187068045604],
            [110.498073349187877, -6.98936515826831],
            [110.497944128777092, -6.989734755659148],
            [110.498398494984173, -6.989929302999725],
            [110.49798011368074, -6.990866142543831],
            [110.497337191140588, -6.991164810176077],
            [110.497233419711165, -6.991594116343801],
            [110.496978389926994, -6.992417581267422],
            [110.496147441961284, -6.992116849967121],
            [110.496131459072927, -6.992111065526024],
            [110.49600158941314, -6.992874719150386],
            [110.495900268545739, -6.993173116348625],
            [110.495755740596977, -6.993661425372214],
            [110.49564029854136, -6.993998717276347],
            [110.495625757500036, -6.994041202407034],
            [110.495589214240155, -6.994348714489993],
            [110.495603477981803, -6.994502505378669],
            [110.495783666674029, -6.994529835224302],
            [110.495989721943587, -6.99456196548411],
            [110.496153268637244, -6.994595176834241],
            [110.496313325571833, -6.994637078167615],
            [110.496464670185361, -6.994689402657898],
            [110.49660904085809, -6.99475389092667],
            [110.496698318478067, -6.994811228100031],
            [110.496781212542032, -6.994858400215368],
            [110.496878098602664, -6.994956150236514],
            [110.496993272066987, -6.995091619883709],
            [110.497125389529828, -6.995200646302553],
            [110.497175844471442, -6.995234392749722],
            [110.497297573727195, -6.995316244189385],
            [110.497416195713029, -6.99536097680031],
            [110.497421712423943, -6.995363783958378],
            [110.497595020663738, -6.995441751994558],
            [110.497748085503574, -6.995513204234514],
            [110.497850687165339, -6.995581125100934],
            [110.497949590120669, -6.995671898436882],
            [110.497987433406735, -6.99573525817699],
            [110.498024916498025, -6.99587098254562],
            [110.498024839209975, -6.995943347478048],
            [110.49801540632113, -6.996033793688604],
            [110.497985840829173, -6.996128976924728],
            [110.497977050472002, -6.996212427770733],
            [110.497966486436042, -6.996327174306697],
            [110.497941952157177, -6.996487113561048],
            [110.497900054436073, -6.996610520447788],
            [110.497849423888056, -6.996763476839801],
            [110.497812754278385, -6.996879934272906],
            [110.49773426302589, -6.997048509713474],
            [110.497593129264786, -6.997245457131347],
            [110.497522293312528, -6.997344883403285],
            [110.497446657392217, -6.997435258911819],
            [110.497418317185719, -6.997480456780406],
            [110.497309678821537, -6.997742663903392],
            [110.49725750567633, -6.99785115568077],
            [110.497191440453065, -6.997986769484529],
            [110.497120575252737, -6.998113332522217],
            [110.497035535023059, -6.998267017271724],
            [110.496974025427164, -6.998375499028996],
            [110.496931529483746, -6.998429727353647],
            [110.496840303662339, -6.998547222948492],
            [110.496712815707554, -6.998709907864425],
            [110.49655703574787, -6.998872562473462],
            [110.496460096349466, -6.998958039154477],
            [110.496350341204433, -6.999069201957323],
            [110.496285851210317, -6.999163025644817],
            [110.496226615510068, -6.999225557376151],
            [110.496153474556621, -6.999272425425923],
            [110.49607050521584, -6.999315277273978],
            [110.495943113842017, -6.999387505819159],
            [110.49585817976687, -6.999441688569888],
            [110.495790116750229, -6.999526018956964],
            [110.495739159096885, -6.999635371975453],
            [110.49568821819831, -6.99972909534412],
            [110.495579243073038, -6.99994794448925],
            [110.495513167328724, -7.000092603601129],
            [110.495442291619284, -7.000228211931087],
            [110.495416751088271, -7.000300549515557],
            [110.495360060192752, -7.000399990553603],
            [110.495307915432861, -7.00048134521636],
            [110.495227703234946, -7.000616943488258],
            [110.495156394423248, -7.000811892459126],
            [110.495001641564997, -7.001216070986305],
            [110.494817649299037, -7.001405780783797],
            [110.494793645793436, -7.001430516876601],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 125,
        id: 129,
        area: 595425.83553299995,
        perimeter: 3761.59535,
        kode_pos: 50242,
        kode_kec: 3374050,
        kode_kel: 3374030041,
        kec: "Semarang Selatan",
        kel: "Peterongan",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.436962098550026, -7.005887538927817],
            [110.435798249849569, -7.005578748435499],
            [110.435172332146593, -7.005424222852087],
            [110.434257368679795, -7.005187938980953],
            [110.433365877698108, -7.00496072721665],
            [110.432246332547948, -7.004751329583585],
            [110.432128953701493, -7.0047240512571],
            [110.432055977300635, -7.004687991488091],
            [110.432390222742214, -7.00414545284653],
            [110.43270615037153, -7.003286508730614],
            [110.433079651132928, -7.002310041771321],
            [110.433223801280263, -7.001487072130245],
            [110.433252965439195, -7.00099864855645],
            [110.432967275423422, -7.000482709022522],
            [110.43245307910226, -6.999423761928637],
            [110.432196173074516, -6.998735991662107],
            [110.432054362451794, -6.997623220231931],
            [110.431970991084413, -6.995443146536858],
            [110.431983752885074, -6.994274866447524],
            [110.432233579381887, -6.994347788509185],
            [110.433867157913738, -6.995013382832085],
            [110.4338671629815, -6.995013384896911],
            [110.433922215857507, -6.995036040974103],
            [110.43442515319397, -6.995227465760869],
            [110.437024148668286, -6.996327797848991],
            [110.438823338080425, -6.997082035757294],
            [110.438626581744003, -6.997487477156201],
            [110.438543318019086, -6.997814723806316],
            [110.438510919549074, -6.998140149814325],
            [110.438503076508113, -6.998467305243418],
            [110.438429273590856, -6.998598742062247],
            [110.437995493423486, -6.999602279160391],
            [110.437857029461554, -6.999936798718596],
            [110.437663374759651, -7.000289343335163],
            [110.43748830438868, -7.000479090373071],
            [110.437313233875315, -7.000668837333023],
            [110.436834136183194, -7.001002948984237],
            [110.43647512524592, -7.001228657512715],
            [110.435741792196254, -7.001707191900391],
            [110.435741672638997, -7.001806692644779],
            [110.43581085716211, -7.001915322050042],
            [110.436049520606872, -7.002015109126515],
            [110.436287955536969, -7.002069668135825],
            [110.436410970710398, -7.002115043219146],
            [110.436480220567788, -7.002169399401351],
            [110.43655664250862, -7.002612722223812],
            [110.436670923605263, -7.003806869873818],
            [110.437018121652926, -7.004232425846128],
            [110.437075402477419, -7.004359131926928],
            [110.437121018540026, -7.004540097257593],
            [110.437117233012074, -7.004865732030183],
            [110.437120172459586, -7.00524564806478],
            [110.437062686250101, -7.005525990854436],
            [110.436962098550026, -7.005887538927817],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 126,
        id: 130,
        area: 6405830.5785360001,
        perimeter: 12780.916715,
        kode_pos: 50265,
        kode_kec: 3374030,
        kode_kel: 3374031001,
        kec: "Banyumanik",
        kel: "Pudak Payung",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.422504705355962, -7.098143121232227],
            [110.422406640847896, -7.098296002157497],
            [110.422338782614602, -7.09842439472112],
            [110.42225479512183, -7.098549993200321],
            [110.422169155758638, -7.098694614227371],
            [110.422132471239152, -7.098839296189764],
            [110.422120167811883, -7.098947826596743],
            [110.422131414145724, -7.099078003979343],
            [110.422107236257546, -7.099398627049879],
            [110.422038853026635, -7.100210071504737],
            [110.42187942486936, -7.100938270171967],
            [110.421942501259565, -7.101203580372616],
            [110.421938843742694, -7.102243286176571],
            [110.421930813028141, -7.102892376063579],
            [110.421915877437172, -7.103289029597822],
            [110.421886438560904, -7.103736150589754],
            [110.421683726428569, -7.104175843309552],
            [110.421502719258399, -7.104579501878785],
            [110.421300240863815, -7.104831676940276],
            [110.420967863553784, -7.105033204174844],
            [110.420700638483694, -7.105097780389069],
            [110.420382890690348, -7.105147868857873],
            [110.420036470349459, -7.10503204023107],
            [110.41977660095634, -7.104988441837899],
            [110.419458871288342, -7.105024105259896],
            [110.419126502192753, -7.105218418891324],
            [110.418876668300499, -7.105362101016346],
            [110.418633527919908, -7.105534535756034],
            [110.418456249412216, -7.105719391498154],
            [110.418237699008174, -7.105982339437455],
            [110.418039864454713, -7.106142492126428],
            [110.417727582634853, -7.106348632471324],
            [110.41747749427465, -7.106492842554467],
            [110.417273432901339, -7.106643679626966],
            [110.417095677355391, -7.106794549673924],
            [110.416898076288049, -7.107037364637438],
            [110.416818802239618, -7.107319744064008],
            [110.416713222080048, -7.1076020903657],
            [110.416646935637772, -7.107815213265511],
            [110.416575606501624, -7.107985180656278],
            [110.416480640314646, -7.108101302718376],
            [110.416293794047064, -7.108350784118914],
            [110.41609266288603, -7.108624865568016],
            [110.415988590279241, -7.108797634513212],
            [110.415862338819863, -7.108988725934441],
            [110.415810631253692, -7.108997513243856],
            [110.415750070768908, -7.108998912318055],
            [110.415704325419853, -7.108964920130269],
            [110.415648294868504, -7.108888127969902],
            [110.415623321304082, -7.108780391273223],
            [110.415617897654414, -7.108696138759107],
            [110.415619314800381, -7.108588630238017],
            [110.415637323063748, -7.108471415466128],
            [110.415665488795355, -7.108392443110198],
            [110.415808611200873, -7.10820147545409],
            [110.415363417955845, -7.10817415341217],
            [110.41475369697973, -7.10811476523445],
            [110.414474357461827, -7.108080005807823],
            [110.414196301791193, -7.108038876238174],
            [110.414151492695908, -7.108032929168659],
            [110.414002424416992, -7.108013144773477],
            [110.41342712096808, -7.107975461829776],
            [110.413110719492551, -7.107992901809179],
            [110.412837167516969, -7.108036108402139],
            [110.412758549280142, -7.108048525850263],
            [110.412551778860063, -7.108129820308619],
            [110.412434282980499, -7.108231616960004],
            [110.412351974886946, -7.108286607669288],
            [110.41211635779041, -7.10844402521484],
            [110.411786965401618, -7.108644949797228],
            [110.411620456869812, -7.108744457649934],
            [110.411474174977954, -7.108831877688292],
            [110.411236712029535, -7.108971751227733],
            [110.411091136755786, -7.109083706377032],
            [110.410856302948318, -7.109162415714562],
            [110.410532148897346, -7.109256303157515],
            [110.410530312709724, -7.109259251331547],
            [110.410529597449298, -7.109256299912265],
            [110.410345834695747, -7.109301941633524],
            [110.410213123633682, -7.10932980778329],
            [110.410136378618361, -7.109487725681498],
            [110.410008711859703, -7.109561473675034],
            [110.409873465522992, -7.10957659330124],
            [110.409697451299024, -7.109548334112879],
            [110.409585236344824, -7.109509961592756],
            [110.409460290206169, -7.109451183720461],
            [110.409325177374882, -7.109361809204254],
            [110.409235993909064, -7.109269944564138],
            [110.409172318435836, -7.10918320970446],
            [110.409090737266496, -7.109132132937682],
            [110.409004066251825, -7.109070855116258],
            [110.408927561953789, -7.109040173899579],
            [110.408825491077764, -7.109050238263437],
            [110.408718281460764, -7.109088331062734],
            [110.408539624808526, -7.109131429935756],
            [110.408391699607279, -7.109085365654851],
            [110.408236172283551, -7.1089985134232],
            [110.408001644841775, -7.108837649758446],
            [110.407891655351719, -7.109054143425679],
            [110.407981472533791, -7.109169459245289],
            [110.40783373281883, -7.109187361398909],
            [110.407769744156184, -7.109214415985972],
            [110.407641300264913, -7.109298492676103],
            [110.407554443315504, -7.109382486674851],
            [110.407452179670486, -7.109542920066993],
            [110.407362738526757, -7.109652397088051],
            [110.407247815518275, -7.109736355125503],
            [110.407069099693587, -7.10982532878627],
            [110.406949152144946, -7.109848113029495],
            [110.406857286880808, -7.109858190002035],
            [110.406767989413098, -7.109855527059711],
            [110.406637858943427, -7.109860457728153],
            [110.406505160667962, -7.109878128254102],
            [110.406362240226315, -7.10990852882893],
            [110.406262678053025, -7.10995172809548],
            [110.406140136444861, -7.110007641072801],
            [110.406037960576796, -7.110099261028506],
            [110.405961322100524, -7.110173073242277],
            [110.405897437581842, -7.11024945042939],
            [110.405838678871561, -7.11030799372466],
            [110.40578508200926, -7.110320668178782],
            [110.405726428102241, -7.11029765523436],
            [110.405670364966028, -7.110244061971933],
            [110.405585895782522, -7.110152346638166],
            [110.405490340784723, -7.110079860481633],
            [110.405348706238996, -7.109971133392925],
            [110.40523104379983, -7.109925755158115],
            [110.405149827903585, -7.109925650912048],
            [110.405072290777284, -7.109925551374649],
            [110.404966738413719, -7.109925415852159],
            [110.404838229565058, -7.109952387159555],
            [110.404733173293479, -7.110006524893407],
            [110.404610353310531, -7.110088258781518],
            [110.404496021388013, -7.110222670457129],
            [110.404434240582262, -7.110327501141897],
            [110.404393020065044, -7.110423235627952],
            [110.404379012520508, -7.110662686288912],
            [110.404399381873645, -7.110801832363381],
            [110.404415363228523, -7.110874410999688],
            [110.404443113988236, -7.110953939220199],
            [110.404471860792597, -7.111032475062929],
            [110.404511557604749, -7.111105063036634],
            [110.404543295097113, -7.1111786344355],
            [110.404563133914195, -7.111222380834732],
            [110.404565050477018, -7.111279021740297],
            [110.404546100184717, -7.111317750004138],
            [110.404495276710009, -7.11138823432056],
            [110.404435499125753, -7.11145970077663],
            [110.404362821687059, -7.111506309217906],
            [110.404229396171473, -7.11160550334386],
            [110.404171657982261, -7.111638219766924],
            [110.404104977215397, -7.111662975425729],
            [110.404032333033939, -7.111679031231501],
            [110.403954783966228, -7.111687976911245],
            [110.403884100066833, -7.111694488292985],
            [110.403812974371036, -7.111714930774997],
            [110.403669138885974, -7.111766748592337],
            [110.403586541957324, -7.111791483685413],
            [110.403505956333774, -7.111799329189165],
            [110.403375617438243, -7.111819034488041],
            [110.403285079102261, -7.111830841761788],
            [110.403159721679543, -7.111844591474913],
            [110.403068211675674, -7.111838511634865],
            [110.402999588800057, -7.1118264993311],
            [110.402959840848638, -7.111793657441764],
            [110.402932040259486, -7.111752881694331],
            [110.402915188422895, -7.111707151773511],
            [110.402915264146657, -7.11164852613303],
            [110.402922286484682, -7.111602826980537],
            [110.402961250127262, -7.111472708180206],
            [110.403018160424253, -7.111310815487293],
            [110.403085002842658, -7.111160859458475],
            [110.403137892975423, -7.11103075857541],
            [110.403190776662257, -7.110905625958126],
            [110.403240864705367, -7.110773468167544],
            [110.403277757521792, -7.11069210669551],
            [110.403301609779064, -7.110628819312082],
            [110.403345069535035, -7.110502239073673],
            [110.4033916537431, -7.110366617408203],
            [110.40341137794401, -7.110212870268052],
            [110.403421953329115, -7.110131474893517],
            [110.403410739079831, -7.110050051466038],
            [110.403399513162071, -7.109977673466651],
            [110.403375836034044, -7.109905279436546],
            [110.403354411097226, -7.109841933748674],
            [110.40331886040903, -7.109760478985703],
            [110.403247088040686, -7.109678977578875],
            [110.403195077732761, -7.109633683385661],
            [110.403111067127512, -7.109606438858302],
            [110.403038046116521, -7.10961539024453],
            [110.402959931429109, -7.109624335056721],
            [110.402860876077852, -7.109633252873023],
            [110.402733522532827, -7.109642134197437],
            [110.402592291185499, -7.109660043038597],
            [110.402435495825017, -7.109677931766747],
            [110.402309840160726, -7.109686815162374],
            [110.402062940592373, -7.109742165072673],
            [110.401971242393131, -7.109607611960258],
            [110.401851305065989, -7.10944898251052],
            [110.401714575970317, -7.109173398600739],
            [110.401615259941678, -7.1088968985128],
            [110.401518643642405, -7.108543383584628],
            [110.401452266611614, -7.108339144732249],
            [110.401362202042307, -7.108102639899499],
            [110.401280258963382, -7.107949659594328],
            [110.401136568788459, -7.107740530818116],
            [110.401001942435144, -7.107583115883799],
            [110.400835797569812, -7.10742442651824],
            [110.400484985641896, -7.107158654676081],
            [110.400349502378461, -7.107089923975588],
            [110.400211179843012, -7.107037630704885],
            [110.400038490085976, -7.106994129080862],
            [110.399792105670073, -7.106947652497691],
            [110.399598078733732, -7.106910475129431],
            [110.399359344608968, -7.106842594245699],
            [110.399034796592815, -7.106706630855724],
            [110.39875541229145, -7.106552496180472],
            [110.398445319063427, -7.106365194538969],
            [110.398063452257389, -7.106117650818815],
            [110.397849562515631, -7.105957359924938],
            [110.397714739646176, -7.105823386996564],
            [110.39762655949859, -7.105698587470778],
            [110.397551280507201, -7.105549246630353],
            [110.397506754887999, -7.105439949029095],
            [110.397485321326883, -7.105339912958648],
            [110.397483907281199, -7.105242980076541],
            [110.3975107086588, -7.105131425695171],
            [110.397603761645669, -7.104905290804544],
            [110.397632478293389, -7.104701590098212],
            [110.397645191106008, -7.104564883531022],
            [110.397738070013347, -7.104472347026754],
            [110.3979517419518, -7.104386431780842],
            [110.398305725065995, -7.104227434704175],
            [110.398378602481444, -7.104214826175751],
            [110.398475623318149, -7.104227260732416],
            [110.398524857877021, -7.104268866474868],
            [110.398760112989038, -7.104581504976389],
            [110.398870916385647, -7.104655500863614],
            [110.398958671740331, -7.104686386378566],
            [110.399126579041663, -7.104672756672964],
            [110.399229894920609, -7.104582113805864],
            [110.399284004061542, -7.104428325044016],
            [110.399276386739871, -7.104278253837991],
            [110.399209741093031, -7.10410362727357],
            [110.399095254334597, -7.103886497349681],
            [110.399003154052565, -7.103643281023797],
            [110.398978701623278, -7.103495544838334],
            [110.398963464817498, -7.103367822245],
            [110.398957481590614, -7.103230880110967],
            [110.398957922990178, -7.103228285953403],
            [110.398978056966328, -7.103109956150121],
            [110.399041119333731, -7.102874035931],
            [110.399168572700276, -7.102582272615194],
            [110.399238822168357, -7.102451180145204],
            [110.399330672993798, -7.10234114294447],
            [110.399518566778951, -7.102171155582321],
            [110.399608926005783, -7.102111631187245],
            [110.399703037533399, -7.101991743049519],
            [110.399869766618863, -7.101698088287404],
            [110.399996262642787, -7.10142836139782],
            [110.400167018614994, -7.101165694364345],
            [110.400333454496078, -7.100907331302443],
            [110.400512821941433, -7.100657604144583],
            [110.400702913586613, -7.100455296758938],
            [110.400878019322917, -7.100164622364961],
            [110.401001358816302, -7.099869571427755],
            [110.401043233518408, -7.099731382871299],
            [110.401063024413375, -7.09966099951197],
            [110.401069328707578, -7.099550229213986],
            [110.401047874627565, -7.099465579109236],
            [110.401012522464072, -7.099408605674305],
            [110.400946584211937, -7.09918234792711],
            [110.400877498698193, -7.098899743087866],
            [110.400828199395434, -7.098656184920022],
            [110.400806898382754, -7.098447139995867],
            [110.400818272084152, -7.098355959798919],
            [110.400852438114484, -7.098261884363914],
            [110.400927924262177, -7.098128390106591],
            [110.401049924166117, -7.097880834647263],
            [110.401104093420869, -7.097679349317658],
            [110.401139787631195, -7.097471685783855],
            [110.401166533076463, -7.097249524767738],
            [110.401160254389822, -7.097128606718983],
            [110.401137867500566, -7.097013739808287],
            [110.401037500353553, -7.096768418400691],
            [110.400959222511133, -7.096555992045706],
            [110.400956261398477, -7.096463672852498],
            [110.400985599608489, -7.096406782893435],
            [110.401206754816201, -7.096321894695939],
            [110.401406118384628, -7.096244120648588],
            [110.401655876390976, -7.096108760906598],
            [110.401746944128192, -7.0960477345751],
            [110.401825615611187, -7.095955520550389],
            [110.401891983015076, -7.095849443351089],
            [110.401919852330266, -7.09573716219191],
            [110.401899978172565, -7.095621742352541],
            [110.4018369415694, -7.095533961528159],
            [110.401740045557176, -7.095426135404648],
            [110.401592351523533, -7.095295164954782],
            [110.401332339475374, -7.09507173428574],
            [110.401192362764803, -7.094928464957154],
            [110.401120142633005, -7.094796053108166],
            [110.401080261535213, -7.094668298729177],
            [110.401064982468611, -7.094572886462183],
            [110.401073172560075, -7.094462415093246],
            [110.401080673166973, -7.094349811191091],
            [110.401132989714782, -7.094212037585822],
            [110.401362826398156, -7.093932164286706],
            [110.401796766164296, -7.093554918898759],
            [110.402345502594869, -7.093131125820622],
            [110.402839065376838, -7.09265207613316],
            [110.402968339389602, -7.092475161652219],
            [110.403100868670862, -7.092266305073688],
            [110.403162828286455, -7.092149230412826],
            [110.403210755621885, -7.092009280256265],
            [110.403240247729926, -7.091832380239225],
            [110.40324759059655, -7.091714213798262],
            [110.403251411569087, -7.091535446616445],
            [110.403208690544758, -7.091389034953893],
            [110.403131725543801, -7.091184494237873],
            [110.403015471413767, -7.090962794498767],
            [110.4028585404578, -7.09072911805249],
            [110.402740574644213, -7.090534706419401],
            [110.402713739662218, -7.090442356499723],
            [110.402740857064074, -7.090315457646665],
            [110.402825315331853, -7.090130530908195],
            [110.402985945187893, -7.089887204051093],
            [110.403209332005602, -7.089350742036242],
            [110.403380972122989, -7.088932501025193],
            [110.403639212871155, -7.088386882468935],
            [110.403856389278673, -7.088034826307214],
            [110.404030912420282, -7.087805820252258],
            [110.404065153653093, -7.087614839267323],
            [110.404067461088559, -7.087312580339705],
            [110.404006419127484, -7.087024709311495],
            [110.403938853061533, -7.086693512875293],
            [110.403794638537747, -7.086485323071993],
            [110.403472012239192, -7.086225964382472],
            [110.403272598772375, -7.085983743639275],
            [110.403230306206012, -7.085826624461401],
            [110.403237709489488, -7.085725325963318],
            [110.403279009326795, -7.085637723134471],
            [110.403399774979931, -7.085458203771615],
            [110.403573317654235, -7.085248793388924],
            [110.403875857228115, -7.084901760736971],
            [110.403991772838395, -7.08472428572507],
            [110.404078599489722, -7.084576307601795],
            [110.404153914070008, -7.08439754288259],
            [110.404192782155135, -7.084193534575199],
            [110.404189134075509, -7.083957166268545],
            [110.404109030517347, -7.083229812671545],
            [110.40410576626104, -7.082910817402319],
            [110.404102165139008, -7.082716565637826],
            [110.404081224454515, -7.082531907854114],
            [110.404069882847537, -7.082368417997453],
            [110.404033483434375, -7.082227974907539],
            [110.40397010099035, -7.082110576113591],
            [110.40388109296164, -7.082036676081285],
            [110.403657973189482, -7.08198235913337],
            [110.403448921884149, -7.081971982688834],
            [110.40310768160677, -7.082048419342532],
            [110.402835113046848, -7.082136222218432],
            [110.402593315734464, -7.082192088977457],
            [110.402437548632065, -7.082197774897204],
            [110.402325021559065, -7.082125357625678],
            [110.402277599892528, -7.082034842172598],
            [110.402251600539657, -7.08168164840527],
            [110.402250298503503, -7.081270483944064],
            [110.402211454347636, -7.08067667512105],
            [110.402147479476142, -7.080363073338478],
            [110.402171051757833, -7.080111497854687],
            [110.402201585689696, -7.080004283757552],
            [110.402269077664371, -7.079886638918864],
            [110.402494225527988, -7.07967602707283],
            [110.402750825865468, -7.079436404751731],
            [110.403082061855017, -7.079023876318231],
            [110.403315375297041, -7.07876977957072],
            [110.403626812386548, -7.078924937279255],
            [110.403763049688038, -7.079042702766101],
            [110.403774135963872, -7.079223626085522],
            [110.403807754809279, -7.079485987373636],
            [110.403898636853825, -7.079667012915626],
            [110.404068841691213, -7.079775776388472],
            [110.404352879288282, -7.079821367336165],
            [110.40468250892134, -7.079839880074159],
            [110.405369190134849, -7.079850261784153],
            [110.40607738265895, -7.079896189968466],
            [110.406478675141869, -7.079935293772666],
            [110.406885775322678, -7.079978375491981],
            [110.407355391709856, -7.080060382992428],
            [110.408162573031106, -7.080151864970526],
            [110.408673265818607, -7.080197741564505],
            [110.408841912509402, -7.080197955854535],
            [110.408969858405442, -7.080161936532741],
            [110.409111207196261, -7.080044525018592],
            [110.409303096122287, -7.079791495649448],
            [110.409441944054052, -7.079637898983751],
            [110.409706657799006, -7.079303552639947],
            [110.409926039522247, -7.079014375963028],
            [110.41003574176942, -7.078860742124635],
            [110.410077746751455, -7.07876129524508],
            [110.410095879389615, -7.07874322729678],
            [110.410132393160325, -7.07873422811094],
            [110.410579303194638, -7.078870476326763],
            [110.410996242321829, -7.078988595268216],
            [110.411388361133191, -7.079043364184942],
            [110.411415751290789, -7.079088626166928],
            [110.411324006344529, -7.07958601085129],
            [110.41119920790274, -7.080264263011915],
            [110.411121079114693, -7.08073452842402],
            [110.410960159738949, -7.081557462177515],
            [110.410776632786522, -7.082579367409086],
            [110.410803774540454, -7.082597492720735],
            [110.411606319291693, -7.082779417923941],
            [110.411954512892265, -7.082888403858298],
            [110.412761120136608, -7.083215059777103],
            [110.413350819055509, -7.083441940673302],
            [110.413806812486868, -7.083560106722884],
            [110.414370119028263, -7.083624134657774],
            [110.414712145834258, -7.08368788350493],
            [110.415432237242115, -7.083960153324966],
            [110.415763160467975, -7.084078160310677],
            [110.415968163539873, -7.084196008955147],
            [110.41626458786142, -7.084295881332929],
            [110.417162690466199, -7.084550281083414],
            [110.417370603315092, -7.08460481447282],
            [110.417635041592376, -7.084713691419243],
            [110.4178265312798, -7.084777124402745],
            [110.417963419320657, -7.084831693735633],
            [110.418679009948917, -7.08508586222258],
            [110.419027388369301, -7.085276252653096],
            [110.419227842761401, -7.085412185235727],
            [110.419305217494895, -7.085538918564892],
            [110.419396231333749, -7.085620441525401],
            [110.419524065013931, -7.085674874005085],
            [110.419856711135708, -7.085774789443995],
            [110.420387299621439, -7.085983497212072],
            [110.421017154135171, -7.086237555441858],
            [110.421249618881518, -7.086346390648333],
            [110.421299919599136, -7.086400726146965],
            [110.421395348661832, -7.086572709058617],
            [110.421372067159865, -7.086862135464695],
            [110.421266444092367, -7.087829870377124],
            [110.421321159620291, -7.087974666181342],
            [110.421320967768949, -7.088128439104291],
            [110.421137956292995, -7.08875234931504],
            [110.420690163014186, -7.089312611051462],
            [110.420305505030669, -7.089845814588172],
            [110.420181167982321, -7.09016225117788],
            [110.420158032592241, -7.090560223314614],
            [110.42036711486935, -7.091039894498132],
            [110.420472165418317, -7.091211889617655],
            [110.420453829088245, -7.091392776295322],
            [110.420398468914328, -7.091763571803649],
            [110.420612656367339, -7.092008066871699],
            [110.420931464664534, -7.092316010628993],
            [110.420954272422676, -7.092406493838588],
            [110.420931249965037, -7.092487874440064],
            [110.420648334468908, -7.092668431218992],
            [110.420562197766102, -7.092758778549526],
            [110.420585084617244, -7.092785943530304],
            [110.420963373695713, -7.0928225971621],
            [110.421127712977878, -7.092877074863562],
            [110.421734112024026, -7.0928868756653],
            [110.422012296699364, -7.092869130945454],
            [110.422059508016687, -7.092905371607283],
            [110.422086504218015, -7.093041087387279],
            [110.422072560598991, -7.093330525375999],
            [110.42207643395281, -7.093855167986263],
            [110.422099298388545, -7.09390042383279],
            [110.422349978897145, -7.093927872131216],
            [110.42288030469939, -7.093901395000859],
            [110.422944256670263, -7.093901474465874],
            [110.422957467708258, -7.09419999173177],
            [110.42320198240607, -7.094634428663742],
            [110.422919930758852, -7.09491226537325],
            [110.422844939227204, -7.095226811426468],
            [110.42279473163093, -7.095607628105881],
            [110.42273649297519, -7.095781435284266],
            [110.422545312500887, -7.096211756494495],
            [110.422453732365199, -7.096534561776046],
            [110.422378482058363, -7.097056106839966],
            [110.422344717308363, -7.09754458357916],
            [110.422377108767648, -7.098157342311285],
            [110.422504705355962, -7.098143121232227],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 127,
        id: 131,
        area: 3004085.018749,
        perimeter: 9131.199243,
        kode_pos: 50262,
        kode_kec: 3374030,
        kode_kel: 3374031010,
        kec: "Banyumanik",
        kel: "Tinjomoyo",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.421227526905312, -7.045352209372234],
            [110.420859937298431, -7.045225117267074],
            [110.420373487781532, -7.045052650085572],
            [110.419941363782982, -7.044880249820462],
            [110.419378304940679, -7.044653413655888],
            [110.418790019706677, -7.044462727473382],
            [110.41789253484248, -7.044226428342753],
            [110.417096717805308, -7.043917890371845],
            [110.416559590698327, -7.043772492736239],
            [110.416003914278861, -7.043753708190292],
            [110.415305612315791, -7.04375283566958],
            [110.414835033155455, -7.044014566217294],
            [110.414560273240198, -7.044032313305702],
            [110.41426098812569, -7.043977665625893],
            [110.413863578856365, -7.043877667359894],
            [110.413692772526261, -7.043805089369392],
            [110.413351125932778, -7.043687069624787],
            [110.413064166992143, -7.043505799890577],
            [110.412972254841847, -7.043695639698023],
            [110.4128498857186, -7.044030168981164],
            [110.412934958894468, -7.044328776750016],
            [110.412941632730551, -7.044871514145154],
            [110.412942153103131, -7.044907696732488],
            [110.412927031957281, -7.04500717806577],
            [110.412900106848127, -7.045043326192958],
            [110.412793877993039, -7.045142693114522],
            [110.412695899980463, -7.045205888439659],
            [110.412598261813542, -7.045223856754752],
            [110.412474627031202, -7.045214655935605],
            [110.412337762271079, -7.045151165561798],
            [110.412194718375559, -7.04505148545389],
            [110.412051471369679, -7.044888486676802],
            [110.411910239256926, -7.044698353951115],
            [110.411592984833533, -7.044308999139468],
            [110.411251464048931, -7.044091477742434],
            [110.410976763632505, -7.043837858362765],
            [110.41088541894861, -7.043801561381898],
            [110.410702083803315, -7.043792284918951],
            [110.410616680939938, -7.043755995375126],
            [110.410574048047522, -7.0436835778029],
            [110.410586385888777, -7.043547911148705],
            [110.410568063187256, -7.04349361517479],
            [110.410357182678041, -7.043339576190521],
            [110.410161998469846, -7.043303148153461],
            [110.409947922892314, -7.043438560311893],
            [110.409770538158739, -7.043646382499896],
            [110.40969131437582, -7.043646282508545],
            [110.409538685845263, -7.043519453131881],
            [110.409197201034999, -7.043274793963706],
            [110.408947060939894, -7.043066431926274],
            [110.408794272429304, -7.043066238820593],
            [110.408659863673492, -7.043075114380781],
            [110.408446139545546, -7.043156253408034],
            [110.408146546374468, -7.04334582939387],
            [110.408085713883835, -7.04334575240803],
            [110.408024690220543, -7.043273311361844],
            [110.4079518901314, -7.042893309207918],
            [110.407744509415181, -7.04265786427973],
            [110.40769557203285, -7.042648756836051],
            [110.407646906116938, -7.042648695207153],
            [110.407451313265611, -7.042711765793237],
            [110.407280439632558, -7.042693458340385],
            [110.407188961890625, -7.042539569330838],
            [110.407152259930797, -7.042476204487558],
            [110.406933672754604, -7.042376427165096],
            [110.406691521100939, -7.042339938181194],
            [110.406450172318941, -7.042339631990445],
            [110.406197869378104, -7.042275993452981],
            [110.406022422901273, -7.042293861646354],
            [110.406022250276834, -7.042429543529263],
            [110.405978221757579, -7.042565169717689],
            [110.405846515939928, -7.042673548113607],
            [110.405583097602786, -7.042673213426341],
            [110.40526510618497, -7.042645672786944],
            [110.405100969732118, -7.042627207035054],
            [110.405150892370855, -7.042456778298619],
            [110.405187694333208, -7.042279886763274],
            [110.405195573520373, -7.042139500058411],
            [110.40518224743019, -7.042022165302281],
            [110.405167233936851, -7.041718273846723],
            [110.405159734541797, -7.041560558398759],
            [110.405129097478351, -7.04143166217172],
            [110.40507396507877, -7.041307791114228],
            [110.404908882876143, -7.04111544503548],
            [110.404696581732409, -7.040909555592177],
            [110.404551688050574, -7.040761055611869],
            [110.404447295554846, -7.040602494722391],
            [110.404423875193743, -7.040447407701334],
            [110.40448147416798, -7.040258715715783],
            [110.404562669689597, -7.040086907791465],
            [110.404714758059086, -7.039884852760088],
            [110.404819614449337, -7.039679366783623],
            [110.404890722595752, -7.039430241271276],
            [110.404890726434957, -7.039430227820655],
            [110.404897817965988, -7.039207552941984],
            [110.404877797258607, -7.039032245394923],
            [110.404824015045094, -7.038873748957651],
            [110.40465222009324, -7.038654427836941],
            [110.404504039007662, -7.03843850752041],
            [110.404409785603519, -7.038257227395525],
            [110.404294113555892, -7.038020690382901],
            [110.404186658390159, -7.037747453127139],
            [110.404154138401339, -7.037585859333753],
            [110.404144670087135, -7.037462759740275],
            [110.404193000151395, -7.037303192105202],
            [110.404299133426321, -7.037103309924374],
            [110.4044225406398, -7.036943837790377],
            [110.405005941131208, -7.036436884922783],
            [110.405249155636227, -7.036211349708696],
            [110.405309095303309, -7.036137202733114],
            [110.40533034147856, -7.036046283148174],
            [110.405324760074535, -7.035929512350523],
            [110.405288374967242, -7.035779453152209],
            [110.405169016690124, -7.035553939944629],
            [110.40490427326796, -7.035177957327929],
            [110.404615816800586, -7.034828516234266],
            [110.404367919309351, -7.03448778692998],
            [110.40419495432441, -7.034260624204715],
            [110.404148956701135, -7.034100936502619],
            [110.404133747569475, -7.033950904188924],
            [110.404095376612858, -7.033848923480567],
            [110.40385941471898, -7.033297202814871],
            [110.403772061400758, -7.033007201997478],
            [110.403744418096224, -7.032809925539551],
            [110.403742233143348, -7.032585812828225],
            [110.403749527010575, -7.03215772928865],
            [110.403770542407912, -7.031948345883619],
            [110.40381306978415, -7.031809926503282],
            [110.403857500099448, -7.031688818726552],
            [110.403892273338528, -7.031592700816499],
            [110.403968614387693, -7.031355758223],
            [110.404116237650925, -7.030697235176033],
            [110.404126150749931, -7.030471266687493],
            [110.404107182254393, -7.030250069573889],
            [110.40405683708299, -7.030103358234746],
            [110.403972791682122, -7.029966220242456],
            [110.403754098559631, -7.029747173081953],
            [110.403226885113426, -7.029261538314297],
            [110.401997127381904, -7.027908276151327],
            [110.401756422515803, -7.027680684444414],
            [110.401547966594961, -7.027533275983514],
            [110.401366795175377, -7.02736922516817],
            [110.401047616182865, -7.027053285439512],
            [110.400612172723925, -7.026511816901491],
            [110.400315700085827, -7.026220723672698],
            [110.400008942238358, -7.026011341065058],
            [110.399611093520321, -7.025798470828247],
            [110.39943243653066, -7.02567352211185],
            [110.399286200828016, -7.025548498617892],
            [110.399213517800931, -7.025373239734416],
            [110.399240721046482, -7.02520810500179],
            [110.399352358310338, -7.024975662272189],
            [110.399467455853269, -7.024675807772883],
            [110.399562288236851, -7.024392781322601],
            [110.399606559635671, -7.024072611218049],
            [110.399600309052403, -7.023929921604362],
            [110.39956663178387, -7.023828457503098],
            [110.400561513829956, -7.023007264277154],
            [110.400806579517408, -7.023229174113116],
            [110.40092331922331, -7.023302277256917],
            [110.401107196936749, -7.023407565879282],
            [110.401337834117584, -7.023492487001796],
            [110.402216564154358, -7.021550112444758],
            [110.402554827865046, -7.021982431278175],
            [110.402971853928094, -7.022493640534906],
            [110.403610599767177, -7.023212320968031],
            [110.404622746482246, -7.024299163336773],
            [110.405182797774557, -7.024889342906381],
            [110.405580788204091, -7.025282704390726],
            [110.405658297963399, -7.025359770064806],
            [110.406256319123614, -7.025952914835326],
            [110.406895119043398, -7.026636573607849],
            [110.407679730858263, -7.027504260224186],
            [110.408137711046322, -7.027974663100874],
            [110.408811666394939, -7.028579573626963],
            [110.409625825185046, -7.029184659964284],
            [110.410434269456402, -7.029690520256187],
            [110.411111485588805, -7.030029878970035],
            [110.411803354862897, -7.030331319027514],
            [110.412682168685791, -7.030627156183356],
            [110.413882295330964, -7.030911721198962],
            [110.414841377001395, -7.031043342487073],
            [110.416296631937669, -7.031177064668825],
            [110.416931303449346, -7.03123068629859],
            [110.418117081481071, -7.031313680207749],
            [110.41801872957825, -7.032118815499209],
            [110.417899644832445, -7.032759684822441],
            [110.41778795606136, -7.033217927064548],
            [110.417647822253315, -7.033760488080285],
            [110.417528496647279, -7.03432416490072],
            [110.417530758714975, -7.034610619009592],
            [110.417560140948311, -7.034818396288867],
            [110.417649802485485, -7.035048582984839],
            [110.417802318683613, -7.035299104381358],
            [110.417985133712705, -7.03547864730764],
            [110.418224848366052, -7.035631629842043],
            [110.418523257414591, -7.035743850907551],
            [110.418739934477856, -7.035845317912881],
            [110.418906822657164, -7.035971578667613],
            [110.419048845125815, -7.036087156097937],
            [110.419217477453884, -7.036240049959662],
            [110.419459727288881, -7.03651256890211],
            [110.419880970550082, -7.037010593460034],
            [110.420285582939172, -7.037606658714053],
            [110.420748500694927, -7.038333371675725],
            [110.420888577770626, -7.038585652259411],
            [110.420977150313334, -7.038813013445895],
            [110.421070334170111, -7.039155849141816],
            [110.421155410417114, -7.039802133487479],
            [110.421240686082541, -7.040051564211263],
            [110.421352108617512, -7.040253329157712],
            [110.421522463071568, -7.040452384954706],
            [110.421914749887421, -7.040816827464292],
            [110.422017638925027, -7.040964313051069],
            [110.422102686765669, -7.041168589485361],
            [110.422162646606466, -7.041541498228899],
            [110.422136644388729, -7.042448697140457],
            [110.42209903958809, -7.042679453089475],
            [110.422002636513298, -7.043034414588085],
            [110.421785024723576, -7.043533959012353],
            [110.421676936062198, -7.043742666931693],
            [110.421518268771408, -7.044104777137221],
            [110.421420644604893, -7.044392213508941],
            [110.421364272279035, -7.044664920875525],
            [110.421327503419391, -7.044881967295713],
            [110.421299029613778, -7.045026660002375],
            [110.421227526905312, -7.045352209372234],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 128,
        id: 132,
        area: 6350145.4501259997,
        perimeter: 15517.704423,
        kode_pos: 50211,
        kode_kec: 3374010,
        kode_kel: 3374010010,
        kec: "Mijen",
        kel: "Kedung Pane",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.340483298680738, -7.052076977874793],
            [110.339687915206099, -7.052172911159588],
            [110.339111778255031, -7.052217323164444],
            [110.337709510971777, -7.052414334874257],
            [110.33737727104581, -7.052459090448622],
            [110.337213974072014, -7.052485994827268],
            [110.337226976581675, -7.05229606085693],
            [110.337205408556201, -7.052142259246635],
            [110.337187531661542, -7.05197941751963],
            [110.33717752574249, -7.051852768374001],
            [110.337169719072236, -7.051771349115899],
            [110.337148112494773, -7.051644683509285],
            [110.337071319194038, -7.05132798716966],
            [110.337045481373266, -7.051192270202635],
            [110.337017109941556, -7.051047504285893],
            [110.336888795588663, -7.050549827744828],
            [110.336875870283379, -7.050486491926214],
            [110.336840232474117, -7.050278398216238],
            [110.336813327300447, -7.050097452959691],
            [110.336778564023163, -7.050070267557268],
            [110.336698864435505, -7.050006836951175],
            [110.336669297054485, -7.049907296091406],
            [110.336655252887752, -7.049834913327729],
            [110.336691778290287, -7.049418878920611],
            [110.336666236442014, -7.049274117008175],
            [110.336640681740519, -7.049138400430207],
            [110.336588157665133, -7.04886696526362],
            [110.336561381172316, -7.048794564423464],
            [110.336495828878526, -7.048731153885517],
            [110.336450416894735, -7.048631590522659],
            [110.336405313606662, -7.048513936888381],
            [110.336385160618718, -7.048360137265893],
            [110.336344031408913, -7.048233443917676],
            [110.336354294493304, -7.048179186370642],
            [110.336372441312264, -7.04815207607747],
            [110.336388041661749, -7.048124962167743],
            [110.33639433069888, -7.048079744329822],
            [110.336381405499083, -7.048016408503567],
            [110.336355516502948, -7.047916872856124],
            [110.336245928773195, -7.047582039166724],
            [110.336226766041861, -7.047527739833782],
            [110.336093437237551, -7.047174781700036],
            [110.33606919448593, -7.047111429794455],
            [110.335960532879497, -7.046921323029513],
            [110.335880293785124, -7.046839800862696],
            [110.335652308962722, -7.046595252388106],
            [110.3355912068387, -7.046586120197285],
            [110.335548495806151, -7.046577014139246],
            [110.335459395529639, -7.046558796783207],
            [110.335469337071601, -7.046531674858097],
            [110.335520073467123, -7.046468429509954],
            [110.335591194388655, -7.046396167773617],
            [110.335634831501821, -7.046351003025738],
            [110.335722247354184, -7.046161174833332],
            [110.335739249832272, -7.045944110517462],
            [110.335791543065895, -7.045383372906695],
            [110.335804468438369, -7.045247710965823],
            [110.335822872626537, -7.045039693976726],
            [110.335842498393802, -7.044967359024486],
            [110.335888540437765, -7.044822698759644],
            [110.335901632849442, -7.044768445231115],
            [110.336099844382886, -7.044660182445675],
            [110.336127791105895, -7.044506451099434],
            [110.33617814140851, -7.044316570134556],
            [110.336250946701043, -7.044054358193247],
            [110.336385072305163, -7.043647507566194],
            [110.336485643835033, -7.043358198891831],
            [110.33661414879252, -7.042924204108675],
            [110.336703608313698, -7.042689151727632],
            [110.336454726146172, -7.042616435873084],
            [110.336129476691838, -7.042525520799988],
            [110.335798555768292, -7.042443642813149],
            [110.335308971571848, -7.042316312395497],
            [110.335131107113767, -7.042243696784976],
            [110.335050534436178, -7.042198355486463],
            [110.334978179835559, -7.042143980505883],
            [110.334880978334951, -7.042053388774299],
            [110.334706908238616, -7.041899370236766],
            [110.334423380387946, -7.04171805986305],
            [110.334223215906633, -7.041609230802325],
            [110.333864684427539, -7.041445904075692],
            [110.333511503190849, -7.041300675420447],
            [110.333306773400921, -7.041218975549635],
            [110.333139931328972, -7.041155420303805],
            [110.333034434814863, -7.041128133863819],
            [110.332814619106628, -7.041109729732778],
            [110.332503132056843, -7.041091194714449],
            [110.33207192418584, -7.041099624626709],
            [110.331633090004388, -7.041098997897173],
            [110.330948976062672, -7.041079929352797],
            [110.330256361212406, -7.041069892988553],
            [110.329953079599832, -7.041051368030476],
            [110.329839276881742, -7.041060233657681],
            [110.329857224317848, -7.041033140045893],
            [110.329949069189709, -7.040888546083186],
            [110.330107593774642, -7.040635503434585],
            [110.3303318006122, -7.040156421087861],
            [110.330448476200303, -7.039885227686226],
            [110.330500190890461, -7.039731530800179],
            [110.330723947602593, -7.039171039405144],
            [110.330844518968419, -7.038945078156087],
            [110.331135831426991, -7.038429909796085],
            [110.331375301492173, -7.037959893868728],
            [110.331524847968595, -7.037652565626652],
            [110.331777370959841, -7.037155431997251],
            [110.332013701502916, -7.036703501815808],
            [110.332254223854619, -7.036287758845289],
            [110.332422442732693, -7.035980456823367],
            [110.332545610425015, -7.035718317249786],
            [110.332733274960674, -7.035266317163894],
            [110.332902561394832, -7.034805245425698],
            [110.333032120771918, -7.034425525178692],
            [110.333123072095148, -7.034136203421503],
            [110.333213804873992, -7.03380165455538],
            [110.33331807952473, -7.033494260992931],
            [110.333402201387344, -7.033232065476778],
            [110.333438727022852, -7.033022818801103],
            [110.333480395262512, -7.032761818354319],
            [110.333503261885724, -7.032599034518545],
            [110.333541658605753, -7.032424662924862],
            [110.333613384919843, -7.032234396037423],
            [110.333674893634296, -7.032050913488632],
            [110.333726242894187, -7.031833422021366],
            [110.333772846951391, -7.03164061043154],
            [110.333846036627079, -7.03134067278501],
            [110.333976183129991, -7.030745954829057],
            [110.33421906030766, -7.029838647952755],
            [110.334349321855001, -7.029162343226511],
            [110.334479640972916, -7.02844524513444],
            [110.334610032113929, -7.02767715535024],
            [110.334654642792472, -7.027412061836674],
            [110.334671926415353, -7.027221717352999],
            [110.3347131295791, -7.026960018431868],
            [110.334750963938134, -7.026674518591125],
            [110.334795845011399, -7.02621905639078],
            [110.334833795185475, -7.025851969959534],
            [110.334871788755805, -7.025454288551458],
            [110.334909617980017, -7.025172188099533],
            [110.334940704811117, -7.024845885259484],
            [110.334978606360323, -7.02451279317896],
            [110.335033501310946, -7.024193322972764],
            [110.335118932264351, -7.023935086098586],
            [110.335253055875768, -7.023538071344299],
            [110.335323786962277, -7.023339173654248],
            [110.335425961247736, -7.023113184418008],
            [110.335570406857912, -7.022805846778096],
            [110.335791675238397, -7.022291228176072],
            [110.335791719660563, -7.022291233234411],
            [110.336227178986604, -7.021513386254515],
            [110.336227195461333, -7.021513357049291],
            [110.336753516919188, -7.021681806653398],
            [110.337166208781781, -7.021809311877012],
            [110.337582733792658, -7.021895111232111],
            [110.338055274647147, -7.021949388160174],
            [110.338309344037668, -7.021953712929516],
            [110.338453859940444, -7.021896340572345],
            [110.338622893961769, -7.0218061252527],
            [110.338780032300591, -7.021724938466167],
            [110.338990694475072, -7.021607645571669],
            [110.339089537403126, -7.021535421891653],
            [110.339262836191509, -7.021451335393967],
            [110.339419387330338, -7.021364024474665],
            [110.339628309220501, -7.021281298598932],
            [110.339896074952449, -7.02119283343565],
            [110.34002088299421, -7.021165872921736],
            [110.340138605161499, -7.021147947777158],
            [110.340199678661605, -7.021175169787073],
            [110.340244599971811, -7.021220459815835],
            [110.340415012483632, -7.021356380003569],
            [110.340496227307597, -7.021546446973353],
            [110.340581686067736, -7.021736519899386],
            [110.340621953287922, -7.021872257066944],
            [110.340729429375571, -7.022098542407014],
            [110.340780241312743, -7.022180022159295],
            [110.340856894286901, -7.022287101165747],
            [110.340992425847773, -7.022373952850517],
            [110.341147406353798, -7.02232142477742],
            [110.341522500291305, -7.022237128867395],
            [110.341569546725651, -7.022226555966776],
            [110.341987621922826, -7.022191344542443],
            [110.342126974726838, -7.022183584874782],
            [110.342373713497523, -7.022251549483384],
            [110.342508796737079, -7.022328920882778],
            [110.342652173436463, -7.022411042445327],
            [110.342882881587116, -7.022554558096722],
            [110.343129637436775, -7.022610589658989],
            [110.343356520856858, -7.022642727876293],
            [110.343584209099333, -7.022663305839533],
            [110.344430828712845, -7.022791178691879],
            [110.344641570550479, -7.022818609333999],
            [110.344708919589948, -7.022809658055912],
            [110.344875292713553, -7.022800845091781],
            [110.345010261054171, -7.022791988213076],
            [110.34533198583199, -7.022765301246729],
            [110.345440076198869, -7.022756406725986],
            [110.345532039783762, -7.02274748967765],
            [110.345607310734778, -7.022738549321815],
            [110.345670415867531, -7.022729591981792],
            [110.345732106368828, -7.022720632659753],
            [110.345893412082646, -7.022693721501087],
            [110.346070844562021, -7.022666832769269],
            [110.346467595142911, -7.022604068080054],
            [110.347408323962668, -7.022605377884259],
            [110.347476509126224, -7.022605472747122],
            [110.348028535312366, -7.022655248051801],
            [110.348087836662472, -7.022660595119261],
            [110.348115833747954, -7.022669679415153],
            [110.348470792684921, -7.022751581074161],
            [110.348559606320322, -7.022769795205128],
            [110.348685753634754, -7.0227971065492],
            [110.349110004585015, -7.022897194730498],
            [110.349208708129368, -7.0229244678536],
            [110.349617869814494, -7.022888853861406],
            [110.349821501371167, -7.022943408507796],
            [110.349859388376629, -7.022961551800697],
            [110.350206929096075, -7.023088668846233],
            [110.350245829441945, -7.023081529768273],
            [110.350504568542902, -7.023034015942793],
            [110.35088253783772, -7.022859391275505],
            [110.351038643205271, -7.022836555141875],
            [110.351076192293291, -7.022818707684644],
            [110.35129406360862, -7.022715149633221],
            [110.351587981025318, -7.022633366015102],
            [110.35187989903045, -7.02258093302516],
            [110.353062996718947, -7.022547342013013],
            [110.353300033415522, -7.022522229107555],
            [110.35340193937391, -7.022483231474631],
            [110.353519568880174, -7.022405117363921],
            [110.353648999113958, -7.022291795167035],
            [110.353786339666144, -7.022123690446745],
            [110.354035646616794, -7.021722867999437],
            [110.354367557880366, -7.021075588821494],
            [110.354628605034321, -7.020682609729501],
            [110.354883746037146, -7.020311148302392],
            [110.355062344489795, -7.020051125195393],
            [110.355230979622121, -7.019914373458445],
            [110.35548198692392, -7.019699458513018],
            [110.355634995452817, -7.019531374625672],
            [110.355721360360803, -7.01939842351412],
            [110.355768535512354, -7.019277160025348],
            [110.355783087470314, -7.019219342677028],
            [110.355787523762942, -7.0192017165454],
            [110.35573756674934, -7.019008042743752],
            [110.355708395193574, -7.018856342371459],
            [110.355679237110323, -7.018694857479606],
            [110.355703868583277, -7.018587261389091],
            [110.35576736456585, -7.018519447772129],
            [110.356009677202906, -7.018420201413008],
            [110.356146994770867, -7.018348026621754],
            [110.356238773985794, -7.018266743925213],
            [110.35625127235356, -7.018230579469579],
            [110.356273144491965, -7.018167291670867],
            [110.356284968080956, -7.018004490689676],
            [110.356280923078145, -7.017859758742831],
            [110.356283926306332, -7.017733127261803],
            [110.356302132832951, -7.017660789030616],
            [110.356347487578631, -7.017597533427484],
            [110.356455959085324, -7.017516273559303],
            [110.356564380867511, -7.017471195196683],
            [110.356621531394907, -7.017471273536373],
            [110.356759843686859, -7.017498599303424],
            [110.356831989161705, -7.017498698166776],
            [110.356880406316733, -7.017471628304761],
            [110.356922623981603, -7.017426459141149],
            [110.356952675933428, -7.017381273306337],
            [110.357027702845727, -7.017137150245309],
            [110.357060596410662, -7.017082922891178],
            [110.357078155521876, -7.017059000964564],
            [110.357093760479771, -7.017037741306111],
            [110.357102290207635, -7.017035859774252],
            [110.357134513826509, -7.0170287517188],
            [110.357153444858952, -7.017046868448464],
            [110.357107608775877, -7.017254849912734],
            [110.357083840671237, -7.017462861594382],
            [110.357084356870175, -7.017499043907431],
            [110.357121541417129, -7.017616685058313],
            [110.357165823891876, -7.017716245125548],
            [110.357254525396428, -7.017815866020145],
            [110.357328244389564, -7.017906420984179],
            [110.357387546705681, -7.01798791079838],
            [110.357461290561986, -7.01806037497577],
            [110.357550041809631, -7.018123814284406],
            [110.357737459117814, -7.018241661023169],
            [110.357799640774815, -7.01827801501557],
            [110.357846015118042, -7.018305127383782],
            [110.357905059445784, -7.018368525985641],
            [110.357959294080402, -7.018431918001538],
            [110.358110656315347, -7.018640169311769],
            [110.358259447578433, -7.018866507873685],
            [110.358335921828029, -7.019011338893954],
            [110.358394830022306, -7.019174236694622],
            [110.358473456257755, -7.019400479269887],
            [110.358522573661674, -7.019481955045986],
            [110.358659907727628, -7.019705704936319],
            [110.358770128956067, -7.019826401144476],
            [110.359185830955965, -7.019952644227392],
            [110.359388528508333, -7.020029865000342],
            [110.359550404650804, -7.019922364389966],
            [110.360037835807404, -7.020158991045038],
            [110.360512428354482, -7.020398164537024],
            [110.360959952491271, -7.020698072129086],
            [110.36122915810634, -7.020824461405959],
            [110.36146228232397, -7.020953871901483],
            [110.361622375950603, -7.021137650354206],
            [110.361765980993113, -7.021164545463535],
            [110.361986503927284, -7.021128133300477],
            [110.36230727360342, -7.021068495044664],
            [110.362567896079881, -7.021022124707022],
            [110.362805085078591, -7.02101243451872],
            [110.363058908479246, -7.021052828785075],
            [110.363299279172566, -7.02115996275253],
            [110.363474473485752, -7.02125254573508],
            [110.363250221770357, -7.02140701887086],
            [110.36316501272006, -7.021504498858514],
            [110.363143594908422, -7.02161917916299],
            [110.363105098290632, -7.021784818223469],
            [110.363052625182718, -7.021908358113035],
            [110.362968801852276, -7.022011523142792],
            [110.362845625087502, -7.022094962538596],
            [110.36261412603227, -7.022197926880942],
            [110.36239742123972, -7.02228123904912],
            [110.36230379954381, -7.022349964340089],
            [110.362224898496336, -7.022453135905304],
            [110.362224751110588, -7.02256133258364],
            [110.362278664422433, -7.022733537286672],
            [110.362381856639672, -7.022866464660814],
            [110.362603115974736, -7.023053650927226],
            [110.362809668136649, -7.023196554759346],
            [110.362971964470702, -7.023304972117751],
            [110.363129438708299, -7.023339612268876],
            [110.363267416821841, -7.02323160271871],
            [110.363479694228289, -7.022784349133115],
            [110.363632693693646, -7.022489474268999],
            [110.363746105596448, -7.022347004922489],
            [110.363805215981458, -7.022317576849887],
            [110.363943039969854, -7.022322681803391],
            [110.364110318957444, -7.022386843149373],
            [110.36422331664599, -7.022549291703538],
            [110.364345845630538, -7.022942901120635],
            [110.364446266785109, -7.023304513286837],
            [110.364463165159336, -7.02354797925701],
            [110.364457735164308, -7.023921743073045],
            [110.364417593548268, -7.024482345416918],
            [110.364387497252082, -7.02489542010957],
            [110.364330557267735, -7.025138785969351],
            [110.36424656653567, -7.025364901991486],
            [110.364123242021734, -7.025556538356843],
            [110.363990058914567, -7.025757997387375],
            [110.363842061201879, -7.025993862551317],
            [110.363624290658066, -7.026352879040029],
            [110.363340266928816, -7.026708166066805],
            [110.363176195353915, -7.026890500849673],
            [110.363083045122195, -7.027046177795705],
            [110.363010374877732, -7.027200308775309],
            [110.362846264417001, -7.02741097126695],
            [110.36267599868539, -7.027514608781163],
            [110.362445882908048, -7.02761186973127],
            [110.362286753005108, -7.027635259840657],
            [110.362028454730492, -7.02760658043706],
            [110.36177026384388, -7.027499212424437],
            [110.361455433754799, -7.027341406412952],
            [110.36112175708665, -7.027142656445351],
            [110.360869897686513, -7.027013263796071],
            [110.360721904179201, -7.02695640619591],
            [110.360602204491414, -7.026943652762158],
            [110.360454103490028, -7.026965483533512],
            [110.360359517007268, -7.027018862721383],
            [110.360283794443447, -7.027100595526724],
            [110.360252173790442, -7.027185536053848],
            [110.360261500320505, -7.027276827551882],
            [110.360314980449502, -7.027333556327187],
            [110.360431435116169, -7.027415551379202],
            [110.360541567418778, -7.027513275499842],
            [110.360664142416809, -7.02772747569651],
            [110.360706417574434, -7.027914812224674],
            [110.360709116397601, -7.028245308017415],
            [110.360702316519607, -7.028610413830971],
            [110.360719259036017, -7.028892142115791],
            [110.360706399097737, -7.029080977200765],
            [110.360684152293416, -7.029222586320405],
            [110.360633585745191, -7.029338976448328],
            [110.360584695518085, -7.029381401579191],
            [110.360521647572256, -7.029409643437437],
            [110.360436572214894, -7.029418969961469],
            [110.36029165962114, -7.029412477069022],
            [110.360197150101797, -7.029409200496202],
            [110.36006794245435, -7.02943735195457],
            [110.359967058793899, -7.029487574866224],
            [110.359859770801819, -7.029613330042338],
            [110.359796576304362, -7.029748588063273],
            [110.359758585477124, -7.02988388051011],
            [110.359741057563213, -7.030030217313777],
            [110.359728304887469, -7.030140363895131],
            [110.359636786240571, -7.030253550374521],
            [110.359415893649825, -7.030366654895183],
            [110.359415887601557, -7.030366657992102],
            [110.359185967704391, -7.030473262008925],
            [110.359020488362404, -7.030531265189197],
            [110.358872330084751, -7.030594013323481],
            [110.35877456200005, -7.030669420544952],
            [110.358686258240496, -7.03073539807107],
            [110.358579060276213, -7.03079505462644],
            [110.35839942308175, -7.030838874246411],
            [110.358144248035941, -7.030829082098204],
            [110.357908006078887, -7.030797282967139],
            [110.357695455362901, -7.030719876844373],
            [110.357421524672375, -7.030603042181684],
            [110.357197932367527, -7.030536637099257],
            [110.357097126094217, -7.03053020370244],
            [110.357012024553327, -7.030558414792163],
            [110.356961543513037, -7.030611853713511],
            [110.356936244557787, -7.030681064899228],
            [110.356917159961554, -7.030813235438366],
            [110.356948422260331, -7.03098954066081],
            [110.357064790560656, -7.031134487273696],
            [110.357264570715301, -7.031334630284119],
            [110.357481589980139, -7.031599321468815],
            [110.357586925443471, -7.031748974156248],
            [110.357662316315867, -7.031909602160398],
            [110.357677865466428, -7.032057557936241],
            [110.357652402191277, -7.032246375512206],
            [110.357574984088629, -7.032414662809379],
            [110.357492885624481, -7.032549894482718],
            [110.35734776883514, -7.03269133475856],
            [110.357013493170584, -7.032926941573177],
            [110.356620974381315, -7.033132566474039],
            [110.35638141195831, -7.033223516005156],
            [110.356176548131671, -7.033283037700751],
            [110.355962250152075, -7.033329956167198],
            [110.355751146001381, -7.033345403522629],
            [110.355585825836428, -7.033286946587006],
            [110.355409567996389, -7.033163949971951],
            [110.354819666918075, -7.032572974023171],
            [110.354484699374353, -7.032168053667196],
            [110.354270758274012, -7.031956873643836],
            [110.354119670298076, -7.031859091623256],
            [110.353965357983782, -7.031814813281813],
            [110.353810971753802, -7.031824042957826],
            [110.353691153709974, -7.031896271061418],
            [110.353586970507436, -7.032053504262455],
            [110.353504723048459, -7.032295751262483],
            [110.353421015610138, -7.032454586440442],
            [110.3532948223734, -7.032580313781027],
            [110.353093030648566, -7.032696494057992],
            [110.352925981230172, -7.032749771464895],
            [110.352762043038766, -7.032831380954559],
            [110.352657735853612, -7.032916381968612],
            [110.35262214705466, -7.032996710564761],
            [110.352564486252817, -7.033159447840946],
            [110.352454074614371, -7.03341256604753],
            [110.352335532986913, -7.033611400648622],
            [110.352123981508328, -7.033755834225358],
            [110.351743531406697, -7.033890988538629],
            [110.351557185781132, -7.034017365949912],
            [110.351396411251784, -7.034270414088835],
            [110.351165230469718, -7.03469522691281],
            [110.35101272994919, -7.035102057870539],
            [110.350834180573969, -7.035725941841137],
            [110.350574680713137, -7.036217174129445],
            [110.349970599456768, -7.037304931657443],
            [110.349627005072577, -7.037735108923189],
            [110.349322967557796, -7.038003279939918],
            [110.349116663524441, -7.038081701524905],
            [110.348921945291366, -7.038113845546564],
            [110.348643970054226, -7.038020844911643],
            [110.348454125867931, -7.037881659884883],
            [110.34839341314742, -7.037676353097733],
            [110.348496442073483, -7.037451064343418],
            [110.34864036154039, -7.037284559686692],
            [110.348806135837009, -7.037125159335832],
            [110.349034640705597, -7.036998841845819],
            [110.349262875121042, -7.036863478475905],
            [110.349299301749028, -7.036739054296257],
            [110.349229956442585, -7.036609298472121],
            [110.349044650882561, -7.036539580427317],
            [110.348822213303251, -7.036506856212823],
            [110.348570189556085, -7.036500699864308],
            [110.348224478314492, -7.036371734283535],
            [110.347979020713709, -7.036227841003781],
            [110.347691819401305, -7.035992935584],
            [110.347455835232083, -7.035814980424655],
            [110.347196460102793, -7.035680328765609],
            [110.347020386545751, -7.035638407001913],
            [110.346906499025934, -7.035631095804109],
            [110.346766314473683, -7.035679682790739],
            [110.346685516871133, -7.03576865123737],
            [110.346587557483417, -7.035984854548016],
            [110.346557462097209, -7.036252056088377],
            [110.346563552222364, -7.036451436755479],
            [110.346488654661741, -7.036875528293494],
            [110.346477710453541, -7.037110941839329],
            [110.346495682325013, -7.037329983065108],
            [110.346436536226051, -7.037445254407499],
            [110.346341026708757, -7.037526781043328],
            [110.34617882562037, -7.0376003164708],
            [110.346079386541305, -7.037740861234598],
            [110.346034503113131, -7.037961637811462],
            [110.346071220660107, -7.038221007792846],
            [110.346168187715634, -7.038485092638866],
            [110.34631269279096, -7.038695579162121],
            [110.346484502535702, -7.038849590486432],
            [110.346728794580869, -7.038967521443974],
            [110.346919059479191, -7.039104032854514],
            [110.347061997330712, -7.039325031917959],
            [110.347108263800109, -7.039537909689196],
            [110.347102363011345, -7.039794853889398],
            [110.34706152794665, -7.040151121613252],
            [110.34691305852273, -7.040657828690991],
            [110.346826119795637, -7.040886773188739],
            [110.346724151417831, -7.041033430807384],
            [110.346649210881765, -7.041083353124303],
            [110.346543815136286, -7.041107060716416],
            [110.346413104802139, -7.041103384742327],
            [110.346290108900433, -7.04107399523448],
            [110.346134726420345, -7.040989311230008],
            [110.346015978672455, -7.040889035001665],
            [110.345887085744224, -7.040756505670139],
            [110.345776801574118, -7.040676602628942],
            [110.34565627237167, -7.040635711263928],
            [110.345467757340117, -7.040630357223116],
            [110.345250274394971, -7.040692833915105],
            [110.345087104445724, -7.040780838285472],
            [110.344998575911688, -7.040931728090137],
            [110.344976305585689, -7.041088513129191],
            [110.344963493810454, -7.041468760989073],
            [110.344986840093952, -7.042331547244248],
            [110.345023101493254, -7.042740485958749],
            [110.345149249463432, -7.043259950274797],
            [110.345168352240606, -7.043399088991266],
            [110.345173335865908, -7.043595272105442],
            [110.345155918269583, -7.043787634983634],
            [110.34511138172256, -7.043995895519203],
            [110.345038075642066, -7.044252388595736],
            [110.344959691552276, -7.044435531400667],
            [110.344832176005696, -7.044633369550603],
            [110.344672166218331, -7.044911189084271],
            [110.344520249956716, -7.045254441945147],
            [110.344436708511012, -7.045552978561133],
            [110.344296885297013, -7.045946436139563],
            [110.344201483020811, -7.04615330984364],
            [110.344084866093965, -7.046353739578433],
            [110.343888054310369, -7.046606973824476],
            [110.343597072866572, -7.046884609008083],
            [110.343257011710705, -7.047137641759528],
            [110.342958296016576, -7.047318950636297],
            [110.342667593430264, -7.047511975105838],
            [110.342439823131116, -7.047640610185706],
            [110.342215554830076, -7.047694591656462],
            [110.342025305195421, -7.047712988481141],
            [110.341711075504961, -7.047727817066841],
            [110.34139173138287, -7.04775621248776],
            [110.34107461245155, -7.047788790521481],
            [110.340789854344678, -7.04785983055825],
            [110.340521849367306, -7.047951565922506],
            [110.340235195143876, -7.048061560985472],
            [110.340001764962139, -7.048159364399176],
            [110.339898122035535, -7.048246172077628],
            [110.339828354589145, -7.048339396506854],
            [110.339833588528208, -7.048420814832433],
            [110.339984809296737, -7.048572316764154],
            [110.34025852438316, -7.048835979783607],
            [110.34061806788462, -7.049089757318134],
            [110.340786734008418, -7.049211198232676],
            [110.340970005937919, -7.04932344431827],
            [110.341098865924138, -7.049479729942748],
            [110.341193602294823, -7.04966947218004],
            [110.341313045909629, -7.05004050039022],
            [110.341369545735489, -7.05023687617871],
            [110.341442190094241, -7.050511857208853],
            [110.341452138663684, -7.050683246181719],
            [110.341426508796332, -7.05079180408183],
            [110.341358422862669, -7.050896908564392],
            [110.341256373008136, -7.050998571582466],
            [110.341151036527208, -7.051022177987338],
            [110.341038927882238, -7.05103050378184],
            [110.340865697193024, -7.051023472285876],
            [110.340681577903467, -7.050998417965783],
            [110.340546665361259, -7.050962046113713],
            [110.340471698322347, -7.05095289493072],
            [110.340436600625722, -7.050961890742401],
            [110.340411650481784, -7.050998036956444],
            [110.340376488800871, -7.051052259470135],
            [110.340263794487583, -7.051246937187092],
            [110.34020922175884, -7.051404321414723],
            [110.340185904049903, -7.051559034927553],
            [110.340207451934646, -7.051694807857353],
            [110.340288761755588, -7.051846954288203],
            [110.3403633201292, -7.051969227825444],
            [110.340483298680738, -7.052076977874793],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 129,
        id: 133,
        area: 2273641.450003,
        perimeter: 9430.342642,
        kode_pos: 50223,
        kode_kec: 3374020,
        kode_kel: 3374020012,
        kec: "Gunungpati",
        kel: "Jatirejo",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.346724151417831, -7.041033430807384],
            [110.346948691194257, -7.041115177822127],
            [110.347069808447174, -7.041204410431796],
            [110.347101782316372, -7.041289956070873],
            [110.347122978683544, -7.041432487344659],
            [110.347219129501795, -7.041525247623296],
            [110.347343797911734, -7.041625172757441],
            [110.347422158948419, -7.04168940784125],
            [110.347446991070385, -7.041782068585873],
            [110.34741980939161, -7.041944414236834],
            [110.347280803195488, -7.042206536171617],
            [110.347212400167734, -7.042360212089443],
            [110.347179993462717, -7.042468711359244],
            [110.347171429430489, -7.042522971654854],
            [110.347171353537547, -7.042577243797054],
            [110.347179803733155, -7.042604391715054],
            [110.34719674207247, -7.042631551479647],
            [110.347232932853146, -7.042649692737992],
            [110.347273675940869, -7.04264974959693],
            [110.347314148737055, -7.042640760700247],
            [110.347384342677813, -7.042622767894686],
            [110.347422847547733, -7.042604730867299],
            [110.347448594914127, -7.042604766788719],
            [110.347476310204286, -7.042613850829305],
            [110.347493261204875, -7.042631965227184],
            [110.347510186921852, -7.042668170339764],
            [110.347522287005646, -7.04312045597788],
            [110.347522185860626, -7.043192818837357],
            [110.347515897997866, -7.043238036940448],
            [110.347498575531702, -7.043283239648498],
            [110.347372634674741, -7.043509198293552],
            [110.347273095536906, -7.043671876120558],
            [110.347239890508845, -7.043744192769918],
            [110.347218632150714, -7.04377129922018],
            [110.347195122930245, -7.043789357153],
            [110.347169645787517, -7.043798366963134],
            [110.347146174518684, -7.043789288823659],
            [110.347115359494552, -7.04377115505668],
            [110.347058024147728, -7.04369871201724],
            [110.347019607749132, -7.043653431509906],
            [110.346983442212874, -7.043617199516867],
            [110.346932268278124, -7.04358999193764],
            [110.34687034268137, -7.043562769338275],
            [110.346821406954177, -7.043553655621118],
            [110.346785190809356, -7.043553605038541],
            [110.346761694244492, -7.043562617593306],
            [110.346742429099393, -7.043580681430895],
            [110.346731897073283, -7.04362589358699],
            [110.346731846435986, -7.043662075010252],
            [110.346746495952459, -7.043707322340884],
            [110.346778392076558, -7.043761639134763],
            [110.346872188518446, -7.0438612692488],
            [110.3469106049169, -7.043906549768105],
            [110.346929523851969, -7.043933712309708],
            [110.346942463105052, -7.043988002621551],
            [110.346950887993401, -7.044033241255319],
            [110.346950824714241, -7.044078468035584],
            [110.346944215869669, -7.04415082179601],
            [110.346946390788673, -7.044214142448973],
            [110.346952564823084, -7.044250332565672],
            [110.346965516740255, -7.044295577521217],
            [110.346967459345976, -7.044322716355007],
            [110.346928929000484, -7.044358844040721],
            [110.346873485589839, -7.044349721233155],
            [110.346754186564397, -7.044277191607876],
            [110.346579456857398, -7.044186493759748],
            [110.346481623289137, -7.044141130183593],
            [110.346417704415089, -7.044122950107851],
            [110.346372717121497, -7.044122887230499],
            [110.346340732352218, -7.044131887896367],
            [110.346319486594126, -7.044149948944001],
            [110.346300196069947, -7.044186103468767],
            [110.346289393712794, -7.044222269858222],
            [110.346291581211105, -7.044276545151115],
            [110.346331851468847, -7.044412282031931],
            [110.346357497584989, -7.044484680861203],
            [110.346370103168198, -7.044575152206622],
            [110.346369989155278, -7.044656560399682],
            [110.346367869228956, -7.04475605653338],
            [110.346366416526919, -7.04478319062],
            [110.346361479871376, -7.044873637443187],
            [110.346359064329548, -7.044982178534775],
            [110.346367451162635, -7.04505455323744],
            [110.346390783119432, -7.045163130320915],
            [110.346429123554501, -7.045262683010757],
            [110.346454524784335, -7.045307945378296],
            [110.34649350712462, -7.045353226727303],
            [110.346531961591978, -7.045371371218441],
            [110.346561940536418, -7.04538045848917],
            [110.346606632348298, -7.045389566319002],
            [110.346642861298506, -7.04538057157354],
            [110.346713338617391, -7.045362579306482],
            [110.34678354565348, -7.045335541277744],
            [110.34684127801502, -7.045326576557525],
            [110.346905235048553, -7.045317620524699],
            [110.346945708084974, -7.045308631682869],
            [110.347001189581377, -7.045290618425128],
            [110.347037418518951, -7.045281623647647],
            [110.347069390704291, -7.045281668296373],
            [110.347105594330202, -7.04529076422514],
            [110.347133309766505, -7.045299848299083],
            [110.347159019317829, -7.045327020317954],
            [110.347175945086235, -7.045363225445103],
            [110.347184357356369, -7.045417509432349],
            [110.347182250238902, -7.045507960226288],
            [110.347166938065499, -7.04573407318409],
            [110.347160152036153, -7.045933061923667],
            [110.347160038138838, -7.046014470124895],
            [110.347166469830626, -7.046068751346162],
            [110.347177170936845, -7.046104947782439],
            [110.347211639023186, -7.04614117740536],
            [110.347271276143431, -7.046186487541188],
            [110.34734135676166, -7.046249902997347],
            [110.347418215316381, -7.046322373279652],
            [110.347484026570044, -7.046403873505545],
            [110.347537426412927, -7.04645822028021],
            [110.347575560131062, -7.046503500370601],
            [110.347598980950849, -7.046548759925888],
            [110.347609656803769, -7.046603047068158],
            [110.347630712927327, -7.04672066631368],
            [110.347647883773377, -7.046784007893274],
            [110.347679767538594, -7.046847370003872],
            [110.347775532689298, -7.046956048118675],
            [110.347832906441582, -7.047001355037649],
            [110.347879836681713, -7.047028556632838],
            [110.347951099838056, -7.047055792166648],
            [110.348087439397261, -7.047083118451959],
            [110.348157853754103, -7.047110352772269],
            [110.348213284890036, -7.047128520816069],
            [110.348285654577651, -7.047173848594574],
            [110.34838375927194, -7.047228257613824],
            [110.348571147039706, -7.047373244803559],
            [110.348671194514338, -7.047454792602742],
            [110.348730819322114, -7.04750914793622],
            [110.348788463555621, -7.047563500503855],
            [110.348826867800341, -7.047617826261763],
            [110.348867202141335, -7.047708336208774],
            [110.348877607842539, -7.047753577583133],
            [110.348875589377727, -7.047780710898348],
            [110.348859115653511, -7.047825914827193],
            [110.348801281979078, -7.04790724263424],
            [110.348775728862108, -7.047970524659643],
            [110.34876261253379, -7.048042869387374],
            [110.348760556175392, -7.048097138774346],
            [110.348781574742972, -7.048241894066673],
            [110.348800701122173, -7.048323329094533],
            [110.348830591918201, -7.048395733744643],
            [110.348898899667262, -7.048513418793123],
            [110.348926577456993, -7.048549638853977],
            [110.348966987632153, -7.048585876650333],
            [110.349028901392714, -7.048622144396711],
            [110.349065054831357, -7.048667421632149],
            [110.349101183031692, -7.048730789581629],
            [110.349158764342292, -7.048830368909627],
            [110.349192616409425, -7.048902779059768],
            [110.349209787525922, -7.048966120602959],
            [110.349215936525837, -7.049020401420846],
            [110.349209610831196, -7.049092755618002],
            [110.349186013039471, -7.049174131136247],
            [110.349112993940011, -7.049391118451091],
            [110.349095916410107, -7.049463457667428],
            [110.349087056827514, -7.049526762954399],
            [110.349093488756793, -7.049581044166104],
            [110.349133697048742, -7.049762007681033],
            [110.349148617261093, -7.049816300715599],
            [110.349150805059594, -7.049870576015222],
            [110.349137713956139, -7.04992483003261],
            [110.349112185996759, -7.049970021349693],
            [110.34900732044116, -7.050096510512009],
            [110.348979528916871, -7.050141698665813],
            [110.348962514468269, -7.050168811083938],
            [110.34895396304475, -7.050214026043254],
            [110.348960432844819, -7.050241171182776],
            [110.348983608899914, -7.050259294224831],
            [110.349017819727379, -7.050277432640232],
            [110.34907978420847, -7.05027751896825],
            [110.349205398206635, -7.050286739321949],
            [110.349256598240416, -7.050295856008616],
            [110.349295065847656, -7.050304954958033],
            [110.349331244671646, -7.050332141468113],
            [110.349350434305038, -7.050368349693187],
            [110.349354615345135, -7.050413582393147],
            [110.34934381302962, -7.050449748852153],
            [110.349281992619979, -7.050549161886695],
            [110.349260425836675, -7.050594358725768],
            [110.349249893828485, -7.050639570933027],
            [110.349247592414187, -7.050666703853278],
            [110.349258293758965, -7.050702900259354],
            [110.349285984315557, -7.050730074952332],
            [110.349398752417898, -7.050820685752827],
            [110.349428706535434, -7.050847863590688],
            [110.349439124954287, -7.050884059600018],
            [110.349437106491635, -7.050911192915809],
            [110.349417841110323, -7.050929256839686],
            [110.349385560337481, -7.050947302637641],
            [110.349244899666971, -7.050974242858573],
            [110.34919366168559, -7.050992262236389],
            [110.349161663844214, -7.051010308411772],
            [110.349144379051907, -7.051028375082097],
            [110.349135827622291, -7.051073590043264],
            [110.3491398066204, -7.051263548458569],
            [110.349150394328575, -7.051381153081651],
            [110.349167219424572, -7.051489721019383],
            [110.349167156292737, -7.051534947805292],
            [110.349156649499434, -7.051562069291587],
            [110.349128895770164, -7.051580121373541],
            [110.349094659590932, -7.051580073672175],
            [110.349049967126518, -7.051570966023718],
            [110.348968812829142, -7.051534671435923],
            [110.348868713958552, -7.051489305056152],
            [110.348832522448447, -7.051471163862422],
            [110.348794029492524, -7.051480155580424],
            [110.348737994035034, -7.051489122839699],
            [110.348645408311427, -7.051534220627411],
            [110.348553080237963, -7.051597409501692],
            [110.348442618465853, -7.051678663810537],
            [110.348368386091778, -7.051750923255068],
            [110.348289035419612, -7.051841266291157],
            [110.348222429832433, -7.051922581720753],
            [110.348181289166632, -7.05200393267233],
            [110.348150592114834, -7.052103388937991],
            [110.348127264238713, -7.052193810111318],
            [110.348091524761884, -7.052257077842767],
            [110.348045328995681, -7.052311285603901],
            [110.347986400736616, -7.05236547558671],
            [110.347866331238137, -7.052437670947777],
            [110.347574105092548, -7.052600079557823],
            [110.347505003414369, -7.052645209889175],
            [110.347456273706428, -7.052690368670365],
            [110.347423105922076, -7.052735549188005],
            [110.347361504403395, -7.052880189055657],
            [110.347320363494433, -7.052961539906538],
            [110.34726162935192, -7.053079047648526],
            [110.347217941905228, -7.053160394927562],
            [110.347197481229216, -7.05322368392471],
            [110.347186923606401, -7.053286986762433],
            [110.347186847598081, -7.053341258879867],
            [110.3471969575956, -7.05339554523482],
            [110.347220095716793, -7.053440804428817],
            [110.34730167672933, -7.053576599009692],
            [110.347375605611646, -7.053721428257346],
            [110.347416505968198, -7.053811939119585],
            [110.347452068377237, -7.053875306408036],
            [110.34750063360093, -7.053947737233246],
            [110.347526010010057, -7.054011090285443],
            [110.347543746908954, -7.054074432663284],
            [110.347552699851548, -7.054146808139353],
            [110.347566450201029, -7.054228235688806],
            [110.347611763799591, -7.054400161043924],
            [110.347629745680976, -7.05449063987495],
            [110.347638698639102, -7.054563015350053],
            [110.347638610007863, -7.054626332822652],
            [110.347615670480934, -7.054843389674241],
            [110.347599610261284, -7.054997138537926],
            [110.347596193938926, -7.055214222660165],
            [110.347605611478443, -7.055358961748274],
            [110.347616160803355, -7.055503702417244],
            [110.347638682507608, -7.055585142220504],
            [110.347681601658039, -7.055648519780817],
            [110.34774970301909, -7.055711932518805],
            [110.347818936183188, -7.055775346829362],
            [110.347861855374802, -7.055838724376452],
            [110.347873637837935, -7.055911103800829],
            [110.347858515091872, -7.05600153638507],
            [110.347828679130927, -7.056091948414925],
            [110.347802829728451, -7.056164275269506],
            [110.347782613921026, -7.056254700733242],
            [110.347777947496738, -7.056354193289572],
            [110.347771916985351, -7.056417502457675],
            [110.347758736831864, -7.056535073858703],
            [110.347760590829409, -7.056625530152769],
            [110.347780270466387, -7.056716011351967],
            [110.347815512179565, -7.056806514292585],
            [110.347853634019231, -7.056860839773365],
            [110.347935059388107, -7.056906180371976],
            [110.348026117624244, -7.056942489041048],
            [110.34812286012604, -7.056960714889778],
            [110.348196105392972, -7.056987953281288],
            [110.348280900877299, -7.057051389272304],
            [110.348345619804562, -7.057105751851876],
            [110.348423894900023, -7.057178224089002],
            [110.348499044952902, -7.057259737324736],
            [110.348536022472203, -7.057323106533578],
            [110.348593063950631, -7.057404594483724],
            [110.34866283807186, -7.057486100195818],
            [110.348732058968437, -7.057558559755802],
            [110.348819646171677, -7.057649135674085],
            [110.348885497034644, -7.057703499769765],
            [110.348951347913243, -7.057757863857437],
            [110.349028491422885, -7.05783033443115],
            [110.349082433264329, -7.05790277263783],
            [110.349139757853663, -7.057984260929014],
            [110.349176735513254, -7.058047630098398],
            [110.349199553164709, -7.0581200248937],
            [110.349208468474416, -7.058219536420302],
            [110.349213718103343, -7.058309997463033],
            [110.349218710060512, -7.058382367401769],
            [110.349222287279176, -7.058454735367668],
            [110.349229259872175, -7.058527108067924],
            [110.349239382806488, -7.058572349044259],
            [110.349273235455343, -7.058644759224586],
            [110.349317286877493, -7.058708138250581],
            [110.349353403131076, -7.058780551582695],
            [110.349371693667251, -7.058852940061359],
            [110.349378075116363, -7.058943402681275],
            [110.349373421587686, -7.059033849916311],
            [110.349360229063166, -7.059160466734341],
            [110.349328028441434, -7.059323238535807],
            [110.349275248387883, -7.059431709403119],
            [110.349260408681161, -7.059522142428297],
            [110.349245493129828, -7.059666847577218],
            [110.349318844901561, -7.059820721188717],
            [110.349303916711136, -7.059974471692618],
            [110.349274338366229, -7.060082974904745],
            [110.349274136127491, -7.060227700569293],
            [110.349288621951303, -7.060390537460724],
            [110.349376737736947, -7.060508250173705],
            [110.349494343459156, -7.06058077713088],
            [110.349597147420752, -7.060716601031967],
            [110.349626346721877, -7.060879458432368],
            [110.349640668420591, -7.061159884927765],
            [110.349596427218017, -7.061232186227039],
            [110.349552122817855, -7.061349714291049],
            [110.349537295705929, -7.061431101962977],
            [110.349566659285259, -7.061476369762],
            [110.349610736254192, -7.06152165807261],
            [110.349669809567132, -7.061566967285475],
            [110.349728574674614, -7.061630366805988],
            [110.349787213469824, -7.061784219863863],
            [110.349786733407655, -7.06212794332585],
            [110.34978667023978, -7.062173170096786],
            [110.349727412298392, -7.062462539385163],
            [110.349727285944695, -7.062552992924582],
            [110.34982992612224, -7.062806406411429],
            [110.349844361603758, -7.063005424708744],
            [110.349858860260042, -7.063159216235468],
            [110.349902735270675, -7.063349230195763],
            [110.349917284480526, -7.063466840305543],
            [110.34991801973915, -7.06354817292984],
            [110.349828349453944, -7.063530033932149],
            [110.349485953558698, -7.063547647221041],
            [110.349333409838593, -7.063773568686265],
            [110.349104756742477, -7.064198382001722],
            [110.348894575517107, -7.064767946860536],
            [110.34870384967958, -7.065183767472877],
            [110.348475423198991, -7.065445764028902],
            [110.348474941916422, -7.065789487347064],
            [110.348512426898239, -7.066097082243757],
            [110.348531017414999, -7.066359423894922],
            [110.348416746837145, -7.06653112618559],
            [110.348340442522712, -7.066666700043251],
            [110.348207213952065, -7.066838375765211],
            [110.348035824662418, -7.066982861968418],
            [110.347826198570331, -7.067154430703096],
            [110.347769058691583, -7.067344303449222],
            [110.347635546754788, -7.067515978569813],
            [110.347559356217261, -7.067570144125806],
            [110.347483127597741, -7.0676514457113],
            [110.347330547093279, -7.06810350035298],
            [110.347158636521556, -7.068618845385657],
            [110.347063081477231, -7.068962435373646],
            [110.346986547711523, -7.069260825158276],
            [110.346909924910392, -7.069622532340297],
            [110.346890509628011, -7.069948138225886],
            [110.346757237982345, -7.069947951409962],
            [110.346282001337613, -7.069856831301276],
            [110.345559319771027, -7.069665864290458],
            [110.34254985899031, -7.0689830158018],
            [110.342513869260387, -7.068974849582829],
            [110.34260118659715, -7.068812701062589],
            [110.342671088204213, -7.068681865168245],
            [110.34271025151078, -7.068595559354257],
            [110.342760569443627, -7.068509269265797],
            [110.342780227842383, -7.068411792574903],
            [110.34276919523262, -7.06832541596561],
            [110.34270520172754, -7.068222249627611],
            [110.342618895047622, -7.068121837652526],
            [110.342546504128549, -7.06804094617585],
            [110.342418383415165, -7.067929331825193],
            [110.342173348011869, -7.067669902927055],
            [110.342028566492573, -7.067508119850302],
            [110.34189496333174, -7.067329637478677],
            [110.341711310395098, -7.067048008321448],
            [110.341510965206339, -7.066738497131661],
            [110.341374664510056, -7.06649593651692],
            [110.341305196798274, -7.06632033046086],
            [110.341283073001264, -7.066189364730413],
            [110.341266494950105, -7.066080693553726],
            [110.341266759205155, -7.065894042658196],
            [110.341314379114323, -7.065743674584446],
            [110.341392642508268, -7.065615636508794],
            [110.341482044653617, -7.065498757507499],
            [110.34159935681447, -7.065365202856068],
            [110.341680392936567, -7.065248311992949],
            [110.341719575775798, -7.065148077066781],
            [110.34175040844157, -7.065036686986673],
            [110.341750581829672, -7.064914110261875],
            [110.341720052615514, -7.064810991075753],
            [110.341541879374603, -7.064599015661263],
            [110.341355375872354, -7.064361955865211],
            [110.341160542148984, -7.064099811680802],
            [110.341043687771005, -7.06391020946062],
            [110.340951895658321, -7.063745715195088],
            [110.340804512501194, -7.063452993710858],
            [110.340651540423579, -7.063168621811896],
            [110.340537459302396, -7.062990166777188],
            [110.340428868673399, -7.062873007959745],
            [110.340356423629999, -7.06283111791356],
            [110.340211395382639, -7.062844841982517],
            [110.339977012625354, -7.062942014755844],
            [110.339745426308383, -7.063033619681992],
            [110.339525041849768, -7.063091810276803],
            [110.339399529949887, -7.063108347559083],
            [110.339315950246188, -7.063052512390511],
            [110.339274195991294, -7.062999522299815],
            [110.339221390064893, -7.06287408473322],
            [110.339252250792427, -7.062743194014406],
            [110.339319430816474, -7.062564995470632],
            [110.339406146949869, -7.062375681221684],
            [110.339476044323206, -7.062247631597931],
            [110.33949571486859, -7.062141797557652],
            [110.339501477875373, -7.062010871269325],
            [110.339490489399751, -7.061893850458121],
            [110.339462852219754, -7.061718303446721],
            [110.33945747656054, -7.061576218027865],
            [110.339477324917837, -7.061345021462128],
            [110.339552969241765, -7.061097188844916],
            [110.339636971410627, -7.060854939714406],
            [110.33968456746048, -7.060721286779927],
            [110.339737717001299, -7.06060435673409],
            [110.339802135498857, -7.060406653273795],
            [110.339838588855301, -7.060264627026962],
            [110.339863911446358, -7.060105869988416],
            [110.339858586975751, -7.05992756871037],
            [110.339822560188139, -7.059768724873316],
            [110.339728231357824, -7.059463525473603],
            [110.339663384198431, -7.059300617324181],
            [110.339638651524069, -7.059182992710426],
            [110.339639464149116, -7.0590970784222],
            [110.339653541455561, -7.059002379790053],
            [110.339689645414154, -7.058938840278075],
            [110.339724820673766, -7.058875572559791],
            [110.33981005460268, -7.058754661482408],
            [110.339854786714312, -7.058673935401774],
            [110.339888384152474, -7.058579264366383],
            [110.339913647362579, -7.058462294819664],
            [110.339992351810125, -7.058022243400542],
            [110.340057042064828, -7.05763231725529],
            [110.340107516939113, -7.057434593963874],
            [110.340174640452261, -7.05729539684085],
            [110.340647055879884, -7.056488170623904],
            [110.340769966573447, -7.056337908807925],
            [110.340889966434034, -7.056274003905898],
            [110.341037789082705, -7.056254711696104],
            [110.341210677486771, -7.056257741537665],
            [110.341377913904864, -7.056313694321394],
            [110.341690109665578, -7.056403281576925],
            [110.34200797716359, -7.05642601643583],
            [110.342229847197316, -7.056409726685159],
            [110.342380142684277, -7.05637375698049],
            [110.342468271072988, -7.05630130210129],
            [110.342529816544683, -7.056162096697344],
            [110.342543940297901, -7.056033967869553],
            [110.342524718947743, -7.055822216831568],
            [110.342483078873371, -7.055688437789125],
            [110.34242465228499, -7.055593636886299],
            [110.342304892635838, -7.055487606219137],
            [110.342118211018104, -7.05537869539473],
            [110.341524747065662, -7.055021271137134],
            [110.341349005298866, -7.054896494767195],
            [110.341204442495993, -7.054750592740453],
            [110.341029460477358, -7.054544343120895],
            [110.340821925047024, -7.054196346016256],
            [110.340646360125419, -7.053872373580711],
            [110.340526678234554, -7.053636405205597],
            [110.340458901336618, -7.053472448905935],
            [110.340421958310614, -7.05332476335934],
            [110.340405222075205, -7.053181843917019],
            [110.340392252601717, -7.053037049585826],
            [110.340428075304814, -7.052988039165421],
            [110.340520340897939, -7.052960141932004],
            [110.340659596443601, -7.052971619783922],
            [110.340773241401948, -7.053006617189618],
            [110.340858047867144, -7.053061009037919],
            [110.341073317320863, -7.053205349459517],
            [110.341272820039279, -7.053278682851876],
            [110.341507882796876, -7.053324241132295],
            [110.341720577421782, -7.053322835109667],
            [110.341865548307936, -7.053285435248642],
            [110.341922054907798, -7.05325355132636],
            [110.341937161733227, -7.053217848649642],
            [110.341927823157704, -7.053167069860982],
            [110.341916647660327, -7.053084324938685],
            [110.341896075197894, -7.052992165732962],
            [110.341821069371321, -7.052796518354837],
            [110.341744237632028, -7.052655217590422],
            [110.341696779703113, -7.05260087853908],
            [110.341594739183691, -7.052528371826122],
            [110.341407790021918, -7.052473836104352],
            [110.341232469569405, -7.052455370851034],
            [110.341027398369633, -7.05240431596206],
            [110.340907021905011, -7.052351500292366],
            [110.34079419491799, -7.052283653582718],
            [110.340651263233369, -7.05221012373114],
            [110.340495226443025, -7.052087689926715],
            [110.340483298680738, -7.052076977874793],
            [110.3403633201292, -7.051969227825444],
            [110.340288761755588, -7.051846954288203],
            [110.340207451934646, -7.051694807857353],
            [110.340185904049903, -7.051559034927553],
            [110.34020922175884, -7.051404321414723],
            [110.340263794487583, -7.051246937187092],
            [110.340376488800871, -7.051052259470135],
            [110.340411650481784, -7.050998036956444],
            [110.340436600625722, -7.050961890742401],
            [110.340471698322347, -7.05095289493072],
            [110.340546665361259, -7.050962046113713],
            [110.340681577903467, -7.050998417965783],
            [110.340865697193024, -7.051023472285876],
            [110.341038927882238, -7.05103050378184],
            [110.341151036527208, -7.051022177987338],
            [110.341256373008136, -7.050998571582466],
            [110.341358422862669, -7.050896908564392],
            [110.341426508796332, -7.05079180408183],
            [110.341452138663684, -7.050683246181719],
            [110.341442190094241, -7.050511857208853],
            [110.341369545735489, -7.05023687617871],
            [110.341313045909629, -7.05004050039022],
            [110.341193602294823, -7.04966947218004],
            [110.341098865924138, -7.049479729942748],
            [110.340970005937919, -7.04932344431827],
            [110.340786734008418, -7.049211198232676],
            [110.34061806788462, -7.049089757318134],
            [110.34025852438316, -7.048835979783607],
            [110.339984809296737, -7.048572316764154],
            [110.339833588528208, -7.048420814832433],
            [110.339828354589145, -7.048339396506854],
            [110.339898122035535, -7.048246172077628],
            [110.340001764962139, -7.048159364399176],
            [110.340235195143876, -7.048061560985472],
            [110.340521849367306, -7.047951565922506],
            [110.340789854344678, -7.04785983055825],
            [110.34107461245155, -7.047788790521481],
            [110.34139173138287, -7.04775621248776],
            [110.341711075504961, -7.047727817066841],
            [110.342025305195421, -7.047712988481141],
            [110.342215554830076, -7.047694591656462],
            [110.342439823131116, -7.047640610185706],
            [110.342667593430264, -7.047511975105838],
            [110.342958296016576, -7.047318950636297],
            [110.343257011710705, -7.047137641759528],
            [110.343597072866572, -7.046884609008083],
            [110.343888054310369, -7.046606973824476],
            [110.344084866093965, -7.046353739578433],
            [110.344201483020811, -7.04615330984364],
            [110.344296885297013, -7.045946436139563],
            [110.344436708511012, -7.045552978561133],
            [110.344520249956716, -7.045254441945147],
            [110.344672166218331, -7.044911189084271],
            [110.344832176005696, -7.044633369550603],
            [110.344959691552276, -7.044435531400667],
            [110.345038075642066, -7.044252388595736],
            [110.34511138172256, -7.043995895519203],
            [110.345155918269583, -7.043787634983634],
            [110.345173335865908, -7.043595272105442],
            [110.345168352240606, -7.043399088991266],
            [110.345149249463432, -7.043259950274797],
            [110.345023101493254, -7.042740485958749],
            [110.344986840093952, -7.042331547244248],
            [110.344963493810454, -7.041468760989073],
            [110.344976305585689, -7.041088513129191],
            [110.344998575911688, -7.040931728090137],
            [110.345087104445724, -7.040780838285472],
            [110.345250274394971, -7.040692833915105],
            [110.345467757340117, -7.040630357223116],
            [110.34565627237167, -7.040635711263928],
            [110.345776801574118, -7.040676602628942],
            [110.345887085744224, -7.040756505670139],
            [110.346015978672455, -7.040889035001665],
            [110.346134726420345, -7.040989311230008],
            [110.346290108900433, -7.04107399523448],
            [110.346413104802139, -7.041103384742327],
            [110.346543815136286, -7.041107060716416],
            [110.346649210881765, -7.041083353124303],
            [110.346724151417831, -7.041033430807384],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 130,
        id: 134,
        area: 5515925.8730640002,
        perimeter: 15530.591743000001,
        kode_pos: 50244,
        kode_kec: 3374160,
        kode_kel: 3374091010,
        kec: "Ngaliyan",
        kel: "Wonosari",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.29321434706381, -6.984644193346045],
            [110.293375498389381, -6.984378108882126],
            [110.29335213802041, -6.984260486950014],
            [110.293289567215012, -6.984131047564359],
            [110.293266242176827, -6.983989908297985],
            [110.293286079794171, -6.983840994587189],
            [110.293333271141307, -6.983762674019313],
            [110.293423566278889, -6.983715774542119],
            [110.293521771706651, -6.983625771686839],
            [110.29358862406211, -6.983516124022566],
            [110.293690905093527, -6.98332421854821],
            [110.293781417631038, -6.983132295420114],
            [110.293856244766246, -6.982936429217399],
            [110.293891826214889, -6.982752262938206],
            [110.293915674500624, -6.982544561698317],
            [110.293962953747865, -6.982407447696394],
            [110.294061176413024, -6.98230568601709],
            [110.294175007966103, -6.982258821582375],
            [110.294324056545861, -6.982270803285117],
            [110.294404927109738, -6.982226885325617],
            [110.294304814954359, -6.982072536927637],
            [110.294225486192772, -6.98195023134743],
            [110.294485796885454, -6.98166645224737],
            [110.294647986202662, -6.981416822471287],
            [110.294770918134702, -6.981186731766302],
            [110.294862890351624, -6.980907617596005],
            [110.294864509738844, -6.980902703130835],
            [110.29409506729796, -6.980632082363837],
            [110.293487499242332, -6.980322507197945],
            [110.292742870313234, -6.979855943324224],
            [110.293106763454432, -6.979165665326913],
            [110.293360376390851, -6.978516931483794],
            [110.293416871762943, -6.978372417814376],
            [110.293279591171739, -6.978357336129968],
            [110.293009951420188, -6.978327713313136],
            [110.292769674964092, -6.97833225277302],
            [110.292670198186514, -6.978335026970071],
            [110.292602950277953, -6.978336902360146],
            [110.292416692724601, -6.978287628592899],
            [110.292215805823574, -6.978184438808421],
            [110.292059057406277, -6.978076415699815],
            [110.291876866909618, -6.977994868341722],
            [110.291779818637266, -6.977904515846645],
            [110.291755588601291, -6.977713400746024],
            [110.291705747361931, -6.977596618479324],
            [110.291619315898586, -6.977032172016577],
            [110.291624860019098, -6.976605927751635],
            [110.291571157931401, -6.976449064604479],
            [110.291346086341733, -6.976125362777789],
            [110.291243364875143, -6.975958627113156],
            [110.29119801839191, -6.975669208023493],
            [110.291013935617485, -6.975272358981714],
            [110.290867179504943, -6.97504186422763],
            [110.290715453773316, -6.9748554570652],
            [110.290578327798656, -6.974742563614381],
            [110.290485288338843, -6.974659133100547],
            [110.290451200434447, -6.974502299343837],
            [110.290426838247456, -6.974399374197054],
            [110.290348501724111, -6.974320865211505],
            [110.290277483525841, -6.974269575350453],
            [110.290162010650079, -6.974190977363335],
            [110.290066482136538, -6.974135223713033],
            [110.289606761132376, -6.973564529446069],
            [110.289507335192965, -6.973254246958636],
            [110.289457509462551, -6.973173965211159],
            [110.289380867561661, -6.973132855321042],
            [110.289316766044038, -6.973056116828591],
            [110.289222312983512, -6.972997156384536],
            [110.289101097490899, -6.972941720389163],
            [110.288847947640818, -6.972841526411726],
            [110.288687493911979, -6.972782466491828],
            [110.288593035616486, -6.972727070672271],
            [110.288496925311691, -6.972584336081234],
            [110.288445289126088, -6.972521875249458],
            [110.288388724244896, -6.972480532534903],
            [110.288359760688891, -6.972459363351936],
            [110.288263505246206, -6.972412876631983],
            [110.28809945185796, -6.972375199580924],
            [110.287905065780137, -6.972342823866022],
            [110.287837300115598, -6.972330245093026],
            [110.287773142438269, -6.97229093616295],
            [110.287710825107254, -6.972214200177564],
            [110.287470983075877, -6.971855530458686],
            [110.287368498333038, -6.971719696489177],
            [110.287361989565227, -6.971713465344127],
            [110.287311719091619, -6.971665339082075],
            [110.287233679302162, -6.971609256202928],
            [110.287153451415961, -6.971580617189266],
            [110.286985498785072, -6.971566521317458],
            [110.286980444412464, -6.971566097114825],
            [110.286830624122871, -6.971553394354933],
            [110.286728971818022, -6.971537199533869],
            [110.286646954792303, -6.971512122481312],
            [110.286573902604346, -6.97145675862773],
            [110.286342442941987, -6.971169447122755],
            [110.286214254954857, -6.971007057608383],
            [110.286060023880012, -6.970849373001331],
            [110.285957782108667, -6.970746443742712],
            [110.285918456556033, -6.970699448931269],
            [110.285911487058385, -6.970691120242827],
            [110.285770645458456, -6.970639218565806],
            [110.285612384896169, -6.970605114230441],
            [110.285505363927058, -6.970600496469133],
            [110.285331373006258, -6.970647020574994],
            [110.285139564360989, -6.970680149866932],
            [110.285003563469957, -6.970671032212631],
            [110.284742615842887, -6.970715196525596],
            [110.284845941572982, -6.970221449774298],
            [110.284922928475993, -6.969710265840226],
            [110.285010720115878, -6.969253491881748],
            [110.285128790099876, -6.968905672698977],
            [110.285158359167966, -6.96881856587931],
            [110.285390761650362, -6.968298153714015],
            [110.285996304236704, -6.968419762912165],
            [110.286669328647505, -6.968553897607851],
            [110.286926926616445, -6.968593471947234],
            [110.286990635094114, -6.968601846773836],
            [110.287759566240354, -6.968706505655525],
            [110.288177209313048, -6.968772150034605],
            [110.288397363174127, -6.968819547235335],
            [110.288581348937697, -6.968873873353953],
            [110.288731783051375, -6.968947279450924],
            [110.28892689403142, -6.969073604709463],
            [110.289367948424186, -6.969344626376479],
            [110.289707381889983, -6.96955044633986],
            [110.290035195082382, -6.969753262053],
            [110.290528569081232, -6.970032758682916],
            [110.290608634572891, -6.97011482473703],
            [110.290735988525171, -6.970260697423414],
            [110.291324113486482, -6.9706166785989],
            [110.292210838375965, -6.971164313587065],
            [110.292818977451063, -6.971543996349292],
            [110.293248735624729, -6.971775908982366],
            [110.29359109705139, -6.97195305998412],
            [110.293911624433534, -6.972106504867428],
            [110.294148399686478, -6.972205193811122],
            [110.294441655617362, -6.972314893134413],
            [110.294651137461869, -6.972384404755578],
            [110.294900703087592, -6.972461260207725],
            [110.29515208584543, -6.972541760287966],
            [110.295547363233098, -6.972677105485367],
            [110.295884343150973, -6.972797795305685],
            [110.296092005714158, -6.9728654825958],
            [110.296195695272516, -6.972900251367012],
            [110.296236287215535, -6.972914045449133],
            [110.296418067335253, -6.972975229664862],
            [110.296733199649481, -6.973081318207814],
            [110.297061084094068, -6.973191067575601],
            [110.297356182955667, -6.973288020807575],
            [110.297603951556624, -6.973348483055192],
            [110.297917324334733, -6.97341268475157],
            [110.298165106653684, -6.973464041631655],
            [110.298496707204805, -6.973526449024325],
            [110.298879351065352, -6.973579826865116],
            [110.29907979766125, -6.97359833461732],
            [110.299236526217783, -6.973602209244125],
            [110.29959373118669, -6.973604560159916],
            [110.300186037190528, -6.973607259240182],
            [110.301093682416933, -6.973577645947435],
            [110.301441829030551, -6.973543561607202],
            [110.30178452433239, -6.973498542809812],
            [110.30214911910295, -6.973433524865491],
            [110.302526465831889, -6.973372167508003],
            [110.302803556784866, -6.97332340901309],
            [110.30309704470811, -6.973278316591625],
            [110.303397827926418, -6.973229592695679],
            [110.303662361331675, -6.973193443096587],
            [110.303990252224722, -6.973152162097333],
            [110.304396739636843, -6.973103593307357],
            [110.304797762219792, -6.97305319509152],
            [110.305311800473859, -6.972988394574779],
            [110.305731039773804, -6.972943485412846],
            [110.306015398837729, -6.972909303853659],
            [110.3062833474483, -6.972880561089928],
            [110.306704419841395, -6.97282836963914],
            [110.307134604617559, -6.972776191170952],
            [110.307495516077253, -6.972736657915799],
            [110.307914757807353, -6.972689925751116],
            [110.308248326532834, -6.97265399389338],
            [110.308601937257563, -6.972621733183353],
            [110.308895395183612, -6.97259666829624],
            [110.309527830534478, -6.972575740920052],
            [110.309963429191015, -6.97255998825281],
            [110.310451888007336, -6.972538849273982],
            [110.311215603762435, -6.972476227649113],
            [110.311811637217232, -6.972422465627215],
            [110.312332903443831, -6.9723995514516],
            [110.31251514433275, -6.972405279811136],
            [110.312666381692637, -6.972425531202329],
            [110.312795730628523, -6.972458497890409],
            [110.313070793831272, -6.972549949344196],
            [110.313333059951532, -6.972668697450378],
            [110.313531515190675, -6.972803741930143],
            [110.313744502774014, -6.972971585942183],
            [110.313893703293758, -6.973139337213105],
            [110.314150232330221, -6.973443821370337],
            [110.314328489764421, -6.973682634794352],
            [110.314481309223538, -6.973868601445084],
            [110.314657821246016, -6.974054602441103],
            [110.314868984658716, -6.974224264410313],
            [110.315047414709184, -6.974344711074157],
            [110.315198560292927, -6.974428697558317],
            [110.315488115296773, -6.974582083711784],
            [110.315806838411746, -6.974730048885561],
            [110.316273120820028, -6.974921932224885],
            [110.316763125186569, -6.975093818154991],
            [110.317107427447141, -6.975198114852969],
            [110.317847109988477, -6.975374002822784],
            [110.318215163243465, -6.975438270437287],
            [110.318376444476655, -6.975458973042222],
            [110.318657949773112, -6.975495361510837],
            [110.319142654025072, -6.97555251262904],
            [110.319658333120074, -6.975615170983571],
            [110.320879237612459, -6.975737116667858],
            [110.321688277757858, -6.975843899454369],
            [110.322355186490498, -6.975934087631395],
            [110.322878172486895, -6.975985826853279],
            [110.323193442567373, -6.976002668195068],
            [110.323561573226812, -6.976014121978024],
            [110.324091938387539, -6.976003955400676],
            [110.324605914066026, -6.975984659623993],
            [110.325057914083288, -6.975972558810906],
            [110.325376850238854, -6.97597483569157],
            [110.325861642408768, -6.975971886120386],
            [110.326215195851574, -6.97598149599064],
            [110.326692648649981, -6.976013134695289],
            [110.327148231460015, -6.976044741754273],
            [110.327660319930061, -6.976070965757481],
            [110.328199727973725, -6.976109975312887],
            [110.328664410694387, -6.976150699038127],
            [110.329081697922732, -6.976198639090741],
            [110.329517181874294, -6.976266635986832],
            [110.329799595834089, -6.976319846949178],
            [110.330021874790006, -6.976367509310784],
            [110.330265961826328, -6.976458907304057],
            [110.330451715667266, -6.976559327614282],
            [110.330602841866266, -6.976659698757374],
            [110.330763034148291, -6.976792861308255],
            [110.330964850854329, -6.976980516534175],
            [110.331265104538232, -6.97729176062113],
            [110.331189473011122, -6.977382855763439],
            [110.331171303361771, -6.977428056872909],
            [110.330992718536535, -6.977880072317015],
            [110.330840571339692, -6.978241671438016],
            [110.330732401642436, -6.978512879153179],
            [110.330622109977625, -6.978684584755317],
            [110.330552684159613, -6.978765894602321],
            [110.330244311837404, -6.978973500544506],
            [110.330227299337679, -6.979000612505405],
            [110.330158246296094, -6.979217603338136],
            [110.330084641073654, -6.979452678426656],
            [110.330044957936934, -6.979705892394166],
            [110.330008052403031, -6.979796293673659],
            [110.329912496642123, -6.979949929236052],
            [110.329713283808019, -6.980184825835683],
            [110.329541859300903, -6.980374534984192],
            [110.329443770041877, -6.980519121455814],
            [110.329321004050897, -6.980708899654301],
            [110.329141812060413, -6.980989051237636],
            [110.329046191326754, -6.981187913303538],
            [110.328783234039804, -6.981685034329338],
            [110.328503764746216, -6.982254494554764],
            [110.328329213209997, -6.982652242046328],
            [110.328211448042524, -6.982905344510595],
            [110.328184378868713, -6.983040986405945],
            [110.328186770110136, -6.983149534172669],
            [110.328215882095918, -6.983366664360657],
            [110.328245045720834, -6.98354761316771],
            [110.328259254300434, -6.983701404582316],
            [110.328244439148563, -6.983972744375938],
            [110.328246392608847, -6.984189835873481],
            [110.328251265427753, -6.984343613986837],
            [110.328285019012114, -6.984678340500592],
            [110.328321267115257, -6.985049252016843],
            [110.328347704008891, -6.985356832013929],
            [110.328362182616118, -6.985519669169575],
            [110.328381328597629, -6.985782011963559],
            [110.328403020712358, -6.986044358382989],
            [110.32843952704836, -6.986234363003065],
            [110.328512707563831, -6.986496782762917],
            [110.328573608167204, -6.986641595310357],
            [110.328641890098496, -6.986768327639386],
            [110.328800675348447, -6.9869132795612],
            [110.328932623560391, -6.987031057143048],
            [110.329025518250177, -6.987157824468002],
            [110.329113294931417, -6.987302675221265],
            [110.329147654436298, -6.987411268485699],
            [110.329169231703844, -6.98755602501087],
            [110.329193971716435, -6.987863602578501],
            [110.329198125010194, -6.987926926033384],
            [110.329209838548167, -6.98824353042161],
            [110.329197493122777, -6.988370147935965],
            [110.3291690092409, -6.988505787840461],
            [110.329087480317355, -6.988740851220279],
            [110.328961252366412, -6.988975850929132],
            [110.328745793115345, -6.989292131751542],
            [110.328521088077565, -6.989545081737995],
            [110.328362724653928, -6.989698627185506],
            [110.32815541853823, -6.989852102787583],
            [110.327960315984214, -6.98997845960942],
            [110.327875109821079, -6.990014519515505],
            [110.327424312495978, -6.990104329849725],
            [110.327395957301462, -6.990149516170207],
            [110.327403749309369, -6.990239980886137],
            [110.327448060347521, -6.990511404917259],
            [110.327467862100193, -6.990710431086581],
            [110.327479897706027, -6.990800901858943],
            [110.327463694478936, -6.990855150884229],
            [110.327402792142138, -6.990909336096339],
            [110.327085858275396, -6.991162153557147],
            [110.327020699324976, -6.991225377989461],
            [110.326980127284784, -6.991306728252994],
            [110.326918786581885, -6.991469457059867],
            [110.326823920481488, -6.991731636881005],
            [110.326775157025054, -6.991803930054282],
            [110.326449875412962, -6.992156233983619],
            [110.326112441288288, -6.99249947489463],
            [110.325873933280221, -6.992734315302437],
            [110.325672116918511, -6.992978248542361],
            [110.325441442982822, -6.993317765228182],
            [110.325312328467007, -6.99323321792227],
            [110.325167663728493, -6.993177916877253],
            [110.325038477630585, -6.993143298142201],
            [110.324933380908917, -6.993132817431442],
            [110.324824763744118, -6.993173982070098],
            [110.324641863574939, -6.993345887891492],
            [110.324303335672141, -6.993030558944633],
            [110.323787411268569, -6.992568505554265],
            [110.323726664422637, -6.992514146228845],
            [110.323165563797076, -6.992187707656921],
            [110.322289580437214, -6.991670863236059],
            [110.321652458582747, -6.99129004158044],
            [110.321559844759093, -6.991225933984873],
            [110.321445087454904, -6.991127767123309],
            [110.321281861896978, -6.990940072941528],
            [110.321132524908151, -6.990643514701142],
            [110.321112328946754, -6.990394087960484],
            [110.321150827520725, -6.990140559842588],
            [110.321101859453591, -6.990039281711353],
            [110.321044425671957, -6.989922105541101],
            [110.320994977231237, -6.989777155995271],
            [110.320961356202915, -6.989675891205534],
            [110.320917746382477, -6.989614304701511],
            [110.320851479553937, -6.989581474885086],
            [110.32073169343586, -6.989581302224613],
            [110.320105528044166, -6.989511958448539],
            [110.31970183756745, -6.989369169943599],
            [110.31940973972327, -6.989244275183385],
            [110.319237317166611, -6.989196151907312],
            [110.3189498308206, -6.989195736509054],
            [110.318783506240095, -6.989142155344513],
            [110.318564077431972, -6.989069475342975],
            [110.318215696890221, -6.988960427329601],
            [110.317985774605759, -6.988905822578476],
            [110.317622051950053, -6.988841978656215],
            [110.317187192398322, -6.98878519118322],
            [110.316743118825201, -6.988749227440814],
            [110.316654915684694, -6.988958166688838],
            [110.316575426646281, -6.989148003557391],
            [110.316439738511633, -6.989464393640264],
            [110.316291766966032, -6.989862173891672],
            [110.31599088817255, -6.989771283830398],
            [110.315441415616391, -6.989625760475019],
            [110.315066232554614, -6.989528939811085],
            [110.314958557055604, -6.989778829469389],
            [110.314534351284863, -6.989808970770659],
            [110.313856797666475, -6.989805204382969],
            [110.313011387597925, -6.989540815937649],
            [110.312667670443858, -6.989512936202535],
            [110.311947559367013, -6.989439362572777],
            [110.311958731230462, -6.989132240173217],
            [110.311927365612775, -6.988545034081774],
            [110.311451322005141, -6.987200475291775],
            [110.31007404032745, -6.986851960115725],
            [110.309474331170208, -6.986752750562449],
            [110.308543959085469, -6.985554338358021],
            [110.306639515288637, -6.98473937308687],
            [110.306563605355819, -6.984480935847706],
            [110.306432534507294, -6.984264765935915],
            [110.30620381753188, -6.983932885973529],
            [110.305605127217802, -6.982019080148524],
            [110.305660487784181, -6.981841298117551],
            [110.305839034511962, -6.981477364136069],
            [110.305780927560733, -6.980643013960708],
            [110.305713139396204, -6.980625974826846],
            [110.305743406835788, -6.980219474139017],
            [110.30584120807562, -6.979827143261948],
            [110.305219628789047, -6.97957360931908],
            [110.304719555671809, -6.979219206024841],
            [110.304042617522882, -6.97878875575416],
            [110.303577784882521, -6.97854050336897],
            [110.303295076416859, -6.978231890891223],
            [110.302942339683938, -6.977407832065055],
            [110.302274875363196, -6.977412002726344],
            [110.301541647123429, -6.977436075766878],
            [110.300707573890506, -6.977268112344099],
            [110.300379037291506, -6.977176682713266],
            [110.299899084033058, -6.976898090358016],
            [110.299383615856769, -6.976700282888165],
            [110.298908412079882, -6.976628844103561],
            [110.2983573688701, -6.976552239764494],
            [110.297745881571316, -6.976318921258843],
            [110.297207909965692, -6.976763629354275],
            [110.296966204041809, -6.977136709114315],
            [110.296868674585184, -6.977456575277354],
            [110.296848634732299, -6.97787837866581],
            [110.29694223965204, -6.978490887510962],
            [110.296972602984823, -6.978807518497741],
            [110.296955937973877, -6.979169305956954],
            [110.296833482906223, -6.979521890469456],
            [110.296508903133343, -6.980154578103066],
            [110.296095223142842, -6.980778087178499],
            [110.295062579428972, -6.982522288757892],
            [110.294345715026935, -6.983668785697398],
            [110.294049033664294, -6.984114417834985],
            [110.293291071248518, -6.984743607428379],
            [110.29321434706381, -6.984644193346045],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 131,
        id: 135,
        area: 1014814.066242,
        perimeter: 3902.874623,
        kode_pos: 50174,
        kode_kec: 3374100,
        kode_kel: 3374042007,
        kec: "Gayamsari",
        kel: "Tambakrejo",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.45156023917994, -6.957083067604896],
            [110.45140352010975, -6.957091915993363],
            [110.450754227048336, -6.957130317981358],
            [110.45011280568319, -6.957220029056122],
            [110.449514886302964, -6.957364063270563],
            [110.448036204901427, -6.957850802154588],
            [110.445218561711513, -6.958842520260974],
            [110.444859715552255, -6.95895969181321],
            [110.44408757960862, -6.959302516873159],
            [110.442728562229291, -6.959780333228903],
            [110.442519357273184, -6.959843342023445],
            [110.442382174757824, -6.959883977282639],
            [110.44094931667594, -6.955351221237851],
            [110.440630562654306, -6.954427551920126],
            [110.440408100304339, -6.953721239225252],
            [110.440292677151973, -6.953221730412277],
            [110.440235076475759, -6.952878343799875],
            [110.440173219614238, -6.95236106353792],
            [110.440191426748427, -6.95205789464764],
            [110.440308010319896, -6.951576494682032],
            [110.440531841245217, -6.950961460828515],
            [110.44086455691756, -6.950282192652514],
            [110.440983647833392, -6.950039401712261],
            [110.44123833057715, -6.949935623676315],
            [110.441352671520136, -6.949889199970844],
            [110.441777228195917, -6.949716825445981],
            [110.442144290165956, -6.949567793962554],
            [110.442635335911589, -6.949381107012401],
            [110.442956687166983, -6.94931014589852],
            [110.443233365435404, -6.949283718929228],
            [110.443554653647652, -6.949266261549362],
            [110.444036533567186, -6.949284661864752],
            [110.444330986689295, -6.949320676706221],
            [110.444768152117533, -6.949419280206518],
            [110.445356870617289, -6.94965182132635],
            [110.448224673716666, -6.951151032593085],
            [110.448750429119727, -6.951425880724465],
            [110.44977162587729, -6.951957651929191],
            [110.450137267242894, -6.95217209359467],
            [110.450449332915539, -6.952413224988896],
            [110.450761253931191, -6.952779199356243],
            [110.450929596371509, -6.953020572513828],
            [110.450988442351715, -6.953104947128944],
            [110.451133654097248, -6.95333285443597],
            [110.45118075326711, -6.953406775710099],
            [110.451255681236063, -6.953524373715001],
            [110.451478051362571, -6.954157765353837],
            [110.451584314252415, -6.954871278889381],
            [110.451597085113505, -6.955401877826975],
            [110.45156356176976, -6.956939990931715],
            [110.45156023917994, -6.957083067604896],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 132,
        id: 136,
        area: 4762187.5593250003,
        perimeter: 11326.78468,
        kode_pos: 50152,
        kode_kec: 3374150,
        kode_kel: 3374090006,
        kec: "Tugu",
        kel: "Karang Anyar",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.331265104538232, -6.97729176062113],
            [110.330964850854329, -6.976980516534175],
            [110.331142587592154, -6.976731522616857],
            [110.331768054798431, -6.975149468219527],
            [110.332654439230055, -6.973902460130386],
            [110.334004144355731, -6.972095289957442],
            [110.334387150200811, -6.971317927221366],
            [110.334508377874769, -6.970811556766735],
            [110.33460964957068, -6.970413702700172],
            [110.334767038320834, -6.97014256292021],
            [110.3356657855919, -6.968741793201915],
            [110.336474599242123, -6.967720801094799],
            [110.336773416079183, -6.967259905861274],
            [110.336898266554201, -6.966789720995108],
            [110.336998683052116, -6.966192866399616],
            [110.337312254393936, -6.964691771898563],
            [110.337786022623206, -6.963534625981191],
            [110.338035532407133, -6.962928934247136],
            [110.338336354386541, -6.962042907010977],
            [110.338662149099505, -6.960089558258337],
            [110.338838593306065, -6.958325953494511],
            [110.339251107565488, -6.957268218625019],
            [110.339761609150543, -6.956536254031465],
            [110.340135450194893, -6.955822189061583],
            [110.340263903857149, -6.953778108646326],
            [110.340459072507215, -6.9529141537233],
            [110.340880876576179, -6.951784216227086],
            [110.341496741568264, -6.95057379609065],
            [110.342711041431286, -6.948285222389974],
            [110.343906494879022, -6.948709721041858],
            [110.349842748228312, -6.950819176981129],
            [110.350380455628525, -6.951010247583903],
            [110.351728625862435, -6.951583945026368],
            [110.353006361893719, -6.952127665377208],
            [110.35521706609525, -6.95284150968479],
            [110.354951699333995, -6.953531259384259],
            [110.354192285471115, -6.955505138391223],
            [110.35327128630135, -6.956834128233095],
            [110.351898163609988, -6.958696788129706],
            [110.35096874437825, -6.959410102065417],
            [110.350747202653963, -6.959644978884026],
            [110.350485593152897, -6.959599392811954],
            [110.350042776800933, -6.959463103632082],
            [110.349942177273448, -6.959589601201722],
            [110.349991983810867, -6.959987667702855],
            [110.349911061874423, -6.960204646389879],
            [110.34977012110798, -6.960249679779448],
            [110.349226891473833, -6.960104206394469],
            [110.349126095937223, -6.960167385620047],
            [110.349045173578347, -6.96038436411757],
            [110.349022380448204, -6.960501923075872],
            [110.349017026689566, -6.960691869297939],
            [110.349021856921681, -6.960881829530673],
            [110.34900128946478, -6.961026527771541],
            [110.348944142023555, -6.961234493531484],
            [110.348861189252986, -6.961487650802647],
            [110.348649172407207, -6.961993901778909],
            [110.348529418691498, -6.962265099096402],
            [110.348394476133251, -6.962472957594289],
            [110.348291982627387, -6.962536134240315],
            [110.347911949101942, -6.962617019135551],
            [110.347724010248356, -6.96268912325021],
            [110.347609277372641, -6.962806555290475],
            [110.347572301723716, -6.962951230777828],
            [110.347551904092072, -6.96317733777739],
            [110.347531003141867, -6.963358217051277],
            [110.347498295765391, -6.963484807601446],
            [110.347291838901384, -6.963855384312207],
            [110.34705859462666, -6.964162606082738],
            [110.347004707765137, -6.964262031121731],
            [110.346807838457806, -6.964858755869928],
            [110.346626810517009, -6.965455502366863],
            [110.346572898354822, -6.965573018100017],
            [110.346249442551624, -6.966061022681762],
            [110.346122368663316, -6.966305072794678],
            [110.34596649690981, -6.966711900241967],
            [110.345798673630995, -6.966964939251101],
            [110.345687956839257, -6.967245193434258],
            [110.345638432528361, -6.967462214490626],
            [110.345621723731156, -6.967679280968004],
            [110.345650107772414, -6.967815001268463],
            [110.345748020799576, -6.967996044819256],
            [110.345919102917222, -6.968249552825623],
            [110.345955766194876, -6.968331012169557],
            [110.345955578130372, -6.968466692918913],
            [110.345898220517128, -6.96862038534706],
            [110.345734382024744, -6.96885533896265],
            [110.345660541185723, -6.969063280927069],
            [110.345631780748462, -6.969198922100517],
            [110.345660900619208, -6.969416052017137],
            [110.345639457756377, -6.969578839518812],
            [110.34559257950184, -6.969723500993105],
            [110.345477435881563, -6.969931385699338],
            [110.345362342362435, -6.97010308884001],
            [110.34513106779066, -6.970618356072385],
            [110.345082857353773, -6.971314784904059],
            [110.345042296885367, -6.971595136007939],
            [110.344954568215542, -6.971821149310674],
            [110.344779431194965, -6.972246040090659],
            [110.344752279627272, -6.972445001162222],
            [110.344750783563157, -6.972707315598176],
            [110.344713013952955, -6.973014806690291],
            [110.344545407513181, -6.973313072202208],
            [110.344240526787786, -6.973864418119772],
            [110.343927019940892, -6.974515251120137],
            [110.343815130527076, -6.97482263906489],
            [110.343658171185439, -6.975600324804324],
            [110.343638826596859, -6.975880705119511],
            [110.343601225346191, -6.976269604767744],
            [110.343562040001643, -6.976577093658318],
            [110.343655157019612, -6.976749085607844],
            [110.343868097035084, -6.977011698045422],
            [110.34412874641832, -6.977346739851717],
            [110.34407093280555, -6.978233108106859],
            [110.344032482840987, -6.978622006587241],
            [110.344003582570181, -6.978857146642433],
            [110.343958054161234, -6.979047036609432],
            [110.343751278605438, -6.979435700992977],
            [110.343716140441288, -6.980086920378334],
            [110.343603246378066, -6.981316936632411],
            [110.344014023072219, -6.981923549278372],
            [110.344028312445332, -6.982023068462653],
            [110.344047568054904, -6.982213048473502],
            [110.344042468937715, -6.982421085398976],
            [110.344027619452717, -6.98252056405939],
            [110.343993538315942, -6.982819014590397],
            [110.344007380972187, -6.983036123244543],
            [110.344267857031568, -6.983497800452225],
            [110.344882658812352, -6.984475557298046],
            [110.34509191233137, -6.984747209753584],
            [110.345284663505154, -6.984883158323334],
            [110.345803118189394, -6.985164285133691],
            [110.345852550852342, -6.985218626081817],
            [110.345888641353355, -6.985737094545005],
            [110.34570459654536, -6.985753847939215],
            [110.345134311063987, -6.985815808393927],
            [110.344661537657359, -6.985877903698885],
            [110.344159033103679, -6.985926746165857],
            [110.34353090348624, -6.985986972797088],
            [110.34303501671954, -6.986030869137303],
            [110.342522634719558, -6.986051622711186],
            [110.342198728353154, -6.986028051651257],
            [110.341909580545945, -6.985968198674402],
            [110.341405679625183, -6.985833733777655],
            [110.340133272621742, -6.985417732877067],
            [110.339586755685986, -6.98524032722301],
            [110.339118590863805, -6.985075778047693],
            [110.33863404651224, -6.984894816143858],
            [110.338300735486953, -6.984741381552916],
            [110.338062181689892, -6.984599005983614],
            [110.337721680821403, -6.984374540555221],
            [110.337479559195131, -6.984177528659847],
            [110.337290273570318, -6.983993338155055],
            [110.337053712261735, -6.983730776628569],
            [110.336820819379952, -6.983451830849587],
            [110.336546075078388, -6.983125479202354],
            [110.336276796197964, -6.982800956151698],
            [110.335965653270335, -6.982438132315548],
            [110.335619946882176, -6.982029733769687],
            [110.335281541496244, -6.981614061169146],
            [110.334792105808063, -6.98102881924829],
            [110.334508269644232, -6.98068970658459],
            [110.334129798883367, -6.980253945428081],
            [110.333851410624405, -6.979929408406825],
            [110.333502067956289, -6.97951736140734],
            [110.333287376072818, -6.979258471304293],
            [110.333036270074743, -6.978974035185494],
            [110.332794235719206, -6.978718748323946],
            [110.332437437072045, -6.978425057334825],
            [110.332171682195337, -6.978189768023076],
            [110.331718484636369, -6.977759362639983],
            [110.331319934928246, -6.977349065677041],
            [110.331265104538232, -6.97729176062113],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 133,
        id: 137,
        area: 1661080.173624,
        perimeter: 7394.226882,
        kode_pos: 50216,
        kode_kec: 3374010,
        kode_kel: 3374010003,
        kec: "Mijen",
        kel: "Karang Malang",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.326885491060978, -7.102120444652604],
            [110.32685401251689, -7.101868126255824],
            [110.326881479806772, -7.101469746709315],
            [110.326887211803708, -7.101349612979866],
            [110.326911746000178, -7.101211905687338],
            [110.326943516117353, -7.101035661535661],
            [110.326977966176273, -7.100890986447956],
            [110.327025768499695, -7.100710149455298],
            [110.327090937252109, -7.100456975104745],
            [110.327292767384307, -7.099824095552962],
            [110.32732382155065, -7.099679415490874],
            [110.327392372465667, -7.099435291278555],
            [110.327409547825795, -7.0992996364055],
            [110.327416523065821, -7.099173012065389],
            [110.327416706987862, -7.099046377881947],
            [110.327396648351808, -7.098829261182487],
            [110.327389949004711, -7.09876593424799],
            [110.327376458352816, -7.0987025974711],
            [110.327169256041159, -7.097969626401311],
            [110.326982750532792, -7.097209549330546],
            [110.326972668746464, -7.09713717217143],
            [110.326983249924297, -7.096865827975625],
            [110.326976524354208, -7.096820591635624],
            [110.326936013248812, -7.096657717181074],
            [110.32689516662667, -7.096531023509688],
            [110.326827141794737, -7.096413335746838],
            [110.326758472186796, -7.096349918948048],
            [110.326625917214841, -7.096241182898753],
            [110.32643158290864, -7.096195674413282],
            [110.326230792970051, -7.096113975214037],
            [110.326203680526461, -7.096077754600207],
            [110.326189880803355, -7.096032507989006],
            [110.326173007886865, -7.095960120967],
            [110.326173099976188, -7.095896803882876],
            [110.326180246429431, -7.095652590698171],
            [110.32619424346349, -7.095562157840925],
            [110.326214788239994, -7.095444598532723],
            [110.326293486310348, -7.095227625110944],
            [110.326402863269081, -7.094929288312802],
            [110.326440451810242, -7.094766527118173],
            [110.326464753946937, -7.094594701328642],
            [110.326820254395614, -7.094531899580456],
            [110.326874689460993, -7.094459615938317],
            [110.326898807472986, -7.094414424302935],
            [110.326897201632534, -7.09435110474428],
            [110.326905578513234, -7.094233527741692],
            [110.326929439815373, -7.094170245093117],
            [110.327004945825635, -7.094007538776197],
            [110.327064204387881, -7.09392621677237],
            [110.327141289918629, -7.093844920586681],
            [110.327203687346497, -7.093745512468798],
            [110.327272619269664, -7.093628023165304],
            [110.327320815726779, -7.093564775735153],
            [110.327325678696312, -7.09352860149911],
            [110.327207857682708, -7.093211844651476],
            [110.327179100022803, -7.093139440436865],
            [110.327169597300013, -7.093058018793988],
            [110.327171360759309, -7.093012794749779],
            [110.327214851746092, -7.092877177949268],
            [110.32725505212268, -7.092669193815527],
            [110.327270837648413, -7.09257873407883],
            [110.327367560814565, -7.092551767586229],
            [110.327431563927789, -7.092515678979677],
            [110.327479437896585, -7.092479567011974],
            [110.32756416321179, -7.092398281782674],
            [110.327722578034681, -7.092235695297508],
            [110.327939619382761, -7.092037012280677],
            [110.328086649719054, -7.091919635784524],
            [110.328396207828021, -7.091730131646726],
            [110.328567211531663, -7.091667061570337],
            [110.328925256265478, -7.091604261645605],
            [110.329032217728766, -7.091604416139512],
            [110.329121892037023, -7.091622636292693],
            [110.329192015462482, -7.091658918847223],
            [110.329253906684187, -7.09171328015445],
            [110.329354137482056, -7.091867195369404],
            [110.329459501004251, -7.091993982003861],
            [110.329571155271822, -7.092075551075832],
            [110.329649511013557, -7.092093754801949],
            [110.329726195117757, -7.092093865454005],
            [110.329863782549353, -7.092048837330498],
            [110.330058272497496, -7.091985800578088],
            [110.33053614310667, -7.091832718942689],
            [110.330621046287789, -7.091823795960885],
            [110.330689794224909, -7.091832940341029],
            [110.330750323061636, -7.091851118197356],
            [110.33085577838979, -7.091914587394671],
            [110.331067242018662, -7.092050571848363],
            [110.331150343025286, -7.09211400879582],
            [110.33118877437181, -7.092150245437598],
            [110.331279720204805, -7.0922679656241],
            [110.331429366754634, -7.092494314218051],
            [110.331474293361566, -7.092539605516794],
            [110.33154439104743, -7.092593978363093],
            [110.331606617830317, -7.092612158562935],
            [110.33166516603977, -7.092630333462124],
            [110.331690903051168, -7.092639415820106],
            [110.331775497331193, -7.092648582851087],
            [110.331884156903882, -7.092648739153351],
            [110.331949509392501, -7.092657878476444],
            [110.332034360582753, -7.09268513648783],
            [110.332273054997344, -7.092775932965628],
            [110.332614619556523, -7.092957330441421],
            [110.332948531245734, -7.093147762028702],
            [110.333206350821811, -7.093319993504585],
            [110.333492145428721, -7.093519400992817],
            [110.334128844163757, -7.093900218196382],
            [110.334334946332774, -7.094027148302816],
            [110.334574348009596, -7.094217443370088],
            [110.334769118713496, -7.094353402402302],
            [110.334833004755168, -7.094398720577617],
            [110.33492234502971, -7.094453120533448],
            [110.335029216421418, -7.094516590910986],
            [110.335079841907728, -7.094534754067634],
            [110.335209633833017, -7.09459825722524],
            [110.335363735188722, -7.094679885810789],
            [110.335481063585775, -7.094752416394677],
            [110.335659461491687, -7.094861215661964],
            [110.335752763531673, -7.094915621141903],
            [110.33586211705564, -7.095024321570329],
            [110.335963560450935, -7.095123965330643],
            [110.336068669531684, -7.095232659648613],
            [110.336141816064483, -7.095332262915873],
            [110.336170022301204, -7.095395620586748],
            [110.336214588947158, -7.095495182985357],
            [110.336238807709847, -7.09557662562309],
            [110.336266988056082, -7.095658073923151],
            [110.336307374983875, -7.095712403665327],
            [110.336368148702945, -7.095757717208837],
            [110.336441129001628, -7.095775912184579],
            [110.336550869479296, -7.095812250349019],
            [110.336677318149057, -7.095839567023787],
            [110.336742118248722, -7.095839659595969],
            [110.336782325866949, -7.095821626359384],
            [110.336877172556854, -7.095785580486227],
            [110.336982219091595, -7.095740503821208],
            [110.337132013660138, -7.095668355006979],
            [110.337257189817123, -7.095596171001407],
            [110.337331932643181, -7.095569141668803],
            [110.337551813067435, -7.095560410089891],
            [110.337686815498145, -7.095542512004547],
            [110.337791836057804, -7.095515525782303],
            [110.337981503327427, -7.095461524215183],
            [110.338206567609973, -7.095389482340842],
            [110.338431348861945, -7.095317439947534],
            [110.338636322179966, -7.095245369226497],
            [110.338846080110812, -7.095191395901236],
            [110.339010832935472, -7.095146403754823],
            [110.33910310682171, -7.095128444415766],
            [110.339212384675406, -7.095092418574374],
            [110.339313404807569, -7.095092562319421],
            [110.339448690049281, -7.095074664105118],
            [110.339607165895885, -7.095065844173623],
            [110.339739608541549, -7.095056987171577],
            [110.339895254690077, -7.095048163117125],
            [110.339990332465504, -7.095048298266536],
            [110.340050862188249, -7.095066474981171],
            [110.340114234494294, -7.095075610385782],
            [110.340191999612315, -7.095111902260249],
            [110.340281083529447, -7.095148210204092],
            [110.340338719194591, -7.095211609484129],
            [110.340399184572703, -7.095275012777954],
            [110.340442105841191, -7.095338391145159],
            [110.340488975823234, -7.095410820460575],
            [110.340512938134808, -7.095474171894027],
            [110.340533723410402, -7.095582745527945],
            [110.34055138314902, -7.095700360064894],
            [110.340575242487049, -7.095836074091792],
            [110.340589776694088, -7.09596272953138],
            [110.340610574873367, -7.096062257838906],
            [110.340634511480275, -7.096143699917739],
            [110.340688147106263, -7.096234229514692],
            [110.340732740571639, -7.096315700924353],
            [110.340810402981276, -7.096424355313867],
            [110.340840346369262, -7.096460579200072],
            [110.34089097234758, -7.096478741762337],
            [110.340962551016105, -7.096487888720157],
            [110.341031312849893, -7.096487986325744],
            [110.341076330892747, -7.096469959535494],
            [110.341118249121806, -7.096442882997875],
            [110.341153941995728, -7.096415797621505],
            [110.341187113858069, -7.096370617977448],
            [110.341216864343409, -7.096343524162786],
            [110.341255708464203, -7.096289307217639],
            [110.341303041686814, -7.096235102312402],
            [110.341373900014517, -7.096153794742638],
            [110.341505969893561, -7.096009256569861],
            [110.341562641074717, -7.095955064875931],
            [110.341581625788905, -7.09593700110988],
            [110.341671134345972, -7.095873810621699],
            [110.341793776086718, -7.095792576395757],
            [110.341883310285965, -7.095711295207387],
            [110.341935209438731, -7.095629960660587],
            [110.34199191906238, -7.095548632923218],
            [110.342025167850551, -7.095449181241258],
            [110.342068041872153, -7.095349743193714],
            [110.34207909340148, -7.095093658575143],
            [110.342066268368896, -7.094856994921479],
            [110.342010557965224, -7.094699152330467],
            [110.3419186827619, -7.09452153803054],
            [110.341859626167661, -7.094403131598659],
            [110.341853181025087, -7.094307806924596],
            [110.341896089719199, -7.094212552191795],
            [110.341971882277235, -7.094130491019133],
            [110.342080540025634, -7.09407477033495],
            [110.342212131561595, -7.0940881037098],
            [110.34244892733247, -7.094160747393505],
            [110.342665958338543, -7.094249796704053],
            [110.342856658055879, -7.094345382147902],
            [110.343044034981929, -7.094463970024099],
            [110.343231393374623, -7.094595704780975],
            [110.343429705137325, -7.094701935069806],
            [110.343562969298489, -7.094842103590324],
            [110.343676998385249, -7.094986806251471],
            [110.34376990202081, -7.095070657314843],
            [110.343818930449544, -7.095151185335283],
            [110.343881877745332, -7.095219976617315],
            [110.343964803643303, -7.095346048064522],
            [110.344064842521959, -7.095529395621342],
            [110.344127680491951, -7.095675476849715],
            [110.344233260397772, -7.095993374281707],
            [110.344243733422573, -7.096175435799235],
            [110.344239530926998, -7.096345597210542],
            [110.344450144170779, -7.096470993374302],
            [110.34461408433836, -7.09665814099808],
            [110.344714299421469, -7.096789494173195],
            [110.344798771683188, -7.096924978387456],
            [110.34485300039691, -7.097037594568136],
            [110.344857850794355, -7.097104795102394],
            [110.344856392455412, -7.097156929339245],
            [110.344883793800378, -7.097239433254283],
            [110.344922369783276, -7.097387167714762],
            [110.34487242429519, -7.097600715704961],
            [110.344833978087976, -7.097783762990223],
            [110.344784452806977, -7.098025467386739],
            [110.344726889974709, -7.09830639804492],
            [110.345184035964834, -7.099203479705195],
            [110.345187750722545, -7.09932378594496],
            [110.345140731674064, -7.099607009041472],
            [110.345159854189703, -7.099820473275747],
            [110.344771158229292, -7.099967390326945],
            [110.343826836978963, -7.100175612479999],
            [110.343765223471166, -7.09979133840755],
            [110.343719039513275, -7.099484699674763],
            [110.34357137588924, -7.099287446464042],
            [110.34355618308102, -7.099267151473289],
            [110.342596946028195, -7.099036833788816],
            [110.342701495862556, -7.099277583755524],
            [110.342742967732846, -7.099435918321291],
            [110.342778774606145, -7.099572624541697],
            [110.34273593088156, -7.099650177371874],
            [110.342463912862073, -7.099704121474096],
            [110.342246255413784, -7.099777545841254],
            [110.34207908468403, -7.099862683707364],
            [110.341942981839097, -7.099955626915742],
            [110.341876751485671, -7.100087475929771],
            [110.341861046515106, -7.100203873896446],
            [110.341903625685248, -7.100312593178177],
            [110.341856659666021, -7.100557009068297],
            [110.341759399821342, -7.100653887966716],
            [110.341611625483907, -7.100758456513048],
            [110.341386070696487, -7.100921124716854],
            [110.341215042135289, -7.100986853369108],
            [110.341036177486572, -7.101099138911366],
            [110.340892276686986, -7.101211474048054],
            [110.340768135894692, -7.1013284433409],
            [110.340748342661655, -7.101347093127962],
            [110.340689953363821, -7.101428504301103],
            [110.340588835852515, -7.101505974062026],
            [110.340534347974824, -7.10157574873556],
            [110.340514741115314, -7.10170378308787],
            [110.340002074616393, -7.101606037205535],
            [110.33945811890662, -7.101655711788335],
            [110.338890460336913, -7.101980879669026],
            [110.33860392465148, -7.10216589856045],
            [110.338492696064648, -7.102237719853662],
            [110.338392734553381, -7.102302265851512],
            [110.33823301026797, -7.102612491745563],
            [110.338077181747536, -7.10291496177771],
            [110.338037750023332, -7.103322375992306],
            [110.337967549871863, -7.10351242868649],
            [110.3378702608494, -7.103628709940653],
            [110.337823508795623, -7.103721779299447],
            [110.337580061724367, -7.103875421580033],
            [110.337547430926165, -7.103896015238268],
            [110.337294684502453, -7.10405476165004],
            [110.33721279585626, -7.104268081506267],
            [110.337115222963362, -7.104582276349662],
            [110.336831474618791, -7.104686648757923],
            [110.336683781386768, -7.104733005563888],
            [110.336578783168264, -7.104806588091928],
            [110.336427227231624, -7.104837416604837],
            [110.336248632788752, -7.104759547615151],
            [110.336093336715436, -7.104689473256772],
            [110.335957409533037, -7.104658233280428],
            [110.335821454521664, -7.104646396572783],
            [110.335778937802232, -7.104494989573038],
            [110.335666520698624, -7.10432407909703],
            [110.335577413085801, -7.104153201995312],
            [110.335531039438521, -7.103982386109357],
            [110.335523074880072, -7.103972096157826],
            [110.335417495603409, -7.103835691100087],
            [110.33520180207131, -7.103772722498023],
            [110.334909231700948, -7.103687310633436],
            [110.334352348644742, -7.103483877737585],
            [110.334300561727801, -7.103464959608039],
            [110.333929305869233, -7.103332096878989],
            [110.333878034260294, -7.103313748087115],
            [110.333761336512708, -7.103250306275172],
            [110.333529468658895, -7.103124253007945],
            [110.33333808848711, -7.103061964209457],
            [110.333320263932052, -7.102645560418968],
            [110.333345003694845, -7.10234507228702],
            [110.333398353564434, -7.10206308919627],
            [110.333369270465212, -7.101833873850182],
            [110.333251904303211, -7.101487618051327],
            [110.333134426208815, -7.101133870503687],
            [110.333058598820244, -7.10090315269942],
            [110.333038038397092, -7.100840594058024],
            [110.332946269833116, -7.100766681878901],
            [110.332883925913009, -7.100716468855254],
            [110.33270865374908, -7.10058022501807],
            [110.332587850362287, -7.100434993353102],
            [110.332509471099328, -7.100241470053456],
            [110.332512692454813, -7.100105482890197],
            [110.332564371008246, -7.099933300945877],
            [110.332603843752722, -7.099833630397722],
            [110.332595556568037, -7.099791927172083],
            [110.332582819056952, -7.099727828758088],
            [110.332540621583433, -7.099618974625331],
            [110.332453053264118, -7.099504011147334],
            [110.332362455325836, -7.099392065337646],
            [110.332253718682736, -7.099271027295338],
            [110.332205435851961, -7.099186340704082],
            [110.332193452307052, -7.099104728383531],
            [110.33218749322063, -7.099041256971044],
            [110.331940620334578, -7.099105678895442],
            [110.3317728698959, -7.09914945358743],
            [110.331385413047585, -7.099299997506542],
            [110.331013051930725, -7.099471717113599],
            [110.330791990744103, -7.099619478295277],
            [110.330706819191533, -7.099939691587166],
            [110.330658051688559, -7.100190450469507],
            [110.330627387296317, -7.100478244738101],
            [110.330572143208087, -7.10088581196643],
            [110.330504647948231, -7.101083181148997],
            [110.330351514614122, -7.101202874642195],
            [110.330233325177389, -7.101258793072466],
            [110.3301210646611, -7.101231553534784],
            [110.330032122545887, -7.101146324508492],
            [110.329927543062311, -7.101111778775065],
            [110.329867807892924, -7.101092046448208],
            [110.32974718159781, -7.101144856591413],
            [110.329710984077735, -7.101160703842271],
            [110.329604399253299, -7.101267062264868],
            [110.329528424563151, -7.101342875525733],
            [110.329281374600598, -7.101862171225264],
            [110.329007584400941, -7.102359267880421],
            [110.328775711135677, -7.102440340295892],
            [110.328320605098313, -7.102401734528126],
            [110.327991520072231, -7.102376209216796],
            [110.32785668247935, -7.102413586900167],
            [110.327687306361128, -7.102491618608081],
            [110.327633879038402, -7.102588604845746],
            [110.32760857568806, -7.102745122481794],
            [110.327545940448118, -7.102707458649093],
            [110.32741757645806, -7.102603946700107],
            [110.32706074075152, -7.102262140800377],
            [110.326885491060978, -7.102120444652604],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 134,
        id: 138,
        area: 2106591.475021,
        perimeter: 7201.539474,
        kode_pos: 50184,
        kode_kec: 3374160,
        kode_kel: 3374091007,
        kec: "Ngaliyan",
        kel: "Purwoyoso",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.368149026703321, -6.987748722601787],
            [110.368807301120569, -6.989978453260526],
            [110.369054661278156, -6.990893420223744],
            [110.369142713720592, -6.991224464228296],
            [110.369192244103374, -6.991473642901578],
            [110.369255325089256, -6.991926043367074],
            [110.36925916834997, -6.992224547769147],
            [110.369246012787642, -6.992541120268337],
            [110.369195452490246, -6.992902869825023],
            [110.369153451385543, -6.993121687248122],
            [110.369113026337871, -6.993332293482251],
            [110.369050396400269, -6.993555784924141],
            [110.368942329315743, -6.993870030974978],
            [110.368786866361447, -6.99433133594414],
            [110.368715206982387, -6.994540519654167],
            [110.368672059290901, -6.994709476032376],
            [110.368631382100205, -6.99491879732055],
            [110.368623152794072, -6.995073208864214],
            [110.368633428772384, -6.995238423421282],
            [110.368648876205256, -6.995461334072219],
            [110.36869320340864, -6.995676416743665],
            [110.36875586674185, -6.995917746343377],
            [110.368829045638549, -6.99614597886343],
            [110.368904887808213, -6.996345370366206],
            [110.369035671551387, -6.996673324999197],
            [110.36915077898324, -6.99694881390641],
            [110.369305232644038, -6.997240088922944],
            [110.369428329923622, -6.997429054742363],
            [110.369588145445661, -6.997636425360184],
            [110.369742750826006, -6.997814944374648],
            [110.369926186989176, -6.998022346527829],
            [110.370130594605186, -6.998248132333566],
            [110.370313978788758, -6.998467307477925],
            [110.370426949930305, -6.998612185520477],
            [110.370537350684884, -6.998775150966425],
            [110.370655883284783, -6.998992399857671],
            [110.370781096862927, -6.999291066546765],
            [110.370854353843697, -6.999544647068688],
            [110.370913327144081, -6.999843014687025],
            [110.371026186292355, -7.000494436659577],
            [110.371099223103442, -7.00088348786174],
            [110.371161715159147, -7.001118752621798],
            [110.371263237357311, -7.001363114973594],
            [110.371401809363178, -7.001616572251242],
            [110.371582511608381, -7.001888176646917],
            [110.371764442529027, -7.002087419169903],
            [110.371987703401757, -7.002268625951277],
            [110.372089468161676, -7.002332079838869],
            [110.372335613584127, -7.002473246427674],
            [110.372576948237381, -7.002572248864501],
            [110.372576980159167, -7.002572259594836],
            [110.372478510401152, -7.002730598090051],
            [110.372298220746956, -7.002784630118402],
            [110.372168338384995, -7.002802547630925],
            [110.372060806334318, -7.002820494946394],
            [110.371977592905125, -7.00284752014108],
            [110.371725491055059, -7.00286527431885],
            [110.37161628570118, -7.002865128430882],
            [110.371442304829159, -7.00285585052499],
            [110.371387690045708, -7.002864822966735],
            [110.371341291918867, -7.002864760953025],
            [110.371302484060351, -7.002900890806102],
            [110.371274131954735, -7.002946080063666],
            [110.371236197057556, -7.002964120214602],
            [110.37120056182377, -7.002955027143204],
            [110.371164655790892, -7.002936888276052],
            [110.371129339822588, -7.00290065933321],
            [110.371063198198613, -7.002855343738203],
            [110.370963438134225, -7.002773801451546],
            [110.370891650358757, -7.00271943285331],
            [110.37069125739292, -7.002574437891315],
            [110.370668106841521, -7.002538225195169],
            [110.370665645470254, -7.002474903888866],
            [110.370642765716326, -7.002447736984664],
            [110.37051994016511, -7.002266664021576],
            [110.370452998455249, -7.002185165561107],
            [110.370361738215976, -7.002094589111962],
            [110.370248447047558, -7.001976846864302],
            [110.370166523075042, -7.001886282882317],
            [110.370101278967525, -7.00180478665358],
            [110.370044017163238, -7.001678073958643],
            [110.37001920561859, -7.001614722724163],
            [110.369997186820896, -7.001578511517872],
            [110.369921450673786, -7.001515092077461],
            [110.369821100988361, -7.001451639655496],
            [110.369727775632896, -7.001424378342351],
            [110.369657329935222, -7.001424283956298],
            [110.369616614538913, -7.001406138541181],
            [110.369561814139374, -7.001342747102547],
            [110.369496865269696, -7.001252205770719],
            [110.369434943466189, -7.00122498649101],
            [110.369216089283753, -7.001134238813775],
            [110.368922658598152, -7.001170027002447],
            [110.368794498443094, -7.001169855067085],
            [110.368746144423696, -7.001151699332508],
            [110.368632780940146, -7.001088229214922],
            [110.368511495892875, -7.001024748439683],
            [110.368377208996932, -7.000952204753297],
            [110.368274337770345, -7.000870657789582],
            [110.368196928838756, -7.000789145000283],
            [110.368095803843474, -7.000671418640826],
            [110.368038457517173, -7.000608023629034],
            [110.367946300633321, -7.000553627275206],
            [110.367856111994186, -7.000508278975992],
            [110.367814018713702, -7.000462995286917],
            [110.367730115090652, -7.000372428279579],
            [110.36765355507687, -7.000290916555532],
            [110.367602101328217, -7.000263711122567],
            [110.367547523356436, -7.000245546910967],
            [110.367475404745747, -7.000227359113325],
            [110.367318177887498, -7.000172875168627],
            [110.367175404032835, -7.000100319762391],
            [110.367072995446605, -7.000043412808591],
            [110.367016799260895, -7.000018697594791],
            [110.366957153284758, -6.999982435651037],
            [110.36692832049728, -6.999964306008867],
            [110.366896104942953, -6.999937126388588],
            [110.366894726951102, -6.99990998825947],
            [110.366903853328139, -6.999855727988732],
            [110.36692091346643, -6.99979243296678],
            [110.366917972092835, -6.999665793057693],
            [110.366899090052755, -6.999611495102528],
            [110.366884710244648, -6.999575294054703],
            [110.366875459449588, -6.999511963632195],
            [110.366868189047977, -6.999448635874128],
            [110.366882824862756, -6.999294883337682],
            [110.366885210073917, -6.999204432294315],
            [110.366864076938995, -6.999141085884835],
            [110.366837839185962, -6.999086778031898],
            [110.366801133663103, -6.999032456093251],
            [110.366732754106437, -6.998969046105466],
            [110.366651065473803, -6.998914663622582],
            [110.366593682964577, -6.998878404692208],
            [110.366507492156131, -6.998805925277805],
            [110.366363401880349, -6.998661004500285],
            [110.366289671501434, -6.998579496403877],
            [110.366286903380995, -6.998534265554047],
            [110.3662671605111, -6.998489011846782],
            [110.366222472539548, -6.998479906244781],
            [110.366172114117504, -6.998479838426259],
            [110.366124572616741, -6.998488819820624],
            [110.365984482111742, -6.998524812811648],
            [110.365858573842146, -6.998533688597814],
            [110.36573268999409, -6.998524473535674],
            [110.365492251036457, -6.998497013162273],
            [110.365433417463493, -6.998487888415055],
            [110.365374559456541, -6.998496854473648],
            [110.365327017946058, -6.998505835785907],
            [110.365282268897815, -6.998541957127661],
            [110.36524852898782, -6.998596184158617],
            [110.365226081127773, -6.998668517262613],
            [110.365137646562118, -6.99941916802667],
            [110.365137340940734, -6.999645303168237],
            [110.365202093135935, -6.99988057150339],
            [110.36524840534905, -6.999943951936139],
            [110.365265884858758, -6.999989202626891],
            [110.365251407384378, -7.000025364785516],
            [110.365164811445879, -7.000043338802056],
            [110.364509854803131, -7.000051500095772],
            [110.364273007375829, -7.000087361832044],
            [110.364012567885837, -7.000204600337643],
            [110.363803409123776, -7.000267635511555],
            [110.363566573820634, -7.00029445147456],
            [110.363423431885636, -7.000285212412853],
            [110.363176177828493, -7.000275832406277],
            [110.362984411618328, -7.000239391143321],
            [110.362780480171438, -7.000202933329514],
            [110.362582748306167, -7.000184574663863],
            [110.362471231353453, -7.000220605245666],
            [110.36234288926417, -7.000356112797178],
            [110.36224994401033, -7.000256487024886],
            [110.362176804570595, -7.000156888297827],
            [110.361947041830774, -6.999975668450581],
            [110.361681632230699, -6.999794400149726],
            [110.361467405486877, -6.999631292004746],
            [110.360921629828425, -6.999241598234688],
            [110.360499364687513, -6.998942525904553],
            [110.359999422456298, -6.998552893474748],
            [110.359800769382915, -6.998380760429688],
            [110.359211206992683, -6.997945778284411],
            [110.359115386267192, -6.997882329931549],
            [110.358710590010404, -6.997637552447665],
            [110.358443989668004, -6.997501507967483],
            [110.358307122284145, -6.997456094340576],
            [110.357950002910925, -6.99731088077875],
            [110.357392644490844, -6.997120166597345],
            [110.356967406029852, -6.996929632293824],
            [110.356771718263403, -6.996866047075752],
            [110.356478451056432, -6.996784237578655],
            [110.356126363295346, -6.996684256642563],
            [110.355886923782435, -6.996548247972625],
            [110.355650301120249, -6.996421288455113],
            [110.35507759583929, -6.996076778794636],
            [110.354894098722596, -6.995995118736442],
            [110.354737453316318, -6.995931586226473],
            [110.354276545646755, -6.995759091598389],
            [110.353878124169711, -6.995613818517372],
            [110.353525521939133, -6.995477653439199],
            [110.353352468150121, -6.995414097969357],
            [110.352991624514388, -6.995305057407087],
            [110.352790009443154, -6.995232417091312],
            [110.352683993016697, -6.9951779989373],
            [110.352487274745471, -6.995042047418653],
            [110.352397384249215, -6.994987651382466],
            [110.352261121533459, -6.994915100737982],
            [110.352143505956903, -6.994860666517233],
            [110.351988841736983, -6.99479713588326],
            [110.351790068638337, -6.994715453681606],
            [110.351492044347253, -6.99459745311963],
            [110.351208448591777, -6.994479472261776],
            [110.350969257240791, -6.994370597848902],
            [110.350770509516607, -6.994270824484102],
            [110.350693325309123, -6.994234536468258],
            [110.350601184420753, -6.994171091618132],
            [110.350445168623111, -6.994062328741065],
            [110.350557067602239, -6.993953941201248],
            [110.350674895126275, -6.993854604287628],
            [110.35078566237253, -6.993746212207122],
            [110.350874054801395, -6.993655880055246],
            [110.350992240012317, -6.993502271141194],
            [110.351106539327304, -6.993294384433943],
            [110.351226546627643, -6.993050323948401],
            [110.351285553808808, -6.992932815016879],
            [110.351340833237501, -6.992851482544322],
            [110.351400048407072, -6.992788246284991],
            [110.351462658459013, -6.992725014691085],
            [110.3515769907942, -6.992698035899441],
            [110.35168737487561, -6.992662006242812],
            [110.351882656326026, -6.992608002581933],
            [110.352085293349887, -6.992554008953994],
            [110.352203353271463, -6.99249085352062],
            [110.352321105337438, -6.992445788436731],
            [110.352538503294141, -6.992355633286524],
            [110.352722776037936, -6.992283523317069],
            [110.353126572879205, -6.992220760194821],
            [110.353774864292049, -6.992113105174901],
            [110.353922309052152, -6.992077125807311],
            [110.354062127704083, -6.992032090528199],
            [110.354158108029551, -6.991977949696381],
            [110.354231726190889, -6.99193282359372],
            [110.354349835385179, -6.991833486022056],
            [110.354434573830261, -6.991725057278534],
            [110.354493542803041, -6.991634684018952],
            [110.354723489811192, -6.991264137354716],
            [110.354919018564232, -6.991029224531562],
            [110.355299079291527, -6.990532247308707],
            [110.355520426335801, -6.990243097047305],
            [110.355668068658986, -6.990062390785078],
            [110.355837777591915, -6.989881714632383],
            [110.356057069347628, -6.989646833705521],
            [110.356352241698744, -6.989366829251636],
            [110.356514545061074, -6.989222324333487],
            [110.356669172746933, -6.989104945105692],
            [110.356846137585904, -6.988996641717145],
            [110.357148516314439, -6.988825191462242],
            [110.357421140279399, -6.988689882074907],
            [110.357736741121187, -6.988572721912925],
            [110.358016621612876, -6.98850978535304],
            [110.358455249319107, -6.988419928497311],
            [110.358808685216175, -6.988357091570323],
            [110.358956214798724, -6.988257792685541],
            [110.359523884478918, -6.987932929455551],
            [110.360062414139861, -6.987608025904976],
            [110.360291801323243, -6.987436474334945],
            [110.360438309349249, -6.987255764773449],
            [110.360604472916307, -6.986975582206514],
            [110.360658200070773, -6.986785701275161],
            [110.360688821064826, -6.986682664324171],
            [110.360937093934211, -6.986706260107653],
            [110.361263303451764, -6.986727508557816],
            [110.361820865412625, -6.986765256908496],
            [110.361987055731731, -6.986783025340466],
            [110.362534874735474, -6.986848112697126],
            [110.362880375427835, -6.98690308304375],
            [110.363241191367834, -6.986998355024151],
            [110.363493280178204, -6.987079609042794],
            [110.364672719535278, -6.987504262579522],
            [110.365287914734722, -6.987699283990531],
            [110.365600161868755, -6.987780617951744],
            [110.365929664947302, -6.987850646946955],
            [110.366398218996906, -6.987920400463123],
            [110.366752787039047, -6.98793120354362],
            [110.366986684131376, -6.987912823622723],
            [110.367798650787222, -6.987797919613925],
            [110.368149026703321, -6.987748722601787],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 135,
        id: 139,
        area: 2244369.2664600001,
        perimeter: 8833.686223000001,
        kode_pos: 50183,
        kode_kec: 3374160,
        kode_kel: 3374091006,
        kec: "Ngaliyan",
        kel: "Kalipancur",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.372576980159167, -7.002572259594836],
            [110.373028342306611, -7.002723982218369],
            [110.373539164678519, -7.00287586482244],
            [110.374210070456286, -7.003045490587509],
            [110.374703344832028, -7.003199539972988],
            [110.375021154519899, -7.003357738397491],
            [110.375187697879383, -7.003465335017502],
            [110.375498820352377, -7.003704603601041],
            [110.375704696614463, -7.003921818963151],
            [110.376041949271666, -7.004303558606902],
            [110.376554321701292, -7.004941916320076],
            [110.377154373211326, -7.005619833751206],
            [110.37757055049255, -7.006027973043914],
            [110.378329712986499, -7.006762218542272],
            [110.378450869555806, -7.006891839262797],
            [110.378450872651968, -7.006891842590366],
            [110.378748921227583, -7.007212167510069],
            [110.378988452158794, -7.007482120894349],
            [110.379322100551875, -7.007846357382616],
            [110.380459862433881, -7.00914040375532],
            [110.380981681090333, -7.009744564574993],
            [110.381580529413043, -7.010408745634311],
            [110.381931311204767, -7.010777283083137],
            [110.382367785442909, -7.011133093121238],
            [110.382761484794514, -7.0114417671276],
            [110.383399150515217, -7.011909119396424],
            [110.384819959857182, -7.012976690772343],
            [110.385684434242052, -7.01362409538754],
            [110.386801396632421, -7.014468704806839],
            [110.387604849490458, -7.015064827107924],
            [110.387611156048905, -7.015173014648008],
            [110.387629134522498, -7.015497809434759],
            [110.387765521161342, -7.015908387610389],
            [110.387789274035981, -7.016222254112577],
            [110.387732200049797, -7.016753285943555],
            [110.387626945582653, -7.017163548928019],
            [110.387526583409212, -7.017433559695444],
            [110.387144730058353, -7.01747238345578],
            [110.385699835431623, -7.017391249585311],
            [110.385104769385279, -7.017441424454449],
            [110.384526569385244, -7.017593528716314],
            [110.383897562681199, -7.017598365056761],
            [110.383687799459693, -7.017671689914388],
            [110.383506205590095, -7.017869605922181],
            [110.382903033459598, -7.019771096063709],
            [110.382801747976728, -7.020211016591635],
            [110.382711255985214, -7.020391806425498],
            [110.382560890787829, -7.020491108453156],
            [110.382295734977717, -7.020500637589416],
            [110.382080482812398, -7.020438077047572],
            [110.3818370691274, -7.020245263556986],
            [110.381118776053782, -7.019202590758651],
            [110.380462811874793, -7.018165660924426],
            [110.38025920145482, -7.017870991333599],
            [110.380123418866106, -7.017706626980374],
            [110.379987576434871, -7.017587554941372],
            [110.379840288359674, -7.017553391041901],
            [110.379612126631798, -7.017574587227499],
            [110.379426480096285, -7.017660413617037],
            [110.379318573629845, -7.017841440614713],
            [110.379227472009163, -7.01816969016483],
            [110.379100972812381, -7.018786000122684],
            [110.378987918613049, -7.019341314489445],
            [110.378959179882685, -7.019647000245136],
            [110.378987040365843, -7.020003714868746],
            [110.378986634992316, -7.020309438114396],
            [110.378969237035548, -7.020609477312482],
            [110.378912262281716, -7.020841525497231],
            [110.378848704516926, -7.021056071128429],
            [110.37877885253782, -7.021245932816167],
            [110.378701018532226, -7.021481011099661],
            [110.378630787462868, -7.021743235741621],
            [110.378587773167624, -7.021962082877023],
            [110.378536261819306, -7.022347000074807],
            [110.378530181784555, -7.022658377296],
            [110.378518577800207, -7.022862177735656],
            [110.378494629279729, -7.023018289632077],
            [110.378342511114013, -7.02316200656589],
            [110.378178251404933, -7.023105173315776],
            [110.3753822089989, -7.020964826698943],
            [110.375081593520505, -7.02073470574144],
            [110.375244713613853, -7.020466820859277],
            [110.375467049056184, -7.020101688601422],
            [110.375521866602639, -7.019962823500691],
            [110.37553059148172, -7.019740078582096],
            [110.375501913952249, -7.019604358935036],
            [110.375465820066154, -7.019513856576813],
            [110.375394974589142, -7.019387126233415],
            [110.375327476002909, -7.01929658206504],
            [110.375275183034788, -7.01926033072322],
            [110.375172860527314, -7.019187831037788],
            [110.374781678040947, -7.018897856222804],
            [110.374592570110678, -7.01877096820932],
            [110.374360801000151, -7.018598796104463],
            [110.374189529669451, -7.018462886294528],
            [110.374107307268616, -7.01838136777574],
            [110.37402796249016, -7.018263671362338],
            [110.373970132119553, -7.018136958206067],
            [110.373950448260729, -7.01804647764945],
            [110.373953212152173, -7.017883663593955],
            [110.374001954836004, -7.01761236569819],
            [110.374040638001432, -7.017458644970762],
            [110.374169515554016, -7.016925136405994],
            [110.374201089249212, -7.016798542465858],
            [110.374242637736401, -7.016617689231336],
            [110.374298320295438, -7.016445900258976],
            [110.374414777900043, -7.016102329065975],
            [110.37459453242694, -7.015605069800322],
            [110.374708653765097, -7.015315767967682],
            [110.374831569690016, -7.015008386935656],
            [110.374842356955511, -7.014981264999914],
            [110.375038459718283, -7.014529254394619],
            [110.375700804233574, -7.012918561391949],
            [110.375331322826966, -7.013227101583458],
            [110.374166975280971, -7.014166276441876],
            [110.373737900755245, -7.014500385369812],
            [110.372639274930023, -7.013892875106694],
            [110.372291583524174, -7.013666274773013],
            [110.371734995079663, -7.013303713382836],
            [110.371441306220277, -7.013104321008554],
            [110.371269754775938, -7.012968409976692],
            [110.371174790210745, -7.012895919424291],
            [110.37096702498998, -7.012759959809331],
            [110.370782435159583, -7.012642122012627],
            [110.370658206677192, -7.012548678945973],
            [110.370772958185896, -7.012308017149838],
            [110.370853601719475, -7.012084336992636],
            [110.370946604378332, -7.011722021986888],
            [110.371020087341037, -7.011391303055275],
            [110.37106169479128, -7.01122838257482],
            [110.37108876940809, -7.011009495582578],
            [110.371098791749375, -7.010797883200008],
            [110.371101428621458, -7.010647072940688],
            [110.371083472947873, -7.010525895112059],
            [110.370997372162989, -7.010175032434282],
            [110.370922244354901, -7.009917088926006],
            [110.370861714613113, -7.009666462397112],
            [110.37076955432272, -7.009401198627016],
            [110.370616600231884, -7.009082338857501],
            [110.370445382286121, -7.008706909638443],
            [110.370278626471503, -7.008372005404818],
            [110.370067574128669, -7.007946587487026],
            [110.369720077100553, -7.007259279654814],
            [110.369992150161323, -7.007138901172989],
            [110.369825706437609, -7.006661812596477],
            [110.369802838701005, -7.006625600231192],
            [110.369729620290201, -7.006372230104859],
            [110.369683440516425, -7.006209350497799],
            [110.369627419415835, -7.006001230555602],
            [110.369571325454302, -7.005847383075522],
            [110.369504492830771, -7.005684475762862],
            [110.369397078207442, -7.005403923462188],
            [110.369209312043239, -7.004915218533938],
            [110.369042138323863, -7.004471768320986],
            [110.368957838200501, -7.004254564955851],
            [110.36884733641287, -7.003955917574158],
            [110.368829905078385, -7.00387448533793],
            [110.368794686537314, -7.003765892947418],
            [110.368787573963075, -7.003584974864832],
            [110.36871372979725, -7.003376830932216],
            [110.368659273056608, -7.003268212711736],
            [110.36862055012584, -7.003241024449048],
            [110.36855551606142, -7.003213800859087],
            [110.368504369194866, -7.003168505054259],
            [110.368335474809257, -7.002743143213752],
            [110.368272333041489, -7.00257119531176],
            [110.368158154111185, -7.002272542881971],
            [110.368126540677622, -7.002218227858421],
            [110.368019093909425, -7.002172856380495],
            [110.367883699483642, -7.002082220194691],
            [110.367827460337054, -7.002036917487653],
            [110.367747061042152, -7.001864946342343],
            [110.367655676856756, -7.001656778718694],
            [110.367608232491563, -7.001593396961828],
            [110.367562153983982, -7.001566198739498],
            [110.367480994096724, -7.001538953345231],
            [110.3674150750428, -7.001538864708205],
            [110.367233702544254, -7.001556711630772],
            [110.367164954347089, -7.001556619153003],
            [110.367068775646743, -7.001547444335095],
            [110.366963741132366, -7.001511232324156],
            [110.366888574038754, -7.001451370688689],
            [110.366848180827702, -7.001358783969453],
            [110.366828981372322, -7.001040577879618],
            [110.366821880991267, -7.000830543035849],
            [110.366874331465937, -7.000566510847185],
            [110.366925628535554, -7.000356531413756],
            [110.367072995446605, -7.000043412808591],
            [110.367175404032835, -7.000100319762391],
            [110.367318177887498, -7.000172875168627],
            [110.367475404745747, -7.000227359113325],
            [110.367547523356436, -7.000245546910967],
            [110.367602101328217, -7.000263711122567],
            [110.36765355507687, -7.000290916555532],
            [110.367730115090652, -7.000372428279579],
            [110.367814018713702, -7.000462995286917],
            [110.367856111994186, -7.000508278975992],
            [110.367946300633321, -7.000553627275206],
            [110.368038457517173, -7.000608023629034],
            [110.368095803843474, -7.000671418640826],
            [110.368196928838756, -7.000789145000283],
            [110.368274337770345, -7.000870657789582],
            [110.368377208996932, -7.000952204753297],
            [110.368511495892875, -7.001024748439683],
            [110.368632780940146, -7.001088229214922],
            [110.368746144423696, -7.001151699332508],
            [110.368794498443094, -7.001169855067085],
            [110.368922658598152, -7.001170027002447],
            [110.369216089283753, -7.001134238813775],
            [110.369434943466189, -7.00122498649101],
            [110.369496865269696, -7.001252205770719],
            [110.369561814139374, -7.001342747102547],
            [110.369616614538913, -7.001406138541181],
            [110.369657329935222, -7.001424283956298],
            [110.369727775632896, -7.001424378342351],
            [110.369821100988361, -7.001451639655496],
            [110.369921450673786, -7.001515092077461],
            [110.369997186820896, -7.001578511517872],
            [110.37001920561859, -7.001614722724163],
            [110.370044017163238, -7.001678073958643],
            [110.370101278967525, -7.00180478665358],
            [110.370166523075042, -7.001886282882317],
            [110.370248447047558, -7.001976846864302],
            [110.370361738215976, -7.002094589111962],
            [110.370452998455249, -7.002185165561107],
            [110.37051994016511, -7.002266664021576],
            [110.370642765716326, -7.002447736984664],
            [110.370665645470254, -7.002474903888866],
            [110.370668106841521, -7.002538225195169],
            [110.37069125739292, -7.002574437891315],
            [110.370891650358757, -7.00271943285331],
            [110.370963438134225, -7.002773801451546],
            [110.371063198198613, -7.002855343738203],
            [110.371129339822588, -7.00290065933321],
            [110.371164655790892, -7.002936888276052],
            [110.37120056182377, -7.002955027143204],
            [110.371236197057556, -7.002964120214602],
            [110.371274131954735, -7.002946080063666],
            [110.371302484060351, -7.002900890806102],
            [110.371341291918867, -7.002864760953025],
            [110.371387690045708, -7.002864822966735],
            [110.371442304829159, -7.00285585052499],
            [110.37161628570118, -7.002865128430882],
            [110.371725491055059, -7.00286527431885],
            [110.371977592905125, -7.00284752014108],
            [110.372060806334318, -7.002820494946394],
            [110.372168338384995, -7.002802547630925],
            [110.372298220746956, -7.002784630118402],
            [110.372478510401152, -7.002730598090051],
            [110.372576980159167, -7.002572259594836],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 136,
        id: 140,
        area: 1430177.636864,
        perimeter: 5318.240337,
        kode_pos: 50235,
        kode_kec: 3374040,
        kode_kel: 3374032003,
        kec: "Gajah Mungkur",
        kel: "Karangrejo",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.405580788204091, -7.025282704390726],
            [110.405791260247696, -7.025029172805749],
            [110.405902391415736, -7.024563199513155],
            [110.405931701319531, -7.024524258462241],
            [110.405995173116835, -7.024465871592156],
            [110.406021299860171, -7.024373331497577],
            [110.406079863977908, -7.024339299762699],
            [110.406185570175126, -7.024338796575797],
            [110.406262787176061, -7.024356985311532],
            [110.406327815238114, -7.02439324956547],
            [110.406454713278947, -7.024501955975182],
            [110.406616931809197, -7.024646888976872],
            [110.406738725773295, -7.024764634342062],
            [110.406955764250696, -7.024963909486025],
            [110.407031609669403, -7.025006381656858],
            [110.407164911463028, -7.025003302031775],
            [110.40716994866419, -7.024876629275998],
            [110.407022173056404, -7.02475625966262],
            [110.406978405845422, -7.024658758834578],
            [110.406949328545807, -7.024514177934581],
            [110.406975418070516, -7.024450871368302],
            [110.407043815854465, -7.024353512381857],
            [110.407123623517464, -7.024231806437651],
            [110.407135095135629, -7.024158736828842],
            [110.407107643382886, -7.024014157989262],
            [110.407072349314959, -7.023833371675874],
            [110.407057151301174, -7.023770034084921],
            [110.407050995589216, -7.023715753408617],
            [110.407038607665271, -7.023652710371812],
            [110.407048932092522, -7.02352279611382],
            [110.407029588096322, -7.023417225427317],
            [110.406998863340007, -7.023326731756827],
            [110.406980293108305, -7.023245298935841],
            [110.406962005811479, -7.023163866472129],
            [110.406915482866566, -7.023037170892643],
            [110.406884712313243, -7.022982859088179],
            [110.406850512233234, -7.022955679391644],
            [110.406841527254429, -7.022901395135585],
            [110.406838506714067, -7.022829027457954],
            [110.406838575475518, -7.022774754651513],
            [110.406823106047469, -7.022702371230119],
            [110.406810714463063, -7.022657128147619],
            [110.40681074884462, -7.022629991744354],
            [110.406826367252137, -7.022584784084863],
            [110.406847915709037, -7.022548629405923],
            [110.406916170388016, -7.022494442819772],
            [110.406934878084598, -7.022467330026375],
            [110.406934923915799, -7.022431148154237],
            [110.406922520867369, -7.022394950541907],
            [110.406891727432196, -7.022358729675902],
            [110.406826722508612, -7.022304374581268],
            [110.406774166475486, -7.022250035222849],
            [110.406740849622892, -7.022195720192523],
            [110.406728186588282, -7.022141431283022],
            [110.406726592166066, -7.022060019925558],
            [110.406767856395689, -7.021899583798817],
            [110.406805238489639, -7.021843027807117],
            [110.40685111745853, -7.021774027711672],
            [110.406905106634554, -7.021706777379954],
            [110.406956974844149, -7.021540790418997],
            [110.406934708148128, -7.021388173452618],
            [110.406910389638426, -7.021165991342166],
            [110.407229187262402, -7.021224798195907],
            [110.407491704989326, -7.02127393744081],
            [110.408059540144862, -7.021329562839563],
            [110.408776942454324, -7.021418931324531],
            [110.409573737394652, -7.021502297709288],
            [110.40953657137122, -7.021920166302404],
            [110.410684545661169, -7.021961266107924],
            [110.410786201320448, -7.02124758199105],
            [110.410876070477187, -7.020907427029745],
            [110.410955665877154, -7.020609025815865],
            [110.411053379929442, -7.020301601829816],
            [110.411170050003818, -7.01999420159756],
            [110.411194178470723, -7.019930913444516],
            [110.411340726263148, -7.019487868280118],
            [110.411989854321462, -7.019694122000667],
            [110.41205750218468, -7.019563575077738],
            [110.412261986002662, -7.019624933162843],
            [110.412422170360244, -7.019696770507783],
            [110.412548604889409, -7.019774886438944],
            [110.412622344072744, -7.019831866749085],
            [110.412689756656391, -7.019888839130299],
            [110.412797261616774, -7.01992689901127],
            [110.412995463984444, -7.01995243056404],
            [110.413202086035326, -7.019990614343105],
            [110.413362435645212, -7.020015038728957],
            [110.413568656160734, -7.020042432992674],
            [110.414018098815859, -7.020088554445429],
            [110.414566334996252, -7.020146126815388],
            [110.415036753309266, -7.020246267561696],
            [110.416110534852166, -7.020589986712436],
            [110.416179888333843, -7.020464972020307],
            [110.416434346721076, -7.020570644730412],
            [110.416636301308117, -7.020616123370722],
            [110.416858457573127, -7.020646883142189],
            [110.417016338531781, -7.020661709081363],
            [110.417194417951364, -7.020698226482597],
            [110.41736457114807, -7.020740859227943],
            [110.417505505429915, -7.020786607556953],
            [110.417640237583214, -7.020817847541465],
            [110.417774992936131, -7.020830443881019],
            [110.417946578933737, -7.020816751722085],
            [110.418202213118434, -7.02075018476333],
            [110.418464518168065, -7.020672665978612],
            [110.418743177977973, -7.020592125404185],
            [110.418992729858147, -7.020514752667012],
            [110.419530274288789, -7.020320763015408],
            [110.419745596674872, -7.021146079669408],
            [110.419760530163757, -7.021198909775295],
            [110.419936825750838, -7.021859449535477],
            [110.420143361647064, -7.022547163146148],
            [110.420229571419895, -7.023071908908818],
            [110.420224876504861, -7.023207585653699],
            [110.420172730554327, -7.023506022790584],
            [110.420087448024361, -7.023831555446306],
            [110.419959837826099, -7.024066580694696],
            [110.419747530238766, -7.024382910618696],
            [110.41959815481745, -7.024726244130598],
            [110.419393530389272, -7.025085674998166],
            [110.419183194935599, -7.025414188197377],
            [110.419040979026718, -7.025714443129195],
            [110.418879311037855, -7.026127617047957],
            [110.41872776957176, -7.026643690782854],
            [110.41865502136875, -7.026977615927628],
            [110.41859560748766, -7.0272036797199],
            [110.418566263095926, -7.027366462305094],
            [110.418482142418952, -7.027862272551682],
            [110.418474201247719, -7.027909078026775],
            [110.418405448453285, -7.028512206532834],
            [110.418334137054487, -7.029137777924204],
            [110.418325739368839, -7.029211445544296],
            [110.418187393834501, -7.030524258197006],
            [110.418183727595505, -7.030559048372226],
            [110.418176366809803, -7.030619092525941],
            [110.418128477456222, -7.03118016505513],
            [110.418117081481071, -7.031313680207749],
            [110.416931303449346, -7.03123068629859],
            [110.416296631937669, -7.031177064668825],
            [110.414841377001395, -7.031043342487073],
            [110.413882295330964, -7.030911721198962],
            [110.412682168685791, -7.030627156183356],
            [110.411803354862897, -7.030331319027514],
            [110.411111485588805, -7.030029878970035],
            [110.410434269456402, -7.029690520256187],
            [110.409625825185046, -7.029184659964284],
            [110.408811666394939, -7.028579573626963],
            [110.408137711046322, -7.027974663100874],
            [110.407679730858263, -7.027504260224186],
            [110.406895119043398, -7.026636573607849],
            [110.406256319123614, -7.025952914835326],
            [110.405658297963399, -7.025359770064806],
            [110.405580788204091, -7.025282704390726],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 137,
        id: 141,
        area: 830501.73446499999,
        perimeter: 3692.335814,
        kode_pos: 50164,
        kode_kec: 3374100,
        kode_kel: 3374042006,
        kec: "Gayamsari",
        kel: "Kaligawe",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.443791523651427, -6.966577879205641],
            [110.443796994824709, -6.966540626064735],
            [110.443883168698918, -6.965986805539006],
            [110.443955196885312, -6.965558090280761],
            [110.444044168275084, -6.965087011860298],
            [110.444046108755884, -6.965076737548468],
            [110.444047850909428, -6.964774235557859],
            [110.443975369887795, -6.96441088595525],
            [110.443795376231208, -6.963820133939687],
            [110.443562726380321, -6.963191705895397],
            [110.443284949285442, -6.962525610623919],
            [110.443109213221874, -6.962100908312406],
            [110.442799690822255, -6.961533780144169],
            [110.442798917196654, -6.961532362652475],
            [110.442706525524599, -6.961286017335989],
            [110.442530661881932, -6.960786248784017],
            [110.442442868914867, -6.960418820804366],
            [110.442395335947467, -6.959925611503266],
            [110.442393843895047, -6.959920891532071],
            [110.442382174757824, -6.959883977282639],
            [110.442519357273184, -6.959843342023445],
            [110.442728562229291, -6.959780333228903],
            [110.44408757960862, -6.959302516873159],
            [110.444859715552255, -6.95895969181321],
            [110.445218561711513, -6.958842520260974],
            [110.448036204901427, -6.957850802154588],
            [110.449514886302964, -6.957364063270563],
            [110.45011280568319, -6.957220029056122],
            [110.450754227048336, -6.957130317981358],
            [110.45140352010975, -6.957091915993363],
            [110.45156023917994, -6.957083067604896],
            [110.451557534902747, -6.957465935664921],
            [110.451548922239667, -6.95779954690271],
            [110.451525646781235, -6.959285313938973],
            [110.451519312715533, -6.959594915947442],
            [110.451501752071209, -6.96037461332382],
            [110.451493221133973, -6.9608839274524],
            [110.45147529530044, -6.961278989778196],
            [110.451441560263206, -6.961613739956988],
            [110.451411144741854, -6.96182473300642],
            [110.451347240976773, -6.9621578614973],
            [110.451280277858601, -6.962389438962481],
            [110.451219463833581, -6.962590628860461],
            [110.451030403048449, -6.963074625149657],
            [110.450674852769055, -6.963917864113665],
            [110.450220514089054, -6.964995378822996],
            [110.449871496187257, -6.965824288701584],
            [110.449580657326351, -6.966506129280056],
            [110.449430481861796, -6.966939653185779],
            [110.449401565484166, -6.967023128369759],
            [110.449311873728107, -6.967406470922049],
            [110.449266184395171, -6.967739253723731],
            [110.449266184193874, -6.967739257487492],
            [110.449083303797835, -6.96773146317168],
            [110.449042810625571, -6.967729737353435],
            [110.448720034279873, -6.967720315437078],
            [110.448531896379777, -6.967729141539211],
            [110.448454120507648, -6.967710959656181],
            [110.448379173624588, -6.967692781062328],
            [110.448205003517074, -6.967611167666299],
            [110.448046088812305, -6.967547663157987],
            [110.447907228170479, -6.967511318722448],
            [110.447786430691295, -6.96751117761568],
            [110.447705500670736, -6.967529174194315],
            [110.447547894674628, -6.96755612671458],
            [110.447350672338061, -6.96759207842922],
            [110.44717811509426, -6.967582831080153],
            [110.447129173725145, -6.967582773838078],
            [110.446999408300584, -6.967510257518023],
            [110.446765600081918, -6.967383346015612],
            [110.446511695638932, -6.967265456440717],
            [110.446306973097293, -6.967183806606333],
            [110.446152846869595, -6.967138398228237],
            [110.445560377964554, -6.966965837990489],
            [110.445312938211202, -6.966884137644154],
            [110.445051636574235, -6.96680242090209],
            [110.444762589805251, -6.966738762560153],
            [110.444501876692129, -6.966692472475528],
            [110.443791523651427, -6.966577879205641],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 138,
        id: 142,
        area: 332059.66843100003,
        perimeter: 3582.196253,
        kode_pos: 50131,
        kode_kec: 3374130,
        kode_kel: 3374070021,
        kec: "Semarang Tengah",
        kel: "Pindrikan Lor",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.413733087536201, -6.974055443700791],
            [110.41334131459233, -6.974841774356595],
            [110.413095862164482, -6.975438473016903],
            [110.412535123640055, -6.976595600823181],
            [110.412376824602802, -6.976948178636939],
            [110.411653570708054, -6.978566423510747],
            [110.410846280922854, -6.978339278786698],
            [110.410465041212873, -6.978248347679108],
            [110.409946491974551, -6.978238654170266],
            [110.409529470835466, -6.978256223634249],
            [110.409229842941343, -6.978282985188341],
            [110.408629138527672, -6.978363642407952],
            [110.408322121017207, -6.978417530620631],
            [110.407014226331924, -6.978669163120007],
            [110.406753604576309, -6.978723108573362],
            [110.405901736560068, -6.978984356390393],
            [110.404971377921512, -6.979345004077542],
            [110.404143314544129, -6.979796233933242],
            [110.40285777437586, -6.980745034358081],
            [110.402272656538372, -6.981222542028311],
            [110.401877707700876, -6.981113148262547],
            [110.401519956716186, -6.977513080042981],
            [110.40306622681662, -6.977397816714404],
            [110.405151701732166, -6.977237630752457],
            [110.407016229706329, -6.977077157713705],
            [110.407058488532911, -6.977068634045578],
            [110.407059309458063, -6.977069376797133],
            [110.407447806950458, -6.977013412144349],
            [110.408110108063298, -6.976846649706189],
            [110.408436767517699, -6.976726088745015],
            [110.408436771973356, -6.976726088602987],
            [110.408512814046205, -6.976723664622649],
            [110.408866083778022, -6.976600617276037],
            [110.409195924950424, -6.976464825487117],
            [110.409468456143955, -6.976352627311889],
            [110.409527751863436, -6.976320630763449],
            [110.410062090596043, -6.976032295818895],
            [110.410551526915185, -6.975734767268342],
            [110.410936765346122, -6.975460042007781],
            [110.411541140941821, -6.975019760234304],
            [110.412614949096181, -6.974240238614211],
            [110.412614977633197, -6.974240217897941],
            [110.412674582770919, -6.974334396982391],
            [110.41273134574574, -6.974401127894093],
            [110.412790120054183, -6.974470223316694],
            [110.412889037723474, -6.97453241066353],
            [110.412891036688691, -6.974533667368295],
            [110.413623995050997, -6.974109439058652],
            [110.413733051221882, -6.974055460568108],
            [110.413733087536201, -6.974055443700791],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 139,
        id: 143,
        area: 745837.428144,
        perimeter: 3616.621339,
        kode_pos: 50232,
        kode_kec: 3374110,
        kode_kel: 3374050022,
        kec: "Semarang Timur",
        kel: "Karangtempel",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.434726201606182, -6.98531556209926],
            [110.435065780741596, -6.985324912402763],
            [110.436449104299243, -6.985407977702565],
            [110.43715998792517, -6.985463100106315],
            [110.437819067154891, -6.985545296318377],
            [110.4388348385569, -6.98565690387981],
            [110.439728451650907, -6.985755616714854],
            [110.440890356919098, -6.985893715606894],
            [110.440769342163179, -6.986516744140683],
            [110.440709649803665, -6.986732822960672],
            [110.440599713670665, -6.987375891550503],
            [110.440582726134807, -6.987610753362119],
            [110.440582725797086, -6.987610758031134],
            [110.440429168666213, -6.988334845516906],
            [110.440305648183354, -6.989267804876389],
            [110.440235543152212, -6.989707525609649],
            [110.440110327713711, -6.99053488420106],
            [110.439913322416331, -6.991615013404218],
            [110.439844980230944, -6.992069218665554],
            [110.439784231646627, -6.992501707022476],
            [110.439681570443042, -6.9933554218839],
            [110.439453860781583, -6.994893561894138],
            [110.439385407095401, -6.99547273535379],
            [110.439354980670089, -6.995732235429584],
            [110.439294365521789, -6.996051881874957],
            [110.439188651222921, -6.99630753085546],
            [110.439069940650015, -6.996558621096423],
            [110.438823338080425, -6.997082035757294],
            [110.437024148668286, -6.996327797848991],
            [110.43442515319397, -6.995227465760869],
            [110.433922215857507, -6.995036040974103],
            [110.4338671629815, -6.995013384896911],
            [110.433867157913738, -6.995013382832085],
            [110.43396660395409, -6.99385353568313],
            [110.434099051520377, -6.99287677697548],
            [110.434205046119246, -6.99178239439835],
            [110.434285240692247, -6.990733208416325],
            [110.434391712649301, -6.989476006518363],
            [110.434471242549606, -6.988743413382687],
            [110.434524494117724, -6.98798365204858],
            [110.434631127223682, -6.986590766955582],
            [110.434726201606182, -6.98531556209926],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 140,
        id: 144,
        area: 2908984.4787360001,
        perimeter: 9410.426473,
        kode_pos: 50274,
        kode_kec: 3374070,
        kode_kel: 3374052009,
        kec: "Tembalang",
        kel: "Jangli",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.443556523932571, -7.014995113839905],
            [110.443785776111056, -7.015140439097342],
            [110.443621082893941, -7.015755603650734],
            [110.443615059009502, -7.015845710272282],
            [110.44365136329516, -7.016008573046649],
            [110.443669549525794, -7.016180459890895],
            [110.443669130716117, -7.016533235519161],
            [110.443687349175164, -7.016677985774532],
            [110.443696188077254, -7.016858907079903],
            [110.443695855162446, -7.017139318470353],
            [110.443668217851055, -7.01730210545979],
            [110.443603756918563, -7.017501030961243],
            [110.443557761449966, -7.017636659552353],
            [110.443506752034963, -7.017944147467422],
            [110.443435525666658, -7.018360157880469],
            [110.443419749666134, -7.018541049981464],
            [110.443357096943146, -7.018884706205787],
            [110.443300546870006, -7.019092686548783],
            [110.443260807813829, -7.019201185884902],
            [110.443192808092675, -7.01928251506432],
            [110.443078941577141, -7.019390926417916],
            [110.442934855381381, -7.019454074168594],
            [110.442829584239064, -7.019472040264961],
            [110.442687281664959, -7.019462825740755],
            [110.442617104487326, -7.019471787928023],
            [110.44252850462, -7.019507864835083],
            [110.44243617282406, -7.019589164982939],
            [110.442291957248315, -7.019760858830236],
            [110.442262163507465, -7.019833187723876],
            [110.442210433383408, -7.02003212807171],
            [110.442180607289998, -7.020131593532538],
            [110.442157170047778, -7.020330567502738],
            [110.442170985703228, -7.020590166874213],
            [110.442079995390344, -7.020782085231875],
            [110.441920333661713, -7.021062974388815],
            [110.441813976137837, -7.021279940747413],
            [110.441753403701469, -7.021551580906939],
            [110.441749607007424, -7.02175071501312],
            [110.441778105615057, -7.021940222254337],
            [110.441779343718338, -7.022184588113315],
            [110.44177748012639, -7.022464862632313],
            [110.441799734605311, -7.022546298945289],
            [110.44198426209168, -7.022736474779187],
            [110.442212307240723, -7.022971929990517],
            [110.442462410490521, -7.023216456865905],
            [110.44272605164258, -7.02349718186221],
            [110.443005841742831, -7.023759834836092],
            [110.443033839278186, -7.023811193009479],
            [110.443026652977522, -7.023868081231296],
            [110.44296771291549, -7.024045813588375],
            [110.443460551896337, -7.024203606053546],
            [110.443565424364394, -7.0242581080096],
            [110.443604528111095, -7.0242990489546],
            [110.443910467853613, -7.024434541666572],
            [110.444292941350952, -7.024563012800772],
            [110.444246235441568, -7.024929230378811],
            [110.444742253040076, -7.025356543787255],
            [110.444891699952379, -7.025394059251579],
            [110.445117693245834, -7.025413885024657],
            [110.445365103565408, -7.02538217332348],
            [110.445505656476527, -7.025416122026964],
            [110.445578571885349, -7.025458880835941],
            [110.445701145045263, -7.025647496336528],
            [110.445818918135487, -7.025771277709634],
            [110.445912722316677, -7.0258184367642],
            [110.446039023400701, -7.025864814647422],
            [110.446113708068793, -7.025916465594011],
            [110.446207914305461, -7.026014368224446],
            [110.4464017710512, -7.026121278642317],
            [110.446946029887755, -7.026390402723656],
            [110.447299951566762, -7.02658640283539],
            [110.44750445531119, -7.02671999575607],
            [110.447623505932867, -7.026878380256515],
            [110.447744534295353, -7.026994698304626],
            [110.447853954989682, -7.027058146008909],
            [110.448178015921172, -7.027212301858493],
            [110.448357258636591, -7.027372326185136],
            [110.448506390779087, -7.027547373467696],
            [110.448581363438379, -7.027791691379083],
            [110.4486352333426, -7.027936483466578],
            [110.449158166761109, -7.028478264426407],
            [110.449412134196308, -7.028647188874397],
            [110.449620527048836, -7.028734722418705],
            [110.449921404288446, -7.029008241042636],
            [110.449830534659696, -7.029137687633121],
            [110.449903918855114, -7.029197753151968],
            [110.450123451916213, -7.029361654453983],
            [110.450339037028343, -7.029485637946391],
            [110.450506672739493, -7.029617547739748],
            [110.450682237632932, -7.029801353789792],
            [110.451065379499852, -7.030125098825545],
            [110.451280848157538, -7.030348864518331],
            [110.451172842135691, -7.03046847728919],
            [110.451168692541557, -7.030600185625961],
            [110.451228458608668, -7.030735960139125],
            [110.451328412940143, -7.030897538121695],
            [110.451516625796231, -7.031087715003792],
            [110.451594776443756, -7.031277762994518],
            [110.451586390931979, -7.031431527489374],
            [110.451516197852499, -7.031693766191257],
            [110.451427122189401, -7.031892663930514],
            [110.451323606036198, -7.032100590278321],
            [110.4511994247475, -7.032317537930097],
            [110.45105115117606, -7.03257063948929],
            [110.450977102683169, -7.032742418080593],
            [110.450892426070268, -7.033049867385765],
            [110.450753446514511, -7.033339161891845],
            [110.450392926298676, -7.033628196403402],
            [110.450061697266349, -7.03379062737448],
            [110.449445715009702, -7.034052224394751],
            [110.44918868984449, -7.03415142312889],
            [110.448930677124196, -7.034368212605486],
            [110.448696873984474, -7.034449347289477],
            [110.448333004484908, -7.034457964304003],
            [110.447848048038963, -7.034457392733128],
            [110.447601304374032, -7.034475192808896],
            [110.447344833837903, -7.034583436675685],
            [110.447069907981145, -7.034745931838679],
            [110.446861594872232, -7.035044188608699],
            [110.446761460517521, -7.035261163245442],
            [110.446673674509199, -7.035324378284074],
            [110.446595561957892, -7.035342377043988],
            [110.446532760239393, -7.035333257273171],
            [110.446372809720827, -7.035170248479802],
            [110.446181864687205, -7.034898656521104],
            [110.446013010509091, -7.034608999557603],
            [110.445883596302849, -7.034464117825885],
            [110.445683698377096, -7.034346289249457],
            [110.445555244420447, -7.034346137177779],
            [110.44532825282532, -7.034409187125902],
            [110.444933088416704, -7.034562493012185],
            [110.444841602639301, -7.034643794387466],
            [110.444772046330939, -7.034842713716781],
            [110.44477582478801, -7.034996492313039],
            [110.444867826388418, -7.035195603195439],
            [110.445136568177375, -7.035476333375404],
            [110.445293187482321, -7.035585065396745],
            [110.445368399377841, -7.035865566140048],
            [110.445350466848197, -7.035956000263088],
            [110.445209262429302, -7.036209107976929],
            [110.444936043309156, -7.036362558231469],
            [110.444688070134106, -7.036461765046318],
            [110.444408117229585, -7.036805163213609],
            [110.444287412799881, -7.036949748495516],
            [110.444221760020113, -7.036958716086736],
            [110.443981042889106, -7.036904157021],
            [110.443729366561257, -7.036786266075263],
            [110.443534625851214, -7.03661416948384],
            [110.443409162337545, -7.036478337326407],
            [110.443278519770715, -7.036414863248837],
            [110.443002740152025, -7.03634217094542],
            [110.442846185822532, -7.036332634465032],
            [110.442709865606219, -7.036368958995221],
            [110.442403258833863, -7.036522367952597],
            [110.442326245074469, -7.036567548715754],
            [110.442267132596385, -7.036549342389675],
            [110.442214592251972, -7.03647691553658],
            [110.442201967962447, -7.036386445176734],
            [110.442201890184165, -7.036214579978892],
            [110.442097879432851, -7.036083300223189],
            [110.441969772491319, -7.036007614491823],
            [110.441807309936195, -7.035967455921479],
            [110.44154733528822, -7.035932176604648],
            [110.441289833970444, -7.035919380351235],
            [110.441139815552106, -7.035926694717384],
            [110.440935973519018, -7.035992643201314],
            [110.440573244305043, -7.036164558498617],
            [110.43982405007624, -7.036494621853481],
            [110.43933388984162, -7.036601440689516],
            [110.439186374380853, -7.036606259602693],
            [110.438798975529991, -7.036500887545556],
            [110.438374993617103, -7.036390934750736],
            [110.438277579452105, -7.036399843878273],
            [110.438196745869419, -7.036327382674036],
            [110.438129602151491, -7.036164482630507],
            [110.438093222673757, -7.036064938184822],
            [110.437940566226047, -7.035956208631858],
            [110.437812449347703, -7.035910827179339],
            [110.437427598144311, -7.035955592517472],
            [110.437012775207975, -7.036044465657394],
            [110.436592890063849, -7.036161672342409],
            [110.436419289450285, -7.036587566442173],
            [110.436376532906579, -7.036614651535099],
            [110.435850326985459, -7.03669783811725],
            [110.436136934089333, -7.037183744530179],
            [110.43621596320628, -7.037294194517718],
            [110.436284052975864, -7.037316347482219],
            [110.436425786549819, -7.037334910616658],
            [110.436527098344371, -7.03728721217044],
            [110.436488249120018, -7.037443501371491],
            [110.436467831901751, -7.037581420291212],
            [110.436383055618705, -7.037656727329289],
            [110.436278019590517, -7.037739366936012],
            [110.43619504206039, -7.037849621785901],
            [110.43617279040231, -7.037982020732829],
            [110.436223778641974, -7.038101614224954],
            [110.436346354544, -7.038240897183725],
            [110.436459596789518, -7.038290556293311],
            [110.436516173830384, -7.038351938286898],
            [110.436504300419543, -7.038410879638788],
            [110.436386172194773, -7.038497991752481],
            [110.436291671291514, -7.038566266485785],
            [110.43627029623957, -7.038674719124984],
            [110.436277169862365, -7.038846877885433],
            [110.436298283098594, -7.038955381702223],
            [110.435979606547065, -7.03896678886306],
            [110.435665730696755, -7.038912171280667],
            [110.435308044346357, -7.038811425454821],
            [110.434776794255896, -7.038735584228476],
            [110.434411963042933, -7.038664254546108],
            [110.434143245463432, -7.038744945615158],
            [110.433823752813282, -7.038901527496298],
            [110.433593621599329, -7.038933963592738],
            [110.433514249282155, -7.03894515078346],
            [110.433390007110447, -7.038329694722917],
            [110.433201817687504, -7.037424915090418],
            [110.433041808617787, -7.036627508352003],
            [110.432916327563547, -7.036123461183983],
            [110.432800750643963, -7.03577059454686],
            [110.432574555306545, -7.035135412546489],
            [110.432403559515237, -7.034732089159625],
            [110.432240077485986, -7.034371605925788],
            [110.432028745072245, -7.033958155626081],
            [110.431624004989501, -7.033352519581127],
            [110.431261668180184, -7.032800303208962],
            [110.431084803045621, -7.032592041538256],
            [110.430685498487648, -7.032193553672116],
            [110.430156482105644, -7.031743050709141],
            [110.429715768640648, -7.031462369682088],
            [110.429169803900692, -7.031263268916458],
            [110.42861565494357, -7.031168891581766],
            [110.428159631101792, -7.031095889711698],
            [110.428682314503916, -7.031017557721469],
            [110.429213955663258, -7.030986096187172],
            [110.429611218055172, -7.030969070969205],
            [110.429944256437778, -7.030922785527783],
            [110.430227660981302, -7.030858930438113],
            [110.430607579167699, -7.0306930563617],
            [110.430935002793916, -7.030460000176916],
            [110.431190075931596, -7.030249380482774],
            [110.431385098166999, -7.029951115107856],
            [110.431470448489762, -7.029797444867478],
            [110.43155723541409, -7.029625685312331],
            [110.431908313246566, -7.02873060469292],
            [110.432252448003055, -7.027726969019906],
            [110.432571858533905, -7.026578574673275],
            [110.432891146715534, -7.025529680522326],
            [110.433105319381497, -7.025068617891074],
            [110.43326167886876, -7.024854846236972],
            [110.433369732990684, -7.024707116584835],
            [110.433895598365069, -7.024092656124028],
            [110.434329617776839, -7.023622812575291],
            [110.43496732566868, -7.022936121357126],
            [110.435633945097322, -7.022204236254149],
            [110.436161393289737, -7.021680229919886],
            [110.436607389142097, -7.02136417224975],
            [110.437255080731646, -7.021075492671849],
            [110.437612007301098, -7.020949283240007],
            [110.438344979802409, -7.020796387254554],
            [110.438649183433739, -7.020751523666164],
            [110.439068353973312, -7.020625387543808],
            [110.439434344383898, -7.020490141960294],
            [110.440051857172932, -7.020120012410282],
            [110.44015240521999, -7.020029677042213],
            [110.440646624600333, -7.019605126278752],
            [110.441043440017026, -7.01924377766465],
            [110.441456664689369, -7.018882448192057],
            [110.441886613840722, -7.018494001575095],
            [110.442134445540489, -7.018268157767023],
            [110.442440492585831, -7.017861472183553],
            [110.442697112781914, -7.01738236335185],
            [110.442786178953313, -7.01716613944845],
            [110.442863202366098, -7.016978872867215],
            [110.44297038954025, -7.016695226664725],
            [110.443253739813443, -7.01586337302333],
            [110.443285535084044, -7.015772955313914],
            [110.443556523932571, -7.014995113839905],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 141,
        id: 145,
        area: 1061818.677653,
        perimeter: 4259.43193,
        kode_pos: 50249,
        kode_kec: 3374100,
        kode_kel: 3374042001,
        kec: "Gayamsari",
        kel: "Pandean Lamper",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.440582726134807, -6.987610753362119],
            [110.441228169188278, -6.987647916306123],
            [110.441263217787579, -6.987675094501007],
            [110.441312401145481, -6.987711334996836],
            [110.44163459258418, -6.987747898981278],
            [110.442398614438574, -6.987848304503564],
            [110.443022313297107, -6.987948543010487],
            [110.443821309893934, -6.988139443129847],
            [110.44546022646594, -6.988846927579834],
            [110.446041603472494, -6.989091841326973],
            [110.446560206786003, -6.989309544012513],
            [110.447527108125215, -6.989627273103785],
            [110.447950269200675, -6.989691087951516],
            [110.447905240095778, -6.989971447495257],
            [110.447827251328178, -6.990613590711376],
            [110.44769344357023, -6.991328032902068],
            [110.447580659855362, -6.991725905169871],
            [110.447404482190763, -6.992141794138384],
            [110.447060100615161, -6.992928353366636],
            [110.446905551090339, -6.993235720785796],
            [110.446813872041758, -6.993488888656072],
            [110.446792766139083, -6.993633592744653],
            [110.446806688314112, -6.99382356575205],
            [110.446743396277753, -6.993995356942131],
            [110.446559503661589, -6.994474555247685],
            [110.446362351530013, -6.995161785589446],
            [110.44635783191444, -6.995876379009221],
            [110.446368241715547, -6.996165848959968],
            [110.44630019869291, -6.996527591065975],
            [110.446202460061215, -6.99688025266134],
            [110.446089422482586, -6.997250987322226],
            [110.445946646518863, -6.997648823588486],
            [110.445748322656897, -6.998128004341418],
            [110.445282337614373, -6.999104374847607],
            [110.444944256281119, -6.999818574656292],
            [110.44493660113983, -7.000071841020427],
            [110.444831136247487, -7.000496857422617],
            [110.444671852541703, -7.000976083550134],
            [110.444390662348312, -7.001869490098534],
            [110.44400464361847, -7.001730655980596],
            [110.443628786889207, -7.001487374985509],
            [110.443248400107578, -7.001268735663007],
            [110.442777935709898, -7.001042422696622],
            [110.442499378494048, -7.000944265108846],
            [110.442314914146721, -7.000891370097576],
            [110.442115404307614, -7.000823406780675],
            [110.441874492503203, -7.000736581276563],
            [110.441524424771828, -7.000604474915407],
            [110.440823251314598, -7.00029229147415],
            [110.440698577598667, -7.000236886984338],
            [110.440438714008565, -6.999984237546466],
            [110.439992781996082, -6.999714681285954],
            [110.439631534815859, -6.999484732768147],
            [110.439200756967978, -6.99913994192138],
            [110.438914858828653, -6.998883744516015],
            [110.438503076508113, -6.998467305243418],
            [110.438510919549074, -6.998140149814325],
            [110.438543318019086, -6.997814723806316],
            [110.438626581744003, -6.997487477156201],
            [110.438823338080425, -6.997082035757294],
            [110.439069940650015, -6.996558621096423],
            [110.439188651222921, -6.99630753085546],
            [110.439294365521789, -6.996051881874957],
            [110.439354980670089, -6.995732235429584],
            [110.439385407095401, -6.99547273535379],
            [110.439453860781583, -6.994893561894138],
            [110.439681570443042, -6.9933554218839],
            [110.439784231646627, -6.992501707022476],
            [110.439844980230944, -6.992069218665554],
            [110.439913322416331, -6.991615013404218],
            [110.440110327713711, -6.99053488420106],
            [110.440235543152212, -6.989707525609649],
            [110.440305648183354, -6.989267804876389],
            [110.440429168666213, -6.988334845516906],
            [110.440582725797086, -6.987610758031134],
            [110.440582726134807, -6.987610753362119],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 142,
        id: 146,
        area: 842766.37224199995,
        perimeter: 3646.381656,
        kode_pos: 50244,
        kode_kec: 3374050,
        kode_kel: 3374030035,
        kec: "Semarang Selatan",
        kel: "Randusari",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.408283080708983, -6.996110365345777],
            [110.408201014505735, -6.995983735745015],
            [110.408055181285675, -6.99563982361887],
            [110.407945084629475, -6.995454208509739],
            [110.407804070517784, -6.995322915542676],
            [110.407563291040816, -6.995114566282491],
            [110.407335197224342, -6.994942414856717],
            [110.407116663402135, -6.994751101270844],
            [110.406928248259092, -6.994589128236028],
            [110.406838349880601, -6.994454827988782],
            [110.406838317316158, -6.994454837891824],
            [110.406201186425903, -6.991475569964725],
            [110.406244749046309, -6.991214298132331],
            [110.406479892199769, -6.990779867215627],
            [110.406903740818535, -6.990076060573832],
            [110.407535432433747, -6.988966483195071],
            [110.407801345159442, -6.988548356269527],
            [110.408017466326712, -6.988155025690617],
            [110.408336512282844, -6.987227865465227],
            [110.408887817513559, -6.986144649286833],
            [110.409410069391896, -6.985084846473827],
            [110.409552636325486, -6.984795535167678],
            [110.409733856909313, -6.984777019121112],
            [110.409896559260716, -6.984707149824092],
            [110.410037374209168, -6.984771201668567],
            [110.411495908019575, -6.985433626261929],
            [110.413656749081852, -6.986417791435978],
            [110.413803320992315, -6.9864605909292],
            [110.413825584064611, -6.986936221612948],
            [110.414009709940515, -6.988564641782886],
            [110.41403433556097, -6.988781764560163],
            [110.414092810202035, -6.989306476643132],
            [110.414130564914387, -6.989885435994196],
            [110.414135898897186, -6.990600037614374],
            [110.41407214506448, -6.991133643178451],
            [110.413951763766505, -6.991703360287382],
            [110.413812178995954, -6.992472054537521],
            [110.413774021753667, -6.992897145769807],
            [110.413779704476298, -6.993331337060105],
            [110.413786225572977, -6.994000712497304],
            [110.413830260274977, -6.994534452056169],
            [110.414056396020371, -6.995554366973688],
            [110.414182867954167, -6.995664322269459],
            [110.414243531763645, -6.995717182133834],
            [110.414101553955248, -6.995752576930843],
            [110.414042544909961, -6.995767238579917],
            [110.413581507661391, -6.99586105812759],
            [110.413502155489084, -6.995877491620089],
            [110.412630165391832, -6.996027443402155],
            [110.411792517814689, -6.996161096174948],
            [110.411322686882784, -6.996180917797932],
            [110.41072216333248, -6.99617200295723],
            [110.410583320683301, -6.996126929629632],
            [110.410338384145092, -6.995983760811637],
            [110.410032225220206, -6.995795615649159],
            [110.409823907728153, -6.995770863917657],
            [110.409570498587726, -6.995868508761045],
            [110.409337520785186, -6.99596209731906],
            [110.40890030036887, -6.996039102269062],
            [110.408585683913245, -6.99607952487858],
            [110.408283080708983, -6.996110365345777],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 143,
        id: 147,
        area: 737125.37562599999,
        perimeter: 3697.731537,
        kode_pos: 50179,
        kode_kec: 3374120,
        kode_kel: 3374060018,
        kec: "Semarang Utara",
        kel: "Bulu Lor",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.400690808636895, -6.968700302875177],
            [110.401682325337745, -6.96865811871979],
            [110.402484336866564, -6.968659133388177],
            [110.403244016991977, -6.968578683772455],
            [110.404120150182109, -6.968353651777634],
            [110.404328193229745, -6.968263459041681],
            [110.40540829572636, -6.967812544061774],
            [110.405828058506401, -6.967632161893782],
            [110.406387662625448, -6.967379590942707],
            [110.406445383254265, -6.9675967554878],
            [110.407028512885816, -6.967308031272393],
            [110.407288381755421, -6.967172674633593],
            [110.407443282724529, -6.967498506987393],
            [110.407552888411104, -6.96739914382923],
            [110.407712044600771, -6.967715797655384],
            [110.407440350494056, -6.967806050451112],
            [110.407383940548598, -6.967896434767805],
            [110.407400345257216, -6.968574868122412],
            [110.407074968588233, -6.968610642037086],
            [110.407080327141131, -6.969524244562458],
            [110.407126662883712, -6.970021805353385],
            [110.40723049640485, -6.97023902769228],
            [110.407273677464445, -6.97032049138835],
            [110.407550843454018, -6.971505799901391],
            [110.407725930542426, -6.97242866074226],
            [110.407957742608033, -6.973912413816208],
            [110.407959269633395, -6.974048098268687],
            [110.408078006119908, -6.974563840758757],
            [110.408282482516213, -6.975513874806449],
            [110.408320378934036, -6.975749105363195],
            [110.4084146276289, -6.976391454142552],
            [110.408433276055604, -6.976673260022325],
            [110.408436771973356, -6.976726088602987],
            [110.408436767517699, -6.976726088745015],
            [110.408110108063298, -6.976846649706189],
            [110.407447806950458, -6.977013412144349],
            [110.407059309458063, -6.977069376797133],
            [110.407058488532911, -6.977068634045578],
            [110.407016229706329, -6.977077157713705],
            [110.405151701732166, -6.977237630752457],
            [110.40306622681662, -6.977397816714404],
            [110.401519956716186, -6.977513080042981],
            [110.401309383765323, -6.974922457787222],
            [110.401117575436587, -6.972769567363088],
            [110.400889951280192, -6.970451043138938],
            [110.400857950219304, -6.970171570455171],
            [110.400857949778256, -6.970171566572808],
            [110.400690808636895, -6.968700302875177],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 144,
        id: 148,
        area: 1158839.4012500001,
        perimeter: 5524.421813,
        kode_pos: 50151,
        kode_kec: 3374150,
        kode_kel: 3374090004,
        kec: "Tugu",
        kel: "Jerakah",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.361987055731731, -6.986783025340466],
            [110.361820865412625, -6.986765256908496],
            [110.361263303451764, -6.986727508557816],
            [110.360937093934211, -6.986706260107653],
            [110.360688821064826, -6.986682664324171],
            [110.360492911866899, -6.986664045076077],
            [110.360062646948052, -6.986596418566152],
            [110.359401086719473, -6.986470682072935],
            [110.358767298106912, -6.986340358829811],
            [110.358431900345948, -6.986270548091801],
            [110.35812662230056, -6.986170724555626],
            [110.357969379506912, -6.986103467845865],
            [110.357800631158383, -6.985989959143848],
            [110.357680458758225, -6.98588576382498],
            [110.357558098891815, -6.985689092960031],
            [110.35736193631061, -6.985318935476408],
            [110.3572715353598, -6.985182542864316],
            [110.357733909240423, -6.984677826971817],
            [110.357978744929952, -6.98436273159233],
            [110.35806151070544, -6.984222319798967],
            [110.358119185022659, -6.984057669090975],
            [110.35813968737601, -6.983958489547208],
            [110.358157768674104, -6.983767730597862],
            [110.358089841747471, -6.983154999272041],
            [110.358479966864195, -6.982323351707511],
            [110.35849393997718, -6.982241961955773],
            [110.358187030466098, -6.981382229855892],
            [110.358196452506732, -6.981318924754508],
            [110.358514792196132, -6.980640951394924],
            [110.359065015187824, -6.979358047411991],
            [110.359156953435303, -6.979147038389966],
            [110.359261627814519, -6.978930959069452],
            [110.35938853921229, -6.978661191627435],
            [110.359695644373858, -6.978033593064263],
            [110.35994373533228, -6.977513176416542],
            [110.360030560551252, -6.977323340287782],
            [110.360189758343381, -6.976961739214754],
            [110.360367171890204, -6.976518753938342],
            [110.360435325401909, -6.976328892397007],
            [110.360454746628776, -6.976193237363578],
            [110.360618445485017, -6.975849733009338],
            [110.360814172129011, -6.975253000063381],
            [110.361096711089274, -6.97468352071823],
            [110.361278563343916, -6.974303858884761],
            [110.362148041591055, -6.972324085617091],
            [110.362303299698652, -6.971944387283832],
            [110.362376814426156, -6.971763577961987],
            [110.362408633311944, -6.971664121200987],
            [110.362431705187888, -6.971338516927828],
            [110.362450154558772, -6.971293314685084],
            [110.362663529650973, -6.971031285160663],
            [110.362690760976008, -6.970977049323002],
            [110.362705478308641, -6.970343889152355],
            [110.362750961512106, -6.970181132773583],
            [110.363102158702119, -6.969041882116112],
            [110.363323003077213, -6.968065273177854],
            [110.363489230528373, -6.967429162863399],
            [110.363634089123025, -6.966974789394336],
            [110.363917201871487, -6.966196273112664],
            [110.364075250367577, -6.965761662826907],
            [110.364380949730887, -6.965996416178873],
            [110.365382744145222, -6.966766601015584],
            [110.367117165699369, -6.968057199796531],
            [110.366948919155121, -6.968616647328476],
            [110.366072241885135, -6.971550972454152],
            [110.366204470485513, -6.97287671102394],
            [110.366155818678308, -6.974097926195378],
            [110.366199245504973, -6.974265742782413],
            [110.366172102853241, -6.974473726643057],
            [110.366326492964703, -6.974527616503877],
            [110.365797510454541, -6.976061559104078],
            [110.365596813771077, -6.976681860839046],
            [110.365320729021704, -6.977428752097547],
            [110.365020890828589, -6.978259356047769],
            [110.364492384961977, -6.979731696022655],
            [110.364302493693202, -6.980304770665115],
            [110.364153201379949, -6.980937637510388],
            [110.363844204460079, -6.981969421152305],
            [110.363706169079251, -6.982659559991716],
            [110.363621751478235, -6.983106085424883],
            [110.363526640526601, -6.983513944911119],
            [110.363437674679389, -6.984145132293478],
            [110.363333865298657, -6.984628135593308],
            [110.363086180112404, -6.985027199660198],
            [110.362855847057602, -6.985308185287582],
            [110.36267279950502, -6.985584940088104],
            [110.362571489873275, -6.98580812272078],
            [110.362407728497374, -6.986125702225465],
            [110.362162275482177, -6.986473233376262],
            [110.361987055731731, -6.986783025340466],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 145,
        id: 150,
        area: 4826788.2705349997,
        perimeter: 11225.046796000001,
        kode_pos: 50221,
        kode_kec: 3374020,
        kode_kel: 3374020015,
        kec: "Gunungpati",
        kel: "Sukorejo",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.387526583409212, -7.017433559695444],
            [110.387540800315392, -7.017831064355578],
            [110.387569830917187, -7.018156275444531],
            [110.387654714913793, -7.018452714680326],
            [110.387756511066655, -7.018595652264374],
            [110.387877212753025, -7.018751089432224],
            [110.388218411840512, -7.019088849827416],
            [110.388511573834677, -7.019277967973558],
            [110.38890925349871, -7.019455175094272],
            [110.389293012125293, -7.019536735851271],
            [110.389757094902535, -7.019632967549883],
            [110.390134793948064, -7.019721803151007],
            [110.390424047643975, -7.019826586216368],
            [110.39070524720735, -7.019943405641849],
            [110.390841710230191, -7.020092162324682],
            [110.390869558104811, -7.020313059898538],
            [110.390869349191036, -7.020473686113896],
            [110.390897285873692, -7.02062631754452],
            [110.390961788776124, -7.020748117777863],
            [110.391069792390041, -7.020875512527492],
            [110.391282753197359, -7.020923976833679],
            [110.391635345187368, -7.02090598114899],
            [110.391919722523227, -7.020854703961914],
            [110.392195501106798, -7.020790503992095],
            [110.392419625949515, -7.020696110210315],
            [110.392587761392306, -7.020593036186757],
            [110.392760265876177, -7.020442625705906],
            [110.392932820457645, -7.020253480814151],
            [110.393059554949645, -7.020086290455758],
            [110.393146247868174, -7.019917367782742],
            [110.393222068187043, -7.019767522997843],
            [110.393312675293004, -7.019655740762299],
            [110.393433402956191, -7.019569820378845],
            [110.393609230059766, -7.019504770108099],
            [110.393789726300284, -7.019482465344137],
            [110.393928792588596, -7.019516451945706],
            [110.394098938450284, -7.019628740621449],
            [110.394427096117795, -7.019968302129008],
            [110.394788376934201, -7.020322146783059],
            [110.395258122391141, -7.020769025027063],
            [110.395555111205653, -7.021062017615781],
            [110.395806293541256, -7.021351962533585],
            [110.39593633490783, -7.021536357177038],
            [110.396028150528139, -7.0217509026261],
            [110.396114005648258, -7.02198341968657],
            [110.396199966296408, -7.022134164204176],
            [110.396313198330873, -7.022275724816166],
            [110.396691803097724, -7.02274294465371],
            [110.396902102809122, -7.02293478799493],
            [110.397172616930931, -7.023081632678172],
            [110.397428169537591, -7.02316835669424],
            [110.397698901452728, -7.023146166456614],
            [110.397977177032956, -7.023105204084695],
            [110.398229090786003, -7.023098014708292],
            [110.398559824439914, -7.02319610357786],
            [110.39886416272239, -7.02335425971167],
            [110.39914924565258, -7.023524237252952],
            [110.39956663178387, -7.023828457503098],
            [110.399600309052403, -7.023929921604362],
            [110.399606559635671, -7.024072611218049],
            [110.399562288236851, -7.024392781322601],
            [110.399467455853269, -7.024675807772883],
            [110.399352358310338, -7.024975662272189],
            [110.399240721046482, -7.02520810500179],
            [110.399213517800931, -7.025373239734416],
            [110.399286200828016, -7.025548498617892],
            [110.39943243653066, -7.02567352211185],
            [110.399611093520321, -7.025798470828247],
            [110.400008942238358, -7.026011341065058],
            [110.400315700085827, -7.026220723672698],
            [110.400612172723925, -7.026511816901491],
            [110.401047616182865, -7.027053285439512],
            [110.401366795175377, -7.02736922516817],
            [110.401547966594961, -7.027533275983514],
            [110.401756422515803, -7.027680684444414],
            [110.401997127381904, -7.027908276151327],
            [110.403226885113426, -7.029261538314297],
            [110.403754098559631, -7.029747173081953],
            [110.403972791682122, -7.029966220242456],
            [110.40405683708299, -7.030103358234746],
            [110.404107182254393, -7.030250069573889],
            [110.404126150749931, -7.030471266687493],
            [110.404116237650925, -7.030697235176033],
            [110.403968614387693, -7.031355758223],
            [110.403892273338528, -7.031592700816499],
            [110.403857500099448, -7.031688818726552],
            [110.40381306978415, -7.031809926503282],
            [110.403770542407912, -7.031948345883619],
            [110.403749527010575, -7.03215772928865],
            [110.403742233143348, -7.032585812828225],
            [110.403744418096224, -7.032809925539551],
            [110.403772061400758, -7.033007201997478],
            [110.40385941471898, -7.033297202814871],
            [110.404095376612858, -7.033848923480567],
            [110.404133747569475, -7.033950904188924],
            [110.404148956701135, -7.034100936502619],
            [110.40419495432441, -7.034260624204715],
            [110.404367919309351, -7.03448778692998],
            [110.404615816800586, -7.034828516234266],
            [110.40490427326796, -7.035177957327929],
            [110.405169016690124, -7.035553939944629],
            [110.405288374967242, -7.035779453152209],
            [110.405324760074535, -7.035929512350523],
            [110.40533034147856, -7.036046283148174],
            [110.405309095303309, -7.036137202733114],
            [110.405249155636227, -7.036211349708696],
            [110.405005941131208, -7.036436884922783],
            [110.4044225406398, -7.036943837790377],
            [110.404299133426321, -7.037103309924374],
            [110.404193000151395, -7.037303192105202],
            [110.404144670087135, -7.037462759740275],
            [110.404154138401339, -7.037585859333753],
            [110.404186658390159, -7.037747453127139],
            [110.404294113555892, -7.038020690382901],
            [110.404409785603519, -7.038257227395525],
            [110.404504039007662, -7.03843850752041],
            [110.40465222009324, -7.038654427836941],
            [110.404824015045094, -7.038873748957651],
            [110.404877797258607, -7.039032245394923],
            [110.404897817965988, -7.039207552941984],
            [110.404890726434957, -7.039430227820655],
            [110.404890722595752, -7.039430241271276],
            [110.404882505687041, -7.039429105887696],
            [110.404443910209551, -7.039368502090519],
            [110.404192762122236, -7.039315574705873],
            [110.404099155418791, -7.039279273628932],
            [110.403959445525345, -7.039156991628178],
            [110.403873589333969, -7.03910712238349],
            [110.403757878818709, -7.039097929499247],
            [110.403642474347336, -7.039070646034941],
            [110.403477059006022, -7.038989025973287],
            [110.403386680297956, -7.038862274172272],
            [110.403284828255366, -7.038636007552839],
            [110.403168263498372, -7.038409722155213],
            [110.403139421318102, -7.038174503142943],
            [110.403052236078338, -7.037984437073455],
            [110.40290640177183, -7.037857614469067],
            [110.402527409653416, -7.037522448456749],
            [110.401929563195651, -7.037078456978235],
            [110.401623102013232, -7.036888110489215],
            [110.401462769113508, -7.036815541766418],
            [110.401167602620347, -7.036643300383834],
            [110.400452956451517, -7.036380067124883],
            [110.399489973854386, -7.036053196107937],
            [110.399154561838074, -7.035935174717836],
            [110.398891760040556, -7.035898655512495],
            [110.398406073978293, -7.035807577035847],
            [110.398187045992543, -7.035834431934093],
            [110.397720142079919, -7.035879058844098],
            [110.397442887394305, -7.035860611168771],
            [110.39697582891003, -7.035805737053967],
            [110.396523553365768, -7.035696608772896],
            [110.396027376149334, -7.035623605280984],
            [110.395633292395658, -7.035586914909579],
            [110.394984802048, -7.035586077365827],
            [110.394488402640121, -7.035684935676643],
            [110.393889874721452, -7.035774615782607],
            [110.393451677715404, -7.035936866492181],
            [110.392969919943368, -7.036090014830378],
            [110.392444142049357, -7.036369741873012],
            [110.392181270798474, -7.036604582434402],
            [110.391976802024914, -7.036749044155693],
            [110.39168474040936, -7.036802937432968],
            [110.391378284301851, -7.03682967545167],
            [110.39111538868643, -7.036865515380024],
            [110.390948208291888, -7.036838161542126],
            [110.390870683559896, -7.036838060670603],
            [110.390715009247657, -7.036883085329724],
            [110.390559712235415, -7.036855746821447],
            [110.390229359913207, -7.036764862118003],
            [110.389957233674707, -7.036719280321356],
            [110.389763280758984, -7.036610482136759],
            [110.389258322210679, -7.036329414715529],
            [110.389005801780172, -7.036220539833828],
            [110.388355001726197, -7.036038781073573],
            [110.387888487086656, -7.036001989570375],
            [110.387754080488875, -7.036010859266819],
            [110.38766466076251, -7.036019787766337],
            [110.387494922691772, -7.036001474837406],
            [110.387441200369182, -7.03597426821244],
            [110.38737422748396, -7.035910862460444],
            [110.387315954480798, -7.035901740758901],
            [110.387208745377833, -7.035883509555646],
            [110.387146240203052, -7.03586533684879],
            [110.38707017782616, -7.035829055495066],
            [110.386985320709726, -7.035810853504787],
            [110.386869033954184, -7.035810701230401],
            [110.386743657632891, -7.035837673355083],
            [110.386587193943484, -7.035837468381834],
            [110.386484240815534, -7.035810197148205],
            [110.386341441224999, -7.035746691891042],
            [110.386260816324153, -7.035737540764132],
            [110.386158110533529, -7.035737406117573],
            [110.386068431687008, -7.035728243087244],
            [110.386014980543152, -7.035710082107127],
            [110.385916578235978, -7.035664725834967],
            [110.385827194236128, -7.035646517703504],
            [110.38570185359545, -7.035646353265775],
            [110.385530924584671, -7.035673265293512],
            [110.385393959775541, -7.035691176401639],
            [110.38532237697035, -7.03569108242753],
            [110.385218316027817, -7.035645718585539],
            [110.385146733231949, -7.035645624585181],
            [110.385094661115119, -7.035654601640775],
            [110.385010027554074, -7.035681626804426],
            [110.384899045220124, -7.035735753660917],
            [110.38476883512179, -7.035780809780946],
            [110.384645120618615, -7.03583491984124],
            [110.384547436207001, -7.035889064085385],
            [110.384423745485023, -7.035925083231986],
            [110.38426724607838, -7.035952013755447],
            [110.384150086696778, -7.035969950535639],
            [110.384071972490361, -7.035987938655363],
            [110.384019852629706, -7.036033097283734],
            [110.383954681920954, -7.036105375052113],
            [110.383850171132181, -7.036186646474862],
            [110.383759017957544, -7.036222708256242],
            [110.383633111338781, -7.0362225425064],
            [110.383560384798159, -7.036231492189311],
            [110.383519877183687, -7.036267620606116],
            [110.383483330670629, -7.036303754235296],
            [110.383446760281174, -7.036357978707637],
            [110.383394285766244, -7.036457409423141],
            [110.383345549089213, -7.036511617860344],
            [110.383296860155241, -7.036529644599573],
            [110.383236299829122, -7.036538610254505],
            [110.38317972449255, -7.036529490275121],
            [110.383114979830225, -7.036493223208886],
            [110.383046286013169, -7.036447905491702],
            [110.382993695690161, -7.036420699866368],
            [110.382941376364656, -7.03640254003096],
            [110.382896672430959, -7.036402481104761],
            [110.382848254484685, -7.036429553592089],
            [110.382763337748514, -7.036456577952173],
            [110.382682417963423, -7.036456471253574],
            [110.382601474275702, -7.036474455384569],
            [110.382532660984779, -7.03651959181326],
            [110.382443416486893, -7.036609928464641],
            [110.382378847207832, -7.036655070466144],
            [110.38223305124086, -7.036718196146419],
            [110.382098018785967, -7.036772290548734],
            [110.381989052353433, -7.036799283013377],
            [110.381919697019157, -7.036826327753973],
            [110.381889386915461, -7.036853424041535],
            [110.381873211667639, -7.036889584427787],
            [110.381848157677638, -7.037007142013657],
            [110.381774733471659, -7.037115590279542],
            [110.38169791398974, -7.037224034045663],
            [110.381639015264412, -7.03726013798507],
            [110.381607031440325, -7.0372691411678],
            [110.38158213300693, -7.037269108273677],
            [110.381551870749206, -7.037260022855947],
            [110.381526996264753, -7.037241899119641],
            [110.381498455579617, -7.03721472510249],
            [110.381464539100776, -7.037187543980336],
            [110.38143061065017, -7.037169408275552],
            [110.381398638806104, -7.037169366024812],
            [110.381362976808404, -7.037178364330186],
            [110.381286253055237, -7.037214444665337],
            [110.381254281208967, -7.037214402404468],
            [110.381218631185945, -7.037214355279155],
            [110.381181012587064, -7.037205260113756],
            [110.381150208432658, -7.037178083084939],
            [110.381089743863512, -7.037114685099499],
            [110.381049307892852, -7.03709654076457],
            [110.380984527394048, -7.037087409668446],
            [110.380927940074301, -7.037087334833831],
            [110.3808430351217, -7.037105313406146],
            [110.380689340597783, -7.037150337258566],
            [110.380608360765663, -7.037195457281478],
            [110.380527332944922, -7.037276758960632],
            [110.380438394862722, -7.037349004719332],
            [110.380373542368957, -7.037394146042462],
            [110.380248045399242, -7.037511570529628],
            [110.380179243750362, -7.037547661159946],
            [110.380118412331058, -7.037547580602573],
            [110.380061836967826, -7.037538460241367],
            [110.38000133650425, -7.037502198375929],
            [110.379940565118901, -7.037456890711789],
            [110.379839688771028, -7.037357257311401],
            [110.379827594535627, -7.03730296869199],
            [110.379860192284681, -7.037257784716671],
            [110.37991260759577, -7.037203581558708],
            [110.379953163566924, -7.037131271818994],
            [110.379961428748288, -7.037086055602534],
            [110.379953271605473, -7.037049863065954],
            [110.37992925801511, -7.037022694958313],
            [110.379896744327695, -7.037004561022417],
            [110.379864489567737, -7.037004518293074],
            [110.379795711986759, -7.037022518038213],
            [110.379613675725068, -7.037103685708642],
            [110.37949622102424, -7.037130666323598],
            [110.379460005146427, -7.037130618314796],
            [110.379415313187337, -7.037121513634002],
            [110.379354812832432, -7.037085251693472],
            [110.379260444232116, -7.03698562681271],
            [110.37915959217365, -7.036867902453916],
            [110.379109066765452, -7.036777381125352],
            [110.379095816840064, -7.036741181824701],
            [110.379101240710142, -7.036705007292256],
            [110.379133296644511, -7.036641731786844],
            [110.379183731404012, -7.036587526083675],
            [110.379226514857734, -7.036542355659844],
            [110.379239848909378, -7.036515237045588],
            [110.379237067616259, -7.036479051629776],
            [110.379231727881177, -7.036451908253315],
            [110.379205167938778, -7.036424736737574],
            [110.379157411830036, -7.036379446247457],
            [110.379051087969955, -7.036334078072938],
            [110.378874439056105, -7.03627957114517],
            [110.378921779788882, -7.036125861616757],
            [110.378972135937673, -7.035917883489597],
            [110.379110370343099, -7.035583385850818],
            [110.379147946166356, -7.035411572468494],
            [110.379135629193371, -7.035312056381633],
            [110.379067068177278, -7.03516723854893],
            [110.378917105996251, -7.034958994718643],
            [110.378623117972225, -7.034759605179236],
            [110.378260292791751, -7.034623442098588],
            [110.377997223473159, -7.034577865577046],
            [110.377756162926872, -7.034577545309391],
            [110.377575048451632, -7.034604440892751],
            [110.377258335823299, -7.034685428666705],
            [110.376684820130166, -7.034901755866027],
            [110.376537645642159, -7.034937741638759],
            [110.376420793464078, -7.034937586035008],
            [110.376364230593893, -7.034919419848328],
            [110.376322675355709, -7.03489222821727],
            [110.376288819724266, -7.034819819700872],
            [110.376273944885639, -7.034729345610838],
            [110.376277888605415, -7.034530351454735],
            [110.376300692375608, -7.034403745839326],
            [110.376384056213979, -7.034268175445406],
            [110.376467197400288, -7.034087377594171],
            [110.376482030658451, -7.033996943061786],
            [110.37651071563117, -7.033915572398417],
            [110.376608015475654, -7.033725747930974],
            [110.376642383216719, -7.033626293962279],
            [110.376722598344813, -7.03351785557865],
            [110.376825532575438, -7.033346129404672],
            [110.376940091133051, -7.033156327805743],
            [110.377051994707841, -7.033047931504463],
            [110.377076670225605, -7.033002737175406],
            [110.37715032945583, -7.032930471697673],
            [110.377216361499634, -7.032849150634646],
            [110.377289773895185, -7.032749748512039],
            [110.377392412754688, -7.032587067212853],
            [110.377484215754905, -7.032487689496754],
            [110.377554750527494, -7.032424465219569],
            [110.377575711697858, -7.032406402212276],
            [110.377655583253159, -7.032343190324246],
            [110.377792854177798, -7.032307190962829],
            [110.377981311983717, -7.032289350397444],
            [110.378101335962583, -7.032244282607377],
            [110.378124349827246, -7.032171949707871],
            [110.378124163142829, -7.032099586006137],
            [110.37810736760639, -7.03196388223202],
            [110.378113266891077, -7.031782981427522],
            [110.378165013361027, -7.031593096049498],
            [110.378222478582501, -7.031357991089922],
            [110.378319861318786, -7.031104848223009],
            [110.378331431160973, -7.030914909493173],
            [110.378314141861154, -7.030724932467517],
            [110.378251571344578, -7.030543940786157],
            [110.378154693888064, -7.030417176162894],
            [110.377989100018937, -7.030263184007752],
            [110.377606960756651, -7.029973222734538],
            [110.377356006056317, -7.029755798972292],
            [110.377145065434377, -7.02944797397151],
            [110.377031218799061, -7.029104096256088],
            [110.377019685037496, -7.028841763421699],
            [110.377019913923732, -7.028669900533675],
            [110.376980382022438, -7.028398485045998],
            [110.376963472583583, -7.028136145060508],
            [110.37698659477536, -7.027982403463563],
            [110.377101163808476, -7.027783556245094],
            [110.377215943424673, -7.027638981859932],
            [110.377370122547603, -7.027440187184033],
            [110.377434678862727, -7.027404091202684],
            [110.377479405960543, -7.027386059741867],
            [110.377549006874091, -7.027386152173304],
            [110.377648291431981, -7.02740437487283],
            [110.377827067463556, -7.027431748505529],
            [110.377931209974179, -7.027413795862293],
            [110.37812003288272, -7.027332637516038],
            [110.378234457029677, -7.027242334972984],
            [110.378567459622616, -7.027034731577639],
            [110.378766292934017, -7.026872177304354],
            [110.378960117404674, -7.026646298251441],
            [110.379044351730244, -7.026492637435719],
            [110.379080391876613, -7.026411276253293],
            [110.379175082522963, -7.026266674690186],
            [110.379250936795898, -7.026031593802235],
            [110.37929840203509, -7.025868838797925],
            [110.379341635288824, -7.025697032746293],
            [110.379382015219591, -7.025543313782477],
            [110.379373062396482, -7.025253847967163],
            [110.379281108334908, -7.024828590732742],
            [110.379188775501149, -7.024475696477229],
            [110.378986148612825, -7.024086474418464],
            [110.378798975069515, -7.02377868168917],
            [110.378645060442665, -7.023353342300805],
            [110.378494629279729, -7.023018289632077],
            [110.378518577800207, -7.022862177735656],
            [110.378530181784555, -7.022658377296],
            [110.378536261819306, -7.022347000074807],
            [110.378587773167624, -7.021962082877023],
            [110.378630787462868, -7.021743235741621],
            [110.378701018532226, -7.021481011099661],
            [110.37877885253782, -7.021245932816167],
            [110.378848704516926, -7.021056071128429],
            [110.378912262281716, -7.020841525497231],
            [110.378969237035548, -7.020609477312482],
            [110.378986634992316, -7.020309438114396],
            [110.378987040365843, -7.020003714868746],
            [110.378959179882685, -7.019647000245136],
            [110.378987918613049, -7.019341314489445],
            [110.379100972812381, -7.018786000122684],
            [110.379227472009163, -7.01816969016483],
            [110.379318573629845, -7.017841440614713],
            [110.379426480096285, -7.017660413617037],
            [110.379612126631798, -7.017574587227499],
            [110.379840288359674, -7.017553391041901],
            [110.379987576434871, -7.017587554941372],
            [110.380123418866106, -7.017706626980374],
            [110.38025920145482, -7.017870991333599],
            [110.380462811874793, -7.018165660924426],
            [110.381118776053782, -7.019202590758651],
            [110.3818370691274, -7.020245263556986],
            [110.382080482812398, -7.020438077047572],
            [110.382295734977717, -7.020500637589416],
            [110.382560890787829, -7.020491108453156],
            [110.382711255985214, -7.020391806425498],
            [110.382801747976728, -7.020211016591635],
            [110.382903033459598, -7.019771096063709],
            [110.383506205590095, -7.017869605922181],
            [110.383687799459693, -7.017671689914388],
            [110.383897562681199, -7.017598365056761],
            [110.384526569385244, -7.017593528716314],
            [110.385104769385279, -7.017441424454449],
            [110.385699835431623, -7.017391249585311],
            [110.387144730058353, -7.01747238345578],
            [110.387526583409212, -7.017433559695444],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 146,
        id: 151,
        area: 531659.94617400004,
        perimeter: 3692.970564,
        kode_pos: 50245,
        kode_kec: 3374050,
        kode_kel: 3374030036,
        kec: "Semarang Selatan",
        kel: "Barusari",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.406838317316158, -6.994454837891824],
            [110.406398919355325, -6.994588461260594],
            [110.406170460602269, -6.994705764603882],
            [110.405959183286583, -6.994951274295608],
            [110.405767953629862, -6.99520655953529],
            [110.405600174425615, -6.995430845160781],
            [110.405411937799769, -6.995679595500504],
            [110.405285170931037, -6.995777397885955],
            [110.405191137722511, -6.995834423883705],
            [110.405076693707642, -6.995879178757756],
            [110.404823325087705, -6.995944166847607],
            [110.404624329351833, -6.995949502635215],
            [110.404438863414043, -6.995958626429098],
            [110.404274012973389, -6.995960289537729],
            [110.404021123432642, -6.995958097799655],
            [110.403640801450237, -6.995995049684423],
            [110.403255330483205, -6.99604081135131],
            [110.403016937252673, -6.99604479421696],
            [110.402790306852339, -6.996016431675193],
            [110.402610546378128, -6.995958181718724],
            [110.402315978305765, -6.995829823690687],
            [110.402209073136035, -6.995754138259762],
            [110.40216730309379, -6.995724566300454],
            [110.402029787964409, -6.995627084547146],
            [110.402292083942214, -6.994874808414673],
            [110.402385878823964, -6.994482485504386],
            [110.402446533353441, -6.99413451485869],
            [110.402468834389566, -6.993710542062439],
            [110.402371602744452, -6.993161391626511],
            [110.402376255213909, -6.992684039762572],
            [110.402403008484313, -6.992593880206329],
            [110.402450657318624, -6.99088928076895],
            [110.402450254129732, -6.990875404468225],
            [110.402768185295386, -6.990893311604562],
            [110.403022521274835, -6.990893633946487],
            [110.403225967916427, -6.990866755227413],
            [110.403373998629874, -6.990812669778716],
            [110.403460400859956, -6.990722324296861],
            [110.403553130304346, -6.990550577403129],
            [110.403747676480691, -6.990179958570873],
            [110.403946251831073, -6.989755071782846],
            [110.40409456857688, -6.989474849169516],
            [110.404239004594331, -6.989131303149403],
            [110.404412977394358, -6.98869733947888],
            [110.40463695096129, -6.987874482722554],
            [110.405023927187443, -6.986789512238582],
            [110.406576988750246, -6.983419604844292],
            [110.406657101770307, -6.983246047114641],
            [110.406840427455307, -6.983327543278868],
            [110.408550641066569, -6.984088965791624],
            [110.408614858478387, -6.984118312214658],
            [110.40895095691279, -6.984292291606834],
            [110.409338476935915, -6.984721133158246],
            [110.409552636325486, -6.984795535167678],
            [110.409410069391896, -6.985084846473827],
            [110.408887817513559, -6.986144649286833],
            [110.408336512282844, -6.987227865465227],
            [110.408017466326712, -6.988155025690617],
            [110.407801345159442, -6.988548356269527],
            [110.407535432433747, -6.988966483195071],
            [110.406903740818535, -6.990076060573832],
            [110.406479892199769, -6.990779867215627],
            [110.406244749046309, -6.991214298132331],
            [110.406201186425903, -6.991475569964725],
            [110.406838317316158, -6.994454837891824],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 147,
        id: 152,
        area: 942939.391053,
        perimeter: 4332.286235,
        kode_pos: 50256,
        kode_kec: 3374060,
        kode_kel: 3374051003,
        kec: "Candisari",
        kel: "Jomblang",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.44482071564201, -7.011468844510691],
            [110.444757192864117, -7.01159565658914],
            [110.444604178158329, -7.012020616314071],
            [110.444199648713393, -7.013168921920816],
            [110.444011729926913, -7.013693341011846],
            [110.443919839473793, -7.013956137969156],
            [110.443875951772014, -7.014081652020572],
            [110.443572268504354, -7.01495015091445],
            [110.443556523932571, -7.014995113839905],
            [110.443285535084044, -7.015772955313914],
            [110.443253739813443, -7.01586337302333],
            [110.44297038954025, -7.016695226664725],
            [110.442863202366098, -7.016978872867215],
            [110.442621687140075, -7.016930400875795],
            [110.44252876448931, -7.016911795381747],
            [110.442389596433387, -7.016884493470536],
            [110.442242767820375, -7.01687527349594],
            [110.442041986232738, -7.016793625038131],
            [110.441748382985693, -7.016729957202464],
            [110.441462647888741, -7.016711526241875],
            [110.441154572355629, -7.016684022976397],
            [110.440934422515312, -7.016710897457233],
            [110.440806571741248, -7.016683608566238],
            [110.440620230620283, -7.016592931189567],
            [110.440550706110201, -7.016529529581842],
            [110.44049674261359, -7.016466146511397],
            [110.440373265475912, -7.016330316281516],
            [110.440249766800292, -7.016212577071439],
            [110.440141861587335, -7.016067719819523],
            [110.439956466919469, -7.015895633508639],
            [110.439894875363024, -7.015823195743736],
            [110.439705716323445, -7.015723469143518],
            [110.439689894058048, -7.015705359189316],
            [110.439441821465394, -7.015659835391633],
            [110.439204238862544, -7.015596232925832],
            [110.439193803050529, -7.015569083855153],
            [110.439009367412766, -7.015541726914513],
            [110.438945741426593, -7.015514514282691],
            [110.438917198335048, -7.015487343569136],
            [110.438842799494907, -7.015478209119834],
            [110.438764973044712, -7.015496207165626],
            [110.438747116177794, -7.015523322423747],
            [110.438708039818621, -7.015550412313916],
            [110.438672674001978, -7.015550370033324],
            [110.438586370574697, -7.015559312378864],
            [110.438518424859382, -7.015595413265057],
            [110.438434047715802, -7.015649585555617],
            [110.438406299232568, -7.015667643433291],
            [110.438291431038124, -7.015667506020102],
            [110.438212211595001, -7.015667411236012],
            [110.438144885913502, -7.015658285138397],
            [110.438085525560552, -7.015612986430392],
            [110.438034108854112, -7.015549606156727],
            [110.437998557736748, -7.015468153802567],
            [110.437978839662122, -7.015395765932434],
            [110.437939284204404, -7.015350490915412],
            [110.437887845847712, -7.015305201669633],
            [110.437820487677783, -7.015323212090787],
            [110.437650068335344, -7.015404417807355],
            [110.437507364872047, -7.01549470219514],
            [110.4373290017584, -7.015593989333159],
            [110.437257617391907, -7.015666268036897],
            [110.437182239442407, -7.015765678536828],
            [110.437126688053894, -7.015847021728322],
            [110.437027598535224, -7.015901176110285],
            [110.436777676456742, -7.01598228613962],
            [110.43649437982306, -7.016054310440076],
            [110.436260268845984, -7.016162575749197],
            [110.435972379894721, -7.016288867385243],
            [110.435647438268433, -7.016406068760967],
            [110.435381323504515, -7.016541431713248],
            [110.435244593468042, -7.016604585945225],
            [110.435138985370926, -7.016667777583543],
            [110.434964952525775, -7.016694704727342],
            [110.434861912537031, -7.016739808333201],
            [110.434714180962644, -7.016775812571404],
            [110.434544685814018, -7.016793699490728],
            [110.434320618489963, -7.016784384007142],
            [110.434167511258821, -7.016820381590898],
            [110.433959810006158, -7.016847267768746],
            [110.433806735525465, -7.016856128690711],
            [110.433755797719954, -7.016865112776131],
            [110.433686480584413, -7.016865029156972],
            [110.433640102409299, -7.016846882151366],
            [110.433599121719766, -7.016810650601451],
            [110.433573158037234, -7.016756346116082],
            [110.433566433394802, -7.016702064844417],
            [110.433615979749092, -7.016439804360704],
            [110.433670846985933, -7.016222777916253],
            [110.433714930163958, -7.016032875032069],
            [110.433703939749407, -7.015996679668371],
            [110.433649400512849, -7.015942340719012],
            [110.433562063682601, -7.015869871141851],
            [110.433463681814274, -7.015806433748926],
            [110.433369282816074, -7.015724910091556],
            [110.433292697271696, -7.015652453445546],
            [110.433211302004281, -7.015579990982435],
            [110.433193238480882, -7.015543787071358],
            [110.433184283298303, -7.015462366525107],
            [110.433134564869619, -7.015398987818066],
            [110.433048914835794, -7.015335565720555],
            [110.432931294178246, -7.015272104997927],
            [110.432777763841571, -7.015190509817327],
            [110.432642025048537, -7.015136072665213],
            [110.432411538960011, -7.015054384378346],
            [110.432176548062628, -7.014954599487163],
            [110.43195511578574, -7.0148729219376],
            [110.431828724711139, -7.014809450348378],
            [110.431584702396975, -7.014691563203437],
            [110.431367852042797, -7.014564663321853],
            [110.431046043728827, -7.014410499621938],
            [110.431229464844407, -7.013895126972509],
            [110.431502623817892, -7.013316544170622],
            [110.431777718693539, -7.012774145584414],
            [110.431806655809325, -7.012475678261826],
            [110.431807127142889, -7.012086721245945],
            [110.431773353700393, -7.011706768327705],
            [110.43174569499827, -7.01141727805682],
            [110.431727298124471, -7.010955934000223],
            [110.431709074569639, -7.010585045404153],
            [110.431718904232739, -7.010178008630547],
            [110.431766033821717, -7.010042382739366],
            [110.431859672282641, -7.009816357814928],
            [110.432056555494086, -7.009608548713403],
            [110.432253177503966, -7.009382648144072],
            [110.432440712974127, -7.009183873083313],
            [110.43250635255545, -7.008948768639242],
            [110.432563551741737, -7.008605036006059],
            [110.432623909025352, -7.008596134997543],
            [110.432682723994361, -7.00862334258335],
            [110.43281760126618, -7.0086868240782],
            [110.433391605410648, -7.008958882347275],
            [110.433628219868893, -7.009061666994556],
            [110.434059801628294, -7.00924914447129],
            [110.434795149962113, -7.009222892900585],
            [110.435709055077922, -7.009169718021178],
            [110.435741090288388, -7.009175168572606],
            [110.436088569993402, -7.009234289498533],
            [110.436458647717757, -7.009297255003697],
            [110.43860371643315, -7.00981541811952],
            [110.43866225985829, -7.009833579133754],
            [110.438699251104154, -7.00984425959374],
            [110.438964030491221, -7.009920709088216],
            [110.439413052017727, -7.010050354245969],
            [110.43988915905048, -7.010187819215907],
            [110.440396550894462, -7.010333152415831],
            [110.440513072203231, -7.010369473337261],
            [110.440738486731362, -7.010433060478242],
            [110.440749769888683, -7.010436468742562],
            [110.441039126663838, -7.010523873635514],
            [110.441077276160073, -7.01053469048677],
            [110.441391347671455, -7.01062351077598],
            [110.442021920409076, -7.010832590226254],
            [110.442489432253282, -7.010968828359879],
            [110.442788439889526, -7.011005365257514],
            [110.442866788343224, -7.011023549260288],
            [110.442985126909264, -7.011055493655658],
            [110.443001417197209, -7.011059891064755],
            [110.443119940885779, -7.011078122656569],
            [110.44335896876602, -7.01111458809917],
            [110.44341159264566, -7.011114650447246],
            [110.443473825210376, -7.011123769715314],
            [110.443732690032604, -7.011133121838954],
            [110.443871029198249, -7.011142331163061],
            [110.444040196379973, -7.011160622473882],
            [110.444188427191648, -7.011178888955693],
            [110.44423283556533, -7.011187987037065],
            [110.444377051873587, -7.011251476434752],
            [110.444535403722497, -7.011324028049563],
            [110.444550817164981, -7.011331702835044],
            [110.444626451772493, -7.011369364924527],
            [110.44482071564201, -7.011468844510691],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 148,
        id: 153,
        area: 1211871.4603909999,
        perimeter: 4717.900062,
        kode_pos: 50248,
        kode_kec: 3374100,
        kode_kel: 3374042002,
        kec: "Gayamsari",
        kel: "Gayamsari",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.451610521182843, -7.004123047572086],
            [110.451572442628446, -7.004133181207446],
            [110.451204315681224, -7.004503297244326],
            [110.451039965584087, -7.004668535071527],
            [110.450608409350608, -7.005102421461666],
            [110.449978896677877, -7.005723946576735],
            [110.44953602937025, -7.006207608023293],
            [110.44928692221994, -7.005628871874265],
            [110.449204909130898, -7.005402017401071],
            [110.449131444237523, -7.005149662612218],
            [110.449108998070713, -7.004937050311488],
            [110.449093401915889, -7.004509048269608],
            [110.44906621342929, -7.004291923114124],
            [110.448990330849568, -7.004101877567361],
            [110.448910140814917, -7.003966100289061],
            [110.448740873526376, -7.003794036376729],
            [110.448622519611206, -7.003677971031476],
            [110.448350392269674, -7.003490576234968],
            [110.448046238066041, -7.003309226892495],
            [110.447876269459755, -7.003220475017602],
            [110.447673477024622, -7.003116508292464],
            [110.447493223893957, -7.003035740537653],
            [110.447392351154022, -7.002998780887784],
            [110.447244210496123, -7.002944502281314],
            [110.447057437833834, -7.002886843374166],
            [110.446801224353635, -7.002807562965692],
            [110.446554586876687, -7.002733080305616],
            [110.446386963388363, -7.002687410210311],
            [110.446212142486246, -7.002651304810708],
            [110.446076464863367, -7.002633599336797],
            [110.446008565683996, -7.002624738706992],
            [110.445814559157611, -7.002607757019208],
            [110.445668466025126, -7.002586045108764],
            [110.445515223492833, -7.002533211741647],
            [110.44537158232886, -7.002463636503471],
            [110.445215981251479, -7.002379687280223],
            [110.445084353874819, -7.00227901314782],
            [110.444994466512071, -7.002208116372894],
            [110.444892901376591, -7.002128008954668],
            [110.444787612035654, -7.0020369390542],
            [110.44468948058136, -7.001969810645096],
            [110.444390662348312, -7.001869490098534],
            [110.444671852541703, -7.000976083550134],
            [110.444831136247487, -7.000496857422617],
            [110.44493660113983, -7.000071841020427],
            [110.444944256281119, -6.999818574656292],
            [110.445282337614373, -6.999104374847607],
            [110.445748322656897, -6.998128004341418],
            [110.445946646518863, -6.997648823588486],
            [110.446089422482586, -6.997250987322226],
            [110.446202460061215, -6.99688025266134],
            [110.44630019869291, -6.996527591065975],
            [110.446368241715547, -6.996165848959968],
            [110.44635783191444, -6.995876379009221],
            [110.446362351530013, -6.995161785589446],
            [110.446559503661589, -6.994474555247685],
            [110.446743396277753, -6.993995356942131],
            [110.446806688314112, -6.99382356575205],
            [110.446792766139083, -6.993633592744653],
            [110.446813872041758, -6.993488888656072],
            [110.446905551090339, -6.993235720785796],
            [110.447060100615161, -6.992928353366636],
            [110.447404482190763, -6.992141794138384],
            [110.447580659855362, -6.991725905169871],
            [110.44769344357023, -6.991328032902068],
            [110.447827251328178, -6.990613590711376],
            [110.447905240095778, -6.989971447495257],
            [110.447950269200675, -6.989691087951516],
            [110.448038799242411, -6.989709282777274],
            [110.448396052730118, -6.989763974469432],
            [110.448893672823459, -6.989782647848278],
            [110.449370399895997, -6.98976511404938],
            [110.449747814445885, -6.989756509364897],
            [110.450336532219907, -6.989775287593815],
            [110.450750138379917, -6.989784815446349],
            [110.451114496855226, -6.989812376699005],
            [110.451250771054177, -6.989821404021439],
            [110.452447903469988, -6.989900704936116],
            [110.452495491087689, -6.990219917797195],
            [110.452509470620726, -6.990319677987417],
            [110.45275395181055, -6.992064329936925],
            [110.453404861249751, -6.996642187207706],
            [110.453520170710092, -6.997417402486353],
            [110.453555839501846, -6.997657200220387],
            [110.453568901526495, -6.997752365700918],
            [110.453675368381582, -6.998528046038764],
            [110.45372827745669, -6.99891113958142],
            [110.453759693838322, -6.999164016400036],
            [110.453779367541244, -6.999413369555305],
            [110.453792305534307, -6.999732527378485],
            [110.453774722148481, -7.000051739440156],
            [110.453752658853446, -7.000315209508163],
            [110.453725531115069, -7.000511646586093],
            [110.453689159702037, -7.00071683017219],
            [110.45363518673922, -7.000956547733828],
            [110.453589843892871, -7.001154153205766],
            [110.453545362963482, -7.001315800545122],
            [110.45350091765259, -7.001446810212792],
            [110.453443547559772, -7.001607006222281],
            [110.453357202257351, -7.001831278675179],
            [110.453207133614313, -7.002137747051539],
            [110.453045853804298, -7.002429244140697],
            [110.452828375185533, -7.002769289877784],
            [110.452640938905503, -7.003023360898506],
            [110.452431054336841, -7.003269926600252],
            [110.452175293000195, -7.003527071478262],
            [110.451610521182843, -7.004123047572086],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 149,
        id: 154,
        area: 735226.78645899997,
        perimeter: 3611.274746,
        kode_pos: 50248,
        kode_kec: 3374050,
        kode_kel: 3374030044,
        kec: "Semarang Selatan",
        kel: "Lamper Tengah",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.44953602937025, -7.006207608023293],
            [110.448851855319845, -7.006860613042567],
            [110.44843264200712, -7.007276212828215],
            [110.448115973474273, -7.007583389895951],
            [110.447959619508595, -7.007736980662193],
            [110.447618010582957, -7.008080310249145],
            [110.447273006085339, -7.008423635550844],
            [110.446889480917292, -7.008803097417721],
            [110.446069730217658, -7.009625276764041],
            [110.445920426617135, -7.009796966140643],
            [110.445671656400847, -7.010104221332618],
            [110.445450361543195, -7.010384372153195],
            [110.445358533796139, -7.010519946959083],
            [110.445267250443905, -7.010673613477335],
            [110.445071364713542, -7.010999021803422],
            [110.444976653959401, -7.011179820835889],
            [110.444963149796706, -7.01120486969855],
            [110.444862455135876, -7.011391520096918],
            [110.44482071564201, -7.011468844510691],
            [110.444626451772493, -7.011369364924527],
            [110.444550817164981, -7.011331702835044],
            [110.444535403722497, -7.011324028049563],
            [110.444377051873587, -7.011251476434752],
            [110.44423283556533, -7.011187987037065],
            [110.444188427191648, -7.011178888955693],
            [110.444040196379973, -7.011160622473882],
            [110.443871029198249, -7.011142331163061],
            [110.443732690032604, -7.011133121838954],
            [110.443473825210376, -7.011123769715314],
            [110.44341159264566, -7.011114650447246],
            [110.44335896876602, -7.01111458809917],
            [110.443119940885779, -7.011078122656569],
            [110.443001417197209, -7.011059891064755],
            [110.442985126909264, -7.011055493655658],
            [110.442866788343224, -7.011023549260288],
            [110.442788439889526, -7.011005365257514],
            [110.442489432253282, -7.010968828359879],
            [110.442021920409076, -7.010832590226254],
            [110.441391347671455, -7.01062351077598],
            [110.44150788782143, -7.010260672955537],
            [110.441699074922894, -7.009665426759985],
            [110.441733018885884, -7.009360455386107],
            [110.44174238059604, -7.009189659952371],
            [110.441730387143849, -7.009006638679709],
            [110.441712269928772, -7.008838860716801],
            [110.441672808478202, -7.008649706576239],
            [110.441611991930856, -7.008448326592989],
            [110.441548166191836, -7.008210341623468],
            [110.441496682428621, -7.007862567097885],
            [110.441490932632803, -7.007563648786332],
            [110.441452047086784, -7.006681906962671],
            [110.44135889292474, -7.006258215430901],
            [110.441308109394626, -7.005907737643803],
            [110.441282917090732, -7.005564963638409],
            [110.441257654772258, -7.00528101875299],
            [110.441196579492242, -7.004974011181064],
            [110.441138064302407, -7.004667006646116],
            [110.441139696235126, -7.004237342084289],
            [110.441149496619104, -7.00394858589806],
            [110.44108362990842, -7.003583097216893],
            [110.440994244522273, -7.003211169867163],
            [110.440958107780602, -7.002912227613101],
            [110.440902366385174, -7.002013994315172],
            [110.440831271601638, -7.00149458203422],
            [110.440737300162127, -7.000985347838164],
            [110.440712814922009, -7.000624362530541],
            [110.440704927974437, -7.000411274502704],
            [110.440698577598667, -7.000236886984338],
            [110.440823251314598, -7.00029229147415],
            [110.441524424771828, -7.000604474915407],
            [110.441874492503203, -7.000736581276563],
            [110.442115404307614, -7.000823406780675],
            [110.442314914146721, -7.000891370097576],
            [110.442499378494048, -7.000944265108846],
            [110.442777935709898, -7.001042422696622],
            [110.443248400107578, -7.001268735663007],
            [110.443628786889207, -7.001487374985509],
            [110.44400464361847, -7.001730655980596],
            [110.444390662348312, -7.001869490098534],
            [110.44468948058136, -7.001969810645096],
            [110.444787612035654, -7.0020369390542],
            [110.444892901376591, -7.002128008954668],
            [110.444994466512071, -7.002208116372894],
            [110.445084353874819, -7.00227901314782],
            [110.445215981251479, -7.002379687280223],
            [110.44537158232886, -7.002463636503471],
            [110.445515223492833, -7.002533211741647],
            [110.445668466025126, -7.002586045108764],
            [110.445814559157611, -7.002607757019208],
            [110.446008565683996, -7.002624738706992],
            [110.446076464863367, -7.002633599336797],
            [110.446212142486246, -7.002651304810708],
            [110.446386963388363, -7.002687410210311],
            [110.446554586876687, -7.002733080305616],
            [110.446801224353635, -7.002807562965692],
            [110.447057437833834, -7.002886843374166],
            [110.447244210496123, -7.002944502281314],
            [110.447392351154022, -7.002998780887784],
            [110.447493223893957, -7.003035740537653],
            [110.447673477024622, -7.003116508292464],
            [110.447876269459755, -7.003220475017602],
            [110.448046238066041, -7.003309226892495],
            [110.448350392269674, -7.003490576234968],
            [110.448622519611206, -7.003677971031476],
            [110.448740873526376, -7.003794036376729],
            [110.448910140814917, -7.003966100289061],
            [110.448990330849568, -7.004101877567361],
            [110.44906621342929, -7.004291923114124],
            [110.449093401915889, -7.004509048269608],
            [110.449108998070713, -7.004937050311488],
            [110.449131444237523, -7.005149662612218],
            [110.449204909130898, -7.005402017401071],
            [110.44928692221994, -7.005628871874265],
            [110.44953602937025, -7.006207608023293],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 150,
        id: 155,
        area: 1030878.831296,
        perimeter: 4648.693368,
        kode_pos: 50614,
        kode_kec: 3374060,
        kode_kel: 3374051007,
        kec: "Candisari",
        kel: "Tegalsari",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.419356439565718, -6.997475916787626],
            [110.420104498584891, -6.997738722922102],
            [110.420890030152137, -6.99801666476609],
            [110.421096740776022, -6.998098328687927],
            [110.421373808244397, -6.998252443143601],
            [110.421567985848867, -6.998379279085675],
            [110.421713275135758, -6.998526715667193],
            [110.421847051748003, -6.998697087372117],
            [110.422090529480542, -6.999094556691571],
            [110.422364501781104, -6.999465759204764],
            [110.422489078701645, -6.99961968587787],
            [110.422739772427946, -6.999845652069131],
            [110.422830746422505, -6.999927652630428],
            [110.423054200261262, -7.000140519082302],
            [110.423125483779145, -7.000208425081141],
            [110.423550126521363, -7.000636480827296],
            [110.423790988210442, -7.000893043173946],
            [110.424045197250251, -7.001191695493509],
            [110.424207655058794, -7.001379210122598],
            [110.424287067004315, -7.001470877074989],
            [110.424700765424078, -7.001982336487993],
            [110.425142260373264, -7.002524097560512],
            [110.425612538110116, -7.003008520150933],
            [110.425920834400586, -7.003341593985501],
            [110.426242897252166, -7.003721898838071],
            [110.426366389078638, -7.003839641245531],
            [110.426635237400433, -7.004113247751995],
            [110.426860946077284, -7.004241656592383],
            [110.427098165231641, -7.004349042480331],
            [110.427369859528326, -7.004439258249155],
            [110.427798451922996, -7.004538012894082],
            [110.427800744353902, -7.004538460221465],
            [110.427853241819633, -7.004654091973214],
            [110.42790321179649, -7.004764156584724],
            [110.42796242646655, -7.004927048024594],
            [110.428001359140779, -7.005017550609054],
            [110.427925239005091, -7.005261687097378],
            [110.42788218733547, -7.005533000369948],
            [110.427868647511204, -7.005731985372274],
            [110.427861680996443, -7.005876705241301],
            [110.427831842045222, -7.005985215186975],
            [110.427745907651939, -7.00615697550136],
            [110.427660017318885, -7.006292553762762],
            [110.427460965763856, -7.006654132218991],
            [110.427289129485175, -7.006970516117835],
            [110.427199854850301, -7.007097044624571],
            [110.427113997304602, -7.007205486234497],
            [110.42705127296901, -7.007368229141917],
            [110.426958220790013, -7.007576162661371],
            [110.426859227175996, -7.007784088909048],
            [110.426789701497299, -7.007955868989756],
            [110.426763179826395, -7.008127701507136],
            [110.42673301990753, -7.008498530999122],
            [110.426725787646077, -7.00886034293008],
            [110.42671533266072, -7.009312606108796],
            [110.42671491257012, -7.009656335299232],
            [110.426743439005051, -7.009927735654068],
            [110.426793279655882, -7.010352935820887],
            [110.426811054235543, -7.010533867870754],
            [110.426705580088822, -7.010578966774075],
            [110.426618156900005, -7.010578860092033],
            [110.426461439717258, -7.010560577774805],
            [110.426327933293038, -7.010533278229646],
            [110.426228432962247, -7.010460792590552],
            [110.42613861844346, -7.010334045659091],
            [110.426023690269503, -7.010152994932763],
            [110.425862701168398, -7.009926660319803],
            [110.425682484572846, -7.00969125664514],
            [110.425536651730084, -7.009564441148857],
            [110.425425302234814, -7.009464804327651],
            [110.425279391955911, -7.009401307281117],
            [110.425060759649355, -7.009346766739193],
            [110.424689371670794, -7.0092739480788],
            [110.423894449622608, -7.009200610093592],
            [110.423172499534033, -7.009145451386293],
            [110.422335988641947, -7.009072059749969],
            [110.421921292155076, -7.009017276736622],
            [110.421110799500482, -7.008952960467286],
            [110.420397848080867, -7.008943036182433],
            [110.419983096154226, -7.008933478958618],
            [110.419664299453942, -7.008887857844217],
            [110.419372391573859, -7.008833224233304],
            [110.419234098941558, -7.008787825815474],
            [110.419065295627163, -7.00870620761671],
            [110.418934686760934, -7.008624636587125],
            [110.418804416893266, -7.008497838410997],
            [110.418597293414905, -7.008289535579213],
            [110.418347776823737, -7.008044998183022],
            [110.418039774416968, -7.007737069721789],
            [110.417854640866082, -7.007592112374659],
            [110.417756501058605, -7.007564854300034],
            [110.417598393313099, -7.007528476400715],
            [110.417510993292439, -7.007510277089022],
            [110.417430971677504, -7.00747399589689],
            [110.417373006649242, -7.007446787536967],
            [110.417347561160142, -7.007442406979801],
            [110.417318854802218, -7.007437575630034],
            [110.417318809457996, -7.007347219803726],
            [110.417303960698433, -7.007229609835642],
            [110.41731256063801, -7.007139165448875],
            [110.417347234171302, -7.007012571361088],
            [110.417377731264423, -7.00683169906239],
            [110.417386703939599, -7.006668891092613],
            [110.417373509895882, -7.006576597168932],
            [110.417363423721639, -7.006506043148535],
            [110.417298089530505, -7.006261733534313],
            [110.417190589126776, -7.006026417148994],
            [110.417075822792995, -7.005718727697459],
            [110.417005587287392, -7.005320638394833],
            [110.416985495592101, -7.005181780784151],
            [110.416982024312219, -7.005157790088257],
            [110.416867627110335, -7.004325461768802],
            [110.416774123178101, -7.003755478988269],
            [110.416758826729819, -7.003655521539973],
            [110.416753355787378, -7.003619770655029],
            [110.416637752692012, -7.003303034593875],
            [110.416497762594133, -7.003031495783903],
            [110.416312573283108, -7.002705627824644],
            [110.41620841283779, -7.002515542954781],
            [110.416151760107496, -7.002343608047258],
            [110.416121135809618, -7.002171705444108],
            [110.416131568269677, -7.001972717287168],
            [110.416165404111766, -7.001837076709224],
            [110.41624028539448, -7.001683396052734],
            [110.416372010452491, -7.001547876915461],
            [110.416427433595146, -7.001505694443792],
            [110.416490665061033, -7.001457569050729],
            [110.416661059707707, -7.001394461829414],
            [110.416868821803732, -7.001313309849682],
            [110.417222297226502, -7.001223292792593],
            [110.41760687094721, -7.001151404972113],
            [110.417947298347229, -7.001088507719746],
            [110.418086549445462, -7.001043452439291],
            [110.418372124489039, -7.000953350491971],
            [110.418552703751729, -7.000890255139351],
            [110.418661083239726, -7.000872298052357],
            [110.418759244137291, -7.000881464843651],
            [110.418864727297219, -7.000917777188091],
            [110.41899618286601, -7.000999349134137],
            [110.419125069855127, -7.001099008895261],
            [110.419220356903963, -7.001144354086612],
            [110.41932613426647, -7.001171621187884],
            [110.41947326223935, -7.001162757252182],
            [110.419622992354149, -7.00110866892684],
            [110.419837056304218, -7.000964204779955],
            [110.41998434060875, -7.00082870371262],
            [110.420101114503609, -7.000656982940441],
            [110.420250851774398, -7.000367711094928],
            [110.420343101027726, -7.00012359592626],
            [110.420362190369701, -7.000015073296206],
            [110.420366340952484, -6.999861304700548],
            [110.42032452131977, -6.9995898878146],
            [110.42030209385824, -6.999511297251536],
            [110.420243452596935, -6.999305805772464],
            [110.420231560938561, -6.999264134847243],
            [110.420113897689149, -6.999010715526662],
            [110.419937321126923, -6.998811496645519],
            [110.419657637027512, -6.998485513410374],
            [110.419475152204186, -6.998267656141523],
            [110.419407342947864, -6.998186702826551],
            [110.41933096097614, -6.997938575325713],
            [110.419314072152247, -6.997688024677773],
            [110.419356439565718, -6.997475916787626],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 151,
        id: 156,
        area: 4087835.648031,
        perimeter: 11919.784403,
        kode_pos: 50156,
        kode_kec: 3374150,
        kode_kel: 3374090008,
        kec: "Tugu",
        kel: "Mangkang Wetan",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.33110571126204, -6.94338968660738],
            [110.331201062054276, -6.94343078440628],
            [110.332615320317245, -6.944044068616128],
            [110.334046982784884, -6.945080343314594],
            [110.335062137092521, -6.945708852614784],
            [110.335399312944588, -6.945917606427268],
            [110.33567231589339, -6.946005282906379],
            [110.335586316971899, -6.946186981952754],
            [110.335130881955578, -6.947150591453226],
            [110.334665667788968, -6.947787897683456],
            [110.334250492133734, -6.948218144106457],
            [110.333318889234761, -6.948933969325878],
            [110.331725998979323, -6.950292711474821],
            [110.330895249603572, -6.951639300744283],
            [110.329402919276887, -6.952324639288942],
            [110.328285289604054, -6.953290910868951],
            [110.328085073156686, -6.954240391165909],
            [110.327467653023803, -6.954954099182676],
            [110.327260233714895, -6.955596025963213],
            [110.326171613677545, -6.956444742693239],
            [110.326026400794987, -6.9571048479482],
            [110.325785233424497, -6.958596990326344],
            [110.325304791948923, -6.960251607824836],
            [110.323915805543962, -6.962628555853732],
            [110.323536780230555, -6.963188826418933],
            [110.321931778682284, -6.962716169414181],
            [110.321592954267373, -6.963647355532696],
            [110.321121713246498, -6.965184390104876],
            [110.320212143495553, -6.963862461332526],
            [110.319707677107843, -6.965091904201959],
            [110.318616092264065, -6.967577804298296],
            [110.318655940745757, -6.967604997796302],
            [110.318306099007302, -6.968518073831604],
            [110.317882558498951, -6.970118489637414],
            [110.317553916885615, -6.971040640655549],
            [110.318686098835627, -6.971404088773014],
            [110.318537269882654, -6.972009912566663],
            [110.318438286857926, -6.972380629099742],
            [110.318358927955629, -6.972679011117392],
            [110.31828039974269, -6.973185437336948],
            [110.31902835542796, -6.97339455941106],
            [110.318376444476655, -6.975458973042222],
            [110.318215163243465, -6.975438270437287],
            [110.317847109988477, -6.975374002822784],
            [110.317107427447141, -6.975198114852969],
            [110.316763125186569, -6.975093818154991],
            [110.316273120820028, -6.974921932224885],
            [110.315806838411746, -6.974730048885561],
            [110.315488115296773, -6.974582083711784],
            [110.315198560292927, -6.974428697558317],
            [110.315047414709184, -6.974344711074157],
            [110.314868984658716, -6.974224264410313],
            [110.314657821246016, -6.974054602441103],
            [110.314481309223538, -6.973868601445084],
            [110.314328489764421, -6.973682634794352],
            [110.314150232330221, -6.973443821370337],
            [110.313893703293758, -6.973139337213105],
            [110.313744502774014, -6.972971585942183],
            [110.313531515190675, -6.972803741930143],
            [110.313333059951532, -6.972668697450378],
            [110.313070793831272, -6.972549949344196],
            [110.312795730628523, -6.972458497890409],
            [110.312666381692637, -6.972425531202329],
            [110.31251514433275, -6.972405279811136],
            [110.312332903443831, -6.9723995514516],
            [110.311811637217232, -6.972422465627215],
            [110.311215603762435, -6.972476227649113],
            [110.310451888007336, -6.972538849273982],
            [110.309963429191015, -6.97255998825281],
            [110.309527830534478, -6.972575740920052],
            [110.308895395183612, -6.97259666829624],
            [110.308601937257563, -6.972621733183353],
            [110.308248326532834, -6.97265399389338],
            [110.307914757807353, -6.972689925751116],
            [110.307495516077253, -6.972736657915799],
            [110.307134604617559, -6.972776191170952],
            [110.306704419841395, -6.97282836963914],
            [110.3062833474483, -6.972880561089928],
            [110.306015398837729, -6.972909303853659],
            [110.305731039773804, -6.972943485412846],
            [110.305311800473859, -6.972988394574779],
            [110.304797762219792, -6.97305319509152],
            [110.304396739636843, -6.973103593307357],
            [110.303990252224722, -6.973152162097333],
            [110.303662361331675, -6.973193443096587],
            [110.30364233818355, -6.972965084692222],
            [110.30365664314661, -6.972856561918115],
            [110.303670827890699, -6.972829446864271],
            [110.303853169036685, -6.972531220007072],
            [110.304528568198464, -6.971482957357789],
            [110.304861996555843, -6.970976909909433],
            [110.305274214716789, -6.970371479417388],
            [110.305433599607724, -6.970100353953746],
            [110.305553286252447, -6.969892487361101],
            [110.305748867624956, -6.969630460275288],
            [110.305885783768048, -6.96944070953696],
            [110.306157405789094, -6.969025023346664],
            [110.306560285042877, -6.96841957764439],
            [110.307327005551826, -6.967398579609389],
            [110.307971275514433, -6.966540216718041],
            [110.308663727006746, -6.965618605805976],
            [110.30933972846249, -6.964724105562179],
            [110.309455128864627, -6.964543367382566],
            [110.309597292663213, -6.96424507886686],
            [110.309694693763959, -6.963991951633012],
            [110.309898889086128, -6.96363948150562],
            [110.310183556449218, -6.963196675253889],
            [110.310666693639732, -6.962446616534893],
            [110.310966550471008, -6.961868151687654],
            [110.31107752883446, -6.96161504380069],
            [110.311159652590334, -6.961361893860228],
            [110.311193257496996, -6.961208172001895],
            [110.311195152669498, -6.961072494653199],
            [110.311130655894956, -6.960683451103943],
            [110.311062442099853, -6.960321538159548],
            [110.311051015241901, -6.96020393209351],
            [110.311069719424793, -6.959986871150892],
            [110.311108574437597, -6.959724612836347],
            [110.311207836352551, -6.959163946181151],
            [110.311214744282282, -6.959082548163736],
            [110.311204948770552, -6.959010171175746],
            [110.311285044957501, -6.958983151723418],
            [110.311350187747905, -6.958928974472223],
            [110.311558898471318, -6.958775507319318],
            [110.311989036194973, -6.958477636524112],
            [110.312250132959605, -6.95828806380002],
            [110.312358070192161, -6.958179676509243],
            [110.312429771755703, -6.958089327238128],
            [110.312496637926145, -6.958017061617753],
            [110.312570293317762, -6.957944805846324],
            [110.312701979584432, -6.957845498297062],
            [110.312792594864533, -6.95778231245632],
            [110.312928807200947, -6.95768301140554],
            [110.31306057220867, -6.95752943178336],
            [110.313518298388459, -6.956933102987161],
            [110.313672502798354, -6.956716238263513],
            [110.314049474155837, -6.956210245183936],
            [110.314377914119078, -6.955812725620743],
            [110.314416721687138, -6.955776600423507],
            [110.314531033010823, -6.955758675239646],
            [110.314578879732281, -6.955731608463012],
            [110.314732761024715, -6.955541878878136],
            [110.314887530167013, -6.955325014465866],
            [110.314980203502969, -6.95520755900416],
            [110.315084527432091, -6.955053938971894],
            [110.315194265380271, -6.954873190688176],
            [110.315247269378787, -6.954800904537154],
            [110.315470831034233, -6.954547957949677],
            [110.315582740119453, -6.954430530143222],
            [110.315637645635505, -6.95441251879262],
            [110.315695044731754, -6.95443069244411],
            [110.315765147396093, -6.954466975147849],
            [110.315871150063842, -6.954521400411945],
            [110.315944686615723, -6.954530551997215],
            [110.316090140934293, -6.954494580683928],
            [110.31621156324367, -6.954449529270289],
            [110.316361030202344, -6.954377382252988],
            [110.316523575118026, -6.954260027294578],
            [110.316652160918125, -6.954151668623963],
            [110.316949876067028, -6.953871692160631],
            [110.317040542301825, -6.953772324025168],
            [110.317108281929592, -6.953681968164603],
            [110.317169889539258, -6.953528285969633],
            [110.317220912907828, -6.953455996694454],
            [110.317288626335127, -6.953383731470884],
            [110.317440151321492, -6.95325731489799],
            [110.317500161587489, -6.953230265312076],
            [110.317556442161361, -6.953239391764831],
            [110.317674878596137, -6.953302879892395],
            [110.317900101188684, -6.953466020711804],
            [110.318031820176657, -6.953538573245072],
            [110.318267782454683, -6.952923828714335],
            [110.318420416412238, -6.952616506241571],
            [110.318646612523324, -6.952300244156707],
            [110.31886083610074, -6.952047282222388],
            [110.318991440019047, -6.951911789624033],
            [110.319572372389516, -6.951397038918677],
            [110.320118213229534, -6.950891282374196],
            [110.32038925550485, -6.950665537210441],
            [110.320961571336724, -6.950042227771749],
            [110.321676986680203, -6.949247260417469],
            [110.322516238284166, -6.948298697536861],
            [110.322656997895422, -6.948181308957083],
            [110.32278012828661, -6.948127212706758],
            [110.322956105410384, -6.948109373381757],
            [110.32328140426479, -6.948118883313278],
            [110.323512812902052, -6.948110168287667],
            [110.323670994441599, -6.948074212557608],
            [110.323787657250691, -6.947992970696883],
            [110.324099842913753, -6.947676828059387],
            [110.324313996915393, -6.947469089881567],
            [110.32452401080846, -6.947188982725826],
            [110.324701287887706, -6.946854556592343],
            [110.324854930931537, -6.946628641159795],
            [110.324957384739648, -6.946592605573567],
            [110.325064570093986, -6.946610848928782],
            [110.325650076939809, -6.947045860034622],
            [110.325960587778198, -6.947308617466305],
            [110.326099815555253, -6.947471632136566],
            [110.32627643465834, -6.947797516640151],
            [110.326429536670503, -6.947951505581718],
            [110.326573728203854, -6.948005982711965],
            [110.326769211125054, -6.947997215040381],
            [110.327365187766546, -6.947835244431823],
            [110.327714197125587, -6.947681968193008],
            [110.328110421130248, -6.947347851068869],
            [110.328531808026341, -6.947022814576686],
            [110.32877859438463, -6.946941755676764],
            [110.329109036054163, -6.946915087310592],
            [110.329341935561189, -6.946852099207383],
            [110.329546636759147, -6.946725753447378],
            [110.329756660213846, -6.94643659827283],
            [110.331096537265381, -6.94341040678586],
            [110.33110571126204, -6.94338968660738],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 152,
        id: 157,
        area: 4867734.4632040001,
        perimeter: 10308.442191,
        kode_pos: 50181,
        kode_kec: 3374150,
        kode_kel: 3374090007,
        kec: "Tugu",
        kel: "Randugarut",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.330964850854329, -6.976980516534175],
            [110.330763034148291, -6.976792861308255],
            [110.330602841866266, -6.976659698757374],
            [110.330451715667266, -6.976559327614282],
            [110.330265961826328, -6.976458907304057],
            [110.330021874790006, -6.976367509310784],
            [110.329799595834089, -6.976319846949178],
            [110.329517181874294, -6.976266635986832],
            [110.329081697922732, -6.976198639090741],
            [110.328664410694387, -6.976150699038127],
            [110.328199727973725, -6.976109975312887],
            [110.327660319930061, -6.976070965757481],
            [110.327148231460015, -6.976044741754273],
            [110.326692648649981, -6.976013134695289],
            [110.326215195851574, -6.97598149599064],
            [110.325861642408768, -6.975971886120386],
            [110.325376850238854, -6.97597483569157],
            [110.325057914083288, -6.975972558810906],
            [110.324605914066026, -6.975984659623993],
            [110.324091938387539, -6.976003955400676],
            [110.323561573226812, -6.976014121978024],
            [110.323193442567373, -6.976002668195068],
            [110.322878172486895, -6.975985826853279],
            [110.322355186490498, -6.975934087631395],
            [110.321688277757858, -6.975843899454369],
            [110.320879237612459, -6.975737116667858],
            [110.319658333120074, -6.975615170983571],
            [110.319142654025072, -6.97555251262904],
            [110.318657949773112, -6.975495361510837],
            [110.318376444476655, -6.975458973042222],
            [110.31902835542796, -6.97339455941106],
            [110.31828039974269, -6.973185437336948],
            [110.318358927955629, -6.972679011117392],
            [110.318438286857926, -6.972380629099742],
            [110.318537269882654, -6.972009912566663],
            [110.318686098835627, -6.971404088773014],
            [110.317553916885615, -6.971040640655549],
            [110.317882558498951, -6.970118489637414],
            [110.318306099007302, -6.968518073831604],
            [110.318655940745757, -6.967604997796302],
            [110.318616092264065, -6.967577804298296],
            [110.319707677107843, -6.965091904201959],
            [110.320212143495553, -6.963862461332526],
            [110.321121713246498, -6.965184390104876],
            [110.321592954267373, -6.963647355532696],
            [110.321931778682284, -6.962716169414181],
            [110.323536780230555, -6.963188826418933],
            [110.323915805543962, -6.962628555853732],
            [110.325304791948923, -6.960251607824836],
            [110.325785233424497, -6.958596990326344],
            [110.326026400794987, -6.9571048479482],
            [110.326171613677545, -6.956444742693239],
            [110.327260233714895, -6.955596025963213],
            [110.327467653023803, -6.954954099182676],
            [110.328085073156686, -6.954240391165909],
            [110.328285289604054, -6.953290910868951],
            [110.329402919276887, -6.952324639288942],
            [110.330895249603572, -6.951639300744283],
            [110.331725998979323, -6.950292711474821],
            [110.333318889234761, -6.948933969325878],
            [110.334250492133734, -6.948218144106457],
            [110.334665667788968, -6.947787897683456],
            [110.335130881955578, -6.947150591453226],
            [110.335586316971899, -6.946186981952754],
            [110.33567231589339, -6.946005282906379],
            [110.335848565182204, -6.946061685789548],
            [110.33654725754181, -6.9462857619206],
            [110.340825100772179, -6.947657673492234],
            [110.34206436390032, -6.948055098805274],
            [110.342711041431286, -6.948285222389974],
            [110.341496741568264, -6.95057379609065],
            [110.340880876576179, -6.951784216227086],
            [110.340459072507215, -6.9529141537233],
            [110.340263903857149, -6.953778108646326],
            [110.340135450194893, -6.955822189061583],
            [110.339761609150543, -6.956536254031465],
            [110.339251107565488, -6.957268218625019],
            [110.338838593306065, -6.958325953494511],
            [110.338662149099505, -6.960089558258337],
            [110.338336354386541, -6.962042907010977],
            [110.338035532407133, -6.962928934247136],
            [110.337786022623206, -6.963534625981191],
            [110.337312254393936, -6.964691771898563],
            [110.336998683052116, -6.966192866399616],
            [110.336898266554201, -6.966789720995108],
            [110.336773416079183, -6.967259905861274],
            [110.336474599242123, -6.967720801094799],
            [110.3356657855919, -6.968741793201915],
            [110.334767038320834, -6.97014256292021],
            [110.33460964957068, -6.970413702700172],
            [110.334508377874769, -6.970811556766735],
            [110.334387150200811, -6.971317927221366],
            [110.334004144355731, -6.972095289957442],
            [110.332654439230055, -6.973902460130386],
            [110.331768054798431, -6.975149468219527],
            [110.331142587592154, -6.976731522616857],
            [110.330964850854329, -6.976980516534175],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 153,
        id: 158,
        area: 5093879.3477649996,
        perimeter: 10261.417767000001,
        kode_pos: 50185,
        kode_kec: 3374150,
        kode_kel: 3374090005,
        kec: "Tugu",
        kel: "Tugurejo",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.345888641353355, -6.985737094545005],
            [110.345852550852342, -6.985218626081817],
            [110.345803118189394, -6.985164285133691],
            [110.345284663505154, -6.984883158323334],
            [110.34509191233137, -6.984747209753584],
            [110.344882658812352, -6.984475557298046],
            [110.344267857031568, -6.983497800452225],
            [110.344007380972187, -6.983036123244543],
            [110.343993538315942, -6.982819014590397],
            [110.344027619452717, -6.98252056405939],
            [110.344042468937715, -6.982421085398976],
            [110.344047568054904, -6.982213048473502],
            [110.344028312445332, -6.982023068462653],
            [110.344014023072219, -6.981923549278372],
            [110.343603246378066, -6.981316936632411],
            [110.343716140441288, -6.980086920378334],
            [110.343751278605438, -6.979435700992977],
            [110.343958054161234, -6.979047036609432],
            [110.344003582570181, -6.978857146642433],
            [110.344032482840987, -6.978622006587241],
            [110.34407093280555, -6.978233108106859],
            [110.34412874641832, -6.977346739851717],
            [110.343868097035084, -6.977011698045422],
            [110.343655157019612, -6.976749085607844],
            [110.343562040001643, -6.976577093658318],
            [110.343601225346191, -6.976269604767744],
            [110.343638826596859, -6.975880705119511],
            [110.343658171185439, -6.975600324804324],
            [110.343815130527076, -6.97482263906489],
            [110.343927019940892, -6.974515251120137],
            [110.344240526787786, -6.973864418119772],
            [110.344545407513181, -6.973313072202208],
            [110.344713013952955, -6.973014806690291],
            [110.344750783563157, -6.972707315598176],
            [110.344752279627272, -6.972445001162222],
            [110.344779431194965, -6.972246040090659],
            [110.344954568215542, -6.971821149310674],
            [110.345042296885367, -6.971595136007939],
            [110.345082857353773, -6.971314784904059],
            [110.34513106779066, -6.970618356072385],
            [110.345362342362435, -6.97010308884001],
            [110.345477435881563, -6.969931385699338],
            [110.34559257950184, -6.969723500993105],
            [110.345639457756377, -6.969578839518812],
            [110.345660900619208, -6.969416052017137],
            [110.345631780748462, -6.969198922100517],
            [110.345660541185723, -6.969063280927069],
            [110.345734382024744, -6.96885533896265],
            [110.345898220517128, -6.96862038534706],
            [110.345955578130372, -6.968466692918913],
            [110.345955766194876, -6.968331012169557],
            [110.345919102917222, -6.968249552825623],
            [110.345748020799576, -6.967996044819256],
            [110.345650107772414, -6.967815001268463],
            [110.345621723731156, -6.967679280968004],
            [110.345638432528361, -6.967462214490626],
            [110.345687956839257, -6.967245193434258],
            [110.345798673630995, -6.966964939251101],
            [110.34596649690981, -6.966711900241967],
            [110.346122368663316, -6.966305072794678],
            [110.346249442551624, -6.966061022681762],
            [110.346572898354822, -6.965573018100017],
            [110.346626810517009, -6.965455502366863],
            [110.346807838457806, -6.964858755869928],
            [110.347004707765137, -6.964262031121731],
            [110.34705859462666, -6.964162606082738],
            [110.347291838901384, -6.963855384312207],
            [110.347498295765391, -6.963484807601446],
            [110.347531003141867, -6.963358217051277],
            [110.347551904092072, -6.96317733777739],
            [110.347572301723716, -6.962951230777828],
            [110.347609277372641, -6.962806555290475],
            [110.347724010248356, -6.96268912325021],
            [110.347911949101942, -6.962617019135551],
            [110.348291982627387, -6.962536134240315],
            [110.348394476133251, -6.962472957594289],
            [110.348529418691498, -6.962265099096402],
            [110.348649172407207, -6.961993901778909],
            [110.348861189252986, -6.961487650802647],
            [110.348944142023555, -6.961234493531484],
            [110.34900128946478, -6.961026527771541],
            [110.349021856921681, -6.960881829530673],
            [110.349017026689566, -6.960691869297939],
            [110.349022380448204, -6.960501923075872],
            [110.349045173578347, -6.96038436411757],
            [110.349126095937223, -6.960167385620047],
            [110.349226891473833, -6.960104206394469],
            [110.34977012110798, -6.960249679779448],
            [110.349911061874423, -6.960204646389879],
            [110.349991983810867, -6.959987667702855],
            [110.349942177273448, -6.959589601201722],
            [110.350042776800933, -6.959463103632082],
            [110.350485593152897, -6.959599392811954],
            [110.350747202653963, -6.959644978884026],
            [110.35096874437825, -6.959410102065417],
            [110.351898163609988, -6.958696788129706],
            [110.35327128630135, -6.956834128233095],
            [110.354192285471115, -6.955505138391223],
            [110.354951699333995, -6.953531259384259],
            [110.35521706609525, -6.95284150968479],
            [110.355828828738098, -6.953038550891471],
            [110.356348911554235, -6.953206264665495],
            [110.357940924262522, -6.953486881875047],
            [110.359133415100857, -6.953557247528723],
            [110.359903269778641, -6.953602893222075],
            [110.359730057371365, -6.954040951149634],
            [110.359688658074916, -6.954345806312874],
            [110.35965603820658, -6.95458617094402],
            [110.359590067608778, -6.955919156201974],
            [110.359565660262163, -6.956412321197015],
            [110.359504651948484, -6.958187272877824],
            [110.359421507544795, -6.960079310056107],
            [110.359346193573387, -6.961598051531572],
            [110.359397163856428, -6.961825032099295],
            [110.359550670503239, -6.962066790953479],
            [110.359711592057323, -6.962242682258982],
            [110.36007014212062, -6.962535956573311],
            [110.362609307203542, -6.964588909696181],
            [110.364075250367577, -6.965761662826907],
            [110.363917201871487, -6.966196273112664],
            [110.363634089123025, -6.966974789394336],
            [110.363489230528373, -6.967429162863399],
            [110.363323003077213, -6.968065273177854],
            [110.363102158702119, -6.969041882116112],
            [110.362750961512106, -6.970181132773583],
            [110.362705478308641, -6.970343889152355],
            [110.362690760976008, -6.970977049323002],
            [110.362663529650973, -6.971031285160663],
            [110.362450154558772, -6.971293314685084],
            [110.362431705187888, -6.971338516927828],
            [110.362408633311944, -6.971664121200987],
            [110.362376814426156, -6.971763577961987],
            [110.362303299698652, -6.971944387283832],
            [110.362148041591055, -6.972324085617091],
            [110.361278563343916, -6.974303858884761],
            [110.361096711089274, -6.97468352071823],
            [110.360814172129011, -6.975253000063381],
            [110.360618445485017, -6.975849733009338],
            [110.360454746628776, -6.976193237363578],
            [110.360435325401909, -6.976328892397007],
            [110.360367171890204, -6.976518753938342],
            [110.360189758343381, -6.976961739214754],
            [110.360030560551252, -6.977323340287782],
            [110.35994373533228, -6.977513176416542],
            [110.359695644373858, -6.978033593064263],
            [110.35938853921229, -6.978661191627435],
            [110.359261627814519, -6.978930959069452],
            [110.359156953435303, -6.979147038389966],
            [110.359065015187824, -6.979358047411991],
            [110.358514792196132, -6.980640951394924],
            [110.358196452506732, -6.981318924754508],
            [110.358187030466098, -6.981382229855892],
            [110.35849393997718, -6.982241961955773],
            [110.358479966864195, -6.982323351707511],
            [110.358089841747471, -6.983154999272041],
            [110.358157768674104, -6.983767730597862],
            [110.35813968737601, -6.983958489547208],
            [110.358119185022659, -6.984057669090975],
            [110.35806151070544, -6.984222319798967],
            [110.357978744929952, -6.98436273159233],
            [110.357733909240423, -6.984677826971817],
            [110.3572715353598, -6.985182542864316],
            [110.357237250350295, -6.985131508632016],
            [110.357061763410442, -6.984870034468901],
            [110.356784548315872, -6.984552937984527],
            [110.35642632148263, -6.984258848943776],
            [110.356232142134843, -6.984131434179113],
            [110.355938520059127, -6.983969206346648],
            [110.355316657972139, -6.983667238918733],
            [110.354570078674953, -6.983328704133037],
            [110.353764983033514, -6.983029031958978],
            [110.35307347938388, -6.982794420762107],
            [110.352651426610677, -6.98265753815904],
            [110.352180676453671, -6.982504361710251],
            [110.351726068341065, -6.982422604080379],
            [110.351368860107002, -6.982370187865292],
            [110.351089518930891, -6.982379539619244],
            [110.350793866188653, -6.982440793894098],
            [110.350540361265473, -6.982557276467156],
            [110.350329004093368, -6.982728987400202],
            [110.350065463754461, -6.983056401705097],
            [110.349723004760918, -6.983591878810068],
            [110.349215565236733, -6.984399152031791],
            [110.349021036053969, -6.984656747290225],
            [110.348784780386126, -6.984872535451312],
            [110.348414350341457, -6.985108610078227],
            [110.348067015029471, -6.985294735401636],
            [110.347711470641528, -6.985441216075255],
            [110.347301415125372, -6.985566153120494],
            [110.346884801129534, -6.985653099074788],
            [110.346519484123291, -6.985695528623872],
            [110.346048411822451, -6.98572294883336],
            [110.345888641353355, -6.985737094545005],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 154,
        id: 159,
        area: 4351324.1617860002,
        perimeter: 14413.411668000001,
        kode_pos: 50155,
        kode_kec: 3374150,
        kode_kel: 3374090009,
        kec: "Tugu",
        kel: "Mangkang Kulon",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.296195695272516, -6.972900251367012],
            [110.296092005714158, -6.9728654825958],
            [110.295884343150973, -6.972797795305685],
            [110.295547363233098, -6.972677105485367],
            [110.29515208584543, -6.972541760287966],
            [110.294900703087592, -6.972461260207725],
            [110.294651137461869, -6.972384404755578],
            [110.294441655617362, -6.972314893134413],
            [110.294148399686478, -6.972205193811122],
            [110.293911624433534, -6.972106504867428],
            [110.29359109705139, -6.97195305998412],
            [110.293248735624729, -6.971775908982366],
            [110.292818977451063, -6.971543996349292],
            [110.292210838375965, -6.971164313587065],
            [110.291324113486482, -6.9706166785989],
            [110.290735988525171, -6.970260697423414],
            [110.290608634572891, -6.97011482473703],
            [110.290528569081232, -6.970032758682916],
            [110.290035195082382, -6.969753262053],
            [110.289707381889983, -6.96955044633986],
            [110.289367948424186, -6.969344626376479],
            [110.28892689403142, -6.969073604709463],
            [110.288731783051375, -6.968947279450924],
            [110.288581348937697, -6.968873873353953],
            [110.288397363174127, -6.968819547235335],
            [110.288177209313048, -6.968772150034605],
            [110.287759566240354, -6.968706505655525],
            [110.286990635094114, -6.968601846773836],
            [110.287060851919989, -6.968418707621753],
            [110.287315231665502, -6.967680700067843],
            [110.287430155403086, -6.967393143412544],
            [110.287586889320636, -6.966987784449671],
            [110.287837776989434, -6.966263637653024],
            [110.287942270990371, -6.965989931583725],
            [110.288001626596483, -6.965740423886285],
            [110.287883731165323, -6.965698646812696],
            [110.287946749576477, -6.96532087946252],
            [110.288812743921142, -6.965452411201523],
            [110.289257264561755, -6.965533050109991],
            [110.28958726456608, -6.965649210176466],
            [110.289908541898555, -6.965799317533769],
            [110.290154220628793, -6.965942519458786],
            [110.290410161134744, -6.966075006396732],
            [110.290545178834847, -6.966171677709231],
            [110.290863334648591, -6.966466384538096],
            [110.29104659964726, -6.966580010118511],
            [110.291487988464439, -6.966783256120955],
            [110.292119909379338, -6.967083255816247],
            [110.292657757530606, -6.967344526489979],
            [110.293154625207478, -6.967571971271125],
            [110.29374802777896, -6.967806794985503],
            [110.293699931529972, -6.967911604220027],
            [110.29400005695571, -6.968062108608432],
            [110.294509224146424, -6.968300457577495],
            [110.295123789369896, -6.968594341074162],
            [110.295516381530462, -6.968780964612137],
            [110.295704126259693, -6.968461400661325],
            [110.296529000270553, -6.966672283954152],
            [110.296533459515558, -6.966674023440475],
            [110.296533277811648, -6.966668022511911],
            [110.296827229191393, -6.966019311930798],
            [110.29682896202003, -6.966015487807883],
            [110.297225749721719, -6.965115609534286],
            [110.297669687950943, -6.964200226325672],
            [110.298332805424224, -6.96276827823984],
            [110.2989555568452, -6.961422417190412],
            [110.29961297352375, -6.959953127007709],
            [110.297813835360202, -6.959303810242963],
            [110.291520850120662, -6.957099755995314],
            [110.292007262518425, -6.955641802369498],
            [110.292009801438795, -6.955642689052118],
            [110.292045772734809, -6.955508705925451],
            [110.291541663779867, -6.955339960360619],
            [110.291835575496833, -6.954738030352722],
            [110.29094728841892, -6.954223025431133],
            [110.291644902970646, -6.953256832321619],
            [110.291772875431136, -6.953079590432186],
            [110.29303561872355, -6.952071156959172],
            [110.29307418941157, -6.951975219315089],
            [110.291352222907079, -6.950390080668644],
            [110.292723606920916, -6.949078298169045],
            [110.293239311373284, -6.948310216078624],
            [110.293622371435106, -6.947523844957937],
            [110.293703530921036, -6.947352104953652],
            [110.293770182612377, -6.947234615192472],
            [110.293844229567014, -6.947090000494981],
            [110.293925240463579, -6.947017758660535],
            [110.294043227327705, -6.946999843758199],
            [110.294183211183082, -6.947027188147641],
            [110.294330576843265, -6.947036452857877],
            [110.294382114476448, -6.947000348318639],
            [110.294389860039558, -6.946927997333542],
            [110.29627400751427, -6.945094598513581],
            [110.296391373144274, -6.944732959929661],
            [110.297482408039301, -6.943793864152966],
            [110.29789610265739, -6.943134168255635],
            [110.298127947678623, -6.942447066853697],
            [110.29847590445965, -6.941669683730807],
            [110.298773632334161, -6.941190721699425],
            [110.299071803038416, -6.940603216159889],
            [110.299402291404263, -6.940350435137114],
            [110.299616923661333, -6.940206026774215],
            [110.300030077369456, -6.93990814062291],
            [110.300294307976884, -6.939890439665958],
            [110.300640747975621, -6.939945222359804],
            [110.300758692918578, -6.939954441506631],
            [110.300843042643606, -6.939918384480126],
            [110.300969324369333, -6.939837162563758],
            [110.301039893315178, -6.939746813220029],
            [110.301181012299764, -6.939195255934651],
            [110.301235766881049, -6.939086792598157],
            [110.301384758169874, -6.938951331993692],
            [110.301882422120514, -6.938698795046329],
            [110.302638548107694, -6.938310957360063],
            [110.303321572163043, -6.938004419060232],
            [110.303562363210162, -6.937959545755353],
            [110.303737784082756, -6.937932667107639],
            [110.303846150855364, -6.937914735387403],
            [110.303966102114217, -6.937905865965138],
            [110.304724691041343, -6.938151201866893],
            [110.304846366137241, -6.938124244076975],
            [110.305001955096259, -6.937925474498952],
            [110.305179179245954, -6.937826235245029],
            [110.305571200465252, -6.937275043266276],
            [110.305649622344362, -6.937229931287397],
            [110.305762528129165, -6.937202960406064],
            [110.305815468513742, -6.937045515823208],
            [110.306154605606864, -6.937157080855065],
            [110.306446330851443, -6.937234603736191],
            [110.306640835657532, -6.937271760151979],
            [110.306852161828502, -6.937275420802947],
            [110.30711390293645, -6.937212114445162],
            [110.307358847192276, -6.937165543555262],
            [110.307627297119922, -6.937102246690297],
            [110.307917548537361, -6.937040657464335],
            [110.308313502085298, -6.936954081883501],
            [110.308598768119111, -6.936860640708493],
            [110.308890708765503, -6.936790673253766],
            [110.309115530844622, -6.936740720097109],
            [110.309253068566434, -6.936737568212253],
            [110.309720925386074, -6.936800261231473],
            [110.310535859405888, -6.936938878631477],
            [110.311610704105178, -6.937119772010008],
            [110.312331729096897, -6.937248194151275],
            [110.313498667803614, -6.93747809615371],
            [110.314483447392163, -6.937630947480466],
            [110.314576433993395, -6.937637276958304],
            [110.314693432106168, -6.937645338709824],
            [110.314247421894876, -6.938085624006542],
            [110.313876159063199, -6.938624539345324],
            [110.313578792699289, -6.939301068519164],
            [110.313122064165782, -6.940379311193785],
            [110.312850966872148, -6.940768097507658],
            [110.312013987676565, -6.94131865091608],
            [110.31020708531112, -6.942410514044783],
            [110.309130221634263, -6.942960712821084],
            [110.308454172483664, -6.94333058665512],
            [110.308093569044146, -6.943673783704524],
            [110.30773893712059, -6.944188850610164],
            [110.307555833592787, -6.944631804950157],
            [110.30754527455295, -6.944695106910874],
            [110.307534027596716, -6.945228765535258],
            [110.307577154123649, -6.945527324726369],
            [110.307737613838214, -6.946061234082737],
            [110.307815375778588, -6.946468387912786],
            [110.307814965746786, -6.946748792844964],
            [110.307700251469171, -6.94723707365859],
            [110.307597793321747, -6.947662054942916],
            [110.30727942017883, -6.948719894324671],
            [110.307054161173298, -6.949551736053746],
            [110.306807159225229, -6.950356409751016],
            [110.306596042380548, -6.951188271740819],
            [110.306545022195039, -6.951450511795725],
            [110.306392222179539, -6.951866373523404],
            [110.306206638409023, -6.952454048508698],
            [110.306079053977825, -6.952842810989565],
            [110.305977410676476, -6.95309593141541],
            [110.305938823373054, -6.953367234882888],
            [110.305855491569787, -6.953864606094868],
            [110.305817063443925, -6.954027365766088],
            [110.305638735905177, -6.954488416303477],
            [110.305524202776311, -6.954850061643981],
            [110.305383980253055, -6.955175487920704],
            [110.305255541827677, -6.955374296781421],
            [110.305153694311116, -6.955573144619696],
            [110.305059727544219, -6.955799139986862],
            [110.304979082800074, -6.956007064220007],
            [110.304887405517661, -6.956214972232154],
            [110.30463575248784, -6.95652214389666],
            [110.304525214883896, -6.956666706797012],
            [110.304358979022751, -6.956983049077053],
            [110.304133553539899, -6.957344530907216],
            [110.303993771927281, -6.957561413279349],
            [110.303803236987818, -6.9580495807855],
            [110.303510553502846, -6.958555688504953],
            [110.303145613571886, -6.959143097513564],
            [110.302858370187394, -6.959603986054798],
            [110.302779986780791, -6.959811913053477],
            [110.302731175327423, -6.960300288687207],
            [110.302690241987506, -6.960625860060484],
            [110.302585792701379, -6.96086088454985],
            [110.302472613895787, -6.961068760160321],
            [110.302432243668335, -6.961204380500662],
            [110.302399876737795, -6.961475692496348],
            [110.302386126839764, -6.961783213233283],
            [110.302423027027118, -6.962081763335996],
            [110.302510619823835, -6.962534158693827],
            [110.302539842065144, -6.962859833440413],
            [110.302509900732957, -6.963212556908854],
            [110.302391484511062, -6.963709875039059],
            [110.302318744440257, -6.963926855486402],
            [110.302238246006155, -6.964225232370636],
            [110.302216222987525, -6.964388015681193],
            [110.302183882272544, -6.964641236986513],
            [110.302138203527051, -6.964731622798128],
            [110.301993420152385, -6.964885179572962],
            [110.301791856324556, -6.96498438052368],
            [110.301305033530497, -6.965155522511202],
            [110.301181330825429, -6.965209611623029],
            [110.301141120452925, -6.965236688152664],
            [110.301050018529523, -6.965435550530703],
            [110.300985171620852, -6.965670632978834],
            [110.300893826833018, -6.965842358990893],
            [110.300776253672524, -6.965959774271472],
            [110.300596175336935, -6.966068051733016],
            [110.300301675572754, -6.966266612967611],
            [110.300202153440949, -6.966420236066825],
            [110.300051658667201, -6.966799916609033],
            [110.299927876028121, -6.967098228780616],
            [110.29980989863779, -6.967297051014127],
            [110.299702212562693, -6.967423525920812],
            [110.299402162091923, -6.967739667347812],
            [110.299321310083187, -6.967893317866787],
            [110.299286205661318, -6.968101308085391],
            [110.299496854251302, -6.968743837769854],
            [110.299576805827456, -6.969006270433002],
            [110.299554984719933, -6.969413277298207],
            [110.299506046257207, -6.969793108005867],
            [110.299486365577209, -6.96990162261399],
            [110.299461889161975, -6.970001084794524],
            [110.299433465685212, -6.970091495805002],
            [110.299282780012931, -6.970407858382016],
            [110.299141429555547, -6.970724234735125],
            [110.299080756276794, -6.970814597869481],
            [110.298800492048173, -6.971139813285338],
            [110.298756603421737, -6.971166884082835],
            [110.298698866522713, -6.971184889005369],
            [110.298666603365675, -6.971193886424297],
            [110.298627294803723, -6.971184782754791],
            [110.298560303166582, -6.971148502041213],
            [110.298458596530168, -6.971057897892586],
            [110.298368879036062, -6.970894949028184],
            [110.298270001097862, -6.970614397480317],
            [110.298207037340333, -6.970532896145419],
            [110.298174518246725, -6.970523802529039],
            [110.298098379990819, -6.970550825362352],
            [110.298072879353839, -6.970577923415281],
            [110.298042758222437, -6.970668331788108],
            [110.298019238025802, -6.970885384336404],
            [110.29799814247913, -6.970993896737121],
            [110.297949592925704, -6.971111413650841],
            [110.297884581742409, -6.97126508733905],
            [110.297823625266815, -6.971355449858409],
            [110.297756499006752, -6.971409621949917],
            [110.29766166275779, -6.971454707522978],
            [110.297312694887836, -6.971562732307842],
            [110.297261154577939, -6.971598836882344],
            [110.297177580029867, -6.971680120343417],
            [110.297096807415727, -6.971779498576026],
            [110.297034072474844, -6.971924130183503],
            [110.296943303874073, -6.972276762177268],
            [110.296891938462551, -6.972385229436372],
            [110.296780586254684, -6.972502652692248],
            [110.296637334548407, -6.97257480186996],
            [110.296376048975901, -6.972692001723753],
            [110.296313462149612, -6.972737135026046],
            [110.296228299866414, -6.972854959735789],
            [110.296195695272516, -6.972900251367012],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 155,
        id: 160,
        area: 4949227.5352769997,
        perimeter: 12693.91752,
        kode_pos: 50154,
        kode_kec: 3374150,
        kode_kel: 3374090010,
        kec: "Tugu",
        kel: "Mangunharjo",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.314693432106168, -6.937645338709824],
            [110.314938018571013, -6.937661889379836],
            [110.315286666130078, -6.937586678363672],
            [110.315439654093709, -6.937497047811344],
            [110.315802333618024, -6.937284565608774],
            [110.316181463227053, -6.93708825528781],
            [110.316439165505216, -6.937028055166916],
            [110.3166347767731, -6.937028336797798],
            [110.316668688327184, -6.937043337383233],
            [110.320540923970711, -6.938756177648576],
            [110.32282716155818, -6.939774314783368],
            [110.324245178542469, -6.940405797129132],
            [110.324976741756501, -6.94073158030977],
            [110.330637898958145, -6.943186570705243],
            [110.330670485621511, -6.94320070182176],
            [110.331105689141992, -6.943389677073265],
            [110.33110571126204, -6.94338968660738],
            [110.331096537265381, -6.94341040678586],
            [110.329756660213846, -6.94643659827283],
            [110.329546636759147, -6.946725753447378],
            [110.329341935561189, -6.946852099207383],
            [110.329109036054163, -6.946915087310592],
            [110.32877859438463, -6.946941755676764],
            [110.328531808026341, -6.947022814576686],
            [110.328110421130248, -6.947347851068869],
            [110.327714197125587, -6.947681968193008],
            [110.327365187766546, -6.947835244431823],
            [110.326769211125054, -6.947997215040381],
            [110.326573728203854, -6.948005982711965],
            [110.326429536670503, -6.947951505581718],
            [110.32627643465834, -6.947797516640151],
            [110.326099815555253, -6.947471632136566],
            [110.325960587778198, -6.947308617466305],
            [110.325650076939809, -6.947045860034622],
            [110.325064570093986, -6.946610848928782],
            [110.324957384739648, -6.946592605573567],
            [110.324854930931537, -6.946628641159795],
            [110.324701287887706, -6.946854556592343],
            [110.32452401080846, -6.947188982725826],
            [110.324313996915393, -6.947469089881567],
            [110.324099842913753, -6.947676828059387],
            [110.323787657250691, -6.947992970696883],
            [110.323670994441599, -6.948074212557608],
            [110.323512812902052, -6.948110168287667],
            [110.32328140426479, -6.948118883313278],
            [110.322956105410384, -6.948109373381757],
            [110.32278012828661, -6.948127212706758],
            [110.322656997895422, -6.948181308957083],
            [110.322516238284166, -6.948298697536861],
            [110.321676986680203, -6.949247260417469],
            [110.320961571336724, -6.950042227771749],
            [110.32038925550485, -6.950665537210441],
            [110.320118213229534, -6.950891282374196],
            [110.319572372389516, -6.951397038918677],
            [110.318991440019047, -6.951911789624033],
            [110.31886083610074, -6.952047282222388],
            [110.318646612523324, -6.952300244156707],
            [110.318420416412238, -6.952616506241571],
            [110.318267782454683, -6.952923828714335],
            [110.318031820176657, -6.953538573245072],
            [110.317900101188684, -6.953466020711804],
            [110.317674878596137, -6.953302879892395],
            [110.317556442161361, -6.953239391764831],
            [110.317500161587489, -6.953230265312076],
            [110.317440151321492, -6.95325731489799],
            [110.317288626335127, -6.953383731470884],
            [110.317220912907828, -6.953455996694454],
            [110.317169889539258, -6.953528285969633],
            [110.317108281929592, -6.953681968164603],
            [110.317040542301825, -6.953772324025168],
            [110.316949876067028, -6.953871692160631],
            [110.316652160918125, -6.954151668623963],
            [110.316523575118026, -6.954260027294578],
            [110.316361030202344, -6.954377382252988],
            [110.31621156324367, -6.954449529270289],
            [110.316090140934293, -6.954494580683928],
            [110.315944686615723, -6.954530551997215],
            [110.315871150063842, -6.954521400411945],
            [110.315765147396093, -6.954466975147849],
            [110.315695044731754, -6.95443069244411],
            [110.315637645635505, -6.95441251879262],
            [110.315582740119453, -6.954430530143222],
            [110.315470831034233, -6.954547957949677],
            [110.315247269378787, -6.954800904537154],
            [110.315194265380271, -6.954873190688176],
            [110.315084527432091, -6.955053938971894],
            [110.314980203502969, -6.95520755900416],
            [110.314887530167013, -6.955325014465866],
            [110.314732761024715, -6.955541878878136],
            [110.314578879732281, -6.955731608463012],
            [110.314531033010823, -6.955758675239646],
            [110.314416721687138, -6.955776600423507],
            [110.314377914119078, -6.955812725620743],
            [110.314049474155837, -6.956210245183936],
            [110.313672502798354, -6.956716238263513],
            [110.313518298388459, -6.956933102987161],
            [110.31306057220867, -6.95752943178336],
            [110.312928807200947, -6.95768301140554],
            [110.312792594864533, -6.95778231245632],
            [110.312701979584432, -6.957845498297062],
            [110.312570293317762, -6.957944805846324],
            [110.312496637926145, -6.958017061617753],
            [110.312429771755703, -6.958089327238128],
            [110.312358070192161, -6.958179676509243],
            [110.312250132959605, -6.95828806380002],
            [110.311989036194973, -6.958477636524112],
            [110.311558898471318, -6.958775507319318],
            [110.311350187747905, -6.958928974472223],
            [110.311285044957501, -6.958983151723418],
            [110.311204948770552, -6.959010171175746],
            [110.311214744282282, -6.959082548163736],
            [110.311207836352551, -6.959163946181151],
            [110.311108574437597, -6.959724612836347],
            [110.311069719424793, -6.959986871150892],
            [110.311051015241901, -6.96020393209351],
            [110.311062442099853, -6.960321538159548],
            [110.311130655894956, -6.960683451103943],
            [110.311195152669498, -6.961072494653199],
            [110.311193257496996, -6.961208172001895],
            [110.311159652590334, -6.961361893860228],
            [110.31107752883446, -6.96161504380069],
            [110.310966550471008, -6.961868151687654],
            [110.310666693639732, -6.962446616534893],
            [110.310183556449218, -6.963196675253889],
            [110.309898889086128, -6.96363948150562],
            [110.309694693763959, -6.963991951633012],
            [110.309597292663213, -6.96424507886686],
            [110.309455128864627, -6.964543367382566],
            [110.30933972846249, -6.964724105562179],
            [110.308663727006746, -6.965618605805976],
            [110.307971275514433, -6.966540216718041],
            [110.307327005551826, -6.967398579609389],
            [110.306560285042877, -6.96841957764439],
            [110.306157405789094, -6.969025023346664],
            [110.305885783768048, -6.96944070953696],
            [110.305748867624956, -6.969630460275288],
            [110.305553286252447, -6.969892487361101],
            [110.305433599607724, -6.970100353953746],
            [110.305274214716789, -6.970371479417388],
            [110.304861996555843, -6.970976909909433],
            [110.304528568198464, -6.971482957357789],
            [110.303853169036685, -6.972531220007072],
            [110.303670827890699, -6.972829446864271],
            [110.30365664314661, -6.972856561918115],
            [110.30364233818355, -6.972965084692222],
            [110.303662361331675, -6.973193443096587],
            [110.303397827926418, -6.973229592695679],
            [110.30309704470811, -6.973278316591625],
            [110.302803556784866, -6.97332340901309],
            [110.302526465831889, -6.973372167508003],
            [110.30214911910295, -6.973433524865491],
            [110.30178452433239, -6.973498542809812],
            [110.301441829030551, -6.973543561607202],
            [110.301093682416933, -6.973577645947435],
            [110.300186037190528, -6.973607259240182],
            [110.29959373118669, -6.973604560159916],
            [110.299236526217783, -6.973602209244125],
            [110.29907979766125, -6.97359833461732],
            [110.298879351065352, -6.973579826865116],
            [110.298496707204805, -6.973526449024325],
            [110.298165106653684, -6.973464041631655],
            [110.297917324334733, -6.97341268475157],
            [110.297603951556624, -6.973348483055192],
            [110.297356182955667, -6.973288020807575],
            [110.297061084094068, -6.973191067575601],
            [110.296733199649481, -6.973081318207814],
            [110.296418067335253, -6.972975229664862],
            [110.296236287215535, -6.972914045449133],
            [110.296195695272516, -6.972900251367012],
            [110.296228299866414, -6.972854959735789],
            [110.296313462149612, -6.972737135026046],
            [110.296376048975901, -6.972692001723753],
            [110.296637334548407, -6.97257480186996],
            [110.296780586254684, -6.972502652692248],
            [110.296891938462551, -6.972385229436372],
            [110.296943303874073, -6.972276762177268],
            [110.297034072474844, -6.971924130183503],
            [110.297096807415727, -6.971779498576026],
            [110.297177580029867, -6.971680120343417],
            [110.297261154577939, -6.971598836882344],
            [110.297312694887836, -6.971562732307842],
            [110.29766166275779, -6.971454707522978],
            [110.297756499006752, -6.971409621949917],
            [110.297823625266815, -6.971355449858409],
            [110.297884581742409, -6.97126508733905],
            [110.297949592925704, -6.971111413650841],
            [110.29799814247913, -6.970993896737121],
            [110.298019238025802, -6.970885384336404],
            [110.298042758222437, -6.970668331788108],
            [110.298072879353839, -6.970577923415281],
            [110.298098379990819, -6.970550825362352],
            [110.298139991995768, -6.970532796554037],
            [110.298174518246725, -6.970523802529039],
            [110.298207037340333, -6.970532896145419],
            [110.298270001097862, -6.970614397480317],
            [110.298368879036062, -6.970894949028184],
            [110.298458596530168, -6.971057897892586],
            [110.298560303166582, -6.971148502041213],
            [110.298627294803723, -6.971184782754791],
            [110.298666603365675, -6.971193886424297],
            [110.298698866522713, -6.971184889005369],
            [110.298756603421737, -6.971166884082835],
            [110.298800492048173, -6.971139813285338],
            [110.299080756276794, -6.970814597869481],
            [110.299141429555547, -6.970724234735125],
            [110.299282780012931, -6.970407858382016],
            [110.299433465685212, -6.970091495805002],
            [110.299461889161975, -6.970001084794524],
            [110.299486365577209, -6.96990162261399],
            [110.299506046257207, -6.969793108005867],
            [110.299554984719933, -6.969413277298207],
            [110.299576805827456, -6.969006270433002],
            [110.299496854251302, -6.968743837769854],
            [110.299368568751731, -6.968454197500693],
            [110.299286205661318, -6.968101308085391],
            [110.299321310083187, -6.967893317866787],
            [110.299402162091923, -6.967739667347812],
            [110.299702212562693, -6.967423525920812],
            [110.29980989863779, -6.967297051014127],
            [110.299927876028121, -6.967098228780616],
            [110.300051658667201, -6.966799916609033],
            [110.300202153440949, -6.966420236066825],
            [110.300301675572754, -6.966266612967611],
            [110.300596175336935, -6.966068051733016],
            [110.300776253672524, -6.965959774271472],
            [110.300893826833018, -6.965842358990893],
            [110.300985171620852, -6.965670632978834],
            [110.301050018529523, -6.965435550530703],
            [110.301141120452925, -6.965236688152664],
            [110.301181330825429, -6.965209611623029],
            [110.301305033530497, -6.965155522511202],
            [110.301791856324556, -6.96498438052368],
            [110.301993420152385, -6.964885179572962],
            [110.302138203527051, -6.964731622798128],
            [110.302183882272544, -6.964641236986513],
            [110.302216222987525, -6.964388015681193],
            [110.302238246006155, -6.964225232370636],
            [110.302318744440257, -6.963926855486402],
            [110.302391484511062, -6.963709875039059],
            [110.302509900732957, -6.963212556908854],
            [110.302539842065144, -6.962859833440413],
            [110.302510619823835, -6.962534158693827],
            [110.302423027027118, -6.962081763335996],
            [110.302386126839764, -6.961783213233283],
            [110.302399876737795, -6.961475692496348],
            [110.302432243668335, -6.961204380500662],
            [110.302472613895787, -6.961068760160321],
            [110.302585792701379, -6.96086088454985],
            [110.302690241987506, -6.960625860060484],
            [110.302731175327423, -6.960300288687207],
            [110.302779986780791, -6.959811913053477],
            [110.302858370187394, -6.959603986054798],
            [110.303145613571886, -6.959143097513564],
            [110.303510553502846, -6.958555688504953],
            [110.303803236987818, -6.9580495807855],
            [110.303993771927281, -6.957561413279349],
            [110.304133553539899, -6.957344530907216],
            [110.304358979022751, -6.956983049077053],
            [110.304525214883896, -6.956666706797012],
            [110.30463575248784, -6.95652214389666],
            [110.304887405517661, -6.956214972232154],
            [110.304979082800074, -6.956007064220007],
            [110.305059727544219, -6.955799139986862],
            [110.305153694311116, -6.955573144619696],
            [110.305255541827677, -6.955374296781421],
            [110.305383980253055, -6.955175487920704],
            [110.305524202776311, -6.954850061643981],
            [110.305638735905177, -6.954488416303477],
            [110.305817063443925, -6.954027365766088],
            [110.305855491569787, -6.953864606094868],
            [110.305938823373054, -6.953367234882888],
            [110.305977410676476, -6.95309593141541],
            [110.306079053977825, -6.952842810989565],
            [110.306206638409023, -6.952454048508698],
            [110.306392222179539, -6.951866373523404],
            [110.306545022195039, -6.951450511795725],
            [110.306596042380548, -6.951188271740819],
            [110.306807159225229, -6.950356409751016],
            [110.307054161173298, -6.949551736053746],
            [110.30727942017883, -6.948719894324671],
            [110.307597793321747, -6.947662054942916],
            [110.307700251469171, -6.94723707365859],
            [110.307814965746786, -6.946748792844964],
            [110.307815375778588, -6.946468387912786],
            [110.307737613838214, -6.946061234082737],
            [110.307577154123649, -6.945527324726369],
            [110.307534027596716, -6.945228765535258],
            [110.30754527455295, -6.944695106910874],
            [110.307555833592787, -6.944631804950157],
            [110.30773893712059, -6.944188850610164],
            [110.308093569044146, -6.943673783704524],
            [110.308454172483664, -6.94333058665512],
            [110.309130221634263, -6.942960712821084],
            [110.31020708531112, -6.942410514044783],
            [110.312013987676565, -6.94131865091608],
            [110.312850966872148, -6.940768097507658],
            [110.313122064165782, -6.940379311193785],
            [110.313578792699289, -6.939301068519164],
            [110.313876159063199, -6.938624539345324],
            [110.314247421894876, -6.938085624006542],
            [110.314693432106168, -6.937645338709824],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 156,
        id: 161,
        area: 3425783.249171,
        perimeter: 11632.151619,
        kode_pos: 50211,
        kode_kec: 3374160,
        kode_kel: 3374091005,
        kec: "Ngaliyan",
        kel: "Banbankerep",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.351038643205271, -7.022836555141875],
            [110.350974497445378, -7.02278633337614],
            [110.350807761116513, -7.02265579008755],
            [110.35066025739286, -7.022497197946822],
            [110.350552636501376, -7.022158674673715],
            [110.35052053400905, -7.02192464801383],
            [110.350553485281054, -7.021546722363324],
            [110.350424780907986, -7.020822999159511],
            [110.350493976686593, -7.020290335431508],
            [110.350703748637571, -7.019714669488788],
            [110.351767421516428, -7.017060980941425],
            [110.35212883112446, -7.015735339988421],
            [110.352208081260571, -7.015746248527334],
            [110.352600701118021, -7.014637704861352],
            [110.352933392005895, -7.014416771309213],
            [110.353473594194099, -7.014362167344274],
            [110.353709195819107, -7.014244877080003],
            [110.354040000918729, -7.011688272215697],
            [110.357441105210015, -7.012087963984682],
            [110.357658148326735, -7.010351714559787],
            [110.357752071445361, -7.010030132172147],
            [110.357884018304233, -7.009753572038422],
            [110.358126844185279, -7.009414896710899],
            [110.358357789025575, -7.009155767894164],
            [110.358740557664234, -7.008768853640154],
            [110.359386789585898, -7.008141533912008],
            [110.359808349417946, -7.007743601867005],
            [110.360013657332743, -7.007494814916002],
            [110.360152549302569, -7.00720165895136],
            [110.360219289525403, -7.007008031357444],
            [110.363899814206064, -7.007447509383501],
            [110.364310213104062, -7.004155246762596],
            [110.364414526786959, -7.002992030119336],
            [110.363999188157607, -7.002649798166136],
            [110.363776408205382, -7.002447545013508],
            [110.363587772968856, -7.002280392869785],
            [110.363448135289758, -7.002089955590875],
            [110.363397287606844, -7.001831943620744],
            [110.363335906601506, -7.001614770511909],
            [110.363274464324249, -7.00144282440647],
            [110.363192308037952, -7.001316077347901],
            [110.363120533743427, -7.001252662259629],
            [110.362976948405247, -7.001152968258681],
            [110.362740973038854, -7.000962694906214],
            [110.362566599386156, -7.000826777420652],
            [110.362371622264106, -7.000654650287995],
            [110.362320303926111, -7.000527944898555],
            [110.362330919092528, -7.00041941428172],
            [110.36234288926417, -7.000356112797178],
            [110.362471231353453, -7.000220605245666],
            [110.362582748306167, -7.000184574663863],
            [110.362780480171438, -7.000202933329514],
            [110.362984411618328, -7.000239391143321],
            [110.363176177828493, -7.000275832406277],
            [110.363423431885636, -7.000285212412853],
            [110.363566573820634, -7.00029445147456],
            [110.363803409123776, -7.000267635511555],
            [110.364012567885837, -7.000204600337643],
            [110.364273007375829, -7.000087361832044],
            [110.364509854803131, -7.000051500095772],
            [110.365164811445879, -7.000043338802056],
            [110.365251407384378, -7.000025364785516],
            [110.365265884858758, -6.999989202626891],
            [110.36524840534905, -6.999943951936139],
            [110.365202093135935, -6.99988057150339],
            [110.365137340940734, -6.999645303168237],
            [110.365137646562118, -6.99941916802667],
            [110.365226081127773, -6.998668517262613],
            [110.36524852898782, -6.998596184158617],
            [110.365282268897815, -6.998541957127661],
            [110.365327017946058, -6.998505835785907],
            [110.365374559456541, -6.998496854473648],
            [110.365433417463493, -6.998487888415055],
            [110.365492251036457, -6.998497013162273],
            [110.36573268999409, -6.998524473535674],
            [110.365858573842146, -6.998533688597814],
            [110.365984482111742, -6.998524812811648],
            [110.366124572616741, -6.998488819820624],
            [110.366172114117504, -6.998479838426259],
            [110.366222472539548, -6.998479906244781],
            [110.3662671605111, -6.998489011846782],
            [110.366286903380995, -6.998534265554047],
            [110.366289671501434, -6.998579496403877],
            [110.366363401880349, -6.998661004500285],
            [110.366507492156131, -6.998805925277805],
            [110.366593682964577, -6.998878404692208],
            [110.366651065473803, -6.998914663622582],
            [110.366732754106437, -6.998969046105466],
            [110.366801133663103, -6.999032456093251],
            [110.366837839185962, -6.999086778031898],
            [110.366864076938995, -6.999141085884835],
            [110.366885210073917, -6.999204432294315],
            [110.366882824862756, -6.999294883337682],
            [110.366868189047977, -6.999448635874128],
            [110.366875459449588, -6.999511963632195],
            [110.366884710244648, -6.999575294054703],
            [110.366899090052755, -6.999611495102528],
            [110.366917972092835, -6.999665793057693],
            [110.36692091346643, -6.99979243296678],
            [110.366903853328139, -6.999855727988732],
            [110.366894726951102, -6.99990998825947],
            [110.366896104942953, -6.999937126388588],
            [110.36692832049728, -6.999964306008867],
            [110.366957153284758, -6.999982435651037],
            [110.367016799260895, -7.000018697594791],
            [110.367072995446605, -7.000043412808591],
            [110.366925628535554, -7.000356531413756],
            [110.366874331465937, -7.000566510847185],
            [110.366821880991267, -7.000830543035849],
            [110.366828981372322, -7.001040577879618],
            [110.366848180827702, -7.001358783969453],
            [110.366888574038754, -7.001451370688689],
            [110.366963741132366, -7.001511232324156],
            [110.367068775646743, -7.001547444335095],
            [110.367164954347089, -7.001556619153003],
            [110.367233702544254, -7.001556711630772],
            [110.3674150750428, -7.001538864708205],
            [110.367480994096724, -7.001538953345231],
            [110.367562153983982, -7.001566198739498],
            [110.367608232491563, -7.001593396961828],
            [110.367655676856756, -7.001656778718694],
            [110.367747061042152, -7.001864946342343],
            [110.367827460337054, -7.002036917487653],
            [110.367883699483642, -7.002082220194691],
            [110.368019093909425, -7.002172856380495],
            [110.368126540677622, -7.002218227858421],
            [110.368158154111185, -7.002272542881971],
            [110.368272333041489, -7.00257119531176],
            [110.368335474809257, -7.002743143213752],
            [110.368504369194866, -7.003168505054259],
            [110.36855551606142, -7.003213800859087],
            [110.36862055012584, -7.003241024449048],
            [110.368659273056608, -7.003268212711736],
            [110.36871372979725, -7.003376830932216],
            [110.368787573963075, -7.003584974864832],
            [110.368794686537314, -7.003765892947418],
            [110.368829905078385, -7.00387448533793],
            [110.36884733641287, -7.003955917574158],
            [110.368957838200501, -7.004254564955851],
            [110.369042138323863, -7.004471768320986],
            [110.369209312043239, -7.004915218533938],
            [110.369397078207442, -7.005403923462188],
            [110.369504492830771, -7.005684475762862],
            [110.369571325454302, -7.005847383075522],
            [110.369627419415835, -7.006001230555602],
            [110.369683440516425, -7.006209350497799],
            [110.369729620290201, -7.006372230104859],
            [110.369802838701005, -7.006625600231192],
            [110.369825706437609, -7.006661812596477],
            [110.369992150161323, -7.007138901172989],
            [110.369720077100553, -7.007259279654814],
            [110.370067574128669, -7.007946587487026],
            [110.370278626471503, -7.008372005404818],
            [110.370445382286121, -7.008706909638443],
            [110.370616600231884, -7.009082338857501],
            [110.37076955432272, -7.009401198627016],
            [110.370861714613113, -7.009666462397112],
            [110.370922244354901, -7.009917088926006],
            [110.370997372162989, -7.010175032434282],
            [110.371083472947873, -7.010525895112059],
            [110.371101428621458, -7.010647072940688],
            [110.371098791749375, -7.010797883200008],
            [110.37108876940809, -7.011009495582578],
            [110.37106169479128, -7.01122838257482],
            [110.371020087341037, -7.011391303055275],
            [110.370946604378332, -7.011722021986888],
            [110.370853601719475, -7.012084336992636],
            [110.370772958185896, -7.012308017149838],
            [110.370658206677192, -7.012548678945973],
            [110.370782435159583, -7.012642122012627],
            [110.37096702498998, -7.012759959809331],
            [110.371174790210745, -7.012895919424291],
            [110.371269754775938, -7.012968409976692],
            [110.371441306220277, -7.013104321008554],
            [110.371734995079663, -7.013303713382836],
            [110.372291583524174, -7.013666274773013],
            [110.372639274930023, -7.013892875106694],
            [110.373737900755245, -7.014500385369812],
            [110.374166975280971, -7.014166276441876],
            [110.375331322826966, -7.013227101583458],
            [110.375700804233574, -7.012918561391949],
            [110.375038459718283, -7.014529254394619],
            [110.374842356955511, -7.014981264999914],
            [110.374831569690016, -7.015008386935656],
            [110.374708653765097, -7.015315767967682],
            [110.37459453242694, -7.015605069800322],
            [110.374414777900043, -7.016102329065975],
            [110.374298320295438, -7.016445900258976],
            [110.374242637736401, -7.016617689231336],
            [110.374201089249212, -7.016798542465858],
            [110.374169515554016, -7.016925136405994],
            [110.374040638001432, -7.017458644970762],
            [110.374001954836004, -7.01761236569819],
            [110.373953212152173, -7.017883663593955],
            [110.373950448260729, -7.01804647764945],
            [110.373970132119553, -7.018136958206067],
            [110.37402796249016, -7.018263671362338],
            [110.374107307268616, -7.01838136777574],
            [110.374189529669451, -7.018462886294528],
            [110.374360801000151, -7.018598796104463],
            [110.374592570110678, -7.01877096820932],
            [110.374781678040947, -7.018897856222804],
            [110.375172860527314, -7.019187831037788],
            [110.375275183034788, -7.01926033072322],
            [110.375327476002909, -7.01929658206504],
            [110.375394974589142, -7.019387126233415],
            [110.375465820066154, -7.019513856576813],
            [110.375501913952249, -7.019604358935036],
            [110.37553059148172, -7.019740078582096],
            [110.375521866602639, -7.019962823500691],
            [110.375467049056184, -7.020101688601422],
            [110.375244713613853, -7.020466820859277],
            [110.375081593520505, -7.02073470574144],
            [110.374521166403213, -7.020394091245727],
            [110.37423818020379, -7.020133282770432],
            [110.374125073572856, -7.019963285517713],
            [110.373960777757006, -7.019934758608273],
            [110.373773663864839, -7.020019432112209],
            [110.372923063379361, -7.020465558170109],
            [110.37272401452887, -7.020613384355872],
            [110.372610993870865, -7.020765202847095],
            [110.37255882030027, -7.021028124212667],
            [110.372536439404328, -7.021427503851839],
            [110.37253619664034, -7.021608672948422],
            [110.37249065063375, -7.021767135266683],
            [110.372337498012413, -7.021880161253612],
            [110.37212200402513, -7.021998765286492],
            [110.371797814270664, -7.022088807007241],
            [110.371509901645325, -7.022071545638063],
            [110.371277726577205, -7.021952342101383],
            [110.370945228654804, -7.021683918975029],
            [110.370825591964675, -7.021608990855361],
            [110.370660993605924, -7.021578862996223],
            [110.370473534949042, -7.021594586388847],
            [110.370207063295567, -7.021696136473946],
            [110.370025485330814, -7.021877061996361],
            [110.369821088034911, -7.022171187606987],
            [110.369679306216653, -7.022255920226137],
            [110.369526366289179, -7.022210422355874],
            [110.369373540774646, -7.022080001478267],
            [110.369192717458276, -7.021700435113114],
            [110.369097978917267, -7.02142296747328],
            [110.368927313714408, -7.02100937019068],
            [110.368825649133598, -7.020760125638265],
            [110.368627674014931, -7.020493766854992],
            [110.368322001752489, -7.020249909187008],
            [110.367758129956499, -7.019761647681277],
            [110.367405396431053, -7.019434423879491],
            [110.367127756349049, -7.019195253591103],
            [110.366807663234127, -7.018930493974795],
            [110.366538944271767, -7.018809474498873],
            [110.366291728246921, -7.018796856891628],
            [110.365943937813455, -7.018814332507352],
            [110.365598149197467, -7.018904449791152],
            [110.365314624651589, -7.019045604858121],
            [110.365025310493465, -7.019277336495373],
            [110.364750416243467, -7.019556160259946],
            [110.364521573471137, -7.019896178374555],
            [110.364387440196722, -7.020107368859224],
            [110.364030202781194, -7.020676719940171],
            [110.363807275458967, -7.02098375741996],
            [110.363619985321563, -7.021147023679257],
            [110.363474473485752, -7.02125254573508],
            [110.363299279172566, -7.02115996275253],
            [110.363058908479246, -7.021052828785075],
            [110.362805085078591, -7.02101243451872],
            [110.362567896079881, -7.021022124707022],
            [110.36230727360342, -7.021068495044664],
            [110.361986503927284, -7.021128133300477],
            [110.361765980993113, -7.021164545463535],
            [110.361622375950603, -7.021137650354206],
            [110.36146228232397, -7.020953871901483],
            [110.36122915810634, -7.020824461405959],
            [110.360959952491271, -7.020698072129086],
            [110.360512428354482, -7.020398164537024],
            [110.360037835807404, -7.020158991045038],
            [110.359550404650804, -7.019922364389966],
            [110.359388528508333, -7.020029865000342],
            [110.359185830955965, -7.019952644227392],
            [110.358770128956067, -7.019826401144476],
            [110.358659907727628, -7.019705704936319],
            [110.358522573661674, -7.019481955045986],
            [110.358473456257755, -7.019400479269887],
            [110.358394830022306, -7.019174236694622],
            [110.358335921828029, -7.019011338893954],
            [110.358259447578433, -7.018866507873685],
            [110.358110656315347, -7.018640169311769],
            [110.357959294080402, -7.018431918001538],
            [110.357905059445784, -7.018368525985641],
            [110.357846015118042, -7.018305127383782],
            [110.357799640774815, -7.01827801501557],
            [110.357737459117814, -7.018241661023169],
            [110.357550041809631, -7.018123814284406],
            [110.357461290561986, -7.01806037497577],
            [110.357387546705681, -7.01798791079838],
            [110.357328244389564, -7.017906420984179],
            [110.357254525396428, -7.017815866020145],
            [110.357165823891876, -7.017716245125548],
            [110.357121541417129, -7.017616685058313],
            [110.357084356870175, -7.017499043907431],
            [110.357083840671237, -7.017462861594382],
            [110.357107608775877, -7.017254849912734],
            [110.357153444858952, -7.017046868448464],
            [110.357134513826509, -7.0170287517188],
            [110.357102290207635, -7.017035859774252],
            [110.357093760479771, -7.017037741306111],
            [110.357078155521876, -7.017059000964564],
            [110.357060596410662, -7.017082922891178],
            [110.357027702845727, -7.017137150245309],
            [110.356952675933428, -7.017381273306337],
            [110.356922623981603, -7.017426459141149],
            [110.356880406316733, -7.017471628304761],
            [110.356831989161705, -7.017498698166776],
            [110.356759843686859, -7.017498599303424],
            [110.356621531394907, -7.017471273536373],
            [110.356564380867511, -7.017471195196683],
            [110.356455959085324, -7.017516273559303],
            [110.356347487578631, -7.017597533427484],
            [110.356302132832951, -7.017660789030616],
            [110.356283926306332, -7.017733127261803],
            [110.356280923078145, -7.017859758742831],
            [110.356284968080956, -7.018004490689676],
            [110.356273144491965, -7.018167291670867],
            [110.35625127235356, -7.018230579469579],
            [110.356238773985794, -7.018266743925213],
            [110.356146994770867, -7.018348026621754],
            [110.356009677202906, -7.018420201413008],
            [110.35576736456585, -7.018519447772129],
            [110.355703868583277, -7.018587261389091],
            [110.355679237110323, -7.018694857479606],
            [110.355708395193574, -7.018856342371459],
            [110.35573756674934, -7.019008042743752],
            [110.355787523762942, -7.0192017165454],
            [110.355783087470314, -7.019219342677028],
            [110.355768535512354, -7.019277160025348],
            [110.355721360360803, -7.01939842351412],
            [110.355634995452817, -7.019531374625672],
            [110.35548198692392, -7.019699458513018],
            [110.355230979622121, -7.019914373458445],
            [110.355062344489795, -7.020051125195393],
            [110.354883746037146, -7.020311148302392],
            [110.354628605034321, -7.020682609729501],
            [110.354367557880366, -7.021075588821494],
            [110.354035646616794, -7.021722867999437],
            [110.353786339666144, -7.022123690446745],
            [110.353648999113958, -7.022291795167035],
            [110.353519568880174, -7.022405117363921],
            [110.35340193937391, -7.022483231474631],
            [110.353300033415522, -7.022522229107555],
            [110.353062996718947, -7.022547342013013],
            [110.35187989903045, -7.02258093302516],
            [110.351587981025318, -7.022633366015102],
            [110.35129406360862, -7.022715149633221],
            [110.351076192293291, -7.022818707684644],
            [110.351038643205271, -7.022836555141875],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 157,
        id: 162,
        area: 2580927.535712,
        perimeter: 8546.534035000001,
        kode_pos: 50278,
        kode_kec: 3374070,
        kode_kel: 3374052003,
        kec: "Tembalang",
        kel: "Kramas",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.438028672243078, -7.063698713747556],
            [110.438062244449938, -7.063653738318693],
            [110.438102228555408, -7.063581422371932],
            [110.438137957395369, -7.063518146809526],
            [110.438158689582195, -7.063454853177866],
            [110.438176298334241, -7.063400601297589],
            [110.438195289105764, -7.063373487627328],
            [110.438224727392864, -7.063364477571242],
            [110.438256146367237, -7.063355469898814],
            [110.438320932255181, -7.063364593440175],
            [110.438597902036733, -7.063401108975449],
            [110.438637515809035, -7.06340115665714],
            [110.438916477244547, -7.063428628887441],
            [110.439077990360857, -7.063474050748164],
            [110.439115601653427, -7.063492187014977],
            [110.43915121046102, -7.063528411904604],
            [110.439222428092791, -7.063600861676842],
            [110.439274720405564, -7.063646152135216],
            [110.439460839895602, -7.063700648972431],
            [110.439767727115409, -7.063800518415788],
            [110.440287647637078, -7.063927779946352],
            [110.440760619524752, -7.06403689358297],
            [110.4410078254772, -7.064118454098992],
            [110.441250514737646, -7.064191254632163],
            [110.441476542409163, -7.064236752992935],
            [110.441761990935859, -7.064282322382095],
            [110.442017739971021, -7.06431881054456],
            [110.442347079365973, -7.06433729553418],
            [110.442710950304786, -7.064346776039351],
            [110.443080154153066, -7.064392444720281],
            [110.443404085075571, -7.064438059080017],
            [110.443842853148411, -7.064519992287046],
            [110.444206953294398, -7.064574699531589],
            [110.444361393546714, -7.064620111200583],
            [110.444446269709843, -7.064629257851425],
            [110.444511077556101, -7.064620289532539],
            [110.444615901016093, -7.064511868093689],
            [110.444790808902866, -7.064240710624227],
            [110.444861731405297, -7.064087021128851],
            [110.444971753238661, -7.063652966863073],
            [110.445010537247654, -7.063399738299691],
            [110.445068802009899, -7.063182715008992],
            [110.445152424891404, -7.063056177131545],
            [110.445236233658576, -7.063011049191727],
            [110.445306983337957, -7.063002087810343],
            [110.445416465601539, -7.063020309052733],
            [110.445532108251214, -7.063092810753005],
            [110.445789142637167, -7.06323784467247],
            [110.445943539583027, -7.063319437854806],
            [110.446123477960853, -7.063337742631319],
            [110.446478379893293, -7.063274845268187],
            [110.446948194047806, -7.063184947315197],
            [110.447063074098764, -7.063185083521947],
            [110.447436826500052, -7.063212663051296],
            [110.447676479358037, -7.06322199244175],
            [110.44782984142816, -7.063222174029563],
            [110.447992866910027, -7.063186184883199],
            [110.448052330540065, -7.063150073134972],
            [110.448225015194538, -7.062842729415501],
            [110.448455358221906, -7.06258972697793],
            [110.448647427032384, -7.062399997887293],
            [110.448868138421659, -7.062156029354518],
            [110.44916572305489, -7.061749331891168],
            [110.449290730910676, -7.061559523293577],
            [110.449684215028654, -7.061171029689851],
            [110.450039473085326, -7.060565397819583],
            [110.450298846146396, -7.060167699945884],
            [110.450435811880084, -7.059914586302587],
            [110.450532000090263, -7.059688561196976],
            [110.450599145517771, -7.059616275973204],
            [110.450800811294286, -7.059444648182221],
            [110.450954342820722, -7.059300100270505],
            [110.451050141069274, -7.059164529927562],
            [110.451127013358004, -7.059001800691616],
            [110.45120400282741, -7.058739570672441],
            [110.451300360879529, -7.058368816959266],
            [110.451406243277603, -7.058079484238485],
            [110.451579164030605, -7.057808321327304],
            [110.45176251123965, -7.05757335273166],
            [110.451877485786568, -7.057492077915297],
            [110.451963796960499, -7.05748313372161],
            [110.452078948015696, -7.057492314442734],
            [110.452274132118589, -7.057537771209974],
            [110.452318545044207, -7.057546868861955],
            [110.452874366361527, -7.05770129486718],
            [110.453583612207794, -7.057801626629394],
            [110.45430214290505, -7.057947195899543],
            [110.454758450750461, -7.058029139047763],
            [110.455303553176265, -7.058156413110633],
            [110.45599285645396, -7.058374310097175],
            [110.456310822058484, -7.058437999418068],
            [110.456885553072965, -7.058393440856397],
            [110.457533988848382, -7.058231375150815],
            [110.458053460716542, -7.058014885501178],
            [110.458287274715431, -7.057933747002935],
            [110.458633127555956, -7.057861783891152],
            [110.458910453128951, -7.05783496882129],
            [110.459629520954479, -7.057763437456468],
            [110.460305537855461, -7.057728037393129],
            [110.460355358415569, -7.057710128154699],
            [110.46045658257286, -7.058261899493694],
            [110.460308199582059, -7.059093918539674],
            [110.460208871916279, -7.059835538725601],
            [110.460051601276447, -7.060278588676082],
            [110.459955008840666, -7.060368932377745],
            [110.459858311442702, -7.060549731421444],
            [110.459814338020777, -7.06064918154729],
            [110.459735299159817, -7.060730499967406],
            [110.45947229755582, -7.060847787473431],
            [110.459393248101037, -7.06093815136347],
            [110.459279122691967, -7.061019429038832],
            [110.458963596280171, -7.061046199885294],
            [110.458814436911965, -7.061082209075165],
            [110.458665203907657, -7.061181536956921],
            [110.458542264635014, -7.0612989863959],
            [110.458427991696382, -7.061506901319994],
            [110.458226348578151, -7.061660441472634],
            [110.458068280473867, -7.061814032150342],
            [110.457918910161254, -7.062030951689894],
            [110.457787156707894, -7.062184572831906],
            [110.457672999113427, -7.062292986663081],
            [110.45752401681932, -7.062419451052346],
            [110.457348510401147, -7.062482565721124],
            [110.457172982844284, -7.062563771383456],
            [110.45710980983651, -7.06262701666912],
            [110.457104600587911, -7.062726511588183],
            [110.457102514401569, -7.062816964597888],
            [110.457067343449879, -7.062889288009509],
            [110.457007314553323, -7.062925400305105],
            [110.4569439323854, -7.062925326514072],
            [110.45687601236645, -7.062934292973799],
            [110.456846542712611, -7.062970440830626],
            [110.456827196144317, -7.063060873733891],
            [110.456821976268145, -7.063169414173909],
            [110.456792747325764, -7.063241744478512],
            [110.456734373871356, -7.063314040833649],
            [110.456671232375058, -7.063350149453675],
            [110.456622521644277, -7.063386274877224],
            [110.456588178319279, -7.063476690291894],
            [110.456583262453435, -7.063567139991568],
            [110.456578388828106, -7.063621407569172],
            [110.456554022883978, -7.063648515805588],
            [110.456520004728134, -7.063702749420397],
            [110.456364860014602, -7.06377493293906],
            [110.456273726857759, -7.063792917783275],
            [110.456188839878067, -7.063792818808447],
            [110.456149749589713, -7.063828955393515],
            [110.456117135587192, -7.06389223615335],
            [110.456012682293448, -7.063928296493063],
            [110.455940790151885, -7.063946303712163],
            [110.45590171040665, -7.063973394744512],
            [110.455895064930203, -7.064090979031191],
            [110.455842633321268, -7.06416328218614],
            [110.455771013517506, -7.064190335234729],
            [110.455660094445534, -7.064190205777723],
            [110.455529630191577, -7.064208144557054],
            [110.455438454592851, -7.064262311342466],
            [110.455346879571238, -7.064415978588486],
            [110.455268405211783, -7.064497296791277],
            [110.455183737538562, -7.064551471121827],
            [110.45515086157495, -7.064596660411912],
            [110.455111665230248, -7.064723252175058],
            [110.455046510948137, -7.064786494817085],
            [110.455013656139855, -7.064813593039627],
            [110.454987433393427, -7.06497638210918],
            [110.454915749849135, -7.065057708176095],
            [110.454817783038067, -7.065111866881301],
            [110.45473274745062, -7.065238405001056],
            [110.454628523713922, -7.065319692962181],
            [110.454569562538396, -7.06541007938567],
            [110.454530450782244, -7.065464306865646],
            [110.454439023683292, -7.065491336514647],
            [110.454334559312485, -7.065536441964807],
            [110.454315799250224, -7.065608784318385],
            [110.454337438480493, -7.065735447184363],
            [110.454293778127749, -7.065807760393202],
            [110.454228057664551, -7.065871002235403],
            [110.454213552546122, -7.065934304024068],
            [110.454242591101391, -7.066024793400365],
            [110.454227718137531, -7.066160459064567],
            [110.454198424913884, -7.066287062306734],
            [110.454190940668227, -7.066395600002523],
            [110.454205548375683, -7.066486072485739],
            [110.454168951266382, -7.066567439481011],
            [110.454161456391816, -7.06668502269971],
            [110.45415431875908, -7.066739287570171],
            [110.454197820052627, -7.066802657269442],
            [110.454205102685975, -7.066865984561849],
            [110.454175851847864, -7.066956405693064],
            [110.454124834421862, -7.067028710257386],
            [110.454102940477426, -7.067119139996441],
            [110.454095381878517, -7.067290996360718],
            [110.454058752829695, -7.067399499913592],
            [110.454051226049998, -7.067544219698359],
            [110.454073169447113, -7.06765279184888],
            [110.454042546193932, -7.067707029206009],
            [110.453991277550571, -7.067752196839437],
            [110.453991224463124, -7.067797424463796],
            [110.453991171375321, -7.067842652088069],
            [110.453967664545914, -7.067860715625573],
            [110.453925754762409, -7.067887803138975],
            [110.453874224368391, -7.067914879375995],
            [110.453790132439494, -7.067960008524025],
            [110.453748222637472, -7.067987096019869],
            [110.453720156577774, -7.068032290810669],
            [110.453692090512433, -7.068077485599273],
            [110.453645370389594, -7.068104567446274],
            [110.45361733618833, -7.068122625656847],
            [110.453607956035782, -7.068158796807116],
            [110.453621757281397, -7.068213086205692],
            [110.453617166181942, -7.068267354043131],
            [110.453579773063083, -7.068303492351996],
            [110.453542348057141, -7.068366767229262],
            [110.453504912412612, -7.068439087626449],
            [110.453472308321935, -7.068493322612706],
            [110.453453537344529, -7.068574710426783],
            [110.453429987938449, -7.068628956026955],
            [110.453406764006743, -7.068647019862683],
            [110.453406700225401, -7.068701293004511],
            [110.45341115316505, -7.06876461697959],
            [110.453336366806155, -7.068836893550629],
            [110.453247704771471, -7.068918199364873],
            [110.453210290301001, -7.068972428683326],
            [110.453009112380997, -7.069207376521236],
            [110.452528242124913, -7.06955054216427],
            [110.452195759489129, -7.069794380848861],
            [110.452138505647838, -7.069875723342377],
            [110.452096542291812, -7.069948038272642],
            [110.452034761050015, -7.070029375426599],
            [110.45197466688731, -7.070119760088096],
            [110.451923931713097, -7.070192064681581],
            [110.451863565208825, -7.070273403471335],
            [110.451823582493915, -7.070345720699844],
            [110.451785318857262, -7.070399948880279],
            [110.451750744362286, -7.070445135864642],
            [110.451546650097185, -7.070752443781304],
            [110.451388555296333, -7.070924122768449],
            [110.451155433054495, -7.07113189544025],
            [110.450844149282858, -7.07139384903963],
            [110.450672786897982, -7.071538375510264],
            [110.450268584358483, -7.0718906744039],
            [110.449962392896765, -7.072152633338495],
            [110.449712819371598, -7.072631751631886],
            [110.449488754731959, -7.073074717778907],
            [110.44917140212074, -7.073680392959099],
            [110.448899605621975, -7.074286121757846],
            [110.448631476151988, -7.074900900197124],
            [110.448392609266961, -7.075416212292923],
            [110.44817556224379, -7.075904413499694],
            [110.447930981248078, -7.07646494611096],
            [110.447581548960727, -7.076916807830375],
            [110.44727177405187, -7.077332534223089],
            [110.44712655770806, -7.077533755797953],
            [110.447089282740166, -7.077585406665063],
            [110.446955476660492, -7.077522114532242],
            [110.446753221676801, -7.077467601050278],
            [110.44650374402157, -7.07738589476807],
            [110.446371664327131, -7.077331464564996],
            [110.44620451865643, -7.077258901566381],
            [110.446062241538485, -7.077213504670937],
            [110.445887738895806, -7.07714093281055],
            [110.445817911476553, -7.077086576557218],
            [110.445752840504696, -7.077077453565419],
            [110.445683220522568, -7.077086416190813],
            [110.445648960268045, -7.077104466436254],
            [110.445486746205532, -7.077167591883895],
            [110.445401835340419, -7.077185581771872],
            [110.445322061028463, -7.077167395680951],
            [110.445269745053054, -7.077140196778059],
            [110.445212400544165, -7.077058718752389],
            [110.44513508444615, -7.076877716189109],
            [110.445082898170142, -7.07674197117494],
            [110.445008935341548, -7.076692828913197],
            [110.444930845240293, -7.076692735811283],
            [110.444850949372878, -7.076751581192074],
            [110.444784994183422, -7.076761903826584],
            [110.444654891662793, -7.076721877027912],
            [110.444465791909366, -7.076678312699007],
            [110.444335681152381, -7.076645219998489],
            [110.444203886928889, -7.07656878654492],
            [110.444070446613338, -7.07641780851453],
            [110.443916284047589, -7.076181861835344],
            [110.443733112851291, -7.076070991880447],
            [110.4432845820822, -7.075862408843254],
            [110.442645231646139, -7.075681200984003],
            [110.442359879496223, -7.075583025134569],
            [110.442295391641451, -7.075493440031737],
            [110.442287171695284, -7.075397677587168],
            [110.442355622275016, -7.075146701292073],
            [110.442452432301025, -7.074858746719856],
            [110.442515128564636, -7.074704784984926],
            [110.442709425791278, -7.074278293968137],
            [110.442905778902301, -7.073874702658815],
            [110.443072871066889, -7.073546502923985],
            [110.443079433383531, -7.07350128317652],
            [110.44307297945349, -7.073456047864441],
            [110.443053498441685, -7.073419842493464],
            [110.442949509421553, -7.073302126386347],
            [110.442735317227175, -7.073057641159474],
            [110.442345057319216, -7.072632034725731],
            [110.442103440225509, -7.072369425380947],
            [110.441745312981695, -7.072004055773265],
            [110.441565209753492, -7.071839527680142],
            [110.441418607960912, -7.071717327445712],
            [110.441310042464991, -7.071641024398657],
            [110.441160345534755, -7.07154332561382],
            [110.441015118677782, -7.071446343832754],
            [110.440699006202365, -7.071273233768922],
            [110.440278821068205, -7.071028500220947],
            [110.43996216709661, -7.070811027328577],
            [110.439709407879064, -7.070638858655944],
            [110.439439061924432, -7.070502861019956],
            [110.439087272242958, -7.070359756937773],
            [110.438807767090879, -7.070236525645623],
            [110.438568276109621, -7.070130936267457],
            [110.438362118734844, -7.070031187179567],
            [110.438221882865108, -7.069958866910261],
            [110.438026769223086, -7.069831781576353],
            [110.437860237351188, -7.069723034558177],
            [110.437564220011851, -7.069471815019444],
            [110.437435861554249, -7.069252465817038],
            [110.437128780658014, -7.068750719647466],
            [110.436876754721126, -7.068717795354945],
            [110.436283500064391, -7.06862662304975],
            [110.435501817300107, -7.068517130800814],
            [110.43490998566827, -7.068430170007705],
            [110.434816968087887, -7.068416502063656],
            [110.43484192862347, -7.068343552311391],
            [110.434872553443498, -7.068254048462335],
            [110.435051621937006, -7.067931896943567],
            [110.435389102579791, -7.067431533133262],
            [110.435670477101695, -7.067141531872905],
            [110.436036575942097, -7.066806884895583],
            [110.43634206349229, -7.066540190108051],
            [110.436536420375703, -7.066406892924581],
            [110.436741595218152, -7.066302480406717],
            [110.437036524175184, -7.066230472537912],
            [110.437495883956956, -7.066131526355056],
            [110.437699464991169, -7.066080765448907],
            [110.437878704471828, -7.065934852050947],
            [110.438050362592634, -7.065801526811506],
            [110.438124246463516, -7.065734281435344],
            [110.438151434726393, -7.065642921696705],
            [110.438171799421056, -7.065486738785622],
            [110.438215023963139, -7.065074068249658],
            [110.438181440477166, -7.064766480282046],
            [110.438147432097225, -7.064576483493862],
            [110.43814765050594, -7.064395573457785],
            [110.438159122585404, -7.064033766676331],
            [110.438159242699072, -7.063934266154152],
            [110.438136715430815, -7.063843783862445],
            [110.438100301499972, -7.063771375869417],
            [110.438028672243078, -7.063698713747556],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 158,
        id: 163,
        area: 3793961.2188380002,
        perimeter: 12119.810255,
        kode_pos: 50266,
        kode_kec: 3374030,
        kode_kel: 3374031003,
        kec: "Banyumanik",
        kel: "Jabungan",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.435029883562095, -7.098965724462529],
            [110.434795094491122, -7.098872113165517],
            [110.434451231982052, -7.098707109166034],
            [110.43438347718255, -7.098634415522447],
            [110.434383518566449, -7.098600530403476],
            [110.434519642865581, -7.09824248161393],
            [110.434471236481599, -7.09819885598975],
            [110.434313893626211, -7.098075225423215],
            [110.434139634369615, -7.097915268648912],
            [110.434028372617263, -7.097755388633662],
            [110.433955890377206, -7.097585874409853],
            [110.433883390437543, -7.097430882371039],
            [110.433806020928245, -7.097295247337502],
            [110.433629395388053, -7.097089300504068],
            [110.433600408499316, -7.097016654065676],
            [110.433503619845837, -7.096910039727009],
            [110.43340197937377, -7.096808260187709],
            [110.433314817553665, -7.096754905682769],
            [110.433266399593379, -7.096720961412942],
            [110.433232614220216, -7.096609583195279],
            [110.433184373963059, -7.096430416996967],
            [110.433155345718816, -7.096391655652534],
            [110.433106915960522, -7.096367392830826],
            [110.433043918776278, -7.096367315904689],
            [110.432976093429971, -7.096352710859899],
            [110.43295190522015, -7.096318796153315],
            [110.432937420736522, -7.096275211822213],
            [110.43293747406247, -7.096231645247205],
            [110.432966620773911, -7.096173591991532],
            [110.432986069684063, -7.096120367627787],
            [110.433000672655254, -7.09606713734485],
            [110.433000785215981, -7.095975163462338],
            [110.432969435355602, -7.09585362265579],
            [110.432923023428486, -7.09576449639621],
            [110.432876554635584, -7.095721841147037],
            [110.432799057677812, -7.09569076576206],
            [110.432709925758815, -7.09566354873997],
            [110.432647945281474, -7.095624747108297],
            [110.432597642468309, -7.095547233838104],
            [110.43255124486997, -7.095446489795361],
            [110.432508705059078, -7.09536124082434],
            [110.432473989892586, -7.095217912562357],
            [110.432414040366183, -7.095103597880638],
            [110.432425865067302, -7.094944836139785],
            [110.432480423968585, -7.094712547411653],
            [110.432537032615016, -7.094389255303799],
            [110.432560416302906, -7.094288596528551],
            [110.432587657743753, -7.09420343282565],
            [110.432607197867412, -7.094075661217031],
            [110.432611245284292, -7.093936252907737],
            [110.432572653429261, -7.093792919914241],
            [110.432524438533591, -7.093593422600088],
            [110.43248968551552, -7.093481075015436],
            [110.432443288112651, -7.093380330971299],
            [110.43240852564162, -7.093275728551544],
            [110.432412539895381, -7.093163428335648],
            [110.432451449381617, -7.093047298168014],
            [110.432501989008301, -7.092931182204391],
            [110.432544846283704, -7.092756967989406],
            [110.432527607284698, -7.092588489240005],
            [110.432543180528398, -7.092534291996552],
            [110.43259368688409, -7.092445284108081],
            [110.432617075168324, -7.092340752727973],
            [110.432605535084576, -7.092267159409912],
            [110.432570748937493, -7.092181919925443],
            [110.432512693038291, -7.092104397194678],
            [110.432435343679359, -7.09195327166495],
            [110.432392880026612, -7.091806061340741],
            [110.432404600248844, -7.091732496438525],
            [110.432821131151016, -7.091166889716843],
            [110.432752134404609, -7.091127544470299],
            [110.432681796102287, -7.091027958126769],
            [110.432570493314003, -7.090874048769356],
            [110.43251172372014, -7.090801613022093],
            [110.432464861107007, -7.09071110082025],
            [110.432429755372311, -7.090493966028512],
            [110.432429877169938, -7.090394465717879],
            [110.432418408202963, -7.09028590575255],
            [110.432389089887792, -7.090195414976233],
            [110.432306866991723, -7.090095814071676],
            [110.432219029005964, -7.089960024309343],
            [110.432195697448591, -7.0898333588544],
            [110.432207387878563, -7.089761009168996],
            [110.432260436399702, -7.089652528027773],
            [110.432331006958464, -7.089562159275544],
            [110.432448933296257, -7.089390438877119],
            [110.432578394946404, -7.089273005507065],
            [110.432648876852966, -7.089255000564413],
            [110.432719336624913, -7.089255086578033],
            [110.43291308325486, -7.089327687018682],
            [110.432995405704929, -7.089345878461993],
            [110.433042412074585, -7.089318799318758],
            [110.433048409759351, -7.089273579147345],
            [110.433072029049129, -7.089165061988373],
            [110.433107172731511, -7.089119877369533],
            [110.433130703543966, -7.089083724080588],
            [110.433219045736735, -7.08903860433806],
            [110.43342483186504, -7.088984582270824],
            [110.433566666547136, -7.088930482169342],
            [110.433625535524172, -7.088921508416055],
            [110.433698009189186, -7.088894460235085],
            [110.433825107251778, -7.088858433091218],
            [110.433936641904822, -7.088822386955334],
            [110.434011074256716, -7.088813432107126],
            [110.434069954252521, -7.088795412809638],
            [110.434118669371117, -7.088759290128817],
            [110.4341891952987, -7.088705102996347],
            [110.434260581156195, -7.088641871398725],
            [110.434373846649251, -7.088578690757766],
            [110.434525008117447, -7.088533647195248],
            [110.434600605382542, -7.088497557164223],
            [110.434622166281713, -7.088452355890444],
            [110.434627630934273, -7.088379998534342],
            [110.434627785302354, -7.088253361715443],
            [110.434622541178214, -7.088144809330309],
            [110.434573980541415, -7.088054295254318],
            [110.434460924758355, -7.087945611706421],
            [110.434315599395447, -7.087845934363922],
            [110.434261651382656, -7.087764459205006],
            [110.434245588281712, -7.087710166653578],
            [110.43426176170756, -7.087674004337785],
            [110.434364296152296, -7.087592719616772],
            [110.434396892855091, -7.087547531778954],
            [110.434402313403183, -7.087511356372765],
            [110.434391321691905, -7.087475160996654],
            [110.434337623581428, -7.087420822652826],
            [110.434283653545123, -7.087357438472399],
            [110.434246073710497, -7.087312165235351],
            [110.434154413543453, -7.087293962670106],
            [110.433976425512171, -7.087293745979376],
            [110.433822489922051, -7.087293558516227],
            [110.433678175310106, -7.087293382723234],
            [110.433573215596937, -7.087275163842683],
            [110.43348440726578, -7.087238873624561],
            [110.433399277556433, -7.087202587874251],
            [110.433313875941792, -7.08715725627895],
            [110.433238683356549, -7.08709384611841],
            [110.433117105879148, -7.087012288388457],
            [110.433005715350347, -7.086930743054529],
            [110.432917212220332, -7.086876362111523],
            [110.432854687012707, -7.086867240337903],
            [110.432779450300089, -7.086840012049986],
            [110.432726873277772, -7.086794720405129],
            [110.432654804561167, -7.086722268473776],
            [110.432592584478328, -7.086695056043244],
            [110.432569392051008, -7.086685982238038],
            [110.432553013018378, -7.086658825752289],
            [110.432559848539441, -7.086622652103738],
            [110.432592750428057, -7.086559373775397],
            [110.432618861044332, -7.086496087155675],
            [110.432638746329459, -7.086432792936598],
            [110.432642208332425, -7.086378524172388],
            [110.432648771933344, -7.086333304691172],
            [110.432684808569206, -7.086251939181749],
            [110.432717688290751, -7.086206751809891],
            [110.432747422167012, -7.086188697092219],
            [110.43277345532465, -7.086188728854406],
            [110.432813059941225, -7.086197822670413],
            [110.432845862242999, -7.086215953683735],
            [110.432914557402356, -7.086270310471014],
            [110.43296068137596, -7.086270366728392],
            [110.433013052780723, -7.086252339602592],
            [110.433085265181404, -7.086207200167812],
            [110.433220024792135, -7.086153091475322],
            [110.433302141076908, -7.086107964077244],
            [110.433390754587947, -7.086071890080929],
            [110.433443408925882, -7.086053863248244],
            [110.433476211246116, -7.086071994218349],
            [110.433518928531271, -7.086081091767363],
            [110.433564769521851, -7.086081147618545],
            [110.433679677051487, -7.086063196598508],
            [110.433732320339601, -7.086054215218663],
            [110.433764894901415, -7.086027118391706],
            [110.433821848950373, -7.08596386925035],
            [110.433894039161544, -7.085936820653254],
            [110.433982641566729, -7.085909792024601],
            [110.434054787627176, -7.085918925349477],
            [110.434150126118595, -7.085937132390586],
            [110.434228791506612, -7.085937228122468],
            [110.434337190700106, -7.085919269015207],
            [110.434504502566341, -7.085856154033129],
            [110.434586618689337, -7.085811026393525],
            [110.434609888280406, -7.085756781681971],
            [110.434626355589785, -7.085711574198851],
            [110.434645935553348, -7.085666370499571],
            [110.434692092543344, -7.085639290115836],
            [110.434754933670831, -7.085621275511638],
            [110.434824022145094, -7.085585177488784],
            [110.434863387865875, -7.08555808883152],
            [110.434892893740766, -7.085494806179455],
            [110.434912756637189, -7.085449602809646],
            [110.434909449141969, -7.085377234773826],
            [110.434902973910383, -7.085350090398289],
            [110.434889990399739, -7.085322938112961],
            [110.434860322756364, -7.085286720047774],
            [110.434804644039275, -7.085232379361104],
            [110.434758847198111, -7.085196141684908],
            [110.434735992857171, -7.085141840891473],
            [110.434736036933828, -7.08510565893758],
            [110.434742578267247, -7.085078530383735],
            [110.434772084122315, -7.085015247736931],
            [110.434834774396094, -7.084888686906202],
            [110.434864258192505, -7.084843495228656],
            [110.434900522208196, -7.08480735729052],
            [110.43497274522646, -7.084753172038415],
            [110.43499230308251, -7.084726059294887],
            [110.434989245498784, -7.084680828067776],
            [110.434969481773749, -7.084644622044449],
            [110.434907272814499, -7.084608364443663],
            [110.434888651986967, -7.084563114304034],
            [110.434894943378637, -7.084508848935371],
            [110.434901278827766, -7.084418401610386],
            [110.434876204816732, -7.084327916116965],
            [110.434832565083269, -7.084146953036659],
            [110.434832631177485, -7.084092680102573],
            [110.434857881413905, -7.084038437772654],
            [110.434922104112971, -7.084047561313811],
            [110.435035269252921, -7.084065789807626],
            [110.435160896002458, -7.084074987907077],
            [110.435229973202311, -7.08404793529046],
            [110.435267945939145, -7.084002753887324],
            [110.435337133178678, -7.083885246354592],
            [110.435425334239284, -7.083722534372821],
            [110.435456820677672, -7.083659254069188],
            [110.435475856530005, -7.083595958650343],
            [110.435482202842053, -7.083496465813697],
            [110.435495417381773, -7.083333662786568],
            [110.435539574357705, -7.083089487774735],
            [110.435564978445512, -7.082908608524753],
            [110.435584014244554, -7.082845313095851],
            [110.435584190227658, -7.082700585244424],
            [110.435559127145964, -7.082601054287253],
            [110.435552706897539, -7.08252868246385],
            [110.435546569617216, -7.082456310983658],
            [110.435521506552789, -7.082356780025105],
            [110.435496660465091, -7.082311522353616],
            [110.435477745685546, -7.082275317383239],
            [110.435477822686849, -7.082211998947968],
            [110.435490328225924, -7.082166786603235],
            [110.435521803534328, -7.082112551772822],
            [110.435540828299196, -7.082058301832345],
            [110.435547119558123, -7.082004036440335],
            [110.435534735015707, -7.081949748387386],
            [110.435522056510308, -7.081904505481811],
            [110.435465584182879, -7.081804936407874],
            [110.43545321065352, -7.08174160286256],
            [110.43545330965182, -7.081660193444233],
            [110.435491021185982, -7.081596920675675],
            [110.435516249192716, -7.081560769269559],
            [110.435522529444953, -7.08151554936753],
            [110.435528864685338, -7.081425102009522],
            [110.435522749405493, -7.081334639544711],
            [110.435510408869675, -7.081244169526463],
            [110.435497818365306, -7.081126562690947],
            [110.435473049325182, -7.081017986582748],
            [110.435441544104123, -7.080864174777064],
            [110.43545443155098, -7.080737553349893],
            [110.435479758487361, -7.080619992518632],
            [110.435536505605867, -7.080493424297721],
            [110.435605659202693, -7.080403053135687],
            [110.435706221960999, -7.080312720053277],
            [110.435781883706255, -7.080222356752261],
            [110.435834191157994, -7.080023419055519],
            [110.435882352973948, -7.079743066771099],
            [110.435941984447922, -7.079571274446541],
            [110.436025547072632, -7.079499011678629],
            [110.436132845794944, -7.079453914173119],
            [110.436347333393883, -7.079454174014807],
            [110.436526167387541, -7.079454390588247],
            [110.436669270964046, -7.079517882390343],
            [110.437110207557836, -7.079455097400066],
            [110.437431993802946, -7.079410258975416],
            [110.437646656683981, -7.079265790292435],
            [110.437682365052424, -7.079220605898041],
            [110.43770658136475, -7.079084952528325],
            [110.437706833252804, -7.078876906131791],
            [110.437754809365615, -7.07851514373765],
            [110.437791019606863, -7.078289049752617],
            [110.437910105535366, -7.078090192378722],
            [110.43802255185193, -7.077999873063699],
            [110.438204629229631, -7.077891546746011],
            [110.438414970143739, -7.077810390981602],
            [110.438534174640381, -7.077747216214031],
            [110.438580657304897, -7.077683953707097],
            [110.438646370588799, -7.077629759897108],
            [110.438779429324796, -7.077575647278644],
            [110.438933699416111, -7.077530605698185],
            [110.439144050981668, -7.077440404081814],
            [110.439200132471456, -7.077395244091774],
            [110.439263429994554, -7.077232501134032],
            [110.439277448084198, -7.077105880854586],
            [110.439277666444141, -7.076924970880388],
            [110.439179740084441, -7.076707760634458],
            [110.439096135740797, -7.076581022746424],
            [110.43883715964148, -7.076399800422592],
            [110.438718272447076, -7.076200655856828],
            [110.438641176470455, -7.076073925747388],
            [110.438613272404851, -7.075983436989436],
            [110.438613327051286, -7.075938209499009],
            [110.438613392626507, -7.075883936510383],
            [110.438606678016598, -7.07582060983508],
            [110.438578806757917, -7.07570298458143],
            [110.438583083991261, -7.075675853204937],
            [110.43858566344619, -7.075648719781197],
            [110.438635084782945, -7.075495005675132],
            [110.438676898869431, -7.075314145854764],
            [110.438642106529656, -7.075069875097154],
            [110.438583991084542, -7.074925076846473],
            [110.438508527527858, -7.074852621764816],
            [110.438413746029923, -7.074843461955283],
            [110.438225837084119, -7.074861326319089],
            [110.43815595652535, -7.074852177984751],
            [110.43803453402343, -7.074644003227722],
            [110.438015151827003, -7.074526388195475],
            [110.438061776389972, -7.074345534240067],
            [110.438201701843496, -7.074228111418293],
            [110.438327218028292, -7.074092580146859],
            [110.4383506723333, -7.073884561666518],
            [110.438327556911517, -7.073812169695891],
            [110.438268658402862, -7.073613097403759],
            [110.438267037158781, -7.073549776866769],
            [110.43835132817695, -7.073341831751331],
            [110.43835686839769, -7.073206155753615],
            [110.438335450768861, -7.07313376583112],
            [110.438333927883534, -7.07298903580341],
            [110.438372053547596, -7.0725820337313],
            [110.438406445988832, -7.072220254705142],
            [110.438431739907884, -7.071894646749008],
            [110.438588099090211, -7.071523969183702],
            [110.438783321822456, -7.071071928737065],
            [110.438787065814324, -7.071017660166548],
            [110.438817745604098, -7.070918196473189],
            [110.438848392629112, -7.070845869277669],
            [110.438882707208052, -7.070782592009775],
            [110.438924966486752, -7.070701233278246],
            [110.438978239359145, -7.070637978830725],
            [110.439020498606652, -7.070556620087678],
            [110.439046671878515, -7.070479455634175],
            [110.439087272242958, -7.070359756937773],
            [110.439439061924432, -7.070502861019956],
            [110.439709407879064, -7.070638858655944],
            [110.43996216709661, -7.070811027328577],
            [110.440278821068205, -7.071028500220947],
            [110.440699006202365, -7.071273233768922],
            [110.441015118677782, -7.071446343832754],
            [110.441160345534755, -7.07154332561382],
            [110.441310042464991, -7.071641024398657],
            [110.441418607960912, -7.071717327445712],
            [110.441565209753492, -7.071839527680142],
            [110.441745312981695, -7.072004055773265],
            [110.442103440225509, -7.072369425380947],
            [110.442345057319216, -7.072632034725731],
            [110.442735317227175, -7.073057641159474],
            [110.442949509421553, -7.073302126386347],
            [110.443053498441685, -7.073419842493464],
            [110.44307297945349, -7.073456047864441],
            [110.443079433383531, -7.07350128317652],
            [110.443072871066889, -7.073546502923985],
            [110.442905778902301, -7.073874702658815],
            [110.442709425791278, -7.074278293968137],
            [110.442515128564636, -7.074704784984926],
            [110.442452432301025, -7.074858746719856],
            [110.442355622275016, -7.075146701292073],
            [110.442287171695284, -7.075397677587168],
            [110.442295391641451, -7.075493440031737],
            [110.442359879496223, -7.075583025134569],
            [110.442645231646139, -7.075681200984003],
            [110.4432845820822, -7.075862408843254],
            [110.443733112851291, -7.076070991880447],
            [110.443916284047589, -7.076181861835344],
            [110.444070446613338, -7.07641780851453],
            [110.444203886928889, -7.07656878654492],
            [110.444335681152381, -7.076645219998489],
            [110.444465791909366, -7.076678312699007],
            [110.444654891662793, -7.076721877027912],
            [110.444784994183422, -7.076761903826584],
            [110.444850949372878, -7.076751581192074],
            [110.444930845240293, -7.076692735811283],
            [110.445008935341548, -7.076692828913197],
            [110.445082898170142, -7.07674197117494],
            [110.44513508444615, -7.076877716189109],
            [110.445212400544165, -7.077058718752389],
            [110.445269745053054, -7.077140196778059],
            [110.445322061028463, -7.077167395680951],
            [110.445401835340419, -7.077185581771872],
            [110.445486746205532, -7.077167591883895],
            [110.445648960268045, -7.077104466436254],
            [110.445683220522568, -7.077086416190813],
            [110.445752840504696, -7.077077453565419],
            [110.445817911476553, -7.077086576557218],
            [110.445887738895806, -7.07714093281055],
            [110.446062241538485, -7.077213504670937],
            [110.44620451865643, -7.077258901566381],
            [110.446371664327131, -7.077331464564996],
            [110.44650374402157, -7.07738589476807],
            [110.446753221676801, -7.077467601050278],
            [110.446955476660492, -7.077522114532242],
            [110.447089282740166, -7.077585406665063],
            [110.447143506825526, -7.077608452682689],
            [110.447196433253879, -7.077630947034394],
            [110.447240249709793, -7.077667181163283],
            [110.447292239858072, -7.077730561564924],
            [110.447371343023789, -7.077835969794729],
            [110.447392331346805, -7.077897495513023],
            [110.447387388207304, -7.077967082654665],
            [110.447368818820067, -7.078056291340938],
            [110.447335256761917, -7.078200979867225],
            [110.447294600631224, -7.078337595714919],
            [110.447263722120525, -7.078418481147101],
            [110.447260422179795, -7.078468648931077],
            [110.447323487330522, -7.078569067230172],
            [110.44748232959688, -7.07873484037046],
            [110.447724257456755, -7.078979356660886],
            [110.447936719136308, -7.079236593210527],
            [110.448423142928277, -7.079966147681641],
            [110.448544538779473, -7.080370223454042],
            [110.448641569399953, -7.080519235128833],
            [110.448706209113837, -7.080658497755959],
            [110.448740083777423, -7.080783157946114],
            [110.448754474675212, -7.080943400780274],
            [110.448757392105236, -7.081215302534593],
            [110.449060321243948, -7.081244793320828],
            [110.449312959307107, -7.08133086987129],
            [110.449499133858041, -7.081449236499533],
            [110.449703116489076, -7.081578953222053],
            [110.450007430262744, -7.081807513483406],
            [110.450199974539615, -7.08201975705638],
            [110.450369863988456, -7.08220931559768],
            [110.450439454882414, -7.082272517078714],
            [110.450520195103721, -7.082305407832558],
            [110.450643318300877, -7.082300822895663],
            [110.450739190594689, -7.082263160433468],
            [110.451063419656435, -7.082091988219945],
            [110.45140393011809, -7.08185286035998],
            [110.451789859271997, -7.081566850897384],
            [110.452146681043217, -7.081233871949291],
            [110.452374412685074, -7.080985847262801],
            [110.452480851068415, -7.080874970936443],
            [110.4526819299154, -7.080719836961874],
            [110.452847287673748, -7.080631017062393],
            [110.452994035750734, -7.080595933298333],
            [110.453159815007908, -7.080558285286306],
            [110.453359002138441, -7.080477983698858],
            [110.453594039465344, -7.080345018571915],
            [110.453903331425252, -7.080799079853066],
            [110.454056641583108, -7.08120894543707],
            [110.454254898336771, -7.081542047649237],
            [110.45490798853902, -7.081958900366502],
            [110.454674626880717, -7.082400289574812],
            [110.454599336150309, -7.082638380222932],
            [110.454597564275957, -7.08264306093674],
            [110.454510221763854, -7.082873791211133],
            [110.454385168323796, -7.08316206566651],
            [110.454299578901058, -7.083305322500804],
            [110.454074111886612, -7.083267511969638],
            [110.454032969332658, -7.083386928041028],
            [110.45398841794902, -7.083499513569746],
            [110.453797123264451, -7.083458329747761],
            [110.453535580930208, -7.083411449953783],
            [110.453452095095415, -7.083396485618231],
            [110.453284674141884, -7.083392875583808],
            [110.453257150474272, -7.0835532667834],
            [110.453183489410691, -7.083722136903351],
            [110.452960896027832, -7.084145120230369],
            [110.45301552123891, -7.084182730417323],
            [110.452890446365657, -7.084488070666634],
            [110.452749957266803, -7.084825818758974],
            [110.452698450947992, -7.08504079393519],
            [110.452642981389459, -7.085180817584343],
            [110.453057600733757, -7.085426998782347],
            [110.453300657037559, -7.085626087349945],
            [110.453358701611037, -7.085696235415361],
            [110.453442890472616, -7.085692329825608],
            [110.453530882932753, -7.085862627106265],
            [110.453653282462724, -7.085750643233027],
            [110.453893681369749, -7.085859048813316],
            [110.453961729141156, -7.085945226807097],
            [110.454017613956793, -7.08614752279404],
            [110.454025334608019, -7.086399819235671],
            [110.454045227682045, -7.086527988564538],
            [110.454099119977215, -7.086720270801043],
            [110.454139040482801, -7.086862479609171],
            [110.454257393174302, -7.086994859537705],
            [110.454422474886499, -7.087165487587561],
            [110.454192560695986, -7.08771912907847],
            [110.454042988118758, -7.087961289643779],
            [110.453679968746144, -7.088354992316174],
            [110.45349130651546, -7.088541515855048],
            [110.453330623876852, -7.088709039243645],
            [110.453094013213033, -7.08897366233346],
            [110.452998139357831, -7.089144212808049],
            [110.452919333719819, -7.089328436413779],
            [110.452789564791914, -7.089266844841895],
            [110.452553929075393, -7.089157342816885],
            [110.452345648648844, -7.089030806587767],
            [110.452038359593217, -7.088835888235739],
            [110.451696942419005, -7.088606796782745],
            [110.451563454281128, -7.088801195411364],
            [110.451426581451301, -7.088968283874201],
            [110.451299918768328, -7.089169516994819],
            [110.451202321658755, -7.089352011437732],
            [110.451109775046319, -7.089597657221136],
            [110.451102398710674, -7.089640365328324],
            [110.451059088261871, -7.089891127483087],
            [110.45094583365163, -7.089833789645529],
            [110.450941671275388, -7.089843016951278],
            [110.450778721154506, -7.090204250305094],
            [110.450767514683932, -7.090232732922878],
            [110.450661163098104, -7.090503038297686],
            [110.450947826697856, -7.090598037328618],
            [110.451032441508218, -7.090742618676581],
            [110.451162004964118, -7.090834940889434],
            [110.451014649330943, -7.091026578273701],
            [110.450770071519372, -7.091190699042354],
            [110.450605759534184, -7.09147896670718],
            [110.450485484206226, -7.091364008383387],
            [110.450395175664553, -7.091363901559018],
            [110.449937776989572, -7.091685938713074],
            [110.449934884667471, -7.091817153648853],
            [110.449716032558513, -7.091751285288965],
            [110.449672769037463, -7.09131110580557],
            [110.449330480259292, -7.091488391744305],
            [110.44914996710628, -7.091400698851714],
            [110.44912818132282, -7.091310460408204],
            [110.449109239361832, -7.09113001272321],
            [110.449057250070126, -7.091124483645063],
            [110.448833185184, -7.090839911484959],
            [110.448679680984796, -7.09105295916337],
            [110.448523966795491, -7.090823142278048],
            [110.448554141128881, -7.090763036354194],
            [110.448319312408913, -7.090325363148642],
            [110.448393327856635, -7.090218836122054],
            [110.448604307261334, -7.090000389293122],
            [110.448582797946642, -7.089677785345435],
            [110.448240669454549, -7.089721118693491],
            [110.448180278598258, -7.089697300689785],
            [110.448073814228806, -7.089655311382451],
            [110.44778065972416, -7.090078278717015],
            [110.447497420799778, -7.090539263502541],
            [110.447178243751793, -7.091000205304065],
            [110.446976748022763, -7.091253240014957],
            [110.446840465382479, -7.091397806058104],
            [110.446765651167283, -7.091440623784944],
            [110.446666383028713, -7.09144282634362],
            [110.446572749479174, -7.09143743688982],
            [110.446452422273907, -7.091398815044831],
            [110.446377574991331, -7.091368124104094],
            [110.446321489280976, -7.091303453560241],
            [110.446280736970806, -7.091225200505042],
            [110.446250230618787, -7.091118058000593],
            [110.44623502719773, -7.091022834417069],
            [110.44620542360046, -7.09087234049882],
            [110.446116292016839, -7.090689473767276],
            [110.446031286270326, -7.090614568134849],
            [110.445913964733023, -7.090522622978568],
            [110.445701367251033, -7.090405062686425],
            [110.445552510861958, -7.090354732214421],
            [110.445268258814409, -7.090383294602008],
            [110.445137204294724, -7.090389938512772],
            [110.444730350191364, -7.090472757256816],
            [110.444701387328806, -7.090498224102002],
            [110.444274108224832, -7.090582718119422],
            [110.444294280413644, -7.09079185418073],
            [110.444331502207987, -7.09097550917658],
            [110.444339394430727, -7.091036903809981],
            [110.44434330550088, -7.091067328532132],
            [110.444347843126138, -7.091188606688376],
            [110.444477590860274, -7.091205196973697],
            [110.444479221464945, -7.091264702320064],
            [110.444482087523539, -7.091267148843207],
            [110.444481403689579, -7.09126718003931],
            [110.444479589685784, -7.091354276806761],
            [110.444354767700673, -7.091369497939113],
            [110.444436642033295, -7.09152671550021],
            [110.444388704611143, -7.091583016306522],
            [110.444309930216534, -7.091692222711544],
            [110.444131976218458, -7.091818388660227],
            [110.443950619234613, -7.091930887882734],
            [110.443601961356336, -7.091839955861793],
            [110.44357432423692, -7.092075602193849],
            [110.443471797079951, -7.092032783829004],
            [110.443134153145792, -7.091713826113245],
            [110.442913492598237, -7.091658438510814],
            [110.442616928304929, -7.091627076081166],
            [110.442465162105321, -7.091640674734301],
            [110.442123605218924, -7.091740175225665],
            [110.442019919768256, -7.0919226457836],
            [110.441888601886248, -7.092139535014208],
            [110.441777492564071, -7.092350574361001],
            [110.44158368587965, -7.092549988533011],
            [110.441495118179731, -7.092671856880415],
            [110.44146799162516, -7.092779695557378],
            [110.441465502684437, -7.092789590068379],
            [110.441520016369722, -7.092974720867784],
            [110.441629266809002, -7.093159917467512],
            [110.441714504020595, -7.0933336983044],
            [110.441766064726323, -7.093515921879436],
            [110.441747907965606, -7.093637341123353],
            [110.441638213131412, -7.093806733956423],
            [110.441535992082308, -7.093879968482121],
            [110.441425634434253, -7.09391581830356],
            [110.441259061753343, -7.09389537775904],
            [110.441090264901931, -7.093852445395517],
            [110.440946229797319, -7.093811791688278],
            [110.440795405511579, -7.093800365571375],
            [110.44070271146127, -7.093796562814149],
            [110.440576472241744, -7.093823547295824],
            [110.440446521581734, -7.093877663791894],
            [110.440394249898972, -7.094019724808964],
            [110.440409176497511, -7.094119321667427],
            [110.44039762591359, -7.094339132806787],
            [110.440379871883636, -7.094488335999464],
            [110.440377797626354, -7.094502263431248],
            [110.440349782395444, -7.094690369316146],
            [110.440313100558996, -7.094859268522024],
            [110.440230044414704, -7.094985047814565],
            [110.440176861723003, -7.095087674793459],
            [110.44008727324001, -7.09513063078287],
            [110.440024238083197, -7.095153743081476],
            [110.439906099203768, -7.095154014675662],
            [110.439848051299336, -7.095166366964037],
            [110.439731925441706, -7.095215916098974],
            [110.439682097837178, -7.095286249056223],
            [110.439640475500084, -7.095426984988597],
            [110.439631904635561, -7.09565885772943],
            [110.439627473957728, -7.095894876233311],
            [110.439596031751762, -7.096186762516353],
            [110.439504506377489, -7.096459942796612],
            [110.43936325327779, -7.096720640690847],
            [110.439186728025618, -7.097012351683969],
            [110.438979176044072, -7.097252265485483],
            [110.438763348443757, -7.097479746864387],
            [110.43853921009709, -7.097723781144976],
            [110.438447954834345, -7.097773359975028],
            [110.438365090429386, -7.097740133576292],
            [110.438344464708806, -7.097657293258108],
            [110.43835288068631, -7.097553784238233],
            [110.438340615717806, -7.0974129832819],
            [110.438344896504518, -7.097301187721213],
            [110.438311880319034, -7.09718106549706],
            [110.438303830878766, -7.096982298885143],
            [110.438279145329204, -7.096829060593398],
            [110.438183870552322, -7.096775115317957],
            [110.438084425466357, -7.096741868817359],
            [110.437931022215636, -7.096766527724059],
            [110.437744411965724, -7.096828413272863],
            [110.437611594018989, -7.096969038497558],
            [110.437570001041252, -7.097084929603464],
            [110.437524177341174, -7.097271208626615],
            [110.437472042730221, -7.097534084192483],
            [110.43745095954138, -7.097828053133708],
            [110.437429851163529, -7.098142725874613],
            [110.437396543447591, -7.098262767756843],
            [110.437292746622447, -7.098399287251101],
            [110.437197305618255, -7.098481986875806],
            [110.437035601224736, -7.098514916904599],
            [110.436878037012178, -7.09855199266536],
            [110.436778501028812, -7.098593279558307],
            [110.436707941383929, -7.098667727731114],
            [110.436703690383467, -7.098754678674149],
            [110.436711870054523, -7.098845785470558],
            [110.436686913008657, -7.09891614821439],
            [110.436620579132708, -7.098924349230168],
            [110.436558425757951, -7.098903569945056],
            [110.436483887062934, -7.098841367948148],
            [110.436421779057781, -7.098783321802001],
            [110.436314149074704, -7.098663108865658],
            [110.436235530744042, -7.09854707191483],
            [110.436136105625891, -7.098497261939202],
            [110.435937194940578, -7.098447331050806],
            [110.435744469361751, -7.098424322579687],
            [110.435582729601393, -7.098486237415145],
            [110.435441635206701, -7.098614429558452],
            [110.435259022741974, -7.098796401089727],
            [110.435072300255158, -7.098949382174482],
            [110.435029883562095, -7.098965724462529],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 159,
        id: 164,
        area: 7982728.3475639997,
        perimeter: 17587.401973,
        kode_pos: 50279,
        kode_kec: 3374070,
        kode_kel: 3374052001,
        kec: "Tembalang",
        kel: "Rowosari",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.459558585228862, -7.078029147166889],
            [110.459638489637797, -7.077271733430556],
            [110.459778951854403, -7.076662025927569],
            [110.4600149143278, -7.076155298847406],
            [110.460250415324666, -7.076045354350645],
            [110.460853635162195, -7.075979922723486],
            [110.461706947097539, -7.075907431674686],
            [110.462207123018743, -7.075900661847],
            [110.462618932139648, -7.075981963593547],
            [110.463181438133731, -7.076140591107551],
            [110.463902225803878, -7.076178160851583],
            [110.464814364402159, -7.076120427520505],
            [110.465954621976721, -7.075974779963301],
            [110.466109144671677, -7.075923522263526],
            [110.466182808501955, -7.07582808473547],
            [110.466285986764873, -7.075651854722704],
            [110.466455574487924, -7.075292004630718],
            [110.466647244979086, -7.074917484053153],
            [110.466919841264712, -7.074528360320509],
            [110.46720778303343, -7.07409938005738],
            [110.467367540300785, -7.073755831735676],
            [110.467419433469004, -7.073412160007536],
            [110.467398877709456, -7.073077450979296],
            [110.467368818359816, -7.072887459956887],
            [110.467330459826485, -7.072778869435006],
            [110.467283277639908, -7.072715496593085],
            [110.467219086613284, -7.072679240956609],
            [110.467103365314372, -7.072670063025068],
            [110.466370399009193, -7.072750633876653],
            [110.465952473770926, -7.072741109344776],
            [110.465690985237345, -7.072767946122774],
            [110.465417557950559, -7.072792723361515],
            [110.465185880507647, -7.072786671222947],
            [110.465022428167288, -7.072694814329039],
            [110.464861729283157, -7.072606927146298],
            [110.464670743252157, -7.072473625408587],
            [110.464369998683736, -7.072377470270461],
            [110.463954294591957, -7.072159898875261],
            [110.463559261476917, -7.071951590043014],
            [110.463345234664885, -7.071708323754394],
            [110.463255071546385, -7.071444495340566],
            [110.463131258767106, -7.071091576255017],
            [110.463097738407939, -7.07035407125708],
            [110.463185046956056, -7.069983855985142],
            [110.463341900139042, -7.069579003662025],
            [110.463447905330497, -7.069364240952173],
            [110.463766465157093, -7.069165605503471],
            [110.46389693067357, -7.069147664504077],
            [110.46447190507962, -7.069100050139204],
            [110.465277080709086, -7.069031540446924],
            [110.465846641147451, -7.06905040223097],
            [110.466192218927091, -7.069038375762422],
            [110.46654552775226, -7.069044566508839],
            [110.466701973006266, -7.068992669861045],
            [110.466777409050337, -7.068871246208779],
            [110.466829796231238, -7.068645644768266],
            [110.466911163496135, -7.068402717896151],
            [110.467256795452897, -7.067921321847478],
            [110.467604787514702, -7.067469441608236],
            [110.467914822874391, -7.066974675762725],
            [110.468094924955224, -7.066494627462285],
            [110.468286584225353, -7.066037737000349],
            [110.46866962407006, -7.065366975463673],
            [110.468907492841097, -7.065020074948209],
            [110.469145110432194, -7.064893049299151],
            [110.469423087229018, -7.064928082831481],
            [110.469602481216214, -7.065067155543045],
            [110.469747070492545, -7.06525247815538],
            [110.469765794079606, -7.065445699214238],
            [110.469776582076335, -7.065662804946498],
            [110.469721934500555, -7.065943155183588],
            [110.469587589834902, -7.066386234829499],
            [110.469554874253589, -7.066539972132806],
            [110.46959445177076, -7.066820429533263],
            [110.469811213351207, -7.067055860684136],
            [110.470046177290087, -7.067209902395616],
            [110.470285051321127, -7.067409176247609],
            [110.470426993993712, -7.06749979314082],
            [110.470764863054086, -7.06773536147483],
            [110.47104237449814, -7.067798995416739],
            [110.471429647488861, -7.067806100063374],
            [110.47184089592092, -7.067794993849855],
            [110.472153810882673, -7.067668052040282],
            [110.472562557403279, -7.06769217131732],
            [110.473857691399687, -7.067919774177683],
            [110.474394163002259, -7.06793847057598],
            [110.475269866694191, -7.067729432294637],
            [110.475930375411707, -7.067539230869763],
            [110.476787930334879, -7.067245098107008],
            [110.477292156762644, -7.066962140032977],
            [110.477535643427856, -7.066765682211617],
            [110.477611180318902, -7.066551677483068],
            [110.477727378281969, -7.066233566694533],
            [110.477663912762679, -7.06601362000761],
            [110.477507872326726, -7.065706776586143],
            [110.477421173893489, -7.065544665834162],
            [110.477305625900783, -7.065284157337926],
            [110.477068380653392, -7.065081374349186],
            [110.476715256282333, -7.064913178421722],
            [110.476252129525676, -7.064704355314065],
            [110.476038105206513, -7.064455308341962],
            [110.475747395426168, -7.064068490934276],
            [110.475656578584704, -7.063806067304108],
            [110.475668427035004, -7.063558032084721],
            [110.475784501579398, -7.063349859417184],
            [110.475987488469244, -7.063112853370778],
            [110.476260050633002, -7.062812277156884],
            [110.476422506156766, -7.06256365297113],
            [110.476463355895277, -7.062291747447809],
            [110.476446253250529, -7.062048707873525],
            [110.47644645496726, -7.061869335913842],
            [110.47651616688195, -7.061684255758104],
            [110.476742281504769, -7.061481992437378],
            [110.477142369460566, -7.061088979388339],
            [110.477299090359551, -7.060788272569399],
            [110.477438720863191, -7.0602329537021],
            [110.47763585511953, -7.060048016003041],
            [110.477699689931754, -7.059938149638381],
            [110.477757836578547, -7.05973569767599],
            [110.477706014003743, -7.05946368821547],
            [110.47767156731264, -7.059191698199066],
            [110.47776438116594, -7.059064505642118],
            [110.477955645008223, -7.058948995604358],
            [110.478256910264179, -7.058874111884533],
            [110.478604627552301, -7.058695128220909],
            [110.479079689562369, -7.058585720863483],
            [110.47947349613456, -7.058626663669639],
            [110.47972249136717, -7.05867901714264],
            [110.479878712224036, -7.058823846356353],
            [110.480029044338295, -7.059055462015126],
            [110.480301188296167, -7.059125199602373],
            [110.480695053568269, -7.059114065646146],
            [110.480932639459297, -7.059010178289087],
            [110.481147076810785, -7.058888906453958],
            [110.48126883652489, -7.058773317714669],
            [110.481361720227497, -7.058582476188151],
            [110.481344589235832, -7.058362581368352],
            [110.481257942144097, -7.058154181700027],
            [110.481078643541167, -7.057928320406673],
            [110.4809456418606, -7.057737227768123],
            [110.480792477621236, -7.057271927538885],
            [110.48058217599214, -7.05645807225724],
            [110.480559240563352, -7.056249743372256],
            [110.480617327367824, -7.056099366675078],
            [110.480756442803056, -7.056001155934736],
            [110.481173621259913, -7.055856964588626],
            [110.481335897695431, -7.055764565603154],
            [110.481475218925155, -7.055481196277696],
            [110.481642020366067, -7.054938180032834],
            [110.481648010030227, -7.054918681045592],
            [110.481652339960505, -7.054938191488564],
            [110.481958008433878, -7.056315532148602],
            [110.482281247833541, -7.056511369193551],
            [110.482660437944119, -7.056667374471686],
            [110.483111441272968, -7.056883299336224],
            [110.483297158341486, -7.056857574114024],
            [110.483472929747151, -7.056797928274574],
            [110.483700697956237, -7.05666254208309],
            [110.484080339332309, -7.056411632258904],
            [110.484851494912746, -7.056005569288778],
            [110.485676626846953, -7.055537729340569],
            [110.485920380198166, -7.05539039149981],
            [110.486118164434274, -7.055284891201858],
            [110.486405905202091, -7.055081750463096],
            [110.486537819820896, -7.054958225395739],
            [110.486585827112123, -7.05487849099204],
            [110.486609870303909, -7.054802719594952],
            [110.486597947592458, -7.054750844811742],
            [110.486582031602836, -7.054698965637752],
            [110.486536203376403, -7.054613144016995],
            [110.486524394953491, -7.054457546008707],
            [110.486548732586172, -7.054114487839747],
            [110.486576927211246, -7.053895104054089],
            [110.486633017584268, -7.053727612604638],
            [110.486737145767776, -7.053452461222571],
            [110.486801183027453, -7.053320882714664],
            [110.486791281111536, -7.053247068674948],
            [110.486755495434494, -7.053107401753053],
            [110.486771525601284, -7.053055557688146],
            [110.486867407910594, -7.053015769436437],
            [110.487051133275074, -7.05298405636359],
            [110.487206932089393, -7.052928376395734],
            [110.487298821075726, -7.052888583666547],
            [110.487414647704796, -7.052868763990528],
            [110.487578362811476, -7.052876922348292],
            [110.487774002135083, -7.052905062463332],
            [110.487989651636056, -7.052893330826232],
            [110.488113482303277, -7.052857562305486],
            [110.488209381995119, -7.052801816342328],
            [110.488285367890072, -7.052698176210103],
            [110.488385339600228, -7.052570626168835],
            [110.488493074543015, -7.052646542019992],
            [110.488680626684271, -7.052766428204034],
            [110.488792311240701, -7.052882241928301],
            [110.488951845083122, -7.053061937767333],
            [110.489071624276463, -7.053232176049794],
            [110.489074679828661, -7.053229710109725],
            [110.489220832282143, -7.053413297334576],
            [110.489468796482029, -7.053702267639608],
            [110.489639552310948, -7.053877300260185],
            [110.489741164235184, -7.054007529238846],
            [110.48981017918932, -7.054170252074604],
            [110.489903633063321, -7.054316736854779],
            [110.489982865836836, -7.054440842065437],
            [110.49018203777689, -7.054685030711579],
            [110.490373051767179, -7.054945475125625],
            [110.490430003229363, -7.054974000581966],
            [110.490596783029503, -7.055063638653098],
            [110.490751356780237, -7.055149197170485],
            [110.490977010079405, -7.055371050376658],
            [110.491237244677478, -7.05560717282243],
            [110.491477084199659, -7.055883934791574],
            [110.491615275578525, -7.056062997498985],
            [110.49167618211888, -7.056197247976353],
            [110.491765500238884, -7.056404720738027],
            [110.49186300317983, -7.056571540528578],
            [110.492013480872572, -7.056681491336322],
            [110.492139577317872, -7.056754819894866],
            [110.492165864133852, -7.05678464760596],
            [110.491965441184433, -7.057089266600965],
            [110.491494723653872, -7.057337046513162],
            [110.491343573903436, -7.057567439090567],
            [110.491262082700445, -7.057735621605911],
            [110.491262573703054, -7.057739082186347],
            [110.491266592689513, -7.057767407952126],
            [110.491271067971226, -7.0578309756911],
            [110.491289147599971, -7.057921799483602],
            [110.491289083143627, -7.057980822070045],
            [110.49128904347792, -7.058017143661624],
            [110.491270800219851, -7.058076146434018],
            [110.491243477472693, -7.05812605889985],
            [110.491207065317141, -7.058175961455194],
            [110.491157028968146, -7.058216768745376],
            [110.49112969132004, -7.058280301799126],
            [110.491106888446197, -7.058352920203419],
            [110.491074872141454, -7.058539033662099],
            [110.491065619025008, -7.05868885030657],
            [110.491063135830629, -7.058881806270557],
            [110.49105369912759, -7.059199610258704],
            [110.491062500790349, -7.059462951686107],
            [110.49114395838086, -7.059780854793577],
            [110.491363913489835, -7.060205603675966],
            [110.492249547043002, -7.060776953860318],
            [110.492299799880172, -7.060952538387635],
            [110.492628416639789, -7.061138565105739],
            [110.492628325854398, -7.061221878125687],
            [110.49292482249048, -7.061365022973718],
            [110.493064126498538, -7.06144491692485],
            [110.49305439463356, -7.061629385407428],
            [110.49332717425213, -7.061671338312745],
            [110.493603502166991, -7.061737098664774],
            [110.4937333038784, -7.06179079798904],
            [110.493847635848908, -7.061827817868338],
            [110.493946467013458, -7.061876722781994],
            [110.494009549151201, -7.061932730027251],
            [110.494234732198493, -7.061921072345778],
            [110.494256112325672, -7.061980604908465],
            [110.494335847934437, -7.062060433921074],
            [110.494408484426543, -7.062095028095493],
            [110.494507269189896, -7.062186779604652],
            [110.494597688509458, -7.062302325785824],
            [110.494654754467547, -7.062413075068674],
            [110.495075872435081, -7.062978062354675],
            [110.495270932265285, -7.063199048964981],
            [110.495445545755757, -7.063317327109647],
            [110.495538000339735, -7.063368770256721],
            [110.495792322858335, -7.063443492819045],
            [110.495812524749596, -7.063772110756973],
            [110.496048917788158, -7.063792903513919],
            [110.496357224451529, -7.063849714019826],
            [110.496413675224218, -7.063926789695634],
            [110.496459830419795, -7.064019257192705],
            [110.496562541981788, -7.064091248485461],
            [110.496644712365409, -7.064147814643136],
            [110.496958108884257, -7.064250839099184],
            [110.496983612002282, -7.064430567625953],
            [110.497620071211045, -7.064608165474146],
            [110.497769082208208, -7.064651755628342],
            [110.499059401373273, -7.065029210117965],
            [110.499265946807967, -7.065254172352375],
            [110.499485582603285, -7.065470132766252],
            [110.499520185770038, -7.065504156857606],
            [110.499937715136994, -7.066053970272688],
            [110.500001283588418, -7.066108520972144],
            [110.500064905716883, -7.066113129409345],
            [110.500212599079958, -7.066124638284877],
            [110.50048063912709, -7.066220269965323],
            [110.500685096103823, -7.066274971509878],
            [110.500807703092491, -7.066370447230121],
            [110.500916670891186, -7.066470448530863],
            [110.501030071534998, -7.066674879400611],
            [110.501057135786738, -7.066865597218222],
            [110.501029613805258, -7.067101658715915],
            [110.50097947006816, -7.067242351568706],
            [110.500879367605194, -7.067351209448935],
            [110.500752054766295, -7.067405555636573],
            [110.500570253045467, -7.067414441306811],
            [110.500438497746543, -7.067373438231144],
            [110.500370379402796, -7.067323422912193],
            [110.500315929604142, -7.067241640765326],
            [110.500261528595445, -7.067114456564169],
            [110.500189041111398, -7.066900988974322],
            [110.500143715123144, -7.066787435122713],
            [110.50005287781255, -7.066732855193552],
            [110.499934737613728, -7.066710027431704],
            [110.499821103171172, -7.066723526154316],
            [110.499707439430296, -7.066764266076303],
            [110.499648293652214, -7.066823225325951],
            [110.499634556729049, -7.06691855498664],
            [110.499625310877619, -7.067063831773723],
            [110.499654571268607, -7.067324925238928],
            [110.499636221157516, -7.067483812879984],
            [110.49959523005306, -7.067565492649771],
            [110.499481590510698, -7.067583531478023],
            [110.499381624450692, -7.067565263287863],
            [110.499186251821783, -7.067515111115583],
            [110.49890907775449, -7.0674603307264],
            [110.498622848421007, -7.067373758974246],
            [110.498382047588436, -7.067305396776319],
            [110.498168510712503, -7.067241604019092],
            [110.49798214468386, -7.067268644601419],
            [110.497873015672297, -7.067318469355362],
            [110.497832038988875, -7.067386528340432],
            [110.497813727466777, -7.067509094240383],
            [110.497790797609014, -7.067699758287764],
            [110.497795131637488, -7.067894991915624],
            [110.49778129625183, -7.068081125548273],
            [110.497749198285021, -7.068344423038324],
            [110.497755929701597, -7.068423883935488],
            [110.497833117898537, -7.068492070231229],
            [110.49794669399202, -7.068533054465001],
            [110.498101153790614, -7.068592243535022],
            [110.498237400106689, -7.068683194407225],
            [110.498328188572657, -7.068783176729834],
            [110.498496175241982, -7.06894226482724],
            [110.498641467320169, -7.069074087204556],
            [110.498686832266287, -7.069151319553812],
            [110.498668540441443, -7.069255724675584],
            [110.498632103600869, -7.06932832881412],
            [110.498522949670942, -7.069400854702965],
            [110.498302474607684, -7.069448289583516],
            [110.49801160628661, -7.06944797634717],
            [110.497729827613171, -7.069447672726346],
            [110.497475366913577, -7.069401996316645],
            [110.497207345452765, -7.069288202025911],
            [110.49702114060473, -7.069165415486167],
            [110.496921306942795, -7.069024561293215],
            [110.496866990211231, -7.068820193318875],
            [110.496867088439487, -7.068729389274655],
            [110.496903589334693, -7.068597762658939],
            [110.49700826231323, -7.068466209615823],
            [110.497190206748328, -7.068325659496256],
            [110.497340312911234, -7.068207776001535],
            [110.497390433317022, -7.068089784644367],
            [110.497408686116216, -7.068021701206484],
            [110.497372440527926, -7.067917237337409],
            [110.497218069388921, -7.06777632439895],
            [110.497009101844853, -7.067689834997512],
            [110.496872757860331, -7.067689687848585],
            [110.4967772630459, -7.067739527046514],
            [110.496740811320137, -7.067825751636343],
            [110.496731633301053, -7.067907465463521],
            [110.496713326374248, -7.068025491093334],
            [110.496685856137148, -7.068211609938752],
            [110.49663116617009, -7.068352297308219],
            [110.49656740129565, -7.068479354243691],
            [110.496449093771801, -7.068610892447721],
            [110.496339949546879, -7.068674337411594],
            [110.496276317308528, -7.068678808848017],
            [110.49620820419176, -7.068624252739819],
            [110.49613104574216, -7.068528824980178],
            [110.496040238147614, -7.068447003072131],
            [110.495962971521806, -7.068451459724776],
            [110.495935673162705, -7.068478671443532],
            [110.495912870401597, -7.068551290091921],
            [110.495924165993074, -7.068612595102177],
            [110.495983100895344, -7.068748865048447],
            [110.496078340202914, -7.068935116521899],
            [110.496128156047988, -7.069098617835087],
            [110.496205206382768, -7.069293930035984],
            [110.49624362337731, -7.069491470568951],
            [110.496188805216619, -7.069750203099249],
            [110.49608403330727, -7.069972559943051],
            [110.495997465189504, -7.070172235411402],
            [110.495756353251792, -7.070389904462234],
            [110.495531120892807, -7.070632561698166],
            [110.495458206595771, -7.070814090994325],
            [110.495439909036307, -7.070923036126926],
            [110.495453454844849, -7.071004774506548],
            [110.495462391777494, -7.071145530574133],
            [110.495435034096644, -7.071227224655997],
            [110.495344073373033, -7.071286148813805],
            [110.495212194332908, -7.071358649236347],
            [110.495025841342908, -7.071372067901555],
            [110.49485315738012, -7.071353719873231],
            [110.494744120840821, -7.071317279990031],
            [110.49464878790647, -7.071217292069671],
            [110.494585318185401, -7.071071936643936],
            [110.494567242506335, -7.070976572719105],
            [110.494512837816742, -7.07085392814742],
            [110.49444923489763, -7.070831158110849],
            [110.494376468271255, -7.070876481202775],
            [110.494267303491014, -7.070958086417162],
            [110.494158133733819, -7.071044231802841],
            [110.494130751137504, -7.071148626791794],
            [110.494048795955095, -7.071284743951576],
            [110.493948695979768, -7.071389059948623],
            [110.493850944989262, -7.071423005296031],
            [110.493696361311109, -7.071477319782694],
            [110.493614490009548, -7.071536253461506],
            [110.493568893294196, -7.071672410038911],
            [110.493555095573328, -7.071822221794001],
            [110.493527608914192, -7.072021960915261],
            [110.493458481341577, -7.072147563903962],
            [110.49345019328409, -7.072162623081639],
            [110.493372856772865, -7.072230642045756],
            [110.493300089824743, -7.07227596494382],
            [110.493209039551544, -7.072416612229244],
            [110.49312723732757, -7.072411983019725],
            [110.493063564966434, -7.072452775568754],
            [110.492956657153073, -7.0725480034971],
            [110.492825961925178, -7.072576237488487],
            [110.49261006943334, -7.072587352881595],
            [110.492394170741065, -7.072604143421785],
            [110.492149934965553, -7.072558475167605],
            [110.492104424521244, -7.072615178091187],
            [110.492047477535166, -7.072739971589997],
            [110.491956425558953, -7.072881753643795],
            [110.491859779322624, -7.072944076014549],
            [110.491751770941747, -7.07300638596519],
            [110.491677855102012, -7.073063057842889],
            [110.491546861684853, -7.073363703314959],
            [110.4913958818792, -7.073757968592042],
            [110.491333154095258, -7.073973559715886],
            [110.491281744908491, -7.074228889962667],
            [110.491213124662139, -7.074637433228737],
            [110.491073425455681, -7.075105488944533],
            [110.490914019329921, -7.075411778341172],
            [110.490675009264777, -7.075780408368582],
            [110.490561306519922, -7.075854062253407],
            [110.490396473891735, -7.075927660146966],
            [110.490010022449141, -7.076052092540652],
            [110.489816728107598, -7.076176736303736],
            [110.489699801229676, -7.076599414389563],
            [110.489546137112427, -7.076848956980971],
            [110.489420903312606, -7.077075829686809],
            [110.489290000753471, -7.077291345627128],
            [110.489227439903416, -7.077353704744869],
            [110.489074031191848, -7.077370562141648],
            [110.488852499101398, -7.077341942683799],
            [110.488591118055865, -7.077387057598132],
            [110.488466127376952, -7.077392595512168],
            [110.488290019207284, -7.077386726702598],
            [110.488085486734704, -7.077397852318144],
            [110.487909297246389, -7.077465761520497],
            [110.487823999238117, -7.077539445919219],
            [110.487770564119757, -7.077610659692765],
            [110.487763054747731, -7.077789611185532],
            [110.487737301536171, -7.077935672439482],
            [110.48766293194943, -7.07806139160599],
            [110.487521217838918, -7.078278745111973],
            [110.487455171725983, -7.078494154525099],
            [110.487385276032398, -7.078884867180695],
            [110.487322885775825, -7.079100280585794],
            [110.48721655458499, -7.07937773362997],
            [110.487179845095767, -7.079512826033826],
            [110.487000239795435, -7.079928983342896],
            [110.486337960917311, -7.080414000501233],
            [110.486042761923883, -7.080796943740824],
            [110.486023481585889, -7.080895415301167],
            [110.485672387036971, -7.081376547927889],
            [110.485535045731382, -7.081740272429884],
            [110.485293549191596, -7.0821777512718],
            [110.485102008535577, -7.082021592305949],
            [110.484973365492877, -7.081950316184477],
            [110.484721462920703, -7.081892583079751],
            [110.484502361787591, -7.081892340342977],
            [110.484145532791302, -7.081871078753569],
            [110.483612950624192, -7.082126931112107],
            [110.482908503647351, -7.082467444329557],
            [110.482693041158143, -7.082570961514386],
            [110.481733797502827, -7.083031820427506],
            [110.48104255890506, -7.083374230937877],
            [110.480484148683544, -7.083597118987031],
            [110.480166327652242, -7.083723976054052],
            [110.480100075973255, -7.083774225703161],
            [110.47985687974662, -7.084222285048551],
            [110.479552135373169, -7.084090368630805],
            [110.479344829430715, -7.08407186221915],
            [110.47892632226899, -7.084245609154515],
            [110.478670734959266, -7.084657922779281],
            [110.478675356353023, -7.08497089873222],
            [110.478567998580431, -7.08536074986469],
            [110.477860928842162, -7.086162254371556],
            [110.477673728249428, -7.086848529073532],
            [110.477845786045762, -7.08739576796459],
            [110.477663217470933, -7.087420204546998],
            [110.477416474046805, -7.087479067283658],
            [110.477169797227205, -7.087478789901301],
            [110.476957538470927, -7.087582046214972],
            [110.476893241308488, -7.087724895662917],
            [110.476695760761615, -7.087847881825468],
            [110.476628950428776, -7.087834742770437],
            [110.476576200044647, -7.087772324667374],
            [110.476523428020712, -7.087729093828617],
            [110.476470612694854, -7.087724237523084],
            [110.476369783932967, -7.087714530303585],
            [110.476321727172376, -7.087748053928286],
            [110.476249660976009, -7.087781550494988],
            [110.476201587949788, -7.087829464557286],
            [110.476148734696693, -7.087858185933918],
            [110.476091128312916, -7.087843730538754],
            [110.476067183900327, -7.087786141672791],
            [110.476062436197154, -7.087738168090318],
            [110.476024096976161, -7.087670969350938],
            [110.475956908528673, -7.087637315847478],
            [110.475889682152001, -7.0876372400532],
            [110.475803269923517, -7.087617955321837],
            [110.475673657018504, -7.087584231378909],
            [110.475515292498756, -7.087497709911061],
            [110.47532812217888, -7.087406359058567],
            [110.475227309807536, -7.08738226114866],
            [110.475150501423599, -7.087362987142862],
            [110.47508807154793, -7.087367713473544],
            [110.475025625385555, -7.087386830244539],
            [110.474934313636567, -7.087453882626089],
            [110.474761277531741, -7.087602388628805],
            [110.474621930570422, -7.087683777128603],
            [110.474424988127296, -7.087741116354223],
            [110.474424138259067, -7.087742420535628],
            [110.474204068844031, -7.087769647441181],
            [110.474122442253346, -7.087764758278966],
            [110.473863189492931, -7.087721293509802],
            [110.473767130059301, -7.087740372056342],
            [110.473642417171291, -7.087620310319089],
            [110.473512848022935, -7.087548211264593],
            [110.473324445107821, -7.0876890568227],
            [110.473219487672893, -7.087614718400257],
            [110.472986392855134, -7.087573505502219],
            [110.472763630415159, -7.08745808472563],
            [110.472574089587084, -7.087416921026611],
            [110.472423063077414, -7.087301581485105],
            [110.472210481910125, -7.087245035740737],
            [110.471982587123875, -7.087137286663475],
            [110.471782395487892, -7.086997012208901],
            [110.471715267575703, -7.086910593169414],
            [110.471698578466871, -7.086807442573582],
            [110.471566533813188, -7.08680129647341],
            [110.471320793194366, -7.086489223792386],
            [110.470858927939773, -7.086212881223452],
            [110.470301279294631, -7.085714576232156],
            [110.470313400310545, -7.085612657632536],
            [110.470421695343717, -7.085390928095744],
            [110.47033695196977, -7.085228865889829],
            [110.470181199987607, -7.085133319317425],
            [110.470091271464369, -7.085050782588268],
            [110.469968900427858, -7.084910754688524],
            [110.469931405271765, -7.084898221910476],
            [110.469746378772172, -7.084880524907144],
            [110.469630132405385, -7.084851665249373],
            [110.469557642754737, -7.08482660249024],
            [110.469480186128123, -7.08477155796344],
            [110.469427787199962, -7.084671577880283],
            [110.4693977168413, -7.084726499735408],
            [110.469135065278948, -7.084801140190017],
            [110.468990143236098, -7.084701054430221],
            [110.468990309025443, -7.084556170193305],
            [110.468885379941653, -7.084471118116381],
            [110.468518016884317, -7.084270857834335],
            [110.468579418050311, -7.084152272659035],
            [110.46861960822082, -7.083994944125039],
            [110.468629736556124, -7.083885043377124],
            [110.468634855061524, -7.083782630926203],
            [110.468648744325975, -7.083663991447513],
            [110.468774012687319, -7.083456799933896],
            [110.468519101065567, -7.083324114325136],
            [110.468279173275121, -7.083208931766787],
            [110.468049239492913, -7.083101254544191],
            [110.467871775825031, -7.083031107352105],
            [110.467694289297228, -7.082980944125979],
            [110.467501801978727, -7.082928265654903],
            [110.467374336463649, -7.082870665553625],
            [110.467306908696514, -7.082793150125006],
            [110.467073185734378, -7.082719191201984],
            [110.46696827176693, -7.082621648725649],
            [110.466880807632791, -7.082571588355913],
            [110.466678332492123, -7.082506408082006],
            [110.466653355054149, -7.082481399377551],
            [110.466612467421783, -7.082429206606353],
            [110.466560066491752, -7.082331724259189],
            [110.466470222429834, -7.082176744812211],
            [110.466377857714093, -7.082039248505993],
            [110.466211052623621, -7.081865076045627],
            [110.466203024606159, -7.081856693445828],
            [110.465908291715721, -7.081561590403151],
            [110.465748347633436, -7.081478972543866],
            [110.465540906446776, -7.08138381003992],
            [110.465370942811219, -7.081316168586857],
            [110.465171006481611, -7.081218516546188],
            [110.46505104933172, -7.081155928478804],
            [110.465026083568517, -7.081120927686177],
            [110.46501862780498, -7.081080951014648],
            [110.465013664012716, -7.081048471226925],
            [110.465016219306762, -7.081001012048164],
            [110.46502879716725, -7.08093607835036],
            [110.465050105553416, -7.080889889736308],
            [110.46507771293183, -7.080802491260525],
            [110.465107838168734, -7.080700107639221],
            [110.465143019269021, -7.080550267707514],
            [110.465153156917637, -7.080432873066127],
            [110.465155784122416, -7.080322963809584],
            [110.465150926784816, -7.080198057911645],
            [110.465143494037633, -7.080138097216884],
            [110.465114868054357, -7.080024405025353],
            [110.465047455455462, -7.079934399304102],
            [110.464957543248318, -7.079839371692629],
            [110.464907588839708, -7.079789354127478],
            [110.464816367372862, -7.079745534114267],
            [110.464683910533253, -7.079680433597985],
            [110.464573953350197, -7.079620354947216],
            [110.464451507615038, -7.079547771869772],
            [110.464376538682515, -7.079505219483307],
            [110.464291581786043, -7.07945016555778],
            [110.464259123021549, -7.07940766208865],
            [110.464264176085038, -7.079362703797278],
            [110.464279202714224, -7.079342737047564],
            [110.464331798787256, -7.079270355416102],
            [110.464359374486023, -7.079210435010346],
            [110.464379454154397, -7.079145509962694],
            [110.464401501298866, -7.079074315913907],
            [110.464401604749767, -7.078984612524916],
            [110.464369036144632, -7.078911181262711],
            [110.464306557359549, -7.078818687555187],
            [110.464257659027268, -7.078747955778535],
            [110.464214256222874, -7.078631019426738],
            [110.464138206494141, -7.078508608908906],
            [110.464075699576867, -7.078440579736283],
            [110.464010430797856, -7.078407885120206],
            [110.463917954161232, -7.078372440870392],
            [110.463869002592546, -7.078347919893639],
            [110.463771099471572, -7.078298877925775],
            [110.463573618774319, -7.078252554597031],
            [110.463470973951871, -7.078384139737355],
            [110.463470781917408, -7.078545261526076],
            [110.463458132118618, -7.078641278819441],
            [110.463407904304518, -7.078703850396197],
            [110.463320127420829, -7.078707924484452],
            [110.463203126964117, -7.078682737759352],
            [110.463052708860474, -7.078640811258103],
            [110.462918999613365, -7.078607254589415],
            [110.462797795444104, -7.07860293940736],
            [110.462697470060874, -7.078615349491889],
            [110.462534406879229, -7.078665264793099],
            [110.462459120546313, -7.078711106125871],
            [110.462408844268168, -7.078815430510335],
            [110.462387834994303, -7.07891143810032],
            [110.46232078217308, -7.079065846668633],
            [110.462224423575037, -7.079261974339063],
            [110.461973742757309, -7.07917817863218],
            [110.461972596934828, -7.079178034207252],
            [110.461873446268115, -7.079165536774498],
            [110.461737567324107, -7.079200869665532],
            [110.461474163792147, -7.07927572028904],
            [110.461005973043399, -7.079337807722875],
            [110.460663229179332, -7.079349936517373],
            [110.460512786903848, -7.079328885653562],
            [110.46041662155919, -7.079358001239515],
            [110.460182494576912, -7.079416183867398],
            [110.460036217375503, -7.079407663570608],
            [110.459927590804057, -7.079369959849568],
            [110.459839925510238, -7.079278001581607],
            [110.459790833586823, -7.078363554671061],
            [110.459789041063061, -7.078361301613048],
            [110.459614535023562, -7.078141961443328],
            [110.459558585228862, -7.078029147166889],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 160,
        id: 165,
        area: 2652556.7496420001,
        perimeter: 9397.878623000001,
        kode_pos: 50199,
        kode_kec: 3374080,
        kode_kel: 3374041003,
        kec: "Pedurungan",
        kel: "Plamongansari",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.484248957432811, -7.039917297149533],
            [110.4842048094944, -7.03990332991855],
            [110.484191294060736, -7.039899054029371],
            [110.483962725784323, -7.039853574059601],
            [110.483830378280004, -7.039790108950683],
            [110.483746151164979, -7.03970860571757],
            [110.483626390317056, -7.039265239640221],
            [110.483362576185741, -7.038342298240077],
            [110.483182516519108, -7.03767272574953],
            [110.48297872023997, -7.036975990220008],
            [110.482666948608127, -7.035926357292303],
            [110.482377813440479, -7.034876749234963],
            [110.482113953561054, -7.03399903521717],
            [110.481766109589728, -7.032822723687234],
            [110.481477048112737, -7.031709796168228],
            [110.481189262027129, -7.030723508196741],
            [110.480888582384082, -7.02962865872611],
            [110.480636631018868, -7.028732866106472],
            [110.480428041669299, -7.028027078856449],
            [110.480248817888224, -7.027375597642737],
            [110.480098869048803, -7.026859832703431],
            [110.479933439502162, -7.026271685832598],
            [110.479749679907798, -7.025629245037356],
            [110.479623690940883, -7.025194916885848],
            [110.479502835229511, -7.024724412045966],
            [110.479449602146062, -7.024507258785128],
            [110.479434777672338, -7.024353467291503],
            [110.479439839037923, -7.024127333147985],
            [110.479508569164707, -7.023638947538315],
            [110.479572246538496, -7.023114373927137],
            [110.479596920640788, -7.02280685120466],
            [110.479640743636253, -7.022580760040923],
            [110.479674786034408, -7.022499387482656],
            [110.479850283112555, -7.022173940824441],
            [110.480015544147406, -7.021893710693124],
            [110.480094663043346, -7.021730977726246],
            [110.480235591606501, -7.021450720523331],
            [110.480328250923094, -7.021324184875912],
            [110.480697615523127, -7.020944678960895],
            [110.480917168373949, -7.020691645299986],
            [110.481257703047604, -7.020284970183815],
            [110.481446931562388, -7.020077130672251],
            [110.481636675715606, -7.019914519616112],
            [110.481794954341268, -7.01980614729738],
            [110.482054692259439, -7.019544111790306],
            [110.482238604425291, -7.019281992460243],
            [110.482390798158889, -7.019046974846606],
            [110.482726843395824, -7.018604111116814],
            [110.483159899580428, -7.017935213980039],
            [110.483255173590564, -7.017745361319764],
            [110.483337695727329, -7.017573585793806],
            [110.483451523277623, -7.017492300686019],
            [110.483603576214492, -7.017383920805699],
            [110.483686018414033, -7.017284509906763],
            [110.483768540304823, -7.017112734284225],
            [110.483838270519399, -7.016995218172777],
            [110.483914468224285, -7.016913891556372],
            [110.483971376894246, -7.016877771720076],
            [110.484040987499654, -7.016868802636508],
            [110.484186716081922, -7.016850871588247],
            [110.484300413898751, -7.016887178907488],
            [110.484395518015063, -7.016851100976225],
            [110.484534808862819, -7.016769843544494],
            [110.484845068559522, -7.016616408910384],
            [110.485003235999258, -7.016607536851614],
            [110.485199529334949, -7.016662025760067],
            [110.485313530003538, -7.016680241965551],
            [110.485408321283856, -7.016671300282225],
            [110.485465532615095, -7.016617089382217],
            [110.48556071104538, -7.016255474603272],
            [110.488429000563812, -7.017597253562596],
            [110.48873926629679, -7.017697093280467],
            [110.48880402769494, -7.017724300634875],
            [110.489262772826891, -7.017878575346279],
            [110.489746113531538, -7.018050967583757],
            [110.490165268766134, -7.018187107197496],
            [110.490506647861423, -7.018296025203891],
            [110.491339574059936, -7.018577342548092],
            [110.492199379799402, -7.018858687567125],
            [110.492807183107885, -7.019058348286958],
            [110.49346843165749, -7.019285202781714],
            [110.494871263936233, -7.019757086818123],
            [110.495938405037592, -7.020092922120049],
            [110.49692379014509, -7.020419621828538],
            [110.497878054194473, -7.020746286253565],
            [110.49826263564573, -7.020881424386089],
            [110.498998776549868, -7.021140095971986],
            [110.498954063995541, -7.021213765633621],
            [110.498869965215746, -7.021358537786763],
            [110.498808751987212, -7.021510958685329],
            [110.498785621556507, -7.021732039092355],
            [110.498785544769035, -7.021803776551961],
            [110.498785327755314, -7.022006514095925],
            [110.498792258867269, -7.0221990504492],
            [110.49878538360457, -7.022228444587633],
            [110.498783683461653, -7.022277816438375],
            [110.498775395919438, -7.022325535461151],
            [110.498778665834251, -7.022348579999875],
            [110.498779058826145, -7.022353151935161],
            [110.498781919893005, -7.022386436621783],
            [110.498786833575124, -7.022412774494692],
            [110.498786791291749, -7.022452273383124],
            [110.498788383966087, -7.022503294540803],
            [110.498794929083914, -7.022544446256962],
            [110.498799057845886, -7.022566229190257],
            [110.498799846291391, -7.022567492555719],
            [110.498794782852457, -7.022681046578603],
            [110.498797968200833, -7.02278308889306],
            [110.498814360074661, -7.022858812697143],
            [110.498822522539001, -7.02292794455191],
            [110.498824097596994, -7.022995423578704],
            [110.498797742679685, -7.022993749627675],
            [110.498731858030865, -7.022987096063167],
            [110.498654437050817, -7.022985367524893],
            [110.498629483569744, -7.022993648123541],
            [110.498617493010883, -7.022997627087041],
            [110.498454860783553, -7.023222147737316],
            [110.498370698936512, -7.023361949772622],
            [110.498298082206375, -7.023488597829266],
            [110.498306263999766, -7.023539626031536],
            [110.498317736855924, -7.023593949331601],
            [110.498309359280043, -7.023725603465771],
            [110.49830757969751, -7.023849035713715],
            [110.498342028272262, -7.023984027237145],
            [110.498364998701589, -7.024069632820241],
            [110.498348462244664, -7.024128863523507],
            [110.498320405290741, -7.024178207169305],
            [110.498262709339613, -7.024215998581749],
            [110.498181975926826, -7.024230724293685],
            [110.498107815814691, -7.024260269124035],
            [110.498003970815745, -7.024321052148554],
            [110.497928702530956, -7.024436952010391],
            [110.497865354230157, -7.024548022557822],
            [110.49783878904843, -7.024644904894858],
            [110.497828951234212, -7.024699396118469],
            [110.497832140021146, -7.024718638453906],
            [110.497837134753397, -7.024748778537562],
            [110.49789961556715, -7.024857467487168],
            [110.497980218464946, -7.024964530043966],
            [110.498178285024849, -7.025197050912236],
            [110.498355328165175, -7.025407648635662],
            [110.49845897760207, -7.025529547876453],
            [110.498539608955255, -7.0256102777636],
            [110.498595583706916, -7.025641607611687],
            [110.498656510950426, -7.025663068017431],
            [110.498710891301883, -7.025645022488049],
            [110.498809821059268, -7.025559547239934],
            [110.49890212985693, -7.025503689088922],
            [110.499002662806305, -7.025459360227162],
            [110.49909491519557, -7.025456167215272],
            [110.499167370029483, -7.025480931447518],
            [110.49924145632427, -7.025520509510701],
            [110.499284249184299, -7.025555116777847],
            [110.499328617163343, -7.025657203064794],
            [110.49933835483678, -7.025793813934244],
            [110.49932997394184, -7.025928759665732],
            [110.499311721545197, -7.02605217433223],
            [110.499267099948995, -7.026187081354442],
            [110.499210673371678, -7.026233392956302],
            [110.49913121962075, -7.026278059919395],
            [110.499017174820466, -7.026302348157827],
            [110.498833947957593, -7.026285878924129],
            [110.498752523486175, -7.026269518476591],
            [110.498646653376497, -7.026265336920623],
            [110.498548899122454, -7.026287608290185],
            [110.498432820432384, -7.026309860034204],
            [110.498414352096219, -7.026444095788071],
            [110.498369447823691, -7.026547790636508],
            [110.498344928009018, -7.026629131374566],
            [110.498342798184396, -7.026716598594301],
            [110.498348832184149, -7.026785766980608],
            [110.498371167668807, -7.026842747764755],
            [110.498381236700766, -7.026946501433042],
            [110.498336701927869, -7.027069662892394],
            [110.498118178124727, -7.027159885129499],
            [110.498004325154497, -7.027171891298464],
            [110.497841384299733, -7.02722460530292],
            [110.497566355251607, -7.027249750125688],
            [110.497446377239257, -7.027236387020344],
            [110.497393236685795, -7.02742550823083],
            [110.497362464256142, -7.027641097680018],
            [110.497284790418803, -7.027923764562128],
            [110.497180813917609, -7.028051805986393],
            [110.49703813481274, -7.028196079242488],
            [110.496909738637342, -7.028311889346601],
            [110.496805882288328, -7.028328051243497],
            [110.496799393388812, -7.028681990511981],
            [110.496797278556514, -7.028755218496148],
            [110.496750392330995, -7.028808056667573],
            [110.496687248271797, -7.028832398941271],
            [110.496609889013783, -7.028822144984463],
            [110.496536647852082, -7.028769177792276],
            [110.496446086823241, -7.028677754786098],
            [110.496375703647388, -7.028614359871362],
            [110.496144660962543, -7.028505564191621],
            [110.496106201015635, -7.028487431618654],
            [110.495926467791747, -7.028550557622433],
            [110.495836484281696, -7.02882182917316],
            [110.495688313200773, -7.029264904690487],
            [110.495488803241415, -7.029825517797612],
            [110.495463036387264, -7.029843581275069],
            [110.495373364625991, -7.02982539349261],
            [110.49497033457834, -7.029680229527473],
            [110.494700802475478, -7.029580437252591],
            [110.494617638368524, -7.02956225634824],
            [110.494540357306022, -7.029598355431208],
            [110.494495574953973, -7.029670671997237],
            [110.494399033909005, -7.029987164155958],
            [110.494138827560633, -7.030718545685825],
            [110.49208890584238, -7.029930392122136],
            [110.491812327527853, -7.0298654271856],
            [110.491615546893939, -7.029843841849433],
            [110.491239098804371, -7.029802831733573],
            [110.490719342482194, -7.029802267120262],
            [110.490347085372406, -7.029720451950145],
            [110.490318507910999, -7.029714059946276],
            [110.489981335974036, -7.029638643576305],
            [110.489476424873104, -7.029438455904674],
            [110.488846517139805, -7.029263358318447],
            [110.488740128011827, -7.02927141777104],
            [110.488431990624079, -7.029186601213502],
            [110.487947903173179, -7.029084644337042],
            [110.487669353401557, -7.029075294292862],
            [110.487300229611293, -7.028972801119623],
            [110.4865913101346, -7.028715859069004],
            [110.486454620315683, -7.028993676591205],
            [110.486091857895076, -7.02897420273723],
            [110.485480860377777, -7.028968081976065],
            [110.484927156507325, -7.028951122718492],
            [110.484806840833031, -7.029220782172437],
            [110.484678344922955, -7.029487707418021],
            [110.484598463642271, -7.02962370829872],
            [110.484495306906965, -7.029743671940173],
            [110.484484194864791, -7.029926246007341],
            [110.484440431515338, -7.030035204597652],
            [110.484331974118817, -7.030102831655983],
            [110.484167592404788, -7.030097583187476],
            [110.483990324974116, -7.03006741115127],
            [110.483780304120501, -7.030056279143682],
            [110.483526549808829, -7.030126853882896],
            [110.483362836634186, -7.030173001256469],
            [110.483147390485229, -7.030134611301411],
            [110.482918185361456, -7.030205212780047],
            [110.482803444145318, -7.030365871040209],
            [110.4827760224657, -7.03049664882246],
            [110.482767670573736, -7.030649248995701],
            [110.482822128187451, -7.030736514479314],
            [110.482999299351405, -7.030853892233011],
            [110.483143774294049, -7.030938531841858],
            [110.483285500507449, -7.031042244553265],
            [110.48333441854308, -7.031205808577614],
            [110.483342348736173, -7.03143473142592],
            [110.483331278314253, -7.031579153113478],
            [110.483426654778171, -7.031663738498972],
            [110.483587535431226, -7.031712968898939],
            [110.483733441545112, -7.031748470660355],
            [110.483963869095632, -7.031795138650741],
            [110.48409750282859, -7.031817087185385],
            [110.48432119008902, -7.031803707637569],
            [110.484544889361246, -7.031779427321873],
            [110.484768555586029, -7.031785123714066],
            [110.484943096021041, -7.031815292505576],
            [110.485057623846529, -7.031848120436875],
            [110.485139326133748, -7.031965392490037],
            [110.485130992954353, -7.032101641758728],
            [110.485005251041059, -7.032344043528984],
            [110.484893240643132, -7.032501980134365],
            [110.484767681689206, -7.032578146753237],
            [110.484252014336221, -7.032694761471606],
            [110.484014706117847, -7.032691774942255],
            [110.483815613001809, -7.032664303897458],
            [110.483700982766393, -7.032724131250635],
            [110.483608117035175, -7.03283576075796],
            [110.483539731463665, -7.033010096096167],
            [110.483476840416188, -7.033149010298507],
            [110.483449463870386, -7.033238910640749],
            [110.483473889878169, -7.033350669456],
            [110.483498358050184, -7.033424275968841],
            [110.483536356436872, -7.033596003439142],
            [110.483552460816185, -7.033833110788341],
            [110.483527646346744, -7.034072898177326],
            [110.483576543632168, -7.034255538334978],
            [110.483639157853119, -7.034367339255356],
            [110.483748299955238, -7.03433748274558],
            [110.483876539126115, -7.034304922089847],
            [110.48399929575649, -7.034296881866968],
            [110.484173831031995, -7.03433250133576],
            [110.484411055918414, -7.034411792455908],
            [110.484612754345989, -7.034550998059085],
            [110.484738106579755, -7.034662867899237],
            [110.484849805216939, -7.034788348529452],
            [110.484874207589726, -7.03492190861308],
            [110.484903981333176, -7.035131779311644],
            [110.484871032302394, -7.035327955165338],
            [110.484780912029024, -7.035423236888395],
            [110.484764422454845, -7.035534950631273],
            [110.484832429497715, -7.035703985881476],
            [110.484975573578708, -7.035829401068107],
            [110.485165044815517, -7.03585696116453],
            [110.485355968200849, -7.035873522081773],
            [110.485546731808213, -7.035920484962121],
            [110.485661363471138, -7.035974688911269],
            [110.48567212743707, -7.036108233984042],
            [110.485595624835497, -7.03622260699988],
            [110.485309155481758, -7.036274070341359],
            [110.485118012729401, -7.036456446420232],
            [110.485016888206417, -7.036636196276512],
            [110.485002994200272, -7.036867820268269],
            [110.485046382739966, -7.037099507275452],
            [110.485073416872439, -7.037320275617175],
            [110.485075979420699, -7.037470162671421],
            [110.48502948161881, -7.037584568568899],
            [110.48486017517817, -7.037753342671537],
            [110.484726272202778, -7.037973933866129],
            [110.484614182056802, -7.03820272453442],
            [110.484469244521605, -7.038535035351223],
            [110.484384359411081, -7.038829259927017],
            [110.484370443889617, -7.039079959999667],
            [110.484348230753014, -7.039434207285468],
            [110.484307277590517, -7.039663996493601],
            [110.484248957432811, -7.039917297149533],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 161,
        id: 166,
        area: 2603536.512168,
        perimeter: 6703.811673,
        kode_pos: 50261,
        kode_kec: 3374030,
        kode_kel: 3374031011,
        kec: "Banyumanik",
        kel: "Ngesrep",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.433174779537552, -7.050911974740911],
            [110.432886517818687, -7.050784758505103],
            [110.432390143039541, -7.050621336521865],
            [110.431866932731353, -7.050421699364302],
            [110.43131011838426, -7.050167747695712],
            [110.430809871994029, -7.049931955337529],
            [110.43015915371619, -7.049650751465757],
            [110.429649560241614, -7.04942399205938],
            [110.42932753864639, -7.049215552244685],
            [110.428885137286471, -7.048880328034557],
            [110.428695241172207, -7.048681094785615],
            [110.428286688730353, -7.048201183354518],
            [110.427864361539008, -7.047648890716685],
            [110.427560911029076, -7.047295744474321],
            [110.427379414066664, -7.047104756962498],
            [110.427170284243431, -7.046914706657112],
            [110.426899144997577, -7.046705213511893],
            [110.426535092525853, -7.046489437246272],
            [110.425903420192626, -7.046146229666439],
            [110.42532799289333, -7.045845724283526],
            [110.424974818524973, -7.045664380002185],
            [110.424284019990154, -7.045256436190368],
            [110.424029379291142, -7.04507525872067],
            [110.423816008355999, -7.044881043691604],
            [110.423539868345856, -7.044631425976175],
            [110.423161569611395, -7.044404821973617],
            [110.42268932636911, -7.044207365093131],
            [110.422326323749516, -7.043994719441544],
            [110.422088343048387, -7.043811975338354],
            [110.421785024723576, -7.043533959012353],
            [110.422002636513298, -7.043034414588085],
            [110.42209903958809, -7.042679453089475],
            [110.422136644388729, -7.042448697140457],
            [110.422162646606466, -7.041541498228899],
            [110.422102686765669, -7.041168589485361],
            [110.422017638925027, -7.040964313051069],
            [110.421914749887421, -7.040816827464292],
            [110.421522463071568, -7.040452384954706],
            [110.421352108617512, -7.040253329157712],
            [110.421240686082541, -7.040051564211263],
            [110.421155410417114, -7.039802133487479],
            [110.421070334170111, -7.039155849141816],
            [110.420977150313334, -7.038813013445895],
            [110.420888577770626, -7.038585652259411],
            [110.420748500694927, -7.038333371675725],
            [110.420285582939172, -7.037606658714053],
            [110.419880970550082, -7.037010593460034],
            [110.419459727288881, -7.03651256890211],
            [110.419217477453884, -7.036240049959662],
            [110.419048845125815, -7.036087156097937],
            [110.418906822657164, -7.035971578667613],
            [110.418739934477856, -7.035845317912881],
            [110.418523257414591, -7.035743850907551],
            [110.418224848366052, -7.035631629842043],
            [110.417985133712705, -7.03547864730764],
            [110.417802318683613, -7.035299104381358],
            [110.417649802485485, -7.035048582984839],
            [110.417560140948311, -7.034818396288867],
            [110.417530758714975, -7.034610619009592],
            [110.417528496647279, -7.03432416490072],
            [110.417647822253315, -7.033760488080285],
            [110.41778795606136, -7.033217927064548],
            [110.417899644832445, -7.032759684822441],
            [110.41801872957825, -7.032118815499209],
            [110.418117081481071, -7.031313680207749],
            [110.418267510014289, -7.031328212721852],
            [110.418834585931492, -7.031382995225531],
            [110.419834374293771, -7.03146774550572],
            [110.420713140958142, -7.031493869167472],
            [110.421464356559312, -7.031476706484569],
            [110.422902096167533, -7.031360888264985],
            [110.424861580215719, -7.031185045380283],
            [110.425757791064299, -7.031111123146048],
            [110.426228681696571, -7.031084585673709],
            [110.426847928071354, -7.031070752801521],
            [110.428022236832334, -7.031093365662281],
            [110.428159631101792, -7.031095889711698],
            [110.42861565494357, -7.031168891581766],
            [110.429169803900692, -7.031263268916458],
            [110.429715768640648, -7.031462369682088],
            [110.430156482105644, -7.031743050709141],
            [110.430685498487648, -7.032193553672116],
            [110.431084803045621, -7.032592041538256],
            [110.431261668180184, -7.032800303208962],
            [110.431624004989501, -7.033352519581127],
            [110.432028745072245, -7.033958155626081],
            [110.432240077485986, -7.034371605925788],
            [110.432403559515237, -7.034732089159625],
            [110.432574555306545, -7.035135412546489],
            [110.432800750643963, -7.03577059454686],
            [110.432916327563547, -7.036123461183983],
            [110.433041808617787, -7.036627508352003],
            [110.433201817687504, -7.037424915090418],
            [110.433390007110447, -7.038329694722917],
            [110.433514249282155, -7.03894515078346],
            [110.433527322900474, -7.039005305971234],
            [110.433701204286848, -7.039805365771067],
            [110.433861657104174, -7.040586597456751],
            [110.434051906680423, -7.041514517970938],
            [110.434529234006362, -7.043474787293017],
            [110.434769893179492, -7.044418997321897],
            [110.434895383820219, -7.044954099159733],
            [110.434955271797705, -7.045480741980814],
            [110.434952237366304, -7.045904010243618],
            [110.434934245382436, -7.046183650304307],
            [110.43489386065032, -7.046490570612786],
            [110.43475740046982, -7.047024091118592],
            [110.434476385423025, -7.047765483540499],
            [110.434135819008603, -7.048615349780623],
            [110.433714448657838, -7.049674166746299],
            [110.433395725230071, -7.050464895426463],
            [110.433174779537552, -7.050911974740911],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 162,
        id: 167,
        area: 1195680.879371,
        perimeter: 4616.017302,
        kode_pos: 50254,
        kode_kec: 3374060,
        kode_kel: 3374051001,
        kec: "Candisari",
        kel: "Jatingaleh",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.41959815481745, -7.024726244130598],
            [110.419910249156885, -7.024355975431138],
            [110.420356652365072, -7.023949479801217],
            [110.421016835128327, -7.023407565080419],
            [110.421413950743499, -7.023046234957197],
            [110.421911390505215, -7.02254934551092],
            [110.422565445484267, -7.021926011156253],
            [110.42350904216589, -7.020986438805541],
            [110.424114406861662, -7.020381133096699],
            [110.424502757873029, -7.020010743645339],
            [110.425377428864508, -7.019206764998701],
            [110.426391047574526, -7.018375817753301],
            [110.427193183411134, -7.017653180576539],
            [110.4272618462998, -7.01772560407885],
            [110.427358213946619, -7.017816176823507],
            [110.42752274502979, -7.017924923728644],
            [110.427698898318383, -7.018015593719912],
            [110.427846464950264, -7.018115274319301],
            [110.427897575422833, -7.018196746276227],
            [110.427942972132669, -7.018323438849561],
            [110.427965418580811, -7.018477239998242],
            [110.427908119282009, -7.018829945311807],
            [110.427770863989096, -7.019553419285843],
            [110.42765797769647, -7.020014602929468],
            [110.427639943162049, -7.020186445732759],
            [110.427669995467355, -7.020367392708328],
            [110.427852703502964, -7.020883209955483],
            [110.428041647399084, -7.021389989239139],
            [110.42818750900382, -7.021960034566548],
            [110.428247952336704, -7.022276701303872],
            [110.428260136163217, -7.022493808537943],
            [110.428277713494268, -7.022928014724831],
            [110.428307993840136, -7.023154189529069],
            [110.428356050405142, -7.023651751469261],
            [110.428337107565682, -7.024104004155204],
            [110.428293324301393, -7.024972320239348],
            [110.428255581782579, -7.02575923400947],
            [110.428239633702049, -7.026075807571496],
            [110.428239434764123, -7.026238626589885],
            [110.428235058148061, -7.026347167425448],
            [110.428241950182468, -7.026727087434213],
            [110.428247681763878, -7.026898959195857],
            [110.428308032646456, -7.027523173281702],
            [110.428350468208166, -7.02775840840399],
            [110.428447876924707, -7.028156529817543],
            [110.428581607462846, -7.028699423729995],
            [110.428800397803514, -7.029559014299224],
            [110.428952530838046, -7.030092885057399],
            [110.428970689671146, -7.030282862983671],
            [110.428939978062672, -7.030409462749338],
            [110.428872540104862, -7.030490790174716],
            [110.428677193346559, -7.030590052660405],
            [110.428342609231123, -7.030716281728543],
            [110.428257934196665, -7.030779496994871],
            [110.428181170266399, -7.03085176741215],
            [110.428167492399012, -7.030912904860056],
            [110.428159631101792, -7.031095889711698],
            [110.428022236832334, -7.031093365662281],
            [110.426847928071354, -7.031070752801521],
            [110.426228681696571, -7.031084585673709],
            [110.425757791064299, -7.031111123146048],
            [110.424861580215719, -7.031185045380283],
            [110.422902096167533, -7.031360888264985],
            [110.421464356559312, -7.031476706484569],
            [110.420713140958142, -7.031493869167472],
            [110.419834374293771, -7.03146774550572],
            [110.418834585931492, -7.031382995225531],
            [110.418267510014289, -7.031328212721852],
            [110.418117081481071, -7.031313680207749],
            [110.418128477456222, -7.03118016505513],
            [110.418176366809803, -7.030619092525941],
            [110.418183727595505, -7.030559048372226],
            [110.418187393834501, -7.030524258197006],
            [110.418325739368839, -7.029211445544296],
            [110.418334137054487, -7.029137777924204],
            [110.418405448453285, -7.028512206532834],
            [110.418474201247719, -7.027909078026775],
            [110.418482142418952, -7.027862272551682],
            [110.418566263095926, -7.027366462305094],
            [110.41859560748766, -7.0272036797199],
            [110.41865502136875, -7.026977615927628],
            [110.41872776957176, -7.026643690782854],
            [110.418879311037855, -7.026127617047957],
            [110.419040979026718, -7.025714443129195],
            [110.419183194935599, -7.025414188197377],
            [110.419393530389272, -7.025085674998166],
            [110.41959815481745, -7.024726244130598],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 163,
        id: 168,
        area: 530454.16962399997,
        perimeter: 3594.172822,
        kode_pos: 50253,
        kode_kec: 3374060,
        kode_kel: 3374051005,
        kec: "Candisari",
        kel: "Kaliwiru",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.425765641022593, -7.016004916176239],
            [110.426564725581727, -7.016096560130644],
            [110.427029496039509, -7.016160446206149],
            [110.427830926985735, -7.016242833298119],
            [110.428604870334695, -7.016361367663799],
            [110.428811395541103, -7.016370776722887],
            [110.427881436686931, -7.017047945919033],
            [110.427193183411134, -7.017653180576539],
            [110.426391047574526, -7.018375817753301],
            [110.425377428864508, -7.019206764998701],
            [110.424502757873029, -7.020010743645339],
            [110.424114406861662, -7.020381133096699],
            [110.42350904216589, -7.020986438805541],
            [110.422565445484267, -7.021926011156253],
            [110.421911390505215, -7.02254934551092],
            [110.421413950743499, -7.023046234957197],
            [110.421016835128327, -7.023407565080419],
            [110.420356652365072, -7.023949479801217],
            [110.419910249156885, -7.024355975431138],
            [110.41959815481745, -7.024726244130598],
            [110.419747530238766, -7.024382910618696],
            [110.419959837826099, -7.024066580694696],
            [110.420087448024361, -7.023831555446306],
            [110.420172730554327, -7.023506022790584],
            [110.420224876504861, -7.023207585653699],
            [110.420229571419895, -7.023071908908818],
            [110.420143361647064, -7.022547163146148],
            [110.419936825750838, -7.021859449535477],
            [110.419760530163757, -7.021198909775295],
            [110.419745596674872, -7.021146079669408],
            [110.419530274288789, -7.020320763015408],
            [110.419117715763349, -7.018747074382504],
            [110.418682714518866, -7.017059834685928],
            [110.418555807513272, -7.016647530585304],
            [110.418308145591752, -7.015687916233557],
            [110.418289854898688, -7.015616212688811],
            [110.418397569445247, -7.015610468084772],
            [110.419619562193972, -7.015545295338729],
            [110.421566827701497, -7.015638153479031],
            [110.422581887335568, -7.015702721513681],
            [110.423503875928645, -7.015758127591323],
            [110.424337026127574, -7.015813422955619],
            [110.425765641022593, -7.016004916176239],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 164,
        id: 169,
        area: 816196.335173,
        perimeter: 3723.859866,
        kode_pos: 50252,
        kode_kec: 3374060,
        kode_kel: 3374051006,
        kec: "Candisari",
        kel: "Wonotingal",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.425765641022593, -7.016004916176239],
            [110.424337026127574, -7.015813422955619],
            [110.423503875928645, -7.015758127591323],
            [110.422581887335568, -7.015702721513681],
            [110.421566827701497, -7.015638153479031],
            [110.419619562193972, -7.015545295338729],
            [110.418397569445247, -7.015610468084772],
            [110.418289854898688, -7.015616212688811],
            [110.418175359822854, -7.015208824268307],
            [110.418123917658306, -7.015020949091197],
            [110.417870616420174, -7.014095849701524],
            [110.417768126184512, -7.013697720513826],
            [110.417694020434766, -7.013453400055545],
            [110.417610227914253, -7.013263340595144],
            [110.417491658283438, -7.013055146987324],
            [110.417352412830184, -7.012865018713635],
            [110.417178028723654, -7.012720074318214],
            [110.41701546003776, -7.012629417544379],
            [110.416698460129624, -7.012502386958201],
            [110.416527967994611, -7.012424384537032],
            [110.416520036647682, -7.012420755838162],
            [110.416429036589946, -7.012339233276469],
            [110.416274547702372, -7.01212194928821],
            [110.416084523267841, -7.011814166077664],
            [110.415898448726978, -7.01151543321864],
            [110.415773706237729, -7.011264873967312],
            [110.415713057151763, -7.011078939306146],
            [110.41567957983176, -7.010801798445968],
            [110.415705048679541, -7.010511142126413],
            [110.4157953973057, -7.010142986977799],
            [110.415982039585586, -7.009646467906859],
            [110.416125404320127, -7.009164972846182],
            [110.416158080842024, -7.008874436159704],
            [110.416158367851878, -7.00864381962076],
            [110.416131174368815, -7.008349529591602],
            [110.416288486880049, -7.008196218738471],
            [110.416448755168759, -7.008087871721816],
            [110.41689414954466, -7.007798968391389],
            [110.416990125368159, -7.007735937951706],
            [110.417200938447209, -7.007597490023246],
            [110.417292827789069, -7.007537143192524],
            [110.417307964134281, -7.007478991079509],
            [110.417318854802218, -7.007437575630034],
            [110.417347561160142, -7.007442406979801],
            [110.417373006649242, -7.007446787536967],
            [110.417430971677504, -7.00747399589689],
            [110.417510993292439, -7.007510277089022],
            [110.417598393313099, -7.007528476400715],
            [110.417756501058605, -7.007564854300034],
            [110.417854640866082, -7.007592112374659],
            [110.418039774416968, -7.007737069721789],
            [110.418347776823737, -7.008044998183022],
            [110.418597293414905, -7.008289535579213],
            [110.418804416893266, -7.008497838410997],
            [110.418934686760934, -7.008624636587125],
            [110.419065295627163, -7.00870620761671],
            [110.419234098941558, -7.008787825815474],
            [110.419372391573859, -7.008833224233304],
            [110.419664299453942, -7.008887857844217],
            [110.419983096154226, -7.008933478958618],
            [110.420397848080867, -7.008943036182433],
            [110.421110799500482, -7.008952960467286],
            [110.421921292155076, -7.009017276736622],
            [110.422335988641947, -7.009072059749969],
            [110.423172499534033, -7.009145451386293],
            [110.423894449622608, -7.009200610093592],
            [110.424689371670794, -7.0092739480788],
            [110.425060759649355, -7.009346766739193],
            [110.425279391955911, -7.009401307281117],
            [110.425425302234814, -7.009464804327651],
            [110.425536651730084, -7.009564441148857],
            [110.425682484572846, -7.00969125664514],
            [110.425862701168398, -7.009926660319803],
            [110.426023690269503, -7.010152994932763],
            [110.42613861844346, -7.010334045659091],
            [110.426228432962247, -7.010460792590552],
            [110.426327933293038, -7.010533278229646],
            [110.426461439717258, -7.010560577774805],
            [110.426618156900005, -7.010578860092033],
            [110.426705580088822, -7.010578966774075],
            [110.426811054235543, -7.010533867870754],
            [110.42684474501587, -7.01060627311755],
            [110.426889031279444, -7.010714873360551],
            [110.426927376176664, -7.010823466353452],
            [110.427180884349539, -7.011511234918843],
            [110.427187020273607, -7.011583606548168],
            [110.427183852867543, -7.01162883027806],
            [110.427163155245466, -7.011664987113171],
            [110.426943818629127, -7.011954176175086],
            [110.426878536096282, -7.012125961368303],
            [110.426872539413566, -7.012171181640372],
            [110.426887174064845, -7.01223451812025],
            [110.426937291312555, -7.012433580656157],
            [110.426951804369452, -7.012596417681016],
            [110.426939733606332, -7.012750176754022],
            [110.426880403406415, -7.01291292367774],
            [110.426702195979857, -7.013346891010797],
            [110.426660767301229, -7.013446341123974],
            [110.426485554679488, -7.013744629241359],
            [110.426345762798348, -7.013997732960608],
            [110.42626577867415, -7.014160454538166],
            [110.426235949750918, -7.014259918769342],
            [110.42621817587262, -7.014449852885818],
            [110.426203025813464, -7.014576471594857],
            [110.426098943526313, -7.015010529144953],
            [110.426053975441747, -7.015227566548623],
            [110.425985546619799, -7.015426484230767],
            [110.425789696082134, -7.015941839089082],
            [110.425765641022593, -7.016004916176239],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 165,
        id: 170,
        area: 846622.90109199996,
        perimeter: 4460.32004,
        kode_pos: 50231,
        kode_kec: 3374040,
        kode_kel: 3374032008,
        kec: "Gajah Mungkur",
        kel: "Lempongsari",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.419356439565718, -6.997475916787626],
            [110.419314072152247, -6.997688024677773],
            [110.41933096097614, -6.997938575325713],
            [110.419407342947864, -6.998186702826551],
            [110.419475152204186, -6.998267656141523],
            [110.419657637027512, -6.998485513410374],
            [110.419937321126923, -6.998811496645519],
            [110.420113897689149, -6.999010715526662],
            [110.420231560938561, -6.999264134847243],
            [110.420243452596935, -6.999305805772464],
            [110.42030209385824, -6.999511297251536],
            [110.42032452131977, -6.9995898878146],
            [110.420366340952484, -6.999861304700548],
            [110.420362190369701, -7.000015073296206],
            [110.420343101027726, -7.00012359592626],
            [110.420250851774398, -7.000367711094928],
            [110.420101114503609, -7.000656982940441],
            [110.41998434060875, -7.00082870371262],
            [110.419837056304218, -7.000964204779955],
            [110.419622992354149, -7.00110866892684],
            [110.41947326223935, -7.001162757252182],
            [110.41932613426647, -7.001171621187884],
            [110.419220356903963, -7.001144354086612],
            [110.419125069855127, -7.001099008895261],
            [110.41899618286601, -7.000999349134137],
            [110.418864727297219, -7.000917777188091],
            [110.418759244137291, -7.000881464843651],
            [110.418661083239726, -7.000872298052357],
            [110.418552703751729, -7.000890255139351],
            [110.418372124489039, -7.000953350491971],
            [110.418086549445462, -7.001043452439291],
            [110.417947298347229, -7.001088507719746],
            [110.41760687094721, -7.001151404972113],
            [110.417222297226502, -7.001223292792593],
            [110.416868821803732, -7.001313309849682],
            [110.416661059707707, -7.001394461829414],
            [110.416490665061033, -7.001457569050729],
            [110.416427433595146, -7.001505694443792],
            [110.416372010452491, -7.001547876915461],
            [110.41624028539448, -7.001683396052734],
            [110.416165404111766, -7.001837076709224],
            [110.416131568269677, -7.001972717287168],
            [110.416121135809618, -7.002171705444108],
            [110.416151760107496, -7.002343608047258],
            [110.41620841283779, -7.002515542954781],
            [110.416312573283108, -7.002705627824644],
            [110.416497762594133, -7.003031495783903],
            [110.416637752692012, -7.003303034593875],
            [110.416753355787378, -7.003619770655029],
            [110.416758826729819, -7.003655521539973],
            [110.416774123178101, -7.003755478988269],
            [110.416867627110335, -7.004325461768802],
            [110.416982024312219, -7.005157790088257],
            [110.416985495592101, -7.005181780784151],
            [110.417005587287392, -7.005320638394833],
            [110.417075822792995, -7.005718727697459],
            [110.417190589126776, -7.006026417148994],
            [110.417298089530505, -7.006261733534313],
            [110.417363423721639, -7.006506043148535],
            [110.417373509895882, -7.006576597168932],
            [110.417386703939599, -7.006668891092613],
            [110.417377731264423, -7.00683169906239],
            [110.417347234171302, -7.007012571361088],
            [110.41731256063801, -7.007139165448875],
            [110.417303960698433, -7.007229609835642],
            [110.417318809457996, -7.007347219803726],
            [110.417318854802218, -7.007437575630034],
            [110.417307964134281, -7.007478991079509],
            [110.417292827789069, -7.007537143192524],
            [110.417200938447209, -7.007597490023246],
            [110.416990125368159, -7.007735937951706],
            [110.41689414954466, -7.007798968391389],
            [110.416448755168759, -7.008087871721816],
            [110.416288486880049, -7.008196218738471],
            [110.416131174368815, -7.008349529591602],
            [110.416006904884924, -7.007937943735964],
            [110.415868763204884, -7.007656419462771],
            [110.415688942039068, -7.007480927118698],
            [110.415194289842418, -7.00694657933925],
            [110.414679724051908, -7.006439390856253],
            [110.414330747023726, -7.006095227207533],
            [110.414198238376613, -7.005950334110135],
            [110.413696564744001, -7.005542661266204],
            [110.413608421340939, -7.005469939935086],
            [110.413630610255709, -7.005343578023917],
            [110.413742328484389, -7.004918578840846],
            [110.413819881100068, -7.004439264016844],
            [110.413683050184815, -7.004357683928303],
            [110.413492159286577, -7.004294127381246],
            [110.413138895879328, -7.004212277154053],
            [110.412956436123622, -7.004193958416772],
            [110.412796881462185, -7.004184713710499],
            [110.412656304977489, -7.004157401657357],
            [110.412452875355982, -7.004166193031212],
            [110.412023111055518, -7.004174701376986],
            [110.411832435429318, -7.00416541743289],
            [110.411595678145972, -7.004128939264476],
            [110.411173711564956, -7.004010819644635],
            [110.410854998984036, -7.003901874523495],
            [110.410622825418798, -7.003820174146961],
            [110.410449781804303, -7.003738547791374],
            [110.410197792785127, -7.003665867870875],
            [110.409678209036258, -7.003556670031568],
            [110.409719776618005, -7.00334867583978],
            [110.409810820624585, -7.003167880211215],
            [110.409905734453474, -7.003059453370162],
            [110.410079933329357, -7.002896852986217],
            [110.410205153291017, -7.002761327597915],
            [110.410242883559164, -7.002679965450771],
            [110.410258297429507, -7.002571438833158],
            [110.410243178953067, -7.00244478294214],
            [110.410190432503512, -7.002318079870218],
            [110.410105682363309, -7.002218473146272],
            [110.410016054479584, -7.002173133262623],
            [110.409984956337169, -7.002155003266548],
            [110.409995628841841, -7.001992197740592],
            [110.410023639995032, -7.001539958108078],
            [110.410044611081602, -7.001286710534854],
            [110.410082840031563, -7.001033484596244],
            [110.410142355805789, -7.000726012361777],
            [110.410218449945731, -7.000509015851913],
            [110.410270099667997, -7.000382443646783],
            [110.410301345346397, -7.000282982349833],
            [110.410329003949428, -7.000111152582666],
            [110.410343184768337, -6.999857896452644],
            [110.410347112475435, -6.999658900449962],
            [110.410371319251709, -6.999532293829531],
            [110.4104162355992, -6.999360485669747],
            [110.4104613667145, -6.999242950752565],
            [110.410540741897165, -6.999116413226885],
            [110.410599452880987, -6.998998895300413],
            [110.410654802894584, -6.998854236662425],
            [110.410696550259175, -6.998727651976292],
            [110.410769101290896, -6.998628242344897],
            [110.410851803197801, -6.998555981900219],
            [110.411003581665284, -6.998447625900982],
            [110.411079493550233, -6.998375356912485],
            [110.411100203020254, -6.998330155334778],
            [110.411117619545536, -6.998203540150184],
            [110.411128382149528, -6.997968370656685],
            [110.411139608536004, -6.997814611227237],
            [110.411157002348574, -6.997706087004868],
            [110.41122959844958, -6.997570495338389],
            [110.411319440943188, -6.997443970725004],
            [110.411422840607671, -6.997335554040438],
            [110.411505519522791, -6.997281384419908],
            [110.411701929903188, -6.997227356938601],
            [110.411812311708729, -6.997191312881491],
            [110.411988091934191, -6.99711916849533],
            [110.412153698519688, -6.997037965833561],
            [110.412291590791455, -6.996947683007286],
            [110.412561796053467, -6.996703791748511],
            [110.412699733370332, -6.996577326837095],
            [110.412854928351166, -6.996450883402832],
            [110.412958304854129, -6.996360557304454],
            [110.413092801817697, -6.996270269978016],
            [110.413179146949631, -6.996225150102792],
            [110.413275332355937, -6.99616188751068],
            [110.413389503285572, -6.996093301899497],
            [110.413481922366756, -6.995977753041657],
            [110.413490287338462, -6.995936233217457],
            [110.413502155489084, -6.995877491620089],
            [110.413581507661391, -6.99586105812759],
            [110.414042544909961, -6.995767238579917],
            [110.414101553955248, -6.995752576930843],
            [110.414243531763645, -6.995717182133834],
            [110.414546296771462, -6.99591554034284],
            [110.415011686667455, -6.996181434379543],
            [110.415677123369633, -6.996553702567351],
            [110.416101748086447, -6.996750154790845],
            [110.416604023804581, -6.99692221222966],
            [110.417020574959011, -6.997041099876632],
            [110.417453492761325, -6.997139598531193],
            [110.417763923240386, -6.997180800400197],
            [110.418074284604032, -6.997229420127131],
            [110.418455127399355, -6.997277701821597],
            [110.41876896327058, -6.99733737997127],
            [110.419356439565718, -6.997475916787626],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 166,
        id: 171,
        area: 679519.76335200004,
        perimeter: 3700.124161,
        kode_pos: 50257,
        kode_kec: 3374060,
        kode_kel: 3374051004,
        kec: "Candisari",
        kel: "Candi",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.425765641022593, -7.016004916176239],
            [110.425789696082134, -7.015941839089082],
            [110.425985546619799, -7.015426484230767],
            [110.426053975441747, -7.015227566548623],
            [110.426098943526313, -7.015010529144953],
            [110.426203025813464, -7.014576471594857],
            [110.42621817587262, -7.014449852885818],
            [110.426235949750918, -7.014259918769342],
            [110.42626577867415, -7.014160454538166],
            [110.426345762798348, -7.013997732960608],
            [110.426485554679488, -7.013744629241359],
            [110.426660767301229, -7.013446341123974],
            [110.426702195979857, -7.013346891010797],
            [110.426880403406415, -7.01291292367774],
            [110.426939733606332, -7.012750176754022],
            [110.426951804369452, -7.012596417681016],
            [110.426937291312555, -7.012433580656157],
            [110.426887174064845, -7.01223451812025],
            [110.426872539413566, -7.012171181640372],
            [110.426878536096282, -7.012125961368303],
            [110.426943818629127, -7.011954176175086],
            [110.427163155245466, -7.011664987113171],
            [110.427183852867543, -7.01162883027806],
            [110.427187020273607, -7.011583606548168],
            [110.427180884349539, -7.011511234918843],
            [110.426927376176664, -7.010823466353452],
            [110.426889031279444, -7.010714873360551],
            [110.42684474501587, -7.01060627311755],
            [110.426811054235543, -7.010533867870754],
            [110.426793279655882, -7.010352935820887],
            [110.426743439005051, -7.009927735654068],
            [110.42671491257012, -7.009656335299232],
            [110.42671533266072, -7.009312606108796],
            [110.426725787646077, -7.00886034293008],
            [110.42673301990753, -7.008498530999122],
            [110.426763179826395, -7.008127701507136],
            [110.426789701497299, -7.007955868989756],
            [110.426859227175996, -7.007784088909048],
            [110.426958220790013, -7.007576162661371],
            [110.42705127296901, -7.007368229141917],
            [110.427113997304602, -7.007205486234497],
            [110.427199854850301, -7.007097044624571],
            [110.427289129485175, -7.006970516117835],
            [110.427460965763856, -7.006654132218991],
            [110.427660017318885, -7.006292553762762],
            [110.427745907651939, -7.00615697550136],
            [110.427831842045222, -7.005985215186975],
            [110.427861680996443, -7.005876705241301],
            [110.427868647511204, -7.005731985372274],
            [110.42788218733547, -7.005533000369948],
            [110.427925239005091, -7.005261687097378],
            [110.428001359140779, -7.005017550609054],
            [110.42796242646655, -7.004927048024594],
            [110.42790321179649, -7.004764156584724],
            [110.427853241819633, -7.004654091973214],
            [110.427800744353902, -7.004538460221465],
            [110.428637465011434, -7.004701730687144],
            [110.429255523159455, -7.004801982043858],
            [110.429683786497066, -7.004865820345747],
            [110.430297842265418, -7.004945787525572],
            [110.43071698723864, -7.004984544064425],
            [110.430981149168517, -7.004973389147892],
            [110.43152309882268, -7.004763675756017],
            [110.431690111044219, -7.004711133469766],
            [110.431934392538352, -7.004651452024732],
            [110.432017277044849, -7.004660597676202],
            [110.432055977300635, -7.004687991488091],
            [110.432049198528361, -7.004741021800921],
            [110.432031726184803, -7.004877707811983],
            [110.431948523406945, -7.005571886986843],
            [110.431920886951815, -7.006009691944561],
            [110.431942280314345, -7.006315838714506],
            [110.431987959404523, -7.006583025250709],
            [110.432457258591853, -7.008369795583645],
            [110.432563551741737, -7.008605036006059],
            [110.43250635255545, -7.008948768639242],
            [110.432440712974127, -7.009183873083313],
            [110.432253177503966, -7.009382648144072],
            [110.432056555494086, -7.009608548713403],
            [110.431859672282641, -7.009816357814928],
            [110.431766033821717, -7.010042382739366],
            [110.431718904232739, -7.010178008630547],
            [110.431709074569639, -7.010585045404153],
            [110.431727298124471, -7.010955934000223],
            [110.43174569499827, -7.01141727805682],
            [110.431773353700393, -7.011706768327705],
            [110.431807127142889, -7.012086721245945],
            [110.431806655809325, -7.012475678261826],
            [110.431777718693539, -7.012774145584414],
            [110.431502623817892, -7.013316544170622],
            [110.431229464844407, -7.013895126972509],
            [110.431046043728827, -7.014410499621938],
            [110.4309492268208, -7.014944068166416],
            [110.430896965482489, -7.015577191418701],
            [110.430870933599223, -7.015812343449706],
            [110.43078477018922, -7.015938876305217],
            [110.430655645868271, -7.016029174954594],
            [110.430363000993239, -7.016110229662525],
            [110.429907129452246, -7.01617299495391],
            [110.429597302947215, -7.016190709659142],
            [110.42933606268177, -7.01627180185964],
            [110.428811395541103, -7.016370776722887],
            [110.428604870334695, -7.016361367663799],
            [110.427830926985735, -7.016242833298119],
            [110.427029496039509, -7.016160446206149],
            [110.426564725581727, -7.016096560130644],
            [110.425765641022593, -7.016004916176239],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 167,
        id: 172,
        area: 427094.13506599999,
        perimeter: 2968.212864,
        kode_pos: 50249,
        kode_kec: 3374050,
        kode_kel: 3374030043,
        kec: "Semarang Selatan",
        kel: "Lamper Kidul",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.432563551741737, -7.008605036006059],
            [110.432457258591853, -7.008369795583645],
            [110.431987959404523, -7.006583025250709],
            [110.431942280314345, -7.006315838714506],
            [110.431920886951815, -7.006009691944561],
            [110.431948523406945, -7.005571886986843],
            [110.432031726184803, -7.004877707811983],
            [110.432049198528361, -7.004741021800921],
            [110.432055977300635, -7.004687991488091],
            [110.432128953701493, -7.0047240512571],
            [110.432246332547948, -7.004751329583585],
            [110.433365877698108, -7.00496072721665],
            [110.434257368679795, -7.005187938980953],
            [110.435172332146593, -7.005424222852087],
            [110.435798249849569, -7.005578748435499],
            [110.436962098550026, -7.005887538927817],
            [110.437960211171799, -7.006151206670723],
            [110.439275393279658, -7.006487462076511],
            [110.439518912722562, -7.006551071248719],
            [110.439861433065701, -7.006632889352816],
            [110.44028903732881, -7.006778127310478],
            [110.44058862944668, -7.006796575007431],
            [110.440793192361753, -7.006787772856204],
            [110.440914576398825, -7.006778871695975],
            [110.440970899299501, -7.006760847596332],
            [110.441090596575322, -7.006742898842484],
            [110.441160488918058, -7.006733936393863],
            [110.441452047086784, -7.006681906962671],
            [110.441490932632803, -7.007563648786332],
            [110.441496682428621, -7.007862567097885],
            [110.441548166191836, -7.008210341623468],
            [110.441611991930856, -7.008448326592989],
            [110.441672808478202, -7.008649706576239],
            [110.441712269928772, -7.008838860716801],
            [110.441730387143849, -7.009006638679709],
            [110.44174238059604, -7.009189659952371],
            [110.441733018885884, -7.009360455386107],
            [110.441699074922894, -7.009665426759985],
            [110.44150788782143, -7.010260672955537],
            [110.441391347671455, -7.01062351077598],
            [110.441077276160073, -7.01053469048677],
            [110.441039126663838, -7.010523873635514],
            [110.440749769888683, -7.010436468742562],
            [110.440738486731362, -7.010433060478242],
            [110.440513072203231, -7.010369473337261],
            [110.440396550894462, -7.010333152415831],
            [110.43988915905048, -7.010187819215907],
            [110.439413052017727, -7.010050354245969],
            [110.438964030491221, -7.009920709088216],
            [110.438699251104154, -7.00984425959374],
            [110.43866225985829, -7.009833579133754],
            [110.43860371643315, -7.00981541811952],
            [110.436458647717757, -7.009297255003697],
            [110.436088569993402, -7.009234289498533],
            [110.435741090288388, -7.009175168572606],
            [110.435709055077922, -7.009169718021178],
            [110.434795149962113, -7.009222892900585],
            [110.434059801628294, -7.00924914447129],
            [110.433628219868893, -7.009061666994556],
            [110.433391605410648, -7.008958882347275],
            [110.43281760126618, -7.0086868240782],
            [110.432682723994361, -7.00862334258335],
            [110.432623909025352, -7.008596134997543],
            [110.432563551741737, -7.008605036006059],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 168,
        id: 173,
        area: 1964450.416224,
        perimeter: 6419.121261,
        kode_pos: 50273,
        kode_kec: 3374070,
        kode_kel: 3374052011,
        kec: "Tembalang",
        kel: "Kedung Mundu",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.459840307898375, -7.024928313245832],
            [110.459835717093171, -7.02481071564787],
            [110.459784055172292, -7.024711154947542],
            [110.4597077258924, -7.024656793632258],
            [110.459505889277921, -7.024502786476907],
            [110.458994272063407, -7.024077055116626],
            [110.458693252137863, -7.02381438648195],
            [110.458219098920679, -7.023533426581652],
            [110.457711308811128, -7.023225290670738],
            [110.457363847409837, -7.022998749808379],
            [110.457038999917387, -7.022790326008312],
            [110.456734052402254, -7.02250051502466],
            [110.456465056268058, -7.022210535044711],
            [110.456798844561092, -7.022012129886178],
            [110.457037072995888, -7.021768175591142],
            [110.457186429724686, -7.021062794781644],
            [110.457424919458916, -7.020348471390312],
            [110.457633847516391, -7.019751705927279],
            [110.457723452182904, -7.019335713672183],
            [110.457932337451197, -7.018775130185728],
            [110.458200291585186, -7.018268888122217],
            [110.458438820513067, -7.017762611900899],
            [110.458647253959541, -7.017346756435042],
            [110.458826262468435, -7.016930866919679],
            [110.45894546836891, -7.016605364025475],
            [110.459123921911768, -7.01642465837949],
            [110.459450711808998, -7.015927528696933],
            [110.459432592779336, -7.015692323141601],
            [110.459455687274897, -7.015538575122117],
            [110.459512901223675, -7.01548436759626],
            [110.459552835722818, -7.015448231303753],
            [110.45964435687165, -7.015330744289079],
            [110.459701727186044, -7.015140853429852],
            [110.459776335155055, -7.014969073522942],
            [110.459850555897347, -7.014887748814455],
            [110.459976562694564, -7.014797438085743],
            [110.460113624436957, -7.014689048896722],
            [110.460308172368045, -7.014535497901538],
            [110.460422578983525, -7.014445173705809],
            [110.460456969314592, -7.014309529713413],
            [110.460480042553186, -7.014173872714287],
            [110.460531628909692, -7.014092521872109],
            [110.460623076681415, -7.014038353510082],
            [110.460731741334556, -7.0140203871702],
            [110.460845561082664, -7.013948153315734],
            [110.460951406845354, -7.013921138118382],
            [110.460994798622949, -7.013830732241991],
            [110.461016153315072, -7.013713164364372],
            [110.461086495858581, -7.013559470427171],
            [110.46120421179603, -7.013297284001514],
            [110.461298351185363, -7.013116480612664],
            [110.461415931795088, -7.012971886355064],
            [110.461515656827572, -7.012854408299066],
            [110.461662588895663, -7.012773166603921],
            [110.461797323951089, -7.012719047592207],
            [110.461827095044157, -7.012683304509151],
            [110.461850209633894, -7.012655929436026],
            [110.461854451778777, -7.012683335853181],
            [110.461876216058684, -7.012827452735856],
            [110.461922868973545, -7.012964978705503],
            [110.462041717759035, -7.013231556437639],
            [110.462171924487606, -7.013489643698751],
            [110.462289307878677, -7.013797319737873],
            [110.462325997292808, -7.013964596362869],
            [110.462368487551615, -7.014021334710167],
            [110.462447885071725, -7.014055439422131],
            [110.462561349582714, -7.014069741714501],
            [110.462757090909719, -7.014081303628672],
            [110.463015223951388, -7.014112778206896],
            [110.463313026638829, -7.014186815265877],
            [110.463663296480675, -7.014276501740498],
            [110.463890160900689, -7.014361795364557],
            [110.464108481938823, -7.014475423993056],
            [110.464233181327927, -7.014588945718012],
            [110.464380593163028, -7.014688320892286],
            [110.464611698900683, -7.014786374215695],
            [110.46522992123613, -7.015005334138499],
            [110.465914835347363, -7.015205944991301],
            [110.466805233181375, -7.015583943711947],
            [110.467695719483359, -7.015885409789674],
            [110.468719493048226, -7.016235211404219],
            [110.469211479467447, -7.016446937256862],
            [110.46977450136238, -7.016562370269188],
            [110.469937590944724, -7.016597985547826],
            [110.470025458974177, -7.016668946958698],
            [110.470587882530253, -7.017314427682098],
            [110.470821684034561, -7.017540033150241],
            [110.471102234618499, -7.017820963723414],
            [110.471360124339967, -7.018070689252456],
            [110.471426265175097, -7.018118811787986],
            [110.471473065755816, -7.018152561433275],
            [110.471632257124568, -7.018269409895619],
            [110.471789561527359, -7.018404225176399],
            [110.471905752753941, -7.018517735483898],
            [110.471999305133181, -7.018580199497783],
            [110.472138256745325, -7.018637045582465],
            [110.472331099603238, -7.018705290180472],
            [110.472533876992571, -7.018769294135491],
            [110.472891189294316, -7.018908585567772],
            [110.473103856699296, -7.019008031512482],
            [110.473239910977341, -7.019118729333241],
            [110.473347572459332, -7.019249236680507],
            [110.473433928993899, -7.019403813260269],
            [110.47355565482053, -7.01964204694645],
            [110.473657575497811, -7.019832072072185],
            [110.473736927164467, -7.019908692290398],
            [110.473918381184944, -7.020013771878229],
            [110.474197677303295, -7.020151557684621],
            [110.474648550685629, -7.02034764251841],
            [110.475034171997919, -7.020543653939647],
            [110.475164600710826, -7.020611827616771],
            [110.475234021449978, -7.020689853742791],
            [110.475338783273585, -7.020877047249289],
            [110.475389653598043, -7.021052842517806],
            [110.475392316021342, -7.021208742446978],
            [110.475360946314836, -7.021353266357669],
            [110.475353769486048, -7.021428372316301],
            [110.475365060602485, -7.021479405763294],
            [110.475416042125332, -7.021555994004683],
            [110.475552081822073, -7.021680863671144],
            [110.475690955489995, -7.021808570960371],
            [110.475781633902557, -7.021905044965749],
            [110.475828409111188, -7.021937693871612],
            [110.475950365177638, -7.021971844013279],
            [110.476066666147233, -7.021988980866871],
            [110.476160270539921, -7.022006092347131],
            [110.476231168840144, -7.022031681927771],
            [110.476285006661499, -7.022091266335056],
            [110.476338771584196, -7.022216043933194],
            [110.47647588955212, -7.022645622276873],
            [110.476610215029183, -7.023035514610009],
            [110.47674452486774, -7.023439579348917],
            [110.476919955225952, -7.023859279623395],
            [110.477024961244837, -7.024057958637657],
            [110.476683989844389, -7.024463090384128],
            [110.476628676207028, -7.024628263814039],
            [110.47652924707414, -7.024804403663776],
            [110.47621790122092, -7.024685637460355],
            [110.476151583183096, -7.024831521092164],
            [110.475900540004275, -7.025015753139747],
            [110.475707505436588, -7.025089893148458],
            [110.475564028236477, -7.025216412962476],
            [110.475373607983627, -7.025417236009177],
            [110.47519551638517, -7.025669397428048],
            [110.475033977823529, -7.025940100390495],
            [110.474823182317024, -7.026166487594157],
            [110.47447775824979, -7.026383194502497],
            [110.474036640879959, -7.026654067268186],
            [110.473730403580717, -7.026744179393933],
            [110.473199161575906, -7.026897357572408],
            [110.472795472028338, -7.027095906495569],
            [110.47245001581733, -7.027339748484153],
            [110.472102295772686, -7.027583587639532],
            [110.471808101249522, -7.027782258937464],
            [110.471554499101103, -7.027863383285963],
            [110.471331545910488, -7.027863131863053],
            [110.471209944983741, -7.027808721224811],
            [110.471128834531314, -7.027727219513785],
            [110.471118781787538, -7.027609615664202],
            [110.470977205054055, -7.02745568108164],
            [110.470845398149237, -7.027419349994243],
            [110.470571578271418, -7.027364767338774],
            [110.470329832540827, -7.027219765007648],
            [110.470268759528651, -7.027183513687578],
            [110.470153949357339, -7.027129110469971],
            [110.469930867345852, -7.026993174621468],
            [110.46980097352693, -7.026766888325532],
            [110.469465025592712, -7.026359457342782],
            [110.468998655806857, -7.025942832881799],
            [110.468761612831926, -7.025644060377899],
            [110.468455956328583, -7.025471848035592],
            [110.468341157082293, -7.025408398854704],
            [110.468249527611718, -7.025372112648105],
            [110.468042929149149, -7.025172875669575],
            [110.467989557495315, -7.025082359375897],
            [110.467890057396829, -7.025000836305948],
            [110.467653041330152, -7.02492820268704],
            [110.467232452977512, -7.024810132549671],
            [110.466521091824319, -7.024619366754326],
            [110.465893572444855, -7.024347285282706],
            [110.465032023138519, -7.023866887993631],
            [110.46429915820741, -7.023431864093895],
            [110.463759140498738, -7.023096560921764],
            [110.463424984096449, -7.022860993979449],
            [110.46321917544698, -7.022716029401176],
            [110.46306508123044, -7.022625397349707],
            [110.46298786155414, -7.02260721781206],
            [110.462871859454566, -7.022607084987531],
            [110.462781814441755, -7.022670300836264],
            [110.462562775746818, -7.022959508054512],
            [110.461093806321841, -7.024911664445436],
            [110.461042501656323, -7.024993015553918],
            [110.460982991905155, -7.025074357222963],
            [110.460900637462359, -7.025092353671424],
            [110.460450259713568, -7.025046607820928],
            [110.460193135938013, -7.024992038502633],
            [110.460027085422226, -7.024964710622368],
            [110.459840307898375, -7.024928313245832],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 169,
        id: 174,
        area: 1243428.6382780001,
        perimeter: 5608.612614,
        kode_pos: 50272,
        kode_kec: 3374070,
        kode_kel: 3374052012,
        kec: "Tembalang",
        kel: "Sendangguwo",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.456465056268058, -7.022210535044711],
            [110.456448402042653, -7.022174565123331],
            [110.45644309491594, -7.022102173379173],
            [110.456443136919134, -7.022065991194329],
            [110.456451425931846, -7.021993636331946],
            [110.456462565253773, -7.021903193654709],
            [110.456523731426813, -7.021613806647692],
            [110.456620662259411, -7.021224959901263],
            [110.456650988334417, -7.021179767230265],
            [110.456678506085296, -7.021116480186265],
            [110.456678537569601, -7.021089343545435],
            [110.456645571111977, -7.020971713097773],
            [110.456466990420168, -7.020528273852856],
            [110.45630599370817, -7.020048672696964],
            [110.456097056780862, -7.019433332553432],
            [110.455963588226496, -7.019125628837261],
            [110.455725572609595, -7.018700211592281],
            [110.455496904574304, -7.018265759538116],
            [110.455357694000497, -7.018030413494968],
            [110.455167106248538, -7.017695506643726],
            [110.455043425498587, -7.017487315227159],
            [110.454910135812995, -7.017270067068246],
            [110.454582444334179, -7.016835499658141],
            [110.454227906914213, -7.016373764176239],
            [110.45392280870665, -7.015975404876975],
            [110.453732672624255, -7.015739999206407],
            [110.453510618691141, -7.015459328538486],
            [110.453117102740194, -7.014943273645976],
            [110.452846470621495, -7.014590181684853],
            [110.452600934847297, -7.014309483294239],
            [110.452242030487199, -7.013956288131642],
            [110.451967290572838, -7.013729828721996],
            [110.451880495726058, -7.013675454099976],
            [110.451670900437662, -7.013629981657331],
            [110.451466093770179, -7.013602605825833],
            [110.451145015160819, -7.013566048483789],
            [110.450918989126421, -7.013538647619283],
            [110.450513075280625, -7.013465808448021],
            [110.450296962364675, -7.013429373336072],
            [110.450166837710057, -7.013411129908439],
            [110.449993499708896, -7.013329516975188],
            [110.449567017634251, -7.013184288510048],
            [110.448706591934723, -7.012984277222715],
            [110.448308915823574, -7.012884309227054],
            [110.44747081049546, -7.012711458618949],
            [110.447246840346409, -7.012620739613734],
            [110.446863647481337, -7.01247555974458],
            [110.446581677955422, -7.012384772135857],
            [110.446075877780189, -7.012085672888892],
            [110.445121835475888, -7.011623224072343],
            [110.444894130693996, -7.011506676897192],
            [110.44482071564201, -7.011468844510691],
            [110.444862455135876, -7.011391520096918],
            [110.444963149796706, -7.01120486969855],
            [110.444976653959401, -7.011179820835889],
            [110.445071364713542, -7.010999021803422],
            [110.445267250443905, -7.010673613477335],
            [110.445358533796139, -7.010519946959083],
            [110.445450361543195, -7.010384372153195],
            [110.445671656400847, -7.010104221332618],
            [110.445920426617135, -7.009796966140643],
            [110.446069730217658, -7.009625276764041],
            [110.446889480917292, -7.008803097417721],
            [110.447273006085339, -7.008423635550844],
            [110.447618010582957, -7.008080310249145],
            [110.447959619508595, -7.007736980662193],
            [110.448115973474273, -7.007583389895951],
            [110.44843264200712, -7.007276212828215],
            [110.448851855319845, -7.006860613042567],
            [110.44953602937025, -7.006207608023293],
            [110.449820585556608, -7.006586133745945],
            [110.450035914514274, -7.006804640686938],
            [110.450163425354987, -7.006935175941904],
            [110.450332113848759, -7.007030328314784],
            [110.450615671548505, -7.007149707982817],
            [110.450938897266951, -7.00731165103659],
            [110.451194065686678, -7.00744800410088],
            [110.451328727475186, -7.007528944010145],
            [110.451464769691697, -7.007642482024541],
            [110.451581006154001, -7.007710645201477],
            [110.451759692554077, -7.007747701908463],
            [110.452020624810373, -7.007807530292602],
            [110.452253197303889, -7.007858822044255],
            [110.452417728720391, -7.007870351681894],
            [110.452660321655614, -7.00784370667478],
            [110.452924107620504, -7.007889365485771],
            [110.453125437191702, -7.007971799746567],
            [110.453261526246621, -7.008045654593891],
            [110.453366349135308, -7.008170493711179],
            [110.453614239848662, -7.008466985298809],
            [110.45375029612552, -7.008569184799443],
            [110.453911885304251, -7.008671413934229],
            [110.454056498838042, -7.008733940551862],
            [110.454247937475756, -7.008783766338622],
            [110.454619457068318, -7.008891907952983],
            [110.454852046943586, -7.00892902608778],
            [110.455078979357509, -7.00895196513849],
            [110.455831888102466, -7.009220696177465],
            [110.456101299954355, -7.009308877040789],
            [110.456328111106899, -7.009436691231606],
            [110.456481150821745, -7.009572923447705],
            [110.456764531311137, -7.009848195989504],
            [110.457036623225548, -7.010072434642179],
            [110.457386839307716, -7.010204642677949],
            [110.45803205715913, -7.010376873608213],
            [110.458684415474067, -7.010508011898703],
            [110.459047443052086, -7.010601967981042],
            [110.459251621830887, -7.010675899605871],
            [110.459414629910214, -7.010779545860113],
            [110.459610225986467, -7.010915826114992],
            [110.459854087083272, -7.011020982463011],
            [110.460143359877279, -7.011109183971385],
            [110.460524823904379, -7.011207411879044],
            [110.460853792178469, -7.011315499942083],
            [110.461052291084741, -7.011395093277876],
            [110.461293283238561, -7.011528590476988],
            [110.461547001370448, -7.011697533210837],
            [110.461714238206383, -7.011825276697343],
            [110.461782231981658, -7.011907554671666],
            [110.461801999928866, -7.011986942901619],
            [110.461778929723479, -7.012312882244973],
            [110.461850209633894, -7.012655929436026],
            [110.461827095044157, -7.012683304509151],
            [110.461797323951089, -7.012719047592207],
            [110.461662588895663, -7.012773166603921],
            [110.461515656827572, -7.012854408299066],
            [110.461415931795088, -7.012971886355064],
            [110.461298351185363, -7.013116480612664],
            [110.46120421179603, -7.013297284001514],
            [110.461086495858581, -7.013559470427171],
            [110.461016153315072, -7.013713164364372],
            [110.460994798622949, -7.013830732241991],
            [110.460951406845354, -7.013921138118382],
            [110.460845561082664, -7.013948153315734],
            [110.460731741334556, -7.0140203871702],
            [110.460623076681415, -7.014038353510082],
            [110.460531628909692, -7.014092521872109],
            [110.460480042553186, -7.014173872714287],
            [110.460456969314592, -7.014309529713413],
            [110.460422578983525, -7.014445173705809],
            [110.460308172368045, -7.014535497901538],
            [110.460113624436957, -7.014689048896722],
            [110.459976562694564, -7.014797438085743],
            [110.459850555897347, -7.014887748814455],
            [110.459776335155055, -7.014969073522942],
            [110.459701727186044, -7.015140853429852],
            [110.45964435687165, -7.015330744289079],
            [110.459552835722818, -7.015448231303753],
            [110.459512901223675, -7.01548436759626],
            [110.459455687274897, -7.015538575122117],
            [110.459432592779336, -7.015692323141601],
            [110.459450711808998, -7.015927528696933],
            [110.459123921911768, -7.01642465837949],
            [110.45894546836891, -7.016605364025475],
            [110.458826262468435, -7.016930866919679],
            [110.458647253959541, -7.017346756435042],
            [110.458438820513067, -7.017762611900899],
            [110.458200291585186, -7.018268888122217],
            [110.457932337451197, -7.018775130185728],
            [110.457723452182904, -7.019335713672183],
            [110.457633847516391, -7.019751705927279],
            [110.457424919458916, -7.020348471390312],
            [110.457186429724686, -7.021062794781644],
            [110.457037072995888, -7.021768175591142],
            [110.456798844561092, -7.022012129886178],
            [110.456465056268058, -7.022210535044711],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 170,
        id: 175,
        area: 847674.83793000004,
        perimeter: 3966.288765,
        kode_pos: 50249,
        kode_kec: 3374050,
        kode_kel: 3374030038,
        kec: "Semarang Selatan",
        kel: "Mugasari",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.419356439565718, -6.997475916787626],
            [110.41876896327058, -6.99733737997127],
            [110.418455127399355, -6.997277701821597],
            [110.418074284604032, -6.997229420127131],
            [110.417763923240386, -6.997180800400197],
            [110.417453492761325, -6.997139598531193],
            [110.417020574959011, -6.997041099876632],
            [110.416604023804581, -6.99692221222966],
            [110.416101748086447, -6.996750154790845],
            [110.415677123369633, -6.996553702567351],
            [110.415011686667455, -6.996181434379543],
            [110.414546296771462, -6.99591554034284],
            [110.414243531763645, -6.995717182133834],
            [110.414182867954167, -6.995664322269459],
            [110.414056396020371, -6.995554366973688],
            [110.413830260274977, -6.994534452056169],
            [110.413786225572977, -6.994000712497304],
            [110.413779704476298, -6.993331337060105],
            [110.413774021753667, -6.992897145769807],
            [110.413812178995954, -6.992472054537521],
            [110.413951763766505, -6.991703360287382],
            [110.41407214506448, -6.991133643178451],
            [110.414135898897186, -6.990600037614374],
            [110.414130564914387, -6.989885435994196],
            [110.414092810202035, -6.989306476643132],
            [110.41403433556097, -6.988781764560163],
            [110.414009709940515, -6.988564641782886],
            [110.413825584064611, -6.986936221612948],
            [110.413803320992315, -6.9864605909292],
            [110.414055753526171, -6.986532762068293],
            [110.417003918953228, -6.987415286669637],
            [110.417598739559821, -6.98758219479571],
            [110.418296906614785, -6.987860011643623],
            [110.419715258960309, -6.988544915096863],
            [110.421356278332809, -6.989344417233579],
            [110.422359353519028, -6.989841322388367],
            [110.422527835256361, -6.9894887536362],
            [110.422648586376482, -6.989261697485862],
            [110.422757924474979, -6.98919053338707],
            [110.422906569928671, -6.989172549109394],
            [110.422945224087528, -6.989180219915713],
            [110.422998680774953, -6.989190828235545],
            [110.423110951629113, -6.989227147771387],
            [110.42391956996758, -6.989653317715458],
            [110.423966034160401, -6.989732103185827],
            [110.423983765821276, -6.98985000738188],
            [110.42398376025929, -6.989850031626058],
            [110.42395499141621, -6.989975432840135],
            [110.42370326273489, -6.990490166326604],
            [110.423195731251596, -6.99149985968005],
            [110.423134161723794, -6.991555647107164],
            [110.423044693539666, -6.991598438290419],
            [110.422953340325805, -6.991592667526241],
            [110.42248211152436, -6.991364914638699],
            [110.421913463720557, -6.992391611651044],
            [110.42135574072492, -6.993394979172287],
            [110.420605843524029, -6.99468756585707],
            [110.420354636000312, -6.995130486886817],
            [110.42009405835104, -6.99560053273545],
            [110.419875984223808, -6.99601635768662],
            [110.419499034445423, -6.997019944904822],
            [110.419356439565718, -6.997475916787626],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 171,
        id: 176,
        area: 433911.73384900001,
        perimeter: 3309.821778,
        kode_pos: 50162,
        kode_kec: 3374100,
        kode_kel: 3374042003,
        kec: "Gayamsari",
        kel: "Siwalan",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.452447903469988, -6.989900704936116],
            [110.451250771054177, -6.989821404021439],
            [110.451114496855226, -6.989812376699005],
            [110.450750138379917, -6.989784815446349],
            [110.450336532219907, -6.989775287593815],
            [110.449747814445885, -6.989756509364897],
            [110.449370399895997, -6.98976511404938],
            [110.448893672823459, -6.989782647848278],
            [110.448396052730118, -6.989763974469432],
            [110.448038799242411, -6.989709282777274],
            [110.447950269200675, -6.989691087951516],
            [110.44796878581856, -6.989582562945603],
            [110.448162403368187, -6.988045044727229],
            [110.44817950879424, -6.987692287945293],
            [110.448146192547497, -6.987393745483205],
            [110.448087372007862, -6.98713135539513],
            [110.448062521905115, -6.986850913958552],
            [110.448062755292341, -6.98665191192369],
            [110.448114085557052, -6.986064010639485],
            [110.448224493955195, -6.985277176141896],
            [110.448480117598336, -6.98369450205041],
            [110.448590386821039, -6.983025259437841],
            [110.448785459567716, -6.981930974436269],
            [110.448980755582255, -6.981370377709454],
            [110.449020336329667, -6.980909100224628],
            [110.449020611435074, -6.980673915904222],
            [110.449027153167336, -6.979434681353597],
            [110.448966952722827, -6.978900922803696],
            [110.448962029699018, -6.97875618803465],
            [110.448912440973345, -6.977697123835924],
            [110.450019950474143, -6.978505493191115],
            [110.450892370944359, -6.979142267809277],
            [110.450901756977061, -6.97920318188137],
            [110.450916312591431, -6.97929956661211],
            [110.451153380874786, -6.980869387091572],
            [110.451418898994959, -6.982778013165166],
            [110.451684493456582, -6.984622434128714],
            [110.451720810886655, -6.984783959103597],
            [110.451721754629403, -6.984790915773591],
            [110.451950081139458, -6.986473988847566],
            [110.452222743785512, -6.988382622818947],
            [110.452388530908578, -6.989499408200009],
            [110.452445692970301, -6.989885877123015],
            [110.452447903469988, -6.989900704936116],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 172,
        id: 177,
        area: 2130957.221068,
        perimeter: 7012.532791,
        kode_pos: 50197,
        kode_kec: 3374080,
        kode_kel: 3374041012,
        kec: "Pedurungan",
        kel: "Muktiharjo Kidul",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.4679847320172, -6.982848531631336],
            [110.467770089250081, -6.982830198297073],
            [110.467498831430589, -6.98278466418489],
            [110.467284155300902, -6.982739193828213],
            [110.467001632470058, -6.982648418771026],
            [110.46685715862192, -6.982566845196921],
            [110.467014841741928, -6.981725783632831],
            [110.466409239278619, -6.98163464313618],
            [110.466008441516109, -6.981561824923855],
            [110.465517642835223, -6.981271810332327],
            [110.464907519700603, -6.980927386434221],
            [110.463947668390446, -6.980383561723772],
            [110.463326045912169, -6.980201943394074],
            [110.460823697932668, -6.979493535274944],
            [110.460528678896338, -6.979447970193146],
            [110.460220121907795, -6.979366207125327],
            [110.458811142681427, -6.978966587918925],
            [110.458418584276487, -6.978866636225448],
            [110.458287923587349, -6.978830303952448],
            [110.458239567572321, -6.978812157289096],
            [110.458204518513796, -6.978784980320643],
            [110.458161579317675, -6.978730657559039],
            [110.458127378985637, -6.978703481559996],
            [110.457926012870402, -6.978648976850088],
            [110.457707337536561, -6.97863968005252],
            [110.457623618416491, -6.978621492693525],
            [110.457351809024829, -6.978566906784986],
            [110.456742974972329, -6.978340066968592],
            [110.456430720908784, -6.97827638841854],
            [110.455779944719751, -6.978112818132868],
            [110.455729315206213, -6.978103714177272],
            [110.455526283625616, -6.978022069840753],
            [110.454938145204011, -6.977759069392212],
            [110.454642887063216, -6.977677318186588],
            [110.454597926186239, -6.977659175091631],
            [110.454251734910599, -6.977586410367527],
            [110.454280308176422, -6.977342212954898],
            [110.454302039333712, -6.977143235487816],
            [110.45426136398298, -6.977088915030374],
            [110.454219242694535, -6.977061729614948],
            [110.454170310760134, -6.977052627465368],
            [110.453976584333873, -6.976998129988871],
            [110.453681305901981, -6.976934469347847],
            [110.453518919003585, -6.976934281387207],
            [110.453429814734591, -6.976925132655365],
            [110.453211727245701, -6.9768977433809],
            [110.452872033102324, -6.976834030797571],
            [110.452765105928577, -6.976824861285324],
            [110.452531479850208, -6.976779362550167],
            [110.452170872067995, -6.976697534082491],
            [110.452032836051814, -6.976679282736556],
            [110.451667911238957, -6.97666076786625],
            [110.451640763003553, -6.976651690763251],
            [110.451248470313601, -6.976569824804276],
            [110.450988849040399, -6.976497158405441],
            [110.450689471259111, -6.976463549148321],
            [110.450502146954577, -6.976442519330825],
            [110.450370507999793, -6.975664298460999],
            [110.45013320509878, -6.974055328398994],
            [110.449817875135295, -6.972011099620694],
            [110.449658478042835, -6.970944396289018],
            [110.449385514100896, -6.969306848781793],
            [110.449243871591619, -6.968322205844458],
            [110.449244187988057, -6.968051118094367],
            [110.449257754712491, -6.96789686335989],
            [110.449266184193874, -6.967739257487492],
            [110.449266184395171, -6.967739253723731],
            [110.449311873728107, -6.967406470922049],
            [110.449401565484166, -6.967023128369759],
            [110.449430481861796, -6.966939653185779],
            [110.449580657326351, -6.966506129280056],
            [110.449871496187257, -6.965824288701584],
            [110.450220514089054, -6.964995378822996],
            [110.450674852769055, -6.963917864113665],
            [110.451030403048449, -6.963074625149657],
            [110.451219463833581, -6.962590628860461],
            [110.453551357463098, -6.964622696657319],
            [110.455838771698623, -6.966656127659897],
            [110.457823698579162, -6.968466332260731],
            [110.460574120784017, -6.97095956195702],
            [110.461130448687541, -6.971448778916334],
            [110.462453650833169, -6.972612354114221],
            [110.464148910242073, -6.974071513956907],
            [110.465934988854926, -6.975671335349921],
            [110.467429591324517, -6.97700785699467],
            [110.468781334204991, -6.978198439576404],
            [110.469167674968375, -6.978547263364012],
            [110.469193485037962, -6.978570519454942],
            [110.469167535789822, -6.978670800559209],
            [110.46908797562152, -6.978978261230539],
            [110.468758871101684, -6.980307592946475],
            [110.468645116587993, -6.980832109181264],
            [110.468566083464566, -6.980922476081084],
            [110.468486838855412, -6.980949523586745],
            [110.468249551277225, -6.98088593706492],
            [110.46811401836716, -6.98090387547134],
            [110.468023437025309, -6.980949001298207],
            [110.467978069718924, -6.981039406048408],
            [110.467876373204646, -6.981156884033394],
            [110.467794176798577, -6.981292475171291],
            [110.467839217113806, -6.981491528967131],
            [110.46785024723323, -6.981744817937317],
            [110.467872787854262, -6.981826253676179],
            [110.467997205894875, -6.981880667548742],
            [110.46805348408283, -6.981898822195118],
            [110.468121341058549, -6.981935081071555],
            [110.468143779577844, -6.982106972591204],
            [110.468120891547315, -6.982333086545545],
            [110.468052687106535, -6.982604377342219],
            [110.468041186849888, -6.982767184995176],
            [110.4679847320172, -6.982848531631336],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 173,
        id: 178,
        area: 2141142.8513349998,
        perimeter: 7689.388392,
        kode_pos: 50116,
        kode_kec: 3374090,
        kode_kel: 3374040010,
        kec: "Genuk",
        kel: "Kudu",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.504460655042976, -6.978673538175388],
            [110.504408773559831, -6.978579949664777],
            [110.504138052497382, -6.978090778977168],
            [110.504013856102517, -6.977909546617837],
            [110.503768946455835, -6.977614554799028],
            [110.50311778437738, -6.977052413818028],
            [110.502969938852672, -6.976943710484321],
            [110.502797188111714, -6.976844026518375],
            [110.502071445819041, -6.976390979591692],
            [110.501744041633742, -6.976200675733074],
            [110.5014814039762, -6.976028531334717],
            [110.500525201885168, -6.975475736787035],
            [110.500136672318888, -6.975303458505331],
            [110.49966636430446, -6.97514918454893],
            [110.499137807047674, -6.974967711452844],
            [110.498891190907415, -6.974895084709424],
            [110.498511609604591, -6.974822316648539],
            [110.497879677436856, -6.974749280019724],
            [110.497730323027852, -6.974731029910554],
            [110.497564843067508, -6.974712762597392],
            [110.497446635370693, -6.974701722610945],
            [110.497428499257367, -6.974492491433356],
            [110.497421590347088, -6.974278419712417],
            [110.497312625393548, -6.973471310642681],
            [110.497287451847811, -6.973256120263993],
            [110.497260690385545, -6.973105273916977],
            [110.497224004184474, -6.972871498815465],
            [110.497187357361099, -6.972600818060717],
            [110.49714302316525, -6.972333311801482],
            [110.497055602915793, -6.972070895328156],
            [110.497022033920672, -6.971980403240451],
            [110.496917406736799, -6.971748624236574],
            [110.496708726939758, -6.971167137313865],
            [110.49663819364342, -6.970924099697566],
            [110.496616772805922, -6.970807208850012],
            [110.496634139832551, -6.97068646436337],
            [110.496636763171693, -6.970614102081694],
            [110.496594334533214, -6.970342687840524],
            [110.49654472424308, -6.969908444519587],
            [110.496515797741466, -6.969709409734933],
            [110.496487231388841, -6.969438010253863],
            [110.49646203091244, -6.969193751261366],
            [110.496437132661995, -6.968931401319001],
            [110.49641192256594, -6.968696187947165],
            [110.496379337755542, -6.968479057985158],
            [110.496368761301113, -6.968316225283845],
            [110.49637253555268, -6.968225772954519],
            [110.496390766696663, -6.96810819912345],
            [110.496428944335321, -6.967992949574545],
            [110.496552303811924, -6.967765495859562],
            [110.496792771469742, -6.967399770418027],
            [110.496906883753056, -6.967184608637396],
            [110.496994871740114, -6.966941955344049],
            [110.49704235702005, -6.966715864954714],
            [110.497082583234118, -6.966399310465436],
            [110.497126618044646, -6.966056143074227],
            [110.49720389979683, -6.965742526792503],
            [110.497244148363237, -6.965521135370518],
            [110.497250598940099, -6.965244349448664],
            [110.497196143325581, -6.96435547915639],
            [110.497184214941029, -6.963995635843728],
            [110.497187770969816, -6.963546620198493],
            [110.497158776028243, -6.963124870736132],
            [110.497140921008651, -6.962889665161617],
            [110.497152165907522, -6.962690673075121],
            [110.497201228586732, -6.962470218036761],
            [110.497293928688677, -6.96212999416868],
            [110.497359480079467, -6.961940105411052],
            [110.497411300146965, -6.961741553512723],
            [110.497479157404413, -6.961606304658739],
            [110.497590337123157, -6.961252743003946],
            [110.4976480578555, -6.960854935215512],
            [110.497788504614448, -6.960139631024203],
            [110.497939921415082, -6.95957390405603],
            [110.498191113106486, -6.958557918735284],
            [110.498194000195241, -6.958480202198126],
            [110.498439468666334, -6.958566557143986],
            [110.498472037921204, -6.958472862602298],
            [110.498475726018398, -6.958397162272638],
            [110.498475798710473, -6.958328668039076],
            [110.498504718106062, -6.958274624226245],
            [110.498540872122447, -6.95820256325722],
            [110.498623907752844, -6.958148576767524],
            [110.498728628101205, -6.958058563589],
            [110.498782809340256, -6.957997336580571],
            [110.498829870641472, -6.957845977926566],
            [110.498894985932409, -6.957680218522044],
            [110.498955862072791, -6.957429377464076],
            [110.499011008547839, -6.957138154728291],
            [110.499041452665097, -6.95700696624989],
            [110.499087821250825, -6.956828209089672],
            [110.499160199130614, -6.956617755751937],
            [110.499213680742827, -6.956535619141309],
            [110.499283047073106, -6.956443405423469],
            [110.499329274986565, -6.956397310769147],
            [110.49914362833141, -6.956273357730772],
            [110.499112963561387, -6.956252883385064],
            [110.498864901150199, -6.956111306275012],
            [110.49919188359938, -6.955315676107357],
            [110.498707258678721, -6.955075793651245],
            [110.498905541312837, -6.954529490909113],
            [110.499127001436904, -6.953899577370852],
            [110.499478447870899, -6.952822776946057],
            [110.499514919280443, -6.952815336467236],
            [110.499909950795072, -6.952707205953088],
            [110.500549721548353, -6.952563150627492],
            [110.501339726418038, -6.952401161290882],
            [110.501527801580281, -6.952446587482414],
            [110.501590489106974, -6.952555201222959],
            [110.501903142243549, -6.953306318909488],
            [110.501928573945804, -6.953333482598968],
            [110.501960814113389, -6.953342562150062],
            [110.502058987182792, -6.953333619724165],
            [110.502097177610594, -6.953333659873359],
            [110.503995233534127, -6.953209012284407],
            [110.504628364958961, -6.953191583586962],
            [110.504633183441086, -6.953194507727174],
            [110.50543466238679, -6.953680891450425],
            [110.505544346635247, -6.953768572764155],
            [110.506363755469792, -6.954423604367575],
            [110.50681681764479, -6.954822085211797],
            [110.506550265539104, -6.95542786632346],
            [110.506439691537295, -6.955662938064943],
            [110.506306683789703, -6.955979397256773],
            [110.50628309968134, -6.956078874847427],
            [110.506298394319799, -6.956332169080806],
            [110.506298280948897, -6.956440716797804],
            [110.506282636741489, -6.956522111359161],
            [110.506251141004043, -6.956612535039887],
            [110.506180879290852, -6.956711963934006],
            [110.506118180080534, -6.956883765925164],
            [110.506110145615409, -6.956992305372633],
            [110.506110069998954, -6.95706467051329],
            [110.506156983223917, -6.957109947719094],
            [110.506227018208378, -6.957227614259717],
            [110.506232076160259, -6.957255053836473],
            [110.506250366161197, -6.957354277748844],
            [110.506250271662367, -6.957444734175171],
            [110.506242586254942, -6.957489954420853],
            [110.506172050953381, -6.957580337363106],
            [110.506023323196771, -6.957779186522504],
            [110.505992138571472, -6.957842473540753],
            [110.505889503628708, -6.958059462054045],
            [110.505803323804415, -6.958231239460603],
            [110.505787651051804, -6.958339770914189],
            [110.505709420587465, -6.958484419642254],
            [110.505654434808918, -6.958583864376997],
            [110.505607596189208, -6.958737591520928],
            [110.505521245736617, -6.959072190393798],
            [110.505489749652654, -6.959162613991904],
            [110.505466457532194, -6.959253046160028],
            [110.505309788892617, -6.959469978026342],
            [110.505145396645943, -6.959768312603192],
            [110.505074794444539, -6.959922014814949],
            [110.505067042536211, -6.960030554506171],
            [110.505051303190825, -6.960202405390556],
            [110.505082137495222, -6.96047380713763],
            [110.505065116323507, -6.960519017581916],
            [110.505057100451168, -6.960609465693957],
            [110.504953620198734, -6.960729095872785],
            [110.50493983909233, -6.960745027758335],
            [110.504564405119652, -6.961043141192801],
            [110.504478319099519, -6.961124461876395],
            [110.504329399603094, -6.961504223114008],
            [110.504117600680658, -6.961956283556698],
            [110.504047176190994, -6.9622094878665],
            [110.504000044219168, -6.962372260106572],
            [110.503984209345703, -6.962634567281012],
            [110.503976231205968, -6.962688832799573],
            [110.503858826449019, -6.962960079084536],
            [110.503694393923169, -6.963294595510251],
            [110.503678447049822, -6.963394080891093],
            [110.503647023992102, -6.963683508609073],
            [110.503631150791506, -6.963981998286373],
            [110.503614590526539, -6.964126711248023],
            [110.503512763606835, -6.964379882450414],
            [110.503395339073364, -6.964669219824301],
            [110.503251378979002, -6.964904255405997],
            [110.503218353286115, -6.965103224913122],
            [110.503195305187759, -6.965229839734382],
            [110.503188655714851, -6.965365517429052],
            [110.503160515401916, -6.965492126895148],
            [110.503139221782988, -6.965564469681411],
            [110.503080518773316, -6.965700092666459],
            [110.503071955610409, -6.965772448827256],
            [110.503071907998901, -6.965817677002573],
            [110.503331279327753, -6.965863177767221],
            [110.5036831589033, -6.965908775513219],
            [110.504076529787653, -6.966044872988678],
            [110.505106405030759, -6.966471097587572],
            [110.505223723215948, -6.966552631214203],
            [110.505257039058208, -6.966615985615016],
            [110.505256637286195, -6.966999275098705],
            [110.505256621857427, -6.967013993672881],
            [110.505252171667166, -6.967081588537886],
            [110.505248284993186, -6.967140624012393],
            [110.5051067745175, -6.967737488595313],
            [110.505079775614746, -6.967855053737205],
            [110.504903752519709, -6.968180512672779],
            [110.504543287050865, -6.968496732556673],
            [110.50445932979278, -6.968704694390017],
            [110.504450652751601, -6.968885598207358],
            [110.504492256183525, -6.969138919910755],
            [110.504559180389251, -6.969256583472523],
            [110.504893917688918, -6.969464984153366],
            [110.50496091796947, -6.969510282577135],
            [110.504960861021956, -6.969564556394533],
            [110.504810474829512, -6.970260913556211],
            [110.504780675128984, -6.970351338781211],
            [110.504559420130775, -6.971183306190208],
            [110.504446123731242, -6.971581195735566],
            [110.5044423984709, -6.971626420051333],
            [110.505286995425493, -6.971772035606487],
            [110.505417941684868, -6.97180835530264],
            [110.505458076009575, -6.971844579903691],
            [110.505472466138897, -6.971880777552063],
            [110.505476049035437, -6.971971237762375],
            [110.505483357099948, -6.97201647364252],
            [110.505517003636101, -6.972034600156086],
            [110.506103009011653, -6.972198034852028],
            [110.506226599973246, -6.972234346645402],
            [110.506277494204738, -6.972261536783446],
            [110.506295835590109, -6.972306784188119],
            [110.506310206858672, -6.9723610730898],
            [110.50631751500751, -6.972406308962356],
            [110.506156303444342, -6.973175020401642],
            [110.506053842042519, -6.973762880256642],
            [110.505949782484961, -6.974195683365302],
            [110.505941986549217, -6.974228108077976],
            [110.505903814406977, -6.97438687287339],
            [110.505834507170647, -6.975100806615119],
            [110.505795997449198, -6.97549830423412],
            [110.505774457778713, -6.975857757462903],
            [110.505714782877646, -6.976257347460831],
            [110.505621351583628, -6.976557517614892],
            [110.50550675954571, -6.976855551009614],
            [110.505404796262923, -6.977219149052028],
            [110.505400248556171, -6.977519412249308],
            [110.505393724076583, -6.977540844471402],
            [110.505357776345406, -6.977658928907029],
            [110.505306925530689, -6.977715968817977],
            [110.505094983708403, -6.978013900022131],
            [110.504959330223571, -6.978214641239251],
            [110.504950856181111, -6.978223090602593],
            [110.504861940932571, -6.978252601212089],
            [110.504739191715757, -6.97825670154775],
            [110.504595284053977, -6.978256550517287],
            [110.504569879691417, -6.978264982102066],
            [110.504485720737947, -6.978580030460041],
            [110.504460655042976, -6.978673538175388],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        gid: 174,
        id: 179,
        area: 2216305.0748020001,
        perimeter: 7307.410616,
        kode_pos: 50117,
        kode_kec: 3374090,
        kode_kel: 3374040011,
        kec: "Genuk",
        kel: "Karang Roto",
        ket: "",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.497446635370693, -6.974701722610945],
            [110.497308579666679, -6.974667261690025],
            [110.497023206103407, -6.974594592740494],
            [110.496001625613459, -6.974340225801561],
            [110.495073941502369, -6.97411309402399],
            [110.49456173777034, -6.973985907271476],
            [110.493901341202204, -6.973813333047215],
            [110.493612856876723, -6.973740658774124],
            [110.492643022757093, -6.973513477185136],
            [110.491884499174162, -6.973304612304252],
            [110.491696417052523, -6.973259181787825],
            [110.49138191613342, -6.973177432571951],
            [110.491122591265409, -6.973086696978664],
            [110.490889040008625, -6.972968852154114],
            [110.490733257963001, -6.972878227956103],
            [110.490526026686197, -6.972751365717029],
            [110.490215292104565, -6.972588209198062],
            [110.489829334917573, -6.972397834376927],
            [110.489528482851526, -6.972252779359361],
            [110.4887920980416, -6.971980614304198],
            [110.488272621822176, -6.971781048153807],
            [110.488186375823361, -6.971744772229751],
            [110.488011318108008, -6.971690308801171],
            [110.487762169481726, -6.971608628112895],
            [110.487712964118018, -6.971590483512835],
            [110.487651057630643, -6.971545188267501],
            [110.48758122006366, -6.971508930034153],
            [110.487360996470741, -6.971363961167578],
            [110.487293139270321, -6.971327705043805],
            [110.487266273395576, -6.971318630265297],
            [110.487150918153148, -6.971255185705838],
            [110.487008980150947, -6.971182513195385],
            [110.48701896025743, -6.971110312502251],
            [110.487029252940403, -6.971010821844004],
            [110.487059456952309, -6.970811850967414],
            [110.487099933912162, -6.970531480643394],
            [110.487125328522126, -6.970332504533562],
            [110.487125212802397, -6.970178728838997],
            [110.487136637034155, -6.970079239399652],
            [110.487174351421515, -6.969997869736399],
            [110.487208397909811, -6.969907450466525],
            [110.487231147995885, -6.969798927690685],
            [110.487246581962722, -6.969654214485447],
            [110.487254016040254, -6.969581857575108],
            [110.487280657898651, -6.969536658371695],
            [110.487382718518973, -6.969337765404886],
            [110.487454771902435, -6.969156931109153],
            [110.487515792095678, -6.968976084832578],
            [110.48756120358108, -6.968840449733392],
            [110.48757256872743, -6.968795233942203],
            [110.487572617845103, -6.968750005881062],
            [110.487561350937369, -6.968704765550163],
            [110.487535070651532, -6.968677600185539],
            [110.48745956566593, -6.968650381438427],
            [110.487266969342329, -6.968595898794972],
            [110.487210428553681, -6.968559654961178],
            [110.487195191247153, -6.968523455938581],
            [110.487191552862456, -6.968487269500972],
            [110.487237013528159, -6.968306406373895],
            [110.487320441754633, -6.968071310687022],
            [110.487373629057899, -6.967809045311395],
            [110.487415510119561, -6.967537722038972],
            [110.487423305610037, -6.967393000521706],
            [110.48743471002085, -6.967311602279154],
            [110.487521766524026, -6.96712173857857],
            [110.487574737511395, -6.967058476638214],
            [110.487608734570216, -6.96701328537203],
            [110.487643093078489, -6.966895729505183],
            [110.487639220860629, -6.96681431469501],
            [110.487613078145145, -6.96666051075691],
            [110.487590593494204, -6.966524802030102],
            [110.487560352728536, -6.966497632380295],
            [110.487416952252929, -6.966470340048978],
            [110.487277278616574, -6.966397823600396],
            [110.487242791508223, -6.966363644407139],
            [110.487222866824624, -6.966305370615079],
            [110.487234587942751, -6.966248795494587],
            [110.487281182295234, -6.966189778495422],
            [110.487337830887057, -6.966126520562227],
            [110.487481671295598, -6.966009083406581],
            [110.487784033197215, -6.96580136172493],
            [110.488029667928146, -6.965729262777411],
            [110.488184482260806, -6.965666111017025],
            [110.488218498767722, -6.965602828464046],
            [110.4882791469701, -6.965503392231244],
            [110.488301867112995, -6.965422006193939],
            [110.48831326149687, -6.965349653523514],
            [110.4883398455773, -6.965096404783623],
            [110.488329486173896, -6.964996891694995],
            [110.488306981750952, -6.964879274213046],
            [110.48829948075074, -6.964752627340604],
            [110.48830730527331, -6.964580768924058],
            [110.488307432717619, -6.964463175930389],
            [110.488273622197923, -6.964336500587096],
            [110.488206011010732, -6.964074104280283],
            [110.488187213732331, -6.963929353928618],
            [110.488206305146264, -6.963802735830987],
            [110.488270728790525, -6.963612847403117],
            [110.488395800243367, -6.963323522682066],
            [110.488407184739174, -6.963260215613756],
            [110.488403302573374, -6.963187846405519],
            [110.488358284005685, -6.962961657055347],
            [110.488358342806734, -6.962907383361912],
            [110.488385052737073, -6.96279886473877],
            [110.488404446525507, -6.962654155694398],
            [110.488423518208535, -6.962545628804835],
            [110.488480234764424, -6.962419051370468],
            [110.488582438907301, -6.962346796867737],
            [110.488654090162839, -6.962274509313959],
            [110.488692086472199, -6.962193139744095],
            [110.488703696112736, -6.961921783483299],
            [110.488696477891196, -6.96179513690284],
            [110.488655176088358, -6.96153276907583],
            [110.488662658693741, -6.961415184010628],
            [110.488693447535681, -6.960935799040463],
            [110.488700578680692, -6.960881532982618],
            [110.48873765763544, -6.960863481796808],
            [110.488838963761381, -6.960836454374751],
            [110.488894460306028, -6.960791286190484],
            [110.488885163887929, -6.960755093636963],
            [110.488839081216312, -6.960727906969808],
            [110.488286068740265, -6.960682081055316],
            [110.487880435325934, -6.960645459671448],
            [110.48787116840758, -6.960582130256351],
            [110.48803802222757, -6.959849615060117],
            [110.488056654113834, -6.959885817725793],
            [110.488157960054323, -6.959858790435958],
            [110.488462277110997, -6.959931484529953],
            [110.488637232721786, -6.960076403674814],
            [110.488747776344283, -6.960139842520319],
            [110.488812305891983, -6.960112775354633],
            [110.488858476583587, -6.960058551464327],
            [110.488867968714885, -6.959913831669941],
            [110.488740045072205, -6.958918674388055],
            [110.488731394625106, -6.958285471059791],
            [110.488760752342912, -6.956774882766212],
            [110.48882627839339, -6.956087485699933],
            [110.488854412708548, -6.955707599635486],
            [110.488859007456895, -6.955644285188538],
            [110.488880273320547, -6.955599079997342],
            [110.488979334702989, -6.955553958774695],
            [110.489185339317245, -6.955499907286724],
            [110.489387373621881, -6.955454897054405],
            [110.489494629054434, -6.955418830185013],
            [110.489580765176797, -6.955292284216838],
            [110.489723245696538, -6.955120570797137],
            [110.489890971859253, -6.954885565103649],
            [110.489990198881046, -6.95468666809318],
            [110.490063516795871, -6.954379195574371],
            [110.490111471886536, -6.954243562733003],
            [110.490193110600345, -6.95408987488161],
            [110.490244510801801, -6.953976247597654],
            [110.490274782380993, -6.953909472229348],
            [110.490285779434316, -6.953976292016285],
            [110.490296980714291, -6.95404475852525],
            [110.490305408971352, -6.954099041389516],
            [110.490315721537812, -6.954120878119107],
            [110.49033532727465, -6.954162393014104],
            [110.490438475642449, -6.954262005967785],
            [110.490618522000261, -6.954406929785187],
            [110.490828837945756, -6.954551886088796],
            [110.490944765354996, -6.954606284520105],
            [110.491064975124502, -6.95462450499518],
            [110.491283943538974, -6.954615694644958],
            [110.491515886089303, -6.954643080659084],
            [110.491795120319992, -6.954625289147311],
            [110.492245894181266, -6.954508179450872],
            [110.492833597416393, -6.954382170339967],
            [110.492848084030314, -6.954379188348891],
            [110.494057660553992, -6.954130201845335],
            [110.495022262267469, -6.953923181699871],
            [110.496211887711183, -6.9535988056207],
            [110.497525730065334, -6.953247421302859],
            [110.498264562153551, -6.953058246033639],
            [110.498401867386761, -6.953032287168869],
            [110.498883088102204, -6.952941307458606],
            [110.499478447870899, -6.952822776946057],
            [110.499127001436904, -6.953899577370852],
            [110.498905541312837, -6.954529490909113],
            [110.498707258678721, -6.955075793651245],
            [110.49919188359938, -6.955315676107357],
            [110.498864901150199, -6.956111306275012],
            [110.499112963561387, -6.956252883385064],
            [110.49914362833141, -6.956273357730772],
            [110.499329274986565, -6.956397310769147],
            [110.499283047073106, -6.956443405423469],
            [110.499213680742827, -6.956535619141309],
            [110.499160199130614, -6.956617755751937],
            [110.499087821250825, -6.956828209089672],
            [110.499041452665097, -6.95700696624989],
            [110.499011008547839, -6.957138154728291],
            [110.498955862072791, -6.957429377464076],
            [110.498894985932409, -6.957680218522044],
            [110.498829870641472, -6.957845977926566],
            [110.498782809340256, -6.957997336580571],
            [110.498728628101205, -6.958058563589],
            [110.498623907752844, -6.958148576767524],
            [110.498540872122447, -6.95820256325722],
            [110.498504718106062, -6.958274624226245],
            [110.498475798710473, -6.958328668039076],
            [110.498475726018398, -6.958397162272638],
            [110.498472037921204, -6.958472862602298],
            [110.498439468666334, -6.958566557143986],
            [110.498194000195241, -6.958480202198126],
            [110.498191113106486, -6.958557918735284],
            [110.497939921415082, -6.95957390405603],
            [110.497788504614448, -6.960139631024203],
            [110.4976480578555, -6.960854935215512],
            [110.497590337123157, -6.961252743003946],
            [110.497479157404413, -6.961606304658739],
            [110.497411300146965, -6.961741553512723],
            [110.497359480079467, -6.961940105411052],
            [110.497293928688677, -6.96212999416868],
            [110.497201228586732, -6.962470218036761],
            [110.497152165907522, -6.962690673075121],
            [110.497140921008651, -6.962889665161617],
            [110.497158776028243, -6.963124870736132],
            [110.497187770969816, -6.963546620198493],
            [110.497184214941029, -6.963995635843728],
            [110.497196143325581, -6.96435547915639],
            [110.497250598940099, -6.965244349448664],
            [110.497244148363237, -6.965521135370518],
            [110.49720389979683, -6.965742526792503],
            [110.497126618044646, -6.966056143074227],
            [110.497082583234118, -6.966399310465436],
            [110.49704235702005, -6.966715864954714],
            [110.496994871740114, -6.966941955344049],
            [110.496906883753056, -6.967184608637396],
            [110.496792771469742, -6.967399770418027],
            [110.496552303811924, -6.967765495859562],
            [110.496428944335321, -6.967992949574545],
            [110.496390766696663, -6.96810819912345],
            [110.49637253555268, -6.968225772954519],
            [110.496368761301113, -6.968316225283845],
            [110.496379337755542, -6.968479057985158],
            [110.49641192256594, -6.968696187947165],
            [110.496437132661995, -6.968931401319001],
            [110.49646203091244, -6.969193751261366],
            [110.496487231388841, -6.969438010253863],
            [110.496515797741466, -6.969709409734933],
            [110.49654472424308, -6.969908444519587],
            [110.496594334533214, -6.970342687840524],
            [110.496636763171693, -6.970614102081694],
            [110.496634139832551, -6.97068646436337],
            [110.496616772805922, -6.970807208850012],
            [110.49663819364342, -6.970924099697566],
            [110.496708726939758, -6.971167137313865],
            [110.496917406736799, -6.971748624236574],
            [110.497022033920672, -6.971980403240451],
            [110.497055602915793, -6.972070895328156],
            [110.49714302316525, -6.972333311801482],
            [110.497187357361099, -6.972600818060717],
            [110.497224004184474, -6.972871498815465],
            [110.497260690385545, -6.973105273916977],
            [110.497287451847811, -6.973256120263993],
            [110.497312625393548, -6.973471310642681],
            [110.497421590347088, -6.974278419712417],
            [110.497428499257367, -6.974492491433356],
            [110.497446635370693, -6.974701722610945],
          ],
        ],
      },
    },
  ],
};
